<ng-container *ngIf="{ isOpen: isOpen$ | async } as $">
    <interacta-dialog
        *ngIf="$.isOpen && control"
        [closeOnBackdropClick]="false"
        [isOpen]="$.isOpen"
        [size]="'extra-small'"
        (closing)="close()"
    >
        <div class="typo-t6" title>
            {{
                (mode === 'generic'
                    ? 'TRANSLATE.TRANSLATE_DIALOG_GENERIC_TITLE'
                    : mode === 'post'
                      ? 'TRANSLATE.TRANSLATE_DIALOG_POST_TITLE'
                      : 'TRANSLATE.TRANSLATE_DIALOG_COMMENT_TITLE'
                ) | translate
            }}
        </div>
        <div class="mb-16" search>
            <interacta-dialog-search
                *ngIf="languageList.length > 10"
                [control]="searchControl"
                [iconPosition]="'right'"
            />
        </div>
        <div class="max-h-404 flex flex-col gap-8 overflow-auto" content>
            <interacta-input-single-select
                [canClearSelection]="false"
                [control]="control"
                [filterClearSelection]="false"
                [items]="filteredLanguageList"
                [maxItemsForRadioView]="filteredLanguageList.length + 1"
                [quickItems]="preselected ? [preselected] : []"
            />
        </div>
        <interacta-dialog-buttons>
            <button
                interacta-dialog-button
                type="button"
                [appearance]="'ghost'"
                [bgColor]="'surface-A'"
                [label]="'BUTTON.LABEL_BUTTON_CANCEL' | translate"
                (click)="close()"
            ></button>
            <button
                interacta-dialog-button
                type="button"
                [appearance]="'fill'"
                [bgColor]="'primary'"
                [disabled]="!control.valid"
                [label]="'BUTTON.LABEL_BUTTON_APPLY' | translate"
                (click)="submit()"
            ></button>
        </interacta-dialog-buttons>
    </interacta-dialog>
</ng-container>
