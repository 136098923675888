import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Language } from '@interacta-shared/data-access-configuration';
import { TranslationInputType } from '@modules/communities/models/custom-metadata/custom-metadata.model';

@Component({
    selector: 'interacta-input-translation-dialog-v2',
    templateUrl: './input-translation-dialog-v2.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTranslationDialogV2Component implements OnInit {
    @Input() languages: Language[] = [];
    @Input() defaultLanguage!: Language;
    @Input() form!: UntypedFormGroup;
    @Input() fieldName = '';
    @Input() isFieldRequired = true;
    @Input() inputType: TranslationInputType = TranslationInputType.STRING;
    @Input() isDialogOpen = false;
    @Input() readonly = false;
    @Input() plainTextMaxLength = 8000;
    @Output() closeDialog = new EventEmitter<UntypedFormGroup>();

    localForm!: UntypedFormGroup;

    ngOnInit(): void {
        const group: { [key: string]: UntypedFormControl } = {};
        this.languages.forEach(
            (language: Language) =>
                (group[language.code] = new UntypedFormControl(
                    this.form.controls[language.code].value,
                    this.form.controls[language.code].validator
                        ? [this.form.controls[language.code].validator]
                        : [],
                )),
        );
        this.localForm = new UntypedFormGroup(group);
    }
}
