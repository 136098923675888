import { CustomPostSerialize } from '@modules/post/models/custom-post.model';
import { fromMetadataFieldModeType } from '../custom-metadata/custom-metadata.serialize';
import { ScreenSerialize } from '../screen.model';
import { CatalogEntryFilter } from './catalog-entry-filter';

export const fromCatalogEntryFilter = (filter: CatalogEntryFilter): any => ({
    pageToken: filter.pageToken,
    pageSize: filter.pageSize,
    calculateTotalItemsCount: false,
    label: filter.label,
    context: filter.context
        ? {
              type: fromMetadataFieldModeType(filter.context.fieldMode),
              fieldId: filter.context.fieldId,
              communityId: filter.context.communityId,
              postContext: filter.context.postContext
                  ? CustomPostSerialize.contextCustomPost(
                        filter.context.postContext,
                    )
                  : undefined,
              screenContext: filter.context.screenContext
                  ? ScreenSerialize.screenContext(filter.context.screenContext)
                  : undefined,
          }
        : undefined,
});
