import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';
import { SeparatorComponent } from '../separator/separator.component';

@Component({
    selector: 'interacta-dialog-buttons',
    template: `
        @if (showSeparator) {
            <interacta-separator class="mt-16 block" [thickness]="'soft'" />
        }
        <div class="mt-16 flex justify-end gap-x-12">
            @if (showLoader) {
                <interacta-loader />
            } @else {
                <ng-content select="button[interacta-dialog-button]" />
            }
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, LoaderComponent, SeparatorComponent],
})
export class DialogButtonsComponent {
    @Input() showLoader = false;
    @Input() showSeparator = false;
}
