import { AbstractControl } from '@angular/forms';
import { ICustomFieldDefinition } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { merge, Observable } from 'rxjs';
import { debounceTime, filter, map, pairwise, startWith } from 'rxjs/operators';
import { ListenChangesChange } from './listen-changes.model';

export function getListenChanges(
    fields: ICustomFieldDefinition[],
    controls: Record<number, AbstractControl>,
): Observable<ListenChangesChange> {
    const listenChangesFields = fields.filter(
        (field) => field.listenChanges && !!controls[field.id],
    );
    const listenChangesById$ = listenChangesFields.map((field) =>
        controls[field.id].valueChanges.pipe(
            debounceTime(500),
            filter((_) => controls[field.id].valid && controls[field.id].dirty),
            startWith(controls[field.id].value),
            pairwise(),
            filter(([prev, curr]) => prev !== curr),
            map(([_, curr]) => ({ fieldId: field.id, change: curr })),
        ),
    );
    return merge(...listenChangesById$);
}
