import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { IconComponent } from '@interacta-shared/ui-icons';
import { getRandomColor } from '@interacta-shared/util';
import { GroupAvatar, Size } from '../../model';

@Component({
    selector: 'interacta-group-avatar',
    templateUrl: './group-avatar.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, IconComponent],
})
export class GroupAvatarComponent implements OnInit {
    @Input({ required: true }) group!: GroupAvatar;
    @Input() size: Extract<Size, 'regular' | 'small' | 'extra-small'> =
        'regular';
    @Input() classes = '';

    colorClass = '';

    ngOnInit(): void {
        this.colorClass = getRandomColor(this.group.id);
    }
}
