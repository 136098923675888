export function unique<T>(
    array: T[],
    selector?: (x: T) => string | number,
): T[] {
    if (selector) {
        const map = new Map<string | number, T>();
        array.forEach((x) => map.set(selector(x), x));
        return [...map.values()];
    } else {
        return [...new Set(array)];
    }
}
