import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Size } from '../../model';
import { InputTextV2Component } from '../input-text-V2/input-text-V2.component';

@Component({
    selector: 'interacta-input-search',
    templateUrl: './input-search.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [InputTextV2Component],
})
export class InputSearchComponent {
    @Input({ required: true }) control!: UntypedFormControl;
    @Input() iconClickable = true;
    @Input() isReadonly = false;
    @Input() bgColor: 'default' | 'bg-card' = 'default';
    @Input() fontSize: Extract<Size, 'regular' | 'small'> = 'regular';
    @Input() iconPosition?: 'right' | 'left';
    @Input() iconColor?: 'primary' | 'gray' | 'black';
    @Input() label?: string;

    @ViewChild('input') input!: InputTextV2Component;

    iconClicked(): void {
        if (this.control.value?.length > 0) {
            this.control.setValue('');
        }
    }

    public focus(): void {
        this.input.focus();
    }
}
