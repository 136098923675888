import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Appearance, Palette } from '@interacta-shared/ui';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';

@Component({
    standalone: true,
    selector: 'interacta-filters-pill',
    templateUrl: './filters-pill.component.html',
    imports: [IconComponent, MatTooltipModule, MatRippleModule, NgClass, NgIf],
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersPillComponent {
    @Input({ required: true }) label!: string;
    @Input() appearance: Appearance = 'fill';
    @Input() isActive = false;
    @Input() disabled = false;
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-A';
    @Input() icon?: Extract<
        InteractaIcon,
        'close' | 'sort-a-z' | 'sort-z-a' | 'chevron-small-down'
    > = 'close';
    @Input() iconTooltip = '';
    @Input() enableHover = false;
    @Output() chipClicked = new EventEmitter<void>();
    @Output() iconClicked = new EventEmitter<void>();
}
