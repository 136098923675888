import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InteractaIcon } from '@interacta-shared/ui-icons';
import { TranslateModule } from '@ngx-translate/core';
import { Size } from '../../model';
import { ApplyPipe } from '../../pipes';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { ImageComponent } from '../image/image.component';

@Component({
    selector: 'interacta-image-preview',
    standalone: true,
    imports: [
        CommonModule,
        ImageComponent,
        TranslateModule,
        IconButtonComponent,
        MatTooltipModule,
        ApplyPipe,
    ],
    templateUrl: `./image-preview.component.html`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewComponent {
    @Input() alt = '';
    @Input() imageSrc: string | undefined | null = null;
    @Input() disabled = false;
    @Input() active = false;
    @Input() imageSize: Extract<Size, 'regular' | 'small'> = 'regular';
    @Input() iconButton: { icon: InteractaIcon; label: string } | null = null;

    @Output() imgClicked = new EventEmitter<void>();
    @Output() iconClicked = new EventEmitter<void>();

    buildIconLabel(data: { label: string; alt: string }): string {
        return `${data.label}${data.alt.length ? ': ' + data.alt : ''}`;
    }
}
