import { NgClass, NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Size } from '../../model';

@Component({
    selector: 'interacta-input-multiple-select-label-item',
    template: `
        <div #container class="flex flex-wrap space-x-4">
            <span
                [matTooltip]="label"
                [matTooltipDisabled]="!tooltipEnabled"
                [ngClass]="{
                    italic: italic,
                    'typo-sm': size === 'small',
                    'typo-xs': size === 'extra-small'
                }"
                >{{ label }}</span
            >
            <span
                *ngIf="countState"
                [ngClass]="{
                    'typo-sm-bold': size === 'small',
                    'typo-xs-bold': size === 'extra-small'
                }"
                >({{ countState.count }}/{{ countState.totalCount }})</span
            >
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, MatTooltipModule, NgIf],
})
export class InputMultipleSelectLabelItemComponent implements AfterViewInit {
    @Input({ required: true }) label!: string;
    @Input() italic = false;
    @Input() countState?: { count: number; totalCount: number };
    @Input() size: Extract<Size, 'small' | 'extra-small'> = 'small';

    tooltipEnabled = false;
    @ViewChild('container') containerRef!: ElementRef<HTMLElement>;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        const element = this.containerRef.nativeElement;
        this.tooltipEnabled = element.scrollHeight > element.clientHeight;
        setTimeout(() => this.cdr.markForCheck());
    }
}
