import { IExternalLink } from '@modules/core/models/external-link/external-link.model';
import { DigitalWorkplaceDefinition } from '@modules/digital-workplace/models/digital-workplace.model';
import { BehaviorSubject } from 'rxjs';

export class LinksDialogState {
    isOpen = new BehaviorSubject<boolean>(false);
    externalLinks = new BehaviorSubject<IExternalLink[] | undefined>(undefined);
    dwDefinitions = new BehaviorSubject<
        DigitalWorkplaceDefinition[] | undefined
    >(undefined);
    linksTotalCount = new BehaviorSubject<number>(0);
}
