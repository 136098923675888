<div
    class="rounded-4 bg-status-H text-text-on-status w-fit"
    [ngClass]="{
        'typo-sm-bold px-8 py-4': size === 'regular',
        'typo-xs max-w-120 truncate px-4': size === 'small'
    }"
>
    {{ label }}
    <span *ngIf="excludedGroupsCount" class="text-text-error ml-4">
        {{ '-' + excludedGroupsCount }}
    </span>
</div>
