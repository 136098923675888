import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IconComponent } from '@interacta-shared/ui-icons';
import { fadeInOutAnimation } from '../../animations';
import { Palette, Size } from '../../model';

@Component({
    selector: 'interacta-accordion',
    templateUrl: './accordion.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInOutAnimation('advanced', 300)],
    standalone: true,
    imports: [NgClass, IconComponent, NgIf],
})
export class AccordionComponent {
    @Input({ required: true }) isOpen = false;
    @Input() label!: string;
    @Input() size: Extract<Size, 'extra-large' | 'regular' | 'small'> =
        'regular';
    @Input() colorPalette: Extract<Palette, 'gray-700' | 'textColor'> =
        'textColor';

    @Output() toggleOpen = new EventEmitter();
}
