<interacta-input-multiple-select-indeterminate
    [control]="control"
    [items]="items"
    [noResultLabel]="
        ('UI.NO_RESULT' | translate) +
        '\<br\>' +
        ('UI.NO_RESULT_HINT' | translate)
    "
    [occurrences]="occurrences"
    [totalCount]="items.length"
/>
