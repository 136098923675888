import { mapArrayById } from '@interacta-shared/util';
import {
    AdminV2PaginatedFilters,
    DefaultAdminV2PaginatedFilters,
} from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { fromAdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.serialize';
import { fromAdminV2Date } from '@modules/admin-v2-shared/utils/admin-v2-filters';
import { DefaultGroupSortOrder } from '../utils/admin-v2-group-config';
import {
    AdminV2GroupDetail,
    AdminV2GroupFilters,
    AdminV2GroupRow,
    AdminV2GroupSort,
    AdminV2GroupStatusFilter,
    AdminV2GroupsUsersUpdate,
    AdminV2NewGroup,
} from './admin-v2-group.model';

export const fromAdminV2GroupFilters = (
    filters: AdminV2GroupFilters,
    paginatedFilters?: AdminV2PaginatedFilters,
    sort?: AdminV2GroupSort,
): any => {
    if (!sort) {
        sort = DefaultGroupSortOrder;
    }
    if (!paginatedFilters) {
        paginatedFilters = DefaultAdminV2PaginatedFilters;
    }

    const { from: createdFrom, to: createdTo } = fromAdminV2Date(
        filters.created,
    );

    return {
        fullTextFilter: filters.search,
        statusFilter: fromAdminV2GroupStatusFilter(filters.status),
        workspaceIds: mapArrayById(filters.workspaces),
        visible: filters.visibility,
        tagIds: mapArrayById(filters.tags),
        creationTimestampFrom: createdFrom,
        creationTimestampTo: createdTo,
        excludeGroupByMemberUserId: filters.excludeGroupByMemberUserId,
        externalIdFullTextFilter: filters.externalId,
        orderTypeId: getSortColumn(sort.sortColumn),
        orderDesc: sort.orderDesc,
        checkUserMember: filters.checkUserMember,
        ...fromAdminV2PaginatedFilters(paginatedFilters),
    };
};

export const fromAdminV2GroupsUsersUpdate = (
    request: AdminV2GroupsUsersUpdate,
): any => {
    return { groupMembers: request.groups };
};

const getSortColumn = (sortColumn: keyof AdminV2GroupRow) => {
    switch (sortColumn) {
        case 'group':
            return 'name';
        case 'visible':
            return 'visibility';
        default:
            return sortColumn;
    }
};

const fromAdminV2GroupStatusFilter = (
    status: AdminV2GroupStatusFilter,
): number | null => {
    switch (status) {
        case AdminV2GroupStatusFilter.ACTIVE_GROUPS:
            return 0;
        case AdminV2GroupStatusFilter.ALL_GROUPS:
            return null;
        case AdminV2GroupStatusFilter.DELETED_GROUPS:
            return 1;
    }
};

export const fromAdminV2GroupDetail = (
    groupDetail: AdminV2GroupDetail,
): any => {
    return {
        name: groupDetail.fullName,
        email: groupDetail.email,
        description: groupDetail.description,
        visible: groupDetail.visible,
        externalId: groupDetail.externalId,
        tagIds: groupDetail.tags?.map((tag) => tag.id) ?? [],
        occToken: groupDetail.occToken,
    };
};

export const fromAdminV2NewGroup = (group: AdminV2NewGroup): any => ({
    name: group.name,
    email: group.email,
    description: group.description,
    visible: group.visible,
    externalId: group.externalId,
    workspaceIds: group.workspaces.map((workspace) => workspace.id),
    tagIds: group.tags?.map((tag) => tag.id) ?? [],
    memberIds: group.members.map((member) => member.user.id),
});
