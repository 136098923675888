<div class="bg-surface-A flex w-full flex-col gap-8 rounded-md px-16 py-8">
    <div class="typo-sm-bold">{{ community.name }}</div>
    <div class="text-text-mid-contrast typo-sm flex gap-8">
        <span *ngIf="community.membersCount != null">{{
            community.membersCount +
                ' ' +
                ('COMMUNITY-CARD.' +
                    (community.membersCount > 1 ? 'MEMBERS_PLURAL' : 'MEMBERS')
                    | translate)
        }}</span>
        <span *ngIf="community.postsCount != null">{{
            community.postsCount +
                ' ' +
                ('COMMUNITY-CARD.' +
                    (community.postsCount! > 1 ? 'POSTS_PLURAL' : 'POSTS')
                    | translate)
        }}</span>
    </div>
</div>
