import {
    FirstLoading,
    LastLoading,
    PageTokenInfo,
    RegularLoading,
} from './page-token.model';

export const firstLoading = (): FirstLoading => ({ tag: 'firstLoading' });

export const regularLoading = (nextPageToken: string): RegularLoading => ({
    tag: 'regularLoading',
    nextPageToken,
});

export const lastLoading = (): LastLoading => ({ tag: 'lastLoading' });

/**
 *
 * @param pageToken undefined --> firstLoading
 *                  string --> regularLoading
 *                  null --> lastLoading
 * @returns
 */
export const fromPageToken = (
    pageToken: string | null | undefined,
): PageTokenInfo =>
    pageToken === undefined
        ? firstLoading()
        : pageToken != null
          ? regularLoading(pageToken)
          : lastLoading();

export const getNextPageToken = (
    pageTokenInfo: PageTokenInfo,
): string | null =>
    pageTokenInfo.tag === 'regularLoading' ? pageTokenInfo.nextPageToken : null;
