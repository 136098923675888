import { IValidationError } from '@interacta-shared/data-access-error';
import { InteractaIcon } from '@interacta-shared/ui-icons';
import { PaginatedList } from '@interacta-shared/util';
import { AdminV2RoleAssociation } from '@modules/admin-v2-roles/models/admin-v2-roles-associations.model';
import {
    AdminV2PaginatedList,
    AdminV2PaginatedListRow,
} from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import {
    AdminV2AdminMembersCol,
    AdminV2CoverImage,
    AdminV2FiltersLabel,
    AdminV2Label,
    AdminV2OccToken,
    AdminV2Sort,
} from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import { ScreenFieldDefinition } from '@modules/admin-v2-workflow-designer/models/workflow-admin.model';
import {
    AdminV2Workspace,
    AdminV2WorkspaceCapabilities,
} from '@modules/admin-v2-workspace/models/admin-v2-workspace.model';
import { ConfigurableFieldType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { IWorkflowDefinition } from '@modules/communities/models/workflow/workflow.model';
import { ConditionalValidationConfiguration } from '@modules/conditional-validation-configuration/models/conditional-validation-configuration.model';
import { i18nDeltaV2, i18nStringV2 } from '@modules/core';
import {
    Member,
    MemberGroup,
    MemberUser,
} from '@modules/core/models/member/member.model';
import { CustomFieldsConfigurationBaseWithValidationAndOrder } from '@modules/custom-fields-configuration/models/custom-fields-configuration.model';
import {
    ConfigurationFieldType,
    VisibilityType,
} from '@modules/post/models/base-post.model';
import { AdminV2AcknowledgeField } from './admin-v2-community-acknowledge-field.model';
import { AdminV2CommunityHashtagCol } from './admin-v2-community-hashtag.model';
import {
    AdminV2CommunityPostIdType,
    AdminV2CommunityTitleType,
} from './admin-v2-community-standard-fields.model';

export interface AdminV2CommunityFilters {
    status: AdminV2CommunityStatusFilter;
    search?: string;
    workspaces?: AdminV2Workspace[];
    adminMembers?: Member[];
}

export interface AdminV2Community {
    id: number;
    draft: boolean;
    active: boolean;
    deleted: boolean;
    name: i18nStringV2;
    occToken?: AdminV2OccToken;
}

export type AdminV2CommunityLite = Omit<
    AdminV2Community,
    'occToken' | 'name'
> & {
    name: string;
};

// associazione community e i suoi ruoli operativi
export interface AdminV2CommunityRoles extends AdminV2Community {
    operationalRoles: AdminV2RoleAssociation[];
}

export interface AdminV2CommunityRow extends AdminV2PaginatedListRow {
    baseData: AdminV2Community;
    workspace: AdminV2Workspace[] | null;
    lastModifyTimestamp: number;
    lastModifyUser: Member;
    membersCount: number;
    creationTimestamp: number;
    creatorUser: Member;
    adminMembers: AdminV2AdminMembersCol;
    postVisibility: VisibilityType;
    workflow: string;
    hashtags: AdminV2CommunityHashtagCol;
    capabilities: AdminV2CommunityCapabilities;
}

export type AdminV2CommunitySort = AdminV2Sort<AdminV2CommunityRow>;

export enum AdminV2CommunityStatusFilter {
    ALL = 'all',
    DRAFT = 'draft',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export type AdminV2CommunityStatusFilterLabel =
    AdminV2Label<AdminV2CommunityStatusFilter>;

export type AdminV2CommunityListPage = PaginatedList<AdminV2CommunityRow>;

export type AdminV2CommunityList = AdminV2PaginatedList<AdminV2CommunityRow>;

export type AdminV2CommunityFiltersLabel = AdminV2FiltersLabel<
    Required<AdminV2CommunityFilters>
>;

export type AdminV2CommunityManageMode =
    | 'disable'
    | 'delete'
    | 'visible'
    | 'invisible';

export interface AdminV2CommunityDetail extends AdminV2Community {
    capabilities: AdminV2CommunityCapabilities;
    // header
    lastModifyTimestamp: number;
    creationTimestamp: number;
    publicationTimestamp: number;
    workspace: AdminV2Workspace | null;
    // only overview
    groups: PaginatedList<MemberGroup> | null;
    users: PaginatedList<MemberUser> | null;
    // base info
    baseInfo: AdminV2CommunityBaseInfo;
    // impostazioni
    settings: AdminV2CommunitySettings;
    postFields: {
        standardFields: AdminV2CommunityStandardFields;
        customFields: AdminV2CommunityCustomField[];
    };
    acknowledgeTaskDefinition: AdminV2AcknowledgeField[];
    // ruoli - separati a livello di pagina
    workflow: AdminV2CommunityDetailWorkflow | null;
    customFieldsEnabled: boolean;
    occToken: AdminV2OccToken;
}

export interface AdminV2CommunityBaseInfo {
    name: i18nStringV2;
    coverImageContentViewLink: string | null;
    coverImageContentRef: string | null;
    description: i18nDeltaV2;
    compilationGuidelines: i18nDeltaV2;
    externalId: string | null;
    // dove puoi trovare le community
    visibleInDashboard: boolean;
    visibleInOrganizationTree: boolean;
}

export type AdminV2CommunityBaseInfoVisibility = keyof Pick<
    AdminV2CommunityBaseInfo,
    'visibleInDashboard' | 'visibleInOrganizationTree'
>;

export type AdminV2CommunityBaseInfoUpdate = Omit<
    AdminV2CommunityBaseInfo,
    'coverImageContentViewLink' | 'coverImageContentRef'
> & {
    coverImage: AdminV2CoverImage | null;
};

export interface AdminV2CommunitySettings {
    // tipi post
    postEnabled: boolean;
    acknowledgeTaskEnabled: boolean; // abilita tab presa visione
    eventEnabled: boolean;
    surveyEnabled: boolean;
    feedbackEnabled: boolean;
    // visibilità dei post
    defaultPostVisibility: VisibilityType;
    // altre opzioni
    attachmentEnabled: boolean;
    likeEnabled: boolean;
    // opzioni mobile
    offlineEnabled: boolean;
    mobileAttachmentResizeEnabled: boolean;
    // abilita tab
    hashtagEnabled: boolean; // abilita tab hashtag
    workflowEnabled: boolean; // abilita tab processo
}

export type AdminV2CommunityDetailWorkflow = Pick<
    IWorkflowDefinition,
    | 'id'
    | 'draft'
    | 'metadata'
    | 'title'
    | 'publicationTimestamp'
    | 'creationTimestamp'
    | 'lastModifyTimestamp'
>;

export type AdminV2CommunityDetailSettingsSectionType = 'workflow' | 'hashtag';

export type AdminV2CommunitySettingsUpdate = Omit<
    AdminV2CommunitySettings,
    'hashtagEnabled' | 'workflowEnabled'
>;

export type AdminV2CommunityCustomField =
    CustomFieldsConfigurationBaseWithValidationAndOrder & {
        searchable: boolean;
        sortable: boolean;
        visibleInCreate: boolean;
        visibleInDetail: boolean;
        visibleInEdit: boolean;
        visibleInPreview: boolean;
        visibleInList: boolean;

        labelV2: i18nStringV2;
        descriptionV2: i18nDeltaV2 | null;
        /**
         * TODO
         * spostare questi valore nel CustomFieldsConfigurationBase quando sarà
         * usato anche dagli tipi di campi custom (screen, survey?, ack?)
         */
        conditionalValidations: ConditionalValidationConfiguration[];
        externalId: string | null;
    };

export interface AdminV2CommunityCapabilities {
    editCommunity: boolean;
    manageWorkflow: boolean;
    viewCommunityDetail: boolean;
}

export interface AdminV2CommunityOverviewInfo {
    icon: InteractaIcon;
    label: string | null;
    buttonLabel?: string;
}

export type AdminV2NewCommunity = Omit<
    AdminV2CommunityBaseInfoUpdate,
    'coverImageContentViewLink'
> & {
    workspace: AdminV2Workspace;
};

export interface AdminV2CommunitiesCol {
    workspaceId?: AdminV2Workspace['id'];
    workspaceCapabilities?: AdminV2WorkspaceCapabilities;
    communities: AdminV2Community[];
}

// service deserialization
export type AdminV2CommunityPostDefinition = {
    settings: AdminV2CommunitySettings;
    standardFields: AdminV2CommunityStandardFields;
} & Pick<
    AdminV2CommunityBaseInfo,
    'visibleInDashboard' | 'visibleInOrganizationTree'
> &
    Pick<AdminV2CommunityDetail, 'customFieldsEnabled'>;

export type AdminV2CommunityDetailPartial = AdminV2Community &
    Pick<
        AdminV2CommunityDetail,
        | 'capabilities'
        | 'lastModifyTimestamp'
        | 'creationTimestamp'
        | 'publicationTimestamp'
        | 'workspace'
        | 'baseInfo'
        | 'workflow'
    > & { occToken: AdminV2OccToken };

export type AdminV2CommunityCustomFieldsVisibleInFilter =
    | 'creation'
    | 'edit'
    | 'detail'
    | 'preview'
    | 'list';

export interface AdminV2CommunityCustomFieldsFilters {
    text: string;
    type: ConfigurableFieldType | null;
    required: boolean | null;
    visibleIn?: AdminV2CommunityCustomFieldsVisibleInFilter | null;
    searchable?: boolean | null;
}

// STANDARD FIELDS
export interface AdminV2CommunityStandardFields {
    titleEnabled: ConfigurationFieldType;
    descriptionEnabled: ConfigurationFieldType;
    watchersEnabled: ConfigurationFieldType;
    watchersVisibleInPreview: boolean;
    titleAutocompleteEnabled: boolean;
    titleAutocompleteFields: AdminV2CommunityTitleType[] | null;
    postIdEnabled: boolean;
    postIdFields: AdminV2CommunityPostIdType[] | null;
    sharedWorkspaceProgressive?: boolean;
}

export type AdminV2CommunityPostFieldsType = 'standard' | 'custom';

export type CustomFieldsAdminApiResponse = {
    id: number;
    fieldListChanged: boolean;
    occToken: AdminV2OccToken;
};

export interface PublishCommunityResponse {
    communityData:
        | (Pick<AdminV2Community, 'id' | 'draft'> & {
              occToken: AdminV2OccToken;
          })
        | null;
    saved: boolean;
    validationErrors?: IValidationError[];
}

export type AdminV2CommunityPreview = AdminV2Community &
    Pick<AdminV2CommunityDetail, 'workspace' | 'workflow' | 'capabilities'> &
    Pick<
        AdminV2CommunityBaseInfo,
        'description' | 'coverImageContentViewLink'
    > & {
        adminMembers: Member[];
        usersCounter: number;
        postsCounter: number;
    };

export type AdminV2CommunityPublishError =
    | 'blocking'
    | 'wfDesigner_blocking'
    | 'overridable'
    | 'ignore';

export interface FieldCommunityPublishError {
    fieldDefinition: AdminV2CommunityCustomField | ScreenFieldDefinition;
    targetCommunityName: AdminV2CommunityLite['name'];
}
