import {
    CURRENT_USER_SEARCH_VALUE,
    EMPTY_SEARCH_VALUE,
    IList,
    PaginatedList,
    assertUnreachable,
    flatten,
    isDefined,
} from '@interacta-shared/util';
import { IUsersGroup } from '../user-group.model';
import { IUser } from '../user.model';
import {
    Member,
    MemberGroup,
    MemberInnerType,
    MemberUser,
} from './member.model';

export const wrapMemberList = (
    list: IList<IUser | IUsersGroup>,
): IList<Member> => {
    return {
        list: list.list.map(wrapMember).filter(isDefined),
        nextPageToken: list.nextPageToken,
    };
};

export const wrapMemberPaginatedList = (
    list: PaginatedList<IUser | IUsersGroup>,
): PaginatedList<Member> => {
    return {
        ...list,
        list: list.list.map(wrapMember).filter(isDefined),
    };
};

export const equals = (a: Member, b: Member): boolean => {
    return (
        ((isMemberUser(a) && isMemberUser(b)) ||
            (isMemberGroup(a) && isMemberGroup(b))) &&
        a.innerId === b.innerId
    );
};

export const wrapMember = (member: IUser | IUsersGroup): Member => {
    if ('firstName' in member) {
        return {
            id: getMemberId(member.id, 'user'),
            innerId: member.id,
            user: member,
        };
    } else {
        return {
            id: getMemberId(member.id, 'group'),
            innerId: member.id,
            group: member,
        };
    }
};

export const wrapMembers = (
    ...members: (IUser | IUsersGroup)[][]
): Member[] => {
    const list = flatten(members.filter((m) => !!m)).map((m) => wrapMember(m));
    return list;
};

export const extractUsersFromMembers = (members: Member[]): IUser[] => {
    const userList = members
        .map((u) => (isMemberUser(u) ? u.user : undefined))
        .filter(isDefined);
    return userList;
};

export const extractGroupsFromMembers = (members: Member[]): IUsersGroup[] => {
    const groupList = members
        .map((g) => (isMemberGroup(g) ? g.group : undefined))
        .filter(isDefined);
    return groupList;
};

export const memberAlphabeticalOrder = (
    members: Member[],
    desc?: boolean,
): Member[] =>
    members.sort((m1, m2) => memberAlphabeticalOrderSort(m1, m2, desc));

export const memberAlphabeticalOrderSort = (
    m1: Member,
    m2: Member,
    desc?: boolean,
): number => {
    const fullName1 = getMemberFullname(m1);
    const fullName2 = getMemberFullname(m2);

    if (fullName1 > fullName2) {
        return desc ? -1 : 1;
    } else if (fullName1 < fullName2) {
        return desc ? 1 : -1;
    } else {
        return 0;
    }
};

export function isMember(item: unknown): item is Member {
    return (
        item != null &&
        typeof item === 'object' &&
        'id' in item &&
        typeof item.id === 'string' &&
        'innerId' in item &&
        typeof item.innerId === 'number' &&
        item.innerId != undefined
    );
}

export function isMemberUser(member: Member): member is MemberUser {
    return (member as MemberUser).user !== undefined;
}

export function isMemberGroup(member: Member): member is MemberGroup {
    return (member as MemberGroup).group !== undefined;
}

export function getMemberUser(member: Member): IUser | undefined {
    return isMemberUser(member) ? member.user : undefined;
}

export function getMemberGroup(member: Member): IUsersGroup | undefined {
    return isMemberGroup(member) ? member.group : undefined;
}

export const getMemberFullname = (member: Member): string => {
    return isMemberUser(member) ? member.user.fullName : member.group.fullName;
};

const getMemberId = (
    id: Member['innerId'],
    type: MemberInnerType,
): Member['id'] => {
    switch (type) {
        case 'user':
            return `u-${id}`;
        case 'group':
            return `g-${id}`;
        default: {
            assertUnreachable(type);
        }
    }
};

const getUserPlaceholder = (translatedName: string): Omit<IUser, 'id'> => ({
    fullName: translatedName,
    accountPhotoUrl: '',
    firstName: translatedName,
    lastName: translatedName,
    contactEmail: '',
    googleAccountId: '',
    privateProfile: false,
    icon: 'person',
    licences: [],
});

export const getEmptyUserPlaceholder = (translatedName: string): IUser => ({
    ...getUserPlaceholder(translatedName),
    id: EMPTY_SEARCH_VALUE,
});

export const getCurrentUserPlaceholder = (translatedName: string): IUser => ({
    ...getUserPlaceholder(translatedName),
    id: CURRENT_USER_SEARCH_VALUE,
});

export const getCurrentUserPlaceholderAsMember = (
    translatedName: string,
): Member => wrapMember(getCurrentUserPlaceholder(translatedName));

export const getEmptyUserPlaceholderAsMember = (
    translatedName: string,
): Member => wrapMember(getEmptyUserPlaceholder(translatedName));

export const getEmptyGroupPlaceholder = (
    translatedName: string,
): IUsersGroup => ({
    id: EMPTY_SEARCH_VALUE,
    fullName: translatedName,
});
export const getEmptyGroupPlaceholderAsMember = (
    translatedName: string,
): Member => wrapMember(getEmptyGroupPlaceholder(translatedName));

export const splitUserAndGroupsFromMembersArray = (
    members: Member[],
): {
    users: MemberUser[];
    groups: MemberGroup[];
} => {
    return {
        users: members.filter(isMemberUser) ?? [],
        groups: members.filter(isMemberGroup) ?? [],
    };
};

export const extractMemberInnerIdFromMembersArray = (
    members: Member[] | undefined,
): number[] | undefined => {
    return members?.map((m) => m.innerId);
};
