import { mapArrayById } from '@interacta-shared/util';
import {
    AdminV2PaginatedFilters,
    DefaultAdminV2PaginatedFilters,
} from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { fromAdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.serialize';
import {
    cleanEmptyI18nDeltaV2,
    cleanEmptyI18nStringV2,
    fromI18nDeltaV2,
    i18nStringToV2,
} from '@modules/admin-v2-shared/utils/admin-v2-i18n';
import { parseIfNotNull } from '@modules/admin-v2-shared/utils/admin-v2-utils';
import { ICustomFieldAdminValidation } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { ConditionalValidationConfiguration } from '@modules/conditional-validation-configuration/models/conditional-validation-configuration.model';
import {
    extractGroupsFromMembers,
    extractUsersFromMembers,
} from '@modules/core/models/member/member.utils';
import { fromCustomFieldConfigurationBase } from '@modules/custom-fields-configuration/models/custom-fields-configuration.serialize';
import { DefaultCommunitySortOrder } from '../utils/admin-v2-community-config';
import { AdminV2AcknowledgeField } from './admin-v2-community-acknowledge-field.model';
import {
    AdminV2CommunityHashtag,
    AdminV2CommunityHashtagFilters,
    AdminV2CommunityHashtagStatusFilter,
} from './admin-v2-community-hashtag.model';
import { AdminV2CommunityRolesFilters } from './admin-v2-community-roles.model';
import {
    AdminV2CommunityPostIdType,
    AdminV2CommunityTitleType,
} from './admin-v2-community-standard-fields.model';
import {
    AdminV2CommunityBaseInfoUpdate,
    AdminV2CommunityCustomField,
    AdminV2CommunityFilters,
    AdminV2CommunityPostDefinition,
    AdminV2CommunityRow,
    AdminV2CommunitySort,
    AdminV2CommunityStatusFilter,
} from './admin-v2-community.model';

export const fromAdminV2CommunityFilters = (
    filters: AdminV2CommunityFilters,
    paginatedFilters?: AdminV2PaginatedFilters,
    sort?: AdminV2CommunitySort,
): any => {
    if (!sort) {
        sort = DefaultCommunitySortOrder;
    }
    if (!paginatedFilters) {
        paginatedFilters = DefaultAdminV2PaginatedFilters;
    }

    return {
        fullTextFilter: filters.search,
        workspaceIds: mapArrayById(filters.workspaces),
        adminUserIds: mapArrayById(
            extractUsersFromMembers(filters.adminMembers ?? []),
        ),
        adminGroupIds: mapArrayById(
            extractGroupsFromMembers(filters.adminMembers ?? []),
        ),

        orderTypeId: getSortColumn(sort.sortColumn),
        orderDesc: sort.orderDesc,
        ...fromAdminV2CommunityStatusFilter(filters.status),
        ...fromAdminV2PaginatedFilters(paginatedFilters),
    };
};

const fromAdminV2CommunityStatusFilter = (
    status: AdminV2CommunityStatusFilter,
): any => {
    switch (status) {
        case AdminV2CommunityStatusFilter.ALL:
            return {
                activeStatusFilter: null,
                draftStatusFilter: null,
            };
        case AdminV2CommunityStatusFilter.ACTIVE:
            return {
                activeStatusFilter: true,
                draftStatusFilter: false,
            };
        case AdminV2CommunityStatusFilter.INACTIVE:
            return {
                activeStatusFilter: false,
                draftStatusFilter: null,
            };
        case AdminV2CommunityStatusFilter.DRAFT:
            return {
                activeStatusFilter: null,
                draftStatusFilter: true,
            };
    }
};

export const fromAdminV2CommunityBaseInfoUpdate = (
    community: AdminV2CommunityBaseInfoUpdate,
): any => ({
    name: cleanEmptyI18nStringV2(community.name),
    description: fromI18nDeltaV2(cleanEmptyI18nDeltaV2(community.description)),
    info: fromI18nDeltaV2(
        cleanEmptyI18nDeltaV2(community.compilationGuidelines),
    ),
    coverImageContentRef: community.coverImage?.contentRef ?? null,
    externalId: community.externalId,
    visibleInOrganizationTree: community.visibleInOrganizationTree,
    visibleInDashboard: community.visibleInDashboard,
});

const getSortColumn = (sortColumn: keyof AdminV2CommunityRow) => {
    switch (sortColumn) {
        case 'baseData':
            return 'name';
        case 'creatorUser':
            return 'creationUser';
        default:
            return sortColumn;
    }
};

export const fromAdminV2CommunityPostDefinition = (
    postDefinition: AdminV2CommunityPostDefinition,
): any => {
    const { settings, standardFields } = postDefinition;
    return {
        // SETTINGS
        customPostEnabled: settings.postEnabled,
        acknowledgeTaskEnabled: settings.acknowledgeTaskEnabled,
        eventPostEnabled: settings.eventEnabled,
        surveyPostEnabled: settings.surveyEnabled,
        feedbackPostEnabled: settings.feedbackEnabled,
        defaultPostVisibility: settings.defaultPostVisibility,
        attachmentEnabled: settings.attachmentEnabled,
        likeEnabled: settings.likeEnabled,
        offlineEnabled: settings.offlineEnabled,
        mobileAttachmentResizeEnabled: settings.mobileAttachmentResizeEnabled,
        hashtagEnabled: settings.hashtagEnabled,
        workflowEnabled: settings.workflowEnabled,
        // STANDARD FIELDS
        titleEnabled: standardFields.titleEnabled,
        descriptionEnabled: standardFields.descriptionEnabled,
        watchersEnabled: standardFields.watchersEnabled,
        watchersVisibleInPreview: standardFields.watchersVisibleInPreview,
        titleAutoCompletion:
            standardFields.titleAutocompleteFields === null
                ? null // vuol dire che la regex rimane invariata (caso illeggibile)
                : standardFields.titleAutocompleteEnabled &&
                    standardFields.titleAutocompleteFields.length > 0
                  ? fromAdminV2CommunityTypeList(
                        standardFields.titleAutocompleteFields,
                    )
                  : {}, // l'oggetto vuoto significa che cancello la regex dal database
        customIdAutoCompletion:
            standardFields.postIdFields === null
                ? null // vuol dire che la regex rimane invariata (caso illeggibile)
                : standardFields.postIdEnabled &&
                    standardFields.postIdFields.length > 0
                  ? fromAdminV2CommunityTypeList(standardFields.postIdFields)
                  : {}, // l'oggetto vuoto significa che cancello la regex dal database
        // DETAIL
        visibleInOrganizationTree: postDefinition.visibleInOrganizationTree,
        visibleInDashboard: postDefinition.visibleInDashboard,
        customFieldsEnabled: postDefinition.customFieldsEnabled,
    };
};

const fromAdminV2CommunityTypeList = (
    postIdTypeList: AdminV2CommunityPostIdType[] | AdminV2CommunityTitleType[],
): any => {
    const contentData: {
        workspaceProgressiveCounterConfig: any;
        communityProgressiveCounterConfig: any;
        tokens: {
            type: string;
            value?: string;
            fieldId?: number;
            digits?: number;
        }[];
    } = {
        workspaceProgressiveCounterConfig: null,
        communityProgressiveCounterConfig: null,
        tokens: [],
    };
    for (const postIdType of postIdTypeList) {
        switch (postIdType.fieldType) {
            case 'text':
                contentData.tokens.push({
                    type: postIdType.fieldType,
                    value: postIdType.fieldValue,
                });
                break;
            case 'field_id':
                contentData.tokens.push({
                    type: postIdType.fieldType,
                    fieldId: postIdType.fieldValue,
                });
                break;
            case 'yy':
            case 'yyyy':
                contentData.tokens.push({
                    type: postIdType.fieldType,
                });
                break;
            case 'prog_community':
                contentData.tokens.push({
                    type: postIdType.fieldType,
                    digits: postIdType.fieldValue.digits,
                });
                contentData.communityProgressiveCounterConfig = {
                    startValue: postIdType.fieldValue.start,
                    resetsEveryYear: postIdType.fieldValue.resetEveryYear,
                    forceReset: postIdType.fieldValue.forceReset,
                };
                break;
            case 'prog_workspace':
                contentData.tokens.push({
                    type: postIdType.fieldType,
                    digits: postIdType.fieldValue.digits,
                });
                contentData.workspaceProgressiveCounterConfig = {
                    startValue: postIdType.fieldValue.start,
                    resetsEveryYear: postIdType.fieldValue.resetEveryYear,
                    forceReset: postIdType.fieldValue.forceReset,
                };
                break;
        }
    }
    return contentData;
};

export const fromAdminV2AcknowledgeField = (
    acknowledgeField: AdminV2AcknowledgeField,
): any => ({
    ...fromCustomFieldConfigurationBase(acknowledgeField),
    label: cleanEmptyI18nStringV2(i18nStringToV2(acknowledgeField.labelV2)),
    description: parseIfNotNull(
        acknowledgeField.descriptionV2,
        fromI18nDeltaV2,
    ),
    validations: fromAdminV2CommunityCustomFieldValidation(acknowledgeField),
    order: acknowledgeField.order ?? 1,
});

export const fromAdminV2CommunityCustomField = (
    customField: AdminV2CommunityCustomField,
): any => ({
    ...fromCustomFieldConfigurationBase(customField),
    label: cleanEmptyI18nStringV2(i18nStringToV2(customField.labelV2)),
    description: parseIfNotNull(customField.descriptionV2, fromI18nDeltaV2),
    validations: fromAdminV2CommunityCustomFieldValidation(customField),
    searchable: customField.searchable ?? false,
    sortable: customField.sortable ?? false,
    visibleInCreate: customField.visibleInCreate ?? false,
    visibleInDetail: customField.visibleInDetail ?? false,
    visibleInEdit: customField.visibleInEdit ?? false,
    visibleInPreview: customField.visibleInPreview ?? false,
    visibleInList: customField.visibleInList ?? false,
    order: customField.order ?? 1,
    externalId: customField.externalId,
});

export const fromAdminV2CommunityHashtagFilters = (
    filters: AdminV2CommunityHashtagFilters,
): any => {
    return {
        name: filters.search,
        deletedStatusFilter: fromAdminV2CommunityHashtagStatusFilter(
            filters.status,
        ),
        notTranslatedToLanguageFilter:
            filters.notTranslatedLanguage?.code ?? undefined,
        orderBy: filters.sort.sortColumn,
        orderDesc: filters.sort.orderDesc,
        ...fromAdminV2PaginatedFilters({
            pageToken: filters.pageToken,
            pageSize: filters.pageSize,
        }),
    };
};

const fromAdminV2CommunityHashtagStatusFilter = (
    status: AdminV2CommunityHashtagStatusFilter,
): boolean | null => {
    switch (status) {
        case AdminV2CommunityHashtagStatusFilter.ACTIVE_HASHTAG:
            return false;
        case AdminV2CommunityHashtagStatusFilter.DELETED_HASHTAG:
            return true;
        case AdminV2CommunityHashtagStatusFilter.ALL_HASHTAG:
            return null;
    }
};

export const fromAdminV2CommunityRolesFilters = (
    filters: AdminV2CommunityRolesFilters,
    paginatedFilers: AdminV2PaginatedFilters,
): any => ({
    name: filters.search,
    ...fromAdminV2PaginatedFilters(paginatedFilers),
});

export const fromAdminV2CommunityCustomFieldValidation = (
    customField: Pick<
        AdminV2CommunityCustomField,
        'required' | 'validations' | 'conditionalValidations'
    >,
): {
    required: boolean;
    validations: ICustomFieldAdminValidation;
    conditionalValidations: ConditionalValidationConfiguration[];
} => {
    return {
        required: customField.required ?? false,
        validations: { ...customField.validations },
        conditionalValidations: customField.conditionalValidations,
    };
};

export const fromAdminV2CommunityHashtag = (
    hashtag: AdminV2CommunityHashtag,
): any => ({
    name: cleanEmptyI18nStringV2(hashtag.name),
    externalId: hashtag.externalId,
    deleted: hashtag.deleted,
});
