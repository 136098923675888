import { Member } from '@modules/core/models/member/member.model';
import {
    getMemberFullname,
    isMemberUser,
} from '@modules/core/models/member/member.utils';
import {
    MentionData,
    MentionItem,
    MentionItemPlaceholder,
    mentionTypeKeys,
} from '@modules/mentions';

export const DATA_ID = 'data-id';
export const DATA_CLIENTUID = 'data-client-uid';
export const DATA_VALUE = 'data-value';
export const DATA_MENTION_TYPE = 'data-mention-type';
export const DATA_MENTION_CLICK = 'data-mention-click';

export const loadingMentionItemPlaceholder = (
    instances = 4,
): MentionItemPlaceholder[] => {
    const placeholders: MentionItemPlaceholder[] = [];
    for (let index = 0; index < instances; index++) {
        placeholders.push({
            type: 'loading',
            disabled: true,
        });
    }
    return placeholders;
};

export function isMentionItemPlaceholder(
    item: MentionItem | MentionItemPlaceholder,
): item is MentionItemPlaceholder {
    return 'disabled' in item;
}

export function isMentionDataMember(item: MentionData): item is Member {
    return 'user' in item || 'group' in item;
}

export function toMentionItem(data: MentionData): MentionItem {
    return {
        data,
        ...(isMentionDataMember(data)
            ? isMemberUser(data)
                ? {
                      id: data.innerId,
                      value: getMemberFullname(data),
                      denotationChar: mentionTypeKeys.user,
                      mentionType: 'user',
                  }
                : {
                      id: data.innerId,
                      value: getMemberFullname(data),
                      denotationChar: mentionTypeKeys.group,
                      mentionType: 'group',
                  }
            : {
                  id: data.id,
                  value: data.name,
                  denotationChar: mentionTypeKeys.hashtag,
                  mentionType: 'hashtag',
              }),
    };
}
