<div class="w-360 flex h-full bg-black py-64 pl-24 pr-32">
    <div
        *ngIf="{
            sections: sections$ | async,
            selectedSection: selectedSection$ | async
        } as $"
        class="mt-24 flex flex-col gap-y-16"
    >
        <h2 class="typo-t6 shrink-0 break-words">{{ attachment.name }}</h2>

        <ng-container *ngIf="$.sections">
            <interacta-input-search
                class="shrink-0"
                iconPosition="right"
                [control]="searchControl"
            ></interacta-input-search>
            <span
                *ngIf="$.sections?.totalCount as counter"
                class="typo-xs-bold-uppercase shrink-0"
            >
                {{
                    counter
                        | i18nPlural
                            : {
                                  '=1': 'SHARED.FILTERS.RESULTS_SING',
                                  other: 'SHARED.FILTERS.RESULTS_PLUR'
                              }
                        | translate: { counter }
                }}
            </span>

            <div
                #scrollContainer
                class="flex flex-col gap-y-12 overflow-auto"
                interactaScrollTracker
                (scrolled)="scrollTracker($event, $.sections.nextPageToken)"
            >
                <ng-container *ngIf="$.sections.list.length; else noResults">
                    <div
                        *ngFor="let section of $.sections.list; let last = last"
                        class="flex grow flex-col gap-y-12"
                    >
                        <button
                            class="rounded-lg border-2 p-8 text-left"
                            type="button"
                            [ngClass]="{
                                'typo-sm border-black':
                                    $.selectedSection !== section.id,
                                'border-emphasis typo-sm-bold':
                                    $.selectedSection === section.id
                            }"
                            (click)="
                                selectSection(section.id);
                                goToAudioVideoFrame.emit(
                                    section.audioVideoSectionPosition.start
                                )
                            "
                        >
                            <interacta-light-box-section [section]="section">
                            </interacta-light-box-section>
                        </button>

                        <interacta-separator
                            *ngIf="!last"
                        ></interacta-separator>
                    </div>
                </ng-container>
                <ng-template #noResults>
                    <interacta-no-result
                        *ngIf="
                            $.sections.nextPageToken.tag !== 'firstLoading' ||
                            ($.sections.nextPageToken.tag === 'firstLoading' &&
                                !searchControl.value)
                        "
                        textAlign="center"
                        [imageName]="
                            searchControl.value ? 'no-results' : 'attachments'
                        "
                        [lockedColor]="'light'"
                        [subtitle]="
                            (searchControl.value
                                ? 'ATTACHMENT_SECTIONS.NO_RESULTS_FROM_THE_SEARCH_SUBTITLE'
                                : ''
                            ) | translate
                        "
                        [title]="
                            (searchControl.value
                                ? 'ATTACHMENT_SECTIONS.NO_RESULTS_FROM_THE_SEARCH_TITLE'
                                : 'ATTACHMENT_SECTIONS.NO_SECTIONS_TITLE'
                            ) | translate
                        "
                    ></interacta-no-result>
                </ng-template>
                <div
                    *ngIf="$.sections.isFetching"
                    class="flex flex-col gap-y-16"
                >
                    <interacta-light-box-section-placeholder
                        *ngFor="let _ of 3 | array"
                    ></interacta-light-box-section-placeholder>
                </div>
            </div>
        </ng-container>
    </div>
</div>
