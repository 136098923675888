import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Palette, Size } from '@interacta-shared/ui';
import { uuid } from '@interacta-shared/util';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'interacta-advanced-search-bar',
    templateUrl: './advanced-search-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedSearchBarComponent {
    @Input({ required: true }) search!: FormControl<string>;
    @Input() disabled = false;
    @Input() placeholder = '';
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-A';
    @Input() size: Extract<Size, 'regular' | 'small'> = 'regular';
    @Input() openLeft = false;

    @Output() inputFocused = new EventEmitter<void>();

    isInputFocused$ = new BehaviorSubject<boolean>(false);
    readonly componentId = `interacta-${uuid()}`;

    @ViewChild('searchInput')
    searchInput?: ElementRef<HTMLInputElement>;

    @HostListener('document:click', ['$event'])
    closeFilter(event: MouseEvent): void {
        const target = event.target as HTMLElement;
        if (this.isInputFocused$.value && !this.isComponentChild(target)) {
            this.isInputFocused$.next(false);
        }
    }

    isComponentChild(element: HTMLElement | null): boolean {
        return !!element?.closest(`#${this.componentId}`);
    }

    openSearch(): void {
        this.isInputFocused$.next(true);
        this.searchInput?.nativeElement.focus();
    }

    closeSearch(): void {
        this.isInputFocused$.next(false);
    }

    inputFocus(): void {
        this.isInputFocused$.next(true);
        this.inputFocused.emit();
    }
}
