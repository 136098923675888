import { TranslateService } from '@ngx-translate/core';

export const SearchIcons = {
    search: 'M2.35 9.85a7.25 7.25 0 001.031 3.773 7.198 7.198 0 002.696 2.696A7.251 7.251 0 009.85 17.35c.89 0 1.742-.149 2.555-.445a7.731 7.731 0 002.18-1.243l5.976 5.977 1.078-1.078-5.976-5.977a7.73 7.73 0 001.242-2.18 7.385 7.385 0 00.445-2.554c0-1.36-.344-2.617-1.031-3.773a7.199 7.199 0 00-2.696-2.696A7.25 7.25 0 009.85 2.35c-1.36 0-2.617.344-3.773 1.031A7.199 7.199 0 003.38 6.077 7.251 7.251 0 002.35 9.85zm1.5 0c0-1.094.273-2.094.82-3a5.837 5.837 0 012.18-2.18 5.696 5.696 0 013-.82c1.094 0 2.102.273 3.023.82a6.07 6.07 0 012.18 2.18c.531.906.797 1.906.797 3a5.949 5.949 0 01-.797 3.023 6.326 6.326 0 01-2.18 2.18 5.948 5.948 0 01-3.023.797 5.823 5.823 0 01-3-.797 6.072 6.072 0 01-2.18-2.18 5.818 5.818 0 01-.82-3.023z',
    delete: 'M20 5.611L18.389 4 12 10.389 5.611 4 4 5.611 10.389 12 4 18.389 5.611 20 12 13.611 18.389 20 20 18.389 13.611 12 20 5.611z',
};

export const Emojii18n = (translateService: TranslateService) => ({
    search: translateService.instant('SHARED.EMOJI_PICKER.SEARCH'),
    emojilist: translateService.instant('SHARED.EMOJI_PICKER.LIST'),
    notfound: translateService.instant('SHARED.EMOJI_PICKER.NOT_FOUND'),
    clear: translateService.instant('SHARED.EMOJI_PICKER.CLEAR'),
    categories: {
        search: translateService.instant(
            'SHARED.EMOJI_PICKER.CATEGORIES.SEARCH_RESULT',
        ),
        recent: translateService.instant(
            'SHARED.EMOJI_PICKER.CATEGORIES.RECENT',
        ),
        people: translateService.instant(
            'SHARED.EMOJI_PICKER.CATEGORIES.PEOPLE',
        ),
        nature: translateService.instant(
            'SHARED.EMOJI_PICKER.CATEGORIES.NATURE',
        ),
        foods: translateService.instant('SHARED.EMOJI_PICKER.CATEGORIES.FOODS'),
        activity: translateService.instant(
            'SHARED.EMOJI_PICKER.CATEGORIES.ACTIVITY',
        ),
        places: translateService.instant(
            'SHARED.EMOJI_PICKER.CATEGORIES.PLACES',
        ),
        objects: translateService.instant(
            'SHARED.EMOJI_PICKER.CATEGORIES.OBJECTS',
        ),
        symbols: translateService.instant(
            'SHARED.EMOJI_PICKER.CATEGORIES.SYMBOLS',
        ),
        flags: translateService.instant('SHARED.EMOJI_PICKER.CATEGORIES.FLAGS'),
    },
});
