<div class="flex min-h-0 flex-col space-y-16">
    <interacta-input-search
        *ngIf="likers.totalCount > 20"
        class="mt-16 shrink-0"
        iconPosition="right"
        search
        [control]="searchUsers"
    ></interacta-input-search>

    <div class="flex min-h-40 flex-col space-y-8 overflow-y-auto">
        <div class="mt-16 grow" @users>
            <ng-container
                *ngFor="let liker of likers.list; let last = last"
                class="flex flex-col space-y-8"
            >
                <interacta-profiled-user
                    [showInfo]="true"
                    [user]="liker"
                ></interacta-profiled-user>

                <interacta-separator
                    *ngIf="!last"
                    class="my-8 block"
                    thickness="soft"
                ></interacta-separator>
            </ng-container>
        </div>

        <interacta-loader
            *ngIf="likers.isFetching"
            class="shrink-0 self-center"
        ></interacta-loader>

        <interacta-no-result
            *ngIf="likers.list.length === 0 && !likers.isFetching"
            class="shrink-0 align-middle"
            imageName="no-results"
            [subtitle]="'UI.NO_RESULT_HINT' | translate"
            [title]="'DETAIL.NO_USERS_RESULT_1' | translate"
        ></interacta-no-result>
    </div>
</div>
