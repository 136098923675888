import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'interacta-light-box-info-section[label]',
    template: `
        <div class="flex flex-col gap-y-4">
            <p class="typo-sm text-text-low-contrast">{{ label }}</p>
            <ng-content></ng-content>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightBoxInfoSectionComponent {
    @Input() label!: string;
}
