import {
    ConfigurationService,
    Timezone,
    fromDateTimeToLocalString,
    fromDateToISO8601,
    toDateFromISO8601,
    toDateTimeFromString,
} from '@interacta-shared/data-access-configuration';
import { PaginatedList, emptyPaginatedList } from '@interacta-shared/util';
import {
    IUser,
    IUserAction,
    UserDeserilize,
    UserExtendedDeserialize,
} from '@modules/core';
import {
    MemberGroup,
    MemberUser,
} from '@modules/core/models/member/member.model';
import {
    isMemberUser,
    wrapMember,
} from '@modules/core/models/member/member.utils';
import { add } from 'date-fns';
import { buildEventState } from '../utils/event-post.utils';
import {
    BasePostDeserialize,
    BasePostSerialize,
    IBasePost,
    IBasePostCreate,
    IBasePostEdit,
    IPostMetadata,
    ParticipantsResponseCount,
    PostType,
} from './base-post.model';
import { StreamingCommentsList } from './comment.model';

export enum EventParticipateType {
    YES = 1,
    MAYBE = 2,
    NO = 3,
}

export enum EventStreamingMode {
    NONE,
    INTERACTA,
    EXTERNAL,
}

export enum EventInvitationType {
    GUEST,
    COLLABORATOR,
    ORGANIZER,
}

export type EventState = 'scheduled' | 'open' | 'closed';

export interface IEventPost extends IBasePost {
    tag: PostType.EVENT;
    startAt: {
        zonedDatetime?: Date;
        localDatetime: Date;
    };
    endAt: {
        zonedDatetime?: Date;
        localDatetime: Date;
    };
    timezone?: Timezone;
    allDay: boolean;
    location: string;
    participateType: EventParticipateType | null;
    streamingVideoSrc: string;
    participantsYesList: PaginatedList<IUserAction>;
    participantsNoList: PaginatedList<IUserAction>;
    participantsMaybeList: PaginatedList<IUserAction>;
    participantsYesCount: number;
    participantsMaybeCount: number;
    participantsNoCount: number;
    externalStreamingVideoLink: string | null;
    streamKey: string | null;
    streamingStartTimestamp: Date | null;
    streamingEndTimestamp: Date | null;
    serverLive: string;
    streamingVideo: EventStreamingMode;
    participantsResponseCount: number;
    streamingComments: StreamingCommentsList;
    hasOngoingLiveStreaming: boolean;
    invitations?: Invitations;
    questionsAndAnswers?: boolean;
    videoConferenceLink?: string;
    streamingEncodedVideoEmbeed?: string;
    state: EventState;
}

export interface Invitations {
    users: InvitationUser[];
    groups: InvitationGroup[];
    invitedUsersTotalCount: number | null;
}

export interface InvitationUser extends MemberUser {
    invitationTypeId: EventInvitationType;
    participant?: {
        participationTypeId: EventParticipateType;
        notes?: string;
    };
}

export interface InvitationGroup extends MemberGroup {
    invitationTypeId: EventInvitationType;
    membersCount?: number;
    participantsCount?: number;
    participants?: Invitation[];
    participantsResponseCountByTypeMap?: ParticipantsResponseCount;
}

export interface PostInvitationsMembersEdit {
    addInvitations?: Invitation[];
    removeUserIds?: number[];
    removeGroupIds?: number[];
}

export type Invitation = InvitationUser | InvitationGroup;

export interface IEventPostCreate extends IBasePostCreate, IEventPost {}

export interface IEventPostEdit extends IBasePostEdit, IEventPostCreate {
    sendNotification: boolean;
}

export interface EventStateStyle {
    label: string;
    background: string;
}

export class EventPostDeserialize extends BasePostDeserialize {
    private static dateInfo = (
        record: any,
        allDay?: boolean,
    ): { zonedDatetime?: Date; localDatetime: Date } => ({
        zonedDatetime:
            (allDay
                ? toDateFromISO8601(record.zonedDatetime)
                : toDateTimeFromString(record.zonedDatetime)) ?? undefined,
        localDatetime: (allDay
            ? toDateFromISO8601(record.localDatetime)
            : toDateTimeFromString(record.localDatetime))!,
    });

    private static eventInvitations = (record: any): Invitations => {
        return {
            users:
                record.invitations?.users?.map((u: any) => {
                    return {
                        ...wrapMember(UserDeserilize.userDetails(u)),
                        invitationTypeId: u.typeId,
                    };
                }) || [],
            groups:
                record.invitations?.groups?.map((g: any) => {
                    return {
                        ...wrapMember(UserExtendedDeserialize.usersGroup(g)),
                        membersCount: g.membersCount,
                        invitationTypeId: g.typeId,
                    };
                }) || [],
            invitedUsersTotalCount:
                record.invitations?.invitedUsersTotalCount ?? 0,
        };
    };

    public static eventPostDetails = (
        configurationService: ConfigurationService,
        recordPost: any,
        metadata?: IPostMetadata,
    ): IEventPost => {
        const event = recordPost.event || { startAt: {}, endAt: {} };

        const participantsResponseCountByTypeMap: ParticipantsResponseCount =
            event.participantsResponseCountByTypeMap;
        const participantsYesCount = participantsResponseCountByTypeMap
            ? participantsResponseCountByTypeMap[EventParticipateType.YES]
            : 0;
        const participantsMaybeCount = participantsResponseCountByTypeMap
            ? participantsResponseCountByTypeMap[EventParticipateType.MAYBE]
            : 0;
        const participantsNoCount = participantsResponseCountByTypeMap
            ? participantsResponseCountByTypeMap[EventParticipateType.NO]
            : 0;

        const startAt = EventPostDeserialize.dateInfo(
            event.startAt,
            event.allDay,
        );
        const endAt = EventPostDeserialize.dateInfo(event.endAt, event.allDay);
        const allDay: boolean = event.allDay;

        const post = <IEventPost>{
            type: PostType.EVENT,
            tag: PostType.EVENT,
            startAt,
            endAt,
            timezone: event.timezone
                ? configurationService.getTimezone(event.timezone)
                : undefined,
            allDay,
            location: event.location,
            participateType: event.participateTypeId,
            participantsResponseCount: event.participantsResponseCount,
            participantsYesCount,
            participantsMaybeCount,
            participantsNoCount,
            participantsYesList: emptyPaginatedList(participantsYesCount),
            participantsMaybeList: emptyPaginatedList(participantsMaybeCount),
            participantsNoList: emptyPaginatedList(participantsNoCount),
            streamingVideo: event.streamingVideo
                ? EventStreamingMode.INTERACTA
                : event.externalStreamingVideoLink
                  ? EventStreamingMode.EXTERNAL
                  : EventStreamingMode.NONE,
            invitations: EventPostDeserialize.eventInvitations(event),
            streamingStartTimestamp: event.streamingStartTimestamp ?? null,
            streamingEndTimestamp: event.streamingEndTimestamp ?? null,
            externalStreamingVideoLink:
                event.externalStreamingVideoLink ?? null,
            streamKey: event.streamKey ?? null,
            serverLive: event.serverLive,
            videoConferenceLink: event.videoConferenceLink,
            streamingEncodedVideoEmbeed:
                event.streamingEncodedVideoEmbeed ?? undefined,
            // TODO: Event questionsAndAnswers
            questionsAndAnswers: false, // Mock
            streamingComments: {
                ...emptyPaginatedList(recordPost.commentsCount as number),
                nextSyncToken: null,
            },
            hasOngoingLiveStreaming:
                event.streamingStartTimestamp != null &&
                event.streamingStartTimestamp < new Date() &&
                event.streamingEndTimestamp == null,
            state: buildEventState({ startAt, endAt, allDay }),
        };
        return EventPostDeserialize.basePostDetails(
            post,
            recordPost,
            configurationService,
            metadata,
        );
    };

    public static eventPostDetailsForEdit = (
        configurationService: ConfigurationService,
        record: any,
        metadata: IPostMetadata,
    ): IEventPost => {
        const post: IEventPost = EventPostDeserialize.eventPostDetails(
            configurationService,
            record,
            metadata,
        );
        return EventPostDeserialize.basePostDetailsForEdit(post, record);
    };

    public static eventPostDetailsForCreate = (
        configurationService: ConfigurationService,
        record: any,
        metadata: IPostMetadata,
        currentUser: IUser,
    ): IEventPost => {
        const recordManipulation = {
            ...record.contentData,
            ...record,
        };
        const post = EventPostDeserialize.eventPostDetailsForEdit(
            configurationService,
            recordManipulation,
            metadata,
        );
        return EventPostDeserialize.basePostDetailsForCreate(post, currentUser);
    };
}

export class EventPostSerialize extends BasePostSerialize {
    private static eventInvitations = (post: IEventPost): any => {
        const streaming = post.streamingVideo === EventStreamingMode.INTERACTA;
        return {
            users: post.invitations?.users
                ? post.invitations?.users
                      .filter(
                          (u) =>
                              streaming ||
                              u.invitationTypeId !==
                                  EventInvitationType.COLLABORATOR,
                      )
                      .map((u) => {
                          return {
                              userId: u.innerId,
                              typeId: u.invitationTypeId,
                          };
                      })
                : [],
            groups: post.invitations?.groups
                ? post.invitations?.groups
                      .filter(
                          (g) =>
                              streaming ||
                              g.invitationTypeId !==
                                  EventInvitationType.COLLABORATOR,
                      )
                      .map((g) => {
                          return {
                              groupId: g.innerId,
                              typeId: g.invitationTypeId,
                          };
                      })
                : [],
        };
    };

    public static eventEditInvitations = (
        members: PostInvitationsMembersEdit,
    ): any => {
        const addUsers: any = [];
        const addGroups: any = [];
        members.addInvitations?.forEach((m) => {
            if (isMemberUser(m)) {
                addUsers.push({
                    userId: m.innerId,
                    typeId: m.invitationTypeId,
                });
            } else {
                addGroups.push({
                    groupId: m.innerId,
                    typeId: m.invitationTypeId,
                });
            }
        });

        return {
            addUsers: addUsers,
            addGroups: addGroups,
            removeUserIds: members.removeUserIds ?? [],
            removeGroupIds: members.removeGroupIds ?? [],
        };
    };

    public static eventPostDetails = (post: IEventPost): any => {
        const startAt = post.allDay
            ? fromDateToISO8601(post.startAt.localDatetime)
            : fromDateTimeToLocalString(post.startAt.localDatetime);
        let endAt: string | undefined;
        if (post.allDay) {
            endAt =
                post.endAt && post.endAt.localDatetime
                    ? fromDateToISO8601(post.endAt.localDatetime) ?? undefined
                    : post.startAt?.localDatetime
                      ? fromDateToISO8601(post.startAt.localDatetime) ??
                        undefined
                      : undefined;
        } else {
            if (post.startAt?.localDatetime) {
                endAt =
                    fromDateTimeToLocalString(
                        post.endAt && post.endAt.localDatetime
                            ? post.endAt.localDatetime
                            : add(post.startAt.localDatetime, { minutes: 30 }),
                    ) ?? undefined;
            } else {
                endAt =
                    fromDateTimeToLocalString(post.endAt.localDatetime) ??
                    undefined;
            }
        }
        const record: any = {
            event: {
                startAt: { localDatetime: startAt },
                endAt: { localDatetime: endAt },
                allDay: !!post.allDay,
                location: post.location,
                timezone: post.timezone ? post.timezone.zoneId : undefined,
                invitations: EventPostSerialize.eventInvitations(post),
                externalStreamingVideoLink:
                    post.streamingVideo === EventStreamingMode.EXTERNAL
                        ? post.externalStreamingVideoLink
                        : undefined,
                streamingVideo:
                    post.streamingVideo === EventStreamingMode.INTERACTA,
                // TODO: Event questionsAndAnswers, uncomment and check DTO parameter name
                // questionsAndAnswers: post.questionsAndAnswers ?? false,
            },
        };

        return BasePostSerialize.basePostDetails(record, post);
    };

    public static newEventPost = (post: IEventPostCreate): any => {
        const record: any = EventPostSerialize.eventPostDetails(post);
        return BasePostSerialize.newBasePost(record, post);
    };

    public static editEventPost = (post: IEventPostEdit): any => {
        const record = EventPostSerialize.eventPostDetails(post);
        record.event.invitations = {
            ...record.event.invitations,
            sendEditPostNotifications: post.sendNotification || false,
        };
        return EventPostSerialize.editBasePost(record, post);
    };

    public static copyEventPost = (post: IEventPostEdit): any => {
        const record = EventPostSerialize.eventPostDetails(post);
        return EventPostSerialize.copyBasePost(record, post);
    };
}

export function isEventPost(post: IBasePost): post is IEventPost {
    return post.type === PostType.EVENT;
}
