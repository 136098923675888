<div
    class="flex max-w-max shrink-0 items-center gap-2 rounded-2xl"
    [ngClass]="{
        'text-text-primary': color === 'primary',
        'text-text-error': color === 'error',
        'bg-surface-B': bgColor === 'surface-B',
        'bg-surface-A': bgColor === 'surface-A',
        'bg-surface-primary-low-contrast': bgColor === 'primary-300',
        'h-32': size === 'large',
        'h-24': size === 'regular',
        'h-20': size === 'small',
        'px-8': size === 'large' || size === 'regular',
        'px-4': size === 'small',
        'border-border-700 border': appearance === 'border'
    }"
>
    <div class="flex grow items-center gap-4">
        <interacta-icon *ngIf="iconLeft" class="size-16" [icon]="iconLeft" />

        <span
            class="typo-xs line-clamp-1 h-16 break-all"
            [innerHTML]="label"
        ></span>

        <span *ngIf="countState" class="typo-xs-bold h-16"
            >({{ countState.count }}/{{ countState.totalCount }})</span
        >
    </div>

    <div *ngIf="iconButtonRightArray.length" class="flex shrink-0">
        <button
            *ngFor="let iconDescriptor of iconButtonRightArray"
            interacta-icon-button
            type="button"
            [class]="iconDescriptor.iconClass"
            [disabled]="isReadonly"
            [icon]="iconDescriptor.icon"
            [matTooltip]="iconDescriptor.tooltip ?? ''"
            [matTooltipDisabled]="!iconDescriptor.tooltip"
            [size]="'extra-small'"
            (click)="action.emit(iconDescriptor.icon)"
        ></button>
    </div>
</div>
