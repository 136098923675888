import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Appearance, Shape, Size } from '@interacta-shared/ui';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-drive-file-select',
    templateUrl: './drive-file-select.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriveFileSelectComponent {
    @Input() label?: string;
    @Input() tooltip?: string;
    @Input() shape: Shape = 'rectangular';
    @Input() size: Extract<Size, 'large' | 'regular' | 'small'> = 'small';
    @Input() appearance: Appearance = 'fill';
    @Input() acceptedMimeType?: string[];

    @Output() addDriveAttachments = new EventEmitter<IAttachment[]>();
}
