import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'encodeURI',
})
export class EncodeURIPipe implements PipeTransform {
    transform(uri: string | number | boolean): string {
        return encodeURIComponent(uri);
    }
}
