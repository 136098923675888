import { InteractaIcon } from '@interacta-shared/ui-icons';
import { ContentMimeType } from '@interacta-shared/util-common';

export enum CustomButtonType {
    POST_ACTION = 'post-action',
    ATTACHMENT_ACTION = 'attachments-action',
}
export interface ICustomButton {
    id: number;
    label: string;
    url: string;
    icon: InteractaIcon;
    detailMode: boolean;
    listMode: boolean;
    maxItems: number;
    minItems: number;
    type: CustomButtonType;
    mimeTypes?: ContentMimeType[];
}

export const CUSTOM_BUTTON_MAX_ITEMS = 50;

export const toCustomButton = (
    record: any,
    type: CustomButtonType,
): ICustomButton => {
    return {
        id: record.id,
        label: record.label,
        url: record.url,
        detailMode: record.detailMode,
        listMode: record.listMode,
        icon: record.icon ?? 'external-link',
        maxItems: record.maxItems ?? CUSTOM_BUTTON_MAX_ITEMS,
        minItems: record.minItems ?? 0,
        mimeTypes: record.mimeTypes,
        type,
    };
};

export const toCustomButtonArray = (
    record: any,
    type: CustomButtonType,
): ICustomButton[] => {
    return (
        record
            .filter((action: any) => !action.deleted)
            .map((customAction: any) => toCustomButton(customAction, type)) ??
        []
    );
};
