import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Language } from '@interacta-shared/data-access-configuration';
import { CloudTranslationService } from '@modules/communities/services/cloud-translation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageSelectorDialogComponent } from '../language-selector-dialog/language-selector-dialog.component';

@Component({
    selector: 'interacta-translate-button[active]',
    templateUrl: './translate-button.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslateComponent implements OnInit, OnDestroy {
    @Input() active!: boolean;
    @Input() mode: 'button' | 'button-menu' = 'button';
    @Input() origin?: 'post' | 'comment';
    @Input() isLoading = false;

    @Output() translate = new EventEmitter<Language | null>();

    @ViewChild(LanguageSelectorDialogComponent)
    languageSelectorDialogComponent?: LanguageSelectorDialogComponent;

    isTranslateMenuOpen = false;
    lastLanguage: Language | null = null;
    destroy$ = new Subject<void>();

    constructor(private cloudTranslationService: CloudTranslationService) {}

    ngOnInit(): void {
        this.cloudTranslationService.lastLanguage$
            .pipe(takeUntil(this.destroy$))
            .subscribe((lastLanguage: Language | null) => {
                this.lastLanguage = lastLanguage;
            });
    }

    translateClick(): void {
        if (this.active) {
            this.select(null);
        } else {
            if (this.lastLanguage) {
                this.openTranslateMenu();
            } else {
                this.openLanguageSelector();
            }
        }
    }

    openTranslateMenu(): void {
        this.isTranslateMenuOpen = true;
    }

    closeTranslateMenu(): void {
        this.isTranslateMenuOpen = false;
    }

    openLanguageSelector(event?: MouseEvent): void {
        event?.stopPropagation();
        this.closeTranslateMenu();
        this.languageSelectorDialogComponent
            ?.open(this.lastLanguage, this.origin || 'generic')
            .pipe(takeUntil(this.destroy$))
            .subscribe((language: Language | null) => {
                this.select(language);
            });
    }

    select(language: Language | null): void {
        this.translate.emit(language);
        this.closeTranslateMenu();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
