import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Timezone } from '../../model/timezone.model';
import { DateTimeComponent } from '../date-time/date-time.component';
import { DateTimeType } from '../input-date-time/input-date-time.component';
import { StepMode } from '../input-time-v2/input-time-v2.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
    standalone: true,
    selector: 'interacta-menu-date-time',
    templateUrl: './menu-date-time.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MenuComponent, DateTimeComponent],
})
export class MenuDateTimeComponent {
    @Input({ required: true }) open!: boolean;
    @Input({ required: true }) origin!: CdkOverlayOrigin;
    @Input({ required: true }) control!: FormControl<Date | null>;
    @Input({ required: true }) timezones!: Timezone[];
    @Input({ required: true }) userTimezone!: Timezone | null;
    @Input() timezoneControl = new FormControl<Timezone | null>(null);
    @Input() type: DateTimeType = 'date';
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() showFastSelect = true;
    @Input() stepMode: StepMode = 'minute';
    @Input() editTimezone = false;

    @Output() closing = new EventEmitter<void>();

    calendarDateClicked(): void {
        if (this.type === 'date') {
            this.closeMenu();
        }
    }

    closeMenu(): void {
        this.closing.emit();
    }
}
