import { Injectable } from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
} from '@angular/forms';
import { IValidationError } from '@interacta-shared/data-access-error';
import { TipService } from '@interacta-shared/feature-tip';
import { isDefined } from '@interacta-shared/util';
import { MetadataActivationStatusMap } from '@modules/communities/models/custom-metadata-activation/custom-metadata-activation.model';
import { getActiveValidators } from '@modules/communities/models/custom-metadata-activation/custom-metadata-activation.utils';
import { ICustomField } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import {
    patchFormGroup,
    updateInputValueFromFormGroup,
} from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import { CustomMetadataActivationService } from '@modules/communities/services/custom-metadata-activation.service';
import { CustomValidators } from '@modules/core';
import {
    DataFormControlsValidators,
    FormServiceAbstract,
} from '@modules/core/helpers/form-service.abstract';
import { CommonCollectionsHolderServiceAbstract } from '@modules/post/helpers/common-collections-holder-service.abstract';
import { QuillTable } from '@modules/shared-v2/models/quill-table/quill-table.model';
import {
    areQuillTablesEqual,
    replaceQuillTableTitle,
} from '@modules/shared-v2/models/quill-table/quill-table.utils';
import { BehaviorSubject } from 'rxjs';
import { ConfigurationFieldType } from '../models/base-post.model';
import {
    ICustomPost,
    ICustomPostCreate,
    ICustomPostEdit,
    ICustomPostForContext,
} from '../models/custom-post.model';
import { ILinkPreview } from '../models/link-preview.model';

// @ts-ignore
import { maxLengthDelta } from '@interacta-shared/util-common';
import Delta from 'quill-delta/lib/delta';

@Injectable()
export class CustomPostDataValidators extends DataFormControlsValidators {
    readonly title: UntypedFormControl = new UntypedFormControl();
    readonly descriptionDelta: UntypedFormControl = new UntypedFormControl();
    readonly watchers: UntypedFormControl = new UntypedFormControl();
    readonly workflowInitStateId: UntypedFormControl = new UntypedFormControl();
    fieldsMap = new UntypedFormGroup({});

    public readonly DESCRIPTION_MAX_LENGTH = 20000;

    public patch(customFieldsMap: ICustomField[]): void {
        patchFormGroup(this.fieldsMap, customFieldsMap);
    }

    public setValidators(
        customFields: ICustomField[],
        titleEnabled: ConfigurationFieldType,
        descriptionEnabled: ConfigurationFieldType,
        watchersEnabled: ConfigurationFieldType,
        fieldsActivationStatusMap: Record<
            number,
            BehaviorSubject<MetadataActivationStatusMap>
        >,
    ): void {
        this.updateValidator(
            this.title,
            titleEnabled === ConfigurationFieldType.REQUIRED
                ? [CustomValidators.required]
                : null,
        );
        this.updateValidator(this.descriptionDelta, [
            descriptionEnabled === ConfigurationFieldType.REQUIRED
                ? CustomValidators.required
                : CustomValidators.nullValidator,
            maxLengthDelta(this.DESCRIPTION_MAX_LENGTH),
        ]);
        this.updateValidator(
            this.watchers,
            watchersEnabled === ConfigurationFieldType.REQUIRED
                ? [CustomValidators.required]
                : null,
        );

        customFields.forEach((field) => {
            this.updateValidator(
                this.fieldsMap.controls[field.configuration.id],
                getActiveValidators(fieldsActivationStatusMap, field),
            );
        });
    }

    protected setValidatorCustom(
        validationError: IValidationError,
        refError: ValidationErrors,
    ): void {
        if (validationError.customFieldId) {
            const control: AbstractControl =
                this.fieldsMap.controls[validationError.customFieldId];
            if (control) {
                control.setErrors(refError);
            } else {
                console.error(validationError);
            }
        } else if (validationError.field === 'description') {
            this.descriptionDelta.setErrors(refError);
        }
    }
}

@Injectable()
export class CustomPostFormService extends FormServiceAbstract<
    ICustomPost,
    CustomPostDataValidators
> {
    linkPreviewObject: ILinkPreview | null = null;

    get customFieldsMapVisible(): ICustomField[] {
        return this._customFieldsMapVisible;
    }

    private _customFieldsMapVisible: ICustomField[] = [];

    mapDataFromForm(): ICustomPost {
        const data = super.mapDataFromForm();
        data.linkPreview = this.linkPreviewObject ?? undefined;
        return data;
    }

    patch(customFieldsMapVisible: ICustomField[]): void {
        this._customFieldsMapVisible = customFieldsMapVisible;
        this.dataFormControls.patch(customFieldsMapVisible);
        this.form.setControl('fieldsMap', this.dataFormControls.fieldsMap);
    }

    clearWorkflowInitStateControl(): void {
        this.dataFormControls.workflowInitStateId.reset();
    }

    applyEditQuillTable(
        oldTable: QuillTable | undefined,
        newTable: QuillTable,
    ): void {
        let descriptionDelta: Delta | undefined =
            this.dataFormControls.descriptionDelta.value;

        if (descriptionDelta) {
            descriptionDelta = replaceQuillTableTitle(
                descriptionDelta,
                newTable,
            );
        }

        this.dataFormControls.descriptionDelta.setValue(descriptionDelta);
        if (!areQuillTablesEqual(oldTable, newTable)) {
            this.dataFormControls.descriptionDelta.markAsDirty();
        }
    }
}

@Injectable()
export class CustomPostFormCollectionsHolderService extends CommonCollectionsHolderServiceAbstract<ICustomPost> {
    private data!: ICustomPost;

    constructor(
        protected tipService: TipService,
        protected formService: CustomPostFormService,
        private customMetadataActivationService: CustomMetadataActivationService,
    ) {
        super(tipService, formService);
    }

    // @Override
    protected prepareData<
        K extends ICustomPostCreate | ICustomPostEdit | ICustomPostForContext,
    >(data: K, enableValidation = true): K | null {
        if (enableValidation) {
            this.formService.markFormGroupTouched(this.formService.form);

            data.metadata &&
                this.formService.dataFormControls.setValidators(
                    this.formService.customFieldsMapVisible,
                    data.metadata.titleEnabled,
                    data.metadata.descriptionEnabled,
                    data.metadata.watchersEnabled,
                    this.customMetadataActivationService
                        .fieldsActivationStatusMap,
                );
        }

        const _data = super.prepareData(data, enableValidation);

        if (!_data) {
            return null;
        }

        return {
            ...data,
            ..._data,
            ...this.formService.mapDataFromForm(),
            attachmentsList: data.attachmentsList,
            customFields: {
                ...data.customFields,
                data: updateInputValueFromFormGroup(
                    this.formService.dataFormControls.fieldsMap,
                    data.customFields.data,
                    this.formService.customFieldsMapVisible,
                ),
            },
        };
    }

    public getContextData(
        listenChangesFieldId?: number,
    ): ICustomPostForContext | undefined {
        const _data = this.prepareData(<ICustomPostForContext>this.data, false);

        if (
            !_data ||
            (isDefined(listenChangesFieldId) &&
                this.formService.dataFormControls.fieldsMap.controls[
                    listenChangesFieldId
                ].valid == false)
        ) {
            return undefined;
        }

        _data.addAttachments = undefined;
        return _data;
    }

    public setData(data: ICustomPost): void {
        this.data = data;
    }
}
