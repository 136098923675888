import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { AttachmentSection } from '../../models/attachment-section/attachment-section.model';

@Component({
    selector: 'interacta-light-box-section[section]',
    templateUrl: './light-box-section.component.html',
    styleUrls: ['./light-box-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class LightBoxSectionComponent {
    @Input() section!: AttachmentSection;

    secondsToHHMMSS(seconds: number): string {
        seconds = Math.floor(seconds);

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const mm = minutes.toString().padStart(2, '0');
        const ss = remainingSeconds.toString().padStart(2, '0');

        if (hours > 0) {
            const hh = hours.toString().padStart(2, '0');
            return `${hh}:${mm}:${ss}`;
        } else {
            return `${mm}:${ss}`;
        }
    }
}
