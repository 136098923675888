import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { formatDistance, secondsToMilliseconds } from 'date-fns';
import { first } from 'rxjs/operators';

/**
 *
 * E' una pipe wrapper per utilizzare la funzione
 * formatDistance di date-fns
 *
 * {@link seconds} numero di secondi da trasformare
 *
 */
@Pipe({
    name: 'formatDistance',
})
export class FormatDistancePipe implements PipeTransform {
    private locale?: Locale;

    constructor(configService: ConfigurationService) {
        configService
            .getLocaleDateFns()
            .pipe(first())
            .subscribe((locale: Locale) => (this.locale = locale));
    }

    transform(seconds: number): string {
        const zero = new Date(0);
        const now = new Date(secondsToMilliseconds(seconds));

        return formatDistance(now, zero, {
            locale: this.locale,
        });
    }
}
