import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import {
    EMPTY_SEARCH_VALUE,
    emptyPaginatedList,
    isDefined,
    mapById,
    PaginatedList,
    paginatedListFromArray,
} from '@interacta-shared/util';
import { TranslateModule } from '@ngx-translate/core';
import { concat, filter, of, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil,
} from 'rxjs/operators';
import {
    ArrowKeysItemDirective,
    ArrowKeysListDirective,
    ScrollTrackerDirective,
} from '../../directives';
import { InputSelectItem, Size } from '../../model';
import { ApplyPipe, FormControlValueV2Pipe, InputStatePipe } from '../../pipes';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { ImageComponent } from '../image/image.component';
import { InputTextV2Component } from '../input-text-V2/input-text-V2.component';
import { LoadMoreComponent } from '../load-more/load-more.component';
import { MenuDecoratorComponent } from '../menu-decorator/menu-decorator.component';
import { MenuComponent } from '../menu/menu.component';
import { RadioButtonComponent } from '../radio-button/radio-button.component';
import { RadioGroupComponent } from '../radio-group/radio-group.component';
import { SeparatorComponent } from '../separator/separator.component';

@Component({
    selector: 'interacta-input-single-select',
    templateUrl: './input-single-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        RadioGroupComponent,
        ReactiveFormsModule,
        NgFor,
        NgClass,
        RadioButtonComponent,
        NgTemplateOutlet,
        IconButtonComponent,
        SeparatorComponent,
        InputTextV2Component,
        CdkOverlayOrigin,
        MenuComponent,
        MenuDecoratorComponent,
        ScrollTrackerDirective,
        ArrowKeysListDirective,
        ArrowKeysItemDirective,
        VirtualScrollerModule,
        LoadMoreComponent,
        AsyncPipe,
        TranslateModule,
        InputStatePipe,
        ApplyPipe,
        FormControlValueV2Pipe,
        ImageComponent,
        ImagePreviewComponent,
    ],
})
export class InputSingleSelectComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input({ required: true }) control!: FormControl<
        number | InputSelectItem | null
    >;
    @Input({ required: true }) items: InputSelectItem[] = [];
    @Input() label?: string;
    @Input() isReadonly = false;
    @Input() forceListView = false;
    @Input() customLabelTemplate?: TemplateRef<unknown>;
    @Input() maxItemsForRadioView = 5;
    @Input() canClearSelection = true;
    @Input() quickItems?: InputSelectItem[];
    @Input() filterClearSelection = true;
    @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
    @Input() imagesSize?: Extract<Size, 'regular' | 'small'>;

    @Output() openFullScreen = new EventEmitter<string>();

    _items: PaginatedList<InputSelectItem> = emptyPaginatedList();
    textSearchControl = new FormControl('', { nonNullable: true });
    isOpen = false;
    EMPTY_SEARCH_VALUE = EMPTY_SEARCH_VALUE;
    public readonly VIRTUAL_SCROLL_THRESHOLD = 50;
    displayedItems: InputSelectItem[] = [];
    currentIndex = 0;
    mode: 'list' | 'radio' | 'visual' = 'radio';

    private destroy$ = new Subject<void>();
    private changeControl$ = new Subject<void>();

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.textSearchControl.setValidators(this.control.validator);
        this.textSearchControl.valueChanges
            .pipe(
                filter(() => this.mode === 'list'),
                debounceTime(300),
                distinctUntilChanged(),
                map((text) => (isDefined(text) ? text : '')),
                takeUntil(this.destroy$),
            )
            .subscribe((text: string) => {
                this.filterItems(text);
                this.findAndSetCurrentIndex(!!text);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['quickItems'] || changes['items']) {
            const _quickItems = this.quickItems || [];
            this._items = this.toPaginatedList([
                ..._quickItems,
                ...this.items.filter(
                    (i) => _quickItems.findIndex((s) => s.id === i.id) < 0,
                ),
            ]);

            if (this.filterClearSelection) {
                this.detectItemsChanges(changes['items'].previousValue);
            }
        }

        if (changes['control']) {
            this.changeControl$.next();
            concat(of(this.control.value), this.control.valueChanges)
                .pipe(takeUntil(this.changeControl$), takeUntil(this.destroy$))
                .subscribe((controlValue) => {
                    if (controlValue === null) {
                        this.clearSelection();
                    } else {
                        const item = this.items.find(
                            (i) => i.id === mapById(controlValue),
                        );
                        /*
                         * Se nessun elemento selezionabile (this.items) corrisponde a controlValue,
                         * evito di settare a null il control per preservare il corretto funzionamento degli input gerarchici
                         */
                        item && this.itemSelected(item, false);
                    }
                });
        }

        if (
            changes['items'] ||
            changes['forceListView'] ||
            changes['maxItemsForRadioView']
        ) {
            this.mode = this.determineMode();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    isSelected($: {
        item: InputSelectItem;
        controlValue: number | InputSelectItem | null;
    }): boolean {
        if ($.controlValue != null) {
            if (typeof $.controlValue === 'number') {
                return $.item.id === $.controlValue;
            } else {
                return $.item.id === $.controlValue.id;
            }
        }
        return false;
    }

    openMenu(): void {
        if (this.isOpen) return;

        this.clearTextSearch();
        this.isOpen = true;
    }

    closeMenu(): void {
        if (this.isOpen) {
            this.isOpen = false;
            this.findAndSetCurrentIndex(this.control.value == null);
            const item = this.items.find((i) => i.id === this.control.value);
            this.textSearchControl.setValue(item?.label ?? '', {
                emitEvent: false,
            });
        }
    }

    trackItem(_idx: number, item: InputSelectItem): number {
        return item.id;
    }

    clearSelection(): void {
        if (this.control.value != null) {
            this.control.setValue(null);
        }
        this.clearTextSearch();
        this.currentIndex = 0;
    }

    clearTextSearch(): void {
        this.textSearchControl.markAsDirty();
        this.textSearchControl.setValue('');
    }

    iconClicked(): void {
        if (this.control.value != null && this.canClearSelection) {
            this.clearSelection();
            this.control.markAsDirty();
        } else {
            this.openMenu();
        }
    }

    itemSelected(
        item: InputSelectItem | null | undefined,
        markAsDirty: boolean,
    ): void {
        if (item == null) {
            this.clearSelection();
        } else {
            if (this.control.value != item.id) {
                this.control.setValue(item.id);
                markAsDirty && this.control.markAsDirty();
            }
            this.textSearchControl.setValue(item.label, { emitEvent: false });
        }
        this.closeMenu();
    }

    openFullscreen(item: InputSelectItem | null | undefined): void {
        if (item?.temporaryContentViewLink) {
            this.openFullScreen.emit(item.temporaryContentViewLink);
        }
    }

    private filterItems(filter: string): void {
        if (Array.isArray(this.items)) {
            this._items = {
                ...paginatedListFromArray(
                    this.items.filter(
                        (item) =>
                            !filter ||
                            item.label
                                .toLowerCase()
                                .includes(filter.toLowerCase()),
                    ),
                ),
                totalCount: this.items.length,
            };
            this.cdr.markForCheck();
        }
    }

    private detectItemsChanges(
        previousItems: PaginatedList<InputSelectItem> | InputSelectItem[],
    ): void {
        const prevTotal: number =
            (previousItems &&
                this.toPaginatedList(previousItems)?.totalCount) ||
            0;
        if (
            (this._items.totalCount === 0 && prevTotal > 0) ||
            (this._items.totalCount > 0 &&
                !this._items.list.some(
                    (i) => i.id === mapById(this.control.value),
                ))
        ) {
            this.clearSelection();
        } else if (this._items.totalCount > 0) {
            /**
             * Seleziono l'item corrispondente all'eventuale this.control.value
             * per ottenere il comportamento corretto del campi input gerarchici
             */
            const itemTobeSelected = this._items.list.find(
                (i) => i.id === mapById(this.control.value),
            );
            itemTobeSelected && this.itemSelected(itemTobeSelected, false);
        }
    }

    private toPaginatedList(
        items: PaginatedList<InputSelectItem> | InputSelectItem[],
    ): PaginatedList<InputSelectItem> {
        return Array.isArray(items) ? paginatedListFromArray(items) : items;
    }

    private findAndSetCurrentIndex(reset: boolean): void {
        if (reset) {
            this.currentIndex = 0;
        } else {
            const index = this.items.findIndex(
                (i) => i.id === this.control.value,
            );
            this.currentIndex = index === -1 ? 0 : index;
        }
    }

    private determineMode(): 'list' | 'radio' | 'visual' {
        if (
            this._items.list.some((i) => !!i.temporaryContentViewLink) &&
            this.imagesSize
        )
            return 'visual';
        else if (
            this._items.totalCount > this.maxItemsForRadioView ||
            this.forceListView
        )
            return 'list';
        else return 'radio';
    }
}
