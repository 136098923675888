import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    ButtonMenuComponent,
    IconButtonComponent,
    MenuComponent,
    MenuDecoratorComponent,
} from '@interacta-shared/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'interacta-editable-cell-menu-button',
    standalone: true,
    imports: [
        CommonModule,
        IconButtonComponent,
        OverlayModule,
        MenuComponent,
        MenuDecoratorComponent,
        ButtonMenuComponent,
        TranslateModule,
    ],
    templateUrl: './editable-cell-menu-button.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableCellMenuButtonComponent {
    isCellMenuOpen = false;
    @Input() canDeleteRow = true;
    @Input() canDeleteColumn = true;

    @Output() addRowAbove = new EventEmitter<void>();
    @Output() addRowBelow = new EventEmitter<void>();
    @Output() addColumnRight = new EventEmitter<void>();
    @Output() addColumnLeft = new EventEmitter<void>();
    @Output() deleteRow = new EventEmitter<void>();
    @Output() deleteColumn = new EventEmitter<void>();
}
