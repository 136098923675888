import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'interacta-info-card',
    templateUrl: './info-card.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoCardComponent {
    @Input() inError = false;
}
