<button
    #openMenu="cdkOverlayOrigin"
    cdkOverlayOrigin
    class="flex items-center gap-8"
    type="button"
    [class.hidden]="isMenuOpen && layoutMode === 'expand'"
    [disabled]="isReadonly"
    (click)="isMenuOpen = true"
>
    <interacta-icon
        *ngIf="display !== 'row'"
        class="h-24 w-24"
        icon="globe"
    ></interacta-icon>
    <div
        class="flex text-left"
        [ngClass]="{
            'flex-col': display === 'column',
            'flex-row gap-4': display === 'row'
        }"
    >
        <span class="typo-sm-bold">{{
            control.value?.utcOffset || '---'
        }}</span>
        <span class="typo-sm">{{ control.value?.formattedZone || '---' }}</span>
    </div>
</button>

<interacta-menu
    *ngIf="layoutMode === 'menu' && isMenuOpen"
    positionX="after"
    positionY="above"
    [open]="isMenuOpen"
    [origin]="openMenu"
    (closing)="closeMenu()"
>
    <div class="w-320 h-308 bg-surface-A rounded-lg p-16 shadow-sm">
        <interacta-menu-timezone
            [defaultTimezones]="defaultTimezones"
            [focusSearch]="true"
            [timezones]="timezones"
            (selectTimezone)="setTimezone($event)"
        ></interacta-menu-timezone>
    </div>
</interacta-menu>

<interacta-menu-timezone
    *ngIf="layoutMode === 'expand' && isMenuOpen"
    [defaultTimezones]="defaultTimezones"
    [focusSearch]="true"
    [timezones]="timezones"
    (selectTimezone)="setTimezone($event)"
></interacta-menu-timezone>
