export enum ReportAbuseType {
    Nudes = 'nudes',
    Violence = 'violence',
    Spam = 'spam',
    ReservedInfo = 'reserved-info',
}

export enum ReportAbuseEntityType {
    Post = 'post',
    Comment = 'comment',
    Attachment = 'attachment',
}

export interface ReportAbuseEntity {
    type: ReportAbuseEntityType;
    id: number;
}

export interface ReportAbuse {
    type: ReportAbuseType;
    entity: ReportAbuseEntity;
    notes: string;
}
