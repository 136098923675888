const colorRegistry = [
    'bg-avatar-A',
    'bg-avatar-B',
    'bg-avatar-C',
    'bg-avatar-D',
    'bg-avatar-E',
    'bg-avatar-F',
    'bg-avatar-G',
    'bg-avatar-H',
    'bg-avatar-I',
];

const colorRegistryVariables = [
    'var(--in-theme-avatar-A)',
    'var(--in-theme-avatar-B)',
    'var(--in-theme-avatar-C)',
    'var(--in-theme-avatar-D)',
    'var(--in-theme-avatar-E)',
    'var(--in-theme-avatar-F)',
    'var(--in-theme-avatar-G)',
    'var(--in-theme-avatar-H)',
    'var(--in-theme-avatar-I)',
];

const colorRegistryVariablesHashtagOrder = [
    '--in-theme-avatar-A',
    '--in-theme-avatar-B',
    '--in-theme-avatar-C',
    '--in-theme-avatar-D',
    '--in-theme-avatar-E',
    '--in-theme-avatar-F',
    '--in-theme-avatar-G',
    '--in-theme-avatar-H',
    '--in-theme-avatar-I',
];

const colorRegistrySecondaryVariables = [
    'var(--in-theme-palette-secondary)',
    'var(--in-theme-palette-tertiary)',
    'var(--in-theme-palette-quaternary)',
    'var(--in-theme-palette-quinary)',
];

const colorRegistrySecondaryClasses = [
    'bg-palette-secondary',
    'bg-palette-tertiary',
    'bg-palette-quaternary',
    'bg-palette-quinary',
];

export function getRandomSecondaryColorVariables(id: number): string {
    return colorRegistrySecondaryVariables[
        id % colorRegistrySecondaryVariables.length
    ];
}

export function getRandomSecondaryColor(id: number): string {
    return colorRegistrySecondaryClasses[
        Math.abs(id) % colorRegistrySecondaryClasses.length
    ];
}

export function getRandomColor(id: number): string {
    return colorRegistry[id % colorRegistry.length];
}

export function getRandomColorVariables(id: number): string {
    return colorRegistryVariables[id % colorRegistryVariables.length];
}

export function getRandomColorVariablesHashtagOrder(id: number): string {
    return colorRegistryVariablesHashtagOrder[
        id % colorRegistryVariablesHashtagOrder.length
    ];
}
