<div
    class="relative block w-max rounded-tr-2xl"
    [ngClass]="{ 'mt-12': buttonType !== 'select' }"
>
    <div
        #openMenu="cdkOverlayOrigin"
        cdkOverlayOrigin
        interactaResize
        resizeAxis="width"
        [ngSwitch]="buttonType"
        (changeSize)="setMenuWidth($event.width)"
        (click)="showMembersMenu()"
    >
        <button
            *ngSwitchCase="'button'"
            icon="plus"
            interacta-button
            type="button"
            [active]="active"
            [appearance]="appearance"
            [label]="label"
            [size]="size"
        ></button>
        <interacta-filters-pill
            *ngSwitchCase="'select'"
            [appearance]="appearance"
            [bgColor]="bgColor"
            [icon]="active ? 'close' : 'chevron-small-down'"
            [isActive]="active"
            [label]="label"
            (iconClicked)="active ? selectMember.emit() : showMembersMenu()"
        >
        </interacta-filters-pill>
        <button
            *ngSwitchDefault
            interacta-chip-button-v2
            type="button"
            [appearance]="appearance"
            [bgColor]="bgColor"
            [icon]="'plus'"
            [label]="label"
            [size]="size"
        ></button>
    </div>
    <interacta-menu
        [open]="showMembersList"
        [origin]="openMenu"
        [overridePositions]="
            menuPositions ?? [
                {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'top'
                },
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'bottom'
                }
            ]
        "
        (closing)="closeList()"
    >
        <interacta-menu-decorator class="block" [style.width.px]="menuWidth">
            <input
                #searchInput
                class="typo-sm px-12 pb-4 pt-12"
                type="text"
                [formControl]="searchControl"
            />
            <div
                *ngIf="members$ | async as members"
                class="max-h-272 flex flex-col overflow-y-auto overflow-x-hidden"
                interactaScrollTracker
                (scrolled)="scroll($event, members.nextPageToken)"
            >
                <div
                    *ngIf="showSearchPlaceholders$ | async; else dataTemplate"
                    class="flex flex-col"
                >
                    <interacta-member-list-placeholder
                        class="px-12"
                        [size]="size"
                    ></interacta-member-list-placeholder>
                </div>

                <ng-template #dataTemplate>
                    <interacta-chip-member
                        *ngFor="let member of members.list"
                        appearance="fill"
                        class="w-full"
                        [bgOnHover]="true"
                        [member]="member"
                        [memberClickBehavior]="'none'"
                        (selectMember)="closeList(); selectMember.emit($event)"
                    ></interacta-chip-member>
                    <interacta-load-more
                        *ngIf="members.nextPageToken.tag !== 'lastLoading'"
                        class="h-30 mt-8 w-max shrink-0 self-center"
                        [isLoading]="isFetching"
                        [label]="'SHOW_MORE' | translate"
                        (loadMore)="nextPagination(members.nextPageToken)"
                    ></interacta-load-more>
                    <div
                        *ngIf="!isFetching && !members.list.length"
                        class="typo-sm p-12"
                    >
                        {{ 'UI.NO_RESULT' | translate }}
                    </div>
                </ng-template>
            </div>
        </interacta-menu-decorator>
    </interacta-menu>
</div>
