import {
    isDefined,
    isValidNumber,
    mapArrayById,
    mapById,
} from '@interacta-shared/util';
import { cleanEmptyI18nStringV2 } from '@modules/admin-v2-shared/utils/admin-v2-i18n';
import { fromFieldMetadataV2 } from '@modules/communities/models/custom-metadata/custom-metadata.serialize';
import {
    cleanFromMetadataBase,
    extractCustomFieldTypeFromAdminInputForm,
} from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import {
    cleanEmptyi18nString,
    mPartition,
} from '@modules/core/helpers/generic.utils';
import { ItemOrderByCriteria } from '@modules/shared-v2/models/orderby';
import {
    CreateEditEnumFieldValueDTO,
    CustomFieldsConfigurationBase,
    CustomFieldsConfigurationEnumValues,
} from './custom-fields-configuration.model';

export const fromCustomFieldsConfigurationEnumValues = (
    enumValue: CustomFieldsConfigurationEnumValues,
    allOtherEnumValues: CustomFieldsConfigurationEnumValues[],
    i18nLegacyMode = true,
): CreateEditEnumFieldValueDTO => {
    //clientRef must be present if there is no BE id and there is at least 1 enum value that uses clientRef as parentClientRef
    const clientRef =
        !enumValue.id &&
        allOtherEnumValues.find(
            (e) =>
                enumValue.clientRef &&
                e.parentIds?.find((s) => s.clientRef == enumValue.clientRef),
        )
            ? enumValue.clientRef
            : null;
    const [parentIds, parentClientRefs] = enumValue.parentIds
        ? mPartition(enumValue.parentIds, (p) => isValidNumber(mapById(p)))
        : [[], []];
    return {
        id: isValidNumber(enumValue.id) ? enumValue.id : undefined,
        clientRef,
        deleted: enumValue.deleted,
        label:
            !i18nLegacyMode && enumValue.labelV2
                ? cleanEmptyI18nStringV2(enumValue.labelV2)
                : cleanEmptyi18nString(enumValue.label),
        parentIds: mapArrayById(parentIds || []) ?? undefined,
        parentClientRefs: mapArrayById(parentClientRefs || []) ?? undefined,
        externalId: enumValue.externalId,
        contentRef: enumValue.image?.contentRef,
        temporaryContentViewLink: enumValue.image?.temporaryContentViewLink,
        visualizationOrder: enumValue.visualizationOrder,
    };
};

export const fromCustomFieldConfigurationBase = (
    field: CustomFieldsConfigurationBase,
): any => {
    const enumValues = (field.enumValues || []).map((i) =>
        fromCustomFieldsConfigurationEnumValues(i, [], false),
    );

    const record = {
        id: field.id ?? null,
        type: extractCustomFieldTypeFromAdminInputForm(field),
        metadata: {
            ...cleanFromMetadataBase(field.originalMetadata),
            ...fromFieldMetadataV2(field.metadata),
        },
        enumValues,
        enumValuesOrderByCriteria:
            enumValues.length && isDefined(field.enumValuesOrderByCriteria)
                ? fromItemOrderByCriteriaToEnum(field.enumValuesOrderByCriteria)
                : undefined, //todo testare screen e survey per capire se va bene
        parentId: field.parentId ?? null,
        readonly: field.readonly ?? false,
        deleted: field.deleted ?? false,
    };
    return record;
};

/**
 * DTO BE knows these values -> 0 = ASC, 1 = DESC,  2 = CUSTOM,
 */
export const fromItemOrderByCriteriaToEnum = (
    enumValuesOrderByCriteria: ItemOrderByCriteria,
): 0 | 1 | 2 => {
    switch (enumValuesOrderByCriteria) {
        case ItemOrderByCriteria.ASC:
            return 0;
        case ItemOrderByCriteria.DESC:
            return 1;
        case ItemOrderByCriteria.CUSTOM:
            return 2;
    }
};
