<div
    *ngIf="control | inputState: isReadonly | async as state"
    class="flex flex-col space-y-12"
>
    <interacta-input-search
        *ngIf="_items.totalCount >= 20"
        [control]="textSearchControl"
        [iconPosition]="'right'"
        [isReadonly]="state.disabled"
        [label]="'SHARED.BUTTON.SEARCH' | translate"
        [ngClass]="{
            'text-text-disabled pointer-events-none': state.disabled
        }"
    />
    @if (mode === 'check') {
        <div
            #scroll
            class="overflow-y-auto"
            interactaScrollTracker
            [ngClass]="{
                'max-h-282': _items.totalCount < VIRTUAL_SCROLL_THRESHOLD,
                'h-282': _items.totalCount >= VIRTUAL_SCROLL_THRESHOLD,
                'text-text-disabled pointer-events-none': state.disabled
            }"
            (scrolled)="onScroll($event)"
        >
            <div
                *ngIf="_items.totalCount > 5 && withChips"
                class="flex flex-wrap items-center"
            >
                <interacta-chip
                    *ngFor="let item of chipItems$ | async"
                    class="mb-8 mr-8"
                    [iconButtonRight]="'close'"
                    [label]="item.label"
                    (action)="removeItem(item.id)"
                />
            </div>

            <interacta-checkbox-group
                *ngIf="_items.list.length"
                class="flex flex-col"
                [formControl]="control"
                [ngClass]="{
                    'space-y-8': itemsSpacing === 'small',
                    'space-y-12': itemsSpacing === 'medium',
                    'space-y-16': itemsSpacing === 'large'
                }"
            >
                @if (_items.totalCount < VIRTUAL_SCROLL_THRESHOLD) {
                    <interacta-checkbox
                        *ngFor="let item of _items.list; trackBy: trackItem"
                        [disabled]="state.disabled"
                        [ngClass]="{
                            'border-border-300 border-b pb-4':
                                item.id === EMPTY_SEARCH_VALUE &&
                                orientation === 'vertical',
                            'flex-1/2': orientation === 'horizontal'
                        }"
                        [value]="item.id"
                    >
                        <interacta-input-multiple-select-label-item
                            [italic]="item.id === EMPTY_SEARCH_VALUE"
                            [label]="item.label | translate"
                        />
                    </interacta-checkbox>
                } @else {
                    <virtual-scroller
                        [enableUnequalChildrenSizes]="false"
                        [items]="_items.list"
                        [parentScroll]="scroll"
                        (vsUpdate)="displayedItems = $event"
                    >
                        <interacta-checkbox
                            *ngFor="
                                let item of displayedItems;
                                trackBy: trackItem
                            "
                            [disabled]="state.disabled"
                            [ngClass]="{
                                'border-border-300 border-b pb-4':
                                    item.id === EMPTY_SEARCH_VALUE
                            }"
                            [value]="item.id"
                        >
                            <interacta-input-multiple-select-label-item
                                [italic]="item.id === EMPTY_SEARCH_VALUE"
                                [label]="item.label | translate"
                            />
                        </interacta-checkbox>
                    </virtual-scroller>
                }
            </interacta-checkbox-group>

            <interacta-load-more
                *ngIf="_items.nextPageToken.tag !== 'lastLoading'"
                class="flex items-center justify-center py-8"
                size="regular"
                [isLoading]="_items.isFetching"
                [label]="'UI.SHOW_MORE_UNCOUNT' | translate"
                (loadMore)="doSearch()"
            />

            <interacta-checkbox
                *ngIf="!_items.isFetching && !_items.list.length"
                [disabled]="true"
                [ngClass]="{
                    'flex-1/2': orientation === 'horizontal'
                }"
            >
                <interacta-input-multiple-select-label-item
                    [italic]="true"
                    [label]="'NO_AVAILABLE_OPTION' | translate"
                />
            </interacta-checkbox>
        </div>
    }

    @if (mode === 'visual' && imagesSize) {
        <interacta-checkbox-group
            *ngIf="_items.list.length"
            class="flex flex-wrap"
            [disabled]="state.disabled"
            [formControl]="control"
            [ngClass]="{
                'gap-16': imagesSize === 'regular',
                'gap-8': imagesSize === 'small'
            }"
        >
            <div
                *ngFor="let item of _items.list; trackBy: trackItem"
                class="flex flex-col"
                [ngClass]="{
                    'w-250 gap-8': imagesSize === 'regular',
                    'w-180 gap-4': imagesSize === 'small'
                }"
            >
                <interacta-image-preview
                    [active]="
                        { item, items: control.value ?? [] }
                            | apply: isItemSelected
                    "
                    [alt]="item.label"
                    [disabled]="state.disabled"
                    [iconButton]="{
                        icon: 'fullscreen',
                        label: 'UI.VISUAL_SELECT.OPEN_IMAGE'
                    }"
                    [imageSize]="imagesSize"
                    [imageSrc]="
                        item.temporaryContentPreviewImageHiResLink ??
                        item.temporaryContentViewLink
                    "
                    (iconClicked)="openFullscreen(item)"
                    (imgClicked)="itemSelected(item)"
                />

                <interacta-checkbox
                    [align]="'start'"
                    [disabled]="state.disabled"
                    [value]="item.id"
                    [wordBreak]="'normal'"
                >
                    <interacta-input-multiple-select-label-item
                        class="block"
                        [italic]="item.id === EMPTY_SEARCH_VALUE"
                        [label]="item.label | translate"
                        [ngClass]="{
                            'pt-2': imagesSize === 'regular',
                            'pt-4': imagesSize === 'small'
                        }"
                        [size]="
                            imagesSize === 'regular' ? 'small' : 'extra-small'
                        "
                    />
                </interacta-checkbox>
            </div>
        </interacta-checkbox-group>

        <interacta-load-more
            *ngIf="_items.nextPageToken.tag !== 'lastLoading'"
            class="flex items-center justify-center py-8"
            size="regular"
            [isLoading]="_items.isFetching"
            [label]="'UI.SHOW_MORE_UNCOUNT' | translate"
            [ngClass]="{
                'text-text-disabled pointer-events-none': state.disabled
            }"
            (loadMore)="doSearch()"
        />

        <interacta-checkbox
            *ngIf="!_items.isFetching && !_items.list.length"
            [disabled]="true"
            [ngClass]="{
                'flex-1/2': orientation === 'horizontal',
                'text-text-disabled pointer-events-none': state.disabled
            }"
        >
            <interacta-input-multiple-select-label-item
                [italic]="true"
                [label]="'NO_AVAILABLE_OPTION' | translate"
            />
        </interacta-checkbox>
    }
</div>
