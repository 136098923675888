import { createAction, props } from '@ngrx/store';

export const userMentionClick = createAction(
    '[Mentions] user mention click',
    props<{ id: number }>(),
);

export const hashtagMentionClick = createAction(
    '[Mentions] hashtag mention click',
    props<{ id: number }>(),
);

export const tableMentionClick = createAction(
    '[Mentions] table mention click',
    props<{ id: number }>(),
);
