import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';

export interface Options {
    step?: number;
    showTicks?: boolean;
    min: number;
    max: number;
}

@Component({
    selector: 'interacta-range-slider',
    templateUrl: './range-slider.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class RangeSliderComponent {
    @Input({ required: true }) options!: Options;

    @Input({ required: true }) minValueControl!: FormControl<number | null>;
    @Input({ required: true }) maxValueControl!: FormControl<number | null>;

    @Output() userIsDraggingSlider = new EventEmitter<boolean>();
}
