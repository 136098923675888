import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CheckboxState } from '@interacta-shared/ui';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';

type SelectableHashtagType = Pick<IHashTag, 'id' | 'name'> & {
    label: string;
};

@Component({
    selector: 'interacta-hashtag-select',
    templateUrl: './hashtag-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HashtagSelectComponent implements OnChanges {
    @Input({ required: true }) control!: UntypedFormControl;
    @Input() hashtags: IHashTag[] | null = null;
    @Input() occurrences?: Record<
        number,
        { occ: number; state: CheckboxState }
    > = {};

    items: SelectableHashtagType[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hashtags) {
            this.items = (this.hashtags || []).map((h) => ({
                id: h.id,
                name: h.name,
                label: h.name,
            }));
        }
    }
}
