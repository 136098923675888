import { MetadataFieldModeType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { AnyGallerySourceDetail } from './gallery-source.model';
export interface IFilePickerAttachment {
    filePickerMode: MetadataFieldModeType;
    fieldId: number;
    attachments?: IListAttachments;
    historyId?: string;
    /* Following ids are used to get the correct data for linked posts posts */
    communityId?: number;
    postId?: number;
}

export interface IFilePickerAttachmentOutput extends IFilePickerAttachment {
    index: number;
}

export function canShowSearchBarFn(attachment: IAttachment): boolean {
    const canShowSearchBar =
        !!attachment &&
        !attachment.drive &&
        (attachment.isMediaAudio || attachment.isMediaVideo);
    return canShowSearchBar;
}

export class GalleryState {
    sourceDetail = new BehaviorSubject<AnyGallerySourceDetail | null>(null);
    attachmentsList = new BehaviorSubject<IListAttachments | null>(null);
    containsText = new BehaviorSubject<string | null>(null);

    index = new BehaviorSubject<number>(0);
    isOpen = new BehaviorSubject<boolean>(false);
    autoplay = new BehaviorSubject<boolean>(false);
    showInfo = new BehaviorSubject<boolean>(false);
    isSearchBarActive = new BehaviorSubject<boolean>(false);
    canEdit = new BehaviorSubject<boolean>(false);
    isEditModeActive = new BehaviorSubject<boolean>(false);
    hideNavigation = new BehaviorSubject<boolean>(false);

    attachment = combineLatest([
        this.attachmentsList,
        this.index,
        this.isOpen,
    ]).pipe(
        filter(([_, __, isOpen]) => isOpen),
        map(([attachmentsList, index]) => attachmentsList?.attachments[index]),
        distinctUntilChanged(
            (a1, a2) =>
                a1?.id === a2?.id &&
                a1?.lastModifyTimestamp === a2?.lastModifyTimestamp,
        ),
        shareReplay(1),
    );

    canShowSearchBarForThatAttachment = this.attachment.pipe(
        map((a) => (a ? canShowSearchBarFn(a) : false)),
    );
}
