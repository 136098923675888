<span [ngClass]="{ 'inline-block align-bottom': display === 'inline' }">
    {{ zonedDate }}
    <span #openMenu="cdkOverlayOrigin" cdkOverlayOrigin>
        <button
            *ngIf="isZoned"
            type="button"
            [disabled]="disableButton"
            (click)="toggleTimezoneMenu()"
        >
            <interacta-icon
                class="mr-2 inline-block h-16 w-16 align-text-bottom"
                [icon]="'globe'"
            ></interacta-icon>
        </button>
    </span>
</span>

<interacta-menu
    *ngIf="isMenuOpen"
    positionX="after"
    positionY="above"
    [open]="isMenuOpen"
    [origin]="openMenu"
    (closing)="isMenuOpen = false"
>
    <div
        class="min-w-252 bg-surface-A flex flex-col gap-8 rounded-2xl p-16 shadow-sm"
    >
        <div *ngFor="let localDate of localDates" class="flex flex-col gap-4">
            <span class="typo-sm-bold">
                {{ localDate.date }}
            </span>
            <span class="typo-sm">
                {{ localDate.timezone }}
            </span>
        </div>
    </div>
</interacta-menu>
