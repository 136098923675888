import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    InputSelectSearchEvent,
    ScrollTrackerEvent,
    emptyInputSelectSearchEvent,
} from '@interacta-shared/ui';
import {
    PaginatedList,
    emptyPaginatedList,
    fetchPaginatedList,
    fetchPaginatedListSuccess,
    getNextPageToken,
    mapArrayById,
} from '@interacta-shared/util';
import { Tag } from '@modules/core/models/tag/tag.model';
import {
    SurveyRecipientTagExcludedGroups,
    SurveyRecipientsGroup,
} from '@modules/post/models/survey-post/survey-post.model';
import { TagService } from '@modules/shared-v2/services/tag.service';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil,
} from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';

@Component({
    selector: 'interacta-tag-groups-dialog',
    templateUrl: './tag-groups-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagGroupsDialogComponent implements OnInit, OnDestroy {
    @Input() isOpen = false;
    @Input({ required: true }) tag!: Tag;
    @Input() originalExcludedGroupIds: SurveyRecipientsGroup['id'][] = [];
    @Input() recipientsTagExcludedGroupsControl!: FormControl<
        SurveyRecipientTagExcludedGroups[] | null
    >;
    /**
     * @param isTagLocked true means that is not possible to add groups to exludedList.
     *   Those groups must not be part of 'originalExcludedGroupIds'
     */
    @Input() isTagLocked = false;
    @Input() communityId: number | null = null;

    @Output() closeDialog = new EventEmitter<void>();

    private _localRecipientsTagExcludedGroups = new BehaviorSubject<
        SurveyRecipientTagExcludedGroups[] | null
    >(null);

    excludedGroups$!: Observable<
        SurveyRecipientTagExcludedGroups['excludedGroups']
    >;
    tagGroups$ = new BehaviorSubject<PaginatedList<SurveyRecipientsGroup>>(
        emptyPaginatedList<SurveyRecipientsGroup>(),
    );
    filteredTagGroups$!: Observable<PaginatedList<SurveyRecipientsGroup>>;
    searchControl = new FormControl<string>('', { nonNullable: true });

    filters$ = new BehaviorSubject<InputSelectSearchEvent>(
        emptyInputSelectSearchEvent(),
    );

    totalGroupsCount = 0;

    private tagsService = inject(TagService);
    private destroy$ = new Subject<void>();

    ngOnInit(): void {
        this._localRecipientsTagExcludedGroups.next(
            this.recipientsTagExcludedGroupsControl.value,
        );

        this.excludedGroups$ = this._localRecipientsTagExcludedGroups.pipe(
            map(
                (rec) =>
                    rec?.find((r) => r.tagId === this.tag.id)?.excludedGroups ??
                    [],
            ),
            shareReplay(1),
            takeUntil(this.destroy$),
        );

        this.searchControl.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe((text) =>
                this.filters$.next({
                    text,
                    nextPageToken: null,
                }),
            );

        this.filters$
            .pipe(
                filter(() => !this.tagGroups$.value.isFetching),
                takeUntil(this.destroy$),
            )
            .subscribe((filters) => this.searchGroups(filters));

        this.filteredTagGroups$ = combineLatest([
            this.excludedGroups$,
            this.tagGroups$.asObservable(),
        ]).pipe(
            filter(([_, allGroups]) => !allGroups.isFetching),
            map(([excluded, allGroups]) => {
                const excludedIdsSet = new Set(mapArrayById(excluded));

                return {
                    ...allGroups,
                    list: allGroups.list.filter(
                        (item) => !excludedIdsSet.has(item.id),
                    ),
                };
            }),
            takeUntil(this.destroy$),
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    addToExcluded(group: SurveyRecipientsGroup): void {
        const newVal = this._localRecipientsTagExcludedGroups.value?.map((r) =>
            r.tagId === this.tag.id
                ? { ...r, excludedGroups: [group, ...r.excludedGroups] }
                : r,
        );
        this._localRecipientsTagExcludedGroups.next(newVal ?? []);
    }

    removeFromExcluded(group: SurveyRecipientsGroup): void {
        const newVal = this._localRecipientsTagExcludedGroups.value?.map((r) =>
            r.tagId === this.tag.id
                ? {
                      ...r,
                      excludedGroups: r.excludedGroups.filter(
                          (e) => e.id != group.id,
                      ),
                  }
                : r,
        );
        this._localRecipientsTagExcludedGroups.next(newVal ?? []);
    }

    confirm(): void {
        this.recipientsTagExcludedGroupsControl.setValue(
            this._localRecipientsTagExcludedGroups.value,
        );
        this.closeDialog.emit();
    }

    onScroll(event: ScrollTrackerEvent): void {
        if (event?.thresholdReached) {
            this.doSearch();
        }
    }

    doSearch(): void {
        const items = this.tagGroups$.value;
        if (getNextPageToken(items.nextPageToken) && !items.isFetching) {
            this.filters$.next({
                text: this.searchControl.value,
                nextPageToken: getNextPageToken(items.nextPageToken),
            });
        }
    }

    searchGroups(filter: InputSelectSearchEvent): void {
        this.tagGroups$.next(
            fetchPaginatedList(
                this.tagGroups$.value,
                filter.nextPageToken ?? undefined,
            ),
        );

        this.tagsService
            .getTagGroups(filter, this.tag.id, this.communityId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if (!filter.nextPageToken && !filter.text.length) {
                    this.totalGroupsCount = res.totalCount;
                }
                this.tagGroups$.next(
                    fetchPaginatedListSuccess(this.tagGroups$.value, res),
                );
            });
    }
}
