import {
    firstLoading,
    lastLoading,
    PaginatedList,
    uuid,
} from '@interacta-shared/util';
import {
    AUDIO,
    ContentMimeType,
    DOC,
    G_DRAW,
    IMAGE,
    isMediaAudio,
    isMediaImage,
    isMediaVideo,
    PDF,
    PPT,
    VIDEO,
    XLS,
    ZIP,
} from '@interacta-shared/util-common';
import { idArraytoMap } from '@modules/core/helpers/generic.utils';
import { GenericPost } from '@modules/post/models/generic-post.model';
import {
    AttachmentCategoryType,
    AttachmentMimeTypeFilter,
    IAttachment,
    IAttachmentPost,
    IListAttachments,
    MimeTypeObj,
} from './attachment.model';

export function isEmbeddable(isDrive: boolean, mimeType: any): boolean {
    return (
        isDrive ||
        Object.keys(embeddableMimeTypeUrlBuilders).includes(mimeType as string)
    );
}

export function getAttachmentsMimetype(type: any): string {
    switch (type) {
        case ContentMimeType.PDF:
            return 'PDF';
        case ContentMimeType.JPEG:
            return 'JPEG';
        case ContentMimeType.JPG:
            return 'JPG';
        case ContentMimeType.PNG:
            return 'PNG';
        case ContentMimeType.GIF:
            return 'GIF';
        case ContentMimeType.DOC2:
            return 'DOC2';
        case ContentMimeType.DOC:
            return 'DOC';
        case ContentMimeType.XLS:
            return 'XLS';
        case ContentMimeType.XLS2:
            return 'XLS2';
        case ContentMimeType.PPT:
            return 'PPT';
        case ContentMimeType.PPT2:
            return 'PPT2';
        case ContentMimeType.TXT:
            return 'TXT';
        case ContentMimeType.ZIP:
            return 'ZIP';
        case ContentMimeType.CSV:
            return 'CSV';
        case ContentMimeType.MP3:
            return 'MP3';
        case ContentMimeType.AUDIO_MPEG:
            return 'AUDIO_MPEG';
        case ContentMimeType.AUDIO_MP4:
            return 'AUDIO_MP4';
        case ContentMimeType.WAV:
            return 'WAV';
        case ContentMimeType.AUDIO_OGG:
            return 'OGG';
        case ContentMimeType.M4A:
            return 'M4A';
        case ContentMimeType.MP4:
            return 'MP4';
        case ContentMimeType.AVI:
            return 'AVI';
        case ContentMimeType.WEBM:
            return 'WEBM';
        case ContentMimeType.OGG:
            return 'OGG';
        case ContentMimeType.WMV:
            return 'WMV';
        case ContentMimeType.MOV:
            return 'MOV';
        case ContentMimeType.MPEG:
            return 'MPEG';
        case ContentMimeType.X_PHOTOSHOP:
            return 'X_PHOTOSHOP';
        case ContentMimeType.PSD:
            return 'PSD';
        case ContentMimeType.PHOTOSHOP:
            return 'PHOTOSHOP';
        case ContentMimeType.RAR:
            return 'RAR';
        case ContentMimeType.GZIP:
            return 'GZIP';
        case ContentMimeType.G_AUDIO:
            return 'G_AUDIO';
        case ContentMimeType.G_DOC:
            return 'G_DOC';
        case ContentMimeType.G_SDK:
            return 'G_SDK';
        case ContentMimeType.G_DRAW:
            return 'JPG';
        case ContentMimeType.G_FILE:
            return 'G_FILE';
        case ContentMimeType.G_FOLDER:
            return 'G_FOLDER';
        case ContentMimeType.G_FORM:
            return 'G_FORM';
        case ContentMimeType.G_FUSION:
            return 'G_FUSION';
        case ContentMimeType.G_VIDEO:
            return 'G_VIDEO';
        case ContentMimeType.G_MAPS:
            return 'G_MAPS';
        case ContentMimeType.G_PHOTO:
            return 'G_PHOTO';
        case ContentMimeType.G_SLIDES:
            return 'G_SLIDES';
        case ContentMimeType.G_SCRIPT:
            return 'G_SCRIPT';
        case ContentMimeType.G_SHORTCUT:
            return 'G_SHORTCUT';
        case ContentMimeType.G_SITE:
            return 'G_SITE';
        case ContentMimeType.G_SHEET:
            return 'G_SHEET';
        case ContentMimeType.G_UNKNOWN:
            return 'G_UNKNOWN';
        default:
            return 'OTHER';
    }
}

export function isMediaAttachment(attachment: IAttachment): boolean {
    return (
        attachment.isMediaAudio ||
        attachment.isMediaGif ||
        attachment.isMediaImage ||
        attachment.isMediaVideo
    );
}

export function isFileAttachment(attachment: IAttachment): boolean {
    return !isMediaAttachment(attachment);
}

export function attachmentsMedia(attachments: IAttachment[]): IAttachment[] {
    return attachments.filter(isMediaAttachment);
}

export function attachmentsFiles(attachments: IAttachment[]): IAttachment[] {
    return attachments.filter(isFileAttachment);
}

export function emptyAttachmentsList(
    attachmentCategoryType?: AttachmentCategoryType,
): IListAttachments {
    return {
        attachments: [],
        pageTokenInfo: firstLoading(),
        totalCount: 0,
        attachmentCategoryType,
        isLoading: false,
        isError: false,
    };
}

export function attachmentsToAttachmentList(
    attachments: IAttachment[],
    attachmentCategoryType?: AttachmentCategoryType,
): IListAttachments {
    return {
        attachments,
        totalCount: attachments?.length || 0,
        pageTokenInfo: lastLoading(),
        attachmentCategoryType,
        isLoading: false,
        isError: false,
    };
}

export function getUpdateAttachments(
    attachments: IAttachment[],
): IAttachment[] {
    const newVersions: IAttachment[] = [];
    attachments.forEach((attachment) => {
        if ((attachment?.versions?.length ?? 0) > 0) {
            const versions = getNewVersionAttachments(attachment);
            newVersions.push(...versions);
        } else if (attachment.inUpdating) {
            newVersions.push(attachment);
        }
    });

    return newVersions;
}
export function getNewVersionAttachments(
    attachment: IAttachment,
): IAttachment[] {
    return (attachment.versions || [])
        .filter((a: IAttachment) => a.inPending)
        .map((a) => ({
            ...a,
            name: attachment.name,
            hashtags: attachment.hashtags,
        }));
}
export function getAddAttachments(attachments: IAttachment[]): IAttachment[] {
    return (attachments || []).filter(
        (attachment) =>
            attachment.inPending &&
            !attachment.inDeleting &&
            !attachment.isCanceled &&
            !attachment.isLoadingError,
    );
}

export function getRemoveAttachmentIds(
    attachments: IAttachment[],
): IAttachment['id'][] {
    return (attachments || [])
        .filter(
            (attachment) =>
                attachment.inDeleting &&
                !attachment.inPending &&
                !attachment.isDraft,
        )
        .map((attachment) => attachment.id);
}

export function attachmentsListFilter(
    list: IListAttachments,
    deletedIds: Set<IAttachment['id']>,
): IListAttachments {
    const remainingAttachments = list.attachments.filter(
        (a) => !deletedIds.has(a.id),
    );

    const deletionCount = list.attachments.length - remainingAttachments.length;

    return {
        ...list,
        attachments: remainingAttachments,
        totalCount: list.totalCount - deletionCount,
    };
}

export function paginatedListToAttachmentsList(
    paginatedList: PaginatedList<IAttachment>,
    categoryType: AttachmentCategoryType,
): IListAttachments {
    return {
        attachmentCategoryType: categoryType,
        attachments: paginatedList.list,
        totalCount: paginatedList.totalCount,
        pageTokenInfo: paginatedList.nextPageToken,
        isLoading: paginatedList.isFetching,
        isError: false,
    };
}

export function updateAttachmentsList(
    currAttachmentList: IListAttachments,
    updatedList: IAttachment[],
): IListAttachments {
    const updatedMap = idArraytoMap(updatedList);
    return updatedList.length
        ? {
              ...currAttachmentList,
              attachments: currAttachmentList.attachments.map((a) =>
                  updatedMap[a.id]
                      ? {
                            ...updatedMap[a.id],
                            capabilities: a.capabilities,
                            post: updatedMap[a.id].post ?? a.post,
                        }
                      : a,
              ),
          }
        : currAttachmentList;
}

export function postToAttachmentPost(post: GenericPost): IAttachmentPost {
    return {
        id: post.id,
        type: post.type,
        title: post.title,
        code: post.code,
        descriptionDelta: post.descriptionDelta,
        draft: post.draft,
        metadata: post.metadata,
    };
}

// https://injenia.atlassian.net/browse/IISP-6459?focusedCommentId=62677
// const googleViewerUrlBuilder = (url: string | undefined): string | undefined =>
//     url
//         ? `https://docs.google.com/viewer?url=${encodeURIComponent(
//               url,
//           )}&embedded=true`
//         : undefined;

const officeViewerUrlBuilder = (url: string | undefined): string | undefined =>
    url
        ? `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              url,
          )}`
        : undefined;

const embeddableMimeTypeUrlBuilders: Partial<
    Record<ContentMimeType, (url: string | undefined) => string | undefined>
> = {
    [ContentMimeType.PDF]: (url: string | undefined) => url,
    [ContentMimeType.DOC]: officeViewerUrlBuilder,
    [ContentMimeType.DOC2]: officeViewerUrlBuilder,
    [ContentMimeType.PPT]: officeViewerUrlBuilder,
    [ContentMimeType.PPT2]: officeViewerUrlBuilder,
    [ContentMimeType.XLS]: officeViewerUrlBuilder,
    [ContentMimeType.XLS2]: officeViewerUrlBuilder,
};
export function buildTemporaryContentEmbedLink(
    attachment: IAttachment,
): string | undefined {
    if (attachment.drive) {
        return attachment.drive.embedLink;
    }

    const urlBuilder =
        embeddableMimeTypeUrlBuilders[attachment.contentMimeType];

    return urlBuilder?.(attachment.temporaryContentViewLink);
}

export const FilterMimeTypesDocuments: MimeTypeObj[] = [
    {
        id: AttachmentMimeTypeFilter.PDF,
        label: 'DASHBOARD.ATTACHMENTS.MIMETYPE.PDF',
        src: PDF,
    },
    {
        id: AttachmentMimeTypeFilter.DOCUMENT,
        label: 'DASHBOARD.ATTACHMENTS.MIMETYPE.DOCUMENT',
        src: DOC,
    },
    {
        id: AttachmentMimeTypeFilter.SPREADSHEET,
        label: 'DASHBOARD.ATTACHMENTS.MIMETYPE.EXCEL',
        src: XLS,
    },
    {
        id: AttachmentMimeTypeFilter.PRESENTATION,
        label: 'DASHBOARD.ATTACHMENTS.MIMETYPE.PRESENTATION',
        src: PPT,
    },
    {
        id: AttachmentMimeTypeFilter.DRAWING,
        label: 'DASHBOARD.ATTACHMENTS.MIMETYPE.DRAW',
        src: G_DRAW,
    },
    {
        id: AttachmentMimeTypeFilter.ARCHIVE,
        label: 'DASHBOARD.ATTACHMENTS.MIMETYPE.ARCHIVE',
        src: ZIP,
    },
];

export const FilterMimeTypesMedia: MimeTypeObj[] = [
    {
        id: AttachmentMimeTypeFilter.AUDIO,
        label: 'DASHBOARD.ATTACHMENTS.MIMETYPE.AUDIO',
        src: AUDIO,
    },
    {
        id: AttachmentMimeTypeFilter.IMAGE,
        label: 'DASHBOARD.ATTACHMENTS.MIMETYPE.IMAGE',
        src: IMAGE,
    },
    {
        id: AttachmentMimeTypeFilter.VIDEO,
        label: 'DASHBOARD.ATTACHMENTS.MIMETYPE.VIDEO',
        src: VIDEO,
    },
];

export const FilterMimeTypes: MimeTypeObj[] = [
    ...FilterMimeTypesDocuments,
    ...FilterMimeTypesMedia,
];

export function getMimeTypeObjsFromAttachmentMimeTypes(
    idsOrObjs: number[] | MimeTypeObj[],
): MimeTypeObj[] {
    return idsOrObjs
        .map((idOrObj) => {
            return typeof idOrObj == 'number'
                ? FilterMimeTypes.find((mimeType) => idOrObj === mimeType.id) ??
                      null
                : idOrObj;
        })
        .filter((item): item is MimeTypeObj => item !== null);
}

export function getAttachmentTypeLabel(
    contentMimeType: ContentMimeType,
): string {
    if (isMediaAudio(contentMimeType)) {
        return 'DASHBOARD.ATTACHMENTS.MIMETYPE.AUDIO';
    } else if (isMediaImage(contentMimeType)) {
        return 'DASHBOARD.ATTACHMENTS.MIMETYPE.IMAGE';
    } else if (isMediaVideo(contentMimeType)) {
        return 'DASHBOARD.ATTACHMENTS.MIMETYPE.VIDEO';
    } else {
        switch (contentMimeType) {
            case ContentMimeType.PDF:
                return 'DASHBOARD.ATTACHMENTS.MIMETYPE.PDF';
            case ContentMimeType.DOC:
            case ContentMimeType.DOC2:
            case ContentMimeType.TXT:
                return 'DASHBOARD.ATTACHMENTS.MIMETYPE.DOCUMENT';
            case ContentMimeType.XLS:
            case ContentMimeType.XLS2:
            case ContentMimeType.CSV:
                return 'DASHBOARD.ATTACHMENTS.MIMETYPE.EXCEL';
            case ContentMimeType.PPT:
            case ContentMimeType.PPT2:
                return 'DASHBOARD.ATTACHMENTS.MIMETYPE.PRESENTATION';
            default:
                return 'DASHBOARD.ATTACHMENTS.MIMETYPE.ARCHIVE';
        }
    }
}

export function buildReference(
    attachment: IAttachment,
    hashTagsEnabled?: boolean,
): IAttachment {
    if (typeof attachment.id === 'string') {
        throw new Error(
            "Can't build reference from an attachment not yet saved",
        );
    }

    return {
        ...attachment,
        id: uuid(),
        hashtags:
            hashTagsEnabled && attachment.hashtags
                ? [...attachment.hashtags]
                : [],
        referencedAttachmentId: attachment.id,
        isAttachmentRef: true,
        isNew: true,
    };
}

export function canEditAttachment(attachment: IAttachment): boolean {
    return (
        (attachment.capabilities?.canEdit ?? true) &&
        !attachment.drive &&
        !attachment.isPartial &&
        !attachment.isLoadingError &&
        !attachment.isCanceled &&
        !attachment.inDeleting
    );
}

export function canDeleteAttachment(attachment: IAttachment): boolean {
    return (
        (attachment.capabilities?.canDelete ?? true) &&
        !attachment.isCanceled &&
        !attachment.inDeleting
    );
}

export function canEditHashtagsAttachment({
    attachment,
    hashtagsAvailable,
}: {
    attachment: IAttachment;
    hashtagsAvailable: boolean;
}): boolean {
    return (
        (attachment.capabilities?.canEditHashtags ?? true) &&
        hashtagsAvailable &&
        !attachment.isPartial &&
        !attachment.isLoadingError &&
        !attachment.isCanceled &&
        !attachment.inDeleting
    );
}

export function canManageVersionAttachment({
    attachment,
    driveIntegrationEnabled,
}: {
    attachment: IAttachment;
    driveIntegrationEnabled: boolean;
}): boolean {
    return (
        ((attachment.capabilities?.canEdit ||
            (attachment.isAttachmentRef &&
                attachment.capabilities?.canEditHashtags)) &&
            (!attachment.drive ||
                (attachment.drive != null && driveIntegrationEnabled))) ??
        false
    );
}

export function canAddVersionAttachment(attachment: IAttachment): boolean {
    return (
        canEditAttachment(attachment) &&
        !attachment.isAttachmentRef &&
        typeof attachment.id === 'number'
    );
}

export function srcFallback(attachment: IAttachment): string {
    return (
        'assets/shared/images/' +
        (attachment.isMediaVideo
            ? 'no_video.jpg'
            : attachment.isMediaAudio
              ? 'no_audio.jpg'
              : 'no_image.jpg')
    );
}
