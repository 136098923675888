import { emptyPaginatedList } from '@interacta-shared/util';
import { toAdminV2Community } from '@modules/admin-v2-community/models/admin-v2-community.deserialize';
import { AdminV2GroupCol } from '@modules/admin-v2-group/models/admin-v2-group.model';
import { toAdminV2Workspace } from '@modules/admin-v2-workspace/models/admin-v2-workspace.deserialize';
import {
    AdminV2Group,
    AdminV2GroupDetail,
    AdminV2GroupRow,
} from './admin-v2-group.model';

export const toAdminV2Group = (record: any): AdminV2Group => ({
    id: record.id,
    email: record.email,
    occToken: record.occToken,
    fullName: record.name,
    deleted: record.deleted,
    visible: record.visible,
    description: record.description,
});

export const toAdminV2GroupRow = (record: any): AdminV2GroupRow => ({
    id: record.id,
    group: toAdminV2GroupDetailCol(record),
    description: record.description,
    usersCount: record.membersCount,
    visible: record.visible,
    externalId: record.externalId,
    tags: record.tags ?? [],
    creationTimestamp: record.creationTimestamp ?? null,
    logicallyDisabled: record.deleted,
    deleted: record.deleted,
    selected: false,
    loading: false,
    workspaces: record.workspaces.map(toAdminV2Workspace),
    communities: {
        communities: record.communities.map(toAdminV2Community),
    },
});

export const toAdminV2GroupDetail = (record: any): AdminV2GroupDetail => ({
    id: record.id ?? null,
    fullName: record.name,
    email: record.email ?? null,
    description: record.description ?? null,
    visible: record.visible ?? true,
    externalId: record.externalId ?? null,
    occToken: record.occToken,
    membersCount: record.membersCount,
    tags: record.tags ?? [],
    users: emptyPaginatedList(),
    workspaceRoleAssociationsList: [],
    creationTimestamp: record.creationTimestamp,
    lastModifyTimestamp: record.lastModifyTimestamp,
    deleted: record.deleted ?? false,
});

export const toAdminV2GroupDetailCol = (record: any): AdminV2GroupCol => ({
    id: record.id,
    fullName: record.name,
    deleted: record.deleted ?? null,
});
