import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormControl,
    ReactiveFormsModule,
    UntypedFormControl,
} from '@angular/forms';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';
import { filterMap, uuid } from '@interacta-shared/util';
import { Subject, concat, of, takeUntil } from 'rxjs';
import { DecimalNumberV2Directive } from '../../directives/decimal-number-v2.directive';
import { InputStatePipe } from '../../pipes/input-state.pipe';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
    selector: 'interacta-input-number-v2',
    templateUrl: './input-number-v2.component.html',
    styles: [
        `
            .no-spinner::-webkit-inner-spin-button,
            .no-spinner::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            /* Firefox */
            .no-spinner {
                -moz-appearance: textfield;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        NgClass,
        DecimalNumberV2Directive,
        IconComponent,
        IconButtonComponent,
        AsyncPipe,
        InputStatePipe,
    ],
})
export class InputNumberV2Component implements OnChanges, OnDestroy {
    @Input({ required: true }) control!: UntypedFormControl;
    @Input() isReadonly = false;
    @Input() hideSpinners = true;
    @Input() textAlign: 'center' | 'left' = 'left';
    @Input() decimalDigits = 0;
    @Input() decimalLocale?: string;
    @Input() label?: string;
    @Input() minValue?: number;
    @Input() maxValue?: number;
    @Input() icon?: InteractaIcon;
    @Input() iconClickable = true;
    @Input() iconFocusableWithTab = false;
    @Input() iconPosition?: 'right' | 'left';
    @Input() iconColor: 'primary' | 'gray' | 'black' = 'primary';
    @Input() alternativeText = '';
    @Input() emitOnChange = false;
    @Output() iconClicked = new EventEmitter<void>();
    @Output() blurEmitter = new EventEmitter<void>();

    id = uuid();

    private destroy$ = new Subject<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['control'] || changes['maxValue'] || changes['minValue']) {
            this.destroy$.next();
            this.enforceMinMaxValue(this.minValue, this.maxValue, this.control);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    private enforceMinMaxValue(
        min: number | undefined,
        max: number | undefined,
        control: FormControl<number | null>,
    ): void {
        if (min != null || max != null) {
            concat(of(control.value), control.valueChanges)
                .pipe(
                    takeUntil(this.destroy$),
                    filterMap((v) => v ?? undefined),
                )
                .subscribe((v) => {
                    if (min != null && v < min) {
                        control.setValue(min, { emitEvent: this.emitOnChange });
                    } else if (max != null && v > max) {
                        control.setValue(max, { emitEvent: this.emitOnChange });
                    }
                });
        }
    }
}
