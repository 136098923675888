import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';

@Component({
    selector: 'interacta-show-more-toggle',
    templateUrl: './show-more-toggle.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMoreToggleComponent {
    @Input() isShowingMore = false;
    @Input() arrow = true;

    @Output() toggle = new EventEmitter<boolean>();
}
