import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    ViewChild,
    forwardRef,
    signal,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    ApplyPipe,
    ButtonComponent,
    DeltaViewComponent,
    ToggleComponent,
} from '@interacta-shared/ui';
import { uuid } from '@interacta-shared/util';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import Quill, { Delta } from 'quill/core';
import { EditableTableData } from '../../models';
import { EditableCellMenuButtonComponent } from '../editable-cell-menu-button/editable-cell-menu-button.component';
import { EditableCellQuillToolbarComponent } from '../editable-cell-quill-toolbar/editable-cell-quill-toolbar.component';

interface CellIndex {
    rowIndex: number;
    columnIndex: number;
}

function areEquals(newIndex: CellIndex, current: CellIndex | null): boolean {
    return (
        current !== null &&
        newIndex.rowIndex === current.rowIndex &&
        newIndex.columnIndex === current.columnIndex
    );
}

@Component({
    selector: 'interacta-editable-table',
    templateUrl: './editable-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditableTableComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        CommonModule,
        ButtonComponent,
        ToggleComponent,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        DeltaViewComponent,
        QuillModule,
        ApplyPipe,
        EditableCellQuillToolbarComponent,
        EditableCellMenuButtonComponent,
    ],
})
export class EditableTableComponent implements ControlValueAccessor {
    @ViewChild('myTable') myTable!: ElementRef;
    @Input() readonly = false;

    activeCell = signal<CellIndex | null>(null);

    isCellMenuOpen = false;

    tableData: EditableTableData | null = null;

    activateCell(cellIndex: CellIndex): void {
        this.activeCell.update((current) =>
            areEquals(cellIndex, current) ? current : cellIndex,
        );
    }

    focusCellEditor(quill: Quill): void {
        quill.focus();
    }

    deactivateCell(): void {
        this.activeCell.set(null);
        this.isCellMenuOpen = false;
    }

    openCellMenu(): void {
        this.isCellMenuOpen = true;
    }

    isActiveCellPipe($: {
        newIndex: CellIndex;
        current: CellIndex | null;
    }): boolean {
        return areEquals($.newIndex, $.current);
    }

    private onChange: (value: EditableTableData) => void = (
        _: EditableTableData,
    ) => {
        // default empty implementation
    };

    private onTouched: (value: EditableTableData) => void = (
        _: EditableTableData,
    ) => {
        // default empty funcion
    };

    registerOnChange(fn: (value: EditableTableData) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: (value: EditableTableData) => void): void {
        this.onTouched = fn;
    }

    writeValue(value: EditableTableData | null | undefined): void {
        if (value) {
            this.tableData = value;
        }
    }

    private emitChange() {
        if (this.tableData) {
            this.onChange(this.tableData);
            this.onTouched(this.tableData);
        }
    }

    addRow(): void {
        if (this.tableData) {
            this.tableData.rows.push({});
            this.emitChange();
        }
    }

    addRowAbove(rowIndex: number): void {
        if (this.tableData) {
            this.tableData.rows.splice(rowIndex, 0, {});
            this.activeCell.update((current) =>
                current
                    ? { ...current, rowIndex: current.rowIndex + 1 }
                    : current,
            );
            this.emitChange();
        }
    }

    addRowBelow(rowIndex: number): void {
        if (this.tableData) {
            this.tableData.rows.splice(rowIndex + 1, 0, {});
            this.emitChange();
        }
    }

    addColumn(): void {
        if (this.tableData) {
            const newColumn = { key: uuid() };
            this.tableData.columns.push(newColumn);
            this.tableData.rows.forEach(
                (row) => (row[newColumn.key] = new Delta()),
            );
            this.emitChange();
        }
    }

    addColumnRight(columnIndex: number): void {
        if (this.tableData) {
            const newColumnKey = uuid();
            this.tableData.columns.splice(columnIndex + 1, 0, {
                key: newColumnKey,
            });
            this.tableData.rows.forEach(
                (row) => (row[newColumnKey] = new Delta()),
            );
            this.emitChange();
        }
    }

    addColumnLeft(columnIndex: number): void {
        if (this.tableData) {
            const newColumnKey = uuid();
            this.tableData.columns.splice(columnIndex, 0, {
                key: newColumnKey,
            });
            this.tableData.rows.forEach(
                (row) => (row[newColumnKey] = new Delta()),
            );
            this.activeCell.update((current) =>
                current
                    ? { ...current, columnIndex: current.columnIndex + 1 }
                    : current,
            );
            this.emitChange();
        }
    }

    deleteRow(rowIndex: number): void {
        if (this.tableData) {
            this.tableData.rows.splice(rowIndex, 1);
            this.deactivateCell();
            this.emitChange();
        }
    }

    deleteColumn(columnIndex: number): void {
        if (this.tableData) {
            const deletedColumnKey = this.tableData.columns[columnIndex].key;
            this.tableData.columns.splice(columnIndex, 1);
            this.tableData.rows.forEach((row) => delete row[deletedColumnKey]);
            this.deactivateCell();
            this.emitChange();
        }
    }

    trackByIdx(idx: number): number {
        return idx;
    }
}
