import {
    Area,
    AuthUser,
    BusinessUnit,
    CurrentUser,
    toAuthUser,
} from '@interacta-shared/data-access-auth';
import {
    Language,
    Timezone,
} from '@interacta-shared/data-access-configuration';
import { InteractaIcon } from '@interacta-shared/ui-icons';
import { IList } from '@interacta-shared/util';

export interface IUser extends AuthUser {
    followedByMe?: boolean;
    icon?: InteractaIcon;
}

export interface IUserCommunity {
    id: number;
    firstName: string;
    lastName: string;
    contactEmail: string;
    googleAccountId: string;
    microsoftAccountId: string;
    serviceAccount: boolean;
    activeDWD: boolean;
    accountPhotoUrl: string;
    deleted: boolean;
}

export interface UserCapabilities {
    accessAdminArea: boolean;
    createUsers: boolean;
    createWorkspaces: boolean;
    manageUsers: boolean;
    manageWorkspaces: boolean;
    manageWorkspacesUsersAssociations: boolean;
    reindexPosts: boolean;
    manageCommunities: boolean;
    manageCatalogs: boolean;
    manageWorkflowTemplates: boolean;
    manageDigitalWorkplace: boolean;
    manageRoles: boolean;
    resellerAdmin: boolean;
    createCommunities: boolean;
    manageAllWorkspaces: boolean;
}

export type AdminCapabilities = keyof UserCapabilities;

export interface PartialSharedProfile extends IUser {
    area: Area | null;
    businessUnit: BusinessUnit | null;
    highResAccountPhotoUrl: string;
    role: string;
    privateEmail: string | null;
    phone?: string;
    mobilePhone?: string;
    place?: string;
    internalPhone?: string;
    biography?: string;
    manager?: IUser[];
    employees?: IUser[];
}

export interface SharedProfile extends PartialSharedProfile {
    language: Language | null;
    timezone: Timezone | null;
    profilePhotoContentRef: string;
    removePhotoProfile: boolean;
    canManageProfilePhoto: boolean;
    occToken: number;
    currentUser?: CurrentUser;
    deleted?: boolean;
    editProfile: boolean;
    emailNotificationsEnabled: boolean;
    privateEmailVerified: boolean;
}

export class UserDeserilize {
    public static userDetails = (record: any): IUser => ({
        ...toAuthUser(record),
        followedByMe: record.followedByMe,
    });

    public static userCapabilities = (record: any): UserCapabilities => ({
        accessAdminArea: record.accessAdminArea,

        createUsers: record.createUsers,
        createWorkspaces: record.createWorkspaces,
        createCommunities: record.createCommunities,

        manageUsers: record.manageUsers,
        manageWorkspaces: record.manageWorkspaces,
        manageWorkspacesUsersAssociations:
            record.manageWorkspacesUsersAssociations,
        manageCommunities: record.manageCommunities,
        manageWorkflowTemplates: record.manageWorkflowTemplates,
        manageCatalogs: record.manageCatalogs,
        manageDigitalWorkplace: record.manageDigitalWorkplace,
        manageRoles: record.manageRoles,
        reindexPosts: record.reindexPosts,
        resellerAdmin: record.resellerAdmin,
        manageAllWorkspaces: record.manageAllWorkspaces,
    });

    public static peopleUser = (record: any): PartialSharedProfile => {
        const {
            area,
            businessUnit,
            followedByMe,
            highResAccountPhotoUrl,
            role,
            phone,
            mobilePhone,
            place,
            internalPhone,
            biography,
            privateEmail,
        } = record;
        return {
            ...UserDeserilize.userDetails(record),
            area,
            businessUnit,
            followedByMe,
            highResAccountPhotoUrl,
            role,
            phone,
            mobilePhone,
            place,
            internalPhone,
            biography,
            privateEmail,
        };
    };

    public static userList = (record: any): IList<IUser> => {
        return {
            list: record.items
                ? (<Array<any>>record.items).map((item) =>
                      this.userDetails(item),
                  )
                : [],
            totalCount: record.totalItemsCount || 0,
            nextPageToken: record.nextPageToken,
        };
    };
}
