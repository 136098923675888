import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';

export const renderHashtagItem = (
    hashtag: IHashTag,
    domSanitizer: DomSanitizer,
): HTMLButtonElement => {
    const buttonElement = document.createElement('button');
    buttonElement.className =
        'w-full px-8 py-4 typo-sm-bold text-left break-all';
    buttonElement.innerHTML =
        domSanitizer.sanitize(SecurityContext.HTML, hashtag.name) ?? '';
    return buttonElement;
};
