import { toWorkflowStatus } from '@modules/communities/models/workflow/workflow-base.deserialize';
import { VisibilityType } from '../base-post.model';
import { IPostLink } from './post-link.model';

export const toPostLink = (recordPost: any): IPostLink => ({
    id: +recordPost.id,
    communityId: +recordPost.communityId,
    title: recordPost.title,
    type: recordPost.type,
    tag: recordPost.type,
    code: recordPost.customId,
    currentWorkflowState: recordPost.currentWorkflowState
        ? toWorkflowStatus(recordPost.currentWorkflowState)
        : null,
    deleted: recordPost.deleted ?? false,
    visibility: recordPost.visibility ?? VisibilityType.PUBLIC,
    announcement: recordPost.announcement ?? false,
});
