import { inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { renderHashtagItem } from '@modules/mentions/helpers/mention-item-hashtag.renderer';
import { renderLoadingItem } from '@modules/mentions/helpers/mention-item-loading.renderer';
import {
    isMentionDataMember,
    isMentionItemPlaceholder,
    MentionItemWrapper,
} from '@modules/mentions';
import { renderMemberItem } from '@modules/mentions/helpers/mention-item-member.renderer';
import { assertUnreachable } from '@interacta-shared/util';

@Injectable({
    providedIn: 'root',
})
export class MentionItemRenderingService {
    private readonly domSanitizer = inject(DomSanitizer);

    renderItem(item: MentionItemWrapper): HTMLButtonElement | HTMLDivElement {
        if (isMentionItemPlaceholder(item)) {
            switch (item.type) {
                case 'loading':
                    return renderLoadingItem();
                default:
                    assertUnreachable(item.type);
            }
        }
        if (isMentionDataMember(item.data)) {
            return renderMemberItem(item.data, this.domSanitizer);
        } else {
            return renderHashtagItem(item.data, this.domSanitizer);
        }
    }
}
