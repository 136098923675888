import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { InteractaIcon } from '@interacta-shared/ui-icons';

@Component({
    selector: 'interacta-input-number-localized',
    template: `
        <interacta-input-number-v2
            [alternativeText]="alternativeText"
            [control]="control"
            [decimalDigits]="decimalDigits ?? 0"
            [decimalLocale]="decimalLocale"
            [hideSpinners]="hideSpinners"
            [icon]="icon"
            [iconClickable]="iconClickable"
            [iconColor]="iconColor"
            [iconFocusableWithTab]="iconFocusableWithTab"
            [iconPosition]="iconPosition"
            [isReadonly]="isReadonly"
            [label]="label"
            [maxValue]="maxValue"
            [minValue]="minValue"
            [textAlign]="textAlign"
            (blurEmitter)="blurEmitter.emit($event)"
            (iconClicked)="iconClicked.emit()"
        />
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputNumberLocalizedComponent {
    @Input({ required: true }) control!: UntypedFormControl;
    @Input() isReadonly = false;
    @Input() label?: string;
    @Input() decimalDigits?: number;
    @Input() hideSpinners = true;
    @Input() textAlign: 'center' | 'left' = 'left';
    @Input() minValue?: number;
    @Input() maxValue?: number;
    @Input() icon?: InteractaIcon;
    @Input() iconClickable = true;
    @Input() iconFocusableWithTab = false;
    @Input() iconPosition?: 'right' | 'left';
    @Input() iconColor: 'primary' | 'gray' | 'black' = 'primary';
    @Input() alternativeText = '';
    @Output() iconClicked = new EventEmitter<void>();
    @Output() blurEmitter = new EventEmitter<void>();

    decimalLocale: string;

    constructor(configService: ConfigurationService) {
        this.decimalLocale = configService.getCurrentLanguage().code;
    }
}
