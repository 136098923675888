<div
    *ngIf="{
        isVideo:
            attachment.isMediaVideo &&
            canPlayMedia &&
            attachment.type === AttachmentStorageType.STORAGE,
        isIframe: attachment.isEmbeddable,
        isImage:
            attachment.isMediaImage && !attachment.temporaryContentEmbedLink,
        isAudio:
            attachment.isMediaAudio &&
            canPlayMedia &&
            attachment.type === AttachmentStorageType.STORAGE,
        isIcon:
            !attachment.isEmbeddable &&
            !attachment.isMediaVideo &&
            !attachment.isMediaImage &&
            !attachment.isMediaAudio &&
            attachment.iconPath,
        cantPlayVideoOrAudio:
            (attachment.isMediaVideo || attachment.isMediaAudio) &&
            !canPlayMedia
    } as $"
    #container
    class="flex h-full w-full items-center justify-center"
    (click)="backdrop.emit()"
>
    <ng-container *ngIf="showAttachment">
        <!--
             TODO IISP-5686 remove !attachment.drive
         -->

        <ng-container *ngIf="$.isImage">
            <interacta-image
                *ngIf="attachment.temporaryContentViewLink; else loader"
                #image
                class="absolute"
                classes="w-full h-full"
                [alt]="attachment.name"
                [ngClass]="{
                    'opacity-0': !imgLoaded
                }"
                [src]="attachment.temporaryContentViewLink"
                [style.height.px]="imgSize.height !== 0 ? imgSize.height : ''"
                [style.width.px]="imgSize.width !== 0 ? imgSize.width : ''"
                (click)="$event.stopImmediatePropagation()"
                (imgLoad)="onImgLoad($event)"
            ></interacta-image>
        </ng-container>

        <ng-container *ngIf="$.isIframe">
            <iframe
                *ngIf="attachment.temporaryContentEmbedLink; else loader"
                class="px-84 bg-surface-A-reverse h-full w-full"
                title="documentFrame"
                [src]="
                    attachment.temporaryContentEmbedLink
                        | bypassSecurityTrust: 'resource-url'
                "
                (click)="$event.stopImmediatePropagation()"
            ></iframe>
        </ng-container>

        <ng-container *ngIf="$.isVideo">
            <video
                *ngIf="attachment.temporaryContentViewLink; else loader"
                #video
                class="h-full w-full"
                controls
                [autoplay]="autoplay"
                [currentTime]="goToAudioVideoFrame ?? 0"
                [muted]="true"
                [src]="attachment.temporaryContentViewLink"
                (click)="$event.stopImmediatePropagation()"
                (error)="onVideoError()"
                (volumechange)="onVideoVolumeChange($event)"
            ></video>
        </ng-container>

        <audio
            *ngIf="$.isAudio"
            class="mx-auto"
            controls
            [currentTime]="goToAudioVideoFrame ?? 0"
            [src]="attachment.temporaryContentViewLink"
            (click)="$event.stopImmediatePropagation()"
            (error)="onAudioError()"
        ></audio>

        <interacta-image
            *ngIf="$.isIcon"
            alt="file icon"
            [src]="attachment.iconPath"
            (click)="$event.stopImmediatePropagation()"
        ></interacta-image>

        <interacta-image
            *ngIf="$.cantPlayVideoOrAudio"
            [alt]="'LIGHTBOX.MEDIA_CANT_PLAY' | translate"
            [src]="attachment | apply: srcFallback"
            (click)="$event.stopImmediatePropagation()"
        ></interacta-image>
    </ng-container>

    <ng-template #loader>
        <interacta-loader [size]="'large'"></interacta-loader>
    </ng-template>
</div>
