import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    IWorkspace,
    IWorkspaceCapabilities,
    WorkspaceDeserialize,
} from '../models/workspace.model';

@Injectable({
    providedIn: 'root',
})
export class WorkspaceService {
    private baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/settings`;
    private baseUrlAdmin = `${inject(ENVIRONMENT).apiBasePath.common}/admin/data`;

    constructor(private http: HttpClient) {}

    public getWorkspaceCapabilities(
        workspaceId: number,
    ): Observable<IWorkspaceCapabilities> {
        return this.http
            .get(
                `${this.baseUrlAdmin}/workspaces/${workspaceId}/admin-capabilities`,
            )
            .pipe(
                map((res: any) =>
                    WorkspaceDeserialize.workspaceCapabilities(res),
                ),
            );
    }

    public getWorkspaceDetails(workspaceId: number): Observable<IWorkspace> {
        return this.http
            .get(`${this.baseUrl}/workspaces/${workspaceId}/details`)
            .pipe(
                map((result: any) =>
                    WorkspaceDeserialize.workspaceInfo(result),
                ),
            );
    }
}
