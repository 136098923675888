<interacta-dialog
    [isOpen]="isDialogOpen"
    [size]="size"
    (closing)="closeDialog.emit()"
>
    <h6 class="typo-t6 flex" title>{{ title }}</h6>

    <div class="flex min-h-0 w-full flex-col" content>
        <div
            *ngIf="tags.length || members.length"
            class="h-500 mt-16 flex flex-col gap-y-8 overflow-y-auto"
        >
            <div
                *ngFor="let tag of tags; let last = last; trackBy: trackById"
                class="flex flex-col gap-y-8"
            >
                <interacta-group-tag
                    [excludedGroupsCount]="
                        {
                            tagId: tag.id,
                            tagExcludedGroups
                        } | apply: getExcludedGroupCountByTagId
                    "
                    [label]="tag.name"
                >
                </interacta-group-tag>
                <interacta-separator
                    [thickness]="last ? 'strong' : 'soft'"
                ></interacta-separator>
            </div>
            <div
                *ngFor="
                    let member of members;
                    let last = last;
                    trackBy: trackById
                "
                class="flex flex-col space-y-8"
            >
                <interacta-member
                    [member]="member"
                    [memberClickBehavior]="memberClickBehavior"
                    [showInfo]="true"
                ></interacta-member>
                <interacta-separator
                    *ngIf="!last"
                    thickness="soft"
                ></interacta-separator>
            </div>
        </div>

        <interacta-no-result
            *ngIf="tags.length === 0 && members.length === 0"
            class="align-middle"
            imageName="no-results"
            [subtitle]="'UI.NO_RESULT_HINT' | translate"
            [title]="'DETAIL.NO_USERS_RESULT_1' | translate"
        ></interacta-no-result>
    </div>
</interacta-dialog>
