import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'interacta-dialog-description',
    template: `
        <div class="text-text-mid-contrast typo-sm mb-8">
            <ng-content />
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class DialogDescriptionComponent {}
