<div
    class="flex h-36 w-max items-center space-x-8 rounded-3xl"
    [ngClass]="{
        'bg-surface-B': appearance === 'fill' && bgColor === 'surface-B',
        'bg-surface-A': appearance !== 'ghost' && bgColor === 'surface-A',
        'px-16 py-8': size === 'regular',
        'px-12 py-4': size === 'small'
    }"
>
    <interacta-icon
        *ngIf="icon"
        class="text-text-primary"
        [icon]="icon"
        [ngClass]="{
            'h-24 w-24': size === 'regular',
            'w-18 h-18': size === 'small'
        }"
    ></interacta-icon>
    <span
        [ngClass]="{
            'typo-sm': size === 'regular',
            'typo-xs': size === 'small'
        }"
    >
        {{ label }}
    </span>
</div>
