import { Pipe, PipeTransform } from '@angular/core';
import { Member } from '@modules/core/models/member/member.model';
import { getMemberFullname } from '@modules/core/models/member/member.utils';

@Pipe({
    name: 'memberFullname',
})
export class MemberFullnamePipe implements PipeTransform {
    transform(value: Member | undefined): string {
        return value ? getMemberFullname(value) : '';
    }
}
