import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import {
    PaginatedList,
    mapArrayById,
    toPaginatedList,
} from '@interacta-shared/util';
import {
    AdminV2CommunityRolesAssociationMap,
    AdminV2MemberRoleAssociation,
    AdminV2MemberRolesAssociations,
    AdminV2RolesMembersAssociationsUpdate,
} from '@modules/admin-v2-roles/models/admin-v2-roles-associations.model';
import {
    toAdminV2MemberRoleAssociation,
    toAdminV2MemberRoleAssociationMap,
    toAdminV2MemberRolesAssociations,
    toAdminV2MemberRolesAssociationsPaginatedItem,
    toAdminV2Role,
} from '@modules/admin-v2-roles/models/admin-v2-roles.deserialize';
import { AdminV2Role } from '@modules/admin-v2-roles/models/admin-v2-roles.model';
import { AdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { AdminV2OccToken } from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import { Member } from '@modules/core/models/member/member.model';
import {
    extractGroupsFromMembers,
    extractUsersFromMembers,
} from '@modules/core/models/member/member.utils';
import { Observable, map } from 'rxjs';
import {
    AdminV2CommunityRolesFilters,
    AdminV2CommunityRolesSuggestions,
} from '../models/admin-v2-community-roles.model';
import { toAdminV2CommunityRolesSuggestions } from '../models/admin-v2-community.deserialize';
import { AdminV2Community } from '../models/admin-v2-community.model';
import { fromAdminV2CommunityRolesFilters } from '../models/admin-v2-community.serialize';

@Injectable({ providedIn: 'root' })
export class AdminV2CommunityRolesService {
    private readonly commonApiBasePath = inject(ENVIRONMENT).apiBasePath.common;
    private readonly baseUrlManage = `${this.commonApiBasePath}/admin/manage/communities`;
    private readonly baseUrlDataV3 = `${this.commonApiBasePath}/internal/v3/admin/data/communities`;

    private readonly http = inject(HttpClient);

    // Raggruppa per PERSONE
    getOperationalMemberRolesList(
        filters: AdminV2CommunityRolesFilters,
        paginatedFilers: AdminV2PaginatedFilters,
    ): Observable<PaginatedList<AdminV2MemberRolesAssociations>> {
        return this.http
            .post<any>(
                `${this.baseUrlDataV3}/${filters.communityId}/members`,
                fromAdminV2CommunityRolesFilters(filters, paginatedFilers),
            )
            .pipe(
                map((res) =>
                    toPaginatedList(
                        res,
                        toAdminV2MemberRolesAssociationsPaginatedItem,
                    ),
                ),
            );
    }

    // Raggruppa per RUOLI
    getOperationalRolesList(
        communityId: AdminV2Community['id'],
    ): Observable<AdminV2Role[]> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${communityId}/operational-roles`)
            .pipe(map((res) => res.roles.map(toAdminV2Role)));
    }

    getOperationalMembersByRoleList(
        filters: AdminV2CommunityRolesFilters,
        paginatedFilers: AdminV2PaginatedFilters,
        roleId: AdminV2Role['id'],
    ): Observable<PaginatedList<AdminV2MemberRoleAssociation>> {
        return this.http
            .post<any>(`${this.baseUrlDataV3}/${filters.communityId}/members`, {
                ...fromAdminV2CommunityRolesFilters(filters, paginatedFilers),
                roleIds: [roleId],
            })
            .pipe(
                map((res) =>
                    toPaginatedList(res, (item) =>
                        toAdminV2MemberRoleAssociation(item, roleId),
                    ),
                ),
            );
    }
    getOperationalMembersByRoleIdsList(
        communityId: AdminV2Community['id'],
        pageSize: number,
        roleIds: AdminV2Role['id'][],
    ): Observable<AdminV2CommunityRolesAssociationMap> {
        return this.http
            .post<any>(
                `${this.baseUrlDataV3}/${communityId}/operational-members`,
                {
                    roleIds,
                    pageSize,
                },
            )
            .pipe(
                map((res) =>
                    toAdminV2MemberRoleAssociationMap(
                        res.operationalRoleAssociations,
                    ),
                ),
            );
    }

    editOperationalRolesAssociations(
        communityId: AdminV2Community['id'],
        update: AdminV2RolesMembersAssociationsUpdate,
        occToken: AdminV2OccToken,
    ): Observable<{
        occToken: AdminV2OccToken;
    }> {
        return this.http
            .put<any>(
                `${this.baseUrlManage}/${communityId}/operational-roles/${occToken}`,
                {
                    addUserOperationalRoleAssociations: update.toAdd.userRole,
                    addGroupOperationalRoleAssociations: update.toAdd.groupRole,
                    removeOperationalRoleAssociationIds: update.toRemove,
                    removeOperationalRoleId: update.removeOperationalRoleId,
                },
            )
            .pipe(
                map((res) => ({
                    occToken: res.nextOccToken,
                })),
            );
    }

    getOperationalRolesSuggestions(
        communityId: AdminV2Community['id'],
    ): Observable<AdminV2CommunityRolesSuggestions> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${communityId}/roles-suggestions`)
            .pipe(map((res) => toAdminV2CommunityRolesSuggestions(res)));
    }

    getAdminRolesList(
        communityId: AdminV2Community['id'],
    ): Observable<AdminV2MemberRolesAssociations[]> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${communityId}/managers`)
            .pipe(
                map((res) =>
                    toAdminV2MemberRolesAssociations(res.adminMembers),
                ),
            );
    }

    editAdminRoles(
        communityId: AdminV2Community['id'],
        membersToAdd: Member[],
        membersToRemove: Member[],
        occToken: AdminV2OccToken,
    ): Observable<number> {
        const update = {
            addUserIds:
                mapArrayById(extractUsersFromMembers(membersToAdd)) ?? [],
            addGroupIds:
                mapArrayById(extractGroupsFromMembers(membersToAdd)) ?? [],
            removeUserIds:
                mapArrayById(extractUsersFromMembers(membersToRemove)) ?? [],
            removeGroupIds:
                mapArrayById(extractGroupsFromMembers(membersToRemove)) ?? [],
        };

        return this.http
            .put<{
                nextOccToken: AdminV2OccToken;
            }>(
                `${this.baseUrlManage}/${communityId}/managers/${occToken}`,
                update,
            )
            .pipe(map((res) => res.nextOccToken));
    }
}
