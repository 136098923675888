import { toPaginatedList } from '@interacta-shared/util';
import { toAdminV2Community } from '@modules/admin-v2-community/models/admin-v2-community.deserialize';
import { AdminV2Community } from '@modules/admin-v2-community/models/admin-v2-community.model';
import { toAdminV2Group } from '@modules/admin-v2-group/models/admin-v2-group.deserialize';
import { toAdminV2MemberItem } from '@modules/admin-v2-shared/models/admin-v2-member.deserialize';
import { i18nStringToV2 } from '@modules/admin-v2-shared/utils/admin-v2-i18n';
import { toAdminV2Workspace } from '@modules/admin-v2-workspace/models/admin-v2-workspace.deserialize';
import { toWorkflowDefinition } from '@modules/communities/models/workflow/workflow.deserialize';
import { Member } from '@modules/core/models/member/member.model';
import { wrapMember } from '@modules/core/models/member/member.utils';
import {
    adminCapabilities,
    operationalCapabilities,
} from '../utils/admin-v2-roles.utils';
import {
    AdminV2CommunityRolesAssociationMap,
    AdminV2MemberRoleAssociation,
    AdminV2MemberRolesAssociations,
    AdminV2RoleAssociation,
} from './admin-v2-roles-associations.model';
import {
    AdminV2AdminRoleInfo,
    AdminV2CapabilityRow,
    AdminV2OperationalRoleInfo,
    AdminV2Role,
    AdminV2RoleWorkflow,
    AdminV2RolesTable,
    AdminV2RolesType,
} from './admin-v2-roles.model';

export const toAdminV2Role = (record: any): AdminV2Role => ({
    id: record.id,
    name: record.name,
    description: record.description,
    deleted: record.deleted,
    standard: record.standard,
    capabilities: record.capabilities,
});

export const toAdminV2CapabilitiesTable = (
    record: any,
    config: AdminV2RolesType,
): AdminV2RolesTable => {
    const cols: AdminV2Role[] = [];
    const rows: AdminV2CapabilityRow[] = (
        config === 'admin' ? adminCapabilities : operationalCapabilities
    ).map((c) => ({
        code: c.code,
        category: c.category,
        roles: [],
    }));
    for (const role of record) {
        cols.push(toAdminV2Role(role));
        for (const row of rows) {
            row.roles.push({
                id: role.id,
                value: role.capabilities[row.code],
            });
        }
    }

    return { cols, rows };
};

export const toAdminV2OperationalRoleInfo = (
    record: any,
): AdminV2OperationalRoleInfo => {
    type CommunitiesMap = { [key: number]: AdminV2Community };
    const communitiesMap: CommunitiesMap = record.communities.reduce(
        (res: CommunitiesMap, item: any) => ({
            ...res,
            [item.id]: toAdminV2Community(item),
        }),
        {},
    );

    const communities: AdminV2Community[] = [
        ...new Set(
            [
                ...record.roleAssociations,
                ...record.postCapabilityExtensions,
                ...record.postFieldCommunities,
                ...record.ackFieldCommunities,
            ].map((item) => item.communityId),
        ),
    ].map((item) => communitiesMap[item]);

    const workflows: AdminV2RoleWorkflow[] = (
        [
            ...record.screenFieldCommunities,
            ...record.communityWorkflowAssociations,
        ] as { workflow: any; communityId: AdminV2Community['id'] }[]
    )
        .reduce<{ workflow: any; communityIds: number[] }[]>((res, item) => {
            const idx = res.findIndex(
                ({ workflow }) => workflow.id === item.workflow.id,
            );
            if (idx > -1) {
                if (!res[idx].communityIds.includes(item.communityId)) {
                    res[idx].communityIds.push(item.communityId);
                }
            } else {
                res.push({
                    workflow: item.workflow,
                    communityIds: [item.communityId],
                });
            }
            return res;
        }, [])
        .map((item) => ({
            workflow: toWorkflowDefinition(item.workflow),
            communities: item.communityIds.map((id) => communitiesMap[id]),
        }));

    const surveys: AdminV2Community[] = record.surveyCommunities.map(
        (item: any) => communitiesMap[item.communityId],
    );

    const feedback: AdminV2Community[] = record.feedbackCommunities.map(
        (item: any) => communitiesMap[item.communityId],
    );

    return {
        type: 'operational',
        communities,
        workflows,
        surveys,
        feedback,
    };
};

export const toAdminV2AdminRoleInfo = (record: any): AdminV2AdminRoleInfo => ({
    type: 'admin',
    workspaces: record.workspaces.map(toAdminV2Workspace),
});

const toAdminV2RoleAssociation = (record: any): AdminV2RoleAssociation => ({
    id: record.role.id,
    name: i18nStringToV2(record.role.name),
    user: !!record.user,
    groups: record.group ? [toAdminV2Group(record.group)] : [],
    capabilities: record.role.capabilities,
});

export const mergeRoleWithSameId = (
    recordRoles: any,
): AdminV2RoleAssociation[] =>
    [...(recordRoles || [])].reduce<AdminV2RoleAssociation[]>(
        (roles, record) => {
            const role = toAdminV2RoleAssociation(record);
            const index = roles.findIndex((item) => role.id === item.id);
            if (index > -1) {
                roles[index] = {
                    ...roles[index],
                    user: roles[index].user || role.user,
                    groups: [...(roles[index].groups || []), ...role.groups],
                };
            } else {
                roles.push(role);
            }
            return roles;
        },
        [],
    );

export const toAdminV2MemberRolesAssociations = (
    record: any,
    currentMember?: Member,
): AdminV2MemberRolesAssociations[] => {
    const membersRoles: {
        [key: string]: AdminV2MemberRolesAssociations;
    } = {};

    record.forEach((association: any) => {
        const member = currentMember
            ? currentMember
            : toAdminV2MemberItem(
                  wrapMember(
                      association.user ? association.user : association.group,
                  ),
              );

        if (!membersRoles[member.id]) {
            membersRoles[member.id] = {
                member,
                roles: [],
                associationIds: [],
            };
        }
        membersRoles[member.id].roles.push({
            role: toAdminV2Role(association.role),
            associationId: association.roleAssociationId,
        });
        membersRoles[member.id].associationIds.push(
            association.roleAssociationId,
        );
    });
    return Object.values(membersRoles);
};

export const toAdminV2MemberRolesAssociationsPaginatedItem = (
    record: any,
): AdminV2MemberRolesAssociations => {
    const member = toAdminV2MemberItem(
        wrapMember(record.user ? record.user : record.group),
    );
    const association = toAdminV2MemberRolesAssociations(record.roles, member);
    return association[0];
};

export const toAdminV2MemberRoleAssociation = (
    record: any,
    roleId: AdminV2Role['id'],
): AdminV2MemberRoleAssociation => ({
    member: toAdminV2MemberItem(
        wrapMember(record.user ? record.user : record.group),
    ),
    associationId:
        record.roles.find((r: any) => r.role.id === roleId)
            ?.roleAssociationId ?? null,
});

export const toAdminV2MemberRoleAssociationMap = (
    record: any,
): AdminV2CommunityRolesAssociationMap => {
    const associations: AdminV2CommunityRolesAssociationMap = {};
    for (const roleAssociations of record) {
        associations[roleAssociations.items[0].role.id] = toPaginatedList(
            roleAssociations,
            (association) => ({
                member: toAdminV2MemberItem(association),
                associationId: association.roleAssociationId,
            }),
        );
    }
    return associations;
};
