import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
    AsyncPipe,
    NgClass,
    NgFor,
    NgIf,
    NgTemplateOutlet,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { IconComponent } from '@interacta-shared/ui-icons';
import {
    EMPTY_SEARCH_VALUE,
    Index,
    PaginatedList,
    emptyPaginatedList,
    getNextPageToken,
    isDefined,
    mapArrayById,
    paginatedListFromArray,
    unique,
    uuid,
} from '@interacta-shared/util';
import { TranslateModule } from '@ngx-translate/core';
import produce from 'immer';
import { ReplaySubject, Subject, concat, of } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators';
import {
    ArrowKeysItemDirective,
    ArrowKeysListDirective,
} from '../../directives/arrow-keys-list.directive';
import { ScrollTrackerDirective } from '../../directives/scroll-tracker.directive';
import { InputSelectSearchEvent, ScrollTrackerEvent, Size } from '../../model';
import { ApplyPipe } from '../../pipes/apply.pipe';
import { InputStatePipe } from '../../pipes/input-state.pipe';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { LoadMoreComponent } from '../load-more/load-more.component';
import { MenuDecoratorComponent } from '../menu-decorator/menu-decorator.component';
import { MenuComponent } from '../menu/menu.component';

function toArray<V>(value: V | V[] | null): V[] | null {
    return value ? (Array.isArray(value) ? value : [value]) : null;
}

@Component({
    selector: 'interacta-input-generic-select',
    templateUrl: './input-generic-select.component.html',
    styles: [
        `
            .scrollable-list.cdk-drop-list-dragging
                .draggable-item:not(.cdk-drag-placeholder) {
                transition: transform 400ms cubic-bezier(0, 0, 0.2, 1);
            }

            .scrollable-list.cdk-drop-list-dragging {
                cursor: grabbing !important;
            }

            .scrollable-list.cdk-drop-list-dragging
                > .draggable-list-placeholder {
                opacity: 0;
            }

            .cdk-drag-placeholder {
                opacity: 0;
            }

            .cdk-drag-animating {
                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        CdkOverlayOrigin,
        NgClass,
        CdkDropList,
        NgFor,
        NgTemplateOutlet,
        IconButtonComponent,
        CdkDrag,
        IconComponent,
        ReactiveFormsModule,
        MenuComponent,
        MenuDecoratorComponent,
        ScrollTrackerDirective,
        ArrowKeysListDirective,
        ArrowKeysItemDirective,
        CheckboxComponent,
        VirtualScrollerModule,
        LoadMoreComponent,
        AsyncPipe,
        TranslateModule,
        InputStatePipe,
        ApplyPipe,
    ],
})
export class InputGenericSelectComponent<T extends { id: Index }>
    implements OnInit, OnChanges, OnDestroy
{
    @Input({ required: true }) control!: FormControl<T | T[] | null>;
    @Input({ required: true }) items: PaginatedList<T> | T[] | null = [];
    @Input() searchItemTemplate!: TemplateRef<any>;
    @Input() selectedItemTemplate!: TemplateRef<any>;
    @Input() searchPlaceholderTemplate?: TemplateRef<any>;
    @Input() label?: string;
    @Input() isReadonly = false;
    @Input() showChip = true;
    @Input() chipSize: Extract<Size, 'regular' | 'small'> = 'regular';
    @Input() noResultLabel?: string;
    @Input() maxSelectableItems = Infinity;
    @Input() enableVirtualScroll = false;
    @Input() showSearchPlaceholders = false;
    @Input() lockedIds: Index[] = [];
    @Input() enableDragAndDrop = false;

    @Output() search = new EventEmitter<InputSelectSearchEvent>();
    @Output() itemListOpened = new EventEmitter<boolean>();
    @Output() itemsSelected = new EventEmitter<T[]>();

    value$ = new ReplaySubject<T[] | null>(1);

    inputId = uuid();
    isOpen = false;
    isLimitReached = false;
    currentIndex = 0;
    _items: PaginatedList<T> = emptyPaginatedList();
    textSearchControl = new FormControl('', { nonNullable: true });
    selectedItems: T[] = [];
    displayedItems: T[] = [];
    EMPTY_SEARCH_VALUE = EMPTY_SEARCH_VALUE;
    isGrabbing = false;
    readonly VIRTUAL_SCROLL_THRESHOLD = 15;

    private changeControl$ = new Subject<void>();
    private destroy$ = new Subject<void>();

    @ViewChild('input') inputRef?: ElementRef<HTMLInputElement>;
    @ViewChild('scroll') scrollRef?: ElementRef<HTMLElement>;

    constructor(
        private cdr: ChangeDetectorRef,
        public elementRef: ElementRef,
    ) {}

    ngOnInit(): void {
        this.value$.pipe(takeUntil(this.destroy$)).subscribe((controlValue) => {
            if (controlValue == null) {
                this.isLimitReached = false;
                this.clearSelection();
            } else {
                this.selectedItems = controlValue ?? [];
                this.isLimitReached = this.getIsLimitReached(
                    this.selectedItems,
                );
                if (
                    this.maxSelectableItems === 1 &&
                    this.selectedItems.length
                ) {
                    const index = this._items.list.findIndex((i) =>
                        this.selectedItems.some(({ id }) => id === i.id),
                    );
                    this.currentIndex = index === -1 ? 0 : index;
                }
            }
            setTimeout(() => this.cdr.markForCheck());
        });

        this.textSearchControl.valueChanges
            .pipe(
                debounceTime(300),
                map((text) => (isDefined(text) ? text : '')),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe((text: string) => {
                this.scrollRef?.nativeElement.scrollTo(0, 0);
                this.currentIndex = 0;
                this.search.emit({
                    text,
                    nextPageToken: null,
                });
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['items'] && this.items) {
            this._items = Array.isArray(this.items)
                ? paginatedListFromArray(this.items)
                : this.items;
            this.cdr.markForCheck();
        }

        if (changes['control']) {
            this.changeControl$.next();
            concat(of(this.control.value), this.control.valueChanges)
                .pipe(
                    map(toArray),
                    takeUntil(this.changeControl$),
                    takeUntil(this.destroy$),
                )
                .subscribe((val) => this.value$.next(val));
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    isSelected($: { selectedItems: T[]; item: T }): boolean {
        return $.selectedItems.some(({ id }) => id === $.item.id);
    }

    onItemDrop(change: CdkDragDrop<T>): void {
        if (this.value$) {
            of(null)
                .pipe(
                    withLatestFrom(this.value$),
                    map(([_, value]) => value),
                )
                .subscribe((value) => {
                    const reorderedItems = produce(value, (draft) => {
                        if (draft) {
                            const [deleted] = draft.splice(
                                change.previousIndex,
                                1,
                            );
                            draft.splice(change.currentIndex, 0, deleted);
                        }
                    });
                    this.control.setValue(reorderedItems);
                });
        }
    }

    trackItem(_idx: number, item: T): number | string {
        return item.id;
    }

    removeItemByChip(item: T): void {
        this.changeSelection(false, item);
        this.updateControlValue(this.selectedItems);
    }

    changeSelection(value: boolean, item: T | undefined): void {
        if (!item) return;
        if (value) {
            if (this.maxSelectableItems === 1) {
                this.updateSelectedItems([item]);
            } else {
                this.updateSelectedItems(
                    this.selectedItems.some(({ id }) => id === item.id)
                        ? this.selectedItems.filter(({ id }) => id !== item.id)
                        : [...this.selectedItems, item],
                );
            }
        } else {
            this.updateSelectedItems(
                this.selectedItems.filter(({ id }) => id !== item.id),
            );
        }
    }

    clearSelection(): void {
        this.currentIndex = 0;
        this.textSearchControl.markAsDirty();
        this.resetControlValue();
        this.updateSelectedItems([]);
    }

    onScroll(event: ScrollTrackerEvent): void {
        if (event?.thresholdReached) {
            this.doSearch();
        }
    }

    inputFocus(): void {
        this.isOpen = true;
    }

    doSearch(): void {
        if (
            getNextPageToken(this._items.nextPageToken) &&
            !this._items.isFetching
        ) {
            this.search.emit({
                text: this.textSearchControl.value,
                nextPageToken: getNextPageToken(this._items.nextPageToken),
            });
        }
    }

    openMenu(event: MouseEvent): void {
        if (!this.isOpen) {
            this.isOpen = true;
            event.stopPropagation();
            setTimeout(() => this.focus());
            this.itemListOpened.emit(true);
        }
    }

    closeMenu(): void {
        this.isOpen = false;
        if (this.selectedItems.length === 0) {
            this.currentIndex = 0;
        }
        this.resetControlValue();
        this.updateControlValue(this.selectedItems);
        this.itemListOpened.emit(false);
    }

    private resetControlValue(): void {
        this.textSearchControl.setValue('');
    }

    private focus(): void {
        this.inputRef?.nativeElement.focus();
    }

    private updateSelectedItems(selectedItems: T[]): void {
        this.selectedItems = selectedItems;

        if (this.maxSelectableItems === 1) {
            this.closeMenu();
        }
    }

    private updateControlValue(selectedItems: T[]) {
        const selectedIdsSet = new Set(mapArrayById(selectedItems));

        if (this.value$) {
            of(null)
                .pipe(
                    withLatestFrom(this.value$),
                    map(([_, value]) => value),
                )
                .subscribe((value) => {
                    const controlValueListWithoutUnselected = (
                        value ?? []
                    ).filter((i) => selectedIdsSet.has(i.id));

                    const items = unique(
                        controlValueListWithoutUnselected.concat(selectedItems),
                        (val) => val.id,
                    );
                    this.control.setValue(items);
                    this.itemsSelected.emit(items);
                    this.control.markAsDirty();
                });
        }
    }

    private getIsLimitReached(selectedIds: unknown[]): boolean {
        return selectedIds.length >= this.maxSelectableItems;
    }
}
