import { IBasePost, PostType } from '../../post/models/base-post.model';
import {
    ICustomPost,
    ICustomPostCreate,
    ICustomPostEdit,
} from './custom-post.model';
import {
    IEventPost,
    IEventPostCreate,
    IEventPostEdit,
} from './event-post.model';
import {
    SurveyPost,
    SurveyPostCreate,
    SurveyPostEdit,
} from './survey-post/survey-post.model';

export type GenericPost = ICustomPost | IEventPost | SurveyPost;

export type GenericPostCreate =
    | ICustomPostCreate
    | IEventPostCreate
    | SurveyPostCreate;

export type GenericPostEdit = ICustomPostEdit | IEventPostEdit | SurveyPostEdit;

export function fromBasePost(basePost: IBasePost): GenericPost {
    return basePost.type === PostType.CUSTOM
        ? (basePost as ICustomPost)
        : basePost.type === PostType.EVENT
          ? (basePost as IEventPost)
          : (basePost as SurveyPost);
}
