import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import { AppRouterState } from './app-router.serializer';
import { AppState } from './app.reducer';

export const selectRouterFeature =
    createFeatureSelector<RouterReducerState<AppRouterState>>('router');

export const selectRouteState: Selector<AppState, AppRouterState | undefined> =
    createSelector(selectRouterFeature, (state) => state?.state);
