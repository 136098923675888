<form
    class="space-y-form-field"
    interactaFormFocusErrors
    [formGroup]="formService.form"
>
    @for (
        fields of formService.customFieldsScreenMap | splitFieldsInGroups;
        track trackByFn
    ) {
        <section [ngClass]="{ 'flex gap-x-12': fields.length === 2 }">
            @for (field of fields; track field.configuration.id) {
                @if (
                    customMetadataActivationService.fieldsActivationStatusMap[
                        field.configuration.id
                    ] | async;
                    as activationStatus
                ) {
                    @if (
                        activationStatus[ValidationType.ENABLED] == null ||
                        activationStatus[ValidationType.ENABLED]?.isActive
                    ) {
                        <interacta-form-field-v2
                            class="block"
                            interactaFormFocusErrorsItem
                            @field
                            [control]="
                                $any(
                                    formService.dataFormControls.fieldsMap
                                        .controls[field.configuration.id]
                                )
                            "
                            [description]="
                                {
                                    configuration: field.configuration,
                                    translate
                                } | apply: getCustomFieldFullDescription
                            "
                            [name]="
                                field.configuration.customFieldType ===
                                CustomFieldType.LINK
                                    ? ''
                                    : (field.configuration.label
                                      | required: field.configuration.required)
                            "
                        >
                            <interacta-custom-field-input
                                [availableHashtags]="
                                    postDefinition.activeHashtags
                                "
                                [communityId]="postDefinition.communityId"
                                [control]="
                                    $any(
                                        formService.dataFormControls.fieldsMap
                                            .controls[field.configuration.id]
                                    )
                                "
                                [enableMention]="createMention"
                                [enableTag]="false"
                                [fieldDefinition]="field.configuration"
                                [metadataFieldMode]="
                                    MetadataFieldModeType.SCREEN
                                "
                                [tables]="tables()"
                                (addOrEditQuillTable)="
                                    addOrEditQuillTable($event)
                                "
                                (openDocument)="openDocument($event)"
                            />
                        </interacta-form-field-v2>
                    }
                }
            }
        </section>
    }
</form>
