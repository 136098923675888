import { Palette, Size } from '@interacta-shared/ui';
import { Theme, systemTheme } from '@modules/core/models/theme.model';
import { CommentLocationType } from '@modules/post/models/comment.model';
import { createReducer, on } from '@ngrx/store';
import * as UIActions from './ui.actions';

export interface CommentEditor {
    postId: number;
    verticalAlign: 'center' | 'end';
    overlayPalette: Extract<Palette, 'surface-A' | 'black-not-black-80'>;
    size: Size;
    location: CommentLocationType;
}

export interface NotificationPanel {
    isOpen: boolean;
    onlyUnread: boolean;
}

export interface UIState {
    commentEditor: CommentEditor | null;
    theme: Theme | null;
    notificationPanel: NotificationPanel | null;
}

export const initialState: UIState = {
    commentEditor: null,
    theme: null,
    notificationPanel: null,
};

export const uiReducer = createReducer<UIState>(
    initialState,

    on(UIActions.openCommentFullScreen, (state, props) => ({
        ...state,
        commentEditor: {
            postId: props.postId,
            verticalAlign: props.verticalAlign ?? 'center',
            overlayPalette: props.overlayPalette ?? 'black-not-black-80',
            size: props.size ?? 'regular',
            location: props.location ?? 'detail',
        },
    })),

    on(UIActions.closeCommentFullScreen, (state) => ({
        ...state,
        commentEditor: null,
    })),

    on(UIActions.changeTheme, (state, props) => ({
        ...state,
        theme: props.theme.isSystem ? systemTheme() : props.theme,
    })),

    on(UIActions.toggleNotificationPanel, (state, props) => ({
        ...state,
        notificationPanel: {
            isOpen: props.isOpen,
            onlyUnread: state.notificationPanel?.onlyUnread ?? false,
        },
    })),

    on(UIActions.toggleOnlyUnreadNotification, (state, props) => ({
        ...state,
        notificationPanel: {
            isOpen: state.notificationPanel?.isOpen ?? false,
            onlyUnread: props.onlyUnread,
        },
    })),
);
