import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from '@interacta-shared/util-common';

@Pipe({
    name: 'formatBytes',
})
export class FormatBytesPipe implements PipeTransform {
    transform(size: number, decimals = 2): string {
        return formatBytes(size, decimals);
    }
}
