<div
    *ngIf="{
        isInputFocused: (isInputFocused$ | async) ?? false
    } as $"
    class="relative"
    [attr.id]="componentId"
    [ngClass]="{
        'w-172 h-44': size === 'regular',
        'w-250 h-32': size === 'small'
    }"
>
    <div
        class="absolute z-20 flex flex-col"
        [ngClass]="{
            'rounded-3xl shadow-sm': $.isInputFocused,
            'right-0': openLeft
        }"
    >
        <div
            class="duration-250 flex items-center justify-between rounded-3xl transition"
            [ngClass]="{
                'py-4': size === 'small',
                'w-172': !$.isInputFocused && size === 'regular',
                'w-250': !$.isInputFocused && size === 'small',
                'px-12': !$.isInputFocused,
                'w-420 rounded-b-none px-16': $.isInputFocused,
                'bg-surface-A': bgColor === 'surface-A',
                'bg-surface-B': bgColor === 'surface-B'
            }"
        >
            <input
                #searchInput
                class="typo-sm min-w-0 grow bg-transparent"
                type="text"
                [formControl]="search"
                [ngClass]="{
                    'h-20': $.isInputFocused
                }"
                [placeholder]="placeholder | translate"
                (focus)="inputFocus()"
            />
            <button
                *ngIf="search.value"
                class="shrink-0"
                interacta-icon-button
                type="button"
                [attr.aria-label]="'SEARCH.INPUT_CLEAR' | translate"
                [icon]="'close'"
                [matTooltip]="'SEARCH.INPUT_CLEAR' | translate"
                [size]="size === 'regular' ? 'regular' : 'extra-small'"
                (click)="search.reset()"
            ></button>
            <button
                interacta-icon-button
                type="submit"
                [active]="true"
                [attr.aria-label]="'SHARED.BUTTON.SEARCH' | translate"
                [icon]="'search-left'"
                [matTooltip]="'SHARED.BUTTON.SEARCH' | translate"
                [size]="size === 'regular' ? 'extra-large' : 'extra-small'"
                (click)="openSearch()"
            ></button>
        </div>

        <div
            *ngIf="$.isInputFocused"
            class="typo-sm bg-surface-A flex w-full flex-col rounded-b-3xl px-16 pb-16"
        >
            <interacta-separator [thickness]="'soft'" />

            <ng-content select="[advanced-search]" />
        </div>
    </div>
</div>
