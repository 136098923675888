<div
    class="relative"
    [ngClass]="{
        'w-250 h-172': imageSize === 'regular',
        'w-180 h-128': imageSize === 'small'
    }"
>
    <button
        class="bg-surface-A box-border flex size-full items-center justify-center rounded-lg"
        type="button"
        [attr.aria-disabled]="disabled"
        [disabled]="disabled"
        [ngClass]="{
            'border-divider-300 border p-[7px]': !active,
            'border-border-primary border-2 p-6': active
        }"
        (click)="imgClicked.emit()"
    >
        @if (imageSrc) {
            <interacta-image
                class="size-full"
                [alt]="alt"
                [classes]="'size-full object-contain'"
                [src]="imageSrc"
            />
        } @else {
            <div
                class="bg-surface-100 flex size-full items-center justify-center"
            >
                <span
                    class="text-text"
                    [ngClass]="{
                        'typo-sm': imageSize === 'regular',
                        'typo-xs': imageSize === 'small'
                    }"
                    >{{ 'UI.VISUAL_SELECT.NO_IMAGE' | translate }}</span
                >
            </div>
        }
    </button>
    @if (iconButton && imageSrc) {
        <button
            *ngIf="
                {
                    label: iconButton.label | translate,
                    alt
                } | apply: buildIconLabel as label
            "
            class="absolute right-12 top-12"
            interacta-icon-button
            type="button"
            [attr.aria-label]="label"
            [bgColor]="'surface-B'"
            [icon]="iconButton.icon"
            [matTooltip]="label"
            [size]="'extra-small'"
            (click)="iconClicked.emit()"
        ></button>
    }
</div>
