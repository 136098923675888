import { Delta } from 'quill/core';
import {
    EditableTableData,
    ServerEditableTableData,
    latestVersion,
} from './editable-table.model';

const emptyTable: EditableTableData = {
    version: latestVersion,
    columns: [],
    headerRow: false,
    rows: [],
};

const toEditableTableDataV1 = (
    data: ServerEditableTableData,
): EditableTableData => ({
    ...data,
    version: latestVersion,
    rows: data.rows.map((serverRow) =>
        Object.keys(serverRow).reduce(
            (row, key) => ({
                ...row,
                [key]: new Delta([{ insert: `${serverRow[key]}\n` }]),
            }),
            {} as Record<string, Delta>,
        ),
    ),
});
const toEditableTableDataV2 = (
    data: ServerEditableTableData,
): EditableTableData => ({
    ...data,
    version: latestVersion,
    rows: data.rows.map((serverRow) =>
        Object.keys(serverRow).reduce(
            (row, key) => ({
                ...row,
                [key]: new Delta(JSON.parse(serverRow[key])),
            }),
            {} as Record<string, Delta>,
        ),
    ),
});

const versionSpecificDeserializeFnMap: {
    [key: number]: (data: ServerEditableTableData) => EditableTableData;
} = {
    1: toEditableTableDataV1,
    2: toEditableTableDataV2,
};

export const toEditableTableData = (data: string): EditableTableData => {
    const serverData: ServerEditableTableData = JSON.parse(data);
    const deserializeFn = versionSpecificDeserializeFnMap[serverData.version];
    if (deserializeFn != null) {
        return deserializeFn(serverData);
    } else {
        console.error('EditableTableData version not supported', serverData);
        return emptyTable;
    }
};
