<div
    *ngIf="{
        hiddenCount: members.length - visibleAvatars
    } as $"
    #openMenu="cdkOverlayOrigin"
    cdkOverlayOrigin
    class="z-0 ml-2 flex items-center"
>
    @for (member of members | slice: 0 : visibleAvatars; track member.innerId) {
        @if (!$last || ($last && $.hiddenCount <= 0)) {
            <button
                *ngIf="{
                    selected: { member, selectedMembers } | apply: isSelected
                } as $$"
                class="hoverable-avatar border-surface-A rounded-full border-2"
                type="button"
                [ngClass]="{
                    '-ml-8': !$first,
                    'outline-border-primary outline outline-2': $$.selected
                }"
                [style.z-index]="
                    visibleAvatars - $index + ($$.selected ? visibleAvatars : 0)
                "
                (click)="toggleMember(member)"
            >
                <interacta-member-avatar [member]="member" [size]="'small'">
                </interacta-member-avatar>
            </button>
        }
    }

    <button
        *ngIf="$.hiddenCount && $.hiddenCount >= 1"
        class="w-30 h-30 bg-text-primary border-surface-A box-content rounded-full border-2"
        type="button"
        [attr.aria-label]="'SHOW_MORE' | translate"
        [matTooltip]="'SHOW_MORE' | translate"
        [ngClass]="{
            '-ml-8': visibleAvatars > 0,
            'outline-border-primary outline outline-2': anyHiddenSelected()
        }"
        (click)="isOpen ? closeMenu() : showMenu()"
    >
        <p class="text-text typo-xs-bold">+{{ $.hiddenCount + 1 }}</p>
    </button>

    <interacta-menu
        positionX="after"
        [open]="isOpen"
        [origin]="openMenu"
        (closing)="closeMenu()"
    >
        <interacta-menu-decorator class="block">
            <input
                *ngIf="members.length >= SEARCH_THRESHOLD"
                #searchInput
                class="typo-sm rounded-t-2xl px-16 pt-16"
                type="text"
                [formControl]="searchControl"
            />
            <div
                class="bg-surface-A flex flex-col overflow-y-auto py-10"
                [ngClass]="{
                    'pb-10 pt-4': members.length >= SEARCH_THRESHOLD
                }"
                [style.max-height]="'min(80vh, 14rem)'"
            >
                <interacta-checkbox
                    *ngFor="
                        let member of filteredMembers()
                            | slice: visibleAvatars - 1 : members.length
                    "
                    class="hover:bg-surface-300 px-16 py-6"
                    [checked]="{ member, selectedMembers } | apply: isSelected"
                    [fullWidth]="true"
                    [size]="'small'"
                    (changeCheck)="toggleMember(member)"
                >
                    <interacta-member
                        [fontWeight]="'regular'"
                        [member]="member"
                        [memberClickBehavior]="'none'"
                        [showInfo]="false"
                        [size]="'small'"
                    >
                    </interacta-member>
                </interacta-checkbox>
            </div>
        </interacta-menu-decorator>
    </interacta-menu>
</div>
