import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { PageTokenInfo } from '@interacta-shared/util';
import { Member } from '@modules/core/models/member/member.model';

@Component({
    selector: 'interacta-member-list-chips[members][title][counter]',
    templateUrl: './member-list-chips.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberListChipsComponent {
    @Input() members!: Member[];
    @Input() title!: string;
    @Input() counter!: number;
    @Input() canAdd = false;
    @Input() canDelete = false;
    @Input() openDialog = false;
    @Input() isFetching = false;
    @Input() communityId?: number;
    @Input() nextPageToken?: PageTokenInfo;

    @Output() deleteMember = new EventEmitter<Member>();
    @Output() toggleDialog = new EventEmitter<void>();
    @Output() closeDialog = new EventEmitter<void>();
    @Output() nextPagination = new EventEmitter<void>();
    @Output() selectMember = new EventEmitter<Member>();

    trackByFn(index: number, item: Member): number {
        return item.innerId;
    }
}
