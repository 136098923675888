import { NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';
import { Appearance, Palette, Size } from '../../model';
import { IconButtonComponent } from '../icon-button/icon-button.component';

export interface ChipCount {
    count: number;
    totalCount: number;
}

export interface IconDescriptor {
    icon: InteractaIcon;
    iconClass?: string;
    tooltip?: string;
}

@Component({
    selector: 'interacta-chip',
    templateUrl: './chip.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        IconComponent,
        NgFor,
        IconButtonComponent,
        MatTooltipModule,
    ],
})
export class ChipComponent implements OnChanges {
    @Input({ required: true }) label!: string;
    @Input() iconLeft?: InteractaIcon;
    @Input() iconButtonRight?:
        | InteractaIcon
        | (InteractaIcon | IconDescriptor)[];
    @Input() isReadonly = false;
    @Input() bgColor: Extract<
        Palette,
        'surface-A' | 'surface-B' | 'primary-300'
    > = 'surface-B';
    @Input() color: 'regular' | 'primary' | 'error' = 'regular';
    @Input() countState?: ChipCount;
    @Input() size: Extract<Size, 'regular' | 'small' | 'large'> = 'regular';
    @Input() appearance: Extract<Appearance, 'fill' | 'border'> = 'fill';

    @Output() action = new EventEmitter<InteractaIcon>();

    iconButtonRightArray: IconDescriptor[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['iconButtonRight']) {
            this.iconButtonRightArray = this.iconButtonRight
                ? this.iconButtonRight instanceof Array
                    ? this.iconButtonRight.map((icon) =>
                          icon instanceof Object ? icon : { icon },
                      )
                    : [{ icon: this.iconButtonRight }]
                : [];
        }
    }
}
