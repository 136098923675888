<ng-container
    *ngIf="{
        user: getMemberUser(member),
        group: getMemberGroup(member)
    } as $"
>
    <interacta-avatar
        *ngIf="$.user"
        [matTooltip]="$.user.fullName"
        [matTooltipPosition]="'above'"
        [size]="size"
        [user]="$.user"
    ></interacta-avatar>

    <interacta-group-avatar
        *ngIf="$.group"
        [group]="$.group"
        [matTooltip]="$.group.fullName"
        [matTooltipPosition]="'above'"
        [size]="size"
    ></interacta-group-avatar>
</ng-container>
