@if (tableData) {
    <div *ngIf="!readonly" class="mb-20 flex space-x-16">
        <button
            interacta-button
            type="button"
            [appearance]="'border'"
            [bgColor]="'textColor'"
            [label]="'UI.EDITABLE_TABLE.ADD_ROW' | translate"
            [size]="'regular'"
            (click)="addRow()"
        ></button>
        <button
            interacta-button
            type="button"
            [appearance]="'border'"
            [bgColor]="'textColor'"
            [label]="'UI.EDITABLE_TABLE.ADD_COLUMN' | translate"
            [size]="'regular'"
            (click)="addColumn()"
        ></button>
        <interacta-toggle
            [checked]="tableData.headerRow"
            [label]="'UI.EDITABLE_TABLE.HEADER_ROW' | translate"
            [labelPosition]="'before'"
            (toggle)="tableData.headerRow = $event"
        ></interacta-toggle>
    </div>
    <div>
        <table #myTable>
            <tbody>
                <tr
                    *ngFor="
                        let row of tableData.rows;
                        let rowIndex = index;
                        trackBy: trackByIdx
                    "
                    class="border-border-300 border"
                    [ngClass]="{
                        'bg-surface-100': rowIndex === 0 && tableData.headerRow
                    }"
                >
                    <td
                        *ngFor="
                            let column of tableData.columns;
                            let columnIndex = index;
                            trackBy: trackByIdx
                        "
                        class="border-border-300 max-w-320 min-w-200 relative h-full break-words border px-8 py-8 align-top"
                        (click)="activateCell({ rowIndex, columnIndex })"
                    >
                        @if (
                            readonly ||
                            !(
                                {
                                    newIndex: { rowIndex, columnIndex },
                                    current: activeCell()
                                } | apply: isActiveCellPipe
                            )
                        ) {
                            <interacta-delta-view
                                class="block min-h-28"
                                [delta]="row[column.key]"
                            ></interacta-delta-view>
                        } @else {
                            <quill-editor
                                class="w-full break-normal"
                                classes="ql-comment-editor"
                                format="object"
                                placeholder=""
                                [(ngModel)]="row[column.key]"
                                (onEditorCreated)="focusCellEditor($event)"
                            >
                                <interacta-editable-cell-quill-toolbar
                                    quill-editor-toolbar
                                />
                            </quill-editor>
                            <interacta-editable-cell-menu-button
                                [canDeleteColumn]="tableData.columns.length > 1"
                                [canDeleteRow]="tableData.rows.length > 1"
                                (addColumnLeft)="addColumnLeft(columnIndex)"
                                (addColumnRight)="addColumnRight(columnIndex)"
                                (addRowAbove)="addRowAbove(rowIndex)"
                                (addRowBelow)="addRowBelow(rowIndex)"
                                (deleteColumn)="deleteColumn(columnIndex)"
                                (deleteRow)="deleteRow(rowIndex)"
                            />
                        }
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
}
