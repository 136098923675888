import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Appearance, Shape, Size } from '@interacta-shared/ui';
import { InteractaIcon } from '@interacta-shared/ui-icons';
import { uuid } from '@interacta-shared/util';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { FileUploader } from 'ng2-file-upload';

@Component({
    selector: 'interacta-file-select[uploader]',
    templateUrl: './file-select.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSelectComponent {
    @Input() driveIntegrationEnabled = false;
    @Input() uploader!: FileUploader;
    @Input() label?: string;
    @Input() tooltip?: string;
    @Input() icon?: InteractaIcon = 'paperclip';
    @Input() shape: Shape = 'rectangular';
    @Input() size: Extract<Size, 'large' | 'regular' | 'small'> = 'small';
    @Input() appearance: Appearance = 'fill';
    @Input() acceptedMimeType?: string[];

    @Output() fileSelected = new EventEmitter<File[]>();
    @Output() driveAttachmentSelected = new EventEmitter<IAttachment[]>();

    inputId = `uploader-${uuid()}`;
    fileSelectModeMenuOpen = false;
}
