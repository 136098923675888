<div class="flex flex-col space-y-16">
    <div>
        <span class="typo-sm-bold">{{ title }} ({{ counter }})</span>
        <ng-content select="[subtitle]"></ng-content>
    </div>
    <div class="flex flex-col space-y-8">
        <interacta-chip-member
            *ngFor="let member of members | slice: 0 : 5; trackBy: trackByFn"
            [canDelete]="canDelete"
            [member]="member"
            (deleteMember)="deleteMember.emit($event)"
        ></interacta-chip-member>
    </div>
    <interacta-show-more-toggle
        *ngIf="members.length > 5"
        [arrow]="false"
        (toggle)="toggleDialog.emit()"
    ></interacta-show-more-toggle>
    <interacta-member-picker
        *ngIf="communityId && canAdd"
        [appearance]="'fill'"
        [bgColor]="'surface-A'"
        [communityIds]="[communityId]"
        [label]="'DETAIL.VISIBILITY.ADD_MEMBER_TO_NOTIFY' | translate"
        [selectedMembers]="members"
        [type]="'community_members'"
        (selectMember)="selectMember.emit($event)"
    ></interacta-member-picker>
</div>

<interacta-dialog-members
    *ngIf="openDialog"
    [isDialogOpen]="openDialog"
    [isFetching]="isFetching"
    [memberClickBehavior]="'preview'"
    [memberList]="members"
    [nextPageToken]="nextPageToken"
    [title]="title"
    [totalCount]="counter"
    (closeDialog)="closeDialog.emit()"
    (nextPagination)="nextPagination.emit()"
>
</interacta-dialog-members>
