<interacta-dialog
    [isOpen]="isDialogOpen"
    [size]="size"
    (closing)="closeDialog.emit()"
>
    <div class="typo-t6 flex" title>{{ title }} ({{ totalCount }})</div>
    <interacta-input-text-v2
        *ngIf="totalCount > 20 && enableSearch"
        class="mt-16 shrink-0"
        iconPosition="right"
        search
        [control]="searchMembers"
    ></interacta-input-text-v2>
    <div class="flex min-h-0 w-full flex-col" content>
        <div
            *ngIf="memberList"
            class="h-500 mt-16 flex flex-col space-y-8 overflow-y-auto"
            interactaScrollTracker
            (scrolled)="scrollTracker($event)"
        >
            <div
                *ngFor="
                    let member of memberList;
                    let last = last;
                    trackBy: trackByFn
                "
                class="flex flex-col space-y-8"
            >
                <interacta-member
                    [member]="member"
                    [memberClickBehavior]="memberClickBehavior"
                    [showInfo]="true"
                ></interacta-member>
                <interacta-separator
                    *ngIf="!last"
                    thickness="soft"
                ></interacta-separator>
            </div>
            <interacta-load-more
                *ngIf="nextPageToken && nextPageToken.tag !== 'lastLoading'"
                class="flex justify-center"
                [isLoading]="isFetching"
                [label]="'SHOW_MORE' | translate"
                (click)="newPage()"
            ></interacta-load-more>
            <interacta-no-result
                *ngIf="memberList.length === 0"
                class="align-middle"
                imageName="no-results"
                [subtitle]="'UI.NO_RESULT_HINT' | translate"
                [title]="'DETAIL.NO_USERS_RESULT_1' | translate"
            ></interacta-no-result>
        </div>
    </div>
</interacta-dialog>
