<interacta-dialog
    *ngIf="{
        selectedHashtag: selectedHashtag$ | async,
        totalCount: (totalCount$ | async) ?? 0
    } as $"
    [isOpen]="isOpen"
    [size]="size"
    (closing)="isOpenChange.emit(false)"
>
    <h6 class="typo-t6" title>{{ title }}</h6>
    <div
        *ngIf="showSubtitle && list.nextPageToken.tag !== 'firstLoading'"
        class="typo-sm"
        subtitle
    >
        {{
            'COMMUNITY_DETAIL.HASHTAG_SUBTITLE'
                | translate: { count: $.totalCount }
        }}
    </div>

    <interacta-input-text-v2
        *ngIf="
            $.totalCount > N_MEMBERS_TO_SHOW &&
            (!$.selectedHashtag || isAdminSection)
        "
        class="mt-16 shrink-0"
        iconPosition="right"
        search
        [control]="searchHashtags"
    />

    <div
        *ngIf="
            isAdminSection || (!$.selectedHashtag && list.totalCount !== 1);
            else detail
        "
        class="flex min-h-0 w-full flex-col"
        content
    >
        <div
            class="h-500 mt-16 flex flex-col space-y-8 overflow-y-auto"
            interactaScrollTracker
            (scrolled)="scrollTracker($event)"
        >
            <ng-container
                *ngIf="
                    list.nextPageToken.tag !== 'firstLoading';
                    else loadingBlock
                "
            >
                <div
                    *ngFor="
                        let hashtag of list.list;
                        let last = last;
                        trackBy: trackByFn
                    "
                    class="flex flex-col space-y-8"
                >
                    <div class="flex flex-col">
                        <div
                            *ngIf="!isAdminSection"
                            class="flex items-center gap-x-8"
                        >
                            <button
                                class="typo-medium"
                                type="button"
                                [ngClass]="{
                                    'text-text-mid-contrast line-through':
                                        hashtag.deleted
                                }"
                                (click)="selectHashtag(hashtag)"
                            >
                                #{{ hashtag.name }}
                            </button>
                            <interacta-icon
                                *ngIf="hashtag.followedByMe"
                                class="text-text-primary h-20 w-20"
                                [icon]="'bell'"
                            />
                        </div>
                        <div
                            *ngIf="isAdminSection"
                            class="typo-medium flex"
                            [ngClass]="{
                                'text-text-mid-contrast line-through':
                                    hashtag.deleted
                            }"
                        >
                            #{{ hashtag.name }}
                        </div>

                        <div
                            *ngIf="hashtag.instancesCount"
                            class="typo-sm text-text-mid-contrast"
                        >
                            <span>
                                {{
                                    'COMMUNITY_DETAIL.HASHTAG_USED' | translate
                                }}
                            </span>
                            <span class="typo-sm-bold text-text">
                                {{ hashtag.instancesCount }}
                            </span>
                            <span>
                                {{
                                    ((hashtag.instancesCount === 1
                                        ? 'COMMUNITY_DETAIL.HASHTAG_TIMES_SING'
                                        : 'COMMUNITY_DETAIL.HASHTAG_TIMES_PLUR'
                                    ) | translate) +
                                        (hashtag.deleted
                                            ? ('COMMUNITY_DETAIL.HASHTAG_DELETED'
                                              | translate)
                                            : '')
                                }}
                            </span>
                        </div>
                        <span
                            *ngIf="!hashtag.instancesCount"
                            class="typo-sm text-text-mid-contrast"
                            >{{
                                'COMMUNITY_DETAIL.HASHTAG_NOT_USED' | translate
                            }}</span
                        >
                    </div>
                    <interacta-separator *ngIf="!last" thickness="soft" />
                </div>
            </ng-container>
            <ng-template #loadingBlock>
                <div
                    *ngFor="let _ of 3 | array; let last = last"
                    class="flex flex-col space-y-8"
                >
                    <div
                        class="bg-surface-300 h-44 w-full animate-pulse rounded-md"
                    ></div>
                    <interacta-separator *ngIf="!last" thickness="soft" />
                </div>
            </ng-template>

            <interacta-load-more
                *ngIf="list.nextPageToken.tag === 'regularLoading'"
                class="flex justify-center"
                [isLoading]="list.isFetching"
                [label]="'SHOW_MORE' | translate"
                (click)="newPage()"
            />
            <interacta-no-result
                *ngIf="!list.isFetching && list.list.length === 0"
                class="align-middle"
                [imageName]="'no-results'"
                [title]="'COMMUNITY_DETAIL.HASHTAG_NO_RESULT' | translate"
            />
        </div>
    </div>

    <ng-template #detail>
        <div
            *ngIf="$.selectedHashtag && community; else loader"
            class="typo-t5"
            title
        >
            #{{ $.selectedHashtag.name }}
        </div>

        <!-- content -->
        <div *ngIf="$.selectedHashtag" content>
            <div class="typo-sm mb-16">
                {{
                    $.selectedHashtag.counter
                        | i18nPlural
                            : {
                                  '=1': 'HASHTAG_INFO.COUNT_IN_COMMUNITY_SING',
                                  other: 'HASHTAG_INFO.COUNT_IN_COMMUNITY_PLUR'
                              }
                        | translate
                            : {
                                  count: $.selectedHashtag.counter,
                                  community: community.name
                              }
                }}
            </div>

            <!-- follow/unfollow button -->

            <button
                *ngIf="!$.selectedHashtag.deleted"
                class="mb-16"
                interacta-button
                type="button"
                [active]="$.selectedHashtag.followedByMe ?? false"
                [appearance]="
                    $.selectedHashtag.followedByMe ? 'border' : 'fill'
                "
                [icon]="'bell'"
                [label]="
                    ($.selectedHashtag.followedByMe
                        ? 'HASHTAG_INFO.ALREADY_FOLLOWING'
                        : 'HASHTAG_INFO.FOLLOW'
                    ) | translate
                "
                (click)="
                    $event.stopPropagation(); toggleFollow($.selectedHashtag)
                "
            ></button>

            <interacta-separator class="mb-8 block" [thickness]="'strong'" />

            <!-- search type selection -->

            <p class="typo-sm-bold">
                {{ 'HASHTAG_INFO.SEARCH' | translate }}
            </p>

            <interacta-radio-group
                *ngIf="
                    community.metadata?.attachmentEnabled;
                    else attachmentsNotEnabled
                "
                [formControl]="searchType"
            >
                <interacta-radio-button class="typo-sm mt-12" [value]="'post'">
                    {{ 'HASHTAG_INFO.SEARCH_TYPE.POST' | translate }}
                </interacta-radio-button>
                <interacta-radio-button
                    class="typo-sm mt-12"
                    [value]="'attachment'"
                >
                    {{ 'HASHTAG_INFO.SEARCH_TYPE.ATTACHMENT' | translate }}
                </interacta-radio-button>
                <interacta-radio-button class="typo-sm mt-12" [value]="'both'">
                    {{ 'HASHTAG_INFO.SEARCH_TYPE.BOTH' | translate }}
                </interacta-radio-button>
            </interacta-radio-group>
            <ng-template #attachmentsNotEnabled>
                <interacta-checkbox-group [formControl]="searchType">
                    <interacta-checkbox class="typo-sm mt-12" [value]="'post'">
                        {{ 'HASHTAG_INFO.SEARCH_TYPE.POST' | translate }}
                    </interacta-checkbox>
                </interacta-checkbox-group>
            </ng-template>

            <!-- apply / cancel buttons -->

            <interacta-dialog-buttons>
                <button
                    interacta-dialog-button
                    type="button"
                    [appearance]="'ghost'"
                    [bgColor]="'surface-A'"
                    [label]="
                        'BUTTON.LABEL_BUTTON_CANCEL'
                            | translate: { hashtag: $.selectedHashtag.name }
                    "
                    (click)="close()"
                ></button>
                <button
                    interacta-dialog-button
                    type="button"
                    [appearance]="'fill'"
                    [bgColor]="'primary'"
                    [label]="'BUTTON.LABEL_BUTTON_APPLY' | translate"
                    (click)="apply($.selectedHashtag, searchType.value)"
                ></button>
            </interacta-dialog-buttons>
        </div>

        <!-- loader -->

        <ng-template #loader>
            <div class="h-200 m-auto flex items-center" title>
                <interacta-loader [size]="'large'" />
            </div>
        </ng-template>
    </ng-template>

    <interacta-dialog-buttons *ngIf="canAdd" [showSeparator]="true">
        <button
            interacta-dialog-button
            type="button"
            [active]="true"
            [appearance]="'ghost'"
            [label]="'BUTTON.LABEL_BUTTON_ADD_HASHTAGS' | translate"
            (click)="addHashtags()"
        ></button>
    </interacta-dialog-buttons>
</interacta-dialog>
