import { Injectable } from '@angular/core';
import {
    firstLoading,
    lastLoading,
    PageTokenInfo,
    regularLoading,
} from '@interacta-shared/util';
import { searchTag } from '@modules/communities/models/hashtag/hashtag.utils';
import { CommunitiesService } from '@modules/communities/services/communities.service';
import { SearchMemberOrderType } from '@modules/core/models/user-autocomplete.model';
import { UsersService } from '@modules/core/services/users.service';
import { Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import {
    loadingMentionItemPlaceholder,
    MentionItem,
    MentionItemWrapper,
    toMentionItem,
} from '@modules/mentions';

function buildPageTokenInfo(
    nextPageToken: string | null | undefined,
): PageTokenInfo {
    return nextPageToken != null
        ? regularLoading(nextPageToken)
        : lastLoading();
}

interface ScrollToBottomEvent {
    scrollTop: number;
    callback: (scrollTop: number) => void;
}

function detectScrollToBottom(
    tracker: Element,
    callback: (scrollTop: number) => void,
    endReached$: Subject<ScrollToBottomEvent>,
): void {
    const { scrollTop, scrollHeight, clientHeight } = tracker;
    const delta = clientHeight * 0.3;
    const endReached =
        scrollTop !== 0 && scrollTop + clientHeight >= scrollHeight - delta;

    if (endReached) {
        endReached$.next({
            scrollTop,
            callback,
        });
    }
}
@Injectable({ providedIn: 'root' })
export class MentionService {
    private searchMemberRequests$ = new Subject<{
        searchTerm: string;
        renderList: (_: MentionItemWrapper[]) => void;
        scrollableListClass: string;
        workspaceId: number;
    }>();

    private searchHashtagRequests$ = new Subject<{
        searchTerm: string;
        renderList: (_: MentionItemWrapper[]) => void;
        communityId: number;
    }>();

    private endReached$ = new Subject<ScrollToBottomEvent>();

    constructor(
        usersService: UsersService,
        communitiesService: CommunitiesService,
    ) {
        this.searchMemberRequests$
            .pipe(debounceTime(500))
            .subscribe(
                ({
                    searchTerm,
                    renderList,
                    scrollableListClass,
                    workspaceId,
                }) => {
                    let pageTokenInfo: PageTokenInfo = firstLoading();
                    let renderedItems: MentionItem[] = [];

                    const memberFilter = {
                        workspaceId,
                        orderBy: SearchMemberOrderType.NAME,
                        calculateTotalItemsCount: false,
                        pageSize: 10,
                        name: searchTerm,
                    };

                    usersService
                        .getMembers(memberFilter)
                        .subscribe((matchedMembers) => {
                            if (matchedMembers.list.length > 0) {
                                renderedItems =
                                    matchedMembers.list.map(toMentionItem);

                                renderList(renderedItems);
                                pageTokenInfo = buildPageTokenInfo(
                                    matchedMembers.nextPageToken,
                                );

                                const scrollableListElement =
                                    document.querySelector(
                                        `body > .${scrollableListClass}`,
                                    );

                                const loadNextPage = (scrollTop: number) => {
                                    if (
                                        pageTokenInfo.tag === 'regularLoading'
                                    ) {
                                        usersService
                                            .getMembers({
                                                ...memberFilter,
                                                pageToken:
                                                    pageTokenInfo.nextPageToken,
                                            })
                                            .subscribe((matchedMembers) => {
                                                renderedItems.push(
                                                    ...matchedMembers.list.map(
                                                        toMentionItem,
                                                    ),
                                                );
                                                pageTokenInfo =
                                                    buildPageTokenInfo(
                                                        matchedMembers.nextPageToken,
                                                    );

                                                const selected =
                                                    scrollableListElement?.getElementsByClassName(
                                                        'ql-mention-list-item selected',
                                                    )?.[0];
                                                let selectedIndex:
                                                    | string
                                                    | null = null;
                                                if (selected) {
                                                    selectedIndex =
                                                        selected.getAttribute(
                                                            'data-index',
                                                        );
                                                }

                                                renderList(renderedItems);
                                                if (scrollableListElement) {
                                                    scrollableListElement.scrollTop =
                                                        scrollTop;
                                                    if (selectedIndex) {
                                                        const toBeDeselected:
                                                            | Element
                                                            | undefined =
                                                            scrollableListElement.getElementsByClassName(
                                                                'ql-mention-list-item selected',
                                                            )?.[0];
                                                        toBeDeselected?.classList.remove(
                                                            'selected',
                                                        );

                                                        const toBeSelected =
                                                            scrollableListElement.querySelector(
                                                                `.ql-mention-list-item[data-index="${selectedIndex}"]`,
                                                            );
                                                        toBeSelected?.classList.add(
                                                            'selected',
                                                        );
                                                    }
                                                }
                                            });
                                    }
                                };

                                scrollableListElement?.addEventListener(
                                    'scroll',
                                    () =>
                                        detectScrollToBottom(
                                            scrollableListElement,
                                            loadNextPage,
                                            this.endReached$,
                                        ),
                                );
                            } else {
                                renderList([]);
                            }
                        });
                },
            );

        this.searchHashtagRequests$
            .pipe(debounceTime(500))
            .subscribe(({ searchTerm, renderList, communityId }) => {
                communitiesService
                    .getCommunityHashtags(communityId)
                    .pipe(
                        map((hashtags) => searchTag(searchTerm, hashtags.list)),
                    )
                    .subscribe((matchedHashtag) => {
                        if (matchedHashtag.length > 0) {
                            const renderedItems =
                                matchedHashtag.map(toMentionItem);
                            renderList(renderedItems);
                            //TODO add virtual pagination
                        } else {
                            renderList([]);
                        }
                    });
            });

        this.endReached$
            .pipe(debounceTime(200))
            .subscribe(({ scrollTop, callback }) => callback(scrollTop));
    }

    searchMembers(
        searchTerm: string,
        renderList: (_: MentionItemWrapper[]) => void,
        scrollableListClass: string,
        workspaceId: number,
        startNewSession = true,
    ): void {
        startNewSession && renderList(loadingMentionItemPlaceholder());
        this.searchMemberRequests$.next({
            searchTerm,
            renderList,
            scrollableListClass,
            workspaceId,
        });
    }

    searchHashtags(
        searchTerm: string,
        renderList: (_: MentionItemWrapper[]) => void,
        communityId: number,
    ): void {
        this.searchHashtagRequests$.next({
            searchTerm,
            renderList,
            communityId,
        });
    }
}
