<div class="flex items-center space-x-8">
    <button
        interacta-button-light-box
        type="button"
        [attr.aria-label]="'LIGHTBOX.ZOOM_OUT' | translate"
        [disabled]="zoom <= minZoom"
        [icon]="'minus'"
        [label]="'LIGHTBOX.ZOOM_OUT' | translate"
        [labelPosition]="'above'"
        [size]="'extra-small'"
        (click)="zoomOut.emit()"
    ></button>
    <span class="w-64 text-center">{{ zoom }}%</span>

    <button
        interacta-button-light-box
        type="button"
        [attr.aria-label]="'LIGHTBOX.ZOOM_IN' | translate"
        [disabled]="zoom >= maxZoom"
        [icon]="'plus'"
        [label]="'LIGHTBOX.ZOOM_IN' | translate"
        [labelPosition]="'above'"
        [size]="'extra-small'"
        (click)="zoomIn.emit()"
    ></button>
</div>
