import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    inject,
} from '@angular/core';
import {
    FormControl,
    ReactiveFormsModule,
    UntypedFormControl,
} from '@angular/forms';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import {
    addHours,
    isAfter,
    set,
    startOfToday,
    startOfTomorrow,
    startOfYesterday,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Timezone } from '../../model/timezone.model';
import { DateTimeType } from '../input-date-time/input-date-time.component';
import {
    IInputTimeV2Value,
    InputTimeV2Component,
    StepMode,
} from '../input-time-v2/input-time-v2.component';
import { InputTimezoneComponent } from '../input-timezone/input-timezone.component';

type FastSelectType = 'yesterday' | 'today' | 'tomorrow';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        InputTimeV2Component,
        MatDatepickerModule,
        ReactiveFormsModule,
        InputTimezoneComponent,
    ],
    selector: 'interacta-date-time',
    templateUrl: './date-time.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeComponent implements OnInit, AfterViewInit {
    @Input({ required: true }) control!: FormControl<Date | null>;
    @Input({ required: true }) timezones!: Timezone[];
    @Input({ required: true }) userTimezone!: Timezone | null;
    @Input() timezoneControl = new FormControl<Timezone | null>(null);
    @Input() type: DateTimeType = 'date-time';
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() showFastSelect = true;
    @Input() stepMode: StepMode = 'minute';
    @Input() editTimezone = false;
    @Output() calendarDateClicked = new EventEmitter<Date>();

    @ViewChild(MatCalendar) matCalendar!: MatCalendar<Date>;

    private readonly cdr = inject(ChangeDetectorRef);

    timeControl = new UntypedFormControl();
    now = new Date();
    fastSelect: FastSelectType = 'today';
    selectedDate: Date | null = null;
    _minDate?: Date;
    _maxDate?: Date;

    private destroy$ = new Subject<void>();
    ngOnInit(): void {
        this.setDateRange(this.timezoneControl.value);

        this.timezoneControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((_timezone) => this.setDateRange(_timezone));

        if (!this.control.value) {
            this.control.setValue(this.getInitialDate());
        }

        this.control.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.cdr.markForCheck());

        if (
            this.control.value &&
            this.control.value.getHours() >= 0 &&
            this.control.value.getMinutes() >= 0
        ) {
            this.timeControl.setValue({
                hours: this.control.value.getHours(),
                minutes: this.control.value.getMinutes(),
            });
        }
    }

    ngAfterViewInit(): void {
        this.matCalendar.focusActiveCell();
        this.selectDate(this.control.value, true);
    }

    setDateRange(timezone: Timezone | null): void {
        this._minDate =
            this.minDate && timezone
                ? utcToZonedTime(this.minDate, timezone.zoneId)
                : this.minDate;
        this._maxDate =
            this.maxDate && timezone
                ? utcToZonedTime(this.maxDate, timezone.zoneId)
                : this.maxDate;
        this.control.updateValueAndValidity();
        this.cdr.markForCheck();
    }

    fastSelectClick(type: FastSelectType): void {
        switch (type) {
            case 'yesterday':
                this.selectDate(startOfYesterday());
                break;
            case 'today':
                this.selectDate(startOfToday());
                break;
            case 'tomorrow':
                this.selectDate(startOfTomorrow());
                break;
        }
    }

    selectDate(selectedDate: Date | null, initialize = false): void {
        const previousDate = this.control.value;
        this.control.setValue(selectedDate);
        this.selectedDate = selectedDate;
        if (selectedDate) {
            this.timeControl.setValue({
                hours: previousDate
                    ? previousDate.getHours()
                    : new Date().getHours(),
                minutes: previousDate
                    ? previousDate.getMinutes()
                    : new Date().getMinutes(),
            });
            this.matCalendar.activeDate = selectedDate;
            this.timeControl.enable();
            if (!initialize) {
                this.calendarDateClicked.emit(selectedDate);
            }
        } else {
            this.timeControl.disable();
        }
        this.matCalendar.focusActiveCell();
        this.control.markAsDirty();
        this.cdr.detectChanges();
    }

    updateTime(time: IInputTimeV2Value | null = this.timeControl.value): void {
        const date = set(this.control.value ?? this.now, {
            hours: time?.hours || 0,
            minutes: time?.minutes || 0,
            seconds: 0,
            milliseconds: 0,
        });

        this.control.setValue(date);
        this.control.markAsTouched();
        this.control.markAsDirty();
    }

    private getInitialDate(): Date {
        if (this.minDate && isAfter(this.minDate, this.now)) {
            return this.minDate;
        }
        const initialDate = addHours(this.now, 1);
        initialDate.setMinutes(0, 0, 0);
        return initialDate;
    }
}
