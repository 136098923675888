import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormControl,
    ReactiveFormsModule,
    UntypedFormControl,
} from '@angular/forms';
import {
    VirtualScrollerComponent,
    VirtualScrollerModule,
} from '@iharbeck/ngx-virtual-scroller';
import {
    areEqualObjects,
    EMPTY_SEARCH_VALUE,
    emptyPaginatedList,
    getNextPageToken,
    isDefined,
    mapArrayById,
    mapById,
    PaginatedList,
    paginatedListFromArray,
} from '@interacta-shared/util';
import { TranslateModule } from '@ngx-translate/core';
import { concat, Observable, of, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    startWith,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { ScrollTrackerDirective } from '../../directives';
import {
    InputSelectItem,
    InputSelectSearchEvent,
    ScrollTrackerEvent,
    Size,
} from '../../model';
import { ApplyPipe, InputStatePipe } from '../../pipes';
import { CheckboxGroupComponent } from '../checkbox-group/checkbox-group.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { ChipComponent } from '../chip/chip.component';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { ImageComponent } from '../image/image.component';
import { InputMultipleSelectLabelItemComponent } from '../input-multiple-select-label-item/input-multiple-select-label-item.component';
import { InputSearchComponent } from '../input-search/input-search.component';
import { LoadMoreComponent } from '../load-more/load-more.component';

@Component({
    selector: 'interacta-input-multiple-select',
    templateUrl: './input-multiple-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        InputSearchComponent,
        ScrollTrackerDirective,
        NgFor,
        ChipComponent,
        CheckboxGroupComponent,
        ReactiveFormsModule,
        CheckboxComponent,
        InputMultipleSelectLabelItemComponent,
        VirtualScrollerModule,
        LoadMoreComponent,
        AsyncPipe,
        TranslateModule,
        InputStatePipe,
        ImageComponent,
        ImagePreviewComponent,
        ApplyPipe,
    ],
})
export class InputMultipleSelectComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input({ required: true }) control!: FormControl<
        (number | InputSelectItem)[] | null
    >;
    @Input() items: PaginatedList<InputSelectItem> | InputSelectItem[] = [];
    @Input() isReadonly = false;
    @Input() withChips = true;
    @Input() orientation: 'vertical' | 'horizontal' = 'vertical';
    @Input() itemsSpacing: 'small' | 'medium' | 'large' = 'large';
    @Input() imagesSize?: Extract<Size, 'regular' | 'small'>;

    @Output() search = new EventEmitter<InputSelectSearchEvent>();
    @Output() openFullScreen = new EventEmitter<string>();

    _items: PaginatedList<InputSelectItem> = emptyPaginatedList();
    public readonly VIRTUAL_SCROLL_THRESHOLD = 15;
    selectedItems: InputSelectItem[] = [];
    displayedItems: InputSelectItem[] = [];
    textSearchControl = new UntypedFormControl();

    chipItems$?: Observable<InputSelectItem[]>;
    EMPTY_SEARCH_VALUE = EMPTY_SEARCH_VALUE;
    mode: 'check' | 'visual' = 'check';
    private changeControl$ = new Subject<void>();
    private destroy$ = new Subject<void>();

    @ViewChild(VirtualScrollerComponent)
    virtualScroller?: VirtualScrollerComponent;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.textSearchControl.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                map((text) => (isDefined(text) ? text : '')),
                takeUntil(this.destroy$),
            )
            .subscribe((text: string) => this.filterItems(text));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['items']) {
            this._items = Array.isArray(this.items)
                ? paginatedListFromArray(this.items)
                : this.items;
            this.mode =
                this._items.list.some((i) => !!i.temporaryContentViewLink) &&
                this.imagesSize
                    ? 'visual'
                    : 'check';

            this.detectItemsChanges();

            if (this._items?.totalCount > 5) {
                this.chipItems$ = this.control.valueChanges.pipe(
                    startWith(this.control.value),
                    map(
                        (values) =>
                            this.selectedItems
                                .filter((i) =>
                                    values?.some(
                                        (value) => i.id === mapById(value),
                                    ),
                                )
                                .concat(
                                    this._items.list.filter(
                                        (i) =>
                                            values?.some(
                                                (value) =>
                                                    i.id === mapById(value),
                                            ) &&
                                            !this.selectedItems
                                                .map((e) => e.id)
                                                .includes(i.id),
                                    ),
                                ),
                        tap((_) => this.virtualScroller?.refresh()),
                    ),
                    takeUntil(this.destroy$),
                );
                this.chipItems$.subscribe(
                    (items) => (this.selectedItems = items),
                );
            }
        }

        if (changes['control']) {
            this.changeControl$.next();
            concat(of(this.control.value), this.control.valueChanges)
                .pipe(takeUntil(this.changeControl$), takeUntil(this.destroy$))
                .subscribe((controlValue) => {
                    if (
                        controlValue != null &&
                        controlValue.length &&
                        controlValue.some((v) => typeof v !== 'number')
                    ) {
                        const itemIds =
                            mapArrayById(
                                this._items.list.filter((i) =>
                                    controlValue.some(
                                        (v) => i.id === mapById(v),
                                    ),
                                ),
                            ) ?? null;

                        if (
                            itemIds?.length &&
                            !areEqualObjects(itemIds, this.control.value)
                        ) {
                            /*
                             * Se nessun elemento selezionabile (this._items) corrisponde a controlValue,
                             * evito di settare a null il control per preservare il corretto funzionamento degli input gerarchici
                             */
                            this.control.setValue(itemIds);
                        }
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    trackItem(_idx: number, item: InputSelectItem): number {
        return item.id;
    }

    iconClicked(): void {
        if (this.textSearchControl.value?.length > 0) {
            this.textSearchControl.setValue('');
        }
    }

    onScroll(event: ScrollTrackerEvent): void {
        if (event?.thresholdReached) {
            this.doSearch();
        }
    }

    doSearch(): void {
        if (
            getNextPageToken(this._items.nextPageToken) &&
            !this._items.isFetching
        ) {
            this.search.emit({
                text: this.textSearchControl.value,
                nextPageToken: getNextPageToken(this._items.nextPageToken),
            });
        }
    }

    removeItem(itemId: number): void {
        if (this.control.value) {
            this.control.setValue(
                this.control.value.filter(
                    (value) => itemId !== mapById(value),
                ) ?? null,
            );
        }
    }

    itemSelected(item: InputSelectItem): void {
        if (this.control.value?.some((i) => mapById(i) === item.id)) {
            this.removeItem(item.id);
        } else {
            this.control.patchValue([...(this.control.value ?? []), item]);
        }
    }

    openFullscreen(item: InputSelectItem | null | undefined): void {
        if (item?.temporaryContentViewLink) {
            this.openFullScreen.emit(item.temporaryContentViewLink);
        }
    }

    isItemSelected(data: {
        item: InputSelectItem;
        items: (number | InputSelectItem)[];
    }): boolean {
        return data.items.some((i) => mapById(i) === data.item.id);
    }

    private filterItems(filter: string): void {
        if (Array.isArray(this.items)) {
            this._items = {
                ...paginatedListFromArray(
                    this.items.filter(
                        (item) =>
                            !filter ||
                            item.label
                                .toLowerCase()
                                .includes(filter.toLowerCase()),
                    ),
                ),
                totalCount: this.items.length,
            };
            this.cdr.markForCheck();
        } else {
            this.search.emit({
                text: filter,
                nextPageToken: null,
            });
        }
    }

    private detectItemsChanges(): void {
        if (this.control.value?.length) {
            /**
             * Seleziono gli item corrispondenti all'eventuale this.control.value
             * per ottenere il comportamento corretto del campi input gerarchici
             */
            const ids = mapArrayById(this.control.value);
            const itemIdsTobeSelected =
                mapArrayById(
                    this._items.list.filter(({ id }) => ids?.includes(id)),
                ) ?? null;
            if (!areEqualObjects(this.control.value, itemIdsTobeSelected)) {
                this.control.setValue(itemIdsTobeSelected);
            }
        }
    }
}
