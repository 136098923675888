import { AttachmentSectionSource } from '@modules/light-box/models/attachment-section/attachment-section.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SectionsState } from './sections.reducer';

const selectSectionsFeature = createFeatureSelector<SectionsState>('sections');

export const attachmentItems = (id: AttachmentSectionSource['id']) =>
    createSelector(selectSectionsFeature, (state) => state.items[id]);

export const currentAttachmentItems = createSelector(
    selectSectionsFeature,
    (state) =>
        state.currentAttachment
            ? state.items[state.currentAttachment.id]
            : undefined,
);

export const currentAttachment = createSelector(
    selectSectionsFeature,
    (state) => state.currentAttachment,
);

export const containsText = createSelector(
    selectSectionsFeature,
    (state) => state.containsText,
);

export const active = createSelector(
    selectSectionsFeature,
    (state) => state.active,
);

export const selectedSection = createSelector(
    selectSectionsFeature,
    (state) => state.selectedSection,
);
