import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { assertUnreachable } from '@interacta-shared/util';
import { SurveyPost } from '@modules/post/models/survey-post/survey-post.model';
import { isSurveyPostExpired } from '@modules/post/models/survey-post/survey-post.utils';
import { TranslateService } from '@ngx-translate/core';
import { enUS } from 'date-fns/locale';
import {
    DateFormattedLabels,
    taskExpirationLabels,
    taskExpiredLabels,
    taskExpiresLabels,
} from '../models/date-formatted-labels';
import { getTranslatedDateFormattedLabel } from '../utils/expiration-date.utils';

/**
 * See https://injenia.atlassian.net/l/cp/r6L3KnZu
 *
 */
@Pipe({
    name: 'surveyPostExpirationDate',
})
export class SurveyPostExpirationDatePipe implements PipeTransform {
    locale!: Locale;

    constructor(
        private configurationService: ConfigurationService,
        private translateService: TranslateService,
    ) {
        this.configurationService
            .getLocaleDateFns()
            .subscribe((locale) => (this.locale = locale));
    }

    transform(date: Date, post?: SurveyPost): string {
        if (!this.locale) {
            console.warn(`The locale shouldn't be null here.`);
        }

        // Defaulting to enUS which is always eagerly loaded by date-fns and
        // doesn't increase bundle size.
        const locale = this.locale ?? enUS;

        return getTranslatedDateFormattedLabel(
            this.translateService,
            this.getLabelsOfCategory(post),
            locale,
            date,
        );
    }

    private getLabelsOfCategory(post?: SurveyPost): DateFormattedLabels {
        if (post) {
            if (isSurveyPostExpired(post)) {
                return taskExpiredLabels;
            }
            const state = post.state;
            switch (state) {
                case 'open':
                    return taskExpiresLabels;
                case 'closed':
                case 'scheduled':
                    return taskExpirationLabels;
                default:
                    assertUnreachable(state);
            }
        } else return taskExpirationLabels;
    }
}
