<div *ngIf="control | inputState: isReadonly | async as state" class="relative">
    <input
        autocomplete="off"
        class="h-40 w-full rounded-lg border-2"
        inputmode="numeric"
        interactaDecimalNumberV2
        type="text"
        [decimalDigits]="decimalDigits"
        [decimalLocale]="decimalLocale ?? 'it'"
        [formControl]="control"
        [id]="id"
        [ngClass]="{
            'border-border-300 focus:border-text': !state.invalid,
            'border-border-error': state.invalid,
            'no-spinner': hideSpinners,
            'bg-surface-A': !state.disabled,
            'bg-surface-100 text-text-disabled pointer-events-none cursor-default':
                state.disabled,
            'text-center': textAlign === 'center',
            'pl-8': textAlign === 'left' && iconPosition !== 'left',
            'pr-40': icon && iconPosition === 'right',
            'pl-40': icon && iconPosition === 'left'
        }"
        [placeholder]="label || ''"
        [readonly]="state.disabled"
        (blur)="blurEmitter.emit()"
    />

    <interacta-icon
        *ngIf="icon && !iconClickable"
        class="absolute top-4 m-4 h-24 w-24"
        [icon]="icon"
        [ngClass]="{
            'text-text': !state.invalid && iconColor === 'black',
            'text-text-mid-contrast': !state.invalid && iconColor === 'gray',
            'text-text-primary': !state.invalid && iconColor === 'primary',
            'text-text-error': state.invalid,
            'left-8': iconPosition === 'left',
            'right-8': iconPosition === 'right'
        }"
    >
    </interacta-icon>

    <button
        *ngIf="icon && iconClickable"
        class="absolute top-4"
        interacta-icon-button
        type="button"
        [attr.aria-hidden]="!alternativeText"
        [attr.aria-label]="alternativeText"
        [icon]="icon"
        [ngClass]="{
            'text-text-mid-contrast': !state.invalid && iconColor === 'gray',
            'text-text-primary': !state.invalid && iconColor === 'primary',
            'text-text-error': state.invalid,
            'left-8': iconPosition === 'left',
            'right-8': iconPosition === 'right'
        }"
        [tabindex]="iconFocusableWithTab ? 0 : -1"
        (click)="iconClicked.emit(); $event.stopPropagation()"
    ></button>
</div>
