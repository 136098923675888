import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import {
    AccordionComponent,
    ApplyPipe,
    ArrayPipe,
    AvatarComponent,
    ButtonComponent,
    ButtonLightBoxComponent,
    ButtonMenuComponent,
    BypassSecurityTrustPipe,
    CheckboxComponent,
    ChipButtonV2Component,
    ChipChoiceComponent,
    ChipComponent,
    DeltaViewComponent,
    DialogButtonComponent,
    DialogButtonsComponent,
    DialogComponent,
    DialogDescriptionComponent,
    DialogSearchComponent,
    FormControlValueV2Pipe,
    FormFieldV2Component,
    GroupAvatarComponent,
    IconButtonComponent,
    ImageComponent,
    InputDateComponent,
    InputGenericSelectComponent,
    InputMultipleSelectComponent,
    InputMultipleSelectIndeterminateComponent,
    InputNumberSpinnerComponent,
    InputNumberV2Component,
    InputPasswordV2Component,
    InputSearchComponent,
    InputSingleSelectComponent,
    InputStatePipe,
    InputTextareaV2Component,
    InputTextV2Component,
    InputTimeV2Component,
    InputTimezoneComponent,
    LoaderComponent,
    LoadMoreComponent,
    MenuComponent,
    MenuDecoratorComponent,
    NotNullPipe,
    RadioButtonComponent,
    RadioGroupComponent,
    RequiredPipe,
    ResizeDirective,
    ScrollTrackerDirective,
    SeparatorComponent,
    ShowErrorsComponent,
    TabComponent,
    ToggleComponent,
    UserComponent,
} from '@interacta-shared/ui';
import {
    FileUploadInputComponent,
    FilterCollapseWrapperComponent,
    FiltersPillComponent,
    NoResultComponent,
    WarningBadgeComponent,
} from '@interacta-shared/ui-common';
import { IconComponent } from '@interacta-shared/ui-icons';
import {
    quillIconsOverride,
    registerQuillPlainClipboard,
} from '@interacta-shared/util-common';
import { MoveCursorListDirective } from '@modules/core/directives/move-cursor-list.directive';
import { EmptyLabelReplaceWithPipe } from '@modules/core/pipes/empty-label-replace-with.pipe';
import { FilterAttachmentCustomButtonsPipe } from '@modules/core/pipes/filter-attachment-custom-buttons.pipe';
import { PostCodeTitlePipe } from '@modules/core/pipes/post-code-name.pipe';
import '@modules/mentions/helpers/quill-magic-url';
import '@modules/mentions/helpers/quill-mention-blot';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { IsSameDayPipeModule, ParseIsoPipeModule } from 'ngx-date-fns';
import { QuillModule } from 'ngx-quill';
import { AdminV2MemberComponent } from './components/admin-v2-member/admin-v2-member.component';
import { AdvancedSearchBarComponent } from './components/advanced-search-bar/advanced-search-bar.component';
import { AttachmentsCounterComponent } from './components/attachments-counter/attachments-counter.component';
import { BackComponent } from './components/back/back.component';
import { BadgeDraftComponent } from './components/badge-draft/badge-draft.component';
import { ChipMemberComponent } from './components/chip-member/chip-member.component';
import { CommunitiesMultipleSelectComponent } from './components/communities-multiple-select/communities-multiple-select.component';
import { CommunityCardComponent } from './components/community-card/community-card.component';
import { CommunitySelectionComponent } from './components/community-selection/community-selection.component';
import { CreationDateComponent } from './components/creation-date/creation-date.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { DateRangeDialogComponent } from './components/date-range-dialog/date-range-dialog.component';
import { DateTimeExtendedComponent } from './components/date-time-extended/date-time-extended.component';
import { DialogCommentLikersComponent } from './components/dialog-comment-likers/dialog-comment-likers.component';
import { DialogItemsOrderbyCriteriaComponent } from './components/dialog-items-orderby-criteria/dialog-items-orderby-criteria.component';
import { DialogLikersViewersComponent } from './components/dialog-likers-viewers/dialog-likers-viewers.component';
import { DialogMembersComponent } from './components/dialog-members/dialog-members.component';
import { DriveFileSelectComponent } from './components/drive-file-select/drive-file-select.component';
import { DropZoneAreaComponent } from './components/drop-zone-area/drop-zone-area.component';
import { EndListComponent } from './components/end-list/end-list.component';
import { ExternalLinksComponent } from './components/external-links/external-links.component';
import { FileSelectComponent } from './components/file-select/file-select.component';
import { GroupTagComponent } from './components/group-tag/group-tag.component';
import { HashtagMiniChipComponent } from './components/hashtag-mini-chip/hashtag-mini-chip.component';
import { HashtagSelectComponent } from './components/hashtag-select/hashtag-select.component';
import { HashtagsDetailDialogComponent } from './components/hashtags-detail-dialog/hashtags-detail-dialog.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { InputMemberPickerComponent } from './components/input-member-picker/input-member-picker.component';
import { InputNumberLocalizedComponent } from './components/input-number-localized/input-number-localized.component';
import { InputRolesPickerComponent } from './components/input-roles-picker/input-roles-picker.component';
import { InputTagMemberPickerComponent } from './components/input-tag-member-picker/input-tag-member-picker.component';
import { InputTagMembersExtendedFieldDialogComponent } from './components/input-tag-members-extended-field-dialog/input-tag-members-extended-field-dialog.component';
import { InputTranslationDialogV2Component } from './components/input-translation-dialog-v2/input-translation-dialog-v2.component';
import { InputTranslationV2Component } from './components/input-translation-v2/input-translation-v2.component';
import { InvisibleScrollComponent } from './components/invisible-scroll/invisible-scroll.component';
import { LanguageSelectorDialogComponent } from './components/language-selector-dialog/language-selector-dialog.component';
import { LayerComponent } from './components/layer/layer.component';
import { MemberAvatarSelectionComponent } from './components/member-avatar-selection/member-avatar-selection.component';
import { MemberAvatarComponent } from './components/member-avatar/member-avatar.component';
import { MemberListChipsComponent } from './components/member-list-chips/member-list-chips.component';
import { MemberListPlaceholderComponent } from './components/member-list-placeholder/member-list-placeholder.component';
import { MemberListComponent } from './components/member-list/member-list.component';
import { MemberPickerComponent } from './components/member-picker/member-picker.component';
import { MemberComponent } from './components/member/member.component';
import { MentionDeltaEditorComponent } from './components/mention-delta-editor/mention-delta-editor.component';
import { MenuTimezoneComponent } from './components/menu-timezone/menu-timezone.component';
import { OtherFiltersMultipleSelectComponent } from './components/other-filters-multiple-select/other-filters-multiple-select.component';
import { OtherFiltersTextComponent } from './components/other-filters-text/other-filters-text.component';
import { PeopleGroupsSplitterComponent } from './components/people-groups-splitter/people-groups-splitter.component';
import { PostCodeComponent } from './components/post-code/post-code.component';
import { PrivateEmailFormComponent } from './components/private-email-form/private-email-form.component';
import { ProfiledUserComponent } from './components/profiled-user/profiled-user.component';
import { QuickActionComponent } from './components/quick-action/quick-action.component';
import { QuillTableSettingsDialogComponent } from './components/quill-table-settings-dialog/quill-table-settings-dialog.component';
import { QuillToolbarComponent } from './components/quill-toolbar/quill-toolbar.component';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { RecentCommunitiesComponent } from './components/recent-communities/recent-communities.component';
import { ShowMoreToggleComponent } from './components/show-more-toggle/show-more-toggle.component';
import { StatusCategoriesComponent } from './components/status-categories/status-categories.component';
import { TagGroupsDialogComponent } from './components/tag-groups-dialog/tag-groups-dialog.component';
import { TagMemberDialogComponent } from './components/tag-member-dialog/tag-member-dialog.component';
import { TagSplittableComponent } from './components/tag-splittable/tag-splittable.component';
import { TranslateComponent } from './components/translate-button/translate-button.component';
import { UsersGroupComponent } from './components/users-group/users-group.component';
import { WindowPaginatorComponent } from './components/window-paginator/window-paginator.component';
import { WipPageComponent } from './components/wip-page/wip-page.component';
import { WorkflowStateBadgeComponent } from './components/workflow-state-badge/workflow-state-badge.component';
import { ZonedDateComponent } from './components/zoned-date/zoned-date.component';
import { AttachmentCopyPasteDirective } from './directives/attachment-copy-paste.directive';
import { GoogleDrivePickerV2Directive } from './directives/google-drive-picker-v2.directive';
import { HideTooltipDirective } from './directives/hide-tooltip.directive';
import { MentionEventHandlerDirective } from './directives/mention-event-handler.directive';
import {
    ShowMoreButtonDirective,
    ShowMoreChildrenDirective,
    ShowMoreItemDirective,
} from './directives/show-more-children.directive';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { CurrentLanguageDeltaV2Pipe } from './pipes/current-language-delta-v2.pipe';
import { CurrentLanguageV2Pipe } from './pipes/current-language-v2.pipe';
import { CurrentLanguagePipe } from './pipes/current-language.pipe';
import { DateIntlPipe } from './pipes/date-intl.pipe';
import { DateTooltipPipe } from './pipes/date-tooltip.pipe';
import { EncodeURIPipe } from './pipes/encode-uri.pipe';
import { FilterAttachmentsPipe } from './pipes/filter-attachments.pipe';
import { FilterByPipe } from './pipes/filter-by.pipe';
import { FilterNullPipe } from './pipes/filter-null.pipe';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { FormatDistancePipe } from './pipes/format-distance.pipe';
import { FromCommunitiesStatePipe } from './pipes/from-communities-state.pipe';
import { InteractaDateDistancePipe } from './pipes/interacta-date-distance.pipe';
import { LabelInternationalizedPipe } from './pipes/label-internationalized.pipe';
import { MemberFullnamePipe } from './pipes/member-fullname.pipe';
import { MiddleEllipsisPipe } from './pipes/middle-ellipsis.pipe';
import { OrderByCriteriaLabelPipe } from './pipes/orderby-criteria-label.pipe';
import { ParticipantCountersPipe } from './pipes/participant-counters.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { ShowNoResultsPipe } from './pipes/show-no-results.pipe';
import { SurveyPostClosingDatePipe } from './pipes/survey-post-closing-date.pipe';
import { SurveyPostExpirationDatePipe } from './pipes/survey-post-expiration-date.pipe';
import { TagMemberFullnamePipe } from './pipes/tag-member-fullname.pipe';
import { TaskExpirationDatePipe } from './pipes/task-expiration-date.pipe';
import { TimelineDatePipe } from './pipes/timeline-date.pipe';
import { ToInputSelectItemsPipe } from './pipes/to-input-select-item.pipe';
import { ToKebabCasePipe } from './pipes/to-kebab-case.pipe';
import { ToSnakeUpperCasePipe } from './pipes/to-snake-upper-case.pipe';
import { TotalCountPipe } from './pipes/total-count.pipe';
import { TranslateArrayPipe } from './pipes/translate-array.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { TypeofPipe } from './pipes/typeof.pipe';
import { WorkflowBadgeColorPipe } from './pipes/workflow-badge-color.pipe';

const dfnsPipeModules = [IsSameDayPipeModule, ParseIsoPipeModule];

const materialModules = [MatDialogModule, MatRippleModule, MatSliderModule];

const modules = [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    MatDatepickerModule,
    MatTooltipModule,
    TranslateModule,
    VirtualScrollerModule,
    A11yModule,
    FileUploadModule,
    PickerModule,
    DragDropModule,
    ...dfnsPipeModules,
    ...materialModules,
];

const standaloneComponents = [
    IconComponent,
    AccordionComponent,
    AvatarComponent,
    ButtonComponent,
    ButtonMenuComponent,
    CheckboxComponent,
    ChipButtonV2Component,
    ChipChoiceComponent,
    DialogButtonComponent,
    DialogButtonsComponent,
    DialogComponent,
    DialogDescriptionComponent,
    DialogSearchComponent,
    FiltersPillComponent,
    FilterCollapseWrapperComponent,
    GroupAvatarComponent,
    IconButtonComponent,
    IconComponent,
    ImageComponent,
    InputGenericSelectComponent,
    InputMultipleSelectComponent,
    InputMultipleSelectIndeterminateComponent,
    InputNumberSpinnerComponent,
    InputNumberV2Component,
    InputPasswordV2Component,
    InputSearchComponent,
    InputSingleSelectComponent,
    InputTextareaV2Component,
    InputTextV2Component,
    LoaderComponent,
    LoadMoreComponent,
    MenuComponent,
    MenuDecoratorComponent,
    NoResultComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    SeparatorComponent,
    TabComponent,
    ToggleComponent,
    UserComponent,
    ButtonLightBoxComponent,
    DeltaViewComponent,
    ShowErrorsComponent,
    FormFieldV2Component,
    InputDateComponent,
    InputTimeV2Component,
    InputTimezoneComponent,
    FileUploadInputComponent,
    ChipComponent,
    WarningBadgeComponent,
];

const standalonePipes = [
    ApplyPipe,
    ArrayPipe,
    BypassSecurityTrustPipe,
    FormControlValueV2Pipe,
    InputStatePipe,
    RequiredPipe,
    NotNullPipe,
];

const standaloneDirectives = [ResizeDirective, ScrollTrackerDirective];

const directives = [
    MoveCursorListDirective,
    ShowMoreChildrenDirective,
    ShowMoreButtonDirective,
    ShowMoreItemDirective,
    GoogleDrivePickerV2Directive,
    HideTooltipDirective,
    MentionEventHandlerDirective,
    AttachmentCopyPasteDirective,
];

const datePipes = [
    DateIntlPipe,
    InteractaDateDistancePipe,
    DateTooltipPipe,
    TaskExpirationDatePipe,
    TimelineDatePipe,
    SurveyPostExpirationDatePipe,
    SurveyPostClosingDatePipe,
];

const pipes = [
    CurrentLanguagePipe,
    CurrentLanguageV2Pipe,
    CurrentLanguageDeltaV2Pipe,
    EncodeURIPipe,
    FilterNullPipe,
    FormatBytesPipe,
    EmptyLabelReplaceWithPipe,
    FilterAttachmentsPipe,
    ReversePipe,
    ShowNoResultsPipe,
    MemberFullnamePipe,
    TagMemberFullnamePipe,
    MiddleEllipsisPipe,
    FormatDistancePipe,
    TypeofPipe,
    ParticipantCountersPipe,
    LabelInternationalizedPipe,
    TranslateArrayPipe,
    CapitalizeFirstPipe,
    TotalCountPipe,
    FilterByPipe,
    WorkflowBadgeColorPipe,
    FromCommunitiesStatePipe,
    FilterAttachmentCustomButtonsPipe,
    PostCodeTitlePipe,
    TrimPipe,
    ToInputSelectItemsPipe,
    ToSnakeUpperCasePipe,
    ToKebabCasePipe,
    OrderByCriteriaLabelPipe,
    ...datePipes,
];

const components = [
    CreationDateComponent,
    MentionDeltaEditorComponent,
    HashtagMiniChipComponent,
    QuillToolbarComponent,
    ShowMoreToggleComponent,
    DialogLikersViewersComponent,
    InvisibleScrollComponent,
    FileSelectComponent,
    DriveFileSelectComponent,
    DialogMembersComponent,
    InputMemberPickerComponent,
    MemberListChipsComponent,
    StatusCategoriesComponent,
    MemberListChipsComponent,
    MemberPickerComponent,
    WipPageComponent,
    DialogCommentLikersComponent,
    RangeSliderComponent,
    BackComponent,
    PrivateEmailFormComponent,
    BadgeDraftComponent,
    RecentCommunitiesComponent,
    QuickActionComponent,
    WorkflowStateBadgeComponent,
    MemberListComponent,
    InputRolesPickerComponent,
    CommunityCardComponent,
    OtherFiltersTextComponent,
    OtherFiltersMultipleSelectComponent,
    InputTranslationDialogV2Component,
    InputTranslationV2Component,
    MenuTimezoneComponent,
    DateTimeExtendedComponent,
    ZonedDateComponent,
    DateRangeDialogComponent,
    InfoCardComponent,
    HashtagSelectComponent,
    TranslateComponent,
    LanguageSelectorDialogComponent,
    CustomButtonComponent,
    ProfiledUserComponent,
    UsersGroupComponent,
    MemberComponent,
    ChipMemberComponent,
    LayerComponent,
    DropZoneAreaComponent,
    PeopleGroupsSplitterComponent,
    MemberListPlaceholderComponent,
    QuillTableSettingsDialogComponent,
    WindowPaginatorComponent,
    EndListComponent,
    ExternalLinksComponent,
    InputTagMemberPickerComponent,
    GroupTagComponent,
    TagMemberDialogComponent,
    TagGroupsDialogComponent,
    InputTagMembersExtendedFieldDialogComponent,
    MemberAvatarComponent,
    InputNumberLocalizedComponent,
    PostCodeComponent,
    TagSplittableComponent,
    CommunitySelectionComponent,
    AdminV2MemberComponent,
    CommunitiesMultipleSelectComponent,
    HashtagsDetailDialogComponent,
    MemberAvatarSelectionComponent,
    AttachmentsCounterComponent,
    DialogItemsOrderbyCriteriaComponent,
    AdvancedSearchBarComponent,
];

@NgModule({
    imports: [
        ...modules,
        ...standaloneComponents,
        ...standalonePipes,
        ...standaloneDirectives,
        QuillModule.forRoot(),
    ],
    declarations: [...directives, ...pipes, ...components],
    exports: [...modules, ...directives, ...pipes, ...components],
})
export class SharedV2Module {
    constructor() {
        quillIconsOverride();
        registerQuillPlainClipboard();
    }
}
