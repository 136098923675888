import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IconComponent } from '@interacta-shared/ui-icons';
import { Timezone } from '../../model/timezone.model';
import { MenuTimezoneComponent } from '../menu-timezone/menu-timezone.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
    standalone: true,
    imports: [
        IconComponent,
        OverlayModule,
        CommonModule,
        MenuComponent,
        MenuTimezoneComponent,
    ],
    selector: 'interacta-input-timezone',
    templateUrl: './input-timezone.component.html',
    styles: [],
})
export class InputTimezoneComponent implements OnInit {
    @Input({ required: true }) control!: FormControl<Timezone | null>;
    @Input({ required: true }) timezones!: Timezone[];
    @Input({ required: true }) userTimezone!: Timezone | null;
    @Input() layoutMode: 'menu' | 'expand' = 'menu';
    @Input() display: 'row' | 'column' = 'column';
    @Input() isReadonly = false;
    @Output() timezoneChange = new EventEmitter<Timezone>();

    public defaultTimezones: Timezone[] = [];
    public isMenuOpen = false;

    public ngOnInit(): void {
        this.setDefaultTimezones();
        if (this.control.value) {
            this.setTimezone(this.control.value);
        }
    }

    public setDefaultTimezones(): void {
        const currentTimezone = this.control.value;
        const londonTimezone =
            this.timezones.find((timezone) =>
                timezone.zoneId.toLowerCase().includes('london'),
            ) ?? null;

        const _defaultTimezones: Timezone[] = [];
        this.appendNotNull(_defaultTimezones, currentTimezone);
        this.appendNotNull(_defaultTimezones, this.userTimezone);
        this.appendNotNull(_defaultTimezones, londonTimezone);

        const seen = new Set<number>();
        this.defaultTimezones = _defaultTimezones.filter((t) => {
            if (seen.has(t.id)) {
                return false;
            } else {
                seen.add(t.id);
                return true;
            }
        });
    }

    private appendNotNull(array: Timezone[], value: Timezone | null): void {
        if (value) {
            array.push(value);
        }
    }

    public closeMenu(): void {
        this.isMenuOpen = false;
    }

    public setTimezone(timezone: Timezone): void {
        this.timezoneChange.emit(timezone);
        this.control.setValue(timezone);
        this.setDefaultTimezones();
        this.closeMenu();
    }
}
