import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import { getRandomSecondaryColorVariables } from '@interacta-shared/util';
import { sanitizeLink } from '@modules/core/helpers/generic.utils';
import {
    IExternalLink,
    SuiteId,
} from '@modules/core/models/external-link/external-link.model';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Component({
    selector: 'interacta-external-links',
    templateUrl: './external-links.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalLinksComponent {
    @Input({ required: true }) externalLinks!: IExternalLink[];

    @Output() imageLoadError = new EventEmitter<string>();

    showExternalLinks$ = new BehaviorSubject(true);

    readonly SuiteId = SuiteId;

    protected readonly getRandomSecondaryColorVariables =
        getRandomSecondaryColorVariables;

    constructor(private authService: AuthService) {}

    async openExternalLink(link: IExternalLink): Promise<void> {
        if (!link.url) return;

        const url = link.authRequired
            ? await this.getUrlWithAuth(link.url).toPromise()
            : link.url;

        if (url == null) {
            throw new Error(
                `External Link ${link.label} has no url associated`,
            );
        }

        window.open(sanitizeLink(url), '_blank');
    }

    private getUrlWithAuth(url: string): Observable<string> {
        return this.authService
            .renewAccessToken()
            .pipe(map((accessToken) => `${url}#access_token=${accessToken}`));
    }
}
