<div class="flex min-w-0 space-x-8">
    <button
        *ngIf="attachmentsList.totalCount > MAX_PREVIEWS"
        class="shrink-0"
        interacta-button-light-box
        type="button"
        [attr.aria-label]="'LIGHTBOX.PREVIOUS' | translate"
        [disabled]="
            attachmentsList.totalCount <= MAX_PREVIEWS || pageBegin === 0
        "
        [icon]="'chevron-big-left'"
        [size]="'regular'"
        (click)="slide('left')"
    ></button>

    <div
        #scroll
        class="smooth-scroll relative flex min-w-0 grow space-x-8 overflow-hidden p-2"
        [style.max-width.rem]="MAX_WIDTH"
    >
        <ng-container
            *ngFor="
                let attachmentPlaceholder of attachmentsList.totalCount | array;
                let idx = index;
                trackBy: trackPreview
            "
        >
            <button
                *ngIf="
                    attachmentsList.attachments[idx] != null &&
                    attachmentsList.attachments[idx] as attachment
                "
                #preview
                class="relative shrink-0"
                type="button"
                (click)="changeAttachment.emit({ index: idx })"
            >
                <interacta-attachment-preview
                    size="small"
                    [active]="idx === currentIndex"
                    [attachment]="attachment"
                ></interacta-attachment-preview>
                <div
                    class="absolute inset-0 flex items-center justify-center"
                    (mouseenter)="hoverPreview[idx] = true"
                    (mouseleave)="hoverPreview[idx] = false"
                >
                    <interacta-attachment-icon
                        [attachment]="attachment"
                        [class.hidden]="hoverPreview[idx]"
                    ></interacta-attachment-icon>
                </div>
            </button>

            <div
                *ngIf="attachmentsList.attachments[idx] == null"
                #preview
                class="bg-surface-500 h-56 w-56 shrink-0 rounded-lg"
            ></div>
        </ng-container>
    </div>

    <button
        *ngIf="attachmentsList.totalCount > MAX_PREVIEWS"
        class="shrink-0"
        interacta-button-light-box
        type="button"
        [attr.aria-label]="'LIGHTBOX.NEXT' | translate"
        [disabled]="
            attachmentsList.totalCount <= MAX_PREVIEWS ||
            pageBegin >= attachmentsList.totalCount - MAX_PREVIEWS
        "
        [icon]="'chevron-big-right'"
        [size]="'regular'"
        (click)="slide('right')"
    ></button>
</div>
