import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { IUsersGroup } from '@modules/core';
import {
    DialogServiceV2,
    DialogServiceV2Data,
} from '@modules/shared-v2/models/dialog-service-v2';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface GroupInfoDialogData extends DialogServiceV2Data {
    group: IUsersGroup | null;
}
export const groupInfoInitialState = (): GroupInfoDialogData => ({
    isOpen: false,
    isFetching: false,
    group: null,
});

export class GroupInfoDialogState {
    public data = new BehaviorSubject<GroupInfoDialogData>(
        groupInfoInitialState(),
    );
}

@Injectable({
    providedIn: 'root',
})
export class GroupInfoV2Service extends DialogServiceV2<GroupInfoDialogState> {
    readonly state: GroupInfoDialogState;
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/settings`;

    private close$ = new Subject<void>();
    private destroy$ = new Subject<void>();

    constructor(private http: HttpClient) {
        super();
        this.state = new GroupInfoDialogState();
    }

    initialize(): void {
        this.state.data.next(groupInfoInitialState());
    }

    flush(): void {
        this.state.data.next(groupInfoInitialState());
        this.destroy$.next();
    }

    isOpen(): boolean {
        return this.state.data.value.isOpen;
    }

    openDialog(groupId: IUsersGroup['id']): Observable<void> {
        return new Observable((subscriber) => {
            this.state.data.next({
                isOpen: true,
                isFetching: true,
                group: null,
            });

            this.http
                .get<any>(`${this.baseUrl}/groups/${groupId}/detail`)
                .subscribe({
                    next: (res) => {
                        this.state.data.next({
                            ...this.state.data.value,
                            isFetching: false,
                            group: <IUsersGroup>{
                                id: res.id,
                                fullName: res.name,
                                tags: res.tags,
                                membersCount: res.membersCount,
                                description: res.description,
                                deleted: res.deleted,
                            },
                        });
                    },
                    error: (error) => this.error(this.state.data, error),
                });

            this.close(this.close$, this.state.data, subscriber);
        });
    }

    closeDialog(): void {
        this.close$.next();
    }
}
