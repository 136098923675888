import { NgClass, NgIf, UpperCasePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { getAbbreviation, getRandomColor } from '@interacta-shared/util';
import { Size, UserAvatar } from '../../model';
import { ApplyPipe } from '../../pipes';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { ImageComponent } from '../image/image.component';
import { LoaderComponent } from '../loader/loader.component';

@Component({
    selector: 'interacta-avatar',
    templateUrl: './avatar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        ImageComponent,
        NgClass,
        LoaderComponent,
        IconButtonComponent,
        UpperCasePipe,
        ApplyPipe,
    ],
})
export class AvatarComponent implements OnChanges {
    @Input({ required: true }) user!: UserAvatar;
    @Input() size: Size | 'medium' = 'regular';
    @Input() canEdit = false;
    @Input() isLoading = false;
    @Input() enableChristmasEasterEgg = false;

    @Output() avatarEdit = new EventEmitter();

    abbreviationName = '';
    loadError = false;

    readonly getRandomColor = getRandomColor;

    ngOnChanges(changes: SimpleChanges): void {
        const changedUser = changes['user'];

        if (changedUser) {
            if (
                changedUser.currentValue?.accountPhotoUrl !==
                changedUser.previousValue?.accountPhotoUrl
            ) {
                this.loadError = false;
            }

            if (changedUser.currentValue) {
                this.abbreviationName = getAbbreviation(this.user.fullName);
            }
        }
    }
}
