import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalizeFirst',
})
export class CapitalizeFirstPipe implements PipeTransform {
    transform(value: string | string[]): string {
        const returnLabel = Array.isArray(value)
            ? value.join(', ').toLowerCase()
            : value;
        return returnLabel.charAt(0).toUpperCase() + returnLabel.slice(1);
    }
}
