import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { postEdit } from '@modules/app-routing/routes';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

interface AttachmentsVersionsDialogOpenParams {
    canAddVersion: boolean;
    canEditPost: boolean;
    attachment: IAttachment | null;
    acceptedMimeType: string[] | undefined;
    confirmLabel: string;
}

export interface AttachmentsVersionsDialogState {
    isOpen: boolean;
    content: Required<AttachmentsVersionsDialogOpenParams>;
}

@Injectable({ providedIn: 'root' })
export class AttachmentsVersionsService {
    private state$ = new BehaviorSubject<AttachmentsVersionsDialogState>({
        isOpen: false,
        content: {
            canAddVersion: false,
            canEditPost: false,
            attachment: null,
            acceptedMimeType: undefined,
            confirmLabel: 'BUTTON.LABEL_BUTTON_SAVE',
        },
    });

    private close$ = new Subject<IAttachment | undefined>();

    constructor(private router: Router) {}

    open({
        attachment,
        canAddVersion = false,
        canEditPost = false,
        acceptedMimeType = undefined,
        confirmLabel = 'BUTTON.LABEL_BUTTON_SAVE',
    }: AttachmentsVersionsDialogOpenParams): Observable<
        IAttachment | undefined
    > {
        return new Observable((subscriber) => {
            this.state$.next({
                isOpen: true,
                content: {
                    attachment,
                    canAddVersion,
                    canEditPost,
                    acceptedMimeType,
                    confirmLabel,
                },
            });

            return this.close$.pipe(first()).subscribe((attachment) => {
                this.state$.next({ ...this.state$.value, isOpen: false });

                subscriber.next(attachment);
                subscriber.complete();
            });
        });
    }

    close(attachment?: IAttachment): void {
        this.close$.next(attachment);
    }

    getState(): Observable<AttachmentsVersionsDialogState> {
        return this.state$.asObservable();
    }

    goToPost(postId: number): void {
        this.close();
        this.router.navigate([postEdit, postId]);
    }
}
