import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable()
export class FormControlsService {
    private controls: {
        [key: number]: AbstractControl;
    } = {};

    init(controls: { [key: number]: AbstractControl }): void {
        this.controls = controls;
    }

    getValue(fieldId: number): any | undefined {
        return this.controls[fieldId]?.value;
    }

    getValueChanges(fieldId: number): Observable<any> | undefined {
        return this.controls[fieldId]?.valueChanges;
    }
}
