import { AdminV2ConfirmDialogData } from '@modules/admin-v2-shared/models/admin-v2-confirm-dialog.model';
import { AdminV2ColumnVisibility } from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import {
    AdminV2CommunityFiltersLabel,
    AdminV2CommunityRow,
    AdminV2CommunitySort,
    AdminV2CommunityStatusFilter,
    AdminV2CommunityStatusFilterLabel,
} from '../models/admin-v2-community.model';

export const DefaultCommunityStatusFilter = AdminV2CommunityStatusFilter.ALL;

export const DefaultCommunityColumnVisibility: AdminV2ColumnVisibility<AdminV2CommunityRow> =
    {
        baseData: true,
        workspace: true,
        lastModifyTimestamp: true,
        lastModifyUser: true,
        membersCount: true,
        creationTimestamp: false,
        creatorUser: false,
        adminMembers: false,
        postVisibility: false,
        workflow: false,
        hashtags: false,
    };

export const DefaultCommunitySortOrder: AdminV2CommunitySort = {
    sortColumn: 'baseData',
    orderDesc: false,
};

const filtersLabelRoot = 'ADMIN_V2.COMMUNITIES.FILTERS';
export const CommunityFiltersLabels: AdminV2CommunityFiltersLabel = {
    status: `${filtersLabelRoot}.STATUS`,
    search: `${filtersLabelRoot}.SEARCH`,
    workspaces: `${filtersLabelRoot}.WORKSPACE`,
    adminMembers: `${filtersLabelRoot}.ADMIN_MEMBER`,
};

const statusFiltersLabelRoot = 'ADMIN_V2.COMMUNITIES.FILTERS.STATUS';
export const CommunityStatusFilterLabels: AdminV2CommunityStatusFilterLabel[] =
    [
        {
            label: `${statusFiltersLabelRoot}.ALL`,
            value: AdminV2CommunityStatusFilter.ALL,
        },
        {
            label: `${statusFiltersLabelRoot}.ACTIVE`,
            value: AdminV2CommunityStatusFilter.ACTIVE,
        },
        {
            label: `${statusFiltersLabelRoot}.DRAFT`,
            value: AdminV2CommunityStatusFilter.DRAFT,
        },
        {
            label: `${statusFiltersLabelRoot}.INACTIVE`,
            value: AdminV2CommunityStatusFilter.INACTIVE,
        },
    ];

const disableAcknowledgeDialogLabelRoot =
    'ADMIN_V2.COMMUNITIES.DIALOGS.DISABLE_ACKNOWLEDGE_FLAG';
export const disableAcknowledgeDialogData: Omit<
    AdminV2ConfirmDialogData,
    'isOpen'
> = {
    title: disableAcknowledgeDialogLabelRoot + '.TITLE',
    description: [
        {
            icon: 'trash-bin',
            label: disableAcknowledgeDialogLabelRoot + '.DESCRIPTION_1',
        },
        {
            icon: 'retry',
            label: disableAcknowledgeDialogLabelRoot + '.DESCRIPTION_2',
        },
    ],
};
