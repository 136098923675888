import { Pipe, PipeTransform } from '@angular/core';
import { toSnakeUpperCase } from '../utils/parse-string.utils';

@Pipe({
    name: 'toSnakeUpperCase',
})
export class ToSnakeUpperCasePipe implements PipeTransform {
    transform(s: string): string {
        return toSnakeUpperCase(s);
    }
}
