<div
    class="flex"
    [ngClass]="{
        'flex-col space-y-8': orientation === 'vertical',
        'flex-wrap gap-8': orientation === 'horizontal'
    }"
>
    <ng-container *ngFor="let category of categories">
        <interacta-chip-choice
            *ngIf="categoriesState[category.type] as categoryType"
            [bgColor]="categoryType === 'off' ? 'gray-300' : 'primary-300'"
            [disabled]="disabled"
            [icon]="
                categoryType === 'on'
                    ? 'check'
                    : categoryType === 'partial'
                      ? 'undeterminate-circle'
                      : undefined
            "
            [label]="category.label | translate"
            [size]="size"
            (toggle)="onStateCategoryChange(category.type)"
        >
        </interacta-chip-choice>
    </ng-container>
</div>
