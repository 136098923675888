// @ts-ignore
import * as Delta from 'quill-delta';

/**
 * @deprecated
 * use functions from {@link libs\shared\common\util\src\lib\utils\delta.util.ts} instead
 */
export class DeltaUtility {
    public static readonly prefixLinkCustom = 'interacta';

    public static readonly opsDeltaOperations = ['insert', 'delete', 'retain'];
    private static readonly deltaValidAttributes = [
        'link',
        'list',
        'bold',
        'italic',
        'underline',
    ];

    public static isEmpty(delta: Delta): boolean {
        return (
            Array.isArray(delta) &&
            delta.length === 1 &&
            delta[0].insert === '\n'
        );
    }

    public static sanitizeDeltaObject(output: Delta[]): Delta[] {
        output.forEach((element) => {
            if (element.attributes) {
                Object.keys(element.attributes as Record<string, any>).forEach(
                    (attr) => {
                        if (!DeltaUtility.deltaValidAttributes.includes(attr)) {
                            delete element.attributes[attr];
                        }
                    },
                );
            }
        });
        return output;
    }

    public static fromString(str: string): Delta {
        return {
            ops: [{ insert: `${str}\n` }],
        };
    }

    public static plainTextLength(delta: Delta): number {
        let counter = 0;
        if (delta && !this.isEmpty(delta.ops)) {
            const ops: Delta[] = delta.ops;
            ops.forEach((element) => {
                counter += element.insert?.length;
            });
        }
        return counter;
    }
}
