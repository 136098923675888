import { PageTokenInfo } from '@interacta-shared/util';
import {
    IWorkflowOperation,
    IWorkflowStatus,
} from '@modules/communities/models/workflow/workflow.model';
import { ICustomPost } from '@modules/post/models/custom-post.model';
import { IEventHistory } from '../notification-user.model';
import {
    WorkflowHistoryItem,
    WorkflowHistoryItemBase,
    WorkflowHistoryItemEditScreen,
    WorkflowHistoryItemInitial,
    WorkflowHistoryItemStateChange,
} from './workflow-history.model';

export function workflowHistoryItemBase(
    historyItem: IEventHistory,
): WorkflowHistoryItemBase {
    return {
        authorUser: historyItem.authorUser,
        id: `${historyItem.id}`,
        timestamp: historyItem.timestamp,
        workflowScreenData: historyItem.workflowScreenData ?? [],
    };
}

export function workflowHistoryItemInitial(
    initialState: IWorkflowStatus,
    post: ICustomPost,
): WorkflowHistoryItemInitial {
    return {
        tag: 'initial',
        id: `initial`,
        authorUser: post.creatorUser,
        timestamp: post.creationTimestamp!,
        workflowScreenData: [],
        state: initialState,
    };
}

export function workflowHistoryItemStateChange(
    historyItem: IEventHistory,
    transition: IWorkflowOperation,
): WorkflowHistoryItemStateChange {
    return {
        ...workflowHistoryItemBase(historyItem),
        tag: 'state-change',
        transition,
    };
}

export function workflowHistoryItemEditScreen(
    historyItem: IEventHistory,
    transition?: IWorkflowOperation,
): WorkflowHistoryItemEditScreen {
    return {
        ...workflowHistoryItemBase(historyItem),
        tag: 'edit-screen',
        transition,
    };
}

export function toWorkflowHistoryItem(
    list: IEventHistory[],
    prevList: WorkflowHistoryItem[],
    pageToken: PageTokenInfo,
    post: ICustomPost,
): WorkflowHistoryItem[] {
    const workflowList: WorkflowHistoryItem[] = [];

    let latestTransition = prevList
        .filter(
            (item): item is WorkflowHistoryItemStateChange =>
                item.tag === 'state-change',
        )
        .reverse()
        .find((item) => item.tag === 'state-change')?.transition;

    for (const item of list) {
        workflowList.push(
            item.postWorkflowOperationDetail
                ? workflowHistoryItemStateChange(
                      item,
                      item.postWorkflowOperationDetail,
                  )
                : workflowHistoryItemEditScreen(
                      item,
                      latestTransition ?? undefined,
                  ),
        );

        if (item.postWorkflowOperationDetail) {
            latestTransition = item.postWorkflowOperationDetail;
        }
    }

    if (pageToken.tag === 'lastLoading') {
        if (list.length > 0) {
            const firstHistoryEvent = list[0];
            if (firstHistoryEvent.postWorkflowOperationDetail) {
                const firstStateChange = workflowHistoryItemStateChange(
                    firstHistoryEvent,
                    firstHistoryEvent.postWorkflowOperationDetail,
                );
                workflowList.unshift(
                    workflowHistoryItemInitial(
                        firstStateChange.transition.fromState,
                        post,
                    ),
                );
            } else {
                console.error(
                    `First workflow history event of post ${post.id} is expected to be a "state change", but it is NOT.`,
                );
            }
        } else {
            if (post.currentWorkflowState) {
                workflowList.unshift(
                    workflowHistoryItemInitial(post.currentWorkflowState, post),
                );
            } else {
                console.error(
                    `Current workflow status of post ${post.id} is expected to be present, but it is NOT.`,
                );
            }
        }
    }

    return [...workflowList, ...prevList];
}
