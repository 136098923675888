import { Delta } from 'quill/core';

export const latestVersion = 2;

export type EditableTableRow = Record<string, Delta>;

export interface EditableTableData {
    version: number;
    headerRow: boolean;
    columns: {
        key: string;
    }[];
    rows: EditableTableRow[];
}

export type ServerEditableTableRow = Record<string, string>;

export interface ServerEditableTableData {
    version: number;
    headerRow: boolean;
    columns: {
        key: string;
    }[];
    rows: ServerEditableTableRow[];
}
