@if (mode === 'button') {
    @if (isLoading) {
        <interacta-loader />
    } @else {
        <button
            #translateMenu="cdkOverlayOrigin"
            cdkOverlayOrigin
            interacta-icon-button
            type="button"
            [active]="active"
            [attr.aria-label]="'POST.TRANSLATE' | translate"
            [icon]="'translate'"
            [matTooltip]="
                (active ? 'POST.UNTRANSLATE' : 'POST.TRANSLATE') | translate
            "
            (click)="translateClick()"
        ></button>
        <interacta-menu
            *ngIf="isTranslateMenuOpen && !!lastLanguage"
            [open]="isTranslateMenuOpen"
            [origin]="translateMenu"
            [positionX]="'before'"
            [positionY]="'below'"
            (closing)="isTranslateMenuOpen = false"
        >
            <interacta-menu-decorator>
                <div
                    class="bg-surface-A flex flex-col overflow-hidden rounded-2xl shadow"
                >
                    <interacta-button-menu
                        [label]="lastLanguage.description!"
                        (click)="select(lastLanguage)"
                    />
                    <interacta-button-menu
                        [label]="'TRANSLATE.OTHER_LANGUAGE' | translate"
                        (click)="openLanguageSelector($event)"
                    />
                </div>
            </interacta-menu-decorator>
        </interacta-menu>
    }
} @else {
    <interacta-button-menu
        [icon]="'translate'"
        [label]="(active ? 'POST.UNTRANSLATE' : 'POST.TRANSLATE') | translate"
        (click)="active ? select(null) : openLanguageSelector($event)"
    />
}
<interacta-language-selector-dialog />
