import { areEqualObjects } from '@interacta-shared/util';
import { enumValues } from '@interacta-shared/util-common';
import {
    CustomFieldType,
    ICustomFieldDefinition,
} from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { idArraytoMap } from '@modules/core/helpers/generic.utils';
import { DateCustomFieldFilterOutput } from '@modules/post-filters/components/dashboard-filters-date-time/dashboard-filters-date-time.component';
import {
    enabledPostTypes,
    enabledSurveyTypes,
    isPostTypeEnabled,
    isSurveyTypeEnabled,
} from '@modules/post/utils/post.utils';
import { AttachmentCategoryType } from '../attachment/attachment.model';
import {
    ConfigurationFieldType,
    IPostMetadata,
    PostType,
} from '../base-post.model';
import { SurveyType } from '../survey-post/survey-post.model';
import {
    AttachmentOrderType,
    EventFilterDateOptions,
    FilterStateCategory,
    IEventPostFilters,
    IOrder,
    IPostFilters,
    IPostFiltersCustomField,
    IPostLinkFilters,
    OrderOption,
    PostOrderType,
    PostOtherFiltersId,
    filterStateCategoryOrder,
} from './filter-post.model';

export const emptyEventPostFilters = (): IEventPostFilters => ({
    allEvents: false,
    startDateFrom: undefined,
    startDateTo: undefined,
    endDateFrom: undefined,
    endDateTo: undefined,
});

export const emptyPostLinkFilters = (): IPostLinkFilters => ({
    createdByUser: null,
    createdByGroup: null,
    hashtag: null,
    pageSize: 15,
    pageToken: undefined,
    calculateTotalItemsCount: true,
    workflowStatus: {
        ids: [],
    },
    customFields: [],
    screenFields: [],
    containsText: null,
    postId: null,
});

export const emptyPostAttachmentsFilters = (): IPostFilters => ({
    ...emptyPostFilters(),
    pageSize: 10,
    calculateTotalItemsCount: true,
    mimeTypes: [],
    mimeTypeCategory: AttachmentCategoryType.MULTIMEDIA,
    excludeFilePickers: false,
});

export const defaultPostOrderClause = (): IOrder => ({
    orderBy: PostOrderType.LAST_MODIFY_AND_COMMENT_TIMESTAMP,
    orderDesc: defaultOrderByDesc(
        PostOrderType.LAST_MODIFY_AND_COMMENT_TIMESTAMP,
    ),
});

export const emptyPostFilters = (): IPostFilters => {
    return {
        communityId: undefined,
        createdByMe: null,
        createdByUser: null,
        createdByGroup: null,
        creationDateType: null,
        creationDateFrom: null,
        creationDateTo: null,
        modifiedDateFrom: null,
        modifiedDateTo: null,
        containsText: null,
        followedByMe: null,
        hashtag: null,
        hashtagsLogicalAnd: false,
        order: defaultPostOrderClause(),
        visibility: null,
        workflowStatus: {
            ids: [],
        },
        dashboardWorkflowStatus: {
            ids: [],
        },
        customFields: [],
        screenFields: [],
        acknowledgeTaskFilter: null,
        postTypes: [],
        postSurveyTypes: [],
        event: null,
        eventType: null,
        pinnedFirst: undefined,
        pinned: undefined,
        mentioned: false,
        toManage: false,

        calculateTotalItemsCount: false,

        attachmentName: null,
        attachmentHashtag: null,
        attachmentHashtagsLogicalAnd: false,
        attachmentModifiedDateType: null,
        attachmentModifiedDateFrom: null,
        attachmentModifiedDateTo: null,
        attachmentCreatedByUser: null,
        mimeTypes: null,
        mimeTypeCategory: null,
        attachmentSourceType: null,
        excludeFilePickers: false,
        includeStatelessPosts: undefined,
    };
};

export const filtersAreEquals = (
    filterNew: IPostFilters,
    filterOld: IPostFilters,
): boolean => {
    if (filterIsEmpty(filterNew) && filterIsEmpty(filterOld)) {
        return true;
    }

    let areEquals = true;

    areEquals &&= areEqualObjects(filterNew, filterOld, [
        'customFields',
        'screenFields',
        'pageToken',
    ]);

    const areCustomFieldsEquals = (
        f1: IPostFiltersCustomField[],
        f2: IPostFiltersCustomField[],
    ) => {
        if (f1.length !== f2.length) {
            return false;
        }

        const newFields = f1.map((f) => ({
            id: f.definition.id,
            value: f.value,
        }));

        const oldFields = f2.map((f) => ({
            id: f.definition.id,
            value: f.value,
        }));

        return areEqualObjects(
            idArraytoMap(newFields),
            idArraytoMap(oldFields),
        );
    };

    areEquals &&= areCustomFieldsEquals(
        filterNew.customFields ?? [],
        filterOld.customFields ?? [],
    );

    areEquals &&= areCustomFieldsEquals(
        filterNew.screenFields ?? [],
        filterOld.screenFields ?? [],
    );

    return areEquals;
};

export const copyFilter = (filter: IPostFilters): IPostFilters => {
    const filterCopy: IPostFilters = { ...filter };
    filterCopy.order = filter.order
        ? { ...filter.order }
        : defaultPostOrderClause();

    if (filter.attachmentHashtag)
        filterCopy.attachmentHashtag = [...filter.attachmentHashtag];
    if (filter.workflowStatus)
        filterCopy.workflowStatus = {
            ids: [...filter.workflowStatus.ids],
        };
    if (filter.dashboardWorkflowStatus)
        filterCopy.dashboardWorkflowStatus = {
            ids: [...filter.dashboardWorkflowStatus.ids],
        };
    if (filter.hashtag) filterCopy.hashtag = [...filter.hashtag];
    if (filter.customFields) {
        filterCopy.customFields = [...filter.customFields];
    }
    if (filter.screenFields) {
        filterCopy.screenFields = [...filter.screenFields];
    }
    return filterCopy;
};

export const filterIsEmpty = (
    filter: Partial<IPostFilters>,
    includeCommunityId = true,
): boolean => {
    if (!filter) return true;
    const isEmpty =
        !filter.containsText &&
        !filter.creationDateTo &&
        !filter.creationDateFrom &&
        filter.visibility == null &&
        filter.acknowledgeTaskFilter == null &&
        !filter.customFields?.length &&
        !filter.screenFields?.length &&
        !filter.postTypes?.length &&
        !filter.postSurveyTypes?.length &&
        !filter.event &&
        (!filter.order ||
            (!filter.order.orderBy && filter.order.orderDesc == null) ||
            areEqualObjects(filter.order, defaultPostOrderClause())) &&
        (!filter.createdByUser || filter.createdByUser.length === 0) &&
        (!filter.hashtag || filter.hashtag.length === 0) &&
        (!filter.attachmentHashtag || filter.attachmentHashtag.length === 0) &&
        (!filter.workflowStatus || filter.workflowStatus.ids.length === 0) &&
        (!filter.dashboardWorkflowStatus ||
            filter.dashboardWorkflowStatus.ids.length === 0) &&
        filter.attachmentSourceType == null &&
        !filter.attachmentModifiedDateFrom &&
        !filter.attachmentModifiedDateTo &&
        (!filter.attachmentCreatedByUser ||
            filter.attachmentCreatedByUser.length === 0) &&
        !filter.attachmentName &&
        (!filter.mimeTypes || filter.mimeTypes.length === 0) &&
        //rapid filters
        !filter.toManage &&
        !filter.createdByMe &&
        !filter.followedByMe &&
        !filter.mentioned &&
        !filter.pinned &&
        (!filter.communityId || !includeCommunityId);
    return isEmpty;
};

export const isStateSortable = (postDefinitions?: IPostMetadata[]): boolean => {
    return (
        postDefinitions?.length === 1 &&
        (postDefinitions[0].workflowDefinition?.states || []).length > 0
    );
};

export const defaultOrderByDescAttachment = (
    attachmentOrderType: string,
): boolean => {
    switch (attachmentOrderType) {
        case AttachmentOrderType.ATTACHMENT_NAME:
            return false;
        case AttachmentOrderType.MODIFY_TIMESTAMP:
            return true;
        case PostOrderType.CREATION_TIMESTAMP:
            return true;
        default:
            return false;
    }
};

export const defaultOrderByDesc = (postOrderType: string): boolean => {
    switch (postOrderType) {
        case PostOrderType.CUSTOM_ID:
            return false;
        case PostOrderType.LAST_MODIFY_AND_COMMENT_TIMESTAMP:
            return true;
        case PostOrderType.TITLE:
            return false;
        case PostOrderType.CREATION_TIMESTAMP:
            return true;
        case PostOrderType.RECENCY:
            return true;
        case PostOrderType.STATE:
            return false;
        default:
            return false;
    }
};

export const userHasSearched = (
    filters: IPostFilters,
    enabledFilters = 0,
): boolean => {
    return enabledFilters > 0 || !rapidFiltersAreEmpty(filters);
};

export const constructAttachmentOrderOption = (): OrderOption[] => {
    return enumValues(AttachmentOrderType).map((orderType) => ({
        id: orderType,
        label: `DASHBOARD.ATTACHMENTS.ORDER_OPTIONS.${orderType.toUpperCase()}`,
        orderByDesc: defaultOrderByDescAttachment(orderType),
        disableSortOrder: false,
        hidden: false,
    }));
};

export const CUSTOM_FIELD_ORDER_RADIX = 'customField-';
export const buildCustomFieldOrderId = (fieldId: number): string => {
    return `${CUSTOM_FIELD_ORDER_RADIX}${fieldId}`;
};

export const constructStandardOrderOptions = (
    managedTypes: PostOrderType[],
    postDefinitions?: IPostMetadata[],
): OrderOption[] =>
    managedTypes.map((orderType) => ({
        id: orderType,
        label: `DASHBOARD.ORDER_OPTIONS.${orderType.toUpperCase()}`,
        orderByDesc: defaultOrderByDesc(orderType),
        disableSortOrder: orderType === PostOrderType.RECENCY,
        hidden: isOrderOptionHidden(orderType, postDefinitions),
    }));

export const constructCustomFieldOrderOptionsByFields = (
    fields: ICustomFieldDefinition[],
    filterCriteria: (field: ICustomFieldDefinition) => boolean,
): OrderOption[] => {
    return fields.filter(filterCriteria).map((field) => ({
        id: buildCustomFieldOrderId(field.id),
        label: field.label,
        orderByDesc: false,
        hidden: false,
    }));
};

export const constructCustomFieldOrderOptions = (
    postDefinitions: IPostMetadata[],
    filterCriteria: (field: ICustomFieldDefinition) => boolean,
): OrderOption[] => {
    return postDefinitions.length !== 1
        ? []
        : constructCustomFieldOrderOptionsByFields(
              postDefinitions[0].fieldMetadatas || [],
              filterCriteria,
          );
};

export const constructOrderOptions = (
    postDefinitions: IPostMetadata[],
): OrderOption[] => {
    const standardOrderOptions: OrderOption[] = constructStandardOrderOptions(
        enumValues(PostOrderType),
        postDefinitions,
    );

    const customFieldOrderOptions: OrderOption[] =
        constructCustomFieldOrderOptions(
            postDefinitions,
            (field) => field.sortable,
        );
    return [...standardOrderOptions, ...customFieldOrderOptions];
};

const rapidFiltersAreEmpty = (filter: IPostFilters): boolean => {
    if (!filter) return true;
    return (
        !filter.createdByMe &&
        !filter.mentioned &&
        !filter.toManage &&
        !filter.followedByMe
    );
};

const isOrderOptionHidden = (
    orderType: PostOrderType,
    postDefinitions?: IPostMetadata[],
): boolean => {
    let hidden = false;

    switch (orderType) {
        case PostOrderType.TITLE:
            hidden = (postDefinitions || []).every(
                (p) => p.titleEnabled === ConfigurationFieldType.DISABLED,
            );
            break;
        case PostOrderType.CUSTOM_ID:
            hidden = (postDefinitions || []).every((p) => !p.customIdEnabled);
            break;
        case PostOrderType.STATE:
            hidden = !isStateSortable(postDefinitions);
            break;
        case PostOrderType.SCHEDULED_PUBLICATION:
            hidden = true;
            break;
        default:
            hidden = false;
    }

    return hidden;
};

//OTHER FILTERS SECTION
const isCustomFilterNotEmpty = (
    fieldFilter: IPostFiltersCustomField,
): boolean => {
    const valueToCheck = [
        CustomFieldType.DATE,
        CustomFieldType.DATETIME,
    ].includes(fieldFilter.definition.customFieldType)
        ? (fieldFilter.value as DateCustomFieldFilterOutput | null)?.value
        : fieldFilter.value;
    return (
        valueToCheck != null &&
        valueToCheck !== '' &&
        (!Array.isArray(valueToCheck) || valueToCheck.length > 0)
    );
};

export const clearOtherFilters = (filters: IPostFilters): IPostFilters => {
    const updatedFilters: IPostFilters = {
        ...filters,
        ...emptyPostFilters(),
        communityId: filters.communityId,
    };
    return updatedFilters;
};

export const countOtherFilterEnabled = (
    filter: Partial<IPostFilters>,
    metadata: IPostMetadata | undefined,
): number => {
    return (
        countAttachmentsOtherFilterEnabled(filter) +
        countPostOtherFilterEnabled(filter, metadata)
    );
};

export const countAttachmentsOtherFilterEnabled = (
    filter: Partial<IPostFilters>,
): number => {
    let count = 0;
    count = filter.attachmentName ? count + 1 : count;
    count = filter.attachmentCreatedByUser?.length ? count + 1 : count;
    count =
        filter.attachmentModifiedDateFrom || filter.attachmentModifiedDateTo
            ? count + 1
            : count;
    count = filter.attachmentHashtag?.length ? count + 1 : count;
    count = filter.mimeTypes?.length ? count + 1 : count;
    count = filter.attachmentSourceType != null ? count + 1 : count;

    return count;
};

export const countPostOtherFilterEnabled = (
    filter: Partial<IPostFilters>,
    metadata: IPostMetadata | undefined,
): number => {
    let count = 0;
    count = filter.containsText ? count + 1 : count;
    count = filter.createdByUser?.length ? count + 1 : count;
    count = filter.createdByGroup?.length ? count + 1 : count;
    count =
        filter.creationDateFrom || filter.creationDateTo ? count + 1 : count;
    count = filter.hashtag?.length ? count + 1 : count;
    count = (filter.workflowStatus?.ids?.length ?? 0) > 0 ? count + 1 : count;
    count =
        (filter.dashboardWorkflowStatus?.ids?.length ?? 0) > 0
            ? count + 1
            : count;
    count = filter.visibility ? count + 1 : count;
    count = filter.acknowledgeTaskFilter ? count + 1 : count;

    count = filter.pinned ? count + 1 : count;

    const fields = [
        ...(filter.customFields ?? []),
        ...(filter.screenFields ?? []),
    ];

    count += fields.filter((fieldFilter) =>
        isCustomFilterNotEmpty(fieldFilter),
    ).length;

    count += isPostTypeFilterNotEmpty(
        filter.postTypes,
        filter.postSurveyTypes,
        filter.eventType,
        metadata,
    )
        ? 1
        : 0;
    return count;
};

export const countEnabledPostTypeFilter = (
    metadata?: IPostMetadata,
): number => {
    let acc = 0;
    if (metadata) {
        if (metadata.customPostEnabled) {
            acc++;
        }

        if (metadata.eventPostEnabled) {
            acc++;
        }

        if (metadata.surveyPostEnabled) {
            acc++;
        }

        if (metadata.feedbackPostEnabled) {
            acc++;
        }
    } else {
        acc = 4;
    }
    return acc;
};

export const isPostTypeFilterNotEmpty = (
    postTypes: PostType[] | undefined,
    surveyTypes: SurveyType[] | undefined,
    eventType: EventFilterDateOptions | null | undefined,
    metadata: IPostMetadata | undefined,
): boolean => {
    const _postTypes = postTypes?.filter((p) => isPostTypeEnabled(p, metadata));
    const _eventType = isPostTypeEnabled(PostType.EVENT, metadata)
        ? eventType
        : null;
    const _enabledPostTypes = enabledPostTypes(metadata);

    return (
        _eventType != null ||
        (_postTypes != null &&
            _postTypes.length > 0 &&
            (_postTypes.length != _enabledPostTypes.length ||
                isPostSurveyTypeFilterNotEmpty(surveyTypes, metadata)))
    );
};

const isPostSurveyTypeFilterNotEmpty = (
    surveyTypes: SurveyType[] | undefined,
    metadata: IPostMetadata | undefined,
): boolean => {
    const _surveyTypes = surveyTypes?.filter((p) =>
        isSurveyTypeEnabled(p, metadata),
    );
    const _enabledSurveyTypes = enabledSurveyTypes(metadata);

    return (
        _surveyTypes != null &&
        _surveyTypes.length > 0 &&
        _surveyTypes.length != _enabledSurveyTypes.length
    );
};

export const otherFiltersHiddenInitState = (): Record<
    PostOtherFiltersId,
    boolean
> => ({
    [PostOtherFiltersId.FOLLOWED_BY_ME]: false,
    [PostOtherFiltersId.TO_MANAGE]: false,
    [PostOtherFiltersId.CONTAINS_TEXT]: false,
    [PostOtherFiltersId.POST_HASHTAGS]: false,
    [PostOtherFiltersId.ATTACHMENTS_HASHTAGS]: false,
    [PostOtherFiltersId.PUBLICATION_DATE]: false,
    [PostOtherFiltersId.CREATED_BY_USER]: false,
    [PostOtherFiltersId.CREATED_BY_USER_AND_GROUP]: false,
    [PostOtherFiltersId.WORKFLOW_STATES]: false,
    [PostOtherFiltersId.POST_TYPE]: false,
    [PostOtherFiltersId.VISIBILITY]: false,
    [PostOtherFiltersId.CUSTOM_FIELDS]: false,
    [PostOtherFiltersId.SCREEN_CUSTOM_FIELDS]: false,
    [PostOtherFiltersId.ATTACHMENTS_CONTAINS_TEXT]: false,
    [PostOtherFiltersId.ATTACHMENTS_CREATED_BY_USER]: false,
    [PostOtherFiltersId.ATTACHMENTS_LAST_MODIFY]: false,
    [PostOtherFiltersId.ATTACHMENTS_SOURCE]: false,
    [PostOtherFiltersId.ATTACHMENTS_MIMETYPE]: false,
    [PostOtherFiltersId.PINNED]: true,
});

/**
 * Use true when the filter has to be collapsed
 * Use isAttachment if you want it to be opened when you are post view
 *
 * @param isAttachment when true, only PostOtherFiltersId.ATTACHMENTS_HASHTAGS can be false
 * @returns initial collapsed state for every single filter in the other filters panel
 */
export const otherFiltersCollapsedInitState = (
    isAttachment = false,
): Record<PostOtherFiltersId, boolean> => ({
    [PostOtherFiltersId.FOLLOWED_BY_ME]: true,
    [PostOtherFiltersId.TO_MANAGE]: true,
    [PostOtherFiltersId.CONTAINS_TEXT]: isAttachment,
    [PostOtherFiltersId.POST_HASHTAGS]: isAttachment,
    [PostOtherFiltersId.ATTACHMENTS_HASHTAGS]: !isAttachment,
    [PostOtherFiltersId.PUBLICATION_DATE]: true,
    [PostOtherFiltersId.CREATED_BY_USER]: true,
    [PostOtherFiltersId.CREATED_BY_USER_AND_GROUP]: true,
    [PostOtherFiltersId.WORKFLOW_STATES]: true,
    [PostOtherFiltersId.POST_TYPE]: true,
    [PostOtherFiltersId.VISIBILITY]: true,
    [PostOtherFiltersId.CUSTOM_FIELDS]: false,
    [PostOtherFiltersId.SCREEN_CUSTOM_FIELDS]: false,
    [PostOtherFiltersId.ATTACHMENTS_CONTAINS_TEXT]: false,
    [PostOtherFiltersId.ATTACHMENTS_CREATED_BY_USER]: false,
    [PostOtherFiltersId.ATTACHMENTS_LAST_MODIFY]: false,
    [PostOtherFiltersId.ATTACHMENTS_SOURCE]: false,
    [PostOtherFiltersId.ATTACHMENTS_MIMETYPE]: false,
    [PostOtherFiltersId.PINNED]: true,
});

export const createFiltersCollapsedInitState = (): Record<
    PostOtherFiltersId,
    boolean
> => ({
    [PostOtherFiltersId.FOLLOWED_BY_ME]: true,
    [PostOtherFiltersId.TO_MANAGE]: true,
    [PostOtherFiltersId.CONTAINS_TEXT]: true,
    [PostOtherFiltersId.POST_HASHTAGS]: true,
    [PostOtherFiltersId.ATTACHMENTS_HASHTAGS]: true,
    [PostOtherFiltersId.PUBLICATION_DATE]: true,
    [PostOtherFiltersId.CREATED_BY_USER]: true,
    [PostOtherFiltersId.CREATED_BY_USER_AND_GROUP]: true,
    [PostOtherFiltersId.WORKFLOW_STATES]: true,
    [PostOtherFiltersId.POST_TYPE]: true,
    [PostOtherFiltersId.VISIBILITY]: true,
    [PostOtherFiltersId.CUSTOM_FIELDS]: true,
    [PostOtherFiltersId.SCREEN_CUSTOM_FIELDS]: true,
    [PostOtherFiltersId.ATTACHMENTS_CONTAINS_TEXT]: true,
    [PostOtherFiltersId.ATTACHMENTS_CREATED_BY_USER]: true,
    [PostOtherFiltersId.ATTACHMENTS_LAST_MODIFY]: true,
    [PostOtherFiltersId.ATTACHMENTS_SOURCE]: true,
    [PostOtherFiltersId.ATTACHMENTS_MIMETYPE]: true,
    [PostOtherFiltersId.PINNED]: true,
});

export const movingFromAttachmentToPostView = (
    orderBy: string | undefined,
): boolean => {
    return (
        !!orderBy &&
        [
            AttachmentOrderType.ATTACHMENT_NAME.toString(),
            AttachmentOrderType.CREATION_TIMESTAMP.toString(),
            AttachmentOrderType.MODIFY_TIMESTAMP.toString(),
        ].includes(orderBy)
    );
};

export const compareFilterStateCategory = (
    a: FilterStateCategory,
    b: FilterStateCategory,
): number => filterStateCategoryOrder[a] - filterStateCategoryOrder[b];
