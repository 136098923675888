import { Pipe, PipeTransform } from '@angular/core';
import { toKebabCase } from '../utils/parse-string.utils';

@Pipe({
    name: 'toKebabCase',
})
export class ToKebabCasePipe implements PipeTransform {
    transform(s: string): string {
        return toKebabCase(s);
    }
}
