import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomValidators } from '@modules/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'interacta-date-range-dialog[isOpen]',
    templateUrl: './date-range-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeDialogComponent implements OnChanges, OnDestroy {
    @Input() isOpen = false;
    @Input() dateRange?: Date[];
    @Output() closeDialog = new EventEmitter();
    @Output() rangeSelected = new EventEmitter<Date[]>();

    fromDateControl = new UntypedFormControl(null, CustomValidators.required);

    toDateControl = new UntypedFormControl(null, CustomValidators.required);

    rangeFormGroup = new UntypedFormGroup({
        fromDateControl: this.fromDateControl,
        toDateControl: this.toDateControl,
    });

    private destroy$ = new Subject<void>();

    constructor() {
        this.fromDateControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((value: Date) => {
                this.toDateControl.setValidators([
                    CustomValidators.required,
                    CustomValidators.dateTimeGreaterThanStart(value, true),
                ]);
                this.toDateControl.markAsTouched();
                this.toDateControl.updateValueAndValidity();
            });
    }
    ngOnDestroy(): void {
        this.destroy$.next();
    }

    confirm(): void {
        this.rangeSelected.emit([
            this.fromDateControl.value,
            this.toDateControl.value,
        ]);
        this.isOpen = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dateRange || changes.isOpen?.currentValue) {
            this.rangeFormGroup.patchValue({
                fromDateControl:
                    this.dateRange?.length ?? 0 > 0
                        ? this.dateRange?.[0] ?? null
                        : null,
                toDateControl:
                    this.dateRange?.length ?? 0 > 1
                        ? this.dateRange?.[1] ?? null
                        : null,
            });
            if (this.fromDateControl.value) {
                this.fromDateControl.markAsTouched();
            }
            if (this.toDateControl.value) {
                this.toDateControl.markAsTouched();
            }
        }
    }
}
