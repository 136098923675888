import { ValidationErrors } from '@angular/forms';
import {
    IValidationError,
    parseServerValidationError,
} from '@interacta-shared/data-access-error';
import { ValidationKey } from '@interacta-shared/util';
import { CommonValidationKey } from '@interacta-shared/util-common';
import { ValidationType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { CustomValidationKey } from '@modules/core';

export const parseCustomServerValidationError = (
    validationError: IValidationError,
): ValidationErrors => {
    const arg = validationError.args?.length > 0 ? validationError.args[0] : '';
    if (validationError.validationTypeId != null) {
        switch (validationError.validationTypeId) {
            case ValidationType.REQUIRED_FIELD:
                return { [ValidationKey.REQUIRED]: true };
            case ValidationType.INVALID_VALUE:
                return { [CommonValidationKey.INVALID_VALUE]: true };
            case ValidationType.MIN_VALUE:
                return { [ValidationKey.MIN_VALUE]: { min: arg } };

            case ValidationType.MAX_VALUE:
                return { [ValidationKey.MAX_VALUE]: { max: arg } };
            case ValidationType.MIN_LENGTH:
                return { [ValidationKey.MIN_LENGTH]: { requiredLength: arg } };
            case ValidationType.MAX_LENGTH:
                return {
                    [ValidationKey.MAX_LENGTH]: {
                        requiredLength: arg,
                        actualLength: '',
                    },
                };
            case ValidationType.ALREADY_EXISTS:
            case ValidationType.NOT_UNIQUE:
                return { [CustomValidationKey.ALREADY_EXISTS]: true };
            default:
                return validationError.defaultMessage
                    ? {
                          [ValidationKey.MESSAGE]:
                              validationError.defaultMessage,
                      }
                    : {
                          [CommonValidationKey.SERVER_GENERIC]: true,
                      };
        }
    } else {
        return parseServerValidationError(validationError);
    }
};
