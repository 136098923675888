import { IList } from '@interacta-shared/util';
import { UserExtendedDeserialize } from '@modules/core/models/user-group.model';
import { UserDeserilize } from '@modules/core/models/user.model';
import { Invitation } from '@modules/post/models/event-post.model';
import {
    IMemberWithRoles,
    Member,
    MemberWithCustomFlags,
} from './member.model';
import { isMemberGroup, isMemberUser, wrapMember } from './member.utils';

export const toMemberList = (
    record: any,
    totalCount?: number,
): IList<Member> => {
    const list = <IList<Member>>{
        list: record?.items
            ? (<Array<any>>record.items).map((item) => toMemberItem(item))
            : [],
        totalCount: totalCount || 0,
        nextPageToken: record.nextPageToken,
    };
    return list;
};

export const toMemberListWithCommunityMember = (
    record: any,
    totalCount?: number,
): IList<MemberWithCustomFlags> => {
    const list = {
        list: record?.items
            ? (<Array<any>>record.items).map((item) =>
                  toMemberWithCommunityFlagItem(item),
              )
            : [],
        totalCount: totalCount || 0,
        nextPageToken: record.nextPageToken,
    };
    return list;
};

export const toMemberWithRolesList = (
    record: any,
    totalCount: number,
): IList<IMemberWithRoles> => {
    const list = {
        list: [],
        totalCount: totalCount,
        nextPageToken: record?.nextPageToken,
    } as IList<IMemberWithRoles>;
    if (record?.items) {
        (<Array<any>>record.items).forEach((item) => {
            if (item.user) {
                const administrator =
                    UserExtendedDeserialize.administrator(item);
                const alreadyAdded = list.list.find(
                    (a) => isMemberUser(a) && a.user.id === administrator.id,
                );
                if (!alreadyAdded) {
                    list.list.push({
                        ...wrapMember(administrator),
                        roles: administrator.roles,
                    });
                } else {
                    alreadyAdded.roles.push(...administrator.roles);
                }
            } else {
                const administratorGroup =
                    UserExtendedDeserialize.administratorsGroup(item);
                const alreadyAdded = list.list.find(
                    (a) =>
                        isMemberGroup(a) &&
                        a.group.id === administratorGroup.id,
                );
                if (!alreadyAdded) {
                    list.list.push({
                        ...wrapMember(administratorGroup),
                        roles: administratorGroup.roles,
                    });
                } else {
                    alreadyAdded.roles.push(...administratorGroup.roles);
                }
            }
        });
    }
    list.totalCount = totalCount ?? list.list.length;
    return list;
};

export const toMemberItem = (record: any): Member => {
    if (record.user) {
        return {
            id: record.id,
            innerId: record.user.id,
            user: UserDeserilize.userDetails(record.user),
        };
    } else {
        return {
            id: record.id,
            innerId: record.group.id,
            group: UserExtendedDeserialize.usersGroup(record.group),
        };
    }
};

export const toInvitation = (record: any): Invitation => {
    const member = wrapMember(record.invitationUser || record.invitationGroup);
    if (isMemberUser(member)) {
        return {
            ...member,
            user: {
                ...member.user,
                fullName: record.name,
            },
            invitationTypeId: record.invitationUser.invitationTypeId,
            participant: {
                notes: record.invitationUser.participant?.notes,
                participationTypeId:
                    record.invitationUser.participant?.participationTypeId,
            },
        };
    } else {
        return {
            ...member,
            group: {
                ...member.group,
                fullName: record.name,
            },
            membersCount: record.invitationGroup.membersCount,
            invitationTypeId: record.invitationGroup.typeId,
            participantsResponseCountByTypeMap:
                record.invitationGroup.participantsResponseCountByTypeMap,
        };
    }
};

export const toMemberWithCommunityFlagItem = (
    record: any,
): MemberWithCustomFlags => ({
    ...toMemberItem(record),
    communityMember: record.communityMember ?? false,
});
