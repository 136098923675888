import { ValidatorFn } from '@angular/forms';
import { InputSelectItem } from '@interacta-shared/ui';
import { InteractaIcon } from '@interacta-shared/ui-icons';
import {
    AUDIO,
    ContentMimeType,
    DOC,
    G_DRAW,
    GenericContentMimeType,
    IMAGE,
    PDF,
    PPT,
    VIDEO,
    XLS,
    ZIP,
} from '@interacta-shared/util-common';
import { Member } from '@modules/core/models/member/member.model';
import {
    i18nDelta,
    i18nString,
    IconRating,
} from '@modules/core/models/utility.model';
import { MetadataActivation } from '../custom-metadata-activation/custom-metadata-activation.model';

export interface ICustomField {
    configuration: ICustomFieldDefinition;
    validators: ValidatorFn[];

    // These types are literally any, they are dynamic and dependent on the custom field's type
    // < Too much any and little type-safety makes Alberto a dull boy >

    /** The unparsed raw value obtained from the server */
    serverValue: unknown;
    /** The value shown in post's detail */
    viewValue: any;
    /** The value shown in post's creation/edit */
    inputValue: any;
}

export interface ICustomFieldMetadataBase {
    // FieldType.RATING
    feedback_icon_type?: IconRating;
    feedback_max_value?: number;
    feedback_min_value?: number;
    feedback_step?: number;

    // FieldType.GENERIC_LIST
    generic_entity_list_config_id?: MetadataGenericListType;
    catalog_id?: number;
    community_id?: number;
    hierarchical?: boolean;
    //USER PICKER
    user_picker_from_community?: boolean;
    user_picker_from_role?: number;
    user_picker_from_group?: number;

    //GROUP PICKER
    group_picker_from_workspace?: boolean;
    group_picker_from_community?: boolean;
    group_picker_from_role?: number;
    group_picker_from_prefix?: string;

    //user and group picker
    extends_post_visibility?: boolean;
    sends_notifications?: boolean;
    strict_order?: boolean;

    //POST_PICKER
    inverse_relation_order_by?: string;
    inverse_relation_order_desc?: boolean;
    inverse_relation_include_terminal_states?: boolean;

    //FILE_PICKER
    disabled_drive?: boolean;
    disabled_local_filesystem?: boolean;

    //COMMENT
    generate_post_comment?: boolean;

    // FieldType.DECIMAL
    decimal_digits?: number;

    // FieldType.STRING
    qr_code?: boolean; // qr.code
    qr_code_secret?: boolean; // qr.code.secret

    dynamic?: boolean; //all field types

    //'Int', 'Bigint', 'Decimal', 'Date', 'Datetime',
    field_group_id?: number;
}

export const METADATA_QR_CODE_DOT_NOTATION = 'qr.code';
export const METADATA_QR_CODE_SECRET_DOT_NOTATION = 'qr.code.secret';

export interface ICustomFieldMetadata extends ICustomFieldMetadataBase {
    // Unknown
    [key: string]: any;
}

export interface ICustomFieldDefinition {
    id: number;
    parentId?: number;
    leaf: boolean;
    name: string;
    label: any;
    type: FieldType;
    customFieldType: CustomFieldType;
    required: boolean;
    searchable: boolean;
    sortable: boolean;
    metadata: ICustomFieldMetadata;
    enumValues?: IEnumValues[];
    validations: IValidationMetadata[];
    readonly: boolean;
    visibleInCreate: boolean;
    visibleInDetail: boolean;
    visibleInEdit: boolean;
    visibleInPreview: boolean;
    fieldMode: MetadataFieldModeType;
    description: i18nDelta | null;
    listenChanges: boolean;
    deleted?: boolean;
}

export type IFieldMetadataScreen = ICustomFieldDefinition;

export interface IEnumValues {
    id: number;
    parentIds?: number[];
    label: i18nString;
    deleted: boolean;
    temporaryContentViewLink?: string;
    temporaryContentPreviewImageLink?: string;
    temporaryContentPreviewImageHiResLink?: string;
}

export interface IValidationMetadata {
    id: number;
    fieldId: number;
    validationType: ValidationType;
    parameter?: string;
    validationParameter?: unknown | unknown[]; //typed version of parameter
    order: number;
    activation?: MetadataActivation;
}

/** @deprecated DA USARE SOLO NELLA ADMIN LEGACY */
export type FieldMetadataAdminApiResponse = {
    id: number;
    fieldListChanged: boolean;
    occToken: string;
};

export enum MetadataGenericListType {
    CORE_USER = 'core.user',
    CORE_GROUP = 'core.group',
    CATALOG = 'communication.catalog',
    POST_LINK = 'communication.post',
    FILE_PICKER = 'communication.post_attachment',
}

export enum MetadataFieldModeType {
    SCREEN = 'screen',
    POST = 'post',
    ACK_TASK = 'ack-task-screen',
    SURVEY = 'survey',
}

export enum FieldType {
    INT = 1,
    BIGINT = 2,
    DECIMAL = 3,
    DATE = 4,
    DATETIME = 5,
    STRING = 6,
    SELECT = 7,
    SELECT_MULTIPLE_VALUES = 8,
    TEXT = 9,
    FLAG = 10,
    HTML = 11,
    RATING = 12,
    SELECT_HIERARCHICAL = 13,
    LINK = 14,
    GENERIC_LIST = 15,
}

export enum CustomFieldType {
    INT = 'Int',
    BIGINT = 'Bigint',
    DECIMAL = 'Decimal',
    DATE = 'Date',
    DATETIME = 'Datetime',
    STRING = 'String',
    SELECT = 'Select',
    SELECT_MULTIPLE_VALUES = 'Select Multiple',
    TEXT = 'Text',
    FLAG = 'Flag',
    HTML = 'HTML',
    RATING = 'Rating',
    SELECT_HIERARCHICAL = 'Select Hierarchical',
    LINK = 'Link',
    USER_PICKER = 'User Picker',
    GROUP_PICKER = 'Group Picker',
    POST_PICKER = 'Post Picker',
    FILE_PICKER = 'File Picker',
    CATALOG = 'Catalog',
    QR_CODE = 'QR Code',
    COMMENT = 'Comment',
}

export enum TranslationInputType {
    STRING = 1,
    TEXTAREA = 2,
    HTML = 3,
}

// validationTypeId from server side error
export enum ValidationType {
    REQUIRED_FIELD = 1,
    MIN_VALUE = 2,
    MAX_VALUE = 3,
    MIN_LENGTH = 4,
    MAX_LENGTH = 5,
    INVALID_VALUE = 6,
    ALREADY_EXISTS = 7,
    NOT_UNIQUE = 8,
    ENABLED = 10,
    ADMITTED_MIME_TYPES = 11,
}

export type MetadataGenericListValue =
    | null
    | number
    | { id: number }
    | (number | { id: number } | Member)[];

export interface ConfigurableFieldTypeInfo extends InputSelectItem {
    code: ConfigurableFieldType;
    icon: InteractaIcon;
    mapToTypes: FieldType[];
    disabled: boolean;
    bottomBorder?: boolean;
    minValueLabel?: string;
    maxValueLabel?: string;
}

export enum ConfigurableFieldType {
    STRING = 'STRING',
    PARAGRAPH = 'PARAGRAPH',
    HTML = 'HTML',
    QR_CODE = 'QR_CODE',
    COMMENT = 'COMMENT',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    FLAG = 'FLAG',
    LINK = 'LINK',
    FEEDBACK = 'FEEDBACK',
    SELECT_SINGLE = 'SELECT_SINGLE',
    SELECT_MULTI = 'SELECT_MULTI',
    POST_PICKER = 'POST_PICKER',
    USER_PICKER = 'USER_PICKER',
    GROUP_PICKER = 'GROUP_PICKER',
    FILE_PICKER = 'FILE_PICKER',
}
export interface ConfigurableMimeType extends InputSelectItem {
    iconPath: string;
    mimeTypes: string[];
}

export const ConfigurableMimeTypes: ConfigurableMimeType[] = [
    {
        id: 1,
        label: 'COMMUNITIES.CONFIGURABLE_MIME_TYPES.LABEL_ALL_IMAGE',
        iconPath: IMAGE,
        mimeTypes: [GenericContentMimeType.IMAGE, ContentMimeType.G_PHOTO],
    },
    {
        id: 2,
        label: 'COMMUNITIES.CONFIGURABLE_MIME_TYPES.LABEL_ALL_VIDEO',
        iconPath: VIDEO,
        mimeTypes: [GenericContentMimeType.VIDEO, ContentMimeType.G_VIDEO],
    },
    {
        id: 3,
        label: 'COMMUNITIES.CONFIGURABLE_MIME_TYPES.LABEL_ALL_AUDIO',
        iconPath: AUDIO,
        mimeTypes: [GenericContentMimeType.AUDIO, ContentMimeType.G_AUDIO],
    },
    {
        id: 4,
        label: 'COMMUNITIES.CONFIGURABLE_MIME_TYPES.LABEL_PDF_FILES',
        iconPath: PDF,
        mimeTypes: [ContentMimeType.PDF],
    },
    {
        id: 5,
        label: 'COMMUNITIES.CONFIGURABLE_MIME_TYPES.LABEL_ALL_DOCUMENTS',
        iconPath: DOC,
        mimeTypes: [
            ContentMimeType.TXT,
            ContentMimeType.DOC,
            ContentMimeType.DOC2,
            ContentMimeType.G_DOC,
        ],
    },
    {
        id: 6,
        label: 'COMMUNITIES.CONFIGURABLE_MIME_TYPES.LABEL_ALL_SPREADSHEETS',
        iconPath: XLS,
        mimeTypes: [
            ContentMimeType.CSV,
            ContentMimeType.XLS,
            ContentMimeType.XLS2,
            ContentMimeType.G_SHEET,
        ],
    },
    {
        id: 7,
        label: 'COMMUNITIES.CONFIGURABLE_MIME_TYPES.LABEL_ALL_PRESENTATIONS',
        iconPath: PPT,
        mimeTypes: [
            ContentMimeType.PPT,
            ContentMimeType.PPT2,
            ContentMimeType.G_SLIDES,
        ],
    },
    {
        id: 8,
        label: 'COMMUNITIES.CONFIGURABLE_MIME_TYPES.LABEL_ALL_DRAWINGS',
        iconPath: G_DRAW,
        mimeTypes: [
            GenericContentMimeType.DRAWING,
            ContentMimeType.G_DRAW,
            ContentMimeType.PSD,
            ContentMimeType.PHOTOSHOP,
            ContentMimeType.X_PHOTOSHOP,
        ],
    },
    {
        id: 9,
        label: 'COMMUNITIES.CONFIGURABLE_MIME_TYPES.LABEL_ALL_ARCHIVES',
        iconPath: ZIP,
        mimeTypes: [
            ContentMimeType.ZIP,
            ContentMimeType.GZIP,
            ContentMimeType.RAR,
        ],
    },
];

export const ConfigurableFieldTypes: ConfigurableFieldTypeInfo[] = [
    {
        id: 1,
        code: ConfigurableFieldType.STRING,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_SHORT_TEXT',
        icon: 'equals',
        mapToTypes: [FieldType.STRING],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_CHARS',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_CHARS',
    },
    {
        id: 2,
        code: ConfigurableFieldType.PARAGRAPH,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_LONG_TEXT',
        icon: 'paragraph',
        mapToTypes: [FieldType.TEXT],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_CHARS',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_CHARS',
    },
    {
        id: 3,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_FORMATTED_PARAGRAPH',
        code: ConfigurableFieldType.HTML,
        icon: 'code',
        mapToTypes: [FieldType.HTML],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_CHARS',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_CHARS',
    },
    {
        id: 17,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_COMMENT',
        code: ConfigurableFieldType.COMMENT,
        icon: 'comments',
        mapToTypes: [FieldType.HTML],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_CHARS',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_CHARS',
        bottomBorder: true,
    },
    {
        id: 4,
        code: ConfigurableFieldType.NUMBER,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_NUMBER',
        icon: 'calculator',
        mapToTypes: [FieldType.INT, FieldType.BIGINT, FieldType.DECIMAL],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_VALUE',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_VALUE',
    },
    {
        id: 5,
        code: ConfigurableFieldType.DATE,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_DATE',
        icon: 'date',
        mapToTypes: [FieldType.DATE],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_DATE',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_DATE',
    },
    {
        id: 6,
        code: ConfigurableFieldType.DATETIME,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_DATETIME',
        icon: 'clock',
        mapToTypes: [FieldType.DATETIME],
        disabled: false,
        bottomBorder: true,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_DATE',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_DATE',
    },
    {
        id: 7,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_SINGLE_SELECT',
        code: ConfigurableFieldType.SELECT_SINGLE,
        icon: 'single-choice',
        mapToTypes: [
            FieldType.SELECT,
            FieldType.SELECT_HIERARCHICAL,
            FieldType.GENERIC_LIST,
        ],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_SELECTABLE',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_SELECTABLE',
    },
    {
        id: 8,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_MULTI_SELECT',
        code: ConfigurableFieldType.SELECT_MULTI,
        icon: 'multiple-choice',
        mapToTypes: [FieldType.SELECT_MULTIPLE_VALUES, FieldType.GENERIC_LIST],
        disabled: false,
        bottomBorder: true,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_SELECTABLE',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_SELECTABLE',
    },
    {
        id: 9,
        code: ConfigurableFieldType.FLAG,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_FLAG',
        icon: 'toggle',
        mapToTypes: [FieldType.FLAG],
        disabled: false,
    },
    {
        id: 10,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_FEEDBACK',
        code: ConfigurableFieldType.FEEDBACK,
        icon: 'star-half',
        mapToTypes: [FieldType.RATING],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_VALUE',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_VALUE',
    },
    {
        id: 11,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_QR_CODE',
        code: ConfigurableFieldType.QR_CODE,
        icon: 'qr-code',
        mapToTypes: [FieldType.STRING],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_CHARS',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_CHARS',
    },
    {
        id: 12,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_LINK',
        code: ConfigurableFieldType.LINK,
        icon: 'link',
        mapToTypes: [FieldType.LINK],
        disabled: false,
        bottomBorder: true,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_CHARS',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_CHARS',
    },
    {
        id: 13,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_USER_PICKER',
        code: ConfigurableFieldType.USER_PICKER,
        icon: 'person',
        mapToTypes: [FieldType.GENERIC_LIST],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_SELECTABLE',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_SELECTABLE',
    },
    {
        id: 14,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_GROUP_PICKER',
        code: ConfigurableFieldType.GROUP_PICKER,
        icon: 'people',
        mapToTypes: [FieldType.GENERIC_LIST],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_SELECTABLE',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_SELECTABLE',
    },
    {
        id: 15,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_POST_PICKER',
        code: ConfigurableFieldType.POST_PICKER,
        icon: 'post',
        mapToTypes: [FieldType.GENERIC_LIST],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_SELECTABLE',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_SELECTABLE',
    },
    {
        id: 16,
        label: 'COMMUNITIES.CONFIGURABLE_FIELDS.LABEL_FILE_PICKER',
        code: ConfigurableFieldType.FILE_PICKER,
        icon: 'paperclip',
        mapToTypes: [FieldType.GENERIC_LIST],
        disabled: false,
        minValueLabel: 'COMMUNITIES.LABEL_MIN_SELECTABLE',
        maxValueLabel: 'COMMUNITIES.LABEL_MAX_SELECTABLE',
    },
];

export interface ICustomFieldAdminValidation {
    minValue?: string;
    maxValue?: string;
    maxSize?: number;
    admittedMimeTypes?: string[];
}

export interface ILink {
    label?: string;
    url: string;
}

export const ENUM_CATALOG_RADIO = {
    ENUM: 0,
    CATALOG: 1,
};

export const METADATA_RADIO_VALUES = {
    FROM_WORKSPACE: 0,
    FROM_COMMUNITY: 1,
    FROM_ROLE: 2,
    FROM_GROUP: 3,
    FROM_PREFIX: 4,
};

export const ANY_MIME_TYPE = 'ANY_MIME_TYPE';

export enum FILE_PICKER_SOURCE {
    ONLY_LOCAL,
    ONLY_DRIVE,
    BOTH,
}
