export enum GenericContentMimeType {
    IMAGE = 'image/*',
    VIDEO = 'video/*',
    AUDIO = 'audio/*',
    DRAWING = 'drawing/*',
    APPLICATION = 'application/*',
    TEXT = 'text/*',
}

export enum ContentMimeType {
    PDF = 'application/pdf',
    JPEG = 'image/jpeg',
    JPG = 'image/jpg',
    PNG = 'image/png',
    GIF = 'image/gif',
    DOC = 'application/msword',
    DOC2 = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLS = 'application/vnd.ms-excel',
    XLS2 = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    PPT = 'application/vnd.ms-powerpoint',
    PPT2 = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ZIP = 'application/zip',
    CSV = 'text/csv',
    TXT = 'text/plain',
    MP3 = 'audio/mp3',
    AUDIO_MPEG = 'audio/mpeg',
    AUDIO_MP4 = 'audio/mp4',
    WAV = 'audio/wav',
    AUDIO_OGG = 'audio/ogg',
    M4A = 'audio/x-m4a',
    MP4 = 'video/mp4',
    AVI = 'video/avi',
    WEBM = 'video/webm',
    OGG = 'video/ogg',
    WMV = 'video/x-ms-wmv',
    MOV = 'video/quicktime',
    MPEG = 'video/mpeg',

    PSD = 'application/psd',
    PHOTOSHOP = 'application/photoshop',
    X_PHOTOSHOP = 'application/x-photoshop',
    RAR = 'application/x-rar-compressed',
    GZIP = 'application/gzip',

    // Google Drive
    G_AUDIO = 'application/vnd.google-apps.audio',
    G_DOC = 'application/vnd.google-apps.document', // Google Docs
    G_SDK = 'application/vnd.google-apps.drive-sdk', // 3rd party shortcut
    G_DRAW = 'application/vnd.google-apps.drawing', // Google Drawing
    G_FILE = 'application/vnd.google-apps.file', // Google Drive file
    G_FOLDER = 'application/vnd.google-apps.folder', // Google Drive folder
    G_FORM = 'application/vnd.google-apps.form', // Google Forms
    G_FUSION = 'application/vnd.google-apps.fusiontable', // Google Fusion Tables
    G_MAPS = 'application/vnd.google-apps.map', // Google My Maps
    G_PHOTO = 'application/vnd.google-apps.photo',
    G_SLIDES = 'application/vnd.google-apps.presentation', // Google Slides
    G_SCRIPT = 'application/vnd.google-apps.script', // Google Apps Scripts
    G_SHORTCUT = 'application/vnd.google-apps.shortcut', // Shortcut
    G_SITE = 'application/vnd.google-apps.site', // Google Sites
    G_SHEET = 'application/vnd.google-apps.spreadsheet', // Google Sheets
    G_UNKNOWN = 'application/vnd.google-apps.unknown',
    G_VIDEO = 'application/vnd.google-apps.video',
}
