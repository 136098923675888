import { ICommunity } from '@modules/communities/models/communities.model';
import { HomeCommunityIndex } from '@modules/core';
import { CallerLocation } from '@modules/dashboard/components/dashboard-filters-dialog/dashboard-filters-dialog.component';
import {
    AttachmentCategoryType,
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import {
    QuickFilter,
    QuickFiltersList,
} from '@modules/post/models/quick-filters/quick-filters.model';
import { createAction, props } from '@ngrx/store';

export type CommunityError = any;

enum CommunityActionAPIType {
    FetchAttachmentsSuccess = '[Community API] Fetch Attachments Success',
    FetchAttachmentsError = '[Community API] Fetch Attachments Error',

    FetchAttachmentsPageSuccess = '[Community API] Fetch Attachments Page Success',
    FetchAttachmentsPageError = '[Community API] Fetch Attachments Page Error',

    DeleteAttachmentsSuccess = '[Community API] Delete Attachments Success',
    DeleteAttachmentsError = '[Community API] Delete Attachments Error',

    GetRecentCommunitiesSuccess = '[Community API] Get Recent Communities Success',
    GetRecentCommunitiesError = '[Community API] Get Recent Communities Error',

    MassiveUploadHashtagsSuccess = '[Community API] Massive Upload Hashtags Success',
    MassiveUploadHashtagsError = '[Community API] Massive Upload Hashtags Error',

    FetchQuickFiltersSuccess = '[Community] Fetch Quick Filters Success',
    FetchQuickFiltersError = '[Community] Fetch Quick Filters Error',

    CreateCustomFilterSuccess = '[Community API] Create Custom Filter Success',
    CreateCustomFilterError = '[Community API] Create Custom Filter Error',

    DeleteCustomFilterSuccess = '[Community API] Delete Custom Filter Success',
    DeleteCustomFilterError = '[Community API] Delete Custom Filter Error',

    ActualizeQuickFilterSuccess = '[Community API] Actualize Quick Filter Success',
    ActualizeQuickFilterError = '[Community API] Actualize Quick Filter Error',

    ModifyCustomFilterSuccess = '[Community API] Modify Custom Filter Success',
    ModifyCustomFilterError = '[Community API] Modify Custom Filter Error',

    InitializeQuickFiltersSuccess = '[Communty API] Initialize Quick Filters Success',
    InitializeQuickFiltersError = '[Community API] Initialize Quick Filters Error',

    ChangeQuickFiltersOrderSuccess = '[Community API] Change Quick Filters Order Success',
    ChangeQuickFiltersOrderError = '[Community API] Change Quick Filters Order Error',
}

export const fetchAttachmentsSuccess = createAction(
    CommunityActionAPIType.FetchAttachmentsSuccess,
    props<{
        communityId: number;
        attachmentsList: IListAttachments;
        attachmentType?: AttachmentCategoryType;
        mediasCount?: number;
        documentsCount?: number;
    }>(),
);

export const fetchAttachmentsError = createAction(
    CommunityActionAPIType.FetchAttachmentsError,
    props<{
        communityId: number;
        error: CommunityError;
        attachmentType?: AttachmentCategoryType;
    }>(),
);

export const fetchAttachmentsPageSuccess = createAction(
    CommunityActionAPIType.FetchAttachmentsPageSuccess,
    props<{
        communityId: number;
        attachmentsList: IListAttachments;
        attachmentType?: AttachmentCategoryType;
    }>(),
);

export const fetchAttachmentsPageError = createAction(
    CommunityActionAPIType.FetchAttachmentsPageError,
    props<{
        communityId: number;
        error: CommunityError;
        attachmentType?: AttachmentCategoryType;
    }>(),
);

export const deleteAttachmentsSuccess = createAction(
    CommunityActionAPIType.DeleteAttachmentsSuccess,
    props<{
        ids: IAttachment['id'][];
    }>(),
);

export const deleteAttachmentsError = createAction(
    CommunityActionAPIType.DeleteAttachmentsError,
    props<{
        ids: IAttachment['id'][];
        error: CommunityError;
    }>(),
);

export const getRecentCommunitiesSuccess = createAction(
    CommunityActionAPIType.GetRecentCommunitiesSuccess,
    props<{ communities: ICommunity[] }>(),
);

export const getRecentCommunitiesError = createAction(
    CommunityActionAPIType.GetRecentCommunitiesError,
    props<{ error: CommunityError }>(),
);

export const massiveUploadHashtagsSuccess = createAction(
    CommunityActionAPIType.MassiveUploadHashtagsSuccess,
    props<{ updatedAttachments: IAttachment[] }>(),
);

export const massiveUploadHashtagsError = createAction(
    CommunityActionAPIType.MassiveUploadHashtagsError,
    props<{ error: CommunityError }>(),
);

export const fetchQuickFiltersSuccess = createAction(
    CommunityActionAPIType.FetchQuickFiltersSuccess,
    props<{ quickFilters: QuickFiltersList }>(),
);

export const fetchQuickFiltersError = createAction(
    CommunityActionAPIType.FetchQuickFiltersError,
    props<{ error: CommunityError }>(),
);

export const createCustomFilterSuccess = createAction(
    CommunityActionAPIType.CreateCustomFilterSuccess,
    props<{
        quickFilter: QuickFilter;
        communityId: HomeCommunityIndex;
        callerLocation: CallerLocation;
    }>(),
);

export const createCustomFilterError = createAction(
    CommunityActionAPIType.CreateCustomFilterError,
    props<{ error: CommunityError }>(),
);

export const deleteCustomFilterSuccess = createAction(
    CommunityActionAPIType.DeleteCustomFilterSuccess,
    props<{ filters: QuickFilter[]; homeCommunityIndex: HomeCommunityIndex }>(),
);

export const deleteCustomFilterError = createAction(
    CommunityActionAPIType.DeleteCustomFilterError,
    props<{ error: CommunityError }>(),
);

export const actualizeQuickFilterSuccess = createAction(
    CommunityActionAPIType.ActualizeQuickFilterSuccess,
    props<{
        quickFilter: QuickFilter;
        homeCommunityIndex: HomeCommunityIndex;
    }>(),
);

export const actualizeQuickFilterError = createAction(
    CommunityActionAPIType.ActualizeQuickFilterError,
    props<{ error: CommunityError }>(),
);

export const modifyCustomFilterSuccess = createAction(
    CommunityActionAPIType.ModifyCustomFilterSuccess,
    props<{ filter: QuickFilter; homeCommunityIndex: HomeCommunityIndex }>(),
);

export const modifyCustomFilterError = createAction(
    CommunityActionAPIType.ModifyCustomFilterError,
    props<{ error: CommunityError }>(),
);

export const changeQuickFiltersOrderSuccess = createAction(
    CommunityActionAPIType.ChangeQuickFiltersOrderSuccess,
    props<{
        filters: QuickFiltersList;
        homeCommunityIndex: HomeCommunityIndex;
    }>(),
);

export const changeQuickFiltersOrderError = createAction(
    CommunityActionAPIType.ChangeQuickFiltersOrderError,
    props<{ error: CommunityError }>(),
);

export const initializeQuickFiltersSuccess = createAction(
    CommunityActionAPIType.InitializeQuickFiltersSuccess,
    props<{
        quickFilters: QuickFiltersList;
        homeCommunityIndex: HomeCommunityIndex;
    }>(),
);

export const initializeQuickFiltersError = createAction(
    CommunityActionAPIType.InitializeQuickFiltersError,
    props<{ error: CommunityError }>(),
);
