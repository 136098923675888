import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import * as PostApiActions from '@modules/communities/store/post/post-api.actions';
import { createReducer, on } from '@ngrx/store';
import * as UploadActions from './upload.actions';

export interface SessionAttachmentsCounters {
    remainingAttachments: number;
    saveAttachmentsApiCalls: number;
}
export interface UploadState {
    isDialogOpen: boolean;
    isDialogCompact: boolean;
    sessionAttachmentsCounterMap: Record<string, SessionAttachmentsCounters>;
    hashtagsToAttach: IHashTag[];
}

export const initialState: UploadState = {
    isDialogOpen: false,
    isDialogCompact: false,
    sessionAttachmentsCounterMap: {},
    hashtagsToAttach: [],
};

export const uploadReducer = createReducer<UploadState>(
    initialState,
    on(UploadActions.openDialog, (state) => ({
        ...state,
        isDialogOpen: true,
    })),
    on(UploadActions.closeDialog, (state) => {
        return {
            ...state,
            isDialogOpen: false,
        };
    }),
    on(UploadActions.collapseDialog, (state) => ({
        ...state,
        isDialogOpen: true,
        isDialogCompact: true,
    })),
    on(UploadActions.expandDialog, (state) => ({
        ...state,
        isDialogOpen: true,
        isDialogCompact: false,
    })),

    on(UploadActions.updateSessionAttachmentsMap, (state, props) => {
        const actualMapValue =
            state.sessionAttachmentsCounterMap[props.sessionId]
                ?.remainingAttachments ?? 0;

        return {
            ...state,
            sessionAttachmentsCounterMap: {
                ...state.sessionAttachmentsCounterMap,
                [props.sessionId]: {
                    remainingAttachments: actualMapValue + props.counter,
                    saveAttachmentsApiCalls: 0,
                },
            },
        };
    }),

    on(
        PostApiActions.addAttachmentsSuccess,
        PostApiActions.addAttachmentsError,
        (state, props) => {
            if (!props.uploadSessionId) {
                return state;
            }
            const actualMapValue =
                state.sessionAttachmentsCounterMap[props.uploadSessionId];
            const actualAttachmentsCounter =
                actualMapValue?.remainingAttachments;
            const actualApiCalls = actualMapValue?.saveAttachmentsApiCalls ?? 0;

            return {
                ...state,
                sessionAttachmentsCounterMap: {
                    ...state.sessionAttachmentsCounterMap,
                    [props.uploadSessionId]: {
                        saveAttachmentsApiCalls: actualApiCalls + 1,
                        remainingAttachments: actualAttachmentsCounter
                            ? actualAttachmentsCounter -
                              props.attachments.length
                            : 0,
                    },
                },
                hashtagsToAttach: [],
            };
        },
    ),

    on(UploadActions.addHashtagsToAttach, (state, props) => ({
        ...state,
        hashtagsToAttach: props.hashtags,
    })),
);
