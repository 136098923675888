import { toEditableTableData } from '@modules/editable-table';
import { QuillTable } from './quill-table.model';

export const toQuillTables = (tables: any): QuillTable[] =>
    (<Array<any>>tables).map(toQuillTable);

export const toQuillTable = (table: any): QuillTable => ({
    clientUid: table.clientUid,
    title: table.title,
    id: table.id,
    data: toEditableTableData(table.data as string),
});
