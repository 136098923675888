import { CustomFields } from '@modules/communities/models/custom-metadata/CustomFields.class';
import {
    IWorkflowScreen,
    IWorkflowStatus,
} from '@modules/communities/models/workflow/workflow.model';
import { idArraytoMap } from '@modules/core/helpers/generic.utils';
import { OnChangeMessage } from '@modules/post/models/listen-changes/listen-changes.model';
import { ICustomFieldDefinition } from './custom-metadata/custom-metadata.model';
import { fromServerCustomFields } from './custom-metadata/custom-metadata.serialize';
import { toWorkflowStatus } from './workflow/workflow-base.deserialize';
import { toWorkflowScreen } from './workflow/workflow.deserialize';
import { QuillTable } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { toQuillTables } from '@modules/shared-v2/models/quill-table/quill-table.deserialize';
import { fromQuillTables } from '@modules/shared-v2/models/quill-table/quill-table.serialize';

export interface IScreen {
    id: number;
    postId: number;
    customFields: CustomFields;
    workflowScreen: IWorkflowScreen;
    currentWorkflowState?: IWorkflowStatus;
    occToken?: string;
    tables?: QuillTable[];
}

export type IScreenCreate = IScreen;

export type IScreenEdit = IScreenCreate;

export interface IScreenContext {
    postId: number;
    workflowOperationId?: number;
    customFields: CustomFields;
}

export type ScreenOnChangeType = {
    screen: IScreen;
    dataHasChanged: boolean;
    messageInfo: OnChangeMessage | null;
};

export class ScreenDeserialize {
    public static screenDetails = (
        postId: number,
        fieldDefinitions: ICustomFieldDefinition[],
        record: any,
        workflow: IWorkflowScreen | null = null,
    ): IScreen => {
        const workflowScreen = workflow ?? toWorkflowScreen(record.screen);
        const fieldDefinitionsMap = idArraytoMap(fieldDefinitions);
        const fieldDefinitionsWithEnumValues =
            workflowScreen.fieldMetadatas.map((field) => ({
                ...field,
                enumValues: fieldDefinitionsMap[field.id]?.enumValues,
            }));
        return {
            postId,
            id: record.screen.id,
            workflowScreen,
            currentWorkflowState: toWorkflowStatus(record.currentWorkflowState),
            customFields: new CustomFields(
                fieldDefinitionsWithEnumValues,
                record.screenData,
            ),
            occToken: record.screenOccToken,
            tables: record.screenTables
                ? toQuillTables(record.screenTables)
                : undefined,
        };
    };

    public static screenDetailsForEdit = (
        postId: number,
        fieldDefinitions: ICustomFieldDefinition[],
        record: any,
    ): IScreenEdit => {
        const screen = ScreenDeserialize.screenDetails(
            postId,
            fieldDefinitions,
            record,
        );
        return screen;
    };
}

export class ScreenSerialize {
    public static screenDetails = (screen: IScreen): any => ({
        screenData: fromServerCustomFields(screen.customFields.data),
    });

    public static executeScreenInTransition = (
        screen: IScreenEdit | null,
    ): any => {
        if (!screen) return {};
        const record = ScreenSerialize.screenDetails(screen);
        record.screenOccToken = screen.occToken;
        record.tables = screen.tables
            ? fromQuillTables(screen.tables)
            : undefined;
        return record;
    };

    public static newScreen = (screen: IScreenEdit): any => {
        const record = ScreenSerialize.screenDetails(screen);
        return record;
    };

    public static editScreen = (screen: IScreenEdit): any => {
        const record = ScreenSerialize.screenDetails(screen);
        record.tables = screen.tables
            ? fromQuillTables(screen.tables)
            : undefined;
        return record;
    };

    public static screenContext = (screen: IScreenContext): any => ({
        postId: screen.postId,
        workflowOperationId: screen.workflowOperationId,
        screenData: fromServerCustomFields(screen.customFields.data),
    });
}
