import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';
import { openWidthAnimation } from '../../animations';
import { Palette } from '../../model';

@Component({
    selector: 'interacta-chip-choice',
    templateUrl: './chip-choice.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [openWidthAnimation('icon', '*', 200)],
    standalone: true,
    imports: [NgClass, NgIf, IconComponent],
})
export class ChipChoiceComponent {
    @Input() label = '';
    @Input() disabled = false;
    @Input() icon?: InteractaIcon;
    @Input() bgColor: Extract<Palette, 'primary-300' | 'gray-300'> =
        'primary-300';
    @Input() size: 'regular' | 'small' = 'regular';
    @Input() counter?: number;

    @Output() toggle = new EventEmitter<void>();
}
