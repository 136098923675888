import { Pipe, PipeTransform } from '@angular/core';
import { PaginatedList, getTotalCount } from '@interacta-shared/util';

@Pipe({
    name: 'totalCount',
})
export class TotalCountPipe<T> implements PipeTransform {
    transform(value: PaginatedList<T>): number | undefined {
        return getTotalCount(value);
    }
}
