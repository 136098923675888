<button
    type="button"
    [class]="
        'typo-sm hover:bg-surface-100 flex w-full select-none items-center gap-8 px-16 py-8 text-left transition-colors ' +
        classes
    "
>
    <!-- Deprecated
         Use content projection instead
    -->
    <interacta-icon
        *ngIf="icon"
        class="h-24 w-24"
        [icon]="icon"
    ></interacta-icon>
    <span *ngIf="label">{{ label }}</span>
    <!-- ----------------------------- -->

    <ng-content></ng-content>
</button>
