<div class="flex h-full w-full flex-col">
    <div>
        <interacta-form-field-v2
            [control]="searchControl"
            [description]="'UI.TIME_PICKER.SELECT_TIMEZONE' | translate"
            [name]="''"
        >
            <interacta-input-text-v2
                [control]="searchControl"
                [icon]="'search-left'"
                [iconClickable]="false"
                [iconPosition]="'right'"
            ></interacta-input-text-v2>
        </interacta-form-field-v2>
    </div>
    <div class="min-h-136 flex grow flex-col overflow-y-auto">
        <button
            *ngFor="let timezone of filteredTimezones"
            class="hover:bg-surface-100 flex select-none flex-col gap-4 px-16 py-8 text-left transition-colors"
            type="button"
            (click)="selectTimezone(timezone)"
        >
            <span class="typo-sm-bold">{{ timezone?.utcOffset }}</span>
            <span class="typo-sm">{{ timezone?.formattedZone }}</span>
        </button>

        <div
            *ngIf="!filteredTimezones.length"
            class="text-text-mid-contrast typo-sm flex flex-col py-20 text-center"
        >
            <span>{{ 'UI.NO_RESULT' | translate }}</span>
            <span>{{ 'UI.NO_RESULT_HINT' | translate }}</span>
        </div>
    </div>
</div>
