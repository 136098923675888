import { formatDateUsingIntl } from '@interacta-shared/util-common';
import { TranslateService } from '@ngx-translate/core';
import {
    format,
    isThisWeek,
    isThisYear,
    isToday,
    isTomorrow,
    isYesterday,
} from 'date-fns';
import { DateFormattedLabels } from '../models/date-formatted-labels';

export const hoursMinutes = (locale: Locale, date: Date): string => {
    return format(date, 'p', { locale });
};

export const dayOfWeekAndTime = (locale: Locale, date: Date): string => {
    return format(date, 'eee p', { locale });
};

export const dayOfTheMonthAndTime = (locale: Locale, date: Date): string => {
    if (!locale.code) {
        throw new Error("locale.code can't be null");
    }
    // Can't format without the year using date-fns
    const day = formatDateUsingIntl(
        date,
        {
            month: 'short',
            day: 'numeric',
        },
        'PP',
        locale.code,
    );

    const time = format(date, 'p', { locale });

    return `${day} ${time}`;
};

export const dayOfTheMonthAndYear = (locale: Locale, date: Date): string => {
    return format(date, 'PP', { locale });
};

export const getTranslatedDateFormattedLabel = (
    translateService: TranslateService,
    expirationLabels: DateFormattedLabels,
    locale: Locale,
    date: Date,
): string => {
    const { yesterday, today, tomorrow, onDay, atDate } = expirationLabels;

    if (isYesterday(date)) {
        if (!yesterday) throw new Error(`yesterday's label can't be null`);
        return translateService.instant(yesterday, {
            time: hoursMinutes(locale, date),
        });
    }

    if (isToday(date)) {
        return translateService.instant(today, {
            time: hoursMinutes(locale, date),
        });
    }

    if (isTomorrow(date)) {
        if (!tomorrow) throw new Error(`tomorrow's label can't be null`);
        return translateService.instant(tomorrow, {
            time: hoursMinutes(locale, date),
        });
    }

    if (isThisWeek(date)) {
        return translateService.instant(onDay, {
            dayAndTime: dayOfWeekAndTime(locale, date),
        });
    }

    if (isThisYear(date)) {
        return translateService.instant(atDate, {
            date: dayOfTheMonthAndTime(locale, date),
        });
    }

    return translateService.instant(atDate, {
        date: dayOfTheMonthAndYear(locale, date),
    });
};
