import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InputSelectSearchEvent } from '@interacta-shared/ui';
import { PaginatedList, toPaginatedList } from '@interacta-shared/util';
import { Tag } from '@modules/core/models/tag/tag.model';
import { SurveyPostDeserialize } from '@modules/post/models/survey-post/survey-post.deserialize';
import { SurveyRecipientsGroup } from '@modules/post/models/survey-post/survey-post.model';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TagService {
    private baseUrlPeople = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/people`;

    constructor(private http: HttpClient) {}

    fetchTagList(
        search: InputSelectSearchEvent,
        communityId: number | null,
    ): Observable<PaginatedList<Tag>> {
        const pageToken = search.nextPageToken ?? undefined;
        const filter = {
            name: search.text,
            pageToken,
            pageSize: 10,
            communityId,
            calculateTotalItemsCount: !pageToken,
        };

        return this.http
            .post<{
                items: any;
                totalItemsCount: number | null;
                nextPageToken: string | null;
            }>(`${this.baseUrlPeople}/tags`, filter)
            .pipe(map((res) => toPaginatedList<Tag>(res)));
    }

    getTagGroups(
        search: InputSelectSearchEvent,
        tagId: Tag['id'],
        communityId: number | null,
    ): Observable<PaginatedList<SurveyRecipientsGroup>> {
        const pageToken = search.nextPageToken ?? undefined;
        const fullTextFilter = search.text;
        const filter = {
            fullTextFilter,
            pageToken,
            pageSize: 10,
            calculateTotalItemsCount: !pageToken && !fullTextFilter?.length,
            communityId,
        };

        return this.http
            .post<{
                items: any;
                totalItemsCount: number | null;
                nextPageToken: string | null;
            }>(`${this.baseUrlPeople}/tags/${tagId}/groups`, filter)
            .pipe(
                map((res) =>
                    toPaginatedList({
                        ...res,
                        items: (res.items || []).map((i: any) =>
                            SurveyPostDeserialize.toSurveyRecipientsGroup(i),
                        ),
                    }),
                ),
            );
    }
}
