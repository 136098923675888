<interacta-profiled-user
    *ngIf="author"
    [fontWeight]="fontWeight"
    [memberClickBehavior]="memberClickBehavior"
    [rtl]="rtl"
    [showInfo]="showInfo"
    [size]="size"
    [user]="author"
/>
<interacta-users-group
    *ngIf="group"
    [canClick]="memberClickBehavior === 'preview'"
    [fontWeight]="fontWeight"
    [group]="group"
    [rtl]="rtl"
    [showInfo]="showInfo"
    [size]="size"
/>
