<interacta-dialog
    [isOpen]="isOpen"
    [size]="'small'"
    (closing)="closeDialog.emit()"
>
    <h6 class="typo-t6" title>
        {{ 'TAG_MEMBER_PICKER.EXTENDED_FIELD_DIALOG.TITLE' | translate }}
    </h6>

    <p
        class="typo-sm text-text"
        subtitle
        [innerHTML]="
            'TAG_MEMBER_PICKER.EXTENDED_FIELD_DIALOG.SELECTED_COUNT'
                | translate: { count: tags.length + members.length }
        "
    ></p>

    <div class="flex min-h-0 w-full flex-col" content>
        <div
            *ngIf="tags.length || members.length"
            class="h-500 mt-16 flex flex-col gap-y-8 overflow-y-auto"
        >
            <p *ngIf="tags.length" class="typo-xs-bold text-text-primary pt-8">
                {{ 'TAG_MEMBER_PICKER.TAGS' | translate | uppercase }}
            </p>
            <div class="flex flex-col gap-y-8">
                @for (tag of tags; track tag.id) {
                    <div
                        class="text-text bg-surface-B flex w-fit shrink-0 cursor-default items-center rounded-3xl px-8 py-4"
                        (click)="$event.stopPropagation()"
                    >
                        <interacta-tag-splittable
                            [recipientsTagExcludedGroups]="
                                recipientsTagExcludedGroups
                            "
                            [tag]="tag"
                            (tagInfo)="splitTag(tag)"
                        >
                        </interacta-tag-splittable>
                        <button
                            *ngIf="!lockedIds.includes(tag.id)"
                            class="-my-4 ml-8"
                            interacta-icon-button
                            type="button"
                            [attr.aria-label]="
                                'TAG_MEMBER_PICKER.UNSELECT' | translate
                            "
                            [icon]="'close'"
                            [matTooltip]="
                                'TAG_MEMBER_PICKER.UNSELECT' | translate
                            "
                            [size]="'small'"
                            (click)="
                                removeItemByChip(tag); $event.stopPropagation()
                            "
                        ></button>
                    </div>
                }
            </div>

            <p
                *ngIf="members.length"
                class="typo-xs-bold text-text-primary pt-8"
            >
                {{ 'TAG_MEMBER_PICKER.MEMBERS' | translate | uppercase }}
            </p>

            <div class="flex flex-col space-y-8">
                @for (member of members; track member.id) {
                    <div
                        class="text-text bg-surface-B flex w-fit shrink-0 cursor-default items-center rounded-3xl px-8 py-4"
                        (click)="$event.stopPropagation()"
                    >
                        <interacta-member
                            [fontWeight]="'regular'"
                            [member]="member"
                            [memberClickBehavior]="'none'"
                            [size]="'small'"
                        ></interacta-member>
                        <button
                            *ngIf="!lockedIds.includes(member.id)"
                            class="-my-4 ml-8"
                            interacta-icon-button
                            type="button"
                            [attr.aria-label]="
                                'TAG_MEMBER_PICKER.UNSELECT' | translate
                            "
                            [icon]="'close'"
                            [matTooltip]="
                                'TAG_MEMBER_PICKER.UNSELECT' | translate
                            "
                            [size]="'small'"
                            (click)="
                                removeItemByChip(member);
                                $event.stopPropagation()
                            "
                        ></button>
                    </div>
                }
            </div>
        </div>

        <interacta-no-result
            *ngIf="tags.length === 0 && members.length === 0"
            class="align-middle"
            imageName="no-results"
            [subtitle]="'UI.NO_RESULT_HINT' | translate"
            [title]="'DETAIL.NO_USERS_RESULT_1' | translate"
        ></interacta-no-result>
    </div>

    <interacta-dialog-buttons>
        <button
            appearance="ghost"
            interacta-dialog-button
            type="button"
            [label]="'BUTTON.LABEL_BUTTON_CANCEL' | translate"
            (click)="closeDialog.emit()"
        ></button>
        <button
            appearance="fill"
            interacta-dialog-button
            type="button"
            [bgColor]="'primary'"
            [label]="'SHARED.CONFIRM_DIALOG.DEFAULT_CONFIRM' | translate"
            (click)="confirm()"
        ></button>
    </interacta-dialog-buttons>
</interacta-dialog>
