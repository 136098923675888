import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService, SessionState } from '@interacta-shared/data-access-auth';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { filterMap, isDefined } from '@interacta-shared/util';
import {
    CancelledConnection,
    CommunitiesChannelStatus,
    CommunityChannelStatus,
    FirebasePushChannelDeserialize,
    IFirebasePushChannelConnection,
    IFirebasePushChannels,
    IFirebasePushChannelsClientTransport,
    PostChannelStatus,
    PostsChannelStatus,
    PushChannelsStatus,
    UserChannelStatus,
} from '@modules/core/models/firebase-push-channel.model';
import { deleteApp, initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import {
    DatabaseReference,
    getDatabase,
    off,
    onValue,
    ref,
} from 'firebase/database';
import {
    BehaviorSubject,
    Observable,
    forkJoin,
    from,
    of,
    throwError,
    timer,
} from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    mapTo,
    mergeMap,
    retryWhen,
    switchMap,
    tap,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PushChannelsService {
    private readonly commonApiBasePath = inject(ENVIRONMENT).apiBasePath.common;
    private readonly baseUrl = `${this.commonApiBasePath}/internal/v2/communication/update-notifications`;

    private firebasePushChannelsClientTransports: IFirebasePushChannelsClientTransport | null;
    private userChannelDataStream: BehaviorSubject<UserChannelStatus | null>;
    private postChannelDataStream: BehaviorSubject<PostsChannelStatus | null>;
    private communitiesChannelDataStream: BehaviorSubject<CommunitiesChannelStatus | null>;
    private pushChannelsStatus: PushChannelsStatus;
    private pushChannelsClientTransportsConnectionFailedAttemps: number;
    private userChannelDatabaseReference?: DatabaseReference;
    private communitiesChannelDatabaseReferences: Map<
        number,
        DatabaseReference
    > = new Map();
    private postsChannelDatabaseReferences: Map<number, DatabaseReference> =
        new Map();

    private firebaseAppNameDiscriminator = 1;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) {
        this.firebasePushChannelsClientTransports = null;
        this.userChannelDataStream =
            new BehaviorSubject<UserChannelStatus | null>(null);
        this.postChannelDataStream =
            new BehaviorSubject<PostsChannelStatus | null>(null);
        this.communitiesChannelDataStream =
            new BehaviorSubject<CommunitiesChannelStatus | null>(null);
        this.pushChannelsStatus = PushChannelsStatus.DISCONNECTED;
        this.pushChannelsClientTransportsConnectionFailedAttemps = 0;

        this.authService.sessionState$.subscribe((sessionState) =>
            this.onSessionStateChanged(sessionState),
        );
    }

    getUserPostsLastUpdateTimestamp$(): Observable<number> {
        return this.userChannelDataStream.pipe(
            filterMap((status) => status?.posts?.lastUpdateTimestamp),
            distinctUntilChanged(),
            tap((item) =>
                console.debug(
                    'Firebase User channel posts lastUpdateTimestamp: ',
                    item,
                ),
            ),
        );
    }

    /**
     * returns lastNotificationTimestamp as number or null if current user
     * has NOT yet received any notification
     */
    getUserNotificationsLastUpdateTimestamp$(): Observable<number | null> {
        return this.userChannelDataStream.pipe(
            filter(isDefined),
            map((status) => status.notifications?.lastUpdateTimestamp ?? null),
            distinctUntilChanged(),
            tap((item) =>
                console.debug(
                    'Firebase User channel notifications lastUpdateTimestamp: ',
                    item,
                ),
            ),
        );
    }

    getCommunityChannelStream(
        communityId: number,
    ): Observable<CommunityChannelStatus> {
        throw Error('Not implemented');
        // return this.getCommunitiesChannelStream().pipe(
        //     filter((item) => item != null && item.communityId === communityId),
        //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        //     map((item) => item!.communityStatus),
        // );
    }

    getCommunitiesChannelStream(): Observable<CommunitiesChannelStatus | null> {
        throw Error('Not implemented');
        // return this.communitiesChannelDataStream
        //     .asObservable()
        //     .pipe(
        //         tap((item) =>
        //             console.debug('Firebase communities channel stream', item),
        //         ),
        //     );
    }

    getPostLiveCommentsLastUpdateTimestamp$(
        postId: number,
    ): Observable<number> {
        return this.postChannelDataStream.asObservable().pipe(
            filterMap((item) =>
                item != null && item.postId === postId
                    ? item?.postStatus || null
                    : undefined,
            ),
            map((status) => status?.['live-comments'].lastUpdateTimestamp),
            filterMap((lastUpdateTimestamp) => lastUpdateTimestamp),
            distinctUntilChanged(),
            tap((item) =>
                console.debug(
                    'Firebase Posts channel live comments lastUpdateTimestamp: ',
                    item,
                ),
            ),
        );
    }

    registerToPostLiveStreamingChannel(postId: number): Observable<number> {
        console.log(
            `Trying to initialize Firebase post ${postId} channel connection`,
        );

        if (this.postsChannelDatabaseReferences.get(postId) != null) {
            return this.getPostLiveCommentsLastUpdateTimestamp$(postId);
        }

        const pushChannelTransport =
            this.firebasePushChannelsClientTransports != null
                ? of(this.firebasePushChannelsClientTransports)
                : this.firebaseLoader();

        return forkJoin([
            pushChannelTransport,
            this.http.get(`${this.baseUrl}/post-changes-channel/${postId}`),
        ]).pipe(
            switchMap(([channelTransport, postNotificationChannel]) => {
                if (!channelTransport || !postNotificationChannel) {
                    return throwError(
                        new CancelledConnection('Canceled operation.'),
                    );
                }

                const postChannelData =
                    FirebasePushChannelDeserialize.createPostChannels(
                        postNotificationChannel,
                        channelTransport,
                    );

                const postChannelDatabaseReference = ref(
                    postChannelData.clientTransport.firebaseDB,
                    postChannelData.firebaseChannelPath,
                );

                onValue(
                    postChannelDatabaseReference,
                    (snapshot) => {
                        const sharedState: PostChannelStatus = snapshot.val();
                        this.postChannelDataStream.next({
                            postId,
                            postStatus: sharedState,
                        });
                    },
                    (error: any) =>
                        console.error(
                            `Firebase post ${+postId} channel onValue error`,
                            error,
                        ),
                );

                this.postsChannelDatabaseReferences.set(
                    postId,
                    postChannelDatabaseReference,
                );

                return this.getPostLiveCommentsLastUpdateTimestamp$(postId);
            }),
        );
    }

    unRegisterToPostLiveStreamingChannel(postId: number): void {
        const databaseReference =
            this.postsChannelDatabaseReferences.get(postId);
        if (databaseReference) {
            console.debug(`detach Firebase post ${postId} channel`);
            off(databaseReference);
        } else {
            console.debug(`CANNOT detach Firebase post ${postId} channel`);
        }
    }

    private onSessionStateChanged(sessionState: SessionState) {
        if (sessionState.signedIn) {
            if (this.pushChannelsStatus == PushChannelsStatus.DISCONNECTED) {
                this.pushChannelsStatus = PushChannelsStatus.TRYING_TO_CONNECT;
                this.pushChannelsClientTransportsConnectionFailedAttemps = 0;

                console.log('Trying to initialize Firebase connections ...');
                forkJoin([
                    this.firebaseLoader(),
                    this.http.get(`${this.baseUrl}/user-notifications-channel`),
                    /*  this.http.get(
                        `${this.baseUrl}/communities-changes-channels`,
                    ), */
                ])
                    .pipe(
                        mergeMap(
                            ([
                                defaultClientTransport,
                                userNotificationsChannel,
                                //  communitiesChangesChannel,
                            ]) => {
                                if (PushChannelsStatus.TRYING_TO_CONNECT) {
                                    const userChannelData: IFirebasePushChannelConnection =
                                        FirebasePushChannelDeserialize.createUserChannels(
                                            {
                                                response:
                                                    userNotificationsChannel,
                                                defaultClientTransport,
                                            },
                                        );

                                    /* Communites channels are currently NOT used at all:
                                     * BE does not send messages on that channels.
                                     */

                                    // const communitiesChannelsData: {
                                    //     [
                                    //         key: number
                                    //     ]: IFirebasePushChannelConnection;
                                    // } = FirebasePushChannelDeserilize.createCommunitiesChannels(
                                    //     communitiesChangesChannel,
                                    //     defaultClientTransport,
                                    // );

                                    const communitiesChannelsData = {};
                                    const postChannelsData = {};

                                    return of(<IFirebasePushChannels>{
                                        userChannel: userChannelData,
                                        communitiesChannels:
                                            communitiesChannelsData,
                                        postsChannels: postChannelsData,
                                    });
                                }

                                return throwError(
                                    new CancelledConnection(
                                        'Canceled operation.',
                                    ),
                                );
                            },
                        ),
                        retryWhen((attemps) => {
                            return attemps.pipe(
                                mergeMap((error) => {
                                    console.log(
                                        'Detected error during Firebase connections initialization:',
                                        error,
                                    );
                                    this
                                        .pushChannelsClientTransportsConnectionFailedAttemps++;
                                    if (
                                        this.pushChannelsStatus ==
                                            PushChannelsStatus.TRYING_TO_CONNECT &&
                                        !(error instanceof CancelledConnection)
                                    ) {
                                        console.log(
                                            'Retrying to initialize Firebase connections ...',
                                        );
                                        return timer(
                                            Math.min(
                                                Math.pow(
                                                    2,
                                                    this
                                                        .pushChannelsClientTransportsConnectionFailedAttemps,
                                                ) * 1000,
                                                5 * 60000,
                                            ),
                                        );
                                    }
                                    return throwError(error);
                                }),
                            );
                        }),
                    )
                    .subscribe({
                        next: (
                            pushChannelConnections: IFirebasePushChannels,
                        ) => {
                            if (
                                this.pushChannelsStatus ===
                                PushChannelsStatus.TRYING_TO_CONNECT
                            ) {
                                this.pushChannelsStatus =
                                    PushChannelsStatus.CONNECTED;
                                this.channelOnChanges(pushChannelConnections);
                            }
                        },
                        error: (error) => {
                            console.log(
                                'Unable to connect Firebase channels due to this errors:',
                                error,
                            );
                            if (
                                this.pushChannelsStatus ==
                                PushChannelsStatus.TRYING_TO_CONNECT
                            ) {
                                this.doCloseAllClientTransports();
                            }
                        },
                    });
            }
        } else if (
            this.pushChannelsStatus !== PushChannelsStatus.DISCONNECTED
        ) {
            this.doCloseAllClientTransports();
        }
    }

    private firebaseLoader(): Observable<IFirebasePushChannelsClientTransport> {
        return this.http
            .post<any>(
                `${this.commonApiBasePath}/core/auth/create-push-channels-client-transport`,
                null,
            )
            .pipe(
                map((res) => ({
                    firebaseOptions:
                        FirebasePushChannelDeserialize.firebaseOptions(
                            res.clientTransport.transportParams,
                        ),
                    firebaseAuthToken: res.clientTransport.transportParams
                        .firebaseAuthToken as string,
                })),
                mergeMap((clientTransportData) => {
                    if (clientTransportData.firebaseOptions.projectId != null) {
                        const clientTransport =
                            this.firebasePushChannelsClientTransports;
                        if (clientTransport) {
                            return from(
                                this.doCloseClientTransport(clientTransport),
                            ).pipe(mapTo(clientTransportData));
                        }
                    }

                    return of(clientTransportData);
                }),
                mergeMap((clientTransportData) => {
                    const firebaseAppName = `pushChannelsClientTransport-${this
                        .firebaseAppNameDiscriminator++}-${
                        clientTransportData.firebaseOptions.projectId ?? ''
                    }`;
                    const firebaseApp = initializeApp(
                        clientTransportData.firebaseOptions,
                        firebaseAppName,
                    );

                    const channelConnection: IFirebasePushChannelsClientTransport =
                        {
                            id:
                                clientTransportData.firebaseOptions.projectId ??
                                '',
                            firebaseApp: firebaseApp,
                            firebaseDB: getDatabase(firebaseApp),
                        };

                    this.firebasePushChannelsClientTransports =
                        channelConnection;

                    const auth = getAuth(firebaseApp);

                    return from(
                        signInWithCustomToken(
                            auth,
                            clientTransportData.firebaseAuthToken,
                        ),
                    ).pipe(map(() => channelConnection));
                }),
            );
    }

    private channelOnChanges(channelConnection: IFirebasePushChannels) {
        console.log('Firebase connections initialized.');

        this.userChannelDatabaseReference = ref(
            channelConnection.userChannel.clientTransport.firebaseDB,
            channelConnection.userChannel.firebaseChannelPath,
        );

        onValue(
            this.userChannelDatabaseReference,
            (snapshot) => {
                const sharedState: UserChannelStatus = snapshot.val();
                this.userChannelDataStream.next(sharedState);
            },
            (error: any) =>
                console.error('Firebase user channel onValue error', error),
        );

        Object.keys(channelConnection.communitiesChannels).forEach(
            (communityId) => {
                const channelCommunityConnection =
                    channelConnection.communitiesChannels[+communityId];

                const communityChannelDatabaseReference = ref(
                    channelCommunityConnection.clientTransport.firebaseDB,
                    channelCommunityConnection.firebaseChannelPath,
                );

                onValue(
                    communityChannelDatabaseReference,
                    (snapshot) => {
                        const sharedState: CommunityChannelStatus =
                            snapshot.val();
                        this.communitiesChannelDataStream.next({
                            communityId: +communityId,
                            communityStatus: sharedState,
                        });
                    },
                    (error: any) =>
                        console.error(
                            `Firebase community ${+communityId} channel onValue error`,
                            error,
                        ),
                );

                this.communitiesChannelDatabaseReferences.set(
                    +communityId,
                    communityChannelDatabaseReference,
                );
            },
        );
    }

    private doCloseAllClientTransports() {
        this.communitiesChannelDatabaseReferences.forEach(
            (databaseReference, communityId) => {
                console.debug(
                    `detach Firebase community ${communityId} channel`,
                );
                off(databaseReference);
            },
        );
        this.postsChannelDatabaseReferences.forEach(
            (databaseReference, postId) => {
                console.debug(`detach Firebase post ${postId} channel`);
                off(databaseReference);
            },
        );
        if (this.userChannelDatabaseReference) {
            console.debug('detach Firebase user channel');
            off(this.userChannelDatabaseReference);
        }

        this.pushChannelsStatus = PushChannelsStatus.DISCONNECTED;
        if (this.firebasePushChannelsClientTransports != null) {
            this.doCloseClientTransport(
                this.firebasePushChannelsClientTransports,
            );
        }
    }

    private doCloseClientTransport(
        clientTransport: IFirebasePushChannelsClientTransport,
    ): Promise<any> {
        this.firebasePushChannelsClientTransports = null;
        return deleteApp(clientTransport.firebaseApp);
    }
}
