import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import {
    assertUnreachable,
    filterMap,
    isDefined,
} from '@interacta-shared/util';
import { isDeltaEmpty } from '@interacta-shared/util-common';
import { AIActions } from '@modules/ai/store/ai.actions';
import {
    selectActiveContext,
    selectActiveInitialContentGeneration,
} from '@modules/ai/store/ai.selectors';
import { Delta2Server } from '@modules/core/helpers/delta/delta-2-server.class';
import {
    MentionDenotationChar,
    mentionDenotationChars,
    MentionItem,
    MentionItemWrapper,
    mentionTypeKeys,
} from '@modules/mentions';
import { MentionItemRenderingService } from '@modules/mentions/services/mention-item-rendering.service';
import { MentionService } from '@modules/mentions/services/mention.service';
import { QuillToolbar } from '@modules/shared-v2/models/quill';
import { QuillTableSettings } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { LayerService } from '@modules/shared-v2/services/layer.service';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { Store } from '@ngrx/store';
import { QuillModules, Range } from 'ngx-quill';
import Quill from 'quill';
import { Mention } from 'quill-mention';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
    distinctUntilChanged,
    first,
    map,
    skip,
    startWith,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators';

const mentionContainerClass = 'interacta-mention-list-container';
const mentionListClass = 'interacta-mention-list';
const allowedChars = /^([A-Za-zÀ-ÖØ-öø-ÿ0-9_\-']+\s?){0,4}$/;

@Component({
    selector: 'interacta-mention-delta-editor',
    templateUrl: './mention-delta-editor.component.html',
    styleUrls: ['./mention-delta-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class MentionDeltaEditorComponent implements OnInit, OnChanges {
    @Input({ required: true }) control!: UntypedFormControl;
    @Input() enableMemberAutocomplete?: { communityId: number };
    @Input() enableHashtagAutocomplete?: {
        communityId: number;
        workspaceId?: number;
    };
    @Input() tableEnabled = false;
    @Input() placeholder = ' ';
    @Input() withToolbar = true;
    @Input() withBorders = false;
    @Input() isReadonly = false;
    @Input() withMarginLeft = false;
    @Input() plainTextMaxLength = 8000;
    @Input() canPasteFile = false;
    @Input() classes = '';
    @Input() forceDisableAI = false;

    @Output() focusInput = new EventEmitter();
    @Output() detectLink = new EventEmitter<string | null>();
    @Output() copiedFiles = new EventEmitter<File[]>();
    @Output() blurEmitter = new EventEmitter();

    @Output() tableMentionClick = new EventEmitter<string>();
    @Output() addTable = new EventEmitter<QuillTableSettings>();

    quillModule: QuillModules = {};

    editor?: Quill;
    quillKeyboardModule = {
        bindings: {
            // Uncomment to use the browser's default action on tab press. (Focus next element)
            // tab: {
            //     key: 9,
            //     handler: (): boolean => true,
            // },
            // indent in ordered/unordered list is forbidden
            indent: {
                key: 'tab',
                format: ['list'],
                handler: (): boolean => false,
            },
        },
    };

    mentionModule;

    toolbar: QuillToolbar = this.defaultToolbar();
    workspaceId?: number;

    readonly mentionClickEnabled = {
        hashtag: false,
        user: false,
        group: false,
        table: (clickEvent: { clientUid: string }): void =>
            this.tableMentionClick.emit(clickEvent.clientUid),
    };
    readonly aiContentGenEnabled$: Observable<boolean>;

    showAIContentGenButton$!: Observable<boolean>;

    private aiContextId?: number;
    private destroy$ = new Subject<void>();
    private changeControl$ = new Subject<void>();

    constructor(
        mentionsService: MentionService,
        mentionItemRenderingService: MentionItemRenderingService,
        layerService: LayerService,
        private communitiesStateService: CommunitiesStateService,
        private configurationService: ConfigurationService,
        private store: Store,
    ) {
        let isMentionListOpen = false;

        this.mentionModule = {
            mentionDenotationChars: mentionDenotationChars.filter(
                (c) => c.length,
            ),
            mentionContainerClass,
            mentionListClass,
            allowedChars,
            dataAttributes: [
                'id',
                'value',
                'denotationChar',
                'mentionType',
                'clientUid',
            ],
            spaceAfterInsert: true,
            positioningStrategy: 'fixed',
            onSelect: (
                item: MentionItem,
                insertItem: (_: MentionItem) => void,
            ): void => {
                insertItem(item);
                this.triggerApiChanges();
            },

            source: (
                searchTerm: string,
                renderList: (_: MentionItemWrapper[]) => void,
                mentionChar: MentionDenotationChar,
            ): void => {
                switch (mentionChar) {
                    case '@':
                        if (this.enableMemberAutocomplete) {
                            if (this.workspaceId == null) {
                                console.error(
                                    'MentionsService.searchMembers : workspaceId must be defined.',
                                );
                            } else {
                                mentionsService.searchMembers(
                                    searchTerm,
                                    renderList,
                                    mentionContainerClass,
                                    this.workspaceId,
                                    !isMentionListOpen,
                                );
                            }
                        }
                        break;
                    case '#':
                        if (this.enableHashtagAutocomplete) {
                            mentionsService.searchHashtags(
                                searchTerm,
                                renderList,
                                this.enableHashtagAutocomplete?.communityId,
                            );
                        }
                        break;
                    case 'T':
                        // not actually available to the user. Only a temporary placeholder to programmatically add table mention
                        break;
                    default:
                        assertUnreachable(mentionChar);
                }
            },
            renderItem: (
                item: MentionItemWrapper,
            ): HTMLButtonElement | HTMLDivElement =>
                mentionItemRenderingService.renderItem(item),
            onOpen: function (): void {
                isMentionListOpen = true;
                layerService.open('z-mention-list');
            },
            onBeforeClose: function (): void {
                isMentionListOpen = false;
                layerService.close();

                const mentionListElement = document.querySelector(
                    `body > .${mentionContainerClass}`,
                );

                mentionListElement?.removeAllListeners?.('scroll');
            },
        };

        this.aiContentGenEnabled$ = this.configurationService
            .getEnvironmentInfoStream()
            .pipe(
                map(
                    (env) => env?.installedFeatures.aiContentGenerator ?? false,
                ),
            );
    }

    ngOnInit(): void {
        this.quillModule = {
            keyboard: this.quillKeyboardModule,
            toolbar: this.defaultToolbar(),
            magicUrl: true,
            mention: this.mentionModule,
        };

        this.store
            .select(selectActiveInitialContentGeneration)
            .pipe(
                distinctUntilChanged(),
                withLatestFrom(this.store.select(selectActiveContext)),
                filterMap(([delta, state]) =>
                    state?.contextId === this.aiContextId ? delta : undefined,
                ),
                skip(1),
                takeUntil(this.destroy$),
            )
            .subscribe((value) => {
                this.control.setValue(value);

                setTimeout(() => {
                    this.editor?.setSelection({
                        index: this.editor?.getLength() || 0,
                        length: 0,
                    });
                    this.editor?.focus();
                });
            });

        this.showAIContentGenButton$ = this.forceDisableAI
            ? of(false)
            : combineLatest([
                  this.aiContentGenEnabled$.pipe(startWith(null)),
                  this.control.valueChanges.pipe(startWith(null)),
              ]).pipe(
                  map(
                      ([enabled, control]) =>
                          !!enabled && !isDeltaEmpty(control),
                  ),
                  distinctUntilChanged(),
              );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.control) {
            this.changeControl$.next();

            this.control.valueChanges
                .pipe(
                    filterMap((_) =>
                        isDefined(this.editor)
                            ? this.detectLinks(this.editor)
                            : undefined,
                    ),
                    distinctUntilChanged(),
                    takeUntil(this.changeControl$),
                    takeUntil(this.destroy$),
                )
                .subscribe((url) => this.detectLink.emit(url));
        }

        if (changes.enableMemberAutocomplete && this.enableMemberAutocomplete) {
            this.communitiesStateService
                .getCommunity(this.enableMemberAutocomplete.communityId)
                .pipe(first(), takeUntil(this.destroy$))
                .subscribe(
                    (community) => (this.workspaceId = community.workspaceId),
                );
        }
    }

    onEditorCreated(editor: Quill): void {
        this.editor = editor;

        this.editor.on('selection-change', (range) => {
            if (range != null) {
                this.focusInput.emit();
            }
        });
    }

    focus(): void {
        this.editor?.blur();
        this.editor?.setSelection({
            index: this.getIndexPosition(),
            length: 0,
        });
        this.editor?.focus();
    }

    addEmoji(emoji: string): void {
        this.focus();
        this.addAnnotation(emoji);
        this.triggerApiChanges();
    }

    addEmptyTable(tableSetting: QuillTableSettings): void {
        if (this.editor) {
            // 1. mention module requires the denotation char is present in order to inser the related mention
            const selection = this.editor.getSelection(true);
            const tmpDenotationCharIndex = selection.index;
            this.editor.insertText(
                selection?.index ?? 0,
                mentionTypeKeys.table,
            );
            this.editor.blur();
            this.editor.focus();

            // 2. insert the actual table mention
            this.getMentionModule()?.insertItem(
                {
                    clientUid: `${tableSetting.clientUid}`,
                    mentionType: 'table',
                    value: tableSetting.title,
                    denotationChar: '', //do not include denotation char in the mention
                },
                true,
            );

            // 3. remove denotation char aded in step (1), before the actual table mention
            // Emitter source 'user' is required in order to update form control's value
            this.editor.deleteText(tmpDenotationCharIndex, 1, 'user');

            this.addTable.emit(tableSetting);
        }
    }

    addAnnotation(denotationChar: string): void {
        this.getMentionModule()?.openMenu(denotationChar);
    }

    aiClick(): void {
        this.aiContextId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

        this.store.dispatch(
            AIActions.openContentGeneration({
                contextId: this.aiContextId,
                initialContent: this.control.value,
            }),
        );
    }

    private getMentionModule(): Mention | undefined {
        const quillMentionModule = this.editor?.getModule('mention') as Mention;
        return quillMentionModule;
    }

    private getIndexPosition(): number {
        return (
            (((this.editor as any)?.selection?.savedRange as Range)?.index ||
                this.editor?.getLength()) ??
            0
        );
    }

    private detectLinks(editor: Quill): string | null {
        const matches = Delta2Server.findLinkInsideHref(editor.root.innerHTML);
        const url = matches?.[1] ?? null;
        return url;
    }

    private defaultToolbar(): QuillToolbar {
        return {
            container: [
                [
                    'bold',
                    'italic',
                    'underline',
                    { list: 'ordered' },
                    { list: 'bullet' },
                    'link',
                ],
            ],
        };
    }

    // necessary because quill-mention triggers changes as 'api' instead of 'user'
    private triggerApiChanges(): void {
        this.editor?.insertText(this.editor?.getLength() - 1, '', 'user');
    }
}
