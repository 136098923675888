import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    ConfigurationService,
    Language,
    defaultSettings,
} from '@interacta-shared/data-access-configuration';
import { InputTextV2Component } from '@interacta-shared/ui';
import { isDefined } from '@interacta-shared/util';
import { TranslationInputType } from '@modules/communities/models/custom-metadata/custom-metadata.model';

@Component({
    selector: 'interacta-input-translation-v2[form]',
    templateUrl: './input-translation-v2.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTranslationV2Component implements OnInit, AfterViewInit {
    @Input() languages: Language[] = [];
    @Input() language!: Language;
    @Input() defaultLanguage =
        this.configurationService.getDefaultInfo().systemLanguage ??
        defaultSettings.language;
    @Input() form!: UntypedFormGroup;
    @Input() fieldName = '';
    @Input() inputType: TranslationInputType = TranslationInputType.STRING;
    @Input() showOtherLanguages = true;
    @Input() description = '';
    @Input() isFieldRequired = true;
    @Input() readonly = false;
    @Input() plainTextMaxLength = 8000;
    @Input() withBorders = true;
    @Input() withMarginLeft = true;
    @Input() withAutofocus = false;
    @Input() canMention?: boolean;

    TranslationInputType = TranslationInputType;
    reminder = false;
    originalValue = '';
    isDialogOpen = false;

    @ViewChild('inputText') input?: InputTextV2Component;

    constructor(private configurationService: ConfigurationService) {}

    ngOnInit(): void {
        if (!this.language) {
            this.language = this.defaultLanguage;
        }
        this.originalValue = this.form?.value[this.language.code];

        this.form.statusChanges.subscribe(() => {
            const otherLanguages = Object.keys(this.form.value ?? []).find(
                (key) =>
                    key !== this.language.code &&
                    isDefined(this.form.value[key]),
            );

            this.reminder =
                this.form.dirty &&
                otherLanguages != null &&
                this.languages &&
                this.form.value[this.language.code] !== this.originalValue;
        });
    }

    ngAfterViewInit(): void {
        if (this.input && this.withAutofocus) {
            setTimeout(() => this.input?.focus(true), 300);
        }
    }

    updateForm(inputForm: UntypedFormGroup): void {
        if (inputForm) this.form.setValue(inputForm.value);
        this.reminder = false;
    }
}
