import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ScrollTrackerEvent } from '@interacta-shared/ui';
import { PageTokenInfo, isDefined } from '@interacta-shared/util';
import { Store } from '@ngrx/store';
import {
    Subject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    of,
    takeUntil,
    withLatestFrom,
} from 'rxjs';
import {
    AttachmentSection,
    AttachmentSectionSource,
} from '../../models/attachment-section/attachment-section.model';
import * as SectionsActions from '../../store/sections/sections.actions';
import * as SectionsSelectors from '../../store/sections/sections.selectors';
@Component({
    selector: 'interacta-light-box-search',
    templateUrl: './light-box-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightBoxSearchComponent implements OnChanges, OnDestroy {
    @Input({ required: true }) attachment!: AttachmentSectionSource;
    @Input() containsText?: string;
    @Input() active = true;

    @Output() goToAudioVideoFrame = new EventEmitter<number>();

    searchControl = new FormControl<string>('', { nonNullable: true });

    selectedSection$ = this.store.select(SectionsSelectors.selectedSection);
    sections$ = this.store.select(SectionsSelectors.currentAttachmentItems);

    private attachment$ = this.store
        .select(SectionsSelectors.currentAttachment)
        .pipe(filter(isDefined), distinctUntilKeyChanged('id'));
    private destroy$ = new Subject<void>();

    constructor(private store: Store) {
        const searchValue$ = this.searchControl.valueChanges.pipe(
            filter((value) => value.length > 2),
            debounceTime(200),
            distinctUntilChanged(),
        );

        combineLatest([
            searchValue$,
            this.attachment$,
            this.store.select(SectionsSelectors.active),
        ])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([containsText, attachment]) => {
                this.store.dispatch(
                    SectionsActions.requestFetchSectionsList({
                        attachment,
                        containsText,
                        pageToken: null,
                    }),
                );
            });
    }

    scrollTracker(
        event: ScrollTrackerEvent,
        nextPageToken: PageTokenInfo,
    ): void {
        if (event.endReached && nextPageToken.tag === 'regularLoading') {
            of(nextPageToken)
                .pipe(
                    withLatestFrom(
                        this.attachment$,
                        this.store.select(SectionsSelectors.containsText),
                    ),
                    takeUntil(this.destroy$),
                )
                .subscribe(([nextPageToken, attachment, containsText]) => {
                    if (attachment) {
                        this.store.dispatch(
                            SectionsActions.fetchSectionsList({
                                attachment,
                                containsText,
                                pageToken: nextPageToken.nextPageToken,
                            }),
                        );
                    }
                });
        }
    }

    selectSection(id: AttachmentSection['id']): void {
        this.store.dispatch(SectionsActions.selectSection({ id }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.attachment) {
            this.store.dispatch(
                SectionsActions.setCurrentAttachment({
                    currentAttachment: this.attachment,
                }),
            );
        }
        if (changes.containsText) {
            this.searchControl.setValue(this.containsText ?? '');
        }
        if (changes.active) {
            this.store.dispatch(
                SectionsActions.setActive({ active: this.active }),
            );
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
