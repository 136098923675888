import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { Size } from '@interacta-shared/ui';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import {
    isMediaAttachment,
    srcFallback,
} from '@modules/post/models/attachment/attachment.utils';

@Component({
    selector: 'interacta-attachment-preview[attachment]',
    templateUrl: './attachment-preview.component.html',
    styleUrls: ['./attachment-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AttachmentPreviewComponent {
    @Input() attachment!: IAttachment;
    @Input() size: Extract<
        Size,
        'extra-large' | 'large' | 'regular' | 'small'
    > = 'regular';
    @Input() active = false;
    @Input() iconSize: Extract<Size, 'regular' | 'small'> = 'regular';

    isMediaAttachment = isMediaAttachment;
    srcFallback = srcFallback;
}
