import { createReducer, on } from '@ngrx/store';
import { TipData } from '../models/tip.model';
import * as actions from './tip.actions';

export const tipFeatureKey = 'tip';

export interface TipsState {
    currentTip: TipData | null;
}

export const tipsInitialState: TipsState = {
    currentTip: null,
};

export const tipsReducer = createReducer(
    tipsInitialState,

    on(actions.setCurrentTip, (state, { currentTip }) => {
        return {
            ...state,
            currentTip,
        };
    }),

    on(actions.closeCurrentTip, (state) => {
        return {
            ...state,
            currentTip: null,
        };
    }),
);
