import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { formatDateUsingIntl } from '@interacta-shared/util-common';
import { TranslateService } from '@ngx-translate/core';
import { format, isThisYear, isToday, isYesterday } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * See https://injenia.atlassian.net/l/cp/r6L3KnZu
 *
 */
@Pipe({
    name: 'timelineDate',
})
export class TimelineDatePipe implements PipeTransform, OnDestroy {
    locale?: Locale;

    private destroy$ = new Subject<void>();

    constructor(
        private configurationService: ConfigurationService,
        private translateService: TranslateService,
    ) {
        this.configurationService
            .getLocaleDateFns()
            .pipe(takeUntil(this.destroy$))
            .subscribe((locale) => (this.locale = locale));
    }

    transform(date: Date): string {
        if (isToday(date)) {
            return this.translateService.instant('DATE_FORMAT.WORKFLOW.TODAY');
        }

        if (isYesterday(date)) {
            return this.translateService.instant(
                'DATE_FORMAT.WORKFLOW.YESTERDAY',
            );
        }

        if (!this.locale) {
            console.warn(
                `The locale should be initialized here. Defaulting to enUS.`,
            );
            return format(date, 'PPP');
        }

        if (isThisYear(date)) {
            if (!this.locale.code) {
                throw new Error("locale.code can't be null");
            }

            return formatDateUsingIntl(
                date,
                {
                    month: 'long',
                    day: 'numeric',
                },
                'PPP',
                this.locale.code,
            );
        }

        return format(date, 'PPP', { locale: this.locale });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
