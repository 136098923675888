import { updateTimeRelatedState } from '@modules/communities/store/post/post.actions';
import { TimeoutsService } from '@modules/core/services/timeouts.service';
import { Store } from '@ngrx/store';
import { differenceInMilliseconds, endOfDay, isFuture, isPast } from 'date-fns';
import {
    EventState,
    EventStateStyle,
    IEventPost,
} from '../models/event-post.model';

function eventPostActualEnd(event: Pick<IEventPost, 'endAt' | 'allDay'>): Date {
    const date = event.endAt.zonedDatetime ?? event.endAt.localDatetime;
    return event.allDay ? endOfDay(date) : date;
}

export function isEventPostEnded(event: IEventPost): boolean {
    return isPast(eventPostActualEnd(event));
}

const stateBackgroudMap: Record<EventState, EventStateStyle['background']> = {
    scheduled: 'bg-status-G',
    open: 'bg-status-D',
    closed: 'bg-status-F',
};

export function buildEventStateStyle(state: EventState): EventStateStyle {
    return {
        label: `EVENT.STATE.${state.toUpperCase()}`,
        background: stateBackgroudMap[state],
    };
}

export function buildEventState({
    startAt,
    endAt,
    allDay,
}: Pick<IEventPost, 'startAt' | 'endAt' | 'allDay'>): EventState {
    const _startAt = startAt.zonedDatetime ?? startAt.localDatetime;
    const _endAt = eventPostActualEnd({ endAt, allDay });

    return isPast(_endAt)
        ? 'closed'
        : isFuture(_startAt)
          ? 'scheduled'
          : 'open';
}

export function setEventStateUpdateTimeout(
    post: IEventPost,
    timeoutsService: TimeoutsService,
    store: Store,
): void {
    const timeoutList: { postId: number; prefix: string; ms: number }[] = [];
    if (post.state === 'scheduled') {
        // update state when event starts
        timeoutList.push({
            postId: post.id,
            prefix: 'eventStart',
            ms: differenceInMilliseconds(
                post.startAt.zonedDatetime ?? post.startAt.localDatetime,
                new Date(),
            ),
        });
    }
    if (post.state === 'scheduled' || post.state === 'open') {
        // update state when event end
        timeoutList.push({
            prefix: 'eventEnd',
            postId: post.id,
            ms: differenceInMilliseconds(eventPostActualEnd(post), new Date()),
        });
    }

    timeoutList.forEach((timeout) => {
        const fn = (): void => {
            store.dispatch(
                updateTimeRelatedState({
                    postId: timeout.postId,
                }),
            );
        };

        timeoutsService.setTimeout(
            `${timeout.prefix}:${timeout.postId}`,
            fn,
            timeout.ms,
        );
    });
}
