import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'interacta-light-box-footer',
    templateUrl: './light-box-footer.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightBoxFooterComponent {
    @Input() currentAttachmentIdx = 0;
    @Input() attachmentsCount = 0;
    @Input() isAttachmentMediaList = true;
    @Input() minZoom = 100;
    @Input() maxZoom = 250;
    @Input() zoom = 100;
    @Input() showMiniSlider = true;

    @Output() previousAttachment = new EventEmitter<void>();
    @Output() nextAttachment = new EventEmitter<void>();

    @Output() zoomOut = new EventEmitter<void>();
    @Output() zoomIn = new EventEmitter<void>();
}
