import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';

@Component({
    selector: 'interacta-button-menu',
    templateUrl: './button-menu.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, IconComponent],
})
export class ButtonMenuComponent {
    /** @deprecated Use content projection instead */
    @Input() label = '';
    /** @deprecated Use content projection instead */
    @Input() icon?: InteractaIcon;

    @Input() classes?: string;
}
