<interacta-dialog
    *ngIf="{
        items: orderedPaginatedList$ | async,
        isDirty:
            saveButtonConfig !== null &&
            (saveButtonConfig.alwaysVisible ||
                orderUpdated() ||
                orderByControl.dirty)
    } as $"
    [closeOnBackdropClick]="!$.isDirty"
    [isOpen]="isOpen"
    [size]="size"
    (closed)="closed.emit()"
>
    <h6 class="typo-t6" title>
        {{ title | translate }}
    </h6>

    <p class="typo-sm text-text-mid-contrast" subtitle>
        {{ description | translate }}
    </p>

    @if ($.items) {
        <div
            #itemsContainer
            cdkScrollable
            class="change-order-list flex w-full flex-col gap-16 overflow-auto scroll-smooth pt-16"
            content
            interactaScrollTracker
            [ngClass]="{
                'pointer-events-none opacity-50': saveLoading
            }"
            (scrolled)="onScroll($event, $.items)"
        >
            @if (showWarning) {
                <interacta-warning-badge
                    [label]="warningBadgeMessage | translate"
                />
            }
            @if (availableOrderOptions.length > 1) {
                <interacta-radio-group
                    class="typo-sm flex flex-col gap-8"
                    [formControl]="orderByControl"
                >
                    @for (criteria of availableOrderOptions; track criteria) {
                        <interacta-radio-button
                            [disabled]="
                                criteria === ItemOrderByCriteria.CUSTOM &&
                                showWarning
                            "
                            [value]="criteria"
                        >
                            {{ criteria | orderByCriteriaLabel }}
                        </interacta-radio-button>
                    }
                </interacta-radio-group>
            }
            @if (orderByControl.value === ItemOrderByCriteria.CUSTOM) {
                @if (showWarning) {
                    <p class="typo-sm">
                        {{ warningMessage | translate }}
                    </p>
                } @else {
                    @if (
                        $.items.nextPageToken.tag === 'firstLoading' ||
                        loadingItems
                    ) {
                        <div class="flex flex-col gap-y-12 pe-4">
                            <div
                                *ngFor="let _ of 3 | array"
                                class="bg-surface-300 flex h-48 animate-pulse rounded-full px-16"
                            ></div>
                        </div>
                    } @else {
                        <div
                            cdkDropList
                            cdkDropListLockAxis="y"
                            class="flex flex-col gap-y-12 pe-4"
                            [cdkDropListAutoScrollStep]="5"
                            (cdkDropListDropped)="
                                onDrop(
                                    $.items.list[$event.previousIndex].id,
                                    $event
                                )
                            "
                        >
                            @for (row of $.items.list; track row.id) {
                                <div
                                    cdkDrag
                                    class="change-order-list-item relative flex h-48 items-center rounded-full px-16"
                                    [ngClass]="{
                                        'bg-surface-B': !row.deleted,
                                        'item-deleted': row.deleted
                                    }"
                                >
                                    @if (itemTemplate) {
                                        <ng-container
                                            *ngTemplateOutlet="
                                                itemTemplate;
                                                context: { $implicit: row }
                                            "
                                        />
                                    } @else {
                                        <div
                                            class="flex flex-1 items-center gap-x-8"
                                        >
                                            <span class="typo-sm-bold">
                                                {{
                                                    row.name | currentLanguageV2
                                                }}</span
                                            >
                                        </div>
                                    }
                                    <div
                                        class="absolute left-0 top-0 flex w-full justify-center"
                                    >
                                        <interacta-icon
                                            cdkDragHandle
                                            class="size-16 cursor-grab text-gray-700"
                                            [icon]="'equals'"
                                        />
                                    </div>
                                    <button
                                        *ngIf="$index > 0"
                                        interacta-icon-button
                                        type="button"
                                        [attr.aria-label]="
                                            'ORDER_BY_CRITERIA.DIALOG.BRING_TO_TOP'
                                                | translate
                                        "
                                        [icon]="'arrow-up'"
                                        [matTooltip]="
                                            'ORDER_BY_CRITERIA.DIALOG.BRING_TO_TOP'
                                                | translate
                                        "
                                        (click)="moveToTop(row.id, $index)"
                                    ></button>
                                </div>
                            }
                            <interacta-load-more
                                *ngIf="
                                    $.items.nextPageToken.tag ===
                                    'regularLoading'
                                "
                                class="flex items-center justify-center"
                                [isLoading]="$.items.isFetching"
                                [label]="'SHOW_MORE' | translate"
                                [size]="'regular'"
                                (loadMore)="
                                    fetchItems.emit($.items.nextPageToken)
                                "
                            />
                        </div>
                    }
                }
            }
        </div>
    }

    <interacta-dialog-buttons [showLoader]="saveLoading">
        <button
            interacta-dialog-button
            type="button"
            [appearance]="'ghost'"
            [bgColor]="'surface-A'"
            [label]="
                ($.isDirty
                    ? 'BUTTON.LABEL_BUTTON_CANCEL'
                    : 'ORDER_BY_CRITERIA.DIALOG.CLOSE'
                ) | translate
            "
            (click)="closed.emit()"
        ></button>
        <button
            *ngIf="$.isDirty && $.items"
            interacta-dialog-button
            type="button"
            [appearance]="'fill'"
            [bgColor]="'primary'"
            [label]="saveButtonConfig.label | translate"
            (click)="save()"
        ></button>
    </interacta-dialog-buttons>
</interacta-dialog>
