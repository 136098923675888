import { toIUserReverseFullName } from '@modules/admin-v2-user/models/admin-v2-user.deserialize';
import { UserExtendedDeserialize } from '@modules/core';
import { Member } from '@modules/core/models/member/member.model';
import { isMember, wrapMember } from '@modules/core/models/member/member.utils';
import { AdminV2MemberWithCustomFlags } from './admin-v2-members-manager.model';

export const toAdminV2MemberItem = (record: any): Member => {
    if (record.user) {
        return {
            id: record.id,
            innerId: record.user.id,
            user: toIUserReverseFullName(record.user),
        };
    } else {
        return {
            id: record.id,
            innerId: record.group.id,
            group: UserExtendedDeserialize.usersGroup(record.group),
        };
    }
};

export const toAdminV2MemberWithCustomFlags = (
    record: any,
): AdminV2MemberWithCustomFlags => {
    let member: Member;
    if (record.member) {
        member = record.member;
    } else {
        member = isMember(record) ? record : wrapMember(record);
    }
    return {
        ...member,
        workspaceMember: record.workspaceMember,
        deletedMember: false,
        communityMemberAssociation:
            record.associationId ?? record.communityMember,
        checkGroup: record.checkGroupMemberResult,
        checkUser:
            record.checkUserMemberResult ||
            record.checkAreaMemberResult ||
            record.checkBusinessUnitMemberResult,
    };
};
