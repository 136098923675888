import { Pipe, PipeTransform, inject } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { i18nStringV2 } from '@modules/core';
import { getLabelServerTranslationV2 } from '@modules/core/helpers/i18n.utils';

@Pipe({
    name: 'currentLanguageV2',
})
export class CurrentLanguageV2Pipe implements PipeTransform {
    private readonly configurationService = inject(ConfigurationService);

    transform(value: i18nStringV2 | string, translateLabel = false): string {
        return typeof value === 'string'
            ? value
            : getLabelServerTranslationV2(
                  value,
                  this.configurationService,
                  translateLabel,
              );
    }
}
