<div
    class="flex items-center gap-8"
    [ngClass]="{ 'flex-row-reverse justify-end': labelPosition === 'before' }"
>
    <mat-slide-toggle
        [aria-labelledby]="ariaLabelledby ?? innerLabel"
        [checked]="checked"
        [disabled]="disabled"
        (change)="toggle.emit($event.checked)"
    >
    </mat-slide-toggle>
    <label *ngIf="label" [id]="ariaLabelledby ?? innerLabel">
        {{ label }}
    </label>
</div>
