import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Output,
} from '@angular/core';
import { ScrollTrackerEvent } from '@interacta-shared/ui';

@Component({
    selector: 'interacta-invisible-scroll',
    templateUrl: './invisible-scroll.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvisibleScrollComponent {
    @Output() scrolled = new EventEmitter<ScrollTrackerEvent>();
}
