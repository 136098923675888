import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import {
    IBasePost,
    IPostMetadata,
    PostType,
} from '../../post/models/base-post.model';
import { CustomPostDeserialize } from './custom-post.model';
import { EventPostDeserialize } from './event-post.model';
import { IPostFilters } from './filter-post/filter-post.model';
import { SurveyPostDeserialize } from './survey-post/survey-post.deserialize';

export interface IPostItemList extends IBasePost {
    selected?: boolean;
}

export interface PostList {
    communityId?: number;
    list: IPostItemList[];
    filter: IPostFilters;
    totalCount?: number;
    nextPageToken?: string;
    scrollPos?: number;
    totalItemsCount?: number;
}
export interface IPostHistoryFilter {
    retrievePostEvents?: boolean;
    retrieveWorkflowEvents?: boolean;
    orderDesc?: boolean;
    pageToken?: string;
}

export enum DashboardView {
    Extended = 'Extended',
    Compact = 'Compact',
    Row = 'Row',
    Attachments = 'Attachments',
}

export enum DashboardLayout {
    Mosaic = 'Mosaic',
    Column = 'Column',
}

export class PostListDeserialize {
    public static postDetails = (
        configurationService: ConfigurationService,
        recordPost: any,
        metadata?: IPostMetadata,
    ): IBasePost => {
        switch (recordPost.type) {
            case PostType.CUSTOM:
                return CustomPostDeserialize.customPostDetails(
                    configurationService,
                    recordPost,
                    metadata,
                );
            case PostType.EVENT:
                return EventPostDeserialize.eventPostDetails(
                    configurationService,
                    recordPost,
                    metadata,
                );
            case PostType.SURVEY:
                return SurveyPostDeserialize.surveyPostDetails(
                    configurationService,
                    recordPost,
                    undefined,
                    metadata,
                );
            default:
                throw new Error(`${recordPost.type} post type not supported.`);
        }
    };

    public static postList = (record: any, filter: IPostFilters): PostList => {
        const postList: PostList = {
            list: [],
            filter,
            totalCount: record?.totalItemsCount,
            nextPageToken: record?.nextPageToken,
        };
        return postList;
    };
}
