import { Index, isDefined, uuid } from '@interacta-shared/util';
import {
    ConfigurableFieldType,
    ConfigurableFieldTypeInfo,
    ConfigurableFieldTypes,
    FieldType,
    MetadataGenericListType,
} from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { getGroupableFieldTypes } from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import {
    CustomFieldsConfigurationBase,
    CustomFieldsConfigurationBaseWithValidationAndOrder,
    CustomFieldsConfigurationEnumValues,
    CustomFieldsConfigurationEnumValuesParent,
} from '@modules/custom-fields-configuration/models/custom-fields-configuration.model';
import { TranslateService } from '@ngx-translate/core';
import { from, map, Observable, toArray } from 'rxjs';

export const getCustomFieldConfigurationBaseForCreate =
    (): CustomFieldsConfigurationBase => ({
        label: '',
        description: '',
        type: ConfigurableFieldTypes[0],
        fieldType: FieldType.TEXT,
        readonly: false,
        deleted: false,
        required: false,
        metadata: {},
        originalMetadata: {},
        childIds: [],
        enumValues: [],
        localId: null,
    });

export function getFieldId<T extends CustomFieldsConfigurationBase>(
    field: T,
): Index | undefined | null {
    return field.id ?? field.localId;
}

function isCatalog<T extends CustomFieldsConfigurationBase>(field: T): boolean {
    return (
        field.type.mapToTypes.includes(FieldType.GENERIC_LIST) &&
        field.metadata?.generic_entity_list_config_id ===
            MetadataGenericListType.CATALOG
    );
}

export function getFieldParentId<T extends CustomFieldsConfigurationBase>(
    field: T,
): Index | undefined {
    return field.parentId ?? field.localParentId;
}

export function fieldHasChildren<T extends CustomFieldsConfigurationBase>(
    field: T,
): boolean {
    return (field.childIds?.length || 0) > 0;
}

function hasOtherChildren<T extends CustomFieldsConfigurationBase>(
    field: T,
    actualField: T,
    allFields: T[],
): boolean {
    return allFields.some(
        (f) =>
            getFieldParentId(f) === getFieldId(actualField) &&
            getFieldId(f) !== getFieldId(field),
    );
}

function manageChildIds<T extends CustomFieldsConfigurationBase>(
    mode: 'edit' | 'delete',
    actualField: T,
    userUpdatedField: T,
): Index[] {
    const userUpdatedFieldId = getFieldId(userUpdatedField);
    if (!userUpdatedFieldId) return actualField.childIds;

    const actualFieldId = getFieldId(actualField);
    const actualFieldIsChildOfField =
        actualFieldId != null &&
        userUpdatedField.childIds.includes(actualFieldId);

    if (mode === 'edit') {
        if (
            !actualFieldIsChildOfField &&
            !actualField.childIds.includes(userUpdatedFieldId)
        ) {
            //aggiorno la lista dei figli del parent se non ha questo campo
            return [...actualField.childIds, userUpdatedFieldId];
        }
    } else {
        if (actualField.childIds.includes(userUpdatedFieldId)) {
            //rimuovo il child dalla lista se non più parent del campo in questione
            return actualField.childIds.filter((i) => i !== userUpdatedFieldId);
        }
    }
    return actualField.childIds;
}

function manageCatalogFields<T extends CustomFieldsConfigurationBase>(
    field: T,
    actualField: T,
    mode: 'edit' | 'delete',
    allFields: T[],
): T {
    //se sono entrambi cataloghi, imposto il actualField come hierarchical se necessario
    const fieldId = getFieldId(field);
    const actualFieldIsChildOfField =
        actualField != null && fieldId === getFieldParentId(actualField);

    const parentId =
        actualFieldIsChildOfField &&
        mode === 'delete' &&
        typeof fieldId === 'number'
            ? undefined
            : actualField.parentId;
    const localParentId =
        actualFieldIsChildOfField &&
        mode === 'delete' &&
        typeof fieldId === 'string'
            ? undefined
            : actualField.localParentId;

    return {
        ...actualField,
        parentId,
        localParentId,
        childIds: manageChildIds(mode, actualField, field),
        metadata: {
            ...actualField.metadata,
            hierarchical:
                mode === 'edit' ||
                (mode === 'delete' &&
                    (!!(parentId ?? localParentId) ||
                        hasOtherChildren(field, actualField, allFields))),
        },
    };
}

function manageEnumFields<T extends CustomFieldsConfigurationBase>(
    field: T,
    actualField: T,
    mode: 'edit' | 'delete',
): T {
    const fieldId = getFieldId(field);
    const actualFieldId = getFieldId(actualField);
    const actualFieldIsChildOfField =
        actualFieldId && field.childIds.includes(actualFieldId);

    const parentId =
        actualFieldIsChildOfField &&
        mode === 'delete' &&
        typeof fieldId === 'number'
            ? undefined
            : actualField.parentId;
    const localParentId =
        actualFieldIsChildOfField &&
        mode === 'delete' &&
        typeof fieldId === 'string'
            ? undefined
            : actualField.localParentId;

    const enumValues =
        actualFieldIsChildOfField && actualField.enumValues
            ? actualField.enumValues.map((e) => ({
                  ...e,
                  parentIds: getUpdatedActualFieldParentIds(
                      e.parentIds ?? [],
                      field.enumValues ?? [],
                      mode,
                  ),
              }))
            : actualField.enumValues;

    return {
        ...actualField,
        enumValues,
        parentId,
        localParentId,
        childIds: manageChildIds(mode, actualField, field),
    };
}

function getUpdatedActualFieldParentIds(
    actualFieldParentIds: CustomFieldsConfigurationEnumValuesParent[],
    fieldEnumValues: CustomFieldsConfigurationEnumValues[],
    mode: 'edit' | 'delete',
): CustomFieldsConfigurationEnumValuesParent[] | undefined {
    if (mode === 'delete') return undefined;
    else {
        const fieldIds = fieldEnumValues
            .map((f) => f.id ?? f.clientRef)
            .filter(isDefined);
        return actualFieldParentIds.filter((a) => fieldIds.includes(a.id));
    }
}

/**
 * This method updates field that are referenced (child or parent) to the field that is going to be deleted
 * @param field -> the field that has been updated or deleted by user. Used to get children, parent and Id reference
 * @param actualField -> field to be updated if has references to @field
 * @param mode -> edit mode or delete mode
 * @param allFields -> copy of the source fields list
 *
 * @returns actualField updated if needeed
 */
export function manageHierarchicalValues<
    T extends CustomFieldsConfigurationBase,
>(field: T, actualField: T, mode: 'edit' | 'delete', allFields: T[]): T {
    const userUpdatedFieldParentId = getFieldParentId(field);
    const actualFieldId = getFieldId(actualField);
    const actualFieldIsChildOfField =
        actualFieldId != null && field.childIds.includes(actualFieldId);

    if (
        actualFieldIsChildOfField ||
        (userUpdatedFieldParentId &&
            actualFieldId &&
            userUpdatedFieldParentId === actualFieldId)
    ) {
        //CATALOGHI GERARCHICI
        if (isCatalog(field) && isCatalog(actualField)) {
            return manageCatalogFields(field, actualField, mode, allFields);
        }

        //ENUM GERARCHICI
        if (
            field.type.code === ConfigurableFieldType.SELECT_SINGLE &&
            actualField.type.code === ConfigurableFieldType.SELECT_SINGLE
        ) {
            return manageEnumFields(field, actualField, mode);
        }
    }

    return actualField;
}

export function insertFieldIntoArray<T extends CustomFieldsConfigurationBase>(
    field: T,
    position: number,
    allFields: T[] | null,
): T[] {
    const fields = allFields ?? [];
    return [
        ...fields.slice(0, position),
        { ...field },
        ...fields.slice(position),
    ];
}

export function removeFieldFromArray<T extends CustomFieldsConfigurationBase>(
    position: number,
    fields: T[] | null,
): T[] {
    return fields ? fields.filter((_, index) => index !== position) : [];
}

export function isSurveyQuestionsValid<T extends CustomFieldsConfigurationBase>(
    field: T | null,
): boolean {
    if (!field) return false;

    return getFieldId(field) != null && !field.deleted;
}

export const customFieldTemplateReference = 'field';
export function buildFieldIdForTemplateReference<
    T extends CustomFieldsConfigurationBase,
>(id: T['id']): string {
    return `${customFieldTemplateReference}${id ?? ''}`;
}

// ############### GROUP FIELDS FUNCTIONS ############################
export function isParentOfGroupedField<T extends CustomFieldsConfigurationBase>(
    field: T,
): boolean {
    return (
        isDefined(field.metadata.field_group_id) &&
        field.metadata.field_group_id === getFieldId(field)
    );
}

export function isChildOfGroupedField<T extends CustomFieldsConfigurationBase>(
    field: T,
): boolean {
    return (
        isDefined(field.metadata.field_group_id) &&
        field.metadata.field_group_id !== getFieldId(field)
    );
}

export const canSortFieldInList = <
    T extends CustomFieldsConfigurationBase & { order: number },
>(
    index: number,
    movedField: T,
    hoveredField: T,
): boolean => {
    const movedIdx = movedField.order - 1;
    const movedIsParentOfGroup = isParentOfGroupedField(movedField);
    const hoveredIsChildOfGroup = isChildOfGroupedField(hoveredField);
    const hoveredIsParentOfGroup = isParentOfGroupedField(hoveredField);

    // Helper functions for condition checks
    const isDifferentGroup = () =>
        movedField.metadata.field_group_id !==
        hoveredField.metadata.field_group_id;

    const cannotMoveDeleted = () => movedField.deleted || hoveredField.deleted;
    const cannotMoveChild = () => isChildOfGroupedField(movedField);

    const cannotMoveParentUnderChild = () =>
        movedIsParentOfGroup &&
        hoveredIsChildOfGroup &&
        hoveredField.metadata.field_group_id === movedField.id;

    const cannotMoveNextInGroup = () =>
        movedIdx > index &&
        hoveredIsChildOfGroup &&
        (!movedIsParentOfGroup || (movedIsParentOfGroup && isDifferentGroup()));

    const cannotMovePrevInGroup = () =>
        movedIdx < index &&
        hoveredIsParentOfGroup &&
        (!movedIsParentOfGroup || (movedIsParentOfGroup && isDifferentGroup()));

    // Check conditions
    return (
        !cannotMoveDeleted() &&
        !cannotMoveChild() &&
        !cannotMoveParentUnderChild() &&
        !cannotMoveNextInGroup() &&
        !cannotMovePrevInGroup()
    );
};

export const canGroupWithNext = <
    T extends CustomFieldsConfigurationBase,
>(data: {
    field: T;
    allFields: T[];
}): boolean => {
    const fieldType = data.field.type.code;

    if (
        !getGroupableFieldTypes().includes(fieldType) ||
        isDefined(data.field.metadata.field_group_id) ||
        data.allFields.length < 2
    ) {
        return false;
    }

    const currentFieldIdx = data.allFields.findIndex(
        (f) => getFieldId(f) === getFieldId(data.field),
    );

    if (currentFieldIdx < 0 || currentFieldIdx === data.allFields.length - 1) {
        return false;
    }

    const nextField = data.allFields[currentFieldIdx + 1];

    return (
        !nextField.deleted &&
        !isDefined(nextField.metadata.field_group_id) &&
        nextField.type.code === fieldType
    );
};

export const canGroupWithPrevious = <
    T extends CustomFieldsConfigurationBase,
>(data: {
    field: T;
    allFields: T[];
}): boolean => {
    const fieldType = data.field.type.code;
    if (
        !getGroupableFieldTypes().includes(fieldType) ||
        isDefined(data.field.metadata.field_group_id) ||
        data.allFields.length < 2
    ) {
        return false;
    }

    const currentFieldIdx = data.allFields.findIndex(
        (f) => getFieldId(f) === getFieldId(data.field),
    );

    if (currentFieldIdx < 1) {
        return false;
    }

    const prevField = data.allFields[currentFieldIdx - 1];

    return (
        !prevField.deleted &&
        !isDefined(prevField.metadata.field_group_id) &&
        prevField.type.code === fieldType
    );
};

// ############### END OF GROUP FIELDS FUNCTIONS ############################

export const getAdditionalFieldTypesAvailable = (
    translateService: TranslateService,
): Observable<ConfigurableFieldTypeInfo[]> => {
    return from(ConfigurableFieldTypes).pipe(
        map((field) => ({
            ...field,
            label: translateService.instant(field.label) as string,
        })),
        toArray(),
    );
};

export const duplicateEnumValues = (
    enumValues?: CustomFieldsConfigurationEnumValues[],
): CustomFieldsConfigurationEnumValues[] | undefined =>
    enumValues?.map((e) => ({
        ...e,
        id: undefined,
        clientRef: uuid(),
    }));

export const duplicateField = <
    T extends CustomFieldsConfigurationBaseWithValidationAndOrder,
>(
    fieldToDuplicate: T,
): T => {
    return {
        ...fieldToDuplicate,
        id: undefined,
        localId: null,
        order:
            fieldToDuplicate.order +
            1 +
            (isParentOfGroupedField(fieldToDuplicate) ? 1 : 0),
        metadata: {
            ...fieldToDuplicate.metadata,
            field_group_id: undefined,
        },
        enumValues: duplicateEnumValues(fieldToDuplicate.enumValues),
    };
};
