import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterNull',
})
export class FilterNullPipe implements PipeTransform {
    transform<T>(array: (T | null | undefined)[]): T[] {
        return array.filter((x) => x != null) as T[];
    }
}
