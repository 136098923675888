import { mapArrayById } from '@interacta-shared/util';
import {
    AdminV2PaginatedFilters,
    DefaultAdminV2PaginatedFilters,
} from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { fromAdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.serialize';
import { fromAdminV2Date } from '@modules/admin-v2-shared/utils/admin-v2-filters';
import { IUser } from '@modules/core';
import { getMemberUser } from '@modules/core/models/member/member.utils';
import { DefaultSortOrder } from '../utils/admin-v2-user-config';
import {
    AdminV2NewUser,
    AdminV2ResetCustomCredentials,
    AdminV2UserCredentials,
    AdminV2UserDetail,
    AdminV2UserFilters,
    AdminV2UserLoginProvider,
    AdminV2UserRow,
    AdminV2UserSort,
    AdminV2UserStatusFilter,
} from './admin-v2-user.model';

export const fromAdminV2UserFilters = (
    filters: AdminV2UserFilters,
    paginatedFilters?: AdminV2PaginatedFilters,
    sort?: AdminV2UserSort,
): any => {
    if (!sort) {
        sort = DefaultSortOrder;
    }
    if (!paginatedFilters) {
        paginatedFilters = DefaultAdminV2PaginatedFilters;
    }

    const { from: createdFrom, to: createdTo } = fromAdminV2Date(
        filters.created,
    );
    const { from: lastAccessFrom, to: lastAccessTo } = fromAdminV2Date(
        filters.lastAccess,
    );

    return {
        fullTextFilter: filters.search,
        statusFilter: fromAdminV2UserStatusFilter(filters.status),
        excludeByGroupId: filters.excludeUsersByMemberGroupId,
        excludeByAreaId: filters.excludeByAreaId,
        excludeByBusinessUnitId: filters.excludeByBusinessUnitId,
        workspaceIds: filters.workspaceIds,
        creationTimestampFrom: createdFrom,
        creationTimestampTo: createdTo,
        lastAccessTimestampFrom: lastAccessFrom,
        lastAccessTimestampTo: lastAccessTo,
        personalEmailFullTextFilter: filters.personalEmail,
        externalIdFullTextFilter: filters.externalId,
        areaIds: mapArrayById(filters.areas),
        businessUnitIds: mapArrayById(filters.businessUnits),
        role: filters.role,
        managerIds: mapArrayById(filters.managers),
        place: filters.place,
        lang: filters.languages?.map((x) => x.code),
        timeZoneIds: mapArrayById(filters.timezones),
        peopleSectionEnabled: filters.peopleSection,
        visibleInPeopleSection: filters.visibleInPeople,
        reducedProfile: filters.reducedProfile,
        viewUserProfiles: filters.viewUserProfiles,
        loginProviderFilter: getLoginProviderFilter(
            filters.loginProviders ?? [],
        ),
        twoStepAuthenticationEnabled: filters.twoStepAuthenticationEnabled,
        orderTypeId: getSortColumn(sort.sortColumn),
        orderDesc: sort.orderDesc,
        checkGroupMember: filters.checkGroupMember,
        checkAreaMember: filters.checkAreaMember,
        checkBusinessUnitMember: filters.checkBusinessUnitMember,
        ...fromAdminV2PaginatedFilters(paginatedFilters),
    };
};

const getSortColumn = (sortColumn: keyof AdminV2UserRow) => {
    switch (sortColumn) {
        case 'user':
            return 'surname';
        default:
            return sortColumn;
    }
};

const fromAdminV2UserStatusFilter = (
    status: AdminV2UserStatusFilter,
): number[] => {
    switch (status) {
        case AdminV2UserStatusFilter.ACTIVE_USERS:
            return [0];
        case AdminV2UserStatusFilter.ALL_USERS:
            return [];
        case AdminV2UserStatusFilter.BLOCKED_USERS:
            return [4];
        case AdminV2UserStatusFilter.DELETED_USERS:
            return [1];
    }
};

const getLoginProviderFilter = (
    providers: AdminV2UserLoginProvider[],
): string[] => {
    const filter = [];
    for (const login of providers) {
        switch (login) {
            case AdminV2UserLoginProvider.GOOGLE:
                filter.push('google');
                break;
            case AdminV2UserLoginProvider.MICROSOFT:
                filter.push('microsoft');
                break;
            case AdminV2UserLoginProvider.INTERACTA:
                filter.push('custom');
                break;
            case AdminV2UserLoginProvider.SPID:
                filter.push('spid');
                break;
        }
    }
    return filter;
};

export const fromAdminV2UserRegistryResetCustomCredentials = (
    command: AdminV2ResetCustomCredentials,
): any => {
    const record: any = {
        generatePassword: !!command.generatePassword,
        forceCredentialsExpiration: !!command.forceCredentialsExpiration,
        emailNotifyRecipients: command.emailNotifyRecipients,
    };
    if (command.password) {
        record.password = command.password;
    }
    return record;
};

export const fromAdminV2UserCredentials = (
    credentials: AdminV2UserCredentials,
): any => ({
    ...credentials,
    custom: credentials.custom
        ? {
              username: credentials.custom.username,
              canManageProfilePhoto: credentials.custom.canManageProfilePhoto,
              canUserManageCustomCredentials:
                  credentials.custom.canUserManageCustomCredentials,
              profilePhoto: credentials.custom.profilePhoto,
              profilePhotoUrl: credentials.custom.profilePhotoUrl,
              active: credentials.custom.enabled,
              twoStepAuthenticationEnabled:
                  credentials.custom.twoStepAuthenticationEnabled,
          }
        : null,
});

const fromIUser = (user: IUser): any => ({ id: user.id });

export const fromAdminV2UserDetail = (userDetail: AdminV2UserDetail): any => ({
    firstname: userDetail.user.firstName,
    lastname: userDetail.user.lastName,
    contactEmail: userDetail.contactEmail,
    privateEmail: userDetail.privateEmail,
    occToken: userDetail.occToken,
    externalId: userDetail.externalId,
    caption: userDetail.caption,
    userInfo: {
        phone: userDetail.info?.phone || null,
        internalPhone: userDetail.info?.internalPhone || null,
        mobilePhone: userDetail.info?.mobilePhone || null,
        place: userDetail.info?.place || null,
        biography: userDetail.info?.biography || null,
        area: userDetail.info?.area,
        businessUnit: userDetail.info?.businessUnit,
        role: userDetail.info?.role || null,
        manager: userDetail.info?.manager
            ? fromIUser(userDetail.info?.manager)
            : null,
        employees:
            userDetail.info?.employees && userDetail.info?.employees.length > 0
                ? userDetail.info?.employees.map(fromIUser)
                : null,
    },
    userPreferences: {
        defaultLanguageId: userDetail.language?.code ?? null,
        defaultTimezoneId: userDetail.timezone?.id ?? null,
        emailNotificationsEnabled: userDetail.emailNotificationsEnabled,
    },
    userSettings: {
        peopleSectionEnabled: userDetail.settings.peopleSectionEnabled,
        visibleInPeopleSection: userDetail.settings.visibleInPeopleSection,
        reducedProfile: userDetail.settings.reducedProfile,
        viewUserProfiles: userDetail.settings.viewUserProfiles,
        editPrivateEmailEnabled: userDetail.settings.editPrivateEmailEnabled,
    },
});

export const fromAdminV2NewUser = (user: AdminV2NewUser): any => {
    const manager = user.manager ? getMemberUser(user.manager) : null;
    return {
        firstname: user.firstName,
        lastname: user.lastName,
        contactEmail: user.contactEmail,
        privateEmail: user.privateEmail,
        caption: user.caption,
        externalId: user.externalId,
        userInfo: {
            biography: user.biography,
            area: user.areaId !== null ? { id: user.areaId } : null,
            businessUnit:
                user.businessUnitId !== null
                    ? { id: user.businessUnitId }
                    : null,
            role: user.role,
            manager: manager ? fromIUser(manager) : null,
        },
    };
};
