import { PartialSharedProfile } from '@modules/core';
import { createAction, props } from '@ngrx/store';
import { PeopleFilters, PeopleOrderType } from '../models/people.model';

export const fetchPeople = createAction('[People] Fetch People');

export const fetchPeopleNextPage = createAction(
    '[People] Fetch People next page',
);

export const addFilters = createAction(
    '[People] Add Filters',
    props<{
        addFilters: Partial<PeopleFilters>;
    }>(),
);

export const removeFilters = createAction(
    '[People] Remove Filters',
    props<{
        removeFilters: (keyof PeopleFilters)[];
    }>(),
);

export const changeOrderBy = createAction(
    '[People] Change Order By',
    props<{
        orderType: PeopleOrderType;
    }>(),
);

export const toggleOrderDesc = createAction('[People] Toggle Order Desc');

export const changeFilters = createAction(
    '[People] Change Filters',
    props<{
        filters: PeopleFilters;
    }>(),
);

export const changeFiltersSuccess = createAction(
    '[People] Change Filters Success',
    props<{ updatedFilters: PeopleFilters }>(),
);

export const toggleFollowPerson = createAction(
    '[People] Toggle Follow Person',
    props<{
        user: PartialSharedProfile;
    }>(),
);

export const updatePerson = createAction(
    '[People] Update Person',
    props<{
        user: PartialSharedProfile;
    }>(),
);
