import Quill, { Delta } from 'quill/core';
import Clipboard from 'quill/modules/clipboard';

let quillPlainClipboardRegistered = false;

export function registerQuillPlainClipboard(): void {
    if (!quillPlainClipboardRegistered) {
        class PlainClipboard extends Clipboard {
            override onCapturePaste(e: ClipboardEvent) {
                e.preventDefault();
                const clipboardData: DataTransfer | null = e.clipboardData;
                if (!clipboardData || clipboardData.types.includes('Files'))
                    return;

                const range = this.quill.getSelection();
                const text = clipboardData.getData('text/plain');
                if (range) {
                    const delta = new Delta()
                        .retain(range.index)
                        .delete(range.length)
                        .insert(text);
                    const index = text.length + range.index;
                    const length = 0;
                    this.quill.updateContents(delta, 'user');
                    this.quill.setSelection(index, length, 'silent');
                    this.quill.scrollSelectionIntoView();
                }
            }
        }

        Quill.register('modules/clipboard', PlainClipboard, true);
        quillPlainClipboardRegistered = true;
    }
}
