import {
    ReportAbuse,
    ReportAbuseEntityType,
    ReportAbuseType,
} from './report-abuse.model';

export const fromReportAbuse = (report: ReportAbuse): any => ({
    postId:
        report.entity.type === ReportAbuseEntityType.Post
            ? report.entity.id
            : null,
    postAttachmentId:
        report.entity.type === ReportAbuseEntityType.Attachment
            ? report.entity.id
            : null,
    postCommentId:
        report.entity.type === ReportAbuseEntityType.Comment
            ? report.entity.id
            : null,
    type: fromReportAbuseType(report.type),
    notes: report.notes,
});

export const fromReportAbuseType = (reportType: ReportAbuseType): number => {
    switch (reportType) {
        case ReportAbuseType.Nudes:
            return 1;
        case ReportAbuseType.ReservedInfo:
            return 2;
        case ReportAbuseType.Spam:
            return 3;
        case ReportAbuseType.Violence:
            return 4;
    }
};
