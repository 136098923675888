import { isDefined } from '@interacta-shared/util';
import { SurveyRecipientTagExcludedGroups } from '@modules/post/models/survey-post/survey-post.model';
import { Member } from '../member/member.model';
import { Tag, TagMember } from './tag.model';

export function isMemberOfTagMember(member: TagMember): member is Member {
    return (member as Member).innerId !== undefined;
}

export function isTagOfTagMember(member: TagMember): member is Tag {
    return typeof member.id === 'number' && member.hasOwnProperty('name');
}

export const extractMembersFromTagMembers = (
    tagMembers: TagMember[],
): Member[] => {
    const memberList: Member[] = tagMembers
        .map((t) => (isMemberOfTagMember(t) ? t : undefined))
        .filter(isDefined);
    return memberList;
};

export const extractTagsFromTagMembers = (tagMembers: TagMember[]): Tag[] => {
    const tagList: Tag[] = tagMembers
        .map((t) => (isTagOfTagMember(t) ? t : undefined))
        .filter(isDefined);
    return tagList;
};

export const getExcludedGroupCountByTagId = (data: {
    tagId: number;
    tagExcludedGroups: SurveyRecipientTagExcludedGroups[] | null;
}): number => {
    return (
        data.tagExcludedGroups?.find((r) => r.tagId === data.tagId)
            ?.excludedGroups.length ?? 0
    );
};

export const sortAlphabeticallyByIdTagMembers = (
    a: TagMember,
    b: TagMember,
): number => {
    const aIsMember = isMemberOfTagMember(a);
    const bIsMember = isMemberOfTagMember(b);
    return aIsMember && !bIsMember
        ? 1
        : ((!aIsMember && !bIsMember) || (aIsMember && bIsMember)) &&
            a.id > b.id
          ? 1
          : -1;
};
