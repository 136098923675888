<div class="flex flex-col space-y-4">
    <div
        class="flex cursor-pointer items-center justify-between"
        (click)="toggleCollapsed.emit()"
        (mouseenter)="hover = true"
        (mouseleave)="hover = false"
    >
        <div class="flex items-center">
            <button class="flex items-center space-x-4 text-left" type="button">
                <span class="typo-sm-bold"> {{ label }}</span>
                <span
                    *ngIf="active"
                    class="bg-surface-primary h-12 w-12 rounded-full"
                ></span>
            </button>
            <button
                *ngIf="pinnable"
                interacta-icon-button
                size="extra-small"
                type="button"
                [class.hidden]="!pinned && !hover"
                [icon]="'pin'"
                [ngClass]="{
                    'text-text-primary': pinned,
                    'text-text-mid-contrast': !pinned
                }"
                (click)="$event.stopPropagation(); togglePinned.emit()"
            ></button>
        </div>
        <button
            interacta-icon-button
            size="extra-small"
            type="button"
            [icon]="collapsed ? 'chevron-small-down' : 'chevron-small-up'"
        ></button>
    </div>

    <div
        *ngIf="!collapsed"
        class="flex flex-col"
        [@.disabled]="!initialized"
        @field
    >
        <ng-content></ng-content>
    </div>
</div>
