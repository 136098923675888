import { isDefined } from '@interacta-shared/util';
import {
    CommunityTreeType,
    ICommunity,
    ICommunityTree,
} from '@modules/communities/models/communities.model';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export class CommunitiesState {
    communityTree$ = new ReplaySubject<ICommunityTree | null>(1);
    adminCommunityTree: ICommunityTree | null = null;
    insightsCommunitiesTree$ = new ReplaySubject<ICommunityTree | null>(1);

    isInitialized$: Observable<boolean>;
    communityList$: Observable<ICommunity[]>;
    insightsCommunities$: Observable<ICommunity[]>;
    operativityCommunities$: Observable<ICommunity[]>;
    communitiesVisibleInDashboard$: Observable<ICommunity[]>;
    operativityCommunitiesAttachmentEnable$: Observable<ICommunity[]>;
    pinnedCommunitiesIds$ = new BehaviorSubject<
        Pick<ICommunity, 'id' | 'pinnedTimestamp'>[]
    >([]);

    constructor() {
        this.isInitialized$ = this.communityTree$.pipe(
            map((communityTree) => isDefined(communityTree)),
        );

        this.communityList$ = this.communityTree$.asObservable().pipe(
            filter(isDefined),
            map((communityTree) => communityTree.communityList),
        );

        this.insightsCommunities$ = this.insightsCommunitiesTree$
            .asObservable()
            .pipe(
                map(
                    (communityTree) =>
                        communityTree?.communityList.filter(
                            (c) =>
                                c.capabilities?.accessBaseInsights ||
                                (c.capabilities?.accessWorkflowInsights &&
                                    c.metadata?.workflowDefinition),
                        ) ?? [],
                ),
            );

        this.operativityCommunities$ = this.communityTree$
            .asObservable()
            .pipe(
                map(
                    (communityTree) =>
                        communityTree?.communityList.filter(
                            (c) => c.capabilities?.createPost,
                        ) ?? [],
                ),
            );

        this.communitiesVisibleInDashboard$ = this.communityTree$.pipe(
            map(
                (communityTree) =>
                    communityTree?.communityList.filter(
                        (c) => c.visibleInDashboard,
                    ) ?? [],
            ),
        );

        this.operativityCommunitiesAttachmentEnable$ =
            this.operativityCommunities$.pipe(
                map(
                    (communityList) =>
                        communityList?.filter(
                            (c) => c.metadata?.attachmentEnabled,
                        ) ?? [],
                ),
            );
    }

    getTreeByType(type: CommunityTreeType): Observable<ICommunityTree | null> {
        return (
            type === 'insights'
                ? this.insightsCommunitiesTree$
                : this.communityTree$
        ).asObservable();
    }
}
