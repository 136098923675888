import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'interacta-badge-draft',
    templateUrl: './badge-draft.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeDraftComponent {
    @Input() label = 'COMMUNITIES.LABEL_DRAFT';
}
