import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    Timezone,
    ZonedDateTime,
} from '@interacta-shared/data-access-configuration';
import {
    formatDateUsingIntl,
    intlFormatMap,
} from '@interacta-shared/util-common';
import { TranslateService } from '@ngx-translate/core';
import { isSameDay, isToday, isTomorrow, isYesterday } from 'date-fns';

interface IZonedDateRange {
    from: ZonedDateTime | Date;
    to?: ZonedDateTime | Date;
}

interface ILocalDateZone {
    date: string;
    timezone?: string;
}
/**
 * See https://injenia.atlassian.net/l/cp/r6L3KnZu
 *
 */
@Component({
    selector: 'interacta-zoned-date[date]',
    templateUrl: './zoned-date.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZonedDateComponent implements OnChanges {
    @Input() formatOverride: Intl.DateTimeFormatOptions = {};
    @Input() date!: ZonedDateTime | IZonedDateRange | Date;
    // Wether the rendered label should display 'yesterday/today/tomorrow' instead of date
    @Input() shortDay = false;
    // Wether the date/rangeDate is marked as AllDay
    @Input() allDay = false;
    @Input() disableButton = false;
    @Input() display: 'inline' | 'content' = 'inline';

    isZoned = false;
    localDates: ILocalDateZone[] = [];
    zonedDate = '';
    isMenuOpen = false;
    readonly defaultFormat = intlFormatMap.datetime.format;
    private _date!: IZonedDateRange;

    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private configurationService: ConfigurationService,
    ) {}

    ngOnChanges(_: SimpleChanges): void {
        this._date = this.parseInputDate(this.date);

        const isFromADate = this.isInstanceOfDate(this._date.from);
        const isToADate = this.isInstanceOfDate(this._date.to);
        const fromLocalDate = isFromADate
            ? (this._date.from as Date)
            : (this._date.from as ZonedDateTime).localDatetime;
        const toLocalDate = isToADate
            ? (this._date.to as Date)
            : (this._date.to as ZonedDateTime)?.localDatetime;
        const fromTimezone: Timezone | undefined = isFromADate
            ? undefined
            : (this._date.from as ZonedDateTime).timezone;
        const toTimezone: Timezone | undefined = isToADate
            ? undefined
            : (this._date.to as ZonedDateTime)?.timezone;

        this.isZoned =
            (!!fromTimezone || !!toTimezone) &&
            (!this.isCurrentTimezone(fromTimezone) ||
                !this.isCurrentTimezone(toTimezone));

        if (this.isZoned) {
            const format: Intl.DateTimeFormatOptions = {
                ...this.defaultFormat,
                weekday: 'long',
                month: 'short',
            };
            this.localDates = [];
            this.localDates.push({
                date: this.formatDateRange(
                    { from: fromLocalDate },
                    format,
                    false,
                    this.allDay,
                ),
                timezone: fromTimezone
                    ? `(${fromTimezone.utcOffset}, ${fromTimezone.formattedZone})`
                    : undefined,
            });
            if (toLocalDate != null) {
                this.localDates.push({
                    date: this.formatDateRange(
                        { from: toLocalDate },
                        format,
                        false,
                        this.allDay,
                    ),
                    timezone: toTimezone
                        ? `(${toTimezone.utcOffset}, ${toTimezone.formattedZone})`
                        : undefined,
                });
            }
        }

        this.zonedDate = this.formatDateRange(
            this._date,
            { ...this.defaultFormat, ...this.formatOverride },
            this.shortDay,
            this.allDay,
        );
    }

    toggleTimezoneMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

    private parseInputDate(
        date: ZonedDateTime | IZonedDateRange | Date,
    ): IZonedDateRange {
        return this.isInstanceOfIZonedDateRange(date)
            ? date
            : {
                  from: date,
                  to: undefined,
              };
    }

    private isCurrentTimezone(timezone: Timezone | undefined): boolean {
        const systemTimezone = this.authService.getCurrentUserData()?.timezone;
        return timezone != null ? timezone.id === systemTimezone?.id : true;
    }

    private formatDateRange(
        date: IZonedDateRange,
        format?: Intl.DateTimeFormatOptions | undefined,
        shortDay = false,
        allDay = false,
    ): string {
        const dateFrom = this.isInstanceOfDate(date.from)
            ? date.from
            : date.from?.zonedDatetime;
        const dateTo =
            date.to && this.isInstanceOfDate(date.to)
                ? date.to
                : (date.to as ZonedDateTime)?.zonedDatetime;
        const isRanged = !!dateFrom && !!dateTo;
        const sameDay = isRanged && isSameDay(dateFrom, dateTo);
        let _format: Intl.DateTimeFormatOptions = format ?? this.defaultFormat;

        if (allDay) {
            _format = this.trimTime(_format);
        }

        let result = this.formatDate(dateFrom, _format, shortDay);

        if (isRanged) {
            if (sameDay && !allDay) {
                result += ` - ${formatDateUsingIntl(
                    dateTo,
                    this.trimDate(_format),
                    intlFormatMap.time.fallbackFormat,

                    this.configurationService.getCurrentLanguage().code,
                )}`;
            } else if (!sameDay) {
                result += ` - ${this.formatDate(dateTo, _format, shortDay)}`;
            }
        }

        if (sameDay && allDay) {
            result += `, ${
                this.translate.instant('DATE_TIME.ALL_DAY') as string
            }`;
        }

        return result;
    }

    private formatDate(
        date: Date,
        format: Intl.DateTimeFormatOptions,
        shortDay: boolean,
    ): string {
        let result = shortDay ? this.toShortDay(date) : '';

        if (result) {
            result += `, ${formatDateUsingIntl(
                date,
                this.trimDate(format),
                intlFormatMap.time.fallbackFormat,

                this.configurationService.getCurrentLanguage().code,
            )}`;
        } else {
            result = formatDateUsingIntl(
                date,
                format,
                intlFormatMap.date.fallbackFormat,
                this.configurationService.getCurrentLanguage().code,
            );
        }
        return result;
    }

    private toShortDay(date: Date): string {
        if (isYesterday(date)) {
            return `${this.translate.instant('DATE_TIME.YESTERDAY') as string}`;
        }
        if (isToday(date)) {
            return `${this.translate.instant('DATE_TIME.TODAY') as string}`;
        }
        if (isTomorrow(date)) {
            return `${this.translate.instant('DATE_TIME.TOMORROW') as string}`;
        }

        return '';
    }

    private trimTime(
        format: Intl.DateTimeFormatOptions,
    ): Intl.DateTimeFormatOptions {
        return {
            ...format,
            hour: undefined,
            minute: undefined,
            second: undefined,
        };
    }

    private trimDate(
        format: Intl.DateTimeFormatOptions,
    ): Intl.DateTimeFormatOptions {
        return {
            ...format,
            day: undefined,
            month: undefined,
            year: undefined,
            weekday: undefined,
        };
    }

    private isInstanceOfIZonedDateRange(date: any): date is IZonedDateRange {
        return 'from' in date && 'to' in date;
    }

    private isInstanceOfDate(date: any): date is Date {
        return date instanceof Date;
    }
}
