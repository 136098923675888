import { NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IconComponent } from '@interacta-shared/ui-icons';
import { Size } from '../../model';
import { LoaderComponent } from '../loader/loader.component';

@Component({
    selector: 'interacta-load-more',
    templateUrl: './load-more.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, IconComponent, LoaderComponent],
})
export class LoadMoreComponent {
    @Input({ required: true }) label!: string;
    @Input() arrow: 'up' | 'down' | 'none' = 'down';
    @Input() isLoading = false;
    @Input() size: Extract<Size, 'regular' | 'small' | 'large'> = 'small';

    @Output() loadMore = new EventEmitter<void>();
}
