import {
    AppRoute,
    adminV2,
    adminV2Area,
    adminV2BusinessUnit,
    adminV2Catalog,
    adminV2Community,
    adminV2Group,
    adminV2Index,
    adminV2LanguagesManager,
    adminV2People,
    adminV2Roles,
    adminV2Routes,
    adminV2User,
    adminV2WorkflowTemplate,
    adminV2Workspace,
    dashboard,
    dashboardRoutes,
    digitalWorkplaceAdminList,
    digitalWorkplaceRoutes,
    insights,
    insightsRoutes,
    manage,
    manageRoutes,
    people,
    peopleRoutes,
    workflowDesignerRoutes,
} from '@modules/app-routing/routes';
import { IWorkspace } from '@modules/communities/models/workspace.model';
import { AppRouterState } from '@modules/core/store/app-router.serializer';
import { ICommunity } from './../../communities/models/communities.model';
import {
    BaseSidebarSection,
    SectionSelected,
    SidebarSection,
    SidebarSectionAdmin,
    SidebarSectionAdminId,
    SidebarSectionId,
} from './sidebar.model';

const SIDEBAR_ROUTES: AppRoute[] = [
    ...dashboardRoutes,
    ...peopleRoutes,
    ...manageRoutes,
    ...insightsRoutes,
    digitalWorkplaceAdminList,
    ...adminV2Routes.filter(
        (r) =>
            r !== adminV2LanguagesManager &&
            !workflowDesignerRoutes.includes(r),
    ),
    ...digitalWorkplaceRoutes,
];

export function filterHiddenSections<T extends BaseSidebarSection>(
    sidebarSections: T[],
): T[] {
    return sidebarSections.filter((s) => !s.hidden);
}

export function isSidebarShown(route: AppRoute): boolean {
    return SIDEBAR_ROUTES.includes(route);
}

export function filterWorkspaces<
    T extends Pick<IWorkspace, 'name' | 'communities'>,
>(
    workspace: T,
    nameFilter: string,
    forceCommunityVisibileInOrganizationTree: boolean,
    forceCommunityCanCreatePost: boolean,
): T {
    const filteredCommunities = workspace?.communities?.filter(
        (community) =>
            filterCommunityCanCreatePost(
                forceCommunityCanCreatePost,
                community,
            ) &&
            filterCommunityVisibleInOrganizationTree(
                forceCommunityVisibileInOrganizationTree,
                community,
            ) &&
            filterCommunityByName(workspace, community, nameFilter),
    );

    return {
        ...workspace,
        communities: filteredCommunities,
    };
}

function filterCommunityVisibleInOrganizationTree(
    forceVisible: boolean,
    community: ICommunity,
): boolean {
    return forceVisible ? community.visibleInOrganizationTree : true;
}

function filterCommunityCanCreatePost(
    canCreate: boolean,
    community: ICommunity,
): boolean {
    return canCreate ? !!community.capabilities?.createPost : true;
}

function filterCommunityByName(
    workspace: Pick<IWorkspace, 'name' | 'communities'>,
    community: ICommunity,
    nameFilter: string,
): boolean {
    if (
        typeof workspace.name !== 'string' ||
        typeof community.name !== 'string'
    ) {
        return false;
    }

    const communityName = community.name.toLowerCase();
    const workspaceName = workspace.name.toLowerCase();
    const filterLowercase = nameFilter.toLowerCase();

    return (
        workspaceName.includes(filterLowercase) ||
        communityName.includes(filterLowercase)
    );
}

export const getSelectedSidebarSection = (
    route: AppRouterState | undefined,
    showDashboardLink: boolean,
): SidebarSectionId => {
    switch (route?.appBaseRoute) {
        case 'admin-v2':
            return 'admin-v2';
        case 'people':
            return 'people';
        case 'manage':
            return 'manage';
        case 'digital-workplace-admin':
            return 'digital_workplace_admin';
        case 'dashboard':
            return showDashboardLink || route.appRoute !== dashboard
                ? 'community'
                : 'home';
        case 'digital-workplace':
            return 'home';
        case 'insights':
            return 'insights';
        default:
            return 'home';
    }
};

export const routeToSidebarSectionAdmin = (
    route: AppRouterState,
): SidebarSectionAdminId | null => {
    if (route.appBaseRoute !== 'admin-v2') {
        return null;
    }

    switch (route.appRoute) {
        case adminV2People:
            return 'people-admin';
        case adminV2User:
            return 'user';
        case adminV2Group:
            return 'group';
        case adminV2Area:
        case adminV2BusinessUnit:
            return 'organization';
        case adminV2Workspace:
            return 'workspace';
        case adminV2Community:
            return 'community';
        case adminV2WorkflowTemplate:
            return 'workflow_template';
        case adminV2Roles:
            return 'roles';
        case adminV2Catalog:
            return 'catalog';
        case adminV2Index:
            return 'index';
        default:
            return 'overview';
    }
};

export const createNewButtonLabel = (
    sectionSelected: SectionSelected,
): string => {
    if (sectionSelected.sectionId === 'admin-v2') {
        switch (sectionSelected.sectionAdminId) {
            case 'user':
                return 'SIDEBAR.CREATE_NEW_USER';
            case 'group':
                return 'SIDEBAR.CREATE_NEW_GROUP';
            case 'organization':
                return `SIDEBAR.CREATE_NEW_${sectionSelected.route.appRoute === adminV2Area ? 'AREA' : 'BUSINESS_UNIT'}`;
            case 'roles':
                return 'SIDEBAR.CREATE_NEW_ROLE';
            case 'workspace':
                return 'SIDEBAR.CREATE_NEW_WORKSPACE';
            case 'community':
                return 'SIDEBAR.CREATE_NEW_COMMUNITY';
            case 'workflow_template':
                return 'SIDEBAR.CREATE_NEW_WORKFLOW_TEMPLATE';
            case 'catalog':
                return 'SIDEBAR.CREATE_NEW_CATALOG';
            default:
                return 'SIDEBAR.CREATE_NEW';
        }
    } else {
        switch (sectionSelected.sectionId) {
            default:
                return 'SIDEBAR.CREATE_NEW';
        }
    }
};

const CommunitySection: SidebarSection = {
    id: 'community',
    label: 'SIDEBAR.COMMUNITY',
};

export const CommunityAdvancedSection: SidebarSection = {
    ...CommunitySection,
    icon: 'chevron-small-left',
    iconPosition: 'left',
};
export const AdminV2Section: SidebarSection = {
    id: 'admin-v2',
    label: 'SIDEBAR.ADMINISTRATION',
    icon: 'chevron-small-left',
    iconPosition: 'left',
};

export const sidebarNotScrollableSections: SidebarSection[] = [
    {
        id: 'home',
        icon: 'home',
        label: 'SIDEBAR.HOME',
        path: '',
    },
    {
        ...CommunitySection,
        icon: 'people',
    },
];

export const sidebarScrollableSectionItems: SidebarSection[] = [
    {
        id: 'manage',
        icon: 'stream',
        label: 'SIDEBAR.MANAGE',
        path: manage,
    },
    {
        id: 'people',
        icon: 'person',
        label: 'SIDEBAR.PEOPLE',
        path: people,
    },
    {
        id: 'insights',
        icon: 'bar-chart',
        label: 'SIDEBAR.INSIGHTS',
        path: insights,
    },
    {
        id: 'digital_workplace_admin',
        icon: 'digital-wp',
        label: 'SIDEBAR.DIGITAL_WORKPLACE',
        path: digitalWorkplaceAdminList,
    },
    {
        ...AdminV2Section,
        icon: 'persons-cog',
    },
];

export const sidebarSectionAdminItems: SidebarSectionAdmin[] = [
    {
        id: 'overview',
        label: 'SIDEBAR.ADMIN_SUBMENU.OVERVIEW',
        imageName: 'admin',
        path: adminV2,
        uppercase: true,
        hidden: true,
    },
    {
        id: 'people-admin',
        label: 'SIDEBAR.ADMIN_SUBMENU.PEOPLE',
        imageName: 'person',
        path: adminV2People,
        uppercase: true,
    },
    {
        id: 'user',
        label: 'SIDEBAR.ADMIN_SUBMENU.USERS',
        path: adminV2User,
        uppercase: false,
    },
    {
        id: 'group',
        label: 'SIDEBAR.ADMIN_SUBMENU.GROUPS',
        path: adminV2Group,
        uppercase: false,
    },
    {
        id: 'organization',
        label: 'SIDEBAR.ADMIN_SUBMENU.ORGANIZATION',
        path: adminV2Area,
        uppercase: false,
    },
    {
        id: 'roles',
        label: 'SIDEBAR.ADMIN_SUBMENU.ROLES',
        imageName: 'roles',
        path: adminV2Roles,
        uppercase: true,
    },
    {
        id: 'workspace',
        label: 'SIDEBAR.ADMIN_SUBMENU.WORKSPACE',
        imageName: 'workspace',
        path: adminV2Workspace,
        uppercase: true,
    },
    {
        id: 'community',
        label: 'SIDEBAR.ADMIN_SUBMENU.COMMUNITY',
        imageName: 'community',
        path: adminV2Community,
        uppercase: true,
    },
    {
        id: 'workflow_template',
        label: 'SIDEBAR.ADMIN_SUBMENU.WORKFLOW_TEMPLATE',
        imageName: 'process',
        path: adminV2WorkflowTemplate,
        uppercase: true,
    },
    {
        id: 'catalog',
        label: 'SIDEBAR.ADMIN_SUBMENU.CATALOG',
        imageName: 'catalog',
        path: adminV2Catalog,
        uppercase: true,
    },
    {
        id: 'index',
        label: 'SIDEBAR.ADMIN_SUBMENU.INDEXES',
        imageName: 'index',
        path: adminV2Index,
        uppercase: true,
        hidden: true,
    },
];
