import { createAction, props } from '@ngrx/store';

export const clear = createAction('[Hashtag Info] Clear');

export const openDetail = createAction(
    '[Hashtag Info] Open Detail',
    props<{ hashtagId: number }>(),
);

export const closeDetail = createAction('[Hashtag Info] Close Detail');

export const fetchHashtagInfo = createAction(
    '[Hashtag Info] Fetch Hashtag Info',
    props<{ hashtagId: number }>(),
);

export const followHashtag = createAction(
    '[Hashtag Info] Follow Hashtag',
    props<{ hashtagId: number; follow: boolean; showTip?: boolean }>(),
);
