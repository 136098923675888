import { Pipe, PipeTransform } from '@angular/core';
import { PaginatedList } from '@interacta-shared/util';

@Pipe({
    name: 'showNoResults',
})
export class ShowNoResultsPipe implements PipeTransform {
    transform<T>(paginatedList?: PaginatedList<T> | null): boolean {
        return (
            paginatedList != null &&
            !paginatedList.isFetching &&
            paginatedList.nextPageToken.tag === 'lastLoading' &&
            paginatedList.totalCount === 0 &&
            paginatedList.list.length === 0
        );
    }
}
