<input
    multiple
    ng2FileSelect
    type="file"
    accept="{{ acceptedMimeType?.join() ?? null }}"
    [hidden]="true"
    id="{{ inputId }}"
    [uploader]="uploader"
    (cancel)="$event.stopPropagation()"
    (onFileSelected)="fileSelected.emit($event)"
/>
