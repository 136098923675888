import { Pipe, PipeTransform } from '@angular/core';

/**
 * Trucates in the middle a label longer than minClampChars characters.
 * Consieder to use interacta-inner-ellipses-text instead. Is more dynamic.
 */
@Pipe({
    name: 'middleEllipsis',
})
export class MiddleEllipsisPipe implements PipeTransform {
    transform(label: string | null | undefined, minClampChars = 18): string {
        if (!label) return '';
        const clampChars = minClampChars < 3 ? 6 : minClampChars;

        if (label.length > clampChars) {
            return (
                label.substring(0, clampChars / 2 - 3) +
                '...' +
                label.substring(label.length - clampChars / 2)
            );
        } else {
            return label;
        }
    }
}
