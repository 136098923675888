import { isDefined } from '@interacta-shared/util';
import { parseUrl } from '@interacta-shared/util-common';

export const login = 'login';
export const loginForgotPassword = 'login/forgot-password';
export const loginExpiredSession = 'login/expired-session';
export const loginVerifyCredentials = 'login/verify-credentials';
export const loginTerms = 'login/terms';

export const dashboard = 'dashboard';
export const dashboardCommunity = 'community';

export const post = 'post';
export const postDetailsVisibility = '/post/visibility';
export const postDetailsWorkflow = '/post/workflow';
export const postDetailsTasks = '/post/tasks';
//export const postDetailsChronology = '/post/chronology';
export const postDetailsComments = '/post/comments';
export const postDetailsAttachments = '/post/attachments';
export const postDetailsInvitations = '/post/invitations';
export const postDetailsAI = '/post/ai';

export const postCreate = 'post/create';
export const postEdit = 'post/edit';
export const postCopy = 'post/copy';
export const postPrint = 'post/print';

export const postFeedbackMonitor = 'post/feedback/monitor';
export const postFeedbackRecipient = 'post/feedback/recipient';

export const search_v2 = 'search-v2';
export const profile = 'profile';

export const outOfService = 'error/out-of-service';
export const pageNotFound = 'error/page-not-found';
export const forbiddenAccess = 'error/forbidden-access';

export const verifyPrivateEmail = 'verify-private-email';
export const unsubscribe = 'unsubscribe';

export const adminV2 = 'admin-v2';
export const adminV2People = 'admin-v2/people';
export const adminV2User = 'admin-v2/user';
export const adminV2Group = 'admin-v2/group';
export const adminV2Area = 'admin-v2/organization/area';
export const adminV2BusinessUnit = 'admin-v2/organization/business-unit';
export const adminV2Workspace = 'admin-v2/workspace';
export const adminV2Community = 'admin-v2/community';
export const adminV2WorkflowTemplate = 'admin-v2/workflow-templates';
export const adminV2WorkflowCreate = 'admin-v2/workflow-designer/create';
export const adminV2WorkflowEdit = 'admin-v2/workflow-designer/edit';
export const adminV2WorkflowView = 'admin-v2/workflow-designer/view';
export const adminV2Roles = 'admin-v2/roles';
export const adminV2LanguagesManager = 'admin-v2/languages-manager';
export const adminV2Catalog = 'admin-v2/catalog';
export const adminV2Index = 'admin-v2/index';
//todo insert here new adminv2 routes

export const people = 'people';

export const manage = 'manage';
export const manageDrafts = 'manage/drafts';
export const manageTasks = 'manage/tasks';
export const manageProcesses = 'manage/processes';
export const manageMentions = 'manage/mentions';

export const digitalWorkplaceAdminList = 'digital-workplace-admin/list';
export const digitalWorkplaceAdminEdit = 'digital-workplace-admin/edit';
export const digitalWorkplaceAdminPreview = 'digital-workplace-admin/preview';

export const digitalWorkplace = 'digital-workplace';
export const digitalWorkplaceHome = 'digital-workplace/home';

export const insights = 'insights';

export const streaming = 'streaming';

export const bootcamp = 'bootcamp';

export const communityDetail = 'community-detail';

export const notificationSettings = 'notification-settings';

export const loginRoutes: AppRoute[] = [
    login,
    loginForgotPassword,
    loginExpiredSession,
    loginVerifyCredentials,
    loginTerms,
];

export const dashboardRoutes: AppRoute[] = [dashboard, dashboardCommunity];

const postDetailRoutes: AppRoute[] = [
    post,
    postDetailsVisibility,
    postDetailsWorkflow,
    postDetailsTasks,
    //postDetailsChronology,
    postDetailsComments,
    postDetailsAttachments,
    postDetailsInvitations,
    postDetailsAI,
];

export const postEditRoutes: AppRoute[] = [postCreate, postEdit, postCopy];

export const workflowDesignerRoutes: AppRoute[] = [
    adminV2WorkflowCreate,
    adminV2WorkflowEdit,
    adminV2WorkflowView,
];

export const adminV2Routes: AppRoute[] = [
    adminV2,
    adminV2People,
    adminV2User,
    adminV2Group,
    adminV2Area,
    adminV2BusinessUnit,
    adminV2Workspace,
    adminV2Community,
    adminV2WorkflowTemplate,
    ...workflowDesignerRoutes,
    adminV2Roles,
    adminV2LanguagesManager,
    adminV2Catalog,
    adminV2Index,
];

const searchV2Routes: AppRoute[] = [search_v2];
const profileRoutes: AppRoute[] = [profile];

const errorRoutes: AppRoute[] = [pageNotFound, forbiddenAccess, outOfService];

const verifyPrivateEmailRoutes: AppRoute[] = [verifyPrivateEmail];
const unsubscribeRoutes: AppRoute[] = [unsubscribe];

export const peopleRoutes: AppRoute[] = [people];

export const digitalWorkplaceAdminRoutes: AppRoute[] = [
    digitalWorkplaceAdminList,
    digitalWorkplaceAdminEdit,
    digitalWorkplaceAdminPreview,
];

export const digitalWorkplaceRoutes: AppRoute[] = [
    digitalWorkplace,
    digitalWorkplaceHome,
];

export const manageRoutes: AppRoute[] = [
    manage,
    manageDrafts,
    manageMentions,
    manageProcesses,
    manageTasks,
];

export const insightsRoutes: AppRoute[] = [insights];

const streamingRoutes: AppRoute[] = [streaming];

export type IconMode = 'back' | 'menu' | 'none';

export const menuRoutes: AppRoute[] = [
    dashboard,
    dashboardCommunity,
    people,
    digitalWorkplaceAdminList,
    ...manageRoutes,
    ...insightsRoutes,
    ...adminV2Routes.filter(
        (r) =>
            r !== adminV2LanguagesManager &&
            !workflowDesignerRoutes.includes(r),
    ),
    ...digitalWorkplaceRoutes,
];

export const communityDetailRoutes: AppRoute[] = [communityDetail];

export const feedbackRoutes: AppRoute[] = [
    postFeedbackMonitor,
    postFeedbackRecipient,
];

export type AppRoute =
    | typeof login
    | typeof loginForgotPassword
    | typeof loginExpiredSession
    | typeof loginVerifyCredentials
    | typeof loginTerms
    | typeof dashboard
    | typeof dashboardCommunity
    | typeof post
    | typeof postDetailsVisibility
    | typeof postDetailsWorkflow
    | typeof postDetailsTasks
    //  | typeof postDetailsChronology
    | typeof postDetailsComments
    | typeof postDetailsAttachments
    | typeof postDetailsInvitations
    | typeof postDetailsAI
    | typeof postCreate
    | typeof postEdit
    | typeof postCopy
    | typeof postPrint
    | typeof search_v2
    | typeof profile
    | typeof outOfService
    | typeof pageNotFound
    | typeof forbiddenAccess
    | typeof verifyPrivateEmail
    | typeof unsubscribe
    | typeof adminV2
    | typeof adminV2People
    | typeof adminV2User
    | typeof adminV2Group
    | typeof adminV2Area
    | typeof adminV2BusinessUnit
    | typeof adminV2Workspace
    | typeof adminV2Community
    | typeof adminV2WorkflowTemplate
    | typeof adminV2WorkflowCreate
    | typeof adminV2WorkflowEdit
    | typeof adminV2WorkflowView
    | typeof adminV2Roles
    | typeof adminV2LanguagesManager
    | typeof adminV2Catalog
    | typeof adminV2Index
    | typeof people
    | typeof manage
    | typeof manageDrafts
    | typeof manageTasks
    | typeof manageProcesses
    | typeof manageMentions
    | typeof insights
    | typeof streaming
    | typeof bootcamp
    | typeof communityDetail
    | typeof notificationSettings
    | typeof postFeedbackMonitor
    | typeof postFeedbackRecipient
    | typeof digitalWorkplaceAdminList
    | typeof digitalWorkplaceAdminEdit
    | typeof digitalWorkplaceAdminPreview
    | typeof digitalWorkplace
    | typeof digitalWorkplaceHome;

export type AppBaseRoute =
    | 'login'
    | 'dashboard'
    | 'post-detail'
    | 'post-edit'
    | 'post-print'
    | 'post-feedback'
    | 'admin-v2'
    | 'search-v2'
    | 'profile'
    | 'error'
    | 'verify-private-email'
    | 'unsubscribe'
    | 'people'
    | 'manage'
    | 'digital-workplace-admin'
    | 'digital-workplace'
    | 'insights'
    | 'streaming'
    | 'bootcamp'
    | 'community-detail'
    | 'notification-settings';

export const differentialDetailBaseRoute: AppBaseRoute[] = [
    'streaming',
    'post-detail',
    'post-feedback',
];

export function toBaseRoute(appRoute: AppRoute | null): AppBaseRoute | null {
    if (!isDefined(appRoute)) {
        return null;
    } else if (loginRoutes.includes(appRoute)) {
        return 'login';
    } else if (dashboardRoutes.includes(appRoute)) {
        return 'dashboard';
    } else if (postDetailRoutes.includes(appRoute)) {
        return 'post-detail';
    } else if (postEditRoutes.includes(appRoute)) {
        return 'post-edit';
    } else if (postPrint === appRoute) {
        return 'post-print';
    } else if (feedbackRoutes.includes(appRoute)) {
        return 'post-feedback';
    } else if (adminV2Routes.includes(appRoute)) {
        return 'admin-v2';
    } else if (searchV2Routes.includes(appRoute)) {
        return 'search-v2';
    } else if (profileRoutes.includes(appRoute)) {
        return 'profile';
    } else if (errorRoutes.includes(appRoute)) {
        return 'error';
    } else if (verifyPrivateEmailRoutes.includes(appRoute)) {
        return 'verify-private-email';
    } else if (unsubscribeRoutes.includes(appRoute)) {
        return 'unsubscribe';
    } else if (peopleRoutes.includes(appRoute)) {
        return 'people';
    } else if (manageRoutes.includes(appRoute)) {
        return 'manage';
    } else if (digitalWorkplaceAdminRoutes.includes(appRoute)) {
        return 'digital-workplace-admin';
    } else if (digitalWorkplaceRoutes.includes(appRoute)) {
        return 'digital-workplace';
    } else if (insightsRoutes.includes(appRoute)) {
        return 'insights';
    } else if (streamingRoutes.includes(appRoute)) {
        return 'streaming';
    } else if (communityDetailRoutes.includes(appRoute)) {
        return 'community-detail';
    } else if (notificationSettings.includes(appRoute)) {
        return 'notification-settings';
    } else if ('bootcamp' === appRoute) {
        return 'bootcamp';
    } else {
        throw new Error('Unhandled route in toBaseRoute');
    }
}

export function urlToAppRoute(url: string): AppRoute | null {
    const { paths } = parseUrl(url);

    switch (paths[0]) {
        case 'dashboard': {
            return dashboard;
        }
        case 'login': {
            switch (paths[1]) {
                case 'forgot-password':
                    return loginForgotPassword;
                case 'expired-session':
                    return loginExpiredSession;
                case 'verify-credentials':
                    return loginVerifyCredentials;
                case 'terms':
                    return loginTerms;
                default:
                    return login;
            }
        }

        case 'community':
            return dashboardCommunity;
        case 'post': {
            switch (paths[1]) {
                case 'create':
                    return postCreate;
                case 'edit':
                    return postEdit;
                case 'copy':
                    return postCopy;
                case 'print':
                    return postPrint;
                case 'feedback':
                    switch (paths[3]) {
                        case 'monitor':
                            return postFeedbackMonitor;
                        case 'recipient':
                            return postFeedbackRecipient;
                    }
            }

            switch (paths[2]) {
                case 'visibility':
                    return postDetailsVisibility;
                case 'workflow':
                    return postDetailsWorkflow;
                case 'tasks':
                    return postDetailsTasks;
                /*                 case 'chronology':
                    return postDetailsChronology; */
                case 'comments':
                    return postDetailsComments;
                case 'attachments':
                    return postDetailsAttachments;
                case 'invitations':
                    return postDetailsInvitations;
                case 'ai':
                    return postDetailsAI;
                default:
                    return post;
            }
        }
        case 'admin-v2':
            switch (paths[1]) {
                case 'people':
                    return adminV2People;
                case 'user':
                    return adminV2User;
                case 'group':
                    return adminV2Group;
                case 'organization':
                    switch (paths[2]) {
                        case 'business-unit':
                            return adminV2BusinessUnit;
                        case 'area':
                        default:
                            return adminV2Area;
                    }
                case 'workspace':
                    return adminV2Workspace;
                case 'community':
                    return adminV2Community;
                case 'workflow-templates':
                    return adminV2WorkflowTemplate;
                case 'roles':
                    return adminV2Roles;
                case 'languages-manager':
                    return adminV2LanguagesManager;
                case 'catalog':
                    return adminV2Catalog;
                case 'index':
                    return adminV2Index;
                case 'workflow-designer':
                    switch (paths[2]) {
                        case 'view':
                            return adminV2WorkflowView;
                        case 'edit':
                            return adminV2WorkflowEdit;
                        case 'create':
                        default:
                            return adminV2WorkflowCreate;
                    }
                default:
                    return adminV2;
            }
        case 'search-v2':
            return search_v2;
        case 'profile':
            return profile;

        case 'error':
            switch (paths[1]) {
                case 'out-of-service':
                    return outOfService;
                case 'forbidden-access':
                    return forbiddenAccess;
                case 'page-not-found':
                    return pageNotFound;
                default:
                    return pageNotFound;
            }
        case 'verify-private-email':
            return verifyPrivateEmail;
        case 'unsubscribe':
            return unsubscribe;
        case 'people':
            return people;
        case 'manage':
            switch (paths[1]) {
                case 'drafts':
                    return manageDrafts;
                case 'tasks':
                    return manageTasks;
                case 'processes':
                    return manageProcesses;
                case 'mentions':
                    return manageMentions;
                default:
                    return manage;
            }
        case 'digital-workplace-admin':
            switch (paths[1]) {
                case 'list':
                    return digitalWorkplaceAdminList;
                case 'edit':
                    return digitalWorkplaceAdminEdit;
                case 'preview':
                    return digitalWorkplaceAdminPreview;
                default:
                    return digitalWorkplaceAdminList;
            }
        case 'digital-workplace':
            return paths.length === 1 ? digitalWorkplace : digitalWorkplaceHome;
        case 'streaming':
            return streaming;
        case 'insights':
            return insights;
        case 'community-detail':
            return communityDetail;
        case 'notification-settings':
            return notificationSettings;
        case 'bootcamp':
            return bootcamp;
        default:
            return null;
    }
    return null;
}

export function getIconMode(route: AppRoute | null): IconMode {
    if (route && menuRoutes.includes(route)) {
        return 'menu';
    }
    if (postPrint === route) {
        return 'none';
    }

    return 'back';
}
