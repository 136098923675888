import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Member } from '@modules/core/models/member/member.model';
import { Tag, TagMember } from '@modules/core/models/tag/tag.model';
import {
    extractMembersFromTagMembers,
    extractTagsFromTagMembers,
    isTagOfTagMember,
} from '@modules/core/models/tag/tag.utils';
import { SurveyRecipientTagExcludedGroups } from '@modules/post/models/survey-post/survey-post.model';

@Component({
    selector: 'interacta-input-tag-members-extended-field-dialog',
    templateUrl: './input-tag-members-extended-field-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTagMembersExtendedFieldDialogComponent implements OnChanges {
    @Input() isOpen = false;
    @Input({ required: true }) recipients: TagMember[] = [];
    @Input({ required: true })
    recipientsTagExcludedGroups: SurveyRecipientTagExcludedGroups[] = [];
    @Input() lockedIds: TagMember['id'][] = [];

    @Output() closeDialog = new EventEmitter<void>();
    @Output() updateRecipients = new EventEmitter<TagMember[]>();
    @Output() openTagMembers = new EventEmitter<Tag>();

    tags: Tag[] = [];
    members: Member[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isOpen || changes.recipients) {
            this.members = extractMembersFromTagMembers(this.recipients);
            this.tags = extractTagsFromTagMembers(this.recipients);
        }
    }

    confirm(): void {
        this.updateRecipients.emit([...this.tags, ...this.members]);
        this.closeDialog.emit();
    }

    splitTag(tag: Tag): void {
        this.openTagMembers.emit(tag);
        this.closeDialog.emit();
    }

    removeItemByChip(item: TagMember): void {
        if (isTagOfTagMember(item)) {
            this.tags = this.tags.filter((t) => t.id !== item.id);
        } else {
            this.members = this.members.filter((m) => m.id !== item.id);
        }
    }
}
