import { getNextPageToken } from '@interacta-shared/util';
import { AdminV2PaginatedFilters } from './admin-v2-paginated.model';

export const fromAdminV2PaginatedFilters = (
    filters: AdminV2PaginatedFilters,
): any => ({
    pageToken: getNextPageToken(filters.pageToken),
    pageSize: filters.pageSize ?? 25,
    calculateTotalItemsCount: filters.pageToken.tag === 'firstLoading',
});
