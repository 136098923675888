export interface InputSelectItem {
    id: number;
    label: string;
    temporaryContentViewLink?: string;
    temporaryContentPreviewImageLink?: string;
    temporaryContentPreviewImageHiResLink?: string;
}

export interface InputSelectSearchEvent {
    text: string;
    nextPageToken: string | null;
}

export const emptyInputSelectSearchEvent = (): InputSelectSearchEvent => ({
    nextPageToken: null,
    text: '',
});
