<button
    class="rounded-20 typo-sm-bold flex items-center"
    type="button"
    [disabled]="disabled"
    [ngClass]="{
        'space-x-8 px-12 py-8': size === 'regular',
        'space-x-4 px-8 py-4': size === 'small',
        'bg-surface-300': bgColor === 'gray-300',
        'bg-surface-primary-low-contrast': bgColor === 'primary-300'
    }"
    (click)="toggle.emit()"
>
    <interacta-icon
        *ngIf="icon"
        class="w-18 h-18 text-text"
        @icon
        [icon]="icon"
    ></interacta-icon>
    <span>{{ label }}</span>
    <span *ngIf="counter !== undefined">({{ counter }})</span>
</button>
