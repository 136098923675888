import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    ENVIRONMENT,
    Language,
} from '@interacta-shared/data-access-configuration';
import { InputSelectSearchEvent } from '@interacta-shared/ui';
import { PaginatedList, toPaginatedList } from '@interacta-shared/util';
import { toAdminV2MemberItem } from '@modules/admin-v2-shared/models/admin-v2-member.deserialize';
import {
    AdminV2PaginatedFilters,
    DefaultAdminV2PaginatedFilters,
} from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { fromAdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.serialize';
import { AdminV2OccToken } from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import {
    Member,
    MemberGroup,
    MemberUser,
} from '@modules/core/models/member/member.model';
import { Observable, map } from 'rxjs';
import {
    toAdminV2Community,
    toAdminV2CommunityDetailPartial,
    toAdminV2CommunityGroups,
    toAdminV2CommunityPostDefinition,
    toAdminV2CommunityPreview,
    toAdminV2CommunityRow,
    toAdminV2CommunityUsers,
} from '../models/admin-v2-community.deserialize';
import {
    AdminV2Community,
    AdminV2CommunityBaseInfoUpdate,
    AdminV2CommunityDetail,
    AdminV2CommunityDetailPartial,
    AdminV2CommunityFilters,
    AdminV2CommunityListPage,
    AdminV2CommunityLite,
    AdminV2CommunityPostDefinition,
    AdminV2CommunityPreview,
    AdminV2CommunitySettings,
    AdminV2CommunitySort,
    AdminV2CommunityStandardFields,
    AdminV2NewCommunity,
    PublishCommunityResponse,
} from '../models/admin-v2-community.model';
import {
    fromAdminV2CommunityBaseInfoUpdate,
    fromAdminV2CommunityFilters,
    fromAdminV2CommunityPostDefinition,
} from '../models/admin-v2-community.serialize';

@Injectable({ providedIn: 'root' })
export class AdminV2CommunityService {
    private readonly commonApiBasePath = inject(ENVIRONMENT).apiBasePath.common;
    private readonly baseUrlManage = `${this.commonApiBasePath}/admin/manage/communities`;
    private readonly baseUrlDataV3 = `${this.commonApiBasePath}/internal/v3/admin/data/communities`;
    private readonly baseUrlManageV3 = `${this.commonApiBasePath}/internal/v3/admin/manage/communities`;

    private readonly http = inject(HttpClient);

    getCommunityList(
        filters: AdminV2CommunityFilters,
        paginatedFilters?: AdminV2PaginatedFilters,
        sort?: AdminV2CommunitySort,
    ): Observable<AdminV2CommunityListPage> {
        return this.http
            .post<any>(
                `${this.baseUrlDataV3}`,
                fromAdminV2CommunityFilters(filters, paginatedFilters, sort),
            )
            .pipe(map((res) => toPaginatedList(res, toAdminV2CommunityRow)));
    }

    getCommunityWithCatalogList(
        filters: AdminV2CommunityFilters,
        paginatedFilters?: AdminV2PaginatedFilters,
        sort?: AdminV2CommunitySort,
    ): Observable<PaginatedList<AdminV2Community>> {
        return this.http
            .post<any>(
                `${this.baseUrlDataV3}/having-catalogs`,
                fromAdminV2CommunityFilters(filters, paginatedFilters, sort),
            )
            .pipe(map((res) => toPaginatedList(res, toAdminV2Community)));
    }

    getAdminMembers(
        search: InputSelectSearchEvent,
        pageSize?: number,
    ): Observable<PaginatedList<Member>> {
        return this.http
            .post<any>(`${this.baseUrlDataV3}/admin-members`, {
                pageToken: search.nextPageToken,
                fullTextFilter: search.text,
                calculateTotalItemsCount: !search.nextPageToken,
                pageSize: pageSize ?? 15,
            })
            .pipe(map((res) => toPaginatedList(res, toAdminV2MemberItem)));
    }

    getCommunityDetail(
        communityId: AdminV2Community['id'],
    ): Observable<AdminV2CommunityDetailPartial> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${communityId}/detail`)
            .pipe(map((res) => toAdminV2CommunityDetailPartial(res)));
    }

    getCommunityDetailLite(
        communityId: AdminV2Community['id'],
    ): Observable<AdminV2CommunityLite> {
        return this.http.get<AdminV2CommunityLite>(
            `${this.baseUrlDataV3}/${communityId}`,
        );
    }

    getCommunityPostDefinition(
        communityId: AdminV2Community['id'],
    ): Observable<AdminV2CommunityPostDefinition> {
        return this.http
            .get<any>(
                `${this.baseUrlDataV3}/${communityId}/post-definition/detail`,
            )
            .pipe(map((res) => toAdminV2CommunityPostDefinition(res)));
    }

    getCommunityGroups(
        filters: { communityId: number; search?: string },
        paginatedFilters?: AdminV2PaginatedFilters,
    ): Observable<PaginatedList<MemberGroup>> {
        return this.http
            .post<any>(
                `${this.baseUrlDataV3}/${filters.communityId}/members/groups`,
                {
                    fullTextFilter: filters.search || null,

                    ...fromAdminV2PaginatedFilters(
                        paginatedFilters || DefaultAdminV2PaginatedFilters,
                    ),
                },
            )
            .pipe(map((res) => toAdminV2CommunityGroups(res)));
    }

    getCommunityUsers(
        filters: { communityId: number; search?: string },
        paginatedFilters?: AdminV2PaginatedFilters,
    ): Observable<PaginatedList<MemberUser>> {
        return this.http
            .post<any>(
                `${this.baseUrlDataV3}/${filters.communityId}/members/users`,
                {
                    fullTextFilter: filters.search || null,

                    ...fromAdminV2PaginatedFilters(
                        paginatedFilters || DefaultAdminV2PaginatedFilters,
                    ),
                },
            )
            .pipe(map((res) => toAdminV2CommunityUsers(res)));
    }

    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrlManageV3}/${id}`);
    }

    activateById(
        id: number,
        activate: boolean,
        occToken: AdminV2OccToken,
    ): Observable<AdminV2OccToken> {
        return this.http
            .put<{ nextOccToken: AdminV2OccToken }>(
                `${this.baseUrlManageV3}/${id}/active`,
                { activate, occToken },
                // il query param skipValidations è provvisorio
                { params: { skipValidations: activate } },
            )
            .pipe(map((res) => res.nextOccToken));
    }

    editCommunityBaseInfo(
        id: number,
        occToken: AdminV2OccToken,
        update: AdminV2CommunityBaseInfoUpdate,
    ): Observable<{
        occToken: AdminV2OccToken;
    }> {
        return this.http
            .put<{
                occToken: AdminV2OccToken;
            }>(`${this.baseUrlManageV3}/${id}`, {
                ...fromAdminV2CommunityBaseInfoUpdate(update),
                occToken,
            })
            .pipe(
                map(({ occToken }) => ({
                    occToken,
                })),
            );
    }

    editCommunityPostDefinition(
        detail: AdminV2CommunityDetail,
        settings: AdminV2CommunitySettings,
        standardFields: AdminV2CommunityStandardFields,
    ): Observable<AdminV2OccToken> {
        return this.http
            .put<{
                occToken: AdminV2OccToken;
            }>(`${this.baseUrlManageV3}/${detail.id}/post-definition`, {
                ...fromAdminV2CommunityPostDefinition({
                    settings,
                    standardFields,
                    customFieldsEnabled: detail.customFieldsEnabled,
                    visibleInDashboard: detail.baseInfo.visibleInDashboard,
                    visibleInOrganizationTree:
                        detail.baseInfo.visibleInOrganizationTree,
                }),
                occToken: detail.occToken,
            })
            .pipe(map((res) => res.occToken));
    }

    // CREATION
    public createCommunity(
        community: AdminV2NewCommunity,
    ): Observable<AdminV2Community> {
        return this.http
            .post<any>(
                `${this.commonApiBasePath}/internal/v3/admin/manage/workspaces/${community.workspace.id}/communities`,
                fromAdminV2CommunityBaseInfoUpdate(community),
            )
            .pipe(map((res) => toAdminV2Community(res)));
    }

    publishCommunity(
        communityId: AdminV2Community['id'],
        occToken: AdminV2OccToken,
        skipRelationsValidation = false,
    ): Observable<PublishCommunityResponse> {
        return this.http
            .post<any>(
                `${this.baseUrlManage}/${communityId}/post-definition/${occToken}/publish`,
                {},
                // il query param skipValidations è provvisorio
                { params: { skipRelationsValidation, skipValidations: true } },
            )
            .pipe(
                map(
                    (res) =>
                        <PublishCommunityResponse>{
                            saved: true,
                            communityData: {
                                id: res.communityId,
                                occToken: res.nextOccToken,
                                draft: false,
                            },
                        },
                ),
            );
    }

    setVisibleInOrganizationTree(
        communityId: AdminV2Community['id'],
        occToken: AdminV2OccToken,
        visibleInOrganizationTree: boolean,
    ): Observable<number> {
        return this.http
            .put<{ nextOccToken: AdminV2OccToken }>(
                `${this.baseUrlManageV3}/${communityId}/visible`,
                {
                    occToken,
                    visibleInOrganizationTree,
                },
            )
            .pipe(map((res) => res.nextOccToken));
    }

    getTranslationLanguages(
        communityId: AdminV2Community['id'],
    ): Observable<Language[]> {
        return this.http
            .get<{
                languages: Language[];
            }>(`${this.baseUrlDataV3}/${communityId}/languages`)
            .pipe(map(({ languages }) => languages));
    }

    deleteTranslation(
        communityId: AdminV2Community['id'],
        language: Language,
    ): Observable<void> {
        return this.http.delete<any>(
            `${this.baseUrlManageV3}/${communityId}/i18ns/${language.code}`,
        );
    }

    getCommunityPreview(
        communityId: AdminV2Community['id'],
    ): Observable<AdminV2CommunityPreview> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${communityId}/overview`)
            .pipe(map((res) => toAdminV2CommunityPreview(res)));
    }
}
