<div
    class="flex w-full items-center space-x-12 py-4 pr-8"
    [ngClass]="{
        'hover:bg-surface-300': bgOnHover,
        'rounded-3xl pl-4': appearance === 'border',
        'pl-8': appearance === 'fill',
        'bg-surface-A': bgColor === 'surface-A',
        'bg-surface-B': bgColor === 'surface-B',
        'max-w-max': appearance === 'border' && fullWidth === false
    }"
>
    <interacta-member
        class="grow"
        [fontWeight]="'regular'"
        [member]="member"
        [memberClickBehavior]="memberClickBehavior"
        [showInfo]="showInfo"
        [size]="size"
        (click)="memberClickBehavior === 'none' && selectMember.emit(member)"
    >
    </interacta-member>

    <button
        *ngIf="canDelete"
        class="shrink-0"
        icon="close"
        interacta-icon-button
        type="button"
        (click)="deleteMember.emit(member)"
    ></button>
</div>
