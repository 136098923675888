<button
    interacta-button
    size="regular"
    type="button"
    [acceptedMimeType]="acceptedMimeType"
    [appearance]="appearance"
    [attr.aria-label]="label ?? tooltip"
    [icon]="'google-drive'"
    [label]="label"
    [matTooltip]="tooltip ?? ''"
    [matTooltipDisabled]="!tooltip"
    [shape]="shape"
    (googleDrivePickerV2)="addDriveAttachments.emit($event)"
></button>
