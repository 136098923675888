import { Index } from '../index.model';
import { IList } from '../list.model';
import { firstLoading, fromPageToken, lastLoading } from '../page-token';
import { PaginatedList } from './paginated-list.model';

export function emptyPaginatedList<T>(totalCount = 0): PaginatedList<T> {
    return {
        list: [],
        isFetching: false,
        nextPageToken: firstLoading(),
        totalCount,
    };
}

export function paginatedListFromArray<T>(array: T[]): PaginatedList<T> {
    return {
        list: array,
        isFetching: false,
        nextPageToken: lastLoading(),
        totalCount: array.length,
    };
}

export function paginatedListFromIList<T>(list: IList<T>): PaginatedList<T> {
    return {
        list: list.list,
        isFetching: false,
        nextPageToken: fromPageToken(list.nextPageToken),
        totalCount: list.totalCount ?? 0,
    };
}

export function toPaginatedList<T>(
    list: any,
    mapFn: (item: any) => T = (item: any) => item,
): PaginatedList<T> {
    return {
        list: list.items?.map(mapFn) ?? [],
        isFetching: false,
        nextPageToken: fromPageToken(
            list.nextPageToken as string | null | undefined,
        ),
        totalCount: list.totalItemsCount ?? 0,
    };
}

export function fetchPaginatedList<T>(
    currList: PaginatedList<T>,
    pageToken: string | undefined,
): PaginatedList<T> {
    return {
        ...(pageToken ? currList : emptyPaginatedList<T>(currList.totalCount)),
        isFetching: true,
    };
}

export function fetchPaginatedListSuccess<T, P extends PaginatedList<T>>(
    currList: P,
    nextList: P,
): P {
    const list = [...currList.list, ...nextList.list];
    return {
        ...currList,
        list,
        nextPageToken: nextList.nextPageToken,
        isFetching: false,
        totalCount:
            nextList.nextPageToken.tag === 'lastLoading'
                ? list.length
                : nextList.totalCount > 0
                  ? nextList.totalCount
                  : currList.totalCount,
    };
}

export function fetchPaginatedListError<T, P extends PaginatedList<T>>(
    currList: P,
): P {
    return {
        ...currList,
        isFetching: false,
    };
}

export function paginatedListAdd<T, P extends PaginatedList<T>>(
    list: P,
    item: T,
): P {
    return {
        ...list,
        list: [...list.list, item],
        totalCount: list.totalCount + 1,
    };
}

/**
 *
 * @param list
 * @returns undefined if list is fetching or firstLoading, totalCount otherwise
 */
export function getTotalCount<T>(list: PaginatedList<T>): number | undefined {
    return list.isFetching || list.nextPageToken.tag === 'firstLoading'
        ? undefined
        : list.totalCount;
}

export function paginatedListRemoveById<
    T extends { id: Index },
    P extends PaginatedList<T>,
>(plist: P, id: Index): P {
    return paginatedListRemoveByIds(plist, [id]);
}

export function paginatedListReplaceById<
    T extends { id: Index },
    P extends PaginatedList<T>,
>(plist: P, item: T): P {
    return {
        ...plist,
        list: plist.list.map((d) => (d.id === item.id ? item : d)),
    };
}

export function paginatedListRemoveByIds<
    T extends { id: Index },
    P extends PaginatedList<T>,
>(plist: P, ids: Index[]): P {
    const originalLenght = plist.list.length;
    const list = plist.list.filter((item) => !ids.includes(item.id));
    return {
        ...plist,
        list,
        totalCount: plist.totalCount + list.length - originalLenght,
    };
}

export const isPaginatedList = <T>(obj: object): obj is PaginatedList<T> =>
    'list' in obj &&
    'nextPageToken' in obj &&
    'totalCount' in obj &&
    'isFetching' in obj;
