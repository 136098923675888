import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    InputSelectSearchEvent,
    ScrollTrackerEvent,
    emptyInputSelectSearchEvent,
} from '@interacta-shared/ui';
import {
    PaginatedList,
    emptyPaginatedList,
    fetchPaginatedList,
    fetchPaginatedListSuccess,
    getNextPageToken,
    isDefined,
    mapArrayById,
    unique,
    uuid,
} from '@interacta-shared/util';
import { MetadataFieldModeType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { Member } from '@modules/core/models/member/member.model';
import {
    MemberTagTab,
    Tag,
    TagMember,
} from '@modules/core/models/tag/tag.model';
import {
    getExcludedGroupCountByTagId,
    isTagOfTagMember,
    sortAlphabeticallyByIdTagMembers,
} from '@modules/core/models/tag/tag.utils';
import {
    SurveyRecipientTagExcludedGroups,
    SurveyRecipientsGroup,
} from '@modules/post/models/survey-post/survey-post.model';
import { MemberService } from '@modules/shared-v2/services/member.service';
import { TagService } from '@modules/shared-v2/services/tag.service';
import {
    BehaviorSubject,
    Observable,
    Subject,
    concat,
    debounceTime,
    distinctUntilChanged,
    map,
    of,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs';
@Component({
    selector: 'interacta-input-tag-member-picker[control][communityId]',
    templateUrl: './input-tag-member-picker.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MemberService],
})
export class InputTagMemberPickerComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input() control!: FormControl<TagMember[] | null>;
    @Input() recipientsTagExcludedGroupsControl!: FormControl<
        SurveyRecipientTagExcludedGroups[] | null
    >;
    @Input() communityId!: number;
    @Input() maxSelectableItems = Infinity;
    @Input() isReadonly = false;
    @Input() lockedIds: TagMember['id'][] = [];
    @Input() recipientsTagsExcludedGroupLockedIds:
        | SurveyRecipientTagExcludedGroups[]
        | null = null;
    @Input() label?: string;

    value$: Observable<TagMember[] | null> | undefined;

    members$ = new BehaviorSubject(
        <PaginatedList<Member>>emptyPaginatedList(0),
    );
    tags$ = new BehaviorSubject(<PaginatedList<Tag>>emptyPaginatedList(0));

    textSearchControl = new FormControl<string>('');
    isLimitReached = false;

    selectedIds: TagMember['id'][] = [];

    activeTab: MemberTagTab = 'MEMBER';

    inputId = uuid();
    isOpen = false;

    tagGroupDialogData$ = new BehaviorSubject<{
        isOpen: boolean;
        tag: Tag | null;
        originalExcludedGroupIds: SurveyRecipientsGroup['id'][];
        isTagLocked: boolean;
    }>({
        isOpen: false,
        tag: null,
        originalExcludedGroupIds: [],
        isTagLocked: false,
    });

    extendedTagMemberPickerDialogIsOpen = false;
    readonly VISIBLE_CHIPS = 6;

    isTagOfTagMember = isTagOfTagMember;
    getExcludedGroupCountByTagId = getExcludedGroupCountByTagId;

    @ViewChild('input') inputRef?: ElementRef<HTMLInputElement>;

    private destroy$ = new Subject<void>();
    private init$ = new Subject<void>();
    private membersFilter$ = new Subject<InputSelectSearchEvent>();
    private tagFilter$ = new Subject<InputSelectSearchEvent>();

    constructor(
        private tagService: TagService,
        private memberService: MemberService,
        private cdr: ChangeDetectorRef,
        public elementRef: ElementRef,
    ) {}

    ngOnInit(): void {
        this.fetchTags();
        this.fetchMembers();

        this.value$ = concat(
            of(this.control.value),
            this.control.valueChanges,
        ).pipe(shareReplay(1));

        this.value$.pipe(takeUntil(this.destroy$)).subscribe((t) => {
            if (t == null) {
                this.isLimitReached = false;
                this.clearSelection();
            } else {
                this.selectedIds = t.map((val) => val.id) ?? [];
                this.isLimitReached = this.getIsLimitReached(this.selectedIds);
            }
            setTimeout(() => this.cdr.markForCheck());
        });

        this.textSearchControl.valueChanges
            .pipe(
                debounceTime(300),
                map((text) => (isDefined(text) ? text.trim() : '')),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe((text: string) => {
                this.tagFilter$.next({ text, nextPageToken: null });
                this.membersFilter$.next({ text, nextPageToken: null });
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.communityId) {
            this.init$.next();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    private fetchMembers(): void {
        this.membersFilter$
            .pipe(
                tap((filters) =>
                    this.members$.next(
                        fetchPaginatedList(
                            this.members$.value,
                            filters.nextPageToken ?? undefined,
                        ),
                    ),
                ),
                switchMap((filters) =>
                    this.memberService.search(
                        filters,
                        'community_members',
                        null,
                        [this.communityId],
                        MetadataFieldModeType.POST,
                        null,
                        false,
                        null,
                        false,
                    ),
                ),
                takeUntil(this.destroy$),
                takeUntil(this.init$),
            )
            .subscribe((members) =>
                this.members$.next(
                    fetchPaginatedListSuccess(this.members$.value, members),
                ),
            );
    }

    private fetchTags(): void {
        this.tagFilter$
            .pipe(
                tap((filters) => {
                    this.tags$.next(
                        fetchPaginatedList(
                            this.tags$.value,
                            filters.nextPageToken ?? undefined,
                        ),
                    );
                }),
                switchMap((filters) =>
                    this.tagService.fetchTagList(filters, this.communityId),
                ),
                takeUntil(this.destroy$),
                takeUntil(this.init$),
            )
            .subscribe((tags) =>
                this.tags$.next(
                    fetchPaginatedListSuccess(this.tags$.value, tags),
                ),
            );
    }

    trackItem(_idx: number, item: TagMember): TagMember['id'] {
        return item.id;
    }

    setActiveTab(tab: MemberTagTab): void {
        this.activeTab = tab;
    }

    openTagMembers(tag: Tag): void {
        const excludedGroups =
            this.recipientsTagsExcludedGroupLockedIds?.find(
                (r) => r.tagId === tag.id,
            )?.excludedGroups ?? [];

        this.tagGroupDialogData$.next({
            isOpen: true,
            tag,
            originalExcludedGroupIds: mapArrayById(excludedGroups) ?? [],
            isTagLocked: this.lockedIds.includes(tag.id),
        });
    }

    iconClicked(event: MouseEvent): void {
        if (this.isLimitReached) {
            event.stopPropagation();
            this.clearSelection();
            this.control.markAsDirty();
            this.updateControlValue(this.selectedIds);
        } else {
            this.openDialog(event);
        }
    }

    clearSelection(): void {
        this.textSearchControl.markAsDirty();
        this.resetControlValue();
        this.updateSelectedIds([]);
    }

    onScroll(event: ScrollTrackerEvent): void {
        if (event?.thresholdReached) {
            this.doSearch();
        }
    }

    doSearch(): void {
        const text = (this.textSearchControl.value ?? '').trim();
        const isTag = this.activeTab === 'TAG' && !text.length;
        const list = isTag ? this.tags$.value : this.members$.value;
        const nextPageToken = getNextPageToken(list.nextPageToken);

        if (nextPageToken && !list.isFetching) {
            if (isTag) {
                this.tagFilter$.next({
                    text,
                    nextPageToken,
                });
            } else {
                this.membersFilter$.next({
                    text,
                    nextPageToken,
                });
            }
        }
    }

    inputFocus(): void {
        this.isOpen = true;
    }

    updateRecipients(updatedRecipients: TagMember[]): void {
        this.control.setValue(updatedRecipients);
    }

    removeItemByChip(item: TagMember): void {
        this.changeSelection(false, item);
        this.updateControlValue(this.selectedIds);
    }

    changeSelection(value: boolean, item: TagMember): void {
        if (value) {
            if (this.maxSelectableItems === 1) {
                this.updateSelectedIds([item.id]);
            } else {
                this.updateSelectedIds(
                    this.selectedIds.includes(item.id)
                        ? this.selectedIds.filter((i) => i !== item.id)
                        : this.selectedIds.length >= this.maxSelectableItems
                          ? this.selectedIds
                          : [...this.selectedIds, item.id],
                );
            }
        } else {
            this.updateSelectedIds(
                this.selectedIds.filter((itemId) => itemId !== item.id),
            );
        }
    }

    itemListOpened(isOpen: boolean): void {
        if (isOpen) {
            this.tagFilter$.next(emptyInputSelectSearchEvent());
            this.membersFilter$.next(emptyInputSelectSearchEvent());
        }
    }

    openDialog(event: MouseEvent): void {
        if (!this.isOpen) {
            this.isOpen = true;
            event.stopPropagation();
            setTimeout(() => this.focus());
            this.itemListOpened(true);
        }
    }

    closeMenu(): void {
        this.isOpen = false;
        this.resetControlValue();
        this.updateControlValue(this.selectedIds);
        this.itemListOpened(false);
    }

    private updateSelectedIds(selectedItems: TagMember['id'][]): void {
        this.selectedIds = selectedItems;
        this.isLimitReached = this.getIsLimitReached(selectedItems);
        if (this.isLimitReached && this.maxSelectableItems === 1) {
            this.closeMenu();
        }
    }

    private resetControlValue(): void {
        this.textSearchControl.setValue('');
    }

    private focus(): void {
        this.inputRef?.nativeElement.focus();
    }

    private updateControlValue(selectedIds: Array<number | string>) {
        const selectedIdsSet = new Set(selectedIds);

        if (this.value$) {
            of(null)
                .pipe(
                    withLatestFrom(this.value$),
                    map(([_, value]) => value),
                )
                .subscribe((value) => {
                    const controlValueListWithoutUnselected = (
                        value ?? []
                    ).filter((i) => selectedIdsSet.has(i.id));

                    const items: TagMember[] = [
                        ...this.tags$.value.list,
                        ...this.members$.value.list,
                    ];
                    const selectedValues = items.filter((i) =>
                        selectedIdsSet.has(i.id),
                    );

                    const newControlValue = unique(
                        controlValueListWithoutUnselected.concat(
                            selectedValues,
                        ),
                        (val) => val.id,
                    ).sort(sortAlphabeticallyByIdTagMembers);

                    this.control.setValue(newControlValue);
                    this.control.markAsDirty();
                });
        }
    }

    private getIsLimitReached(selectedIds: TagMember['id'][]): boolean {
        return selectedIds.length >= this.maxSelectableItems;
    }
}
