import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Size } from '@interacta-shared/ui';

@Component({
    selector: 'interacta-post-code[code]',
    templateUrl: './post-code.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCodeComponent {
    @Input() code!: string;
    @Input() inline = false;
    @Input() size: Extract<Size, 'large' | 'regular' | 'small'> = 'regular';
}
