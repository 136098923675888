const Keys = {
    TAB: "Tab",
    ENTER: "Enter",
    ESCAPE: "Escape",
    UP: "ArrowUp",
    DOWN: "ArrowDown",
};

export { Keys };

