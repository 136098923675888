import { PaginatedList } from '@interacta-shared/util';
import { AdminV2CommunitiesCol } from '@modules/admin-v2-community/models/admin-v2-community.model';
import { AdminV2Date } from '@modules/admin-v2-shared/models/admin-v2-date.model';
import {
    AdminV2PaginatedList,
    AdminV2PaginatedListRow,
} from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import {
    AdminV2FiltersLabel,
    AdminV2Label,
    AdminV2OccToken,
    AdminV2Sort,
} from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import {
    AdminV2Workspace,
    AdminV2WorkspaceRoleAssociations,
} from '@modules/admin-v2-workspace/models/admin-v2-workspace.model';
import { IUser, IUsersGroup } from '@modules/core';
import { MemberUser } from '@modules/core/models/member/member.model';
import { Tag } from '@modules/core/models/tag/tag.model';

export interface AdminV2GroupFilters {
    search?: string;
    status: AdminV2GroupStatusFilter;
    excludeGroupByMemberUserId?: AdminV2Group['id']; // filtro usato nel member.service per il member-picker
    // other filters
    created?: AdminV2Date;
    externalId?: string;
    tags?: Tag[];
    workspaces?: AdminV2Workspace[];
    visibility?: boolean;
    checkUserMember?: AdminV2Group['id']; // filtro usato nel member service V2 per il componente persone
}

export interface AdminV2Group extends IUsersGroup {
    email: string | null;
    visible: boolean;
    members?: IUser[];
}

export type AdminV2GroupCol = Pick<AdminV2Group, 'id' | 'fullName' | 'deleted'>;

export interface AdminV2GroupRow
    extends AdminV2PaginatedListRow,
        Pick<AdminV2Group, 'description' | 'tags' | 'visible'> {
    group: AdminV2GroupCol;
    creationTimestamp: number | null;
    communities: AdminV2CommunitiesCol;
    workspaces: AdminV2Workspace[];
    usersCount: number;
    externalId: string | null;
}

export type AdminV2GroupSort = AdminV2Sort<AdminV2GroupRow>;

export interface AdminV2GroupDetail extends Omit<AdminV2Group, 'members'> {
    users: PaginatedList<IUser>;
    workspaceRoleAssociationsList: AdminV2WorkspaceRoleAssociations[];
    creationTimestamp: number;
    lastModifyTimestamp: number;
    externalId: string | null;
}

export type AdminV2NewGroup = Pick<
    AdminV2GroupDetail,
    'email' | 'description' | 'externalId' | 'visible' | 'tags'
> & {
    name: string;
    workspaces: AdminV2Workspace[];
    members: MemberUser[];
};

export enum AdminV2GroupStatusFilter {
    ACTIVE_GROUPS = 'activeGroups',
    DELETED_GROUPS = 'deletedGroups',
    ALL_GROUPS = 'allGroups',
}

export type AdminV2GroupListPage = PaginatedList<AdminV2GroupRow>;

export type AdminV2GroupList = AdminV2PaginatedList<AdminV2GroupRow>;

export interface AdminV2UserListGroupUpdateByFilter {
    groupIds: AdminV2Group['id'][];
}

export interface AdminV2GroupsUsersUpdate {
    groups: {
        id: AdminV2Group['id'];
        occToken?: AdminV2OccToken;
        addUserIds: IUser['id'][];
        deleteUserIds: IUser['id'][];
    }[];
}

export type AdminV2GroupFiltersLabel = AdminV2FiltersLabel<
    AdminV2GroupFilters,
    AdminV2GroupStatusFilter
>;

export type AdminV2GroupStatusFilterLabel =
    AdminV2Label<AdminV2GroupStatusFilter>;

export type AdminV2GroupManageMode = 'delete' | 'restore';
