import { DateRange } from '@modules/core/models/utility.model';

export interface AdminV2Date {
    from: Date | null;
    to: Date | null;
    type: AdminV2FilterDateOptions | null;
}

export enum AdminV2FilterDateOptions {
    TODAY,
    YESTERDAY,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM,
}

export interface AdminV2FiltersByDateType extends Partial<DateRange> {
    type: AdminV2FilterDateOptions;
}

export const isAdminV2Date = (obj: any): obj is AdminV2Date => {
    const date = obj as AdminV2Date;
    return (
        date.to !== undefined &&
        date.from !== undefined &&
        date.type !== undefined
    );
};
