<ng-container *ngIf="{ communities: filteredCommunities$ | async } as $">
    <interacta-radio-group
        [ngModel]="selectedCommunity"
        (changeSelection)="
            selectCommunity.emit($event); selectedCommunity = $event
        "
    >
        <interacta-radio-button
            *ngFor="let community of $.communities"
            [value]="community"
        >
            <span class="typo-sm">{{ community.name }}</span>
        </interacta-radio-button>
    </interacta-radio-group>
</ng-container>
