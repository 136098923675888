import { FirebaseApp, FirebaseOptions } from 'firebase/app';
import { Database } from 'firebase/database';

export enum PushChannelsStatus {
    DISCONNECTED,
    TRYING_TO_CONNECT,
    CONNECTED,
}

export interface UserChannelStatus {
    notifications:
        | {
              lastUpdateTimestamp: number;
          }
        | undefined;
    posts:
        | {
              lastUpdateTimestamp: number;
          }
        | undefined;
}

export interface CommunityChannelStatus {
    posts: {
        lastUpdateTimestamp: number;
    };
}

export interface CommunitiesChannelStatus {
    communityId: number;
    communityStatus: CommunityChannelStatus;
}

export interface PostChannelStatus {
    ['live-comments']: {
        lastUpdateTimestamp: number;
    };
}

export interface PostsChannelStatus {
    postId: number;
    postStatus: PostChannelStatus;
}

export class CancelledConnection extends Error {}

export interface IFirebasePushChannelsClientTransport {
    id: string;
    firebaseApp: FirebaseApp;
    firebaseDB: Database;
}

export interface IFirebasePushChannelConnection {
    channelId: string;
    clientTransport: IFirebasePushChannelsClientTransport;
    firebaseChannelPath: string;
}
export interface IFirebasePushChannels {
    userChannel: IFirebasePushChannelConnection;
    communitiesChannels: { [key: number]: IFirebasePushChannelConnection };
    postsChannels: { [key: number]: IFirebasePushChannelConnection };
}

export class FirebasePushChannelDeserialize {
    static firebaseOptions = (record: any): FirebaseOptions => ({
        apiKey: record.firebaseApiKey,
        authDomain: record.firebaseAuthDomain,
        databaseURL: record.firebaseDatabaseURL,
        messagingSenderId: record.firebaseMessagingSenderId,
        projectId: record.firebaseProjectId,
    });

    static createUserChannels({
        response,
        defaultClientTransport,
    }: {
        response: any;
        defaultClientTransport: IFirebasePushChannelsClientTransport;
    }): IFirebasePushChannelConnection {
        return {
            channelId: response['channelConnection']['channelId'],
            clientTransport: defaultClientTransport,
            firebaseChannelPath:
                response['channelConnection']['connectionParams'][
                    'firebaseChannelPath'
                ],
        };
    }

    static createPostChannels(
        response: any,
        defaultClientTransport: IFirebasePushChannelsClientTransport,
    ): IFirebasePushChannelConnection {
        return {
            channelId: response['channelConnection']['channelId'],
            clientTransport: defaultClientTransport,
            firebaseChannelPath:
                response['channelConnection']['connectionParams'][
                    'firebaseChannelPath'
                ],
        };
    }

    /*     static createCommunityChannels(
        response: any,
        communityId: string,
        defaultClientTransport: IFirebasePushChannelsClientTransport,
    ): IFirebasePushChannelConnection {
        return {
            channelId: response['channelConnections'][communityId]['channelId'],
            clientTransport: defaultClientTransport,
            firebaseChannelPath:
                response['channelConnections'][communityId]['connectionParams'][
                    'firebaseChannelPath'
                ],
        };
    } */

    /*     static createCommunitiesChannels(
        response: any,
        defaultClientTransport: IFirebasePushChannelsClientTransport,
    ): { [key: number]: IFirebasePushChannelConnection } {
        const communitiesChannelsData: {
            [key: number]: IFirebasePushChannelConnection;
        } = {};

        for (const communityId in response['channelConnections']) {
            communitiesChannelsData[
                +communityId
            ] = this.createCommunityChannels(
                response,
                communityId,
                defaultClientTransport,
            );
        }
        return communitiesChannelsData;
    }*/
}
