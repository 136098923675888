import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { FileUploadModule, FileUploader } from 'ng2-file-upload';

@Component({
    standalone: true,
    imports: [FileUploadModule],
    selector: 'interacta-file-upload-input',
    templateUrl: './file-upload-input.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadInputComponent {
    @Input({ required: true }) uploader!: FileUploader;
    /** WARNING the "inputId" must be unique, 
        otherwise the ng2FileSelect doesn't work properly **/
    @Input({ required: true }) inputId!: string;
    @Input() acceptedMimeType?: string[];

    @Output() fileSelected = new EventEmitter<File[]>();
}
