import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Size } from '@interacta-shared/ui';

@Component({
    selector: 'interacta-member-list-placeholder',
    templateUrl: './member-list-placeholder.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberListPlaceholderComponent {
    @Input() instances = 4;
    @Input() size: Extract<Size, 'regular' | 'small' | 'large'> = 'regular';
}
