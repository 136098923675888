import { Index, PaginatedList } from '@interacta-shared/util';
import { createAction, props } from '@ngrx/store';
import {
    AttachmentSection,
    AttachmentSectionSource,
} from '../../models/attachment-section/attachment-section.model';

export const setCurrentAttachment = createAction(
    '[Sections] Set Current Attachment',
    props<{ currentAttachment: AttachmentSectionSource }>(),
);

export const setActive = createAction(
    '[Sections] Set Active',
    props<{
        active: boolean;
    }>(),
);

export const requestFetchSectionsList = createAction(
    '[Sections] Request Fetch Sections List',
    props<{
        attachment: AttachmentSectionSource;
        containsText: string;
        pageToken: string | null;
    }>(),
);

export const fetchSectionsList = createAction(
    '[Sections] Fetch Sections List',
    props<{
        attachment: AttachmentSectionSource;
        containsText: string;
        pageToken: string | null;
    }>(),
);

export const fetchSectionsListSuccess = createAction(
    '[Sections] Fetch Sections List Success',
    props<{
        items: PaginatedList<AttachmentSection>;
        containsText: string;
        attachmentId: Index;
    }>(),
);

export const fetchSectionsListError = createAction(
    '[Sections] Fetch Sections List Error',
    props<{
        error: any;
        attachmentId: Index;
    }>(),
);

export const selectSection = createAction(
    '[Sections] Select Section',
    props<{ id: AttachmentSection['id'] }>(),
);

export const flush = createAction('[Sections] Flush');
