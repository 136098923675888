<div class="flex items-center space-x-8">
    <button
        interacta-button-light-box
        type="button"
        [attr.aria-label]="'LIGHTBOX.PREVIOUS' | translate"
        [disabled]="currentIdx <= 1"
        [icon]="'chevron-big-left'"
        [label]="'LIGHTBOX.PREVIOUS' | translate"
        [labelPosition]="'above'"
        [size]="'extra-small'"
        (click)="previous.emit()"
    ></button>
    <span class="line-clamp-1 w-72 text-center">{{
        'LIGHTBOX.COUNT'
            | translate
                : {
                      current: currentIdx,
                      total: attachmentsCount
                  }
    }}</span>

    <button
        interacta-button-light-box
        type="button"
        [attr.aria-label]="'LIGHTBOX.NEXT' | translate"
        [disabled]="currentIdx >= attachmentsCount"
        [icon]="'chevron-big-right'"
        [label]="'LIGHTBOX.NEXT' | translate"
        [labelPosition]="'above'"
        [size]="'extra-small'"
        (click)="next.emit()"
    ></button>
</div>
