import { Component, EventEmitter, inject, OnDestroy } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { Language } from '@interacta-shared/data-access-configuration';
import { CloudTranslationService } from '@modules/communities/services/cloud-translation.service';
import { InputSelectLanguage } from '@modules/shared-v2/models/input-select-language.model';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

type LanguageSelectorDialogMode = 'generic' | 'post' | 'comment';

@Component({
    selector: 'interacta-language-selector-dialog',
    templateUrl: './language-selector-dialog.component.html',
})
export class LanguageSelectorDialogComponent implements OnDestroy {
    control = new UntypedFormControl(null, Validators.required);
    searchControl = new FormControl<string>('', { nonNullable: true });
    isOpen$ = new Subject<boolean>();
    preselected?: InputSelectLanguage | undefined;
    languageList: InputSelectLanguage[] = [];
    filteredLanguageList: InputSelectLanguage[] = [];
    mode: LanguageSelectorDialogMode = 'generic';

    private _closing = new EventEmitter<Language | null>();
    private destroy$ = new Subject<void>();
    private cloudTranslationService = inject(CloudTranslationService);

    initList(preselected: Language | null = null): void {
        this.searchControl.setValue('');

        this.cloudTranslationService
            .getLanguagesList()
            .pipe(take(1), takeUntil(this.destroy$))
            .subscribe((languages: Language[]) => {
                this.languageList = languages.map((lang, i) =>
                    this.languageToItem(lang, i),
                );
                if (preselected) {
                    this.preselected = this.languageList.find(
                        (lang) => lang.code === preselected.code,
                    );
                    this.control.setValue(this.preselected?.id);
                }
                this.filteredLanguageList = this.languageList;
                this.isOpen$.next(true);
            });

        this.searchControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((search) => {
                this.filteredLanguageList = this.languageList?.filter(
                    (lang) =>
                        !search ||
                        lang.description
                            ?.toLowerCase()
                            .includes(search.toLowerCase()),
                );
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    open(
        preselected: Language | null,
        mode: LanguageSelectorDialogMode = 'generic',
    ): Observable<Language | null> {
        this.mode = mode;
        this.initList(preselected);
        return this._closing.pipe(take(1));
    }

    close(): void {
        this.isOpen$.next(false);
        this._closing.emit(null);
    }

    submit(): void {
        this.isOpen$.next(false);
        this._closing.emit(
            this.itemToLanguage(
                this.languageList.find(
                    (lang) => lang.id === this.control.value,
                ),
            ),
        );
    }

    private languageToItem(lang: Language, id: number): InputSelectLanguage {
        return {
            ...lang,
            id,
            label: lang.description || '',
        };
    }

    private itemToLanguage(
        lang: InputSelectLanguage | null | undefined,
    ): Language | null {
        if (!lang) return null;
        return {
            code: lang.code,
            description: lang.description || '',
        };
    }
}
