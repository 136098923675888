import { Language } from '@interacta-shared/data-access-configuration';
import { PaginatedList } from '@interacta-shared/util';
import { AdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { AdminV2Label } from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import { IAdminHashtag } from '@modules/communities/models/hashtag/hashtag.model';
import { i18nStringV2 } from '@modules/core';
import { AdminV2Community } from './admin-v2-community.model';

export interface AdminV2CommunityHashtag
    extends Omit<IAdminHashtag, 'externalId' | 'name'> {
    instancesCount: number;
    externalId: string | null;
    name: i18nStringV2;
}

export interface AdminV2CommunityHashtagCounter {
    activeCount: number;
    deletedCount: number;
}

export interface AdminV2CommunityHashtagFilters
    extends AdminV2PaginatedFilters {
    communityId: AdminV2Community['id'] | null;
    search?: string;
    status: AdminV2CommunityHashtagStatusFilter;
    sort: AdminV2CommunityHashtagSort;
    notTranslatedLanguage?: Language;
}

export enum AdminV2CommunityHashtagStatusFilter {
    ACTIVE_HASHTAG,
    DELETED_HASHTAG,
    ALL_HASHTAG,
}

export type AdminV2CommunityHashtagStatusFilterLabel =
    AdminV2Label<AdminV2CommunityHashtagStatusFilter>;

export interface AdminV2CommunityHashtagSort {
    sortColumn: keyof Partial<AdminV2CommunityHashtag>;
    orderDesc: boolean;
}

export interface AdminV2CommunityHashtagCol {
    communityId: AdminV2Community['id'];
    communityName: AdminV2Community['name'];
    counter: number | null;
    canAdd: boolean;
}

export interface AdminV2CommunityHashtagDetail {
    paginatedList: PaginatedList<AdminV2CommunityHashtag>;
    filters: AdminV2CommunityHashtagFilters;
    counter: AdminV2CommunityHashtagCounter | null;
    newHastagIds: AdminV2CommunityHashtag['id'][];
}

export type AdminV2NewCommunityHashtag = Pick<
    AdminV2CommunityHashtag,
    'communityId' | 'externalId'
> & { name: string };
