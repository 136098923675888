import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { SimpleDateFormat, formatDate } from '@interacta-shared/util-common';

/**
 * Format a date using the given SimpleDateFormat
 *
 * See https://injenia.atlassian.net/l/cp/r6L3KnZu
 */
@Pipe({
    standalone: true,
    name: 'interactaDate',
})
export class InteractaDatePipe implements PipeTransform {
    constructor(private configurationService: ConfigurationService) {}

    transform(
        date: Date | number | null | undefined,
        format: SimpleDateFormat,
    ): string {
        return formatDate(
            date,
            format,
            this.configurationService.getCurrentLanguage().code,
        );
    }
}
