<article
    *ngIf="{
        medias: (medias$ | async) ?? [],
        documents: (documents$ | async) ?? []
    } as $"
    class="typo-xs flex items-center gap-x-8"
>
    @if ($.medias.length) {
        <section class="flex items-center gap-x-4">
            <interacta-icon class="size-16" [icon]="'image'"></interacta-icon>
            <p>
                {{ $.medias.length }}
                {{ 'DASHBOARD.ATTACHMENTS.MEDIA' | translate | lowercase }}
            </p>
        </section>
    }
    @if ($.documents.length) {
        <section class="flex items-center gap-x-4">
            <interacta-icon
                class="size-16"
                [icon]="'document'"
            ></interacta-icon>
            <p>
                {{ $.documents.length }}
                {{
                    ($.documents.length === 1
                        ? 'DASHBOARD.ATTACHMENTS.DOCUMENT'
                        : 'DASHBOARD.ATTACHMENTS.DOCUMENTS'
                    )
                        | translate
                        | lowercase
                }}
            </p>
        </section>
    }
</article>
