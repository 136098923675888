import { Injectable, Optional } from '@angular/core';
import { InputSelectSearchEvent } from '@interacta-shared/ui';
import {
    PaginatedList,
    fromPageToken,
    paginatedListFromIList,
} from '@interacta-shared/util';
import { AdminV2CommunityService } from '@modules/admin-v2-community/services/admin-v2-community.service';
import {
    AdminV2GroupFilters,
    AdminV2GroupStatusFilter,
} from '@modules/admin-v2-group/models/admin-v2-group.model';
import { AdminV2GroupService } from '@modules/admin-v2-group/services/admin-v2-group.service';
import { AdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { AdminV2MemberService } from '@modules/admin-v2-shared/services/admin-v2-member.service';
import {
    AdminV2UserFilters,
    AdminV2UserStatusFilter,
} from '@modules/admin-v2-user/models/admin-v2-user.model';
import { AdminV2UserService } from '@modules/admin-v2-user/services/admin-v2-user.service';
import { AdminV2WorkspaceService } from '@modules/admin-v2-workspace/services/admin-v2-workspace.service';
import { MetadataFieldModeType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { IScreenContext } from '@modules/communities/models/screen.model';
import { AdminCapabilities, IUser, IUsersGroup } from '@modules/core';
import {
    ApiV2AdminGroupsFiltersRequest,
    ApiV2AdminUsersFiltersRequest,
} from '@modules/core/models/admin-api-v2-filters/admin-api-v2-filters.model';
import {
    Member,
    MemberWithCustomFlags,
} from '@modules/core/models/member/member.model';
import { wrapMemberPaginatedList } from '@modules/core/models/member/member.utils';
import {
    IGenericCommunityFilter,
    IGroupPickerSearchRequest,
    IMemberSearchRequest,
    IUserPickerSearchRequest,
    IUserSearchRequest,
    SearchGroupOrderType,
    SearchMemberOrderType,
    SearchUserOrderType,
} from '@modules/core/models/user-autocomplete.model';
import { UsersService } from '@modules/core/services/users.service';
import { PostTransitionFormService } from '@modules/post/components/post-transition-form/post-transition-form.component';
import { ICustomPostForContext } from '@modules/post/models/custom-post.model';
import { CustomPostFormCollectionsHolderService } from '@modules/post/services/custom-post-form.service';
import { PostService } from '@modules/post/services/post.service';
import { ITaskAssigneesRequest } from '@modules/tasks/models/task.model';
import { TaskService } from '@modules/tasks/services/task.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MemberPickerType } from '../components/input-member-picker/input-member-picker.component';

@Injectable()
export class MemberService {
    constructor(
        private userService: UsersService,
        @Optional()
        private collectionsHolderService: CustomPostFormCollectionsHolderService,
        @Optional()
        private transitionFormService: PostTransitionFormService,
        private postService: PostService,
        private taskService: TaskService,
        // ADMIN V2 SERVICES
        private adminV2UserService: AdminV2UserService,
        private adminV2GroupService: AdminV2GroupService,
        private adminV2WorkspaceService: AdminV2WorkspaceService,
        private adminV2CommunityService: AdminV2CommunityService,
        private adminV2MemberService: AdminV2MemberService,
    ) {}

    search(
        search: InputSelectSearchEvent,
        type: MemberPickerType,
        postId: number | null,
        communityIds: number[] | null,
        fieldMode: MetadataFieldModeType,
        fieldId: number | null,
        dynamic: boolean,
        workspaceId: number | null,
        includeNotVisible?: boolean,
        excludeItemsByMemberId?: number,
        excludeItemsByOrganizationId?: number,
        manageType?: Extract<
            AdminCapabilities,
            'manageUsers' | 'manageDigitalWorkplace'
        >[],
    ): Observable<PaginatedList<Member>> {
        return this.getApi(
            search,
            type,
            postId,
            communityIds,
            fieldId,
            fieldMode,
            dynamic,
            workspaceId,
            includeNotVisible,
            excludeItemsByMemberId,
            excludeItemsByOrganizationId,
            manageType,
        );
    }

    private getApi(
        search: InputSelectSearchEvent,
        type: MemberPickerType,
        postId: number | null,
        communityIds: number[] | null,
        fieldId: number | null,
        fieldMode: MetadataFieldModeType,
        dynamic: boolean,
        workspaceId: number | null,
        includeNotVisible?: boolean,
        excludeItemsByMemberId?: number,
        excludeItemsByOrganizationId?: number,
        manageType?: Extract<
            AdminCapabilities,
            'manageUsers' | 'manageDigitalWorkplace'
        >[],
    ): Observable<PaginatedList<Member>> {
        this.singleCommunityIdValidator(type, communityIds);
        const communityId = communityIds?.[0] ? communityIds[0] : null;

        switch (type) {
            case 'custom_user':
                if (fieldId == null) {
                    throw Error(
                        `fieldId is required for MemberPickerType ${type}`,
                    );
                }
                return this.getCustomFieldUserPickerApi(
                    search,
                    communityId,
                    postId,
                    fieldId,
                    fieldMode,
                    dynamic,
                );
            case 'custom_group':
                if (fieldId == null) {
                    throw Error(
                        `fieldId is required for MemberPickerType ${type}`,
                    );
                }
                return this.getCustomFieldGroupPickerApi(
                    search,
                    communityId,
                    postId,
                    fieldId,
                    fieldMode,
                    dynamic,
                );
            case 'community_members':
                return this.getMembers(search, communityId, includeNotVisible);
            case 'task_members':
                return this.getTaskMembersApi(search, postId);
            case 'task_assignees':
                return this.getTaskAssignees(search, communityIds);
            case 'community_users':
                return this.getCommunicationUsers(search, communityId);
            case 'community_groups':
                return this.getCommunicationGroups(search, communityId);
            case 'admin_workspace_members':
                return this.getAdminWorkspaceMembersApi(
                    search,
                    workspaceId,
                    communityId,
                );
            case 'admin_users':
                return this.getAdminUsersApiV2(search, workspaceId, manageType);
            case 'admin_groups':
                return this.getAdminGroupsApiV2(
                    search,
                    workspaceId,
                    manageType,
                );
            case 'admin_v2_groups':
                return this.getAdminV2Groups(search, excludeItemsByMemberId);
            case 'admin_v2_manager':
            case 'admin_v2_users':
                return this.getAdminV2Users({
                    search,
                    excludeUsersByMemberGroupId: excludeItemsByMemberId,
                });
            case 'admin_v2_areas':
                return this.getAdminV2Users({
                    search,
                    excludeByAreaId: excludeItemsByOrganizationId,
                });

            case 'admin_v2_business_units':
                return this.getAdminV2Users({
                    search,
                    excludeByBusinessUnitId: excludeItemsByOrganizationId,
                });

            case 'admin_v2_workspace_admin_members':
                return this.adminV2WorkspaceService.getAllAdminMembers(search);
            case 'admin_v2_community_admin_members':
                return this.adminV2CommunityService.getAdminMembers(search);
            case 'admin_v2_members':
                return this.adminV2MemberService.searchMembers(
                    'admin_v2_workspace_direct_members',
                    { workspaceId: workspaceId ?? undefined },
                    fromPageToken(search.nextPageToken),
                    search.text,
                    false,
                    workspaceId !== undefined,
                );
        }
    }

    private getAdminV2Users({
        search,
        excludeUsersByMemberGroupId,
        excludeByAreaId,
        excludeByBusinessUnitId,
    }: {
        search: InputSelectSearchEvent;
        excludeUsersByMemberGroupId?: number;
        excludeByAreaId?: number;
        excludeByBusinessUnitId?: number;
    }): Observable<PaginatedList<Member>> {
        const filter: AdminV2UserFilters = {
            search: search.text,
            status: AdminV2UserStatusFilter.ACTIVE_USERS,
            excludeUsersByMemberGroupId,
            excludeByAreaId,
            excludeByBusinessUnitId,
        };
        //abbiamo fatto la conversione per tenere il tipo InputSelectSearchEvent e non aggiungere i PageTokenInfo
        const paginatedFilters: AdminV2PaginatedFilters = {
            pageSize: 15,
            pageToken: fromPageToken(search.nextPageToken),
        };
        // viene tornato un PaginatedList<PartialSharedProfile>, di conseguenza quando wrappo in una lista di member mi porto dietro tutte le info di PartialSharedProfile, tra cui aree e businessUnit
        // il tipo corretto sarebbe un interface MemberUser extends MemberBase { user: PartialSharedProfile } che di fatto è un estensione di IUser
        return this.adminV2UserService
            .searchUsers(filter, paginatedFilters)
            .pipe(map(wrapMemberPaginatedList));
    }

    private getAdminV2Groups(
        search: InputSelectSearchEvent,
        excludeGroupByMemberUserId?: number,
    ): Observable<PaginatedList<Member>> {
        const filters: AdminV2GroupFilters = {
            search: search.text,
            status: AdminV2GroupStatusFilter.ACTIVE_GROUPS,
            excludeGroupByMemberUserId: excludeGroupByMemberUserId,
        };
        const paginatedFilters: AdminV2PaginatedFilters = {
            pageSize: 15,
            pageToken: fromPageToken(search.nextPageToken),
        };

        return this.adminV2GroupService
            .getIUsersGroups(filters, paginatedFilters)
            .pipe(map(wrapMemberPaginatedList));
    }

    private getAdminWorkspaceMembersApi(
        search: InputSelectSearchEvent,
        workspaceId: number | null,
        communityId: number | null,
    ): Observable<PaginatedList<MemberWithCustomFlags>> {
        if (workspaceId == null) {
            throw new Error(
                'Missing required workspaceId in getAdminWorkspaceMembersApi',
            );
        }

        return this.adminV2WorkspaceService.getWorkspaceMembers(
            workspaceId,
            {
                search: search.text,
                checkCommunityMember: communityId ?? null,
            },
            {
                pageSize: 15,
                pageToken: fromPageToken(search.nextPageToken),
            },
        );
    }

    private getTaskMembersApi(
        search: InputSelectSearchEvent,
        postId: number | null,
    ): Observable<PaginatedList<Member>> {
        const filter: IGenericCommunityFilter = {
            name: search.text ?? null,
            pageToken: search.nextPageToken ?? undefined,
            calculateTotalItemsCount: true,
        };

        if (postId == null) {
            throw new Error('Missing required postId in getTaskMembersApi');
        }

        return this.postService.viewerMembersList(postId, filter);
    }

    private getCustomFieldUserPickerApi(
        search: InputSelectSearchEvent,
        communityId: number | null,
        postId: number | null,
        fieldId: number,
        fieldMode: MetadataFieldModeType,
        dynamic: boolean,
    ): Observable<PaginatedList<Member>> {
        const filter: IUserPickerSearchRequest = {
            communityIds: communityId ? [communityId] : [],
            calculateTotalItemsCount: true,
            name: search.text ?? null,
            pageToken: search.nextPageToken ?? undefined,
            pageSize: 15,
            orderBy: SearchUserOrderType.NAME,
            orderDesc: false,
            context: this.getContext(dynamic, fieldMode),
            screenContext: this.getScreenContext(dynamic, fieldMode),
        };

        if (communityId == null && fieldMode !== MetadataFieldModeType.SURVEY) {
            throw new Error(
                'Missing required communityId in getCustomFieldGroupPickerApi',
            );
        }
        if (postId == null && fieldMode === MetadataFieldModeType.SURVEY) {
            throw new Error(
                'Missing required postId in getCustomFieldGroupPickerApi',
            );
        }

        const itemId =
            fieldMode === MetadataFieldModeType.SURVEY ? postId! : communityId!;

        return this.userService
            .getUserPickerList(fieldId, itemId, fieldMode, filter)
            .pipe(
                map((res: PaginatedList<IUser>) =>
                    wrapMemberPaginatedList(res),
                ),
            );
    }

    private getCustomFieldGroupPickerApi(
        search: InputSelectSearchEvent,
        communityId: number | null,
        postId: number | null,
        fieldId: number,
        fieldMode: MetadataFieldModeType,
        dynamic: boolean,
    ): Observable<PaginatedList<Member>> {
        const filter: IGroupPickerSearchRequest = {
            calculateTotalItemsCount: true,
            name: search.text ?? null,
            pageToken: search.nextPageToken ?? undefined,
            pageSize: 15,
            orderBy: SearchGroupOrderType.NAME,
            orderDesc: false,
            context: this.getContext(dynamic, fieldMode),
            screenContext: this.getScreenContext(dynamic, fieldMode),
        };

        if (communityId == null && fieldMode !== MetadataFieldModeType.SURVEY) {
            throw new Error(
                'Missing required communityId in getCustomFieldGroupPickerApi',
            );
        }

        if (postId == null && fieldMode === MetadataFieldModeType.SURVEY) {
            throw new Error(
                'Missing required postId in getCustomFieldGroupPickerApi',
            );
        }

        const itemId =
            fieldMode === MetadataFieldModeType.SURVEY ? postId! : communityId!;

        return this.userService
            .getGroupPickerList(fieldId, itemId, fieldMode, filter)
            .pipe(
                map((res: PaginatedList<IUsersGroup>) =>
                    wrapMemberPaginatedList(res),
                ),
            );
    }
    private getMembers(
        search: InputSelectSearchEvent,
        communityId: number | null,
        includeNotVisible?: boolean,
    ): Observable<PaginatedList<Member>> {
        const filter: IMemberSearchRequest = {
            orderBy: SearchMemberOrderType.NAME,
            calculateTotalItemsCount: true,
            name: search.text,
            pageToken: search.nextPageToken ?? undefined,
            communityId: communityId ?? undefined,
            includeNotVisible,
        };

        return this.userService
            .getMembers(filter)
            .pipe(map(paginatedListFromIList));
    }

    private getCommunicationUsers(
        search: InputSelectSearchEvent,
        communityId: number | null,
    ): Observable<PaginatedList<Member>> {
        const filter: IUserSearchRequest = {
            orderBy: SearchUserOrderType.POPULARITY,
            includePostAuthors: true,
            pageSize: 15,
            name: search.text,
            pageToken: search.nextPageToken ?? undefined,
            calculateTotalItemsCount: search.nextPageToken !== null,
            communityIds: communityId ? [communityId] : [],
        };

        return this.userService
            .searchUsers(filter)
            .pipe(map(wrapMemberPaginatedList));
    }

    private getCommunicationGroups(
        search: InputSelectSearchEvent,
        communityId: number | null,
    ): Observable<PaginatedList<Member>> {
        const filter: IMemberSearchRequest = {
            orderBy: SearchMemberOrderType.NAME,
            includeNotVisible: false,
            pageSize: 15,
            name: search.text,
            pageToken: search.nextPageToken ?? undefined,
            calculateTotalItemsCount: search.nextPageToken !== null,
            communityId: communityId ?? undefined,
        };

        return this.userService
            .searchGroups(filter)
            .pipe(map(wrapMemberPaginatedList));
    }

    private getAdminUsersApiV2(
        search: InputSelectSearchEvent,
        workspaceId: number | null,
        manageType:
            | Extract<
                  AdminCapabilities,
                  'manageUsers' | 'manageDigitalWorkplace'
              >[]
            | undefined,
    ): Observable<PaginatedList<Member>> {
        const filter: Partial<ApiV2AdminUsersFiltersRequest> = {
            pageToken: search.nextPageToken ?? undefined,
            calculateTotalItemsCount: !search.nextPageToken,
            fullTextFilter: search.text ?? null,
            workspaceIds: workspaceId ? [workspaceId] : undefined,
            adminCapabilities: manageType,
        };

        return this.adminV2UserService
            .searchUsersV2(filter)
            .pipe(map(wrapMemberPaginatedList));
    }

    private getAdminGroupsApiV2(
        search: InputSelectSearchEvent,
        workspaceId: number | null,
        manageType:
            | Extract<
                  AdminCapabilities,
                  'manageUsers' | 'manageDigitalWorkplace'
              >[]
            | undefined,
    ): Observable<PaginatedList<Member>> {
        const filter: Partial<ApiV2AdminGroupsFiltersRequest> = {
            pageToken: search.nextPageToken ?? undefined,
            calculateTotalItemsCount: !search.nextPageToken,
            fullTextFilter: search.text ?? null,
            workspaceIds: workspaceId ? [workspaceId] : undefined,
            adminCapabilities: manageType,
        };

        return this.adminV2GroupService
            .searchGroupsApiV2(filter)
            .pipe(map(wrapMemberPaginatedList));
    }

    private getContext(
        dynamic: boolean,
        fieldMode: MetadataFieldModeType,
    ): ICustomPostForContext | undefined {
        return dynamic && fieldMode === MetadataFieldModeType.POST
            ? this.collectionsHolderService?.getContextData()
            : undefined;
    }

    private getScreenContext(
        dynamic: boolean,
        fieldMode: MetadataFieldModeType,
    ): IScreenContext | undefined {
        return dynamic && fieldMode === MetadataFieldModeType.SCREEN
            ? this.transitionFormService?.getContextData()
            : undefined;
    }

    private getTaskAssignees(
        search: InputSelectSearchEvent,
        communityIds: number[] | null,
    ): Observable<PaginatedList<Member>> {
        const searchReq: ITaskAssigneesRequest = {
            name: search.text,
            pageToken: search.nextPageToken ?? undefined,
            calculateTotalItemsCount: true,
            communityIds: communityIds ?? [],
            workspaceIds: [],
            tagIds: [],
            includeNotVisible: false,
            orderBy: SearchMemberOrderType.NAME,
            pageSize: 15,
        };
        return this.taskService.getTaskAssignees(searchReq);
    }

    private singleCommunityIdValidator(
        type: MemberPickerType,
        communityIds: number[] | null,
    ): void {
        // Add all types where multiple communities are allowed to pass the validation
        const multipleCommunitiesAllowed = ['task_assignees'];

        if (
            !multipleCommunitiesAllowed.includes(type) &&
            communityIds !== null &&
            communityIds?.length !== 1
        ) {
            throw new Error(
                `Expected only one communityId or null for MemberPickerType ${type}`,
            );
        }
    }
}
