<span
    *ngIf="members.length <= 2; else button"
    [ngClass]="{ 'text-text-mid-contrast': textColor === 'gray' }"
>
    {{
        (members[0] | tagMemberFullname) +
            (members.length > 1
                ? ('ACKNOWLEDGE.AND' | translate) +
                  (members[1] | tagMemberFullname)
                : '')
    }}
</span>
<ng-template #button>
    <button
        *ngIf="{
            peopleCount: (peopleCount$ | async) ?? 0,
            groupCount: (groupCount$ | async) ?? 0,
            groupTagCount: (groupTagCount$ | async) ?? 0,
            translateService
        } as countersAndService"
        type="button"
        [disabled]="disabled"
        [ngClass]="{
            'text-text-primary hover:underline': !disabled
        }"
        (click)="buttonClicked.emit()"
    >
        {{ countersAndService | apply: buttonLabel }}
    </button>
</ng-template>
