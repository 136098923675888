import { ZonedDateTime } from '@interacta-shared/data-access-configuration';
import { PaginatedList, assertUnreachable } from '@interacta-shared/util';
import { CustomFields } from '@modules/communities/models/custom-metadata/CustomFields.class';
import { ICustomFieldDefinition } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { IUser, IUsersGroup, QuillText } from '@modules/core';
import { Member } from '@modules/core/models/member/member.model';
import {
    IGenericCommunityFilter,
    SearchMemberOrderType,
} from '@modules/core/models/user-autocomplete.model';
import { IListAttachments } from '@modules/post/models/attachment/attachment.model';
import { RecipientGroupCardinality } from '@modules/post/models/base-post.model';
import {
    ICommonCollectionsHolder,
    ICommonCollectionsHolderCreate,
    ICommonCollectionsHolderEdit,
} from '@modules/post/models/common-collections-holder.model';
import { ICustomPost } from '@modules/post/models/custom-post.model';
import { SurveyPost } from '@modules/post/models/survey-post/survey-post.model';
import { ITaskFilters } from './filter-task.model';

export enum RangeType {
    MINUTES = 1,
    HOUR = 2,
    DAY = 3,
}

export enum ReminderType {
    EMAIL = 1,
    NOTIFICATION = 2,
}

export enum TaskState {
    OPEN = 1,
    CLOSED = 2,
}

export enum FeedbackTaskState {
    OPEN = 1,
    CLOSED_OK = 2,
    IN_PROGRESS = 3,
    IN_REVIEW = 4,
    REWORK = 5,
    CLOSED_KO = 6,
}

export enum PriorityType {
    NONE = 0,
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
}

export enum ITaskType {
    STANDARD = 1,
    ACKNOWLEDGE = 2,
    SURVEY = 3,
    FEEDBACK = 4,
}

export type TaskFilterState = 'open' | 'closed' | 'expired';

export interface TaskFilters {
    states: Set<TaskFilterState>;
    assignedToMe: boolean;
}

export interface BaseTask extends ICommonCollectionsHolder {
    id: number | null;
    type: ITaskType;
    postId?: number;
    title: string;
    descriptionDelta: QuillText;
    subTasks: ISubTask[];
    expiration: ZonedDateTime | null;
    priority: PriorityType;
    assigneeUser: IUser | null; //to be deprecated
    assigneeGroup: IUsersGroup | null; //to be deprecated
    assigneeMember?: Member;
    reminders?: IReminder[];
    occToken?: string;
    hashtags?: IHashTag[];
    mentions?: IUser[];
    commentsCount?: number;
    creatorUser?: IUser;
    creationTimestamp?: Date;
    closingTimestamp?: Date;
    capabilities?: ITaskCapability;
    lastModifyUser?: IUser;
    lastModifyTimestamp?: Date;
    attachmentsCount?: number;
    deleted: boolean;
}

export interface StandardTask extends BaseTask {
    type: ITaskType.STANDARD;
    state: TaskState;
    post?: ICustomPost;
}

export interface AcknowledgeTask extends BaseTask {
    type: ITaskType.ACKNOWLEDGE;
    state: TaskState;
    post?: ICustomPost;
    screenData: CustomFields;
}

export interface SurveyTask extends BaseTask {
    type: ITaskType.SURVEY;
    state: TaskState;
    post?: SurveyPost;
    screenData: CustomFields;
}

export interface FeedbackTask extends BaseTask {
    type: ITaskType.FEEDBACK;
    state: FeedbackTaskState;
    post?: SurveyPost;
    lastReviewUser?: IUser;
    lastReviewTimestamp?: Date;
    screenData: CustomFields;
    dataCommentsSummary: Record<number, FeedbackTaskCommentSummary>;
    timeToClosed: number | null;
    timeToReview: number | null;
}

export type GenericTask =
    | StandardTask
    | AcknowledgeTask
    | SurveyTask
    | FeedbackTask;

export interface ITaskCreate
    extends StandardTask,
        ICommonCollectionsHolderCreate {
    assigneeUserId?: number;
    assigneeGroupId?: number;
    addWatcherUserIds?: number[];
    addWatcherGroupIds?: number[];
    clientUid: string;
}

export interface ITaskEdit
    extends Omit<ITaskCreate, 'clientUid'>,
        ICommonCollectionsHolderEdit {}

export interface ITaskCapability {
    canViewDetail: boolean;
    canModify: boolean;
    canDelete: boolean;
    canCopy: boolean;
    canUpdateState: boolean;
    canAddComment: boolean;
    canEditAttachments: boolean;
    canEditReminders: boolean;
}

export interface IReminder {
    id: number | null;
    value: number;
    range: RangeType;
    types: ReminderType[];
    checked?: boolean;
}

export interface ISubTask {
    id?: number;
    description: string;
    state: TaskState;
}

export interface ITaskForInterest extends StandardTask {
    postTitle: string;
    postCustomId: string | null;
    communityId: number;
}

export interface TaskAttachmentList {
    taskId: number;
    attachments: IListAttachments;
}

export interface BaseTaskList<T extends BaseTask> extends PaginatedList<T> {
    filter: ITaskFilters;
    assignedToMeExpiringTasksCount: number;
    assignedToMeExpiredTasksCount: number;
    expiredTasksCount: number;
    expiringTasksCount: number;
    totalTasksCount: number;
    assignedToMeTasksCount: number;
    openTasksCount: number;
    assignedToMeMinTasksExpirationDate: ZonedDateTime | undefined;
}

export type TaskList = BaseTaskList<StandardTask>;

export type AcknowledgeTaskList = BaseTaskList<AcknowledgeTask>;

export type SurveyTaskList = BaseTaskList<SurveyTask>;

export type FeedbackTaskList = PaginatedList<FeedbackTask>;

export interface IAcknowledgeTaskFieldDefinition {
    id: number;
    fieldDefinitions: ICustomFieldDefinition[];
}

export interface IAcknowledgeTaskConfirm {
    taskId: number;
    occToken: number;
}
export interface IAcknowledgeTaskConfirmData {
    tasks: IAcknowledgeTaskConfirm[];
    screenData: CustomFieldsAcknowledgeData | null;
}

export interface SurveyTaskConfirm {
    taskId: number;
    occToken: number;
}

export interface SurveyTaskConfirmData {
    tasks: SurveyTaskConfirm[];
    customData: CustomFieldsSurveyData | null;
}
export interface IAcknowledgeTaskRequest {
    descriptionDelta?: QuillText;
    descriptionPlainText?: string;
    allCommunity: boolean;
    recipientGroupCardinality?: RecipientGroupCardinality;
    reminders?: IReminder[];
    recipientMembers?: Member[];
    expiration: ZonedDateTime | undefined;
}

export interface ITaskQueryParamsRequest {
    loadViewLink: boolean;
    loadDownloadLink: boolean;
    loadPreviewImageLink: boolean;
}

interface BaseTaskConfirmResponse<T extends BaseTask> {
    confirmedTasks: T[];
    concurrencyErrorTasks: T[];
}

export type IAcknowledgeTaskConfirmResponse =
    BaseTaskConfirmResponse<AcknowledgeTask>;
export type SurveyTaskConfirmResponse = BaseTaskConfirmResponse<SurveyTask>;
export type FeedbackTaskAnswerResponse = BaseTaskConfirmResponse<FeedbackTask>;

export type TaskAssignee = Pick<
    StandardTask,
    'assigneeGroup' | 'assigneeUser' | 'assigneeMember'
>;

export interface CustomFieldsAcknowledgeData {
    customFields: CustomFields;
}

export interface CustomFieldsSurveyData {
    customFields: CustomFields;
}

export interface FeedbackTaskCommentSummary {
    compliant: boolean | null;
    commentsCount: number;
}

export const toggleTaskState = (state: TaskState): TaskState => {
    switch (state) {
        case TaskState.CLOSED:
            return TaskState.OPEN;
        case TaskState.OPEN:
            return TaskState.CLOSED;
        default:
            assertUnreachable(state);
    }
};

export interface ToggleSubtaskState {
    subtaskId: number;
    subtaskState: TaskState;
}

export interface ITaskAssigneesRequest extends IGenericCommunityFilter {
    communityIds: number[];
    workspaceIds: number[];
    tagIds: number[];
    includeNotVisible: boolean;
    orderBy: SearchMemberOrderType;
}
