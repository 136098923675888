import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import {
    PageTokenInfo,
    PaginatedList,
    emptyPaginatedList,
    getNextPageToken,
    isDefined,
    toPaginatedList,
} from '@interacta-shared/util';
import { AdminV2CommunityRolesService } from '@modules/admin-v2-community/services/admin-v2-community-roles.service';
import { AdminV2GroupStatusFilter } from '@modules/admin-v2-group/models/admin-v2-group.model';
import { AdminV2GroupService } from '@modules/admin-v2-group/services/admin-v2-group.service';
import { AdminV2MemberRoleAssociation } from '@modules/admin-v2-roles/models/admin-v2-roles-associations.model';
import { AdminV2UserStatusFilter } from '@modules/admin-v2-user/models/admin-v2-user.model';
import { AdminV2UserService } from '@modules/admin-v2-user/services/admin-v2-user.service';
import { AdminV2WorkspaceService } from '@modules/admin-v2-workspace/services/admin-v2-workspace.service';
import { IUsersGroup, PartialSharedProfile } from '@modules/core';
import {
    Member,
    MemberWithCustomFlags,
} from '@modules/core/models/member/member.model';
import { wrapMemberPaginatedList } from '@modules/core/models/member/member.utils';
import { Observable, map, of } from 'rxjs';
import {
    toAdminV2MemberItem,
    toAdminV2MemberWithCustomFlags,
} from '../models/admin-v2-member.deserialize';
import {
    AdminV2MemberManagerEntityIds,
    AdminV2MemberManagerType,
    AdminV2MemberWithCustomFlags,
} from '../models/admin-v2-members-manager.model';
import { fromAdminV2PaginatedFilters } from '../models/admin-v2-paginated.serialize';

@Injectable({ providedIn: 'root' })
export class AdminV2MemberService {
    private readonly http = inject(HttpClient);
    private readonly workspaceService = inject(AdminV2WorkspaceService);
    private readonly communityRolesService = inject(
        AdminV2CommunityRolesService,
    );
    private readonly userService = inject(AdminV2UserService);
    private readonly groupService = inject(AdminV2GroupService);

    private readonly baseUrlDataV3 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v3/admin/data`;

    private readonly CURRENT_PAGE_SIZE = 25;
    private readonly SEARCH_PAGE_SIZE = 15;

    currentMembers(
        type: AdminV2MemberManagerType,
        entityIds: AdminV2MemberManagerEntityIds,
        pageToken: PageTokenInfo,
    ): Observable<PaginatedList<AdminV2MemberWithCustomFlags>> {
        const membersList = () => {
            switch (type) {
                case 'admin_v2_workspace_direct_members':
                    return this.currentWorkspaceMembers(pageToken, entityIds);
                case 'admin_v2_role_operational_members':
                    return this.currentRoleOperationalMembers(
                        pageToken,
                        entityIds,
                    );
                case 'admin_v2_organization_users':
                    return this.currentOrganizationMemberUsers(
                        pageToken,
                        entityIds,
                    );
                case 'admin_v2_group_users':
                    return this.currentGroupMembers(pageToken, entityIds);
                default:
                    return of(emptyPaginatedList());
            }
        };
        return membersList().pipe(
            map((res) => ({
                ...res,
                list: res.list.map(toAdminV2MemberWithCustomFlags),
            })),
        );
    }

    searchMembers(
        type: AdminV2MemberManagerType,
        entityIds: AdminV2MemberManagerEntityIds,
        pageToken: PageTokenInfo,
        search: string,
        checkDirectMembers = false,
        excludeDirectMembers = false,
    ): Observable<PaginatedList<AdminV2MemberWithCustomFlags>> {
        const membersList = () => {
            switch (type) {
                case 'admin_v2_workspace_direct_members':
                    return this.searchAllMembers(
                        entityIds,
                        pageToken,
                        search,
                        checkDirectMembers,
                        excludeDirectMembers,
                    );
                case 'admin_v2_role_admin_members':
                case 'admin_v2_role_operational_members':
                    return this.searchRoleMembers(pageToken, search, entityIds);
                case 'admin_v2_organization_users':
                case 'admin_v2_group_users':
                    return this.searchUsers(pageToken, search, entityIds);
                case 'admin_v2_user_groups':
                    return this.searchGroups(pageToken, search, entityIds);
                default:
                    return of(emptyPaginatedList());
            }
        };
        return membersList().pipe(
            map((res) => ({
                ...res,
                list: res.list.map(toAdminV2MemberWithCustomFlags),
            })),
        );
    }

    // CURRENT MEMBERS METHODS
    private currentWorkspaceMembers(
        pageToken: PageTokenInfo,
        entityIds: AdminV2MemberManagerEntityIds,
    ): Observable<PaginatedList<Member>> {
        if (isDefined(entityIds.workspaceId)) {
            return this.workspaceService.getWorkspaceDirectMembers(
                entityIds.workspaceId,
                {
                    pageToken,
                    pageSize: this.CURRENT_PAGE_SIZE,
                },
            );
        }
        return of(emptyPaginatedList<Member>());
    }

    private currentRoleOperationalMembers(
        pageToken: PageTokenInfo,
        entityIds: AdminV2MemberManagerEntityIds,
    ): Observable<PaginatedList<AdminV2MemberRoleAssociation>> {
        if (isDefined(entityIds.communityId) && isDefined(entityIds.roleId)) {
            return this.communityRolesService.getOperationalMembersByRoleList(
                {
                    communityId: entityIds.communityId,
                },
                {
                    pageToken,
                    pageSize: this.CURRENT_PAGE_SIZE,
                },
                entityIds.roleId,
            );
        }
        return of(emptyPaginatedList<AdminV2MemberRoleAssociation>());
    }

    private currentOrganizationMemberUsers(
        pageToken: PageTokenInfo,
        entityIds: AdminV2MemberManagerEntityIds,
    ): Observable<PaginatedList<Member>> {
        return this.userService
            .searchUsers(
                {
                    status: AdminV2UserStatusFilter.ACTIVE_USERS,
                    areas: isDefined(entityIds.areaId)
                        ? [{ id: entityIds.areaId, name: '' }]
                        : [],
                    businessUnits: isDefined(entityIds.businessUnitId)
                        ? [{ id: entityIds.businessUnitId, name: '' }]
                        : [],
                },
                {
                    pageToken,
                    pageSize: this.CURRENT_PAGE_SIZE,
                },
            )
            .pipe(map((res) => wrapMemberPaginatedList(res)));
    }

    private currentGroupMembers(
        pageToken: PageTokenInfo,
        entityIds: AdminV2MemberManagerEntityIds,
    ): Observable<PaginatedList<Member>> {
        if (isDefined(entityIds.groupId)) {
            return this.groupService
                .getMembers(
                    entityIds.groupId,
                    {
                        text: '',
                        nextPageToken: getNextPageToken(pageToken),
                    },
                    this.CURRENT_PAGE_SIZE,
                )
                .pipe(map((res) => wrapMemberPaginatedList(res)));
        }
        return of(emptyPaginatedList<Member>());
    }

    // SEARCH MEMBERS METHODS
    private searchAllMembers(
        entityIds: AdminV2MemberManagerEntityIds,
        pageToken: PageTokenInfo,
        search: string,
        checkDirectMembers = false,
        excludeDirectMembers = false,
    ): Observable<PaginatedList<Member>> {
        return this.http
            .post<any>(`${this.baseUrlDataV3}/members`, {
                ...fromAdminV2PaginatedFilters({
                    pageToken,
                    pageSize: this.SEARCH_PAGE_SIZE,
                }),
                name: search,
                checkWorkspaceDirectMember: checkDirectMembers
                    ? entityIds.workspaceId
                    : undefined,
                excludeDirectMemberByWorkspaceId: excludeDirectMembers
                    ? entityIds.workspaceId
                    : undefined,
            })
            .pipe(map((res) => toPaginatedList(res, toAdminV2MemberItem)));
    }

    private searchUsers(
        pageToken: PageTokenInfo,
        search: string,
        entityIds: AdminV2MemberManagerEntityIds,
    ): Observable<PaginatedList<PartialSharedProfile>> {
        return this.userService.searchUsers(
            {
                status: AdminV2UserStatusFilter.ACTIVE_USERS,
                search,
                checkGroupMember: entityIds.groupId,
                checkAreaMember: entityIds.areaId,
                checkBusinessUnitMember: entityIds.businessUnitId,
            },
            {
                pageSize: this.SEARCH_PAGE_SIZE,
                pageToken,
            },
        );
    }

    private searchGroups(
        pageToken: PageTokenInfo,
        search: string,
        entityIds: AdminV2MemberManagerEntityIds,
    ): Observable<PaginatedList<IUsersGroup>> {
        return this.groupService.getIUsersGroups(
            {
                search: search,
                status: AdminV2GroupStatusFilter.ACTIVE_GROUPS,
                checkUserMember: entityIds.userId,
            },
            {
                pageSize: this.SEARCH_PAGE_SIZE,
                pageToken,
            },
        );
    }

    private searchRoleMembers(
        pageToken: PageTokenInfo,
        search: string,
        entityIds: AdminV2MemberManagerEntityIds,
    ): Observable<PaginatedList<MemberWithCustomFlags>> {
        if (isDefined(entityIds.workspaceId)) {
            return this.workspaceService.getWorkspaceMembers(
                entityIds.workspaceId,
                {
                    search,
                    roleAssociation:
                        isDefined(entityIds.communityId) &&
                        isDefined(entityIds.roleId)
                            ? {
                                  communityId: entityIds.communityId,
                                  roleId: entityIds.roleId,
                              }
                            : undefined,
                },
                {
                    pageToken,
                    pageSize: this.SEARCH_PAGE_SIZE,
                },
            );
        }
        return of(emptyPaginatedList<MemberWithCustomFlags>());
    }
}
