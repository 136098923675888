import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'interacta-creation-date[date]',
    templateUrl: './creation-date.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreationDateComponent {
    @Input() date!: Date;
    @Input() disableTooltip = false;
}
