import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ICommunity } from '@modules/communities/models/communities.model';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-light-box-info',
    templateUrl: './light-box-info.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightBoxInfoComponent {
    @Input() attachment?: IAttachment | null;
    @Input() community?: ICommunity | null;
    @Output() hashtagClicked = new EventEmitter<IHashTag>();
    @Output() showVersions = new EventEmitter<void>();
}
