import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { uuid } from '@interacta-shared/util';

@Component({
    selector: 'interacta-toggle',
    templateUrl: './toggle.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgClass, MatSlideToggleModule, NgIf],
})
export class ToggleComponent {
    @Input('aria-labelledby') ariaLabelledby: string | null = null;
    @Input() label?: string;
    @Input() checked = false;
    @Input() disabled = false;
    @Input() labelPosition: 'after' | 'before' = 'after';

    innerLabel = uuid();

    @Output() toggle = new EventEmitter<boolean>();
}
