import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';
import { Palette } from '../../model';

@Component({
    standalone: true,
    imports: [NgClass, IconComponent, MatRippleModule, NgIf],
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[interacta-button-scroll-arrow]',
    templateUrl: './button-scroll-arrow.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonScrollArrowComponent {
    @Input() icon: Extract<
        InteractaIcon,
        'chevron-big-left' | 'chevron-big-right'
    > = 'chevron-big-left';
    @Input() bgColor: Extract<Palette, 'gray-500' | 'surface-A'> = 'gray-500';
    @Input() @HostBinding('disabled') disabled?: boolean;
}
