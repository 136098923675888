import { mapArrayById } from '@interacta-shared/util';
import { ICatalogEntry, ICatalogsOperativityList } from './catalog.model';

export const toCatalogEntryItem = (
    item: any,
    catalogId?: number,
): ICatalogEntry => ({
    id: item.id,
    label: item.label,
    externalId: item.externalId,
    deleted: item.deleted ?? false,
    catalogId: catalogId ?? item.catalogId,
    parents: (item.parents || []).map(toCatalogEntryItem),
    parentIds: item.parentIds ?? mapArrayById(item.parents || []),
});

export const toCatalogsOperativityList = (
    record: any,
): ICatalogsOperativityList => ({
    id: record.id,
    name: record.name,
    etag: record.etag,
    paged: record.paged,
    entries: (record.entries || []).map((i: any) =>
        toCatalogEntryItem(i, record.id),
    ),
});
