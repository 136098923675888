import { IList } from '@interacta-shared/util';
import {
    IScreenContext,
    ScreenSerialize,
} from '@modules/communities/models/screen.model';
import { UserDeserilize } from '@modules/core';
import {
    CustomPostSerialize,
    ICustomPostForContext,
} from './../../post/models/custom-post.model';
import { IUsersGroup, UserExtendedDeserialize } from './user-group.model';
import { PartialSharedProfile } from './user.model';

export interface IGenericFilter {
    name?: string;
    pageToken?: string;
    pageSize?: number;
}
export interface IGenericCommunityFilter extends IGenericFilter {
    calculateTotalItemsCount?: boolean;
}

export interface IMemberSearchRequest extends IGenericCommunityFilter {
    orderBy?: SearchMemberOrderType;
    workspaceId?: number;
    communityId?: number;
    checkCommunityMember?: number;
    includeNotVisible?: boolean;
}
export interface IUserSearchRequest extends IGenericCommunityFilter {
    completeFilters?: string;
    communityIds?: number[];
    workspaceIds?: number[];
    restrict?: boolean;
    includePostAuthors?: boolean;
    onlyFollowedByMe?: boolean;
    orderBy: SearchUserOrderType;
    orderDesc?: boolean;
    businessUnitIds?: number[];
    areaIds?: number[];
    place?: string;
    role?: string;
    onlyVisibleInPeopleSection?: boolean;
}

export interface IUserPickerSearchRequest extends IUserSearchRequest {
    calculateTotalItemsCount?: boolean;
    context?: ICustomPostForContext;
    screenContext?: IScreenContext;
}

export interface IGroupPickerSearchRequest extends IGenericCommunityFilter {
    orderBy: SearchGroupOrderType;
    orderDesc?: boolean;
    context?: ICustomPostForContext;
    screenContext?: IScreenContext;
}

interface RolesSearchRequest extends IGenericCommunityFilter {
    communityId?: number;
    checkCommunityAssociation?: number;
    includeDeleted?: boolean;
}
export interface OperationalRolesSearchRequest extends RolesSearchRequest {}

export interface AdminRolesSearchRequest extends RolesSearchRequest {}

export enum SearchMemberOrderType {
    NAME = 'name',
    RELEVANCE = 'relevance',
}

export enum SearchGroupOrderType {
    NAME = 'name',
}

export enum SearchUserOrderType {
    POPULARITY = 'popularity',
    GLOBAL_POPULARITY = 'globalPopularity',
    NAME = 'name',
    SURNAME = 'surname',
    EMAIL = 'contactEmail',
    EXTERNAL_EMAIL = 'externalServiceEmail',
}

export class UserSearchDeserialize {
    public static userSearch = (record: any): IList<PartialSharedProfile> => {
        const userSearch = <IList<PartialSharedProfile>>{};
        userSearch.list = (<Array<unknown>>record.items).map(
            UserDeserilize.peopleUser,
        );
        userSearch.nextPageToken = record.nextPageToken;
        userSearch.totalCount = record.totalItemsCount;
        return userSearch;
    };

    public static groupSearch = (record: any): IList<IUsersGroup> => {
        const userSearch = <IList<IUsersGroup>>{};
        userSearch.list = (<Array<unknown>>record.items).map(
            UserExtendedDeserialize.usersGroup,
        );
        userSearch.nextPageToken = record.nextPageToken;
        userSearch.totalCount = record.totalItemsCount;
        return userSearch;
    };
}

export class UserSearchSerialize {
    /*
     * -------------------- INCOMPRENSHIVE BEHAVIOUR --------------------------------------
     * Server error 400 if some params are assigned without strange manipulations
     * (server error: "invalid filterOrderCriteriaETag")
     * 1. userSearch.onlyFollowedByMe === true
     * 2. userSearch.orderBy.toString()
     *
     * For others questions contact @Altobelli @Volpe
     */
    static userSearch = (userSearch: IUserSearchRequest): any => {
        const record = {
            communityIds: userSearch.communityIds,
            workspaceIds: userSearch.workspaceIds,
            includePostAuthors: userSearch.includePostAuthors,
            calculateTotalItemsCount: userSearch.calculateTotalItemsCount,
            onlyFollowedByMe: userSearch.onlyFollowedByMe === true,
            name: userSearch.restrict ? null : userSearch.name,
            firstnameLastname: userSearch.restrict
                ? userSearch.name
                : undefined,
            pageToken: userSearch.pageToken,
            pageSize: userSearch.pageSize,
            orderBy: userSearch.orderBy?.toString(),
            orderDesc: userSearch.orderDesc ?? undefined,
            businessUnitIds: userSearch.businessUnitIds ?? undefined,
            areaIds: userSearch.areaIds ?? undefined,
            place: userSearch.place ?? undefined,
            role: userSearch.role ?? undefined,
            completeFilters: userSearch.completeFilters,
            onlyVisibleInPeopleSection:
                userSearch.onlyVisibleInPeopleSection ?? undefined,
        };
        return record;
    };

    static groupSearch = (userSearch: IMemberSearchRequest): any => {
        const record = {
            communityId: userSearch.communityId,
            workspaceId: userSearch.workspaceId,
            includeNotVisible: userSearch.includeNotVisible,
            calculateTotalItemsCount: userSearch.calculateTotalItemsCount,
            name: userSearch.name,
            pageToken: userSearch.pageToken,
            pageSize: userSearch.pageSize,
            orderBy: userSearch.orderBy?.toString(),
        };
        return record;
    };

    static userPickerSearch = (
        userPickerSearch: IUserPickerSearchRequest,
    ): any => {
        const record = {
            name: userPickerSearch.restrict ? null : userPickerSearch.name,
            firstnameLastname: userPickerSearch.restrict
                ? userPickerSearch.name
                : undefined,
            pageToken: userPickerSearch.pageToken,
            pageSize: userPickerSearch.pageSize,
            orderBy: userPickerSearch.orderBy?.toString(),
            orderDesc: userPickerSearch.orderDesc ?? undefined,
            calculateTotalItemsCount: userPickerSearch.calculateTotalItemsCount
                ? userPickerSearch.calculateTotalItemsCount
                : false,
            context: userPickerSearch.context
                ? CustomPostSerialize.contextCustomPost(
                      userPickerSearch.context,
                  )
                : undefined,
            screenContext: userPickerSearch.screenContext
                ? ScreenSerialize.screenContext(userPickerSearch.screenContext)
                : undefined,
        };
        return record;
    };

    static groupPickerSearch = (
        groupPickerSearch: IGroupPickerSearchRequest,
    ): any => {
        const record = {
            name: groupPickerSearch.name,
            pageToken: groupPickerSearch.pageToken,
            pageSize: groupPickerSearch.pageSize,
            orderBy: groupPickerSearch.orderBy?.toString(),
            orderDesc: groupPickerSearch.orderDesc ?? undefined,
            calculateTotalItemsCount:
                !!groupPickerSearch.calculateTotalItemsCount,
            context: groupPickerSearch.context
                ? CustomPostSerialize.contextCustomPost(
                      groupPickerSearch.context,
                  )
                : undefined,
            screenContext: groupPickerSearch.screenContext
                ? ScreenSerialize.screenContext(groupPickerSearch.screenContext)
                : undefined,
        };
        return record;
    };
}
