import {
    isI18nStringV2,
    toI18nDeltaV2,
} from '@modules/admin-v2-shared/utils/admin-v2-i18n';
import {
    toCustomFieldAdminValidation,
    toFieldCustomMetadata,
} from '@modules/communities/models/custom-metadata/custom-metadata.deserialize';
import { FieldType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { getConfigurableFieldTypeInfoFromRecord } from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import { i18nString, i18nStringV2 } from '@modules/core/models/utility.model';
import { ItemOrderByCriteria } from '@modules/shared-v2/models/orderby';
import {
    CustomFieldsConfigurationBase,
    CustomFieldsConfigurationEnumValues,
    FieldValidationForEditDTO,
} from './custom-fields-configuration.model';

export const toCustomFieldsConfigurationEnumValues = (
    record: any,
): CustomFieldsConfigurationEnumValues => {
    const label: i18nString | i18nStringV2 = record.label;

    return {
        id: record.id,
        label: isI18nStringV2(label)
            ? label.i18ns ?? label.label
            : record.label,
        labelV2: isI18nStringV2(label) ? label : undefined,
        parentIds: record.parentIds,
        deleted: record.deleted ?? false,
        clientRef: null,
        externalId: record.externalId,
        image: record.contentRef
            ? {
                  contentRef: record.contentRef,
                  temporaryContentViewLink: record.temporaryContentViewLink,
              }
            : undefined,
        visualizationOrder: record.visualizationOrder ?? undefined,
    };
};

/**
 * TODO da modificare quando la gestione "legacy" delle lingue sarà risolta
 * @param record
 * @returns
 */
export const toCustomFieldConfigurationBase = (
    record: any,
): CustomFieldsConfigurationBase => {
    const metadata = toFieldCustomMetadata(
        record.metadata as { [key: string]: string },
    );

    //in v3 api, validations object containes all possible validations
    const validationsFromServer: FieldValidationForEditDTO = record.validations;
    const validations = toCustomFieldAdminValidation(
        validationsFromServer.validations,
    );

    const descriptionV2 = record.description
        ? toI18nDeltaV2(record.description)
        : null;

    const type: FieldType = record.type;

    const enumValues = (<Array<any> | undefined>record.enumValues)?.map(
        toCustomFieldsConfigurationEnumValues,
    );

    return {
        id: record.id,
        label: record.label?.i18ns ?? record.label?.label,
        description: descriptionV2?.i18ns,
        type: getConfigurableFieldTypeInfoFromRecord(
            type,
            metadata,
            validations,
        ),
        fieldType: type,
        required: validationsFromServer.required,
        metadata: metadata,
        originalMetadata: metadata,
        enumValues,
        enumValuesOrderByCriteria: enumValues?.length
            ? toEnumOrderByCriteria(record.enumValuesOrderByCriteria)
            : undefined,
        parentId: record.parentId,
        readonly: record.readonly,
        deleted: record.deleted,
        childIds: record.childIds ?? [],
        localId: record.id,
    };
};

/**
 * @param enumValuesOrderByCriteria from BE comes as 0 = ASC, 1 = DESC, 2 = CUSTOM, null
 * when null from BE, it should be initialized as ASC
 */
const toEnumOrderByCriteria = (
    enumValuesOrderByCriteria: 0 | 1 | 2 | null,
): ItemOrderByCriteria => {
    switch (enumValuesOrderByCriteria) {
        case 2:
            return ItemOrderByCriteria.CUSTOM;
        case 1:
            return ItemOrderByCriteria.DESC;
        case 0:
        default:
            return ItemOrderByCriteria.ASC;
    }
};
