import {
    ConfigurationService,
    toZonedDatetime,
} from '@interacta-shared/data-access-configuration';
import {
    fromPageToken,
    mapArrayById,
    toPaginatedList,
} from '@interacta-shared/util';
import { CommunityTreeDeserialize } from '@modules/communities/models/communities.model';
import {
    toAcknowledgeTaskCustomFields,
    toSurveyTaskCustomFields,
} from '@modules/communities/models/custom-metadata/custom-metadata.deserialize';
import {
    CustomFieldType,
    ValidationType,
} from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import {
    wrapMember,
    wrapMembers,
} from '@modules/core/models/member/member.utils';
import { EventType } from '@modules/core/models/notification-user.model';
import { UserExtendedDeserialize } from '@modules/core/models/user-group.model';
import { UserDeserilize } from '@modules/core/models/user.model';
import {
    FeedbackHistoryItem,
    FeedbackTaskHistory,
    FeedbackTaskHistoryBase,
    FeedbackTaskHistoryItemEditState,
    FeedbackTaskHistoryItemStateChange,
} from '@modules/feedback/models/feedback.model';
import { toAttachmentsList } from '@modules/post/models/attachment/attachment.deserialize';
import {
    IBasePost,
    IPostMetadata,
    TaskSummary,
} from '@modules/post/models/base-post.model';
import { ICustomPost } from '@modules/post/models/custom-post.model';
import {
    SurveyPost,
    SurveyQuestionDefinition,
} from '@modules/post/models/survey-post/survey-post.model';
import {
    isFeedBackPostType,
    isSurveyPostType,
} from '@modules/post/models/survey-post/survey-post.utils';
import { TaskOperationResponse } from '../services/task.service';
import { ITaskFilters } from './filter-task.model';
import {
    AcknowledgeTask,
    AcknowledgeTaskList,
    BaseTask,
    BaseTaskList,
    FeedbackTask,
    FeedbackTaskAnswerResponse,
    FeedbackTaskCommentSummary,
    FeedbackTaskList,
    FeedbackTaskState,
    GenericTask,
    IAcknowledgeTaskConfirmResponse,
    IAcknowledgeTaskRequest,
    IReminder,
    ISubTask,
    ITaskCapability,
    ITaskEdit,
    ITaskForInterest,
    ITaskType,
    PriorityType,
    StandardTask,
    SurveyTask,
    SurveyTaskConfirmResponse,
    SurveyTaskList,
    TaskAssignee,
    TaskList,
} from './task.model';

const toBaseTask = (
    record: any,
    configurationService: ConfigurationService,
): BaseTask => ({
    id: record.id,
    type: record.type ?? ITaskType.STANDARD,
    postId: record.postId,
    title: record.title,
    descriptionDelta: record.descriptionDelta
        ? new Server2Delta().process(record.descriptionDelta as string)
        : null,
    priority: record.priority ?? PriorityType.NONE,
    expiration: record.expiration
        ? toZonedDatetime(record.expiration, configurationService)
        : null,
    ...toTaskAssignee(record),
    watcherUsers: record.watcherUsers
        ? (<Array<any>>record.watcherUsers).map(UserDeserilize.userDetails)
        : [],
    watcherGroups: record.watcherGroups
        ? (<Array<any>>record.watcherGroups).map(
              UserExtendedDeserialize.usersGroup,
          )
        : [],
    subTasks: record.subTasks
        ? (<Array<any>>record.subTasks).map(toSubTask)
        : [],
    reminders: record.reminders
        ? (<Array<any>>record.reminders).map(toReminder)
        : [],
    mentions: record.mentions || [],
    hashtags: record.hashtags || [],
    creatorUser: record.creatorUser
        ? UserDeserilize.userDetails(record.creatorUser)
        : undefined,
    creationTimestamp: record.creationTimestamp
        ? new Date(record.creationTimestamp as number)
        : undefined,
    lastModifyUser: record.lastModifyUser
        ? UserDeserilize.userDetails(record.lastModifyUser)
        : undefined,
    lastModifyTimestamp: record.lastModifyTimestamp
        ? new Date(record.lastModifyTimestamp as number)
        : undefined,
    capabilities: record.capabilities
        ? toTaskCapability(record.capabilities)
        : undefined,
    closingTimestamp: record.closingTimestamp
        ? new Date(record.closingTimestamp as number)
        : undefined,
    occToken: record.occToken,
    attachmentsCount: record.attachmentsCount,
    attachmentsList: toAttachmentsList(
        <Array<any> | null>record.attachments,
        (<Array<any> | null>record.attachments)?.length ?? 0,
    ),
    deleted: record.deleted ?? false,
});

export const toStandardTask = (
    record: any,
    configurationService: ConfigurationService,
): StandardTask => ({
    ...toBaseTask(record, configurationService),
    type: ITaskType.STANDARD,
    state: record.state,
});

export const toAcknowledgeTask = (
    record: any,
    configurationService: ConfigurationService,
    postMetadata: IPostMetadata | undefined,
): AcknowledgeTask => ({
    ...toBaseTask(record, configurationService),
    type: ITaskType.ACKNOWLEDGE,
    state: record.state,
    screenData: toAcknowledgeTaskCustomFields(record.screenData, postMetadata),
});

export const toSurveyTask = (
    record: any,
    configurationService: ConfigurationService,
    fieldDefinition: SurveyQuestionDefinition[],
): SurveyTask => ({
    ...toBaseTask(record, configurationService),
    type: ITaskType.SURVEY,
    state: record.state,
    screenData: toSurveyTaskCustomFields(record.surveyData, fieldDefinition),
});

export const toFeedbackTask = (
    record: any,
    configurationService: ConfigurationService,
    survey: SurveyPost,
): FeedbackTask => ({
    ...toBaseTask(record, configurationService),
    type: ITaskType.FEEDBACK,
    postId: survey.id,
    post: survey,
    state: record.state,
    screenData: toSurveyTaskCustomFields(
        record.surveyData,
        filterFeedbackTaskFieldsValidations(
            record.state,
            survey.fieldDefinitions,
        ),
    ),
    dataCommentsSummary: toFeedbackTaskCommentSummaryRecord(
        record.surveyDataCommentsInfo,
        survey.fieldDefinitions,
    ),
    lastReviewUser: record.lastReviewUser
        ? UserDeserilize.userDetails(record.lastReviewUser)
        : undefined,
    lastReviewTimestamp: record.lastReviewTimestamp
        ? new Date(record.lastReviewTimestamp as number)
        : undefined,
    timeToClosed: record.timeToClosed,
    timeToReview: record.timeToReview,
});

export function filterFeedbackTaskFieldsValidations(
    state: FeedbackTaskState,
    questionDefinitions: SurveyQuestionDefinition[],
): SurveyQuestionDefinition[] {
    return state === FeedbackTaskState.REWORK
        ? questionDefinitions.map((fd) => {
              if (fd.customFieldType === CustomFieldType.FILE_PICKER) {
                  return {
                      ...fd,
                      validations: fd.validations.filter(
                          (v) => v.validationType !== ValidationType.MAX_LENGTH,
                      ),
                  };
              } else {
                  return fd;
              }
          })
        : questionDefinitions;
}

/**
 * Attenzione, potenzialmente incompleto.
 * Attualmente usato solo da notification user model
 */
export const toGenericTask = (
    record: any,
    configrationService: ConfigurationService,
    post: IBasePost | null,
): GenericTask => {
    switch (record.type as ITaskType) {
        case ITaskType.STANDARD:
            return toStandardTask(record, configrationService);
        case ITaskType.ACKNOWLEDGE:
            return toAcknowledgeTask(
                record,
                configrationService,
                post?.metadata,
            );
        case ITaskType.SURVEY:
            return toSurveyTask(
                record,
                configrationService,
                post && isSurveyPostType(post) ? post.fieldDefinitions : [],
            );
        case ITaskType.FEEDBACK:
            return post && isFeedBackPostType(post)
                ? toFeedbackTask(record, configrationService, post)
                : toStandardTask(record, configrationService);
    }
};

const toBaseTaskList = <T extends BaseTask>(
    record: any,
    filter: ITaskFilters,
    configurationService: ConfigurationService,
): Omit<BaseTaskList<T>, 'list'> => ({
    nextPageToken: fromPageToken(
        <string | null | undefined>record.nextPageToken,
    ),
    isFetching: record.isFetching,
    filter: filter,
    totalCount: record.totalItemsCount,
    assignedToMeExpiringTasksCount: record.assignedToMeExpiringTasksCount,
    assignedToMeExpiredTasksCount: record.assignedToMeExpiredTasksCount,
    expiredTasksCount: record.expiredTasksCount,
    expiringTasksCount: record.expiringTasksCount,
    totalTasksCount: record.totalTasksCount,
    assignedToMeTasksCount: record.assignedToMeTasksCount,
    openTasksCount: record.openTasksCount,
    assignedToMeMinTasksExpirationDate:
        record.assignedToMeMinTasksExpirationDate
            ? toZonedDatetime(
                  record.assignedToMeMinTasksExpirationDate,
                  configurationService,
              )
            : undefined,
});

export const toTaskList = (
    record: any,
    post: ICustomPost,
    filter: ITaskFilters,
    configurationService: ConfigurationService,
): TaskList => ({
    ...toBaseTaskList(record, filter, configurationService),
    list: (<Array<any>>record.items).map((rec) => ({
        ...toStandardTask(rec, configurationService),
        postId: post.id,
        post: post,
    })),
});

const toTaskAssignee = (record: any): TaskAssignee => {
    const assigneeUser = record.assigneeUser
        ? UserDeserilize.userDetails(record.assigneeUser)
        : null;
    const assigneeGroup = record.assigneeGroup
        ? UserExtendedDeserialize.usersGroup(record.assigneeGroup)
        : null;
    const assigneeMember =
        assigneeUser != null
            ? wrapMember(assigneeUser)
            : assigneeGroup != null
              ? wrapMember(assigneeGroup)
              : undefined;

    const assignee: TaskAssignee = {
        assigneeUser,
        assigneeGroup,
        assigneeMember,
    };
    return assignee;
};

export const toTaskForInterest = (
    record: any,
    configurationService: ConfigurationService,
): ITaskForInterest => ({
    ...toStandardTask(record, configurationService),
    postTitle: record.postTitle,
    postCustomId: record.postCustomId,
    communityId: record.communityId,
});

export const toTaskForEdit = (
    record: TaskOperationResponse,
    configurationService: ConfigurationService,
): StandardTask => {
    const task: StandardTask = toStandardTask(
        { ...record.taskData, capabilities: record.capabilities },
        configurationService,
    );
    task.id = record.taskId || task.id;
    task.occToken = record.nextOccToken;

    return task;
};

export const toTaskDetailsForEdit = (
    record: any,
    configurationService: ConfigurationService,
): ITaskEdit => {
    const recordManipulation = {
        ...(record.contentData ?? record.taskData),
        ...record,
    };

    const task: ITaskEdit = <ITaskEdit>(
        toStandardTask(recordManipulation, configurationService)
    );
    return task;
};

export const toSubTask = (record: any): ISubTask => ({
    id: record.id,
    description: record.description,
    state: record.state,
});
export const toReminder = (record: any): IReminder => ({
    id: record.id,
    value: Number(record.value),
    range: record.range,
    types: record.types,
});

export const toTaskCapability = (record: any): ITaskCapability => ({
    canViewDetail: record.canViewDetail,
    canModify: record.canModify,
    canDelete: record.canDelete,
    canCopy: record.canCopy,
    canUpdateState: record.canUpdateState,
    canAddComment: record.canAddComment,
    canEditAttachments: record.canEditAttachments,
    canEditReminders: record.canEditReminders,
});

type TaskListCounters<T extends BaseTask> = Pick<
    BaseTaskList<T>,
    | 'assignedToMeTasksCount'
    | 'assignedToMeExpiredTasksCount'
    | 'assignedToMeExpiringTasksCount'
    | 'openTasksCount'
    | 'totalTasksCount'
    | 'assignedToMeMinTasksExpirationDate'
>;

const enrichTaskListCounters = <T extends BaseTask>(
    taskList: Partial<TaskListCounters<T>>,
    taskSummary: TaskSummary,
): TaskListCounters<T> => ({
    ...taskList,
    assignedToMeTasksCount:
        taskList.assignedToMeTasksCount ??
        taskSummary.assignedToMeTasksCount ??
        0,
    assignedToMeExpiredTasksCount:
        taskList.assignedToMeExpiredTasksCount ??
        taskSummary.assignedToMeExpiredTasksCount ??
        0,
    assignedToMeExpiringTasksCount:
        taskList.assignedToMeExpiringTasksCount ??
        taskSummary.assignedToMeExpiringTasksCount ??
        0,
    openTasksCount: taskList.openTasksCount ?? taskSummary.openTasksCount ?? 0,
    totalTasksCount:
        taskList.totalTasksCount ?? taskSummary.totalTasksCount ?? 0,
    assignedToMeMinTasksExpirationDate:
        taskList.assignedToMeMinTasksExpirationDate ??
        taskSummary.assignedToMeMinTasksExpirationDate,
});

export const toSurveyTaskList = (
    record: any,
    post: SurveyPost,
    filter: ITaskFilters,
    configurationService: ConfigurationService,
): SurveyTaskList => {
    let result: SurveyTaskList = {
        ...toBaseTaskList(record, filter, configurationService),
        list: (<Array<any>>record.items).map((rec) => ({
            ...toSurveyTask(rec, configurationService, post.fieldDefinitions),
            postId: post.id,
            post: post,
        })),
    };
    //counters -> only first page
    if (post.surveyTaskSummary) {
        result = {
            ...result,
            ...enrichTaskListCounters(result, post.surveyTaskSummary),
        };
    }
    return result;
};

export const toFeeedbackTaskList = (
    record: any,
    post: SurveyPost,
    configurationService: ConfigurationService,
): FeedbackTaskList => {
    return toPaginatedList({
        ...record,
        items: (<Array<any>>record.items).map((rec) => ({
            ...toFeedbackTask(rec, configurationService, post),
        })),
    });
};

export const toFeeedbackTaskHistory = (
    record: any,
    configurationService: ConfigurationService,
): FeedbackTaskHistory => {
    return toPaginatedList({
        items: (<Array<any>>record.items).map((item) => ({
            ...toFeeedbackTaskHistoryItem(item, configurationService),
        })),
        nextPageToken: record.nextPageToken,
        totalItemsCount: record.totalItemsCount,
    });
};

export const toAcknowledgeTaskList = (
    record: any,
    post: ICustomPost,
    filter: ITaskFilters,
    configurationService: ConfigurationService,
): AcknowledgeTaskList => {
    let result: AcknowledgeTaskList = {
        ...toBaseTaskList(record, filter, configurationService),
        list: (<Array<any>>record.items).map((rec) => ({
            ...toAcknowledgeTask(rec, configurationService, post.metadata),
            postId: post.id,
            post: post,
        })),
    };
    //counters -> only first page
    if (post.acknowledgeTaskSummary) {
        result = {
            ...result,
            ...enrichTaskListCounters(result, post.acknowledgeTaskSummary),
        };
    }
    return result;
};

export const toAcknowledgeTaskConfirm = (
    record: any,
    postMetadata: IPostMetadata,
    configurationService: ConfigurationService,
): IAcknowledgeTaskConfirmResponse => ({
    confirmedTasks: (<Array<any>>record?.confirmedTasks || []).map((t) =>
        toAcknowledgeTask(t, configurationService, postMetadata),
    ),
    concurrencyErrorTasks: (
        <Array<any>>record?.concurrencyErrorTasks || []
    ).map((t) => toAcknowledgeTask(t, configurationService, postMetadata)),
});

export const toSurveyTaskConfirm = (
    record: any,
    configurationService: ConfigurationService,
    questionDefinition: SurveyQuestionDefinition[],
): SurveyTaskConfirmResponse => ({
    confirmedTasks: (<Array<any>>record?.confirmedTasks || []).map((t) =>
        toSurveyTask(t, configurationService, questionDefinition),
    ),
    concurrencyErrorTasks: (
        <Array<any>>record?.concurrencyErrorTasks || []
    ).map((t) => toSurveyTask(t, configurationService, questionDefinition)),
});

export const toFeedbackTaskAnswer = (
    record: any,
    configurationService: ConfigurationService,
    surevy: SurveyPost,
): FeedbackTaskAnswerResponse => ({
    confirmedTasks: (<Array<any>>record?.confirmedTasks || []).map((t) =>
        toFeedbackTask(t, configurationService, surevy),
    ),
    concurrencyErrorTasks: (
        <Array<any>>record?.concurrencyErrorTasks || []
    ).map((t) => toFeedbackTask(t, configurationService, surevy)),
});

export const toAcknowledgeTaskRequest = (
    record: any,
    configurationService: ConfigurationService,
): IAcknowledgeTaskRequest => ({
    descriptionDelta: record.descriptionDelta
        ? new Server2Delta().process(record.descriptionDelta as string)
        : null,
    descriptionPlainText: record.descriptionPlainText ?? null,
    expiration: record.expiration
        ? toZonedDatetime(record.expiration, configurationService)
        : undefined,
    recipientMembers: wrapMembers(
        (<Array<any>>record.recipientUsers || []).map((u) =>
            UserDeserilize.userDetails(u),
        ),
        (<Array<any>>record.recipientGroups || []).map((g) =>
            UserExtendedDeserialize.usersGroup(g),
        ),
    ),
    allCommunity: record.allCommunity,
    recipientGroupCardinality: record.recipientGroupCardinality,
    reminders: (<Array<any>>record.reminders || []).map(toReminder),
});

export const toFeeedbackTaskHistoryItem = (
    item: any,
    configurationService: ConfigurationService,
): FeedbackHistoryItem => {
    return item.typeId === EventType.CREATE_SURVEY_TASK_REQUEST
        ? toFeeedbackTaskHistoryItemInitial(item, configurationService)
        : item.typeId === EventType.EDIT_TASK
          ? toFeeedbackTaskHistoryItemEditState(item, configurationService)
          : toFeeedbackTaskHistoryItemStateChange(item, configurationService);
};

const toFeeedbackTaskHistoryItemBase = (
    item: any,
    configurationService: ConfigurationService,
): FeedbackTaskHistoryBase => ({
    id: item.id,
    timestamp: new Date(item.timestamp as number),
    typeId: item.typeId,
    typeDescription: item.typeDescription,
    task: toBaseTask(item.task, configurationService),
    authorUser: UserDeserilize.userDetails(item.authorUser),
    targetUser: item.targetUser
        ? UserDeserilize.userDetails(item.targetUser)
        : null,
    community: item.community
        ? CommunityTreeDeserialize.communityInfoDetails(item.community)
        : null,
});

export const toFeeedbackTaskHistoryItemInitial = (
    item: any,
    configurationService: ConfigurationService,
): FeedbackHistoryItem => ({
    ...toFeeedbackTaskHistoryItemBase(item, configurationService),
    tag: 'initial',
    state: FeedbackTaskState.OPEN,
});

export const toFeeedbackTaskHistoryItemStateChange = (
    item: any,
    configurationService: ConfigurationService,
): FeedbackTaskHistoryItemStateChange => ({
    ...toFeeedbackTaskHistoryItemBase(item, configurationService),
    tag: 'state-change',
    fromState: item.fromState ?? FeedbackTaskState.OPEN,
    toState: item.toState ?? FeedbackTaskState.IN_PROGRESS,
    message: item.message
        ? new Server2Delta().process(item.message as string)
        : null,
});

export const toFeeedbackTaskHistoryItemEditState = (
    item: any,
    configurationService: ConfigurationService,
): FeedbackTaskHistoryItemEditState => ({
    ...toFeeedbackTaskHistoryItemBase(item, configurationService),
    tag: 'edit-state',
});

export const toFeedbackTaskCommentSummary = (
    record: any,
): FeedbackTaskCommentSummary => ({
    compliant: record.compliant ?? null,
    commentsCount: record.commentsCount,
});

const toFeedbackTaskCommentSummaryRecord = (
    record: any,
    fieldDefinitions: SurveyQuestionDefinition[],
): Record<number, FeedbackTaskCommentSummary> =>
    mapArrayById(fieldDefinitions)?.reduce(
        (summary, fieldId) => {
            const data = record?.[fieldId];
            return data
                ? { ...summary, [fieldId]: toFeedbackTaskCommentSummary(data) }
                : summary;
        },
        {} as Record<number, FeedbackTaskCommentSummary>,
    ) ?? {};
