import { PaginatedList, fromPageToken } from '@interacta-shared/util';
import { CommunityTreeDeserialize } from '@modules/communities/models/communities.model';
import {
    toCustomFieldDefinitions,
    toFieldCustomMetadata,
} from '@modules/communities/models/custom-metadata/custom-metadata.deserialize';
import { MetadataFieldModeType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { toPostLink } from './post-link-break-circular.deserialize';
import {
    IEditPostLinkSourcePost,
    IEditPostLinksResponse,
    ILinkedBy,
    IPostLink,
} from './post-link.model';

export const toPostLinkSearch = (record: any): PaginatedList<IPostLink> => ({
    list: (<any[]>record?.items || []).map(toPostLink),
    nextPageToken: fromPageToken(record.nextPageToken),
    totalCount: record.totalItemsCount,
    isFetching: record.isFetching || false,
});

export const toLinkedBy = (record: NonNullable<any>): ILinkedBy => ({
    community: CommunityTreeDeserialize.communityInfoDetails(record?.community),
    postLinkFieldDefinitions: toCustomFieldDefinitions(
        record.postLinkFieldDefinitions,
        MetadataFieldModeType.POST,
    ),
    screenLinkFieldDefinitions: toCustomFieldDefinitions(
        record.screenLinkFieldDefinitions,
        MetadataFieldModeType.SCREEN,
    ),
    defaultMetadata: record.defaultMetadata
        ? toFieldCustomMetadata(record.defaultMetadata)
        : undefined,
});

export const toIEditPostLinkSourcePost = (
    record: any,
): IEditPostLinkSourcePost => ({
    id: record.id,
    occToken: record.occToken,
    title: record.title,
});

export const toIEditPostLinksResponse = (
    record: any,
): IEditPostLinksResponse => ({
    linksSuccess: record.editedPosts?.map(toIEditPostLinkSourcePost) ?? [],
    linksErrorValidation:
        record.validationErrors?.map((res: any) =>
            toIEditPostLinkSourcePost(res.post),
        ) ?? [],
    linksErrorConcurrency:
        record.concurrencyErrorPosts?.map(toIEditPostLinkSourcePost) ?? [],
});
