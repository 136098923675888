<div
    class="flex items-center"
    [ngClass]="{
        'flex-col justify-center ': orientation === 'vertical',
        'justify-center gap-32': orientation === 'horizontal',
        'gap-16': orientation === 'vertical' && size !== 'small'
    }"
>
    <interacta-image
        [alt]="'CONFIG_BASE.LABEL_END_LIST' | translate"
        [classes]="
            'object-contain shrink-0 ' +
            (size === 'large'
                ? 'h-308'
                : size === 'regular'
                  ? 'h-250'
                  : 'h-120')
        "
        fallback="assets/shared/images/doodles/{{ imageName }}.{{
            format === 'webp' ? 'png' : 'webp'
        }}"
        src="assets/shared/images/doodles/{{ imageName }}.{{
            format === 'webp' ? 'webp' : 'png'
        }}"
    ></interacta-image>

    <div class="flex flex-col items-center space-y-12">
        <div
            *ngIf="title"
            class="text-text typo-medium"
            [innerHTML]="title"
            [ngClass]="{ 'text-center': textAlign === 'center' }"
        ></div>
        <div
            *ngIf="subtitle"
            class="text-text-mid-contrast typo-sm"
            [innerHTML]="subtitle"
            [ngClass]="{ 'text-center': textAlign === 'center' }"
        ></div>
    </div>
</div>
