import Quill from 'quill';
import {
    QuillIcons,
    bold,
    bulletList,
    italic,
    link,
    orderedList,
    table,
    underline,
} from '../models/quill-icons.model';

let quillIconsOverridden = false;

export function quillIconsOverride(): void {
    if (!quillIconsOverridden) {
        const icons = Quill.import('ui/icons') as QuillIcons;
        icons['bold'] = bold;
        icons['italic'] = italic;
        icons['underline'] = underline;
        icons['list']['ordered'] = orderedList;
        icons['list']['bullet'] = bulletList;
        icons['link'] = link;
        icons['table'] = table;
        quillIconsOverridden = true;
    }
}
