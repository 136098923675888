// ritorno un oggetto, magari in futuro potrebbe essere comodo anche estrarre i query params
export const parseUrl = (
    url: string,
): { cleanUrl: string; paths: string[] } => {
    const cleanUrl = url.includes('?')
        ? url.substring(0, url.lastIndexOf('?'))
        : url.includes('#')
          ? url.substring(0, url.lastIndexOf('#'))
          : url;
    const [_, ...paths] = cleanUrl.includes('/')
        ? cleanUrl.split('/')
        : ['', cleanUrl];
    return { cleanUrl, paths };
};
