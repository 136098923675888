import {
    assertUnreachable,
    emptyPaginatedList,
    fetchPaginatedList,
    fetchPaginatedListError,
    fetchPaginatedListSuccess,
    firstLoading,
    getNextPageToken,
    Index,
    isDefined,
    lastLoading,
    mapArrayById,
    PageTokenInfo,
    PaginatedList,
    paginatedListAdd,
    regularLoading,
} from '@interacta-shared/util';
import { toScreenCustomFields } from '@modules/communities/models/custom-metadata/custom-metadata.deserialize';
import { CustomFieldType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { PostError } from '@modules/communities/models/post-error/post-error.model';
import {
    buildFetchPostError,
    getFetchPostErrorType,
} from '@modules/communities/models/post-error/post-error.utils';
import {
    PostLinkFilters,
    PostScreenLinkFieldIdsByPostId,
} from '@modules/communities/models/post-link-and-relation/post-link-and-relation.model';
import {
    defaultPostLinkFilters,
    postLinkAndRelationToCustomPost,
} from '@modules/communities/models/post-link-and-relation/post-link-and-relation.utils';
import { IUserAction } from '@modules/core';
import { DeltaUtility } from '@modules/core/helpers/delta/delta-utility.class';
import { mPartition } from '@modules/core/helpers/generic.utils';
import { Member } from '@modules/core/models/member/member.model';
import {
    isMemberGroup,
    isMemberUser,
} from '@modules/core/models/member/member.utils';
import { HomeCommunityIndex } from '@modules/core/models/utility.model';
import { toWorkflowHistoryItem } from '@modules/core/models/workflow-history/workflow-history.deserialize';
import * as fromAppStore from '@modules/core/store';
import {
    answerFeedbackTaskSuccess,
    validateFeedbackTaskSuccess,
} from '@modules/feedback/store/feedback-api.actions';
import {
    AttachmentCategoryType,
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import {
    attachmentsFiles,
    attachmentsListFilter,
    attachmentsMedia,
    emptyAttachmentsList,
    isFileAttachment,
    isMediaAttachment,
    updateAttachmentsList,
} from '@modules/post/models/attachment/attachment.utils';
import {
    FeedbackTaskSummary,
    IBasePost,
    IBasePostDifferential,
    PostType,
    RelatedItemsCounts,
    VisibilityType,
} from '@modules/post/models/base-post.model';
import {
    IComment,
    StreamingCommentsList,
} from '@modules/post/models/comment.model';
import {
    EventParticipateType,
    IEventPost,
    Invitation,
    InvitationGroup,
    isEventPost,
} from '@modules/post/models/event-post.model';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import {
    emptyPostFilters,
    filterIsEmpty,
} from '@modules/post/models/filter-post/filter-post.utils';
import {
    fromBasePost,
    GenericPost,
} from '@modules/post/models/generic-post.model';
import {
    buildSurveyState,
    isFeedBackPostType,
    isSurveyPost,
} from '@modules/post/models/survey-post/survey-post.utils';
import { buildEventState } from '@modules/post/utils/event-post.utils';
import { FeedbackTaskState, ITaskType } from '@modules/tasks/models/task.model';
import { createReducer, on } from '@ngrx/store';
import { isBefore } from 'date-fns';
import produce, { castDraft } from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import * as fromCommunityApiStore from '../community/community-api.actions';
import * as differentialActions from '../differential/differential.actions';
import {
    mergeDiffPostDetail,
    mergeDiffPosts,
    mergePartiallyDashboardOrHome,
    mergePartiallyInDetail,
} from '../differential/differential.utils';
import { createTaskSuccess, deleteTaskSuccess } from '../task/task-api.actions';
import * as fapi from './post-api.actions';
import * as fa from './post.actions';

export type PostFetch = 'dashboard' | 'detail';

export interface PostsMap {
    [postId: number]: GenericPost | undefined;
}

export interface PostLoadingInfo {
    isFetching: boolean;
    pageTokenInfo: PageTokenInfo;
    totalCount: number | null;
    counterIsFetching: boolean;
}

export interface FetchInfo {
    lastFetchTime?: number;
    fetchOrder: number[];
    isStale: boolean;
}

interface RelatedPostsData {
    loadingInfo: PostLoadingInfo;
    fetchInfo: FetchInfo;
    linkedFieldIds: PostScreenLinkFieldIdsByPostId;
}

interface LinkedPosts {
    postId: number | null;
    items: Record<number, RelatedPostsData>;
    filters: PostLinkFilters;
}

export interface PostState {
    allPosts: PostsMap;
    loadingInfo: Record<PostFetch, PostLoadingInfo>;
    fetchInfo: Record<PostFetch, FetchInfo>;
    filters: Record<PostFetch, IPostFilters>;
    previousFilter: IPostFilters | null;
    errors: Record<PostFetch, PostError>;
    relatedItemsCount: {
        postId?: number;
        value?: RelatedItemsCounts;
    };
    linkedPosts: LinkedPosts;

    // differential notifications
    syncToken: string | null;
    diffPosts: IBasePostDifferential[];
    diffPostDetail: IBasePostDifferential | null;

    /**
     * this record contains the list of communities (or home) for which there was
     * at least one fetch post
     */
    postFetchedCommunities: Record<HomeCommunityIndex, boolean>;
}

export const postFeatureKey = 'post';

export interface AppState extends fromAppStore.AppState {
    [postFeatureKey]: PostState;
}

const initialLoadingInfo = (): PostLoadingInfo => ({
    isFetching: false,
    pageTokenInfo: firstLoading(),
    totalCount: null,
    counterIsFetching: false,
});

const initialFetchInfo = (): FetchInfo => ({ fetchOrder: [], isStale: false });

const initRelatedPostsData = (): RelatedPostsData => ({
    loadingInfo: initialLoadingInfo(),
    fetchInfo: initialFetchInfo(),
    linkedFieldIds: {},
});

const initialRelatedPosts = (): LinkedPosts => ({
    postId: null,
    items: {},
    filters: defaultPostLinkFilters(),
});

const initialState: PostState = {
    allPosts: {},
    loadingInfo: {
        dashboard: initialLoadingInfo(),
        detail: initialLoadingInfo(),
    },
    fetchInfo: {
        dashboard: initialFetchInfo(),
        detail: initialFetchInfo(),
    },
    filters: {
        dashboard: emptyPostFilters(),
        detail: emptyPostFilters(),
    },
    previousFilter: null,
    errors: {
        dashboard: null,
        detail: null,
    },
    relatedItemsCount: {
        postId: undefined,
        value: undefined,
    },
    linkedPosts: initialRelatedPosts(),

    syncToken: null,
    diffPosts: [],
    diffPostDetail: null,

    postFetchedCommunities: {
        dashboard: false,
    },
};

const modifyPost = (
    state: PostState,
    postId: number,
    fn: (post: GenericPost) => GenericPost,
): PostState => {
    const post = state.allPosts[postId];
    if (!post) {
        return state;
    }

    const nextPost = fn(post);

    return {
        ...state,
        allPosts: {
            ...state.allPosts,
            [postId]: {
                ...nextPost,
            },
        },
    };
};

const modifyComments = (
    state: PostState,
    postId: number,
    fn: (comments: PaginatedList<IComment>) => PaginatedList<IComment>,
): PostState =>
    modifyPost(state, postId, (post) => {
        const comments = post.comments ?? emptyPaginatedList();
        const newCommentList = fn(comments);

        return {
            ...post,
            comments: newCommentList,
            commentsCount: newCommentList.totalCount,
        };
    });

const modifyStreamingComments = (
    state: PostState,
    postId: number,
    fn: (comments: StreamingCommentsList) => StreamingCommentsList,
): PostState =>
    modifyPost(state, postId, (post) => {
        if (post.tag !== PostType.EVENT) {
            return post;
        }

        const comments = post.streamingComments ?? {
            ...emptyPaginatedList(),
            nextSyncToken: null,
        };
        const newCommentList = fn(comments);

        return {
            ...post,
            streamingComments: newCommentList,
        };
    });

const modifyComment = (
    state: PostState,
    postId: number,
    commentId: number,
    fn: (comment: IComment) => IComment,
): PostState =>
    modifyComments(state, postId, (comments) => {
        return {
            ...comments,
            list: comments.list.map((c) => (c.id === commentId ? fn(c) : c)),
        };
    });

export const postReducer = createReducer(
    initialState,

    on(differentialActions.initSyncTokenSuccess, (state, props) => ({
        ...state,
        syncToken: props.syncToken,
    })),
    on(differentialActions.fetchDifferentialSuccess, (state, props) => ({
        ...state,
        syncToken: props.syncToken,
    })),
    on(differentialActions.addToDiffPosts, (state, props) => ({
        ...state,
        diffPosts: state.diffPosts.find((d) => d.id === props.post.id)
            ? state.diffPosts.map((d) =>
                  d.id === props.post.id ? props.post : d,
              )
            : [...state.diffPosts, props.post],
    })),
    on(differentialActions.removeFromDiffPosts, (state, props) => ({
        ...state,
        diffPosts: state.diffPosts.filter((d) => d.id !== props.post.id),
    })),
    on(differentialActions.mergePartiallyDashboardOrHome, (state, props) =>
        mergePartiallyDashboardOrHome(state, props.post),
    ),
    on(differentialActions.mergeDiffPosts, (state, props) =>
        mergeDiffPosts(state, props.posts),
    ),
    on(differentialActions.discardDiffPosts, (state, _props) => ({
        ...state,
        diffPosts: [],
    })),
    on(differentialActions.mergePartiallyInDetail, (state, props) =>
        mergePartiallyInDetail(
            state,
            props.post,
            props.addToDiffPostDetail ?? false,
        ),
    ),
    on(differentialActions.discardDiffPostDetail, (state) => ({
        ...state,
        diffPostDetail: null,
    })),
    on(differentialActions.mergeDiffPostDetail, (state) =>
        mergeDiffPostDetail(state),
    ),

    on(fa.fetchPosts, (state, props) => ({
        ...state,
        fetchInfo: {
            ...state.fetchInfo,
            [props.fetchType]: {
                fetchOrder:
                    props.fetchType === 'detail' &&
                    props.postId != null &&
                    props.postId in state.allPosts
                        ? [props.postId]
                        : [],
                isStale: false,
                lastFetchTime: Date.now(),
            },
        },
        loadingInfo: {
            ...state.loadingInfo,
            [props.fetchType]: {
                isFetching: true,
                pageTokenInfo: firstLoading(),
            },
        },
        filters: {
            ...state.filters,
            [props.fetchType]: props.filters
                ? props.filters
                : state.filters[props.fetchType],
        },
        linkedPosts: initialRelatedPosts(),
    })),
    on(fapi.fetchPostsSuccess, (state, props) => {
        const [nextPosts, nextFetchInfo] = updatePosts(
            state.allPosts,
            state.fetchInfo,
            props.postList.list,
            props.fetchType,
        );
        return {
            ...state,
            allPosts: nextPosts,
            fetchInfo: nextFetchInfo,
            postFetchedCommunities:
                props.fetchType === 'dashboard' && props.filters
                    ? {
                          ...state.postFetchedCommunities,
                          [props.filters.communityId ?? 'dashboard']: true,
                      }
                    : state.postFetchedCommunities,
            loadingInfo: {
                ...state.loadingInfo,
                [props.fetchType]: {
                    isFetching: false,
                    pageTokenInfo: props.postList.nextPageToken
                        ? regularLoading(props.postList.nextPageToken)
                        : lastLoading(),
                    totalCount:
                        props.postList.totalCount ??
                        state.loadingInfo[props.fetchType].totalCount ??
                        null,
                },
            },
            errors: {
                ...state.errors,
                [props.fetchType]: null,
            },
        };
    }),
    on(fapi.fetchPostsError, (state, props) => {
        const error =
            props.fetchType === 'detail' && props.postId !== undefined
                ? buildFetchPostError(
                      props.postId,
                      getFetchPostErrorType(props.error.status as number),
                      props.error,
                  )
                : props.error;
        return {
            ...state,
            loadingInfo: {
                ...state.loadingInfo,
                [props.fetchType]: {
                    ...state.loadingInfo[props.fetchType],
                    isFetching: false,
                },
            },
            errors: {
                ...state.errors,
                [props.fetchType]: error,
            },
        };
    }),
    on(fa.fetchPostsCount, (state, props) => ({
        ...state,
        loadingInfo: {
            ...state.loadingInfo,
            [props.fetchType]: {
                ...state.loadingInfo[props.fetchType],
                counterIsFetching: true,
            },
        },
    })),
    on(fapi.fetchPostsCountSuccess, (state, props) => ({
        ...state,
        loadingInfo: {
            ...state.loadingInfo,
            [props.fetchType]: {
                ...state.loadingInfo[props.fetchType],
                totalCount: props.totalCount,
                counterIsFetching: false,
            },
        },
    })),
    on(fapi.fetchPostsCountError, (state, props) => ({
        ...state,
        loadingInfo: {
            ...state.loadingInfo,
            [props.fetchType]: {
                ...state.loadingInfo[props.fetchType],
                totalCount: null,
                counterIsFetching: false,
            },
        },
    })),

    on(fa.fetchPostsPage, (state, props) => ({
        ...state,
        loadingInfo: {
            ...state.loadingInfo,
            [props.fetchType]: {
                ...state.loadingInfo[props.fetchType],
                isFetching: true,
            },
        },
    })),
    on(fapi.fetchPostsPageSuccess, (state, props) => {
        const [nextPosts, nextFetchInfo] = updatePosts(
            state.allPosts,
            state.fetchInfo,
            props.postList.list,
            props.fetchType,
            true,
        );
        return {
            ...state,
            allPosts: nextPosts,
            fetchInfo: nextFetchInfo,
            loadingInfo: {
                ...state.loadingInfo,
                [props.fetchType]: {
                    isFetching: false,
                    pageTokenInfo: props.postList.nextPageToken
                        ? regularLoading(props.postList.nextPageToken)
                        : lastLoading(),
                    totalCount:
                        props.postList.totalCount ??
                        state.loadingInfo[props.fetchType].totalCount ??
                        null,
                },
            },
        };
    }),
    on(fapi.fetchPostsPageError, (state, props) => ({
        ...state,
        loadingInfo: {
            ...state.loadingInfo,
            [props.fetchType]: {
                ...state.loadingInfo[props.fetchType],
                isFetching: false,
            },
        },
    })),

    on(fapi.changeFiltersSuccess, (state, props) => {
        return {
            ...state,
            previousFilter:
                props.clearPreviousFilter ||
                filterIsEmpty(props.updatedFilters, false)
                    ? null
                    : state.filters[props.fetchType],
            filters: {
                ...state.filters,
                [props.fetchType]: props.updatedFilters,
            },
            loadingInfo: {
                ...state.loadingInfo,
                [props.fetchType]: {
                    isFetching: false,
                    pageTokenInfo: firstLoading(),
                },
            },
            postFetchedCommunities:
                props.fetchType === 'dashboard' && props.updatedFilters
                    ? {
                          ...state.postFetchedCommunities,
                          [props.updatedFilters.communityId ?? 'dashboard']:
                              false,
                      }
                    : state.postFetchedCommunities,
        };
    }),

    on(fa.likeToggle, fapi.likeToggleError, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            const likesCount = post.likedByMe
                ? (post.likesCount ?? 1) - 1
                : (post.likesCount ?? 0) + 1;
            return {
                ...post,
                likedByMe: !post.likedByMe,
                likesCount,
                likersList: {
                    ...post.likersList,
                    list: post.likedByMe
                        ? post.likersList?.list?.filter(
                              (u) => u.id !== props.currUser.id,
                          )
                        : [
                              ...(post.likersList?.list ?? []),
                              { ...props.currUser, timestamp: new Date() },
                          ],
                    totalCount: likesCount,
                },
            };
        }),
    ),

    on(fa.followToggle, fapi.followToggleError, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            followedByMe: !post.followedByMe,
        })),
    ),

    on(fa.participateEvent, (state, props) =>
        modifyPost(state, props.post.id, (post) => {
            if (
                post.tag !== PostType.EVENT ||
                post.participateType === props.typeId
            ) {
                return post;
            }

            const participantsYesIncrement = getEventResponseIncrement(
                props.typeId,
                post.participateType,
                EventParticipateType.YES,
            );
            const nextParticipantsYesCount =
                post.participantsYesCount + participantsYesIncrement;

            const participantsMaybeIncrement = getEventResponseIncrement(
                props.typeId,
                post.participateType,
                EventParticipateType.MAYBE,
            );
            const nextParticipantsMaybeCount =
                post.participantsMaybeCount + participantsMaybeIncrement;

            const participantsNoIncrement: number = getEventResponseIncrement(
                props.typeId,
                post.participateType,
                EventParticipateType.NO,
            );
            const nextParticipantsNoCount =
                post.participantsNoCount + participantsNoIncrement;

            return {
                ...post,
                participateType: props.typeId,
                participantsYesList: {
                    ...post.participantsYesList,
                    totalCount: nextParticipantsYesCount,
                    list:
                        props.typeId === EventParticipateType.YES
                            ? [
                                  ...post.participantsYesList.list,
                                  { ...props.user, timestamp: new Date() },
                              ]
                            : post.participantsYesList.list.filter(
                                  (user) => user.id !== props.user.id,
                              ),
                },
                participantsYesCount: nextParticipantsYesCount,
                participantsMaybeList: {
                    ...post.participantsMaybeList,
                    totalCount: nextParticipantsMaybeCount,
                    list:
                        props.typeId === EventParticipateType.MAYBE
                            ? [
                                  ...post.participantsMaybeList.list,
                                  { ...props.user, timestamp: new Date() },
                              ]
                            : post.participantsMaybeList.list.filter(
                                  (user) => user.id !== props.user.id,
                              ),
                },
                participantsMaybeCount: nextParticipantsMaybeCount,
                participantsNoList: {
                    ...post.participantsNoList,
                    totalCount: nextParticipantsNoCount,
                    list:
                        props.typeId === EventParticipateType.NO
                            ? [
                                  ...post.participantsNoList.list,
                                  { ...props.user, timestamp: new Date() },
                              ]
                            : post.participantsNoList.list.filter(
                                  (user) => user.id !== props.user.id,
                              ),
                },
                participantsNoCount: nextParticipantsNoCount,
                participantsResponseCount:
                    post.participantsResponseCount +
                    participantsYesIncrement +
                    participantsMaybeIncrement +
                    participantsNoIncrement,
            };
        }),
    ),

    on(fapi.participateEventError, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            if (post.tag !== PostType.EVENT) {
                return post;
            }

            return {
                ...post,
                participateType: props.previousParticipateType,
                participantsList: props.previousParticipantsList,
            };
        }),
    ),

    on(fapi.pinToggleSuccess, (state, props) =>
        produce(state, (draft: PostState) => {
            const post = state.allPosts[props.postId];
            const draftPost = draft.allPosts[props.postId];
            const currIsPinned = post?.pinned ?? false;
            if (draftPost) draftPost.pinned = !currIsPinned;

            draft.fetchInfo.dashboard.isStale = true;
        }),
    ),

    on(
        fa.visibilityChange,
        fa.translatePost,
        fa.untranslatePost,
        fa.addWatchers,
        fa.removeWatchers,
        fa.reload,
        fa.fetchInvitationsList,
        (state, props) =>
            modifyPost(state, props.postId, (post) => ({
                ...post,
                isLoading: true,
            })),
    ),
    on(
        fapi.visibilityChangeError,
        fapi.translatePostError,
        fapi.untranslatePostError,
        fapi.addWatchersError,
        fapi.removeWatchersError,
        fapi.reloadError,
        fapi.fetchInvitationsListError,
        fapi.fetchGroupInvitationsListError,
        (state, props) =>
            modifyPost(state, props.postId, (post) => ({
                ...post,
                isLoading: false,
            })),
    ),

    on(fapi.visibilityChangeSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            privateCommunityViewersList:
                props.visibility === VisibilityType.PUBLIC
                    ? emptyPaginatedList()
                    : post.privateCommunityViewersList,
            visibility: props.visibility,
            occToken: props.nextOccToken,
            isLoading: false,
        })),
    ),

    on(fapi.translatePostSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            isLoading: false,
            isTranslated: true,
            title: props.updatedPost.title,
            descriptionDelta: props.updatedPost.descriptionDelta,
            language: props.updatedPost.language,
            lastModifyTimestamp: props.updatedPost.lastModifyTimestamp,
            rightAlignText: props.updatedPost.rightAlignText,
        })),
    ),

    on(fapi.untranslatePostSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            isLoading: false,
            isTranslated: false,
            title: props.updatedPost.title,
            descriptionDelta: props.updatedPost.descriptionDelta,
            language: props.updatedPost.language,
            lastModifyTimestamp: props.updatedPost.lastModifyTimestamp,
            rightAlignText: props.updatedPost.rightAlignText,
        })),
    ),

    on(fapi.editSuccess, (state, props) => {
        return {
            ...modifyPost(state, props.editedPost.id, (post) => ({
                ...post,
                ...props.editedPost,
                isTranslated: false,
            })),
            linkedPosts: initialRelatedPosts(),
        };
    }),

    on(fa.closeSurvey, (state, props) => {
        return {
            ...modifyPost(state, props.postId, (post) => {
                if (isSurveyPost(post)) {
                    const closingTimestamp = new Date();
                    return {
                        ...post,
                        closingTimestamp: closingTimestamp,
                        state: buildSurveyState(
                            post.startAt,
                            post.scheduledClosing,
                            closingTimestamp,
                        ),
                    };
                } else {
                    throw new Error('Closing post must be survey post');
                }
            }),
        };
    }),

    on(fapi.closeSurveySuccess, (state, props) => {
        return {
            ...modifyPost(state, props.postId, (post) => {
                if (isSurveyPost(post)) {
                    return {
                        ...post,
                        closingTimestamp: props.closedTimestamp,
                        occToken: props.nextOccToken,
                        state: buildSurveyState(
                            post.startAt,
                            post.scheduledClosing,
                            props.closedTimestamp,
                        ),
                    };
                } else {
                    throw new Error('Closing post must be survey post');
                }
            }),
        };
    }),

    on(fapi.closeSurveyError, (state, props) => {
        return {
            ...modifyPost(state, props.postId, (post) => {
                if (isSurveyPost(post)) {
                    return {
                        ...post,
                        closingTimestamp: null,
                        state: buildSurveyState(
                            post.startAt,
                            post.scheduledClosing,
                            null,
                        ),
                    };
                } else {
                    throw new Error('Closing post must be survey post');
                }
            }),
        };
    }),

    on(fapi.createSuccess, fapi.copySuccess, (state, props) => {
        const post =
            props.type === fapi.createSuccess.type
                ? props.createdPost
                : props.copiedPost;
        if (post && (post.draft || post.isScheduled)) return state;
        return produce(state, (draft: PostState) => {
            draft.allPosts[post.id] = fromBasePost(post);
            const dashboardCommunityId = state.filters.dashboard.communityId;

            if (isDefined(dashboardCommunityId)) {
                if (dashboardCommunityId === post.communityId) {
                    const [pinnedIds, unpinnedIds] = mPartition(
                        state.fetchInfo.dashboard.fetchOrder,
                        (id) => state.allPosts[id]?.pinned ?? false,
                    );
                    draft.fetchInfo.dashboard.fetchOrder = [
                        ...pinnedIds,
                        post.id,
                        ...unpinnedIds,
                    ];
                }
            } else {
                if (props.showInVisibleCommunities) {
                    draft.fetchInfo.dashboard.fetchOrder = [
                        post.id,
                        ...state.fetchInfo.dashboard.fetchOrder,
                    ];
                }
            }

            if (post.tag === PostType.CUSTOM) {
                const links = post.customFields.data.filter(
                    (field) =>
                        field.configuration.customFieldType ===
                            CustomFieldType.POST_PICKER &&
                        (<Array<{ id: number }> | null>field.inputValue)?.find(
                            (value: any) =>
                                value.id === state.relatedItemsCount.postId,
                        ),
                );

                if (links.length > 0 && draft.relatedItemsCount.value) {
                    const countIdx =
                        draft.relatedItemsCount.value.linkedBy.findIndex(
                            ({ communityId }) =>
                                communityId === post.communityId,
                        );

                    if (countIdx != null && countIdx !== -1) {
                        draft.relatedItemsCount.value.linkedBy[countIdx]
                            .count++;
                    }

                    const linkedPostItem =
                        draft.linkedPosts.items[post.communityId];

                    if (linkedPostItem) {
                        linkedPostItem?.fetchInfo.fetchOrder.push(post.id);
                        linkedPostItem.loadingInfo.totalCount =
                            (linkedPostItem.loadingInfo.totalCount ?? 0) + 1;

                        linkedPostItem.linkedFieldIds[post.id] = {
                            postLinkFieldIds: links.map(
                                (field) => field.configuration.id,
                            ),
                            screenLinkFieldIds: [],
                        };
                    }
                }
            }
        });
    }),

    on(fapi.addWatchersSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            const addedUsers = props.watchers
                .filter(isMemberUser)
                .map((u) => u.user);
            const addedGroups = props.watchers
                .filter(isMemberGroup)
                .map((g) => g.group);
            return {
                ...post,
                watcherUsers: [...addedUsers, ...(post.watcherUsers ?? [])],
                watcherGroups: [...addedGroups, ...(post.watcherGroups ?? [])],
                watchersCount:
                    (post.watcherUsers ?? []).length +
                    (post.watcherGroups ?? []).length +
                    addedUsers.length +
                    addedGroups.length,
                isLoading: false,
            };
        }),
    ),

    on(fapi.removeWatchersSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            const removedUsers = props.watchers
                .filter(isMemberUser)
                .map((u) => u.innerId);
            const removedGroups = props.watchers
                .filter(isMemberGroup)
                .map((g) => g.innerId);

            const updatedUserWatchers = (post.watcherUsers ?? []).filter(
                (u) => !removedUsers.includes(u.id),
            );
            const updatedGroupWatchers = (post.watcherGroups ?? []).filter(
                (g) => !removedGroups.includes(g.id),
            );

            return {
                ...post,
                watcherUsers: updatedUserWatchers,
                watcherGroups: updatedGroupWatchers,
                watchersCount:
                    updatedUserWatchers.length + updatedGroupWatchers.length,
                isLoading: false,
            };
        }),
    ),

    on(fapi.reloadSuccess, (state, props) => ({
        ...modifyPost(state, props.postId, (post) => ({
            ...post,
            ...props.updatedPost,
            attachmentsList: post.attachmentsList,
            mediaList: post.mediaList,
            documentList: post.documentList,
            commentsDocumentList: post.commentsDocumentList,
            commentsMediaList: post.commentsMediaList,
            comments: post.comments,
            isLoading: false,
        })),
        diffPostDetail:
            state.diffPostDetail?.id === props.postId
                ? null
                : state.diffPostDetail,
        diffPosts: state.diffPosts.filter((d) => d.id !== props.postId),
    })),

    on(fa.fetchComments, fa.fetchCommentsUntilId, (state, props) =>
        modifyComments(state, props.postId, (comments) => ({
            ...comments,
            isFetching: true,
        })),
    ),

    on(fapi.fetchCommentsSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            areCommentsStale: false,
            commentsCount: props.commentsList.totalCount,
            comments: props.pageToken
                ? {
                      ...props.commentsList,
                      list: [
                          ...(post.comments.list ?? []),
                          ...props.commentsList.list,
                      ],
                  }
                : props.commentsList,
        })),
    ),
    on(fapi.fetchCommentsError, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
        })),
    ),

    on(fa.fetchStreamingComments, (state, props) =>
        modifyStreamingComments(state, props.postId, (comments) => ({
            ...comments,
            isFetching: true,
        })),
    ),

    on(fapi.fetchStreamingCommentsSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            streamingComments: getStreamingCommentList(
                post as IEventPost,
                props.commentsList,
                props.pageToken,
                props.syncToken,
            ),
        })),
    ),
    on(fapi.fetchStreamingCommentsError, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            streamingComments:
                post.tag === PostType.EVENT
                    ? {
                          ...post.streamingComments,
                          isFetching: false,
                      }
                    : {
                          ...emptyPaginatedList(),
                          nextSyncToken: null,
                      },
        })),
    ),

    on(fa.fetchAttachments, fa.fetchAttachmentsPage, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            attachmentsList:
                !props.attachmentType && props.entityTypes.includes('post')
                    ? {
                          ...emptyAttachmentsList(),
                          ...post.attachmentsList,
                          isLoading: true,
                      }
                    : post.attachmentsList,
            mediaList:
                props.attachmentType === AttachmentCategoryType.MULTIMEDIA &&
                props.entityTypes.includes('post')
                    ? { ...post.mediaList, isLoading: true }
                    : post.mediaList,
            documentList:
                props.attachmentType === AttachmentCategoryType.DOCUMENT &&
                props.entityTypes.includes('post')
                    ? { ...post.documentList, isLoading: true }
                    : post.documentList,
            commentsMediaList:
                props.attachmentType === AttachmentCategoryType.MULTIMEDIA &&
                props.entityTypes.includes('comment')
                    ? {
                          ...emptyAttachmentsList(),
                          ...post.commentsMediaList,
                          isLoading: true,
                      }
                    : post.commentsMediaList,
            commentsDocumentList:
                props.attachmentType === AttachmentCategoryType.DOCUMENT &&
                props.entityTypes.includes('comment')
                    ? {
                          ...emptyAttachmentsList(),
                          ...post.commentsDocumentList,
                          isLoading: true,
                      }
                    : post.commentsDocumentList,
            filePickerMediaList:
                (props.entityTypes.includes('postFilePicker') ||
                    props.entityTypes.includes('screenFilePicker')) &&
                props.attachmentType === AttachmentCategoryType.MULTIMEDIA
                    ? {
                          ...emptyAttachmentsList(),
                          ...post.filePickerMediaList,
                          isLoading: true,
                      }
                    : post.filePickerMediaList,

            filePickerDocumentList:
                (props.entityTypes.includes('postFilePicker') ||
                    props.entityTypes.includes('screenFilePicker')) &&
                props.attachmentType === AttachmentCategoryType.DOCUMENT
                    ? {
                          ...emptyAttachmentsList(),
                          ...post.filePickerDocumentList,
                          isLoading: true,
                      }
                    : post.filePickerDocumentList,
        })),
    ),

    on(
        fapi.fetchAttachmentsError,
        fapi.fetchAttachmentsPageError,
        (state, props) =>
            modifyPost(state, props.postId, (post) => ({
                ...post,
                attachmentsList:
                    !props.attachmentType && post.attachmentsList
                        ? {
                              ...post.attachmentsList,
                              isLoading: false,
                              isError: true,
                          }
                        : post.attachmentsList,
                mediaList:
                    props.attachmentType ===
                        AttachmentCategoryType.MULTIMEDIA &&
                    props.entityTypes.includes('post')
                        ? { ...post.mediaList, isLoading: false, isError: true }
                        : post.mediaList,
                documentList:
                    props.attachmentType === AttachmentCategoryType.DOCUMENT &&
                    props.entityTypes.includes('post')
                        ? {
                              ...post.documentList,
                              isLoading: false,
                              isError: true,
                          }
                        : post.documentList,
                commentsMediaList:
                    props.attachmentType ===
                        AttachmentCategoryType.MULTIMEDIA &&
                    props.entityTypes.includes('comment') &&
                    post.commentsMediaList
                        ? {
                              ...post.commentsMediaList,
                              isLoading: false,
                              isError: true,
                          }
                        : post.commentsMediaList,
                commentsDocumentList:
                    props.attachmentType === AttachmentCategoryType.DOCUMENT &&
                    props.entityTypes.includes('comment') &&
                    post.commentsDocumentList
                        ? {
                              ...post.commentsDocumentList,
                              isLoading: false,
                              isError: true,
                          }
                        : post.commentsDocumentList,
                filePickerMediaList:
                    (props.entityTypes.includes('postFilePicker') ||
                        props.entityTypes.includes('screenFilePicker')) &&
                    props.attachmentType === AttachmentCategoryType.MULTIMEDIA
                        ? {
                              ...post.filePickerMediaList,
                              isLoading: false,
                              isError: true,
                          }
                        : post.filePickerMediaList,
                filePickerDocumentList:
                    (props.entityTypes.includes('postFilePicker') ||
                        props.entityTypes.includes('screenFilePicker')) &&
                    props.attachmentType === AttachmentCategoryType.DOCUMENT
                        ? {
                              ...post.filePickerDocumentList,
                              isLoading: false,
                              isError: true,
                          }
                        : post.filePickerDocumentList,
            })),
    ),

    on(fapi.fetchAttachmentsSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            const updateAttachmentsList = (
                currAttachmentList: IListAttachments,
            ): IListAttachments => ({
                ...currAttachmentList,
                attachments: props.attachmentsList.attachments,
                pageTokenInfo: props.attachmentsList.pageTokenInfo,
                totalCount: isDefined(props.attachmentsList.totalCount)
                    ? props.attachmentsList.totalCount
                    : currAttachmentList.totalCount,
                isLoading: false,
                isError: false,
            });
            return {
                ...post,
                areAttachmentsStale: false,
                attachmentsList:
                    props.entityTypes.includes('post') &&
                    !props.attachmentType &&
                    post.attachmentsList
                        ? updateAttachmentsList(post.attachmentsList)
                        : post.attachmentsList,
                mediaList:
                    props.entityTypes.includes('post') &&
                    props.attachmentType === AttachmentCategoryType.MULTIMEDIA
                        ? updateAttachmentsList(post.mediaList)
                        : post.mediaList,
                documentList:
                    props.entityTypes.includes('post') &&
                    props.attachmentType === AttachmentCategoryType.DOCUMENT
                        ? updateAttachmentsList(post.documentList)
                        : post.documentList,
                commentsMediaList:
                    props.entityTypes.includes('comment') &&
                    props.attachmentType ===
                        AttachmentCategoryType.MULTIMEDIA &&
                    post.commentsMediaList
                        ? updateAttachmentsList(post.commentsMediaList)
                        : post.commentsMediaList,
                commentsDocumentList:
                    props.entityTypes.includes('comment') &&
                    props.attachmentType === AttachmentCategoryType.DOCUMENT &&
                    post.commentsDocumentList
                        ? updateAttachmentsList(post.commentsDocumentList)
                        : post.commentsDocumentList,
                filePickerMediaList:
                    (props.entityTypes.includes('postFilePicker') ||
                        props.entityTypes.includes('screenFilePicker')) &&
                    props.attachmentType === AttachmentCategoryType.MULTIMEDIA
                        ? updateAttachmentsList(post.filePickerMediaList)
                        : post.filePickerMediaList,
                filePickerDocumentList:
                    (props.entityTypes.includes('postFilePicker') ||
                        props.entityTypes.includes('screenFilePicker')) &&
                    props.attachmentType === AttachmentCategoryType.DOCUMENT
                        ? updateAttachmentsList(post.filePickerDocumentList)
                        : post.filePickerDocumentList,
            };
        }),
    ),

    on(fapi.fetchAttachmentsPageSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            const updateAttachmentsList = (
                currAttachmentList: IListAttachments,
            ): IListAttachments => ({
                ...currAttachmentList,
                attachments: [
                    ...currAttachmentList.attachments,
                    ...props.attachmentsList.attachments,
                ],
                pageTokenInfo: props.attachmentsList.pageTokenInfo,
                isLoading: false,
                isError: false,
            });
            return {
                ...post,
                attachmentsList:
                    !props.attachmentType && post.attachmentsList
                        ? updateAttachmentsList(post.attachmentsList)
                        : post.attachmentsList,
                mediaList:
                    props.attachmentType ===
                        AttachmentCategoryType.MULTIMEDIA &&
                    props.entityTypes.includes('post')
                        ? updateAttachmentsList(post.mediaList)
                        : post.mediaList,
                documentList:
                    props.attachmentType === AttachmentCategoryType.DOCUMENT &&
                    props.entityTypes.includes('post')
                        ? updateAttachmentsList(post.documentList)
                        : post.documentList,
                commentsMediaList:
                    props.attachmentType ===
                        AttachmentCategoryType.MULTIMEDIA &&
                    props.entityTypes.includes('comment') &&
                    post.commentsMediaList
                        ? updateAttachmentsList(post.commentsMediaList)
                        : post.commentsMediaList,
                commentsDocumentList:
                    props.attachmentType === AttachmentCategoryType.DOCUMENT &&
                    props.entityTypes.includes('comment') &&
                    post.commentsDocumentList
                        ? updateAttachmentsList(post.commentsDocumentList)
                        : post.commentsDocumentList,
                filePickerMediaList:
                    (props.entityTypes.includes('postFilePicker') ||
                        props.entityTypes.includes('screenFilePicker')) &&
                    props.attachmentType === AttachmentCategoryType.MULTIMEDIA
                        ? updateAttachmentsList(post.filePickerMediaList)
                        : post.filePickerMediaList,
                filePickerDocumentList:
                    (props.entityTypes.includes('postFilePicker') ||
                        props.entityTypes.includes('screenFilePicker')) &&
                    props.attachmentType === AttachmentCategoryType.DOCUMENT
                        ? updateAttachmentsList(post.filePickerDocumentList)
                        : post.filePickerDocumentList,
            };
        }),
    ),

    on(fapi.addAttachmentsSuccess, (state, props) => {
        const allAddedAttachments = props.attachments;
        const addedMedias = allAddedAttachments.filter((a) =>
            isMediaAttachment(a),
        );
        const addedDocs = allAddedAttachments.filter((a) =>
            isFileAttachment(a),
        );

        const updateAttachmentsList = (
            currAttachmentList: IListAttachments,
            addedList: IAttachment[],
        ): IListAttachments => {
            return !getNextPageToken(currAttachmentList.pageTokenInfo)
                ? {
                      ...currAttachmentList,
                      totalCount:
                          currAttachmentList.totalCount + addedList.length,
                      attachments: [
                          ...currAttachmentList.attachments,
                          ...addedList,
                      ],
                  }
                : currAttachmentList;
        };
        return modifyPost(state, props.postId, (post) => ({
            ...post,
            occToken: props.nextOccToken,
            attachmentList: post.attachmentsList
                ? updateAttachmentsList(
                      post.attachmentsList,
                      allAddedAttachments,
                  )
                : post.attachmentsList,
            mediaList: post.mediaList
                ? updateAttachmentsList(post.mediaList, addedMedias)
                : post.mediaList,
            documentList: post.documentList
                ? updateAttachmentsList(post.documentList, addedDocs)
                : post.documentList,
        }));
    }),

    on(fapi.attachmentsEditSuccess, (state, props) => {
        const allUpdatedAttachments = props.attachmentsEdit.updateAttachments;

        const updatedMedia = allUpdatedAttachments.filter((a) =>
            isMediaAttachment(a),
        );
        const updatedDocs = allUpdatedAttachments.filter((a) =>
            isFileAttachment(a),
        );

        return modifyPost(state, props.postId, (post) => ({
            ...post,
            occToken: props.attachmentsEdit.nextOccToken,
            attachmentList: post.attachmentsList
                ? updateAttachmentsList(
                      post.attachmentsList,
                      allUpdatedAttachments,
                  )
                : post.attachmentsList,
            mediaList: post.mediaList
                ? updateAttachmentsList(post.mediaList, updatedMedia)
                : post.mediaList,
            documentList: post.documentList
                ? updateAttachmentsList(post.documentList, updatedDocs)
                : post.documentList,
        }));
    }),

    on(fapi.deleteAttachmentsSuccess, (state, props) => {
        const deletedIdsSet = new Set(props.deletedIds);

        return modifyPost(state, props.postId, (post) => ({
            ...post,
            attachmentsList: post.attachmentsList
                ? attachmentsListFilter(post.attachmentsList, deletedIdsSet)
                : undefined,
            mediaList: attachmentsListFilter(post.mediaList, deletedIdsSet),
            documentList: attachmentsListFilter(
                post.documentList,
                deletedIdsSet,
            ),
            mainAttachment:
                post.mainAttachment &&
                deletedIdsSet.has(post.mainAttachment?.id)
                    ? undefined
                    : post.mainAttachment,
        }));
    }),

    on(fa.deselectAllAttachments, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            mediaList: {
                ...post.mediaList,
                attachments: post.mediaList.attachments.map((a) => ({
                    ...a,
                    selected: false,
                })),
            },
            documentList: {
                ...post.documentList,
                attachments: post.documentList.attachments.map((a) => ({
                    ...a,
                    selected: false,
                })),
            },
            commentsMediaList: {
                ...post.commentsMediaList,
                attachments: (post.commentsMediaList?.attachments || []).map(
                    (a) => ({ ...a, selected: false }),
                ),
            },
            commentsDocumentList: {
                ...post.commentsDocumentList,
                attachments: (post.commentsDocumentList?.attachments || []).map(
                    (a) => ({ ...a, selected: false }),
                ),
            },
        })),
    ),

    on(fapi.markAsViewedSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            viewedByUsersCount: props.viewersCount,
            viewedByMe: true,
            viewedTimestamp: new Date(props.viewedTimestamp),
            viewersList:
                post.viewedByMe ||
                post.viewersList.list.find(
                    (user) => user.id === props.currUser.id,
                )
                    ? post.viewersList
                    : paginatedListAdd(post.viewersList, {
                          ...props.currUser,
                          timestamp: new Date(),
                      } as IUserAction),
        })),
    ),

    on(fa.fetchViewers, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            viewersList: fetchPaginatedList(post.viewersList, props.pageToken),
        })),
    ),
    on(fa.fetchLikers, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            likersList: fetchPaginatedList(post.likersList, props.pageToken),
        })),
    ),
    on(fa.fetchParticipants, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            if (post.tag !== PostType.EVENT) return post;

            return {
                ...post,
                participantsYesList:
                    props.typeId === EventParticipateType.YES
                        ? fetchPaginatedList(
                              post.participantsYesList,
                              props.pageToken,
                          )
                        : post.participantsYesList,
                participantsMaybeList:
                    props.typeId === EventParticipateType.MAYBE
                        ? fetchPaginatedList(
                              post.participantsMaybeList,
                              props.pageToken,
                          )
                        : post.participantsMaybeList,
                participantsNoList:
                    props.typeId === EventParticipateType.NO
                        ? fetchPaginatedList(
                              post.participantsNoList,
                              props.pageToken,
                          )
                        : post.participantsNoList,
            };
        }),
    ),

    on(fapi.fetchViewersError, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            viewersList: fetchPaginatedListError(post.viewersList),
        })),
    ),
    on(fapi.fetchLikersError, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            likersList: fetchPaginatedList(post.likersList, props.pageToken),
        })),
    ),
    on(fapi.fetchParticipantsError, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            if (post.tag !== PostType.EVENT) return post;

            return {
                ...post,
                participantsYesList:
                    props.typeId === EventParticipateType.YES
                        ? fetchPaginatedList(
                              post.participantsYesList,
                              props.pageToken,
                          )
                        : post.participantsYesList,
                participantsMaybeList:
                    props.typeId === EventParticipateType.MAYBE
                        ? fetchPaginatedList(
                              post.participantsMaybeList,
                              props.pageToken,
                          )
                        : post.participantsMaybeList,
                participantsNoList:
                    props.typeId === EventParticipateType.NO
                        ? fetchPaginatedList(
                              post.participantsNoList,
                              props.pageToken,
                          )
                        : post.participantsNoList,
            };
        }),
    ),

    on(fapi.fetchViewersSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            viewersList: fetchPaginatedListSuccess(
                post.viewersList,
                props.viewersList,
            ),
        })),
    ),
    on(fapi.fetchLikersSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            likersList: fetchPaginatedListSuccess(
                post.likersList,
                props.likersList,
            ),
        })),
    ),
    on(fapi.fetchParticipantsSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            if (post.tag !== PostType.EVENT) return post;

            const participantsYesCount =
                props.typeId === EventParticipateType.YES &&
                !props.name &&
                !props.pageToken
                    ? props.participantsList.totalCount
                    : post.participantsYesCount;
            const participantsNoCount =
                props.typeId === EventParticipateType.NO &&
                !props.name &&
                !props.pageToken
                    ? props.participantsList.totalCount
                    : post.participantsNoCount;
            const participantsMaybeCount =
                props.typeId === EventParticipateType.MAYBE &&
                !props.name &&
                !props.pageToken
                    ? props.participantsList.totalCount
                    : post.participantsMaybeCount;

            return {
                ...post,
                participantsYesList: {
                    ...(props.typeId === EventParticipateType.YES
                        ? fetchPaginatedListSuccess(
                              post.participantsYesList,
                              props.participantsList,
                          )
                        : post.participantsYesList),
                    totalCount:
                        props.typeId === EventParticipateType.YES &&
                        !props.name &&
                        !props.pageToken
                            ? props.participantsList.totalCount
                            : post.participantsYesList.totalCount,
                },
                participantsYesCount,
                participantsMaybeList: {
                    ...(props.typeId === EventParticipateType.MAYBE
                        ? fetchPaginatedListSuccess(
                              post.participantsMaybeList,
                              props.participantsList,
                          )
                        : post.participantsMaybeList),
                    totalCount:
                        props.typeId === EventParticipateType.MAYBE &&
                        !props.name &&
                        !props.pageToken
                            ? props.participantsList.totalCount
                            : post.participantsMaybeList.totalCount,
                },
                participantsMaybeCount,
                participantsNoList: {
                    ...(props.typeId === EventParticipateType.NO
                        ? fetchPaginatedListSuccess(
                              post.participantsNoList,
                              props.participantsList,
                          )
                        : post.participantsNoList),
                    totalCount:
                        props.typeId === EventParticipateType.NO &&
                        !props.name &&
                        !props.pageToken
                            ? props.participantsList.totalCount
                            : post.participantsNoList.totalCount,
                },
                participantsNoCount,
                participantsResponseCount:
                    participantsYesCount +
                    participantsNoCount +
                    participantsMaybeCount,
            };
        }),
    ),

    on(fapi.deletePostSuccess, fa.removePost, (state, props) =>
        produce(state, (draft: PostState) => {
            delete draft.allPosts[props.postId];
            (Object.keys(draft.fetchInfo) as Array<PostFetch>).forEach(
                (postFetch) => {
                    const prevFetchOrderCount =
                        draft.fetchInfo[postFetch].fetchOrder.length;
                    draft.fetchInfo[postFetch].fetchOrder = draft.fetchInfo[
                        postFetch
                    ].fetchOrder?.filter((id) => id != props.postId);
                    const nextFetchOrderCount =
                        draft.fetchInfo[postFetch].fetchOrder.length;
                    const decrement = prevFetchOrderCount - nextFetchOrderCount;
                    const actualLoadingInfoCount: number | null =
                        draft.loadingInfo[postFetch].totalCount;
                    draft.loadingInfo[postFetch].totalCount =
                        actualLoadingInfoCount && decrement > 0
                            ? actualLoadingInfoCount - decrement
                            : actualLoadingInfoCount;
                },
            );
        }),
    ),

    on(fapi.massiveDeletePostsSuccess, (state, props) =>
        produce(state, (draft: PostState) => {
            props.postIds.forEach((postId) => {
                delete draft.allPosts[postId];
                (Object.keys(draft.fetchInfo) as Array<PostFetch>).forEach(
                    (postFetch) => {
                        const prevFetchOrderCount =
                            draft.fetchInfo[postFetch].fetchOrder.length;
                        draft.fetchInfo[postFetch].fetchOrder = draft.fetchInfo[
                            postFetch
                        ].fetchOrder?.filter((id) => id != postId);
                        const nextFetchOrderCount =
                            draft.fetchInfo[postFetch].fetchOrder.length;
                        const decrement =
                            prevFetchOrderCount - nextFetchOrderCount;
                        const actualLoadingInfoCount: number | null =
                            draft.loadingInfo[postFetch].totalCount;
                        draft.loadingInfo[postFetch].totalCount =
                            actualLoadingInfoCount && decrement > 0
                                ? actualLoadingInfoCount - decrement
                                : actualLoadingInfoCount;
                    },
                );
            });
        }),
    ),

    on(fapi.fetchLinksAndRelationsCountSuccess, (state, props) => ({
        ...state,
        relatedItemsCount: {
            postId: props.postId,
            value: props.linksAndRelationsCount,
        },
    })),

    on(fa.fetchLinkedPosts, (state, props) =>
        produce(state, (draft: PostState) => {
            if (state.linkedPosts.postId !== props.postId) {
                draft.linkedPosts = initialRelatedPosts();
                draft.linkedPosts.postId = props.postId;
            }

            if (!draft.linkedPosts.items[props.communityId]) {
                draft.linkedPosts.items[props.communityId] =
                    initRelatedPostsData();
            }

            draft.linkedPosts.items[props.communityId].loadingInfo.isFetching =
                true;
        }),
    ),

    on(fapi.fetchLinkedPostsSuccess, (state, props) =>
        produce(state, (draft: PostState) => {
            draft.allPosts = updatePostsMap(
                draft.allPosts,
                postLinkAndRelationToCustomPost(
                    props.items.posts.list,
                    draft.allPosts,
                ),
            );

            const linkedItem = draft.linkedPosts.items[props.items.communityId];
            const newPostsIds = mapArrayById(props.items.posts.list) ?? [];

            linkedItem.fetchInfo.isStale = false;
            linkedItem.fetchInfo.lastFetchTime = Date.now();
            linkedItem.fetchInfo.fetchOrder =
                props.fetchPageToken.tag === firstLoading().tag
                    ? newPostsIds
                    : [...linkedItem.fetchInfo.fetchOrder, ...newPostsIds];

            linkedItem.loadingInfo.isFetching = false;
            linkedItem.loadingInfo.totalCount = props.items.posts.totalCount;
            linkedItem.loadingInfo.pageTokenInfo =
                props.items.posts.nextPageToken;

            props.items.posts.list.forEach((post) => {
                if (!linkedItem.linkedFieldIds[post.id]) {
                    linkedItem.linkedFieldIds[post.id] = {
                        postLinkFieldIds: [],
                        screenLinkFieldIds: [],
                    };
                }

                linkedItem.linkedFieldIds[post.id].postLinkFieldIds =
                    post.postLinkFieldIds;
                linkedItem.linkedFieldIds[post.id].screenLinkFieldIds =
                    post.screenLinkFieldIds;
            });
        }),
    ),

    on(fa.addLinkedPosts, (state, props) =>
        produce(state, (draft) => {
            const communityIds = new Set(
                props.posts.map((post) => post.communityId),
            );

            communityIds.forEach((communityId) =>
                setLinkedListFetching(draft, communityId, true),
            );
        }),
    ),

    on(fapi.addLinkedPostsError, (state, props) =>
        produce(state, (draft) => {
            const communityIds = new Set(
                props.posts.map((post) => post.communityId),
            );

            communityIds.forEach((communityId) =>
                setLinkedListFetching(draft, communityId, false),
            );
        }),
    ),

    on(fa.removePostsFromRelations, (state, props) =>
        produce(state, (draft) =>
            setLinkedListFetching(draft, props.post.communityId, true),
        ),
    ),

    on(fapi.removePostsFromRelationsError, (state, props) =>
        produce(state, (draft) =>
            setLinkedListFetching(draft, props.post.communityId, false),
        ),
    ),

    on(fapi.addLinkedPostsSuccess, (state, props) => {
        const communityId = props.posts[0].communityId;
        const linksAndRelationsList = state.linkedPosts.items[communityId];

        const occTokensMap = new Map<number, number>();

        for (const link of [
            ...props.linksSuccess,
            ...props.linksErrorValidation,
            ...props.linksErrorConcurrency,
        ]) {
            if (link.occToken != null) {
                occTokensMap.set(link.id, link.occToken);
            }
        }

        const successPostsIds = new Set(
            props.linksSuccess.map((link) => link.id),
        );

        const successPosts = props.posts.filter((post) =>
            successPostsIds.has(post.id),
        );

        return produce(state, (draft) => {
            successPosts.forEach((post) => {
                const draftPost = draft.allPosts[post.id];

                if (draftPost?.tag === PostType.CUSTOM) {
                    const fields = [props.field];

                    for (const field of fields) {
                        draftPost.customFields = draftPost.customFields
                            .changeValueForPostLink(field.id, [
                                props.linkedPost,
                            ])
                            .clone();
                    }

                    draftPost.occToken = occTokensMap.get(post.id);
                }
            });

            if (draft.relatedItemsCount.value) {
                const draftLinksCount =
                    draft.relatedItemsCount.value.linkedBy.find(
                        (link) => link.communityId === communityId,
                    );

                if (draftLinksCount)
                    draftLinksCount.count += props.posts.length;
            }

            if (linksAndRelationsList == null) {
                draft.linkedPosts.items[communityId] = initRelatedPostsData();
            }

            const linkList = draft.linkedPosts.items[communityId];
            linkList.loadingInfo.isFetching = false;
            successPosts.forEach((post) => {
                if (!linkList.fetchInfo.fetchOrder.includes(post.id)) {
                    linkList.fetchInfo.fetchOrder.push(post.id);

                    linkList.linkedFieldIds[post.id] = {
                        postLinkFieldIds: post.postLinkFieldIds,
                        screenLinkFieldIds: post.screenLinkFieldIds,
                    };
                }
            });
        });
    }),

    on(fapi.removePostsFromRelationsSuccess, (state, props) => {
        const communityId = props.post.communityId;
        const linksAndRelationsList = state.linkedPosts.items[communityId];
        const isRemovingLastItem =
            linksAndRelationsList?.loadingInfo.totalCount === 1;

        return produce(state, (draft) => {
            const draftPost = draft.allPosts[props.post.id];

            if (draftPost?.tag === PostType.CUSTOM) {
                const fields = props.fieldsDefinition;

                for (const field of fields) {
                    draftPost.customFields = draftPost.customFields
                        .changeValueForPostLink(field.id, null)
                        .clone();
                }

                draftPost.occToken = props.nextOccToken;
            }

            if (draft.relatedItemsCount.value) {
                const draftLinksCount =
                    draft.relatedItemsCount.value.linkedBy.find(
                        (link) => link.communityId === communityId,
                    );

                if (draftLinksCount) draftLinksCount.count -= 1;
            }

            const linkList = draft.linkedPosts.items[communityId];
            if (isRemovingLastItem) {
                draft.linkedPosts.items[communityId] = initRelatedPostsData();
                return;
            }

            const idx = linkList.fetchInfo.fetchOrder.findIndex(
                (i) => i === props.post.id,
            );
            if (idx !== -1) {
                linkList.fetchInfo.fetchOrder.splice(idx, 1);
                linkList.loadingInfo.totalCount =
                    (linkList.loadingInfo.totalCount ?? 1) - 1;
            }
            linkList.loadingInfo.isFetching = false;
        });
    }),

    on(fapi.confirmAcknowledgeTaskSuccess, (state, props) =>
        produce(state, (draft) => {
            const post = draft.allPosts[props.postId];
            const confirmedTasks = props.response.confirmedTasks.length;
            if (post) {
                if (
                    post.acknowledgeTaskSummary?.assignedToMeExpiringTasksCount
                ) {
                    post.acknowledgeTaskSummary.assignedToMeExpiringTasksCount -=
                        confirmedTasks;
                }
                if (
                    isDefined(post.acknowledgeTaskSummary) &&
                    isDefined(post.acknowledgeTaskSummary.openTasksCount)
                ) {
                    post.acknowledgeTaskSummary.openTasksCount -=
                        confirmedTasks;
                }
            }
        }),
    ),

    on(fapi.confirmSurveyTaskSuccess, (state, props) =>
        produce(state, (draft) => {
            const post = draft.allPosts[props.postId];
            const confirmedTasks = props.response.confirmedTasks.length;
            if (post) {
                if (post.surveyTaskSummary?.assignedToMeExpiringTasksCount) {
                    post.surveyTaskSummary.assignedToMeExpiringTasksCount -=
                        confirmedTasks;
                }
                if (
                    isDefined(post.surveyTaskSummary) &&
                    isDefined(post.surveyTaskSummary.openTasksCount)
                ) {
                    post.surveyTaskSummary.openTasksCount -= confirmedTasks;
                }
            }
        }),
    ),

    on(fa.updateAcknowledgeCounters, (state, props) => {
        return produce(state, (draft) => {
            const draftPost = draft.allPosts[props.postId];
            if (draftPost && draftPost.acknowledgeTaskSummary) {
                draftPost.acknowledgeTaskSummary.openTasksCount =
                    props.openTasksCount;
                draftPost.acknowledgeTaskSummary.assignedToMeExpiringTasksCount =
                    props.assignedToMeExpiringTasksCount;
                draftPost.acknowledgeTaskSummary.assignedToMeExpiredTasksCount =
                    props.assignedToMeExpiredTasksCount;
                draftPost.acknowledgeTaskSummary.assignedToMeMinTasksExpirationDate =
                    props.assignedToMeMinTasksExpirationDate;
            }
        });
    }),

    on(fa.updateSurveyCounters, (state, props) => {
        return produce(state, (draft) => {
            const draftPost = draft.allPosts[props.postId];
            if (draftPost && draftPost.surveyTaskSummary) {
                draftPost.surveyTaskSummary.assignedToMeTasksCount =
                    props.assignedToMeTasksCount;
                draftPost.surveyTaskSummary.assignedToMeExpiringTasksCount =
                    props.assignedToMeExpiringTasksCount;
                draftPost.surveyTaskSummary.assignedToMeExpiredTasksCount =
                    props.assignedToMeExpiredTasksCount;

                if (props.openTasksCount != null) {
                    draftPost.surveyTaskSummary.openTasksCount =
                        props.openTasksCount;
                }

                if (props.totalTasksCount != null) {
                    draftPost.surveyTaskSummary.totalTasksCount =
                        props.totalTasksCount;
                }

                if (props.assignedToMeMinTasksExpirationDate != null) {
                    draftPost.surveyTaskSummary.assignedToMeMinTasksExpirationDate =
                        props.assignedToMeMinTasksExpirationDate;
                }
            }
        });
    }),

    on(fa.updateMyExpiringAcknowledgeCounter, (state, props) => {
        return produce(state, (draft) => {
            const draftPost = draft.allPosts[props.postId];
            if (draftPost && draftPost.acknowledgeTaskSummary)
                draftPost.acknowledgeTaskSummary.assignedToMeExpiringTasksCount =
                    props.assignedToMeExpiringTasksCount;
        });
    }),

    on(fa.likeToggleComment, fapi.likeToggleCommentError, (state, props) =>
        modifyComments(state, props.postId, (comments) => ({
            ...comments,
            list:
                comments.list.map((c) =>
                    c.id === props.comment.id
                        ? {
                              ...c,
                              likedByMe: !c.likedByMe,
                              likesCount:
                                  props.type ===
                                  fapi.likeToggleCommentError.type
                                      ? props.comment.likesCount
                                      : c.likedByMe
                                        ? (props.comment.likesCount ?? 0) - 1
                                        : (props.comment.likesCount ?? 0) + 1,
                          }
                        : c,
                ) ?? [],
        })),
    ),

    on(fa.deleteComment, (state, props) =>
        modifyComments(state, props.postId, (comments) => {
            const list = comments.list
                .filter((c) => c.id !== props.comment.id)
                .map((c) =>
                    c.parentComment?.id === props.comment.id
                        ? {
                              ...c,
                              parentComment: {
                                  ...c.parentComment,
                                  deleted: true,
                              },
                          }
                        : c,
                );
            return {
                ...comments,
                totalCount:
                    (comments.totalCount ?? 0) +
                    list.length -
                    comments.list.length,
                list,
            };
        }),
    ),

    on(fapi.deleteCommentError, (state, props) =>
        modifyComments(state, props.postId, (comments) => ({
            ...comments,
            list: produce(comments.list, (draft) => {
                draft.splice(props.commentIdx, 0, castDraft(props.comment));
            }),
            commentsCount: comments.totalCount ? comments.totalCount + 1 : 1,
        })),
    ),

    on(fapi.deleteCommentSuccess, (state, props) => {
        const documentsIds = attachmentsFiles(props.comment.attachments).map(
            (a) => a.id,
        );
        const mediaIds = attachmentsMedia(props.comment.attachments).map(
            (a) => a.id,
        );

        const nextState = modifyPost(state, props.postId, (post) =>
            // Remove the draft if we are editing the deleted comment
            ({
                ...post,
                commentDraft:
                    post.commentDraft.comment?.id === props.comment.id
                        ? { isEditing: false }
                        : post.commentDraft,
                commentsDocumentList: {
                    ...post.commentsDocumentList,
                    attachments: post.commentsDocumentList.attachments.filter(
                        (a) => !documentsIds.includes(a.id),
                    ),
                    totalCount:
                        post.commentsDocumentList.totalCount -
                        documentsIds.length,
                },
                commentsMediaList: {
                    ...post.commentsMediaList,
                    attachments: post.commentsMediaList.attachments.filter(
                        (a) => !mediaIds.includes(a.id),
                    ),
                    totalCount:
                        post.commentsMediaList.totalCount - mediaIds.length,
                },
                visibleCommentsCountDashboard: Math.max(
                    2,
                    Math.min(
                        post.visibleCommentsCountDashboard ?? 0,
                        post.commentsCount ?? 0,
                    ),
                ),
                visibleCommentsCountDetail: Math.max(
                    2,
                    Math.min(
                        post.visibleCommentsCountDetail ?? 0,
                        post.commentsCount ?? 0,
                    ),
                ),
            }),
        );
        return nextState;
    }),

    on(fapi.translateCommentSuccess, (state, props) =>
        modifyComment(state, props.postId, props.comment.id, (comment) => ({
            ...comment,
            ...props.translatedComment,
        })),
    ),

    on(fapi.untranslateCommentSuccess, (state, props) =>
        modifyComment(state, props.postId, props.comment.id, (comment) => ({
            ...comment,
            ...props.untranslatedComment,
        })),
    ),

    on(
        fa.addComment,
        fa.addStreamingComment,
        fapi.addCommentError,
        (state, props) =>
            modifyPost(state, props.postId, (post) => ({
                ...post,
                isCreatingComment: props.type === fa.addComment.type,
            })),
    ),

    on(fapi.addStreamingCommentSuccess, (state, props) => {
        let nextState = modifyStreamingComments(
            state,
            props.postId,
            (comments) => ({
                ...comments,
                totalCount: (comments.totalCount ?? 0) + 1,
                list: [props.createdComment, ...comments.list],
            }),
        );

        //IISP-5372 i commenti streaming non possono contenere allegati
        nextState = modifyPost(nextState, props.postId, (post) => ({
            ...post,
            isCreatingComment: false,
            commentDraft: {
                isEditing: false,
            },
            followedByMe: props.followedByMe || post.followedByMe,
            commentsCount: post.commentsCount,
        }));
        return nextState;
    }),

    on(fapi.addCommentSuccess, (state, props) => {
        let nextState = modifyComments(state, props.postId, (comments) => ({
            ...comments,
            totalCount: (comments.totalCount ?? 0) + 1,
            list: [props.createdComment, ...comments.list],
        }));

        const [medias, documents] = mPartition(
            props.createdComment.attachments,
            isMediaAttachment,
        );

        nextState = modifyPost(nextState, props.postId, (post) => ({
            ...post,
            isCreatingComment: false,
            commentDraft: {
                isEditing: false,
            },
            followedByMe: props.followedByMe || post.followedByMe,
            commentsCount: post.commentsCount,
            visibleCommentsCountDetail:
                props.location === 'detail'
                    ? Math.max(
                          Math.min(
                              (post.visibleCommentsCountDetail ?? 0) + 1,
                              post.commentsCount ?? 0,
                          ),
                          2,
                      )
                    : post.visibleCommentsCountDetail ?? 2,
            visibleCommentsCountDashboard:
                props.location === 'dashboard'
                    ? Math.max(
                          Math.min(
                              (post.visibleCommentsCountDashboard ?? 0) + 1,
                              post.commentsCount ?? 0,
                          ),
                          2,
                      )
                    : post.visibleCommentsCountDashboard ?? 2,
            commentsMediaList: {
                ...post.commentsMediaList,
                attachments:
                    post.commentsMediaList.pageTokenInfo.tag ===
                        'lastLoading' ||
                    (post.commentsMediaList.pageTokenInfo.tag ===
                        'firstLoading' &&
                        post.commentsMediaList.totalCount === 0)
                        ? [...post.commentsMediaList.attachments, ...medias]
                        : [...post.commentsMediaList.attachments],
                totalCount: post.commentsMediaList.totalCount + medias.length,
            },
            commentsDocumentList: {
                ...post.commentsDocumentList,
                attachments:
                    post.commentsDocumentList.pageTokenInfo.tag ===
                        'lastLoading' ||
                    (post.commentsDocumentList.pageTokenInfo.tag ===
                        'firstLoading' &&
                        post.commentsDocumentList.totalCount === 0)
                        ? [
                              ...post.commentsDocumentList.attachments,
                              ...documents,
                          ]
                        : [...post.commentsDocumentList.attachments],
                totalCount:
                    post.commentsDocumentList.totalCount + documents.length,
            },
        }));
        return nextState;
    }),

    on(fa.replyComment, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            commentDraft: post.commentDraft.comment?.id
                ? post.commentDraft
                : {
                      ...post.commentDraft,
                      isEditing: true,
                      parentComment: props.parentComment,
                  },
        })),
    ),

    on(fa.editComment, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            commentDraft: {
                isEditing: true,
                parentComment: props.comment?.parentComment,
                comment: props.comment,
            },
        })),
    ),

    on(fa.saveEditComment, fapi.saveEditCommentError, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            isCreatingComment: props.type === fa.saveEditComment.type,
        })),
    ),

    on(fapi.saveEditCommentSuccess, (state, props) => {
        let nextState = modifyComments(state, props.postId, (comments) => ({
            ...comments,
            list: comments.list.map((c) =>
                c.id === props.updatedComment.id
                    ? {
                          ...props.updatedComment,
                          customFieldInfo: c.customFieldInfo,
                      }
                    : c,
            ),
        }));

        nextState = modifyPost(nextState, props.postId, (post) => {
            const [addedMedias, addedDocuments] = mPartition(
                props.updatedComment.addedAttachments ?? [],
                isMediaAttachment,
            );
            const addedMediasCount = addedMedias.length;
            const addedDocumentsCount = addedDocuments.length;

            const removedDocumentsCount =
                post.commentsDocumentList.attachments.filter((d) =>
                    props.updatedComment.removedAttachmentIds?.includes(d.id),
                ).length;
            const removedMediasCount =
                post.commentsMediaList.attachments.filter((d) =>
                    props.updatedComment.removedAttachmentIds?.includes(d.id),
                ).length;

            const state = {
                ...post,
                isCreatingComment: false,
                commentDraft: {
                    isEditing: false,
                },
                commentsDocumentList: {
                    ...post.commentsDocumentList,
                    attachments: [
                        ...post.commentsDocumentList.attachments.filter(
                            (a) =>
                                !props.updatedComment.removedAttachmentIds?.includes(
                                    a.id,
                                ),
                        ),
                        ...addedDocuments,
                    ],
                    totalCount:
                        post.commentsDocumentList.totalCount +
                        addedDocumentsCount -
                        removedDocumentsCount,
                },
                commentsMediaList: {
                    ...post.commentsMediaList,
                    attachments: [
                        ...post.commentsMediaList.attachments.filter(
                            (a) =>
                                !props.updatedComment.removedAttachmentIds?.includes(
                                    a.id,
                                ),
                        ),
                        ...addedMedias,
                    ],
                    totalCount:
                        post.commentsMediaList.totalCount +
                        addedMediasCount -
                        removedMediasCount,
                },
            };
            return state;
        });

        return nextState;
    }),

    on(fa.saveCommentDraft, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            commentDraft: props.draft,
        })),
    ),

    on(fa.openDraftComment, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            commentDraft: {
                ...post.commentDraft,
                isEditing: true,
            },
        })),
    ),

    on(fa.closeDraftComment, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            commentDraft: {
                isEditing: false,
                comment: DeltaUtility.fromString(''),
            },
        })),
    ),

    on(fa.fetchWorkflowHistory, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            workflowHistory: {
                ...emptyPaginatedList(),
                ...post.workflowHistory,
                isFetching: true,
            },
        })),
    ),

    on(fapi.fetchWorkflowHistorySuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            workflowHistory:
                post.tag === PostType.CUSTOM
                    ? {
                          ...post.workflowHistory,
                          list: toWorkflowHistoryItem(
                              props.workflowHistory.list.reverse(),
                              props.prevPageToken && post.workflowHistory
                                  ? post.workflowHistory.list
                                  : [],
                              props.workflowHistory.nextPageToken,
                              post,
                          ),
                          nextPageToken: props.workflowHistory.nextPageToken,
                          isFetching: false,
                          totalCount:
                              post.workflowHistory?.totalCount ||
                              props.workflowHistory.totalCount,
                      }
                    : undefined,
        })),
    ),

    on(fa.fetchCommentLikers, fapi.fetchCommentLikersError, (state, props) =>
        modifyComments(state, props.postId, (comments) =>
            produce(comments, (draft) => {
                const comment = draft.list.find(
                    (c) => c.id === props.commentId,
                );

                if (!comment) return;

                comment.likersList =
                    props.type === fa.fetchCommentLikers.type
                        ? fetchPaginatedList(
                              comment.likersList,
                              props.pageToken,
                          )
                        : fetchPaginatedListError(comment.likersList);
            }),
        ),
    ),

    on(fapi.fetchCommentLikersSuccess, (state, props) =>
        modifyComments(state, props.postId, (comments) =>
            produce(comments, (draft) => {
                const comment = draft.list.find(
                    (c) => c.id === props.commentId,
                );

                if (!comment) return;

                comment.likersList = fetchPaginatedListSuccess(
                    comment.likersList,
                    props.likersList,
                );
            }),
        ),
    ),

    on(fapi.editWorkflowScreenSuccess, (state, props) =>
        modifyPost(state, props.post.id, (post) => {
            if (post.tag !== PostType.CUSTOM) {
                return post;
            }

            return {
                ...post,
                customFieldsScreen: toScreenCustomFields(
                    {
                        ...post.customFieldsScreen.serverFieldsMap,
                        ...props.workflowChange.newScreenData,
                    },
                    post.metadata,
                ),
                capabilities:
                    post.capabilities != null
                        ? {
                              ...post.capabilities,
                              workflowPermittedOperations:
                                  props.workflowChange
                                      .newCurrentWorkflowPermittedOperations ??
                                  post.capabilities
                                      ?.workflowPermittedOperations,
                          }
                        : post.capabilities,
            };
        }),
    ),

    on(fapi.workflowTransitionSuccess, (state, props) =>
        modifyPost(state, props.post.id, (post) => {
            if (post.tag !== PostType.CUSTOM) {
                return post;
            }

            if (!post.capabilities) {
                console.warn(
                    'Missing post.capabilities in workflowTransitionSuccess',
                );
                return post;
            }

            if (!post.metadata) {
                console.warn(
                    'Missing post.metadata in workflowTransitionSuccess',
                );
                return post;
            }

            return {
                ...post,
                currentWorkflowState: props.workflowChange.newCurrentState,
                customFieldsScreen: toScreenCustomFields(
                    {
                        ...post.customFieldsScreen.serverFieldsMap,
                        ...props.workflowChange.newScreenData,
                    },
                    post.metadata,
                ),
                capabilities: {
                    ...post.capabilities,
                    workflowPermittedOperations:
                        props.workflowChange
                            .newCurrentWorkflowPermittedOperations ??
                        post.capabilities.workflowPermittedOperations,
                    canEditWorkflowScreenData:
                        props.workflowChange.newCanEditWorkflowScreenData ??
                        post.capabilities.canEditWorkflowScreenData,
                },
            };
        }),
    ),

    on(fapi.fetchVisibilityCountersSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            watchersCount: props.counters.watchersCount ?? 0,
            taskAssigneesList: {
                ...post.taskAssigneesList,
                list: [],
                totalCount: props.counters.taskAssigneesCount ?? 0,
                nextPageToken: firstLoading(),
            },
            ackTaskAssigneeList: {
                ...post.ackTaskAssigneeList,
                list: [],
                totalCount: props.counters.ackTaskAssigneesCount ?? 0,
                nextPageToken: firstLoading(),
            },
            invitationsList: {
                ...post.invitationsList,
                list: [],
                totalCount: props.counters.invitationsCount ?? 0,
                nextPageToken: firstLoading(),
            },
            privateCommunityViewersCount:
                props.counters.viewPrivatePostCapabilityCount ?? 0,
            extendedVisibilityList: {
                ...post.extendedVisibilityList,
                totalCount: props.counters.extendedVisibilityCount ?? 0,
            },
            mentionsCount: props.counters.mentionsCount ?? 0,
            ...(post.tag === PostType.SURVEY
                ? {
                      answerTaskAssigneeList: {
                          ...post.answerTaskAssigneeList,
                          list: [],
                          totalCount:
                              props.counters.surveyTaskAssigneesCount +
                              props.counters.feedbackTaskAssigneesCount,
                          nextPageToken: firstLoading(),
                          isFetching: false,
                      },
                      managersCount:
                          props.counters.surveyManagersCount +
                          props.counters.feedbackManagersCount,
                      answerViewersCount:
                          props.counters.surveyAnswerViewersCount +
                          props.counters.feedbackAnswerViewersCount,
                  }
                : undefined),
        })),
    ),

    on(fa.fetchInvitationsList, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            invitationsList: {
                ...post.invitationsList,
                ...fetchPaginatedList(
                    post.invitationsList ?? emptyPaginatedList(0),
                    props.pageToken ?? undefined,
                ),
            },
        })),
    ),

    on(fapi.fetchInvitationsListSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            isLoading: false,
            invitationsList: {
                ...post.invitationsList,
                ...fetchPaginatedListSuccess(
                    post.invitationsList,
                    props.members,
                ),
                participantsResponseCountByTypeMap:
                    props.members.participantsResponseCountByTypeMap,
            },
        })),
    ),

    on(fapi.fetchInvitationsListError, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            invitationsList: {
                ...post.invitationsList,
                isFetching: false,
            },
        })),
    ),

    on(fa.editInvitationsList, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            invitationsList: {
                ...post.invitationsList,
                isFetching: true,
            },
        })),
    ),

    on(fapi.editInvitationsListSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            if (post.tag !== PostType.EVENT) {
                return post;
            }

            const list: Invitation[] = post.invitationsList.list.filter((m) => {
                return (
                    (isMemberUser(m) &&
                        !props.params.removeUserIds?.includes(m.innerId)) ||
                    (isMemberGroup(m) &&
                        !props.params.removeGroupIds?.includes(m.innerId))
                );
            });
            props.params.addInvitations?.forEach((m) => list.push(m));

            return {
                ...post,
                isLoading: false,
                invitationsList: {
                    ...post.invitationsList,
                    list,
                    isFetching: false,
                },
            };
        }),
    ),

    on(fapi.editInvitationsListError, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            invitationsList: {
                ...post.invitationsList,
                isFetching: false,
            },
        })),
    ),

    on(fa.fetchGroupInvitationsList, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            invitationsList: {
                ...post.invitationsList,
                isFetching: true,
            },
        })),
    ),

    on(fapi.fetchGroupInvitationsListSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            const invitationList = post.invitationsList.list;
            const group = invitationList[
                invitationList.findIndex((m) => m.innerId === props.groupId)
            ] as InvitationGroup;
            group.participants = props.members.list;
            return {
                ...post,
                invitationsList: {
                    ...post.invitationsList,
                    list: invitationList,
                    isFetching: false,
                },
            };
        }),
    ),

    on(fapi.fetchGroupInvitationsListError, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            invitationsList: {
                ...post.invitationsList,
                isFetching: false,
            },
        })),
    ),

    on(fa.fetchCommunityViewersList, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            privateCommunityViewersList: {
                ...post.privateCommunityViewersList,
                isFetching: true,
            },
        })),
    ),

    on(fapi.fetchCommunityViewersListSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            privateCommunityViewersList: {
                ...props.members,
                list: [
                    ...(props.filters?.pageToken != null
                        ? post.privateCommunityViewersList?.list ?? []
                        : []),
                    ...(props.members?.list || []),
                ],
            },
        })),
    ),

    on(fapi.fetchMentionsListSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            memberMentions: {
                ...(post.memberMentions ?? emptyPaginatedList<Member>()),
                list: [...props.members.list],
                nextPageToken: props.members.nextPageToken,
            },
        })),
    ),

    on(fapi.fetchOthersVisibilityListSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            extendedVisibilityList: {
                ...post.extendedVisibilityList,
                ackTaskScreenFields: props.members.ackTaskScreenFields,
                customVisibilityMembers: props.members.customVisibilityMembers,
                postFields: props.members.postFields,
                workflowScreenFields: props.members.workflowScreenFields,
            },
        })),
    ),

    on(fa.fetchTasksAssigneesList, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            taskAssigneesList: {
                ...post.taskAssigneesList,
                isFetching: props.taskType === ITaskType.STANDARD,
            },
            ackTaskAssigneeList: {
                ...post.ackTaskAssigneeList,
                isFetching: props.taskType === ITaskType.ACKNOWLEDGE,
            },
            ...(post.tag === PostType.SURVEY && post.answerTaskAssigneeList
                ? {
                      answerTaskAssigneeList: {
                          ...post.answerTaskAssigneeList,
                          isFetching: [
                              ITaskType.SURVEY,
                              ITaskType.FEEDBACK,
                          ].includes(props.taskType),
                      },
                  }
                : undefined),
        })),
    ),

    on(fapi.fetchTasksAssigneesListSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            const updateMemebrList = (
                memberList: PaginatedList<Member>,
            ): PaginatedList<Member> => ({
                ...memberList,
                list: [...(memberList.list ?? []), ...props.members.list],
                isFetching: false,
                nextPageToken: props.members.nextPageToken,
            });

            switch (props.taskType) {
                case ITaskType.STANDARD:
                    return {
                        ...post,
                        taskAssigneesList: updateMemebrList(
                            post.taskAssigneesList,
                        ),
                    };
                case ITaskType.ACKNOWLEDGE:
                    return {
                        ...post,
                        ackTaskAssigneeList: updateMemebrList(
                            post.ackTaskAssigneeList,
                        ),
                    };
                case ITaskType.SURVEY:
                case ITaskType.FEEDBACK:
                    return isSurveyPost(post)
                        ? {
                              ...post,
                              answerTaskAssigneeList:
                                  post.answerTaskAssigneeList
                                      ? updateMemebrList(
                                            post.answerTaskAssigneeList,
                                        )
                                      : post.answerTaskAssigneeList,
                          }
                        : post;
                default:
                    assertUnreachable(props.taskType);
            }
        }),
    ),

    on(createTaskSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            tasksCount:
                (post.tag === PostType.CUSTOM && post.tasksCount
                    ? post.tasksCount
                    : 0) + 1,
            totalStandardTasksCount: (post.totalStandardTasksCount ?? 0) + 1,
        })),
    ),

    on(deleteTaskSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            tasksCount:
                (post.tag === PostType.CUSTOM && post.tasksCount
                    ? post.tasksCount
                    : 1) - 1,
            totalStandardTasksCount: (post.totalStandardTasksCount ?? 1) - 1,
        })),
    ),

    on(fromCommunityApiStore.massiveUploadHashtagsSuccess, (state, props) => {
        function changeAttachmentsList(
            prevList: IListAttachments,
            updatedAttachments: Map<Index, IAttachment>,
        ) {
            return {
                ...prevList,
                attachments: prevList.attachments.map((a) => {
                    const updatedAttachment = updatedAttachments.get(a.id);
                    return updatedAttachment
                        ? {
                              ...a,
                              hashtags: updatedAttachment.hashtags,
                              lastModifyTimestamp:
                                  updatedAttachment.lastModifyTimestamp,
                          }
                        : a;
                }),
            };
        }
        function changeAttachments(
            state: PostState,
            attachments: Map<Index, IAttachment>,
        ): PostState {
            const next = { ...state.allPosts };
            Object.keys(next).forEach((key) => {
                const post = next[parseInt(key)];
                if (!post) {
                    return;
                }
                next[parseInt(key)] = {
                    ...post,
                    mediaList: changeAttachmentsList(
                        post.mediaList,
                        attachments,
                    ),
                    documentList: changeAttachmentsList(
                        post.documentList,
                        attachments,
                    ),
                    attachmentsList: post?.attachmentsList
                        ? changeAttachmentsList(
                              post.attachmentsList,
                              attachments,
                          )
                        : undefined,
                };
            });
            return { ...state, allPosts: next };
        }

        const mappedArray = new Map(
            props.updatedAttachments.map((a) => [a.id, a]),
        );
        return changeAttachments(state, mappedArray);
    }),

    on(fa.fetchVisibleCommentsCountDetail, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            visibleCommentsCountDetail: props.visibleCommentsCount,
        })),
    ),

    on(fa.fetchVisibleCommentsCountDashboard, (state, props) =>
        modifyPost(state, props.postId, (post) => ({
            ...post,
            visibleCommentsCountDashboard: props.visibleCommentsCount,
        })),
    ),

    on(fa.clearFetchPostError, (state, { fetchType }) => ({
        ...state,
        errors: { ...state.errors, [fetchType]: null },
    })),

    on(fapi.getStreamingVideoSuccess, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            if (post.tag !== PostType.EVENT) {
                return post;
            }
            return {
                ...post,
                streamingVideoSrc: props.streamingVideoSrc,
            };
        }),
    ),

    on(fa.modifyStreamingStartTimestamp, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            if (post.tag !== PostType.EVENT) {
                return post;
            }
            return {
                ...post,
                streamingStartTimestamp: new Date(),
            };
        }),
    ),

    on(fa.modifyStreamingEndTimestamp, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            if (post.tag !== PostType.EVENT) {
                return post;
            }
            return {
                ...post,
                streamingEndTimestamp: new Date(),
            };
        }),
    ),

    on(fapi.fetchFilePickerTemporaryContentViewLinkSuccess, (state, props) => {
        const postIdForUpdate =
            props.source === 'linkedPostFilePicker'
                ? props.filePicker.postId
                : props.postId;

        if (!postIdForUpdate) {
            return state;
        }

        return modifyPost(state, postIdForUpdate, (post) => {
            if (post.tag !== PostType.CUSTOM) return post;

            return produce(post, (draft) => {
                if (props.filePicker.filePickerMode === 'post') {
                    draft.customFields = draft.customFields
                        .changeValueForAttachments(
                            props.filePicker.fieldId,
                            props.attachments,
                        )
                        .clone();
                } else if (props.filePicker.filePickerMode === 'screen') {
                    draft.customFieldsScreen = draft.customFieldsScreen
                        .changeValueForAttachments(
                            props.filePicker.fieldId,
                            props.attachments,
                        )
                        .clone();
                }
            });
        });
    }),

    on(fa.updateSurveyQuestionsDefinition, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            if (isSurveyPost(post)) {
                return {
                    ...post,
                    fieldDefinitions: post.fieldDefinitions.map((c) => {
                        if (c.id === props.fieldId) {
                            return {
                                ...c,
                                enumValues: props.enumValues,
                            };
                        } else {
                            return c;
                        }
                    }),
                };
            } else {
                return post;
            }
        }),
    ),

    on(fa.updateTimeRelatedState, (state, props) =>
        modifyPost(state, props.postId, (post) => {
            if (isSurveyPost(post)) {
                return {
                    ...post,
                    state: buildSurveyState(
                        post.startAt,
                        post.scheduledClosing,
                        post.closingTimestamp,
                    ),
                };
            } else if (isEventPost(post)) {
                return {
                    ...post,
                    state: buildEventState(post),
                };
            } else {
                return post;
            }
        }),
    ),

    on(
        answerFeedbackTaskSuccess,
        validateFeedbackTaskSuccess,
        (state, { type, task, fromState }) => {
            if (task.postId != null && task.state !== fromState) {
                return modifyPost(state, task.postId, (post) => {
                    if (isFeedBackPostType(post)) {
                        let feedbackTaskSummary = updateFeedbackTaskSummary(
                            post.feedbackTaskSummary,
                            fromState,
                            task.state,
                            false,
                        );
                        if (type === answerFeedbackTaskSuccess.type) {
                            feedbackTaskSummary = updateFeedbackTaskSummary(
                                feedbackTaskSummary,
                                fromState,
                                task.state,
                                true,
                            );
                        }

                        return {
                            ...post,
                            feedbackTaskSummary,
                        };
                    }
                    return post;
                });
            }
            return state;
        },
    ),

    on(fa.updateAllPosts, (state, { posts }) => ({
        ...state,
        allPosts: updatePostsMap(state.allPosts, posts),
    })),

    on(fa.pruneAllPosts, (state, { keepPostIds }) => {
        const nextPosts = state.allPosts;
        const keepPostIdsSet = new Set(keepPostIds);
        Object.keys(nextPosts)
            .map((key) => parseInt(key))
            .filter((postId) => !isNaN(postId))
            .filter((postId) => !keepPostIdsSet.has(postId))
            .forEach((postId) => delete nextPosts[postId]);

        return {
            ...state,
            allPosts: nextPosts,
        };
    }),

    on(fapi.fetchPostsCapabilitiesSuccess, (state, { postsCapabilities }) => {
        const postsWithCapabilities = Object.keys(postsCapabilities)
            .map((postId) => +postId)
            .map((postId) => state.allPosts[postId])
            .filter(isDefined)
            .map((post) => ({
                ...post,
                capabilities: postsCapabilities[post.id] ?? post.capabilities,
            }));

        return {
            ...state,
            allPosts: updatePostsMap(state.allPosts, postsWithCapabilities),
        };
    }),
);

function updateFeedbackTaskSummary(
    summary: FeedbackTaskSummary | undefined,
    fromState: FeedbackTaskState,
    toState: FeedbackTaskState,
    assignedToMe: boolean,
): FeedbackTaskSummary | undefined {
    return produce(summary, (draft) => {
        if (draft) {
            const count = assignedToMe
                ? draft.assignedToMeTasksCount
                : draft.tasksCount;
            if (count) {
                count[fromState] && count[fromState]--;
                count[toState]++;
            }
        }
    });
}

function updatePostsMap(currPosts: PostsMap, newPosts: IBasePost[]): PostsMap {
    const posts: GenericPost[] = Object.values(currPosts);
    const nextPosts: PostsMap = {};

    // We add the new posts to nextPosts.
    // The new posts could have been fetched without asking for the capabilities
    // or the metadata, so if they are not present we keep them from the currPost
    for (const newPost of newPosts) {
        const currPost: GenericPost | undefined = currPosts[newPost.id];
        const nextPost = fromBasePost(newPost);
        const isChanged =
            !currPost ||
            (currPost.lastModifyOrCommentTimestamp &&
                nextPost.lastModifyOrCommentTimestamp &&
                isBefore(
                    currPost.lastModifyOrCommentTimestamp,
                    nextPost.lastModifyOrCommentTimestamp,
                ));

        nextPosts[newPost.id] = {
            ...(currPost ?? {}),
            ...nextPost,
            capabilities: nextPost.capabilities ?? currPost?.capabilities,
            metadata: nextPost.metadata ?? currPost?.metadata,
            mediaList:
                isChanged || notInitializedList(currPost?.mediaList)
                    ? nextPost.mediaList
                    : currPost?.mediaList ?? nextPost.mediaList,
            documentList:
                isChanged || notInitializedList(currPost?.documentList)
                    ? nextPost.documentList
                    : currPost?.documentList ?? nextPost.documentList,
            commentsMediaList:
                isChanged || notInitializedList(currPost?.commentsMediaList)
                    ? nextPost.commentsMediaList
                    : currPost?.commentsMediaList ?? nextPost.commentsMediaList,
            commentsDocumentList:
                isChanged || notInitializedList(currPost?.commentsDocumentList)
                    ? nextPost.commentsDocumentList
                    : currPost?.commentsDocumentList ??
                      nextPost.commentsDocumentList,
            filePickerMediaList:
                isChanged || notInitializedList(currPost?.filePickerMediaList)
                    ? nextPost.filePickerMediaList
                    : currPost?.filePickerMediaList ??
                      nextPost.filePickerMediaList,
            filePickerDocumentList:
                isChanged ||
                notInitializedList(currPost?.filePickerDocumentList)
                    ? nextPost.filePickerDocumentList
                    : currPost?.filePickerDocumentList ??
                      nextPost.filePickerDocumentList,
            comments:
                currPost?.comments &&
                (currPost.commentsCount ?? 0) === (nextPost.commentsCount ?? 0)
                    ? currPost.comments
                    : nextPost.comments, //mantengo i commenti eventualmente già caricati solo se il conteggio totale coincide con il il nuovo conteggio totale
            visibleCommentsCountDetail: 2,
            visibleCommentsCountDashboard: 2,
            workflowHistory:
                currPost?.workflowHistory ?? nextPost.workflowHistory,
            memberMentions: newPost.memberMentions ?? currPost?.memberMentions,
        };
    }

    for (const post of posts) {
        if (!(post.id in nextPosts)) {
            nextPosts[post.id] = { ...post };
        }
    }

    return nextPosts;
}

function updatePosts(
    currPosts: PostsMap,
    currFetchInfo: Record<PostFetch, FetchInfo>,
    newPosts: IBasePost[],
    fetchType: PostFetch,
    append = false,
): [PostsMap, Record<PostFetch, FetchInfo>] {
    const nextPosts = updatePostsMap(currPosts, newPosts);

    const newPostsIds = newPosts.map((p) => p.id);
    const nextFetchInfo: Record<PostFetch, FetchInfo> = {
        ...currFetchInfo,
        [fetchType]: {
            lastFetchTime: Date.now(),
            fetchOrder: append
                ? [...currFetchInfo[fetchType].fetchOrder, ...newPostsIds]
                : newPostsIds,
            isStale: false,
        },
    };

    return [nextPosts, nextFetchInfo];
}

function setLinkedListFetching(
    state: WritableDraft<PostState>,
    communityId: number,
    isFetching: boolean,
) {
    const refLinkList = state.linkedPosts.items[communityId];

    if (refLinkList != null) {
        refLinkList.loadingInfo = {
            ...refLinkList.loadingInfo,
            isFetching,
        };
    }
}

function getEventResponseIncrement(
    propsTypeId: EventParticipateType,
    postParticipateType: EventParticipateType | null,
    typeToMatch: EventParticipateType,
): 1 | 0 | -1 {
    return propsTypeId === typeToMatch
        ? 1
        : postParticipateType === typeToMatch
          ? -1
          : 0;
}

function getStreamingCommentList(
    post: IEventPost,
    fetchedCommentsList: StreamingCommentsList,
    pageToken?: string,
    syncToken?: string,
): StreamingCommentsList {
    const actualPostList = post.streamingComments.list ?? [];

    if (pageToken) {
        //aggiungo i nuovi elementi in coda
        return {
            ...fetchedCommentsList,
            list: [...actualPostList, ...fetchedCommentsList.list],
        };
    } else if (syncToken) {
        const lastActualCommentTimestamp = actualPostList[0].creationTimestamp;
        const [fetchedToAdd, fetchedToUpdate] = mPartition(
            fetchedCommentsList.list,
            (comment) => comment.creationTimestamp > lastActualCommentTimestamp,
        );
        const fetchedToUpdateIds = new Set(mapArrayById(fetchedToUpdate));
        const list = [
            ...fetchedToAdd,
            ...(fetchedToUpdate.length
                ? actualPostList.map((comment) =>
                      fetchedToUpdateIds.has(comment.id)
                          ? fetchedToUpdate.find(
                                (ftu) => ftu.id === comment.id,
                            )!
                          : comment,
                  )
                : actualPostList),
        ];

        //aggiungo i nuovi elementi in testa
        return {
            ...fetchedCommentsList,
            totalCount:
                fetchedCommentsList.totalCount ||
                post.streamingComments.totalCount,
            list,
        };
    } else {
        return fetchedCommentsList;
    }
}

function notInitializedList(list: IListAttachments) {
    return list.pageTokenInfo.tag === 'firstLoading' && !list.isLoading;
}
