import { Injectable } from '@angular/core';
import { emptyAttachmentsList } from '@modules/post/models/attachment/attachment.utils';
import { AttachmentService } from '@modules/post/services/attachment.service';
import { EMPTY, iif, of } from 'rxjs';
import {
    GallerySource,
    GallerySourceFeature,
    GallerySourceFn,
    GallerySources,
} from '../models/gallery-source.model';

@Injectable({
    providedIn: 'root',
})
export class GallerySourceService {
    private sources: Partial<GallerySources> = {};

    constructor(attachmentService: AttachmentService) {
        this.addSource('embedded', (detail) => {
            const attachmentList =
                detail.attachmentList ?? emptyAttachmentsList();
            return iif(
                () =>
                    attachmentList.attachments.some(
                        (at) => !at.temporaryContentViewLink,
                    ),
                attachmentService.getBatchAttachmentDetails(
                    attachmentList.attachments,
                ),
                of(attachmentList),
            );
        });
    }

    addSource<F extends GallerySourceFeature>(
        feature: F,
        sourceOrSourceFn: GallerySource<F> | GallerySourceFn<F>,
    ): void {
        const source: GallerySource<F> = isGallerySource(sourceOrSourceFn)
            ? sourceOrSourceFn
            : { sourceFn: sourceOrSourceFn };
        this.sources = { ...this.sources, [feature]: source };
    }

    getSource<T extends GallerySourceFeature>(feature: T): GallerySource<T> {
        return this.sources[feature] ?? emptyGallerySource(feature);
    }
}

function emptyGallerySource<F extends GallerySourceFeature>(_: F) {
    return <GallerySource<F>>{
        sourceFn: () => EMPTY,
    };
}

function isGallerySource<F extends GallerySourceFeature>(
    sourceOrSourceFn: GallerySource<F> | GallerySourceFn<F>,
): sourceOrSourceFn is GallerySource<F> {
    return typeof sourceOrSourceFn === 'object';
}
