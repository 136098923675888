import { Index } from '@interacta-shared/util';
import { i18nStringV2 } from '@modules/core';
import { TranslateService } from '@ngx-translate/core';

export enum ItemOrderByCriteria {
    CUSTOM = 1,
    ASC = 2,
    DESC = 3,
}

export const getOrderByCriteriaLabel = (
    orderBy: ItemOrderByCriteria,
    translateService: TranslateService,
): string => {
    let label;
    switch (orderBy) {
        case ItemOrderByCriteria.CUSTOM:
            label = 'CUSTOM';
            break;
        case ItemOrderByCriteria.ASC:
            label = 'ASC';
            break;
        case ItemOrderByCriteria.DESC:
            label = 'DESC';
            break;
    }
    return translateService.instant(`ORDER_BY_CRITERIA.${label}`);
};

export interface OrderbyCriteriaDialogItemData {
    id: Index;
    name: i18nStringV2;
    deleted?: boolean;
}
