import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reverse',
})
export class ReversePipe implements PipeTransform {
    transform<T>(array: T[]): T[] {
        return array.slice().reverse();
    }
}
