import { isDefined } from '@interacta-shared/util';
import { mPartition } from '@modules/core/helpers/generic.utils';
import {
    IBasePostDifferential,
    TaskSummary,
} from '@modules/post/models/base-post.model';
import { isCustomPost } from '@modules/post/models/custom-post.model';
import { isEventPost } from '@modules/post/models/event-post.model';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import {
    fromBasePost,
    GenericPost,
} from '@modules/post/models/generic-post.model';
import { isSurveyPost } from '@modules/post/models/survey-post/survey-post.utils';
import {
    FetchInfo,
    PostFetch,
    PostsMap,
    PostState,
} from '../post/post.reducer';

export function mergePartiallyDashboardOrHome(
    state: PostState,
    post: IBasePostDifferential,
): PostState {
    const currPost = state.allPosts[post.id];
    const diffPost = fromBasePost(post);

    const mergeBasePost: GenericPost = currPost
        ? {
              ...currPost,

              title: diffPost.title,
              likesCount: diffPost.likesCount,
              watchersCount: diffPost.watchersCount,
              viewedByUsersCount: diffPost.viewedByUsersCount,
              currentWorkflowState: diffPost.currentWorkflowState,
              capabilities: diffPost.capabilities ?? currPost.capabilities,

              metadata: diffPost.metadata ?? currPost.metadata,
              occToken: diffPost.occToken ?? currPost.occToken,

              ...mergeCommentsCount(diffPost, currPost),

              acknowledgeTaskSummary: mergeTaskSummary(
                  currPost.acknowledgeTaskSummary,
                  diffPost.acknowledgeTaskSummary,
              ),
              surveyTaskSummary: mergeTaskSummary(
                  currPost.surveyTaskSummary,
                  diffPost.surveyTaskSummary,
              ),

              feedbackTaskSummary:
                  diffPost.feedbackTaskSummary ?? currPost.feedbackTaskSummary,
          }
        : diffPost;

    const mergeCustomPost: GenericPost =
        isCustomPost(mergeBasePost) && isCustomPost(diffPost)
            ? {
                  ...mergeBasePost,
                  tasksCount: diffPost.tasksCount,
              }
            : mergeBasePost;

    const mergeEventPost: GenericPost =
        isEventPost(mergeBasePost) && isEventPost(diffPost)
            ? {
                  ...mergeBasePost,
                  startAt: diffPost.startAt,
                  endAt: diffPost.endAt,
                  allDay: diffPost.allDay,
                  participantsYesCount: diffPost.participantsYesCount,
                  participantsMaybeCount: diffPost.participantsMaybeCount,
                  participantsNoCount: diffPost.participantsNoCount,
                  streamingStartTimestamp: diffPost.streamingStartTimestamp,
                  streamingEndTimestamp: diffPost.streamingEndTimestamp,
                  participantsResponseCount: diffPost.participantsResponseCount,
              }
            : mergeBasePost;

    const mergeSurveyPost: GenericPost =
        isSurveyPost(mergeBasePost) && isSurveyPost(diffPost)
            ? {
                  ...mergeBasePost,
                  startAt: diffPost.startAt,
                  expiration: diffPost.expiration,
                  closingTimestamp: diffPost.closingTimestamp,
                  state: diffPost.state,
              }
            : mergeBasePost;

    const mergePost = isCustomPost(mergeBasePost)
        ? mergeCustomPost
        : isEventPost(mergeBasePost)
          ? mergeEventPost
          : mergeSurveyPost;

    return {
        ...state,
        allPosts: {
            ...state.allPosts,
            [post.id]: mergePost,
        },
    };
}

export function mergePartiallyInDetail(
    state: PostState,
    post: IBasePostDifferential,
    addToDiffPostDetail: boolean,
): PostState {
    const currPost = state.allPosts[post.id];
    const diffPost = fromBasePost(post);

    const mergeBasePost: GenericPost = currPost
        ? {
              ...currPost,

              likesCount: diffPost.likesCount,
              watchersCount: diffPost.watchersCount,
              viewedByUsersCount: diffPost.viewedByUsersCount,
              capabilities: diffPost.capabilities ?? currPost.capabilities,

              metadata: diffPost.metadata ?? currPost.metadata,

              ...mergeCommentsCount(diffPost, currPost),

              acknowledgeTaskSummary: mergeTaskSummary(
                  currPost.acknowledgeTaskSummary,
                  diffPost.acknowledgeTaskSummary,
              ),
              surveyTaskSummary: mergeTaskSummary(
                  currPost.surveyTaskSummary,
                  diffPost.surveyTaskSummary,
              ),

              feedbackTaskSummary:
                  diffPost.feedbackTaskSummary ?? currPost.feedbackTaskSummary,

              totalStandardTasksCount: diffPost.totalStandardTasksCount,
          }
        : diffPost;

    const mergeCustomPost: GenericPost =
        isCustomPost(mergeBasePost) && isCustomPost(diffPost)
            ? {
                  ...mergeBasePost,
                  tasksCount: diffPost.tasksCount,
              }
            : mergeBasePost;

    const mergeEventPost: GenericPost =
        isEventPost(mergeBasePost) && isEventPost(diffPost)
            ? {
                  ...mergeBasePost,
                  participantsYesCount: diffPost.participantsYesCount,
                  participantsMaybeCount: diffPost.participantsMaybeCount,
                  participantsNoCount: diffPost.participantsNoCount,
                  streamingStartTimestamp: diffPost.streamingStartTimestamp,
                  streamingEndTimestamp: diffPost.streamingEndTimestamp,
                  participantsResponseCount: diffPost.participantsResponseCount,
              }
            : mergeBasePost;

    const mergeSurveyPost: GenericPost =
        isSurveyPost(mergeBasePost) && isSurveyPost(diffPost)
            ? {
                  ...mergeBasePost,
                  startAt: diffPost.startAt,
                  expiration: diffPost.expiration,
                  closingTimestamp: diffPost.closingTimestamp,
                  state: diffPost.state,
              }
            : mergeBasePost;

    const mergePost = isCustomPost(mergeBasePost)
        ? mergeCustomPost
        : isEventPost(mergeBasePost)
          ? mergeEventPost
          : mergeSurveyPost;

    return {
        ...state,
        allPosts: {
            ...state.allPosts,
            [post.id]: mergePost,
        },
        diffPostDetail:
            addToDiffPostDetail || state.diffPostDetail != null
                ? post
                : state.diffPostDetail,
    };
}

export function mergeDiffPosts(
    state: PostState,
    diffPosts: IBasePostDifferential[],
): PostState {
    let nextAllPosts = { ...state.allPosts };
    let nextFetchInfo = { ...state.fetchInfo };
    for (const post of diffPosts) {
        const currPosts = { ...nextAllPosts };
        nextAllPosts = mergeDifferentialPost(currPosts, post);
        nextFetchInfo = mergeDifferentialFetchInfo(
            currPosts,
            nextFetchInfo,
            state.filters,
            post,
        );
    }
    return {
        ...state,
        allPosts: nextAllPosts,
        fetchInfo: nextFetchInfo,
        diffPosts: [],
    };
}

export function mergeDiffPostDetail(state: PostState): PostState {
    if (state.diffPostDetail) {
        return {
            ...state,
            allPosts: mergeDifferentialPost(
                state.allPosts,
                state.diffPostDetail,
            ),
            diffPostDetail: null,
        };
    } else {
        return state;
    }
}

function mergeDifferentialPost(
    currPosts: PostsMap,
    post: IBasePostDifferential,
): PostsMap {
    const currPost = currPosts[post.id];
    const diffPost = fromBasePost(post);

    const mergeBasePost: GenericPost = currPost
        ? {
              ...currPost,
              ...diffPost,
              pinned: currPost.pinned,

              capabilities: diffPost.capabilities ?? currPost.capabilities,
              metadata: diffPost.metadata ?? currPost.metadata,
              comments: currPost.comments,
              areCommentsStale:
                  diffPost.commentsCount !== currPost.commentsCount,
              mainAttachment: currPost.mainAttachment,
              likersList: currPost.likersList,
              viewersList: currPost.viewersList,
          }
        : diffPost;

    const mergeCustomPost: GenericPost =
        isCustomPost(mergeBasePost) && isCustomPost(diffPost)
            ? {
                  ...mergeBasePost,
                  // Handle any possible ICustomPost property
              }
            : mergeBasePost;

    const mergeEventPost: GenericPost =
        isEventPost(mergeBasePost) && currPost && isEventPost(currPost)
            ? {
                  ...mergeBasePost,
                  participantsYesList: currPost.participantsYesList,
                  participantsMaybeList: currPost.participantsMaybeList,
                  participantsNoList: currPost.participantsNoList,
                  streamingVideoSrc: currPost.streamingVideoSrc,
              }
            : mergeBasePost;

    const mergeSurveyPost: GenericPost =
        isSurveyPost(mergeBasePost) && currPost && isSurveyPost(diffPost)
            ? {
                  ...mergeBasePost,
                  startAt: diffPost.startAt,
                  expiration: diffPost.expiration,
                  closingTimestamp: diffPost.closingTimestamp,
                  state: diffPost.state,
                  managers: diffPost.managers ?? mergeBasePost.managers,
                  managersCount:
                      diffPost.managersCount ?? mergeBasePost.managersCount,
                  customAnswerViewer:
                      diffPost.customAnswerViewer ??
                      mergeBasePost.customAnswerViewer,
                  answerViewersCount:
                      diffPost.answerViewersCount ??
                      mergeBasePost.answerViewersCount,
                  answerViewerType: diffPost.answerViewerType,
                  recipients: diffPost.recipients ?? mergeBasePost.recipients,
                  recipientAnswerViewers: diffPost.recipientAnswerViewers,
                  editAnswerEnabled: diffPost.editAnswerEnabled,
                  fieldDefinitions:
                      diffPost.fieldDefinitions ??
                      mergeBasePost.fieldDefinitions,
                  // Handle any other possible SurveyPost property
              }
            : mergeBasePost;

    const mergePost = isCustomPost(mergeBasePost)
        ? mergeCustomPost
        : isEventPost(mergeBasePost)
          ? mergeEventPost
          : mergeSurveyPost;

    const nextPosts: PostsMap = {
        ...currPosts,
        [post.id]: mergePost,
    };

    return nextPosts;
}

function mergeDifferentialFetchInfo(
    currPosts: PostsMap,
    currFetchInfo: Record<PostFetch, FetchInfo>,
    currFilters: Record<PostFetch, IPostFilters>,
    post: IBasePostDifferential,
): Record<PostFetch, FetchInfo> {
    const fetchOrder = () => {
        switch (post.status) {
            case 'CREATED':
                if (!currFetchInfo.dashboard.fetchOrder.includes(post.id)) {
                    if (isDefined(currFilters.dashboard.communityId)) {
                        const [pinnedPostsId, notPinnedPostsId] = mPartition(
                            currFetchInfo.dashboard.fetchOrder,
                            (id) => !!currPosts[id]?.pinned,
                        );
                        return [...pinnedPostsId, post.id, ...notPinnedPostsId];
                    } else {
                        return [post.id, ...currFetchInfo.dashboard.fetchOrder];
                    }
                } else {
                    return currFetchInfo.dashboard.fetchOrder;
                }
            case 'DELETED':
                return currFetchInfo.dashboard.fetchOrder.filter(
                    (id) => id !== post.id,
                );
            case 'MODIFIED': {
                return currFetchInfo.dashboard.fetchOrder;
            }
            case 'TOUCHED':
                return currFetchInfo.dashboard.fetchOrder;
            default:
                throw new Error(
                    'Unhandled case in mergeDifferentialFetchInfo()',
                );
        }
    };
    return {
        ...currFetchInfo,
        dashboard: {
            ...currFetchInfo.dashboard,
            fetchOrder: fetchOrder(),
        },
    };
}

function mergeTaskSummary(
    curr: TaskSummary | undefined,
    diff: TaskSummary | undefined,
): TaskSummary | undefined {
    return diff
        ? {
              ...diff,
              assignedToMeMinTasksExpirationDate:
                  diff?.assignedToMeMinTasksExpirationDate ??
                  curr?.assignedToMeMinTasksExpirationDate,
          }
        : diff;
}

const mergeCommentsCount = (
    diff: Pick<GenericPost, 'commentsCount'>,
    curr: Pick<GenericPost, 'commentsCount' | 'areCommentsStale'>,
): Pick<GenericPost, 'commentsCount' | 'areCommentsStale'> => ({
    commentsCount:
        (diff.commentsCount ?? 0) > (curr.commentsCount ?? 0)
            ? diff.commentsCount
            : curr.commentsCount, // non rimuovo i commenti cancellati

    areCommentsStale:
        curr.areCommentsStale ||
        (diff.commentsCount ?? 0) > (curr.commentsCount ?? 0),
});
