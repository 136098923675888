import {
    EditableTableData,
    ServerEditableTableData,
    ServerEditableTableRow,
} from './editable-table.model';

const toServerEditableTableData = (
    data: EditableTableData,
): ServerEditableTableData => ({
    ...data,
    rows: data.rows.map((row) =>
        Object.keys(row).reduce(
            (serverRow, key) => ({
                ...serverRow,
                [key]: JSON.stringify(row[key].ops),
            }),
            {} as ServerEditableTableRow,
        ),
    ),
});

export const fromEditableTableData = (data: EditableTableData): string =>
    JSON.stringify(toServerEditableTableData(data));
