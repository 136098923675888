import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trim',
})
export class TrimPipe implements PipeTransform {
    transform(value: string | undefined | null): string | undefined | null {
        return value ? value.trim() : value;
    }
}
