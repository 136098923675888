<button
    class="ql-bold small-ql-icon"
    type="button"
    [attr.aria-label]="'QUILL.TOOLBAR.BOLD' | translate"
    [matTooltip]="'QUILL.TOOLBAR.BOLD' | translate"
></button>
<button
    class="ql-italic small-ql-icon"
    type="button"
    [attr.aria-label]="'QUILL.TOOLBAR.ITALIC' | translate"
    [matTooltip]="'QUILL.TOOLBAR.ITALIC' | translate"
></button>
<button
    class="ql-underline small-ql-icon"
    type="button"
    [attr.aria-label]="'QUILL.TOOLBAR.UNDERLINE' | translate"
    [matTooltip]="'QUILL.TOOLBAR.UNDERLINE' | translate"
></button>
<button
    class="ql-list small-ql-icon"
    type="button"
    value="ordered"
    [attr.aria-label]="'QUILL.TOOLBAR.LIST_ORDERED' | translate"
    [matTooltip]="'QUILL.TOOLBAR.LIST_ORDERED' | translate"
></button>
<button
    class="ql-list small-ql-icon"
    type="button"
    value="bullet"
    [attr.aria-label]="'QUILL.TOOLBAR.LIST_BULLET' | translate"
    [matTooltip]="'QUILL.TOOLBAR.LIST_BULLET' | translate"
></button>
<button
    class="ql-link small-ql-icon"
    type="button"
    [attr.aria-label]="'QUILL.TOOLBAR.LINK' | translate"
    [matTooltip]="'QUILL.TOOLBAR.LINK' | translate"
></button>
