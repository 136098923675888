<button
    #cellMenu="cdkOverlayOrigin"
    cdkOverlayOrigin
    class="absolute right-0 top-0"
    interacta-icon-button
    type="button"
    [attr.aria-label]="'UI.EDITABLE_TABLE.OPEN_CELL_MENU' | translate"
    [icon]="'chevron-small-down'"
    [shape]="'rectangular'"
    [size]="'small'"
    (click)="isCellMenuOpen = true"
    (mousedown)="$event.preventDefault()"
></button>
<interacta-menu
    *ngIf="isCellMenuOpen"
    positionX="after"
    positionY="below"
    [open]="isCellMenuOpen"
    [origin]="cellMenu"
    (closing)="isCellMenuOpen = false"
>
    <interacta-menu-decorator>
        <interacta-button-menu
            [label]="'UI.EDITABLE_TABLE.ADD_ROW_ABOVE' | translate"
            (click)="addRowAbove.emit(); isCellMenuOpen = false"
            (mousedown)="$event.preventDefault()"
        ></interacta-button-menu>
        <interacta-button-menu
            [label]="'UI.EDITABLE_TABLE.ADD_ROW_BELOW' | translate"
            (click)="addRowBelow.emit(); isCellMenuOpen = false"
            (mousedown)="$event.preventDefault()"
        ></interacta-button-menu>
        <interacta-button-menu
            [label]="'UI.EDITABLE_TABLE.ADD_COLUMN_RIGHT' | translate"
            (click)="addColumnRight.emit(); isCellMenuOpen = false"
            (mousedown)="$event.preventDefault()"
        ></interacta-button-menu>
        <interacta-button-menu
            [label]="'UI.EDITABLE_TABLE.ADD_COLUMN_LEFT' | translate"
            (click)="addColumnLeft.emit(); isCellMenuOpen = false"
            (mousedown)="$event.preventDefault()"
        ></interacta-button-menu>
        <interacta-button-menu
            *ngIf="canDeleteRow"
            [label]="'UI.EDITABLE_TABLE.DELETE_ROW' | translate"
            (click)="deleteRow.emit(); isCellMenuOpen = false"
            (mousedown)="$event.preventDefault()"
        ></interacta-button-menu>
        <interacta-button-menu
            *ngIf="canDeleteColumn"
            [label]="'UI.EDITABLE_TABLE.DELETE_COLUMN' | translate"
            (click)="deleteColumn.emit(); isCellMenuOpen = false"
            (mousedown)="$event.preventDefault()"
        ></interacta-button-menu>
    </interacta-menu-decorator>
</interacta-menu>
