import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Tag } from '@modules/core/models/tag/tag.model';
import { getExcludedGroupCountByTagId } from '@modules/core/models/tag/tag.utils';
import { SurveyRecipientTagExcludedGroups } from '@modules/post/models/survey-post/survey-post.model';

@Component({
    selector: 'interacta-tag-splittable',
    templateUrl: './tag-splittable.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagSplittableComponent {
    @Input({ required: true }) tag!: Tag;
    @Input({ required: true })
    recipientsTagExcludedGroups: SurveyRecipientTagExcludedGroups[] | null = [];

    @Output() tagInfo = new EventEmitter<void>();

    getExcludedGroupCountByTagId = getExcludedGroupCountByTagId;
}
