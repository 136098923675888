import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { formatDateUsingIntl } from '@interacta-shared/util-common';

/**
 * Format a date using the given Intl format.
 * This is useful when you want a localized format not available
 * using date-fns (e.g.: a date without the year)
 *
 * See https://injenia.atlassian.net/l/cp/r6L3KnZu
 *
 */
@Pipe({
    name: 'dateIntl',
})
export class DateIntlPipe implements PipeTransform {
    constructor(private configurationService: ConfigurationService) {}

    transform(
        date: Date,
        format: Intl.DateTimeFormatOptions,
        fallbackFormat: string,
    ): string {
        return formatDateUsingIntl(
            date,
            format,
            fallbackFormat,
            this.configurationService.getCurrentLanguage().code,
        );
    }
}
