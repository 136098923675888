export const downloadFromHref = (href: string, name: string): void => {
    const a = document.createElement('a');
    a.href = href;
    a.download = name;
    a.click();
    a.remove();
};

export const downloadBlob = (blob: Blob, name: string): void => {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = name;
    a.click();
    URL.revokeObjectURL(objectUrl);
    a.remove();
};
