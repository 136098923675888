import { ITaskFilters } from '@modules/tasks/models/filter-task.model';
import {
    ITaskCreate,
    ITaskEdit,
    StandardTask,
    TaskState,
} from '@modules/tasks/models/task.model';
import { createAction, props } from '@ngrx/store';

enum TaskActionType {
    FetchTasks = '[Task] Fetch Tasks',
    FetchTasksPage = '[Task] Fetch Tasks Page',
    FetchTasksUntilId = '[Task] Fetch Tasks Until Id',
    FetchTaskForEdit = '[Task] Fetch Task for edit',
    CreateTask = '[Task] Create Task',
    EditTask = '[Task] Edit Task',
    DeleteTask = '[Task] Delete Task',
    CopyTask = '[Task] Copy Task',
    ToggleState = '[Task] Toggle State',
    ToggleSubtaskState = '[Task] Toggle Subtask State',
    EditReminders = '[Task] Edit Reminders',
    EditWatchers = '[Task] Edit Watchers',
    FetchTaskAttachments = '[Task] Fetch Tasks Attachments',
}

export const fetchTaskAttachments = createAction(
    TaskActionType.FetchTaskAttachments,
    props<{ postId: number }>(),
);

export const fetchTasks = createAction(
    TaskActionType.FetchTasks,
    props<{ postId: number; filters: ITaskFilters }>(),
);

export const fetchTasksPage = createAction(
    TaskActionType.FetchTasksPage,
    props<{ postId: number }>(),
);

export const fetchTasksUntilId = createAction(
    TaskActionType.FetchTasksUntilId,
    props<{ postId: number; filters: ITaskFilters; taskId: number }>(),
);

export const fetchTaskForEdit = createAction(
    TaskActionType.FetchTaskForEdit,
    props<{ taskId: number }>(),
);

export const createTask = createAction(
    TaskActionType.CreateTask,
    props<{ postId: number; task: ITaskCreate }>(),
);

export const editTask = createAction(
    TaskActionType.EditTask,
    props<{ postId: number; task: ITaskEdit }>(),
);

export const copyTask = createAction(
    TaskActionType.CopyTask,
    props<{ postId: number; copyId: number; task: ITaskEdit }>(),
);

export const deleteTask = createAction(
    TaskActionType.DeleteTask,
    props<{ postId: number; taskId: number }>(),
);

export const toggleState = createAction(
    TaskActionType.ToggleState,
    props<{ postId: number; taskId: number; showConfirmToast?: boolean }>(),
);

export const toggleSubtaskState = createAction(
    TaskActionType.ToggleSubtaskState,
    props<{
        postId: number;
        task: StandardTask;
        subtaskId: number;
        state: TaskState;
    }>(),
);

export const editReminders = createAction(
    TaskActionType.EditReminders,
    props<{ task: StandardTask }>(),
);

export const editWatchers = createAction(
    TaskActionType.EditWatchers,
    props<{
        taskId: number;
        postId: number;
        addWatcherUserIds: number[];
        removeWatcherUserIds: number[];
        addWatcherGroupIds: number[];
        removeWatcherGroupIds: number[];
    }>(),
);
