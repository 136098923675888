import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ScrollTrackerEvent, Size } from '@interacta-shared/ui';
import { PaginatedList } from '@interacta-shared/util';
import { adminV2CommunityHashtags } from '@modules/admin-v2-routing/admin-v2-routes';
import {
    adminV2,
    adminV2Community,
    dashboardCommunity,
} from '@modules/app-routing/routes';
import { ICommunity } from '@modules/communities/models/communities.model';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { changeFilters } from '@modules/communities/store/post/post.actions';
import { AppSelectors, AppState } from '@modules/core/store';
import { SearchType } from '@modules/hashtag-info/components/hashtag-info-dialog/hashtag-info-dialog.component';
import { followHashtag } from '@modules/hashtag-info/store/hashtag-info.actions';
import { emptyPostFilters } from '@modules/post/models/filter-post/filter-post.utils';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
@Component({
    selector: 'interacta-hashtags-detail-dialog',
    templateUrl: './hashtags-detail-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HashtagsDetailDialogComponent
    implements OnChanges, OnInit, OnDestroy
{
    @Input({ required: true }) list!: PaginatedList<IHashTag>;
    @Input({ required: true }) community!: Pick<
        ICommunity,
        'id' | 'name' | 'metadata'
    >;
    @Input() isOpen = false;
    @Input() size: Size = 'regular';
    @Input() title = '';
    @Input() showSubtitle = false;
    @Input() canAdd = false;

    @Output() isOpenChange = new EventEmitter<boolean>();
    @Output() nextPagination = new EventEmitter<void>();
    @Output() searchedMembers = new EventEmitter<string>();

    searchHashtags = new FormControl('', { nonNullable: true });
    searchedHashtags$!: Observable<IHashTag[]>;
    hashtagsList$ = new BehaviorSubject<IHashTag[]>([]);
    destroy$ = new Subject<void>();
    selectedHashtag$ = new BehaviorSubject<IHashTag | null>(null);
    searchType = new FormControl<SearchType[]>([]);
    totalCount$ = new BehaviorSubject<number | null>(null);

    notInDetail = true;
    isAdminSection = false;
    readonly N_MEMBERS_TO_SHOW = 20;

    constructor(
        private store: Store,
        private appStore: Store<AppState>,
        private router: Router,
    ) {
        this.appStore
            .select(AppSelectors.selectRouteState)
            .subscribe((route) => {
                this.isAdminSection = route?.appBaseRoute === adminV2;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.list) {
            if (this.selectedHashtag$.value) {
                const found = this.list.list.find(
                    (h) =>
                        this.selectedHashtag$.value &&
                        h.id === this.selectedHashtag$.value.id,
                );
                if (found) {
                    this.selectedHashtag$.next({
                        ...this.selectedHashtag$.value,
                        followedByMe: found.followedByMe,
                    });
                }
            } else if (this.list.totalCount === 1) {
                this.selectedHashtag$.next(this.list.list[0]);
            }

            if (this.totalCount$.value === null && !this.list.isFetching) {
                this.totalCount$.next(this.list.totalCount);
            }
        }
    }

    ngOnInit(): void {
        this.searchHashtags.valueChanges
            .pipe(
                debounceTime(600),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe((res: string) => this.searchedMembers.emit(res));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    selectHashtag(hashtag: IHashTag): void {
        this.selectedHashtag$.next(hashtag);
    }

    newPage(): void {
        if (!this.list.isFetching) this.nextPagination.emit();
    }

    trackByFn(index: number, item: IHashTag): number {
        return item.id;
    }

    scrollTracker(event: ScrollTrackerEvent): void {
        if (
            event.endReached &&
            this.list.nextPageToken?.tag !== 'lastLoading'
        ) {
            this.newPage();
        }
    }

    close(): void {
        this.isOpenChange.emit(false);
        this.searchType.reset();
    }

    toggleFollow(hashtag: IHashTag): void {
        this.store.dispatch(
            followHashtag({
                hashtagId: hashtag.id,
                follow: !hashtag.followedByMe,
            }),
        );
    }

    apply(hashtag: IHashTag, searchTypes: SearchType[] | null): void {
        if (searchTypes?.length) {
            this.store.dispatch(
                changeFilters({
                    fetchType: 'dashboard',
                    updatedFilters: {
                        ...emptyPostFilters(),
                        communityId: hashtag.communityId,
                        hashtag:
                            searchTypes.includes('post') ||
                            searchTypes.includes('both')
                                ? [hashtag]
                                : null,
                        attachmentHashtag:
                            searchTypes.includes('attachment') ||
                            searchTypes.includes('both')
                                ? [hashtag]
                                : null,
                    },
                }),
            );
            this.router.navigate([dashboardCommunity, hashtag.communityId]);
        }
        this.close();
    }

    addHashtags(): void {
        this.router.navigate([
            adminV2Community,
            this.community.id,
            adminV2CommunityHashtags,
        ]);
    }
}
