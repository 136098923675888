import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    signal,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormControl } from '@angular/forms';
import { ScrollTrackerEvent, Size } from '@interacta-shared/ui';
import {
    emptyPaginatedList,
    firstLoading,
    PageTokenInfo,
    PaginatedList,
    paginatedListFromArray,
} from '@interacta-shared/util';
import {
    ItemOrderByCriteria,
    OrderbyCriteriaDialogItemData,
} from '@modules/shared-v2/models/orderby';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'interacta-dialog-items-orderby-criteria',
    templateUrl: './dialog-items-orderby-criteria.component.html',
    styleUrls: ['./dialog-items-orderby-criteria.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogItemsOrderbyCriteriaComponent<
        T extends OrderbyCriteriaDialogItemData,
    >
    implements OnChanges, OnInit, OnDestroy
{
    @Input({ required: true }) items!: PaginatedList<T> | T[];
    @Input({ required: true }) title!: string;
    @Input({ required: true }) description!: string;
    @Input({ required: true }) orderBy!: ItemOrderByCriteria;

    @Input() isOpen = false;
    @Input() saveLoading = false;
    @Input() loadingItems = false;
    @Input() size: Size = 'regular';

    @Input() availableOrderOptions: ItemOrderByCriteria[] = [
        ItemOrderByCriteria.ASC,
        ItemOrderByCriteria.DESC,
        ItemOrderByCriteria.CUSTOM,
    ];
    @Input() saveButtonConfig: {
        label: string;
        alwaysVisible: boolean;
    } | null = null;
    @Input() showWarning = false;
    @Input() warningMessage = '';
    @Input() warningBadgeMessage = '';

    @Input() itemTemplate: TemplateRef<any> | null = null;

    @Output() closed = new EventEmitter<void>();
    @Output() orderByChange = new EventEmitter<ItemOrderByCriteria>();
    @Output() fetchItems = new EventEmitter<PageTokenInfo>();
    @Output() itemMoved = new EventEmitter<{
        itemId: T['id'];
        previousIndex: number;
        nextIndex: number;
    }>();
    @Output() saveChanges = new EventEmitter<{
        items: PaginatedList<T>;
        orderBy: ItemOrderByCriteria;
    }>();

    readonly ItemOrderByCriteria = ItemOrderByCriteria;

    orderUpdated = signal<boolean>(false);
    orderedPaginatedList$ = new BehaviorSubject<PaginatedList<T>>(
        emptyPaginatedList(),
    );

    orderByControl = new FormControl<ItemOrderByCriteria>(
        ItemOrderByCriteria.CUSTOM,
        {
            nonNullable: true,
        },
    );

    @ViewChild('itemsContainer') itemsContainer?: ElementRef<HTMLElement>;

    private destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.orderByControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((orderBy) => this.orderByChange.emit(orderBy));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isOpen && this.isOpen) {
            if (this.orderBy === ItemOrderByCriteria.CUSTOM) {
                this.fetchItems.emit(firstLoading());
            }
            this.orderByControl.setValue(this.orderBy);
        }
        if (changes.items && this.isOpen) {
            this.setOrderedPaginatedList(this.items);
        }
    }

    onScroll(event: ScrollTrackerEvent, list: PaginatedList<T>): void {
        if (
            event?.thresholdReached &&
            list.nextPageToken.tag === 'regularLoading' &&
            !list.isFetching
        ) {
            this.fetchItems.emit(list.nextPageToken);
        }
    }

    onDrop(elementId: T['id'], event: CdkDragDrop<T[]>): void {
        this.move(elementId, event.previousIndex, event.currentIndex);
    }

    move(itemId: T['id'], previousIndex: number, nextIndex: number): void {
        this.orderUpdated.set(true);

        const entries = [...this.orderedPaginatedList$.value.list];
        moveItemInArray(entries, previousIndex, nextIndex);

        this.orderedPaginatedList$.next({
            ...this.orderedPaginatedList$.value,
            list: entries,
        });
        this.itemMoved.emit({
            itemId,
            previousIndex,
            nextIndex,
        });
    }

    moveToTop(itemId: T['id'], previousIndex: number): void {
        if (this.itemsContainer) {
            this.itemsContainer.nativeElement.scrollTop = 0;
            this.move(itemId, previousIndex, 0);
        }
    }

    save(): void {
        this.saveChanges.emit({
            items: this.orderedPaginatedList$.value,
            orderBy: this.orderByControl.value,
        });
        this.closed.emit();
    }

    private setOrderedPaginatedList(list: T[] | PaginatedList<T>): void {
        if (Array.isArray(list)) {
            this.orderedPaginatedList$.next(paginatedListFromArray(list));
        } else {
            this.orderedPaginatedList$.next(list);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
