import { PageTokenInfo, PaginatedList } from '@interacta-shared/util';
import { AppBaseRoute } from '@modules/app-routing/routes';
import { ICommunity } from '@modules/communities/models/communities.model';
import { ICustomFieldDefinition } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { PostError } from '@modules/communities/models/post-error/post-error.model';
import { IPostLinkAndRelation } from '@modules/communities/models/post-link-and-relation/post-link-and-relation.model';
import { IScreenEdit } from '@modules/communities/models/screen.model';
import {
    IWorkflowChangeScreenData,
    IWorkflowChangeStatus,
    IWorkflowOperation,
} from '@modules/communities/models/workflow/workflow.model';
import { IUser } from '@modules/core';
import { Member } from '@modules/core/models/member/member.model';
import { IEventHistory } from '@modules/core/models/notification-user.model';
import { IGenericCommunityFilter } from '@modules/core/models/user-autocomplete.model';
import { IUserAction } from '@modules/core/models/user-group.model';
import {
    AttachmentCategoryType,
    IAttachment,
    IAttachmentListEditResponse,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import {
    ExtendedPostVisibilityMembers,
    IBasePost,
    IPostCapability,
    InvitationsList,
    PostVisibilityCounters,
    RelatedItemsCounts as RelatedItemsCount,
    VisibilityType,
} from '@modules/post/models/base-post.model';
import {
    IComment,
    ICommentEditResponse,
    ITranslatedComment,
    StreamingCommentsList,
} from '@modules/post/models/comment.model';
import { ICustomPost } from '@modules/post/models/custom-post.model';
import {
    EventParticipateType,
    PostInvitationsMembersEdit,
} from '@modules/post/models/event-post.model';
import { AttachmentEntityType } from '@modules/post/models/filter-attachment.model';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import { GenericPost } from '@modules/post/models/generic-post.model';
import { IEditPostLinkSourcePost } from '@modules/post/models/post-link/post-link.model';
import { PostList } from '@modules/post/models/post-list.model';
import {
    SurveyQuestionDefinition,
    SurveyType,
} from '@modules/post/models/survey-post/survey-post.model';
import { GallerySourceFeature } from '@modules/state/models/gallery-source.model';
import { IFilePickerAttachment } from '@modules/state/models/gallery-state.model';
import {
    IAcknowledgeTaskConfirmResponse,
    ITaskType,
    SurveyTaskConfirmResponse,
} from '@modules/tasks/models/task.model';
import { createAction, props } from '@ngrx/store';
import { PostFetch } from './post.reducer';

enum PostActionAPIType {
    FetchPostsSuccess = '[Post API] Fetch Posts Success',
    FetchPostsError = '[Post API] Fetch Posts Error',

    FetchPostsPageSuccess = '[Post API] Fetch Posts Page Success',
    FetchPostsPageError = '[Post API] Fetch Posts Page Error',

    FetchPostsCountSuccess = '[Post API] Fetch Posts Count Success',
    FetchPostsCountError = '[Post API] Fetch Posts Count Error',

    ChangeFiltersSuccess = '[Post API] Change Filters Success',

    LikeToggleSuccess = '[Post API] Like Toggle Success',
    LikeToggleError = '[Post API] Like Toggle Error',

    FollowToggleSuccess = '[Post API] Follow Toggle Success',
    FollowToggleError = '[Post API] Follow Toggle Error',

    ParticipateEventSuccess = '[Post API] Participate Event Success',
    ParticipateEventError = '[Post API] Participate Event Error',

    PinToggleSuccess = '[Post API] Pin Toggle Success',
    PinToggleError = '[Post API] Pin Toggle Error',

    VisibilityChangeSuccess = '[Post API] Visibility Change Success',
    VisibilityChangeError = '[Post API] Visibility Change Error',

    TranslatePostSuccess = '[Post API] Translate Success',
    TranslatePostError = '[Post API] Translate Error',

    UntranslatePostSuccess = '[Post API] Untranslate Success',
    UntranslatePostError = '[Post API] Untranslate Error',

    AddWatchersSuccess = '[Post API] Add Watchers Success',
    AddWatchersError = '[Post API] Add Watchers Error',

    RemoveWatchersSuccess = '[Post API] Remove Watchers Success',
    RemoveWatchersError = '[Post API] Remove Watchers Error',

    AttachmentsEditSuccess = '[Post API] Attachments Edit Success',
    AttachmentsEditError = '[Post API] Attachments Edit Error',

    AddAttachmentsSuccess = '[Post API] Attachments Add Success',
    AddAttachmentsError = '[Post API] Attachments Add Error',

    DeleteAttachmentsSuccess = '[Post API] Attachments Delete Success',
    DeleteAttachmentsError = '[Post API] Attachments Delete Error',

    ReloadSuccess = '[Post API] ReloadSuccess',
    ReloadError = '[Post API] ReloadError',

    FetchCommentsSuccess = '[Post API] Fetch Comments Success',
    FetchCommentsError = '[Post API] Fetch Comments Error',

    FetchStreamingCommentsSuccess = '[Post API] Fetch Streaming Comments Success',
    FetchStreamingCommentsError = '[Post API] Fetch Streaming Comments Error',

    LikeToggleCommentSuccess = '[Post API] Like Toggle Comment Success',
    LikeToggleCommentError = '[Post API] Like Toggle Comment Error',

    DeleteCommentSuccess = '[Post API] Delete Comment Success',
    DeleteCommentError = '[Post API] Delete Comment Error',

    TranslateCommentSuccess = '[Post API] Translate Comment Success',
    TranslateCommentError = '[Post API] Translate Comment Error',

    UntranslateCommentSuccess = '[Post API] Untranslate Comment Success',
    UntranslateCommentError = '[Post API] Untranslate Comment Error',

    AddStreamingCommentSuccess = '[Post API] Add Streaming Comment Success',
    AddCommentSuccess = '[Post API] Add Comment Success',
    AddCommentError = '[Post API] Add Comment Error',

    SaveEditCommentSuccess = '[Post API] Save Edit Comment Success',
    SaveEditCommentError = '[Post API] Save Edit Comment Error',

    FetchAttachmentsSuccess = '[Post API] Fetch Attachments Success',
    FetchAttachmentsError = '[Post API] Fetch Attachments Error',

    FetchAttachmentsPageSuccess = '[Post API] Fetch Attachments Page Success',
    FetchAttachmentsPageError = '[Post API] Fetch Attachments Page Error',

    MarkAsViewedSuccess = '[Post API] Mark As Viewed Success',
    MarkAsViewedError = '[Post API] Mark As Viewed Error',

    FetchViewersSuccess = '[Post API] Fetch Viewers Success',
    FetchViewersError = '[Post API] Fetch Viewers Error',

    FetchLikersSuccess = '[Post API] Fetch Likers Success',
    FetchLikersError = '[Post API] Fetch Likers Error',

    FetchParticipantsSuccess = '[Post API] Fetch Participants Success',
    FetchParticipantsError = '[Post API] Fetch Participants Error',

    DeleteSuccess = '[Post API] Delete Success',
    DeleteError = '[Post API] Delete Error',

    MassiveDeletePostsSuccess = '[Post API] Massive Delete Posts Success',
    MassiveDeletePostsError = '[Post API] Massive Delete Posts Error',

    FetchLinksAndRelationsCountSuccess = '[Post API] Fetch Links And Relations Count Success',
    FetchLinksAndRelationsCountError = '[Post API] Fetch Links And Relations Count Error',

    FetchLinkedPostsSuccess = '[Post API] Fetch Linked Posts Success',
    FetchLinkedPostsError = '[Post API] Fetch Linked Posts Error',

    AddLinkedPostSuccess = '[Post API] Add Linked Post Success',
    AddLinkedPostError = '[Post API] Add Linked Post Error',

    RemovePostsFromRelationsSuccess = '[Post API] Remove Posts From Relations Success',
    RemovePostsFromRelationsError = '[Post API] Remove Posts From Relations Error',

    ConfirmAcknowledgeTaskSuccess = '[Post API] Confirm Acknowledge Task Success',
    ConfirmAcknowledgeTaskError = '[Post API] Confirm Acknowledge Task Error',

    ConfirmSurveyTaskSuccess = '[Post API] Confirm Survey Task Success',
    ConfirmSurveyTaskError = '[Post API] Confirm Survey Task Error',

    FetchWorkflowHistorySuccess = '[Post API] Get Workflow History Success',
    FetchWorkflowHistoryError = '[Post API] Get Workflow History Error',
    FetchCommentLikersSuccess = '[Post API] Fetch Comment Likers Success',
    FetchCommentLikersError = '[Post API] Fetch Comment Likers Error',

    CreateSuccess = '[Post API] Create Success',
    CreateError = '[Post API] Create Error',

    EditSuccess = '[Post API] Edit Success',
    EditError = '[Post API] Edit Error',

    CopySuccess = '[Post API] Copy Success',
    CopyError = '[Post API] Copy Error',

    WorkflowTransitionSuccess = '[Post API] Workflow Transition Success',
    WorkflowTransitionError = '[Post API] Workflow Transition Error',

    EditWorkflowScreenSuccess = '[Post API] Edit Workflow Screen Success',
    EditWorkflowScreenError = '[Post API] Edit Workflow Screen Error',

    FetchVisibilityCountersSuccess = '[Post API] Fetch Visibility Counters Success',
    FetchVisibilityCountersError = '[Post API] Fetch Visibility Counters Error',

    FetchCommunityViewersListSuccess = '[Post API] Fetch Community Viewers List Success',
    FetchCommunityViewersListError = '[Post API] Fetch Community Viewers List Error',

    FetchOthersVisibilityListSuccess = '[Post API] Fetch Others Visibility List Success',
    FetchOthersVisibilityListError = '[Post API] Fetch Others Visibility List Error',

    FetchTasksAssigneesListSuccess = '[Post API] Fetch Tasks Assignees List Success',
    FetchTasksAssigneesListError = '[Post API] Fetch Tasks Assignees List Error',

    FetchAckTasksAssigneesListSuccess = '[Post API] Fetch Ack Tasks Assignees List Success',
    FetchAckTasksAssigneesListError = '[Post API] Fetch Ack Tasks Assignees List Error',

    FetchSurveyTasksAssigneesListSuccess = '[Post API] Fetch Survey Tasks Assignees List Success',
    FetchSurveyTasksAssigneesListError = '[Post API] Fetch Survey Tasks Assignees List Error',

    FetchMentionsListSuccess = '[Post API] Fetch Mentions List Success',
    FetchMentionsListError = '[Post API] Fetch Mentions List Error',

    GetStreamingVideoSuccess = '[Post API] Get Streaming Video Success',
    GetStreamingVideoError = '[Post API] Get Streaming Video Error',

    FetchInvitationsListSuccess = '[Post API] Fetch Invitations Success',
    FetchInvitationsListError = '[Post API] Fetch Invitations Error',

    FetchGroupInvitationsListSuccess = '[Post API] Fetch Group Invitations Success',
    FetchGroupInvitationsListError = '[Post API] Fetch Group Invitations Error',

    EditInvitationsListSuccess = '[Post API] Edit Invitations List Success',
    EditInvitationsListError = '[Post API] Edit Invitations List Error',

    FetchFilePickerTemporaryContentViewLinkSuccess = '[Post API] Fetch File Picker TemporaryContentViewLink Success',
    FetchFilePickerTemporaryContentViewLinkError = '[Post API] Fetch File Picker TemporaryContentViewLink Error',

    CloseSurveySuccess = '[Post API] Close Survey Success',
    CloseSurveyError = '[Post API] Close Survey Error',

    FetchPostsCapabilitiesSuccess = '[Post API] Fetch Posts Capabilities Success',
    FetchPostsCapabilitiesError = '[Post API] Fetch Posts Capabilities Error',
}

export const fetchPostsSuccess = createAction(
    PostActionAPIType.FetchPostsSuccess,
    props<{
        fetchType: PostFetch;
        postList: PostList;
        filters?: IPostFilters;
        skipComments?: boolean;
    }>(),
);

export const fetchPostsError = createAction(
    PostActionAPIType.FetchPostsError,
    props<{ fetchType: PostFetch; error: PostError; postId?: number }>(),
);

export const fetchPostsPageSuccess = createAction(
    PostActionAPIType.FetchPostsPageSuccess,
    props<{
        fetchType: PostFetch;
        postList: PostList;
        skipComments?: boolean;
    }>(),
);

export const fetchPostsPageError = createAction(
    PostActionAPIType.FetchPostsPageError,
    props<{ fetchType: PostFetch; error: PostError }>(),
);

export const fetchPostsCountSuccess = createAction(
    PostActionAPIType.FetchPostsCountSuccess,
    props<{ fetchType: PostFetch; totalCount: number }>(),
);

export const fetchPostsCountError = createAction(
    PostActionAPIType.FetchPostsCountError,
    props<{ fetchType: PostFetch; error: PostError }>(),
);

export const changeFiltersSuccess = createAction(
    PostActionAPIType.ChangeFiltersSuccess,
    props<{
        fetchType: PostFetch;
        updatedFilters: IPostFilters;
        showTip?: {
            title: string;
            message: string;
            translateParams?: Record<string, unknown>;
        };
        clearPreviousFilter?: boolean;
    }>(),
);

export const likeToggleSuccess = createAction(
    PostActionAPIType.LikeToggleSuccess,
    props<{ postId: number; currUser: IUser }>(),
);

export const likeToggleError = createAction(
    PostActionAPIType.LikeToggleError,
    props<{ postId: number; currUser: IUser; error: PostError }>(),
);

export const followToggleSuccess = createAction(
    PostActionAPIType.FollowToggleSuccess,
    props<{ postId: number }>(),
);

export const followToggleError = createAction(
    PostActionAPIType.FollowToggleError,
    props<{ postId: number; error: PostError }>(),
);

export const participateEventSuccess = createAction(
    PostActionAPIType.ParticipateEventSuccess,
    props<{ postId: number; typeId: EventParticipateType; notes?: string }>(),
);

export const participateEventError = createAction(
    PostActionAPIType.ParticipateEventError,
    props<{
        postId: number;
        previousParticipateType: EventParticipateType | null;
        previousParticipantsList: PaginatedList<IUserAction>;
        error: PostError;
    }>(),
);

export const pinToggleSuccess = createAction(
    PostActionAPIType.PinToggleSuccess,
    props<{ postId: number }>(),
);

export const pinToggleError = createAction(
    PostActionAPIType.PinToggleError,
    props<{ postId: number; error: PostError }>(),
);

export const visibilityChangeSuccess = createAction(
    PostActionAPIType.VisibilityChangeSuccess,
    props<{
        postId: number;
        visibility: VisibilityType;
        nextOccToken: number;
    }>(),
);

export const visibilityChangeError = createAction(
    PostActionAPIType.VisibilityChangeError,
    props<{ postId: number; visibility: VisibilityType; error: PostError }>(),
);

export const translatePostSuccess = createAction(
    PostActionAPIType.TranslatePostSuccess,
    props<{ postId: number; updatedPost: IBasePost }>(),
);

export const translatePostError = createAction(
    PostActionAPIType.TranslatePostError,
    props<{ postId: number; error: PostError }>(),
);

export const untranslatePostSuccess = createAction(
    PostActionAPIType.UntranslatePostSuccess,
    props<{ postId: number; updatedPost: IBasePost }>(),
);

export const untranslatePostError = createAction(
    PostActionAPIType.UntranslatePostError,
    props<{ postId: number; error: PostError }>(),
);

export const addWatchersSuccess = createAction(
    PostActionAPIType.AddWatchersSuccess,
    props<{ postId: number; watchers: Member[] }>(),
);

export const addWatchersError = createAction(
    PostActionAPIType.AddWatchersError,
    props<{ postId: number; watchers: Member[]; error: PostError }>(),
);

export const removeWatchersSuccess = createAction(
    PostActionAPIType.RemoveWatchersSuccess,
    props<{ postId: number; watchers: Member[] }>(),
);

export const removeWatchersError = createAction(
    PostActionAPIType.RemoveWatchersError,
    props<{ postId: number; watchers: Member[]; error: PostError }>(),
);

export const attachmentsEditSuccess = createAction(
    PostActionAPIType.AttachmentsEditSuccess,
    props<{
        postId: number;
        attachmentsEdit: IAttachmentListEditResponse;
    }>(),
);

export const attachmentsEditError = createAction(
    PostActionAPIType.AttachmentsEditError,
    props<{ postId: number; error: PostError }>(),
);

export const addAttachmentsSuccess = createAction(
    PostActionAPIType.AddAttachmentsSuccess,
    props<{
        postId: number;
        attachments: IAttachment[];
        nextOccToken: number;
        uploadSessionId?: string;
        showTip?: boolean;
    }>(),
);

export const addAttachmentsError = createAction(
    PostActionAPIType.AddAttachmentsError,
    props<{
        postId: number;
        attachments: IAttachment[];
        error: PostError;
        uploadSessionId?: string;
        showTip?: boolean;
    }>(),
);

export const deleteAttachmentsSuccess = createAction(
    PostActionAPIType.DeleteAttachmentsSuccess,
    props<{
        postId: number;
        deletedIds: IAttachment['id'][];
    }>(),
);

export const deleteAttachmentsError = createAction(
    PostActionAPIType.DeleteAttachmentsError,
    props<{ postId: number; error: PostError }>(),
);

export const reloadSuccess = createAction(
    PostActionAPIType.ReloadSuccess,
    props<{
        postId: number;
        updatedPost: IBasePost;
        reloadComments: boolean;
        parentCommentId?: number;
    }>(),
);

export const reloadError = createAction(
    PostActionAPIType.ReloadError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchCommentsSuccess = createAction(
    PostActionAPIType.FetchCommentsSuccess,
    props<{
        postId: number;
        commentsList: PaginatedList<IComment>;
        pageToken?: string;
    }>(),
);

export const fetchCommentsError = createAction(
    PostActionAPIType.FetchCommentsError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchStreamingCommentsSuccess = createAction(
    PostActionAPIType.FetchStreamingCommentsSuccess,
    props<{
        postId: number;
        commentsList: StreamingCommentsList;
        pageToken?: string;
        syncToken?: string;
    }>(),
);

export const fetchStreamingCommentsError = createAction(
    PostActionAPIType.FetchStreamingCommentsError,
    props<{ postId: number; error: PostError }>(),
);

export const likeToggleCommentSuccess = createAction(
    PostActionAPIType.LikeToggleCommentSuccess,
    props<{ postId: number; comment: IComment }>(),
);

export const likeToggleCommentError = createAction(
    PostActionAPIType.LikeToggleCommentError,
    props<{ postId: number; comment: IComment; error: PostError }>(),
);

export const deleteCommentSuccess = createAction(
    PostActionAPIType.DeleteCommentSuccess,
    props<{ postId: number; comment: IComment }>(),
);

export const deleteCommentError = createAction(
    PostActionAPIType.DeleteCommentError,
    props<{
        postId: number;
        commentIdx: number;
        comment: IComment;
        error: PostError;
    }>(),
);

export const translateCommentSuccess = createAction(
    PostActionAPIType.TranslateCommentSuccess,
    props<{
        postId: number;
        comment: IComment;
        translatedComment: ITranslatedComment;
    }>(),
);

export const translateCommentError = createAction(
    PostActionAPIType.TranslateCommentError,
    props<{ postId: number; comment: IComment; error: PostError }>(),
);

export const untranslateCommentSuccess = createAction(
    PostActionAPIType.UntranslateCommentSuccess,
    props<{
        postId: number;
        comment: IComment;
        untranslatedComment: IComment;
    }>(),
);

export const untranslateCommentError = createAction(
    PostActionAPIType.UntranslateCommentError,
    props<{ postId: number; comment: IComment; error: PostError }>(),
);

export const addCommentSuccess = createAction(
    PostActionAPIType.AddCommentSuccess,
    props<{
        postId: number;
        createdComment: IComment;
        location: 'dashboard' | 'detail';
        followedByMe: boolean;
    }>(),
);

export const addCommentError = createAction(
    PostActionAPIType.AddCommentError,
    props<{ postId: number; error: PostError }>(),
);

export const addStreamingCommentSuccess = createAction(
    PostActionAPIType.AddStreamingCommentSuccess,
    props<{
        postId: number;
        createdComment: IComment;
        followedByMe: boolean;
    }>(),
);

export const saveEditCommentSuccess = createAction(
    PostActionAPIType.SaveEditCommentSuccess,
    props<{
        postId: number;
        updatedComment: ICommentEditResponse;
    }>(),
);

export const saveEditCommentError = createAction(
    PostActionAPIType.SaveEditCommentError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchAttachmentsSuccess = createAction(
    PostActionAPIType.FetchAttachmentsSuccess,
    props<{
        postId: number;
        attachmentsList: IListAttachments;
        attachmentType?: AttachmentCategoryType;
        entityTypes: AttachmentEntityType[];
    }>(),
);

export const fetchAttachmentsError = createAction(
    PostActionAPIType.FetchAttachmentsError,
    props<{
        postId: number;
        error: PostError;
        attachmentType?: AttachmentCategoryType;
        entityTypes: AttachmentEntityType[];
    }>(),
);

export const fetchAttachmentsPageSuccess = createAction(
    PostActionAPIType.FetchAttachmentsPageSuccess,
    props<{
        postId: number;
        attachmentsList: IListAttachments;
        attachmentType?: AttachmentCategoryType;
        entityTypes: AttachmentEntityType[];
    }>(),
);

export const fetchAttachmentsPageError = createAction(
    PostActionAPIType.FetchAttachmentsPageError,
    props<{
        postId: number;
        error: PostError;
        attachmentType?: AttachmentCategoryType;
        entityTypes: AttachmentEntityType[];
    }>(),
);

export const markAsViewedSuccess = createAction(
    PostActionAPIType.MarkAsViewedSuccess,
    props<{
        postId: number;
        viewersCount: number;
        viewedTimestamp: number;
        currUser: IUser;
    }>(),
);

export const markAsViewedError = createAction(
    PostActionAPIType.MarkAsViewedError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchViewersSuccess = createAction(
    PostActionAPIType.FetchViewersSuccess,
    props<{
        postId: number;
        viewersList: PaginatedList<IUserAction>;
        pageToken?: string;
        name?: string;
    }>(),
);

export const fetchViewersError = createAction(
    PostActionAPIType.FetchViewersError,
    props<{
        postId: number;
        pageToken?: string;
        name?: string;
        error: PostError;
    }>(),
);

export const fetchLikersSuccess = createAction(
    PostActionAPIType.FetchLikersSuccess,
    props<{
        postId: number;
        likersList: PaginatedList<IUserAction>;
        pageToken?: string;
        name?: string;
    }>(),
);

export const fetchLikersError = createAction(
    PostActionAPIType.FetchLikersError,
    props<{
        postId: number;
        pageToken?: string;
        name?: string;
        error: PostError;
    }>(),
);

export const fetchParticipantsSuccess = createAction(
    PostActionAPIType.FetchParticipantsSuccess,
    props<{
        postId: number;
        participantsList: PaginatedList<IUserAction>;
        typeId: EventParticipateType | null;
        pageToken?: string;
        name?: string;
    }>(),
);

export const fetchParticipantsError = createAction(
    PostActionAPIType.FetchParticipantsError,
    props<{
        postId: number;
        typeId: EventParticipateType | null;
        pageToken?: string;
        name?: string;
        error: PostError;
    }>(),
);

export const deletePostSuccess = createAction(
    PostActionAPIType.DeleteSuccess,
    props<{
        postId: number;
        communityId?: number;
        navigateToCommunity: boolean;
    }>(),
);

export const deletePostError = createAction(
    PostActionAPIType.DeleteError,
    props<{ postId: number; error: PostError }>(),
);

export const massiveDeletePostsSuccess = createAction(
    PostActionAPIType.MassiveDeletePostsSuccess,
    props<{
        postIds: number[];
    }>(),
);

export const massiveDeletePostsError = createAction(
    PostActionAPIType.MassiveDeletePostsError,
    props<{ postIds: number[]; error: PostError }>(),
);

export const fetchLinkedPostsSuccess = createAction(
    PostActionAPIType.FetchLinkedPostsSuccess,
    props<{
        postId: number;
        fetchPageToken: PageTokenInfo;
        items: {
            communityId: number;
            posts: PaginatedList<IPostLinkAndRelation>;
        };
    }>(),
);

export const fetchLinkedPostsError = createAction(
    PostActionAPIType.FetchLinkedPostsError,
    props<{ postId: number; communityId: number; error: PostError }>(),
);

export const fetchLinksAndRelationsCountSuccess = createAction(
    PostActionAPIType.FetchLinksAndRelationsCountSuccess,
    props<{
        postId: number;
        linksAndRelationsCount: RelatedItemsCount;
    }>(),
);

export const fetchLinksAndRelationsCountError = createAction(
    PostActionAPIType.FetchLinksAndRelationsCountError,
    props<{
        postId: number;
        error: PostError;
    }>(),
);

export const addLinkedPostsSuccess = createAction(
    PostActionAPIType.AddLinkedPostSuccess,
    props<{
        posts: IPostLinkAndRelation[];
        field: ICustomFieldDefinition;
        linkedPost: IPostLinkAndRelation;
        linksSuccess: IEditPostLinkSourcePost[];
        linksErrorValidation: IEditPostLinkSourcePost[];
        linksErrorConcurrency: IEditPostLinkSourcePost[];
    }>(),
);

export const addLinkedPostsError = createAction(
    PostActionAPIType.AddLinkedPostError,
    props<{
        posts: IPostLinkAndRelation[];
        error: PostError;
    }>(),
);

export const removePostsFromRelationsSuccess = createAction(
    PostActionAPIType.RemovePostsFromRelationsSuccess,
    props<{
        post: IPostLinkAndRelation;
        nextOccToken: number;
        fieldsDefinition: ICustomFieldDefinition[];
    }>(),
);

export const removePostsFromRelationsError = createAction(
    PostActionAPIType.RemovePostsFromRelationsError,
    props<{
        post: IPostLinkAndRelation;
        error: PostError;
    }>(),
);

export const confirmAcknowledgeTaskSuccess = createAction(
    PostActionAPIType.ConfirmAcknowledgeTaskSuccess,
    props<{
        postId: number;
        response: IAcknowledgeTaskConfirmResponse;
        fieldDefinition?: ICustomFieldDefinition[];
    }>(),
);

export const confirmSurveyTaskSuccess = createAction(
    PostActionAPIType.ConfirmSurveyTaskSuccess,
    props<{
        postId: number;
        response: SurveyTaskConfirmResponse;
        fieldDefinition?: SurveyQuestionDefinition[];
    }>(),
);

export const fetchWorkflowHistorySuccess = createAction(
    PostActionAPIType.FetchWorkflowHistorySuccess,
    props<{
        postId: number;
        workflowHistory: PaginatedList<IEventHistory>;
        prevPageToken?: string;
    }>(),
);

export const fetchWorkflowHistoryError = createAction(
    PostActionAPIType.FetchWorkflowHistoryError,
    props<{
        postId: number;
        error: PostError;
    }>(),
);

export const fetchCommentLikersSuccess = createAction(
    PostActionAPIType.FetchCommentLikersSuccess,
    props<{
        postId: number;
        commentId: number;
        likersList: PaginatedList<IUserAction>;
    }>(),
);

export const fetchCommentLikersError = createAction(
    PostActionAPIType.FetchCommentLikersError,
    props<{
        postId: number;
        commentId: number;
        error: PostError;
    }>(),
);

export const createSuccess = createAction(
    PostActionAPIType.CreateSuccess,
    props<{
        createdPost: GenericPost;
        redirectTo?: Extract<
            AppBaseRoute,
            'dashboard' | 'post-detail' | 'manage'
        >;
        successMessage?: string;
        showInVisibleCommunities: boolean;
    }>(),
);

export const createError = createAction(
    PostActionAPIType.CreateError,
    props<{
        error: PostError;
    }>(),
);

export const editSuccess = createAction(
    PostActionAPIType.EditSuccess,
    props<{
        editedPost: GenericPost;
        community: ICommunity;
        isPublishing: boolean;
        redirectTo?: Extract<
            AppBaseRoute,
            'dashboard' | 'post-detail' | 'manage'
        >;
    }>(),
);

export const editError = createAction(
    PostActionAPIType.EditError,
    props<{
        error: PostError;
    }>(),
);

export const copySuccess = createAction(
    PostActionAPIType.CopySuccess,
    props<{
        copiedPost: GenericPost;
        community: ICommunity;
        redirectTo?: Extract<
            AppBaseRoute,
            'dashboard' | 'post-detail' | 'manage'
        >;
        successMessage?: string;
        showInVisibleCommunities: boolean;
    }>(),
);

export const copyError = createAction(
    PostActionAPIType.CopyError,
    props<{
        error: PostError;
    }>(),
);

export const workflowTransitionSuccess = createAction(
    PostActionAPIType.WorkflowTransitionSuccess,
    props<{
        post: ICustomPost;
        transition: IWorkflowOperation;
        workflowChange: IWorkflowChangeStatus;
        screen?: IScreenEdit;
    }>(),
);

export const workflowTransitionError = createAction(
    PostActionAPIType.WorkflowTransitionError,
    props<{
        postId: number;
        error: PostError;
    }>(),
);

export const editWorkflowScreenSuccess = createAction(
    PostActionAPIType.EditWorkflowScreenSuccess,
    props<{
        post: ICustomPost;
        workflowChange: IWorkflowChangeScreenData;
    }>(),
);

export const editWorkflowScreenError = createAction(
    PostActionAPIType.EditWorkflowScreenError,
    props<{
        postId: number;
        error: PostError;
    }>(),
);

export const fetchVisibilityCountersSuccess = createAction(
    PostActionAPIType.FetchVisibilityCountersSuccess,
    props<{
        postId: number;
        counters: PostVisibilityCounters;
        communityId: number;
    }>(),
);

export const fetchVisibilityCountersError = createAction(
    PostActionAPIType.FetchVisibilityCountersError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchInvitationsListSuccess = createAction(
    PostActionAPIType.FetchInvitationsListSuccess,
    props<{
        postId: number;
        members: InvitationsList;
    }>(),
);

export const fetchInvitationsListError = createAction(
    PostActionAPIType.FetchInvitationsListError,
    props<{ postId: number; error: PostError }>(),
);

export const editInvitationsListSuccess = createAction(
    PostActionAPIType.EditInvitationsListSuccess,
    props<{
        postId: number;
        params: PostInvitationsMembersEdit;
    }>(),
);

export const editInvitationsListError = createAction(
    PostActionAPIType.EditInvitationsListError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchGroupInvitationsListSuccess = createAction(
    PostActionAPIType.FetchGroupInvitationsListSuccess,
    props<{
        postId: number;
        groupId: number;
        members: InvitationsList;
    }>(),
);

export const fetchGroupInvitationsListError = createAction(
    PostActionAPIType.FetchGroupInvitationsListError,
    props<{ postId: number; groupId: number; error: PostError }>(),
);

export const fetchCommunityViewersListSuccess = createAction(
    PostActionAPIType.FetchCommunityViewersListSuccess,
    props<{
        postId: number;
        members: PaginatedList<Member>;
        filters?: IGenericCommunityFilter;
    }>(),
);

export const fetchCommunityViewersListError = createAction(
    PostActionAPIType.FetchCommunityViewersListError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchOthersVisibilityListSuccess = createAction(
    PostActionAPIType.FetchOthersVisibilityListSuccess,
    props<{ postId: number; members: ExtendedPostVisibilityMembers }>(),
);

export const fetchOthersVisibilityListError = createAction(
    PostActionAPIType.FetchOthersVisibilityListError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchTasksAssigneesListSuccess = createAction(
    PostActionAPIType.FetchTasksAssigneesListSuccess,
    props<{
        members: PaginatedList<Member>;
        taskType: ITaskType;
        postId: number;
    }>(),
);

export const fetchTasksAssigneesListError = createAction(
    PostActionAPIType.FetchTasksAssigneesListError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchMentionsListSuccess = createAction(
    PostActionAPIType.FetchMentionsListSuccess,
    props<{
        members: PaginatedList<Member>;
        postId: number;
    }>(),
);

export const fetchMentionsListError = createAction(
    PostActionAPIType.FetchMentionsListError,
    props<{ postId: number; error: PostError }>(),
);

export const getStreamingVideoSuccess = createAction(
    PostActionAPIType.GetStreamingVideoSuccess,
    props<{ postId: number; streamingVideoSrc: string }>(),
);

export const getStreamingVideoError = createAction(
    PostActionAPIType.GetStreamingVideoError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchFilePickerTemporaryContentViewLinkSuccess = createAction(
    PostActionAPIType.FetchFilePickerTemporaryContentViewLinkSuccess,
    props<{
        postId: number;
        filePicker: IFilePickerAttachment;
        source: Extract<
            GallerySourceFeature,
            'postFilePicker' | 'screenFilePicker' | 'linkedPostFilePicker'
        >;
        attachments: IAttachment[];
    }>(),
);
export const fetchFilePickerTemporaryContentViewLinkError = createAction(
    PostActionAPIType.FetchFilePickerTemporaryContentViewLinkError,
    props<{
        error: PostError;
    }>(),
);

export const closeSurveySuccess = createAction(
    PostActionAPIType.CloseSurveySuccess,
    props<{
        postId: number;
        closedTimestamp: Date;
        nextOccToken: number;
        surveyType: SurveyType;
    }>(),
);

export const closeSurveyError = createAction(
    PostActionAPIType.CloseSurveyError,
    props<{ postId: number; error: PostError }>(),
);

export const fetchPostsCapabilitiesSuccess = createAction(
    PostActionAPIType.FetchPostsCapabilitiesSuccess,
    props<{ postsCapabilities: Record<IBasePost['id'], IPostCapability> }>(),
);

export const fetchPostsCapabilitiesError = createAction(
    PostActionAPIType.FetchPostsCapabilitiesError,
    props<{ error: PostError }>(),
);
