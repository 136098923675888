import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    WindowPaginatedList,
    nextPageAlreadyFetched,
} from '@modules/shared-v2/helpers/window-paginated-list';

@Component({
    selector: 'interacta-window-paginator[paginatedList]',
    templateUrl: './window-paginator.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WindowPaginatorComponent<T> {
    @Input() paginatedList!: WindowPaginatedList<T>;
    @Output() prevPage = new EventEmitter<void>();
    @Output() nextPage = new EventEmitter<void>();

    min = Math.min;

    nextPageAlreadyFetched = nextPageAlreadyFetched;
}
