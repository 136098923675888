import { Injectable } from '@angular/core';
import { PartialSharedProfile, SharedProfile } from '@modules/core';
import { UsersService } from '@modules/core/services/users.service';
import { AppSelectors, AppState } from '@modules/core/store';
import { AppRouterState } from '@modules/core/store/app-router.serializer';
import { UserActivitiesService } from '@modules/profile/services/user-activities.service';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import {
    ProfilePreviewDialogState,
    initialState,
} from '../models/profile-preview-dialog-state.model';
import { IStateService } from './istate-service';
import { StateService } from './state.service';

@Injectable({ providedIn: 'root' })
export class ProfilePreviewDialogStateService
    implements IStateService<ProfilePreviewDialogState>
{
    readonly state: ProfilePreviewDialogState;
    private readonly _toggleUserFollow$ = new Subject<PartialSharedProfile>();
    private isAdmin = false;
    private route$: Observable<AppRouterState | undefined>;
    private destroy$ = new Subject<void>();

    private close$ = new Subject<void>();

    constructor(
        private userService: UsersService,
        private store: Store<AppState>,
        private userActivitiesService: UserActivitiesService,
        stateService: StateService,
    ) {
        this.state = stateService.profilePreviewDialogState;
        this.route$ = this.store.select(AppSelectors.selectRouteState);
    }

    get toggleUserFollow$(): Observable<PartialSharedProfile> {
        return this._toggleUserFollow$.asObservable();
    }

    initialize(): void {
        this.state.data.next(initialState());
        this.route$
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (route) => (this.isAdmin = route?.appBaseRoute === 'admin-v2'),
            );
    }

    flush(): void {
        this.state.data.next(initialState());
        this.destroy$.next();
    }

    isOpen(): boolean {
        return this.state.data.value.isOpen;
    }

    open(userId: number): Observable<boolean> {
        return new Observable((subscriber) => {
            this.state.data.next({
                isOpen: true,
                isFetching: true,
                isAdmin: this.isAdmin,
            });

            this.userService.getUserDetails(userId).subscribe({
                next: (_user: SharedProfile) => {
                    this.state.data.next({
                        isOpen: true,
                        isFetching: false,
                        user: _user,
                        isAdmin: this.isAdmin,
                    });
                },
                error: () =>
                    this.state.data.next({
                        isOpen: false,
                        isFetching: false,
                        user: undefined,
                        isAdmin: this.isAdmin,
                    }),
            });

            this.close$.pipe(first()).subscribe(() => {
                this.state.data.next({
                    ...this.state.data.value,
                    isOpen: false,
                });
                subscriber.next();
                subscriber.complete();
            });
        });
    }

    close(): void {
        this.close$.next();
    }

    toggleFollow(): void {
        const user = this.state.data.value.user;
        if (!user) {
            throw Error("can't toggleFollow on udefined user");
        }
        this.userActivitiesService.toggleUserFollow(user).subscribe({
            next: () => {
                const newUser = {
                    ...user,
                    followedByMe: !this.state.data.value.user?.followedByMe,
                };
                this.state.data.next({
                    ...this.state.data.value,
                    user: newUser,
                });

                this._toggleUserFollow$.next(newUser);
            },
            error: (error) => {
                throw error;
            },
        });
    }
}
