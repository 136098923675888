import { FocusMonitor } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
} from '@angular/core';
import {
    ControlValueAccessor,
    NgControl,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormControl,
} from '@angular/forms';
import { IconComponent } from '@interacta-shared/ui-icons';
import { isDefined } from '@interacta-shared/util';
import { TranslateModule } from '@ngx-translate/core';
import { add, isSameDay } from 'date-fns';
import { Subject, Subscription } from 'rxjs';
import { FormatInputTimePipe } from '../../pipes/format-input-time.pipe';
import { ButtonMenuComponent } from '../button-menu/button-menu.component';
import { MenuDecoratorComponent } from '../menu-decorator/menu-decorator.component';
import { MenuComponent } from '../menu/menu.component';

export interface IInputTimeV2Value {
    hours: number;
    minutes: number;
}
export type StepMode = 'minute' | 'quarter' | 'hour';
export type TimeFormat = '12H' | '24H';

@Component({
    standalone: true,
    imports: [
        OverlayModule,
        TranslateModule,
        ReactiveFormsModule,
        IconComponent,
        MenuComponent,
        ButtonMenuComponent,
        MenuDecoratorComponent,
        CommonModule,
        FormatInputTimePipe,
    ],
    selector: 'interacta-input-time-v2',
    templateUrl: './input-time-v2.component.html',
    styles: [
        `
            input[type='time']::-webkit-calendar-picker-indicator {
                background: none;
                position: absolute;
                right: 0.875rem;
                z-index: 1;
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTimeV2Component
    implements ControlValueAccessor, OnInit, OnDestroy, OnChanges
{
    static nextId = 0;

    @Input() minTime?: Date;
    @Input() maxTime?: Date;
    @Input() readonly?: boolean;
    // selectedDate is used to bind the timepicker to the DatePicker
    // NOTE: if selectedDate is not provided, the component will always use the current Date as reference, and minTime and maxTime might not work properly
    @Input() selectedDate: Date | null = null;
    @Input() editTimezone = false;
    @Input() set stepMode(mode: StepMode) {
        this._stepMode = mode;
        this.initTimeSteps();
    }
    get stepMode(): StepMode {
        return this._stepMode;
    }
    @Input()
    get value(): IInputTimeV2Value | null {
        const time: string = this.time.value;
        if (time) {
            return {
                hours: Number(time.substr(0, 2)),
                minutes: Number(time.substr(3, 2)),
            };
        } else {
            return null;
        }
    }
    set value(value: IInputTimeV2Value | null) {
        if (
            !value ||
            value.hours === null ||
            value.hours === undefined ||
            value.minutes === null ||
            value.minutes === undefined
        ) {
            this.time.setValue(null);
        } else {
            this.time.setValue(
                `${this.zeroPadding(value.hours)}:${this.zeroPadding(
                    value.minutes,
                )}`,
            );
        }
        this.timeChange.emit(value);
    }
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = !!req;
        this.stateChanges.next();
    }
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: boolean) {
        this._disabled = !!value;
        this._disabled ? this.time.disable() : this.time.enable();
        this.stateChanges.next();
    }
    @HostBinding() id = `mat-link-input-${InputTimeV2Component.nextId++}`;
    @HostBinding('attr.aria-describedby') describedBy = '';
    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    @HostListener('focusout') focusout() {
        this.timeChange.emit(this.value);
    }
    @HostListener('keyup.enter') keyupEnter() {
        this.timeChange.emit(this.value);
    }

    @Output() timeChange = new EventEmitter<IInputTimeV2Value | null>();

    time: UntypedFormControl;
    stateChanges = new Subject<void>();
    focused = false;
    timeSteps: IInputTimeV2Value[] = [];
    unfilteredTimeSteps: IInputTimeV2Value[] = [];
    isTimeMenuOpen = false;
    inputStepInSeconds = 60;
    timeFormat: TimeFormat = '24H';

    public get empty() {
        return this.time.value === null || this.time.value === undefined;
    }
    public get errorState(): boolean {
        return this.ngControl
            ? this.ngControl.errors !== null && !!this.ngControl.touched
            : false;
    }

    private _placeholder?: string;
    private _required = false;
    private _disabled = false;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private _onChange: (_: unknown) => void = () => {};
    private _stepMode: StepMode = 'minute';
    private subscriptions: Subscription[] = [];

    constructor(
        fb: UntypedFormBuilder,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        this.time = fb.control(null);
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    // @Implementation(ControlValueAccessor)
    writeValue(value: IInputTimeV2Value | null): void {
        this.value = value;
        this.cdr.markForCheck();
    }

    // @Implementation(ControlValueAccessor)
    registerOnChange(fn: (_: unknown) => void): void {
        this._onChange = fn;
    }

    // @Implementation(ControlValueAccessor)
    // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-function
    registerOnTouched(_fn: () => {}): void {}

    // @Implementation(ControlValueAccessor)
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cdr.markForCheck();
    }

    private zeroPadding(value: number): string | null {
        const result =
            value != null && value != undefined
                ? ('0' + value).slice(-2)
                : null;
        return result;
    }

    ngOnInit(): void {
        const dateString = new Date().toLocaleTimeString();
        this.timeFormat =
            dateString.match(/am|pm/i) || new Date().toString().match(/am|pm/i)
                ? '12H'
                : '24H';
        this.subscriptions.push(
            this.time.valueChanges.subscribe((_) => this._onChange(this.value)),
        );
        this.subscriptions.push(
            this.fm
                .monitor(this.elRef.nativeElement, true)
                .subscribe((origin) => {
                    this.focused = !!origin;
                    this.stateChanges.next();
                }),
        );
    }

    private setTimeSteps(
        minutesStep: number,
        startDate: Date,
        minTime?: Date,
        maxTime?: Date,
    ): IInputTimeV2Value[] {
        const dates: Date[] = [];
        startDate.setHours(0, 0, 0, 0);
        dates.push(startDate);

        while (isSameDay(startDate, add(startDate, { minutes: minutesStep }))) {
            startDate = add(startDate, { minutes: minutesStep });
            dates.push(startDate);
        }

        this.unfilteredTimeSteps = dates
            .map((date) => this.dateToTime(date))
            .filter((date): date is IInputTimeV2Value => date != null);

        return dates
            .filter((date) => {
                if (isDefined(minTime)) {
                    return date > minTime;
                } else {
                    return date;
                }
            })
            .filter((date) => {
                if (isDefined(maxTime)) {
                    return date < maxTime;
                } else {
                    return date;
                }
            })
            .map((date) => {
                return this.dateToTime(date);
            })
            .filter((date): date is IInputTimeV2Value => date != null);
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);
        this.subscriptions.forEach((s) => {
            s.unsubscribe();
        });
    }

    toggleTimeMenu(event: Event): void {
        if (this.stepMode !== 'minute') {
            event.preventDefault();
            this.isTimeMenuOpen = !this.isTimeMenuOpen;
        }
    }

    closeTimeMenu(): void {
        this.isTimeMenuOpen = false;
    }

    selectTimeStep(time: IInputTimeV2Value): void {
        this.value = time;
        this.closeTimeMenu();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            this.stepMode !== 'minute' &&
            (changes['minTime'] ||
                changes['maxTime'] ||
                changes['selectedDate'])
        ) {
            this.initTimeSteps();
            if (
                this.isTimeGreaterThan(
                    this.value,
                    this.dateToTime(this.maxTime),
                )
            ) {
                this.selectTimeStep(this.timeSteps[this.timeSteps.length - 1]);
            } else if (
                this.isTimeLessThan(
                    this.value,
                    this.dateToTime(this.minTime),
                ) &&
                this.selectedDate &&
                this.minTime &&
                this.minTime.getTime() > this.selectedDate.getTime()
            ) {
                this.selectTimeStep(this.timeSteps[0]);
            } else if (!!this.value && !this.timeMatchesStep(this.value)) {
                this.selectTimeStep(this.getNextStepFromTime(this.value));
            }
        }
    }

    initTimeSteps(): void {
        let step = 1;
        switch (this.stepMode) {
            case 'quarter':
                step = 15;
                break;
            case 'hour':
                step = 60;
                break;
            default:
                break;
        }
        this.timeSteps = this.setTimeSteps(
            step,
            this.selectedDate ?? new Date(),
            this.minTime,
            this.maxTime,
        );
        this.inputStepInSeconds = step * 60;
    }

    private dateToTime(
        date: Date | undefined | null,
    ): IInputTimeV2Value | undefined {
        return date
            ? {
                  hours: date.getHours(),
                  minutes: date.getMinutes(),
              }
            : undefined;
    }

    private isTimeLessThan(
        time1: IInputTimeV2Value | undefined | null,
        time2: IInputTimeV2Value | undefined | null,
    ): boolean {
        return time1 && time2
            ? time1.hours < time2.hours ||
                  (time1.hours === time2.hours && time1.minutes < time2.minutes)
            : false;
    }

    private isTimeGreaterThan(
        time1: IInputTimeV2Value | undefined | null,
        time2: IInputTimeV2Value | undefined | null,
    ): boolean {
        return time1 && time2
            ? time1.hours > time2.hours ||
                  (time1.hours === time2.hours && time1.minutes > time2.minutes)
            : false;
    }

    private timeMatchesStep(
        time: IInputTimeV2Value | undefined | null,
    ): boolean {
        return (
            this.unfilteredTimeSteps
                .filter((step) => step.hours === time?.hours)
                .findIndex((step) => step.minutes === time?.minutes) >= 0
        );
    }

    private getNextStepFromTime(time: IInputTimeV2Value): IInputTimeV2Value {
        return this.unfilteredTimeSteps
            .filter((step) => step.hours >= time.hours)
            .filter((step) => step.minutes >= time.minutes)[0];
    }
}
