<interacta-file-upload-input
    [acceptedMimeType]="acceptedMimeType"
    [inputId]="inputId"
    [uploader]="uploader"
    (fileSelected)="fileSelected.emit($event)"
></interacta-file-upload-input>
<ng-container *ngIf="driveIntegrationEnabled; else localFileOnly">
    <button
        #fileSelectModeButton="cdkOverlayOrigin"
        cdkOverlayOrigin
        interacta-button
        size="regular"
        type="button"
        [appearance]="appearance"
        [attr.aria-label]="label ?? tooltip"
        [icon]="icon"
        [label]="label"
        [matTooltip]="tooltip ?? ''"
        [matTooltipDisabled]="!tooltip"
        [shape]="shape"
        (click)="fileSelectModeMenuOpen = true"
    ></button>
    <interacta-menu
        positionX="before"
        positionY="below"
        [open]="fileSelectModeMenuOpen"
        [origin]="fileSelectModeButton"
        (closing)="fileSelectModeMenuOpen = false"
    >
        <interacta-menu-decorator>
            <label #labelTagMenu for="{{ inputId }}">
                <interacta-button-menu
                    icon="paperclip"
                    [label]="'POST_CREATE.CHOOSE_FILE' | translate"
                    (click)="
                        labelTagMenu.click(); fileSelectModeMenuOpen = false
                    "
                ></interacta-button-menu>
            </label>

            <interacta-button-menu
                icon="google-drive"
                [acceptedMimeType]="acceptedMimeType"
                [label]="'DASHBOARD.ATTACHMENTS.ADD_FROM_DRIVE' | translate"
                (click)="fileSelectModeMenuOpen = false"
                (googleDrivePickerV2)="driveAttachmentSelected.emit($event)"
            ></interacta-button-menu>
        </interacta-menu-decorator>
    </interacta-menu>
</ng-container>
<ng-template #localFileOnly>
    <label #labelTag for="{{ inputId }}">
        <button
            interacta-button
            type="button"
            [appearance]="appearance"
            [attr.aria-label]="
                label ?? ('ATTACHMENT.ADD_ATTACHMENTS' | translate)
            "
            [icon]="icon"
            [label]="label"
            [shape]="shape"
            [size]="size"
            (click)="labelTag.click(); $event.stopPropagation()"
        ></button>
    </label>
</ng-template>
