<interacta-menu
    positionX="after"
    positionY="below"
    [open]="open"
    [origin]="origin"
    (closing)="closeMenu()"
>
    <div class="bg-surface-A px-18 rounded-lg pb-28 pt-24 shadow-sm">
        <interacta-date-time
            [control]="control"
            [editTimezone]="editTimezone"
            [maxDate]="maxDate"
            [minDate]="minDate"
            [showFastSelect]="showFastSelect"
            [stepMode]="stepMode"
            [timezoneControl]="timezoneControl"
            [timezones]="timezones"
            [type]="type"
            [userTimezone]="userTimezone"
            (calendarDateClicked)="calendarDateClicked()"
        >
        </interacta-date-time>
    </div>
</interacta-menu>
