import {
    QuillText,
    i18nDelta,
    i18nDeltaV2,
    i18nString,
    i18nStringV2,
} from '@modules/core';
import { Delta2Server } from '@modules/core/helpers/delta/delta-2-server.class';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';

export const toI18nDeltaV2 = (record: any): i18nDeltaV2 => {
    const server2Delta = new Server2Delta();
    const i18ns: i18nDeltaV2['i18ns'] = {};
    // esiste la possibilità che il record sia solo una stringa contenente un delta (se il backend me la traduce a priori),
    // di conseguenza non ha i18ns né label, ma va solo processato
    if (record?.i18ns) {
        Object.keys(record.i18ns).forEach((k: string) => {
            i18ns[k] = server2Delta.process(record.i18ns[k]);
        });
    }

    const labelToProcess: string | null =
        typeof record === 'string' ? record : record?.label ?? null;
    return {
        label: server2Delta.process(labelToProcess),
        i18ns: Object.keys(i18ns).length ? i18ns : null,
    };
};

export const fromI18nDeltaV2 = (data: i18nDeltaV2): any => {
    const delta2Server = new Delta2Server();
    const i18ns: { [key: string]: string } = {};
    if (data.i18ns) {
        for (const k of Object.keys(data.i18ns)) {
            // ci possono essere i casi "/n" se ho cancellato un testo, la process restituisce un null in questo caso, quindi evito di mandarlo al BE
            const deltaLabel = delta2Server.process(data.i18ns[k]);
            if (deltaLabel) {
                i18ns[k] = deltaLabel;
            }
        }
    }
    return {
        label: delta2Server.process(data.label),
        i18ns: data.i18ns && Object.keys(i18ns).length ? i18ns : null,
    };
};

export const i18nStringToV2 = (str: i18nString | i18nStringV2): i18nStringV2 =>
    isI18nStringV2(str)
        ? str
        : typeof str === 'string'
          ? toI18nStringV2(str)
          : toI18nStringV2(str[Object.keys(str)[0]], str);

export const toI18nStringV2 = (
    label: i18nStringV2['label'],
    i18ns: i18nStringV2['i18ns'] | null = null,
): i18nStringV2 => ({
    label: label || null,
    i18ns,
});

export const isI18nStringV2 = (str: any): str is i18nStringV2 => {
    return str && str.label !== undefined && str.i18ns !== undefined;
};

export const i18nDeltaToV2 = (delta: i18nDelta): i18nDeltaV2 =>
    typeof delta === 'string'
        ? toI18nDeltaV2(delta)
        : toI18nDeltaV2({
              label: delta
                  ? (delta as { [key: string]: QuillText })[
                        Object.keys(delta)[0]
                    ]
                  : '',
              i18ns: delta,
          });

export const cleanEmptyI18nStringV2 = (value: i18nStringV2): i18nStringV2 => {
    return {
        ...value,
        i18ns: cleanEmptyLangV2(value.i18ns),
    };
};

export const cleanEmptyI18nDeltaV2 = (value: i18nDeltaV2): i18nDeltaV2 => {
    return {
        ...value,
        i18ns: cleanEmptyLangV2(value.i18ns),
    };
};

const cleanEmptyLangV2 = <
    T extends i18nStringV2['i18ns'] | i18nDeltaV2['i18ns'],
>(
    i18ns: T,
): T | null => {
    if (i18ns) {
        const i18nsCopy = { ...i18ns };
        for (const lang of Object.keys(i18nsCopy)) {
            if (!i18nsCopy[lang]) {
                delete i18nsCopy[lang];
            }
        }
        if (Object.keys(i18nsCopy).length > 0) {
            return i18nsCopy;
        }
    }
    return null;
};
