import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ErrorService,
    IValidationError,
} from '@interacta-shared/data-access-error';
import { TipService } from '@interacta-shared/feature-tip';
import { Size } from '@interacta-shared/ui';
import { Index } from '@interacta-shared/util';
import { LoadingLayerService } from '@interacta-shared/util-common';
import { fetchPosts } from '@modules/communities/store/post/post.actions';
import {
    CustomButtonType,
    ICustomButton,
} from '@modules/core/models/custom-button/custom-button.models';
import {
    CustomActionsService,
    ExecuteCustomActionResponseDTO,
} from '@modules/core/services/custom-actions.service';
import { SelectionPostType } from '@modules/post-table/components/multiple-selection-button/multiple-selection-button.component';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import { fromCommunityListFilter } from '@modules/post/models/filter-post/filter-post.serialize';
import { filterIsEmpty } from '@modules/post/models/filter-post/filter-post.utils';
import { Store } from '@ngrx/store';
import { EMPTY, firstValueFrom } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

type ButtonMode = 'detail' | 'list' | 'lightbox';

@Component({
    selector: 'interacta-custom-button',
    templateUrl: './custom-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomButtonComponent {
    @Input({ required: true }) customButton!: ICustomButton;
    @Input({ required: true }) mode!: ButtonMode;
    @Input({ required: true }) communityId!: number;
    @Input() size: Extract<Size, 'large' | 'regular' | 'small'> = 'small';
    @Input() ids: Index[] = [];
    @Input() selectionPostType: SelectionPostType = 'no-post';
    @Input() filters?: IPostFilters;

    constructor(
        private authService: AuthService,
        private customActionsService: CustomActionsService,
        private errorService: ErrorService,
        private tipService: TipService,
        private loadingLayerService: LoadingLayerService,
        private store: Store,
    ) {}

    executeAction(): void {
        if (this.customButton.url) {
            this.openCustomLink();
        } else {
            if (this.customButton.type === CustomButtonType.POST_ACTION) {
                this.callServer();
            }
        }
    }

    private async openCustomLink(): Promise<void> {
        let url = this.customButton.url;

        const _ids =
            this.ids.length > 0 && this.selectionPostType !== 'all'
                ? this.ids.toString()
                : '';
        const _filters =
            this.selectionPostType === 'all' &&
            this.filters &&
            !filterIsEmpty(this.filters, false)
                ? JSON.stringify(
                      fromCommunityListFilter(
                          this.filters,
                          this.authService.getCurrentUserData() || undefined,
                      ),
                  )
                : '';

        const _accessToken = url.includes('{accessToken}')
            ? await firstValueFrom(this.authService.renewAccessToken())
            : '';

        url = url.replace('{ids}', _ids);
        url = url.replace('{filters}', _filters ? btoa(_filters) : '');
        url = url.replace('{communityId}', this.communityId.toString());
        url = url.replace('{accessToken}', _accessToken);

        window.open(url);
    }

    private callServer(): void {
        this.loadingLayerService.open({
            firstLabel: '',
        });

        const postIds =
            this.ids.length > 0 && this.selectionPostType !== 'all'
                ? this.ids
                : null;

        const filters: IPostFilters | null =
            this.selectionPostType === 'all' &&
            this.filters &&
            !filterIsEmpty(this.filters, false)
                ? this.filters
                : null;

        this.customActionsService
            .callCustomActionsApi(
                this.communityId,
                filters,
                this.customButton.id,
                postIds,
            )
            .pipe(
                catchError((error: IValidationError) => {
                    this.errorService.handle(error);
                    return EMPTY;
                }),
                finalize(() => this.loadingLayerService.close()),
            )
            .subscribe((response: ExecuteCustomActionResponseDTO) => {
                this.tipService.success(response.message);

                if (
                    response.postDataHasChanged &&
                    this.mode === 'detail' &&
                    this.ids.length === 1
                )
                    this.store.dispatch(
                        fetchPosts({
                            fetchType: 'detail',
                            postId: this.ids[0] as number,
                        }),
                    );
            });
    }
}
