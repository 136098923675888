<interacta-input-date-time
    [canClear]="canClear"
    [control]="control"
    [isReadonly]="isReadonly"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [showFastSelect]="showFastSelect"
    [size]="size"
    [timezones]="[]"
    [type]="'date'"
    [userTimezone]="null"
></interacta-input-date-time>
