import { isDefined } from '@interacta-shared/util';
import {
    IPostView,
    PostViewScenario,
    PostViewScenarioIndex,
} from './post-view.model';

export const toPostView = (record: any): IPostView => ({
    id: record.id,
    name: record.name,
    titleEnabled: record.titleEnabled,
    customIdEnabled: record.customIdEnabled,
    descriptionEnabled: record.descriptionEnabled,
    currentWorkflowStateEnabled: record.currentWorkflowStateEnabled,
    postFieldIds: record.postFieldIds ?? [],
});

export const toPostViewScenarioIndex = (
    record: any[],
): PostViewScenarioIndex => {
    const result: PostViewScenarioIndex = {};

    record.forEach((v) => {
        const postView = toPostView(v);
        v.scenarios?.forEach((s: number) => {
            if (!Object.values(PostViewScenario).includes(s)) {
                throw Error(
                    `Scenario ${s} not supported for view ${postView.id}`,
                );
            }
            const scenario: PostViewScenario = s;
            if (isDefined(result[s])) {
                throw Error(
                    `Multiple views [${result[scenario].id}, ${postView.id}] defined for the same scenario ${scenario}`,
                );
            }
            result[s] = postView;
        });
    });
    return result;
};
