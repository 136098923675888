export const toSnakeCase = (s: string): string =>
    s
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join('_');

export const toSnakeUpperCase = (s: string): string =>
    toSnakeCase(s).toUpperCase();

export const toKebabCase = (s: string): string =>
    s
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join('-');
