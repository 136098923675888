<div class="flex flex-col space-y-16">
    <button
        class="flex items-center"
        type="button"
        [ngClass]="{
            'typo-t4': size === 'extra-large',
            'typo-medium-bold': size === 'regular',
            'typo-sm-bold': size === 'small',
            'gap-x-8': size !== 'small',
            'gap-x-4': size === 'small',
            'text-text': colorPalette === 'textColor',
            'text-text-mid-contrast': colorPalette === 'gray-700'
        }"
        (click)="toggleOpen.emit()"
    >
        {{ label }}
        <interacta-icon
            [icon]="isOpen ? 'chevron-small-up' : 'chevron-small-down'"
            [ngClass]="{
                'h-24 w-24': ['regular', 'extra-large'].includes(size),
                'h-20 w-20': size === 'small'
            }"
        ></interacta-icon>
    </button>

    <div *ngIf="isOpen" @advanced>
        <ng-content></ng-content>
    </div>
</div>
