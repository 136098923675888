import { Directive, Injectable, OnDestroy, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TooltipCollector {
    collection = new Set<MatTooltip>();

    constructor(router: Router) {
        router.events
            .pipe(filter((e) => e instanceof NavigationStart))
            .subscribe(() => {
                for (const mt of this.collection) {
                    if (mt._tooltipInstance?.isVisible()) {
                        mt._overlayRef?.detach();
                        break;
                    }
                }
            });
    }
}

@Directive({
    selector: '[matTooltip]',
})
export class HideTooltipDirective implements OnInit, OnDestroy {
    constructor(
        private mt: MatTooltip,
        private tc: TooltipCollector,
    ) {}

    ngOnInit(): void {
        this.tc.collection.add(this.mt);
    }

    ngOnDestroy(): void {
        this.tc.collection.delete(this.mt);
    }
}
