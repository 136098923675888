import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { TipService } from '@interacta-shared/feature-tip';
import { uuid } from '@interacta-shared/util';
import {
    ContentMimeType,
    getPathColor,
    getPathIcon,
    isMediaAudio,
    isMediaGif,
    isMediaImage,
    isMediaVideo,
} from '@interacta-shared/util-common';
import {
    DrivePickerErrorCode,
    GoogleAPIService,
} from '@modules/core/services/google-api.service';
import {
    AttachmentStorageType,
    IAttachment,
} from '@modules/post/models/attachment/attachment.model';
import { map, mergeMap, toArray } from 'rxjs/operators';

@Directive({
    selector: '[googleDrivePickerV2]',
})
export class GoogleDrivePickerV2Directive {
    @Input() enableMultiselect = true;
    @Input() acceptedMimeType?: string[];

    @Output() googleDrivePickerV2 = new EventEmitter<IAttachment[]>();

    constructor(
        private gapiService: GoogleAPIService,
        private tipService: TipService,
    ) {}

    @HostListener('click') pickDriveObject(): void {
        this.gapiService
            .openDrivePicker(this.enableMultiselect, this.acceptedMimeType)
            .pipe(
                mergeMap((docs) => docs),
                mergeMap((doc) => this.gapiService.getFileDetails(doc)),
                map((googleDriveFile) => {
                    const addAttachment: IAttachment = {
                        id: uuid(),
                        type: AttachmentStorageType.DRIVE,
                        name: googleDriveFile.name ?? '',
                        inPending: true,
                        isDraft: true,
                        contentMimeType:
                            googleDriveFile.mimeType as ContentMimeType,
                        iconPath: getPathIcon(googleDriveFile.mimeType),
                        size: googleDriveFile.size ?? 0,
                        drive: {
                            name: googleDriveFile.name,
                            driveId: googleDriveFile.driveId,
                            mimeType: googleDriveFile.mimeType,
                            size: googleDriveFile.size,
                            thumbnailLinks: googleDriveFile.thumbnailLinks,
                            webContentLink: googleDriveFile.webContentLink,
                            webViewLink: googleDriveFile.webViewLink,
                        },
                        isMediaVideo: isMediaVideo(googleDriveFile.mimeType),
                        isMediaAudio: isMediaAudio(googleDriveFile.mimeType),
                        isMediaImage: isMediaImage(googleDriveFile.mimeType),
                        isMediaGif: isMediaGif(googleDriveFile.mimeType),
                        iconColor: getPathColor(googleDriveFile.mimeType),
                    };
                    return addAttachment;
                }),
                toArray(),
            )
            .subscribe(
                (success) => {
                    this.googleDrivePickerV2.emit(success);
                },
                (error) => {
                    if (
                        ['popup_closed_by_user', 'access_denied'].indexOf(
                            error?.error || [],
                        ) < 0
                    ) {
                        console.error(error);
                    }

                    if (
                        DrivePickerErrorCode.MATCH_INTERACTA_USER ===
                        error?.error
                    ) {
                        this.tipService.warn('GOOGLE_API.ERROR_USER_MISMATCH');
                    } else if (
                        DrivePickerErrorCode.NOT_ALLOWED_MIMETYPE ===
                        error?.error
                    ) {
                        this.tipService.info(
                            'UI.VALIDATION_MESSAGE.mimeTypeNotAdmitted',
                            'manual',
                        );
                    }
                },
            );
    }
}
