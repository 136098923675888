<div class="relative w-full" [ngClass]="{ 'pb-4': reminder }">
    <div *ngIf="showOtherLanguages" class="typo-xs">
        <button
            class="absolute right-0 line-clamp-1 break-words underline"
            type="button"
            (click)="isDialogOpen = true"
        >
            {{ 'ADMIN.LABEL_OTHER_LANGUAGES' | translate }}
        </button>
    </div>
    <interacta-form-field-v2
        name="interacta-translation"
        [control]="$any(form.controls[language.code])"
        [description]="description"
        [name]="
            (fieldName
                | required
                    : isFieldRequired &&
                          defaultLanguage.code === language.code) +
            ' (' +
            language.description +
            ')'
        "
    >
        <div class="relative">
            <div
                class="typo-xs bg-surface-100 aling-middle absolute left-8 top-8 z-10 inline-block h-24 w-24 rounded-full pt-4 text-center"
            >
                {{ language.code | uppercase }}
            </div>
            <ng-container [ngSwitch]="inputType">
                <ng-container *ngSwitchCase="TranslationInputType.STRING">
                    <interacta-input-text-v2
                        #inputText
                        name="interacta-translation"
                        [control]="$any(form.controls[language.code])"
                        [isReadonly]="readonly"
                        [withMarginLeft]="withMarginLeft"
                    >
                    </interacta-input-text-v2>
                </ng-container>
                <ng-container *ngSwitchCase="TranslationInputType.TEXTAREA">
                    <interacta-input-textarea-v2
                        name="interacta-translation"
                        [control]="$any(form.controls[language.code])"
                        [isReadonly]="readonly"
                        [withMarginLeft]="withMarginLeft"
                    >
                    </interacta-input-textarea-v2>
                </ng-container>
                <ng-container *ngSwitchCase="TranslationInputType.HTML">
                    <interacta-mention-delta-editor
                        [control]="$any(form.controls[language.code])"
                        [isReadonly]="readonly"
                        [plainTextMaxLength]="plainTextMaxLength"
                        [withBorders]="withBorders"
                        [withMarginLeft]="withMarginLeft"
                    ></interacta-mention-delta-editor>
                </ng-container>
            </ng-container>
        </div>
    </interacta-form-field-v2>
    <label *ngIf="reminder" class="typo-xs">
        {{ 'ADMIN.LABEL_REMINDER_OTHER_LANGUAGES' | translate }}</label
    >
    <interacta-input-translation-dialog-v2
        *ngIf="isDialogOpen"
        [defaultLanguage]="defaultLanguage"
        [fieldName]="fieldName"
        [form]="form"
        [inputType]="inputType"
        [isDialogOpen]="isDialogOpen"
        [isFieldRequired]="isFieldRequired"
        [languages]="languages"
        [readonly]="readonly"
        (closeDialog)="isDialogOpen = false; updateForm($event)"
    ></interacta-input-translation-dialog-v2>
</div>
