import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
    // transform(items: any[], filter: string, value: any, reverse : boolean): any {
    //     return items.filter(
    //         item => reverse ? !(item[filter] == value) : (item[filter] == value)
    //     );
    // }
    transform<T>(items: Array<T>, filter: Record<string, unknown>): Array<T> {
        return items.filter((item) => {
            const notMatchingField = Object.keys(filter).find(
                (key) => !this.strategyCompare((item as any)[key], filter[key]),
            );
            return !notMatchingField; // true if matches all fields
        });
    }
    strategyCompare<T>(item: T, filter: unknown): boolean {
        if (Array.isArray(item) && (!filter || item.indexOf(filter) > -1)) {
            return true;
        } else if (item === filter) {
            return true;
        }
        return false;
    }
}
