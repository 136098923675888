import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputSelectItem } from '@interacta-shared/ui';
import { areEqualObjects } from '@interacta-shared/util';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'interacta-other-filters-multiple-select',
    templateUrl: './other-filters-multiple-select.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherFiltersMultipleSelectComponent<T extends InputSelectItem>
    implements OnInit, OnChanges, OnDestroy
{
    @Input() filter: number[] | null = null;
    @Input() items: T[] = [];
    @Input() label = '';
    @Input() collapsed = false;
    @Input() orientation: 'vertical' | 'horizontal' = 'vertical';
    @Input() pinned?: boolean;
    @Input() pinnable?: boolean;
    @Input() itemsSpacing: 'small' | 'medium' | 'large' = 'large';
    @Input() disabled = false;

    @Output() togglePinned = new EventEmitter();
    @Output() toggleCollapsed = new EventEmitter();
    @Output() filterChanged = new EventEmitter<number[] | null>();

    filterControl = new FormControl<number[] | null>(null);

    private destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.filterControl.valueChanges
            .pipe(
                distinctUntilChanged((prev, curr) =>
                    areEqualObjects(prev ?? [], curr ?? []),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe((val) => this.filterChanged.emit(val));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filter) {
            this.filterControl.setValue(this.filter);
        }

        if (changes.disabled) {
            this.disabled
                ? this.filterControl.disable()
                : this.filterControl.enable();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
