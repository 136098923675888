import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Size } from '@interacta-shared/ui';
import { flatten } from '@interacta-shared/util';
import {
    IWorkflowDefinitionForFilter,
    IWorkflowStatus,
} from '@modules/communities/models/workflow/workflow.model';
import {
    difference,
    groupBy,
    union,
} from '@modules/core/helpers/generic.utils';
import {
    FilterCategories,
    FilterCategoryLabel,
    FilterStateCategory,
    FilterStateCategoryEnabled,
} from '@modules/post/models/filter-post/filter-post.model';
import { compareFilterStateCategory } from '@modules/post/models/filter-post/filter-post.utils';

function getCategoryLabel(category: FilterStateCategory): string {
    switch (category) {
        case 'todo':
            return 'WORKFLOW.LABEL_TO_DO';
        case 'inProgress':
            return 'WORKFLOW.LABEL_IN_PROGRESS';
        case 'finished':
            return 'WORKFLOW.LABEL_FINISHED';
    }
}

function nextCategory(
    category: FilterStateCategoryEnabled,
): FilterStateCategoryEnabled {
    switch (category) {
        case 'off':
            return 'on';
        case 'partial':
            return 'off';
        case 'on':
            return 'off';
    }
}

function stateToCategory(state: IWorkflowStatus): FilterStateCategory {
    if (state.initState) {
        return 'todo';
    } else if (state.terminal) {
        return 'finished';
    } else {
        return 'inProgress';
    }
}

function defaultCategories(): FilterCategories {
    return {
        todo: 'off',
        inProgress: 'off',
        finished: 'off',
    };
}

@Component({
    selector: 'interacta-status-categories',
    templateUrl: './status-categories.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCategoriesComponent implements OnChanges {
    @Input() selectedIds: IWorkflowStatus['id'][] = [];
    @Input() workflowDefinitions: IWorkflowDefinitionForFilter[] = [];
    @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
    @Input() size: Extract<Size, 'regular' | 'small'> = 'regular';
    @Input() filterUnavailableStates = false;
    @Input() disabled = false;

    @Output() selectedIdsAfterCategoryChange = new EventEmitter<
        IWorkflowStatus['id'][]
    >();

    categories: FilterCategoryLabel[] = [];
    categoriesState!: FilterCategories;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedIds || changes.workflowDefinitions) {
            const selectedIds = this.selectedIds || [];

            this.setState(
                [...selectedIds],
                this.getCategoriesState(this.getAvailableStates(), selectedIds),
                false,
            );
        }

        if (changes.workflowDefinitions) {
            const availableStates = this.getAvailableStates();

            const uniqCategories = new Set(
                availableStates.map(stateToCategory),
            );
            const exisitingCategories = [...uniqCategories];

            this.categories = exisitingCategories
                .map((category) => ({
                    type: category,
                    label: getCategoryLabel(category),
                }))
                .sort((a, b) => compareFilterStateCategory(a.type, b.type));
        }
    }

    onStateCategoryChange(category: FilterStateCategory): void {
        let predicate: (s: IWorkflowStatus) => boolean;
        switch (category) {
            case 'todo':
                predicate = (s) => s.initState;
                break;
            case 'inProgress':
                predicate = (s) => !s.initState && !s.terminal;
                break;
            case 'finished':
                predicate = (s) => s.terminal;
                break;
        }

        const currCategory = this.categoriesState[category];

        const prevIds = new Set(this.selectedIds);

        const currCategoryIds = new Set(
            this.getAvailableStates()
                .filter((s) => predicate(s))
                .map((s) => s.id),
        );

        const nextIds =
            currCategory === 'off'
                ? union(prevIds, currCategoryIds)
                : difference(prevIds, currCategoryIds);

        this.setState([...nextIds], {
            ...this.categoriesState,
            [category]: nextCategory(currCategory),
        });
    }

    getAvailableStates(): IWorkflowStatus[] {
        return flatten(this.workflowDefinitions.map((i) => i.states)).filter(
            (s) =>
                !this.filterUnavailableStates ||
                (this.filterUnavailableStates &&
                    !s.deleted &&
                    s.metadata.searchable),
        );
    }

    private getCategoriesState(
        statesAvailable: IWorkflowStatus[],
        selectedIds: IWorkflowStatus['id'][],
    ): FilterCategories {
        const categories = groupBy(statesAvailable, stateToCategory);

        const selectedIdsSet = new Set(selectedIds);

        const selectedStates: Record<FilterStateCategory, IWorkflowStatus[]> = {
            todo: [],
            inProgress: [],
            finished: [],
        };
        for (const [category, states] of Object.entries(categories)) {
            const cat = category as FilterStateCategory;
            selectedStates[cat] = states.filter((s) =>
                selectedIdsSet.has(s.id),
            );
        }

        const categoriesState = defaultCategories();
        for (const category of Object.keys(categories).map(
            (c) => c as FilterStateCategory,
        )) {
            let state: FilterStateCategoryEnabled;
            if (selectedStates[category].length === 0) {
                state = 'off';
            } else if (
                selectedStates[category].length === categories[category].length
            ) {
                state = 'on';
            } else {
                state = 'partial';
            }

            categoriesState[category] = state;
        }
        return categoriesState;
    }

    private setState(
        selectedIds: IWorkflowStatus['id'][],
        categories: FilterCategories,
        emit = true,
    ): void {
        this.categoriesState = categories;
        emit && this.selectedIdsAfterCategoryChange.emit(selectedIds);
    }
}
