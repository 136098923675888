import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import {
    IList,
    fromPageToken,
    paginatedListFromIList,
} from '@interacta-shared/util';
// IMPORT FROM INTERACTA MODULE
import { PaginatedList } from '@interacta-shared/util';
import { toMemberList } from '@modules/core/models/member/member.deserialize';
import { Member } from '@modules/core/models/member/member.model';
import { SearchMemberOrderType } from '@modules/core/models/user-autocomplete.model';
import { ICustomPost } from '@modules/post/models/custom-post.model';
import {
    ISubTask,
    ITaskAssigneesRequest,
    ITaskCapability,
    ITaskCreate,
    ITaskEdit,
    StandardTask,
    TaskList,
    TaskState,
} from '@modules/tasks/models/task.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ITaskFilters, TaskFilterSerialize } from '../models/filter-task.model';
import {
    toStandardTask,
    toSubTask,
    toTaskCapability,
    toTaskDetailsForEdit,
    toTaskForEdit,
    toTaskForInterest,
    toTaskList,
} from '../models/task.deserialize';
import { ITaskForInterest } from '../models/task.model';
import {
    fromReminder,
    fromTaskCreate,
    fromTaskEdit,
} from '../models/task.serialize';

export interface TaskOperationResponse {
    taskId?: number;
    nextOccToken: string;
    taskData: any;
    capabilities: any;
}

@Injectable({ providedIn: 'root' })
export class TaskService {
    private dataBaseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/tasks/data`;
    private manageBaseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/tasks/manage`;
    private membersBaseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/people/members`;

    constructor(
        private configurationService: ConfigurationService,
        private http: HttpClient,
    ) {}

    public copyTask(idCopy: number, task: ITaskEdit): Observable<StandardTask> {
        return this.http
            .put<TaskOperationResponse>(
                `${this.manageBaseUrl}/copy-task/${idCopy}/${task.occToken}`,
                fromTaskEdit(task),
            )
            .pipe(
                map((res: TaskOperationResponse) =>
                    toTaskForEdit(res, this.configurationService),
                ),
            );
    }

    public editTask(task: ITaskEdit): Observable<StandardTask> {
        return this.http
            .put<TaskOperationResponse>(
                `${this.manageBaseUrl}/edit-task/${task.id}/${task.occToken}`,
                fromTaskEdit(task),
            )
            .pipe(map((res) => toTaskForEdit(res, this.configurationService)));
    }

    public createTask(
        postId: number,
        taskSrc: ITaskCreate,
    ): Observable<StandardTask> {
        return this.http
            .post<TaskOperationResponse>(
                `${this.manageBaseUrl}/create-task/${postId}`,
                fromTaskCreate(taskSrc),
            )
            .pipe(map((res) => toTaskForEdit(res, this.configurationService)));
    }

    public deleteTask(taskId: number): Observable<any> {
        return this.http.delete(`${this.manageBaseUrl}/delete-task/${taskId}`);
    }

    public editTaskReminders(task: StandardTask): Observable<StandardTask> {
        return this.http
            .put<TaskOperationResponse>(
                `${this.manageBaseUrl}/edit-task-reminders/${task.id}`,
                {
                    reminders: (task.reminders || []).map(fromReminder),
                },
            )
            .pipe(
                map((res) => toTaskForEdit(res, this.configurationService)),
                tap(
                    (updatedTask) =>
                        (updatedTask.attachmentsList = task.attachmentsList),
                ),
            );
    }

    public setSubTaskState(
        task: StandardTask,
        subtaskId: number,
        newState: TaskState,
    ): Observable<ISubTask> {
        return this.http
            .put<{ subTask: ISubTask; nextOccToken: string }>(
                `${this.manageBaseUrl}/subtask-state/${subtaskId}`,
                {
                    state: newState,
                },
            )
            .pipe(
                map((record) => {
                    return {
                        subTask: toSubTask(record.subTask),
                        occToken: record.nextOccToken,
                    };
                }),
                tap((result) => {
                    const updatedTask = { ...task };
                    updatedTask.occToken = result.occToken;
                    updatedTask.subTasks = updatedTask.subTasks.map((s) =>
                        s.id === result.subTask.id ? result.subTask : s,
                    );
                }),
                map((result) => result.subTask),
            );
    }

    public setStateTask(
        task: StandardTask,
        newState: TaskState,
    ): Observable<StandardTask> {
        return this.http
            .put<{ task: any }>(
                `${this.manageBaseUrl}/task-state/${task.id ?? ''}`,
                {
                    state: newState,
                },
            )
            .pipe(
                map((res) =>
                    toStandardTask(res.task, this.configurationService),
                ),
                tap(
                    (updatedTask) =>
                        (updatedTask.attachmentsList = task.attachmentsList),
                ),
            );
    }

    public getTaskCapabilities(taskId: number): Observable<ITaskCapability> {
        return this.http
            .get<any>(`${this.dataBaseUrl}/task-capabilities/${taskId}`)
            .pipe(map((res: any) => toTaskCapability(res)));
    }

    public getPostTasks(
        post: ICustomPost,
        filter: ITaskFilters,
    ): Observable<TaskList> {
        const dst = TaskFilterSerialize.taskListFilter(filter);
        dst.calculateTotalItemsCount = true;
        return this.http
            .post<any>(`${this.dataBaseUrl}/post-tasks/${post.id}`, dst)
            .pipe(
                map((record: any) =>
                    toTaskList(record, post, filter, this.configurationService),
                ),
            );
    }

    public getTaskForEdit(taskId: number): Observable<StandardTask> {
        return this.http
            .get<any>(`${this.manageBaseUrl}/task-data-for-edit/${taskId}`)
            .pipe(
                map((record: any) => {
                    const task = toTaskDetailsForEdit(
                        record,
                        this.configurationService,
                    );
                    task.id = taskId;
                    return task;
                }),
            );
    }

    public getExpiringTasks(
        pageToken?: string,
        pageSize = 10,
    ): Observable<PaginatedList<ITaskForInterest>> {
        const filters = {
            pageToken,
            pageSize,
            calculateTotalItemsCount: pageToken == null,
        };
        return this.http
            .post<any>(`${this.dataBaseUrl}/expiring`, filters)
            .pipe(
                map((record: any) => ({
                    list: (<Array<any>>record.items ?? []).map((item) =>
                        toTaskForInterest(item, this.configurationService),
                    ),
                    totalCount: record.totalItemsCount,
                    nextPageToken: fromPageToken(
                        record.nextPageToken as string | null,
                    ),
                    isFetching: false,
                })),
            );
    }

    public editTaskWatchers(
        taskId: number,
        addWatcherUserIds: number[],
        removeWatcherUserIds: number[],
        addWatcherGroupIds: number[],
        removeWatcherGroupIds: number[],
    ): Observable<StandardTask> {
        return this.http
            .put<any>(`${this.manageBaseUrl}/edit-task-watchers/${taskId}`, {
                addWatcherUserIds,
                removeWatcherUserIds,
                addWatcherGroupIds,
                removeWatcherGroupIds,
            })
            .pipe(
                map((record: any) => ({
                    ...toTaskDetailsForEdit(record, this.configurationService),
                    id: taskId,
                })),
            );
    }

    public getTaskAssignees(
        search: ITaskAssigneesRequest,
    ): Observable<PaginatedList<Member>> {
        const body: ITaskAssigneesRequest = {
            pageToken: search.pageToken,
            pageSize: search.pageSize,
            calculateTotalItemsCount: search.calculateTotalItemsCount,
            tagIds: search.tagIds,
            communityIds: search.communityIds,
            workspaceIds: search.workspaceIds,
            name: search.name,
            includeNotVisible: search.includeNotVisible ?? false,
            orderBy: search.orderBy ?? SearchMemberOrderType.NAME,
        };

        return this.http
            .post<{
                items: any[];
                totalItemsCount: number | null;
                nextPageToken: string | null;
            }>(`${this.membersBaseUrl}/assigned-tasks`, body)
            .pipe(
                map((res: any) => toMemberList(res, res.totalItemsCount)),
                map((res: IList<Member>) => paginatedListFromIList(res)),
            );
    }
}
