import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';
import { Size } from '../../model';

@Component({
    standalone: true,
    imports: [NgClass, IconComponent, MatRippleModule, MatTooltipModule],
    selector:
        // eslint-disable-next-line @angular-eslint/component-selector
        'button[interacta-button-light-box], a[interacta-button-light-box]',
    templateUrl: './button-light-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonLightBoxComponent {
    @Input({ required: true }) icon!: InteractaIcon;
    @Input() label = '';
    @Input() labelPosition: TooltipPosition = 'below';
    @Input() size: Extract<Size, 'extra-small' | 'regular' | 'extra-large'> =
        'extra-large';
    @Input() active = false;
    @Input() @HostBinding('disabled') disabled?: boolean;
}
