<button
    *ngIf="canClick; else usersGroupDetail"
    class="block"
    type="button"
    (click)="openGroupInfo()"
>
    <ng-container *ngTemplateOutlet="usersGroupDetail"></ng-container>
</button>

<ng-template #usersGroupDetail>
    <div
        class="flex max-w-full items-center space-x-8"
        [ngClass]="rtl ? 'flex-row-reverse space-x-reverse' : ''"
    >
        <div
            class="shrink-0 flex items-center justify-center rounded-full {{
                group.id === EMPTY_SEARCH_VALUE ? 'bg-avatar-I' : colorClass
            }}"
            [ngClass]="{
                'size-40': size === 'regular',
                'size-30': size === 'small',
                'size-24': size === 'extra-small'
            }"
        >
            <interacta-icon
                class="text-text-on-avatar"
                [icon]="'people'"
                [ngClass]="{
                    'size-30': size === 'regular',
                    'size-20': size === 'small',
                    'size-14': size === 'extra-small'
                }"
            />
        </div>

        <div class="flex min-w-0 grow flex-col">
            <p
                class="line-clamp-1 break-all"
                [ngClass]="{
                    'typo-xs':
                        size === 'extra-small' && fontWeight === 'regular',
                    'typo-xs-bold':
                        size === 'extra-small' && fontWeight === 'bold',
                    'typo-sm':
                        size !== 'extra-small' && fontWeight === 'regular',
                    'typo-sm-bold':
                        size !== 'extra-small' && fontWeight === 'bold',
                    'pr-4 italic': group.id === EMPTY_SEARCH_VALUE,
                    'text-left': !rtl,
                    'text-right': rtl,
                    'line-through': group.deleted
                }"
            >
                {{ group.fullName | translate }}
            </p>
            <div
                *ngIf="showInfo && group.tags?.length"
                class="flex flex-wrap items-center gap-4"
            >
                <interacta-group-tag
                    *ngFor="let tag of group.tags"
                    [label]="tag.name"
                    [size]="'small'"
                />
            </div>
        </div>
    </div>
</ng-template>
