import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Size, User as UiUser } from '@interacta-shared/ui';
import { Member } from '@modules/core/models/member/member.model';
import { isMemberUser } from '@modules/core/models/member/member.utils';
import { IUsersGroup } from '@modules/core/models/user-group.model';

export type MemberClickBehavior = 'profile' | 'preview' | 'none';

@Component({
    selector: 'interacta-member',
    templateUrl: './member.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberComponent implements OnChanges {
    @Input({ required: true }) member!: Member;
    @Input() size: Extract<Size, 'regular' | 'small' | 'extra-small'> =
        'regular';
    @Input() showInfo = false;
    @Input() fontWeight: 'bold' | 'regular' = 'bold';
    @Input() rtl = false;
    @Input() memberClickBehavior: MemberClickBehavior = 'preview';

    author: UiUser | null = null;
    group: IUsersGroup | null = null;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.member) {
            if (isMemberUser(this.member)) {
                this.author = {
                    fullName: this.member.user.fullName,
                    id: this.member.user.id,
                    accountPhotoUrl: this.member.user.accountPhotoUrl,
                    contactEmail:
                        this.member.user.contactEmail ||
                        this.member.user.googleAccountId,
                    followedByMe: this.member.user.followedByMe,
                    caption: this.member.user.caption,
                    icon: this.member.user.icon,
                };
                this.group = null;
            } else {
                this.author = null;
                this.group = this.member.group;
            }
        }
    }
}
