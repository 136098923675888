import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Size } from '@interacta-shared/ui';
import { Member } from '@modules/core/models/member/member.model';
import {
    getMemberGroup,
    getMemberUser,
} from '@modules/core/models/member/member.utils';

@Component({
    selector: 'interacta-member-avatar',
    templateUrl: './member-avatar.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberAvatarComponent {
    @Input({ required: true }) member!: Member;
    @Input() size: Extract<Size, 'regular' | 'small' | 'extra-small'> =
        'regular';

    getMemberUser = getMemberUser;
    getMemberGroup = getMemberGroup;
}
