import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { InputSearchComponent } from '../input-search/input-search.component';

@Component({
    selector: 'interacta-dialog-search[control]',
    templateUrl: './dialog-search.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [InputSearchComponent],
})
export class DialogSearchComponent {
    @Input() control!: UntypedFormControl;
    @Input() iconPosition?: 'right' | 'left';
    @Input() iconColor: 'primary' | 'gray' = 'primary';
    @Input() label?: string;
}
