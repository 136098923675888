import { ICommunity } from '@modules/communities/models/communities.model';
import { IUser, UserDeserilize } from '@modules/core';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import {
    IAdministrator,
    IAdministratorsGroup,
    IUsersGroup,
    UserExtendedDeserialize,
} from '@modules/core/models/user-group.model';
import {
    toMemberList,
    toMemberWithRolesList,
} from '../../core/models/member/member.deserialize';
import {
    IMemberWithRoles,
    Member,
} from '../../core/models/member/member.model';
import { i18nString } from '../../core/models/utility.model';

import { IList } from '@interacta-shared/util';
import { i18nDelta } from './../../core/models/utility.model';

export type WorkspaceBase = Pick<IWorkspace, 'id' | 'name' | 'communities'>;
export interface IWorkspace {
    id: number;
    name: i18nString;
    description: i18nDelta;
    coverImageLink: string;
    logoLink: string;
    coverImageContentRef: string;
    logoContentRef: string;
    adminUsersCount?: number;
    adminGroupsCount?: number;
    memberUsersCount?: number;
    memberGroupsCount?: number;
    members: IList<Member>;
    adminMembers: IList<IMemberWithRoles>;
    users?: IList<IUser>;
    usersGroup?: IList<IUsersGroup>;
    administrators?: IList<IAdministrator>;
    administratorsGroup?: IList<IAdministratorsGroup>;
    communities?: ICommunity[]; // redundant info to show button tree in dashboard navigation
    occToken?: string;
    creatorUser?: IUser;
    lastModifyUser?: IUser;
    creationTimestamp?: Date;
    lastModifyTimestamp?: Date;
    capabilities?: IWorkspaceCapabilities;
    selected?: boolean;
    visibleInOrganizationTree?: boolean;
}

export interface IWorkspaceCapabilities {
    editWorkspace: boolean;
    createCommunity: boolean;
    manageUsers: boolean;
}

export class WorkspaceDeserialize {
    private static getDescription(record: any): i18nDelta {
        const description = record.descriptionDelta ?? record.description;

        if (description) {
            if (typeof description === 'object') {
                const record: i18nDelta = {};

                Object.keys(description).forEach((k) => {
                    record[k] = new Server2Delta().process(description[k]);
                });

                return record;
            } else if (typeof description === 'string') {
                return new Server2Delta().process(description);
            }
        } else {
            return null;
        }
    }

    static workspaceDetails = (record: any): IWorkspace => ({
        id: record.id,
        name: record.name,
        description: WorkspaceDeserialize.getDescription(record),
        coverImageLink: record.coverImageTemporaryContentViewLink,
        logoLink: record.logoTemporaryContentViewLink,
        logoContentRef: record.logoContentRef,
        coverImageContentRef: record.coverImageContentRef,
        lastModifyUser: record.lastModifyUser
            ? UserDeserilize.userDetails(record.lastModifyUser)
            : undefined,
        creatorUser: record.creatorUser
            ? UserDeserilize.userDetails(record.creatorUser)
            : undefined,
        creationTimestamp: record.creationTimestamp
            ? new Date(record.creationTimestamp)
            : undefined,
        lastModifyTimestamp: record.lastModifyTimestamp
            ? new Date(record.lastModifyTimestamp)
            : undefined,
        members: record.members
            ? toMemberList(
                  record.members,
                  record.memberUsersCount + record.memberGroupsCount,
              )
            : { list: [], totalCount: 0 },
        adminMembers: record.adminMembers
            ? toMemberWithRolesList(
                  record.adminMembers,
                  record.adminUsersCount + record.adminGroupsCount,
              )
            : { list: [], totalCount: 0 },
        administrators: record.adminUsers
            ? UserExtendedDeserialize.administratorList(record.adminUsers)
            : { list: [], totalCount: 0 },
        administratorsGroup: record.adminGroups
            ? UserExtendedDeserialize.administratorsGroupList(
                  record.adminGroups,
              )
            : { list: [], totalCount: 0 },
        users: record.memberUsers
            ? UserExtendedDeserialize.userList(record.memberUsers)
            : { list: [], totalCount: 0 },
        usersGroup: record.memberGroups
            ? UserExtendedDeserialize.usersGroupList(record.memberGroups)
            : { list: [], totalCount: 0 },
    });

    static workspaceInfo = (record: any): IWorkspace => {
        const workspace = WorkspaceDeserialize.workspaceDetails(
            record.workspace,
        );
        workspace.adminUsersCount = record.adminUsersCount || 0;
        workspace.adminGroupsCount = record.adminGroupsCount || 0;
        workspace.memberUsersCount = record.memberUsersCount || 0;
        workspace.memberGroupsCount = record.memberGroupsCount || 0;
        workspace.members = record.members
            ? toMemberList(
                  record.members,
                  record.memberUsersCount + record.memberGroupsCount,
              )
            : { list: [], totalCount: 0 };
        workspace.adminMembers = record.adminMembers
            ? toMemberWithRolesList(
                  record.adminMembers,
                  record.adminUsersCount + record.adminGroupsCount,
              )
            : { list: [], totalCount: 0 };
        workspace.administrators = record.adminUsers
            ? UserExtendedDeserialize.administratorList(record.adminUsers)
            : { list: [], totalCount: 0 };
        workspace.administratorsGroup = record.adminGroups
            ? UserExtendedDeserialize.administratorsGroupList(
                  record.adminGroups,
              )
            : { list: [], totalCount: 0 };
        workspace.visibleInOrganizationTree =
            !!record.visibleInOrganizationTree;
        return workspace;
    };

    static workspaceCapabilities = (record: any): IWorkspaceCapabilities => ({
        editWorkspace: record.editWorkspace,
        createCommunity: record.createCommunity,
        manageUsers: record.manageUsers,
    });
}
