import { Pipe, PipeTransform } from '@angular/core';

/**
 * @params color è il colore in esadecimale (vecchio metadato style_color)
 * @params colorCode è il nuovo colore in formato singolo carattere,
 *         che ci permette di switchare tra i temi (nuovo metadato style_color_code)
 * @params colorClass è una classe tailwind custom (usata dal badge degli stati delle missioni)
 */
@Pipe({
    name: 'workflowBadgeColor',
})
export class WorkflowBadgeColorPipe implements PipeTransform {
    transform(data: {
        color?: string;
        colorCode?: string;
        colorClass?: string;
    }): string {
        if (!data.color && !data.colorCode) {
            return data.colorClass ?? 'bg-status-D';
        }

        if (data.colorCode) {
            switch (data.colorCode) {
                case 'A':
                    return 'bg-status-A';
                case 'B':
                    return 'bg-status-B';
                case 'C':
                    return 'bg-status-C';
                case 'D':
                    return 'bg-status-D';
                case 'E':
                    return 'bg-status-E';
                case 'F':
                    return 'bg-status-F';
                case 'G':
                    return 'bg-status-G';
                case 'H':
                    return 'bg-status-H';
                case 'I':
                    return 'bg-status-I';
                case 'J':
                    return 'bg-status-J';
                case 'K':
                    return 'bg-status-K';
                case 'L':
                    return 'bg-status-L';
                case 'M':
                    return 'bg-status-M';
                case 'N':
                    return 'bg-status-N';
                case 'O':
                    return 'bg-status-O';
                default:
                    return 'bg-status-D';
            }
        }

        return '';
    }
}
