<span
    *ngIf="paginatedList.nextPageToken.tag !== 'firstLoading'"
    class="typo-sm flex items-center justify-end gap-16"
>
    <div class="max-w-250 flex">
        <span
            *ngIf="{
                start: paginatedList.list.length
                    ? paginatedList.currentPage * paginatedList.pageSize + 1
                    : 0,
                end: min(
                    (paginatedList.currentPage + 1) * paginatedList.pageSize,
                    paginatedList.totalCount
                ),
                total: paginatedList.totalCount
            } as counters"
            class="shrink-0"
        >
            {{ 'WINDOW_PAGINATOR.COUNTERS' | translate: counters }}</span
        >
        <span class="typo-sm hidden truncate xl:block">
            &nbsp;{{ 'SEARCH.ORDER_BY' | translate }}
        </span>
    </div>
    <span>
        <button
            icon="chevron-small-left"
            interacta-icon-button
            size="small"
            type="button"
            [attr.aria-label]="'WINDOW_PAGINATOR.PREV_PAGE' | translate"
            [disabled]="!paginatedList.currentPage"
            [matTooltip]="'WINDOW_PAGINATOR.PREV_PAGE' | translate"
            (click)="!paginatedList.isFetching && prevPage.emit()"
        ></button>
        <button
            icon="chevron-small-right"
            interacta-icon-button
            size="small"
            type="button"
            [attr.aria-label]="'WINDOW_PAGINATOR.NEXT_PAGE' | translate"
            [disabled]="
                !(paginatedList | apply: nextPageAlreadyFetched) &&
                paginatedList.nextPageToken.tag !== 'regularLoading'
            "
            [matTooltip]="'WINDOW_PAGINATOR.NEXT_PAGE' | translate"
            (click)="!paginatedList.isFetching && nextPage.emit()"
        ></button>
    </span>
</span>
<div
    *ngIf="paginatedList.nextPageToken.tag === 'firstLoading'"
    class="bg-surface-300 w-250 h-32 animate-pulse place-self-center rounded-lg"
></div>
