import { Language } from '@interacta-shared/data-access-configuration';
import { ICommunity } from '@modules/communities/models/communities.model';
import { FetchPostError } from '@modules/communities/models/post-error/post-error.model';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import {
    AdvancedPostType,
    PostType,
    VisibilityType,
} from '@modules/post/models/base-post.model';
import { DraftType } from '@modules/post/models/filter-post/filter-post.model';
import { GenericPost } from '@modules/post/models/generic-post.model';
import { ListenChangesCustomPostData } from '@modules/post/models/listen-changes/listen-changes.model';
import {
    SurveyQuestionForCreate,
    SurveyType,
} from '@modules/post/models/survey-post/survey-post.model';
import { QuillTable } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { createAction, props } from '@ngrx/store';
import { IFilePickerAttachment } from '@modules/state/models/gallery-state.model';

export type QueryCustomParamer = any;

export const resetForm = createAction('[Post Form] reset');

export const initCreateForm = createAction(
    '[Post Form] Init create',
    props<{
        communityId: number;
        q: QueryCustomParamer;
        from: DraftType | null;
        postType: PostType | null;
        subType?: SurveyType;
    }>(),
);

export const initEditForm = createAction(
    '[Post Form] Init edit',
    props<{
        postId: number;
    }>(),
);

export const initCopyForm = createAction(
    '[Post Form] Init copy',
    props<{
        postId: number;
    }>(),
);

export const listenChanges = createAction(
    '[Post Form] Listen changes',
    props<{
        context: ListenChangesCustomPostData['context'];
        changedFieldId: ListenChangesCustomPostData['changedFieldId'];
    }>(),
);

export const setCommunityForCreate = createAction(
    '[Post Form] Set Community for create',
    props<{
        community: ICommunity;
        availablePostTypes: AdvancedPostType[];
        postType: PostType | null;
        subType?: SurveyType;
    }>(),
);

export const fetchPostAttachmentsList = createAction(
    '[Post Form] Fetch Post Attachments List',
    props<{
        postId: number;
        pageSize?: number;
    }>(),
);

export const fetchPostAttachmentsListSuccess = createAction(
    '[Post Form] Get Post Attachments Success',
    props<{
        attachmentsList: IListAttachments;
    }>(),
);

export const fetchPostAttachmentsListError = createAction(
    '[Post Form] Get Post Attachments Error',
    props<{
        error: FetchPostError;
    }>(),
);

export const updateAttachmentsList = createAction(
    '[Post Form] Update Attachments list',
    props<{
        attachments: IAttachment[];
    }>(),
);

export const setPost = createAction(
    '[Post Form] Set Post',
    props<{
        post: GenericPost;
        community?: ICommunity;
        availablePostTypes?: AdvancedPostType[];
    }>(),
);

export const setPostType = createAction(
    '[Post Form] Set Post type',
    props<{
        postType: AdvancedPostType;
    }>(),
);

export const setPostVisibilityAndAnnouncement = createAction(
    '[Post Form] Set Post Visibility And Announcement',
    props<{
        visibility?: VisibilityType;
        announcement?: boolean;
    }>(),
);

export const initFormError = createAction(
    '[Post Form] Init error',
    props<{
        error: FetchPostError;
    }>(),
);

export const fetchFilePickerTemporaryContentViewLink = createAction(
    '[Post Form] Fetch File Picker TemporaryContentViewLink',
    props<{
        postId: number;
        filePicker: IFilePickerAttachment;
    }>(),
);
export const fetchFilePickerTemporaryContentViewLinkSuccess = createAction(
    '[Post Form] Fetch File Picker TemporaryContentViewLink Success',
    props<{
        postId: number;
        filePicker: IFilePickerAttachment;
        attachments: IAttachment[];
    }>(),
);
export const fetchFilePickerTemporaryContentViewLinkError = createAction(
    '[Post Form] Fetch File Picker TemporaryContentViewLink Error',
    props<{
        error: FetchPostError;
    }>(),
);

export const setFocusOnQuestions = createAction(
    '[Post Form] set focus on Questions',
);
export const setFocusOnSettings = createAction(
    '[Post Form] set focus on Settings',
);
export const setFocusOnPartialQuestion = createAction(
    '[Post Form] set focus on partial Questions',
);

export const createSurveyQuestion = createAction(
    '[Post Form] create survey question',
    props<{
        index: number;
    }>(),
);

export const saveSurveyQuestion = createAction(
    '[Post Form] save survey question',
    props<{
        question: SurveyQuestionForCreate;
        index: number;
    }>(),
);

export const duplicateSurveyQuestion = createAction(
    '[Post Form] duplicate survey question',
    props<{
        question: SurveyQuestionForCreate;
        index: number;
    }>(),
);

export const deleteSurveyQuestion = createAction(
    '[Post Form] delete survey question',
    props<{
        question: SurveyQuestionForCreate;
        index: number;
    }>(),
);

export const cancelSurveyQuestion = createAction(
    '[Post Form] cancel survey question',
    props<{
        index: number;
    }>(),
);

export const reorderSurveyQuestions = createAction(
    '[Post Form] Reorder Survey questions',
    props<{
        questions: SurveyQuestionForCreate[];
    }>(),
);

/*IISP-6741 dato che mancano le traduzioni per le enum values, siamo costretti 
a duplicare il valore */
export const updateSurveyLanguage = createAction(
    '[Post Form] Update Enum Values in Survey Questions',
    props<{
        language: Language;
    }>(),
);

export const addOrEditQuillTable = createAction(
    '[Post Form] add or edit quill table',
    props<{
        table: QuillTable;
    }>(),
);

export const setSharedAttachments = createAction(
    '[Post Form] set shared attachments',
    props<{
        sharedAttachments: IAttachment[];
    }>(),
);
