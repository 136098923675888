import { Injectable, inject } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    ConsoleService,
} from '@interacta-shared/data-access-configuration';
import {
    GoogleDriveFile,
    GoogleDriveFileDeserialize,
} from '@modules/core/models/google-drive/google-drive.model';
import {
    GooglePickerDeserializer,
    GooglePickerDocument,
} from '@modules/core/models/google-picker.model';
import { IStateService } from '@modules/state/services/istate-service';
import { addSeconds, isFuture } from 'date-fns';
import {
    BehaviorSubject,
    Observable,
    Subject,
    iif,
    pipe,
    range,
    throwError,
    timer,
    zip,
} from 'rxjs';
import {
    catchError,
    filter,
    first,
    map,
    mergeMap,
    retryWhen,
    tap,
} from 'rxjs/operators';
import { rateLimit } from '../helpers/generic.utils';
import { toLargeThumbnailLink } from '../models/google-drive/google-drive.utils';
import { LazyLoaderService } from './lazy-loader.service';
import { LocalStorageService } from './local-storage.service';

export enum GoogleAPIScope {
    DRIVE_READONLY = 'https://www.googleapis.com/auth/drive.readonly',
}

export enum GoogleAPI {
    PICKER = 'picker',
    CLIENT = 'client',
}

export enum DrivePickerErrorCode {
    MATCH_INTERACTA_USER = 'match-interacta-user',
    NOT_ALLOWED_MIMETYPE = 'not-allowed_mime-type',
}

const DISCOVERY_DOCS = [
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
];

interface DriveApiTypeRegistry {
    getFileDetails: gapi.client.drive.File;
}

type DriveApiType = keyof DriveApiTypeRegistry;

type DriveApiTypeResult<T extends DriveApiType> = DriveApiTypeRegistry[T];

interface DriveApiArgs {
    driveId: string;
}
interface DriveApiBaseRequest<T extends DriveApiType, A extends DriveApiArgs> {
    type: T;
    args: A;
    callback: (result: DriveApiTypeResult<T>) => void;
    error: (error: any) => void;
}

interface GetFileDetailsArgs extends DriveApiArgs {
    getThumbnail: boolean;
}

type GetFileDetailsRequest = DriveApiBaseRequest<
    'getFileDetails',
    GetFileDetailsArgs
>;

type DriveApiRequest = GetFileDetailsRequest;

interface GoogleAccessToken {
    token: string;
    expirationDate: Date;
}

@Injectable({ providedIn: 'root' })
export class GoogleAPIService implements IStateService<void> {
    readonly state = undefined;

    private _showMessage: BehaviorSubject<boolean>;
    private loadedAPIs: Map<GoogleAPI, Promise<void>> = new Map();
    private clientInitialized: Promise<void> | null = null;

    private accessToken: GoogleAccessToken | null = null;

    private readonly driveGlobalSearch = 'driveGlobalSearch';
    private readonly driveRequestQueue$ = new Subject<DriveApiRequest>();
    private pendingAccessTokenRequest: Promise<string> | null = null;
    private readonly consoleService = inject(ConsoleService);

    constructor(
        private configurationService: ConfigurationService,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private lazyLoaderService: LazyLoaderService,
    ) {
        this._showMessage = new BehaviorSubject<boolean>(false);

        this.driveRequestQueue$.pipe(rateLimit(1, 200)).subscribe(
            (request) => this.executeDriveApiRequest(request),
            (error) => console.error('driveRequestQueue$ ', error),
        );
    }

    get showMessage(): Observable<boolean> {
        return this._showMessage.asObservable();
    }

    get isDriveEnabledForInstallation(): boolean {
        return (
            this.configurationService.getEnvironmentInfo()?.installedFeatures
                .googleDriveIntegration ?? false
        );
    }

    get driveIntegrationEnabled(): boolean {
        return !!(
            this.isDriveEnabledForInstallation &&
            this.authService.getCurrentUserData()?.googleAccountId
        );
    }

    initialize(): void {
        this.loadDriveGlobalSearchMessageStatus();
    }

    flush(): void {
        this.accessToken = null;
    }

    loadDriveGlobalSearchMessageStatus(): void {
        const show =
            !this.localStorageService.getEntry(this.driveGlobalSearch) &&
            this.isDriveEnabledForInstallation;
        this._showMessage.next(show);
    }

    markAsSeen(): void {
        this._showMessage.next(false);
        this.localStorageService.setEntry(this.driveGlobalSearch, true);
    }

    markAsSeenMomentarily(): void {
        this._showMessage.next(false);
    }

    /**
     * An empty GooglePickerDocument[] is emitted if user cancel the picker
     */
    openDrivePicker(
        enableMultiselect = true,
        selectableMimeTypes?: string[],
    ): Observable<GooglePickerDocument[]> {
        let builder: google.picker.PickerBuilder;
        let picker;
        const picker$: Observable<GooglePickerDocument[]> = new Observable(
            (observer) => {
                this.initDrivePickerAPI()
                    .then(async () => {
                        const developerKey =
                            this.configurationService.getEnvironmentInfo()
                                ?.googleApisDefaultApiKey;

                        if (!developerKey) {
                            throw new Error(
                                '[gapi] googleApisDefaultApiKey not configured',
                            );
                        }

                        let token = this.getCurrentAccessToken();
                        if (!token) {
                            token = await this.requestAccessToken(
                                GoogleAPIScope.DRIVE_READONLY,
                            );
                        }
                        builder = new google.picker.PickerBuilder();
                        const viewId =
                            this.getViewIdFromMimeTypes(selectableMimeTypes);
                        builder
                            .enableFeature(
                                google.picker.Feature.SUPPORT_TEAM_DRIVES,
                            )
                            .addView(
                                this.setMimeTypes(
                                    new google.picker.DocsView(viewId)
                                        .setIncludeFolders(true)
                                        .setOwnedByMe(true),
                                    selectableMimeTypes,
                                ),
                            )
                            .addView(
                                this.setMimeTypes(
                                    new google.picker.DocsView(viewId)
                                        .setIncludeFolders(true)
                                        .setOwnedByMe(false),
                                    selectableMimeTypes,
                                ),
                            )
                            .addView(
                                this.setMimeTypes(
                                    new google.picker.DocsView(viewId)
                                        .setIncludeFolders(true)
                                        .setEnableTeamDrives(true),
                                    selectableMimeTypes,
                                ),
                            )
                            .setLocale(
                                this.configurationService.getCurrentLanguage()
                                    .code,
                            )
                            .setOAuthToken(token)
                            .setDeveloperKey(developerKey)
                            .setCallback((data: any) =>
                                observer.next(
                                    GooglePickerDeserializer.response(data),
                                ),
                            );
                        if (enableMultiselect) {
                            builder.enableFeature(
                                google.picker.Feature.MULTISELECT_ENABLED,
                            );
                        }
                        picker = builder.build();
                        picker.setVisible(true);
                    })
                    .catch((error) => observer.error(error));
            },
        );

        return iif(
            () => this.driveIntegrationEnabled,
            picker$.pipe(
                filter((documents) => documents?.length > 0),
                tap((documents) => {
                    if (
                        selectableMimeTypes &&
                        documents.some(
                            (document) =>
                                !this.checkMimeType(
                                    document,
                                    selectableMimeTypes,
                                ),
                        )
                    ) {
                        const error = {
                            error: DrivePickerErrorCode.NOT_ALLOWED_MIMETYPE,
                        };
                        throw error;
                    }
                }),
                first(),
            ),
            throwError('Drive integration non available'),
        );
    }

    getFileDetails(
        document: GooglePickerDocument,
        getThumbnail?: boolean, // TODO will be used in IISP-5686
    ): Observable<GoogleDriveFile> {
        this.consoleService.debugDev(
            '[gapi.client.drive] getFileDetails ',
            document,
        );
        const response$ = new Observable<gapi.client.drive.File>(
            (observable) => {
                const callback = (file: gapi.client.drive.File): void => {
                    this.consoleService.debugDev(
                        'getFileDetails callback ',
                        file,
                    );
                    observable.next(file);
                    observable.complete();
                };
                const error = (error: any): void => {
                    this.consoleService.errorDev(
                        '[gapi.client.drive] getFileDetails ',
                        error,
                    );
                    observable.error(error);
                };

                const request: GetFileDetailsRequest = {
                    callback,
                    error,
                    type: 'getFileDetails',
                    args: {
                        driveId: document.driveId,
                        getThumbnail: getThumbnail ?? false,
                    },
                };
                this.enqueueDriveApiRequest(request);
            },
        );

        return backoffAndErrorHandling(response$).pipe(
            map((file) => {
                const result = GoogleDriveFileDeserialize.googleDriveFile(
                    file,
                    document,
                );
                if (getThumbnail && file.hasThumbnail && file.thumbnailLink) {
                    result.thumbnailLinks = {
                        small: file.thumbnailLink,
                        large: toLargeThumbnailLink(file.thumbnailLink),
                    };
                }

                return result;
            }),
        );
    }

    private enqueueDriveApiRequest(request: DriveApiRequest): void {
        this.driveRequestQueue$.next(request);
    }

    private executeDriveApiRequest(request: DriveApiRequest): void {
        this.consoleService.debugDev(
            '[gapi.client.drive] executeDriveApiRequest ',
            request,
        );
        switch (request.type) {
            case 'getFileDetails':
                this._getFileDetails(
                    request.args.driveId,
                    request.args.getThumbnail,
                )
                    .then((file) => request.callback(file))
                    .catch(async (error) => {
                        if (error.status === 403) {
                            this.consoleService.debugDev(
                                '[gapi.client.drive] executeDriveApiRequest getFileDetails catch http 403',
                                error,
                            );
                            const token = await this.requestAccessToken(
                                GoogleAPIScope.DRIVE_READONLY,
                            );
                            this.consoleService.debugDev(
                                'executeDriveApiRequest getFileDetails retry, new access token: ',
                                token,
                            );
                            return this._getFileDetails(
                                request.args.driveId,
                                request.args.getThumbnail,
                            );
                        } else {
                            throw error;
                        }
                    })
                    .then((file) => file && request.callback(file))
                    .catch((error) => request.error(error));
                break;
        }
    }

    private async _getFileDetails(
        driveId: string,
        getThumbnails: boolean,
    ): Promise<gapi.client.drive.File> {
        this.consoleService.debugDev(
            '[gapi.client.drive] internal _getFileDetails',
        );
        if (this.driveIntegrationEnabled) {
            await this.initClientAPI();
            let fields = 'id,webContentLink,size';
            if (getThumbnails) {
                fields = fields + ',hasThumbnail,thumbnailLink';
            }
            const {
                result,
            }: {
                result: gapi.client.drive.File;
            } = await gapi.client.drive.files.get({
                fileId: driveId,
                supportsAllDrives: true,
                fields,
            });

            return result;
        } else {
            throw new Error('Drive integration not available');
        }
    }

    private async loadAPI(api: GoogleAPI): Promise<void> {
        if (this.loadedAPIs.has(api)) {
            return this.loadedAPIs.get(api);
        } else {
            const promise = new Promise((resolve) => {
                gapi.load(api, resolve);
                // eslint-disable-next-line @typescript-eslint/no-empty-function
            }).then(() => {});
            this.loadedAPIs.set(api, promise);

            return promise;
        }
    }

    private async initClientAPI(): Promise<void> {
        if (!this.clientInitialized) {
            this.clientInitialized = (async () => {
                this.consoleService.debugDev('[gapi.client] init client API');
                await this.lazyLoaderService
                    .loadScripts([
                        'https://apis.google.com/js/api.js',
                        'https://accounts.google.com/gsi/client',
                    ])
                    .toPromise();
                await this.loadAPI(GoogleAPI.CLIENT);
                await gapi.client.init({ discoveryDocs: DISCOVERY_DOCS });
            })();
        }
        return this.clientInitialized;
    }

    private async initDrivePickerAPI(): Promise<void> {
        await this.initClientAPI();
        await this.loadAPI(GoogleAPI.PICKER);
    }

    private setCurrentAccessToken(
        tokenResponse: google.accounts.oauth2.TokenResponse,
        requestDate: Date,
    ): void {
        if (!tokenResponse.error) {
            this.accessToken = {
                token: tokenResponse.access_token,
                expirationDate: addSeconds(
                    requestDate,
                    parseInt(tokenResponse.expires_in),
                ),
            };
        } else {
            this.accessToken = null;
        }
    }

    private getCurrentAccessToken(): string | null {
        return this.accessToken && isFuture(this.accessToken.expirationDate)
            ? this.accessToken.token
            : null;
    }

    private async requestAccessToken(scope: GoogleAPIScope): Promise<string> {
        if (!this.pendingAccessTokenRequest) {
            console.debug(
                '[google.accounts.oauth2] Requesting a new access token',
            );
            const client_id =
                this.configurationService.getEnvironmentInfo()
                    ?.googleCredentialsAuthClientId;
            const currentUser = this.authService.getCurrentUserData();
            const hostedDomain: string | undefined =
                currentUser?.googleAccountId?.split('@')[1];
            if (!client_id) {
                throw Error(
                    "Can't sign in: googleCredentialsAuthClientId not configured",
                );
            }
            if (!hostedDomain) {
                throw Error(
                    "Can't sign in: googleAccountId not configured for current user",
                );
            }
            const accessTokenRequest: Promise<google.accounts.oauth2.TokenResponse> =
                new Promise((callback) => {
                    // See https://developers.google.com/identity/oauth2/web/reference/js-reference#TokenClientConfig
                    const clientConfig: google.accounts.oauth2.TokenClientConfig =
                        {
                            client_id,
                            hint: currentUser?.googleAccountId ?? undefined,
                            hosted_domain: hostedDomain
                                ? hostedDomain
                                : undefined,
                            scope: scope,
                            prompt: '', // empty string: The user will be prompted only the first time your app requests access. Cannot be specified with other values.
                            callback,
                        };
                    const tokenClient =
                        google.accounts.oauth2.initTokenClient(clientConfig);
                    tokenClient.requestAccessToken();
                });
            const requestDate = new Date();

            const clearPendingRequestTimeout = setTimeout(
                () => (this.pendingAccessTokenRequest = null),
                1000,
            );

            this.pendingAccessTokenRequest = accessTokenRequest
                .then((response) => {
                    this.setCurrentAccessToken(response, requestDate);
                    if (response.error) {
                        throw new Error(
                            `${response.error} ${response.error_description}`,
                        );
                    }
                    return this.validateGoogleUser(response.access_token).then(
                        () => response.access_token,
                    );
                })

                .finally(() => {
                    clearTimeout(clearPendingRequestTimeout);
                    this.pendingAccessTokenRequest = null;
                });
        } else {
            this.consoleService.debugDev(
                '[google.accounts.oauth2] Waiting for already running request for access token',
            );
        }

        return this.pendingAccessTokenRequest;
    }

    private async validateGoogleUser(accessToken: string) {
        const currentUser = this.authService.getCurrentUserData();
        if (!currentUser) {
            throw Error('Logged-in Interacta user required');
        }
        const googleAccountId = currentUser.googleAccountId?.toLowerCase();
        if (!googleAccountId) {
            throwError('GoogleAccountId required for current Interacta user');
        }
        const userEmail = await this.retrieveUserEmail(accessToken);
        if (googleAccountId !== userEmail) {
            console.error(
                `Signed-in Google user [${userEmail}] does not match the one logged into Interacta [${googleAccountId}]`,
            );
            this.accessToken = null;
            google.accounts.oauth2.revoke(accessToken, () =>
                this.consoleService.debugDev(
                    '[google.accounts.oauth2] access token revoked',
                ),
            );
            const error = {
                error: DrivePickerErrorCode.MATCH_INTERACTA_USER,
            };
            throw error;
        }
    }

    private async retrieveUserEmail(accessToken: string): Promise<string> {
        if (!accessToken) {
            throw Error('Google access token not available');
        }

        const userInfo: any = await new Promise((resolve) => {
            const xhr = new XMLHttpRequest();

            xhr.open('GET', `https://www.googleapis.com/oauth2/v3/userinfo`);
            xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300)
                    resolve(JSON.parse(this.responseText));
                else resolve({ err: '404' });
            };
            xhr.send();
        });
        this.consoleService.debugDev(
            '[www.googleapis.com/oauth2] Retrieved google user email ',
            userInfo.email,
        );
        return userInfo.email;
    }

    private getViewIdFromMimeTypes(mimeTypes?: string[]): string {
        let viewId = google.picker.ViewId.DOCS;

        if (mimeTypes?.length == 1 && mimeTypes.includes('image/*')) {
            viewId = google.picker.ViewId.DOCS_IMAGES;
        } else if (mimeTypes?.length == 1 && mimeTypes.includes('video/*')) {
            viewId = google.picker.ViewId.DOCS_VIDEOS;
        } else if (
            mimeTypes?.length == 2 &&
            mimeTypes.includes('image/*') &&
            mimeTypes.includes('video/*')
        ) {
            viewId = google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS;
        }

        return viewId;
    }

    /**
     * Joins mime-types in a comma separated string.
     * If generic mime-types like "image/*" are present, undefined is returned. They are not supported by google drive picker.
     * @param mimeTypes
     * @returns
     */
    private getMimeTypesString(mimeTypes?: string[]): string | undefined {
        const specificMimeTypes = (mimeTypes || []).filter(
            (t) => !t.includes('*'),
        );
        if (mimeTypes?.length === specificMimeTypes.length) {
            return specificMimeTypes.join(',');
        }
        return undefined;
    }

    private setMimeTypes(
        docView: google.picker.DocsView,
        mimeTypes?: string[],
    ): google.picker.DocsView {
        const mimeTypesString = this.getMimeTypesString(mimeTypes);

        if (mimeTypesString) {
            docView.setMimeTypes(mimeTypesString);
        }
        return docView;
    }

    private checkMimeType(
        document: GooglePickerDocument,
        allowedMimeTypes: string[],
    ): boolean {
        // match "audio/*", "video/*", "image/*" and MIME types
        return (allowedMimeTypes || []).some((mimeType) => {
            const reg = new RegExp(mimeType);
            return !!reg.exec(document.mimeType);
        });
    }
}

/**
 * exponential backoff implementation
 * https://developers.google.com/drive/api/v2/handle-errors#exponential-backoff
 *
 * @param maxTries
 * @param baseRetryDelay (ms)
 * @returns
 */
function backoff(maxTries: number, baseRetryDelay: number) {
    return pipe(
        retryWhen<any>((attempts) =>
            zip(range(1, maxTries + 1), attempts).pipe(
                mergeMap(([i, a]) => {
                    const reason: string | undefined =
                        a.result?.error?.errors?.[0].reason;
                    if (
                        i <= maxTries &&
                        a.status === 403 &&
                        reason &&
                        ['userRateLimitExceeded', 'rateLimitExceeded'].includes(
                            reason,
                        )
                    ) {
                        const retryTimeout = i * i * baseRetryDelay;
                        console.debug(
                            `Exponential backoff, retry after ${retryTimeout}ms`,
                        );
                        return timer(retryTimeout);
                    } else return throwError(a);
                }),
            ),
        ),
    );
}

/**
 * Apply exponential backoff and log errors (not handled by backoff)
 */
function backoffAndErrorHandling<T>(observable: Observable<T>): Observable<T> {
    return observable.pipe(
        backoff(4, 1000),
        catchError((error) => {
            console.debug('[gapi.client.drive] API error ', error);
            return throwError(error);
        }),
    );
}
