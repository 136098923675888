import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe usata per aggiungere l'asterisco delle obbligatorietà alle varie label
 * @param isRequired abilitazione della pipe sulla base del flag
 *
 */

@Pipe({
    name: 'required',
    standalone: true,
})
export class RequiredPipe implements PipeTransform {
    transform(value = '', isRequired = true): string {
        return isRequired ? value + ' *' : value;
    }
}
