import { assertUnreachable } from '@interacta-shared/util';
import { AnyGallerySourceDetail } from '@modules/state/models/gallery-source.model';
import { IFilePickerAttachment } from '@modules/state/models/gallery-state.model';
import { MetadataFieldModeType } from '../models/custom-metadata/custom-metadata.model';

const embeddedFilePickerSource = (
    type: MetadataFieldModeType,
    filePicker: IFilePickerAttachment,
    _postId: number,
    context: 'form' | 'preview' | 'default',
): AnyGallerySourceDetail => {
    if (context !== 'default') {
        throw new Error(`${context} context not supported for type ${type} `);
    }
    return {
        feature: 'embedded',
        attachmentList: filePicker.attachments,
    };
};

const filePickerGallerySourceDetail: {
    [K in MetadataFieldModeType]: (
        type: K,
        filePicker: IFilePickerAttachment,
        postId: number,
        context: 'form' | 'preview' | 'default',
    ) => AnyGallerySourceDetail;
} = {
    post: (_type, filePicker, postId, context) => {
        switch (context) {
            case 'default':
                return { feature: 'postFilePicker', filePicker, postId };
            case 'preview':
                return { feature: 'postPreviewFilePicker', filePicker, postId };
            case 'form':
                return { feature: 'postFormFilePicker', filePicker, postId };
            default:
                assertUnreachable(context);
        }
    },
    screen: (type, filePicker, postId, context) => {
        if (context !== 'default') {
            throw new Error(
                `${context} context not supported for type ${type} `,
            );
        }
        return {
            feature: 'screenFilePicker',
            filePicker,
            postId,
        };
    },
    'ack-task-screen': embeddedFilePickerSource,
    survey: (type, filePicker, postId, context) => {
        if (context !== 'default') {
            throw new Error(
                `${context} context not supported for type ${type} `,
            );
        }
        return {
            feature: 'surveyFilePicker',
            filePicker,
            postId,
        };
    },
};

const filePickerGallerySourceDetailFactory = <M extends MetadataFieldModeType>(
    type: M,
    filePicker: IFilePickerAttachment,
    postId: number,
    context: 'form' | 'preview' | 'default',
): AnyGallerySourceDetail =>
    filePickerGallerySourceDetail[type](type, filePicker, postId, context);

export const getFilePickerGallerySourceDetail = (
    filePicker: IFilePickerAttachment,
    postId: number,
    context: 'form' | 'preview' | 'default' = 'default',
): AnyGallerySourceDetail =>
    filePickerGallerySourceDetailFactory(
        filePicker.filePickerMode,
        filePicker,
        postId,
        context,
    );
