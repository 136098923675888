import { UntypedFormGroup } from '@angular/forms';
import {
    ConfigurationService,
    fromZonedDatetime,
    Language,
    toZonedDatetime,
    ZonedDateTime,
} from '@interacta-shared/data-access-configuration';
import {
    emptyPaginatedList,
    isDefined,
    PaginatedList,
    unique,
} from '@interacta-shared/util';
import { getPathIcon } from '@interacta-shared/util-common';
import { toCustomFieldDefinitions } from '@modules/communities/models/custom-metadata/custom-metadata.deserialize';
import {
    ICustomFieldDefinition,
    MetadataFieldModeType,
} from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { isCatalogGenericListField } from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import { toHashtag } from '@modules/communities/models/hashtag/hashtag.deserialize';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { toPostViewScenarioIndex } from '@modules/communities/models/post-view/post-view.deserialize';
import { PostViewScenarioIndex } from '@modules/communities/models/post-view/post-view.model';
import { toWorkflowStatus } from '@modules/communities/models/workflow/workflow-base.deserialize';
import {
    toWorkflowDefinition,
    toWorkflowOperation,
} from '@modules/communities/models/workflow/workflow.deserialize';
import {
    IWorkflowDefinition,
    IWorkflowOperation,
    IWorkflowStatus,
} from '@modules/communities/models/workflow/workflow.model';
import {
    IUser,
    IUserAction,
    IUsersGroup,
    QuillText,
    UserDeserilize,
    UserExtendedDeserialize,
} from '@modules/core';
import { Delta2Server } from '@modules/core/helpers/delta/delta-2-server.class';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import { idArraytoMap } from '@modules/core/helpers/generic.utils';
import {
    CustomButtonType,
    ICustomButton,
    toCustomButtonArray,
} from '@modules/core/models/custom-button/custom-button.models';
import { Member } from '@modules/core/models/member/member.model';
import {
    isMemberUser,
    wrapMember,
} from '@modules/core/models/member/member.utils';
import { WorkflowHistoryItem } from '@modules/core/models/workflow-history/workflow-history.model';
import {
    ICommonCollectionsHolder,
    ICommonCollectionsHolderCreate,
    ICommonCollectionsHolderEdit,
} from '@modules/post/models/common-collections-holder.model';
import { toQuillTables } from '@modules/shared-v2/models/quill-table/quill-table.deserialize';
import { QuillTable } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { fromQuillTables } from '@modules/shared-v2/models/quill-table/quill-table.serialize';
import {
    FeedbackTaskState,
    IAcknowledgeTaskFieldDefinition,
} from '@modules/tasks/models/task.model';
import { Observable } from 'rxjs';
import {
    toAttachmentDetails,
    toAttachmentsCounter,
    toAttachmentsList,
    toFilePickerAttachmentsCounter,
} from './attachment/attachment.deserialize';
import {
    AttachmentCategoryType,
    IAttachment,
    IAttachmentCounter,
    IAttachmentListEdit,
    ICoverImage,
    IListAttachments,
} from './attachment/attachment.model';
import {
    fromAttachmentAdding,
    fromAttachmentsListEdit,
} from './attachment/attachment.serialize';
import { IComment, ICommentDraft } from './comment.model';
import { ICustomPostForContext } from './custom-post.model';
import { EventParticipateType, Invitation } from './event-post.model';
import { GenericPost } from './generic-post.model';
import {
    ILinkPreview,
    LinkPreviewDeserialize,
    LinkPreviewSerialize,
} from './link-preview.model';
import { toLinkedBy } from './post-link/post-link.deserialize';
import { ILinkedBy } from './post-link/post-link.model';
import { SurveyType } from './survey-post/survey-post.model';
import { getSurveyPostType } from './survey-post/survey-post.utils';

export enum PostType {
    CUSTOM = 1,
    EVENT = 2,
    SURVEY = 3,
}

export enum VisibilityType {
    PRIVATE = 1,
    PUBLIC = 2,
}

export enum ConfigurationFieldType {
    DISABLED = 0,
    ENABLED = 1,
    REQUIRED = 2,
}

export enum Recipients {
    ALL_COMMUNITY = 0,
    SELECTED_USERS = 1,
}

export enum RecipientGroupCardinality {
    ALL = 1,
    AT_LEAST_ONE = 2,
}

export enum FilterAcknowledge {
    CONFIRMED = 1,
    TO_BE_CONFIRMED = 2,
    CONFIRMED_BY_ME = 3,
    TO_BE_CONFIRMED_BY_ME = 4,
}

export enum TaskProcessingStatus {
    IN_PROGRESS = 1,
    COMPLETED = 2,
}

enum ScheduledPublicationResult {
    SUCCESS = 1,
    FAILED = 2,
}

export interface BasePostLite {
    id: number;
    communityId: number;
    type: PostType;
    code?: string;
    title?: string;
    occToken?: number;
    visibility: VisibilityType;
    creatorUser: IUser;
    currentWorkflowState?: IWorkflowStatus;
}

export interface IBasePost extends BasePostLite, ICommonCollectionsHolder {
    descriptionDelta: QuillText;
    description: string;
    pinned: boolean;
    likesCount?: number;
    commentsCount?: number;
    visibleCommentsCountDetail?: number;
    visibleCommentsCountDashboard?: number;

    watchersCount?: number;

    privateCommunityViewersList: PaginatedList<Member>;
    taskAssigneesList: PaginatedList<Member>;
    extendedVisibilityList: ExtendedPostVisibilityMembers;
    ackTaskAssigneeList: PaginatedList<Member>;
    invitationsList: InvitationsList;

    viewedByUsersCount?: number;
    creationTimestamp?: Date;
    lastModifyUser?: IUser;
    lastModifyTimestamp?: Date;
    lastModifyOrCommentTimestamp?: Date;
    hashtags: IHashTag[];
    activeHashtags: IHashTag[];
    memberMentions?: PaginatedList<Member>;
    capabilities?: IPostCapability;
    likedByMe?: boolean;
    followedByMe?: boolean;
    viewedByMe?: boolean;
    viewedTimestamp: Date;
    showLikeSection?: boolean;
    metadata?: IPostMetadata; // don't trust of this property. Must be calcultated from correct api
    rightAlignText: boolean;

    coverImage?: ICoverImage;

    isTranslated?: boolean;
    language?: Language;
    isLoading?: boolean;
    linkPreview?: ILinkPreview;

    viewersList: PaginatedList<IUserAction>;
    likersList: PaginatedList<IUserAction>;

    comments: PaginatedList<IComment>;
    commentDraft: ICommentDraft;
    isCreatingComment: boolean;

    mediaList: IListAttachments;
    documentList: IListAttachments;
    commentsMediaList: IListAttachments;
    commentsDocumentList: IListAttachments;
    filePickerMediaList: IListAttachments; // Attachments to show in post detail media tab
    filePickerDocumentList: IListAttachments; // Attachments to show in post detail document tab

    areCommentsStale?: boolean;

    totalStandardTasksCount?: number;
    mainAttachment?: IAttachment;

    workflowHistory?: PaginatedList<WorkflowHistoryItem>;
    draft: boolean;
    draftOriginalValue: boolean;
    isScheduled: boolean;
    scheduledPublication?: ZonedDateTime;
    scheduledPublicationFailed: boolean;

    privateCommunityViewersCount?: number;
    mentionsCount?: number;

    surveyTaskSummary?: TaskSummary;

    acknowledgeTaskSummary?: TaskSummary;

    feedbackTaskSummary?: FeedbackTaskSummary;

    tables?: QuillTable[];
}

export interface TaskSummary {
    assignedToMeTasksCount: number;
    assignedToMeExpiringTasksCount: number;
    assignedToMeExpiredTasksCount: number;
    totalTasksCount?: number;
    openTasksCount?: number;
    assignedToMeMinTasksExpirationDate?: ZonedDateTime;
    processingStatus?: TaskProcessingStatus;
    toCreateTasksCount?: number;
}

export interface FeedbackTaskSummary {
    assignedToMeTasksCount: Record<FeedbackTaskState, number>;
    processingStatus?: TaskProcessingStatus;
    tasksCount?: Record<FeedbackTaskState, number>;
}

export type InvitationsList = PaginatedList<Invitation> & {
    participantsResponseCountByTypeMap?: ParticipantsResponseCount;
};

export type ParticipantsResponseCount = Record<EventParticipateType, number>;

export interface PostVisibilityCounters {
    taskAssigneesCount: number;
    ackTaskAssigneesCount: number;
    viewPrivatePostCapabilityCount: number;
    extendedVisibilityCount: number;
    watchersCount: number;
    mentionsCount: number;
    invitationsCount: number;
    surveyTaskAssigneesCount: number;
    surveyManagersCount: number;
    surveyAnswerViewersCount: number;
    feedbackTaskAssigneesCount: number;
    feedbackManagersCount: number;
    feedbackAnswerViewersCount: number;
}

export interface IBasePostCreate
    extends IBasePost,
        ICommonCollectionsHolderCreate {
    clientUid?: string;
}

export interface IBasePostEdit
    extends IBasePostCreate,
        ICommonCollectionsHolderEdit {}

export interface IBasePostDifferential extends IBasePost {
    status: 'CREATED' | 'MODIFIED' | 'TOUCHED' | 'DELETED';
}

export interface IPostCapability {
    canViewDetail: boolean;
    canModify: boolean;
    canCopy: boolean;
    canDelete: boolean;
    canAddComment: boolean;
    canViewComment: boolean;
    canEditLike: boolean;
    canEditFollow: boolean;
    canEditAttachments: boolean;
    canViewEventInvitations: boolean;
    canCreateTask: boolean;
    workflowPermittedOperations?: IWorkflowOperation[];
    canEditWorkflowScreenData: boolean;
    canEditVisibility: boolean;
    canEditEventPartecipate: boolean;
    canViewEventStreamingDetail: boolean;
    canEditSurveyAnswer: boolean;
    canViewSurveyAnswers: boolean;
    canValidateTask: boolean;
    canSeparate?: boolean;
}

export interface RelatedItemsCounts {
    linkedBy: Array<{
        communityId: number;
        count: number;
    }>;
}

export interface IPostMetadata {
    acknowledgeTaskEnabled: boolean;
    acknowledgeTaskDefinition?: IAcknowledgeTaskFieldDefinition;
    activeHashtags: IHashTag[];
    attachmentEnabled: boolean;
    attachmentMaxSize: number;
    canEditEventPartecipate: boolean;
    communityId: number;
    customIdEnabled: boolean;
    defaultPostVisibility: VisibilityType;
    descriptionEnabled: ConfigurationFieldType;
    fieldMetadatas: ICustomFieldDefinition[];
    hashtags: IHashTag[];
    hashTagEnabled: boolean;
    likeEnabled: boolean;
    linkedBy: ILinkedBy[];
    manualAuthorEnabled: boolean;
    postViews: PostViewScenarioIndex;
    reportAbuseEnabled: boolean;
    showOpenRelationsButton: boolean;
    titleEnabled: ConfigurationFieldType;
    watchersEnabled: ConfigurationFieldType;
    workflowScreenDataVisibleInDetail: boolean;
    watchersVisibleInPreview: boolean;
    workflowDefinition?: IWorkflowDefinition;
    customButtons?: ICustomButton[];
    attachmentCustomActions?: ICustomButton[];
    customPostEnabled: boolean;
    eventPostEnabled: boolean;
    surveyPostEnabled: boolean;
    feedbackPostEnabled: boolean;
    aiEnabled: boolean;
}

export type PostFormOperation = 'save' | 'edit' | 'copy';

export interface BasePostForm<T extends IBasePost> {
    readonly submitted: boolean;
    readonly form: UntypedFormGroup;
    save(needsScheduling: boolean): Observable<T | null>;
    edit(needsScheduling: boolean): Observable<T | null>;
    copy(needsScheduling: boolean): Observable<T | null>;
    validate: (type: PostFormOperation) => T | null;
}

export interface ExtendedPostVisibilityMembers {
    ackTaskScreenFields: Record<number, Member[]>;
    customVisibilityMembers: Member[];
    postFields: Record<number, Member[]>;
    workflowScreenFields: Record<number, Member[]>;
    totalCount?: number;
}

export interface MainAttachmentParams {
    loadMainAttachmentViewLink: 'true' | 'false';
    loadMainAttachmentDownloadLink: 'true' | 'false';
    loadMainAttachmentPreviewImageLink: 'true' | 'false';
}

export interface AdvancedPostType {
    type: PostType;
    subtype?: SurveyType;
}

export class BasePostDeserialize {
    static postMetadata = (record: any): IPostMetadata => ({
        communityId: record.communityId,
        manualAuthorEnabled: record.manualAuthorEnabled,
        likeEnabled: record.likeEnabled,
        hashTagEnabled: record.hashTagEnabled,
        titleEnabled: record.titleEnabled,
        descriptionEnabled: record.descriptionEnabled,
        watchersEnabled: record.watchersEnabled,
        customIdEnabled: record.customIdEnabled,
        attachmentEnabled: record.attachmentEnabled,
        attachmentMaxSize: record.attachmentMaxSize,
        fieldMetadatas: record.fieldDefinitions
            ? toCustomFieldDefinitions(
                  record.fieldDefinitions as any[],
                  MetadataFieldModeType.POST,
              )
            : [],
        hashtags: (record.hashtags || []).map(toHashtag),
        activeHashtags: (record.hashtags || [])
            .map(toHashtag)
            .filter((hashtag: IHashTag) => !hashtag.deleted),
        workflowDefinition: record.workflowDefinition
            ? toWorkflowDefinition(record.workflowDefinition)
            : undefined,
        defaultPostVisibility: record.defaultPostVisibility,
        watchersVisibleInPreview: record.watchersVisibleInPreview,
        canEditEventPartecipate: record.canEditEventPartecipate,
        postViews: toPostViewScenarioIndex((record.postViews ?? []) as any[]),
        reportAbuseEnabled: record.reportAbuseEnabled ?? false,
        showOpenRelationsButton: record.showOpenRelationsButton ?? false,
        linkedBy: (record.linkedBy || [])
            .filter(isDefined)
            .map((l: any) => toLinkedBy(l)),
        acknowledgeTaskEnabled: record.acknowledgeTaskEnabled ?? false,
        acknowledgeTaskDefinition: record.acknowledgeTaskDefinition
            ? BasePostDeserialize.toAcknowledgeTaskFieldDefinition(
                  record.acknowledgeTaskDefinition,
              )
            : undefined,
        workflowScreenDataVisibleInDetail:
            record.workflowScreenDataVisibleInDetail,
        customButtons: record.customActions
            ? toCustomButtonArray(
                  record.customActions,
                  CustomButtonType.POST_ACTION,
              )
            : undefined,
        attachmentCustomActions: record.attachmentCustomActions
            ? toCustomButtonArray(
                  record.attachmentCustomActions,
                  CustomButtonType.ATTACHMENT_ACTION,
              )
            : undefined,
        customPostEnabled: record.customPostEnabled ?? true,
        eventPostEnabled: record.eventPostEnabled ?? true,
        feedbackPostEnabled: record.feedbackPostEnabled ?? true,
        surveyPostEnabled: record.surveyPostEnabled ?? true,
        aiEnabled: record.aiEnabled ?? true,
    });

    static toBasePostLite = (record: any): BasePostLite => ({
        id: record.id,
        communityId: record.communityId,
        title: record.title ?? undefined,
        type: record.type,
        code: record.customId,
        creatorUser: UserDeserilize.userDetails(record.creatorUser),
        occToken: record.occToken,
        visibility: record.visibility,
        currentWorkflowState: record.currentWorkflowState
            ? toWorkflowStatus(record.currentWorkflowState)
            : undefined,
    });

    protected static basePostDetails<T extends IBasePost>(
        post: T,
        recordPost: any,
        configurationService: ConfigurationService,
        metadata?: IPostMetadata,
    ): T {
        const postAttachmentsCounter: IAttachmentCounter = toAttachmentsCounter(
            recordPost.postAttachmentCounter,
        );

        const commentsAttachmentsCounter: IAttachmentCounter =
            toAttachmentsCounter(recordPost.commentAttachmentCounter);

        const filePickerAttachmentsCounter: IAttachmentCounter =
            toFilePickerAttachmentsCounter(post);

        const scheduledPublicationFailed =
            recordPost.scheduledPublicationResult ===
            ScheduledPublicationResult.FAILED;

        const recordCurrentWorkflowState = recordPost.draft
            ? recordPost.draftData?.workflowInitState
            : recordPost.currentWorkflowState;

        const result: T = {
            ...post,
            id: recordPost.id ? +recordPost.id : null,
            communityId: recordPost.communityId
                ? +recordPost.communityId
                : null,
            title: recordPost.title,
            description: recordPost.descriptionPlainText ?? null,
            descriptionDelta: recordPost.descriptionDelta
                ? new Server2Delta().process(
                      recordPost.descriptionDelta as string,
                  )
                : null,
            code: recordPost.customId,
            creatorUser: recordPost.creatorUser
                ? UserDeserilize.userDetails(recordPost.creatorUser)
                : null,
            creationTimestamp: recordPost.creationTimestamp
                ? new Date(recordPost.creationTimestamp as number)
                : null,
            lastModifyUser: recordPost.lastModifyUser
                ? UserDeserilize.userDetails(recordPost.lastModifyUser)
                : null,
            lastModifyTimestamp: recordPost.lastModifyTimestamp
                ? new Date(recordPost.lastModifyTimestamp as number)
                : null,
            lastModifyOrCommentTimestamp:
                recordPost.lastModifyOrCommentTimestamp
                    ? new Date(
                          recordPost.lastModifyOrCommentTimestamp as number,
                      )
                    : null,
            watcherUsers: recordPost.watcherUsers
                ? recordPost.watcherUsers.map(UserDeserilize.userDetails)
                : [],
            watcherGroups: recordPost.watcherGroups
                ? recordPost.watcherGroups.map(
                      UserExtendedDeserialize.usersGroup,
                  )
                : [],
            commentsCount: recordPost.commentsCount || 0,
            comments: emptyPaginatedList(recordPost.commentsCount as number),
            watchersCount:
                recordPost.watchersCount ??
                (recordPost.watcherUsers?.length ?? 0) +
                    (recordPost.watcherGroups?.length ?? 0),
            viewedByUsersCount: recordPost.viewedByUsersCount || 0,
            likesCount: recordPost.likesCount || 0,
            likedByMe: recordPost.likedByMe || false,
            viewedByMe: recordPost.viewedByMe || false,
            viewedTimestamp: recordPost.viewedTimestamp
                ? new Date(recordPost.viewedTimestamp as number)
                : null,
            showLikeSection: recordPost.showLikeSection || false,
            followedByMe: recordPost.followedByMe || false,
            visibility: recordPost.visibility,
            currentWorkflowState: recordCurrentWorkflowState
                ? toWorkflowStatus(recordCurrentWorkflowState)
                : null,
            hashtags: (recordPost.hashtags || []).map(toHashtag),
            activeHashtags: (recordPost.hashtags || [])
                .map(toHashtag)
                .filter((hashtag: IHashTag) => !hashtag.deleted),
            metadata: metadata,
            announcement: recordPost.announcement,
            memberMentions: recordPost.memberMentions,
            attachmentsList: toAttachmentsList(
                (recordPost.attachments as any[]) || null,
                recordPost.attachmentsCount as number,
            ),
            mediaList: toAttachmentsList(
                null,
                postAttachmentsCounter.mediasCount,
                AttachmentCategoryType.MULTIMEDIA,
            ),
            documentList: toAttachmentsList(
                null,
                postAttachmentsCounter.documentsCount,
                AttachmentCategoryType.DOCUMENT,
            ),
            commentsMediaList: toAttachmentsList(
                null,
                commentsAttachmentsCounter.mediasCount,
                AttachmentCategoryType.MULTIMEDIA,
            ),
            commentsDocumentList: toAttachmentsList(
                null,
                commentsAttachmentsCounter.documentsCount,
                AttachmentCategoryType.DOCUMENT,
            ),
            filePickerMediaList: toAttachmentsList(
                null,
                filePickerAttachmentsCounter.mediasCount,
                AttachmentCategoryType.MULTIMEDIA,
            ),
            filePickerDocumentList: toAttachmentsList(
                null,
                filePickerAttachmentsCounter.documentsCount,
                AttachmentCategoryType.DOCUMENT,
            ),
            pinned: recordPost.pinned ?? false,
            rightAlignText: recordPost.rightAlignText ?? false,
            occToken: recordPost.occToken,
            commentDraft: {
                isEditing: false,
            },
            isCreatingComment: false,
            mainAttachment: recordPost?.mainAttachment
                ? toAttachmentDetails(recordPost.mainAttachment)
                : null,
            likersList: emptyPaginatedList(Number(recordPost.likesCount || 0)),
            viewersList: emptyPaginatedList(
                Number(recordPost.viewedByUsersCount || 0),
            ),
            workflowHistory: null,
            isScheduled:
                recordPost.scheduledPublication != null && recordPost.draft,
            scheduledPublication: recordPost.scheduledPublication
                ? this.toScheduledPublication(
                      recordPost.scheduledPublication,
                      configurationService,
                  )
                : undefined,
            scheduledPublicationFailed,
            capabilities: isDefined(recordPost.capabilities)
                ? this.postCapabilities(recordPost.capabilities)
                : undefined,
            surveyTaskSummary: recordPost.surveyTaskSummary
                ? this.toTaskSummary(
                      recordPost.surveyTaskSummary,
                      configurationService,
                  )
                : undefined,
            acknowledgeTaskSummary: recordPost.acknowledgeTaskSummary
                ? this.toTaskSummary(
                      recordPost.acknowledgeTaskSummary,
                      configurationService,
                  )
                : undefined,
            feedbackTaskSummary: recordPost.feedbackTaskSummary
                ? this.toFeedbackTaskSummary(recordPost.feedbackTaskSummary)
                : undefined,
            tables: recordPost.tables
                ? toQuillTables(recordPost.tables)
                : undefined,
        };
        if (recordPost.coverImage || recordPost.coverImageContentRef) {
            result.coverImage = {
                temporaryContentViewLink: recordPost.coverImage
                    ? recordPost.coverImage.temporaryContentViewLink
                    : undefined,
                temporaryContentPreviewImageLink: recordPost.coverImage
                    ? recordPost.coverImage.temporaryContentPreviewImageLink
                    : undefined,
                contentRef: recordPost.coverImageContentRef,
                temporaryContentPreviewImageHiResLink: recordPost.coverImage
                    ? recordPost.coverImage
                          .temporaryContentPreviewImageHiResLink
                    : undefined,
                iconPath: getPathIcon(recordPost.coverImage.contentMimeType),
            };
        }

        result.linkPreview = recordPost.linkPreview
            ? LinkPreviewDeserialize.linkPreview(recordPost.linkPreview)
            : undefined;

        //Rifaccio il calcolo dei watcher perchè l'API "post-detail-by-id" non restituisce il totale degli utenti e gruppi da notificare
        result.watchersCount = isDefined(result.watchersCount)
            ? result.watchersCount
            : isDefined(result.watcherUsers?.length) &&
                isDefined(result.watcherGroups?.length)
              ? (result.watcherUsers?.length || 0) +
                (result.watcherGroups?.length || 0)
              : undefined;
        result.totalStandardTasksCount = recordPost.totalStandardTasksCount;
        result.draft = !!recordPost.draft;
        result.draftOriginalValue = !!recordPost.draft;

        return result;
    }

    protected static basePostDetailsForEdit<T extends IBasePost>(
        post: T,
        record: any,
    ): T {
        //const post = PostDeserialize.basicPostDetails(record, metadata); // data like title, description, customData etc.
        const result: T = { ...post };
        result.descriptionDelta = post.descriptionDelta;
        result.occToken = record.occToken;
        return result;
    }

    protected static basePostDetailsForCreate<T extends IBasePost>(
        post: T,
        currentUser: IUser,
    ): T {
        const result: T = {
            ...post,
            creatorUser: currentUser,
            id: null,
            draft: false,
            isScheduled: false,
            scheduledPublication: undefined,
        };
        return result;
    }

    static postCapabilities = (record: any): IPostCapability => ({
        canViewDetail: record.canViewDetail,
        canModify: record.canModify,
        canCopy: record.canCopy,
        canDelete: record.canDelete,
        canAddComment: record.canAddComment && (record.canViewComment ?? true),
        canViewComment: record.canViewComment ?? true,
        canEditLike: record.canEditLike,
        canEditFollow: record.canEditFollow,
        canEditAttachments: record.canEditAttachments,
        workflowPermittedOperations: (
            record.workflowPermittedOperations || []
        ).map(toWorkflowOperation),
        canCreateTask: record.canCreateTask,
        canEditWorkflowScreenData: record.canEditWorkflowScreenData,
        canEditVisibility: record.canEditVisibility,
        canEditEventPartecipate: record.canEditEventPartecipate,
        canViewEventStreamingDetail: record.canViewEventStreamingDetail,
        canViewEventInvitations: record.canViewEventInvitations,
        canEditSurveyAnswer: record.canEditSurveyAnswer,
        canViewSurveyAnswers: record.canViewSurveyAnswers,
        canValidateTask: record.canValidateTask,
    });

    static postListCapabilities = (
        record: any,
        postIds: number[],
    ): IPostCapability[] => {
        const capabilities: Array<
            IPostCapability & {
                id: number;
            }
        > = (<Array<{ postId: number }>>record.listPostCapabilities).map(
            (p) => ({
                ...BasePostDeserialize.postCapabilities(p),
                id: p.postId,
            }),
        );

        const capabilitiesMap = idArraytoMap(capabilities);

        return postIds.map((id) => capabilitiesMap[id]);
    };

    static translatedPost = (
        post: any,
        configurationService: ConfigurationService,
        language?: Language,
    ): IBasePost => {
        return {
            ...BasePostDeserialize.basePostDetails(
                {} as IBasePost,
                post,
                configurationService,
            ),
            language,
        };
    };

    static toAcknowledgeTaskFieldDefinition = (
        record: any,
    ): IAcknowledgeTaskFieldDefinition => ({
        id: record.id,
        fieldDefinitions: record.fieldDefinitions
            ? toCustomFieldDefinitions(
                  record.fieldDefinitions,
                  MetadataFieldModeType.ACK_TASK,
              )
            : [],
    });

    static createMembersRecord = (res: any): Record<number, Member[]> => {
        const record: Record<number, Member[]> = {};
        Object.entries(res.userPickers).forEach(
            ([key, value]: [string, any]) => {
                record[parseInt(key)] = value.map((v: any) =>
                    wrapMember(UserDeserilize.userDetails(v)),
                );
            },
        );
        Object.entries(res.groupPickers).forEach(
            ([key, value]: [string, any]) => {
                record[parseInt(key)] = value.map((v: any) =>
                    wrapMember(UserExtendedDeserialize.usersGroup(v)),
                );
            },
        );
        return record;
    };

    static toPostVisibilityCounters = (
        res: any,
        surveyType?: SurveyType,
    ): PostVisibilityCounters => ({
        ackTaskAssigneesCount: res.ackTaskAssigneesCount ?? 0,
        extendedVisibilityCount: res.extendedVisibilityCount ?? 0,
        mentionsCount: res.mentionsCount ?? 0,
        taskAssigneesCount: res.taskAssigneesCount ?? 0,
        viewPrivatePostCapabilityCount: res.viewPrivatePostCapabilityCount ?? 0,
        watchersCount: res.watchersCount ?? 0,
        invitationsCount: res.invitationsCount ?? 0,
        surveyTaskAssigneesCount:
            surveyType === SurveyType.SURVEY
                ? res.surveyTaskAssigneesCount ?? 0
                : 0,
        surveyManagersCount:
            surveyType === SurveyType.SURVEY ? res.surveyManagersCount ?? 0 : 0,
        surveyAnswerViewersCount:
            surveyType === SurveyType.SURVEY
                ? res.surveyAnswerViewersCount ?? 0
                : 0,
        feedbackTaskAssigneesCount:
            surveyType === SurveyType.FEEDBACK
                ? res.surveyTaskAssigneesCount ?? 0
                : 0,
        feedbackManagersCount:
            surveyType === SurveyType.FEEDBACK
                ? res.surveyManagersCount ?? 0
                : 0,
        feedbackAnswerViewersCount:
            surveyType === SurveyType.FEEDBACK
                ? res.surveyAnswerViewersCount ?? 0
                : 0,
    });

    static toExtendedVisibilityMembers = (
        res: any,
    ): ExtendedPostVisibilityMembers => {
        const result = {
            ackTaskScreenFields: BasePostDeserialize.createMembersRecord(
                res.ackTaskScreenFields,
            ),
            customVisibilityMembers:
                BasePostDeserialize.toCustomVisibilityMembers(
                    res.customVisibilityMembers as any[],
                ),
            postFields: BasePostDeserialize.createMembersRecord(res.postFields),
            workflowScreenFields: BasePostDeserialize.createMembersRecord(
                res.workflowScreenFields,
            ),
        };
        return result;
    };

    static toCustomVisibilityMembers = (members: any[]): Member[] => {
        return members.map((m) =>
            m.id && (m.id as string).startsWith('u-')
                ? wrapMember(UserDeserilize.userDetails(m.user))
                : wrapMember(UserExtendedDeserialize.usersGroup(m.group)),
        );
    };

    static toScheduledPublication = (
        record: any,
        configurationService: ConfigurationService,
    ): ZonedDateTime => {
        return toZonedDatetime(record, configurationService);
    };

    static toTaskSummary = (
        record: any,
        configurationService: ConfigurationService,
    ): TaskSummary => ({
        assignedToMeTasksCount: record.assignedToMeTasksCount ?? 0,
        assignedToMeExpiringTasksCount:
            record.assignedToMeExpiringTasksCount ?? 0,
        assignedToMeExpiredTasksCount:
            record.assignedToMeExpiredTasksCount ?? 0,
        totalTasksCount: record.totalTasksCount ?? undefined,
        openTasksCount: record.openTasksCount ?? undefined,
        assignedToMeMinTasksExpirationDate:
            record?.assignedToMeMinTasksExpirationDate != null
                ? toZonedDatetime(
                      record.assignedToMeMinTasksExpirationDate,
                      configurationService,
                  )
                : undefined,
        processingStatus: record.processingStatus ?? undefined,
        toCreateTasksCount: record.toCreateTasksCount ?? undefined,
    });

    static toFeedbackTaskSummary = (record: any): FeedbackTaskSummary => ({
        assignedToMeTasksCount: record.stateIdAssignedToMeTasksCountMap,
        tasksCount: record.stateIdTasksCountMap ?? undefined,
        processingStatus: record.processingStatus ?? undefined,
    });
}

export class BasePostSerialize {
    protected static basePostDetails = (record: any, post: IBasePost): any => {
        const result: any = {
            ...record,
            title:
                post.metadata?.titleEnabled ||
                ([PostType.EVENT, PostType.SURVEY].includes(post.type) &&
                    post.title &&
                    post.title.trim().length > 0)
                    ? post.title
                    : null,
            description:
                post.metadata?.descriptionEnabled ||
                post.type === PostType.EVENT ||
                post.type === PostType.SURVEY
                    ? new Delta2Server().process(post.descriptionDelta)
                    : null,
            visibility: post.visibility,
            tables: post.tables ? fromQuillTables(post.tables) : undefined,
        };
        if (post.coverImage) {
            result.coverImageContentRef = post.coverImage.contentRef;
        }

        result.linkPreview = post.linkPreview
            ? LinkPreviewSerialize.linkPreview(post.linkPreview)
            : null;
        result.draft = post.draft;
        result.scheduledPublication =
            post.isScheduled && post.scheduledPublication
                ? fromZonedDatetime(post.scheduledPublication)
                : null;
        return result;
    };

    protected static newBasePost = (
        record: any,
        post: IBasePostCreate,
    ): any => {
        const result: any = { ...record };
        result.attachments = (post.addAttachments || []).map((a) =>
            fromAttachmentAdding(a),
        );
        result.watcherUserIds = (post.watcherUsers || []).map(
            (user: IUser) => user.id,
        );
        result.watcherGroupIds = (post.watcherGroups || []).map(
            (group: IUsersGroup) => group.id,
        );
        result.draft = post.draft;
        result.clientUid = post.clientUid;
        return result;
    };

    protected static editBasePost = (record: any, post: IBasePostEdit): any => {
        const result = {
            ...record,
            ...fromAttachmentsListEdit(<IAttachmentListEdit>post),
        };
        result.addWatcherUserIds = post.addWatcherUserIds;
        result.addWatcherGroupIds = post.addWatcherGroupIds;
        result.removeWatcherUserIds = post.removeWatcherUserIds;
        result.removeWatcherGroupIds = post.removeWatcherGroupIds;
        result.coverImageContentRef = post.coverImage
            ? post.coverImage.contentRef || null
            : null;
        result.draft = post.draft;
        return result;
    };

    protected static copyBasePost = (record: any, post: IBasePostEdit): any => {
        const result = BasePostSerialize.editBasePost(record, post);
        result.draft = post.draft;
        result.clientUid = post.clientUid;
        return result;
    };

    protected static contextBasePost = (post: ICustomPostForContext): any => {
        const result: any = {
            id: post.id ?? undefined,
            title:
                post.title && post.title.trim().length > 0 ? post.title : null,
            description: new Delta2Server().process(post.descriptionDelta),
            descriptionFormat: 1,
            watcherUserIds: (post.watcherUsers || []).map(
                (user: IUser) => user.id,
            ),
            watcherGroupIds: (post.watcherGroups || []).map(
                (group: IUsersGroup) => group.id,
            ),
            visibility: post.visibility,
            pinned: post.pinned,
        };
        return result;
    };

    static editWatchers = (added: Member[], removed: Member[]): any => {
        const watcherData = <any>{
            addWatcherUserIds: [],
            addWatcherGroupIds: [],
            removeWatcherUserIds: [],
            removeWatcherGroupIds: [],
        };
        (added || []).map((watcher: Member) => {
            if (isMemberUser(watcher)) {
                watcherData.addWatcherUserIds.push(watcher.innerId);
            } else {
                watcherData.addWatcherGroupIds.push(watcher.innerId);
            }
        });
        (removed || []).map((watcher: Member) => {
            if (isMemberUser(watcher)) {
                watcherData.removeWatcherUserIds.push(watcher.innerId);
            } else {
                watcherData.removeWatcherGroupIds.push(watcher.innerId);
            }
        });
        return watcherData;
    };
}

export function getPostDefinitionCatalogIds(
    postDefinition: IPostMetadata,
): number[] {
    const customFields = [
        ...postDefinition.fieldMetadatas,
        ...(postDefinition.workflowDefinition?.screenFieldMetadatas ?? []),
        ...(postDefinition.acknowledgeTaskDefinition?.fieldDefinitions ?? []),
    ];
    const catalogs = customFields.filter(isCatalogGenericListField);
    const catalogIds = catalogs.map(
        (catalog) => catalog.metadata.catalog_id as number,
    );
    return unique(catalogIds);
}

export function defaultBasePostCapabilities(): IPostCapability {
    return {
        canViewDetail: false,
        canModify: false,
        canCopy: false,
        canDelete: false,
        canAddComment: false,
        canViewComment: false,
        canEditLike: false,
        canEditFollow: false,
        canEditAttachments: false,
        canCreateTask: false,
        workflowPermittedOperations: undefined,
        canEditWorkflowScreenData: false,
        canEditVisibility: false,
        canEditEventPartecipate: false,
        canViewEventStreamingDetail: false,
        canViewEventInvitations: false,
        canEditSurveyAnswer: false,
        canViewSurveyAnswers: false,
        canValidateTask: false,
        canSeparate: false,
    };
}

export function getAttachmentsTotalCount(post: IBasePost): number {
    return getMediaCount(post) + getDocumentsCount(post);
}

export function getMediaCount(post: IBasePost): number {
    return (
        post.mediaList.totalCount +
        (post.commentsMediaList?.totalCount ?? 0) +
        post.filePickerMediaList.totalCount
    );
}

export function getDocumentsCount(post: IBasePost): number {
    return (
        post.documentList.totalCount +
        (post.commentsDocumentList?.totalCount ?? 0) +
        post.filePickerDocumentList.totalCount
    );
}

export function getAlreadyConfirmedByMeTaskCount(summary: TaskSummary): number {
    return (
        summary.assignedToMeTasksCount -
        summary.assignedToMeExpiringTasksCount -
        summary.assignedToMeExpiredTasksCount
    );
}

export function getAlreadyConfirmedTotalTaskCount(
    summary: TaskSummary,
): number {
    return (summary.totalTasksCount ?? 0) - (summary.openTasksCount ?? 0);
}

export function buildAdvancedPostType(post: GenericPost): AdvancedPostType {
    return {
        type: post.type,
        subtype: getSurveyPostType(post),
    };
}
