export interface DateFormattedLabels {
    yesterday?: string;
    today: string;
    tomorrow?: string;
    onDay: string;
    atDate: string;
}

export const taskExpiredLabels: DateFormattedLabels = {
    yesterday: 'DATE_FORMAT.TASK.EXPIRED.YESTERDAY',
    today: 'DATE_FORMAT.TASK.EXPIRED.TODAY',
    tomorrow: 'DATE_FORMAT.TASK.EXPIRED.TOMORROW',
    onDay: 'DATE_FORMAT.TASK.EXPIRED.ON_DAY',
    atDate: 'DATE_FORMAT.TASK.EXPIRED.AT_DATE',
};

export const taskExpiresLabels: DateFormattedLabels = {
    yesterday: 'DATE_FORMAT.TASK.EXPIRES.YESTERDAY',
    today: 'DATE_FORMAT.TASK.EXPIRES.TODAY',
    tomorrow: 'DATE_FORMAT.TASK.EXPIRES.TOMORROW',
    onDay: 'DATE_FORMAT.TASK.EXPIRES.ON_DAY',
    atDate: 'DATE_FORMAT.TASK.EXPIRES.AT_DATE',
};

export const taskClosedLabels: DateFormattedLabels = {
    yesterday: 'DATE_FORMAT.TASK.CLOSED.YESTERDAY',
    today: 'DATE_FORMAT.TASK.CLOSED.TODAY',
    tomorrow: 'DATE_FORMAT.TASK.CLOSED.TOMORROW',
    onDay: 'DATE_FORMAT.TASK.CLOSED.ON_DAY',
    atDate: 'DATE_FORMAT.TASK.CLOSED.AT_DATE',
};

export const taskExpirationLabels: DateFormattedLabels = {
    yesterday: 'DATE_FORMAT.TASK.EXPIRATION.YESTERDAY',
    today: 'DATE_FORMAT.TASK.EXPIRATION.TODAY',
    tomorrow: 'DATE_FORMAT.TASK.EXPIRATION.TOMORROW',
    onDay: 'DATE_FORMAT.TASK.EXPIRATION.ON_DAY',
    atDate: 'DATE_FORMAT.TASK.EXPIRATION.AT_DATE',
};

export const surveyPostClosingLabels: DateFormattedLabels = {
    yesterday: `DATE_FORMAT.POST.CLOSING.YESTERDAY`,
    today: `DATE_FORMAT.POST.CLOSING.TODAY`,
    tomorrow: `DATE_FORMAT.POST.CLOSING.TOMORROW`,
    onDay: `DATE_FORMAT.POST.CLOSING.ON_DAY`,
    atDate: `DATE_FORMAT.POST.CLOSING.AT_DATE`,
};

export const surveyPostTypedClosedLabels = (
    type: 'FEEDBACK' | 'SURVEY',
): DateFormattedLabels => {
    return {
        yesterday: `DATE_FORMAT.POST.${type}.CLOSED.YESTERDAY`,
        today: `DATE_FORMAT.POST.${type}.CLOSED.TODAY`,
        tomorrow: `DATE_FORMAT.POST.${type}.CLOSED.TOMORROW`,
        onDay: `DATE_FORMAT.POST.${type}.CLOSED.ON_DAY`,
        atDate: `DATE_FORMAT.POST.${type}.CLOSED.AT_DATE`,
    };
};
