import {
    tipFeatureKey,
    tipsInitialState,
    tipsReducer,
    TipsState,
} from '@interacta-shared/feature-tip';
import {
    uploadReducer,
    UploadState,
} from '@modules/upload-dialog/store/upload.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AppRouterState } from './app-router.serializer';
import * as AppActions from './app.actions';
import { uiReducer, UIState } from './UI/ui.reducer';
import {
    userCapabilitiesFeatureKey,
    userCapabilitiesReducer,
    UserCapabilitiesState,
} from './user-capabilities/user-capabilities.reducer';

export interface AppState {
    router: RouterReducerState<AppRouterState>;
    uiState: UIState;
    uploadState: UploadState;
    [tipFeatureKey]: TipsState;
    [userCapabilitiesFeatureKey]: UserCapabilitiesState;
}

const flush = (reducer: ActionReducer<AppState>): ActionReducer<AppState> => {
    return (state, action) => {
        const nextState =
            action.type === AppActions.flush.type
                ? ({
                      [tipFeatureKey]:
                          state?.[tipFeatureKey] ?? tipsInitialState,
                  } as AppState)
                : state;
        return reducer(nextState, action);
    };
};

export const metaReducers: MetaReducer<AppState>[] = [flush];

export const reducers: ActionReducerMap<AppState> = {
    router: routerReducer,
    uiState: uiReducer,
    uploadState: uploadReducer,
    [tipFeatureKey]: tipsReducer,
    [userCapabilitiesFeatureKey]: userCapabilitiesReducer,
};
