import { Pipe, PipeTransform } from '@angular/core';
import {
    ConfigurationService,
    Language,
} from '@interacta-shared/data-access-configuration';
import { getLabelServerTranslation } from '@modules/core/helpers/generic.utils';
import { i18nString } from '@modules/core/models/utility.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'labelInternationalized',
})
export class LabelInternationalizedPipe implements PipeTransform {
    currentLang$: Observable<Language>;

    constructor(private configurationService: ConfigurationService) {
        this.currentLang$ = configurationService.getCurrentLanguageStream();
    }

    transform(label: i18nString | null | undefined): Observable<string | null> {
        if (!label) return of(null);

        return this.currentLang$.pipe(
            map((lang: Language) => {
                if (!lang || !this.configurationService.getDefaultInfo())
                    return null;
                return (
                    getLabelServerTranslation(
                        label,
                        lang.code,
                        this.configurationService.getDefaultInfo().language
                            .code,
                    ) ?? ''
                );
            }),
        );
    }
}
