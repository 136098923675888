<ng-container
    *ngIf="{
        showInfo: appState.galleryState.showInfo | async,
        attachmentsList: appState.galleryState.attachmentsList | async,
        currentIndex: appState.galleryState.index | async,
        attachment: attachment$ | async,
        sourceDetail: appState.galleryState.sourceDetail | async,
        community: community$ | async,
        isSearchBarActive: appState.galleryState.isSearchBarActive | async,
        canShowSearchBarForThatAttachment:
            appState.galleryState.canShowSearchBarForThatAttachment | async,
        canShowSearchBar: canShowSearchBar$ | async,
        goToAudioVideoFrame: goToAudioVideoFrame$ | async,
        canEdit: appState.galleryState.canEdit | async,
        isEditModeActive: appState.galleryState.isEditModeActive | async,
        containsText: (appState.galleryState.containsText | async) ?? undefined,
        autoplay: appState.galleryState.autoplay | async,
        hideNavigation: appState.galleryState.hideNavigation | async
    } as $"
>
    <div
        *ngIf="$.attachmentsList && $.currentIndex != null"
        cdkTrapFocus
        cdkTrapFocusAutoCapture
        class="bg-transparent-black-not-black-95 text-text dark fixed inset-0 flex"
        @light-box
    >
        <div
            class="transition-margin relative flex h-full w-full grow flex-col items-center justify-center overflow-hidden duration-300"
            [ngClass]="{
                'mr-0': !$.showInfo || !$.isSearchBarActive,
                'mr-360': $.showInfo || $.isSearchBarActive
            }"
            (click)="close()"
        >
            <!--[IISP-8207] Alla seconda apertura dell'editor il click sul bottone 'matita' non scatena la stopPropagation,
            è stato quindi aggiunto lo stopPropagation singolarmente solo a quel bottone  -->
            <interacta-light-box-header
                *ngIf="!$.isEditModeActive"
                class="w-full"
                [attachment]="$.attachment ?? null"
                [canEdit]="$.canEdit || false"
                [canShowSearchBar]="
                    ($.canShowSearchBarForThatAttachment &&
                        $.canShowSearchBar) ||
                    false
                "
                [customButtons]="
                    CUSTOM_ACTIONS_FEATURES.includes($.sourceDetail.feature)
                        ? $.community?.metadata?.attachmentCustomActions ?? []
                        : []
                "
                [isInfoActive]="$.showInfo || false"
                [isSearchBarActive]="$.isSearchBarActive || false"
                (click)="$event.stopPropagation()"
                (closeDialog)="close()"
                (download)="download($event)"
                (editImage)="editAttachment($.attachment)"
                (favorite)="favorite()"
                (info)="info()"
                (openInDrive)="openInDrive($event)"
                (openSearchBar)="openSearchBar()"
            ></interacta-light-box-header>

            <div
                #attachmentContainer
                class="min-h-0 w-full grow"
                id="attachment"
                [ngClass]="{ 'px-100': !$.isEditModeActive }"
                (click)="$event.stopPropagation()"
            >
                <interacta-light-box-attachment
                    *ngIf="!$.isEditModeActive && $.attachment"
                    class="block h-full w-full"
                    [attachment]="$.attachment"
                    [autoplay]="$.autoplay"
                    [goToAudioVideoFrame]="$.goToAudioVideoFrame"
                    [isVideoMuted]="isVideoMuted"
                    [ngClass]="{
                        'cursor-move':
                            panzoom?.getTransform()?.scale !==
                                panzoom?.getMinZoom() &&
                            zoom !== (baseScale | round)
                    }"
                    (backdrop)="close()"
                    (cantPlay)="showCantPlayErrorMessage($event)"
                    (changeScale)="onChangeScale($event)"
                    (click)="$event.stopPropagation()"
                    (imgLoad)="
                        $.attachment &&
                            initPanzoom(
                                $.attachment,
                                $event.element,
                                $event.initialScale
                            )
                    "
                    (muteVideo)="changeMuteVideo($event)"
                ></interacta-light-box-attachment>

                <ng-template #imageEditor></ng-template>

                <button
                    *ngIf="isFullscreen"
                    class="fixed bottom-16 right-20"
                    interacta-button-light-box
                    type="button"
                    [attr.aria-label]="'LIGHTBOX.ZOOM_OUT' | translate"
                    [icon]="'fullscreen-exit'"
                    [label]="'LIGHTBOX.ZOOM_OUT' | translate"
                    [labelPosition]="'above'"
                    (click)="$event.stopPropagation(); closeFullscreen()"
                ></button>
            </div>

            <div
                *ngIf="!$.isEditModeActive && !$.hideNavigation"
                (click)="$event.stopPropagation()"
            >
                <button
                    class="absolute left-16 top-1/2 -translate-y-1/2"
                    interacta-button-scroll-arrow
                    type="button"
                    [attr.aria-label]="'LIGHTBOX.PREVIOUS' | translate"
                    [disabled]="$.currentIndex <= 0"
                    [icon]="'chevron-big-left'"
                    (click)="previous()"
                ></button>

                <button
                    class="absolute right-16 top-1/2 -translate-y-1/2"
                    interacta-button-scroll-arrow
                    type="button"
                    [attr.aria-label]="'LIGHTBOX.NEXT' | translate"
                    [disabled]="
                        $.currentIndex + 1 >= $.attachmentsList.totalCount
                    "
                    [icon]="'chevron-big-right'"
                    (click)="next()"
                ></button>
            </div>

            <interacta-light-box-previews
                *ngIf="
                    !$.isEditModeActive &&
                    !$.hideNavigation &&
                    $.attachmentsList.attachmentCategoryType !==
                        AttachmentCategoryType.DOCUMENT
                "
                class="mt-28"
                [attachmentsList]="$.attachmentsList"
                [currentIndex]="$.currentIndex"
                (changeAttachment)="changeAttachment($event.index)"
                (click)="$event.stopPropagation()"
                (loadAttachmentsPage)="loadAttachmentsPage($event.untilIdx)"
            ></interacta-light-box-previews>

            <interacta-light-box-footer
                *ngIf="!$.isEditModeActive"
                [attachmentsCount]="$.attachmentsList.totalCount"
                [currentAttachmentIdx]="$.currentIndex + 1"
                [isAttachmentMediaList]="
                    $.attachmentsList.attachmentCategoryType !==
                    AttachmentCategoryType.DOCUMENT
                "
                [maxZoom]="maxZoom"
                [minZoom]="minZoom"
                [showMiniSlider]="!$.hideNavigation"
                [zoom]="zoom"
                (nextAttachment)="next()"
                (previousAttachment)="previous()"
                (zoomIn)="onZoom('in')"
                (zoomOut)="onZoom('out')"
            ></interacta-light-box-footer>
        </div>

        <interacta-light-box-info
            class="fixed right-0 h-full shrink-0 transform transition-transform duration-300"
            [attachment]="$.attachment"
            [community]="$.community"
            [ngClass]="{
                'translate-x-full': !$.showInfo,
                'translate-x-0': $.showInfo
            }"
            (hashtagClicked)="filterByHahstag($event)"
            (showVersions)="showVersions($.attachment, $.sourceDetail)"
        ></interacta-light-box-info>

        <interacta-light-box-search
            *ngIf="$.canShowSearchBarForThatAttachment && $.canShowSearchBar"
            class="fixed right-0 h-full shrink-0 transform transition-transform duration-300"
            [active]="$.isSearchBarActive"
            [attachment]="$.attachment"
            [containsText]="$.containsText"
            [ngClass]="{
                'translate-x-full': !$.isSearchBarActive,
                'translate-x-0': $.isSearchBarActive
            }"
            (goToAudioVideoFrame)="goToAudioVideoFrame$.next($event)"
        ></interacta-light-box-search>

        <img
            alt="Interacta Logo"
            class="fixed bottom-20 left-10 w-44"
            [src]="logo"
        />

        <button
            *ngIf="!$.isEditModeActive"
            class="fixed bottom-16 right-20"
            interacta-button-light-box
            type="button"
            [attr.aria-label]="'LIGHTBOX.FULLSCREEN' | translate"
            [icon]="'fullscreen'"
            [label]="'LIGHTBOX.FULLSCREEN' | translate"
            [labelPosition]="'above'"
            (click)="$event.stopPropagation(); fullscreen()"
        ></button>
    </div>
</ng-container>
