import { CustomFields } from '@modules/communities/models/custom-metadata/CustomFields.class';
import { PostType } from '@modules/post/models/base-post.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PostFormState, postFormFeatureKey } from './post-form.reducer';

const selectPostFeature = createFeatureSelector<{
    [postFormFeatureKey]: PostFormState;
}>('post');

export const selectPostFormFeature = createSelector(
    selectPostFeature,
    (state) => state[postFormFeatureKey],
);

export const selectMode = createSelector(
    selectPostFormFeature,
    (state) => state.mode,
);

export const selectCommunity = createSelector(
    selectPostFormFeature,
    (state) => state.community,
);

export const selectVisibility = createSelector(
    selectPostFormFeature,
    (state) => state.visibility,
);

export const selectAnnouncement = createSelector(
    selectPostFormFeature,
    (state) => state.announcement,
);

export const selectFromSource = createSelector(
    selectPostFormFeature,
    (state) => state.fromSource,
);

export const selectOriginalPostId = createSelector(
    selectPostFormFeature,
    (state) => state.originalPostId,
);

export const selectAvailablePostTypes = createSelector(
    selectPostFormFeature,
    (state) => state.availablePostTypes,
);
export const selectPost = createSelector(
    selectPostFormFeature,
    (state) => state.post,
);

export const selectSurveyQuestions = createSelector(
    selectPostFormFeature,
    (state) => state.surveyQuestions,
);

const selectCustomFields = createSelector(selectPost, (post) =>
    post?.tag === PostType.CUSTOM ? post.customFields : null,
);

export const selectPostFormFilePickerAttachments = (fieldId: number) =>
    createSelector(
        selectCustomFields,
        (customFields: CustomFields | null) =>
            customFields?.data.find((c) => c.configuration.id === fieldId)
                ?.viewValue,
    );

export const selectTables = createSelector(
    selectPostFormFeature,
    (state) => state.tables,
);

export const selectSurveyLanguage = createSelector(
    selectPostFormFeature,
    (state) => state.surveyLanguage,
);

export const selectPostAttachmentsList = createSelector(
    selectPostFormFeature,
    (state) => state.attachmentsList,
);
