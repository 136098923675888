<interacta-dialog [isOpen]="isOpen" (closing)="close()">
    <div class="typo-t6" title>{{ 'QUILL.TABLE.ADD' | translate }}</div>
    <div class="typo-sm flex items-center" subtitle>
        {{ 'QUILL.TABLE.SETTINGS.SUBTITLE' | translate }}
    </div>
    <form class="mb-8 mt-24 flex flex-col gap-y-24" content [formGroup]="form">
        <interacta-form-field-v2
            [control]="form.controls.title"
            [name]="'QUILL.TABLE.SETTINGS.TITLE' | translate"
        >
            <interacta-input-text-v2
                [control]="form.controls.title"
                (keyup.enter)="applySettings()"
            ></interacta-input-text-v2>
        </interacta-form-field-v2>
        <interacta-toggle
            class="typo-sm-bold"
            labelPosition="before"
            [checked]="headerRow"
            [label]="'UI.EDITABLE_TABLE.HEADER_ROW' | translate"
            (toggle)="headerRow = $event"
        >
        </interacta-toggle>
        <interacta-form-field-v2
            [control]="form.controls.rows"
            [name]="'QUILL.TABLE.SETTINGS.ROWS' | translate"
        >
            <interacta-input-number-spinner
                class="w-140 block"
                [control]="form.controls.rows"
                [iconSize]="'extra-small'"
                [minValue]="1"
            >
            </interacta-input-number-spinner>
        </interacta-form-field-v2>
        <interacta-form-field-v2
            [control]="form.controls.columns"
            [name]="'QUILL.TABLE.SETTINGS.COLUMNS' | translate"
        >
            <interacta-input-number-spinner
                class="w-140 block"
                [control]="form.controls.columns"
                [iconSize]="'extra-small'"
                [minValue]="1"
            >
            </interacta-input-number-spinner>
        </interacta-form-field-v2>
    </form>

    <interacta-dialog-buttons>
        <button
            appearance="ghost"
            interacta-dialog-button
            type="button"
            [bgColor]="'surface-A'"
            [label]="'BUTTON.LABEL_BUTTON_CANCEL' | translate"
            (click)="close()"
        ></button>
        <button
            appearance="fill"
            interacta-dialog-button
            type="button"
            [bgColor]="'primary'"
            [label]="'QUILL.TABLE.ADD' | translate"
            (click)="applySettings()"
        ></button>
    </interacta-dialog-buttons>
</interacta-dialog>
