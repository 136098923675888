import {
    fromDateTimeToLocalString,
    fromZonedDatetime,
} from '@interacta-shared/data-access-configuration';
import { fromServerCustomFields } from '@modules/communities/models/custom-metadata/custom-metadata.serialize';
import { Delta2Server } from '@modules/core/helpers/delta/delta-2-server.class';
import {
    isMemberGroup,
    isMemberUser,
} from '@modules/core/models/member/member.utils';
import { IUsersGroup } from '@modules/core/models/user-group.model';
import { IUser } from '@modules/core/models/user.model';
import { AcknowledgeData } from '@modules/post/models/acknowledge-data.model';
import { fromAttachmentAddingBasicInfo } from '@modules/post/models/attachment/attachment.serialize';
import { RecipientGroupCardinality } from '@modules/post/models/base-post.model';
import {
    IAcknowledgeTaskConfirmData,
    IAcknowledgeTaskRequest,
    IReminder,
    ISubTask,
    ITaskCreate,
    ITaskEdit,
    StandardTask,
    SurveyTaskConfirmData,
} from './task.model';

export const fromTaskDetails = (task: StandardTask): any => ({
    title: task.title && task.title.trim().length > 0 ? task.title : null,
    descriptionDelta: new Delta2Server().process(task.descriptionDelta),
    priority: task.priority || null,
    expiration: task.expiration ? fromZonedDatetime(task.expiration) : null,
    assigneeUserId: task.assigneeUser ? task.assigneeUser.id : null,
    assigneeGroupId: task.assigneeGroup ? task.assigneeGroup.id : null,
    subTasks: (task.subTasks || [])
        .filter((st) => st.description && st.description.trim().length > 0)
        .map(fromSubTask),
});

export const fromSubTask = (subTask: ISubTask): any => ({
    id: subTask.id,
    description: subTask.description,
    state: subTask.state,
});

export const fromReminder = (reminder: IReminder): any => ({
    id: reminder.id,
    value: reminder.value,
    range: reminder.range,
    types: reminder.types,
});

export const fromTaskCreate = (task: ITaskCreate): any => {
    const record: any = fromTaskDetails(task);
    record.clientUid = task.clientUid;
    record.attachments = (task.attachmentsList?.attachments || []).map((a) =>
        fromAttachmentAddingBasicInfo(a),
    );
    record.watcherUserIds = (task.watcherUsers || []).map(
        (user: IUser) => user.id,
    );
    record.watcherGroupIds = (task.watcherGroups || []).map(
        (usersGroup: IUsersGroup) => usersGroup.id,
    );
    return record;
};

export const fromTaskEdit = (task: ITaskEdit): any => {
    const record = fromTaskDetails(task);

    // Unrecognized field "updateAttachments"
    // Object.assign(record, AttachmentToServerUtility.attachmentsListEdit(<IAttachmentListEdit>task, false));

    record.addAttachments = (task.addAttachments || []).map((a) =>
        fromAttachmentAddingBasicInfo(a),
    );
    record.removeAttachmentIds = task.removeAttachmentIds;

    record.addWatcherUserIds = task.addWatcherUserIds;
    record.removeWatcherUserIds = task.removeWatcherUserIds;
    record.addWatcherGroupIds = task.addWatcherGroupIds;
    record.removeWatcherGroupIds = task.removeWatcherGroupIds;
    return record;
};

export const fromConfirmAcknowledgeTask = (
    data: IAcknowledgeTaskConfirmData,
): any => {
    const record = {
        tasks: (data.tasks || []).map((t) => ({
            id: t.taskId,
            occToken: t.occToken,
        })),
        screenData: data.screenData?.customFields
            ? fromServerCustomFields(data.screenData.customFields.data)
            : null,
    };
    return record;
};

export const fromConfirmSurveyTask = (data: SurveyTaskConfirmData): any => {
    const record = {
        tasks: (data.tasks || []).map((t) => ({
            id: t.taskId,
            occToken: t.occToken,
        })),
        customData: data.customData?.customFields
            ? fromServerCustomFields(data.customData.customFields.data)
            : null,
    };
    return record;
};

export const fromAcknowledgeTaskRequest = (
    ackTask: IAcknowledgeTaskRequest,
): any => {
    const record = {
        recipientGroupCardinality: ackTask.recipientGroupCardinality,
        allCommunity: ackTask.allCommunity,
        reminders: ackTask.reminders,
        recipientGroupIds: (ackTask.recipientMembers || [])
            .filter(isMemberGroup)
            .map((g) => g.innerId),
        recipientUserIds: (ackTask.recipientMembers || [])
            .filter(isMemberUser)
            .map((u) => u.innerId),
        descriptionDelta: ackTask.descriptionDelta
            ? new Delta2Server().process(ackTask.descriptionDelta)
            : undefined,
        expiration: ackTask.expiration
            ? fromZonedDatetime(ackTask.expiration)
            : undefined,
    };
    return record;
};

export const fromAcknowledgeData = (
    acknowledgeData?: AcknowledgeData | null,
): any => {
    if (acknowledgeData && acknowledgeData != null) {
        return {
            descriptionDelta: new Delta2Server().process(
                acknowledgeData.optionalMessage,
            ),
            expiration: {
                datetime: acknowledgeData.expirationDate
                    ? fromDateTimeToLocalString(acknowledgeData.expirationDate)
                    : null,
                timezone: acknowledgeData.timezone?.zoneId,
            },
            recipientUserIds: acknowledgeData.members
                .filter(isMemberUser)
                .map((user) => user.innerId),
            recipientGroupIds: acknowledgeData.members
                .filter(isMemberGroup)
                .map((group) => group.innerId),
            allCommunity: acknowledgeData.requestToAllOrSome === 'all',
            recipientGroupCardinality:
                acknowledgeData.whoSendsAcknowledge === 'all'
                    ? RecipientGroupCardinality.ALL
                    : RecipientGroupCardinality.AT_LEAST_ONE,
            reminders: acknowledgeData.reminders?.map(fromReminder),
        };
    } else {
        return null;
    }
};
