<button
    class="text-text-primary flex w-max items-center space-x-4 p-4"
    type="button"
    (click)="loadMore.emit()"
>
    <ng-container *ngIf="!isLoading">
        <interacta-icon
            *ngIf="arrow !== 'none'"
            class="size-16"
            [icon]="arrow === 'up' ? 'chevron-small-up' : 'chevron-small-down'"
        ></interacta-icon>

        <span class="typo-xs-bold">{{ label }}</span>
    </ng-container>

    <interacta-loader *ngIf="isLoading" [size]="size"></interacta-loader>
</button>
