import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Size } from '../../model';
import { ResizeElementService } from '../../services';

export interface Tab<T> {
    id: T;
    label: string;
    selected: boolean;
    counter: number | null;
    imageSrc?: string;
    icon?: InteractaIcon;
    badge?: boolean;
}

@Component({
    selector: 'interacta-tab',
    templateUrl: './tab.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgFor,
        MatTooltipModule,
        IconComponent,
        NgStyle,
        AsyncPipe,
    ],
})
export class TabComponent<T> implements OnChanges, AfterViewInit, OnDestroy {
    @Input({ required: true }) tabs!: Tab<T>[];
    @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
    @Input() size: Extract<Size, 'regular' | 'small'> | 'auto' = 'regular';
    @Input() variant: 'regular' | 'images' | 'icons' = 'regular';
    @Output() changeTab = new EventEmitter<T>();

    @ViewChildren('tabs') tabsElements!: QueryList<ElementRef<HTMLElement>>;

    selectedIndex = 0;
    selectorWidth$ = new Subject<number | undefined>();

    private destroy$ = new Subject<void>();
    private autoSizeOff$ = new Subject<void>();

    constructor(
        private element: ElementRef<HTMLElement>,
        private resizeElementService: ResizeElementService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['size']) {
            if (
                changes['size'].currentValue === 'auto' &&
                changes['size'].previousValue !== 'auto'
            ) {
                this.resizeElementService
                    .observeElement(this.element.nativeElement)
                    .pipe(
                        takeUntil(this.autoSizeOff$),
                        takeUntil(this.destroy$),
                    )
                    .subscribe((_) => this.calculateWidth());
            } else if (
                changes['size'].currentValue !== 'auto' &&
                changes['size'].previousValue === 'auto'
            ) {
                this.autoSizeOff$.next();
            }
        }

        if (changes['tabs']?.currentValue) {
            this.selectedIndex = this.tabs.findIndex((t) => t.selected);
            if (this.size === 'auto') {
                setTimeout(() => this.calculateWidth());
            }
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.calculateWidth());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    private calculateWidth(): void {
        const firstTab = this.tabsElements?.get(this.selectedIndex);
        this.selectorWidth$.next(firstTab?.nativeElement.clientWidth);
    }
}
