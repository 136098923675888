@if (size !== 'extra-small') {
    <div class="flex w-fit">
        <div
            class="print-adjust-exact inline-block"
            interactaResize
            [class]="
                {
                    color,
                    colorCode,
                    colorClass
                } | workflowBadgeColor
            "
            [matTooltip]="workflowStateName || ''"
            [matTooltipDisabled]="!tooltipVisible"
            [ngClass]="{
                'typo-xs-bold': size !== 'extra-large',
                'typo-t6 px-20 py-12': size === 'extra-large',
                'px-12 py-4': size !== 'extra-large' && size !== 'small',
                'px-8': size === 'small',
                'text-center': size === 'large',
                'border-border-primary border-4': appearance === 'border',
                'w-full': fullwidth,
                'rounded-bl rounded-br-2xl rounded-tl-2xl rounded-tr':
                    rounded === 'leaf' && size !== 'extra-large',
                'rounded-tl-4xl rounded-br-4xl rounded-bl-lg rounded-tr-lg':
                    rounded === 'leaf' && size === 'extra-large',
                'rounded-3xl': rounded === 'pill'
            }"
            [resizeAxis]="'width'"
            [style.background-color]="!colorCode ? color : undefined"
            (changeSize)="checkSize()"
        >
            <span
                #container
                class="line-clamp-1 break-all"
                [ngClass]="{
                    'text-text-on-status': labelColor === 'text-on-status',
                    'text-text-mid-contrast': labelColor === 'gray-700',
                    'text-text-white': labelColor === 'pure-white'
                }"
                >{{ workflowStateName }}
            </span>
        </div>
    </div>
} @else {
    <div
        [class]="
            {
                color,
                colorCode,
                colorClass
            } | workflowBadgeColor
        "
        [matTooltip]="workflowStateName || ''"
        [ngClass]="{
            'rounded-tl-4xl rounded-br-4xl flex w-fit items-center rounded-bl-lg rounded-tr-lg px-8':
                rounded === 'leaf',
            'size-16 rounded-full': rounded === 'pill'
        }"
        [style.background-color]="!colorCode ? color : undefined"
    >
        <span
            *ngIf="rounded === 'leaf'"
            class="typo-xs-bold"
            [ngClass]="{
                'text-text-on-status': labelColor === 'text-on-status',
                'text-text-mid-contrast': labelColor === 'gray-700',
                'text-text-white': labelColor === 'pure-white'
            }"
        >
            {{ workflowStateName }}</span
        >
    </div>
}
