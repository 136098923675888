import { IList } from '@interacta-shared/util';
import { IUser, UserDeserilize } from '@modules/core/models/user.model';
import { toCustomFieldDefinitions } from '../custom-metadata/custom-metadata.deserialize';
import { MetadataFieldModeType } from '../custom-metadata/custom-metadata.model';
import {
    toWorkflowOperationMetadata,
    toWorkflowStatus,
} from './workflow-base.deserialize';
import {
    IWorkflowChangeScreenData,
    IWorkflowChangeStatus,
    IWorkflowDefinition,
    IWorkflowOperation,
    IWorkflowScreen,
} from './workflow.model';

export const toWorkflowDefinition = (record: any): IWorkflowDefinition => {
    const screenFieldMetadatas = record.screenFieldDefinitions
        ? toCustomFieldDefinitions(
              record.screenFieldDefinitions,
              MetadataFieldModeType.SCREEN,
          )
        : [];
    const deletedScreenFieldMetadatas = record.deletedScreenFieldDefinitions
        ? toCustomFieldDefinitions(
              record.deletedScreenFieldDefinitions,
              MetadataFieldModeType.SCREEN,
              true,
          )
        : [];

    return {
        id: record.id,
        empty: record.empty,
        title: record.title,
        transitions: record.transitions
            ? record.transitions.map(toWorkflowOperation)
            : [],
        states: record.states ? record.states.map(toWorkflowStatus) : [],
        screenFieldMetadatas,
        allScreenFieldMetadatas: [
            ...screenFieldMetadatas,
            ...deletedScreenFieldMetadatas,
        ],
        creatorUser: record.creatorUser
            ? UserDeserilize.userDetails(record.creatorUser)
            : undefined,
        creationTimestamp: record.creationTimestamp
            ? new Date(record.creationTimestamp)
            : undefined,
        lastModifyUser: record.lastModifyUser
            ? UserDeserilize.userDetails(record.lastModifyUser)
            : undefined,
        lastModifyTimestamp: record.lastModifyTimestamp
            ? new Date(record.lastModifyTimestamp)
            : undefined,
        publicationUser: record.publicationUser
            ? UserDeserilize.userDetails(record.publicationUser)
            : undefined,
        publicationTimestamp: record.publicationTimestamp
            ? new Date(record.publicationTimestamp)
            : undefined,
        draft: record.draft ?? false,
        template: record.template ?? false,
        metadata: {
            workflowDesigner: record.metadata?.workflowDesigner ?? false,
        },
    };
};

export const toWorkflowOperation = (
    record: any,
    workflowDefinition?: IWorkflowDefinition,
): IWorkflowOperation => ({
    id: record.id,
    fromState: toWorkflowStatus(record.fromState),
    toState: toWorkflowStatus(record.toState),
    name: record.name,
    metadata: record.metadata
        ? toWorkflowOperationMetadata(record.metadata)
        : null,
    screen: record.screen
        ? toWorkflowScreen(record.screen)
        : workflowDefinition?.transitions?.find((t) => t.id === record.id)
              ?.screen,
});

export const toWorkflowScreen = (record: any): IWorkflowScreen => ({
    id: record.id,
    name: record.name,
    message: record.message,
    fieldMetadatas: record.fieldMetadatas
        ? toCustomFieldDefinitions(
              record.fieldMetadatas,
              MetadataFieldModeType.SCREEN,
          )
        : [],
});

export const toWorkflowScreenEdit = (
    record: any,
): IWorkflowChangeScreenData => ({
    newScreenData: record.newScreenData,
    postDataHasChanged: record.postDataHasChanged,
    nextScreenOccToken: record.nextScreenOccToken,
    newCurrentWorkflowPermittedOperations: (
        <Array<any>>record.newCurrentWorkflowPermittedOperations || []
    ).map((op: any) => toWorkflowOperation(op)),
});

export const toWorkflowChangeStatus = (
    record: any,
    currentUser: IUser,
): IWorkflowChangeStatus => ({
    newCurrentState: toWorkflowStatus(record.newCurrentState),
    newCurrentWorkflowPermittedOperations: (
        <Array<any>>record.newCurrentWorkflowPermittedOperations || []
    ).map((op: any) => toWorkflowOperation(op)),
    newCanEditWorkflowScreenData: record.newCanEditWorkflowScreenData,
    newScreenData: record.newScreenData,
    postDataHasChanged: record.postDataHasChanged,
    newLastModifyTimestamp: new Date(record.newLastModifyTimestamp as number),
    newLastModifyUser: currentUser,
});

export const toWorkflowRegistryList = (
    record: any,
): IList<IWorkflowDefinition> => ({
    list: record.items.map(toWorkflowDefinition),
    nextPageToken: record.nextPageToken,
    totalCount: record.totalItemsCount ?? 0,
});
