<button
    *ngIf="memberClickBehavior !== 'none'; else userTemplate"
    class="block"
    type="button"
    (click)="openProfile(user)"
>
    <ng-container *ngTemplateOutlet="userTemplate"></ng-container>
</button>

<ng-template #userTemplate>
    <interacta-user
        [additionalInfo]="additionalInfo"
        [color]="color"
        [fontWeight]="fontWeight"
        [rtl]="rtl"
        [showInfo]="showInfo"
        [size]="size"
        [user]="user"
    ></interacta-user>
</ng-template>
