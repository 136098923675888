import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { paginatedListFromArray } from '@interacta-shared/util';
import { getLabelServerTranslationV2 } from '@modules/core/helpers/i18n.utils';
import {
    isMemberGroup,
    isMemberUser,
} from '@modules/core/models/member/member.utils';
import {
    AdminV2MemberRoleAssociation,
    AdminV2MemberRolesAssociations,
    AdminV2RoleMemberAssociation,
    AdminV2RoleMembersAssociations,
    AdminV2RolesMembersAssociation,
    AdminV2RolesMembersAssociationsUpdate,
} from '../models/admin-v2-roles-associations.model';
import {
    AdminV2CapabilityCol,
    AdminV2Role,
    AdminV2RolesTable,
} from '../models/admin-v2-roles.model';

export const adminCapabilities: AdminV2CapabilityCol[] = [
    { code: 'createWorkspace' },
    { code: 'editWorkspace' },
    { code: 'createCommunity' },
    { code: 'editCommunity' },
    { code: 'manageWorkflow' },
    { code: 'manageUsers' },
    { code: 'manageCatalogs' },
    { code: 'manageDigitalWorkplace' },
    { code: 'manageRoles' },
];

export const operationalCapabilities: AdminV2CapabilityCol[] = [
    // POST
    { code: 'viewPost', category: 'post' },
    { code: 'createPost', category: 'post' },
    { code: 'manageEventPost', category: 'post' },
    { code: 'createFeedbackPost', category: 'post' },
    { code: 'createSurveyPost', category: 'post' },
    { code: 'editPost', category: 'post' },
    { code: 'editPostAttachments', category: 'post' },
    { code: 'deletePost', category: 'post' },
    // POST (ALTRO)
    { code: 'setPostAnnouncementFlag', category: 'post_other' },
    { code: 'editPostVisibility', category: 'post_other' },
    { code: 'pinPost', category: 'post_other' },
    { code: 'exportPosts', category: 'post_other' },
    { code: 'createAcknowledgeTask', category: 'post_other' },
    { code: 'monitorAcknowledgeTask', category: 'post_other' },
    // COMMENTI
    { code: 'viewComment', category: 'comment' },
    { code: 'createComment', category: 'comment' },
    { code: 'editComment', category: 'comment' },
    { code: 'deleteComment', category: 'comment' },
    // TASK
    { code: 'createTask', category: 'task' },
    // STATISTICHE
    { code: 'accessBaseDashboard', category: 'dashboard' },
    { code: 'accessWorkflowDashboard', category: 'dashboard' },
    // AVANZATE
    { code: 'createMention', category: 'advanced' },
    { code: 'viewTask', category: 'advanced' },
    { code: 'viewPostRelations', category: 'advanced' },
    { code: 'viewWorkflowHistory', category: 'advanced' },
    { code: 'viewPostVisibility', category: 'advanced' },
    { code: 'viewPostVisualizations', category: 'advanced' },
];

export const orderRolesListByCols = (
    list: AdminV2RolesTable,
    orderConfig: number[],
): void => {
    list.cols.sort(
        (a, b) => orderConfig.indexOf(a.id) - orderConfig.indexOf(b.id),
    );
    for (const row of list.rows) {
        row.roles.sort(
            (a, b) => orderConfig.indexOf(a.id) - orderConfig.indexOf(b.id),
        );
    }
};

// ROLES ASSOCIATIONS
export const addRolesAssociation = (
    associations: AdminV2RolesMembersAssociation,
    memberRolesAssociations: AdminV2MemberRolesAssociations[],
): AdminV2MemberRolesAssociations[] => {
    let associationsAdded = false;
    for (const member of associations.members) {
        const existingMemberAssociation = memberRolesAssociations.find(
            (m) => m.member.id === member.id,
        );
        if (existingMemberAssociation) {
            for (const role of associations.roles) {
                const roleAlreadyAssociated =
                    existingMemberAssociation.roles.some(
                        (r) => r.role.id === role.id,
                    );
                if (!roleAlreadyAssociated) {
                    existingMemberAssociation.roles.push({
                        role,
                        associationId: null,
                    });
                    associationsAdded = true;
                }
            }
        } else {
            memberRolesAssociations.push({
                member,
                roles: associations.roles.map((r) => ({
                    role: r,
                    associationId: null,
                })),
                associationIds: [],
            });
            associationsAdded = true;
        }
    }
    return associationsAdded ? memberRolesAssociations : [];
};

export const addMembersAssociation = (
    associations: AdminV2RolesMembersAssociation,
    roleMembersAssociations: AdminV2RoleMembersAssociations[],
): AdminV2RoleMembersAssociations[] => {
    let associationsAdded = false;
    for (const role of associations.roles) {
        const existingRoleAssociation = roleMembersAssociations.find(
            (r) => r.role.id === role.id,
        );

        if (existingRoleAssociation) {
            for (const member of associations.members) {
                const memberAlreadyAssociated =
                    existingRoleAssociation.members.list.some(
                        (m) => m.member.id === member.id,
                    );

                if (!memberAlreadyAssociated) {
                    existingRoleAssociation.members.list.push({
                        member,
                        associationId: null,
                    });
                    associationsAdded = true;
                }
            }
        } else {
            roleMembersAssociations.push({
                role,
                members: paginatedListFromArray(
                    associations.members.map((m) => ({
                        member: m,
                        associationId: null,
                    })),
                ),
                associationIds: [],
            });
            associationsAdded = true;
        }
    }
    return associationsAdded ? roleMembersAssociations : [];
};

export const isAdminV2MemberRolesAssociations = (
    obj: any,
): obj is AdminV2MemberRolesAssociations =>
    'member' in obj && 'roles' in obj && 'associationIds' in obj;

export const isAdminV2RoleMembersAssociations = (
    obj: any,
): obj is AdminV2RoleMembersAssociations =>
    'role' in obj && 'members' in obj && 'associationIds' in obj;

/**
 * Rimuove il ruolo o il membro dalla lista associations
 * @param association il behaviorSubject da modificare in base al tipo di visualizzazione in cui ci troviamo
 * @param parentIdx indice della colonna di sinistra
 * @param childIdx indice della colonna di destra
 */
export const removeRolesMembersAssociation = <
    T extends AdminV2MemberRolesAssociations | AdminV2RoleMembersAssociations,
>(
    associations: T[],
    parentIdx: number,
    childIdx?: number,
): T[] => {
    if (childIdx !== undefined) {
        let list:
            | AdminV2RoleMemberAssociation[]
            | AdminV2MemberRoleAssociation[] = [];

        const obj = associations[parentIdx];
        if (isAdminV2MemberRolesAssociations(obj)) {
            list = obj.roles;
        } else if (isAdminV2RoleMembersAssociations(obj)) {
            list = obj.members.list;
        }

        if (list.length === 1) {
            associations.splice(parentIdx, 1);
        } else if (list.length > 0) {
            list.splice(childIdx, 1);
        }
    } else {
        // devo modificare la colonna di sinistra, metto mano al padre
        associations.splice(Number(parentIdx ?? childIdx), 1);
    }
    return associations;
};

export const getAssociationsUpdate = (
    associationsToAdd: AdminV2MemberRolesAssociations[],
    associationsToRemove: number[],
): AdminV2RolesMembersAssociationsUpdate => {
    const associationsUpdate: AdminV2RolesMembersAssociationsUpdate = {
        toAdd: {
            userRole: [],
            groupRole: [],
        },
        toRemove: associationsToRemove,
    };
    for (const association of associationsToAdd) {
        const member = association.member;
        for (const associationRole of association.roles) {
            if (associationRole.associationId === null) {
                if (isMemberUser(member)) {
                    associationsUpdate.toAdd.userRole.push({
                        userId: member.user.id,
                        roleId: associationRole.role.id,
                    });
                } else if (isMemberGroup(member)) {
                    associationsUpdate.toAdd.groupRole.push({
                        groupId: member.group.id,
                        roleId: associationRole.role.id,
                    });
                }
            }
        }
    }

    return associationsUpdate;
};

export const getAssociationsUpdateToAdd = (
    association: AdminV2RolesMembersAssociation,
): AdminV2RolesMembersAssociationsUpdate['toAdd'] => {
    const associationsUpdate: AdminV2RolesMembersAssociationsUpdate['toAdd'] = {
        userRole: [],
        groupRole: [],
    };
    for (const member of association.members) {
        if (isMemberUser(member)) {
            for (const role of association.roles) {
                associationsUpdate.userRole.push({
                    userId: member.user.id,
                    roleId: role.id,
                });
            }
        } else if (isMemberGroup(member)) {
            for (const role of association.roles) {
                associationsUpdate.groupRole.push({
                    groupId: member.group.id,
                    roleId: role.id,
                });
            }
        }
    }
    return associationsUpdate;
};

export const getRolesInfo = ({
    roleAssociations,
    configurationService,
}: {
    roleAssociations: AdminV2RoleMemberAssociation[];
    configurationService: ConfigurationService;
}): string => {
    const roleNames = roleAssociations.map((association) =>
        getLabelServerTranslationV2(
            association.role.name,
            configurationService,
            true,
        ),
    );
    return roleNames.join(', ');
};

export const roleAlphabeticalOrderSort = (
    r1: AdminV2Role,
    r2: AdminV2Role,
    configurationService: ConfigurationService,
    desc?: boolean,
): number => {
    const name1 = getLabelServerTranslationV2(
        r1.name,
        configurationService,
        true,
    );
    const name2 = getLabelServerTranslationV2(
        r2.name,
        configurationService,
        true,
    );

    if (name1 > name2) {
        return desc ? -1 : 1;
    } else if (name1 < name2) {
        return desc ? 1 : -1;
    } else {
        return 0;
    }
};
