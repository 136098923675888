import { getLocaleNumberSymbol } from '@angular/common';
import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    inject,
    Input,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { isValidNumber } from '@interacta-shared/util';

@Directive({
    selector: '[interactaDecimalNumberV2]',
    providers: [
        {
            provide: MAT_INPUT_VALUE_ACCESSOR,
            useExisting: DecimalNumberV2Directive,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DecimalNumberV2Directive),
            multi: true,
        },
    ],
    standalone: true,
})
/**
 * La direttiva permette di gestire l'inserimento di numeri decimali gestiti da un parametro in ingresso,
 * e di visualizzare la stringa "internazionalizzata" con i separatori corretti (migliaia e decimali).
 * [Ad es. in locale 'it' -> '1.234,56'; in locale 'en' -> '1,234.56']
 *
 * Si comporta da provider per i matInput standard di tipo 'text', permettendo così un utilizzo semplificato della direttiva.
 *
 * Riceve e ritorna un 'value' (formControl) di tipo number.
 * Manipola il dato in formato stringa
 *
 * Source Doc = https://link.medium.com/ETzclj8184
 *
 * {@link decimal_digits} = numero di decimali da visualizzare
 *
 * Angelo Rubino
 */
export class DecimalNumberV2Directive implements OnInit, ControlValueAccessor {
    @Input() decimalDigits = 2;
    @Input() decimalLocale = 'it';
    decimalSeparator = '.';
    groupSeparator = '';
    formatter!: Intl.NumberFormat;

    private readonly el = inject(ElementRef<HTMLInputElement>);

    private _value: number | null | undefined = null;
    private regex = '';

    ngOnInit(): void {
        this.decimalSeparator = getLocaleNumberSymbol(this.decimalLocale, 0);
        this.formatter = new Intl.NumberFormat(this.decimalLocale, {
            maximumFractionDigits: this.decimalDigits,
        });
        this.groupSeparator = this.getGroupSeparator();
        this.regex =
            this.decimalDigits === 0
                ? `^\\s*(-?\\d*)\\s*$`
                : `^\\s*(-?\\d*(\\${this.decimalSeparator}\\d{0,${this.decimalDigits}})?)\\s*$`;
    }

    private getGroupSeparator(): string {
        const testNumber = 1000;
        return this.getLocaleFormattedStringByValue(testNumber).substring(1, 2);
    }

    private getLocaleFormattedStringByValue(value: number): string {
        return this.formatter.format(value);
    }

    get value(): number | null | undefined {
        return this._value;
    }

    @Input()
    set value(value: number | null | undefined) {
        this._value = value;
        this.formatValue(value);
    }

    private formatValue(value: number | null | undefined) {
        if (value != null) {
            this.el.nativeElement.value =
                this.getLocaleFormattedStringByValue(value);
        } else {
            this.el.nativeElement.value = '';
        }
    }

    private unFormatValue() {
        const value = this.el.nativeElement.value;
        if (value) {
            this.el.nativeElement.value = value.replace(
                new RegExp(`\\${this.groupSeparator}`, 'g'),
                '',
            );
        } else {
            this.el.nativeElement.value = '';
        }
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string): void {
        if (!value) {
            this._value = null;
            this._onChange(this._value);
        } else if (this.check(value)) {
            const javascriptNumberStringValue = value.replace(
                this.decimalSeparator,
                '.',
            );
            const parsedValue = parseFloat(javascriptNumberStringValue);
            this._value = isValidNumber(parsedValue) ? parsedValue : null;
            this._onChange(this._value); // here to notify Angular Validators
        } else {
            this.formatValue(this._value);
            this.unFormatValue();
        }
    }

    @HostListener('blur')
    _onBlur(): void {
        this.formatValue(this._value); // add formatting pattern
    }

    @HostListener('focus')
    onFocus(): void {
        this.unFormatValue(); // remove group separator for editing purpose
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    _onChange(_value: any): void {}

    writeValue(value: number | null | undefined): void {
        this._value = value;
        this.formatValue(this._value); // format Value
    }

    registerOnChange(fn: (value: number | null | undefined) => void): void {
        this._onChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    registerOnTouched(): void {}

    private check(value: string): boolean {
        const regExp = new RegExp(this.regex);
        return regExp.test(value);
    }
}
