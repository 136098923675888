import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    ENVIRONMENT,
    Language,
} from '@interacta-shared/data-access-configuration';
import { InputSelectSearchEvent } from '@interacta-shared/ui';
import { PaginatedList, toPaginatedList } from '@interacta-shared/util';
import { AdminV2Community } from '@modules/admin-v2-community/models/admin-v2-community.model';
import {
    AdminV2MemberRolesAssociations,
    AdminV2RolesMembersAssociationsUpdate,
} from '@modules/admin-v2-roles/models/admin-v2-roles-associations.model';
import { toAdminV2MemberRolesAssociations } from '@modules/admin-v2-roles/models/admin-v2-roles.deserialize';
import { toAdminV2MemberItem } from '@modules/admin-v2-shared/models/admin-v2-member.deserialize';
import {
    AdminV2PaginatedFilters,
    DefaultAdminV2PaginatedFilters,
} from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { fromAdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.serialize';
import { AdminV2OccToken } from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import { toI18nDeltaV2 } from '@modules/admin-v2-shared/utils/admin-v2-i18n';
import { toIUserReverseFullName } from '@modules/admin-v2-user/models/admin-v2-user.deserialize';
import {
    AdminV2MemberSearchRequest,
    AdminV2WorkspaceCommunity,
    AdminV2WorkspacePreview,
} from '@modules/admin-v2-workspace/models/admin-v2-workspace.model';
import { toMemberWithCommunityFlagItem } from '@modules/core/models/member/member.deserialize';
import {
    Member,
    MemberGroup,
    MemberUser,
    MemberWithCustomFlags,
} from '@modules/core/models/member/member.model';
import {
    isMemberUser,
    wrapMember,
} from '@modules/core/models/member/member.utils';
import { Observable, map } from 'rxjs';
import {
    toAdminV2Workspace,
    toAdminV2WorkspaceCommunities,
    toAdminV2WorkspaceDetail,
    toAdminV2WorkspaceGroups,
    toAdminV2WorkspacePreview,
    toAdminV2WorkspaceRow,
} from '../models/admin-v2-workspace.deserialize';
import {
    AdminV2NewWorkspace,
    AdminV2Workspace,
    AdminV2WorkspaceDetail,
    AdminV2WorkspaceFilters,
    AdminV2WorkspaceListPage,
    AdminV2WorkspaceMembersCounter,
    AdminV2WorkspaceMembersUpdate,
    AdminV2WorkspaceSettingsUpdate,
    AdminV2WorkspaceSort,
} from '../models/admin-v2-workspace.model';
import {
    fromAdminV2MemberSearchRequest,
    fromAdminV2NewWorkspace,
    fromAdminV2WorkspaceFilters,
    fromAdminV2WorkspaceSettingsUpdate,
} from '../models/admin-v2-workspace.serialize';

@Injectable({ providedIn: 'root' })
export class AdminV2WorkspaceService {
    private readonly baseUrlData = `${inject(ENVIRONMENT).apiBasePath.common}/admin/data/workspaces`;
    private readonly baseUrlDataV2 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/admin/data/workspaces`;
    private readonly baseUrlDataV3 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v3/admin/data/workspaces`;
    private readonly baseUrlManageV3 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v3/admin/manage/workspaces`;

    private readonly http = inject(HttpClient);

    getWorkspacesList(
        filter: AdminV2WorkspaceFilters,
        paginatedFilters?: AdminV2PaginatedFilters,
        sort?: AdminV2WorkspaceSort,
    ): Observable<AdminV2WorkspaceListPage> {
        const workspaceFilters = fromAdminV2WorkspaceFilters(
            filter,
            paginatedFilters,
            sort,
        );

        return this.http
            .post<any>(`${this.baseUrlDataV3}`, workspaceFilters)
            .pipe(map((res) => toPaginatedList(res, toAdminV2WorkspaceRow)));
    }

    getWorkspacesCreateEditCommunitiesList(): Observable<AdminV2Workspace[]> {
        return this.http
            .get<any>(
                `${this.baseUrlDataV3}/admin-capabilities/create-edit-communities`,
            )
            .pipe(map((res) => res.workspaces.map(toAdminV2Workspace)));
    }

    getWorkspacesCreateCommunitiesList(): Observable<AdminV2Workspace[]> {
        return this.http
            .get<any>(
                `${this.baseUrlDataV3}/admin-capabilities/create-communities`,
            )
            .pipe(map((res) => res.workspaces.map(toAdminV2Workspace)));
    }

    createWorkspace(
        workspace: AdminV2NewWorkspace,
    ): Observable<AdminV2Workspace['id']> {
        return this.http
            .post<{
                id: AdminV2Workspace['id'];
            }>(`${this.baseUrlManageV3}`, fromAdminV2NewWorkspace(workspace))
            .pipe(map(({ id }) => id));
    }

    deleteById(workspaceId: AdminV2Workspace['id']): Observable<void> {
        return this.http.delete<void>(`${this.baseUrlManageV3}/${workspaceId}`);
    }

    getWorkspaceDetail(
        workspaceId: AdminV2Workspace['id'],
    ): Observable<AdminV2WorkspaceDetail> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${workspaceId}/detail`)
            .pipe(map((res) => toAdminV2WorkspaceDetail(res)));
    }

    getWorkspaceGroups(
        filters: { workspaceId: AdminV2Workspace['id']; search?: string },
        paginatedFilters?: AdminV2PaginatedFilters,
    ): Observable<PaginatedList<MemberGroup>> {
        return this.http
            .post<any>(
                `${this.baseUrlData}/${filters.workspaceId}/direct-members/groups`,
                {
                    name: filters.search || null,
                    calculateMembersCount: true,
                    ...fromAdminV2PaginatedFilters(
                        paginatedFilters || DefaultAdminV2PaginatedFilters,
                    ),
                },
            )
            .pipe(map((res) => toAdminV2WorkspaceGroups(res)));
    }

    getWorkspaceCommunities(
        workspaceId: AdminV2Workspace['id'],
    ): Observable<AdminV2WorkspaceCommunity[]> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${workspaceId}/communities`, {
                params: { loadCounters: true },
            })
            .pipe(map((res) => toAdminV2WorkspaceCommunities(res)));
    }

    editAdminAssociations(
        workspaceId: AdminV2Workspace['id'],
        update: AdminV2RolesMembersAssociationsUpdate,
    ): Observable<AdminV2MemberRolesAssociations[]> {
        return this.http
            .post<any>(`${this.baseUrlManageV3}/${workspaceId}/admins`, {
                addUserRoleAssociations: update.toAdd.userRole,
                addGroupRoleAssociations: update.toAdd.groupRole,
                removeRoleAssociationIds: update.toRemove,
            })
            .pipe(
                map((res) =>
                    toAdminV2MemberRolesAssociations(res.adminMembers),
                ),
            );
    }

    editWorkspaceSettings(
        workspaceId: AdminV2Workspace['id'],
        occToken: AdminV2OccToken,
        update: AdminV2WorkspaceSettingsUpdate,
    ): Observable<{
        occToken: AdminV2OccToken;
        description: AdminV2WorkspaceDetail['description'];
    }> {
        const payload = fromAdminV2WorkspaceSettingsUpdate(update);
        return this.http
            .put<{
                nextOccToken: AdminV2OccToken;
            }>(`${this.baseUrlManageV3}/${workspaceId}`, {
                ...payload,
                occToken,
            })
            .pipe(
                map(({ nextOccToken }) => ({
                    occToken: nextOccToken,
                    description: toI18nDeltaV2(payload.description),
                })),
            );
    }

    reorderWorkspaces(
        orderedWorkspaceIds: AdminV2Workspace['id'][],
    ): Observable<void> {
        return this.http.put<void>(`${this.baseUrlManageV3}/positions`, {
            orderedWorkspaceIds,
        });
    }

    reorderCommunity(
        workspaceId: AdminV2Workspace['id'],
        orderedCommunityIds: AdminV2Community['id'][],
    ): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrlManageV3}/${workspaceId}/communities/position`,
            {
                orderedCommunityIds,
            },
        );
    }

    getWorkspaceUsers(
        filters: { workspaceId: AdminV2Workspace['id']; search?: string },
        paginatedFilters?: AdminV2PaginatedFilters,
    ): Observable<PaginatedList<MemberUser>> {
        return this.http
            .post<any>(
                `${this.baseUrlDataV3}/${filters.workspaceId}/members/users`,
                {
                    ...fromAdminV2PaginatedFilters(
                        paginatedFilters ?? {
                            ...DefaultAdminV2PaginatedFilters,
                            pageSize: 25,
                        },
                    ),
                    fullTextFilter: filters.search,
                },
            )
            .pipe(
                map((res) =>
                    toPaginatedList({
                        ...res,
                        items: res.items
                            .map((x: any) =>
                                wrapMember(toIUserReverseFullName(x)),
                            )
                            .filter(isMemberUser),
                    }),
                ),
            );
    }

    getWorkspacePreview(
        workspaceId: AdminV2Workspace['id'],
    ): Observable<AdminV2WorkspacePreview> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${workspaceId}/overview`)
            .pipe(map((res) => toAdminV2WorkspacePreview(res)));
    }

    deleteTranslation(
        worksapceId: AdminV2Workspace['id'],
        language: Language,
    ): Observable<void> {
        return this.http.delete<any>(
            `${this.baseUrlManageV3}/${worksapceId}/i18ns/${language.code}`,
        );
    }

    // MEMBERS
    getAllAdminMembers(
        search: InputSelectSearchEvent,
        pageSize?: number,
    ): Observable<PaginatedList<Member>> {
        return this.http
            .post<any>(`${this.baseUrlDataV3}/admin-members`, {
                pageToken: search.nextPageToken,
                fullTextFilter: search.text,
                calculateTotalItemsCount: search.nextPageToken ? false : true,
                pageSize: pageSize ?? 15,
            })
            .pipe(map((res) => toPaginatedList(res, toAdminV2MemberItem)));
    }

    getWorkspaceAdminMembers(
        workspaceId: AdminV2Workspace['id'],
    ): Observable<AdminV2MemberRolesAssociations[]> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${workspaceId}/admin-members`)
            .pipe(
                map((res) =>
                    toAdminV2MemberRolesAssociations(res.adminMembers),
                ),
            );
    }

    getWorkspaceDirectMembers(
        workspaceId: AdminV2Workspace['id'],
        paginatedFilters?: AdminV2PaginatedFilters,
    ): Observable<PaginatedList<Member>> {
        return this.http
            .post<any>(`${this.baseUrlDataV2}/${workspaceId}/direct-members`, {
                ...fromAdminV2PaginatedFilters(
                    paginatedFilters ?? DefaultAdminV2PaginatedFilters,
                ),
            })
            .pipe(map((res) => toPaginatedList(res, toAdminV2MemberItem)));
    }

    getWorkspaceMembers(
        workspaceId: number,
        filters: AdminV2MemberSearchRequest,
        paginatedFilters: AdminV2PaginatedFilters,
    ): Observable<PaginatedList<MemberWithCustomFlags>> {
        return this.http
            .post(`${this.baseUrlData}/${workspaceId}/members`, {
                ...fromAdminV2MemberSearchRequest(filters),
                ...fromAdminV2PaginatedFilters(paginatedFilters),
            })
            .pipe(
                map((res) =>
                    toPaginatedList(res, toMemberWithCommunityFlagItem),
                ),
            );
    }

    editMembers(
        update: AdminV2WorkspaceMembersUpdate,
    ): Observable<AdminV2WorkspaceMembersCounter> {
        return this.http
            .post<any>(`${this.baseUrlManageV3}/members`, update)
            .pipe(map((res) => res.workspaceMemberCounts));
    }

    editAllFilteredMembers(
        filters: AdminV2WorkspaceFilters,
        update: AdminV2WorkspaceMembersUpdate,
    ): Observable<AdminV2WorkspaceMembersCounter> {
        return this.http
            .put<any>(`${this.baseUrlManageV3}/members/all`, {
                filters: fromAdminV2WorkspaceFilters(filters),
                ...update,
            })
            .pipe(map((res) => res.workspaceMemberCounts));
    }
}
