import { emptyPaginatedList, firstLoading } from '@interacta-shared/util';
import { PostsMap } from '@modules/communities/store/post/post.reducer';
import { getEmptyUserPlaceholder } from '@modules/core/models/member/member.utils';
import { toAttachmentsList } from '@modules/post/models/attachment/attachment.deserialize';
import { AttachmentCategoryType } from '@modules/post/models/attachment/attachment.model';
import { emptyAttachmentsList } from '@modules/post/models/attachment/attachment.utils';
import {
    ICustomPost,
    isCustomPost,
} from '@modules/post/models/custom-post.model';
import { IOrder } from '@modules/post/models/filter-post/filter-post.model';
import {
    IPostLinkAndRelation,
    PostLinkFilters,
} from './post-link-and-relation.model';

export const defaultPostLinkOrder = (): IOrder => ({
    orderBy: '',
    orderDesc: false,
});

export const defaultPostLinkFilters = (): PostLinkFilters => ({
    pageSize: 10,
    pageToken: firstLoading(),
    calculateTotalItemsCount: true,
    workflowStatus: undefined,
    order: defaultPostLinkOrder(),
});

export const postLinkAndRelationToCustomPost = (
    list: IPostLinkAndRelation[],
    allPosts: PostsMap,
): ICustomPost[] => {
    return list.map((p) => {
        const existingPost = allPosts[p.id];
        return existingPost != null && isCustomPost(existingPost)
            ? {
                  ...existingPost,
                  ...p,
              }
            : defaultCustomPostByPostLinkAndRelation(p);
    });
};

const defaultCustomPostByPostLinkAndRelation = (
    post: IPostLinkAndRelation,
): ICustomPost => {
    return {
        ...post,
        pinned: false,
        rightAlignText: false,
        viewersList: emptyPaginatedList(),
        likersList: emptyPaginatedList(),
        comments: emptyPaginatedList(),
        privateCommunityViewersList: emptyPaginatedList(),
        extendedVisibilityList: {
            ackTaskScreenFields: {},
            customVisibilityMembers: [],
            postFields: {},
            workflowScreenFields: {},
        },
        taskAssigneesList: emptyPaginatedList(),
        ackTaskAssigneeList: emptyPaginatedList(),
        invitationsList: emptyPaginatedList(),
        hashtags: [],
        activeHashtags: [],
        memberMentions: emptyPaginatedList(),
        viewedTimestamp: new Date(),
        creatorUser: getEmptyUserPlaceholder(''),
        commentDraft: { isEditing: false },
        isCreatingComment: false,
        draft: false,
        draftOriginalValue: false,
        isScheduled: false,
        scheduledPublicationFailed: false,
        mediaList: toAttachmentsList(
            null,
            0,
            AttachmentCategoryType.MULTIMEDIA,
        ),
        documentList: toAttachmentsList(
            null,
            0,
            AttachmentCategoryType.DOCUMENT,
        ),
        commentsMediaList: toAttachmentsList(
            null,
            0,
            AttachmentCategoryType.MULTIMEDIA,
        ),
        commentsDocumentList: toAttachmentsList(
            null,
            0,
            AttachmentCategoryType.DOCUMENT,
        ),
        filePickerMediaList: emptyAttachmentsList(
            AttachmentCategoryType.MULTIMEDIA,
        ),
        filePickerDocumentList: emptyAttachmentsList(
            AttachmentCategoryType.DOCUMENT,
        ),
    };
};
