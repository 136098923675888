import { isDefined } from './is-defined';

export function mapById<T>(
    object: T | { id: T } | null | undefined,
): T | null | undefined {
    if (object != null) {
        return typeof object === 'object' && 'id' in object
            ? object.id
            : object;
    }
    return <T>object;
}

export function mapArrayById<T>(
    array: Array<T | { id: T }> | null | undefined,
): T[] | null | undefined {
    if (array != null) {
        return array.map(mapById).filter(isDefined);
    }
    return <Array<T>>(<unknown>array);
}
