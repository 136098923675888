<div
    *ngIf="control | inputState: isReadonly | async as state"
    class="flex flex-col space-y-12"
    [ngClass]="{
        'text-text-disabled pointer-events-none': state.disabled
    }"
>
    @if (_items.totalCount >= 20) {
        <interacta-input-search
            [control]="textSearchControl"
            [iconPosition]="'right'"
            [isReadonly]="state.disabled"
            [label]="'SHARED.BUTTON.SEARCH' | translate"
        />
    }

    <div
        #scroll
        class="overflow-y-auto"
        interactaScrollTracker
        [ngClass]="{
            'max-h-282': _items.totalCount < VIRTUAL_SCROLL_THRESHOLD,
            'h-282': _items.totalCount >= VIRTUAL_SCROLL_THRESHOLD
        }"
        (scrolled)="onScroll($event)"
    >
        @if (_items.totalCount > 5 && withChips) {
            <div class="flex flex-wrap items-center">
                @for (item of chipItems$ | async; track item.id) {
                    <interacta-chip
                        class="mb-8 mr-8"
                        [countState]="
                            getState(item.id) === 'indeterminate'
                                ? {
                                      count: occurrences?.[item.id]?.occ || 0,
                                      totalCount: totalCount || 0
                                  }
                                : undefined
                        "
                        [iconButtonRight]="'close'"
                        [label]="item.label"
                        (action)="removeItem(item.id)"
                    >
                    </interacta-chip>
                }
            </div>
        }

        @if (_items.list.length) {
            <interacta-checkbox-group-indeterminate
                class="flex h-full flex-col space-y-16"
                [canBeIndeterminate]="canBeIndeterminate"
                [formControl]="control"
            >
                @if (_items.totalCount < VIRTUAL_SCROLL_THRESHOLD) {
                    @for (item of _items.list; track item.id) {
                        <interacta-checkbox
                            [disabled]="state.disabled"
                            [ngClass]="{
                                'border-border-300 block border-b pb-4':
                                    item.id === EMPTY_SEARCH_VALUE
                            }"
                            [value]="item.id"
                        >
                            <interacta-input-multiple-select-label-item
                                [countState]="
                                    getState(item.id) === 'indeterminate'
                                        ? {
                                              count:
                                                  occurrences?.[item.id]?.occ ||
                                                  0,
                                              totalCount: totalCount || 0
                                          }
                                        : undefined
                                "
                                [italic]="item.id === EMPTY_SEARCH_VALUE"
                                [label]="item.label | translate"
                            />
                        </interacta-checkbox>
                    }
                }

                @if (_items.totalCount >= VIRTUAL_SCROLL_THRESHOLD) {
                    <virtual-scroller
                        class="h-full"
                        [enableUnequalChildrenSizes]="false"
                        [items]="_items.list"
                        [parentScroll]="scroll"
                        (vsUpdate)="displayedItems = $event"
                    >
                        @for (item of displayedItems; track item.id) {
                            <interacta-checkbox
                                [disabled]="state.disabled"
                                [ngClass]="{
                                    'border-border-300 block border-b pb-4':
                                        item.id === EMPTY_SEARCH_VALUE
                                }"
                                [value]="item.id"
                            >
                                <interacta-input-multiple-select-label-item
                                    [countState]="
                                        getState(item.id) === 'indeterminate'
                                            ? {
                                                  count:
                                                      occurrences?.[item.id]
                                                          ?.occ || 0,
                                                  totalCount: totalCount || 0
                                              }
                                            : undefined
                                    "
                                    [italic]="item.id === EMPTY_SEARCH_VALUE"
                                    [label]="item.label | translate"
                                />
                            </interacta-checkbox>
                        }
                    </virtual-scroller>
                }
            </interacta-checkbox-group-indeterminate>
        }

        @if (_items.nextPageToken.tag !== 'lastLoading') {
            <interacta-load-more
                class="flex items-center justify-center py-8"
                [isLoading]="_items.isFetching"
                [label]="'UI.SHOW_MORE_UNCOUNT' | translate"
                [size]="'regular'"
                (loadMore)="doSearch()"
            />
        }

        @if (!_items.isFetching && !_items.list.length && noResultLabel) {
            <div
                class="typo-medium h-56 px-12 py-14"
                [innerHTML]="noResultLabel"
            ></div>
        }
    </div>
</div>
