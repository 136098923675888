<ng-template #container>
    <div
        *ngIf="isOpen"
        #dialog
        cdkTrapFocus
        cdkTrapFocusAutoCapture
        class="fixed inset-0 z-20 flex h-full w-full items-center justify-center"
        role="dialog"
        @dialog
        (@dialog.done)="$event.toState === 'void' && closed.emit()"
    >
        <div
            class="bg-transparent-black-not-black-80 absolute inset-0"
            (click)="closeOnBackdropClick && close()"
        ></div>

        <div
            class="min-w-352 bg-surface-C relative flex flex-col overflow-hidden rounded-2xl p-16 transition-colors"
            id="content"
            [@width]="size"
            [ngClass]="{
                'w-352': size === 'extra-small',
                'w-464': size === 'small',
                'w-676': size === 'regular',
                'w-856': size === 'large',
                'w-11/12': size === 'extra-large',
                'max-w-11/12': size === 'fit-content',
                'h-full': fullHeight,
                'max-h-4/5': size === 'large' && !fullHeight,
                'max-h-672':
                    !fullHeight &&
                    (size === 'extra-small' ||
                        size === 'small' ||
                        (size === 'regular' && !customRegularHeight)),
                'max-h-11/12':
                    !fullHeight &&
                    (size === 'extra-large' ||
                        size === 'fit-content' ||
                        ((size === 'extra-small' ||
                            size === 'regular' ||
                            size === 'small') &&
                            customRegularHeight))
            }"
        >
            <div class="mb-8 flex shrink-0 items-center justify-between">
                <ng-content select="[title]"></ng-content>

                <button
                    class="self-start"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="'SHARED.BUTTON.CLOSE' | translate"
                    [icon]="'close'"
                    [matTooltip]="'SHARED.BUTTON.CLOSE' | translate"
                    [size]="'regular'"
                    (click)="close()"
                ></button>
            </div>

            <ng-content select="interacta-dialog-description"></ng-content>

            <ng-content select="[subtitle]"></ng-content>

            <ng-content select="[search]"></ng-content>

            <div
                class="flex flex-col"
                [ngClass]="{
                    'overflow-auto': contentOverflowAuto,
                    'min-h-0 grow': !contentOverflowAuto,
                    'h-full': fullHeight
                }"
            >
                <ng-content select="[content]"></ng-content>
            </div>

            <ng-content
                select="interacta-dialog-buttons, [interacta-dialog-buttons]"
            ></ng-content>
        </div>
    </div>
</ng-template>
