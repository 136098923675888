<div
    *ngIf="!wrappedMemberList || wrappedMemberList.length === 1; else list"
    class="bg-surface-B flex items-center space-x-4 rounded-2xl pr-8"
>
    <ng-container *ngIf="wrappedMemberList == null">-</ng-container>
    <ng-container
        *ngIf="wrappedMemberList != null && wrappedMemberList[0] as member"
    >
        <button
            class="flex items-center"
            type="button"
            [disabled]="!canClick"
            (click)="openMemberDialog(member)"
        >
            <interacta-member-avatar size="extra-small" [member]="member">
            </interacta-member-avatar>
            <span class="typo-xs text-text mx-8 line-clamp-1">{{
                getMemberFullname(member)
            }}</span>
        </button>
    </ng-container>
</div>

<ng-template #list>
    <div
        *ngIf="showAll; else visibleList"
        class="flex w-full flex-wrap items-center gap-4"
    >
        <button
            *ngFor="let member of wrappedMemberList; trackBy: trackMember"
            class="flex items-center space-x-4 rounded-2xl bg-white pr-8 text-left"
            type="button"
            [disabled]="!canClick"
            (click)="openMemberDialog(member)"
        >
            <interacta-member-avatar [member]="member" [size]="'extra-small'">
            </interacta-member-avatar>
            <span class="typo-xs mx-8 line-clamp-1">{{
                getMemberFullname(member)
            }}</span>
        </button>
    </div>
    <ng-template #visibleList>
        <ng-container *ngIf="{ hiddenCount: (hiddenCount$ | async) ?? 0 } as $">
            <div
                class="flex w-full items-center space-x-4 overflow-hidden"
                injShowMoreChildren
                [showMoreBtnWidth]="
                    $.hiddenCount > 99
                        ? 36
                        : $.hiddenCount <= 99 && $.hiddenCount !== 0
                          ? 24
                          : 0
                "
                (showMore)="showMoreCount($event)"
            >
                <button
                    *ngFor="
                        let member of wrappedMemberList
                            | slice: 0 : MAX_VISIBLE_MEMBERS;
                        trackBy: trackMember
                    "
                    class="flex shrink-0 items-center"
                    injShowMoreItem
                    type="button"
                    (click)="openMemberDialog(member)"
                >
                    <interacta-member-avatar
                        size="extra-small"
                        [member]="member"
                    >
                    </interacta-member-avatar>
                </button>
                <button
                    *ngIf="$.hiddenCount"
                    class="typo-xs h-24 shrink-0"
                    injShowMoreButton
                    type="button"
                    [ngClass]="{
                        'w-24': $.hiddenCount <= 99,
                        'w-36': $.hiddenCount > 99
                    }"
                    (click)="isDialogOpen = true"
                >
                    +{{ $.hiddenCount }}
                </button>
            </div>
        </ng-container>

        <interacta-dialog-members
            [isDialogOpen]="isDialogOpen"
            [memberList]="wrappedMemberList"
            [title]="fieldName"
            [totalCount]="wrappedMemberList.length"
            (closeDialog)="isDialogOpen = false"
        >
        </interacta-dialog-members>
    </ng-template>
</ng-template>
