import { Pipe, PipeTransform } from '@angular/core';
import { getMemberFullname } from '@modules/core/models/member/member.utils';
import { TagMember } from '@modules/core/models/tag/tag.model';
import { isTagOfTagMember } from '@modules/core/models/tag/tag.utils';

@Pipe({
    name: 'tagMemberFullname',
})
export class TagMemberFullnamePipe implements PipeTransform {
    transform(value: TagMember | undefined): string {
        return value
            ? isTagOfTagMember(value)
                ? value.name
                : getMemberFullname(value)
            : '';
    }
}
