import { flatten, IList } from '@interacta-shared/util';
import {
    IWorkspace,
    WorkspaceDeserialize,
} from '@modules/communities/models/workspace.model';
import {
    IAdministrator,
    IAdministratorsGroup,
    IUser,
    UserDeserilize,
} from '@modules/core';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import { IGenericCommunityFilter } from '@modules/core/models/user-autocomplete.model';
import { i18nString } from '../../core/models/utility.model';
import {
    IPostMetadata,
    VisibilityType,
} from '../../post/models/base-post.model';
import { i18nDelta } from './../../core/models/utility.model';

export interface ICommunity {
    id: number;
    workspaceId: number;
    description: i18nDelta;
    info: i18nDelta;
    color: string;
    coverImageLink: string;
    coverImageContentRef: string;
    coverImageTemporaryContentViewLink: string;
    name: i18nString;
    creationTimestamp?: Date;
    creatorUser?: IUser;
    lastModifyTimestamp?: Date;
    lastModifyUser?: IUser;
    occToken?: string;
    operationalUsers?: IList<IAdministrator>;
    operationalGroups?: IList<IAdministratorsGroup>;
    capabilities?: ICommunityCapabilities;
    metadata?: IPostMetadata;
    draft: boolean;
    deleted: boolean;
    active: boolean;
    etag: number;
    workspaceName: i18nString;
    visibleInOrganizationTree: boolean;
    visibleInDashboard: boolean;
    publicationTimestamp?: Date;
    defaultPostVisibility: VisibilityType;
    pinnedTimestamp?: number;
    membersCount?: number; // loaded only if current user has public visibility in community

    //-- LAZY COUNTERS  --
    // filled only if CommunitiesService.getCommunityDetails(loadCounters = true)

    communityManagersCount?: number;
    completeVisibilityMembersCount?: number;

    postsCount?: number;
    attachmentsCount?: number;

    //--------------------
}

export interface CommunityDetail extends ICommunity {
    workflowTitle?: string;
    workflowId?: number;
}

export interface ICommunityCapabilities {
    createPost: boolean;
    editPost: number;
    deletePost: number;
    pinPost: boolean;
    exportPosts: boolean;
    accessBaseInsights: boolean;
    accessWorkflowInsights: boolean;
    createComment: boolean;
    editComment: number;
    deleteComment: number;
    createTask: boolean;
    createAcknowledgeTask: boolean;
    monitorAcknowledgeTask: boolean;
    viewTask: boolean;
    viewPost: boolean;
    createMention: boolean;
    viewWorkflowHistory: boolean;
    viewPostRelations: boolean;
    viewPostVisualizations: boolean;
    setPostAnnouncementFlag: boolean;
    viewPostVisibility: boolean;
    editPostVisibility: boolean;
    createCustomPost: boolean;
    createEventPost: boolean;
    createSurveyPost: boolean;
    createFeedbackPost: boolean;
    editCommunity: boolean; //admin-capabilities
    manageWorkflow: boolean; //admin-capabilities
}

export type CommunityTreeType = 'post' | 'insights';
export interface ICommunityTree {
    workspaceList: IWorkspace[];
    communityList: ICommunity[];
}

export type ICommunityDashboardPreferences = Record<number, boolean>;

export class CommunityTreeDeserialize {
    static communityTree = (record: any): ICommunityTree => {
        const workspaceList: IWorkspace[] = record.items.map(
            CommunityTreeDeserialize.workspaceTree,
        );
        const communityList: ICommunity[] = flatten(
            workspaceList.map((w) => w.communities ?? []),
        );
        return { workspaceList, communityList };
    };

    static workspaceTree = (record: any): IWorkspace => {
        const workspace = WorkspaceDeserialize.workspaceInfo({
            ...record,
            workspace: record,
        });
        workspace.communities = (
            (record.communityLevels as Array<any>) ?? []
        ).map((community) =>
            CommunityTreeDeserialize.communityInfoDetails(
                community,
                workspace.name,
            ),
        );
        return workspace;
    };

    static commonCommunityDetails = (record: any): ICommunity => {
        const object = {
            community: record,
            membersCount: record.membersCount,
            postsCount: record.postsCount,
        };
        return CommunityTreeDeserialize.communityDetails(object);
    };

    static communityDetails = (record: any): CommunityDetail => {
        const community = CommunityTreeDeserialize.communityInfoDetails(
            record.community,
        );
        community.membersCount = record.membersCount;
        community.communityManagersCount = record.communityManagersCount;
        community.postsCount = record.postsCount;
        community.attachmentsCount = record.attachmentsCount;
        community.defaultPostVisibility = record.defaultPostVisibility;
        community.completeVisibilityMembersCount =
            record.completeVisibilityMembersCount;
        community.workflowId = record.workflowId;
        community.workflowTitle = record.workflowTitle;
        community.visibleInDashboard = record.visibleInDashboard;

        return community;
    };

    static getDescriptionI18n(description: any): i18nDelta {
        if (typeof description === 'object') {
            const record: i18nDelta = {};

            Object.keys(description).forEach((k) => {
                record[k] = new Server2Delta().process(description[k]);
            });

            return record;
        } else if (typeof description === 'string') {
            return new Server2Delta().process(description);
        }
    }

    static communityInfoDetails = (
        record: any,
        workspaceName?: i18nString,
    ): CommunityDetail => ({
        id: record.id,
        description: record.description
            ? CommunityTreeDeserialize.getDescriptionI18n(record.description)
            : null,
        info: record.info
            ? CommunityTreeDeserialize.getDescriptionI18n(record.info)
            : null,
        workspaceId: record.workspaceId || -1,
        capabilities:
            record.operationalCapabilities || record.adminCapabilities
                ? CommunityTreeDeserialize.communityCapabilities({
                      ...(record.operationalCapabilities ?? {}),
                      ...(record.adminCapabilities ?? {}),
                  })
                : undefined,
        coverImageLink: record.coverImageTemporaryContentViewLink,
        name: record.name,
        color: record.colorCode,
        coverImageContentRef: record.coverImageContentRef ?? null,
        coverImageTemporaryContentViewLink:
            record.coverImageTemporaryContentViewLink,
        creatorUser: record.creatorUser
            ? UserDeserilize.userDetails(record.creatorUser)
            : undefined,
        creationTimestamp: record.creationTimestamp
            ? new Date(record.creationTimestamp as number)
            : undefined,
        lastModifyUser: record.lastModifyUser
            ? UserDeserilize.userDetails(record.lastModifyUser)
            : undefined,
        lastModifyTimestamp: record.lastModifyTimestamp
            ? new Date(record.lastModifyTimestamp as number)
            : undefined,
        draft: record.draft,
        active: record.active ?? true,
        deleted: record.deleted ?? false,
        etag: record.etag,
        workspaceName: workspaceName ?? record?.workspace?.name ?? null,
        visibleInOrganizationTree: !!record.visibleInOrganizationTree,
        visibleInDashboard: record.visibleInDashboard,
        publicationTimestamp: record.publicationTimestamp
            ? new Date(record.publicationTimestamp as number)
            : undefined,
        defaultPostVisibility: record.defaultPostVisibility,
        communityManagersCount: record.communityManagersCount,
        completeVisibilityMembersCount: record.completeVisibilityMembersCount,
        pinnedTimestamp: record.pinnedTimestamp,
    });

    static communityCapabilities = (record: any): ICommunityCapabilities => ({
        createPost:
            record.createPost ||
            record.createEventPost ||
            record.createSurveyPost ||
            record.createFeedbackPost,
        editPost: record.editPost,
        deletePost: record.deletePost,
        pinPost: record.pinPost,
        exportPosts: record.exportPosts,
        createComment: record.createComment ?? false,
        editComment: record.editComment,
        deleteComment: record.deleteComment,
        createTask: record.createTask,
        viewTask: record.viewTask,
        accessBaseInsights: record.accessBaseDashboard,
        createAcknowledgeTask: record.createAcknowledgeTask ?? false,
        accessWorkflowInsights: record.accessWorkflowDashboard,
        monitorAcknowledgeTask: record.monitorAcknowledgeTask ?? false,
        createMention: record.createMention,
        viewWorkflowHistory: record.viewWorkflowHistory,
        viewPostRelations: record.viewPostRelations,
        viewPostVisualizations: record.viewPostVisualizations,
        setPostAnnouncementFlag: record.setPostAnnouncementFlag,
        viewPostVisibility: record.viewPostVisibility,
        editPostVisibility: record.editPostVisibility,
        createEventPost: record.createEventPost,
        createCustomPost: record.createPost,
        createSurveyPost: record.createSurveyPost,
        createFeedbackPost: record.createFeedbackPost,
        editCommunity: record.editCommunity ?? false,
        manageWorkflow: record.manageWorkflow ?? false,
        viewPost: record.viewPost,
    });
}

export interface CommunityListFilter {
    pageToken: string | null;
    pageSize: number;
    name: string | null;
    orderBy: 'name' | 'status';
    orderDesc: boolean;
    calculateTotalItemsCount: boolean;
}

export const fromCommunityDashboardPreferences = (
    preferences: ICommunityDashboardPreferences,
): any => {
    return {
        communities: Object.entries(preferences).map(([id, visible]) => ({
            communityId: +id,
            visibleInDashboard: visible,
        })),
    };
};

export const sanitizeCachedCommunity = (
    cachedCommunity: Partial<ICommunity>,
): ICommunity => ({
    ...(cachedCommunity as ICommunity),
    metadata: cachedCommunity.metadata
        ? {
              ...cachedCommunity.metadata,
              workflowDefinition: cachedCommunity.metadata.workflowDefinition
                  ? {
                        ...cachedCommunity.metadata.workflowDefinition,
                        allScreenFieldMetadatas:
                            cachedCommunity.metadata.workflowDefinition
                                .allScreenFieldMetadatas ??
                            cachedCommunity.metadata.workflowDefinition
                                .screenFieldMetadatas,
                    }
                  : undefined,
          }
        : undefined,
});

export const fromCommunityManagerFilter = (
    filter: IGenericCommunityFilter | undefined,
): any => ({
    pageToken: filter?.pageToken,
    pageSize: filter?.pageSize || 25,
    calculateTotalItemsCount: filter?.calculateTotalItemsCount || true,
    name: filter?.name ? filter.name : null,
});
