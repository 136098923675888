import { clone, idArraytoMap } from '@modules/core/helpers/generic.utils';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { IPostLink } from '@modules/post/models/post-link/post-link.model';
import {
    ICustomField,
    ICustomFieldDefinition,
    MetadataGenericListType,
} from './custom-metadata.model';
import { createCustomField, isCustomFieldEmpty } from './custom-metadata.utils';

export class CustomFields {
    public data: ICustomField[];
    public serverFieldsMap: { [key: number]: any };

    constructor(
        private fieldMetadatas?: ICustomFieldDefinition[],
        fieldsMap?: { [key: number]: any },
    ) {
        this.serverFieldsMap = clone(fieldsMap);
        this.data = [];
        if (!fieldMetadatas) {
            return;
        }

        fieldMetadatas.forEach((fieldMetadata: ICustomFieldDefinition) => {
            const serverValue = fieldsMap ? fieldsMap[fieldMetadata.id] : null;
            const field = createCustomField(serverValue, fieldMetadata);
            this.data.push(field);
        });
    }

    get visibleInDetail(): ICustomField[] {
        return this.data.filter((item) => item.configuration.visibleInDetail);
    }
    get visibleInEdit(): ICustomField[] {
        return this.data.filter((item) => item.configuration.visibleInEdit);
    }
    get visibleInCreate(): ICustomField[] {
        return this.data.filter((item) => item.configuration.visibleInCreate);
    }
    get visibleInPreview(): ICustomField[] {
        return this.data.filter((item) => item.configuration.visibleInPreview);
    }
    get visibleInPreviewNotEmpty(): ICustomField[] {
        return this.visibleInPreview.filter(
            (item) => !isCustomFieldEmpty(item),
        );
    }

    get visibleInDetailNotEmpty(): ICustomField[] {
        return this.visibleInDetail.filter((item) => !isCustomFieldEmpty(item));
    }

    get visibleInSearch(): ICustomField[] {
        return this.data.filter((item) => item.configuration.searchable);
    }

    get notEmptyFilePickerFields(): ICustomField[] {
        return this.data.filter(
            (item) =>
                item.configuration.metadata.generic_entity_list_config_id ===
                    MetadataGenericListType.FILE_PICKER &&
                !isCustomFieldEmpty(item),
        );
    }

    isEmpty(): boolean {
        return !this.data.some((item) =>
            item.inputValue !== null && item.inputValue !== undefined
                ? Array.isArray(item.inputValue)
                    ? item.inputValue.length > 0
                    : true
                : false,
        );
    }

    filterByIds(ids: number[]): ICustomField[] {
        return this.data.filter(
            (d) => ids.includes(d.configuration.id) && d != null,
        );
    }

    changeValueForPostLink(
        fieldId: number,
        value: IPostLink[] | null,
    ): CustomFields {
        const field = this.data.find((f) => f.configuration.id === fieldId);
        if (field) {
            field.serverValue = value;
            field.inputValue = value;
            field.viewValue = value;
        }
        return this;
    }

    changeValueForAttachments(
        fieldId: number,
        value: IAttachment[],
    ): CustomFields {
        const field = this.data.find((f) => f.configuration.id === fieldId);
        const attachmentsMap = idArraytoMap(value);
        if (field) {
            field.inputValue = { ...field.inputValue };
            field.serverValue = (field.serverValue as IAttachment[]).map(
                (sv) => attachmentsMap[sv.id] ?? sv,
            );
            const actualViewValue = field.viewValue as IListAttachments;
            field.viewValue = {
                ...actualViewValue,
                attachments: actualViewValue.attachments.map(
                    (sv) => attachmentsMap[sv.id] ?? sv,
                ),
            };
        }
        return this;
    }

    clone(): CustomFields {
        return new CustomFields(
            this.fieldMetadatas,
            this.getCurrentFieldsMap(),
        );
    }

    private getCurrentFieldsMap(): { [key: number]: any } {
        return this.data.reduce(
            (map, field) => ({
                ...map,
                [field.configuration.id]: field.serverValue,
            }),
            {},
        );
    }
}
