import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { Member } from '@modules/core/models/member/member.model';

export const mentionDenotationChars = ['@', '#', 'T'] as const;

export type MentionDenotationChar = (typeof mentionDenotationChars)[number];

export const mentionTypes = ['user', 'group', 'hashtag', 'table'] as const;

export type MentionType = (typeof mentionTypes)[number];

type MentionKeyMap = {
    [P in MentionType]: MentionDenotationChar;
};

export const mentionTypeKeys: MentionKeyMap = {
    user: '@',
    group: '@',
    hashtag: '#',
    table: 'T', // not actually available to the user. Only a temporary placeholder to programmatically add table mention
};

export const hiddendDenotationChars: MentionDenotationChar[] = [
    mentionTypeKeys.table,
];

export interface MentionItem {
    id: number;
    value: string;
    denotationChar: MentionDenotationChar;
    mentionType: MentionType;
    data: MentionData;
}

export interface MentionItemPlaceholder {
    type: 'loading';
    disabled: true;
}

export type MentionItemWrapper = MentionItem | MentionItemPlaceholder;

export type MentionData = Member | IHashTag;
