import { isDefined } from '@interacta-shared/util';
import * as PostSelectors from '@modules/communities/store/post/post.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UIState } from './ui.reducer';

const getUIFeature = createFeatureSelector<UIState>('uiState');

export const commentEditor = createSelector(
    getUIFeature,
    (state) => state.commentEditor,
);

export const postIdOfFullscreenComment = createSelector(
    commentEditor,
    (editor) => (editor ? editor.postId : null),
);

export const postOfFullscreenComment = createSelector(
    postIdOfFullscreenComment,
    PostSelectors.selectAllPosts,
    (postId, posts) => (isDefined(postId) ? posts[postId] : null),
);

export const theme = createSelector(getUIFeature, (ui) => ui.theme);

export const notificationPanel = createSelector(
    getUIFeature,
    (ui) => ui.notificationPanel,
);
