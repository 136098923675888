import {
    Language,
    ZonedDateTime,
} from '@interacta-shared/data-access-configuration';
import { PaginatedList } from '@interacta-shared/util';
import { AppBaseRoute } from '@modules/app-routing/routes';
import { ICatalogEntry } from '@modules/communities/models/catalog/catalog.model';
import { ICommunity } from '@modules/communities/models/communities.model';
import { ICustomFieldDefinition } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import {
    IPostLinkAndRelation,
    PostLinkFilters,
} from '@modules/communities/models/post-link-and-relation/post-link-and-relation.model';
import { IScreenEdit } from '@modules/communities/models/screen.model';
import { IWorkflowOperation } from '@modules/communities/models/workflow/workflow.model';
import { IUser, IUserAction, QuillText } from '@modules/core';
import { Member } from '@modules/core/models/member/member.model';
import { IGenericCommunityFilter } from '@modules/core/models/user-autocomplete.model';
import {
    AttachmentCategoryType,
    IAttachment,
} from '@modules/post/models/attachment/attachment.model';
import {
    IBasePost,
    IBasePostCreate,
    IBasePostEdit,
    IPostMetadata,
    VisibilityType,
} from '@modules/post/models/base-post.model';
import {
    CommentLocationType,
    IComment,
    ICommentDraft,
} from '@modules/post/models/comment.model';
import { ICustomPost } from '@modules/post/models/custom-post.model';
import {
    EventParticipateType,
    IEventPost,
    PostInvitationsMembersEdit,
} from '@modules/post/models/event-post.model';
import { AttachmentEntityType } from '@modules/post/models/filter-attachment.model';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import {
    GenericPost,
    GenericPostCreate,
} from '@modules/post/models/generic-post.model';
import { IPostHistoryFilter } from '@modules/post/models/post-list.model';
import {
    SurveyPost,
    SurveyType,
} from '@modules/post/models/survey-post/survey-post.model';
import { GallerySourceFeature } from '@modules/state/models/gallery-source.model';
import { IFilePickerAttachment } from '@modules/state/models/gallery-state.model';
import { ITaskType } from '@modules/tasks/models/task.model';
import { createAction, props } from '@ngrx/store';
import { PostFetch } from './post.reducer';

enum PostActionType {
    FetchPosts = '[Post] Fetch Posts',
    FetchPostsWithCurrentFilters = '[Post] Fetch Posts With Current Filters',
    FetchPostsCount = '[Post] Fetch Posts Count',

    RequestFetchPostsPage = '[Post] Request Fetch Post Page',
    FetchPostsPage = '[Post] Fetch Posts Page',

    AddFilters = '[Post] Add Filters',
    RemoveFilters = '[Post] Remove Filters',
    ChangeFilters = '[Post] Change Filters',

    LikeToggle = '[Post] Like Toggle',

    FollowToggle = '[Post] Follow Toggle',

    ParticipateEvent = '[Post] Participate Event',

    PinToggle = '[Post] Pin Toggle',

    VisibilityChange = '[Post] Visibility Change',

    TranslatePost = '[Post] Translate',

    UntranslatePost = '[Post] Untranslate',

    Create = '[Post] Create',

    Edit = '[Post] Edit',

    Copy = '[Post] Copy',

    AddWatchers = '[Post] Add Watchers',

    RemoveWatchers = '[Post] Remove Watchers',

    Reload = '[Post] Reload',

    AddAttachments = '[Post] Add Attachments',

    EditAttachments = '[Post] Edit Attachments',

    DeleteAttachments = '[Post] Delete Attachments',

    FetchComments = '[Post] Fetch Comments',
    RequestCommentsUntilId = '[Post] Request Comments Until ID',
    FetchCommentsUntilId = '[Post] Fetch Comments Until ID',

    FetchStreamingComments = '[Post] Fetch Streaming Comments',

    LikeToggleComment = '[Post] Like Toggle Comment',

    DeleteComment = '[Post] Delete Comment',

    TranslateComment = '[Post] Translate Comment',
    UntranslateComment = '[Post] Untranslate Comment',

    // Sync comments' actions
    OpenDraftComment = '[Post] Open Draft Comment',
    CloseDraftComment = '[Post] Close Draft Comment',
    SaveCommentDraft = '[Post] Save Comment Draft',
    ReplyComment = '[Post] Reply Comment',
    EditComment = '[Post] Edit Comment',

    // Async comments' actions
    AddStreamingComment = '[Post] Add Streaming Comment',
    AddComment = '[Post] Add Comment',
    SaveEditComment = '[Post] Save Edit Comment',

    FetchAttachments = '[Post] Fetch Attachments',
    FetchAttachmentsPage = '[Post] Fetch Attachments Page',

    DeselectAllAttachments = '[Post] Deselect All Attachments',

    MarkAsViewed = '[Post] Mark As Viewed',

    FetchViewers = '[Post] Fetch Viewers',

    FetchLikers = '[Post] Fetch Likers',

    FetchParticipants = '[Post] Fetch Participants',

    Delete = '[Post] Delete',
    MassiveDeletePosts = '[Post] Massive Delete Posts',

    Remove = '[Post] Remove',

    FetchLinksAndRelationsCount = '[Post] Fetch Links And Relations Count',

    FetchLinkedPosts = '[Post] Fetch Linked Posts',

    AddLinkedPosts = '[Post] Add Linked Posts',
    RemovePostsFromRelations = '[Post] Remove Posts From Relations',

    UpdateMyExpiringAcknowledgeCounter = '[Post] Update My Expiring Acknowledge Counter',

    UpdateAcknowledgeCounters = '[Post] Update Acknowledge Counters',

    UpdateSurveyCounters = '[Post] Update Survey Counters',

    FetchWorkflowHistory = '[Post] Fetch Workflow History',

    FetchCommentLikers = '[Post] Fetch Comment Likers',

    WorkflowTransition = '[Post] Workflow Transition',

    EditWorkflowScreen = '[Post] Edit Workflow Screen',

    FetchVisibilityCounters = '[Post] Fetch Visibility Counter',

    FetchCommunityViewersList = '[Post] Fetch Community Viewers List',

    FetchOthersVisibilityList = '[Post] Fetch Others Visibility List',

    FetchTasksAssigneesList = '[Post] Fetch Tasks Assignees List',

    FetchInvitationsList = '[Post] Fetch Invitations List',

    FetchGroupInvitationsList = '[Post] Fetch Group Invitations List',

    FetchMentionsList = '[Post] Fetch Mentions List',

    FetchVisibleCommentsCountDetail = '[Post] Fetch Visible Comments Count Detail',

    FetchVisibleCommentsCountDashboard = '[Post] Fetch Visible Comments Count Dashboard',

    ClearFetchPostError = '[Post] Clear fetch error',

    GetStreamingVideo = '[Post] Get Streaming Video',

    EditInvitationsList = '[Post] Edit Invitations List',

    ModifyStreamingStartTimestamp = '[Post] Modify Streaming Start Timestamp',

    ModifyStreamingEndTimestamp = '[Post] Modify Streaming End Timestamp',

    SetPreviousQuickFilter = '[Post] Set Previous Quick Filter',

    fetchFilePickerAttachments = '[Post] Fetch File Picker Attachments',
    FetchFilePickerTemporaryContentViewLink = '[Post] Fetch File Picker TemporaryContentViewLink',

    UpdateSurveyQuestionsDefinitionPosts = '[Post] Update Survey Questions Definition For specified Posts',
    UpdateSurveyQuestionsDefinition = '[Post] Update Survey Questions Definition',

    SetTimeRelatedStateUpdateTimeout = '[Post] Set time related State Update Timeout',

    UpdateTimeRelatedState = '[Post] Update time related State',

    ExportSurveyPostAnswersCsv = '[Post] Export Survey Post Answers Csv',

    CloseSurvey = '[Post] Close survey',

    UpdateAllPosts = '[Post] Update All Posts',

    PruneAllPosts = '[Post] Prune All Posts',

    FetchPostsCapabilities = '[Post] Fetch Posts Capabilities',
}

export const fetchPosts = createAction(
    PostActionType.FetchPosts,
    props<{
        fetchType: PostFetch;
        filters?: IPostFilters;
        postId?: number;
        skipComments?: boolean;
        skipMentions?: boolean;
    }>(),
);

export const fetchPostsWithCurrentFilters = createAction(
    PostActionType.FetchPostsWithCurrentFilters,
    props<{ fetchType: PostFetch; postId?: number }>(),
);

export const fetchPostsCount = createAction(
    PostActionType.FetchPostsCount,
    props<{ fetchType: PostFetch; filters?: IPostFilters }>(),
);

export const requestFetchPostsPage = createAction(
    PostActionType.RequestFetchPostsPage,
    props<{ fetchType: PostFetch; pageSize: number }>(),
);

export const fetchPostsPage = createAction(
    PostActionType.FetchPostsPage,
    props<{ fetchType: PostFetch; filters: IPostFilters }>(),
);

export const addFilters = createAction(
    PostActionType.AddFilters,
    props<{ fetchType: PostFetch; addFilters: Partial<IPostFilters> }>(),
);

export const removeFilters = createAction(
    PostActionType.RemoveFilters,
    props<{
        fetchType: PostFetch;
        removeFilters: (keyof IPostFilters)[];
        clearPreviousFilter?: boolean;
    }>(),
);

export const changeFilters = createAction(
    PostActionType.ChangeFilters,
    props<{
        fetchType: PostFetch;
        updatedFilters: IPostFilters;
        forcePostFetch?: boolean;
        showTip?: {
            title: string;
            message: string;
            translateParams?: Record<string, unknown>;
        };
        clearPreviousFilter?: boolean;
    }>(),
);

export const likeToggle = createAction(
    PostActionType.LikeToggle,
    props<{ postId: number; currUser: IUser }>(),
);

export const followToggle = createAction(
    PostActionType.FollowToggle,
    props<{ postId: number }>(),
);

export const participateEvent = createAction(
    PostActionType.ParticipateEvent,
    props<{
        post: IEventPost;
        user: IUser;
        typeId: EventParticipateType;
        notes?: string;
    }>(),
);

export const pinToggle = createAction(
    PostActionType.PinToggle,
    props<{ postId: number }>(),
);

export const visibilityChange = createAction(
    PostActionType.VisibilityChange,
    props<{ postId: number; visibility: VisibilityType }>(),
);

export const translatePost = createAction(
    PostActionType.TranslatePost,
    props<{ postId: number; language: Language }>(),
);

export const untranslatePost = createAction(
    PostActionType.UntranslatePost,
    props<{ postId: number }>(),
);

export const openDraftComment = createAction(
    PostActionType.OpenDraftComment,
    props<{ postId: number }>(),
);

export const closeDraftComment = createAction(
    PostActionType.CloseDraftComment,
    props<{ postId: number }>(),
);

export const saveCommentDraft = createAction(
    PostActionType.SaveCommentDraft,
    props<{
        postId: number;
        draft: ICommentDraft;
    }>(),
);

export const replyComment = createAction(
    PostActionType.ReplyComment,
    props<{ postId: number; parentComment: IComment }>(),
);

export const editComment = createAction(
    PostActionType.EditComment,
    props<{ postId: number; comment: IComment }>(),
);

export const addStreamingComment = createAction(
    PostActionType.AddStreamingComment,
    props<{
        postId: number;
        delta: QuillText;
        parentCommentId?: number;
        addAttachments?: IAttachment[];
        location: Extract<CommentLocationType, 'lightBoxStreaming'>;
        streamingTimestamp?: number;
    }>(),
);

export const addComment = createAction(
    PostActionType.AddComment,
    props<{
        postId: number;
        delta: QuillText;
        parentCommentId?: number;
        addAttachments?: IAttachment[];
        location: Extract<CommentLocationType, 'dashboard' | 'detail'>;
    }>(),
);

export const saveEditComment = createAction(
    PostActionType.SaveEditComment,
    props<{
        postId: number;
        commentId: number;
        delta: QuillText;
        parentCommentId?: number;
        addAttachments?: IAttachment[];
    }>(),
);

export const create = createAction(
    PostActionType.Create,
    props<{
        community: ICommunity;
        postCreate: GenericPostCreate;
        redirectTo?: Extract<
            AppBaseRoute,
            'dashboard' | 'post-detail' | 'manage'
        >;
        successMessage?: string;
    }>(),
);

export const edit = createAction(
    PostActionType.Edit,
    props<{
        community: ICommunity;
        postEdit: IBasePostEdit;
        isPublishing: boolean;
        redirectTo?: Extract<
            AppBaseRoute,
            'dashboard' | 'post-detail' | 'manage'
        >;
    }>(),
);

export const copy = createAction(
    PostActionType.Copy,
    props<{
        originalPostId: number;
        community: ICommunity;
        postCopy: IBasePostCreate;
        redirectTo?: Extract<
            AppBaseRoute,
            'dashboard' | 'post-detail' | 'manage'
        >;
        successMessage?: string;
    }>(),
);

export const addWatchers = createAction(
    PostActionType.AddWatchers,
    props<{ postId: number; watchers: Member[] }>(),
);

export const removeWatchers = createAction(
    PostActionType.RemoveWatchers,
    props<{ postId: number; watchers: Member[] }>(),
);

export const addAttachments = createAction(
    PostActionType.AddAttachments,
    props<{
        postId: number;
        attachments: IAttachment[];
    }>(),
);

export const editAttachments = createAction(
    PostActionType.EditAttachments,
    props<{
        postId: number;
        attachments: IAttachment[];
    }>(),
);

export const deleteAttachments = createAction(
    PostActionType.DeleteAttachments,
    props<{
        postId: number;
        attachmentsIds: IAttachment['id'][];
    }>(),
);

export const reload = createAction(
    PostActionType.Reload,
    props<{
        postId: number;
        reloadComments: boolean;
        parentCommentId?: number;
    }>(),
);

export const fetchComments = createAction(
    PostActionType.FetchComments,
    props<{
        postId: number;
        pageToken?: string;
        pageSize?: number;
    }>(),
);

export const requestCommentsUntilId = createAction(
    PostActionType.RequestCommentsUntilId,
    props<{ post: GenericPost; parentCommentId: number }>(),
);

export const fetchCommentsUntilId = createAction(
    PostActionType.FetchCommentsUntilId,
    props<{ postId: number; parentCommentId: number; pageToken?: string }>(),
);

export const fetchStreamingComments = createAction(
    PostActionType.FetchStreamingComments,
    props<{
        postId: number;
        pageToken?: string;
        syncToken?: string;
        pageSize?: number;
    }>(),
);

export const fetchCommentLikers = createAction(
    PostActionType.FetchCommentLikers,
    props<{
        postId: number;
        commentId: number;
        likersList: PaginatedList<IUserAction>;
        pageToken?: string;
    }>(),
);

export const likeToggleComment = createAction(
    PostActionType.LikeToggleComment,
    props<{ postId: number; comment: IComment }>(),
);

export const deleteComment = createAction(
    PostActionType.DeleteComment,
    props<{ postId: number; commentIdx: number; comment: IComment }>(),
);

export const translateComment = createAction(
    PostActionType.TranslateComment,
    props<{ postId: number; comment: IComment; language: Language }>(),
);

export const untranslateComment = createAction(
    PostActionType.UntranslateComment,
    props<{ postId: number; comment: IComment }>(),
);

export const fetchAttachments = createAction(
    PostActionType.FetchAttachments,
    props<{
        postId: number;
        attachmentType?: AttachmentCategoryType;
        pageSize?: number;
        calculateTotalItemsCount?: boolean;
        entityTypes: AttachmentEntityType[];
    }>(),
);

export const fetchAttachmentsPage = createAction(
    PostActionType.FetchAttachmentsPage,
    props<{
        postId: number;
        nextPageToken?: string;
        attachmentType?: AttachmentCategoryType;
        pageSize?: number;
        entityTypes: AttachmentEntityType[];
    }>(),
);

export const deselectAllAttachments = createAction(
    PostActionType.DeselectAllAttachments,
    props<{ postId: number }>(),
);

export const markAsViewed = createAction(
    PostActionType.MarkAsViewed,
    props<{ postId: number }>(),
);

export const fetchViewers = createAction(
    PostActionType.FetchViewers,
    props<{
        postId: number;
        pageToken?: string;
        name?: string;
        communityId: number;
    }>(),
);

export const fetchLikers = createAction(
    PostActionType.FetchLikers,
    props<{ postId: number; pageToken?: string; name?: string }>(),
);

export const fetchParticipants = createAction(
    PostActionType.FetchParticipants,
    props<{
        postId: number;
        typeId: EventParticipateType | null;
        pageToken?: string;
        name?: string;
    }>(),
);

export const deletePost = createAction(
    PostActionType.Delete,
    props<{
        postId: number;
        communityId?: number;
        navigateToCommunity: boolean;
    }>(),
);

export const massiveDeletePosts = createAction(
    PostActionType.MassiveDeletePosts,
    props<{
        postIds: number[];
    }>(),
);

export const fetchLinksAndRelationsCount = createAction(
    PostActionType.FetchLinksAndRelationsCount,
    props<{
        postId: number;
    }>(),
);

export const addLinkedPosts = createAction(
    PostActionType.AddLinkedPosts,
    props<{
        posts: IPostLinkAndRelation[];
        field: ICustomFieldDefinition;
        linkedPost: IPostLinkAndRelation;
    }>(),
);

export const fetchLinkedPosts = createAction(
    PostActionType.FetchLinkedPosts,
    props<{
        postId: number;
        communityId: number;
        metadata: IPostMetadata;
        filters: PostLinkFilters;
    }>(),
);

export const removePostsFromRelations = createAction(
    PostActionType.RemovePostsFromRelations,
    props<{
        post: IPostLinkAndRelation;
        fieldsDefinition: ICustomFieldDefinition[];
        removePostIds: number[] | null;
    }>(),
);

export const updateMyExpiringAcknowledgeCounter = createAction(
    PostActionType.UpdateMyExpiringAcknowledgeCounter,
    props<{
        postId: number;
        assignedToMeExpiringTasksCount: number;
    }>(),
);

export const updateAcknowledgeCounters = createAction(
    PostActionType.UpdateAcknowledgeCounters,
    props<{
        postId: number;
        assignedToMeExpiredTasksCount: number;
        assignedToMeExpiringTasksCount: number;
        openTasksCount: number;
        assignedToMeMinTasksExpirationDate: ZonedDateTime | undefined;
    }>(),
);

export const updateSurveyCounters = createAction(
    PostActionType.UpdateSurveyCounters,
    props<{
        postId: number;
        assignedToMeTasksCount: number;
        assignedToMeExpiredTasksCount: number;
        assignedToMeExpiringTasksCount: number;
        totalTasksCount: number | undefined;
        openTasksCount: number | undefined;
        assignedToMeMinTasksExpirationDate: ZonedDateTime | undefined;
    }>(),
);

export const fetchWorkflowHistory = createAction(
    PostActionType.FetchWorkflowHistory,
    props<{
        postId: number;
        filter?: IPostHistoryFilter;
        communityId: number;
    }>(),
);

export const workflowTransition = createAction(
    PostActionType.WorkflowTransition,
    props<{
        post: ICustomPost;
        transition: IWorkflowOperation;
        screen?: IScreenEdit;
    }>(),
);

export const editWorkflowScreen = createAction(
    PostActionType.EditWorkflowScreen,
    props<{
        post: ICustomPost;
        screen: IScreenEdit;
    }>(),
);

export const fetchVisibilityCounters = createAction(
    PostActionType.FetchVisibilityCounters,
    props<{ postId: number; communityId: number; surveyType?: SurveyType }>(),
);

export const fetchCommunityViewersList = createAction(
    PostActionType.FetchCommunityViewersList,
    props<{
        communityId: number;
        postId: number;
        filters?: IGenericCommunityFilter;
    }>(),
);

export const fetchOthersVisibilityList = createAction(
    PostActionType.FetchOthersVisibilityList,
    props<{ postId: number }>(),
);

export const fetchTasksAssigneesList = createAction(
    PostActionType.FetchTasksAssigneesList,
    props<{
        postId: number;
        pageToken: string | null;
        taskType: ITaskType;
    }>(),
);

export const fetchInvitationsList = createAction(
    PostActionType.FetchInvitationsList,
    props<{ postId: number; pageToken: string | null }>(),
);

export const editInvitationsList = createAction(
    PostActionType.EditInvitationsList,
    props<{ postId: number; params: PostInvitationsMembersEdit }>(),
);

export const fetchGroupInvitationsList = createAction(
    PostActionType.FetchGroupInvitationsList,
    props<{ postId: number; groupId: number; pageToken: string | null }>(),
);

export const fetchMentionsList = createAction(
    PostActionType.FetchMentionsList,
    props<{ postId: number; pageToken: string | undefined }>(),
);

export const fetchVisibleCommentsCountDetail = createAction(
    PostActionType.FetchVisibleCommentsCountDetail,
    props<{ postId: number; visibleCommentsCount: number }>(),
);

export const fetchVisibleCommentsCountDashboard = createAction(
    PostActionType.FetchVisibleCommentsCountDashboard,
    props<{ postId: number; visibleCommentsCount: number }>(),
);

export const clearFetchPostError = createAction(
    PostActionType.ClearFetchPostError,
    props<{ fetchType: PostFetch }>(),
);

export const getStreamingVideo = createAction(
    PostActionType.GetStreamingVideo,
    props<{ postId: number }>(),
);

export const removePost = createAction(
    PostActionType.Remove,
    props<{
        postId: number;
        communityId?: number;
    }>(),
);

export const modifyStreamingStartTimestamp = createAction(
    PostActionType.ModifyStreamingStartTimestamp,
    props<{ postId: number }>(),
);

export const modifyStreamingEndTimestamp = createAction(
    PostActionType.ModifyStreamingEndTimestamp,
    props<{ postId: number }>(),
);

export const setPreviousQuickFilter = createAction(
    PostActionType.SetPreviousQuickFilter,
);

export const fetchFilePickerAttachments = createAction(
    PostActionType.fetchFilePickerAttachments,
    props<{
        postId: number;
        attachmentCategoryType: AttachmentCategoryType;
    }>(),
);

export const fetchFilePickerTemporaryContentViewLink = createAction(
    PostActionType.FetchFilePickerTemporaryContentViewLink,
    props<{
        postId: number;
        filePicker: IFilePickerAttachment;
        source: Extract<
            GallerySourceFeature,
            'postFilePicker' | 'screenFilePicker' | 'linkedPostFilePicker'
        >;
    }>(),
);

export const updateSurveyQuestionsDefinitionPosts = createAction(
    PostActionType.UpdateSurveyQuestionsDefinitionPosts,
    props<{
        posts: IBasePost[];
    }>(),
);

export const updateSurveyQuestionsDefinition = createAction(
    PostActionType.UpdateSurveyQuestionsDefinition,
    props<{
        postId: number;
        fieldId: number;
        enumValues: ICatalogEntry[] | undefined;
    }>(),
);

export const setTimeRelatedStateUpdateTimeout = createAction(
    PostActionType.SetTimeRelatedStateUpdateTimeout,
    props<{
        post: SurveyPost | IEventPost;
    }>(),
);

export const updateTimeRelatedState = createAction(
    PostActionType.UpdateTimeRelatedState,
    props<{
        postId: number;
    }>(),
);

export const exportSurveyPostAnswersCsv = createAction(
    PostActionType.ExportSurveyPostAnswersCsv,
    props<{
        postId: number;
        postTitle: string;
    }>(),
);

export const closeSurvey = createAction(
    PostActionType.CloseSurvey,
    props<{ postId: number; occToken: number; surveyType: SurveyType }>(),
);

export const updateAllPosts = createAction(
    PostActionType.UpdateAllPosts,
    props<{
        posts: GenericPost[];
    }>(),
);

export const pruneAllPosts = createAction(
    PostActionType.PruneAllPosts,
    props<{
        keepPostIds: number[];
    }>(),
);

export const fetchPostsCapabilities = createAction(
    PostActionType.FetchPostsCapabilities,
    props<{
        postIds: number[];
    }>(),
);
