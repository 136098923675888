import { Index, mapArrayById } from '@interacta-shared/util';
import { GoogleDriveFileSerialize } from '@modules/core/models/google-drive/google-drive.model';
import {
    AttachmentStorageType,
    IAttachment,
    IAttachmentListEdit,
} from './attachment.model';

export const fromAttachmentsListEdit = (
    attachment: IAttachmentListEdit,
    withHashtag = true,
): IAttachmentListEdit => ({
    addAttachments: (attachment.addAttachments || []).map((a) =>
        fromAttachmentAdding(a, withHashtag),
    ),
    updateAttachments: (attachment.updateAttachments || [])
        .filter(
            (a) => !attachment.removeAttachmentIds?.find((id) => id === a.id),
        )
        .map((a) => fromAttachmentUpdating(a, withHashtag)),
    removeAttachmentIds: attachment.removeAttachmentIds,
    attachmentsUploadSessionId:
        attachment.attachmentsUploadSessionId ?? undefined,
});

export const fromAttachmentAddingBasicInfo = (attachment: IAttachment): any => {
    const record = {
        attachmentId: fromAttachmentId(attachment),
        name: attachment.name,
        contentRef: attachment.contentRef,
    };
    return record;
};

export const fromAttachmentAdding = (
    attachment: IAttachment,
    withHashtag = true,
    withType = true,
): any => {
    let record: any;
    if (attachment.isAttachmentRef) {
        record = {
            referencedAttachmentId: attachment.referencedAttachmentId,
        };
    } else {
        record = {
            ...fromAttachmentAddingBasicInfo(attachment),
            mimeType: attachment.contentMimeType,
            size: attachment.size,
        };
        if (attachment.type === AttachmentStorageType.DRIVE) {
            record.drive = attachment.drive
                ? GoogleDriveFileSerialize.addGoogleDriveFile(attachment.drive)
                : null;
        }
    }
    if (withType) {
        record.type = attachment.type;
    }
    if (withHashtag) {
        record.hashtagIds = mapArrayById(attachment.hashtags || []);
    }
    return record;
};

export const fromAttachmentUpdating = (
    attachment: IAttachment,
    withHashtag = true,
): any => {
    let record: any = {};
    if (attachment.isAttachmentRef) {
        record = {
            type: attachment.type,
            attachmentId: fromAttachmentId(attachment),
        };
    } else {
        record = {
            name: attachment.name,
            type: attachment.type,
            mimeType: attachment.contentMimeType,
            size: attachment.size,
            drive: attachment.drive
                ? GoogleDriveFileSerialize.addGoogleDriveFile(attachment.drive)
                : undefined,
        };
        if (attachment.inPending) {
            // new version
            record.attachmentId = attachment.id;
            record.versionName =
                attachment.versions?.[0]?.versionName ?? attachment.name;
            record.contentRef = attachment.contentRef;
        }
        if (attachment.inUpdating) {
            // update info
            record.attachmentId = attachment.id;
        }
    }

    if (withHashtag) {
        record.hashtagIds = mapArrayById(attachment.hashtags || []);
    }
    return record;
};

//export const fromAttachmentFilterMimeType =

export const fromAttachmentFilePicker = (attachment: IAttachment): any => ({
    attachmentId: fromAttachmentId(attachment),
    type: attachment.type,
    name: attachment.name,
    versionName: attachment.versionName,
    referencedAttachmentId: null,
    contentRef: attachment.contentRef,
    mimeType: attachment.contentMimeType,
    size: attachment.size,
    hashtagIds: mapArrayById(attachment.hashtags || []),
    drive:
        attachment.type === AttachmentStorageType.DRIVE && attachment.drive
            ? GoogleDriveFileSerialize.addGoogleDriveFile(attachment.drive)
            : null,
});

const fromAttachmentId = (attachment: IAttachment): Index | null =>
    ((attachment.versionNumber || 1) > 1 && attachment.inPending) ||
    !attachment.isDraft
        ? attachment.id
        : null;
