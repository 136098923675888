import { PrivateEmailVerificationMode } from '@interacta-shared/data-access-auth';
import {
    toLanguage,
    toTimezone,
} from '@interacta-shared/data-access-configuration';
import {
    IList,
    lastLoading,
    PaginatedList,
    regularLoading,
} from '@interacta-shared/util';
import { i18nString } from '@modules/core';
import { Tag } from './tag/tag.model';
import { IUser, SharedProfile, UserDeserilize } from './user.model';

export interface IUsersGroup {
    id: number;
    fullName: string;
    tags?: Tag[];
    occToken?: number;
    membersCount?: number;
    description?: string | null;
    deleted?: boolean;
}

export interface IRole {
    id: number;
    name: i18nString;
    deleted: boolean;
}

export interface IOperativeRole extends IRole {
    communityAssociation: boolean;
}

export interface IAdministrator extends IUser {
    roles: IAssociationRole[];
}
export interface IAdministratorsGroup extends IUsersGroup {
    roles: IAssociationRole[];
}
export interface IUserRole extends IUser {
    roles: IAssociationRole[];
}

export interface IUserGroupRole extends IUsersGroup {
    roles: IAssociationRole[];
}
export interface IAssociationRole {
    roleAssociationId: number;
    role: IRole;
}
export interface IUserAction extends IUser {
    timestamp: Date | null;
}

export enum ProfilePartialPageType {
    PROFILE = 'profile',
    MY_POSTS = 'myPosts',
    POST_FOLLOWED = 'postFollowed',
    SETTINGS = 'settings',
}

export interface IPropertyDescription {
    id: number;
    name: string;
}

export interface IUserInfoRow {
    label: string;
    key: string;
    canEditValue: boolean;
    canEditVisibility?: boolean;
    values?: any[];
}

export class UserExtendedDeserialize {
    public static usersGroup = (record: any): IUsersGroup => ({
        id: record.id,
        fullName: record.name,
        tags: record.tags ?? [],
        occToken: record.occToken ?? 0,
        description: record.description,
    });

    public static role = (record: any): IRole => ({
        id: record.id,
        name: record.name,
        deleted: record.deleted ?? false,
    });

    public static operativeRole = (record: any): IOperativeRole => ({
        id: record.id,
        name: record.name,
        communityAssociation: record.communityAssociation ?? false,
        deleted: record.deleted ?? false,
    });

    public static roleList = (record: any): IList<IRole> => ({
        list: record.items.map(UserExtendedDeserialize.role),
        nextPageToken: record.nextPageToken,
        totalCount: record.totalItemsCount,
    });

    public static operationalRoleList = (
        record: any,
    ): IList<IOperativeRole> => ({
        list: record.items.map(UserExtendedDeserialize.operativeRole),
        nextPageToken: record.nextPageToken,
        totalCount: record.totalItemsCount,
    });

    public static propertyDescription = (
        record: any,
    ): IPropertyDescription => ({
        id: record.id,
        name: record.name,
    });

    public static userProfileDetails = (record: any): SharedProfile => ({
        ...UserDeserilize.userDetails(record),
        contactEmail: record.contactEmail,
        phone: record.phone,
        language: record.language ? toLanguage(record.language) : null,
        timezone: record.timezone ? toTimezone(record.timezone) : null,
        place: record.place,
        area: record.area
            ? UserExtendedDeserialize.propertyDescription(record.area)
            : null,
        businessUnit: record.businessUnit
            ? UserExtendedDeserialize.propertyDescription(record.businessUnit)
            : null,
        role: record.role,
        mobilePhone: record.mobilePhone,
        internalPhone: record.internalPhone,
        biography: record.biography,
        canManageProfilePhoto: record.canManageProfilePhoto,
        manager: record.manager
            ? [UserDeserilize.userDetails(record.manager)]
            : [],
        employees: record.employees
            ? (<Array<any>>record.employees).map(UserDeserilize.userDetails)
            : [],
        followedByMe: record.followedByMe,
        occToken: record.occToken,
        deleted: record.deleted,
        highResAccountPhotoUrl: record.highResAccountPhotoUrl,
        editProfile: record.editProfile,
        privateEmail: record.privateEmail,
        emailNotificationsEnabled: record.emailNotificationsEnabled,
        privateEmailVerified: record.privateEmailVerified,
        removePhotoProfile: false,
        profilePhotoContentRef: '',
    });

    public static administrators = (record: any): IAdministrator[] => {
        const administrators: IAdministrator[] = [];
        record.forEach((item: any) => {
            const alreadyAdded = administrators.find(
                (a) => a.id === item.user.id,
            );
            if (alreadyAdded) {
                alreadyAdded.roles.push(
                    UserExtendedDeserialize.associationAdminRole(item),
                );
            } else {
                administrators.push(
                    UserExtendedDeserialize.administrator(item),
                );
            }
        });
        return administrators;
    };

    public static administratorsGroups = (
        record: any,
    ): IAdministratorsGroup[] => {
        const administrators: IAdministratorsGroup[] = [];
        record.forEach((item: any) => {
            const alreadyAdded = administrators.find(
                (a) => a.id === item.group.id,
            );
            if (alreadyAdded) {
                alreadyAdded.roles.push(
                    UserExtendedDeserialize.associationAdminRole(item),
                );
            } else {
                administrators.push(
                    UserExtendedDeserialize.administratorsGroup(item),
                );
            }
        });
        return administrators;
    };

    public static associationAdminRole = (record: any): IAssociationRole => ({
        roleAssociationId: record.roleAssociationId,
        role: UserExtendedDeserialize.role(record.role),
    });

    public static administrator = (record: any): IAdministrator => {
        const administrator: IAdministrator = <IAdministrator>(
            UserDeserilize.userDetails(record.user)
        );
        administrator.roles =
            record.roles?.length > 0
                ? record.roles.map((role: any) =>
                      UserExtendedDeserialize.associationAdminRole(role),
                  )
                : [UserExtendedDeserialize.associationAdminRole(record)];
        return administrator;
    };

    public static administratorsGroup = (record: any): IAdministratorsGroup => {
        const administratorsGroup: IAdministratorsGroup = <
            IAdministratorsGroup
        >UserExtendedDeserialize.usersGroup(record.group);
        administratorsGroup.roles =
            record.roles?.length > 0
                ? record.roles.map((role: any) =>
                      UserExtendedDeserialize.associationAdminRole(role),
                  )
                : [UserExtendedDeserialize.associationAdminRole(record)];
        return administratorsGroup;
    };

    public static userList = (record: any): IList<IUser> => ({
        list: (record?.items || []).map(UserDeserilize.userDetails),
        nextPageToken: record.nextPageToken,
    });

    public static usersGroupList = (record: any): IList<IUsersGroup> => ({
        list: (record?.items || []).map(UserExtendedDeserialize.usersGroup),
        nextPageToken: record.nextPageToken,
    });

    public static administratorList = (record: any): IList<IAdministrator> => ({
        list:
            record && record.items
                ? UserExtendedDeserialize.administrators(record.items)
                : [],
        nextPageToken: record.nextPageToken,
    });

    public static administratorsGroupList = (
        record: any,
    ): IList<IAdministratorsGroup> => ({
        list:
            record && record.items
                ? UserExtendedDeserialize.administratorsGroups(record.items)
                : [],
        nextPageToken: record.nextPageToken,
    });

    public static likersList = (record: any): PaginatedList<IUserAction> => ({
        list:
            record && record.items
                ? record.items.map(UserExtendedDeserialize.liker)
                : [],
        nextPageToken: record.nextPageToken
            ? regularLoading(record.nextPageToken)
            : lastLoading(),
        totalCount: record.totalItemsCount,
        isFetching: false,
    });

    public static viewersList = (record: any): PaginatedList<IUserAction> => ({
        list:
            record && record.items
                ? record.items.map(UserExtendedDeserialize.viewer)
                : [],
        nextPageToken: record.nextPageToken
            ? regularLoading(record.nextPageToken)
            : lastLoading(),
        totalCount: record.totalItemsCount,
        isFetching: false,
    });

    public static participantsList = (
        record: any,
    ): PaginatedList<IUserAction> => ({
        list:
            record && record.items
                ? record.items.map(UserExtendedDeserialize.participant)
                : [],
        nextPageToken: record.nextPageToken
            ? regularLoading(record.nextPageToken)
            : lastLoading(),
        totalCount: record.totalItemsCount,
        isFetching: false,
    });

    public static liker = (record: any): IUserAction => {
        const liker = <IUserAction>UserDeserilize.userDetails(record.user);
        liker.timestamp = record.likeTimestamp
            ? new Date(record.likeTimestamp)
            : null;
        return liker;
    };

    public static viewer = (record: any): IUserAction => {
        const viewer = <IUserAction>UserDeserilize.userDetails(record.user);
        viewer.timestamp = record.viewedTimestamp
            ? new Date(record.viewedTimestamp)
            : null;
        return viewer;
    };

    public static participant = (record: any): IUserAction => {
        const participant = <IUserAction>(
            UserDeserilize.userDetails(record.user)
        );
        participant.timestamp = record.partecipateTimestamp
            ? new Date(record.partecipateTimestamp)
            : null;
        return participant;
    };
}
export class UserExtendedSerialize {
    public static usersRolesCollection = (
        users: { id: number; roles: IAssociationRole[] }[] = [],
    ): any => {
        const record: any = [];
        (users || []).forEach((user) => {
            (user.roles || []).forEach((r) => {
                record.push({
                    userId: user.id,
                    roleId: r.role.id,
                });
            });
        });
        return record;
    };
    public static usersGroupsRolesCollection = (
        usersGroups: { id: number; roles: IAssociationRole[] }[] = [],
    ): any => {
        const record: any = [];
        (usersGroups || []).forEach((userGroup) => {
            (userGroup.roles || []).forEach((r) => {
                record.push({
                    groupId: userGroup.id,
                    roleId: r.role.id,
                });
            });
        });
        return record;
    };

    /*
     * profilePhoto = null  -> do nothing
     * profilePhoto = { newContentRef: null } -> remove prev photo
     * profilePhoto = { newContentRef: string } -> add/replace photo
     */
    public static customPhotoProfile = (customCredentials: {
        profilePhotoContentRef: any;
        removePhotoProfile: boolean;
    }): any => {
        return customCredentials.profilePhotoContentRef === undefined &&
            customCredentials.removePhotoProfile === undefined
            ? null
            : {
                  newContentRef: customCredentials.removePhotoProfile
                      ? null
                      : customCredentials.profilePhotoContentRef,
              };
    };

    public static userProfile = (user: SharedProfile): any => ({
        privateEmail:
            user.privateEmail?.trim().length ?? 0 > 0
                ? user.privateEmail
                : null,
        biography: user.biography,
        phone: user.phone,
        internalPhone: user.internalPhone,
        mobilePhone: user.mobilePhone,
        role: user.role,
        profilePhoto: UserExtendedSerialize.customPhotoProfile({
            profilePhotoContentRef: user.profilePhotoContentRef,
            removePhotoProfile: user.removePhotoProfile,
        }),
        occToken: user.occToken,
        privateEmailVerificationMode: PrivateEmailVerificationMode.OTP,
    });

    public static userBiography = (user: SharedProfile): any => ({
        biography: user.biography,
        occToken: user.occToken,
    });
}
