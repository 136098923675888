import { FieldType } from '../custom-metadata/custom-metadata.model';
import { fieldTypeToString } from '../custom-metadata/custom-metadata.utils';
import {
    MetadataActivation,
    MetadataCondition,
    MetadataConditionServerType,
    MetadataConditionType,
} from './custom-metadata-activation.model';
import { metadataConditionServerTypeToString } from './custom-metadata-activation.utils';

const everyFieldType = Object.values(FieldType).filter(
    (x) => typeof x !== 'string',
) as FieldType[];

function warn(
    conditionType: MetadataConditionServerType,
    fieldType: FieldType,
    admissibleFieldTypes: FieldType[] = everyFieldType,
) {
    const sFieldType = fieldTypeToString(fieldType);
    const sCondition = metadataConditionServerTypeToString(conditionType);
    const sAdmissibleConds = admissibleFieldTypes
        .map(fieldTypeToString)
        .join(', ');

    console.warn(
        `Inadmissible fieldType (${sFieldType}) for metadata conditionType (${sCondition}). Expected one of: [${sAdmissibleConds}]`,
    );
}

function empty(fieldType: FieldType): MetadataCondition {
    switch (fieldType) {
        case FieldType.TEXT:
        case FieldType.STRING:
            return { type: MetadataConditionType.TextEmpty };
        case FieldType.SELECT_MULTIPLE_VALUES:
        case FieldType.GENERIC_LIST:
            return { type: MetadataConditionType.ListEmpty };
        case FieldType.HTML:
            warn(MetadataConditionServerType.Empty, FieldType.HTML);
            return { type: MetadataConditionType.GenericEmpty };
        default:
            return { type: MetadataConditionType.GenericEmpty };
    }
}

function notEmpty(fieldType: FieldType): MetadataCondition {
    switch (fieldType) {
        case FieldType.TEXT:
        case FieldType.STRING:
            return { type: MetadataConditionType.TextNotEmpty };
        case FieldType.SELECT_MULTIPLE_VALUES:
        case FieldType.GENERIC_LIST:
            return { type: MetadataConditionType.ListNotEmpty };
        case FieldType.HTML:
            warn(MetadataConditionServerType.NotEmpty, FieldType.HTML);
            return { type: MetadataConditionType.GenericNotEmpty };
        default:
            return { type: MetadataConditionType.GenericNotEmpty };
    }
}

function contains(
    fieldType: FieldType,
    parameter: unknown,
): MetadataCondition | undefined {
    switch (fieldType) {
        case FieldType.TEXT:
        case FieldType.STRING:
            return {
                type: MetadataConditionType.TextContains,
                parameter: parameter as string,
            };
        case FieldType.SELECT_MULTIPLE_VALUES:
        case FieldType.GENERIC_LIST:
            return {
                type: MetadataConditionType.ListContains,
                parameter: parameter as number[],
            };
        default:
            warn(MetadataConditionServerType.Contains, fieldType, [
                FieldType.TEXT,
                FieldType.STRING,
                FieldType.SELECT_MULTIPLE_VALUES,
                FieldType.GENERIC_LIST,
            ]);
    }
}

function notContains(
    fieldType: FieldType,
    parameter: unknown,
): MetadataCondition | undefined {
    switch (fieldType) {
        case FieldType.TEXT:
        case FieldType.STRING:
            return {
                type: MetadataConditionType.TextNotContains,
                parameter: parameter as string,
            };
        case FieldType.SELECT_MULTIPLE_VALUES:
        case FieldType.GENERIC_LIST:
            return {
                type: MetadataConditionType.ListNotContains,
                parameter: parameter as number[],
            };
        default:
            warn(MetadataConditionServerType.NotContains, fieldType, [
                FieldType.TEXT,
                FieldType.STRING,
                FieldType.SELECT_MULTIPLE_VALUES,
                FieldType.GENERIC_LIST,
            ]);
    }
}

function startsWith(
    fieldType: FieldType,
    parameter: unknown,
): MetadataCondition | undefined {
    switch (fieldType) {
        case FieldType.TEXT:
        case FieldType.STRING:
            return {
                type: MetadataConditionType.TextStartsWith,
                parameter: parameter as string,
            };
        default:
            warn(MetadataConditionServerType.StartsWith, fieldType, [
                FieldType.TEXT,
                FieldType.STRING,
            ]);
    }
}

function endsWith(
    fieldType: FieldType,
    parameter: unknown,
): MetadataCondition | undefined {
    switch (fieldType) {
        case FieldType.TEXT:
        case FieldType.STRING:
            return {
                type: MetadataConditionType.TextEndsWith,
                parameter: parameter as string,
            };
        default:
            warn(MetadataConditionServerType.EndsWith, fieldType, [
                FieldType.TEXT,
                FieldType.STRING,
            ]);
    }
}

function equal(fieldType: FieldType, parameter: unknown): MetadataCondition {
    switch (fieldType) {
        case FieldType.SELECT_MULTIPLE_VALUES:
        case FieldType.GENERIC_LIST:
            return {
                type: MetadataConditionType.ListEquals,
                parameter: parameter as number[],
            };
        case FieldType.SELECT:
        case FieldType.SELECT_HIERARCHICAL:
            return {
                type: MetadataConditionType.ListEquals,
                parameter: [parameter as number],
            };
        case FieldType.DATE:
            return {
                type: MetadataConditionType.DateEquals,
                parameter: new Date(parameter as string),
            };
        case FieldType.DATETIME:
            return {
                type: MetadataConditionType.DatetimeEquals,
                parameter: new Date(parameter as string),
            };
        case FieldType.HTML:
            warn(MetadataConditionServerType.Equal, FieldType.HTML);
            return {
                type: MetadataConditionType.GenericEquals,
                parameter: parameter,
            };
        default:
            return {
                type: MetadataConditionType.GenericEquals,
                parameter: parameter,
            };
    }
}

function notEqual(fieldType: FieldType, parameter: unknown): MetadataCondition {
    switch (fieldType) {
        case FieldType.SELECT_MULTIPLE_VALUES:
        case FieldType.GENERIC_LIST:
            return {
                type: MetadataConditionType.ListNotEquals,
                parameter: parameter as number[],
            };
        case FieldType.DATE:
            return {
                type: MetadataConditionType.DateNotEquals,
                parameter: new Date(parameter as string),
            };
        case FieldType.DATETIME:
            return {
                type: MetadataConditionType.DatetimeNotEquals,
                parameter: new Date(parameter as string),
            };
        case FieldType.HTML:
            warn(MetadataConditionServerType.NotEqual, FieldType.HTML);
            return {
                type: MetadataConditionType.GenericNotEquals,
                parameter: parameter,
            };
        default:
            return {
                type: MetadataConditionType.GenericNotEquals,
                parameter: parameter,
            };
    }
}

function lessThan(
    fieldType: FieldType,
    parameter: unknown,
): MetadataCondition | undefined {
    switch (fieldType) {
        case FieldType.INT:
        case FieldType.BIGINT:
        case FieldType.DECIMAL:
        case FieldType.RATING:
            return {
                type: MetadataConditionType.NumberLessThan,
                parameter: parameter as number,
            };
        case FieldType.DATE:
            return {
                type: MetadataConditionType.DateLessThan,
                parameter: new Date(parameter as string),
            };
        case FieldType.DATETIME:
            return {
                type: MetadataConditionType.DatetimeLessThan,
                parameter: new Date(parameter as string),
            };
        default:
            warn(MetadataConditionServerType.LessThan, fieldType, [
                FieldType.INT,
                FieldType.BIGINT,
                FieldType.DECIMAL,
                FieldType.RATING,
                FieldType.DATE,
                FieldType.DATETIME,
            ]);
    }
}

function greaterThan(
    fieldType: FieldType,
    parameter: unknown,
): MetadataCondition | undefined {
    switch (fieldType) {
        case FieldType.INT:
        case FieldType.BIGINT:
        case FieldType.DECIMAL:
        case FieldType.RATING:
            return {
                type: MetadataConditionType.NumberGreaterThan,
                parameter: parameter as number,
            };
        case FieldType.DATE:
            return {
                type: MetadataConditionType.DateGreaterThan,
                parameter: new Date(parameter as string),
            };
        case FieldType.DATETIME:
            return {
                type: MetadataConditionType.DatetimeGreaterThan,
                parameter: new Date(parameter as string),
            };
        default:
            warn(MetadataConditionServerType.GreaterThan, fieldType, [
                FieldType.INT,
                FieldType.BIGINT,
                FieldType.DECIMAL,
                FieldType.RATING,
                FieldType.DATE,
                FieldType.DATETIME,
            ]);
    }
}

function between(
    fieldType: FieldType,
    parameter: { from: any; to: any },
): MetadataCondition | undefined {
    switch (fieldType) {
        case FieldType.INT:
        case FieldType.BIGINT:
        case FieldType.DECIMAL:
        case FieldType.RATING:
            return {
                type: MetadataConditionType.GenericBetween,
                from: parameter.from,
                to: parameter.to,
            };
        case FieldType.DATE:
        case FieldType.DATETIME:
            return {
                type: MetadataConditionType.GenericBetween,
                from: new Date(parameter.from as string),
                to: new Date(parameter.to as string),
            };
        default:
            warn(MetadataConditionServerType.Between, fieldType, [
                FieldType.INT,
                FieldType.BIGINT,
                FieldType.DECIMAL,
                FieldType.RATING,
                FieldType.DATE,
                FieldType.DATETIME,
            ]);
    }
}

function in_(
    fieldType: FieldType,
    parameter: number[],
): MetadataCondition | undefined {
    switch (fieldType) {
        case FieldType.GENERIC_LIST:
        case FieldType.SELECT_HIERARCHICAL:
        case FieldType.SELECT:
        case FieldType.SELECT_MULTIPLE_VALUES:
            return {
                type: MetadataConditionType.ListIn,
                parameter,
            };
        default:
            warn(MetadataConditionServerType.In, fieldType, [
                FieldType.GENERIC_LIST,
                FieldType.SELECT_HIERARCHICAL,
                FieldType.SELECT,
                FieldType.SELECT_MULTIPLE_VALUES,
            ]);
    }
}

function notIn_(
    fieldType: FieldType,
    parameter: number[],
): MetadataCondition | undefined {
    switch (fieldType) {
        case FieldType.GENERIC_LIST:
        case FieldType.SELECT_HIERARCHICAL:
        case FieldType.SELECT:
        case FieldType.SELECT_MULTIPLE_VALUES:
            return {
                type: MetadataConditionType.ListNotIn,
                parameter,
            };
        default:
            warn(MetadataConditionServerType.NotIn, fieldType, [
                FieldType.GENERIC_LIST,
                FieldType.SELECT_HIERARCHICAL,
                FieldType.SELECT,
                FieldType.SELECT_MULTIPLE_VALUES,
            ]);
    }
}

function toActivationMetadataCondition(
    record: any,
    sourceFieldType: FieldType,
): MetadataCondition | undefined {
    const parameter: any = record.activationParameter;
    switch (record.activationTypeId) {
        case MetadataConditionServerType.Empty:
            return empty(sourceFieldType);
        case MetadataConditionServerType.NotEmpty:
            return notEmpty(sourceFieldType);
        case MetadataConditionServerType.Contains:
            return contains(sourceFieldType, parameter);
        case MetadataConditionServerType.NotContains:
            return notContains(sourceFieldType, parameter);
        case MetadataConditionServerType.StartsWith:
            return startsWith(sourceFieldType, parameter);
        case MetadataConditionServerType.EndsWith:
            return endsWith(sourceFieldType, parameter);
        case MetadataConditionServerType.Equal:
            return equal(sourceFieldType, parameter);
        case MetadataConditionServerType.NotEqual:
            return notEqual(sourceFieldType, parameter);
        case MetadataConditionServerType.LessThan:
            return lessThan(sourceFieldType, parameter);
        case MetadataConditionServerType.GreaterThan:
            return greaterThan(sourceFieldType, parameter);
        case MetadataConditionServerType.Between:
            return between(sourceFieldType, parameter);
        case MetadataConditionServerType.In:
            return in_(sourceFieldType, parameter);
        case MetadataConditionServerType.NotIn:
            return notIn_(sourceFieldType, parameter);
        default:
            console.warn(
                `Unhandled conditionServerType ${record.activationTypeId} in toActvationMetadata()`,
            );
            return undefined;
    }
}

export const toActivationMetadata = (
    record: any,
    sourceFieldType: FieldType,
): MetadataActivation => ({
    condition: toActivationMetadataCondition(record, sourceFieldType),
    sourceFieldId: record.activationFieldId,
});
