import {
    IList,
    PaginatedList,
    emptyPaginatedList,
    fetchPaginatedListSuccess,
    paginatedListFromArray,
    paginatedListFromIList,
    toPaginatedList,
} from '@interacta-shared/util';

export interface WindowPaginatedList<T> extends PaginatedList<T> {
    pageSize: number;
    currentPage: number;
}

export function emptyWindowPaginatedList<T>(
    totalCount = 0,
    pageSize = 50,
): WindowPaginatedList<T> {
    return {
        ...emptyPaginatedList(totalCount),
        pageSize,
        currentPage: -1,
    };
}

export function windowPaginatedListFromArray<T>(
    array: T[],
    pageSize?: number,
): WindowPaginatedList<T> {
    return {
        ...paginatedListFromArray(array),
        pageSize: pageSize ?? array.length,
        currentPage: 0,
    };
}

export function windowPaginatedListFromIList<T>(
    list: IList<T>,
    pageSize = 50,
): WindowPaginatedList<T> {
    return {
        ...paginatedListFromIList(list),
        pageSize,
        currentPage: 0,
    };
}

export function windowPaginatedListFromPaginatedList<T>(
    plist: PaginatedList<T>,
    pageSize = 50,
): WindowPaginatedList<T> {
    return {
        ...plist,
        pageSize,
        currentPage: 0,
    };
}

export function toWindowPaginatedList<T>(
    list: any,
    pageSize = 50,
): WindowPaginatedList<T> {
    return {
        ...toPaginatedList(list),
        pageSize,
        currentPage: 0,
    };
}

export function fetchWindowPaginatedList<T>(
    currList: WindowPaginatedList<T>,
    pageToken: string | undefined,
): WindowPaginatedList<T> {
    return {
        ...(pageToken
            ? currList
            : emptyWindowPaginatedList(currList.totalCount, currList.pageSize)),
        isFetching: true,
    };
}

export function fetchWindowPaginatedListSuccess<T>(
    currList: WindowPaginatedList<T>,
    nextList: WindowPaginatedList<T>,
): WindowPaginatedList<T> {
    return {
        ...fetchPaginatedListSuccess(currList, nextList),
        currentPage: currList.currentPage + 1,
    };
}

export function paginatedListRemoveById<
    K extends string | number,
    T extends { id: K },
>(plist: PaginatedList<T>, id: K): PaginatedList<T> {
    const originalLenght = plist.list.length;
    const list = plist.list.filter((item) => item.id !== id);
    return {
        ...plist,
        list,
        totalCount: plist.totalCount + list.length - originalLenght,
    };
}

export function paginatedListReplaceById<
    K extends string | number,
    T extends { id: K },
>(plist: PaginatedList<T>, item: T): PaginatedList<T> {
    return {
        ...plist,
        list: plist.list.map((d) => (d.id === item.id ? item : d)),
    };
}

export function getCurrentPageList<T>(plist: WindowPaginatedList<T>): T[] {
    return plist.currentPage >= 0
        ? plist.list.slice(
              plist.currentPage * plist.pageSize,
              Math.min(
                  (plist.currentPage + 1) * plist.pageSize,
                  plist.list.length,
              ),
          )
        : [];
}

export function nextPageAlreadyFetched<T>(
    plist: WindowPaginatedList<T>,
): boolean {
    return (
        plist.currentPage >= 0 &&
        plist.list.length > (plist.currentPage + 1) * plist.pageSize
    );
}
