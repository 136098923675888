import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Appearance, Palette, Size } from '@interacta-shared/ui';
import { Member } from '@modules/core/models/member/member.model';
import { MemberClickBehavior } from '../member/member.component';

@Component({
    selector: 'interacta-chip-member',
    templateUrl: './chip-member.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipMemberComponent {
    @Input({ required: true }) member!: Member;
    @Input() canDelete = false;
    @Input() size: Extract<Size, 'regular' | 'small' | 'extra-small'> = 'small';
    @Input() bgOnHover = false;
    @Input() appearance: Appearance = 'border';
    @Input() memberClickBehavior: MemberClickBehavior = 'preview';
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-A';
    @Input() showInfo = false;
    @Input() fullWidth = false;

    @Output() deleteMember = new EventEmitter<Member>();
    @Output() selectMember = new EventEmitter<Member>();
}
