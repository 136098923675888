import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { StorageFile } from '@interacta-shared/util-common';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageFileService {
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/core/storage`;
    private readonly http = inject(HttpClient);

    prepareNewStorageFile(): Observable<StorageFile> {
        return this.http.post<StorageFile>(
            `${this.baseUrl}/upload-new-attachment`,
            {},
        );
    }
}
