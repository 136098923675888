import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
} from '@angular/core';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';
import { Appearance, Palette, Size } from '../../model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[interacta-chip-button-v2]',
    templateUrl: './chip-button-v2.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, NgIf, IconComponent],
})
export class ChipButtonV2Component {
    @Input() icon?: InteractaIcon;
    @Input() label?: string;
    @Input() size: Extract<Size, 'regular' | 'small'> = 'regular';
    @Input() appearance: Extract<Appearance, 'fill' | 'ghost'> = 'fill';
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-A';

    constructor(public elementRef: ElementRef<HTMLElement>) {}
}
