<ng-container *ngIf="{ theme: theme$ | async } as $">
    <div
        class="flex items-center justify-between"
        [ngClass]="{ 'pl-28': withMarginLeft }"
    >
        <div class="ql-formats flex items-center space-x-8">
            <button
                class="ql-bold"
                type="button"
                [disabled]="disableButtons"
                [matTooltip]="'QUILL.TOOLBAR.BOLD' | translate"
            ></button>
            <button
                class="ql-italic"
                type="button"
                [disabled]="disableButtons"
                [matTooltip]="'QUILL.TOOLBAR.ITALIC' | translate"
            ></button>
            <button
                class="ql-underline"
                type="button"
                [disabled]="disableButtons"
                [matTooltip]="'QUILL.TOOLBAR.UNDERLINE' | translate"
            ></button>
            <button
                class="ql-list"
                type="button"
                value="ordered"
                [disabled]="disableButtons"
                [matTooltip]="'QUILL.TOOLBAR.LIST_ORDERED' | translate"
            ></button>
            <button
                class="ql-list"
                type="button"
                value="bullet"
                [disabled]="disableButtons"
                [matTooltip]="'QUILL.TOOLBAR.LIST_BULLET' | translate"
            ></button>
            <button
                class="ql-link"
                type="button"
                [disabled]="disableButtons"
                [matTooltip]="'QUILL.TOOLBAR.LINK' | translate"
            ></button>
            @if (userMentionEnabled) {
                <button
                    type="button"
                    [disabled]="disableButtons"
                    [matTooltip]="'QUILL.TOOLBAR.AT_MENTION' | translate"
                    (click)="mentionClick.emit('@')"
                >
                    <interacta-icon class="size-24" [icon]="'at'" />
                </button>
            }
            @if (tagEnabled) {
                <button
                    type="button"
                    [disabled]="disableButtons"
                    [matTooltip]="'QUILL.TOOLBAR.TAG_MENTION' | translate"
                    (click)="mentionClick.emit('#')"
                >
                    <interacta-icon class="size-24" [icon]="'tag'" />
                </button>
            }
            @if (tableEnabled) {
                <button
                    #tableSettingsButton
                    type="button"
                    [disabled]="disableButtons"
                    [matTooltip]="'QUILL.TOOLBAR.TABLE' | translate"
                    (click)="openTableSettings = true"
                >
                    <interacta-icon class="size-24" [icon]="'table'" />
                </button>
                @if (openTableSettings) {
                    <interacta-quill-table-settings-dialog
                        [isOpen]="openTableSettings"
                        (apply)="
                            applyTableSettings($event, tableSettingsButton)
                        "
                        (closing)="closeTableSettings(tableSettingsButton)"
                    />
                }
            }
            <button
                #openMenuBtn="cdkOverlayOrigin"
                cdkOverlayOrigin
                type="button"
                [disabled]="disableButtons"
                [matTooltip]="'QUILL.TOOLBAR.EMOTICON' | translate"
                (click)="openEmojiPicker = true"
            >
                <interacta-icon class="size-24" [icon]="'emoticon'" />
            </button>

            @if (aiEnabled) {
                <button
                    type="button"
                    @aiButtonFade
                    [disabled]="disableButtons"
                    [matTooltip]="'QUILL.TOOLBAR.AI' | translate"
                    (click)="aiClick.emit()"
                >
                    <interacta-image
                        alt="ai logo"
                        [classes]="'size-24'"
                        [loading]="'eager'"
                        [src]="
                            $.theme === 'dark'
                                ? './assets/images-2.0/ai/ai-interacta-logo_dark.svg'
                                : './assets/images-2.0/ai/ai-interacta-logo_light.svg'
                        "
                    ></interacta-image>
                </button>
            }
        </div>
    </div>
    <interacta-menu
        positionX="after"
        positionY="below"
        [open]="openEmojiPicker"
        [origin]="openMenuBtn"
        (closing)="openEmojiPicker = false"
    >
        @if (openEmojiPicker) {
            <emoji-mart
                title=""
                [darkMode]="$.theme === 'dark'"
                [emojiTooltip]="true"
                [i18n]="i18n"
                [isNative]="true"
                [searchIcons]="searchIcons"
                [set]="''"
                [showPreview]="false"
                [style]="{ width: '20rem' }"
                [totalFrequentLines]="2"
                (emojiClick)="addEmoji.emit($event.emoji.native)"
            ></emoji-mart>
        }
    </interacta-menu>
</ng-container>
