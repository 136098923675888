<interacta-input-text-v2
    #input
    type="text"
    [bgColor]="bgColor"
    [control]="control"
    [fontSize]="fontSize"
    [icon]="control.value?.length ? 'close' : 'search-left'"
    [iconClickable]="iconClickable"
    [iconColor]="iconColor ?? (control.value?.length ? 'black' : 'primary')"
    [iconPosition]="iconPosition"
    [isReadonly]="isReadonly"
    [label]="label"
    (iconClicked)="iconClicked()"
></interacta-input-text-v2>
