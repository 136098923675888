import { Injectable } from '@angular/core';
import { Observable, Subject, filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ScrollTrakerService {
    private _scrollToTop$ = new Subject<string>();

    scrollToTop$(scrollTrakerId: string): Observable<string> {
        return this._scrollToTop$
            .asObservable()
            .pipe(filter((id) => id === scrollTrakerId));
    }

    scrollToTop(scrollTrakerId: string): void {
        this._scrollToTop$.next(scrollTrakerId);
    }
}
