import { Directive } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import {
    FormValidationService,
    IValidationError,
    IValidationErrorPayload,
} from '@interacta-shared/data-access-error';
import { TipService } from '@interacta-shared/feature-tip';
import { parseCustomServerValidationError } from '@modules/core/helpers/form-validation-errors.utils';

interface FormGroupControls {
    [key: string]: AbstractControl;
}

interface ValidationError {
    controlName: string;
    errorName: string;
    errorValue: any;
}

export type DataFormControls = { [key: string]: AbstractControl };
export abstract class DataFormControlsValidators {
    abstract setValidators(...args: any[]): void;

    setValidatorsServerSide(error: IValidationErrorPayload): void {
        if ((error?.validationErrors?.list?.length ?? 0) > 0) {
            (error.validationErrors.list || []).forEach((validationError) => {
                let refError: ValidationErrors;
                if (
                    (refError =
                        parseCustomServerValidationError(validationError))
                ) {
                    if (validationError.field in this) {
                        this.controls[validationError.field].setErrors(
                            refError,
                        );
                    } else {
                        this.setValidatorCustom(validationError, refError);
                    }
                }
            });
        }
    }

    protected setValidatorCustom(
        validationError: IValidationError,
        refError: ValidationErrors,
    ): void {
        console.log(
            'setValidatorCustom default implementation ',
            validationError,
        );
    }

    protected updateValidator(
        prop: AbstractControl,
        validators: ValidatorFn | ValidatorFn[] | null,
    ): void {
        prop.setValidators(validators);
        prop.updateValueAndValidity();
    }

    protected get controls(): FormGroupControls {
        return (<unknown>this) as FormGroupControls;
    }
}

@Directive()
export abstract class FormServiceAbstract<
    T,
    V extends DataFormControlsValidators,
> {
    readonly form: UntypedFormGroup;
    readonly dataFormControls: V;

    constructor(
        _dataFormControls: DataFormControlsValidators,
        protected tipService: TipService,
        protected fb: UntypedFormBuilder,
        protected formErrorsService: FormValidationService,
    ) {
        this.dataFormControls = _dataFormControls as V;

        const controls: DataFormControls = {};

        Object.entries(this.dataFormControls)
            .filter((entry) => entry[1] instanceof AbstractControl)
            .forEach((entry) => (controls[entry[0]] = entry[1]));

        this.form = this.fb.group(controls);
    }

    updateFormValues(data: T): void {
        if (data) {
            // update form fields in dynamic way
            Object.keys(data).forEach((key) => {
                // if input exists and not have already set value (default value only if data[key] hasn't value)
                if (key in this.form.controls) {
                    const object = {};
                    object[key] =
                        data && data[key] != undefined
                            ? data[key]
                            : this.form.controls[key].value;
                    this.form.patchValue(object);
                }
            });
        }
    }

    /*
     * Only the first layer form group is resolved
     */
    mapDataFromForm(): T {
        const data = {} as T;
        Object.keys(this.form.controls).forEach(
            (key) => (data[key] = this.form.controls[key].value),
        );
        return data;
    }

    markFormGroupTouched(formGroup: UntypedFormGroup): void {
        Object.keys(formGroup.controls).forEach((key) => {
            if (formGroup.controls[key] instanceof UntypedFormGroup) {
                this.markFormGroupTouched(
                    formGroup.controls[key] as UntypedFormGroup,
                );
            }
            formGroup.controls[key].markAsTouched();
        });
    }

    checkValidity(noMessage?: boolean): boolean {
        this.markFormGroupTouched(this.form);
        if (!this.form.valid) {
            const errors = this.getFormValidationErrors(this.form.controls);

            this.formErrorsService.emitErrors(errors);

            const message = errors.every((e) => e.errorName === 'required')
                ? 'SHARED.ERROR.FORM_REQUIRED'
                : 'SHARED.ERROR.INVALID_FORM';

            noMessage || this.tipService.warn(message);

            return false;
        }

        return true;
    }

    checkValidityFormArray(formArray: UntypedFormArray): void {
        formArray.controls.forEach((formAdmin: UntypedFormGroup) => {
            Object.keys(formAdmin.controls).forEach((key) => {
                formAdmin.controls[key].markAsDirty();
                formAdmin.controls[key].markAsTouched();
                formAdmin.controls[key].updateValueAndValidity({
                    onlySelf: true,
                });
            });
        });
    }

    reset(): void {
        this.form.reset();
        this.resetAllErrors();
    }

    resetAllErrors(): void {
        Object.keys(this.form.controls).forEach((key) => {
            if (this.form.get(key) instanceof UntypedFormControl) {
                this.resetFormControlErrors(
                    this.form.get(key) as UntypedFormControl,
                );
            } else if (this.form.get(key) instanceof UntypedFormGroup) {
                this.resetFormGroupErrors(
                    this.form.get(key) as UntypedFormGroup,
                );
            }
        });
    }

    resetFormControlErrors(control: UntypedFormControl): void {
        control.setErrors(null);
    }

    resetFormGroupErrors(control: UntypedFormGroup): void {
        Object.keys(control.controls).forEach((_key) => {
            if (control.controls[_key] instanceof UntypedFormGroup) {
                this.resetFormGroupErrors(
                    control.controls[_key] as UntypedFormGroup,
                );
            }
            this.resetFormControlErrors(
                control.controls[_key] as UntypedFormControl,
            );
        });
    }

    /**
     * Returns every validation errors of the controls contained in the form,
     * also considering every nested FormGroup inside the controls.
     */
    protected getFormValidationErrors(
        controls: FormGroupControls,
    ): ValidationError[] {
        let errors: ValidationError[] = [];
        for (const key of Object.keys(controls)) {
            const control = controls[key];
            if (control instanceof UntypedFormGroup) {
                const formGroupErrors = this.getFormValidationErrors(
                    control.controls,
                ).map((e) => ({
                    ...e,
                    controlName: `${key} ${e.controlName}`,
                }));
                errors = [...errors, ...formGroupErrors];
            }
            const controlErrors = control.errors;
            if (controlErrors !== null) {
                for (const keyError of Object.keys(controlErrors)) {
                    errors.push({
                        controlName: key,
                        errorName: keyError,
                        errorValue: controlErrors[keyError],
                    });
                }
            }
        }
        return errors;
    }
}
