import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { openHeightAnimation } from '@interacta-shared/ui';
import { PaginatedList } from '@interacta-shared/util';
import { IUserAction } from '@modules/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'interacta-dialog-comment-likers[likers]',
    templateUrl: './dialog-comment-likers.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [openHeightAnimation('users', '*')],
})
export class DialogCommentLikersComponent implements OnInit, OnDestroy {
    @Input() likers!: PaginatedList<IUserAction>;
    @Input() unfilteredTotalCount!: number;

    @Output() searchedUsers: EventEmitter<string> = new EventEmitter();

    searchUsers = new UntypedFormControl('');

    destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.searchUsers.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe((filterByName: string) => {
                this.searchedUsers.emit(filterByName);
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
