import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {
    VirtualScrollerComponent,
    VirtualScrollerModule,
} from '@iharbeck/ngx-virtual-scroller';
import {
    EMPTY_SEARCH_VALUE,
    PaginatedList,
    emptyPaginatedList,
    getNextPageToken,
    isDefined,
    paginatedListFromArray,
} from '@interacta-shared/util';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    startWith,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { ScrollTrackerDirective } from '../../directives/scroll-tracker.directive';
import {
    CheckboxState,
    InputSelectItem,
    InputSelectSearchEvent,
    ScrollTrackerEvent,
} from '../../model';
import { InputStatePipe } from '../../pipes/input-state.pipe';
import { CheckboxGroupIndeterminateComponent } from '../checkbox-group-indeterminate/checkbox-group-indeterminate.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { ChipComponent } from '../chip/chip.component';
import { InputMultipleSelectLabelItemComponent } from '../input-multiple-select-label-item/input-multiple-select-label-item.component';
import { InputSearchComponent } from '../input-search/input-search.component';
import { LoadMoreComponent } from '../load-more/load-more.component';

@Component({
    selector: 'interacta-input-multiple-select-indeterminate',
    templateUrl: './input-multiple-select-indeterminate.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        InputSearchComponent,
        ScrollTrackerDirective,
        NgFor,
        ChipComponent,
        CheckboxGroupIndeterminateComponent,
        ReactiveFormsModule,
        CheckboxComponent,
        InputMultipleSelectLabelItemComponent,
        VirtualScrollerModule,
        LoadMoreComponent,
        AsyncPipe,
        TranslateModule,
        InputStatePipe,
    ],
})
export class InputMultipleSelectIndeterminateComponent<
        T extends InputSelectItem,
    >
    implements OnInit, OnChanges, OnDestroy
{
    /** Contains { item: T, state: CheckboxState } */
    @Input() control!: UntypedFormControl;
    @Input() items: PaginatedList<T> | T[] = [];
    @Input() noResultLabel?: string;
    @Input() isReadonly = false;
    @Input() withChips = true;
    @Input() occurrences?: Record<
        number,
        { occ: number; state: CheckboxState }
    > = {};
    @Input() totalCount?: number;

    @Output() search = new EventEmitter<InputSelectSearchEvent>();

    readonly VIRTUAL_SCROLL_THRESHOLD = 15;
    EMPTY_SEARCH_VALUE = EMPTY_SEARCH_VALUE;

    _items: PaginatedList<T> = emptyPaginatedList();
    displayedItems: T[] = [];
    textSearchControl = new UntypedFormControl();
    canBeIndeterminate: Record<number, boolean> = {};
    chipItems$?: Observable<T[]>;
    mappedArray: { item: number; state: CheckboxState }[] = [];

    private destroy$ = new Subject<void>();

    @ViewChild(VirtualScrollerComponent)
    virtualScroller?: VirtualScrollerComponent;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.textSearchControl.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                map((text) => (isDefined(text) ? text : '')),
                takeUntil(this.destroy$),
            )
            .subscribe((text: string) => this.filterItems(text));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['control']?.currentValue?.value?.length > 0) {
            (this.control.value || []).forEach(
                (obj: number | { item: T; state: CheckboxState }) => {
                    this.mappedArray.push({
                        item: typeof obj === 'number' ? obj : obj.item.id,
                        state:
                            typeof obj === 'number' || !obj.state
                                ? true
                                : obj.state,
                    });
                },
            );

            this.control.setValue(this.mappedArray);

            this.canBeIndeterminate = {};
            this.mappedArray.forEach(
                (h) =>
                    (this.canBeIndeterminate[h.item] =
                        h.state === 'indeterminate'),
            );
        }
        if (changes['items']) {
            this._items = Array.isArray(this.items)
                ? paginatedListFromArray(this.items)
                : this.items;

            if (this._items?.totalCount > 5) {
                this.chipItems$ = this.control.valueChanges.pipe(
                    startWith(this.control.value || []),
                    map(
                        (
                            ids: Array<{
                                item: number;
                                state: CheckboxState;
                            }> | null,
                        ) =>
                            this._items.list.filter((i) =>
                                ids?.some((id) => id.item === i.id),
                            ),
                    ),
                    tap((_) => this.virtualScroller?.refresh()),
                );
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    getState = (itemId: number): CheckboxState => {
        return this.control.value.find((h: any) => h.item === itemId)?.state;
    };

    iconClicked(): void {
        if (this.textSearchControl.value?.length > 0) {
            this.textSearchControl.setValue('');
        }
    }

    onScroll(event: ScrollTrackerEvent): void {
        if (event?.thresholdReached) {
            this.doSearch();
        }
    }

    doSearch(): void {
        if (
            getNextPageToken(this._items.nextPageToken) &&
            !this._items.isFetching
        ) {
            this.search.emit({
                text: this.textSearchControl.value,
                nextPageToken: getNextPageToken(this._items.nextPageToken),
            });
        }
    }

    removeItem(itemId: number | string): void {
        this.control.setValue(
            this.control.value.filter((v: any) => v.item !== itemId),
        );
    }

    private filterItems(filter: string): void {
        if (Array.isArray(this.items)) {
            this._items = {
                ...paginatedListFromArray(
                    this.items.filter(
                        (item) =>
                            !filter ||
                            item.label
                                .toLowerCase()
                                .includes(filter.toLowerCase()),
                    ),
                ),
                totalCount: this.items.length,
            };
            this.cdr.markForCheck();
        } else {
            this.search.emit({
                text: filter,
                nextPageToken: null,
            });
        }
    }
}
