<div
    class="relative rounded-full"
    matRipple
    [class]="size === 'extra-large' ? 'p-6' : 'p-4'"
    [matTooltip]="label"
    [matTooltipShowDelay]="500"
    [ngClass]="{ 'hover:bg-surface-300': !disabled }"
>
    <interacta-icon
        class="transition-colors"
        [icon]="icon"
        [ngClass]="{
            'h-16 w-16': size === 'extra-small',
            'h-24 w-24': size === 'regular',
            'h-32 w-32': size === 'extra-large',
            'text-text-primary': active,
            'text-text-disabled': !active && disabled,
            'text-text': !active && !disabled
        }"
    ></interacta-icon>
</div>
