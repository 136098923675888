<div
    [ngClass]="{
        'w-272': size === 'regular',
        'w-232': size === 'small',
        'w-full': size === 'extra-large'
    }"
>
    <interacta-input-text-v2
        #openMenu="cdkOverlayOrigin"
        cdkOverlayOrigin
        iconColor="black"
        iconPosition="right"
        [bgColor]="isReadonly ? 'default' : 'bg-card'"
        [control]="formattedDateTime"
        [fontSize]="size === 'small' ? 'small' : 'regular'"
        [icon]="
            !isReadonly && formattedDateTime.value && canClear
                ? 'close'
                : 'date'
        "
        [iconClickable]="!isReadonly && canClear"
        [inputClickable]="!isReadonly"
        [isReadonly]="true"
        (click)="!isReadonly && toggleMenu()"
        (iconClicked)="iconAction()"
    ></interacta-input-text-v2>

    <interacta-menu-date-time
        *ngIf="isMenuOpen"
        [control]="control"
        [editTimezone]="editTimezone"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [open]="isMenuOpen"
        [origin]="openMenu"
        [showFastSelect]="showFastSelect"
        [stepMode]="stepMode"
        [timezoneControl]="timezoneControl"
        [timezones]="timezones"
        [type]="type"
        [userTimezone]="userTimezone"
        (closing)="confirm()"
    >
    </interacta-menu-date-time>
</div>
