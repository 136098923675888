import { Index } from '@interacta-shared/util';
import { HomeCommunityIndex } from '@modules/core';
import { CallerLocation } from '@modules/dashboard/components/dashboard-filters-dialog/dashboard-filters-dialog.component';
import {
    AttachmentCategoryType,
    IAttachment,
} from '@modules/post/models/attachment/attachment.model';
import { QuickFilter } from '@modules/post/models/quick-filters/quick-filters.model';
import { createAction, props } from '@ngrx/store';

enum CommunityActionType {
    FetchAttachments = '[Community] Fetch Attachments',
    FetchAttachmentsPage = '[Community] Fetch Attachments Page',

    DeleteAttachments = '[Community] Delete Attachments',

    GetRecentCommunities = '[Community] Get Recent Communities',

    MassiveUploadHashtags = '[Community] Massive Upload Hashtags',

    ToggleAdvancedCommunityResearch = '[Community] Toggle Advanced community research',
    ToggleAdminV2Tab = '[Community] Toggle Admin V2 tab',

    FetchQuickFilters = '[Community] Fetch Quick Filters',
    SetActiveQuickFilter = '[Community] Set Active Quick Filter',
    ResetActiveQuickFilter = '[Community] Reset Active Quick Filter',

    SetInitializedQuickFilters = '[Community] Set Initialized Quick Filters',
    CreateCustomFilter = '[Community] Create Custom Filter',

    DeleteCustomFilter = '[Community] Delete Custom Filter',

    ModifyCustomFilter = '[Community] Modify Custom Filter',

    ActualizeQuickFilter = '[Community] Actualize Quick Filter',

    InitializeQuickFilters = '[Community] Initialize Quick Filters',

    ChangeQuickFiltersOrder = '[Community] Change Quick Filters Order',
}

export const fetchAttachments = createAction(
    CommunityActionType.FetchAttachments,
    props<{
        communityId: number;
        attachmentType: AttachmentCategoryType;
        pageSize: number;
    }>(),
);

export const fetchAttachmentsPage = createAction(
    CommunityActionType.FetchAttachmentsPage,
    props<{
        communityId: number;
        nextPageToken: string;
        attachmentType: AttachmentCategoryType;
        pageSize: number;
    }>(),
);

export const deleteAttachments = createAction(
    CommunityActionType.DeleteAttachments,
    props<{
        ids: IAttachment['id'][];
    }>(),
);

export const getRecentCommunities = createAction(
    CommunityActionType.GetRecentCommunities,
);

export const massiveUploadHashtags = createAction(
    CommunityActionType.MassiveUploadHashtags,
    props<{
        attachmentIds: Index[];
        addHashtagIds: number[];
        removeHashtagIds: number[];
    }>(),
);

export const toggleAdvancedCommunityResearch = createAction(
    CommunityActionType.ToggleAdvancedCommunityResearch,
);

export const toggleAdminV2Tab = createAction(
    CommunityActionType.ToggleAdminV2Tab,
);

export const fetchQuickFilters = createAction(
    CommunityActionType.FetchQuickFilters,
);

export const setActiveQuickFilter = createAction(
    CommunityActionType.SetActiveQuickFilter,
    props<{
        communityId: HomeCommunityIndex;
        quickFilter: QuickFilter | null;
        clearPreviousFilter?: boolean;
    }>(),
);

export const resetActiveQuickFilter = createAction(
    CommunityActionType.ResetActiveQuickFilter,
    props<{
        communityId: HomeCommunityIndex;
    }>(),
);

export const setInitializedQuickFilters = createAction(
    CommunityActionType.SetInitializedQuickFilters,
    props<{
        quickFilter: QuickFilter;
        communityId: HomeCommunityIndex;
    }>(),
);

export const createCustomFilter = createAction(
    CommunityActionType.CreateCustomFilter,
    props<{
        filter: QuickFilter;
        communityId: HomeCommunityIndex;
        callerLocation: CallerLocation;
    }>(),
);

export const deleteCustomFilter = createAction(
    CommunityActionType.DeleteCustomFilter,
    props<{ filterId: Index; homeCommunityIndex: HomeCommunityIndex }>(),
);

export const modifyCustomFilter = createAction(
    CommunityActionType.ModifyCustomFilter,
    props<{ filter: QuickFilter; homeCommunityIndex: HomeCommunityIndex }>(),
);

export const actualizeQuickFilter = createAction(
    CommunityActionType.ActualizeQuickFilter,
    props<{
        quickFilter: QuickFilter;
        homeCommunityIndex: HomeCommunityIndex;
    }>(),
);

export const changeQuickFiltersOrder = createAction(
    CommunityActionType.ChangeQuickFiltersOrder,
    props<{
        quickFilters: QuickFilter[];
        homeCommunityIndex: HomeCommunityIndex;
    }>(),
);

export const initializeQuickFilters = createAction(
    CommunityActionType.InitializeQuickFilters,
    props<{
        homeCommunityIndex: HomeCommunityIndex;
        initializeFilters: QuickFilter[];
    }>(),
);
