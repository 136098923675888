import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LayerService } from '@modules/shared-v2/services/layer.service';

@Component({
    selector: 'interacta-layer',
    templateUrl: './layer.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerComponent implements OnInit {
    constructor(public layerService: LayerService) {}

    ngOnInit(): void {}
}
