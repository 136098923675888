<interacta-form-field-v2
    *ngIf="{
        filteredData: filteredData$ | async
    } as $"
    class="block h-full overflow-y-auto pr-4"
    [control]="communityIdsControl"
    [description]="description"
    [name]="title"
>
    <div class="flex flex-col items-start justify-between gap-8" @searchRow>
        <interacta-input-search
            *ngIf="totalCommunitiesCount > N_COMMUNITIES_TO_SHOW_FILTER"
            class="typo-xs w-full"
            [control]="communityNameFilter"
            [iconPosition]="'right'"
        />

        @if (selectedCommunities() < minCommunities) {
            <p class="text-text-error typo-sm flex items-center gap-4">
                <interacta-icon class="inline size-24" [icon]="'warning'" />
                {{
                    minCommunities
                        | i18nPlural
                            : {
                                  '=1': 'DASHBOARD.SELECT_AT_LEAST_COMMUNITIES.COUNTER_SING',
                                  other: 'DASHBOARD.SELECT_AT_LEAST_COMMUNITIES.COUNTER_PLUR'
                              }
                        | translate: { counter: minCommunities }
                }}
            </p>
        } @else {
            <button
                interacta-button
                type="button"
                [bgColor]="'surface-A'"
                [disabled]="!selectedCommunities()"
                [icon]="
                    selectedCommunities() > 0
                        ? 'check-circle-undeterminate'
                        : undefined
                "
                [label]="
                    selectedCommunities()
                        | i18nPlural
                            : {
                                  '=1': 'DASHBOARD.SELECTED_COMMUNITIES.COUNTER_SING',
                                  other: 'DASHBOARD.SELECTED_COMMUNITIES.COUNTER_PLUR'
                              }
                        | translate: { counter: selectedCommunities() }
                "
                [size]="'regular'"
                (click)="clearAll()"
            ></button>
        }
    </div>

    <div class="text-text-low-contrast flex min-h-0 flex-col overflow-y-auto">
        <ng-container *ngFor="let workspace of $.filteredData; let last = last">
            <ng-container *ngIf="workspace.communities">
                <div
                    class="typo-xs-bold-uppercase flex w-full items-center justify-between py-4"
                >
                    <interacta-checkbox
                        *ngIf="enableMultiSelection"
                        [checked]="checkboxWsState[workspace.id].value"
                        (changeCheck)="
                            toggleWorkspace(workspace.id, $event === true)
                        "
                    >
                        <span
                            class="typo-xs-bold-uppercase hover:text-text-mid-contrast"
                            >{{ workspace.name }}
                        </span>
                    </interacta-checkbox>
                    <span
                        *ngIf="!enableMultiSelection"
                        class="typo-xs-bold-uppercase hover:text-text-mid-contrast"
                        >{{ workspace.name }}
                    </span>

                    <interacta-icon
                        class="h-16 w-16 cursor-pointer"
                        [icon]="
                            toggleOpenWs[workspace.id]
                                ? 'chevron-big-up'
                                : 'chevron-big-down'
                        "
                        (click)="accordionWs(workspace.id)"
                    >
                    </interacta-icon>
                </div>

                <ng-container *ngFor="let community of workspace.communities">
                    <div
                        *ngIf="toggleOpenWs[workspace.id]"
                        class="typo-xs-bold mt-4 pl-32"
                    >
                        <interacta-checkbox
                            [checked]="
                                checkboxCommunitiesState[community.id].value
                            "
                            [variant]="
                                enableMultiSelection ? 'square' : 'circle'
                            "
                            (changeCheck)="
                                toggleCommunities(
                                    community.id,
                                    workspace.id,
                                    $event === true
                                )
                            "
                        >
                            <span
                                class="typo-xs-bold hover:text-text-mid-contrast line-clamp-1"
                                >{{ community.name }}</span
                            >
                        </interacta-checkbox>
                    </div>
                </ng-container>
            </ng-container>
            <interacta-separator
                *ngIf="!last"
                class="my-8"
                [thickness]="'soft'"
            ></interacta-separator>
        </ng-container>
    </div>
</interacta-form-field-v2>
