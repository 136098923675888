import { ThemeMode } from '@interacta-shared/util-common';

export type ThemeOption = ThemeMode | 'system';

export interface Theme {
    isSystem: boolean;
    mode: ThemeMode;
}

export function systemTheme(): Theme {
    const userPrefersDark =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;

    return {
        isSystem: true,
        mode: userPrefersDark ? 'dark' : 'light',
    };
}
