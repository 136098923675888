import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Appearance } from '@interacta-shared/ui';

@Component({
    selector: 'interacta-drop-zone-area[width][height]',
    templateUrl: './drop-zone-area.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropZoneAreaComponent {
    @Input() width!: number;
    @Input() height!: number;
    @Input() appearance: Appearance = 'border';

    @Output() isMouseOver = new EventEmitter<boolean>();
}
