import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { Appearance, Palette, Size } from '@interacta-shared/ui';

@Component({
    selector: 'interacta-workflow-state-badge',
    templateUrl: './workflow-state-badge.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowStateBadgeComponent implements AfterViewInit {
    @Input() size: Size = 'regular';
    @Input() workflowStateName?: string;
    @Input() color?: string;
    @Input() colorCode?: string;
    @Input() colorClass?: string;
    @Input() labelColor: Extract<
        Palette,
        'text-on-status' | 'gray-700' | 'pure-white'
    > = 'text-on-status';
    @Input() appearance: Appearance = 'fill';
    @Input() tooltipEnabled = true;
    @Input() fullwidth = false;
    @Input() rounded: 'pill' | 'leaf' = 'pill';

    tooltipVisible = false;
    @ViewChild('container') containerRef?: ElementRef<HTMLElement>;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.checkSize();
    }

    checkSize(): void {
        const element = this.containerRef?.nativeElement;
        if (element) {
            this.tooltipVisible =
                this.tooltipEnabled &&
                element.scrollHeight > element.clientHeight;
            setTimeout(() => this.cdr.markForCheck());
        }
    }
}
