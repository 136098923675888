import { GooglePickerDocument } from '../google-picker.model';
import { toEmbedLink, toLargeThumbnailLink } from './google-drive.utils';

export interface GoogleDriveFile extends GooglePickerDocument {
    size?: number;
    webContentLink: string | null;
    thumbnailLinks: GoogleDriveThumbnailLinks | null;
}

export interface GoogleDriveThumbnailLinks {
    small: string;
    large: string;
}

export class GoogleDriveFileSerialize {
    public static addGoogleDriveFile = (document: GoogleDriveFile): any => {
        return {
            driveId: document.driveId,
            mimeType: document.mimeType,
            size: document.size,
            webViewLink: document.webViewLink,
            webContentLink: document.webContentLink,
        };
    };
}

export class GoogleDriveFileDeserialize {
    public static googleDriveFile = (
        file: gapi.client.drive.File,
        document: GooglePickerDocument,
    ): GoogleDriveFile => {
        return {
            ...document,
            size:
                file.size != null && file.size !== undefined
                    ? Number(file.size)
                    : undefined,
            webContentLink: file.webContentLink ?? null,
            thumbnailLinks:
                file.hasThumbnail && file.thumbnailLink
                    ? {
                          small: file.thumbnailLink,
                          large: toLargeThumbnailLink(file.thumbnailLink),
                      }
                    : null,
        };
    };

    public static googleDriveFileDes = (record: any): GoogleDriveFile => {
        return {
            driveId: record.driveId,
            mimeType: record.mimeType,
            size: record.size,
            webViewLink: record.webViewLink,
            webContentLink: record.webContentLink ?? null,
            thumbnailLinks: null,
            embedLink: toEmbedLink(record.webViewLink),
        };
    };
}
