import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'postCodeTitle',
})
export class PostCodeTitlePipe implements PipeTransform {
    transform(postCode?: string, postTitle?: string): string | undefined {
        if (postCode && postTitle) return postCode + ' - ' + postTitle;
        else if (postCode) return postCode;
        else if (postTitle) return postTitle;
    }
}
