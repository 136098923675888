import { Pipe, PipeTransform } from '@angular/core';
import {
    AttachmentCategoryType,
    IAttachment,
} from '@modules/post/models/attachment/attachment.model';
import {
    isFileAttachment,
    isMediaAttachment,
} from '@modules/post/models/attachment/attachment.utils';

@Pipe({
    name: 'filterAttachments',
})
export class FilterAttachmentsPipe implements PipeTransform {
    transform(
        attachments: IAttachment[],
        type: AttachmentCategoryType,
    ): IAttachment[] {
        return attachments.filter(
            type === AttachmentCategoryType.MULTIMEDIA
                ? isMediaAttachment
                : isFileAttachment,
        );
    }
}
