import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getAbbreviation, getRandomColor } from '@interacta-shared/util';
import { Member } from '@modules/core/models/member/member.model';
import {
    getMemberFullname,
    isMemberGroup,
    isMemberUser,
} from '@modules/core/models/member/member.utils';

export const renderMemberItem = (
    member: Member,
    domSanitizer: DomSanitizer,
): HTMLButtonElement => {
    const accountPhotoUrl =
        isMemberUser(member) && member.user.accountPhotoUrl
            ? domSanitizer.sanitize(
                  SecurityContext.URL,
                  member.user.accountPhotoUrl,
              )
            : undefined;
    const fullName =
        domSanitizer.sanitize(
            SecurityContext.HTML,
            getMemberFullname(member),
        ) ?? '';
    const backgroundColor = getRandomColor(member.innerId);

    const avatar = isMemberGroup(member)
        ? `<div class="shrink-0 flex items-center justify-center rounded-full w-40 h-40 ${backgroundColor}">
    <div class="w-30 h-30">
        <img src="assets/icons/people.svg" class="w-full h-full"/>
    </div>
</div>
    `
        : accountPhotoUrl
          ? `<div class="flex pointer-events-none">
                <div class="rounded-full object-cover w-40 h-40">
                    <img
                        class="select-none transition-opacity duration-100 ease-linear rounded-full object-cover w-40 h-40"
                        src="${accountPhotoUrl}"/>
                </div>
            </div>`
          : `<div
        class="flex items-center shrink-0 justify-center rounded-full object-cover w-40 h-40 text-text-on-avatar pointer-events-none ${backgroundColor}">
        ${getAbbreviation(fullName).toUpperCase()}
    </div>`;

    const buttonElement = document.createElement('button');
    buttonElement.className = 'w-full text-left px-8 py-4 typo-sm-bold';
    buttonElement.innerHTML = `
      <div class="flex items-center space-x-8 max-w-full">
        ${avatar}
        <div class="flex flex-col grow min-w-0">
          <p class="line-clamp-2">${fullName}</p>
        </div>
      </div>`;
    return buttonElement;
};
