<interacta-input-generic-select
    [control]="control"
    [enableDragAndDrop]="enableDragAndDrop"
    [isReadonly]="isReadonly"
    [items]="members$ | async"
    [lockedIds]="lockedIds"
    [maxSelectableItems]="maxSelectableItems"
    [noResultLabel]="'UI.NO_RESULT' | translate"
    [searchItemTemplate]="searchItemTemplate"
    [searchPlaceholderTemplate]="searchPlaceholderTemplate"
    [selectedItemTemplate]="selectedItemTemplate"
    [showSearchPlaceholders]="showSearchPlaceholders"
    (itemListOpened)="itemListOpened($event)"
    (search)="search($event)"
>
</interacta-input-generic-select>

<ng-template #searchItemTemplate let-item="item">
    <interacta-member
        class="block py-4"
        [fontWeight]="item.id === EMPTY_SEARCH_VALUE ? 'regular' : 'bold'"
        [member]="item"
        [memberClickBehavior]="'none'"
        [showInfo]="true"
    ></interacta-member>
</ng-template>
<ng-template #searchPlaceholderTemplate>
    <interacta-member-list-placeholder
        class="px-12"
    ></interacta-member-list-placeholder>
</ng-template>
<ng-template #selectedItemTemplate let-item="item">
    <span
        *ngIf="item.id === EMPTY_SEARCH_VALUE"
        class="typo-sm line-clamp-1 grow break-all pr-2 italic"
        >{{ item.fullName }}</span
    >
    <interacta-member
        *ngIf="item.id !== EMPTY_SEARCH_VALUE"
        [fontWeight]="'regular'"
        [member]="item"
        [memberClickBehavior]="memberClickBehavior"
        [size]="'small'"
    ></interacta-member>
</ng-template>
