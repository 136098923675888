import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
} from '@angular/core';
import { fadeInOutAnimation } from '@interacta-shared/ui';
import { filterMap } from '@interacta-shared/util';
import { Emojii18n, SearchIcons } from '@interacta-shared/util-common';
import * as UISelectors from '@modules/core/store/UI/ui.selector';
import { QuillTableSettings } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'interacta-quill-toolbar',
    templateUrl: './quill-toolbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInOutAnimation('aiButtonFade')],
})
export class QuillToolbarComponent {
    @Input() userMentionEnabled = true;
    @Input() tagEnabled = true;
    @Input() tableEnabled = false;
    @Input() disableButtons = false;
    @Input() withMarginLeft = false;
    @Input() aiEnabled = false;
    @Output() mentionClick = new EventEmitter<string>();
    @Output() addEmoji = new EventEmitter<string>();
    @Output() addTable = new EventEmitter<QuillTableSettings>();
    @Output() aiClick = new EventEmitter<void>();

    private readonly translate = inject(TranslateService);
    private readonly store = inject(Store);

    openEmojiPicker = false;
    openTableSettings = false;
    searchIcons = SearchIcons;
    i18n = Emojii18n(this.translate);

    theme$ = this.store
        .select(UISelectors.theme)
        .pipe(filterMap((theme) => theme?.mode ?? undefined));

    closeTableSettings(tableSettingsButton: HTMLElement): void {
        this.openTableSettings = false;
        tableSettingsButton.blur();
    }

    applyTableSettings(
        settings: QuillTableSettings,
        tableSettingsButton: HTMLElement,
    ): void {
        this.closeTableSettings(tableSettingsButton);
        this.addTable.emit(settings);
    }
}
