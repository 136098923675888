<label
    *ngIf="{ checked: checked$ | async } as $"
    class="group flex w-auto cursor-pointer"
    [ngClass]="{
        'pointer-events-none cursor-default opacity-50': disabled,
        'items-center': align === 'center'
    }"
>
    <span
        class="flex shrink-0"
        [ngClass]="{
            'mt-2': align === 'start'
        }"
    >
        <input
            #inputRadio
            class="size-0 opacity-0"
            type="radio"
            [name]="_name"
            [value]="value"
            (change)="change($event)"
        />

        <span
            class="flex size-20 items-center justify-center rounded-full border-2 transition-colors"
            [ngClass]="{
                'border-border-700': !$.checked,
                'border-border-primary': $.checked
            }"
        >
            <span
                class="bg-surface-primary size-12 transform rounded-full transition-transform"
                [ngClass]="{
                    'scale-0': !$.checked,
                    'scale-100': $.checked
                }"
            ></span>
        </span>
    </span>

    <span class="ml-10 min-w-0 grow">
        <ng-content></ng-content>
    </span>
</label>
