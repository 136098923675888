import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { InputStatePipe } from '../../pipes/input-state.pipe';

@Component({
    selector: 'interacta-input-textarea-v2',
    templateUrl: './input-textarea-V2.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, NgClass, AsyncPipe, InputStatePipe],
})
export class InputTextareaV2Component {
    @Input() control!: UntypedFormControl;
    @Input() label?: string;
    @Input() maxRows = 5;
    @Input() isReadonly = false;
    @Input() withMarginLeft = false;
}
