import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { filterMap, getNextPageToken } from '@interacta-shared/util';
import { LightBoxComponent } from '@modules/light-box/components/light-box/light-box.component';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { Observable, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {
    AnyGallerySourceDetail,
    FETCH_NEXT_FEATURES,
} from '../models/gallery-source.model';
import { GalleryState } from '../models/gallery-state.model';
import { GallerySourceService } from './gallery-source.service';
import { IStateService } from './istate-service';
import { StateService } from './state.service';

@Injectable({ providedIn: 'root' })
export class GalleryStateService implements IStateService<GalleryState> {
    readonly state: GalleryState;
    private overlayRef!: OverlayRef;

    fetchPage$ = new Subject<{ untilIdx: number }>();
    flush$ = new Subject<void>();

    sourcesService = inject(GallerySourceService);

    constructor(
        stateService: StateService,
        private overlay: Overlay,
    ) {
        this.state = stateService.galleryState;
    }

    setInfoBarOpen(isOpen: boolean): void {
        this.state.showInfo.next(isOpen);
    }

    getInfoBarOpen(): boolean {
        return this.state.showInfo.getValue();
    }

    setSearchBarMode(searchMode: boolean): void {
        this.state.isSearchBarActive.next(searchMode);
    }

    getSearchBarMode(): boolean {
        return this.state.isSearchBarActive.getValue();
    }

    updateIndex(index: number): void {
        this.state.index.next(index);
    }

    openGallery(
        detail: AnyGallerySourceDetail,
        currentIndex: number,
        containsText?: string,
        autoplay?: boolean,
        hideNavigation?: boolean,
    ): void {
        this.state.sourceDetail.next(detail);
        this.updateIndex(currentIndex);
        this.state.isOpen.next(true);
        this.state.containsText.next(containsText ?? null);
        this.setupLightBoxOverlay();
        this.state.autoplay.next(autoplay ?? false);
        this.state.hideNavigation.next(hideNavigation ?? false);
    }

    canEdit(canEdit: boolean): void {
        this.state.canEdit.next(canEdit);
    }

    setEditMode(active: boolean): void {
        this.state.isEditModeActive.next(active);
    }

    previous(): void {
        if (this.state.index.value > 0) {
            this.updateIndex(this.state.index.value - 1);
        }
    }

    next(): void {
        const attachmentsList = this.state.attachmentsList.value;

        if (
            !attachmentsList ||
            this.state.index.value >= attachmentsList.totalCount - 1
        ) {
            return;
        }

        this.updateIndex(this.state.index.value + 1);

        const feature = this.state.sourceDetail.getValue()?.feature;

        if (
            this.state.index.value >= attachmentsList.attachments.length - 5 &&
            feature &&
            FETCH_NEXT_FEATURES.includes(feature) &&
            attachmentsList.pageTokenInfo.tag === 'regularLoading' &&
            !attachmentsList.isLoading
        ) {
            //next page fetch with store
            this.fetchNextPage(
                Math.min(
                    this.state.index.value + 6,
                    attachmentsList.totalCount - 1,
                ),
            );
        }
    }

    fetchNextPage(toIndex: number): void {
        this.fetchPage$.next({ untilIdx: toIndex });
    }

    closeGallery(): void {
        this.state.isOpen.next(false);
        this.state.attachmentsList.next(null);
        this.state.sourceDetail.next(null);
        this.overlayRef.dispose();
    }

    initialize(): void {
        this.initializeGallery();
    }

    flush(): void {
        this.initializeGallery();
        this.flush$.next();
    }

    saveEditedAttachment(
        detail: AnyGallerySourceDetail,
        imageBlob: Blob,
        attachment: IAttachment,
    ): void {
        this.sourcesService
            .getSource(detail.feature)
            .editFn?.(detail, imageBlob, attachment);
    }

    private setupLightBoxOverlay(): void {
        this.overlayRef = this.overlay.create();
        const component = new ComponentPortal(LightBoxComponent);
        this.overlayRef.attach(component);
    }

    private selectFromSource(
        detail: AnyGallerySourceDetail,
    ): Observable<IListAttachments> {
        return this.sourcesService.getSource(detail.feature).sourceFn(detail);
    }

    private fetchFromSource(
        pageToken: string | undefined,
        pageSize: number,
    ): void {
        const detail = this.state.sourceDetail.getValue();
        if (!detail) return;
        this.sourcesService.getSource(detail.feature).fetchFn?.({
            detail,
            pageToken,
            pageSize,
        });
    }

    private initializeGallery(): void {
        this.state.attachmentsList.next(null);

        this.fetchPage$
            .pipe(debounceTime(250))
            .subscribe(({ untilIdx: toIndex }) => this.fetchPage(toIndex));

        this.state.sourceDetail
            .pipe(
                filterMap((gat) => gat ?? undefined),
                distinctUntilChanged(),
                switchMap((gat) =>
                    this.selectFromSource(gat).pipe(
                        tap((list) =>
                            this.checkForUpdateFilePickerPreviewLink(list),
                        ),
                    ),
                ),
                takeUntil(this.flush$),
            )
            .subscribe((attachments) =>
                this.state.attachmentsList.next({ ...attachments }),
            );
    }

    private fetchPage(toIndex?: number): void {
        const attachmentsList = this.state.attachmentsList.value;

        const postId = attachmentsList?.attachments[0].post?.id;

        if (
            attachmentsList == null ||
            postId == null ||
            attachmentsList.isLoading ||
            (toIndex != null && toIndex < attachmentsList.attachments.length)
        ) {
            return;
        }

        this.state.attachmentsList.next({
            ...attachmentsList,
            isLoading: true,
        });

        const pageSize = toIndex
            ? toIndex - attachmentsList.attachments.length + 1
            : 25;

        this.fetchFromSource(
            getNextPageToken(attachmentsList.pageTokenInfo) ?? undefined,
            pageSize,
        );
    }

    private checkForUpdateFilePickerPreviewLink(list: IListAttachments): void {
        const detail = this.state.sourceDetail.getValue();

        if (
            detail &&
            !list.isLoading &&
            list.attachments.some((at) => !at.temporaryContentViewLink)
        ) {
            this.sourcesService
                .getSource(detail.feature)
                .fetchPreviewFn?.(detail);
        }
    }
}
