import {
    IWorkflowOperationMetadata,
    IWorkflowStatus,
    IWorkflowStatusMetadata,
} from './workflow.model';

export const toWorkflowStatus = (record: any): IWorkflowStatus => ({
    id: record.id,
    initState: record.initState,
    name: record.name,
    metadata: toWorkflowStatusMetadata(record.metadata),
    terminal: record.terminal,
    deleted: record.deleted,
});

export const toWorkflowStatusMetadata = (
    record?: any,
): IWorkflowStatusMetadata => ({
    isPreferred: record && record.preferred === 'true',
    userUnmanaged: record && record.user_unmanaged === 'true',
    styleColor: record && record.style_color ? record.style_color : '#959391',
    styleColorCode:
        record && record.style_color_code ? record.style_color_code : '',
    searchable: !record || !record.searchable || record.searchable === 'true',
    rawData: record,
});

export const toWorkflowOperationMetadata = (
    record?: any,
): IWorkflowOperationMetadata => ({
    isPreferred: record && record.preferred === 'true',
    delegateUrl: record.delegate_url ? record.delegate_url : undefined,
});
