import { Language } from '@interacta-shared/data-access-configuration';

export interface IOptionItem {
    id: any;
    label: string;
    badge?: any;
    deleted?: boolean;
}
export interface IDataForEditError {
    notExists: boolean;
    notAvailable: boolean;
    labelNotExists: string;
    labelNotAvailable: string;
}
export interface IFieldTranslation {
    value: string;
    language: Language;
    isDefaultLanguage: boolean;
}

export enum StatusFormType {
    VALID = 'VALID',
    INVALID = 'INVALID',
}
export enum PostDetailSection {
    ATTACHMENTS = 'attachments',
    COMMENTS = 'comments',
    WORKFLOW = 'workflow',
    //  CHRONOLOGY = 'chronology',
    TASKS = 'tasks',
    VISIBILITY = 'visibility',
    INVITATIONS = 'invitations',
    AI = 'ai',
}

export enum CheckboxStateLegacy {
    SELECTED = 1,
    UNSELECTED = 2,
    INDETERMINATE = 3,
}

export enum KeyCode {
    RIGHT_ARROW = 'ArrowRight',
    LEFT_ARROW = 'ArrowLeft',
    ESCAPE = 'Escape',
    ENTER = 'Enter',
    NUMPAD_ENTER = 'NumpadEnter',
    SPACE = 'Space',
    COMMA = 'Comma',
    TAB = 'Tab',
}

export enum ViewSize {
    SMALL = 1,
    MEDIUM = 2,
    LARGE = 3,
}

export enum IconRating {
    STAR = 'star',
    HEART = 'favorite',
}

export enum PlaceholderType {
    AVATAR = 1,
    TEXT = 2,
    BLOCK = 3,
    PERSON = 4,
    MEDIA = 5,
    POST = 6,
    POST_TABLE = 7,
    INSIGHTS_BLOCK = 8,
    INSIGHTS_CHART = 9,
}

export enum ButtonViewType {
    BUTTON = 1,
    ICON = 2,
    MENU = 3,
}

export class UtilitySerialize {
    public static nameTranslations(translations: IFieldTranslation[]): any {
        const record = {
            name: translations
                ? translations.find((t) => t.isDefaultLanguage)?.value
                : null,
            translations: {} as any,
        };
        translations
            .filter((translation: IFieldTranslation) => translation.value)
            .forEach((translation: IFieldTranslation) => {
                record.translations[translation.language.code] =
                    translation.value;
            });
        return record;
    }
}

export type QuillText = unknown;

export type i18nString = { [key: string]: string } | string;
export type i18nDelta = { [key: string]: QuillText } | QuillText;

// new translations format
export interface i18nStringV2 {
    label: string | null;
    i18ns: { [key: string]: string } | null;
}
export interface i18nDeltaV2 {
    label: QuillText | null;
    i18ns: { [key: string]: QuillText } | null;
}

export type FetchChildrenResponse = { id: number; name: i18nString };

export interface DateRange {
    fromDate: Date;
    toDate: Date;
}

export type Selectable<T> = T & { selected: boolean };

export interface Environment {
    production: boolean;
    test: boolean;
    dev: boolean;
}

export type HomeCommunityIndex = 'dashboard' | number;
