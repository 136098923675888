import {
    animate,
    AnimationTriggerMetadata,
    style,
    transition,
    trigger,
} from '@angular/animations';

export function openWidthAnimation(
    name: string,
    width: number | string,
    msDuration = 200,
): AnimationTriggerMetadata {
    return trigger(name, [
        transition(':enter', [
            style({ opacity: 0, width: '0px' }),
            animate(
                `${msDuration}ms ease-out`,
                style({
                    opacity: 1,
                    width: typeof width === 'number' ? `${width}px` : width,
                }),
            ),
        ]),
        transition(':leave', [
            animate(
                `${msDuration}ms ease-in`,
                style({
                    opacity: 0,
                    width: 0,
                }),
            ),
        ]),
    ]);
}
