import { AuthUser } from '@interacta-shared/data-access-auth';
import { Timezone } from '@interacta-shared/data-access-configuration';
import { Size } from '@interacta-shared/ui';
import { InteractaIcon } from '@interacta-shared/ui-icons';
import { Index, PaginatedList } from '@interacta-shared/util';
import { ICommunity } from '@modules/communities/models/communities.model';
import { WorkspaceBase } from '@modules/communities/models/workspace.model';
import { PostLoadingInfo } from '@modules/communities/store/post/post.reducer';
import { i18nString, IUser, IUsersGroup } from '@modules/core';
import { IBasePost } from '@modules/post/models/base-post.model';
import { IComment } from '@modules/post/models/comment.model';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import { GenericPost } from '@modules/post/models/generic-post.model';
import { UsefulLinksGroup } from '@modules/useful-link/models/useful-link.model';

export interface DigitalWorkplaceDefinition {
    id: number;
    name: i18nString;
    lastModifyTimestamp: number;
    publicationDate: Date | null;
    creationDate: Date | null;
    viewedTimestamp: number | null;
    active: boolean;
    position: number;
    status: DigitalWorkplaceStatus[];
    publishedHomeId: number | null;
    visibility: DigitalWorkplaceDefinitionVisibility;
    widgets: DigitalWorkplaceWidget[];
}

export interface DigitalWorkplaceDefinitionVisibility {
    global: boolean;
    workspaces: WorkspaceBase[];
    communities: ICommunity[];
    groups: IUsersGroup[];
}

export enum WidgetType {
    VISUAL_POST_ROLL = 1,
    TEXT_POST_ROLL,
    POST_LIST_COVER,
    STATIC_COVER,
    USEFUL_LINK,
    CALL_TO_ACTION,
    MY_SPACE,
}

export enum DigitalWorkplaceStatus {
    DRAFT = 'draft',
    ONLINE = 'online',
    OFFLINE = 'offline',
    DIRTY = 'dirty',
}

export type DigitalWorkplaceWidget =
    | PostRollWidget
    | CoverWidget
    | UsefulLinkWidget
    | CallToActionWidget
    | MySpaceWidget;

export interface DigitalWorkplaceBaseWidget {
    id: Index;
    name: i18nString;
    position: number;
    active: boolean;
    type: WidgetType;
}

export type PostRollWidgetSize = Extract<Size, 'small' | 'regular' | 'large'>;

export interface WidgetCardSettingsBase {
    showViewsCounter: boolean;
    showLikesCounter: boolean;
    showCommentsCounter: boolean;
    showAttachmentsCounter: boolean;
    showStandardTasksCounter: boolean;
    showCommunity: boolean;
    showWorkspace: boolean;
    showAuthor: boolean;
    showPublicationTimestamp: boolean;
}

export interface PostRollWidgetCardSettingsBase extends WidgetCardSettingsBase {
    showState: boolean;
    showTitle: boolean;
    showCustomId: boolean;
}

export interface TextPostRollWidgetCardSettings
    extends PostRollWidgetCardSettingsBase {
    showDescription: boolean;
    showCustomFields: boolean;
}

export type VisualPostRollWidgetCardSettings = PostRollWidgetCardSettingsBase;

export interface PostRollBaseWidget extends DigitalWorkplaceBaseWidget {
    size: PostRollWidgetSize;
    communityIds: number[];
    filters: IPostFilters;
    empty?: boolean;
}

export interface TextPostRollWidget extends PostRollBaseWidget {
    tag: WidgetType.TEXT_POST_ROLL;
    customFieldIds: number[] | null;
    cardSettings: TextPostRollWidgetCardSettings;
}

export interface VisualPostRollWidget extends PostRollBaseWidget {
    tag: WidgetType.VISUAL_POST_ROLL;
    cardSettings: VisualPostRollWidgetCardSettings;
}

export type PostRollWidget = TextPostRollWidget | VisualPostRollWidget;

export type CoverWidgetSize = 'hero' | 'banner';

export enum CoverWidgetAppearance {
    VISUAL = 1,
    TEXT,
    SPLIT,
}

export enum StaticCoverExternalUrlType {
    YOUTUBE = 'YOUTUBE',
    VIMEO = 'VIMEO',
    VIDEO = 'VIDEO',
    IMAGE = 'IMAGE',
}

export type PostListCoverWidgetCardSettingsBase = WidgetCardSettingsBase;

export interface StaticCoverWidgetContentDate {
    readonly zonedDatetime?: Date;
    localDatetime: Date;
}

export type StaticContentImageFillType = 'fit' | 'fill';
export type StaticContentImageAlignment =
    | 'top'
    | 'bottom'
    | 'center'
    | 'right'
    | 'left';

export interface StaticCoverWidgetContentAttachmentMetadata {
    start_at_seconds: number | undefined;
    fill_type: StaticContentImageFillType | undefined;
    alignment: StaticContentImageAlignment | undefined;
    web_mobile_same_image: boolean | undefined;
}

export interface StaticCoverWidgetContentAttachment {
    temporaryContentPreviewImageHiResLink?: string;
    temporaryContentViewLink?: string; // url temporaneo per la visualizzazione di una immagine/video caricato in interacta
    contentRef?: string; // usato per l'upload
    contentMimeType?: string; // usato solo per creazione / modifica
    secondsToStart?: number; //secondi da cui far partire il video
    fillType?: StaticContentImageFillType; //usato solo per file immagini;
    alignment?: StaticContentImageAlignment; //se imageViewMode = 'fill' va specificato
}

export interface StaticCoverWidgetMobileContentAttachment
    extends StaticCoverWidgetContentAttachment {
    webMobileSameImage?: boolean; //se in fase di configurazione è stata usata la stessa immagine per web e mobile
}

export interface StaticCoverWidgetContent
    extends StaticCoverWidgetContentAttachment {
    id: string;
    name: string | null;
    externalUrlType?: StaticCoverExternalUrlType;
    externalUrl?: string; // url di una immagine esterna, possiamo usralo anche per i contenuty youtube e vimeo
    youtubeVideoId?: string; // computed by client from externalUrl if externalUrlType is YOUTUBE
    startDisplay: StaticCoverWidgetContentDate | null;
    endDisplay: StaticCoverWidgetContentDate | null;
    displayDateTimezone: Timezone | null;
    actionUrl?: string; //cta url -> contentuti statici media caricati da file locale
    actionLabel?: string; //cta label -> contentuti statici media caricati da file locale

    deleted?: boolean;
    mobileStaticContent: StaticCoverWidgetMobileContentAttachment | null;
}

export interface BaseCoverWidget extends DigitalWorkplaceBaseWidget {
    size: CoverWidgetSize;
    appearance: CoverWidgetAppearance;
    mainCover: boolean; // copertina della home può essercene al più una
}

export interface PostListCoverWidget extends BaseCoverWidget {
    tag: WidgetType.POST_LIST_COVER;
    communityIds: number[];
    filters: IPostFilters;
    maxVisiblePosts: number;
    cardSettings: PostListCoverWidgetCardSettingsBase;
    empty?: boolean;
}

export interface StaticCoverWidget extends BaseCoverWidget {
    tag: WidgetType.STATIC_COVER;
    staticContents: StaticCoverWidgetContent[];
}

export type CoverWidget = PostListCoverWidget | StaticCoverWidget;

export type PostListWidget = PostRollWidget | PostListCoverWidget;

export type MySpaceWidgetSize = Extract<Size, 'small' | 'large'>;

export interface MySpaceWidget extends DigitalWorkplaceBaseWidget {
    tag: WidgetType.MY_SPACE;
    size: MySpaceWidgetSize;
    groups?: IUsersGroup[];
    users?: IUser[];
}

export type RollWidget = UsefulLinkWidget | PostRollWidget | MySpaceWidget;

export type CtaPostRecord = Record<
    CallToAction['uuid'],
    PaginatedList<GenericPost>
>;
export type CtaFilterRecord = Record<CallToAction['uuid'], IPostFilters>;

export interface UsefulLinkWidget extends DigitalWorkplaceBaseWidget {
    tag: WidgetType.USEFUL_LINK;
    linksGroups: UsefulLinksGroup[];
    groups?: IUsersGroup[];
    users?: IUser[];
}

export interface DigitalWorkplaceHomeInfo {
    id: DigitalWorkplaceDefinition['id'];
    isPreviewMode: boolean;
    lastModifyTimestamp: number;
    loadingInfo: Pick<PostLoadingInfo, 'isFetching'>;
}

export interface CallToActionWidget extends DigitalWorkplaceBaseWidget {
    tag: WidgetType.CALL_TO_ACTION;
    callToActions: CallToAction[];
}

export enum CallToActionType {
    CREATE_POST = 'create_post',
    CREATE_EVENT = 'create_event',
    CREATE_SURVEY = 'create_survey',
    CREATE_FEEDBACK = 'create_feedback',
}

export enum MobileOptionsType {
    QR_CODE = 'qr_code',
    CAMERA = 'camera',
}

export interface MobileOptionsTypeQrCode {
    type: MobileOptionsType.QR_CODE;
    fieldId: number;
}

export interface MobileOptionsTypeCamera {
    type: MobileOptionsType.CAMERA;
    fieldId?: number;
    postAttachments: boolean;
}

export type CallToActionMobileOptions =
    | MobileOptionsTypeQrCode
    | MobileOptionsTypeCamera;

export interface CallToActionExamplePostData {
    filters: IPostFilters;
    fieldIds: number[];
}

export interface CallToAction {
    uuid: string;
    name: i18nString;
    actionLabel: i18nString;
    communityId: number;
    contentRef?: string;
    contentMimeType?: string; // usato solo per creazione / modifica
    temporaryContentPreviewImageLink?: string;
    temporaryContentViewLink?: string;
    temporaryContentPreviewImageHiResLink?: string;
    actionType: CallToActionType;
    advancedMobileOptions?: CallToActionMobileOptions;
    showExamplePost: boolean;
    examplePostData?: CallToActionExamplePostData;
}

export const POST_LIST_WIDGET_PAGE_SIZE_PREVIEW = 5;

export interface MySpaceCounters {
    commentReplies: number;
    createdByMeClosedTasks: number;
    createdByMeExpiredTasks: number;
    createdByMeExpiringTasks: number;
    expiredTasks: number;
    expiringTasks: number;
    mentionedInPostAndComments: number;
    scheduledDraftPosts: number;
    standardDraftPosts: number;
    toManageWorkflowPosts: number;
}

interface CommunityCounter {
    community: Pick<ICommunity, 'id' | 'name'>;
    today: number;
    lastWeek: number;
    lastMonth: number;
    other: number;
}

export interface MySpaceCountersProcesses {
    communityCounters: CommunityCounter[];
}

interface CreatedByMeExpiredTasks {
    count: number;
    entity: {
        id: string;
        user: Pick<AuthUser, 'id' | 'firstName' | 'lastName'>;
        group: Pick<IUsersGroup, 'id' | 'fullName'> | null;
    };
}

interface CreatedByMeClosedTasks {
    count: number;
    entity: Pick<AuthUser, 'id' | 'firstName' | 'lastName'>;
}

export interface MySpaceCountersTasks {
    assignedToMeExpired: number;
    assignedToMeExpiringToday: number;
    assignedToMeExpiringTomorrow: number;
    assignedToMeExpiringThisWeek: number;
    createdByMeExpiredTasks: CreatedByMeExpiredTasks[];
    createdByMeClosedTasks: CreatedByMeClosedTasks[];
}

export interface MySpaceCountersDrafts {
    staleStandardDraftPosts: number;
    publishingScheduledDraftPosts: Pick<
        IBasePost,
        'id' | 'title' | 'scheduledPublication'
    >[];
}

export interface MentionedInMostMentioned {
    count: number;
    entity: Pick<AuthUser, 'id' | 'firstName' | 'lastName'>;
}

export interface MentionedInMentionedGroup {
    count: number;
    entity: Pick<IUsersGroup, 'id' | 'fullName'>;
}

export interface MySpaceCountersMentionedIn {
    mostMentioner: MentionedInMostMentioned | null;
    lastMonthMentioners: Pick<AuthUser, 'id' | 'firstName' | 'lastName'>[];
    todayMentionerUser: Pick<AuthUser, 'id' | 'firstName' | 'lastName'> | null;
    todayMentionedGroup: MentionedInMentionedGroup | null;
    lastMonthMostMentionedGroup: MentionedInMentionedGroup | null;
    lastWeekRepliedCommentsCount: number;
    todayRepliedCommentsCount: number;
    onFireComment: Pick<IComment, 'id' | 'plainText'> | null;
}

export interface MySpaceCountersDetail {
    processes: MySpaceCountersProcesses;
    tasks: MySpaceCountersTasks;
    drafts: MySpaceCountersDrafts;
    mentionedIn: MySpaceCountersMentionedIn;
}

export enum MySpaceWidgetCardType {
    'SIMPLE',
    'SPLIT',
}

export enum MySpaceWidgetCardContent {
    PROCESSES,
    TASK_ASSIGNED_TO_ME,
    TASK_TO_MONITOR,
    DRAFTS_AND_SCHEDULED,
    MENTIONS_AND_REPLIES,
}

interface MySpaceBaseCard {
    background: string;
    id: MySpaceWidgetCardContent;
    imgPath: string;
    redirectUrl: string;
    queryParams: any;
    show: boolean;
}

export interface MySpaceSimpleCard extends MySpaceBaseCard {
    counter: number;
    detailChip: string | null;
    detailCounter: number | null;
    detailType: 'default' | 'warning' | null;
    infoBoxes: MySpaceWidgetInfoBox[] | null;
    title: string;
    type: MySpaceWidgetCardType.SIMPLE;
}

export interface MySpaceSplitCard extends MySpaceBaseCard {
    bottomCounter: number | null;
    bottomIcon: InteractaIcon;
    bottomInfoBoxes: MySpaceWidgetInfoBox[] | null;
    bottomLabel: string;
    bottomQueryParams: any;
    topCounter: number | null;
    topIcon: InteractaIcon;
    topInfoBoxes: MySpaceWidgetInfoBox[] | null;
    topLabel: string;
    topQueryParams: any;
    type: MySpaceWidgetCardType.SPLIT;
}

export type MySpaceWidgetCard = MySpaceSimpleCard | MySpaceSplitCard;

export interface MySpaceWidgetInfoBox {
    message: string;
    title?: string;
    number?: string;
    name1?: string;
    name2?: string;
    name3?: string;
    day?: string;
    postTitle?: string;
    warning?: boolean;
    community?: Pick<ICommunity, 'id' | 'name'>;
}
