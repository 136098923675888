import { fromDateToTimestampLocal } from '@interacta-shared/data-access-configuration';
import { isDefined, mapArrayById } from '@interacta-shared/util';
import { fromCustomFieldsSearch } from '@modules/communities/models/custom-metadata/custom-metadata.serialize';
import { ScreenSerialize } from '@modules/communities/models/screen.model';
import { IUser } from '@modules/core';
import { AttachmentCategoryType } from '../attachment/attachment.model';
import { FilterAcknowledge, PostType } from '../base-post.model';
import { CustomPostSerialize } from '../custom-post.model';
import {
    IEventPostFilters,
    IPostFilters,
    IPostLinkFilters,
} from './filter-post.model';

const fromCommonFilter = (postFilter: IPostFilters): any => {
    const record = {
        pageToken: postFilter.pageToken,
        pageSize: postFilter.pageSize,

        calculateTotalItemsCount: postFilter.calculateTotalItemsCount,
        orderBy: postFilter.order ? postFilter.order.orderBy : null,
        orderDesc: postFilter.order ? postFilter.order.orderDesc : null,
    };

    return record;
};

const fromPostFilter = (postFilter: IPostFilters, currentUser?: IUser): any => {
    const hasTextToSearch = (postFilter.containsText?.length || 0) > 0;
    return {
        followedByMe: postFilter.followedByMe,

        createdByUserIds:
            postFilter.createdByMe && currentUser
                ? [currentUser.id]
                : postFilter.createdByUser &&
                    postFilter.createdByUser.length > 0
                  ? mapArrayById(postFilter.createdByUser)
                  : null,
        createdByGroupIds:
            postFilter.createdByGroup && postFilter.createdByGroup.length > 0
                ? mapArrayById(postFilter.createdByGroup)
                : null,
        title: hasTextToSearch ? postFilter.containsText : null,
        description: hasTextToSearch ? postFilter.containsText : null,
        creationTimestampFrom: fromDateToTimestampLocal(
            postFilter.creationDateFrom,
        ),
        creationTimestampTo: fromDateToTimestampLocal(
            postFilter.creationDateTo,
        ),
        modifiedTimestampFrom: fromDateToTimestampLocal(
            postFilter.modifiedDateFrom,
        ),
        modifiedTimestampTo: fromDateToTimestampLocal(
            postFilter.modifiedDateTo,
        ),
        mentioned: postFilter.mentioned,

        toManage: postFilter.toManage,

        postTypes: postFilter.postTypes ?? [],
        postSurveyTypes: postFilter.postTypes?.includes(PostType.SURVEY)
            ? postFilter.postSurveyTypes
            : null,
        eventPostFilter:
            postFilter.postTypes?.includes(PostType.EVENT) && postFilter.event
                ? fromEventPostFilter(postFilter.event)
                : undefined,
        draftType: postFilter.draftType,
        onlyPinned: postFilter.pinned || undefined,
        includeStatelessPosts: postFilter.includeStatelessPosts,
        postTypeSpecificFilters: postFilter.postTypeSpecificFilters,
    };
};

const fromEventPostFilter = (filter: IEventPostFilters): any => {
    if (filter.allEvents) return undefined;

    const result: any = {
        startDateFrom: fromDateToTimestampLocal(filter.startDateFrom),

        startDateTo: fromDateToTimestampLocal(filter.startDateTo),

        endDateFrom: fromDateToTimestampLocal(filter.endDateFrom),
        endDateTo: fromDateToTimestampLocal(filter.endDateTo),
    };

    return result;
};

const fromAcknowledgeTaskFilter = (filter: FilterAcknowledge | null): any => {
    let acknowledgeTaskFilter: any = null;
    if (isDefined(filter)) {
        switch (filter) {
            case FilterAcknowledge.CONFIRMED:
                acknowledgeTaskFilter = {
                    confirmed: true,
                    assignedToMe: false,
                };
                break;
            case FilterAcknowledge.TO_BE_CONFIRMED:
                acknowledgeTaskFilter = {
                    confirmed: false,
                    assignedToMe: false,
                };
                break;
            case FilterAcknowledge.CONFIRMED_BY_ME:
                acknowledgeTaskFilter = {
                    confirmed: true,
                    assignedToMe: true,
                };
                break;
            case FilterAcknowledge.TO_BE_CONFIRMED_BY_ME:
                acknowledgeTaskFilter = {
                    confirmed: false,
                    assignedToMe: true,
                };
                break;
            default:
                acknowledgeTaskFilter = {
                    confirmed: null,
                };
                break;
        }
    }
    return acknowledgeTaskFilter;
};

export const fromPostLinkFilters = (filters: IPostLinkFilters): any => {
    const record = <any>{};

    record.createdByUserId =
        filters.createdByUser && filters.createdByUser.length > 0
            ? filters.createdByUser[0].id
            : null;
    record.pageToken = filters.pageToken;
    record.pageSize = filters.pageSize;
    record.calculateTotalItemsCount = filters.calculateTotalItemsCount;
    record.containsText = filters.containsText;
    record.hashtagIds = (filters.hashtag || []).map((hashtag) => hashtag.id);
    record.currentWorkflowStatusIds = filters.workflowStatus?.ids ?? [];
    record.postFieldFilters = filters.customFields
        ? fromCustomFieldsSearch(filters.customFields)
        : [];

    record.screenFieldFilters = filters.screenFields
        ? fromCustomFieldsSearch(filters.screenFields)
        : [];
    record.context = filters.context
        ? CustomPostSerialize.contextCustomPost(filters.context)
        : filters.screenContext
          ? ScreenSerialize.screenContext(filters.screenContext)
          : undefined;
    return record;
};

export const fromRelationFilters = (filters: IPostLinkFilters): any => {
    const record = <any>{};
    record.pageToken = filters.pageToken;
    record.pageSize = filters.pageSize;
    record.calculateTotalItemsCount = filters.calculateTotalItemsCount;
    record.containsText = filters.containsText;
    record.postId = filters.postId;
    return record;
};

export const fromBaseAttachmentsFilters = (postFilter: IPostFilters): any => {
    return {
        name: postFilter.attachmentName,
        createdByUserId:
            postFilter.attachmentCreatedByUser &&
            postFilter.attachmentCreatedByUser.length > 0
                ? postFilter.attachmentCreatedByUser[0].id
                : null,
        mimeTypes: mapArrayById(postFilter.mimeTypes),
        mimeTypeCategory:
            postFilter.mimeTypeCategory === AttachmentCategoryType.MIXED
                ? null
                : postFilter.mimeTypeCategory,
        types:
            postFilter.attachmentSourceType != null
                ? [postFilter.attachmentSourceType]
                : null,
        excludeFilePickers: postFilter.excludeFilePickers ?? false,
        modifiedTimestampFrom: fromDateToTimestampLocal(
            postFilter.attachmentModifiedDateFrom,
        ),
        modifiedTimestampTo: fromDateToTimestampLocal(
            postFilter.attachmentModifiedDateTo,
        ),
    };
};

export const fromCommunityAttachmentFilters = (
    postFilter: IPostFilters,
): any => {
    return {
        ...fromBaseAttachmentsFilters(postFilter),
        hashtagIds: (postFilter.attachmentHashtag || []).map(
            (hashtag) => hashtag.id,
        ),
        hashtagsLogicalAnd:
            postFilter.attachmentHashtag &&
            postFilter.attachmentHashtag.length > 0
                ? postFilter.attachmentHashtagsLogicalAnd
                : undefined,
    };
};

export const fromGlobalAttachmentFilters = (postFilter: IPostFilters): any => {
    return fromBaseAttachmentsFilters(postFilter);
};

export const fromCommunityListFilter = (
    postFilter: IPostFilters,
    currentUser?: IUser,
): any => {
    const record = fromCommonFilter(postFilter);
    record.communityPostFilters = fromPostFilter(postFilter, currentUser);
    record.communityPostFilters.visibility = postFilter.visibility;
    record.communityPostFilters.acknowledgeTaskFilter =
        fromAcknowledgeTaskFilter(postFilter.acknowledgeTaskFilter);
    record.communityPostFilters.hashtagIds = (postFilter.hashtag || []).map(
        (hashtag) => hashtag.id,
    );
    record.communityPostFilters.hashtagsLogicalAnd =
        postFilter.hashtag && postFilter.hashtag.length > 0
            ? postFilter.hashtagsLogicalAnd
            : undefined;

    record.communityPostFilters.currentWorkflowStatusIds =
        postFilter.workflowStatus?.ids ?? [];
    record.communityPostFilters.postFieldFilters = postFilter.customFields
        ? fromCustomFieldsSearch(postFilter.customFields)
        : [];

    record.communityPostFilters.screenFieldFilters = postFilter.screenFields
        ? fromCustomFieldsSearch(postFilter.screenFields)
        : [];

    record.communityPostFilters.draftType = postFilter.draftType;
    record.pinnedFirst = postFilter.pinnedFirst;
    record.communityAttachmentFilters =
        fromCommunityAttachmentFilters(postFilter);
    return record;
};

export const fromGlobalListFilter = (
    postFilter: IPostFilters,
    communityIds?: number[],
    currentUser?: IUser,
): any => {
    const record = fromCommonFilter(postFilter);
    record.globalPostFilters = {
        ...fromPostFilter(postFilter, currentUser),
        currentWorkflowStatusIds: postFilter.dashboardWorkflowStatus?.ids ?? [],
    };
    record.globalAttachmentFilters = fromGlobalAttachmentFilters(postFilter);
    if (communityIds) {
        record.communityIds = communityIds;
    }
    return record;
};
