import {
    FieldType,
    ICustomFieldDefinition,
} from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { IScreenContext } from '@modules/communities/models/screen.model';
import { IUser, IUsersGroup } from '@modules/core';
import { DateCustomFieldFilterBase } from '@modules/post-filters/components/dashboard-filters-date-time/dashboard-filters-date-time.component';
import {
    AttachmentCategoryType,
    AttachmentStorageType,
    MimeTypeObj,
} from '../attachment/attachment.model';
import {
    FilterAcknowledge,
    PostType,
    VisibilityType,
} from '../base-post.model';
import { ICustomPostForContext } from '../custom-post.model';
import { SurveyType } from '../survey-post/survey-post.model';

export interface IPostFilters {
    communityId?: number;
    createdByMe: boolean | null;
    followedByMe: boolean | null;
    containsText: string | null;
    createdByUser: IUser[] | null;
    createdByGroup: IUsersGroup[] | null;
    creationDateType: FilterDateOptions | null;
    creationDateFrom: Date | null;
    creationDateTo: Date | null;
    modifiedDateFrom: Date | null;
    modifiedDateTo: Date | null;
    workflowStatus: IWorkflowsFilter;
    dashboardWorkflowStatus: IWorkflowsFilter;
    hashtag: IHashTag[] | null;
    hashtagsLogicalAnd: boolean;
    visibility: VisibilityType | null;
    acknowledgeTaskFilter: FilterAcknowledge | null;
    order: IOrder | null;
    postTypes: PostType[];
    postSurveyTypes: SurveyType[];
    event: IEventPostFilters | null;
    eventType: EventFilterDateOptions | null;
    customFields: IPostFiltersCustomField[] | undefined;
    screenFields: IPostFiltersCustomField[] | undefined;
    pageToken?: string;
    pageSize?: number;
    draftType?: DraftType;
    pinnedFirst?: boolean;
    pinned?: boolean;
    mentioned: boolean;
    toManage: boolean;

    calculateTotalItemsCount: boolean;

    // attachment filters
    attachmentName: string | null;
    attachmentHashtag: IHashTag[] | null;
    attachmentHashtagsLogicalAnd: boolean;
    attachmentModifiedDateType: FilterDateOptions | null;
    attachmentModifiedDateFrom: Date | null;
    attachmentModifiedDateTo: Date | null;
    attachmentCreatedByUser: IUser[] | null;
    mimeTypes: MimeTypeObj[] | null;
    attachmentSourceType: AttachmentStorageType | null;
    mimeTypeCategory: AttachmentCategoryType | null;
    excludeFilePickers: boolean;

    includeStatelessPosts?: boolean;

    postTypeSpecificFilters?: boolean;
}

export enum DraftType {
    DRAFT = 1,
    SCHEDULED = 2,
}

export type IPostLinkFilters = Pick<
    IPostFilters,
    | 'pageToken'
    | 'pageSize'
    | 'createdByUser'
    | 'createdByGroup'
    | 'hashtag'
    | 'workflowStatus'
    | 'customFields'
    | 'screenFields'
> & {
    calculateTotalItemsCount: boolean;
    containsText: string | null;
    postId: number | null;
    context?: ICustomPostForContext;
    screenContext?: IScreenContext;
};

export interface IPostFiltersCustomField {
    definition: ICustomFieldDefinition;
    value: unknown;
}

export interface CustomFieldServerData {
    columnId: number;
    parameters: unknown | unknown[];
    typeId: FilterType;
}

// Extend if necessary
type ExtendedCustomFieldOptionalData = DateCustomFieldFilterBase | undefined;

export interface ExtendedCustomFieldServerData extends CustomFieldServerData {
    optionalData: ExtendedCustomFieldOptionalData;
    fieldType: FieldType;
}

export type ServerFilterByType = Omit<
    ExtendedCustomFieldServerData,
    'columnId'
>;

export interface IEventPostFilters {
    allEvents?: boolean;
    startDateFrom?: Date;
    startDateTo?: Date;
    endDateFrom?: Date;
    endDateTo?: Date;
}

export type FilterStateCategory = 'todo' | 'inProgress' | 'finished';

export type FilterStateCategoryEnabled = 'off' | 'on' | 'partial';

export type FilterCategories = Record<
    FilterStateCategory,
    FilterStateCategoryEnabled
>;

export const filterStateCategoryOrder: Record<FilterStateCategory, number> = {
    todo: 1,
    inProgress: 2,
    finished: 3,
};

export interface FilterCategoryLabel {
    type: FilterStateCategory;
    label: string;
}
export interface IWorkflowsFilter {
    ids: number[];
}

export enum PostOrderType {
    LAST_MODIFY_AND_COMMENT_TIMESTAMP = 'postLastModifyAndCommentTimestamp',
    CREATION_TIMESTAMP = 'postCreationTimestamp',
    TITLE = 'postTitle',
    CUSTOM_ID = 'postCustomId',
    RECENCY = 'postRecency',
    STATE = 'postState',
    SCHEDULED_PUBLICATION = 'postScheduledPublication',
}

export enum AttachmentOrderType {
    CREATION_TIMESTAMP = 'attachmentCreationTimestamp',
    MODIFY_TIMESTAMP = 'attachmentLastModifyTimestamp',
    ATTACHMENT_NAME = 'attachmentName',
}

export enum PostOtherFiltersId {
    FOLLOWED_BY_ME,
    CONTAINS_TEXT,
    POST_HASHTAGS,
    ATTACHMENTS_HASHTAGS,
    PUBLICATION_DATE,
    CREATED_BY_USER,
    WORKFLOW_STATES,
    POST_TYPE,
    VISIBILITY,
    CUSTOM_FIELDS,
    SCREEN_CUSTOM_FIELDS,
    TO_MANAGE,
    ATTACHMENTS_CONTAINS_TEXT,
    ATTACHMENTS_LAST_MODIFY,
    ATTACHMENTS_CREATED_BY_USER,
    ATTACHMENTS_SOURCE,
    ATTACHMENTS_MIMETYPE,
    PINNED,
    CREATED_BY_USER_AND_GROUP,
    //FEEDBACK -- TODO
}

export enum FilterDateOptions {
    TODAY,
    YESTERDAY,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CUSTOM,
}

export enum EventFilterDateOptions {
    ONGOING,
    UPCOMING,
    IN_PROGRAM,
    ENDED,
    CUSTOM,
}

export enum CustomFieldsFilterDateOptions {
    TODAY,
    LAST_X_DAYS,
    NEXT_X_DAYS,
    CUSTOM,
}

export interface OrderOption {
    id: string;
    label: string;
    orderByDesc: boolean;
    hidden: boolean;
    disableSortOrder?: boolean;
}

export type OrderOptionForConfiguration = Pick<OrderOption, 'id' | 'label'>;

export enum FiltersTabs {
    POSTS = 'posts',
    ATTACHMENTS = 'attachments',
}

export enum FilterType {
    EQUAL = 1,
    INTERVAL = 2,
    LIKE = 3,
    IN = 4,
    CONTAINS = 5,
    ISNULL_OR_IN = 6,
    IS_EMPTY = 7,
}

export interface IOrder {
    orderBy: string;
    orderDesc: boolean;
}
