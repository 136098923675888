import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'interacta-hashtag-chip',
    templateUrl: './hashtag-chip.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class HashtagChipComponent {
    @Input() label = '';
}
