<div
    *ngIf="{
        preview:
            (attachment | apply: isMediaAttachment)
                ? attachment.temporaryContentPreviewImageLink
                : undefined
    } as $"
    class="typo-t2 relative rounded-lg transition-shadow"
    [ngClass]="{
        'flex items-center justify-center': !$.preview,
        'size-56': size === 'small',
        'size-84': size === 'regular',
        'size-148': size === 'large',
        'size-200': size === 'extra-large',
        'ring-primary ring-2': active
    }"
    [ngStyle]="{
        background: !$.preview ? attachment.iconColor : 'unset'
    }"
>
    <interacta-image
        *ngIf="$.preview"
        class="attachment-preview__image"
        alt="{{ attachment.name }}'s preview image"
        [fallback]="attachment | apply: srcFallback"
        [src]="$.preview"
    ></interacta-image>

    <interacta-image
        *ngIf="!$.preview"
        classes="rounded object-cover"
        alt="{{ attachment.name }}'s preview image"
        [classes]="iconSize === 'regular' ? 'size-48' : 'size-24'"
        [src]="attachment.iconPath || ''"
    ></interacta-image>
</div>
