export interface GooglePickerDocument {
    driveId: string;
    name?: string;
    mimeType: string;
    webViewLink: string;
    embedLink?: string;
}

export class GooglePickerDeserializer {
    public static document = (pickedDocument: any): GooglePickerDocument => {
        const webViewLink: string = pickedDocument[google.picker.Document.URL];
        return {
            driveId: pickedDocument[google.picker.Document.ID],
            name: pickedDocument[google.picker.Document.NAME],
            mimeType: pickedDocument[google.picker.Document.MIME_TYPE],
            webViewLink: webViewLink,
            embedLink: webViewLink.replace(/\/(view|edit)/, '/preview'),
        };
    };

    public static response = (response: any): GooglePickerDocument[] => {
        switch (response[google.picker.Response.ACTION]) {
            case google.picker.Action.CANCEL:
                return [];
            case google.picker.Action.PICKED:
                return response[google.picker.Response.DOCUMENTS].map(
                    GooglePickerDeserializer.document,
                );
            default:
                return null;
        }
    };
}
