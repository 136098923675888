import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { OrganizationItem } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    ENVIRONMENT,
} from '@interacta-shared/data-access-configuration';
import { InputSelectSearchEvent } from '@interacta-shared/ui';
import {
    IList,
    PaginatedList,
    paginatedListFromIList,
    toPaginatedList,
} from '@interacta-shared/util';
import { toAdminV2Group } from '@modules/admin-v2-group/models/admin-v2-group.deserialize';
import { AdminV2Group } from '@modules/admin-v2-group/models/admin-v2-group.model';
import { AdminV2OrganizationType } from '@modules/admin-v2-organization/models/admin-v2-organization.model';
import { AdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { AdminV2OccToken } from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import {
    toAdminV2UserCredentials,
    toAdminV2UserDetail,
    toAdminV2UserRow,
    toIUserReverseFullName,
} from '@modules/admin-v2-user/models/admin-v2-user.deserialize';
import {
    AdminV2Email,
    AdminV2NewUser,
    AdminV2ResetCustomCredentials,
    AdminV2User,
    AdminV2UserCredentials,
    AdminV2UserDetail,
    AdminV2UserFilters,
    AdminV2UserListPage,
    AdminV2UserSort,
} from '@modules/admin-v2-user/models/admin-v2-user.model';
import {
    fromAdminV2NewUser,
    fromAdminV2UserCredentials,
    fromAdminV2UserDetail,
    fromAdminV2UserFilters,
    fromAdminV2UserRegistryResetCustomCredentials,
} from '@modules/admin-v2-user/models/admin-v2-user.serialize';
import {
    toAdminV2WorkspaceRoleAssociationsList,
    toAdminV2WorkspaceSelected,
} from '@modules/admin-v2-workspace/models/admin-v2-workspace.deserialize';
import {
    AdminV2Workspace,
    AdminV2WorkspaceRoleAssociations,
    AdminV2WorkspaceSelected,
} from '@modules/admin-v2-workspace/models/admin-v2-workspace.model';
import { PartialSharedProfile } from '@modules/core';
import { ApiV2AdminUsersFiltersRequest } from '@modules/core/models/admin-api-v2-filters/admin-api-v2-filters.model';
import { UserSearchDeserialize } from '@modules/core/models/user-autocomplete.model';
import { Observable, map } from 'rxjs';
import { getDefaultBaseApiV2AdminFilterRequest } from './../../core/models/admin-api-v2-filters/admin-api-v2-filters.model';

@Injectable({ providedIn: 'root' })
export class AdminV2UserService {
    private readonly baseUrlData = `${inject(ENVIRONMENT).apiBasePath.common}/admin/data/users`;
    private readonly baseUrlManage = `${inject(ENVIRONMENT).apiBasePath.common}/admin/manage`;
    private readonly baseUrlDataV2 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/admin/data`;
    private readonly baseUrlManageV3 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v3/admin/manage`;
    private readonly baseUrlDataV3 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v3/admin/data/users`;

    private readonly configurationService = inject(ConfigurationService);
    private readonly http = inject(HttpClient);

    getUserList(
        filters: AdminV2UserFilters,
        paginatedFilters?: AdminV2PaginatedFilters,
        sort?: AdminV2UserSort,
    ): Observable<AdminV2UserListPage> {
        const userFilters = fromAdminV2UserFilters(
            filters,
            paginatedFilters,
            sort,
        );
        return this.http
            .post<any>(`${this.baseUrlData}`, userFilters)
            .pipe(map((res) => toPaginatedList(res, toAdminV2UserRow)));
    }

    searchUsers(
        filters: AdminV2UserFilters,
        paginatedFilters?: AdminV2PaginatedFilters,
        sort?: AdminV2UserSort,
    ): Observable<PaginatedList<PartialSharedProfile>> {
        const userFilters = fromAdminV2UserFilters(
            filters,
            paginatedFilters,
            sort,
        );
        return this.http
            .post<any>(`${this.baseUrlData}`, userFilters)
            .pipe(map((res) => toPaginatedList(res, toIUserReverseFullName)));
    }

    searchUsersV2(
        filter: Partial<ApiV2AdminUsersFiltersRequest>,
    ): Observable<PaginatedList<PartialSharedProfile>> {
        return this.http
            .post<any>(`${this.baseUrlDataV2}/users`, {
                ...getDefaultBaseApiV2AdminFilterRequest(),
                ...filter,
            })
            .pipe(
                map((res) => UserSearchDeserialize.userSearch(res)),
                map((people: IList<PartialSharedProfile>) =>
                    paginatedListFromIList(people),
                ),
            );
    }

    // cambia o richiede il reset della password del singolo utente
    resetUserCustomCredentials(
        userId: AdminV2User['id'],
        command: AdminV2ResetCustomCredentials,
    ): Observable<void> {
        return this.http.post<void>(
            `${this.baseUrlManage}/users/${userId}/custom-credentials/reset`,
            fromAdminV2UserRegistryResetCustomCredentials(command),
        );
    }

    enableTwoStepAuthenticationByIds(
        userIds: AdminV2User['id'][],
    ): Observable<{ notActivated: number }> {
        return this.http.put<{
            notActivated: number;
        }>(`${this.baseUrlManageV3}/users/two-step-authentication-enabled`, {
            userIds,
        });
    }

    // blocca l'utente
    blockById(userId: AdminV2User['id']): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrlManage}/users/${userId}/block`,
            null,
        );
    }

    // AZIONI MASSIVE

    // azione che fa si che al prossimo login venga chiesto agli utenti di cambiare la password (le credenziali custom sono user/password)
    expireUsersCustomCredentials(
        userIds: AdminV2User['id'][],
    ): Observable<void> {
        return this.http.post<void>(
            `${this.baseUrlManageV3}/users/custom-credentials/expire`,
            {
                userIds,
            },
        );
    }

    expireAllFilteredUsersCustomCredentials(
        filters: AdminV2UserFilters,
    ): Observable<void> {
        return this.http.post<void>(
            `${this.baseUrlManageV3}/users/custom-credentials/expire/all`,
            {
                filters: fromAdminV2UserFilters(filters),
            },
        );
    }

    // cancella gli utenti
    deleteByIds(userIds: AdminV2User['id'][]): Observable<void> {
        return this.http.post<void>(`${this.baseUrlManageV3}/users/delete`, {
            userIds,
        });
    }

    deleteAllFiltered(filter: AdminV2UserFilters): Observable<void> {
        return this.http.post<void>(
            `${this.baseUrlManageV3}/users/delete/all`,
            {
                filters: fromAdminV2UserFilters(filter),
            },
        );
    }

    // aggiungi o togli utenti dal workspace
    editWorkspaces(
        userIds: AdminV2User['id'][],
        addWorkspaceIds: AdminV2Workspace['id'][],
        removeWorkspaceIds: AdminV2Workspace['id'][],
    ): Observable<void> {
        return this.http.post<void>(
            `${this.baseUrlManage}/workspaces/member-users`,
            {
                usersIds: userIds,
                addWorkspacesIds: addWorkspaceIds,
                removeWorkspacesIds: removeWorkspaceIds,
            },
        );
    }

    // aggiungi o togli utenti dal workspace
    editAllFilteredWorkspaces(
        filters: AdminV2UserFilters,
        workspaceIds: AdminV2Workspace['id'][],
        add: boolean,
    ): Observable<void> {
        return this.http.post<void>(
            `${this.baseUrlManageV3}/workspaces/member-users/${
                add ? 'add' : 'remove'
            }/all`,
            {
                filters: fromAdminV2UserFilters(filters),
                workspaceIds,
            },
        );
    }

    // assign users to manager
    assignUsersToManager(
        userIds: AdminV2User['id'][],
        managerId: AdminV2User['id'] | null,
    ): Observable<void> {
        return this.http.put<void>(`${this.baseUrlManageV3}/users/manager`, {
            userIds,
            managerId,
        });
    }

    assignAllUsersToManager(
        managerId: AdminV2User['id'],
        filters: AdminV2UserFilters,
    ): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrlManageV3}/users/manager/all`,
            {
                filters: fromAdminV2UserFilters(filters),
                managerId,
            },
        );
    }

    // operazione massiva di aggiunta utenti ad una business unit o area

    assignAllUsersToOrganization(
        organizationId: OrganizationItem['id'],
        filters: AdminV2UserFilters,
        organizationType: AdminV2OrganizationType,
    ): Observable<void> {
        const path =
            organizationType === 'businessUnit' ? 'business-unit' : 'area';
        const parameter =
            organizationType === 'businessUnit' ? 'businessUnitId' : 'areaId';
        return this.http.put<void>(
            `${this.baseUrlManageV3}/users/${path}/all`,
            {
                filters: fromAdminV2UserFilters(filters),
                [parameter]: organizationId,
            },
        );
    }

    getUserGroups(userId: AdminV2User['id']): Observable<AdminV2Group[]> {
        return this.http
            .get<any>(`${this.baseUrlData}/${userId}/groups`)
            .pipe(map((res) => res.items.map(toAdminV2Group)));
    }

    // operazione massiva di aggiunta utenti ai gruppi
    updateMembersByFilters(
        groupIds: AdminV2Group['id'][],
        filter: AdminV2UserFilters,
        add: boolean,
    ): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrlManageV3}/groups/members/${
                add ? 'add' : 'remove'
            }/all`,
            {
                groupIds,
                filters: fromAdminV2UserFilters(filter),
            },
        );
    }

    //ripristina utenti cancellati
    restoreByIds(userIds: AdminV2User['id'][]): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrlManage}/users/${userIds[0]}/restore`,
            null,
        );
    }

    //sblocca utenti bloccati
    unblockByIds(userIds: AdminV2User['id'][]): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrlManage}/users/${userIds[0]}/restore`,
            null,
        );
    }

    getEditUser(userId: AdminV2User['id']): Observable<AdminV2UserDetail> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${userId}/detail`)
            .pipe(
                map((res) =>
                    toAdminV2UserDetail(res, this.configurationService),
                ),
            );
    }

    getEditUserCredentials(
        userId: AdminV2User['id'],
    ): Observable<AdminV2UserCredentials> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${userId}/credentials`)
            .pipe(map((res) => toAdminV2UserCredentials(res)));
    }

    // Abilitazione e Ruoli
    getWorkspaceRoleAssociationsList(
        userId: AdminV2User['id'],
    ): Observable<AdminV2WorkspaceRoleAssociations[]> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${userId}/role-associations`)
            .pipe(
                map((result) => toAdminV2WorkspaceRoleAssociationsList(result)),
            );
    }

    getSelectedWorkspaces(
        userId: AdminV2User['id'],
    ): Observable<AdminV2WorkspaceSelected[]> {
        return this.http
            .get<any>(`${this.baseUrlData}/${userId}/workspaces`)
            .pipe(map((res) => res.items.map(toAdminV2WorkspaceSelected)));
    }

    editUser(userDetail: AdminV2UserDetail): Observable<number> {
        return this.http
            .put<{
                nextOccToken: AdminV2OccToken;
            }>(
                `${this.baseUrlManage}/users/${userDetail.id}`,
                fromAdminV2UserDetail(userDetail),
            )
            .pipe(map(({ nextOccToken }) => nextOccToken));
    }

    editUserCredentials(
        userId: AdminV2User['id'],
        credentials: AdminV2UserCredentials,
        occToken: AdminV2OccToken,
    ): Observable<{
        occToken: AdminV2OccToken;
        credentials: AdminV2UserCredentials;
    }> {
        return this.http
            .put<any>(`${this.baseUrlManage}/users/${userId}/credentials`, {
                userCredentialsConfiguration:
                    fromAdminV2UserCredentials(credentials),
                occToken: occToken,
            })
            .pipe(
                map((res) => ({
                    occToken: res.nextOccToken,
                    credentials: toAdminV2UserCredentials(res),
                })),
            );
    }

    createUser(
        user: AdminV2NewUser,
        credentials: AdminV2UserCredentials,
        command?: AdminV2ResetCustomCredentials,
    ): Observable<AdminV2User['id']> {
        return this.http
            .post<{ userId: number }>(`${this.baseUrlManage}/users`, {
                ...fromAdminV2NewUser(user),
                userCredentialsConfiguration:
                    fromAdminV2UserCredentials(credentials),
                resetUserCustomCredentialsCommand: command
                    ? fromAdminV2UserRegistryResetCustomCredentials(command)
                    : null,
            })
            .pipe(map(({ userId }) => userId));
    }

    getUserEmails(
        filters: InputSelectSearchEvent,
    ): Observable<PaginatedList<AdminV2Email>> {
        const emailFullTextFilter = filters.text;
        const pageToken = filters.nextPageToken;
        return this.http
            .post<any>(`${this.baseUrlDataV3}/email`, {
                emailFullTextFilter,
                pageToken,
            })
            .pipe(map((res) => toPaginatedList(res)));
    }
}
