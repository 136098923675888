import {
    animate,
    AnimationTriggerMetadata,
    style,
    transition,
    trigger,
} from '@angular/animations';

export function fadeInOutAnimation(
    name: string,
    msDuration = 200,
    easing: 'ease-out' | 'ease' | 'ease-in' | 'ease-in-out' = 'ease',
): AnimationTriggerMetadata {
    return trigger(name, [
        transition(':enter', [
            style({
                opacity: 0,
            }),
            animate(
                `${msDuration}ms ${easing}`,
                style({
                    opacity: 1,
                }),
            ),
        ]),
        transition(':leave', [
            animate(
                `${msDuration}ms ${easing}`,
                style({
                    opacity: 0,
                }),
            ),
        ]),
    ]);
}
