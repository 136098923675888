import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    ENVIRONMENT,
    Language,
} from '@interacta-shared/data-access-configuration';
import { IList } from '@interacta-shared/util';
import { AdminV2RolesType } from '@modules/admin-v2-roles/models/admin-v2-roles.model';
import { OperationalRolesSearchRequest } from '@modules/core/models/user-autocomplete.model';
import {
    IOperativeRole,
    UserExtendedDeserialize,
} from '@modules/core/models/user-group.model';
import { Observable, map } from 'rxjs';
import {
    toAdminV2AdminRoleInfo,
    toAdminV2CapabilitiesTable,
    toAdminV2OperationalRoleInfo,
} from '../models/admin-v2-roles.deserialize';
import {
    AdminV2AdminRoleInfo,
    AdminV2NewRole,
    AdminV2OperationalRoleInfo,
    AdminV2Role,
    AdminV2RolesTable,
} from '../models/admin-v2-roles.model';
import { fromAdminV2Role } from '../models/admin-v2-roles.serialize';

@Injectable({ providedIn: 'root' })
export class AdminV2RolesService {
    private readonly baseUrlData = `${inject(ENVIRONMENT).apiBasePath.common}/admin/data`;
    private readonly baseUrlDataV3 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v3/admin/data/roles`;
    private readonly baseUrlManageV3 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v3/admin/manage/roles`;

    private readonly http = inject(HttpClient);

    getCapabilitiesList(): Observable<{
        adminList: AdminV2RolesTable;
        operationalList: AdminV2RolesTable;
    }> {
        return this.http.get<any>(`${this.baseUrlDataV3}`).pipe(
            map((res) => ({
                adminList: toAdminV2CapabilitiesTable(res.adminRoles, 'admin'),
                operationalList: toAdminV2CapabilitiesTable(
                    res.operationalRoles,
                    'operational',
                ),
            })),
        );
    }

    getRolesList(
        loadAdminRoles = true,
        loadOperationalRoles = true,
    ): Observable<{
        adminList: AdminV2Role[];
        operationalList: AdminV2Role[];
    }> {
        return this.http
            .get<{
                adminRoles: AdminV2Role[] | null;
                operationalRoles: AdminV2Role[] | null;
            }>(`${this.baseUrlDataV3}`, {
                params: {
                    loadAdminRoles,
                    loadOperationalRoles,
                },
            })
            .pipe(
                map((res) => ({
                    adminList: res.adminRoles ?? [],
                    operationalList: res.operationalRoles ?? [],
                })),
            );
    }

    createRole(
        role: AdminV2NewRole,
        roleType: AdminV2RolesType,
    ): Observable<AdminV2Role> {
        return this.http
            .post<{
                role: AdminV2Role;
            }>(`${this.baseUrlManageV3}/${roleType}`, role)
            .pipe(map(({ role }) => role));
    }

    editRole(role: AdminV2Role, roleType: AdminV2RolesType): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrlManageV3}/${roleType}/${role.id}`,
            fromAdminV2Role(role),
        );
    }

    deleteRole(id: number, roleType: AdminV2RolesType): Observable<void> {
        return this.http.delete<void>(
            `${this.baseUrlManageV3}/${roleType}/${id}`,
        );
    }

    infoOperationalRole(id: number): Observable<AdminV2OperationalRoleInfo> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/operational/${id}/usage`)
            .pipe(map(toAdminV2OperationalRoleInfo));
    }

    infoAdminRole(id: number): Observable<AdminV2AdminRoleInfo> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/admin/${id}/usage`)
            .pipe(map(toAdminV2AdminRoleInfo));
    }

    searchOperativeRoles(
        search: OperationalRolesSearchRequest,
    ): Observable<IList<IOperativeRole>> {
        return this.http
            .post<any>(`${this.baseUrlData}/operational-roles`, search)
            .pipe(
                map((res: any) =>
                    UserExtendedDeserialize.operationalRoleList(res),
                ),
            );
    }

    getOperativeRoleById(roleId: number): Observable<IOperativeRole> {
        return this.http
            .get<any>(`${this.baseUrlData}/operational-roles/${roleId}`)
            .pipe(map((res) => UserExtendedDeserialize.operativeRole(res)));
    }

    deleteTranslation(language: Language): Observable<void> {
        return this.http.delete<any>(
            `${this.baseUrlManageV3}/i18ns/${language.code}`,
        );
    }
}
