import { inject, Pipe, PipeTransform } from '@angular/core';
import {
    getOrderByCriteriaLabel,
    ItemOrderByCriteria,
} from '@modules/shared-v2/models/orderby';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'orderByCriteriaLabel',
})
export class OrderByCriteriaLabelPipe implements PipeTransform {
    private translateService = inject(TranslateService);

    transform(orderBy: ItemOrderByCriteria): string {
        return getOrderByCriteriaLabel(orderBy, this.translateService);
    }
}
