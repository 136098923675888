<div
    class="bg-surface-A flex w-full gap-36 rounded-lg"
    [class.mt-12]="showFastSelect"
>
    <div class="flex flex-1 flex-col">
        <div
            *ngIf="showFastSelect"
            class="rounded-l-20 rounded-r-20 border-border-500 text-text-mid-contrast flex border-2"
        >
            <button
                class="typo-xs-bold-uppercase flex-1 py-8 text-center"
                type="button"
                (click)="fastSelectClick('yesterday')"
            >
                {{ 'UI.DATE_TIME.YESTERDAY' | translate }}
            </button>
            <button
                class="typo-xs-bold-uppercase border-border-500 flex-1 border-l-2 border-r-2 py-8 text-center"
                type="button"
                (click)="fastSelectClick('today')"
            >
                {{ 'UI.DATE_TIME.TODAY' | translate }}
            </button>
            <button
                class="typo-xs-bold-uppercase flex-1 py-8 text-center"
                type="button"
                (click)="fastSelectClick('tomorrow')"
            >
                {{ 'UI.DATE_TIME.TOMORROW' | translate }}
            </button>
        </div>
        <mat-calendar
            [maxDate]="_maxDate || null"
            [minDate]="_minDate || null"
            [selected]="control.value"
            [startAt]="control.value || now"
            (selectedChange)="selectDate($event)"
        >
        </mat-calendar>
    </div>
    <div
        *ngIf="type === 'date-time'"
        class="flex flex-1 flex-col gap-16"
        [class.pt-16]="!showFastSelect"
    >
        <interacta-input-time-v2
            *ngIf="type === 'date-time'"
            [class.mt-12]="!showFastSelect"
            [editTimezone]="editTimezone"
            [formControl]="timeControl"
            [maxTime]="_maxDate"
            [minTime]="_minDate"
            [readonly]="stepMode === 'quarter'"
            [selectedDate]="selectedDate"
            [stepMode]="stepMode"
            (timeChange)="updateTime($event)"
        >
        </interacta-input-time-v2>
        <div
            [ngClass]="{
                'h-260': !showFastSelect,
                'h-308': showFastSelect
            }"
        >
            <interacta-input-timezone
                *ngIf="editTimezone"
                class="mt-12"
                [control]="timezoneControl"
                [layoutMode]="'expand'"
                [timezones]="timezones"
                [userTimezone]="userTimezone"
            ></interacta-input-timezone>
        </div>
    </div>
</div>
