import {
    adminV2GroupInfo,
    adminV2GroupOverview,
    adminV2GroupRoles,
    adminV2GroupUsers,
} from '@modules/admin-v2-routing/admin-v2-routes';
import {
    AdminV2BreadcrumbItem,
    AdminV2ColumnVisibility,
    AdminV2MenuItem,
} from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import { adminV2Group } from '@modules/app-routing/routes';
import {
    AdminV2GroupFiltersLabel,
    AdminV2GroupRow,
    AdminV2GroupSort,
    AdminV2GroupStatusFilter,
    AdminV2GroupStatusFilterLabel,
} from '../models/admin-v2-group.model';

export const DefaultGroupStatusFilter = AdminV2GroupStatusFilter.ACTIVE_GROUPS;

export const DefaultGroupColumnVisibility: AdminV2ColumnVisibility<AdminV2GroupRow> =
    {
        group: true,
        usersCount: true,
        communities: false,
        workspaces: false,
        visible: true,
        externalId: true,
        tags: false,
        creationTimestamp: false,
    };

export const DefaultGroupSortOrder: AdminV2GroupSort = {
    sortColumn: 'group',
    orderDesc: false,
};

const filtersLabelRoot = 'ADMIN_V2.GROUPS.FILTERS';
export const GroupStatusFilterLabels: AdminV2GroupStatusFilterLabel[] = [
    {
        label: `${filtersLabelRoot}.ACTIVE_GROUPS`,
        value: AdminV2GroupStatusFilter.ACTIVE_GROUPS,
    },
    {
        label: `${filtersLabelRoot}.DELETED_GROUPS`,
        value: AdminV2GroupStatusFilter.DELETED_GROUPS,
    },
    {
        label: `${filtersLabelRoot}.ALL_GROUPS`,
        value: AdminV2GroupStatusFilter.ALL_GROUPS,
    },
];

export const GroupFiltersLabels: AdminV2GroupFiltersLabel = {
    status: `${filtersLabelRoot}.STATUS`,
    [AdminV2GroupStatusFilter.ACTIVE_GROUPS]: `${filtersLabelRoot}.ACTIVE_GROUPS`,
    [AdminV2GroupStatusFilter.DELETED_GROUPS]: `${filtersLabelRoot}.DELETED_GROUPS`,
    [AdminV2GroupStatusFilter.ALL_GROUPS]: `${filtersLabelRoot}.ALL_GROUPS`,
    search: `${filtersLabelRoot}.SEARCH`,
    created: `${filtersLabelRoot}.CREATED`,
    externalId: `${filtersLabelRoot}.EXTERNAL_REFERENCE`,
    tags: `${filtersLabelRoot}.TAG`,
    visibility: `${filtersLabelRoot}.VISIBILITY`,
    workspaces: `${filtersLabelRoot}.WORKSPACE`,
    excludeGroupByMemberUserId: '',
    checkUserMember: '',
};

const labelRootBreadcrumb = 'ADMIN_V2.GROUPS.DETAIL.BREADCRUMB';
export const GroupBreadcrumbItems: AdminV2BreadcrumbItem[] = [
    {
        label: `${labelRootBreadcrumb}.GROUPS`,
        url: adminV2Group,
    },
    {
        label: `${labelRootBreadcrumb}.MANAGE_GROUP`,
    },
];

const labelRootMenu = 'ADMIN_V2.GROUPS.DETAIL.MENU';
export const GroupMenuItems: AdminV2MenuItem[] = [
    {
        label: `${labelRootMenu}.OVERVIEW`,
        url: adminV2GroupOverview,
    },
    {
        label: `${labelRootMenu}.INFO`,
        url: adminV2GroupInfo,
    },
    {
        label: `${labelRootMenu}.USERS`,
        url: adminV2GroupUsers,
    },
    {
        label: `${labelRootMenu}.ROLES`,
        url: adminV2GroupRoles,
    },
];
