<interacta-dialog [isOpen]="isOpen" (closing)="closeDialog.emit()">
    <div class="typo-t6 shrink-0" title>
        {{ 'DATE_RANGE_DIALOG.TITLE' | translate }}
    </div>
    <div content>
        <form [formGroup]="rangeFormGroup">
            <interacta-form-field-v2
                [control]="fromDateControl"
                [name]="'DATE_RANGE_DIALOG.FROM_DATE' | translate"
            >
                <interacta-input-date
                    [control]="fromDateControl"
                    [size]="'small'"
                ></interacta-input-date>
            </interacta-form-field-v2>
            <interacta-form-field-v2
                [control]="toDateControl"
                [name]="'DATE_RANGE_DIALOG.TO_DATE' | translate"
            >
                <interacta-input-date
                    [control]="toDateControl"
                    [size]="'small'"
                ></interacta-input-date>
            </interacta-form-field-v2>
        </form>
    </div>
    <interacta-dialog-buttons>
        <button
            appearance="fill"
            interacta-dialog-button
            type="button"
            [bgColor]="'primary'"
            [disabled]="!rangeFormGroup.valid"
            [label]="'BUTTON.LABEL_BUTTON_CONFIRM' | translate"
            (click)="confirm()"
        ></button>
    </interacta-dialog-buttons>
</interacta-dialog>
