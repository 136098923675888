import { CurrentUser } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    defaultSettings,
    toDateTimeFromString,
} from '@interacta-shared/data-access-configuration';
import { CommunityTreeDeserialize } from '@modules/communities/models/communities.model';
import {
    toCustomFieldAdminValidation,
    toCustomFieldDefinitions,
    toFieldCustomMetadata,
} from '@modules/communities/models/custom-metadata/custom-metadata.deserialize';
import {
    FieldType,
    ICustomFieldMetadata,
    MetadataFieldModeType,
} from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { getConfigurableFieldTypeInfoFromRecord } from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import { UserExtendedDeserialize } from '@modules/core';
import { MemberGroup } from '@modules/core/models/member/member.model';
import { wrapMember } from '@modules/core/models/member/member.utils';
import { Tag } from '@modules/core/models/tag/tag.model';
import { UserDeserilize } from '@modules/core/models/user.model';
import { toCustomFieldsConfigurationEnumValues } from '@modules/custom-fields-configuration/models/custom-fields-configuration.deserialize';
import {
    IPostMetadata,
    RecipientGroupCardinality,
} from '@modules/post/models/base-post.model';
import { BasePostDeserialize, PostType } from './../base-post.model';
import {
    SurveyAnswerViewerType,
    SurveyPost,
    SurveyPostCreate,
    SurveyPostEdit,
    SurveyQuestionForCreate,
    SurveyRecipientTagExcludedGroups,
    SurveyRecipients,
    SurveyRecipientsGroup,
    SurveyType,
} from './survey-post.model';
import { buildSurveyState } from './survey-post.utils';

export class SurveyPostDeserialize extends BasePostDeserialize {
    private static dateInfo = (
        record: any,
    ): { zonedDatetime?: Date; localDatetime: Date } => ({
        zonedDatetime:
            toDateTimeFromString(record.zonedDatetime as string) ?? undefined,
        localDatetime: toDateTimeFromString(record.localDatetime as string)!,
    });

    private static surveyRecipients = (record: any): SurveyRecipients => {
        const recipientTagsFromResponse =
            (record.recipients?.groupTags as {
                tag: Tag;
                excludedGroups: any[];
            }[]) ?? [];
        const groupTags = recipientTagsFromResponse.map((r) => r.tag);
        const groupTagExcludedGroups: SurveyRecipientTagExcludedGroups[] =
            recipientTagsFromResponse.map((gteg) => ({
                tagId: gteg.tag.id,
                excludedGroups: (gteg.excludedGroups || []).map(
                    SurveyPostDeserialize.toSurveyRecipientsGroup,
                ),
            }));

        return {
            users: (record.recipients?.users || []).map((u: any) =>
                wrapMember(UserDeserilize.userDetails(u)),
            ),
            groups: (record.recipients?.groups || []).map(
                SurveyPostDeserialize.toSurveyRecipientsGroup,
            ),
            groupTags,
            groupTagExcludedGroups,
            recipientUsersTotalCount:
                record.recipients?.recipientUsersTotalCount ?? 0,
            recipientGroupCardinality:
                record.recipients?.recipientGroupCardinality ??
                RecipientGroupCardinality.ALL,
        };
    };

    static toSurveyRecipientsGroup = (g: any): SurveyRecipientsGroup => {
        return {
            ...(wrapMember(
                UserExtendedDeserialize.usersGroup(g),
            ) as MemberGroup),
            membersCount: g.membersCount,
            recipientsResponseCount: g.recipientsResponseCount,
            email: g.email,
            visible: g.visible,
        };
    };

    static surveyPostDetails = (
        configurationService: ConfigurationService,
        recordPost: any,
        toCreateAckTasksCount?: number,
        metadata?: IPostMetadata,
        surveyType?: SurveyType,
    ): SurveyPost => {
        const survey = recordPost.survey ?? {};

        const post = <
            Partial<SurveyPost> &
                Pick<SurveyPost, 'startAt' | 'closingTimestamp'>
        >{
            type: PostType.SURVEY,
            tag: PostType.SURVEY,
            surveyType:
                (survey.surveyType as SurveyType) ??
                surveyType ??
                SurveyType.SURVEY,
            startAt: survey.startAt
                ? SurveyPostDeserialize.dateInfo(survey.startAt)
                : undefined,
            expiration: survey.expiration
                ? SurveyPostDeserialize.dateInfo(survey.expiration)
                : undefined,
            scheduledClosing: survey.scheduledClosing
                ? SurveyPostDeserialize.dateInfo(survey.scheduledClosing)
                : undefined,
            timezone: survey.timezone
                ? configurationService.getTimezone(survey.timezone as string)
                : undefined,
            closingUser: survey.closingUser
                ? UserDeserilize.userDetails(survey.closingUser)
                : null,
            closingTimestamp: survey.closingTimestamp
                ? new Date(survey.closingTimestamp as number)
                : null,
            fieldDefinitions:
                survey.fieldDefinitions &&
                Array.isArray(survey.fieldDefinitions)
                    ? toCustomFieldDefinitions(
                          survey.fieldDefinitions,
                          MetadataFieldModeType.SURVEY,
                      )
                    : [],
            recipientAnswerViewers: survey.recipientAnswerViewers ?? false,
            answerViewerType:
                (survey.answerViewersTypeId as SurveyAnswerViewerType) ??
                SurveyAnswerViewerType.CUSTOM,
            recipients: SurveyPostDeserialize.surveyRecipients(survey),
            managers:
                survey.managerUsers || survey.managerGroups
                    ? {
                          users: (<Array<any> | undefined>(
                              survey.managerUsers
                          ))?.map((u) =>
                              wrapMember(UserDeserilize.userDetails(u)),
                          ),
                          groups: (<Array<any> | undefined>(
                              survey.managerGroups
                          ))?.map((g) =>
                              wrapMember(UserExtendedDeserialize.usersGroup(g)),
                          ),
                      }
                    : undefined,
            customAnswerViewer:
                survey.customAnswerViewerUsers ||
                survey.customAnswerViewerGroups
                    ? {
                          users: (<Array<any> | undefined>(
                              survey.customAnswerViewerUsers
                          ))?.map((u) =>
                              wrapMember(UserDeserilize.userDetails(u)),
                          ),
                          groups: (<Array<any> | undefined>(
                              survey.customAnswerViewerGroups
                          ))?.map((g) =>
                              wrapMember(UserExtendedDeserialize.usersGroup(g)),
                          ),
                      }
                    : undefined,
            surveyLanguage: recordPost.language ?? defaultSettings.language,
            toCreateSurveyTasksCount: toCreateAckTasksCount,
        };

        post.state = buildSurveyState(
            post.startAt,
            post.scheduledClosing,
            post.closingTimestamp,
        );

        //todo mancano gli altri campi
        return SurveyPostDeserialize.basePostDetails(
            post as SurveyPost,
            recordPost,
            configurationService,
            metadata,
        );
    };

    static surveyPostDetailsForEdit = (
        configurationService: ConfigurationService,
        record: any,
        metadata: IPostMetadata,
        surveyType?: SurveyType,
    ): SurveyPostEdit => {
        const copyOfSurveyFieldsDefinition = [
            ...(record?.survey?.fieldDefinitions || []),
        ];

        /*DO NOT REMOVE THIS
         **** Il BE restituisce fieldDefinitions a prescindere che il dato sia in VISUALIZZAZIONE o in MODIFICA
         **** il dato però è leggermente diverso ed è necessario mapparlo in un modo o nell'altro
         **** in questo caso siamo in MODIFICA
         **** in VISUALIZZAZIONE  utilizziamo surveyPostDetails con fieldDefinitions valorizzato correttamente
         **** in MODIFICA utilizziamo copyOfSurveyFieldsDefinition rimappato su surveyQuestionDefinition
         */
        if (record.survey) {
            record.survey.fieldDefinitions = null;
        }

        const basePost = SurveyPostDeserialize.surveyPostDetails(
            configurationService,
            record,
            undefined,
            metadata,
            surveyType,
        );
        const post: SurveyPostEdit = {
            ...basePost,
            sendNotification: false,
            surveyQuestions: copyOfSurveyFieldsDefinition.map(
                surveyQuestionDefinition,
            ),
            updateAttachments: [],
            removeAttachmentIds: [],
            removeWatcherUserIds: [],
            removeWatcherGroupIds: [],
        };
        return SurveyPostDeserialize.basePostDetailsForEdit(post, record);
    };

    static surveyPostDetailsForCreate = (
        configurationService: ConfigurationService,
        record: any,
        metadata: IPostMetadata,
        currentUser: CurrentUser,
        surveyType?: SurveyType,
    ): SurveyPostCreate => {
        const recordManipulation = {
            ...record.contentData,
            ...record,
        };
        const post = SurveyPostDeserialize.surveyPostDetailsForEdit(
            configurationService,
            recordManipulation,
            metadata,
            surveyType,
        );
        return SurveyPostDeserialize.basePostDetailsForCreate(
            post,
            currentUser,
        );
    };
}
const surveyQuestionDefinition = (field: any): SurveyQuestionForCreate => {
    const meta: { [key: string]: string } = field.metadata;

    const metadata: ICustomFieldMetadata = toFieldCustomMetadata(meta);

    const questionId = field.id as number;
    const validations = toCustomFieldAdminValidation(field.validations);

    const question: SurveyQuestionForCreate = {
        id: questionId,
        localId: questionId,
        ref: field.ref,
        label: field.label,
        description: field.description
            ? CommunityTreeDeserialize.getDescriptionI18n(field.description)
            : null,
        type: getConfigurableFieldTypeInfoFromRecord(
            field.type as FieldType,
            metadata,
            validations,
        ),
        fieldType: field.type,
        validations,
        required: field.required,
        deleted: field.deleted,
        childIds: field.childIds ?? [],
        parentId: field.parentId,
        readonly: field.readonly,
        metadata: metadata,
        originalMetadata: metadata,
        enumValues: (field.enumValues || []).map(
            toCustomFieldsConfigurationEnumValues,
        ),
        order: field.order,
    };
    return question;
};
