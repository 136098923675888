import { Injectable } from '@angular/core';
import { flatten, isDefined, unique } from '@interacta-shared/util';
import { ICatalogsOperativityList } from '@modules/communities/models/catalog/catalog.model';
import {
    ICommunity,
    ICommunityTree,
    sanitizeCachedCommunity,
} from '@modules/communities/models/communities.model';
import { ICustomFieldDefinition } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { isCatalogGenericListField } from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import { CommunitiesService } from '@modules/communities/services/communities.service';
import { fetchQuickFilters } from '@modules/communities/store/community/community.actions';
import { fetchDifferential } from '@modules/communities/store/differential/differential.actions';
import { fetchHomeDefinitions } from '@modules/communities/store/home-pages/home-pages.actions';
import { AppState } from '@modules/communities/store/post/post.reducer';
import * as PostSelectors from '@modules/communities/store/post/post.selectors';
import { PushChannelsService } from '@modules/core';
import {
    flatMap,
    idArraytoMap,
    mPartition,
} from '@modules/core/helpers/generic.utils';
import { LocalStorageService } from '@modules/core/services/local-storage.service';
import {
    IPostMetadata,
    getPostDefinitionCatalogIds,
} from '@modules/post/models/base-post.model';
import { Store } from '@ngrx/store';
import {
    EMPTY,
    Observable,
    Subject,
    combineLatest,
    forkJoin,
    from,
    of,
} from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    first,
    map,
    mergeMap,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
    throttleTime,
    toArray,
} from 'rxjs/operators';
import { CommunitiesState } from '../models/communities-state.model';
import { CatalogsStateService } from './catalogs-state.service';
import { IStateService } from './istate-service';
import { StateService } from './state.service';

const communitiesCacheKey = 'communities';

interface OrganizationTrees {
    organizationTree: ICommunityTree;
    insightOrganizationTree: ICommunityTree;
}

@Injectable({ providedIn: 'root' })
export class CommunitiesStateService
    implements IStateService<CommunitiesState>
{
    readonly state: CommunitiesState;

    private communitiesCache!: Record<number, Observable<ICommunity>>;
    private postMetadataCache!: Record<number, Observable<IPostMetadata>>;

    private destroy$!: Subject<void>;

    constructor(
        stateService: StateService,
        private catalogsStateService: CatalogsStateService,
        private communitiesService: CommunitiesService,
        private firebasePushtipsService: PushChannelsService,
        private store: Store<AppState>,
        private localStorageService: LocalStorageService,
    ) {
        this.state = stateService.communitiesState;
    }

    /**
     * Returns an Observable containing the community details which resolves
     * immediately from the cache when present, otherwise it will return
     * a new request which will be added to the cache
     */
    getCommunity(communityId: number): Observable<ICommunity> {
        return this.state.isInitialized$.pipe(
            first((isInitialized) => isInitialized),
            switchMap(() => {
                if (this.communitiesCache[communityId] != null) {
                    return this.communitiesCache[communityId];
                } else {
                    const request$ = this.communitiesService
                        .getCommunityDetails(communityId)
                        .pipe(shareReplay(1));

                    this.communitiesCache[communityId] = request$;

                    return request$;
                }
            }),
        );
    }

    getCommunityMembersCount(communityId: number): Observable<number> {
        return this.getCommunity(communityId).pipe(
            switchMap((community) => {
                if (community.membersCount != null) {
                    return of(community.membersCount);
                } else {
                    return this.communitiesService
                        .getCommunityDetails(communityId)
                        .pipe(map((community) => community.membersCount || 0));
                }
            }),
        );
    }

    injectMetadata(community: ICommunity): Observable<ICommunity> {
        return community.metadata
            ? of(community)
            : this.getPostMetadata(community.id).pipe(
                  map((metadata) => ({
                      ...community,
                      metadata,
                  })),
              );
    }

    /**
     * Returns an Observable containing the post-metadata which resolves
     * immediately from the cache when present, otherwise it will b
     * a new request which will be added to the cache
     */
    getPostMetadata(communityId: number): Observable<IPostMetadata> {
        return this.state.isInitialized$.pipe(
            first((isInitialized) => isInitialized),
            switchMap((_) => {
                if (this.postMetadataCache[communityId] != null) {
                    return this.postMetadataCache[communityId];
                } else {
                    const request$ = this.communitiesService
                        .getPostMetadata(communityId)
                        .pipe(
                            switchMap((postDefinition) =>
                                this.catalogsStateService
                                    .getCatalogs(
                                        this.getReferencedCatalogIds([
                                            postDefinition,
                                        ]),
                                    )
                                    .pipe(
                                        map((catalogs) =>
                                            this.updatePostDefinitionWithEnumValues(
                                                catalogs,
                                                postDefinition,
                                            ),
                                        ),
                                    ),
                            ),
                            shareReplay(1),
                        );

                    this.postMetadataCache[communityId] = request$;

                    return request$;
                }
            }),
        );
    }

    getCurrentCommunity(): Observable<ICommunity | null> {
        return combineLatest([
            this.state.communityTree$,
            this.store.select(PostSelectors.selectDashboardCommunityId),
        ]).pipe(
            map(([communityTree, communityId]) =>
                communityId
                    ? communityTree?.communityList.find(
                          (c) => c.id === communityId,
                      ) ?? null
                    : null,
            ),
            shareReplay(1),
        );
    }

    clearCommunitiesCache(allUsers?: boolean): Observable<void> {
        if (allUsers) {
            return this.localStorageService.bulkDeleteIdbEntries(
                new RegExp(communitiesCacheKey),
            );
        } else {
            return this.localStorageService.deleteIdbEntry(
                communitiesCacheKey,
                true,
            );
        }
    }

    initializeCache(): void {
        this.communitiesCache = {};
        this.postMetadataCache = {};

        // Whenever the communityTree changes, the cache is updated
        this.state.communityTree$
            .pipe(takeUntil(this.destroy$), filter(isDefined))
            .subscribe((tree) => {
                const communitiesCache: Record<number, ICommunity> = {};

                for (const community of tree.communityList) {
                    this.communitiesCache[community.id] = of(community);
                    communitiesCache[community.id] = community;
                }
                this.localStorageService
                    .setIdbEntry(communitiesCacheKey, communitiesCache)
                    .subscribe();
            });
    }

    private initializeCommunityTree(
        trees$: Observable<OrganizationTrees>,
    ): void {
        trees$
            .pipe(
                map((t) => t.organizationTree),
                switchMap((organizationTree) =>
                    this.catalogsStateService
                        .getCatalogs(
                            this.getReferencedCatalogIds(
                                organizationTree.communityList
                                    .map((c) => c.metadata)
                                    .filter(isDefined),
                            ),
                        )
                        .pipe(
                            map((catalogs) => ({
                                organizationTree,
                                catalogs,
                            })),
                        ),
                ),
            )
            .subscribe(({ organizationTree, catalogs }) => {
                const workspaces = idArraytoMap(organizationTree.workspaceList);

                const organizationTreeWithEnumValues = {
                    ...organizationTree,
                    communityList: organizationTree.communityList.map((c) => ({
                        ...c,
                        metadata: c.metadata
                            ? this.updatePostDefinitionWithEnumValues(
                                  catalogs,
                                  c.metadata,
                              )
                            : c.metadata,
                        workspaceName: workspaces[c.workspaceId]?.name,
                    })),
                };

                for (const community of organizationTreeWithEnumValues.communityList) {
                    this.communitiesCache[community.id] = of(community);
                    if (community.metadata) {
                        this.postMetadataCache[community.id] = of(
                            community.metadata,
                        );
                    }
                }

                this.state.communityTree$.next(organizationTreeWithEnumValues);
                this.state.pinnedCommunitiesIds$.next(
                    organizationTree.communityList
                        .filter((c) => c.pinnedTimestamp)
                        .map((c) => ({
                            id: c.id,
                            pinnedTimestamp: c.pinnedTimestamp,
                        })),
                );

                this.store.dispatch(fetchQuickFilters());

                this.store.dispatch(fetchHomeDefinitions({ force: false }));
            });
    }

    getAdminCommunityTree(loadCapabilities = true): Observable<ICommunityTree> {
        if (isDefined(this.state.adminCommunityTree)) {
            return of(this.state.adminCommunityTree);
        } else {
            return this.communitiesService
                .adminCommunityTree(loadCapabilities)
                .pipe(tap((tree) => (this.state.adminCommunityTree = tree)));
        }
    }

    private initializeInsightCommunities(
        trees$: Observable<OrganizationTrees>,
    ): void {
        trees$
            .pipe(map((t) => t.insightOrganizationTree))
            .subscribe((insightTree) => {
                const workspaces = idArraytoMap(insightTree.workspaceList);
                const organizationTreeWithEnumValues: ICommunityTree = {
                    ...insightTree,
                    communityList: insightTree.communityList.map((c) => ({
                        ...c,
                        workspaceName: workspaces[c.workspaceId]?.name,
                    })),
                };

                organizationTreeWithEnumValues.workspaceList.forEach(
                    (workspace) => {
                        workspace.communities?.forEach((community) => {
                            community.metadata =
                                organizationTreeWithEnumValues.communityList.find(
                                    (c) => c.id === community.id,
                                )?.metadata;
                        });
                    },
                );

                for (const community of organizationTreeWithEnumValues.communityList) {
                    if (!this.communitiesCache[community.id]) {
                        this.communitiesCache[community.id] = of(community);
                    }

                    if (
                        community.metadata &&
                        !this.postMetadataCache[community.id]
                    ) {
                        this.postMetadataCache[community.id] = of(
                            community.metadata,
                        );
                    }
                }
                this.state.insightsCommunitiesTree$.next(
                    organizationTreeWithEnumValues,
                );
            });
    }

    initializeCommunityTrees(): void {
        const trees$ = this.getCommunitiesCache$().pipe(
            switchMap((communitiesCache) =>
                this.refreshCommunitiesFromCache(communitiesCache),
            ),
            shareReplay(1),
        );

        this.initializeCommunityTree(trees$);
        this.initializeInsightCommunities(trees$);
    }

    initialize(): void {
        this.destroy$ = new Subject<void>();

        this.initializeCache();
        this.initializeCommunityTrees();

        this.firebasePushtipsService
            .getUserPostsLastUpdateTimestamp$()
            .pipe(
                takeUntil(this.destroy$),
                throttleTime(2000, undefined, {
                    trailing: true,
                    leading: false,
                }),
            )
            .subscribe((_) => this.store.dispatch(fetchDifferential()));
    }

    flush(): void {
        this.state.communityTree$.next(null);
        this.state.insightsCommunitiesTree$.next(null);
        this.state.pinnedCommunitiesIds$.next([]);
        this.clearAdminCommunityTree();
        this.destroy$.next();
        this.initializeCache();
    }

    clearAdminCommunityTree(): void {
        this.state.adminCommunityTree = null;
    }

    pinCommunity(communityId: number, pinned: boolean): void {
        this.state.isInitialized$
            .pipe(
                first((isInitialized) => isInitialized),
                tap(() => this.updatePinCommunity(communityId, pinned)),
                concatMap(() =>
                    this.communitiesService
                        .setCommunityPinned(communityId, pinned)
                        .pipe(
                            catchError(() => {
                                this.updatePinCommunity(communityId, !pinned);
                                return EMPTY;
                            }),
                        ),
                ),
            )
            .subscribe();
    }

    private updatePinCommunity(communityId: number, pinned: boolean): void {
        const pinnedCommunity = this.state.pinnedCommunitiesIds$.value.find(
            (c) => c.id === communityId,
        );

        if (pinnedCommunity) {
            const filteredCommunities =
                this.state.pinnedCommunitiesIds$.value.filter(
                    (c) => c.id !== communityId,
                );

            if (pinned) {
                this.state.pinnedCommunitiesIds$.next([
                    ...filteredCommunities,
                    {
                        ...pinnedCommunity,
                        pinnedTimestamp: Date.now(),
                    },
                ]);
            } else {
                this.state.pinnedCommunitiesIds$.next([...filteredCommunities]);
            }
        } else if (pinned) {
            this.state.pinnedCommunitiesIds$.next([
                ...this.state.pinnedCommunitiesIds$.value,
                {
                    id: communityId,
                    pinnedTimestamp: Date.now(),
                },
            ]);
        }
    }

    private refreshCommunitiesFromCache(
        communitiesCache: Record<number, ICommunity>,
    ): Observable<OrganizationTrees> {
        return forkJoin([
            this.communitiesService.getCommunityTree(),
            this.communitiesService.getInsightCommunities(),
        ]).pipe(
            switchMap(([organizationTree, insightsTree]) =>
                this.updateStaleCommunities(
                    communitiesCache,
                    [organizationTree, insightsTree].map(
                        (tree) => tree.communityList,
                    ),
                ).pipe(
                    map((communityList) => idArraytoMap(communityList)),
                    map((communityMap) => {
                        return {
                            organizationTree: {
                                ...organizationTree,
                                communityList:
                                    organizationTree.communityList.map(
                                        (c) => communityMap[c.id] ?? c,
                                    ),
                            },
                            insightOrganizationTree: {
                                ...insightsTree,
                                communityList: insightsTree.communityList.map(
                                    (c) => communityMap[c.id] ?? c,
                                ),
                            },
                        };
                    }),
                ),
            ),
        );
    }

    private updateStaleCommunities(
        communitiesCache: Record<number, ICommunity>,
        communityList: ICommunity[][],
    ): Observable<ICommunity[]> {
        // Associate to every community the cached metadata
        const communitiesWithMetadata: ICommunity[] = unique(
            flatten(communityList),
            (c) => c.id,
        ).map((c) => ({
            ...c,
            metadata: communitiesCache[c.id]?.metadata,

            // Remove edits to editCommunity and manageWorkflow whith data from cache when IISP-9231 is completed.
            // adminCapabilities will come from community-tree and will be updated accordigly on every startup
            capabilities: c.capabilities
                ? {
                      ...c.capabilities,
                      editCommunity:
                          communitiesCache[c.id]?.capabilities?.editCommunity ??
                          c.capabilities.editCommunity,
                      manageWorkflow:
                          communitiesCache[c.id]?.capabilities
                              ?.manageWorkflow ?? c.capabilities.manageWorkflow,
                  }
                : c.capabilities,
        }));

        const [upToDateCommunities, staleCommunities] = mPartition(
            communitiesWithMetadata,
            (c) => communitiesCache[c.id]?.etag === c.etag,
        );

        return from(staleCommunities).pipe(
            mergeMap((c) =>
                forkJoin([
                    this.communitiesService.getCommunityDetails(c.id),
                    this.communitiesService.getPostMetadata(c.id),
                ]).pipe(
                    map(([community, metadata]) => ({
                        ...community,
                        metadata,
                        visibleInDashboard: c.visibleInDashboard,
                        visibleInOrganizationTree: c.visibleInOrganizationTree,
                    })),
                ),
            ),
            toArray(),
            map((updatedCommunities) => [
                ...upToDateCommunities,
                ...updatedCommunities,
            ]),
        );
    }

    private getReferencedCatalogIds(
        postDefinitions: IPostMetadata[],
    ): number[] {
        return unique(
            flatMap(postDefinitions, (postDefinition) =>
                getPostDefinitionCatalogIds(postDefinition),
            ),
        );
    }

    private updatePostDefinitionWithEnumValues(
        catalogs: ICatalogsOperativityList[],
        postDefinition: IPostMetadata,
    ): IPostMetadata {
        const catalogsMap = idArraytoMap(catalogs);
        const enumValuesFromCache = (field: ICustomFieldDefinition) => ({
            ...field,
            enumValues:
                isCatalogGenericListField(field) && field.metadata.catalog_id
                    ? catalogsMap[field.metadata.catalog_id].entries
                    : field.enumValues,
        });

        return {
            ...postDefinition,
            fieldMetadatas:
                postDefinition.fieldMetadatas.map(enumValuesFromCache),
            workflowDefinition: postDefinition.workflowDefinition
                ? {
                      ...postDefinition.workflowDefinition,
                      screenFieldMetadatas:
                          postDefinition.workflowDefinition?.screenFieldMetadatas?.map(
                              enumValuesFromCache,
                          ),
                  }
                : undefined,
            acknowledgeTaskDefinition: postDefinition.acknowledgeTaskDefinition
                ? {
                      ...postDefinition.acknowledgeTaskDefinition,
                      fieldDefinitions:
                          postDefinition.acknowledgeTaskDefinition?.fieldDefinitions?.map(
                              enumValuesFromCache,
                          ),
                  }
                : undefined,
        };
    }

    private getCommunitiesCache$(): Observable<Record<number, ICommunity>> {
        return this.localStorageService
            .getIdbEntry<Record<number, ICommunity>>(communitiesCacheKey)
            .pipe(
                map((cache) => cache ?? {}),
                map((cache) => {
                    const communityIds = Object.keys(cache)
                        .map((o) => parseInt(o))
                        .filter((o) => !isNaN(o));
                    return communityIds.reduce(
                        (sanitizedCache, communityId: number) => ({
                            ...sanitizedCache,
                            [communityId]: sanitizeCachedCommunity(
                                cache[communityId],
                            ),
                        }),
                        {} as Record<number, ICommunity>,
                    );
                }),
            );
    }
}
