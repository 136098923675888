import { UserCapabilities } from '@modules/core/models/user.model';
import { createReducer, on } from '@ngrx/store';
import { fetchUserCapabilitiesSuccess } from './user-capabilities-api.actions';

export const userCapabilitiesFeatureKey = 'userCapabilities';

export interface UserCapabilitiesState {
    capabilities: UserCapabilities;
}

const initialState: UserCapabilitiesState = {
    capabilities: {
        accessAdminArea: false,
        createCommunities: false,
        createUsers: false,
        createWorkspaces: false,
        manageCatalogs: false,
        manageCommunities: false,
        manageDigitalWorkplace: false,
        manageRoles: false,
        manageUsers: false,
        manageWorkflowTemplates: false,
        manageWorkspaces: false,
        manageWorkspacesUsersAssociations: false,
        reindexPosts: false,
        resellerAdmin: false,
        manageAllWorkspaces: false,
    },
};

export const userCapabilitiesReducer = createReducer(
    initialState,
    on(fetchUserCapabilitiesSuccess, (state, { capabilities }) => ({
        ...state,
        capabilities,
    })),
);
