import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { objectIsEmpty } from '@interacta-shared/util';
import { CustomFields } from '@modules/communities/models/custom-metadata/CustomFields.class';
import {
    toPostCustomFields,
    toScreenCustomFields,
} from '@modules/communities/models/custom-metadata/custom-metadata.deserialize';
import { fromServerCustomFields } from '@modules/communities/models/custom-metadata/custom-metadata.serialize';
import { IWorkflowStatus } from '@modules/communities/models/workflow/workflow.model';
import { IUser, UserDeserilize } from '@modules/core/models/user.model';
import {
    BasePostDeserialize,
    BasePostSerialize,
    IBasePost,
    IBasePostCreate,
    IBasePostEdit,
    IPostMetadata,
    PostType,
} from '@modules/post/models/base-post.model';
import { toAcknowledgeTaskRequest } from '@modules/tasks/models/task.deserialize';
import {
    IAcknowledgeTaskRequest,
    StandardTask,
} from '@modules/tasks/models/task.model';
import {
    fromAcknowledgeData,
    fromAcknowledgeTaskRequest,
} from '@modules/tasks/models/task.serialize';
import { AcknowledgeData } from './acknowledge-data.model';
import { OnChangeMessage } from './listen-changes/listen-changes.model';

export interface ICustomPost extends IBasePost {
    tag: PostType.CUSTOM;
    announcement: boolean;
    customFields: CustomFields;
    customFieldsScreen: CustomFields;
    lastTransitionUser?: IUser;
    lastTransitionTimestamp?: Date;
    tasks?: StandardTask[];
    tasksCount?: number;
    acknowledgeTask?: IAcknowledgeTaskRequest;
}

export interface ICustomPostCreate extends IBasePostCreate, ICustomPost {
    workflowInitStateId?: IWorkflowStatus['id'];
    acknowledgeTaskData?: AcknowledgeData;
}

export interface ICustomPostEdit extends IBasePostEdit, ICustomPostCreate {}

export type PostOnChangeType = {
    post: ICustomPost;
    postDataHasChanged: boolean;
    messageInfo: OnChangeMessage | null;
};

export interface ICustomPostForContext extends IBasePostCreate, IBasePost {
    tag: PostType.CUSTOM;
    announcement: boolean;
    customFields: CustomFields;
    customFieldsScreen: CustomFields;
    workflowInitStateId?: number;
}

export class CustomPostDeserialize extends BasePostDeserialize {
    public static customPostDetails = (
        configurationService: ConfigurationService,
        recordPost: any,
        metadata?: IPostMetadata,
    ): ICustomPost => {
        const recordAcknowledgeTask = recordPost.draft
            ? recordPost.draftData?.acknowledgeTaskData
            : recordPost.acknowledgeTask;
        const post: ICustomPost = <ICustomPost>{
            type: PostType.CUSTOM,
            tag: PostType.CUSTOM,
            announcement: recordPost.announcement ?? false,
            customFields: toPostCustomFields(recordPost.customData, metadata),
            customFieldsScreen: toScreenCustomFields(
                !objectIsEmpty(recordPost.screenData)
                    ? recordPost.screenData
                    : recordPost.currentWorkflowScreenData,
                metadata,
            ),
            lastTransitionUser: recordPost.lastTransitionUser
                ? UserDeserilize.userDetails(recordPost.lastTransitionUser)
                : undefined,
            lastTransitionTimestamp: recordPost.lastTransitionTimestamp
                ? new Date(recordPost.lastTransitionTimestamp as number)
                : undefined,
            tasksCount: recordPost.tasksCount || 0,
            acknowledgeTask: recordAcknowledgeTask
                ? toAcknowledgeTaskRequest(
                      recordAcknowledgeTask,
                      configurationService,
                  )
                : null,
        };

        return CustomPostDeserialize.basePostDetails(
            post,
            recordPost,
            configurationService,
            metadata,
        );
    };

    public static customPostDetailsForEdit = (
        record: any,
        metadata: IPostMetadata,
        configurationService: ConfigurationService,
    ): ICustomPost => {
        const post: ICustomPost = CustomPostDeserialize.customPostDetails(
            configurationService,
            record,
            metadata,
        );

        return CustomPostDeserialize.basePostDetailsForEdit(post, record);
    };

    public static customPostDetailsForCreate = (
        record: any,
        metadata: IPostMetadata,
        currentUser: IUser,
        configurationService: ConfigurationService,
    ): ICustomPost => {
        const recordManipulation = {
            ...record.contentData,
            ...record,
        };
        const post = CustomPostDeserialize.customPostDetailsForEdit(
            recordManipulation,
            metadata,
            configurationService,
        );
        return CustomPostDeserialize.basePostDetailsForCreate(
            post,
            currentUser,
        );
    };

    public static customPostDetailsOnChange = (
        record: any,
        metadata: IPostMetadata,
        currentUser: IUser,
        postId: number,
        occToken: number | undefined,
        configurationService: ConfigurationService,
    ): ICustomPost => {
        const recordManipulation = Object.assign(
            {},
            record.contentData,
            record,
            {
                id: postId ?? null,
                communityId: metadata.communityId,
                creatorUser: record.creatorUser ?? currentUser,
                occToken: occToken ?? undefined,
            },
        );
        const post = CustomPostDeserialize.customPostDetailsForEdit(
            recordManipulation,
            metadata,
            configurationService,
        );
        return post;
    };
}
export class CustomPostSerialize extends BasePostSerialize {
    public static customPostDetails = (post: ICustomPost): any => {
        const record: any = {
            customData: fromServerCustomFields(post.customFields.data),
            acknowledgeTask: post.acknowledgeTask
                ? fromAcknowledgeTaskRequest(post.acknowledgeTask)
                : undefined,
            announcement: post.announcement,
        };
        return CustomPostSerialize.basePostDetails(record, post);
    };

    public static newCustomPost = (post: ICustomPostCreate): any => {
        const record: any = {
            ...CustomPostSerialize.customPostDetails(post),
            workflowInitStateId: post.workflowInitStateId,
        };
        record.acknowledgeTask = fromAcknowledgeData(post.acknowledgeTaskData);

        return CustomPostSerialize.newBasePost(record, post);
    };

    public static editCustomPost = (post: ICustomPostEdit): any => {
        const record: any = {
            ...CustomPostSerialize.customPostDetails(post),
        };
        if (post.draft || post.draftOriginalValue) {
            if (post.workflowInitStateId != null) {
                record.workflowInitStateId = post.workflowInitStateId;
            }
            if (post.announcement != null) {
                record.announcement = post.announcement;
            }

            record.acknowledgeTask = fromAcknowledgeData(
                post.acknowledgeTaskData,
            );
        }

        return CustomPostSerialize.editBasePost(record, post);
    };

    public static copyCustomPost = (post: ICustomPostEdit): any => {
        const record: any = {
            ...CustomPostSerialize.customPostDetails(post),
            workflowInitStateId: post.workflowInitStateId,
        };

        record.acknowledgeTask = fromAcknowledgeData(post.acknowledgeTaskData);

        return CustomPostSerialize.copyBasePost(record, post);
    };

    public static contextCustomPost = (post: ICustomPostForContext): any => {
        const record: any = {
            customData: fromServerCustomFields(post.customFields.data),
            workflowInitStateId: post.workflowInitStateId,
            ...CustomPostSerialize.contextBasePost(post),
        };
        return {
            post: record,
        };
    };
}

export function isCustomPost(post: IBasePost): post is ICustomPost {
    return post.type === PostType.CUSTOM;
}

export interface CustomPostRangeNumber {
    min: number;
    max: number;
}

export class CustomPostRangeNumberDeserialize {
    public static getRange = (record: any): CustomPostRangeNumber => ({
        min: record.min,
        max: record.max,
    });
}
