import { Palette, Size } from '@interacta-shared/ui';
import { Theme } from '@modules/core/models/theme.model';
import { createAction, props } from '@ngrx/store';
import { CommentLocationType } from './../../../post/models/comment.model';

export const openCommentFullScreen = createAction(
    '[UI] Comment Fullscreen ON',
    props<{
        postId: number;
        verticalAlign: 'center' | 'end';
        overlayPalette: Extract<Palette, 'surface-A' | 'black-not-black-80'>;
        size: Size;
        location: CommentLocationType;
    }>(),
);

export const closeCommentFullScreen = createAction(
    '[UI] Comment Fullscreen OFF',
);

export const initTheme = createAction(
    '[UI] Init Theme',
    props<{ cachedTheme: Theme | undefined }>(),
);

export const changeTheme = createAction(
    '[UI] Change Theme',
    props<{ theme: Theme; dontSaveInStorage?: boolean }>(),
);

export const toggleNotificationPanel = createAction(
    '[UI] Toggle Notification Panel',
    props<{ isOpen: boolean }>(),
);

export const toggleOnlyUnreadNotification = createAction(
    '[UI] Toggle Only Unread Notification',
    props<{ onlyUnread: boolean }>(),
);
