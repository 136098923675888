import { ContentMimeType, GenericContentMimeType } from '../models/mime-type';

export const PDF = './assets/shared/images/file-types/pdf.png';
export const IMAGE = './assets/shared/images/file-types/image.png';
export const DOC = './assets/shared/images/file-types/word.png';
export const XLS = './assets/shared/images/file-types/excel.png';
export const PPT = './assets/shared/images/file-types/powerpoint.png';
export const TXT = './assets/shared/images/file-types/text.png';
export const ZIP = './assets/shared/images/file-types/zip-box.png';
export const AUDIO = './assets/shared/images/file-types/audio.png';
export const VIDEO = './assets/shared/images/file-types/video.png';
export const GIF = './assets/shared/images/file-types/gif.png';
export const OTHER = './assets/shared/images/file-types/other.png';

export const G_DOC = './assets/shared/images/file-types/g_documents.png';
export const G_DRAW = './assets/shared/images/file-types/g_drawing.png';
export const G_FORM = './assets/shared/images/file-types/g_forms.png';
export const G_FUSION = './assets/shared/images/file-types/g_fusiontable.png';
export const G_MAPS = './assets/shared/images/file-types/g_map.png';
export const G_SLIDES = './assets/shared/images/file-types/g_presentation.png';
export const G_SCRIPT = './assets/shared/images/file-types/g_script.png';
export const G_SITE = './assets/shared/images/file-types/g_site.png';
export const G_SHEET = './assets/shared/images/file-types/g_spreadsheet.png';

export function getPathIcon(type: any): string {
    let url = null;
    if (isMediaVideo(type)) {
        url = VIDEO;
    } else if (isMediaAudio(type)) {
        url = AUDIO;
    } else if (isMediaGif(type)) {
        url = GIF;
    } else if (isMediaImage(type)) {
        url = IMAGE;
    } else {
        switch (type) {
            case ContentMimeType.PDF:
                url = PDF;
                break;
            case ContentMimeType.DOC:
            case ContentMimeType.DOC2:
                url = DOC;
                break;
            case ContentMimeType.XLS:
            case ContentMimeType.XLS2:
                url = XLS;
                break;
            case ContentMimeType.PPT:
            case ContentMimeType.PPT2:
                url = PPT;
                break;
            case ContentMimeType.TXT:
            case ContentMimeType.CSV:
                url = TXT;
                break;
            case ContentMimeType.ZIP:
                url = ZIP;
                break;

            case ContentMimeType.G_AUDIO:
                url = AUDIO;
                break;
            case ContentMimeType.G_VIDEO:
                url = VIDEO;
                break;
            case ContentMimeType.G_DOC:
                url = G_DOC;
                break;
            case ContentMimeType.G_DRAW:
                url = G_DRAW;
                break;
            case ContentMimeType.G_FORM:
                url = G_FORM;
                break;
            case ContentMimeType.G_FUSION:
                url = G_FUSION;
                break;
            case ContentMimeType.G_MAPS:
                url = G_MAPS;
                break;
            case ContentMimeType.G_SLIDES:
                url = G_SLIDES;
                break;
            case ContentMimeType.G_SCRIPT:
                url = G_SCRIPT;
                break;
            case ContentMimeType.G_SITE:
                url = G_SITE;
                break;
            case ContentMimeType.G_SHEET:
                url = G_SHEET;
                break;
            case ContentMimeType.G_PHOTO:
                url = IMAGE;
                break;

            default:
                url = OTHER;
        }
    }
    return url;
}

export function getPathColor(type: any): string {
    let color = null;
    if (isMediaImage(type)) {
        color = '#F8DECC';
    } else if (isMediaVideo(type)) {
        color = '#E7E7E7';
    } else if (isMediaAudio(type)) {
        color = '#DDCAE7';
    } else {
        switch (type) {
            case ContentMimeType.G_SLIDES:
                color = '#FCE9B3';
                break;
            case ContentMimeType.PDF:
            case ContentMimeType.ZIP:
            case ContentMimeType.G_AUDIO:
            case ContentMimeType.G_VIDEO:
            case ContentMimeType.G_MAPS:
            case ContentMimeType.G_PHOTO:
            case ContentMimeType.G_DRAW:
            case ContentMimeType.G_FOLDER:
                color = '#E7E7E7';
                break;
            case ContentMimeType.DOC:
            case ContentMimeType.DOC2:
            case ContentMimeType.TXT:
            case ContentMimeType.CSV:
            case ContentMimeType.G_DOC:
            case ContentMimeType.G_SCRIPT:
            case ContentMimeType.G_SITE:
                color = '#C6DAFC';
                break;
            case ContentMimeType.XLS:
            case ContentMimeType.XLS2:
            case ContentMimeType.G_FUSION:
            case ContentMimeType.G_SHEET:
                color = '#C9E7CB';
                break;
            case ContentMimeType.PPT:
            case ContentMimeType.PPT2:
                color = '#FFDDCF';
                break;
            case ContentMimeType.G_FORM:
                color = '#DDCAE7';
                break;
            case ContentMimeType.GIF:
                color = '#C7E5F0';
                break;
            default:
                color = '#C6DAFC';
        }
    }
    return color;
}

export function isMediaImage(mimeType: any): boolean {
    return mimeType ? mimeType.indexOf('image/') > -1 : false;
}

export function isMediaGif(mimeType: any): boolean {
    return mimeType === ContentMimeType.GIF;
}

export function isMediaVideo(mimeType: any): boolean {
    return mimeType ? mimeType.indexOf('video/') > -1 : false;
}

export function isMediaAudio(mimeType: any): boolean {
    return mimeType ? mimeType.indexOf('audio/') > -1 : false;
}

export function isDocument(mimeType: string): boolean {
    return mimeType ? mimeType.indexOf('application/') > -1 : false;
}

export function isPdf(mimeType: string | string[]): boolean {
    return mimeType.includes(ContentMimeType.PDF);
}

export function getMediaMimetype(): string[] {
    return ['image', 'video', 'audio'];
}

export function getDocumentMimetype(): string[] {
    return ['application', 'text'];
}

export function getGenericMimetype(
    mimeType: string | undefined,
): GenericContentMimeType {
    if (!mimeType) throw new Error('Mime type not defined');
    if (isMediaImage(mimeType)) {
        return GenericContentMimeType.IMAGE;
    } else if (isMediaVideo(mimeType)) {
        return GenericContentMimeType.VIDEO;
    } else if (isMediaAudio(mimeType)) {
        return GenericContentMimeType.AUDIO;
    } else if (isDocument(mimeType)) {
        return GenericContentMimeType.APPLICATION;
    }
    throw new Error('Mime type not found');
}
