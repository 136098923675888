import { ValidationType } from '../custom-metadata/custom-metadata.model';

export enum MetadataConditionServerType {
    Empty = 1,
    NotEmpty = 2,
    Contains = 3,
    NotContains = 4,
    StartsWith = 5,
    EndsWith = 6,
    Equal = 7,
    NotEqual = 8,
    LessThan = 9,
    GreaterThan = 10,
    Between = 11,
    In = 12,
    NotIn = 13,
}

export enum MetadataConditionType {
    // Generic
    GenericEmpty,
    GenericNotEmpty,
    GenericEquals,
    GenericNotEquals,
    GenericBetween,
    // Text
    TextEmpty,
    TextNotEmpty,
    TextContains,
    TextNotContains,
    TextStartsWith,
    TextEndsWith,
    // List
    ListContains,
    ListNotContains,
    ListEmpty,
    ListNotEmpty,
    ListEquals,
    ListNotEquals,
    ListIn,
    ListNotIn,
    // Date
    DateEquals,
    DateNotEquals,
    DateLessThan,
    DateGreaterThan,
    // Datetime
    DatetimeEquals,
    DatetimeNotEquals,
    DatetimeLessThan,
    DatetimeGreaterThan,
    // Number
    NumberGreaterThan,
    NumberLessThan,
}

export const EmptyMetadataConditionTypes: MetadataConditionType[] = [
    MetadataConditionType.GenericEmpty,
    MetadataConditionType.TextEmpty,
    MetadataConditionType.ListEmpty,
];

export interface MetadataConditionUnary<T> {
    type: MetadataConditionType;
    parameter: T;
}

export interface MetadataConditionGenericEmpty {
    type: MetadataConditionType.GenericEmpty;
}

export interface MetadataConditionGenericNotEmpty {
    type: MetadataConditionType.GenericNotEmpty;
}

export interface MetadataConditionGenericEquals
    extends MetadataConditionUnary<any> {
    type: MetadataConditionType.GenericEquals;
}

export interface MetadataConditionGenericNotEquals
    extends MetadataConditionUnary<any> {
    type: MetadataConditionType.GenericNotEquals;
}

export interface MetadataConditionGenericBetween {
    type: MetadataConditionType.GenericBetween;
    from: any;
    to: any;
}

export interface MetadataConditionTextEmpty {
    type: MetadataConditionType.TextEmpty;
}

export interface MetadataConditionTextNotEmpty {
    type: MetadataConditionType.TextNotEmpty;
}

export interface MetadataConditionTextContains
    extends MetadataConditionUnary<string> {
    type: MetadataConditionType.TextContains;
}

export interface MetadataConditionTextNotContains
    extends MetadataConditionUnary<string> {
    type: MetadataConditionType.TextNotContains;
}

export interface MetadataConditionTextStartsWith
    extends MetadataConditionUnary<string> {
    type: MetadataConditionType.TextStartsWith;
}
export interface MetadataConditionTextEndsWith
    extends MetadataConditionUnary<string> {
    type: MetadataConditionType.TextEndsWith;
}

export interface MetadataConditionListContains
    extends MetadataConditionUnary<number[]> {
    type: MetadataConditionType.ListContains;
}

export interface MetadataConditionListNotContains
    extends MetadataConditionUnary<number[]> {
    type: MetadataConditionType.ListNotContains;
}

export interface MetadataConditionListEmpty {
    type: MetadataConditionType.ListEmpty;
}

export interface MetadataConditionListNotEmpty {
    type: MetadataConditionType.ListNotEmpty;
}

export interface MetadataConditionListEquals
    extends MetadataConditionUnary<number[]> {
    type: MetadataConditionType.ListEquals;
}

export interface MetadataConditionListNotEquals
    extends MetadataConditionUnary<number[]> {
    type: MetadataConditionType.ListNotEquals;
}

export interface MetadataConditionListIn
    extends MetadataConditionUnary<number[]> {
    type: MetadataConditionType.ListIn;
}

export interface MetadataConditionListNotIn
    extends MetadataConditionUnary<number[]> {
    type: MetadataConditionType.ListNotIn;
}

export interface MetadataConditionDateEquals
    extends MetadataConditionUnary<Date> {
    type: MetadataConditionType.DateEquals;
}

export interface MetadataConditionDateNotEquals
    extends MetadataConditionUnary<Date> {
    type: MetadataConditionType.DateNotEquals;
}

export interface MetadataConditionDateLessThan
    extends MetadataConditionUnary<Date> {
    type: MetadataConditionType.DateLessThan;
}

export interface MetadataConditionDateGreaterThan
    extends MetadataConditionUnary<Date> {
    type: MetadataConditionType.DateGreaterThan;
}

export interface MetadataConditionDateTimeEquals
    extends MetadataConditionUnary<Date> {
    type: MetadataConditionType.DatetimeEquals;
}

export interface MetadataConditionDateTimeNotEquals
    extends MetadataConditionUnary<Date> {
    type: MetadataConditionType.DatetimeNotEquals;
}

export interface MetadataConditionDateTimeLessThan
    extends MetadataConditionUnary<Date> {
    type: MetadataConditionType.DatetimeLessThan;
}

export interface MetadataConditionDateTimeGreaterThan
    extends MetadataConditionUnary<Date> {
    type: MetadataConditionType.DatetimeGreaterThan;
}

export interface MetadataConditionNumberGreaterThan
    extends MetadataConditionUnary<number> {
    type: MetadataConditionType.NumberGreaterThan;
}

export interface MetadataConditionNumberLessThan
    extends MetadataConditionUnary<number> {
    type: MetadataConditionType.NumberLessThan;
}

export type MetadataCondition =
    // Generic
    | MetadataConditionGenericEmpty
    | MetadataConditionGenericNotEmpty
    | MetadataConditionGenericEquals
    | MetadataConditionGenericNotEquals
    | MetadataConditionGenericBetween
    // Text
    | MetadataConditionTextEmpty
    | MetadataConditionTextNotEmpty
    | MetadataConditionTextContains
    | MetadataConditionTextNotContains
    | MetadataConditionTextStartsWith
    | MetadataConditionTextEndsWith
    // List
    | MetadataConditionListEquals
    | MetadataConditionListNotEquals
    | MetadataConditionListContains
    | MetadataConditionListNotContains
    | MetadataConditionListEmpty
    | MetadataConditionListNotEmpty
    | MetadataConditionListIn
    | MetadataConditionListNotIn
    // Date
    | MetadataConditionDateEquals
    | MetadataConditionDateNotEquals
    | MetadataConditionDateGreaterThan
    | MetadataConditionDateLessThan
    // Datetime
    | MetadataConditionDateTimeEquals
    | MetadataConditionDateTimeNotEquals
    | MetadataConditionDateTimeGreaterThan
    | MetadataConditionDateTimeLessThan
    // Number
    | MetadataConditionNumberGreaterThan
    | MetadataConditionNumberLessThan;

export interface MetadataActivationStatus {
    isActive: boolean;
}

export type MetadataActivationStatusMap = Partial<
    Record<ValidationType, MetadataActivationStatus>
>;

export interface MetadataActivation {
    sourceFieldId: number;
    condition: MetadataCondition | undefined;
}

export type MetadataActivationChange = {
    fieldId: number;
    validationType: ValidationType;
    status: MetadataActivationStatus;
};
