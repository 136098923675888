import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { isDefined } from '@interacta-shared/util';
import { Observable, Subject, iif, of } from 'rxjs';
import {
    catchError,
    finalize,
    map,
    mergeMap,
    tap,
    toArray,
} from 'rxjs/operators';

interface LazyLoadedItem {
    loaded: string;
    error?: any;
}

@Injectable({
    providedIn: 'root',
})
export class LazyLoaderService {
    private loadingItems: Record<string, Subject<LazyLoadedItem>> = {};
    private loadedItems: string[] = [];

    constructor(@Inject(DOCUMENT) private document: Document) {}

    public loadScripts(srcs: string[]): Observable<string[]> {
        const appendFn = (item: string) => this.appendScript(item);
        return this.loadItems(srcs, appendFn);
    }

    public loadStyles(hrefs: string[]): Observable<string[]> {
        const appendFn = (item: string) => this.appendLink(item);
        return this.loadItems(hrefs, appendFn);
    }

    public addStyle(innerText: string): void {
        const s = this.document.createElement('style');
        s.innerText = innerText;
        this.document.getElementsByTagName('body')[0].appendChild(s);
    }

    private loadItems(
        items: string[],
        appendFn: (item: string) => Observable<string>,
    ): Observable<string[]> {
        return of(
            items.filter((item) => !this.loadedItems.includes(item)),
        ).pipe(
            mergeMap((item) => item),
            mergeMap((item) =>
                iif(
                    () => item in this.loadingItems,
                    this.loadingItems[item],
                    of(item).pipe(
                        tap(
                            (item) => (this.loadingItems[item] = new Subject()),
                        ),
                        mergeMap((item) =>
                            appendFn(item).pipe(
                                tap((item) => this.loadedItems.push(item)),
                                map((item) => <LazyLoadedItem>{ loaded: item }),
                                catchError((error) =>
                                    of(<LazyLoadedItem>{
                                        loaded: item,
                                        error: error,
                                    }),
                                ),
                            ),
                        ),
                        tap((item) => {
                            this.loadingItems[item.loaded].next(item);
                        }),
                        finalize(() => delete this.loadingItems[item]),
                    ),
                ),
            ),
            toArray(),
            tap((items) => {
                const errors = items
                    .filter((item) => isDefined(item.error))
                    .map((item) => item.error);
                if (errors.length > 0) {
                    throw new Error(errors.toString());
                }
            }),
            map((items) =>
                items
                    .filter((item) => !isDefined(item.error))
                    .map((item) => item.loaded),
            ),
        );
    }

    private appendScript(
        src: string,
        type = 'text/javascript',
    ): Observable<string> {
        const loaded$ = new Subject<string>();
        console.debug('Lazy loading script item: ' + src);
        const s = this.document.createElement('script');
        s.type = type;
        s.src = src;
        s.onload = () => {
            loaded$.next(src);
            loaded$.complete();
        };
        s.onerror = () => {
            loaded$.error(`Error loading script ${src}`);
        };
        this.document.getElementsByTagName('body')[0].appendChild(s);
        return loaded$.asObservable();
    }

    private appendLink(href: string, rel = 'stylesheet'): Observable<string> {
        const loaded$ = new Subject<string>();
        console.debug(`Lazy loading ${rel} item: ${href}`);
        const s = this.document.createElement('link');
        s.rel = rel;
        s.href = href;
        s.onload = () => {
            loaded$.next(href);
            loaded$.complete();
        };
        s.onerror = () => {
            loaded$.error(`Error loading ${rel} item: ${href}`);
        };
        this.document.getElementsByTagName('body')[0].appendChild(s);
        return loaded$.asObservable();
    }
}
