import { IUser } from '@modules/core';
import { IOrder } from '@modules/post/models/filter-post/filter-post.model';
import { ITaskType } from './task.model';

export interface ITaskFilters {
    createdByUser: IUser[] | null;
    title: string | null;
    description: string | null;
    creationDateFrom: Date | null;
    creationDateTo: Date | null;
    order: IOrder | null;
    pageToken: string | null;
    pageSize: number | null;
    type: ITaskType;
    state: ITaskState | null;
    assigneeName: string | null;
}

export interface ITaskState {
    expiringAssignedToMe: boolean | null;
    expiredAssignedToMe: boolean | null;
    closedAssignedToMe: boolean | null;
}

export class TaskFilterSerialize {
    public static taskListFilter = (task: ITaskFilters): any => {
        const filter = TaskFilterSerialize.commonTaskListFilter(task);
        return filter;
    };

    public static commonTaskListFilter = (task: ITaskFilters): any => {
        const filter = {
            createdByUserId:
                task.createdByUser && task.createdByUser.length > 0
                    ? task.createdByUser[0].id
                    : null,
            title: task.title,
            description: task.description,
            creationTimestampFrom: task.creationDateFrom
                ? task.creationDateFrom.getTime()
                : null,
            creationTimestampTo: task.creationDateTo
                ? task.creationDateTo.getTime()
                : null,
            pageToken: task.pageToken,
            pageSize: task.pageSize,
            type: task.type,
            state: task.state ? { ...task.state } : null,
            assigneeName: task.assigneeName,
            orderBy: task.order?.orderBy,
            orderDesc: task.order?.orderDesc,
        };
        return filter;
    };
}

export const emptyTaskFilters = (
    type: ITaskType = ITaskType.STANDARD,
): ITaskFilters => ({
    createdByUser: null,
    title: null,
    description: null,
    creationDateFrom: null,
    creationDateTo: null,
    order: null,
    pageToken: null,
    type,
    state: null,
    pageSize: null,
    assigneeName: null,
});
