import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    computed,
    signal,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconComponent } from '@interacta-shared/ui-icons';
import {
    CURRENT_USER_SEARCH_VALUE,
    EMPTY_SEARCH_VALUE,
    getRandomColor,
} from '@interacta-shared/util';
import { Size, User } from '../../model';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
    selector: 'interacta-user',
    templateUrl: './user.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        IconComponent,
        AvatarComponent,
        NgTemplateOutlet,
        MatTooltipModule,
    ],
})
export class UserComponent implements OnChanges {
    @Input({ required: true }) user!: User;
    @Input() size: Extract<Size, 'regular' | 'small' | 'extra-small'> =
        'regular';
    @Input() showInfo = false;
    @Input() fontWeight: 'bold' | 'regular' = 'bold';
    @Input() rtl = false;
    @Input() additionalInfo?: string;
    @Input() color: 'black' | 'gray' | 'white' = 'black';

    private readonly userId = signal<number | undefined>(undefined);
    readonly isEmptyOrCurrent = computed(
        () =>
            this.userId() === CURRENT_USER_SEARCH_VALUE ||
            this.userId() === EMPTY_SEARCH_VALUE,
    );
    readonly colorClass = computed(() => {
        const userId = this.userId();
        return userId != null ? getRandomColor(userId) : undefined;
    });

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['user']) {
            this.userId.set(this.user.id);
        }
    }
}
