import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    AttachmentStorageType,
    IAttachment,
} from '@modules/post/models/attachment/attachment.model';
import { srcFallback } from '@modules/post/models/attachment/attachment.utils';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface Size {
    width: number;
    height: number;
}

@Component({
    selector: 'interacta-light-box-attachment',
    templateUrl: './light-box-attachment.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightBoxAttachmentComponent
    implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
    @Input({ required: true }) attachment!: IAttachment;
    @Input() isVideoMuted = true;
    @Input() goToAudioVideoFrame: number | null = null;
    @Input() autoplay = false;

    @Output() muteVideo = new EventEmitter<boolean>();
    @Output() cantPlay = new EventEmitter<'video' | 'audio'>();
    @Output() imgLoad = new EventEmitter<{
        element: HTMLElement;
        initialScale: number;
    }>();
    @Output() backdrop = new EventEmitter<void>();
    @Output() changeScale = new EventEmitter<number>();

    @ViewChild('container') containerRef!: ElementRef<HTMLElement>;

    showAttachment = true;
    canPlayMedia = true;
    imgIntrinsicSize?: Size;
    imgSize: Size = { width: 0, height: 0 };
    imgLoaded = false;

    AttachmentStorageType = AttachmentStorageType;

    private requestedAudioVideoFrame$ = new Subject<void>();
    private readonly destroy$ = new Subject<void>();

    srcFallback = srcFallback;

    constructor(private cdr: ChangeDetectorRef) {}
    ngAfterViewInit(): void {
        this.requestedAudioVideoFrame$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                if (
                    this.attachment &&
                    this.canPlayMedia &&
                    this.attachment.type === AttachmentStorageType.STORAGE
                ) {
                    if (this.attachment.isMediaAudio) {
                        this.containerRef.nativeElement
                            .getElementsByTagName('audio')[0]
                            ?.pause();
                    } else if (this.attachment.isMediaVideo) {
                        this.containerRef.nativeElement
                            .getElementsByTagName('video')[0]
                            ?.pause();
                    }
                }
            });
    }

    ngOnInit(): void {
        fromEvent(window, 'resize')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.onResize());
    }

    ngOnChanges(changes: SimpleChanges): void {
        // To hide the previous image while the current one is loading
        if (changes.attachment?.currentValue) {
            this.imgLoaded = false;
            this.showAttachment = false;
            this.imgSize = {
                width: 0,
                height: 0,
            };

            setTimeout(() => {
                this.showAttachment = true;
                this.cdr.markForCheck();
            });
        }

        if (changes.goToAudioVideoFrame?.currentValue != null) {
            this.requestedAudioVideoFrame$.next();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    onResize(): void {
        if (!this.imgIntrinsicSize) return;

        this.imgSize = this.scaleImg(this.imgIntrinsicSize);

        const scale = this.getScaleRatio(this.imgIntrinsicSize, this.imgSize);
        this.changeScale.emit(scale);

        this.cdr.markForCheck();
    }

    onImgLoad(img: HTMLImageElement): void {
        this.imgLoaded = true;
        this.imgIntrinsicSize = { width: img.width, height: img.height };
        this.imgSize = this.scaleImg(img);

        const initialScale = this.getScaleRatio(
            this.imgIntrinsicSize,
            this.imgSize,
        );

        this.imgLoad.emit({ element: img, initialScale });

        this.cdr.markForCheck();
    }

    onAudioError(): void {
        this.errorHandler('audio');
    }

    onVideoError(): void {
        this.errorHandler('video');
    }

    onVideoVolumeChange(event: Event): void {
        if (!(event.target instanceof HTMLVideoElement)) return;

        const video = event.target;

        this.muteVideo.emit(video?.muted ?? false);
    }

    private getScaleRatio(imgIntrinsicSize: Size, imgSize: Size) {
        // Since the aspect ratio is kept, we can arbitrarily
        // or the height choose the width
        const original = imgIntrinsicSize.width;
        const scaled = imgSize.width;

        // x : scaled = 100 : original
        return (scaled * 100) / original;
    }

    private errorHandler(type: 'video' | 'audio') {
        this.cantPlay.emit(type);
        this.canPlayMedia = false;
        this.cdr.markForCheck();
    }

    private scaleImg(img: Size): Size {
        const container = this.containerRef.nativeElement;

        if (!container) return { width: 0, height: 0 };

        let width: number;
        let height: number;

        if (img.width >= img.height) {
            [width, height] = this.containImg(
                { long: img.width, short: img.height },
                { long: container.offsetWidth, short: container.offsetHeight },
            );
        } else {
            [height, width] = this.containImg(
                { long: img.height, short: img.width },
                { long: container.offsetHeight, short: container.offsetWidth },
            );
        }

        return {
            width,
            height,
        };
    }

    private containImg(
        img: { long: number; short: number },
        container: { long: number; short: number },
    ): [longSide: number, shortSide: number] {
        let long = Math.min(container.long, Math.max(container.long, img.long));

        let short = (long * img.short) / img.long;

        if (short >= container.short) {
            short = container.short;
            long = (short * img.long) / img.short;
        }

        return [long, short];
    }
}
