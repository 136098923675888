<ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'detail'">
        <interacta-button-menu
            [icon]="customButton.icon"
            [label]="customButton.label"
            (click)="executeAction()"
        ></interacta-button-menu>
    </ng-container>

    <ng-container *ngSwitchCase="'list'">
        <button
            interacta-button
            type="button"
            [appearance]="'fill'"
            [attr.aria-label]="customButton.label"
            [bgColor]="'surface-A'"
            [icon]="customButton.icon"
            [label]="customButton.label"
            [size]="size"
            (click)="executeAction()"
        ></button>
    </ng-container>

    <ng-container *ngSwitchCase="'lightbox'">
        <button
            interacta-button-light-box
            type="button"
            [attr.aria-label]="customButton.label"
            [icon]="customButton.icon"
            [label]="customButton.label"
            [size]="'extra-large'"
            (click)="executeAction()"
        ></button>
    </ng-container>
</ng-container>
