import { NgClass, NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IconButtonComponent, openHeightAnimation } from '@interacta-shared/ui';

@Component({
    standalone: true,
    selector: 'interacta-filter-collapse-wrapper',
    templateUrl: './filter-collapse-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [IconButtonComponent, NgClass, NgIf],
    animations: [openHeightAnimation('field', '*')],
})
export class FilterCollapseWrapperComponent implements AfterViewInit {
    @Input() label = '';
    @Input() active = false;
    @Input() collapsed = false;
    @Input() pinned?: boolean;
    @Input() pinnable?: boolean;

    @Output() toggleCollapsed = new EventEmitter();
    @Output() togglePinned = new EventEmitter();

    constructor(private cdr: ChangeDetectorRef) {}

    initialized = false;
    hover = false;

    ngAfterViewInit(): void {
        this.initialized = true;

        setTimeout(() => {
            this.cdr.markForCheck();
        });
    }
}
