<section
    class="max-w-580 mx-auto flex h-full flex-col items-center overflow-hidden p-24 text-center"
>
    <h1 class="typo-t4 mt-32 shrink-0">{{ labels.title | translate }}</h1>

    <interacta-image
        fallback="./assets/images-2.0/doodles/under-maintenance.png"
        src="./assets/images-2.0/doodles/under-maintenance.webp"
        [alt]="'Work in progress'"
        [classes]="'mt-24 max-h-580 object-contain overflow-hidden'"
    ></interacta-image>

    <h2 class="typo-medium mt-16 shrink-0">
        {{ labels.description | translate }}
    </h2>

    <a
        class="mt-48 self-end"
        interacta-button
        size="regular"
        [label]="'SHARED.ERROR_PAGE.BACK_HOME' | translate"
        [routerLink]="['/']"
    ></a>
</section>
