<div class="flex items-center gap-x-4">
    <span class="typo-sm">{{ tag.name }}</span>
    <ng-container
        *ngIf="{
            excludedCount:
                {
                    tagId: tag.id,
                    tagExcludedGroups: recipientsTagExcludedGroups
                } | apply: getExcludedGroupCountByTagId
        } as $"
    >
        <button
            *ngIf="$.excludedCount"
            class="text-text-error hover:bg-surface-primary-low-contrast typo-sm-bold -my-4 -mr-4 rounded-full px-6 py-4"
            matRipple
            type="button"
            [attr.aria-label]="
                'TAG_MEMBER_PICKER.TAGS_INFO_TOOLTIP' | translate
            "
            [matTooltip]="'TAG_MEMBER_PICKER.TAGS_INFO_TOOLTIP' | translate"
            (click)="tagInfo.emit(); $event.stopPropagation()"
        >
            {{ '-' + $.excludedCount }}
        </button>
        <button
            *ngIf="!$.excludedCount"
            class="-my-4 -mr-4"
            interacta-icon-button
            type="button"
            [attr.aria-label]="
                'TAG_MEMBER_PICKER.TAGS_INFO_TOOLTIP' | translate
            "
            [bgColor]="'surface-B'"
            [icon]="'info'"
            [matTooltip]="'TAG_MEMBER_PICKER.TAGS_INFO_TOOLTIP' | translate"
            [size]="'small'"
            (click)="tagInfo.emit(); $event.stopPropagation()"
        ></button>
    </ng-container>
</div>
