import {
    Language,
    defaultSettings,
} from '@interacta-shared/data-access-configuration';
import { ICommunity } from '@modules/communities/models/communities.model';
import { getLabelServerTranslation } from '@modules/core/helpers/generic.utils';
import {
    duplicateEnumValues,
    fieldHasChildren,
    getFieldParentId,
    insertFieldIntoArray,
    manageHierarchicalValues,
    removeFieldFromArray,
} from '@modules/custom-fields-configuration/models/custom-fields-configuration.utils';
import { PostCreateMode } from '@modules/post-create/model/post-create.model';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { emptyAttachmentsList } from '@modules/post/models/attachment/attachment.utils';
import {
    AdvancedPostType,
    PostType,
    VisibilityType,
} from '@modules/post/models/base-post.model';
import { isCustomPost } from '@modules/post/models/custom-post.model';
import { DraftType } from '@modules/post/models/filter-post/filter-post.model';
import { GenericPost } from '@modules/post/models/generic-post.model';
import {
    getEmptyQuestionForCreate,
    getSurveyPostType,
    isSurveyPost,
} from '@modules/post/models/survey-post/survey-post.utils';
import { QuillTable } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { addOrEditQuillTable } from '@modules/shared-v2/models/quill-table/quill-table.utils';
import { createReducer, on } from '@ngrx/store';
import produce from 'immer';
import {
    SurveyPostCreate,
    SurveyQuestionForCreate,
    SurveyType,
} from './../../models/survey-post/survey-post.model';
import * as actions from './post-form.actions';

export const postFormFeatureKey = 'form';

export interface PostFormState {
    mode: PostCreateMode;
    community: ICommunity | null;
    originalPostId: number | null;
    availablePostTypes: AdvancedPostType[];
    q: actions.QueryCustomParamer | null;
    attachmentsList: IListAttachments;
    sharedAttachments: IAttachment[];
    fromSource: DraftType | null;
    post: GenericPost | null;
    postType: PostType | null;
    subtype: SurveyType | undefined;
    surveyLanguage: Language;
    surveyQuestions: SurveyQuestionForCreate[] | null;
    visibility: GenericPost['visibility'];
    announcement: boolean;
    tables: QuillTable[];
}

export const initialState: PostFormState = {
    mode: 'create',
    community: null,
    originalPostId: null,
    availablePostTypes: [],
    q: null,
    fromSource: null,
    sharedAttachments: [],
    post: null,
    postType: null,
    subtype: undefined,
    surveyLanguage: defaultSettings.language,
    surveyQuestions: null,
    visibility: VisibilityType.PUBLIC,
    announcement: false,
    tables: [],
    attachmentsList: emptyAttachmentsList(),
};

export const postFormReducer = createReducer<PostFormState>(
    initialState,

    on(actions.resetForm, (_state) => ({
        ...initialState,
    })),

    on(actions.initCreateForm, (state, props) => ({
        ...initialState,
        q: props.q,
        fromSource: props.from,
        sharedAttachments: state.sharedAttachments,
    })),

    on(actions.initEditForm, () => {
        const mode: PostCreateMode = 'edit';
        return {
            ...initialState,
            mode,
        };
    }),

    on(actions.initCopyForm, (_, { postId }) => {
        const mode: PostCreateMode = 'copy';
        return {
            ...initialState,
            mode,
            originalPostId: postId,
        };
    }),

    on(actions.setCommunityForCreate, (state, props) => ({
        ...state,
        community: props.community,
        availablePostTypes: props.availablePostTypes,
        post: null,
        postType: null,
        surveyQuestions: null,
    })),

    on(actions.setPost, (state, props) => ({
        ...state,
        post: props.post,
        postType: props.post.type,
        subtype: getSurveyPostType(props.post),
        community: props.community ?? state.community,
        availablePostTypes:
            props.availablePostTypes ?? state.availablePostTypes,
        surveyLanguage: isSurveyPost(props.post)
            ? props.post.surveyLanguage
            : defaultSettings.language,
        surveyQuestions: isSurveyPost(props.post)
            ? 'surveyQuestions' in props.post &&
              (props.post as SurveyPostCreate).surveyQuestions?.length
                ? (props.post as SurveyPostCreate).surveyQuestions ?? []
                : state.surveyQuestions ?? []
            : null,
        tables: props.post.tables ?? [],
        announcement: isCustomPost(props.post)
            ? props.post.announcement
            : false,
        visibility: props.post.visibility,
        attachmentsList: props.post.attachmentsList ?? emptyAttachmentsList(),
    })),

    on(actions.setPostVisibilityAndAnnouncement, (state, props) => ({
        ...state,
        visibility: props.visibility ?? state.visibility,
        announcement:
            state.post && isCustomPost(state.post) && props.announcement != null
                ? props.announcement
                : state.announcement,
    })),

    on(actions.setPostType, (state, props) => ({
        ...state,
        post: state.post?.type === props.postType.type ? state.post : null,
        postType: props.postType.type,
        subtype: props.postType.subtype,
        surveyQuestions:
            props.postType.type == PostType.SURVEY
                ? state.surveyQuestions ?? []
                : null,
        attachmentsList: emptyAttachmentsList(),
    })),

    on(
        actions.fetchFilePickerTemporaryContentViewLinkSuccess,
        (state, props) => {
            return produce(state, (draft) => {
                if (
                    draft.post &&
                    props.postId === draft.post.id &&
                    draft.post.tag === PostType.CUSTOM
                ) {
                    if (props.filePicker.filePickerMode === 'post') {
                        draft.post.customFields = draft.post.customFields
                            .changeValueForAttachments(
                                props.filePicker.fieldId,
                                props.attachments,
                            )
                            .clone();
                    } else if (props.filePicker.filePickerMode === 'screen') {
                        draft.post.customFieldsScreen =
                            draft.post.customFieldsScreen
                                .changeValueForAttachments(
                                    props.filePicker.fieldId,
                                    props.attachments,
                                )
                                .clone();
                    }
                }
            });
        },
    ),

    on(actions.fetchPostAttachmentsList, (state) => ({
        ...state,
        attachmentsList: {
            ...state.attachmentsList,
            isLoading: true,
        },
    })),

    on(actions.fetchPostAttachmentsListSuccess, (state, props) => ({
        ...state,
        attachmentsList: props.attachmentsList
            ? {
                  ...props.attachmentsList,
                  isLoading: false,
                  attachments: [
                      ...state.attachmentsList.attachments,
                      ...props.attachmentsList.attachments,
                  ].map((att) =>
                      state.mode === 'copy'
                          ? {
                                ...att,
                                capabilities: {
                                    canDelete: true,
                                    canEdit: true,
                                    canEditHashtags: true,
                                },
                            }
                          : att,
                  ),
              }
            : props.attachmentsList,
    })),

    on(actions.updateAttachmentsList, (state, { attachments }) => {
        return {
            ...state,
            attachmentsList: {
                ...state.attachmentsList,
                attachments,
            },
        };
    }),

    // ------------- QUESTIONS ACTIONS --------------------------
    on(actions.createSurveyQuestion, (state, props) => {
        if (state.postType != PostType.SURVEY) return state;

        return {
            ...state,
            surveyQuestions: insertFieldIntoArray(
                getEmptyQuestionForCreate(props.index),
                props.index,
                state.surveyQuestions,
            ),
        };
    }),

    on(actions.saveSurveyQuestion, (state, props) => {
        if (state.postType != PostType.SURVEY || !state.surveyQuestions)
            return state;

        return {
            ...state,
            surveyQuestions: updateList(
                props.question,
                props.index,
                state.surveyQuestions,
            ),
        };
    }),

    on(actions.duplicateSurveyQuestion, (state, props) => {
        if (state.postType != PostType.SURVEY) return state;

        const question: SurveyQuestionForCreate = {
            ...props.question,
            id: undefined,
            localId: null,
            order: props.index,
            enumValues: duplicateEnumValues(props.question.enumValues),
        };

        return {
            ...state,
            surveyQuestions: insertFieldIntoArray(
                question,
                props.index,
                state.surveyQuestions,
            ),
        };
    }),

    on(actions.cancelSurveyQuestion, (state, props) => {
        if (state.postType != PostType.SURVEY) return state;

        return {
            ...state,
            surveyQuestions: removeFieldFromArray(
                props.index,
                state.surveyQuestions,
            ),
        };
    }),

    on(actions.deleteSurveyQuestion, (state, props) => {
        if (state.postType != PostType.SURVEY) return state;

        const newList = removeFieldFromArray(
            props.index,
            state.surveyQuestions,
        );

        const questionToDelete = props.question;

        const isParent = fieldHasChildren(questionToDelete);
        const isChild = getFieldParentId(questionToDelete);

        if (isChild || isParent) {
            (newList || []).map((actualField, _idx, list) =>
                manageHierarchicalValues(
                    questionToDelete,
                    actualField,
                    'delete',
                    list,
                ),
            );
        }

        return {
            ...state,
            surveyQuestions: newList,
        };
    }),

    on(actions.reorderSurveyQuestions, (state, props) => {
        if (state.postType != PostType.SURVEY) return state;

        return {
            ...state,
            surveyQuestions: props.questions,
        };
    }),

    on(actions.updateSurveyLanguage, (state, props) => {
        return produce(state, (draft) => {
            if (
                draft.post &&
                draft.post.tag === PostType.SURVEY &&
                draft.post.surveyLanguage.code != props.language.code
            ) {
                draft.surveyLanguage = props.language;

                //IISP-6741 remove this when enum values will be internationalized
                draft.surveyQuestions?.forEach((q) => {
                    if (q.enumValues != null && q.enumValues.length) {
                        q.enumValues.forEach((e) => {
                            if (
                                e != null &&
                                e.label != null &&
                                typeof e.label === 'object' &&
                                (e.label[props.language.code] == null ||
                                    !e.label[props.language.code])
                            ) {
                                e.label[props.language.code] =
                                    getLabelServerTranslation(
                                        e.label,
                                        props.language.code,
                                    ) ?? '';
                            }
                        });
                    }
                });
            }
        });
    }),

    on(actions.addOrEditQuillTable, (state, { table }) => {
        return {
            ...state,
            tables: addOrEditQuillTable(state.tables, table),
        };
    }),

    on(actions.setSharedAttachments, (state, props) => ({
        ...state,
        sharedAttachments: props.sharedAttachments,
    })),
);

const updateList = (
    question: SurveyQuestionForCreate,
    position: number,
    surveyQuestionFromState: SurveyQuestionForCreate[],
): PostFormState['surveyQuestions'] => {
    return surveyQuestionFromState.map((actualQuestion, idx, list) =>
        idx === position
            ? question
            : manageHierarchicalValues(question, actualQuestion, 'edit', list),
    );
};
