import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { Appearance, ButtonColor } from '../../model';
import { ButtonComponent } from '../button/button.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[interacta-dialog-button]',
    templateUrl: './dialog-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ButtonComponent],
})
export class DialogButtonComponent {
    @Input() label!: string;
    @Input() appearance!: Appearance;
    @Input() bgColor!: Extract<
        ButtonColor,
        'primary' | 'surface-A' | 'secondary' | 'tertiary'
    >;
    @Input() active!: boolean;
    @Input() @HostBinding('disabled') disabled = false;
}
