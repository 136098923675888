<div class="text-text-on-avatar rounded-full p-4" [class]="colorClass">
    <interacta-icon
        icon="people"
        [class]="classes"
        [ngClass]="{
            'size-32': size === 'regular',
            'size-22': size === 'small',
            'size-16': size === 'extra-small'
        }"
    ></interacta-icon>
</div>
