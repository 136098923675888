import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    signal,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckboxState, openHeightAnimation } from '@interacta-shared/ui';
import { WorkspaceBase } from '@modules/communities/models/workspace.model';
import { filterWorkspaces } from '@modules/sidebar/models/sidebar.utils';
import {
    BehaviorSubject,
    combineLatest,
    concat,
    map,
    Observable,
    of,
    Subject,
    takeUntil,
} from 'rxjs';
@Component({
    selector: 'interacta-communities-multiple-select',
    templateUrl: './communities-multiple-select.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [openHeightAnimation('searchRow', '*', 200)],
})
export class CommunitiesMultipleSelectComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input({ required: true }) title = '';
    @Input({ required: true }) communityIdsControl!: FormControl<number[]>;
    @Input() workspaces!: WorkspaceBase[];
    @Input() enableMultiSelection = true;
    @Input() minCommunities = 0;
    @Input() description = '';

    totalCommunitiesCount = 0;
    N_COMMUNITIES_TO_SHOW_FILTER = 20;
    selectedCommunities = signal<number>(0);
    checkboxCommunitiesState: Record<
        number,
        FormControl<Exclude<CheckboxState, 'indeterminate'>>
    > = {};
    checkboxWsState: Record<number, FormControl<CheckboxState>> = {};
    toggleOpenWs: Record<number, boolean> = {};
    filteredData$!: Observable<WorkspaceBase[]>;
    communityNameFilter = new FormControl<string>('', { nonNullable: true });

    private workspaces$ = new BehaviorSubject<WorkspaceBase[]>([]);
    private destroy$ = new Subject<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.workspaces?.currentValue ||
            (changes.communityIdsControl && this.workspaces)
        ) {
            this.initState();

            this.totalCommunitiesCount = this.workspaces
                .map((w) => w.communities?.length || 0)
                .reduce((acc, curr) => acc + curr, 0);
            this.workspaces$.next(this.workspaces);
        }
    }

    ngOnInit(): void {
        const nameFilter$: Observable<string> = concat(
            of(''),
            this.communityNameFilter.valueChanges,
        );

        this.filteredData$ = combineLatest([
            this.workspaces$,
            nameFilter$,
        ]).pipe(
            map(([workspaces, nameFilter]) =>
                workspaces
                    .map((workspace) =>
                        filterWorkspaces(workspace, nameFilter, false, false),
                    )
                    .filter((workspace) => workspace.communities?.length),
            ),
            takeUntil(this.destroy$),
        );
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    initState(): void {
        this.workspaces.forEach((ws) => {
            this.toggleOpenWs[ws.id] = true;
            (ws.communities || []).forEach(
                (c) =>
                    (this.checkboxCommunitiesState[c.id] =
                        new FormControl<boolean>(
                            this.communityIdsControl.value.includes(c.id),
                            { nonNullable: true },
                        )),
            );

            this.changeWsCheckboxByCommunities(ws.id);
        });
        this.countSelected();
    }

    clearAll(): void {
        Object.keys(this.checkboxCommunitiesState).map((k) =>
            this.checkboxCommunitiesState[Number(k)].setValue(false),
        );
        Object.keys(this.checkboxWsState).map((k) =>
            this.checkboxWsState[Number(k)].setValue(false),
        );
        this.selectedCommunities.set(0);
        this.communityIdsControl.setValue([]);
    }

    countSelected(): void {
        const selectedIds = Object.entries(
            this.checkboxCommunitiesState,
        ).filter(([_id, isSelected]) => isSelected.value);
        this.communityIdsControl.setValue(
            selectedIds.map(([id]) => Number(id)),
        );
        this.selectedCommunities.set(selectedIds.length);
    }

    toggleCommunities(
        communityId: number,
        wsId: number,
        checked: boolean,
    ): void {
        if (checked && !this.enableMultiSelection) {
            this.clearAll();
        }
        this.checkboxCommunitiesState[communityId].setValue(checked);
        this.changeWsCheckboxByCommunities(wsId);
        this.countSelected();
    }

    toggleWorkspace(wsId: number, checked: boolean): void {
        const communitiesId = this.getCommunitiesIdByWsId(wsId);
        this.checkboxWsState[wsId].setValue(checked);
        communitiesId.forEach((c) =>
            this.checkboxCommunitiesState[c].setValue(checked),
        );
        this.countSelected();
    }

    accordionWs(wsId: number): void {
        this.toggleOpenWs[wsId] = !this.toggleOpenWs[wsId];
    }

    private getCommunitiesIdByWsId(wsId: number): number[] {
        const wsTree = this.workspaces.find((w) => w.id === wsId);
        return (wsTree?.communities || []).map((c) => c.id);
    }

    private changeWsCheckboxByCommunities(wsId: number) {
        const communities = this.getCommunitiesIdByWsId(wsId);
        let count = 0;
        communities.forEach((c) => {
            this.checkboxCommunitiesState[c].value ? count++ : null;
        });
        this.checkboxWsState[wsId] = new FormControl<CheckboxState>(
            count === communities.length
                ? true
                : count === 0
                  ? false
                  : 'indeterminate',
            { nonNullable: true },
        );
    }
}
