<div class="pl-16 pr-24">
    <mat-slider
        class="w-full"
        discrete
        [disableRipple]="true"
        [max]="options.max"
        [min]="options.min"
        [showTickMarks]="options.showTicks ?? false"
        [step]="options.step ?? 1"
    >
        <input
            matSliderStartThumb
            [formControl]="minValueControl"
            (dragEnd)="userIsDraggingSlider.emit(false)"
            (dragStart)="userIsDraggingSlider.emit(true)"
        />
        <input
            matSliderEndThumb
            [formControl]="maxValueControl"
            (dragEnd)="userIsDraggingSlider.emit(false)"
            (dragStart)="userIsDraggingSlider.emit(true)"
        />
    </mat-slider>
</div>
