import { Params } from '@angular/router';
import {
    ConfigurationService,
    defaultSettings,
} from '@interacta-shared/data-access-configuration';
import {
    PaginatedList,
    fromPageToken,
    lastLoading,
    paginatedListFromArray,
    regularLoading,
} from '@interacta-shared/util';
import { formatDateUsingIntl } from '@interacta-shared/util-common';
import {
    IWorkspace,
    WorkspaceDeserialize,
} from '@modules/communities/models//workspace.model';
import {
    CommunityTreeDeserialize,
    ICommunity,
} from '@modules/communities/models/communities.model';
import { toScreenCustomFields } from '@modules/communities/models/custom-metadata/custom-metadata.deserialize';
import { ICustomField } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { isCustomValueBlank } from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import { toHashtag } from '@modules/communities/models/hashtag/hashtag.deserialize';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { toWorkflowStatus } from '@modules/communities/models/workflow/workflow-base.deserialize';
import {
    IWorkflowOperation,
    IWorkflowStatus,
} from '@modules/communities/models/workflow/workflow.model';
import { IUser, UserDeserilize } from '@modules/core';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import { FeedbackTaskOperation } from '@modules/feedback/models/feedback.model';
import { toAttachmentDetails } from '@modules/post/models/attachment/attachment.deserialize';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import {
    CommentDeserialize,
    IComment,
} from '@modules/post/models/comment.model';
import { PostListDeserialize } from '@modules/post/models/post-list.model';
import {
    toGenericTask,
    toStandardTask,
} from '@modules/tasks/models/task.deserialize';
import { GenericTask, ITaskType } from '@modules/tasks/models/task.model';
import { isThisYear } from 'date-fns';
import { toWorkflowOperation } from '../../communities/models/workflow/workflow.deserialize';
import { IBasePost, IPostMetadata } from '../../post/models/base-post.model';
import { getLabelServerTranslation } from '../helpers/generic.utils';
import { PostDetailSection, QuillText } from './utility.model';
export interface IEventActivity {
    id: number;
    typeId: EventType;
    typeDescription: string;
    descriptionPlainText: string;
    descriptionDelta: QuillText;
    authorUsers: IUser[];
    read: boolean;
    title: string;
    timestamp: Date;
    aggretated: boolean;
    aggregatedNotificationIds: number[];
    addedAttachments: IAttachment[];
    updatedAttachments: IAttachment[];
    versionedAttachments: IAttachment[];
    workspace: IWorkspace | null;
    community: ICommunity | null;
    task: GenericTask | null;
    post: IBasePost | null;
    comment: IComment | null;
    hashtags: IHashTag[];
    postWorkflowOperationDetail: IWorkflowOperation | null;
    firstPostWorkflowOperationDetail: IWorkflowOperation | null;
    lastPostWorkflowOperationDetail: IWorkflowOperation | null;
    lastTaskTransitionOperationDetail: Pick<
        FeedbackTaskOperation,
        'fromState' | 'toState'
    > | null;
    workflowState: IWorkflowStatus | null;
    pushEnabled: boolean;
}
export interface IEventHistory {
    id: number;
    timestamp: Date | null;
    typeId: EventType;
    typeDescription: string;
    postId: number;
    postWorkflowOperationDetail?: IWorkflowOperation | null;
    commentId: number;
    notes: string;
    task: GenericTask | null;
    postCurrentTitle: string;
    postBeforeUpdateTitle: string;
    postAfterUpdateTitle: string;
    postDetailExternalDeviceLink: string;
    addedAttachmentsCount: number;
    removedAttachmentsCount: number;
    versionedAttachmentsCount: number;
    authorUser: IUser | null;
    targetUser: IUser | null;
    attachment: IAttachment;
    attachmentVersion: IAttachment;
    computedEventData?: NotificationEventData;
    updatedAttachmentsCount: number;
    workflowScreenData?: ICustomField[];
    updatedWorkflowScreenDataCount?: number;
}

export interface NotificationList extends PaginatedList<IEventActivity> {
    nextSyncToken?: string;
    onlyUnread: boolean;
}

export interface INotificationNavigation {
    id: number;
    url: string[];
    event: IEventActivity;
    queryParams?: Params;
}

export enum EventType {
    CREATION = 1,
    EDIT = 2,
    COMMENT = 3,
    MENTION_POST = 4,
    MENTION_COMMENT = 5,
    CHANGE_STATUS = 6,
    LIKE_POST = 7,
    NOT_LIKE_POST = 8,
    ADD_ATTACHMENT = 11,
    REMOVE_ATTACHMENT = 12,
    ADD_ATTACHMENT_VERSION = 13,
    COPY = 14,
    REMOVE_POST = 15,
    REMOVE_COMMENT = 16,
    EDIT_SCREEN_DATA = 17,
    CREATE_TASK = 18,
    EDIT_TASK = 19,
    ADD_COMMENT_ON_TASK = 20,
    MENTION_TASK = 21,
    MENTION_TASK_COMMENT = 22,
    ADD_TASK_ATTACHMENT = 23,
    REMOVE_TASK_ATTACHMENT = 24,
    COPY_TASK = 25,
    DELETE_TASK = 26,
    DELETE_TASK_COMMENT = 27,
    TASK_REMINDER = 28,
    ASSIGNED_TASK = 29,
    OPENED_TASK = 30,
    CLOSED_TASK = 31,
    UPDATE_VISIBILITY_POST = 32,
    UPDATE_ATTACHMENT = 33,
    FOLLOW_ADDED = 34,
    FOLLOW_REMOVED = 35,
    LIKE_COMMENT = 36,
    REMOVE_LIKE_COMMENT = 37,
    EDITED_POST_ATTACHMENTS = 38,
    ADD_HASHTAG = 39,
    CREATE_ACKNOWLEDGE_TASK_REQUEST = 40,
    EVENT_INVITATION_GUEST = 41,
    EVENT_INVITATION_STAFF = 42,
    EDIT_EVENT = 43,
    ADD_COMMENT_ON_YOUR_POST = 44,
    REPLY_YOUR_COMMENT = 45,
    REOPENED_YOUR_TASK = 46,
    CLOSED_YOUR_TASK = 47,
    DELETED_YOUR_TASK = 48,
    CREATE_SURVEY = 49,
    CREATE_SURVEY_TASK_REQUEST = 50,
    EDIT_SURVEY = 51,
    FEEDBACK_TASK_CHANGE_STATUS = 52,
}

export class NotificationUserUtility {
    public static navigationNotificationActivity(
        notification: IEventActivity,
    ): INotificationNavigation | null {
        const postId: number | undefined = notification.post?.id;
        switch (notification.typeId) {
            case EventType.ADD_ATTACHMENT:
            case EventType.REMOVE_ATTACHMENT:
            case EventType.ADD_ATTACHMENT_VERSION:
                return this.openPostDetails(
                    postId!,
                    notification,
                    PostDetailSection.ATTACHMENTS,
                );
            case EventType.COMMENT:
            case EventType.REMOVE_COMMENT:
            case EventType.MENTION_COMMENT:
            case EventType.LIKE_COMMENT:
            case EventType.ADD_COMMENT_ON_YOUR_POST:
            case EventType.REPLY_YOUR_COMMENT:
                return this.openPostDetails(
                    postId!,
                    notification,
                    PostDetailSection.COMMENTS,
                );
            case EventType.CHANGE_STATUS:
            case EventType.EDIT_SCREEN_DATA:
                return this.openPostDetails(
                    postId!,
                    notification,
                    PostDetailSection.WORKFLOW,
                );
            case EventType.CREATE_TASK:
            case EventType.EDIT_TASK:
            case EventType.COPY_TASK:
            case EventType.DELETE_TASK:
            case EventType.TASK_REMINDER:
            case EventType.ADD_COMMENT_ON_TASK:
            case EventType.MENTION_TASK_COMMENT:
            case EventType.DELETE_TASK_COMMENT:
            case EventType.MENTION_TASK:
            case EventType.ASSIGNED_TASK:
            case EventType.ADD_TASK_ATTACHMENT:
            case EventType.REMOVE_TASK_ATTACHMENT:
            case EventType.REOPENED_YOUR_TASK:
            case EventType.CLOSED_YOUR_TASK:
            case EventType.DELETED_YOUR_TASK:
                return this.openPostDetails(
                    postId!,
                    notification,
                    PostDetailSection.TASKS,
                );
            case EventType.EVENT_INVITATION_STAFF:
                return this.openPostDetails(
                    postId!,
                    notification,
                    PostDetailSection.INVITATIONS,
                );
            case EventType.FEEDBACK_TASK_CHANGE_STATUS:
                return postId
                    ? {
                          id: postId,
                          url: [
                              '/post',
                              'feedback',
                              postId.toString(),
                              'recipient',
                          ],
                          event: notification,
                          queryParams: {
                              taskId: !notification.task?.deleted
                                  ? notification.task?.id
                                  : undefined,
                          },
                      }
                    : null;
            case EventType.REMOVE_POST:
                return null;
            default:
                return this.openPostDetails(postId!, notification);
        }
    }

    private static openPostDetails(
        postId: number,
        notification: IEventActivity,
        flowType?: PostDetailSection,
    ): INotificationNavigation {
        const queryParams = flowType
            ? {
                  entity: flowType,
                  commentId:
                      flowType === PostDetailSection.COMMENTS &&
                      !notification.comment?.deleted &&
                      notification.comment?.id
                          ? notification.comment.id
                          : undefined,
                  taskId:
                      flowType === PostDetailSection.TASKS &&
                      !notification.task?.deleted &&
                      notification.task?.id
                          ? notification.task.id
                          : undefined,
              }
            : undefined;

        return {
            id: postId,
            url: ['/post', postId.toString()],
            event: notification,
            queryParams,
        };
    }

    static emptyNotificationList(): NotificationList {
        return {
            ...paginatedListFromArray<IEventActivity>([]),
            onlyUnread: false,
        };
    }
}

export class NotificationDeserialize {
    public static workflowList = (
        record: any,
        currentLang: string = defaultSettings.language.code,
        currentUserData: IUser,
        configurationService: ConfigurationService,
        metadata?: IPostMetadata,
    ): PaginatedList<IEventHistory> => {
        const workflowList = <PaginatedList<IEventHistory>>{};
        workflowList.list =
            record.items.length > 0
                ? record.items.map((item: any) =>
                      NotificationDeserialize.eventHistoryItem(
                          item,
                          currentLang,
                          currentUserData,
                          NotificationScopeType.WORKFLOW_POST,
                          configurationService,
                          metadata,
                      ),
                  )
                : [];
        workflowList.nextPageToken = record.nextPageToken
            ? regularLoading(record.nextPageToken as string)
            : lastLoading();
        return workflowList;
    };

    public static historyList = (
        record: any,
        currentLang: string = defaultSettings.language.code,
        currentUserData: IUser,
        configurationService: ConfigurationService,
    ): PaginatedList<IEventHistory> => {
        const postList = <PaginatedList<IEventHistory>>{};
        postList.list =
            record.items.length > 0
                ? (<Array<any>>record.items).map((item) =>
                      NotificationDeserialize.eventHistoryItem(
                          item,
                          currentLang,
                          currentUserData,
                          NotificationScopeType.HISTORY_POST,
                          configurationService,
                      ),
                  )
                : [];
        return postList;
    };

    public static activityNotificationList = (
        record: any,
        configurationService: ConfigurationService,
    ): NotificationList => {
        const eventList: NotificationList = {
            list: ((record.items as any[]) || []).map((item: any) =>
                NotificationDeserialize.eventActivityItem(
                    item,
                    configurationService,
                ),
            ),
            nextPageToken: fromPageToken(
                record.nextPageToken as string | null | undefined,
            ),
            totalCount: record.totalItemsCount ?? 0,
            isFetching: false,
            nextSyncToken: record.nextSyncToken,
            onlyUnread: record.onlyUnread,
        };
        return eventList;
    };

    public static eventHistoryItem = (
        record: any,
        currentLang: string,
        currentUserData: IUser,
        notificationScope: NotificationScopeType,
        configurationService: ConfigurationService,
        metadata?: IPostMetadata,
    ): IEventHistory => {
        const item = <IEventHistory>{};
        item.id = record.id;
        item.timestamp = record.timestamp
            ? new Date(record.timestamp as number)
            : null;
        item.typeId = record.typeId;
        item.typeDescription = record.typeDescription;
        item.postId = record.postId;
        item.postWorkflowOperationDetail = record.postWorkflowOperationDetail
            ? toWorkflowOperation(
                  record.postWorkflowOperationDetail,
                  metadata?.workflowDefinition,
              )
            : null;
        item.commentId = record.commentId;
        item.task = record.task
            ? toStandardTask(record.task, configurationService)
            : null;
        item.postCurrentTitle = record.postCurrentTitle;
        item.postBeforeUpdateTitle = record.postBeforeUpdateTitle;
        item.postAfterUpdateTitle = record.postAfterUpdateTitle;
        item.notes = record.notes;
        item.addedAttachmentsCount = record.addedAttachmentsCount;
        item.removedAttachmentsCount = record.removedAttachmentsCount;
        item.versionedAttachmentsCount = record.versionedAttachmentsCount;
        item.authorUser = record.authorUser
            ? UserDeserilize.userDetails(record.authorUser)
            : null;
        item.targetUser = record.targetUser
            ? UserDeserilize.userDetails(record.targetUser)
            : null;
        item.attachment = record.attachment;
        item.attachmentVersion = record.attachmentVersion;
        item.updatedAttachmentsCount = record.updatedAttachmentsCount;
        item.computedEventData =
            notificationScope === NotificationScopeType.WORKFLOW_POST
                ? undefined
                : new NotificationEventData(
                      item,
                      currentLang,
                      currentUserData,
                      notificationScope,
                  );

        const workflowScreenData = record.workflowScreenData
            ? NotificationDeserialize.toWorkflowScreenData(
                  item.typeId,
                  record.workflowScreenData as Record<number, any>,
                  record.updatedWorkflowScreenFieldIds as number[] | undefined,
                  metadata,
              )
            : undefined;

        item.workflowScreenData = workflowScreenData;
        item.updatedWorkflowScreenDataCount =
            workflowScreenData?.length ?? undefined;
        return item;
    };

    public static toWorkflowScreenData(
        typeId: EventType,
        workflowScreenData: Record<number, any>,
        updatedWorkflowScreenFieldIds: number[] | undefined,
        metadata?: IPostMetadata,
    ): ICustomField[] {
        const screenFields = toScreenCustomFields(
            workflowScreenData,
            metadata,
            true,
        );
        let screenFieldsFiltered: ICustomField[] = [];
        if (typeId === EventType.CHANGE_STATUS) {
            screenFieldsFiltered = screenFields.data.filter(
                (field) =>
                    //aggiornati
                    updatedWorkflowScreenFieldIds?.includes(
                        field.configuration.id,
                    ) ||
                    //popolati
                    !isCustomValueBlank(field.viewValue),
            );
        } else if (typeId === EventType.EDIT_SCREEN_DATA) {
            screenFieldsFiltered = screenFields.data.filter((field) =>
                updatedWorkflowScreenFieldIds?.includes(field.configuration.id),
            );
        }
        return screenFieldsFiltered;
    }

    public static eventActivityItem = (
        record: any,
        configurationService: ConfigurationService,
    ): IEventActivity => {
        const post = record.post
            ? PostListDeserialize.postDetails(configurationService, record.post)
            : null;

        return {
            id: record.id,
            title: record.title,
            typeDescription: record.typeDescription,
            typeId: record.typeId,
            timestamp: new Date(record.timestamp as number),
            read: record.read,
            hashtags: record.hashtags ? record.hashtags.map(toHashtag) : [],
            post,
            task: record.task
                ? toGenericTask(record.task, configurationService, post)
                : null,
            postWorkflowOperationDetail: record.postWorkflowOperationDetail
                ? toWorkflowOperation(record.postWorkflowOperationDetail)
                : null,
            firstPostWorkflowOperationDetail:
                record.firstPostWorkflowOperationDetail
                    ? toWorkflowOperation(
                          record.firstPostWorkflowOperationDetail,
                      )
                    : null,
            lastPostWorkflowOperationDetail:
                record.lastPostWorkflowOperationDetail
                    ? toWorkflowOperation(
                          record.lastPostWorkflowOperationDetail,
                      )
                    : null,
            lastTaskTransitionOperationDetail:
                record.lastTaskTransitionOperationDetail ?? null,
            workflowState: record.workflowState
                ? toWorkflowStatus(record.workflowState)
                : null,
            workspace: record.workspace
                ? WorkspaceDeserialize.workspaceDetails(record.workspace)
                : null,
            addedAttachments: record.addedAttachments
                ? record.addedAttachments.map(toAttachmentDetails)
                : [],
            updatedAttachments: record.updatedAttachments
                ? record.updatedAttachments.map(toAttachmentDetails)
                : [],
            versionedAttachments: record.versionedAttachments
                ? record.versionedAttachments.map(toAttachmentDetails)
                : [],
            authorUsers: record.authorUsers
                ? record.authorUsers.map(UserDeserilize.userDetails)
                : [],
            aggretated: record.aggregated ?? false,
            aggregatedNotificationIds: record.aggregatedNotificationIds,
            community: record.community
                ? CommunityTreeDeserialize.communityInfoDetails(
                      record.community,
                  )
                : null,
            descriptionPlainText: record.descriptionPlainText,
            descriptionDelta: record.descriptionDelta
                ? new Server2Delta().process(record.descriptionDelta as string)
                : null,
            comment: record.comment
                ? CommentDeserialize.comment(record.comment)
                : null,
            pushEnabled: record.pushEnabled ?? false,
        };
    };

    public static notificationEvent = (
        record: any,
        configurationService: ConfigurationService,
    ): IEventActivity => {
        const notification: IEventActivity =
            NotificationDeserialize.eventActivityItem(
                record,
                configurationService,
            );
        return notification;
    };
}

// ******************* NOTIFICATION BELL/HISTORY HENDLER **********************//
// ****************** for circular dependency must be here ********************//

export enum NotificationScopeType {
    BELL = 1,
    HISTORY_POST = 2,
    WORKFLOW_POST = 3,
}

export interface INotificationLabelParams {
    firstName: string;
    lastName: string;
    fullName: string;
    postCurrentTitle: string;
    postBeforeUpdateTitle: string;
    postAfterUpdateTitle: string;
    toFirstName: string;
    toLastName: string;
    fromState: string;
    toState: string;
    attachmentName: string;
    taskName: string;
    expirationDate: string;
    actionName: string;
    addedAttachmentsCount: number;
    removedAttachmentsCount: number;
    versionedAttachmentsCount: number;
    updatedAttachmentsCount: number;
}

export interface INotificationMessage {
    label: string;
    pluralize?: {
        condition: number;
        labels: {
            '=0': string;
            '=1': string;
            other: string;
        };
    };
    params: INotificationLabelParams;
}

export class NotificationEventData {
    messages: INotificationMessage[]; // if multiple label needed

    constructor(
        public notification: IEventHistory,
        public currentLang: string,
        public currentUser: IUser,
        public scope: NotificationScopeType = NotificationScopeType.BELL,
    ) {
        this.messages = [];
        this._computeMessageByNotificationType(this.notification);
    }

    public hasData() {
        return this.messages && this.messages.length > 0;
    }

    private _computeMessageByNotificationType(userNotification: IEventHistory) {
        const historyLabelSelector =
            this.scope === NotificationScopeType.HISTORY_POST ? '.HISTORY' : '';
        const notification: IEventHistory = userNotification;
        const commonParams = <INotificationLabelParams>{
            firstName: notification.authorUser?.firstName,
            lastName: notification.authorUser?.lastName,
            fullName: notification.authorUser?.fullName,
            postCurrentTitle:
                this.scope != NotificationScopeType.HISTORY_POST &&
                notification.postCurrentTitle
                    ? notification.postCurrentTitle
                    : '',
        };
        const message = <INotificationMessage>{
            params: commonParams,
        };
        switch (notification.typeId) {
            case EventType.CREATION:
                message.label = 'NOTIFICATION.LABEL_CREATION';
                if (
                    notification.addedAttachmentsCount &&
                    notification.addedAttachmentsCount > 0
                ) {
                    commonParams.addedAttachmentsCount =
                        notification.addedAttachmentsCount;
                    this._notificationPluralize(
                        message,
                        'NOTIFICATION.LABEL_CREATION_WITH_ATTACHMENT',
                        notification.addedAttachmentsCount,
                    );
                }
                this.messages.push(message);
                break;
            case EventType.COPY:
                message.label = 'NOTIFICATION.LABEL_COPY';
                this.messages.push(message);
                break;
            case EventType.REMOVE_POST:
                message.label = 'NOTIFICATION.LABEL_REMOVE_POST';
                this.messages.push(message);
                break;
            case EventType.REMOVE_COMMENT:
                message.label =
                    'NOTIFICATION' +
                    historyLabelSelector +
                    '.LABEL_REMOVE_COMMENT';
                this.messages.push(message);
                break;
            case EventType.EDIT_SCREEN_DATA:
                message.label = 'NOTIFICATION.LABEL_EDIT_SCREEN_DATA';
                this.messages.push(message);
                break;
            case EventType.EDIT: {
                let messageEdit =
                    'NOTIFICATION' + historyLabelSelector + '.LABEL_EDIT';
                const hasAttachmentCount =
                    notification.addedAttachmentsCount > 0 ||
                    notification.removedAttachmentsCount > 0 ||
                    notification.versionedAttachmentsCount > 0;

                if (
                    notification.postBeforeUpdateTitle &&
                    notification.postBeforeUpdateTitle !=
                        notification.postAfterUpdateTitle &&
                    !hasAttachmentCount
                ) {
                    commonParams.postBeforeUpdateTitle =
                        notification.postBeforeUpdateTitle;
                    commonParams.postAfterUpdateTitle =
                        notification.postAfterUpdateTitle;
                    messageEdit = 'NOTIFICATION.LABEL_EDIT_TITLE';
                } else if (
                    notification.postBeforeUpdateTitle &&
                    notification.postBeforeUpdateTitle !=
                        notification.postAfterUpdateTitle &&
                    hasAttachmentCount
                ) {
                    commonParams.postBeforeUpdateTitle =
                        notification.postBeforeUpdateTitle;
                    commonParams.postAfterUpdateTitle =
                        notification.postAfterUpdateTitle;
                    messageEdit =
                        'NOTIFICATION.LABEL_EDIT_TITLE_WITH_ATTACHMENT';
                } else if (
                    (!notification.postBeforeUpdateTitle ||
                        notification.postBeforeUpdateTitle ==
                            notification.postAfterUpdateTitle) &&
                    hasAttachmentCount
                ) {
                    messageEdit = 'NOTIFICATION.LABEL_EDIT_WITH_ATTACHMENT';
                }

                this.messages.push({
                    label: messageEdit,
                    params: commonParams,
                });
                break;
            }
            case EventType.COMMENT:
                message.label =
                    'NOTIFICATION' + historyLabelSelector + '.LABEL_COMMENT';
                this.messages.push(message);
                break;
            case EventType.MENTION_POST:
                if (
                    this._notificationForMentionUser(
                        message,
                        commonParams,
                        'NOTIFICATION' +
                            historyLabelSelector +
                            '.LABEL_MENTION_POST',
                        notification,
                    )
                ) {
                    this.messages.push(message);
                }
                break;
            case EventType.MENTION_COMMENT:
                if (
                    this._notificationForMentionUser(
                        message,
                        commonParams,
                        'NOTIFICATION' +
                            historyLabelSelector +
                            '.LABEL_MENTION_COMMENT',
                        notification,
                    )
                ) {
                    this.messages.push(message);
                }
                break;
            case EventType.CHANGE_STATUS:
                message.label = 'NOTIFICATION.LABEL_STATUS_NOTIFICATION';
                this.messages.push(message);

                if (notification.postWorkflowOperationDetail) {
                    const params = <INotificationLabelParams>{
                        fromState: getLabelServerTranslation(
                            notification.postWorkflowOperationDetail.fromState
                                .name,
                            this.currentLang,
                        ),
                        toState: getLabelServerTranslation(
                            notification.postWorkflowOperationDetail.toState
                                .name,
                            this.currentLang,
                        ),
                    };
                    this.messages.push({
                        label: 'NOTIFICATION.LABEL_CHANGE_STATUS_NOTIFICATION',
                        params: params,
                    });
                }
                break;
            case EventType.ADD_ATTACHMENT:
                message.label = 'NOTIFICATION.LABEL_ADD_ATTACHMENT';
                message.params.attachmentName = notification.attachment.name;
                this.messages.push(message);
                break;
            case EventType.REMOVE_ATTACHMENT:
                message.label = 'NOTIFICATION.LABEL_REMOVE_ATTACHMENT';
                message.params.attachmentName = notification.attachment.name;
                this.messages.push(message);
                break;
            case EventType.ADD_ATTACHMENT_VERSION:
                message.label = 'NOTIFICATION.LABEL_ADD_VERSION';
                message.params.attachmentName = notification.attachment.name;
                this.messages.push(message);
                break;
            case EventType.CREATE_TASK:
                message.label =
                    'NOTIFICATION' +
                    historyLabelSelector +
                    '.LABEL_CREATE_TASK';
                message.params.taskName = notification.task?.title ?? '';
                this.messages.push(message);
                break;
            case EventType.EDIT_TASK:
                message.label =
                    'NOTIFICATION' + historyLabelSelector + '.LABEL_EDIT_TASK';
                message.params.taskName = notification.task?.title ?? '';
                this.messages.push(message);
                break;
            case EventType.ADD_COMMENT_ON_TASK:
                message.label = 'NOTIFICATION.LABEL_ADD_COMMENT_ON_TASK';
                message.params.taskName = notification.task?.title ?? '';
                this.messages.push(message);
                break;
            case EventType.ADD_TASK_ATTACHMENT:
                message.label = 'NOTIFICATION.LABEL_ADD_TASK_ATTACHMENT';
                message.params.taskName = notification.task?.title ?? '';
                this.messages.push(message);
                break;
            case EventType.REMOVE_TASK_ATTACHMENT:
                message.label = 'NOTIFICATION.LABEL_REMOVE_TASK_ATTACHMENT';
                message.params.taskName = notification.task?.title ?? '';
                this.messages.push(message);
                break;
            case EventType.COPY_TASK:
                message.label = 'NOTIFICATION.LABEL_COPY_TASK';
                message.params.taskName = notification.task?.title ?? '';
                this.messages.push(message);
                break;
            case EventType.DELETE_TASK:
                message.label = 'NOTIFICATION.LABEL_DELETE_TASK';
                message.params.taskName = notification.task?.title ?? '';
                this.messages.push(message);
                break;
            case EventType.DELETE_TASK_COMMENT:
                message.label = 'NOTIFICATION.LABEL_DELETE_TASK_COMMENT';
                message.params.taskName = notification.task?.title ?? '';
                this.messages.push(message);
                break;
            case EventType.MENTION_TASK:
                if (
                    this._notificationForMentionUser(
                        message,
                        commonParams,
                        'NOTIFICATION.LABEL_MENTIONED_TASK',
                        notification,
                    )
                ) {
                    message.params.taskName = notification.task?.title ?? '';
                    this.messages.push(message);
                }
                break;
            case EventType.MENTION_TASK_COMMENT:
                if (
                    this._notificationForMentionUser(
                        message,
                        commonParams,
                        'NOTIFICATION.LABEL_MENTIONED_TASK_COMMENT',
                        notification,
                    )
                ) {
                    message.params.taskName = notification.task?.title ?? '';
                    this.messages.push(message);
                }
                break;
            case EventType.TASK_REMINDER: {
                message.label = 'NOTIFICATION.LABEL_TASK_REMINDER';
                message.params.taskName = notification.task?.title ?? '';
                // message.params.expirationDate = formatDateAndHour(
                //     notification.task?.expiration.zonedDatetime ?? new Date(),
                //     this.currentLang,
                // );
                const expirationDate =
                    notification.task?.expiration?.zonedDatetime ?? new Date();
                message.params.expirationDate = formatDateUsingIntl(
                    expirationDate,
                    {
                        month: 'short',
                        day: 'numeric',
                        year: isThisYear(expirationDate)
                            ? undefined
                            : 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                    'PPp',
                    this.currentLang,
                );
                this.messages.push(message);
                break;
            }
            case EventType.ASSIGNED_TASK:
                if (this.scope === NotificationScopeType.BELL) {
                    message.label = 'NOTIFICATION.LABEL_ASSIGNED_TASK_TO_YOU';
                    message.params.taskName = notification.task?.title ?? '';
                    this.messages.push(message);
                }
                break;
            case EventType.OPENED_TASK:
                message.label = 'NOTIFICATION.LABEL_OPENED_TASK';
                message.params.taskName = notification.task?.title ?? '';
                this.messages.push(message);
                break;
            case EventType.CLOSED_TASK:
                switch (notification.task?.type) {
                    case ITaskType.ACKNOWLEDGE:
                        message.label =
                            'NOTIFICATION.LABEL_ACKNOWLEDGMENT_SENT';
                        break;
                    default:
                        message.label = 'NOTIFICATION.LABEL_CLOSED_TASK';
                }

                message.params.taskName = notification.task?.title ?? '';
                this.messages.push(message);
                break;
            case EventType.ADD_COMMENT_ON_YOUR_POST:
            case EventType.REPLY_YOUR_COMMENT:
            case EventType.REOPENED_YOUR_TASK:
            case EventType.CLOSED_YOUR_TASK:
            case EventType.DELETED_YOUR_TASK:
                //notifications added for IISP-IISP-6438
                console.log({
                    message: `MISSING IMPLEMENTATION OF NOTIFICATIONS: ${notification.typeId}`,
                    server: notification,
                });
                break;
            case EventType.UPDATE_VISIBILITY_POST:
                message.label =
                    'NOTIFICATION' +
                    historyLabelSelector +
                    '.LABEL_UPDATE_VISIBILITY_POST';
                this.messages.push(message);
                break;
            case EventType.UPDATE_ATTACHMENT:
                message.label =
                    'NOTIFICATION' +
                    historyLabelSelector +
                    '.LABEL_UPDATE_ATTACHMENT';
                message.params.attachmentName = notification.attachment.name;
                this.messages.push(message);
                break;
            case EventType.FOLLOW_ADDED:
                message.label = 'NOTIFICATION.LABEL_FOLLOW_ADDED';
                this.messages.push(message);
                break;
            case EventType.EDITED_POST_ATTACHMENTS:
                commonParams.addedAttachmentsCount =
                    notification.addedAttachmentsCount;
                commonParams.removedAttachmentsCount =
                    notification.removedAttachmentsCount;
                commonParams.updatedAttachmentsCount =
                    notification.updatedAttachmentsCount;
                commonParams.versionedAttachmentsCount =
                    notification.versionedAttachmentsCount;
                if (
                    notification.addedAttachmentsCount > 0 &&
                    !(notification.removedAttachmentsCount > 0)
                ) {
                    this._notificationPluralize(
                        message,
                        'NOTIFICATION' +
                            historyLabelSelector +
                            '.LABEL_ADDED_POST_ATTACHMENTS',
                        notification.addedAttachmentsCount,
                    );
                } else if (
                    notification.removedAttachmentsCount > 0 &&
                    !(notification.addedAttachmentsCount > 0)
                ) {
                    this._notificationPluralize(
                        message,
                        'NOTIFICATION' +
                            historyLabelSelector +
                            '.LABEL_DELETED_POST_ATTACHMENTS',
                        notification.removedAttachmentsCount,
                    );
                } else if (notification.updatedAttachmentsCount > 0) {
                    this._notificationPluralize(
                        message,
                        'NOTIFICATION' +
                            historyLabelSelector +
                            '.LABEL_EDITED_POST_ATTACHMENTS',
                        notification.updatedAttachmentsCount,
                    );
                } else if (notification.versionedAttachmentsCount > 0) {
                    this._notificationPluralize(
                        message,
                        'NOTIFICATION' +
                            historyLabelSelector +
                            '.LABEL_VERSIONED_POST_ATTACHMENTS',
                        notification.versionedAttachmentsCount,
                    );
                }
                this.messages.push(message);
                break;
            case EventType.CREATE_ACKNOWLEDGE_TASK_REQUEST:
                message.label = `NOTIFICATION${historyLabelSelector}.LABEL_CREATE_ACKNOWLEDGE_TASK_REQUESTED`;
                this.messages.push(message);
                break;
            case EventType.EVENT_INVITATION_GUEST:
            case EventType.EVENT_INVITATION_STAFF:
            case EventType.EDIT_EVENT:
            //notification added for IISP-5256
            // eslint-disable-next-line no-fallthrough
            default:
                console.log({
                    message: `MISSING IMPLEMENTATION OF NOTIFICATIONS: ${notification.typeId}`,
                    server: notification,
                });
        }
    }

    private _notificationForMentionUser(
        message: INotificationMessage,
        params: INotificationLabelParams,
        label: string,
        notification: IEventHistory,
    ): boolean {
        if (!notification.targetUser) {
            return false;
        }

        message.label = label;
        params.toFirstName = notification.targetUser.firstName;
        params.toLastName = notification.targetUser.lastName;
        if (
            notification.authorUser &&
            notification.authorUser.id === this.currentUser.id
        ) {
            message.label = `${label}_FROM_YOU`;
        }
        if (
            notification.targetUser &&
            notification.targetUser.id === this.currentUser.id
        ) {
            message.label = `${label}_TO_YOU`;
        }
        return true;
    }

    private _notificationPluralize(
        message: INotificationMessage,
        label: string,
        condition: number,
    ): boolean {
        message.label = label;
        message.pluralize = {
            condition: condition,
            labels: {
                '=0': ``,
                '=1': `${label}_SING`,
                other: `${label}_PLUR`,
            },
        };
        return true;
    }
}
