<textarea
    *ngIf="control | inputState: isReadonly | async as state"
    class="h-auto w-full resize-none rounded-lg border-2"
    type="text"
    [formControl]="control"
    [ngClass]="{
        'border-border-300 focus:border-text': !state.invalid,
        'border-border-error': state.invalid,
        'bg-surface-A text-text': !state.disabled,
        'bg-surface-100 text-text-disabled pointer-events-none cursor-default':
            state.disabled,
        'pt-8 indent-36': withMarginLeft,
        'pl-8': !withMarginLeft
    }"
    [placeholder]="label || ''"
    [readonly]="state.disabled"
    [rows]="maxRows"
></textarea>
