import { TranslateService } from '@ngx-translate/core';
import {
    format,
    isToday,
    isTomorrow,
    isYesterday,
    startOfDay,
    startOfHour,
    startOfMinute,
    startOfMonth,
    startOfSecond,
    startOfYear,
} from 'date-fns';

export type DateGranularity =
    | 'year'
    | 'month'
    | 'day'
    | 'hour'
    | 'minute'
    | 'second';

export function dateGranularity(
    date: Date,
    granularity: DateGranularity,
): Date {
    switch (granularity) {
        case 'year':
            return startOfYear(date);
        case 'month':
            return startOfMonth(date);
        case 'day':
            return startOfDay(date);
        case 'hour':
            return startOfHour(date);
        case 'minute':
            return startOfMinute(date);
        case 'second':
            return startOfSecond(date);
        default:
            throw new Error('Unhandled case in dateGranularity()');
    }
}

/**
 * Date-fns's localization doesn't support custom localized formatting (i.e: you can't format a localized date without an year).
 * This function uses the browser's Intl API to apply a custom formatting to a date.
 * The date-fns default formatting is used as a fallback when the Intl API is not available.
 */
export function formatDateUsingIntl(
    date: Date,
    intlFormat: Intl.DateTimeFormatOptions,
    fallbackFormat: string,
    localeCode: string,
): string {
    return typeof Intl !== 'undefined'
        ? new Intl.DateTimeFormat(localeCode, intlFormat).format(date)
        : format(date, fallbackFormat);
}

export function formatDateRelative(
    date: Date,
    localeCode: string,
    translate: TranslateService,
): string {
    if (isYesterday(date)) {
        return `${
            translate.instant('UI.DATE_TIME.YESTERDAY') as string
        } ${formatDate(date, 'time', localeCode)}`;
    } else if (isTomorrow(date)) {
        return `${
            translate.instant('UI.DATE_TIME.TOMORROW') as string
        } ${formatDate(date, 'time', localeCode)}`;
    } else if (isToday(date)) {
        return `${translate.instant('UI.DATE_TIME.TODAY') as string} ${formatDate(
            date,
            'time',
            localeCode,
        )}`;
    } else {
        return formatDate(date, 'datetime', localeCode);
    }
}

const simpleDateFormats = ['date', 'time', 'datetime'] as const;

export type SimpleDateFormat = (typeof simpleDateFormats)[number];

export interface CustomDateFormat {
    format: Intl.DateTimeFormatOptions;
    fallbackFormat: string;
}

export const intlFormatMap: Record<SimpleDateFormat, CustomDateFormat> = {
    date: {
        format: { day: 'numeric', month: 'short', year: 'numeric' },
        fallbackFormat: 'PP',
    },
    time: {
        format: { hour: 'numeric', minute: 'numeric' },
        fallbackFormat: 'p',
    },
    datetime: {
        format: {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        },
        fallbackFormat: 'PP, p',
    },
};

export function formatDate(
    date: Date | number | null | undefined,
    format: SimpleDateFormat | CustomDateFormat,
    localeCode: string,
): string {
    const _format = typeof format === 'string' ? intlFormatMap[format] : format;

    const _date = isNumber(date) ? new Date(date) : date;

    return _date != null && !isNaN(_date.getTime())
        ? formatDateUsingIntl(
              _date,
              _format.format,
              _format.fallbackFormat,
              localeCode,
          )
        : '';
}

function isNumber(date: Date | number | null | undefined): date is number {
    return date != null && typeof date === 'number';
}

export function roundTimeToInterval(date: Date, minutes: number): Date {
    const interval = minutes * 60 * 1000; // minutes to milliseconds
    return new Date(Math.ceil(date.getTime() / interval) * interval);
}
