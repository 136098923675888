<ng-container *ngIf="control | inputState: isReadonly | async as state">
    <div
        #openMenuBtn="cdkOverlayOrigin"
        cdkOverlayOrigin
        class="flex min-h-40 items-center justify-between rounded-lg border-2 px-8 py-4"
        [ngClass]="{
            'border-border-300 focus-within:border-text': !state.invalid,
            'border-border-error': state.invalid,
            'bg-surface-A text-text': !state.disabled,
            'cursor-pointer': !state.disabled,
            'bg-surface-100 text-text-disabled pointer-events-none cursor-default':
                state.disabled
        }"
        (click)="openMenu($event)"
    >
        <div
            *ngIf="!isOpen"
            cdkDropList
            class="flex flex-wrap gap-4"
            [cdkDropListAutoScrollStep]="5"
            [ngClass]="{
                'scrollable-list draggable-list-placeholder flex-col ':
                    enableDragAndDrop
            }"
            (cdkDropListDropped)="onItemDrop($event)"
        >
            <ng-container *ngIf="{ value: value$ | async } as $">
                <span
                    *ngIf="!$.value?.length && label"
                    class="typo-sm text-text-low-contrast"
                >
                    {{ label }}
                </span>
                <ng-container *ngIf="!enableDragAndDrop">
                    <div
                        *ngFor="let item of $.value; trackBy: trackItem"
                        class="text-text flex max-w-full shrink-0 cursor-default items-center px-8"
                        [ngClass]="{
                            'bg-surface-B rounded-3xl px-8 py-4': showChip,
                            'h-24': chipSize === 'small'
                        }"
                        (click)="$event.stopPropagation()"
                    >
                        <ng-template
                            [ngTemplateOutlet]="selectedItemTemplate"
                            [ngTemplateOutletContext]="{ item: item }"
                        >
                        </ng-template>

                        <button
                            *ngIf="
                                maxSelectableItems > 1 &&
                                !lockedIds.includes(item.id)
                            "
                            class="-my-4"
                            interacta-icon-button
                            type="button"
                            [icon]="'close'"
                            [ngClass]="{
                                'ml-8': chipSize === 'regular',
                                'ml-4': chipSize === 'small'
                            }"
                            [size]="
                                chipSize === 'small' ? 'extra-small' : 'small'
                            "
                            (click)="
                                removeItemByChip(item); $event.stopPropagation()
                            "
                        ></button>
                    </div>
                </ng-container>
                <ng-container *ngIf="enableDragAndDrop">
                    <div
                        *ngFor="let item of $.value; trackBy: trackItem"
                        cdkDrag
                        class="flex items-center gap-x-4"
                        [ngClass]="{
                            'cursor-grab': !isGrabbing
                        }"
                        (cdkDragEnded)="isGrabbing = false"
                        (cdkDragStarted)="isGrabbing = true"
                    >
                        <interacta-icon
                            *ngIf="$.value && $.value.length > 1"
                            class="text-text-mid-contrast h-16 w-16 cursor-grab"
                            icon="equals"
                        >
                        </interacta-icon>
                        <div
                            class="text-text draggable-item flex w-max shrink-0 items-center px-8"
                            [ngClass]="{
                                'bg-surface-B rounded-3xl py-4': showChip,
                                'h-24': chipSize === 'small',
                                'cursor-grab': !isGrabbing
                            }"
                            (click)="$event.stopPropagation()"
                        >
                            <ng-template
                                [ngTemplateOutlet]="selectedItemTemplate"
                                [ngTemplateOutletContext]="{ item: item }"
                            >
                            </ng-template>

                            <button
                                *ngIf="
                                    maxSelectableItems > 1 &&
                                    !lockedIds.includes(item.id)
                                "
                                class="-my-4"
                                interacta-icon-button
                                type="button"
                                [icon]="'close'"
                                [ngClass]="{
                                    'ml-8': chipSize === 'regular',
                                    'ml-4': chipSize === 'small'
                                }"
                                [size]="
                                    chipSize === 'small'
                                        ? 'extra-small'
                                        : 'small'
                                "
                                (click)="
                                    removeItemByChip(item);
                                    $event.stopPropagation()
                                "
                            ></button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <input
            *ngIf="isOpen"
            #input
            autocomplete="off"
            class="w-full pr-40"
            type="text"
            [formControl]="textSearchControl"
            [id]="inputId"
            [readonly]="textSearchControl.disabled || isReadonly"
            (focus)="inputFocus()"
        />
        <button
            class="ml-auto"
            interacta-icon-button
            type="button"
            [icon]="
                isLimitReached && this.maxSelectableItems === 1
                    ? 'close'
                    : isOpen
                      ? 'search-left'
                      : 'chevron-big-down'
            "
            [ngClass]="{
                'text-text-mid-contrast': !state.invalid,
                'text-text-error': state.invalid
            }"
            (click)="
                isLimitReached && this.maxSelectableItems === 1
                    ? clearSelection()
                    : openMenu($event)
            "
        ></button>
    </div>

    <interacta-menu
        positionX="after"
        positionY="below"
        [open]="isOpen"
        [origin]="openMenuBtn"
        (closing)="isOpen && closeMenu()"
    >
        <interacta-menu-decorator
            class="block"
            [style.width.px]="openMenuBtn.elementRef.nativeElement?.clientWidth"
        >
            <div
                *ngIf="{
                    itemsCount: _items.list.length ?? 0,
                    withVirtualScroll:
                        enableVirtualScroll &&
                        (_items.list.length ?? 0) >= VIRTUAL_SCROLL_THRESHOLD
                } as $"
                #scroll
                class="overflow-y-auto"
                interactaArrowKeysList
                interactaScrollTracker
                [ngClass]="{
                    'min-h-200': !$.withVirtualScroll && $.itemsCount > 4,
                    'max-h-240': !$.withVirtualScroll,
                    'h-240': $.withVirtualScroll
                }"
                [selectedIndex]="
                    isOpen ? ($.itemsCount > 0 ? currentIndex : null) : 0
                "
                (changeSelectedIndex)="isOpen && (currentIndex = $event)"
                (itemClicked)="
                    isOpen && changeSelection(true, _items.list[$event])
                "
                (scrolled)="onScroll($event)"
            >
                @if (searchPlaceholderTemplate && showSearchPlaceholders) {
                    <div class="flex flex-col">
                        <ng-template
                            [ngTemplateOutlet]="searchPlaceholderTemplate"
                        />
                    </div>
                } @else {
                    @if ($.itemsCount) {
                        <div class="flex h-full flex-col">
                            @if (!$.withVirtualScroll) {
                                @for (
                                    item of _items.list;
                                    track item.id;
                                    let idx = $index
                                ) {
                                    @if (maxSelectableItems === 1) {
                                        <button
                                            class="typo-medium hover:bg-surface-B px-12 text-left"
                                            interactaArrowKeysItem
                                            type="button"
                                            [ngClass]="{
                                                'bg-surface-300':
                                                    currentIndex === idx,
                                                'border-border-300 border-b ':
                                                    item.id ===
                                                    EMPTY_SEARCH_VALUE
                                            }"
                                            (click)="
                                                changeSelection(true, item)
                                            "
                                        >
                                            <ng-template
                                                [ngTemplateOutlet]="
                                                    searchItemTemplate
                                                "
                                                [ngTemplateOutletContext]="{
                                                    item
                                                }"
                                            />
                                        </button>
                                    } @else {
                                        <interacta-checkbox
                                            *ngIf="{
                                                isSelected:
                                                    { selectedItems, item }
                                                    | apply: isSelected
                                            } as itemInfo"
                                            class="typo-medium hover:bg-surface-B cursor-pointer px-12"
                                            interactaArrowKeysItem
                                            [checked]="itemInfo.isSelected"
                                            [disabled]="
                                                lockedIds.includes(item.id) ||
                                                state.disabled
                                            "
                                            [fullWidth]="true"
                                            [ngClass]="{
                                                'bg-surface-300':
                                                    currentIndex === idx,
                                                'border-border-300 border-b':
                                                    item.id ===
                                                    EMPTY_SEARCH_VALUE
                                            }"
                                            [value]="item.id"
                                            (changeCheck)="
                                                changeSelection(
                                                    $event === true,
                                                    item
                                                )
                                            "
                                        >
                                            <ng-template
                                                [ngTemplateOutlet]="
                                                    searchItemTemplate
                                                "
                                                [ngTemplateOutletContext]="{
                                                    item
                                                }"
                                            />
                                        </interacta-checkbox>
                                    }
                                }
                            } @else {
                                <virtual-scroller
                                    class="h-full"
                                    [enableUnequalChildrenSizes]="false"
                                    [items]="_items.list"
                                    [parentScroll]="scroll"
                                    (vsUpdate)="displayedItems = $event"
                                >
                                    <div class="flex flex-col">
                                        @for (
                                            item of displayedItems;
                                            track item.id;
                                            let idx = $index
                                        ) {
                                            @if (maxSelectableItems === 1) {
                                                <button
                                                    class="typo-medium hover:bg-surface-B px-12 text-left"
                                                    interactaArrowKeysItem
                                                    type="button"
                                                    [ngClass]="{
                                                        'bg-surface-300':
                                                            currentIndex ===
                                                            idx,
                                                        'border-border-300 border-b':
                                                            item.id ===
                                                            EMPTY_SEARCH_VALUE
                                                    }"
                                                    (click)="
                                                        changeSelection(
                                                            true,
                                                            item
                                                        )
                                                    "
                                                >
                                                    <ng-template
                                                        [ngTemplateOutlet]="
                                                            searchItemTemplate
                                                        "
                                                        [ngTemplateOutletContext]="{
                                                            item
                                                        }"
                                                    />
                                                </button>
                                            } @else {
                                                <interacta-checkbox
                                                    *ngIf="{
                                                        isSelected:
                                                            {
                                                                selectedItems,
                                                                item
                                                            }
                                                            | apply: isSelected
                                                    } as itemInfo"
                                                    class="typo-medium hover:bg-surface-B cursor-pointer px-12"
                                                    interactaArrowKeysItem
                                                    [checked]="
                                                        itemInfo.isSelected
                                                    "
                                                    [disabled]="
                                                        lockedIds.includes(
                                                            item.id
                                                        ) || state.disabled
                                                    "
                                                    [fullWidth]="true"
                                                    [ngClass]="{
                                                        'bg-surface-300':
                                                            currentIndex ===
                                                            idx,
                                                        'border-border-300 border-b':
                                                            item.id ===
                                                            EMPTY_SEARCH_VALUE
                                                    }"
                                                    [value]="item.id"
                                                    (changeCheck)="
                                                        changeSelection(
                                                            $event === true,
                                                            item
                                                        )
                                                    "
                                                >
                                                    <ng-template
                                                        [ngTemplateOutlet]="
                                                            searchItemTemplate
                                                        "
                                                        [ngTemplateOutletContext]="{
                                                            item
                                                        }"
                                                    />
                                                </interacta-checkbox>
                                            }
                                        }
                                    </div>
                                </virtual-scroller>
                            }
                        </div>
                    }

                    @if (_items.nextPageToken.tag === 'regularLoading') {
                        <interacta-load-more
                            class="flex items-center justify-center py-8"
                            [isLoading]="_items.isFetching"
                            [label]="'UI.SHOW_MORE_UNCOUNT' | translate"
                            [size]="'regular'"
                            (loadMore)="doSearch()"
                        />
                    }

                    @if (!_items.isFetching && !$.itemsCount) {
                        <div class="typo-sm p-12">
                            {{ noResultLabel }}
                        </div>
                    }
                }
            </div>
        </interacta-menu-decorator>
    </interacta-menu>
</ng-container>
