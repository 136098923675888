import {
    IEventActivity,
    NotificationList,
} from '@modules/core/models/notification-user.model';
import { DataLoaderBehaviour } from './data-loader.model';

export class NotificationBellState {
    public currentActivityNotificationList =
        new DataLoaderBehaviour<NotificationList>();

    public getNotificationUnreadByPostId(postId: number): IEventActivity[] {
        return (
            this.currentActivityNotificationList
                .staticData()
                ?.list.filter(
                    (i) => !i.read && i.post && i.post.id === postId,
                ) ?? []
        );
    }
}
