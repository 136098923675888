import { UserCapabilities } from '@modules/core/models/user.model';
import { createAction, props } from '@ngrx/store';

type UserCapabilitiesError = any;

export const fetchUserCapabilitiesSuccess = createAction(
    '[User Capabilities API] fetch User Capabilities Success',
    props<{ capabilities: UserCapabilities }>(),
);

export const fetchUserCapabilitiesError = createAction(
    '[User Capabilities API] fetch User Capabilities Error',
    props<{ error: UserCapabilitiesError }>(),
);
