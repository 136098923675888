import { Pipe, PipeTransform } from '@angular/core';
import { ICommunity } from '@modules/communities/models/communities.model';
import { Observable } from 'rxjs';
import { CommunitiesStateService } from '../../state/services/communities-state.service';

@Pipe({
    name: 'fromCommunitiesState',
})
export class FromCommunitiesStatePipe implements PipeTransform {
    constructor(private communitiesStateService: CommunitiesStateService) {}
    transform(communityId: number): Observable<ICommunity> {
        return this.communitiesStateService.getCommunity(communityId);
    }
}
