import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'interacta-editable-cell-quill-toolbar',
    standalone: true,
    imports: [MatTooltipModule, TranslateModule],
    templateUrl: './editable-cell-quill-toolbar.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableCellQuillToolbarComponent {}
