<ng-container
    *ngIf="{
        customButtons: customButtons | filterAttachmentCustomButtons: attachment
    } as $"
>
    <ng-container *ngIf="$.customButtons?.length > 1">
        <div *ngIf="expanded" class="flex space-x-16" @fadeLeft>
            <interacta-custom-button
                *ngFor="let customButton of $.customButtons"
                class="flex"
                [communityId]="attachment.communityId"
                [customButton]="customButton"
                [ids]="[attachment.id]"
                [mode]="'lightbox'"
            ></interacta-custom-button>
        </div>

        <button
            *ngIf="!expanded"
            interacta-button-light-box
            type="button"
            [attr.aria-label]="'DASHBOARD.ATTACHMENTS.SHOW_ACTIONS' | translate"
            [icon]="'chevron-small-left'"
            [label]="'DASHBOARD.ATTACHMENTS.SHOW_ACTIONS' | translate"
            (click)="expanded = true"
        ></button>
    </ng-container>

    <interacta-custom-button
        *ngIf="$.customButtons?.length === 1"
        class="flex"
        [communityId]="attachment.communityId"
        [customButton]="$.customButtons[0]"
        [ids]="[attachment.id]"
        [mode]="'lightbox'"
    ></interacta-custom-button>

    <button
        *ngIf="canEdit && attachment.isMediaImage"
        interacta-button-light-box
        type="button"
        [attr.aria-label]="'LIGHTBOX.EDIT' | translate"
        [icon]="'pencil'"
        [label]="'LIGHTBOX.EDIT' | translate"
        (click)="editImage.emit()"
    ></button>
    <button
        *ngIf="showDownload"
        interacta-button-light-box
        type="button"
        [attr.aria-label]="'LIGHTBOX.DOWNLOAD' | translate"
        [icon]="'download'"
        [label]="'LIGHTBOX.DOWNLOAD' | translate"
        (click)="download.emit()"
    ></button>
    <button
        *ngIf="showOpenInDrive"
        interacta-button-light-box
        type="button"
        [attr.aria-label]="'OPEN_IN_GOOGLE_DRIVE' | translate"
        [icon]="'external-link'"
        [label]="'OPEN_IN_GOOGLE_DRIVE' | translate"
        (click)="openInDrive.emit()"
    ></button>
    <button
        *ngIf="canShowSearchBar"
        interacta-button-light-box
        type="button"
        [active]="isSearchBarActive"
        [attr.aria-label]="
            (attachment.isMediaVideo
                ? 'LIGHTBOX.SEARCH.VIDEO'
                : attachment.isMediaAudio
                  ? 'LIGHTBOX.SEARCH.AUDIO'
                  : 'LIGHTBOX.SEARCH.DOCUMENT'
            ) | translate
        "
        [icon]="'search-left'"
        [label]="
            (attachment.isMediaVideo
                ? 'LIGHTBOX.SEARCH.VIDEO'
                : attachment.isMediaAudio
                  ? 'LIGHTBOX.SEARCH.AUDIO'
                  : 'LIGHTBOX.SEARCH.DOCUMENT'
            ) | translate
        "
        (click)="openSearchBar.emit()"
    ></button>
    <!-- TODO: IISP-3478 -->
    <!-- <button
        interacta-button-light-box
        [icon]="'star-empty'"
        [label]="'LIGHTBOX.FAVORITE' | translate"
        (click)="favorite.emit()"
     type="button"></button> -->
    <button
        interacta-button-light-box
        type="button"
        [active]="isInfoActive"
        [attr.aria-label]="'LIGHTBOX.INFO' | translate"
        [icon]="'info'"
        [label]="'LIGHTBOX.INFO' | translate"
        (click)="info.emit()"
    ></button>
    <button
        interacta-button-light-box
        type="button"
        [attr.aria-label]="'LIGHTBOX.CLOSE' | translate"
        [icon]="'close'"
        [label]="'LIGHTBOX.CLOSE' | translate"
        (click)="closeDialog.emit()"
    ></button>
</ng-container>
