import { toArea, toBusinessUnit } from '@interacta-shared/data-access-auth';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { IList } from '@interacta-shared/util';
import { parseIfNotNull } from '@modules/admin-v2-shared/utils/admin-v2-utils';
import { AdminV2User } from '@modules/admin-v2-user/models/admin-v2-user.model';
import { PartialSharedProfile, UserDeserilize } from '@modules/core';
import {
    AdminV2UserCredentials,
    AdminV2UserDetail,
    AdminV2UserInfo,
    AdminV2UserRow,
    AdminV2UserSettings,
} from './admin-v2-user.model';

export const toAdminV2UserRow = (record: any): AdminV2UserRow => ({
    id: record.id,
    user: toAdminV2User(record),
    contactEmail: record.contactEmail,
    privateEmail: record.userProfileInfo.privateEmail,
    lastAccess: record.lastAccessTimestamp
        ? new Date(record.lastAccessTimestamp)
        : null,
    creation: record.creationTimestamp
        ? new Date(record.creationTimestamp)
        : null,
    lastModify: record.lastModifyTimestamp
        ? new Date(record.lastModifyTimestamp)
        : null,
    businessUnit: parseIfNotNull(
        record.userProfileInfo.businessUnit,
        toBusinessUnit,
    ),
    area: parseIfNotNull(record.userProfileInfo.area, toArea),
    externalId: record.externalId,
    timezone: record.userProfileInfo.timezone?.description ?? null,
    language: record.userProfileInfo.language?.description ?? null,
    place: record.userProfileInfo.place,
    manager: parseIfNotNull(record.userProfileInfo.manager, toAdminV2User),
    accountTypes: record.loginProviders,
    twoStepAuthenticationEnabled: record.twoStepAuthenticationEnabled,
    deleted: record.deleted,
    blocked: record.blocked,
    privateEmailVerified: record.privateEmailVerified,
    logicallyDisabled: record.deleted || record.blocked,
    selected: false,
    loading: false,
});

export const toAdminV2UserDetail = (
    record: any,
    configurationService: ConfigurationService,
): AdminV2UserDetail => ({
    id: record.id,
    user: toAdminV2User(record),
    contactEmail: record.contactEmail ?? null,
    externalId: record.externalId ?? null,
    timezone: record.userPreferences?.defaultTimezoneId
        ? configurationService.getTimezoneById(
              record.userPreferences?.defaultTimezoneId,
          )
        : null,
    language: record.userPreferences?.defaultLanguageId
        ? configurationService.getLanguage(
              record.userPreferences?.defaultLanguageId,
          )
        : null,
    emailNotificationsEnabled:
        record.userPreferences?.emailNotificationsEnabled ?? true,
    caption: record.caption ?? null,
    privateEmail: record.privateEmail ?? null,
    privateEmailVerified: record.privateEmailVerified ?? null,
    info: toAdminV2UserInfo(record),
    settings: toAdminV2UserSettings(record),
    occToken: record.occToken,
    serviceAccount: record.serviceAccount,
    creationTimestamp: record.creationTimestamp,
    lastAccessTimestamp: record.lastAccessTimestamp,
    lastModifyTimestamp: record.lastModifyTimestamp,
    groups: [],
    workspaceRoleAssociationsList: [],
    credentials: null,
});

export const toAdminV2UserCredentials = ({
    userCredentialsConfiguration,
}: any): AdminV2UserCredentials => ({
    google: userCredentialsConfiguration?.google
        ? { ...userCredentialsConfiguration.google }
        : null,
    microsoft: userCredentialsConfiguration?.microsoft
        ? { ...userCredentialsConfiguration.microsoft }
        : null,
    custom: userCredentialsConfiguration?.custom
        ? {
              ...userCredentialsConfiguration.custom,
              enabled: userCredentialsConfiguration.custom.active,
          }
        : null,
    spid: userCredentialsConfiguration?.spid
        ? {
              ...userCredentialsConfiguration.spid,
          }
        : null,
});

export const toAdminV2UserInfo = (record: any): AdminV2UserInfo => ({
    area: parseIfNotNull(record.userInfo?.area, toArea),
    businessUnit: parseIfNotNull(record.userInfo?.businessUnit, toBusinessUnit),
    manager: parseIfNotNull(record.userInfo?.manager, toIUserReverseFullName),
    phone: record.userInfo?.phone ?? null,
    internalPhone: record.userInfo?.internalPhone ?? null,
    mobilePhone: record.userInfo?.mobilePhone ?? null,
    biography: record.userInfo?.biography ?? null,
    role: record.userInfo?.role ?? null,
    place: record.userInfo?.place ?? null,
    employees:
        record.userInfo?.employees.map((employee: any) =>
            toIUserReverseFullName(employee),
        ) ?? [],
});

export const toAdminV2UserSettings = (record: any): AdminV2UserSettings => ({
    editPrivateEmailEnabled:
        record.userSettings?.editPrivateEmailEnabled ?? true,
    peopleSectionEnabled: record.userSettings?.peopleSectionEnabled ?? true,
    reducedProfile: record.userSettings?.reducedProfile ?? false,
    viewUserProfiles: record.userSettings?.viewUserProfiles ?? true,
    visibleInPeopleSection: record.userSettings?.visibleInPeopleSection ?? true,
});

export const toAdminV2User = (record: any): AdminV2User => {
    const firstName = record.firstName ?? record.firstname;
    const lastName = record.lastName ?? record.lastname;
    return {
        id: record.id,
        fullName: `${firstName} ${lastName}`,
        inverseFullName: `${lastName} ${firstName}`,
        firstName: firstName,
        lastName: lastName,
        accountPhotoUrl: record.accountPhotoUrl,
        highResAccountPhotoUrl: record.userProfileInfo
            ? record.userProfileInfo.highResAccountPhotoUrl
            : null,
        blocked: record.blocked ?? false,
        deleted: record.deleted ?? false,
    };
};

export const toIUserReverseFullName = (user: any): PartialSharedProfile => ({
    ...UserDeserilize.userDetails(user),
    fullName: `${user.lastName} ${user.firstName}`,
    icon: user.icon,
    area: parseIfNotNull(user.userProfileInfo?.area, toArea),
    businessUnit: parseIfNotNull(
        user.userProfileInfo?.businessUnit,
        toBusinessUnit,
    ),
    highResAccountPhotoUrl:
        user.userProfileInfo?.highResAccountPhotoUrl ?? null,
    role: user.userProfileInfo?.role ?? '',
    privateEmail: user.userProfileInfo?.privateEmail ?? null,
    phone: user.userProfileInfo?.phone,
    mobilePhone: user.userProfileInfo?.mobilePhone,
    place: user.userProfileInfo?.place ?? null,
    internalPhone: user.userProfileInfo?.internalPhone,
    biography: user.userProfileInfo?.biography,
    manager: user.userProfileInfo?.manager
        ? [toIUserReverseFullName(user.userProfileInfo.manager)]
        : [],
    employees: user.userProfileInfo?.employees
        ? user.userProfileInfo.employees.map((employee: any) =>
              toIUserReverseFullName(employee),
          )
        : [],
});

export const toPartialSharedProfileList = (
    record: any,
): IList<PartialSharedProfile> => ({
    list: record.items.map(toIUserReverseFullName),
    nextPageToken: record.nextPageToken,
    totalCount: record.totalItemsCount,
});
