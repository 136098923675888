import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    inject,
} from '@angular/core';
import { isDefined } from '@interacta-shared/util';
import { splitUserAndGroupsFromMembersArray } from '@modules/core/models/member/member.utils';
import { TagMember } from '@modules/core/models/tag/tag.model';
import {
    isMemberOfTagMember,
    isTagOfTagMember,
} from '@modules/core/models/tag/tag.utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'interacta-people-groups-splitter[members]',
    templateUrl: './people-groups-splitter.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleGroupsSplitterComponent implements OnChanges {
    @Input() members: TagMember[] = [];
    @Input() textColor: 'dark' | 'gray' = 'gray';
    @Input() @HostBinding('disabled') disabled = false;

    @Output() buttonClicked = new EventEmitter<void>();

    peopleCount$ = new BehaviorSubject<number>(0);
    groupCount$ = new BehaviorSubject<number>(0);
    groupTagCount$ = new BehaviorSubject<number>(0);

    translateService = inject(TranslateService);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.members) {
            const memberRecipients = this.members.filter(isMemberOfTagMember);
            const groupTagRecipients = this.members.filter(isTagOfTagMember);
            const { users, groups } =
                splitUserAndGroupsFromMembersArray(memberRecipients);

            this.peopleCount$.next(users?.length ?? 0);
            this.groupCount$.next(groups?.length ?? 0);
            this.groupTagCount$.next(groupTagRecipients.length ?? 0);
        }
    }

    buttonLabel({
        peopleCount,
        groupCount,
        groupTagCount,
        translateService,
    }: {
        peopleCount: number;
        groupCount: number;
        groupTagCount: number;
        translateService: TranslateService;
    }): string {
        const groupTagLabel =
            groupTagCount > 0
                ? translateService.instant(
                      groupTagCount === 1
                          ? 'TAG_MEMBER_PICKER.TAGS_SING'
                          : 'TAG_MEMBER_PICKER.TAGS_PLUR',
                      {
                          count: groupTagCount,
                      },
                  )
                : null;

        const groupLabel =
            groupCount > 0
                ? translateService.instant(
                      groupCount === 1
                          ? 'ACKNOWLEDGE.GROUP_SING'
                          : 'ACKNOWLEDGE.GROUP_PLUR',
                      {
                          count: groupCount,
                      },
                  )
                : null;

        const peopleLabel =
            peopleCount > 0
                ? translateService.instant(
                      peopleCount === 1
                          ? 'ACKNOWLEDGE.PEOPLE_SING'
                          : 'ACKNOWLEDGE.PEOPLE_PLUR',
                      {
                          count: peopleCount,
                      },
                  )
                : null;

        const congiuntion = translateService.instant('ACKNOWLEDGE.AND');

        if (groupTagLabel && groupLabel && peopleLabel) {
            return `${groupTagLabel}, ${groupLabel}${congiuntion}${peopleLabel}`;
        } else {
            return [groupTagLabel, groupLabel, peopleLabel]
                .filter(isDefined)
                .join(`${congiuntion}`);
        }
    }
}
