import { PaginatedList, toPaginatedList } from '@interacta-shared/util';
import {
    mergeRoleWithSameId,
    toAdminV2Role,
} from '@modules/admin-v2-roles/models/admin-v2-roles.deserialize';
import { toAdminV2MemberItem } from '@modules/admin-v2-shared/models/admin-v2-member.deserialize';
import {
    i18nDeltaToV2,
    i18nStringToV2,
    toI18nDeltaV2,
} from '@modules/admin-v2-shared/utils/admin-v2-i18n';
import { toIUserReverseFullName } from '@modules/admin-v2-user/models/admin-v2-user.deserialize';
import { toAdminV2Workspace } from '@modules/admin-v2-workspace/models/admin-v2-workspace.deserialize';
import { toWorkflowDefinition } from '@modules/communities/models/workflow/workflow.deserialize';
import {
    MemberGroup,
    MemberUser,
} from '@modules/core/models/member/member.model';
import {
    isMemberGroup,
    isMemberUser,
    wrapMember,
} from '@modules/core/models/member/member.utils';
import { toCustomFieldConfigurationBase } from '@modules/custom-fields-configuration/models/custom-fields-configuration.deserialize';
import { FieldValidationForEditDTO } from '@modules/custom-fields-configuration/models/custom-fields-configuration.model';
import { toCustomFieldAdminValidation } from './../../communities/models/custom-metadata/custom-metadata.deserialize';
import { AdminV2AcknowledgeField } from './admin-v2-community-acknowledge-field.model';
import { AdminV2CommunityHashtag } from './admin-v2-community-hashtag.model';
import { AdminV2CommunityRolesSuggestions } from './admin-v2-community-roles.model';
import {
    AdminV2CommunityPostIdType,
    AdminV2CommunityTitleType,
} from './admin-v2-community-standard-fields.model';
import {
    AdminV2Community,
    AdminV2CommunityCapabilities,
    AdminV2CommunityCustomField,
    AdminV2CommunityDetailPartial,
    AdminV2CommunityPostDefinition,
    AdminV2CommunityPreview,
    AdminV2CommunityRoles,
    AdminV2CommunityRow,
} from './admin-v2-community.model';

export const toAdminV2Community = (record: any): AdminV2Community => ({
    id: record.id,
    name: i18nStringToV2(record.name),
    draft: record.draft ?? false,
    active: record.active ?? true,
    deleted: record.deleted ?? false,
    occToken: record.occToken,
});

export const toAdminV2CommunityRoles = (
    record: any,
): AdminV2CommunityRoles => ({
    ...toAdminV2Community(record.community),
    operationalRoles: mergeRoleWithSameId(record.operationalRoles),
});

export const toAdminV2CommunityRow = (record: any): AdminV2CommunityRow => ({
    id: record.id,
    baseData: toAdminV2Community(record),
    workspace: record.workspace ? [toAdminV2Workspace(record.workspace)] : null,
    lastModifyTimestamp: record.lastModifyTimestamp,
    lastModifyUser: toAdminV2MemberItem(wrapMember(record.lastModifyUser)),
    membersCount: record.membersCount,
    creationTimestamp: record.creationTimestamp,
    creatorUser: toAdminV2MemberItem(wrapMember(record.creatorUser)),
    adminMembers: {
        type: 'community',
        id: record.id,
        members: (record.adminMembers ?? []).map(toAdminV2MemberItem),
        canEdit: record.adminCapabilities.editCommunity ?? false,
    },
    postVisibility: record.defaultPostVisibility,
    workflow: record.workflow ? record.workflow.title : '',
    hashtags: {
        communityId: record.id,
        communityName: i18nStringToV2(record.name),
        counter: record.hashtagsCount,
        canAdd: record.adminCapabilities.editCommunity ?? false,
    },
    logicallyDisabled: !record.active,
    capabilities: toAdminV2CommunityCapabilities(record.adminCapabilities),
    deleted: record.deleted ?? false,
    loading: false,
    selected: false,
});

export const toAdminV2CommunityDetailPartial = (
    record: any,
): AdminV2CommunityDetailPartial => ({
    ...toAdminV2Community(record),
    capabilities: toAdminV2CommunityCapabilities(record.capabilities),
    lastModifyTimestamp: record.lastModifyTimestamp,
    creationTimestamp: record.creationTimestamp,
    publicationTimestamp: record.publicationTimestamp,
    workspace: record.workspace ? toAdminV2Workspace(record.workspace) : null,
    baseInfo: {
        name: record.name,
        coverImageContentViewLink: record.coverImageContentViewLink,
        coverImageContentRef: record.coverImageContentRef,
        description: toI18nDeltaV2(record.description),
        compilationGuidelines: toI18nDeltaV2(record.info),
        externalId: record.externalId ?? null,
        visibleInDashboard: false,
        visibleInOrganizationTree: false,
    },
    workflow: record.workflow ? toWorkflowDefinition(record.workflow) : null,
    occToken: record.occToken,
});

const getValueByType = (
    token: any,
    record?: any,
):
    | AdminV2CommunityPostIdType['fieldValue']
    | AdminV2CommunityTitleType['fieldValue']
    | null => {
    switch (token.type) {
        case 'yy':
        case 'yyyy':
            return new Date();
        case 'text':
            return token.value;
        case 'field_id':
            return token.fieldId;
        case 'prog_community':
            return {
                digits: token.digits,
                start: record.communityProgressiveCounterConfig.startValue,
                forceReset: record.communityProgressiveCounterConfig.forceReset,
                resetEveryYear:
                    record.communityProgressiveCounterConfig.resetsEveryYear,
            };
        case 'prog_workspace':
            return {
                digits: token.digits,
                start: record.workspaceProgressiveCounterConfig.startValue,
                forceReset: record.workspaceProgressiveCounterConfig.forceReset,
                resetEveryYear:
                    record.workspaceProgressiveCounterConfig.resetsEveryYear,
            };
    }
    return null;
};

export const toAdminV2CommunityPostDefinition = (
    record: any,
): AdminV2CommunityPostDefinition => ({
    settings: {
        postEnabled: record.contentData.customPostEnabled,
        acknowledgeTaskEnabled: record.contentData.acknowledgeTaskEnabled,
        eventEnabled: record.contentData.eventPostEnabled,
        surveyEnabled: record.contentData.surveyPostEnabled,
        feedbackEnabled: record.contentData.feedbackPostEnabled,
        defaultPostVisibility: record.contentData.defaultPostVisibility,
        attachmentEnabled: record.contentData.attachmentEnabled,
        likeEnabled: record.contentData.likeEnabled,
        offlineEnabled: record.contentData.offlineEnabled,
        mobileAttachmentResizeEnabled:
            record.contentData.mobileAttachmentResizeEnabled,
        hashtagEnabled: record.contentData.hashtagEnabled,
        workflowEnabled: record.contentData.workflowEnabled,
    },
    standardFields: {
        titleEnabled: record.contentData.titleEnabled,
        descriptionEnabled: record.contentData.descriptionEnabled,
        watchersEnabled: record.contentData.watchersEnabled,
        watchersVisibleInPreview: record.contentData.watchersVisibleInPreview,
        titleAutocompleteEnabled: !!record.titleRegex,
        titleAutocompleteFields: record.titleRegex
            ? record.contentData.titleAutoCompletion
                ? record.contentData.titleAutoCompletion.tokens.map(
                      (token: any) => ({
                          fieldType: token.type,
                          fieldValue: getValueByType(token),
                      }),
                  )
                : null
            : [],
        postIdEnabled: !!record.customIdRegex,
        postIdFields: record.customIdRegex
            ? record.contentData.customIdAutoCompletion
                ? record.contentData.customIdAutoCompletion.tokens.map(
                      (token: any) => ({
                          fieldType: token.type,
                          fieldValue: getValueByType(
                              token,
                              record.contentData.customIdAutoCompletion,
                          ),
                      }),
                  )
                : null
            : [],
        sharedWorkspaceProgressive: record.sharedWorkspaceProgressive,
    },
    visibleInDashboard: record.contentData.visibleInDashboard,
    visibleInOrganizationTree: record.visibleInOrganizationTree,
    customFieldsEnabled: record.contentData.customFieldsEnabled,
});

export const toAdminV2AcknowledgeField = (
    record: any,
): AdminV2AcknowledgeField => {
    //in v3 api, validations object containes all possible validations
    const validationsFromServer: FieldValidationForEditDTO = record.validations;
    const descriptionV2 = record.description
        ? toI18nDeltaV2(record.description)
        : null;

    return {
        ...toCustomFieldConfigurationBase(record),
        labelV2: record.label,
        descriptionV2,
        validations: toCustomFieldAdminValidation(
            validationsFromServer.validations,
        ),
        conditionalValidations:
            validationsFromServer.conditionalValidations ?? [],
        order: record.order,
    };
};

export const toAdminV2CommunityPostCustomFields = (
    record: any,
): AdminV2CommunityCustomField => {
    //in v3 api, validations object containes all possible validations
    const validationsFromServer: FieldValidationForEditDTO = record.validations;
    const descriptionV2 = record.description
        ? toI18nDeltaV2(record.description)
        : null;

    return {
        ...toCustomFieldConfigurationBase(record),
        labelV2: record.label,
        descriptionV2,
        searchable: record.searchable,
        sortable: record.sortable,
        validations: toCustomFieldAdminValidation(
            validationsFromServer.validations,
        ),
        conditionalValidations:
            validationsFromServer.conditionalValidations ?? [],
        visibleInPreview: record.visibleInPreview,
        visibleInCreate: record.visibleInCreate,
        visibleInDetail: record.visibleInDetail,
        visibleInEdit: record.visibleInEdit,
        visibleInList: record.visibleInList,
        order: record.order,
        externalId: record.externalId,
    };
};

export const toAdminV2CommunityHashtag = (
    record: any,
): AdminV2CommunityHashtag => ({
    id: record.id,
    name: i18nStringToV2(record.name),
    communityId: record.communityId,
    deleted: record.deleted,
    externalId: record.externalId,
    instancesCount: record.instancesCount,
});

export const toAdminV2CommunityCapabilities = (
    record: any,
): AdminV2CommunityCapabilities => ({
    editCommunity: record.editCommunity,
    manageWorkflow: record.manageWorkflow,
    viewCommunityDetail: record.viewCommunityDetail,
});

export const toAdminV2CommunityGroups = (
    record: any,
): PaginatedList<MemberGroup> =>
    toPaginatedList({
        ...record,
        items: (<any[]>record.items)
            .map((x) =>
                wrapMember({
                    id: x.id,
                    fullName: x.name,
                    membersCount: x.membersCount ?? 0,
                }),
            )
            .filter(isMemberGroup),
    });

export const toAdminV2CommunityUsers = (
    record: any,
): PaginatedList<MemberUser> =>
    toPaginatedList({
        ...record,
        items: (<any[]>record.items)
            .map((x) => wrapMember(toIUserReverseFullName(x)))
            .filter(isMemberUser),
    });

export const toAdminV2CommunityPreview = (
    record: any,
): AdminV2CommunityPreview => ({
    ...toAdminV2Community(record.community),
    description: i18nDeltaToV2(record.community.description),
    coverImageContentViewLink:
        record.community.coverImageTemporaryContentViewLink,
    postsCounter: record.postCount,
    usersCounter: record.community.membersCount ?? 0,
    workspace: toAdminV2Workspace(record.community.workspace),
    workflow: record.workflow,
    adminMembers: (record.communityManagers ?? []).map(toAdminV2MemberItem),
    capabilities: record.community.adminCapabilities,
});

export const toAdminV2CommunityRolesSuggestions = (
    record: any,
): AdminV2CommunityRolesSuggestions => ({
    survey: record.survey?.map(toAdminV2Role) ?? [],
    acknowledgeTask: record.acknowledgeTask?.map(toAdminV2Role) ?? [],
    feedback: record.feedback?.map(toAdminV2Role) ?? [],
    event: record.event?.map(toAdminV2Role) ?? [],
});
