import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LayerService {
    private _isOpen$ = new BehaviorSubject<boolean>(false);
    private _customClass$ = new BehaviorSubject<string>('');

    open(customClass?: string): void {
        this._isOpen$.next(true);
        this._customClass$.next(customClass ?? '');
    }

    close(): void {
        this._isOpen$.next(false);
        this._customClass$.next('');
    }

    isOpen$(): Observable<boolean> {
        return this._isOpen$.asObservable();
    }

    customClass$(): Observable<string> {
        return this._customClass$.asObservable();
    }
}
