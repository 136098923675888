import { FormControl } from '@angular/forms';
import { Area, BusinessUnit } from '@interacta-shared/data-access-auth';
import {
    Language,
    Timezone,
} from '@interacta-shared/data-access-configuration';
import { PaginatedList } from '@interacta-shared/util';
import { AdminV2Group } from '@modules/admin-v2-group/models/admin-v2-group.model';
import { AdminV2Date } from '@modules/admin-v2-shared/models/admin-v2-date.model';
import {
    AdminV2PaginatedList,
    AdminV2PaginatedListRow,
} from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import {
    AdminV2FiltersLabel,
    AdminV2Label,
    AdminV2OccToken,
    AdminV2Sort,
} from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import { AdminV2WorkspaceRoleAssociations } from '@modules/admin-v2-workspace/models/admin-v2-workspace.model';
import { IUser } from '@modules/core';
import { MemberUser } from '@modules/core/models/member/member.model';

export enum AdminV2AccountType {
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
    CUSTOM = 'custom',
    SPID = 'spid',
}

export enum AdminV2UserStatusFilter {
    ACTIVE_USERS = 'activeUsers',
    BLOCKED_USERS = 'blockedUsers',
    DELETED_USERS = 'deletedUsers',
    ALL_USERS = 'allUsers',
}

export enum AdminV2UserLoginProvider {
    GOOGLE = 1,
    MICROSOFT = 2,
    INTERACTA = 3,
    SPID = 4,
}

export type AdminV2UserSort = AdminV2Sort<AdminV2UserRow>;

export interface AdminV2UserFilters {
    // quick filters
    search?: string;
    status: AdminV2UserStatusFilter;
    excludeUsersByMemberGroupId?: AdminV2Group['id']; // filtro usato nel member.service per il member-picker
    checkBusinessUnitMember?: BusinessUnit['id'];
    checkAreaMember?: Area['id'];
    checkGroupMember?: AdminV2Group['id'];
    excludeByAreaId?: number; // filtro usato nel member.service per il member-picker
    excludeByBusinessUnitId?: number; // filtro usato nel member.service per il member-picker
    workspaceIds?: number[];
    // other filters
    created?: AdminV2Date; //creationTimestampFrom, creationTimestampTo
    lastAccess?: AdminV2Date; //lastAccessFrom, lastAccessTo
    personalEmail?: string; // personalEmailFullTextFilter
    externalId?: string; // externalIdFullTextFilter
    areas?: Area[]; // areaIds
    businessUnits?: BusinessUnit[]; // businessUnitIds
    role?: string; // role
    managers?: IUser[]; // managerIds
    place?: string; // place
    languages?: Language[]; // lang
    timezones?: Timezone[]; // timeZoneIds
    loginProviders?: AdminV2UserLoginProvider[];
    // privacy
    peopleSection?: boolean; // Presente nella sezione Persone
    visibleInPeople?: boolean; // Sezione Persone
    reducedProfile?: boolean; // Tipologia profilo
    viewUserProfiles?: boolean; // Visualizzazione altri profili
    twoStepAuthenticationEnabled?: boolean; // Verfica in due passaggi
}

export type AdminV2UserFiltersLabel = AdminV2FiltersLabel<
    AdminV2UserFilters,
    AdminV2UserStatusFilter
>;

export type AdminV2UserStatusFilterLabel =
    AdminV2Label<AdminV2UserStatusFilter>;

export type AdminV2UserLoginFilterLabel =
    AdminV2Label<AdminV2UserLoginProvider>;

// Table
export type AdminV2User = Pick<
    IUser,
    | 'id'
    | 'fullName'
    | 'firstName'
    | 'lastName'
    | 'accountPhotoUrl'
    | 'blocked'
    | 'deleted'
> & {
    highResAccountPhotoUrl: string;
    inverseFullName: string;
};

export interface AdminV2UserRow extends AdminV2PaginatedListRow {
    user: AdminV2User;
    contactEmail: string | null;
    privateEmail: string | null;
    lastAccess: Date | null;
    creation: Date | null;
    lastModify: Date | null;
    businessUnit: BusinessUnit | null;
    area: Area | null;
    externalId: string | null;
    timezone: string | null;
    language: string | null;
    place: string | null;
    manager: AdminV2User | null;
    accountTypes: AdminV2AccountType[];
    blocked: boolean;
    twoStepAuthenticationEnabled: boolean;
    privateEmailVerified: boolean;
}

export type AdminV2UserList = AdminV2PaginatedList<AdminV2UserRow>;

export type AdminV2UserListPage = PaginatedList<AdminV2UserRow>;

export interface AdminV2UserDetail {
    id: AdminV2User['id'];
    user: AdminV2User;
    caption: string | null;
    contactEmail: string | null;
    privateEmail: string | null;
    privateEmailVerified: boolean;
    externalId: string | null;
    timezone: Timezone | null;
    language: Language | null;
    emailNotificationsEnabled: boolean;
    info: AdminV2UserInfo;
    settings: AdminV2UserSettings;
    occToken: AdminV2OccToken;
    serviceAccount: boolean;
    creationTimestamp: number;
    lastAccessTimestamp: number;
    lastModifyTimestamp: number;
    groups: AdminV2Group[];
    workspaceRoleAssociationsList: AdminV2WorkspaceRoleAssociations[];
    credentials: AdminV2UserCredentials | null;
}

export type AdminV2UserContactsDetail = Pick<
    AdminV2UserDetail,
    'contactEmail' | 'privateEmail' | 'privateEmailVerified'
>;

export type AdminV2NewUser = Pick<AdminV2User, 'firstName' | 'lastName'> &
    Pick<
        AdminV2UserDetail,
        'contactEmail' | 'privateEmail' | 'caption' | 'externalId'
    > & {
        biography: string | null;
        areaId: number | null;
        businessUnitId: number | null;
        role: string | null;
        manager: MemberUser | null;
    };

export interface AdminV2UserInfo {
    area: Area | null;
    manager: IUser | null;
    businessUnit: BusinessUnit | null;
    phone: string | null;
    internalPhone: string | null;
    mobilePhone: string | null;
    role: string | null;
    biography: string | null;
    place: string | null;
    employees: IUser[];
}

export interface AdminV2UserSettings {
    peopleSectionEnabled: boolean;
    visibleInPeopleSection: boolean;
    reducedProfile: boolean;
    viewUserProfiles: boolean;
    editPrivateEmailEnabled: boolean;
}

export interface AdminV2UserCredentials {
    [AdminV2AccountType.GOOGLE]: {
        googleAccountId?: string;
        enabled: boolean;
        profilePhotoUrl?: string;
    } | null;
    [AdminV2AccountType.MICROSOFT]: {
        microsoftAccountId?: string;
        enabled: boolean;
        profilePhotoUrl?: string;
    } | null;
    [AdminV2AccountType.CUSTOM]: {
        username?: string;
        canUserManageCustomCredentials?: boolean;
        enabled: boolean;
        profilePhotoUrl?: string | null;
        profilePhoto?: {
            newContentRef: string | null;
        };
        canManageProfilePhoto?: boolean;
        twoStepAuthenticationEnabled?: boolean;
    } | null;
    [AdminV2AccountType.SPID]: {
        spidAccountId?: string;
        enabled: boolean;
    } | null;
}

// Operations
export interface AdminV2ResetCustomCredentials {
    generatePassword: boolean;
    password?: string;
    forceCredentialsExpiration: boolean;
    emailNotifyRecipients: string[];
}

export type AdminV2UserCredentialsConfig = {
    [key in AdminV2AccountType]: {
        enabled: boolean;
        order: number;
    };
};

export interface AdminV2Email {
    email: string;
}

export type AdminV2UserCredentialsForm = {
    [key in AdminV2AccountType]: FormControl<string>;
};

export type AdminV2UserManageMode = 'ban' | 'unban' | 'restore' | 'delete';

export type AdminV2UserMassActionType =
    | 'twoStepAuthenticationEnabled'
    | 'addToArea'
    | 'addToBusinessUnit'
    | 'assignToManager'
    | 'manageWorkspaces'
    | 'addToGroup'
    | 'changePassword'
    | 'requestChangePassword'
    | 'ban'
    | 'unban'
    | 'restore'
    | 'delete';
