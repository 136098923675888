import { IListAttachments } from '@modules/post/models/attachment/attachment.model';
import { ITaskFilters } from '@modules/tasks/models/filter-task.model';
import {
    ISubTask,
    StandardTask,
    TaskList,
    TaskState,
} from '@modules/tasks/models/task.model';
import { createAction, props } from '@ngrx/store';

enum TaskActionType {
    FetchTasksSuccess = '[Task API] Fetch Tasks Success',
    FetchTasksError = '[Task API] Fetch Tasks Error',

    FetchTaskForEditSuccess = '[Task API] Fetch Tasks For Edit Success',
    FetchTaskForEditError = '[Task API] Fetch Tasks For Edit Error',

    FetchTasksPageSuccess = '[Task API] Fetch Tasks Page Success',
    FetchTasksPageError = '[Task API] Fetch Tasks Page Error',

    CreateTaskSuccess = '[Task API] Create Task Success',
    CreateTaskError = '[Task API] Create Task Error',

    EditTaskSuccess = '[Task API] Edit Task Success',
    EditTaskError = '[Task API] Edit Task Error',

    DeleteTaskSuccess = '[Task API] Delete Task Success',
    DeleteTaskError = '[Task API] Delete Task Error',

    CopyTaskSuccess = '[Task API] Copy Task Success',
    CopyTaskError = '[Task API] Copy Task Error',

    ToggleStateSuccess = '[Task API] Toggle State Success',
    ToggleStateError = '[Task API] Toggle State Error',

    ToggleSubtaskStateSuccess = '[Task API] Toggle Subtask Success',
    ToggleSubtaskStateError = '[Task API] Toggle Subtask Error',

    EditRemindersSuccess = '[Task API] Edit Reminders Success',
    EditRemindersError = '[Task API] Edit Reminders Error',

    EditWatchersSuccess = '[Task API] Edit Watchers Success',
    EditWatchersError = '[Task API] Edit Watchers Error',

    FetchTaskAttachmentsSuccess = '[Task API] Fetch Tasks Attachments Success',
    FetchTaskAttachmentsError = '[Task API] Fetch Tasks Attachments Error',
}

type TaskError = any;

export const fetchTaskAttachmentsSuccess = createAction(
    TaskActionType.FetchTaskAttachmentsSuccess,
    props<{ taskId: number; attachments: IListAttachments }>(),
);

export const fetchTaskAttachmentsError = createAction(
    TaskActionType.FetchTaskAttachmentsError,
    props<{ taskId: number; error: TaskError }>(),
);

export const fetchTasksSuccess = createAction(
    TaskActionType.FetchTasksSuccess,
    props<{ postId: number; tasks: TaskList; filters: ITaskFilters }>(),
);

export const fetchTasksError = createAction(
    TaskActionType.FetchTasksError,
    props<{ postId: number; error: TaskError }>(),
);

export const fetchTaskForEditSuccess = createAction(
    TaskActionType.FetchTaskForEditSuccess,
    props<{ taskId: number; task: StandardTask }>(),
);

export const fetchTaskForEditError = createAction(
    TaskActionType.FetchTaskForEditError,
    props<{ taskId: number; error: TaskError }>(),
);

export const fetchTasksPageSuccess = createAction(
    TaskActionType.FetchTasksPageSuccess,
    props<{ postId: number; tasks: TaskList }>(),
);

export const fetchTasksPageError = createAction(
    TaskActionType.FetchTasksPageError,
    props<{ postId: number; error: TaskError }>(),
);

export const createTaskSuccess = createAction(
    TaskActionType.CreateTaskSuccess,
    props<{ postId: number; task: StandardTask; message: string }>(),
);

export const createTaskError = createAction(
    TaskActionType.CreateTaskError,
    props<{ postId: number; error: TaskError }>(),
);

export const editTaskSuccess = createAction(
    TaskActionType.EditTaskSuccess,
    props<{ postId: number; task: StandardTask; message: string }>(),
);

export const editTaskError = createAction(
    TaskActionType.EditTaskError,
    props<{ postId: number; error: TaskError }>(),
);

export const copyTaskSuccess = createAction(
    TaskActionType.CopyTaskSuccess,
    props<{ postId: number; task: StandardTask; message: string }>(),
);

export const copyTaskError = createAction(
    TaskActionType.CopyTaskError,
    props<{ postId: number; error: TaskError }>(),
);

export const deleteTaskSuccess = createAction(
    TaskActionType.DeleteTaskSuccess,
    props<{ postId: number; taskId: number; message: string }>(),
);

export const deleteTaskError = createAction(
    TaskActionType.DeleteTaskError,
    props<{ postId: number; error: TaskError }>(),
);

export const toggleStateSuccess = createAction(
    TaskActionType.ToggleStateSuccess,
    props<{ postId: number; taskId: number }>(),
);

export const toggleStateError = createAction(
    TaskActionType.ToggleStateError,
    props<{ postId: number; taskId: number; error: TaskError }>(),
);

export const toggleSubtaskStateSuccess = createAction(
    TaskActionType.ToggleSubtaskStateSuccess,
    props<{
        postId: number;
        taskId: number;
        subtask: ISubTask;
    }>(),
);

export const toggleSubtaskStateError = createAction(
    TaskActionType.ToggleSubtaskStateError,
    props<{
        postId: number;
        taskId: number;
        subtaskId: number;
        state: TaskState;
        error: any;
    }>(),
);

export const editRemindersSuccess = createAction(
    TaskActionType.EditRemindersSuccess,
    props<{ task: StandardTask }>(),
);

export const editRemindersError = createAction(
    TaskActionType.EditRemindersError,
    props<{
        task: StandardTask;
        error: TaskError;
    }>(),
);

export const editWatchersSuccess = createAction(
    TaskActionType.EditWatchersSuccess,
    props<{ task: StandardTask }>(),
);

export const editWatchersError = createAction(
    TaskActionType.EditWatchersError,
    props<{ task: StandardTask; error: TaskError }>(),
);
