import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICommunity } from '@modules/communities/models/communities.model';

import {
    Observable,
    ReplaySubject,
    combineLatest,
    concat,
    map,
    of,
} from 'rxjs';

@Component({
    selector: 'interacta-community-selection',
    templateUrl: './community-selection.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunitySelectionComponent implements OnInit, OnChanges {
    @Input({ required: true }) communities!: ICommunity[];
    @Input() searchControl = new FormControl<string>('');
    @Output() selectCommunity = new EventEmitter<ICommunity>();

    selectedCommunity?: ICommunity;

    communities$ = new ReplaySubject<ICommunity[]>(1);
    filteredCommunities$!: Observable<ICommunity[]>;

    ngOnInit(): void {
        this.filteredCommunities$ = combineLatest([
            this.communities$,
            concat(of(''), this.searchControl.valueChanges).pipe(
                map((search: string | null) => search?.toLowerCase() ?? ''),
            ),
        ]).pipe(
            map(([communities, search]) => {
                const filteredCommunities = search
                    ? communities.filter((community) =>
                          community.name
                              .toString()
                              .toLowerCase()
                              .includes(search),
                      )
                    : communities;
                if (
                    this.selectedCommunity &&
                    !filteredCommunities.includes(this.selectedCommunity)
                ) {
                    this.selectedCommunity = undefined;
                    this.selectCommunity.emit(undefined);
                }

                return filteredCommunities;
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.communities) {
            this.communities$.next(this.communities);
        }
    }
}
