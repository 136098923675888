// Negative value used as ID to simulate current user value
export const CURRENT_USER_SEARCH_VALUE = -Math.E;
// String value to serialize/deserialize special ID Math number as a shared interface for web/mobile
export const CURRENT_USER_SEARCH_VALUE_LABEL = 'current';

export const isCurrentUser = (val: number | 'current'): boolean => {
    return (
        val === CURRENT_USER_SEARCH_VALUE_LABEL ||
        val === CURRENT_USER_SEARCH_VALUE
    );
};
