import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { assertUnreachable } from '@interacta-shared/util';
import {
    FeedbackTaskState,
    GenericTask,
    TaskState,
} from '@modules/tasks/models/task.model';
import { isExpired } from '@modules/tasks/models/task.utils';
import { TranslateService } from '@ngx-translate/core';
import { enUS } from 'date-fns/locale';
import {
    DateFormattedLabels,
    taskClosedLabels,
    taskExpirationLabels,
    taskExpiredLabels,
    taskExpiresLabels,
} from '../models/date-formatted-labels';
import { getTranslatedDateFormattedLabel } from '../utils/expiration-date.utils';

/**
 * See https://injenia.atlassian.net/l/cp/r6L3KnZu
 *
 */
@Pipe({
    name: 'taskExpirationDate',
})
export class TaskExpirationDatePipe implements PipeTransform {
    locale!: Locale;

    constructor(
        private configurationService: ConfigurationService,
        private translateService: TranslateService,
    ) {
        this.configurationService
            .getLocaleDateFns()
            .subscribe((locale) => (this.locale = locale));
    }

    transform(date: Date, task?: GenericTask): string {
        if (!this.locale) {
            console.warn(`The locale shouldn't be null here.`);
        }

        // Defaulting to enUS which is always eagerly loaded by date-fns and
        // doesn't increase bundle size.
        const locale = this.locale ?? enUS;

        return getTranslatedDateFormattedLabel(
            this.translateService,
            this.getLabelsOfCategory(task),
            locale,
            date,
        );
    }

    private getLabelsOfCategory(task?: GenericTask): DateFormattedLabels {
        if (task) {
            if (isExpired(task)) {
                return taskExpiredLabels;
            }
            const state = task.state;
            switch (state) {
                case TaskState.OPEN:
                case FeedbackTaskState.OPEN:
                case FeedbackTaskState.IN_PROGRESS:
                    return taskExpiresLabels;
                case TaskState.CLOSED:
                case FeedbackTaskState.CLOSED_OK:
                case FeedbackTaskState.CLOSED_KO:
                    return taskClosedLabels;
                case FeedbackTaskState.IN_REVIEW:
                case FeedbackTaskState.REWORK:
                    return taskExpirationLabels;
                default:
                    assertUnreachable(state);
            }
        } else return taskExpirationLabels;
    }
}
