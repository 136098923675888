import {
    AfterViewInit,
    ContentChild,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
} from '@angular/core';
import { ResizeElementService } from '@interacta-shared/ui';
import { Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[injShowMoreItem]',
})
export class ShowMoreItemDirective {
    constructor(private ref: ElementRef) {}

    get nativeElement(): HTMLElement | undefined {
        return this.ref.nativeElement instanceof HTMLElement
            ? this.ref.nativeElement
            : undefined;
    }
}

@Directive({
    selector: '[injShowMoreButton]',
})
export class ShowMoreButtonDirective {
    constructor(private ref: ElementRef) {}

    get nativeElement(): HTMLElement | undefined {
        return this.ref.nativeElement instanceof HTMLElement
            ? this.ref.nativeElement
            : undefined;
    }
}

@Directive({
    selector: '[injShowMoreChildren]',
})
export class ShowMoreChildrenDirective
    implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
    @Input() active = true;
    @Input() showMoreBtnWidth?: number;
    @Input() ignoreShowMoreBtnWidth = false;

    @Output() showMore = new EventEmitter<number>();

    @ContentChild(ShowMoreButtonDirective)
    showMoreBtn?: ShowMoreButtonDirective;

    @ContentChildren(ShowMoreItemDirective)
    showMoreItems?: QueryList<ShowMoreItemDirective>;

    private destroy$ = new Subject<void>();

    constructor(
        private ref: ElementRef,
        private resizeElementService: ResizeElementService,
    ) {}

    ngOnInit(): void {
        this.resizeElementService
            .observeElement(this.ref.nativeElement)
            .pipe(debounceTime(100), takeUntil(this.destroy$))
            .subscribe(() => this.hideOverflowingChildren());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.active && !this.active) {
            this.showChildren();
            this.showButton();
        }
        if (changes.showMoreBtnWidth && !changes.showMoreBtnWidth.firstChange) {
            this.hideOverflowingChildren();
        }
    }

    ngAfterViewInit(): void {
        this.showMoreItems?.changes
            .pipe(startWith({}), takeUntil(this.destroy$))
            .subscribe(() => this.hideOverflowingChildren());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    private getChildren(): HTMLElement[] {
        if (!this.showMoreItems) return [];

        return this.showMoreItems
            .map((c) => c.nativeElement)
            .filter((c) => c instanceof HTMLElement) as HTMLElement[];
    }

    private showChildren() {
        const children = this.getChildren();

        for (const child of children) {
            child.style.position = '';
            child.style.visibility = '';
            child.style.zIndex = '';
        }
    }

    private showButton() {
        const showMoreBtn = this.showMoreBtn?.nativeElement;

        if (showMoreBtn) {
            showMoreBtn.style.visibility = 'hidden';
            showMoreBtn.style.position = 'absolute';
        }
    }

    private hideOverflowingChildren(): void {
        if (!this.active) return;
        if (!(this.ref.nativeElement instanceof HTMLElement)) return;

        const children = this.getChildren();
        const showMoreBtn = this.showMoreBtn?.nativeElement;

        this.showChildren();
        this.showButton();

        const containerWidth =
            this.ref.nativeElement.getBoundingClientRect().width;

        let contentWidth = 0;

        for (const child of children) {
            child.style.opacity = '0';

            const childStyle = getComputedStyle(child);
            const marginLeft = parseFloat(childStyle.marginLeft);
            contentWidth += child.getBoundingClientRect().width + marginLeft;
        }

        if (Math.floor(contentWidth) <= Math.round(containerWidth)) {
            for (const child of children) {
                child.style.opacity = '';
            }

            this.showButton();

            this.showMore.emit(0);
            return;
        }

        let showMoreCount = 0;
        if (this.ignoreShowMoreBtnWidth) {
            contentWidth = 0;
        } else {
            const showMoreBtnMarginLeft = showMoreBtn
                ? parseFloat(getComputedStyle(showMoreBtn).marginLeft)
                : 0;

            const showMoreBtnWidth =
                showMoreBtn?.clientWidth ?? 0 + showMoreBtnMarginLeft;

            contentWidth = showMoreBtnWidth;
        }

        for (const child of children) {
            const childStyle = getComputedStyle(child);
            const marginLeft = parseFloat(childStyle.marginLeft);
            contentWidth += child.getBoundingClientRect().width + marginLeft;
            if (contentWidth > containerWidth - (this.showMoreBtnWidth ?? 0)) {
                showMoreCount++;
                child.style.visibility = 'hidden';
                child.style.position = 'absolute';
                child.style.zIndex = '-1';
            }
        }

        for (const child of children) {
            child.style.opacity = '';
        }

        if (showMoreBtn) {
            showMoreBtn.style.opacity = '';
            showMoreBtn.style.position = '';
            showMoreBtn.style.visibility = '';
        }

        this.showMore.emit(showMoreCount);
    }
}
