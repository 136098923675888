import { fromDateToTimestampLocal } from '@interacta-shared/data-access-configuration';
import {
    CURRENT_USER_SEARCH_VALUE_LABEL,
    isCurrentUser,
    mapArrayById,
} from '@interacta-shared/util';
import { fromCustomFieldsSearchToQuickFilterData } from '@modules/communities/models/custom-metadata/custom-metadata.serialize';
import {
    ActualQuickFilterVersion,
    CustomQuickFilterData,
    QuickFilter,
    QuickFilterDateOptions,
    QuickFilterEventDateOptions,
} from '@modules/post/models/quick-filters/quick-filters.model';
import { PostType } from '../base-post.model';
import {
    EventFilterDateOptions,
    FilterDateOptions,
    IPostFilters,
} from '../filter-post/filter-post.model';

export const fromQuickFilter = (quickFilter: QuickFilter): any => {
    const data = {
        client: 'web',
        communityId: quickFilter.filters.communityId ?? null,
        label: quickFilter.label,
        description: quickFilter.description,
        shared: quickFilter.shared,
        parameters: JSON.stringify(
            convertToCustomQuickFilterData(quickFilter.filters),
        ),
    };
    return data;
};

export const fromPartialQuickFilter = (quickFilter: QuickFilter): any => {
    return {
        label: quickFilter.label,
        description: quickFilter.description,
        shared: quickFilter.shared,
        parameters: JSON.stringify(
            convertToCustomQuickFilterData(quickFilter.filters),
        ),
    };
};

export const fromQuickFiltersOrder = (
    communityId: number | null,
    filters: QuickFilter[],
): any => ({
    communityId,
    filtersVisualizationOrder: [...filters]
        .sort()
        .map(({ showInBar, id }, index) => ({ index, showInBar, filterId: id }))
        .sort((a, b) =>
            a.showInBar === b.showInBar
                ? a.index - b.index
                : a.showInBar
                  ? -1
                  : 1,
        )
        .map(({ index, showInBar, filterId }) => ({
            filterId,
            order: index + 1,
            showInBar,
        })),
});

export const convertToCustomQuickFilterData = (
    filter: Partial<IPostFilters>,
): Partial<CustomQuickFilterData> => {
    const record: Partial<CustomQuickFilterData> = {};

    record.createdByUserIds =
        filter.createdByUser && filter.createdByUser.length > 0
            ? mapArrayById(filter.createdByUser)!.map((id) => {
                  return isCurrentUser(id)
                      ? CURRENT_USER_SEARCH_VALUE_LABEL
                      : id;
              })
            : undefined;
    record.createdByGroupIds =
        filter.createdByGroup && filter.createdByGroup.length > 0
            ? filter.createdByGroup.map((group) => group.id)
            : undefined;
    const hasTextToSearch = (filter.containsText?.length || 0) > 0;
    record.title = filter.containsText?.length
        ? filter.containsText
        : undefined;
    record.description = hasTextToSearch ? filter.containsText : undefined;
    record.creationDateType =
        filter.creationDateType != null
            ? fromFilterDateToQuickFilterDate(filter.creationDateType)
            : undefined;
    record.creationTimestampFrom =
        filter.creationDateType === FilterDateOptions.CUSTOM
            ? fromDateToTimestampLocal(filter.creationDateFrom)
            : undefined;
    record.creationTimestampTo =
        filter.creationDateType === FilterDateOptions.CUSTOM
            ? fromDateToTimestampLocal(filter.creationDateTo)
            : undefined;
    record.postTypes =
        filter.postTypes && filter.postTypes.length
            ? filter.postTypes
            : undefined;
    record.postSurveyTypes =
        filter.postSurveyTypes && filter.postSurveyTypes.length
            ? filter.postSurveyTypes
            : undefined;

    record.event =
        filter.postTypes?.includes(PostType.EVENT) && filter.event
            ? {
                  startDateFromTimestamp:
                      fromDateToTimestampLocal(filter.event.startDateFrom) ??
                      undefined,

                  startDateToTimestamp:
                      fromDateToTimestampLocal(filter.event.startDateTo) ??
                      undefined,

                  endDateFromTimestamp:
                      fromDateToTimestampLocal(filter.event.endDateFrom) ??
                      undefined,
                  endDateToTimestamp:
                      fromDateToTimestampLocal(filter.event.endDateTo) ??
                      undefined,
              }
            : undefined;
    record.eventType =
        filter.eventType != null
            ? fromEventFilterDateToQuickFilterEventDate(filter.eventType)
            : undefined;

    record.hashtagIds =
        filter.hashtag && filter.hashtag.length
            ? filter.hashtag.map((h) => h.id)
            : undefined;
    record.hashtagsLogicalAnd = filter.attachmentHashtagsLogicalAnd ?? false;
    record.visibility = filter.visibility ?? undefined;
    record.currentWorkflowStatusIds =
        filter.communityId && filter.workflowStatus?.ids?.length
            ? filter.workflowStatus.ids
            : !filter.communityId && filter.dashboardWorkflowStatus?.ids?.length
              ? filter.dashboardWorkflowStatus.ids
              : undefined;

    record.postFieldFilters =
        filter.customFields && filter.customFields.length
            ? fromCustomFieldsSearchToQuickFilterData(filter.customFields)
            : undefined;

    record.screenFieldFilters =
        filter.screenFields && filter.screenFields.length
            ? fromCustomFieldsSearchToQuickFilterData(filter.screenFields)
            : undefined;

    //Attachment Filters
    record.attachmentHashtagIds = filter.attachmentHashtag
        ? filter.attachmentHashtag.map((h) => h.id)
        : undefined;
    record.attachmentHashtagsLogicalAnd =
        filter.attachmentHashtagsLogicalAnd ?? false;
    record.attachmentCreatedByUserId =
        filter.attachmentCreatedByUser &&
        filter.attachmentCreatedByUser.length > 0
            ? filter.attachmentCreatedByUser[0].id
            : undefined;

    record.attachmentModifiedDateType =
        filter.attachmentModifiedDateType != null
            ? fromFilterDateToQuickFilterDate(filter.attachmentModifiedDateType)
            : undefined;
    record.attachmentModifiedTimestampFrom =
        record.attachmentModifiedDateType == 'custom'
            ? fromDateToTimestampLocal(filter.attachmentModifiedDateFrom)
            : undefined;
    record.attachmentModifiedTimestampTo =
        record.attachmentModifiedDateType == 'custom'
            ? fromDateToTimestampLocal(filter.attachmentModifiedDateTo)
            : undefined;

    record.attachmentName = filter.attachmentName ?? undefined;
    record.mimeTypes =
        filter.mimeTypes?.map((mimeType) => {
            return mimeType.id;
        }) ?? undefined;
    record.attachmentSourceType = filter.attachmentSourceType ?? undefined;

    record.toManage = filter.toManage === true ? filter.toManage : undefined;

    record.pinned = filter.pinned === true ? filter.pinned : undefined;
    if (filter.order != null) {
        record.orderBy = filter.order.orderBy;
        record.orderDesc = filter.order.orderDesc;
    }

    record.version = ActualQuickFilterVersion;
    return record;
};

const fromFilterDateToQuickFilterDate = (
    filterDateOptions: FilterDateOptions,
): QuickFilterDateOptions | null => {
    switch (filterDateOptions) {
        case FilterDateOptions.TODAY: {
            return 'today';
        }
        case FilterDateOptions.YESTERDAY: {
            return 'yesterday';
        }
        case FilterDateOptions.LAST_7_DAYS: {
            return 'last7Days';
        }
        case FilterDateOptions.LAST_30_DAYS: {
            return 'last30Days';
        }
        case FilterDateOptions.CUSTOM: {
            return 'custom';
        }
        default:
            return null;
    }
};

const fromEventFilterDateToQuickFilterEventDate = (
    eventType: EventFilterDateOptions,
): QuickFilterEventDateOptions | null => {
    switch (eventType) {
        case EventFilterDateOptions.UPCOMING: {
            return 'upcoming';
        }
        case EventFilterDateOptions.ONGOING: {
            return 'ongoing';
        }
        case EventFilterDateOptions.IN_PROGRAM: {
            return 'inProgram';
        }
        case EventFilterDateOptions.ENDED: {
            return 'ended';
        }
        case EventFilterDateOptions.CUSTOM: {
            return 'custom';
        }
        default:
            return null;
    }
};
