import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe, range, throwError, timer, zip } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

const retryStatus = [429, 502];
const errorStatus = 500;
const interactaBackendHeader = 'interacta-backend-version';
const excludedApis = [
    /internal\/v2\/communication\/posts\/data\/communities\/\d+\/export$/,
];

function backoff(maxTries: number, ms: number) {
    return pipe(
        retryWhen<HttpEvent<any>>((attempts) =>
            zip(range(1, maxTries + 1), attempts).pipe(
                mergeMap(([i, a]: [number, HttpResponse<any>]) => {
                    if (
                        i <= maxTries &&
                        (retryStatus.includes(a.status) || // if 429 OR
                            (a.status === errorStatus &&
                                !a.headers?.has(interactaBackendHeader))) && // 500 not generated by interacta-backend
                        (excludedApis.length === 0 ||
                            excludedApis.every(
                                (api) => a.url == null || !api.test(a.url),
                            ))
                    )
                        return timer(i * i * ms);
                    else return throwError(a);
                }),
            ),
        ),
    );
}

@Injectable()
export class ExponentialBackoffInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(backoff(4, 250));
    }
}
