import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'emptyLabelReplaceWith',
})
export class EmptyLabelReplaceWithPipe implements PipeTransform {
    transform(value: any, defaultValue = '-'): string {
        return value == null || value === '' ? defaultValue : value;
    }
}
