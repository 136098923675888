import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { CustomValidators } from '@modules/core';
import { QuillTableSettings } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { generateQuillTableClientUid } from '@modules/shared-v2/models/quill-table/quill-table.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'interacta-quill-table-settings-dialog',
    templateUrl: './quill-table-settings-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuillTableSettingsDialogComponent {
    @Input() isOpen = false;

    @Output() apply = new EventEmitter<QuillTableSettings>();
    @Output() closing = new EventEmitter<void>();

    form = this.fb.group({
        title: this.fb.control(
            this.translateService.instant(
                'QUILL.TABLE.SETTINGS.DEFAULT_TITLE',
            ) as string,
            {
                validators: [
                    CustomValidators.required,
                    CustomValidators.maxLength(255),
                ],
            },
        ),
        rows: this.fb.control(3, {
            validators: [CustomValidators.required, CustomValidators.min(1)],
        }),
        columns: this.fb.control(3, {
            validators: [CustomValidators.required, CustomValidators.min(1)],
        }),
    });

    headerRow = true;

    constructor(
        private fb: NonNullableFormBuilder,
        private translateService: TranslateService,
    ) {}

    close(): void {
        this.closing.emit();
    }

    applySettings(): void {
        if (this.form.valid) {
            this.apply.emit({
                clientUid: generateQuillTableClientUid(),
                headerRow: this.headerRow,
                ...this.form.getRawValue(),
            });
            this.close();
        }
    }
}
