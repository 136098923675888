import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    ENVIRONMENT,
    Language,
    toLanguages,
} from '@interacta-shared/data-access-configuration';
import { LocalStorageService } from '@modules/core/services/local-storage.service';
import {
    CommentDeserialize,
    IComment,
    ITranslatedComment,
} from '@modules/post/models/comment.model';
import { Observable, ReplaySubject, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import {
    BasePostDeserialize,
    IBasePost,
} from '../../post/models/base-post.model';
import { PostService } from '../../post/services/post.service';

type TranslateCache<OriginalT, TranslatedT> = Record<
    number,
    { timestamp: number; original: OriginalT; translated: TranslatedT }
>;

type TranslatePostsCache = TranslateCache<IBasePost, IBasePost>;

type TranslateCommentsCache = TranslateCache<IComment, ITranslatedComment>;

@Injectable({ providedIn: 'root' })
export class CloudTranslationService {
    lastLanguageJson: Language | null = null;

    private _lastLanguage$ = new ReplaySubject<Language | null>(1);
    private languagesListCache: Language[] | null = null;
    private translatedPostCache: TranslatePostsCache = {};
    private translatedCommentsCache: TranslateCommentsCache = {};

    private baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts/data`;
    private baseUrlSettings = `${inject(ENVIRONMENT).apiBasePath.common}/core/settings`;
    private readonly cookieLastLangKey = 'lastTranslationLanguage';

    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
        private postService: PostService,
        private localStorageService: LocalStorageService,
        authService: AuthService,
    ) {
        authService.login$
            .pipe(
                switchMap(() =>
                    this.getLastLanguage()
                        ? this.getLanguageByCode(this.getLastLanguage()!.code)
                        : of(null),
                ),
                tap((_lang) =>
                    this.localStorageService.setEntry(
                        this.cookieLastLangKey,
                        _lang,
                    ),
                ),
            )
            .subscribe((_lang) => this._lastLanguage$.next(_lang));
    }

    get lastLanguage$(): Observable<Language | null> {
        return this._lastLanguage$.asObservable().pipe(distinctUntilChanged());
    }

    getUnfilteredLanguageList(): Observable<Language[]> {
        return this.http
            .get<any>(`${this.baseUrlSettings}/translation-languages`)
            .pipe(map(toLanguages));
    }

    getLanguagesList(): Observable<Language[]> {
        const lastLanguage = this.getLastLanguage();

        if (this.languagesListCache) {
            const sortedList = lastLanguage
                ? this.prependLastLanguage(
                      this.languagesListCache,
                      lastLanguage,
                  )
                : this.languagesListCache;

            return of(sortedList);
        }

        return this.http
            .get<any>(`${this.baseUrlSettings}/translation-languages`)
            .pipe(
                map(toLanguages),
                map((languages) =>
                    lastLanguage
                        ? this.prependLastLanguage(languages, lastLanguage)
                        : languages,
                ),
                tap((languages) => {
                    this.languagesListCache = languages;
                }),
            );
    }

    getPostTranslation(
        postId: number,
        language: Language,
    ): Observable<IBasePost> {
        this.localStorageService.setEntry(this.cookieLastLangKey, language);

        if (Object.keys(this.translatedPostCache)?.length >= 100) {
            this.removeOlderElementFromCache(this.translatedPostCache);
        }

        return this.http
            .post(`${this.baseUrl}/translate-post/${postId}`, {
                targetLanguage: language.code,
            })
            .pipe(
                map((post: unknown) =>
                    BasePostDeserialize.translatedPost(
                        post,
                        this.configurationService,
                        language,
                    ),
                ),
                tap((post: IBasePost) => {
                    this.translatedPostCache[post.id] = {
                        timestamp: Date.now(),
                        original: post,
                        translated: post,
                    };
                    this._lastLanguage$.next(language);
                }),
            );
    }

    getCommentTranslation(
        comment: IComment,
        language: Language,
        postId: number,
    ): Observable<ITranslatedComment> {
        this.localStorageService.setEntry(this.cookieLastLangKey, language);

        if (Object.keys(this.translatedCommentsCache).length >= 100) {
            this.removeOlderElementFromCache(this.translatedCommentsCache);
        }

        return this.http
            .post<any>(`${this.baseUrl}/translate-comment/${comment.id}`, {
                targetLanguage: language.code,
            })
            .pipe(
                map((comment) =>
                    CommentDeserialize.translatedComment(
                        comment.comment,
                        postId,
                        language,
                    ),
                ),
                tap((translated: ITranslatedComment) => {
                    this.translatedCommentsCache[comment.id] = {
                        timestamp: Date.now(),
                        original: comment,
                        translated,
                    };
                    this._lastLanguage$.next(language);
                }),
            );
    }

    getOriginalPost(postId: number): Observable<IBasePost> {
        return this.postService.getPost(postId, true).pipe(
            tap((_) => {
                this.removePostFromCache(postId);
            }),
        );
    }

    getOriginalComment(commentId: number): IComment | null {
        const cachedComment = this.translatedCommentsCache[commentId];

        if (cachedComment) {
            delete this.translatedCommentsCache[commentId];
        }

        return cachedComment?.original ?? null;
    }

    getTranslatedPostFromCache(post: IBasePost): IBasePost {
        const cached = this.translatedPostCache[post.id];

        if (!cached) {
            return post;
        }

        const translated = cached.translated;

        return {
            ...post,
            title: translated.title,
            descriptionDelta: translated.descriptionDelta,
            language: translated.language,
            lastModifyTimestamp: translated.lastModifyTimestamp,
            rightAlignText: translated.rightAlignText,
            isTranslated: true,
        };
    }

    getTranslatedCommentFromCache(comment: IComment): IComment {
        const cached = this.translatedCommentsCache[comment.id];

        if (!cached) {
            return comment;
        }

        const translated = cached.translated;
        const parentComment = comment.parentComment
            ? {
                  ...comment.parentComment,
                  comment: translated.comment,
                  rightAlignText: translated.rightAlignText,
                  language: translated.language,
              }
            : undefined;

        return {
            ...comment,
            comment: translated.comment,
            rightAlignText: translated.rightAlignText,
            language: translated.language,
            isTranslated: true,
            parentComment,
        };
    }

    removePostFromCache(postId: number): void {
        delete this.translatedPostCache[postId];
    }

    private getLastLanguage(): Language | null {
        return (
            this.localStorageService.getEntry(this.cookieLastLangKey) ?? null
        );
    }

    private removeOlderElementFromCache(
        cache: TranslatePostsCache | TranslateCommentsCache,
    ): void {
        const cachedValues = Object.values(cache);

        if (cachedValues.length <= 0) {
            return;
        }

        const older = cachedValues.reduce((prev, curr) =>
            prev.timestamp < curr.timestamp ? prev : curr,
        );

        delete cache[older.translated.id];
    }

    private getLanguageByCode(code: string): Observable<Language | null> {
        return this.getUnfilteredLanguageList().pipe(
            map((lang) => lang.find((_lang) => _lang.code === code) || null),
        );
    }

    private prependLastLanguage(
        languages: Language[],
        lastLanguage: Language,
    ): Language[] {
        return [
            lastLanguage,
            ...languages.filter((l) => l.code !== lastLanguage.code),
        ];
    }
}
