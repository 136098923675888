import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import {
    ConfigurationService,
    ENVIRONMENT,
    fromZonedDatetime,
    toZonedDatetime,
    ZonedDateTime,
} from '@interacta-shared/data-access-configuration';
import {
    assertUnreachable,
    IList,
    PaginatedList,
    paginatedListFromIList,
    toPaginatedList,
} from '@interacta-shared/util';

import {
    IUserAction,
    UserExtendedDeserialize,
} from '@modules/core/models/user-group.model';
import { DashboardPost } from '@modules/dashboard/models/dashboard-post.model';
import { toAttachmentsListEditResponse } from '@modules/post/models/attachment/attachment.deserialize';
import {
    IAttachmentListEdit,
    IAttachmentListEditResponse,
} from '@modules/post/models/attachment/attachment.model';
import { fromAttachmentsListEdit } from '@modules/post/models/attachment/attachment.serialize';
import {
    BasePostDeserialize,
    BasePostLite,
    ExtendedPostVisibilityMembers,
    IBasePost,
    InvitationsList,
    IPostCapability,
    IPostMetadata,
    PostType,
    PostVisibilityCounters,
    RelatedItemsCounts,
    VisibilityType,
} from '@modules/post/models/base-post.model';
import {
    CommentCreateResponse,
    CommentDeserialize,
    CommentSerialize,
    IComment,
    ICommentCreate,
    ICommentEdit,
    ICommentEditResponse,
} from '@modules/post/models/comment.model';
import {
    CustomPostDeserialize,
    CustomPostSerialize,
    ICustomPost,
} from '@modules/post/models/custom-post.model';
import {
    IPostHistoryFilter,
    PostListDeserialize,
} from '@modules/post/models/post-list.model';
import { ReportAbuse } from '@modules/report-abuse/models/report-abuse.model';
import { fromReportAbuse } from '@modules/report-abuse/models/report-abuse.serialize';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { ITaskType } from '@modules/tasks/models/task.model';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, takeWhile } from 'rxjs/operators';
import {
    toInvitation,
    toMemberItem,
    toMemberList,
} from '../../core/models/member/member.deserialize';
import { Member } from '../../core/models/member/member.model';
import {
    IEventHistory,
    NotificationDeserialize,
} from '../../core/models/notification-user.model';
import { IGenericCommunityFilter } from '../../core/models/user-autocomplete.model';
import {
    EventPostDeserialize,
    EventPostSerialize,
    IEventPostEdit,
    PostInvitationsMembersEdit,
} from '../models/event-post.model';
import {
    ExportPostsOutputFormat,
    ExportPostsResponseDTO,
} from '../models/export-post/export-post.model';
import { IPostFilters } from '../models/filter-post/filter-post.model';
import { fromCommunityListFilter } from '../models/filter-post/filter-post.serialize';
import { GenericPost, GenericPostEdit } from '../models/generic-post.model';
import { SurveyPostDeserialize } from '../models/survey-post/survey-post.deserialize';
import { SurveyType } from '../models/survey-post/survey-post.model';

@Injectable({ providedIn: 'root' })
export class PostService {
    private baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts`;
    private taskBaseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/tasks/data`;

    constructor(
        private communitiesStateService: CommunitiesStateService,
        private http: HttpClient,
        private authService: AuthService,
        private configurationService: ConfigurationService,
    ) {}

    // READ LIST

    public getPostDetail(
        postId: number,
        loadMainAttachment = false,
        skipMentions?: boolean,
    ): Observable<IBasePost> {
        const requests = [
            this.getPostCapabilities(postId),
            this.getPost(postId, loadMainAttachment, skipMentions),
        ];
        return forkJoin(requests).pipe(
            map((records: any[]) => {
                const post: ICustomPost = records[1];
                post.capabilities = records[0];
                return post;
            }),
        );
    }

    public getPost(
        postId: number,
        loadMainAttachment = false,
        skipMentions?: boolean,
    ): Observable<IBasePost> {
        const _loadMainAttachment = String(loadMainAttachment);
        return this.http
            .get(`${this.baseUrl}/data/post-detail-by-id/${postId}`, {
                params: {
                    loadMainAttachment: _loadMainAttachment,
                    loadMainAttachmentPreviewImageHiResLink:
                        _loadMainAttachment,
                    loadMainAttachmentPreviewImageHiResAnimatedLink:
                        _loadMainAttachment,
                },
            })
            .pipe(
                mergeMap((rec: any) =>
                    forkJoin([
                        this.communitiesStateService.getPostMetadata(
                            rec.communityId,
                        ),
                        skipMentions ? of(undefined) : this.getMentions(postId),
                    ]).pipe(
                        map(([metadata, memberMentions]) => ({
                            metadata,
                            record: { ...rec, memberMentions },
                        })),
                    ),
                ),
                map((res: { record: any; metadata: IPostMetadata }) =>
                    PostListDeserialize.postDetails(
                        this.configurationService,
                        res.record,
                        res.metadata,
                    ),
                ),
            );
    }

    public getPostCapabilities(postId: number): Observable<IPostCapability> {
        return this.http
            .get(`${this.baseUrl}/data/post-capabilities/${postId}`)
            .pipe(
                map((res: any) => CustomPostDeserialize.postCapabilities(res)),
            );
    }

    public getPostListCapabilities(
        postIds: number[],
    ): Observable<IPostCapability[]> {
        return this.http
            .post(`${this.baseUrl}/data/post-capabilities`, {
                postIds,
            })
            .pipe(
                map((res: any) =>
                    BasePostDeserialize.postListCapabilities(res, postIds),
                ),
            );
    }

    public setLikeComment(
        commentId: number,
        likedByMe: boolean,
    ): Observable<void> {
        const url = likedByMe
            ? `setup-post-comment-like`
            : `remove-post-comment-like`;
        return this.http.put<void>(
            `${this.baseUrl}/manage/${url}/${commentId}`,
            null,
        );
    }

    public getPostComments(
        postId: number,
        pageSize = 2,
        pageToken?: string,
    ): Observable<PaginatedList<IComment>> {
        const data = { pageToken, calculateTotalItemsCount: true, pageSize };
        return this.http
            .post<any>(`${this.baseUrl}/data/comments-list/${postId}`, data)
            .pipe(
                takeWhile(() =>
                    this.authService.currentUserData()?.id ? true : false,
                ),
                map((res) => CommentDeserialize.commentsList(res)),
            );
    }

    public getPostCommentUsers(
        commentId: number,
        pageToken?: string,
    ): Observable<PaginatedList<IUserAction>> {
        const data = { pageToken, calculateTotalItemsCount: true };
        return this.http
            .post(`${this.baseUrl}/data/comment-likes-list/${commentId}`, data)
            .pipe(map((res) => UserExtendedDeserialize.likersList(res)));
    }

    public createNewComment(
        postId: number,
        comment: ICommentCreate,
    ): Observable<CommentCreateResponse> {
        return this.http
            .post<any>(
                `${this.baseUrl}/manage/create-comment/${postId}`,
                CommentSerialize.fromComment(comment),
            )
            .pipe(
                map((record) => ({
                    createdComment: CommentDeserialize.comment(record.comment),
                    postFolloweByMeEnabled: record.followedByMe ?? false,
                })),
            );
    }

    public editComment(
        comment: ICommentEdit,
    ): Observable<ICommentEditResponse> {
        return this.http
            .put<any>(
                `${this.baseUrl}/manage/edit-comment/${comment.id}`,
                CommentSerialize.fromComment(comment),
            )
            .pipe(
                map((record) =>
                    CommentDeserialize.commentEdit(
                        record.comment,
                        record.addedAttachments,
                        record.removedAttachmentIds,
                    ),
                ),
            );
    }

    public viewerMembersList(
        postId: number,
        search: IGenericCommunityFilter,
    ): Observable<PaginatedList<Member>> {
        return this.http
            .post(`${this.baseUrl}/data/viewer-members-list/${postId}`, search)
            .pipe(
                map((res: any) => toMemberList(res, res.totalItemsCount)),
                map((res: IList<Member>) => paginatedListFromIList(res)),
            );
    }

    public addWatchers(watchers: Member[], postId: number): Observable<any> {
        return this.http.put(
            `${this.baseUrl}/manage/edit-post-watchers/${postId}`,
            CustomPostSerialize.editWatchers(watchers, []),
        );
    }

    public removeWatchers(watchers: Member[], postId: number): Observable<any> {
        return this.http.put(
            `${this.baseUrl}/manage/edit-post-watchers/${postId}`,
            CustomPostSerialize.editWatchers([], watchers),
        );
    }

    private getHistoryList(
        filter: IPostHistoryFilter,
        res: any,
        metadata?: IPostMetadata,
    ): PaginatedList<IEventHistory> {
        const historyList =
            filter.retrievePostEvents === true ||
            filter.retrieveWorkflowEvents === false
                ? NotificationDeserialize.historyList(
                      res,
                      this.configurationService.getCurrentLanguage().code,
                      this.authService.getCurrentUserData()!,
                      this.configurationService,
                  )
                : NotificationDeserialize.workflowList(
                      res,
                      this.configurationService.getCurrentLanguage().code,
                      this.authService.getCurrentUserData()!,
                      this.configurationService,
                      metadata,
                  );
        return historyList;
    }

    public getPostHistory(
        postId: number,
        filter: IPostHistoryFilter = {},
        communityId?: number,
    ): Observable<PaginatedList<IEventHistory>> {
        if (communityId) {
            return forkJoin([
                this.http.post(
                    `${this.baseUrl}/data/history-list/${postId}`,
                    filter,
                ),
                this.communitiesStateService.getPostMetadata(communityId),
            ]).pipe(
                map(([res, metadata]) =>
                    this.getHistoryList(filter, res, metadata),
                ),
            );
        } else {
            return this.http
                .post(`${this.baseUrl}/data/history-list/${postId}`, filter)
                .pipe(map((res) => this.getHistoryList(filter, res)));
        }
    }

    public addPostFollow(postId: number): Observable<any> {
        return this.http.put(
            `${this.baseUrl}/manage/setup-post-follow/${postId}`,
            {},
        );
    }

    public removePostFollow(postId: number): Observable<any> {
        return this.http.put(
            `${this.baseUrl}/manage/remove-post-follow/${postId}`,
            {},
        );
    }

    public markPostAsViewed(
        postId: number,
    ): Observable<{ viewersCount: number; viewedTimestamp: number }> {
        return this.http.put<{ viewersCount: number; viewedTimestamp: number }>(
            `${this.baseUrl}/manage/mark-post-as-viewed/${postId}`,
            {},
        );
    }

    public markPostAsTouched(postId: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/manage/touch/${postId}`);
    }

    public editPostAttachments(
        attachments: IAttachmentListEdit,
        postId: number,
    ): Observable<IAttachmentListEditResponse> {
        return this.http
            .put<any>(
                `${this.baseUrl}/manage/edit-post-attachments/${postId}`,
                fromAttachmentsListEdit(attachments),
            )
            .pipe(map((res: any) => toAttachmentsListEditResponse(res)));
    }

    public addPostLike(postId: number): Observable<any> {
        return this.http.put(
            `${this.baseUrl}/manage/setup-post-like/${postId}`,
            {},
        );
    }

    public removePostLike(postId: number): Observable<any> {
        return this.http.put(
            `${this.baseUrl}/manage/remove-post-like/${postId}`,
            {},
        );
    }

    public getLikeList(
        postId: number,
        pageToken?: string,
        name?: string,
    ): Observable<PaginatedList<IUserAction>> {
        return this.http
            .post(`${this.baseUrl}/data/likes-list/${postId}`, {
                pageToken,
                calculateTotalItemsCount: !name && !pageToken,
                name,
            })
            .pipe(map((res) => UserExtendedDeserialize.likersList(res)));
    }

    public getViewerList(
        postId: number,
        pageToken?: string,
        name?: string,
    ): Observable<PaginatedList<IUserAction>> {
        return this.http
            .post(`${this.baseUrl}/data/visualizations-list/${postId}`, {
                pageToken,
                calculateTotalItemsCount: !name && !pageToken,
                name,
            })
            .pipe(map((res) => UserExtendedDeserialize.viewersList(res)));
    }

    public communityRelationsPostsCounters(
        postId: number,
    ): Observable<RelatedItemsCounts> {
        return this.http.get<RelatedItemsCounts>(
            `${this.baseUrl}/data/posts/${postId}/community-relations-posts-counters`,
        );
    }

    public deletePost(postId: number): Observable<void> {
        return this.http.delete<void>(
            `${this.baseUrl}/manage/delete-post/${postId}`,
        );
    }

    deletePostBatch(postIds: number[]): Observable<number[]> {
        return this.http
            .put<{
                postIds: number[];
            }>(`${this.baseUrl}/manage/delete-batch-posts`, { postIds })
            .pipe(map(({ postIds }) => postIds));
    }

    public publicPost(postId: number): Observable<{ nextOccToken: number }> {
        return this.http.put<{ nextOccToken: number }>(
            `${this.baseUrl}/manage/edit-post-visibility/${postId}`,
            { visibility: VisibilityType.PUBLIC },
        );
    }

    public deleteComment(commentId: number): Observable<any> {
        return this.http.delete(
            `${this.baseUrl}/manage/delete-comment/${commentId}`,
        );
    }

    public getMentions(
        postId: number,
        pageToken?: string,
    ): Observable<PaginatedList<Member>> {
        return this.http
            .post<any>(`${this.baseUrl}/data/mentions/${postId}`, {
                pageToken,
                calculateTotalItemsCount: true,
            })
            .pipe(
                map((res: any) => toPaginatedList(res)),
                map((res: PaginatedList<any>) => ({
                    ...res,
                    list: res.list.map(toMemberItem),
                })),
            );
    }

    public getInvitations(
        postId: number,
        pageToken: string | null,
    ): Observable<InvitationsList> {
        return this.http
            .post<any>(`${this.baseUrl}/data/event-invitations/${postId}`, {
                pageToken,
                calculateTotalItemsCount: true,
            })
            .pipe(
                map((res: any) => {
                    return {
                        ...toPaginatedList(res),
                        participantsResponseCountByTypeMap:
                            res.participantsResponseCountByTypeMap,
                    };
                }),
                map((res: PaginatedList<any>) => ({
                    ...res,
                    list: res.list.map(toInvitation),
                })),
            );
    }

    public editInvitations(
        postId: number,
        params: PostInvitationsMembersEdit,
    ): Observable<any> {
        return this.http.put<any>(
            `${this.baseUrl}/manage/edit-post-invitations/${postId}`,
            EventPostSerialize.eventEditInvitations(params),
        );
    }

    public getGroupInvitations(
        postId: number,
        groupId: number,
        pageToken: string | null,
    ): Observable<InvitationsList> {
        return this.http
            .post<any>(
                `${this.baseUrl}/data/event-invitations/${postId}/${groupId}`,
                {
                    pageToken,
                    calculateTotalItemsCount: true,
                },
            )
            .pipe(
                map((res: any) => {
                    return {
                        ...toPaginatedList(res),
                        participantsResponseCountByTypeMap:
                            res.participantsResponseCountByTypeMap,
                    };
                }),
                map((res: PaginatedList<any>) => ({
                    ...res,
                    list: res.list.map(toInvitation),
                })),
            );
    }

    public setupPinPost(postId: number): Observable<unknown> {
        return this.http.put(
            `${this.baseUrl}/manage/setup-post-pin/${postId}`,
            {},
        );
    }

    public removePinPost(postId: number): Observable<unknown> {
        return this.http.put(
            `${this.baseUrl}/manage/remove-post-pin/${postId}`,
            {},
        );
    }

    public reportAbuse(report: ReportAbuse): Observable<unknown> {
        return this.http.post(
            `${this.baseUrl}/manage/report-abuse`,
            fromReportAbuse(report),
        );
    }

    public getPostForEdit(postId: number): Observable<GenericPostEdit> {
        return forkJoin([
            this.http.get<any>(
                `${this.baseUrl}/manage/data-for-edit/${postId}`,
                { params: { loadAttachments: false } },
            ),
            this.getPostCapabilities(postId),
        ]).pipe(
            mergeMap(([record, capabilities]) =>
                this.communitiesStateService
                    .getPostMetadata(record.communityId)
                    .pipe(
                        map((metadata) => ({
                            record,
                            capabilities,
                            metadata,
                        })),
                    ),
            ),
            mergeMap((result) => {
                return of({ ...result, relations: [] });
            }),
            map((result: any) => this.toPostForEdit(result, postId)),
        );
    }

    public getPostForCopy(postId: number): Observable<GenericPost> {
        return forkJoin([
            this.http.get<any>(
                `${this.baseUrl}/manage/data-for-copy/${postId}`,
                { params: { loadAttachments: false } },
            ),
            this.getPostCapabilities(postId),
        ]).pipe(
            mergeMap(([record, capabilities]) =>
                this.communitiesStateService
                    .getPostMetadata(record.communityId)
                    .pipe(
                        map((metadata) => ({
                            record,
                            capabilities,
                            metadata,
                        })),
                    ),
            ),
            map((result) => this.toPostForCopy(result)),
        );
    }

    public getVisibilityCounters(
        postId: number,
        surveyType?: SurveyType,
    ): Observable<PostVisibilityCounters> {
        return this.http
            .get(`${this.baseUrl}/data/viewer-counters/${postId}`)
            .pipe(
                map((counters) =>
                    BasePostDeserialize.toPostVisibilityCounters(
                        counters,
                        surveyType,
                    ),
                ),
            );
    }

    public getOthersVisibilityList(
        postId: number,
    ): Observable<ExtendedPostVisibilityMembers> {
        return this.http
            .get(
                `${this.baseUrl}/data/extended-visibility-viewers-list/${postId}`,
            )
            .pipe(
                map((res: any) =>
                    BasePostDeserialize.toExtendedVisibilityMembers(res),
                ),
            );
    }

    public getAssigneesTask(
        postId: number,
        pageToken: string | null,
        taskType: ITaskType,
    ): Observable<PaginatedList<Member>> {
        return this.http
            .post(`${this.taskBaseUrl}/post-tasks/${postId}/assignees`, {
                pageToken,
                type: taskType,
                pageSize: 10,
                calculateTotalItemsCount: true,
            })
            .pipe(
                map((res: any) => toPaginatedList(res)),
                map((res: PaginatedList<any>) => ({
                    ...res,
                    list: res.list.map(toMemberItem),
                })),
            );
    }

    public publish(postId: number, occToken: number): Observable<unknown> {
        return this.http.put(`${this.baseUrl}/manage/publish/${postId}`, {
            occToken,
        });
    }

    public reschedule(
        postId: number,
        occToken: number,
        scheduledPublication: ZonedDateTime,
    ): Observable<{ occToken: number; scheduledPublication: ZonedDateTime }> {
        return this.http
            .put<any>(`${this.baseUrl}/manage/schedule-publication/${postId}`, {
                occToken,
                scheduledPublication: fromZonedDatetime(scheduledPublication),
            })
            .pipe(
                map((res: any) => ({
                    occToken: res.occToken,
                    scheduledPublication: toZonedDatetime(
                        res.scheduledPublication,
                        this.configurationService,
                    ),
                })),
            );
    }

    exportPosts(
        communityId: number,
        filters: IPostFilters,
        outputFormat?: ExportPostsOutputFormat,
    ): Observable<ExportPostsResponseDTO> {
        return this.http.post<ExportPostsResponseDTO>(
            `${this.baseUrl}/data/communities/${communityId}/export`,
            {
                filters: fromCommunityListFilter(
                    filters,
                    this.authService.getCurrentUserData() || undefined,
                ),
                outputFormat,
            },
        );
    }

    getBasePostLiteBatch(postIds: number[]): Observable<BasePostLite[]> {
        return this.http
            .post<{ posts: any[] }>(`${this.baseUrl}/data/base-info`, {
                postIds,
            })
            .pipe(
                map((res) =>
                    res.posts.map((p) => BasePostDeserialize.toBasePostLite(p)),
                ),
            );
    }

    toDashboardPost(post: GenericPost): Observable<DashboardPost> {
        return this.communitiesStateService.getCommunity(post.communityId).pipe(
            mergeMap((c) => this.communitiesStateService.injectMetadata(c)),
            map((community) => ({
                ...post,
                community,
            })),
        );
    }

    private toPostForEdit(result: any, postId: number): GenericPostEdit {
        switch (result.record.type as PostType) {
            case PostType.CUSTOM: {
                const post = CustomPostDeserialize.customPostDetailsForEdit(
                    {
                        ...result.record.customData,
                        id: postId,
                        communityId: result.record.communityId,
                        occToken: result.record?.occToken,
                        linkPreview: result.record?.linkPreview,
                        coverImage: result.record?.coverImage,
                    },
                    result.metadata,
                    this.configurationService,
                );
                post.capabilities = result.capabilities;
                post.creationTimestamp = result.record.creationTimestamp;

                return {
                    ...post,
                    updateAttachments: [],
                    removeAttachmentIds: [],
                    removeWatcherUserIds: [],
                    removeWatcherGroupIds: [],
                };
            }
            case PostType.EVENT: {
                const post = EventPostDeserialize.eventPostDetailsForEdit(
                    this.configurationService,
                    {
                        ...result.record,
                        ...result.record.eventData,
                        id: postId,
                        communityId: result.record.communityId,
                        occToken: result.record?.occToken,
                        coverImage: result.record?.coverImage,
                    },
                    result.metadata,
                );

                const postForEdit: IEventPostEdit = {
                    ...post,
                    capabilities: result.capabilities,
                    creationTimestamp: result.record.creationTimestamp,
                    sendNotification: false,
                    updateAttachments: [],
                    removeAttachmentIds: [],
                    removeWatcherUserIds: [],
                    removeWatcherGroupIds: [],
                };

                return postForEdit;
            }
            case PostType.SURVEY: {
                const post = SurveyPostDeserialize.surveyPostDetailsForEdit(
                    this.configurationService,
                    {
                        ...result.record,
                        ...result.record.surveyData,
                        id: postId,
                        communityId: result.record.communityId,
                        occToken: result.record?.occToken,
                        coverImage: result.record?.coverImage,
                    },
                    result.metadata,
                );
                post.capabilities = result.capabilities;
                post.creationTimestamp = result.record.creationTimestamp;
                return post;
            }
            default:
                assertUnreachable();
        }
    }

    private toPostForCopy(result: any): GenericPost {
        switch (result.record.type as PostType) {
            case PostType.CUSTOM: {
                const post = CustomPostDeserialize.customPostDetailsForCreate(
                    {
                        ...result.record.customData,
                        communityId: result.record.communityId,
                        occToken: result.record?.occToken,
                        coverImage: result.record?.coverImage,
                    },
                    result.metadata,
                    this.authService.currentUserData()!,
                    this.configurationService,
                );
                post.capabilities = result.capabilities;
                return post;
            }
            case PostType.EVENT: {
                const post = EventPostDeserialize.eventPostDetailsForCreate(
                    this.configurationService,
                    {
                        ...result.record.eventData,
                        communityId: result.record.communityId,
                        occToken: result.record?.occToken,
                        coverImage: result.record?.coverImage,
                    },
                    result.metadata,
                    this.authService.getCurrentUserData()!,
                );
                post.capabilities = result.capabilities;
                return post;
            }
            case PostType.SURVEY: {
                const post = SurveyPostDeserialize.surveyPostDetailsForCreate(
                    this.configurationService,
                    {
                        ...result.record.surveyData,
                        communityId: result.record.communityId,
                        occToken: result.record?.occToken,
                        coverImage: result.record?.coverImage,
                    },
                    result.metadata,
                    this.authService.getCurrentUserData()!,
                );
                post.capabilities = result.capabilities;
                return post;
            }
            default:
                assertUnreachable();
        }
    }
}
