import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserComponent } from '@interacta-shared/ui';
import { EMPTY_SEARCH_VALUE } from '@interacta-shared/util';
import { ProfilePreviewDialogStateService } from '@modules/state/services/profile-preview-dialog-state.service';
import { MemberClickBehavior } from '../member/member.component';

@Component({
    selector: 'interacta-profiled-user[user]',
    templateUrl: './profiled-user.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfiledUserComponent {
    @Input() user!: UserComponent['user'];
    @Input() size: UserComponent['size'] = 'regular';
    @Input() showInfo = false;
    @Input() fontWeight: UserComponent['fontWeight'] = 'bold';
    @Input() rtl: UserComponent['rtl'] = false;
    @Input() additionalInfo?: UserComponent['additionalInfo'];
    @Input() memberClickBehavior: MemberClickBehavior = 'preview';
    @Input() color: 'black' | 'gray' | 'white' = 'black';

    EMPTY_SEARCH_VALUE = EMPTY_SEARCH_VALUE;

    constructor(
        public router: Router,
        private profilePreviewDialogStateService: ProfilePreviewDialogStateService,
    ) {}

    public openProfile(user: UserComponent['user']): void {
        if (!user.id || user.id === EMPTY_SEARCH_VALUE) {
            console.error('No ID provided');
        } else if (this.memberClickBehavior === 'preview') {
            this.profilePreviewDialogStateService.open(user.id).subscribe();
        } else if (this.memberClickBehavior === 'profile') {
            this.router.navigate(['/profile', user.id]);
        }
    }
}
