export function formatBytes(bytes: number, decimals?: number): string {
    if (bytes == 0) {
        return '0 B';
    }
    const k = 1024,
        dm = decimals ?? 2,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Bytes conversion
 * @param bytes source number to convert
 * @param decimals number of maximum decimal after conversion
 * @param scale positive number to convert from higher scale to lower (i.e. scale = 1, to convert from MB to KB)
 *
 */
export function bytesConverter(bytes: number, decimals = 2, scale = 1): number {
    const converterFactor = Math.pow(1024, scale);
    const decimalFactor = Math.pow(10, decimals);
    const number = bytes * converterFactor;
    return (
        Math.round((number + Number.EPSILON) * decimalFactor) / decimalFactor
    );
}
