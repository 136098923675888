<div
    *ngIf="control | inputState: isReadonly | async as state"
    class="flex h-40 items-center gap-x-8 rounded-lg border-2 px-12 py-8"
    [ngClass]="{
        'border-border-300 focus:border-text': !state.invalid,
        'border-border-error': state.invalid,
        'bg-surface-A': !state.disabled,
        'bg-surface-100 text-text-disabled pointer-events-none cursor-default':
            state.disabled
    }"
>
    <button
        class="shrink-0"
        interacta-icon-button
        type="button"
        [icon]="'minus'"
        [size]="iconSize"
        (click)="stepDown()"
    ></button>

    <input
        #inputRef
        autocomplete="off"
        class="no-spinner min-w-0 grow text-center"
        inputmode="numeric"
        type="number"
        [formControl]="control"
        [id]="id"
        [readonly]="state.disabled"
        (blur)="blurEmitter.emit()"
        (wheel)="inputRef.blur()"
    />

    <button
        class="shrink-0"
        interacta-icon-button
        type="button"
        [icon]="'plus'"
        [size]="iconSize"
        (click)="stepUp()"
    ></button>
</div>
