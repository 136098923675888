import { flatten } from '@interacta-shared/util';
import { CustomFields } from '@modules/communities/models/custom-metadata/CustomFields.class';
import {
    groupBy,
    idArraytoMap,
    mPartition,
} from '@modules/core/helpers/generic.utils';
import { IPostMetadata } from '@modules/post/models/base-post.model';
import { SurveyQuestionDefinition } from '@modules/post/models/survey-post/survey-post.model';
import { fromSurveyDefinitionToCustomDefinition } from '@modules/post/models/survey-post/survey-post.utils';
import { CommunityTreeDeserialize } from '../communities.model';
import { toActivationMetadata } from '../custom-metadata-activation/custom-metadata-activation.deserialize';
import {
    CustomFieldType,
    ICustomFieldAdminValidation,
    ICustomFieldDefinition,
    ICustomFieldMetadata,
    IEnumValues,
    IValidationMetadata,
    METADATA_QR_CODE_DOT_NOTATION,
    METADATA_QR_CODE_SECRET_DOT_NOTATION,
    MetadataFieldModeType,
    MetadataGenericListType,
    ValidationType,
} from './custom-metadata.model';

export const toCustomFieldDefinitions = (
    record: any[],
    fieldMode: MetadataFieldModeType,
    deleted?: boolean,
): ICustomFieldDefinition[] => {
    const fieldsWithoutActivations: ICustomFieldDefinition[] = record.map(
        (r) => ({
            id: r.id,
            parentId: r.parentId,
            name: r.name,
            label: r.label,
            type: r.type,
            customFieldType: toCustomFieldType(r),
            required: r.required,
            searchable: r.searchable,
            metadata: r.metadata
                ? toFieldCustomMetadata(r.metadata as { [key: string]: string })
                : {},
            sortable: r.sortable,
            enumValues: (r.enumValues || []).map(toEnumValues),
            validations: (r.validations || []).map((v: any) =>
                toValidationsMetadata(v),
            ),
            readonly: r.readonly,
            visibleInPreview: r.visibleInPreview,
            visibleInCreate: r.visibleInCreate,
            visibleInDetail: r.visibleInDetail,
            visibleInEdit: r.visibleInEdit,
            description:
                r.descriptionDelta || r.description
                    ? CommunityTreeDeserialize.getDescriptionI18n(
                          r.descriptionDelta ?? r.description,
                      )
                    : null,
            listenChanges: toListenChanges(r),
            fieldMode,
            leaf: false,
            deleted,
        }),
    );

    const fieldsMap = idArraytoMap(fieldsWithoutActivations);

    const fieldsWithActivations = fieldsWithoutActivations.map(
        (field, idx) => ({
            ...field,
            validations: field.validations.map((validation) => {
                const recordValidation = record[idx].validations.find(
                    (v: any) => v.id === validation.id,
                );

                const sourceFieldId = recordValidation.activationFieldId;
                const sourceField =
                    sourceFieldId != null ? fieldsMap[sourceFieldId] : null;
                if (sourceFieldId != null && !sourceField) {
                    console.error(
                        `ICustomFieldDefinition deserialization:  can't find defintion for field ${sourceFieldId}, configured as activationFieldId of field ${field.id} for validation ${validation.id}`,
                    );
                }

                return {
                    ...validation,
                    activation: sourceField
                        ? toActivationMetadata(
                              recordValidation,
                              sourceField.type,
                          )
                        : undefined,
                };
            }),
        }),
    );

    const fieldsWithoutDuplicatedValidations = fieldsWithActivations.map(
        (field) => {
            const validationsByType = groupBy(
                field.validations,
                (validation) => validation.validationType,
            );

            const validationsWithoutDuplicates = flatten(
                Object.entries(validationsByType).map(
                    ([validationType, validations]) => {
                        if (validations.length === 1) {
                            return validations;
                        }

                        const [withActivation, withoutActivation] = mPartition(
                            validations,
                            (validation) => validation.activation != null,
                        );

                        const [activeValidation, ...disabledValidations] = [
                            ...withoutActivation,
                            ...withActivation,
                        ];

                        console.warn(
                            `Invalid configuration: more than one validation of type ${validationType} on field ${field.id}, the following validations has been disabled:`,
                        );
                        disabledValidations.forEach(console.warn);

                        return [activeValidation];
                    },
                ),
            );

            return {
                ...field,
                validations: validationsWithoutDuplicates,
            };
        },
    );

    const childFieldIds = fieldsWithoutDuplicatedValidations
        .filter((f) => f.metadata.hierarchical && f.parentId != null)
        .map((f) => f.id);
    const parentFieldIds = new Set(
        fieldsWithoutDuplicatedValidations
            .filter((f) => f.parentId != null)
            .map((f) => f.parentId),
    );
    const leafFieldIds = childFieldIds.filter((f) => !parentFieldIds.has(f));

    const fieldsWithLeafsResolved = fieldsWithoutDuplicatedValidations.map(
        (f) => (leafFieldIds.includes(f.id) ? { ...f, leaf: true } : f),
    );

    return fieldsWithLeafsResolved;
};

export const toPostCustomFields = (
    record: any,
    metadata?: IPostMetadata,
): CustomFields => {
    if (!metadata) {
        return new CustomFields();
    }
    return new CustomFields(metadata.fieldMetadatas, record);
};

export const toScreenCustomFields = (
    record: any,
    metadata?: IPostMetadata,
    includeDeleted?: boolean,
): CustomFields => {
    if (!metadata || !metadata.workflowDefinition) {
        return new CustomFields();
    }

    const screenMetadatas = includeDeleted
        ? metadata.workflowDefinition.allScreenFieldMetadatas
        : metadata.workflowDefinition.screenFieldMetadatas;
    return new CustomFields(screenMetadatas, record);
};

export const toAcknowledgeTaskCustomFields = (
    record: any,
    metadata: IPostMetadata | undefined,
): CustomFields => {
    if (!metadata || !metadata.acknowledgeTaskDefinition) {
        return new CustomFields();
    }
    return new CustomFields(
        metadata.acknowledgeTaskDefinition.fieldDefinitions,
        record,
    );
};

export const toSurveyTaskCustomFields = (
    record: any,
    fieldDefinition: SurveyQuestionDefinition[],
): CustomFields => {
    if (!fieldDefinition) {
        return new CustomFields();
    }
    const newDef = fieldDefinition.map(fromSurveyDefinitionToCustomDefinition);

    return new CustomFields(newDef, record);
};

export const toFieldCustomMetadata = (record: {
    [key: string]: string;
}): ICustomFieldMetadata =>
    <ICustomFieldMetadata>{
        ...record,

        dynamic: record.dynamic ? record.dynamic === 'true' : undefined,

        field_group_id: record.field_group_id
            ? +record.field_group_id
            : undefined,

        feedback_max_value: record.feedback_max_value
            ? +record.feedback_max_value
            : undefined,
        feedback_min_value: record.feedback_min_value
            ? +record.feedback_min_value
            : undefined,
        feedback_step: record.feedback_step ? +record.feedback_step : undefined,

        catalog_id: record.catalog_id ? +record.catalog_id : undefined,
        community_id: record.community_id ? +record.community_id : undefined,
        hierarchical: record.hierarchical
            ? record.hierarchical === 'true'
            : undefined,

        inverse_relation_order_by: record.inverse_relation_order_by
            ? record.inverse_relation_order_by
            : undefined,
        inverse_relation_order_desc: record.inverse_relation_order_desc
            ? record.inverse_relation_order_desc === 'true'
            : undefined,
        inverse_relation_include_terminal_states:
            record.inverse_relation_include_terminal_states
                ? record.inverse_relation_include_terminal_states === 'true'
                : undefined,

        user_picker_from_community: record.user_picker_from_community
            ? record.user_picker_from_community === 'true'
            : undefined,
        user_picker_from_role: record.user_picker_from_role
            ? +record.user_picker_from_role
            : undefined,
        user_picker_from_group: record.user_picker_from_group
            ? +record.user_picker_from_group
            : undefined,
        group_picker_from_role: record.group_picker_from_role
            ? +record.group_picker_from_role
            : undefined,
        disabled_drive: record.disabled_drive
            ? record.disabled_drive === 'true'
            : undefined,
        disabled_local_filesystem: record.disabled_local_filesystem
            ? record.disabled_local_filesystem === 'true'
            : undefined,

        generate_post_comment: record.generate_post_comment
            ? record.generate_post_comment === 'true'
            : undefined,

        decimal_digits: record.decimal_digits
            ? +record.decimal_digits
            : undefined,

        qr_code: record[METADATA_QR_CODE_DOT_NOTATION]
            ? record[METADATA_QR_CODE_DOT_NOTATION] === 'true'
            : undefined,
        qr_code_secret: record[METADATA_QR_CODE_SECRET_DOT_NOTATION]
            ? record[METADATA_QR_CODE_SECRET_DOT_NOTATION] === 'true'
            : undefined,

        extends_post_visibility: record.extends_post_visibility
            ? record.extends_post_visibility === 'true'
            : undefined,
        sends_notifications: record.sends_notifications
            ? record.sends_notifications === 'true'
            : undefined,
        strict_order: record.strict_order
            ? record.strict_order === 'true'
            : undefined,
    };

export const toEnumValues = (record: any): IEnumValues => ({
    id: record.id,
    parentIds: record.parentIds,
    label: record.label,
    deleted: record.deleted,
    temporaryContentViewLink: record.temporaryContentViewLink ?? undefined,
    temporaryContentPreviewImageLink:
        record.temporaryContentPreviewImageLink ?? undefined,
    temporaryContentPreviewImageHiResLink:
        record.temporaryContentPreviewImageHiResLink ?? undefined,
});

export const toValidationsMetadata = (record: any): IValidationMetadata => ({
    id: record.id,
    fieldId: record.fieldId,
    validationType: record.validationTypeId,
    parameter: record.parameter,
    validationParameter: record.validationParameter,
    order: record.order,
    activation: undefined,
});

export const toCustomFieldAdminValidation = (
    record: any,
): ICustomFieldAdminValidation => ({
    minValue: record?.minValue,
    maxValue: record?.maxValue,
    maxSize: record?.maxSize,
    admittedMimeTypes: record?.admittedMimeTypes ?? undefined,
});

export const toCustomFieldType = (record: any): CustomFieldType => {
    switch (record.type) {
        case 1:
            return CustomFieldType.INT;
        case 2:
            return CustomFieldType.BIGINT;
        case 3:
            return CustomFieldType.DECIMAL;
        case 4:
            return CustomFieldType.DATE;
        case 5:
            return CustomFieldType.DATETIME;
        case 6:
            if (record.metadata[METADATA_QR_CODE_DOT_NOTATION] === 'true') {
                return CustomFieldType.QR_CODE;
            } else {
                return CustomFieldType.STRING;
            }
        case 7:
            return CustomFieldType.SELECT;
        case 8:
            return CustomFieldType.SELECT_MULTIPLE_VALUES;
        case 9:
            return CustomFieldType.TEXT;
        case 10:
            return CustomFieldType.FLAG;
        case 11:
            if (record.metadata['generate_post_comment'] === 'true') {
                return CustomFieldType.COMMENT;
            } else {
                return CustomFieldType.HTML;
            }
        case 12:
            return CustomFieldType.RATING;
        case 13:
            return CustomFieldType.SELECT_HIERARCHICAL;
        case 14:
            return CustomFieldType.LINK;
        case 15:
            return toCustomFieldTypeGenericList(
                record.metadata.generic_entity_list_config_id as string,
            );
        default:
            console.warn(`Unhandled CustomFieldType: ${record.type}`);
            return CustomFieldType.INT;
    }
};

const toCustomFieldTypeGenericList = (configId: string): CustomFieldType => {
    switch (configId as MetadataGenericListType) {
        case MetadataGenericListType.CORE_USER:
            return CustomFieldType.USER_PICKER;
        case MetadataGenericListType.CORE_GROUP:
            return CustomFieldType.GROUP_PICKER;
        case MetadataGenericListType.FILE_PICKER:
            return CustomFieldType.FILE_PICKER;
        case MetadataGenericListType.POST_LINK:
            return CustomFieldType.POST_PICKER;
        case MetadataGenericListType.CATALOG:
            return CustomFieldType.CATALOG;
        default:
            console.warn(`Unhandled generic list's config id: ${configId}`);
            return CustomFieldType.USER_PICKER;
    }
};

const toListenChanges = (record: any): boolean => {
    const validations: IValidationMetadata[] = (record.validations || []).map(
        (v: any) => toValidationsMetadata(v),
    );
    const customFieldType = toCustomFieldType(record);
    const listenChangesFromBE = record.listenChanges ?? false;

    if (customFieldType !== CustomFieldType.FILE_PICKER) {
        return listenChangesFromBE;
    } else {
        return (
            listenChangesFromBE &&
            validations.some(
                (v) =>
                    v.validationType === ValidationType.MAX_LENGTH &&
                    v.parameter != null &&
                    Number(v.parameter) === 1,
            )
        );
    }
};
