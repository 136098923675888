import {
    AfterViewInit,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
} from '@angular/core';
import { startWith } from 'rxjs/operators';

@Directive({
    selector: '[interactaArrowKeysItem]',
    standalone: true,
})
export class ArrowKeysItemDirective {
    constructor(private ref: ElementRef) {}

    get nativeElement(): HTMLElement | undefined {
        return this.ref.nativeElement instanceof HTMLElement
            ? this.ref.nativeElement
            : undefined;
    }
}

@Directive({
    selector: '[interactaArrowKeysList]',
    standalone: true,
})
export class ArrowKeysListDirective implements OnChanges, AfterViewInit {
    @Input() selectedIndex!: number | null;
    @Input() moveWithArrows = true;

    @Output() itemClicked: EventEmitter<number> = new EventEmitter();
    @Output() changeSelectedIndex: EventEmitter<number> = new EventEmitter();

    @ContentChildren(ArrowKeysItemDirective, { descendants: true })
    arrowKeysItems?: QueryList<ArrowKeysItemDirective>;

    constructor(private elem: ElementRef) {}

    @HostListener('focus') onFocus(): void {
        if (this.selectedIndex == null) {
            this.indexChanged(0);
        }
    }

    @HostListener('blur') onBlur(): void {
        this.indexChanged(0);
    }

    @HostListener('document:keydown.arrowup', ['$event'])
    onArrowUpHandler(): void {
        if (this.moveWithArrows) this.move('up');
    }

    @HostListener('document:keydown.arrowdown', ['$event'])
    onArrowDownHandler(): void {
        if (this.moveWithArrows) this.move('down');
    }

    @HostListener('document:keydown.enter', ['$event']) onEnterHandler(): void {
        if (
            (this.selectedIndex === -1 || this.selectedIndex == null) &&
            this.arrowKeysItems?.length === 1
        ) {
            this.itemClicked.emit(0);
        }
        if (this.selectedIndex != null) {
            this.itemClicked.emit(this.selectedIndex);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['selectedIndex'] &&
            !changes['selectedIndex'].isFirstChange() &&
            (this.selectedIndex || this.selectedIndex === 0)
        ) {
            const selectedIndex = this.selectedIndex;

            setTimeout(
                () =>
                    this.scrollCardToItem(
                        selectedIndex,
                        changes['selectedIndex'].previousValue <= selectedIndex
                            ? 'down'
                            : 'up',
                    ),
                400,
            );
        }
    }

    ngAfterViewInit(): void {
        this.arrowKeysItems?.changes.pipe(startWith({})).subscribe();
    }

    private indexChanged(index: number): void {
        this.changeSelectedIndex.emit(index);
    }

    private getChildren(): HTMLElement[] {
        if (!this.arrowKeysItems) return [];

        return this.arrowKeysItems
            .map((c) => c.nativeElement)
            .filter((c) => c instanceof HTMLElement) as HTMLElement[];
    }

    private move(moveType: 'up' | 'down'): void {
        const items: HTMLElement[] = this.getChildren();
        const itemsCount = items.length;

        let i = 0;
        if (moveType === 'up') {
            if (!this.selectedIndex) {
                return;
            }
            i = this.selectedIndex - 1;
        }

        if (moveType === 'down') {
            if (this.selectedIndex === undefined && itemsCount > 0) {
                this.indexChanged(0);
                return;
            }
            if (this.selectedIndex === itemsCount - 1) {
                return;
            }
            i = (this.selectedIndex || 0) + 1;
        }
        this.indexChanged(i);
    }

    private scrollCardToItem(index: number, moveType: 'up' | 'down'): void {
        const cardScroll: HTMLElement = this.elem.nativeElement;
        const items: HTMLElement[] = this.getChildren();

        if (!items || items[index] == null) {
            return;
        }

        const item = items[index];

        if (!(item instanceof HTMLElement)) return;

        const itemBottom = Math.floor(item.offsetTop + item.offsetHeight);
        const scrollTop = Math.ceil(cardScroll.scrollTop);

        const itemTop = item.offsetTop;
        const scrollBottom = Math.floor(
            cardScroll.scrollTop + cardScroll.offsetHeight,
        );

        if (moveType === 'up' && itemBottom <= scrollTop) {
            item.scrollIntoView(true);
        }

        if (moveType === 'down' && itemTop >= scrollBottom) {
            item.scrollIntoView(false);
        }
    }
}
