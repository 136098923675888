<interacta-filter-collapse-wrapper
    [active]="!!control.value?.length"
    [collapsed]="collapsed"
    [label]="label"
    [pinnable]="pinnable"
    [pinned]="pinned"
    (toggleCollapsed)="toggleCollapsed.emit()"
    (togglePinned)="togglePinned.emit()"
>
    <p *ngIf="description" class="typo-sm text-text-mid-contrast mb-4">
        {{ description }}
    </p>
    <interacta-input-text-v2
        iconPosition="right"
        [control]="control"
        [icon]="
            control.value ? 'close' : iconSearch ? 'search-left' : undefined
        "
        [iconClickable]="control.value"
        [iconColor]="control.value ? 'black' : 'gray'"
        (iconClicked)="resetInput()"
        (keyup.enter)="enterPressed$.next()"
    ></interacta-input-text-v2>
</interacta-filter-collapse-wrapper>
