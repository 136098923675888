import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    Signal,
    SimpleChanges,
    ViewChild,
    computed,
    signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Member } from '@modules/core/models/member/member.model';
import {
    getMemberFullname,
    isMemberUser,
} from '@modules/core/models/member/member.utils';
import { debounceTime } from 'rxjs';

@Component({
    selector: 'interacta-member-avatar-selection',
    templateUrl: './member-avatar-selection.component.html',
    styles: [
        `
            .hoverable-avatar:hover {
                z-index: 10000 !important;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberAvatarSelectionComponent implements OnChanges {
    @Input({ required: true }) members!: Member[];
    @Input() selectedMembers: Member[] = [];
    @Input() visibleAvatars = 3;

    @Output() selectMembers = new EventEmitter<Member[]>();

    @ViewChild('searchInput')
    searchInput!: ElementRef;

    readonly SEARCH_THRESHOLD = 20;

    isOpen = false;
    searchControl = new FormControl<string>({ disabled: false, value: '' });
    anyHiddenSelected = signal(false);
    filter: Signal<string | null | undefined>;
    filteredMembers = computed(() => {
        if (this.filter()) {
            return this.members.filter(
                (m) =>
                    getMemberFullname(m)
                        .toLowerCase()
                        .indexOf(this.filter()?.toLowerCase() ?? '') >= 0,
            );
        } else return this.members;
    });

    isMemberUser = isMemberUser;
    getMemberFullname = getMemberFullname;

    constructor() {
        this.filter = toSignal(
            this.searchControl.valueChanges.pipe(debounceTime(300)),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.selectedMembers) {
            this.anyHiddenSelected.set(this.isAnyHiddenSelected());
        }
    }

    toggleMember(member: Member): void {
        if (this.isSelected({ member, selectedMembers: this.selectedMembers }))
            this.selectMembers.emit([
                ...this.selectedMembers.filter(
                    (m) => m.innerId !== member.innerId,
                ),
            ]);
        else this.selectMembers.emit([...this.selectedMembers, member]);
    }

    isSelected(data: { member: Member; selectedMembers: Member[] }): boolean {
        return data.selectedMembers.some(
            (m) => m.innerId === data.member.innerId,
        );
    }

    showMenu(): void {
        this.isOpen = true;
        requestAnimationFrame(() => {
            if (this.searchInput) this.searchInput.nativeElement.focus();
        });
    }

    closeMenu(): void {
        this.isOpen = false;
        this.searchControl.setValue(null, { emitEvent: false });
    }

    private isAnyHiddenSelected(): boolean {
        return this.members.some(
            (m, index) =>
                this.isSelected({
                    member: m,
                    selectedMembers: this.selectedMembers,
                }) && index >= this.visibleAvatars - 1,
        );
    }
}
