import { TranslateService } from '@ngx-translate/core';
import {
    InteractaBuildInfo,
    UserCredentialsValidFormat,
} from './environment-info.model';

export const isNewBuild = (
    previous: InteractaBuildInfo,
    current: InteractaBuildInfo,
): boolean => {
    return (
        !previous ||
        previous.backend.hash !== current.backend.hash ||
        previous.frontend.hash !== current.frontend.hash
    );
};

export const credentialFormatMessage = (
    format: UserCredentialsValidFormat,
    translateService: TranslateService,
): string => {
    let message = '';
    let notFirst = 0;
    if (
        format.minLength ||
        format.maxLength ||
        format.atLeastLowerCharsQuantity ||
        format.atLeastUpperCharsQuantity ||
        format.atLeastNumericCharsQuantity
    ) {
        message = translateService.instant(
            'VALIDATION_INTERACTA.LABEL_ERROR_PASSWORD_FORMAT',
        );
        if (format.minLength || format.maxLength) {
            message += translateService.instant(
                'VALIDATION_INTERACTA.LABEL_ERROR_PASSWORD_FORMAT_LENGTH',
                format,
            );
            notFirst++;
        }
        if (format.atLeastLowerCharsQuantity) {
            message += notFirst ? ', ' : '';
            message += translateService.instant(
                'VALIDATION_INTERACTA.LABEL_ERROR_PASSWORD_FORMAT_LOWERCASE',
                format,
            );
            notFirst++;
        }
        if (format.atLeastUpperCharsQuantity) {
            message += notFirst ? ', ' : '';
            message += translateService.instant(
                'VALIDATION_INTERACTA.LABEL_ERROR_PASSWORD_FORMAT_UPPERCASE',
                format,
            );
            notFirst++;
        }
        if (format.atLeastNumericCharsQuantity) {
            message += notFirst ? ', ' : '';
            message += translateService.instant(
                'VALIDATION_INTERACTA.LABEL_ERROR_PASSWORD_FORMAT_DIGITS',
                format,
            );
        }
    }
    return message;
};
