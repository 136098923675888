import { ConnectedPosition } from '@angular/cdk/overlay';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ErrorService } from '@interacta-shared/data-access-error';
import {
    Appearance,
    ButtonComponent,
    ChipButtonV2Component,
    InputSelectSearchEvent,
    Palette,
    ScrollTrackerEvent,
    Size,
    emptyInputSelectSearchEvent,
} from '@interacta-shared/ui';
import {
    PageTokenInfo,
    PaginatedList,
    emptyPaginatedList,
    getNextPageToken,
} from '@interacta-shared/util';
import { MetadataFieldModeType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { Member } from '@modules/core/models/member/member.model';
import { equals } from '@modules/core/models/member/member.utils';
import { MemberPickerType } from '@modules/shared-v2/components/input-member-picker/input-member-picker.component';
import { MemberService } from '@modules/shared-v2/services/member.service';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    finalize,
    map,
    switchMap,
    takeUntil,
} from 'rxjs/operators';

@Component({
    selector: 'interacta-member-picker',
    templateUrl: './member-picker.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MemberService],
})
export class MemberPickerComponent
    implements OnChanges, OnInit, OnDestroy, AfterViewInit
{
    @Input({ required: true }) label!: string;
    @Input({ required: true }) type!: MemberPickerType;
    @Input({ required: true }) communityIds!: number[];
    @Input() workspaceId?: number;
    @Input() fieldId?: number;
    @Input() selectedMembers: Member[] = [];
    @Input() fieldMode: MetadataFieldModeType = MetadataFieldModeType.POST;
    @Input() dynamic = false;
    @Input() postId?: number;
    @Input() size: Extract<Size, 'regular' | 'small'> = 'regular';
    @Input() appearance: Extract<Appearance, 'fill' | 'ghost'> = 'fill';
    @Input() bgColor: Extract<Palette, 'surface-A' | 'surface-B'> = 'surface-A';
    @Input() active = false;
    @Input() buttonType: 'chip' | 'button' | 'select' = 'chip';
    @Input() useButtonWidth = true;
    @Input() menuPositions: ConnectedPosition[] | null = null;

    @ViewChild(ChipButtonV2Component)
    private chipButton?: ChipButtonV2Component;

    @ViewChild(ButtonComponent)
    private button?: ButtonComponent;

    @ViewChild('searchInput', { static: true })
    searchInput!: ElementRef;

    showMembersList = false;
    searchControl = new UntypedFormControl();
    members$ = new BehaviorSubject<PaginatedList<Member>>(
        emptyPaginatedList(0),
    );
    filteredList: Member[] = [];
    isFetching = false;
    menuWidth?: number;
    showSearchPlaceholders$ = new BehaviorSubject(true);

    private filter$ = new Subject<InputSelectSearchEvent>();
    private destroy$ = new Subject<void>();
    private init$ = new Subject<void>();

    @Output() selectMember = new EventEmitter<Member>();

    constructor(
        private memberService: MemberService,
        private errorService: ErrorService,
    ) {}

    closeList(): void {
        this.showMembersList = false;
        this.searchControl.setValue(null, { emitEvent: false });
        this.showSearchPlaceholders$.next(true);
    }

    nextPagination(nextPageToken: PageTokenInfo): void {
        this.isFetching = true;
        this.filter$.next({
            text: this.searchControl.value,
            nextPageToken: getNextPageToken(nextPageToken),
        });
    }

    scroll($event: ScrollTrackerEvent, nextPageToken: PageTokenInfo): void {
        if ($event.endReached && nextPageToken.tag !== 'lastLoading') {
            this.nextPagination(nextPageToken);
        }
    }

    search(filter: InputSelectSearchEvent): void {
        this.filter$.next(filter);
    }

    searchApi(
        filter: InputSelectSearchEvent,
    ): Observable<PaginatedList<Member>> {
        return this.memberService.search(
            filter,
            this.type,
            this.postId ?? null,
            this.communityIds,
            this.fieldMode,
            this.fieldId ?? null,
            this.dynamic,
            this.workspaceId ?? null,
        );
    }

    showMembersMenu(): void {
        this.showMembersList = true;
        setTimeout(() => this.searchInput.nativeElement.focus(), 200);
        this.filter$.next(emptyInputSelectSearchEvent());
    }

    setMenuWidth(width: number): void {
        if (this.useButtonWidth) {
            this.menuWidth = width;
        }
    }

    private initMemberPicker(): void {
        this.init$.next();
    }

    ngAfterViewInit(): void {
        if (this.useButtonWidth) {
            const trigger = this.chipButton ?? this.button;
            if (trigger) {
                this.menuWidth = trigger.elementRef.nativeElement.offsetWidth;
            }
        }
    }

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe((text) =>
                this.filter$.next({ text, nextPageToken: null }),
            );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.fieldId ||
            changes.communityIds ||
            changes.selectedMembers
        ) {
            this.initMemberPicker();
            this.filter$
                .pipe(
                    finalize(() => (this.isFetching = false)),
                    switchMap((filter) =>
                        this.searchApi(filter).pipe(
                            map((members) => ({ members, filter })),
                            catchError((error) => {
                                this.errorService.handle(error);
                                return EMPTY;
                            }),
                        ),
                    ),
                    takeUntil(this.destroy$),
                    takeUntil(this.init$),
                )
                .subscribe(({ members, filter }) => {
                    const currentList = this.members$.value;
                    const newList: PaginatedList<Member> = {
                        ...members,
                        list:
                            filter.nextPageToken != null
                                ? [...currentList.list, ...members.list]
                                : [...members.list],
                    };
                    const filteredList =
                        this.selectedMembers.length > 0
                            ? (newList.list || []).filter((member) => {
                                  const found = this.selectedMembers.find(
                                      (selected) => equals(selected, member),
                                  );
                                  if (found) {
                                      return !found;
                                  } else {
                                      return member;
                                  }
                              })
                            : newList.list;
                    //check that filtered list is empty, if it's empty a new pagination is required
                    if (
                        filteredList.length ||
                        members.nextPageToken.tag === 'lastLoading'
                    ) {
                        this.members$.next({
                            ...newList,
                            list: filteredList,
                        });
                        this.showSearchPlaceholders$.next(false);
                    } else {
                        this.filter$.next({
                            text: this.searchControl.value,
                            nextPageToken: getNextPageToken(
                                members.nextPageToken,
                            ),
                        });
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
