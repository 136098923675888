import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { i18nString } from '@modules/core';
import { getLabelServerTranslation } from '@modules/core/helpers/generic.utils';

@Pipe({
    name: 'currentLanguage',
})
export class CurrentLanguagePipe implements PipeTransform {
    private currentLanguage: string;

    constructor(private configurationService: ConfigurationService) {
        this.currentLanguage =
            this.configurationService.getCurrentLanguage().code;
    }

    transform(value: i18nString): string {
        return (
            getLabelServerTranslation(
                value,
                this.currentLanguage,
                this.configurationService.getDefaultInfo().systemLanguage?.code,
            ) ?? ''
        );
    }
}
