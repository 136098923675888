import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { sampleTime, takeUntil } from 'rxjs/operators';
import { ScrollTrackerEvent } from '../model';
import { ScrollTrakerService } from '../services';

@Directive({
    selector: '[interactaScrollTracker]',
    standalone: true,
})
export class ScrollTrackerDirective implements OnInit, OnDestroy, OnChanges {
    @Output() scrolled = new EventEmitter<ScrollTrackerEvent>();
    @Input() scrollToTop = false;
    @Input() scrollPosition?: number;
    @Input() scrollTrackerId?: string;
    @Input() scrollOffsetPx = 0;

    readonly threshold = 75;

    private destroy$ = new Subject<void>();

    constructor(
        private element: ElementRef,
        private scrollTrakerService: ScrollTrakerService,
    ) {}

    private _detectTopPosition(tracker: HTMLElement) {
        const { scrollTop, scrollHeight, offsetHeight } = tracker;
        const scrollPercentage =
            100 - (scrollTop / (scrollHeight - offsetHeight)) * 100;
        const endReached = Math.ceil(scrollTop) <= 10;
        const offsetReached = scrollTop <= this.scrollOffsetPx;

        this.scrolled.emit({
            pos: scrollTop,
            endReached,
            thresholdReached: scrollPercentage > this.threshold || endReached,
            offsetReached,
        });
    }

    private _detectBottomPosition(tracker: HTMLElement) {
        const { scrollTop, scrollHeight, offsetHeight, clientHeight } = tracker;
        const scrollPercentage =
            (scrollTop / (scrollHeight - offsetHeight)) * 100;
        const delta = clientHeight * 0.3;
        const endReached =
            scrollTop !== 0 && scrollTop + clientHeight >= scrollHeight - delta;

        const offsetReached =
            scrollTop + offsetHeight >= scrollHeight - this.scrollOffsetPx;

        this.scrolled.emit({
            pos: scrollTop,
            thresholdReached: scrollPercentage > this.threshold || endReached,
            endReached,
            offsetReached,
        });
    }

    ngOnInit(): void {
        const scrollElement = this.element.nativeElement;

        fromEvent(scrollElement, 'scroll')
            .pipe(sampleTime(300), takeUntil(this.destroy$))
            .subscribe(() => {
                if (this.scrollToTop) this._detectTopPosition(scrollElement);
                else this._detectBottomPosition(scrollElement);
            });

        if (this.scrollTrackerId) {
            this.scrollTrakerService
                .scrollToTop$(this.scrollTrackerId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.element.nativeElement.scrollTop = 0;
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['scrollPosition'] &&
            !changes['scrollPosition'].isFirstChange()
        ) {
            // this.element.nativeElement.scrollTo({ top: changes.scrollPosition.currentValue, behavior: 'smooth' });
            setTimeout(
                () =>
                    (this.element.nativeElement.scrollTop =
                        changes['scrollPosition'].currentValue),
            );
        } else if (
            changes['scrollPosition'] &&
            changes['scrollPosition'].isFirstChange() &&
            changes['scrollToTop'] &&
            changes['scrollToTop'].currentValue
        ) {
            setTimeout(
                () =>
                    (this.element.nativeElement.scrollTop =
                        this.element.nativeElement.scrollHeight),
            );
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
