import { FetchPostError, FetchPostErrorType } from './post-error.model';

export function isFetchPostError(error: any): error is FetchPostError {
    return 'postId' in error;
}

export function buildFetchPostError(
    postId: number | null,
    type: FetchPostErrorType,
    error?: any,
): FetchPostError {
    return {
        postId,
        type,
        error,
    };
}

export function getFetchPostErrorType(
    errorStatus?: number,
): FetchPostErrorType {
    switch (errorStatus) {
        case 403:
            return 'notAvailable';
        case 404:
            return 'notFound';
        default:
            return 'other';
    }
}
