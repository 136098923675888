import {
    MentionType,
    hiddendDenotationChars,
    mentionTypeKeys,
    mentionTypes,
} from '@modules/mentions';
import { DeltaUtility } from './delta-utility.class';

// @ts-ignore
import Delta from 'quill-delta/lib/delta';

export class Server2Delta {
    public process(input: string | null): Delta | null {
        if (!input) return null;
        try {
            const obj: Delta[] = JSON.parse(input);
            if (DeltaUtility.isEmpty(obj)) return null;

            const output = this.mentions(obj);
            return { ops: output };
        } catch (error) {
            console.error({
                message: 'server 2 delta error',
                input: input,
                error: error,
            });
        }
        return null;
    }

    private mentions(delta: Delta[]): Delta[] {
        return delta.map((element: Record<string, any>) => {
            const key = Object.keys(element).find((key) =>
                DeltaUtility.opsDeltaOperations.includes(key),
            );
            let extracts = null;
            const link: string | null = element.attributes?.link ?? null;
            const reg =
                /interacta:((user|group|hashtag):([0-9]+):?([0-9]*))|(table):([0-9a-zA-Z_-]+):?([0-9]*)/g;
            if (key && link && (extracts = reg.exec(link))) {
                // i.e. {link: "interacta:user:5"}
                let mentionId: number | undefined = undefined;
                let instanceId: number | undefined = undefined;
                let clientUid: string | undefined = undefined;
                const mentionType: MentionType | undefined = mentionTypes.find(
                    (mention) => link.includes(mention),
                );
                if (mentionType) {
                    if (extracts?.length) {
                        switch (mentionType) {
                            case 'user':
                            case 'group':
                            case 'hashtag':
                                mentionId = +extracts[3];
                                instanceId = extracts[4]
                                    ? +extracts[4]
                                    : undefined;
                                break;
                            case 'table':
                                clientUid = extracts[6];
                                mentionId = extracts[7]
                                    ? +extracts[7]
                                    : undefined;
                        }
                    }
                    const denotationChar =
                        this.getDenotationCharAsString(mentionType);

                    const mention: any = {
                        denotationChar,
                        value: this.buildMentionValue(
                            element[key],
                            denotationChar,
                        ),
                        mentionType,
                    };
                    if (mentionId) {
                        mention.id = mentionId;
                    }
                    if (instanceId) {
                        mention.instanceId = instanceId;
                    }
                    if (clientUid) {
                        mention.clientUid = clientUid;
                    }

                    const mentionElement = {
                        ...element,
                        [key]: {
                            mention,
                        },
                    };
                    delete mentionElement.attributes;
                    return mentionElement;
                }
            }
            return element;
        });
    }

    private getDenotationCharAsString(mentionType: MentionType): string {
        const denotationChar = mentionTypeKeys[mentionType];
        return hiddendDenotationChars.includes(denotationChar)
            ? ''
            : denotationChar;
    }

    private buildMentionValue(
        originalValue: string,
        denotationChar: string,
    ): string {
        return originalValue.substring(
            denotationChar.length,
            originalValue.length,
        );
    }
}
