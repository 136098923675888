import { AdminCapabilities } from '@modules/core';
import { SearchMemberOrderType } from '../user-autocomplete.model';

/**
 * This file represents the following DTOs:
 * ListSystemUsersRequestDTO
 *
 */

export interface BaseApiV2AdminFilterRequest {
    pageToken?: string;
    pageSize: number;
    calculateTotalItemsCount: boolean;
    fullTextFilter: string | null;
    /**
     * statusFilter [0] means only Active items
     * statusFilter [1] means only disabled items
     * status null means that we don't want to filter based on status
     */
    statusFilter: number[] | null;
    orderTypeId: SearchMemberOrderType;
    orderDesc: boolean;
    workspaceIds?: number[];
    adminCapabilities?: Extract<
        AdminCapabilities,
        'manageUsers' | 'manageDigitalWorkplace'
    >[];
}

export type ApiV2AdminGroupsFiltersRequest = BaseApiV2AdminFilterRequest;
export type ApiV2AdminUsersFiltersRequest = BaseApiV2AdminFilterRequest;

export const getDefaultBaseApiV2AdminFilterRequest =
    (): BaseApiV2AdminFilterRequest => {
        return {
            pageSize: 15,
            calculateTotalItemsCount: false,
            fullTextFilter: null,
            statusFilter: [0],
            orderTypeId: SearchMemberOrderType.NAME,
            orderDesc: false,
        };
    };
