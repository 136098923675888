import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import {
    attachmentsFiles,
    attachmentsMedia,
} from '@modules/post/models/attachment/attachment.utils';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'interacta-attachments-counter',
    templateUrl: './attachments-counter.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsCounterComponent implements OnChanges {
    @Input() attachments: IAttachment[] = [];

    medias$ = new BehaviorSubject<IAttachment[]>([]);
    documents$ = new BehaviorSubject<IAttachment[]>([]);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.attachments) {
            this.medias$.next(attachmentsMedia(this.attachments));
            this.documents$.next(attachmentsFiles(this.attachments));
        }
    }
}
