<ng-container *ngIf="{ roles: roles$ | async } as $">
    <interacta-form-field-v2
        *ngIf="$.roles"
        [control]="control"
        [description]="description"
        [name]="name"
        [showError]="showError"
    >
        <interacta-input-generic-select
            [chipSize]="'small'"
            [control]="control"
            [isReadonly]="isReadonly"
            [items]="$.roles"
            [maxSelectableItems]="maxSelectableItems"
            [noResultLabel]="'UI.NO_RESULT' | translate"
            [searchItemTemplate]="searchItemTemplate"
            [selectedItemTemplate]="selectedItemTemplate"
            (search)="loadRoles($event)"
        >
        </interacta-input-generic-select>
    </interacta-form-field-v2>
</ng-container>

<ng-template #searchItemTemplate let-item="item">
    <div class="typo-sm line-clamp-1 break-all py-4" [matTooltip]="item.label">
        {{ item.label }}
    </div>
</ng-template>
<ng-template #selectedItemTemplate let-item="item">
    <div
        class="typo-sm line-clamp-1 break-all"
        [matTooltip]="item.label"
        [ngClass]="{
            'line-through': item.deleted
        }"
    >
        {{ item.label }}
    </div>
</ng-template>
