import { IAdminHashtag, IHashTag } from './hashtag.model';

export const toHashtag = (record: any): IHashTag => ({
    id: record.id,
    name: record.name,
    deleted: record.deleted,
    communityId: record.communityId,
    followedByMe: record.followedByMe || false,
    instancesCount: record.instancesCount ?? undefined,
});

export const toAdminHashtag = (record: any): IAdminHashtag => ({
    id: record.id,
    name: record.name,
    deleted: record.deleted,
    communityId: record.communityId,
    externalId: record.externalId,
});
