import { Index } from '@interacta-shared/util';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { createAction, props } from '@ngrx/store';
import { Delta } from 'quill/core';
import {
    AIContentGenerationType,
    AIContextType,
    AIFeedbackDetailType,
    AIToneOfVoiceType,
    AttachmentsListType,
} from '../models/ai.model';

enum AIActionType {
    SendFeedback = '[AI] Send Feedback ',
    SendFeedbackDetail = '[AI] Send Feedback Detail',
    SendGeneratedContentFeedback = '[AI] Send Generated Content Feedback ',
    SetAlreadyAnimated = '[AI] Set Already Animated',
    SetPostId = '[AI] Set Post Id',
    GetResponseFromAI = '[AI] Get Response From AI',
    StartNewConversation = '[AI] Start New Conversation',
    SaveDraftQuestion = '[AI] Save Draft Question',
    SetActiveContext = '[AI] Set Active Context',
    SwitchActiveContxt = '[AI] Switch Active Context',
    SetActiveThreadInActiveContext = '[AI] Set Active Thread In Active Context',
    AddThreadInActiveContext = '[AI] Add Thread In Active Context',
    GetAttachmentsDetail = '[AI] Get Attachments Detail',
    CacheAttachmentsDetail = '[AI] Cache Attachments Detail',
    ToggleAIPanel = '[AI] Toggle AI Panel',
    SetIsAIPanelAnimating = '[AI] Set Is AI Panel Animating',
    FetchAttachmentList = '[AI] Fetch Attachment List',
    OpenGuide = '[AI] Open Guide',
    OpenContentGeneration = '[AI] Open Content Generation',
    SetGeneratedContentInActiveContext = '[AI] Set Generated Content In Active Context',
    FetchContentGeneration = '[AI] Fetch Content Generation',
}

const setActiveContext = createAction(
    AIActionType.SetActiveContext,
    props<{
        contextId: number | null;
        contextType: AIContextType;
        workflowEnabled: boolean;
        threadId?: Index;
        initialContentGeneration?: Delta | null;
    }>(),
);

const switchActiveContext = createAction(
    AIActionType.SwitchActiveContxt,
    props<{
        contextType: AIContextType;
    }>(),
);

const setActiveThreadInActiveContext = createAction(
    AIActionType.SetActiveThreadInActiveContext,
    props<{
        threadId: Index;
    }>(),
);

const addThreadInActiveContext = createAction(
    AIActionType.AddThreadInActiveContext,
    props<{
        threadId: Index;
        attachment?: IAttachment;
        requestMessage: string;
    }>(),
);

const sendFeedback = createAction(
    AIActionType.SendFeedback,
    props<{
        key: string;
        messageId: number;
        like: boolean | undefined;
        contextType: AIContextType;
        threadId?: Index;
    }>(),
);

const sendFeedbackDetail = createAction(
    AIActionType.SendFeedbackDetail,
    props<{
        key: string;
        messageId: number;
        like: boolean | undefined;
        feedbacks: AIFeedbackDetailType[] | undefined;
        text: string | undefined;
        contextType: AIContextType;
        threadId?: Index;
    }>(),
);

const setAlreadyAnimated = createAction(
    AIActionType.SetAlreadyAnimated,
    props<{ clientId: string; contextType: AIContextType; threadId: Index }>(),
);

const getResponseFromAI = createAction(
    AIActionType.GetResponseFromAI,
    props<{
        clientId: string;
        timestamp: Date;
        contextId: number;
        question: string;
        sessionId: number | null;
        contextType: AIContextType;
        threadId?: Index;
        attachmentId?: Index;
    }>(),
);

const startNewConversation = createAction(
    AIActionType.StartNewConversation,
    props<{
        contextId: number;
        contextType: AIContextType;
        requestMessage: string;
        responseMessage: string;
    }>(),
);

const saveDraftQuestion = createAction(
    AIActionType.SaveDraftQuestion,
    props<{
        contextId: number;
        contextType: AIContextType;
        draftQuestion: string;
    }>(),
);

const getAttachmentDetail = createAction(
    AIActionType.GetAttachmentsDetail,
    props<{
        id: number;
    }>(),
);

const cacheAttachmentDetail = createAction(
    AIActionType.CacheAttachmentsDetail,
    props<{
        attachments: IAttachment[];
    }>(),
);

const toggleAIPanel = createAction(
    AIActionType.ToggleAIPanel,
    props<{ isOpen: boolean }>(),
);

const setIsPanelAnimating = createAction(
    AIActionType.SetIsAIPanelAnimating,
    props<{ isAnimating: boolean }>(),
);

const openGuide = createAction(AIActionType.OpenGuide);

const fetchAttachmentsList = createAction(
    AIActionType.FetchAttachmentList,
    props<{
        postId: number;
        entityType: AttachmentsListType;
        pageToken: string | undefined;
        initial?: boolean;
    }>(),
);

const openContentGeneration = createAction(
    AIActionType.OpenContentGeneration,
    props<{ contextId: number; initialContent: Delta | undefined | null }>(),
);

const setGeneratedContentInActiveContext = createAction(
    AIActionType.SetGeneratedContentInActiveContext,
    props<{ generatedContent: Delta | undefined | null }>(),
);

const fetchContentGeneration = createAction(
    AIActionType.FetchContentGeneration,
    props<{
        initialContent: Delta | null;
        contentGenerationType: AIContentGenerationType;
    }>(),
);

const sendGeneratedContentFeedback = createAction(
    AIActionType.SendGeneratedContentFeedback,
    props<{
        feedbackId: number | null;
        sourceContent: Delta | null;
        generatedContent: Delta | null;
        contentGeneratorType: AIContentGenerationType;
        toneType: AIToneOfVoiceType | null;
        like: boolean | undefined;
        feedbacks: AIFeedbackDetailType[] | undefined;
        text: string | undefined;
        contextType: AIContextType;
        confirmed: boolean | undefined;
    }>(),
);

export const AIActions = {
    sendFeedback,
    sendFeedbackDetail,
    setAlreadyAnimated,
    getResponseFromAI,
    startNewConversation,
    saveDraftQuestion,
    setActiveContext,
    switchActiveContext,
    setActiveThreadInActiveContext,
    addThreadInActiveContext,
    getAttachmentDetail,
    toggleAIPanel,
    setIsPanelAnimating,
    cacheAttachmentDetail,
    fetchAttachmentsList,
    openGuide,
    openContentGeneration,
    setGeneratedContentInActiveContext,
    fetchContentGeneration,
    sendGeneratedContentFeedback,
};
