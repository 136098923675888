import {
    adminV2UserGroups,
    adminV2UserOrganization,
    adminV2UserOverview,
    adminV2UserPrivacy,
    adminV2UserProfile,
    adminV2UserRoles,
} from '@modules/admin-v2-routing/admin-v2-routes';
import {
    AdminV2BreadcrumbItem,
    AdminV2ColumnVisibility,
    AdminV2MenuItem,
} from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import { adminV2User } from '@modules/app-routing/routes';
import {
    AdminV2UserFiltersLabel,
    AdminV2UserLoginFilterLabel,
    AdminV2UserLoginProvider,
    AdminV2UserRow,
    AdminV2UserSort,
    AdminV2UserStatusFilter,
    AdminV2UserStatusFilterLabel,
} from '../models/admin-v2-user.model';

export const DefaultColumnVisibility: AdminV2ColumnVisibility<AdminV2UserRow> =
    {
        user: true,
        contactEmail: true,
        businessUnit: true,
        area: true,
        lastAccess: true,
        externalId: false,
        creation: false,
        timezone: false,
        language: false,
        place: false,
        manager: false,
        accountTypes: false,
    };

export const DefaultSortOrder: AdminV2UserSort = {
    sortColumn: 'user',
    orderDesc: false,
};

export const DefaultUserStatusFilter = AdminV2UserStatusFilter.ACTIVE_USERS;

const filtersLabelRoot = 'ADMIN_V2.USERS.FILTERS';
export const UserStatusFilterLabels: AdminV2UserStatusFilterLabel[] = [
    {
        label: `${filtersLabelRoot}.ACTIVE_USERS`,
        value: AdminV2UserStatusFilter.ACTIVE_USERS,
    },
    {
        label: `${filtersLabelRoot}.BLOCKED_USERS`,
        value: AdminV2UserStatusFilter.BLOCKED_USERS,
    },
    {
        label: `${filtersLabelRoot}.DELETED_USERS`,
        value: AdminV2UserStatusFilter.DELETED_USERS,
    },
    {
        label: `${filtersLabelRoot}.ALL_USERS`,
        value: AdminV2UserStatusFilter.ALL_USERS,
    },
];

export const LoginFilterLabels: AdminV2UserLoginFilterLabel[] = [
    {
        label: `${filtersLabelRoot}.GOOGLE`,
        value: AdminV2UserLoginProvider.GOOGLE,
    },
    {
        label: `${filtersLabelRoot}.MICROSOFT`,
        value: AdminV2UserLoginProvider.MICROSOFT,
    },
    {
        label: `${filtersLabelRoot}.INTERACTA`,
        value: AdminV2UserLoginProvider.INTERACTA,
    },
    {
        label: `${filtersLabelRoot}.SPID`,
        value: AdminV2UserLoginProvider.SPID,
    },
];

export const UserFiltersLabels: AdminV2UserFiltersLabel = {
    [AdminV2UserStatusFilter.ACTIVE_USERS]: `${filtersLabelRoot}.ACTIVE_USERS`,
    [AdminV2UserStatusFilter.BLOCKED_USERS]: `${filtersLabelRoot}.BLOCKED_USERS`,
    [AdminV2UserStatusFilter.DELETED_USERS]: `${filtersLabelRoot}.DELETED_USERS`,
    [AdminV2UserStatusFilter.ALL_USERS]: `${filtersLabelRoot}.ALL_USERS`,
    search: `${filtersLabelRoot}.SEARCH`,
    areas: `${filtersLabelRoot}.AREA`,
    businessUnits: `${filtersLabelRoot}.BUSINESS_UNIT`,
    personalEmail: `${filtersLabelRoot}.EMAIL`,
    created: `${filtersLabelRoot}.CREATED`,
    externalId: `${filtersLabelRoot}.EXTERNAL_REFERENCE`,
    languages: `${filtersLabelRoot}.LANGUAGE`,
    lastAccess: `${filtersLabelRoot}.LAST_ACCESS`,
    managers: `${filtersLabelRoot}.MANAGER`,
    peopleSection: `${filtersLabelRoot}.PRESENT_IN_PEOPLE_SECTION`,
    place: `${filtersLabelRoot}.PLACE`,
    loginProviders: `${filtersLabelRoot}.LOGIN_TYPE`,
    reducedProfile: `${filtersLabelRoot}.PROFILE_TYPE`,
    role: `${filtersLabelRoot}.ROLE`,
    status: `${filtersLabelRoot}.STATUS`,
    timezones: `${filtersLabelRoot}.TIME_ZONE`,
    viewUserProfiles: `${filtersLabelRoot}.VIEW_OTHER_PROFILES`,
    visibleInPeople: `${filtersLabelRoot}.PEOPLE_SECTION`,
    twoStepAuthenticationEnabled: `${filtersLabelRoot}.TWO_STEP_AUTH`,
    excludeUsersByMemberGroupId: '',
    excludeByAreaId: '',
    excludeByBusinessUnitId: '',
    workspaceIds: '',
    checkAreaMember: '',
    checkBusinessUnitMember: '',
    checkGroupMember: '',
};

const breadcrumbLabelRoot = 'ADMIN_V2.USERS.DETAIL.BREADCRUMB';
export const UserBreadcrumbItems: AdminV2BreadcrumbItem[] = [
    {
        label: `${breadcrumbLabelRoot}.USERS`,
        url: adminV2User,
    },
    {
        label: `${breadcrumbLabelRoot}.MANAGE_USER`,
    },
];

const menuLabelRoot = 'ADMIN_V2.USERS.DETAIL.MENU';
export const UserMenuItems: AdminV2MenuItem[] = [
    {
        label: `${menuLabelRoot}.OVERVIEW`,
        url: adminV2UserOverview,
    },
    {
        label: `${menuLabelRoot}.PROFILE`,
        url: adminV2UserProfile,
    },
    {
        label: `${menuLabelRoot}.ORGANIZATION`,
        url: adminV2UserOrganization,
    },
    {
        label: `${menuLabelRoot}.PRIVACY`,
        url: adminV2UserPrivacy,
    },
    {
        label: `${menuLabelRoot}.GROUPS`,
        url: adminV2UserGroups,
    },
    {
        label: `${menuLabelRoot}.ROLES`,
        url: adminV2UserRoles,
    },
];
