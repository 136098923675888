<ng-container
    *ngIf="{
        controlValue: control | formControlValueV2$ | async,
        controlState: control | inputState: isReadonly | async,
        textSearchValue: textSearchControl | formControlValueV2$ | async,
        textSearchState: textSearchControl | inputState: isReadonly | async
    } as $"
>
    @if (mode === 'radio') {
        <interacta-radio-group
            [formControl]="control"
            [orientation]="orientation"
        >
            <div
                *ngFor="let item of _items.list; trackBy: trackItem; index as i"
                class="flex flex-col gap-8"
                [ngClass]="{ ' flex-1/2': orientation === 'horizontal' }"
            >
                <interacta-radio-button
                    [disabled]="$.controlState?.disabled ?? false"
                    [value]="item.id"
                >
                    <div class="typo-sm flex items-center space-x-12">
                        <ng-container *ngIf="customLabelTemplate">
                            <ng-template
                                [ngTemplateOutlet]="customLabelTemplate"
                                [ngTemplateOutletContext]="{ item: item }"
                            />
                        </ng-container>

                        <span
                            *ngIf="!customLabelTemplate"
                            [ngClass]="{
                                italic: item.id === EMPTY_SEARCH_VALUE
                            }"
                            >{{ item.label | translate }}</span
                        >
                        <button
                            *ngIf="
                                canClearSelection &&
                                ({ item, controlValue: $.controlValue }
                                    | apply: isSelected)
                            "
                            interacta-icon-button
                            type="button"
                            [icon]="'close'"
                            [size]="'extra-small'"
                            (click)="$event.preventDefault(); clearSelection()"
                        ></button>
                    </div>
                </interacta-radio-button>
                <interacta-separator
                    *ngIf="
                        _items.list.length > 1 &&
                        quickItems &&
                        i === quickItems.length - 1
                    "
                />
            </div>
        </interacta-radio-group>
    }

    @if (mode === 'list') {
        <interacta-input-text-v2
            #openMenuBtn="cdkOverlayOrigin"
            cdkOverlayOrigin
            class="block"
            [bgColor]="
                $.controlValue !== null && !$.controlState?.disabled
                    ? 'bg-card'
                    : 'default'
            "
            [control]="textSearchControl"
            [icon]="
                $.controlValue !== null && canClearSelection
                    ? 'close'
                    : 'chevron-big-down'
            "
            [iconClickable]="!$.controlState?.disabled"
            [iconColor]="'gray'"
            [iconPosition]="'right'"
            [inputClickable]="!$.controlState?.disabled"
            [isReadonly]="
                !!$.controlState?.disabled ||
                ($.controlValue !== null && !isOpen)
            "
            [label]="label"
            [setFocus]="isOpen"
            (click)="!$.controlState?.disabled && openMenu()"
            (iconClicked)="iconClicked()"
            (inputFocus)="openMenu()"
        />

        <interacta-menu
            positionX="after"
            positionY="below"
            [open]="isOpen"
            [origin]="openMenuBtn"
            (closing)="isOpen && closeMenu()"
        >
            <interacta-menu-decorator
                class="block"
                [style.width.px]="
                    openMenuBtn.elementRef.nativeElement?.clientWidth
                "
            >
                <div
                    #scroll
                    class="overflow-y-auto"
                    interactaArrowKeysList
                    [moveWithArrows]="
                        _items.totalCount < VIRTUAL_SCROLL_THRESHOLD
                    "
                    [ngClass]="{
                        'max-h-224':
                            _items.totalCount < VIRTUAL_SCROLL_THRESHOLD,
                        'h-282': _items.totalCount >= VIRTUAL_SCROLL_THRESHOLD
                    }"
                    [selectedIndex]="isOpen ? currentIndex : 0"
                    (changeSelectedIndex)="isOpen && (currentIndex = $event)"
                    (itemClicked)="
                        isOpen && itemSelected(_items.list[$event], true)
                    "
                >
                    <div *ngIf="_items.list.length">
                        <ng-container
                            *ngIf="_items.totalCount < VIRTUAL_SCROLL_THRESHOLD"
                        >
                            <div
                                *ngFor="
                                    let item of _items.list;
                                    trackBy: trackItem;
                                    let idx = index
                                "
                                class="typo-medium hover:bg-surface-B border-border-300 h-auto cursor-pointer border-b px-12 py-14"
                                interactaArrowKeysItem
                                [ngClass]="{
                                    'bg-surface-300': currentIndex === idx,
                                    italic: item.id === EMPTY_SEARCH_VALUE
                                }"
                                (click)="itemSelected(item, true)"
                            >
                                {{ item.label }}
                            </div>
                        </ng-container>
                        <virtual-scroller
                            *ngIf="
                                _items.totalCount >= VIRTUAL_SCROLL_THRESHOLD
                            "
                            [enableUnequalChildrenSizes]="false"
                            [items]="_items.list"
                            [parentScroll]="scroll"
                            (vsUpdate)="displayedItems = $event"
                        >
                            <div
                                *ngFor="
                                    let item of displayedItems;
                                    trackBy: trackItem;
                                    let idx = index
                                "
                                class="typo-medium hover:bg-surface-B border-border-300 h-auto cursor-pointer border-b px-12 py-14 text-left"
                                interactaArrowKeysItem
                                [ngClass]="{
                                    'bg-surface-300': currentIndex === idx,
                                    italic: item.id === EMPTY_SEARCH_VALUE
                                }"
                                (click)="itemSelected(item, true)"
                            >
                                {{ item.label }}
                            </div>
                        </virtual-scroller>
                    </div>

                    <interacta-load-more
                        *ngIf="_items.nextPageToken.tag !== 'lastLoading'"
                        class="flex items-center justify-center py-8"
                        size="regular"
                        [isLoading]="_items.isFetching"
                        [label]="'UI.SHOW_MORE_UNCOUNT' | translate"
                    />
                    <div
                        *ngIf="!_items.isFetching && !_items.list.length"
                        class="typo-medium h-56 px-12 py-14"
                    >
                        {{ 'UI.NO_RESULT' | translate }}
                    </div>
                </div>
            </interacta-menu-decorator>
        </interacta-menu>
    }

    @if (mode === 'visual' && imagesSize) {
        <interacta-radio-group
            [formControl]="control"
            [horizontalSpacing]="imagesSize === 'small' ? 'regular' : 'large'"
            [orientation]="'horizontal'"
            [verticalSpacing]="imagesSize === 'small' ? 'regular' : 'large'"
        >
            <div
                *ngFor="let item of _items.list; trackBy: trackItem; index as i"
                class="flex flex-col"
                [ngClass]="{
                    'w-250 typo-sm gap-y-8': imagesSize === 'regular',
                    'w-180 typo-xs gap-y-4': imagesSize === 'small'
                }"
            >
                <interacta-image-preview
                    [active]="
                        { item, controlValue: control.value }
                            | apply: isSelected
                    "
                    [alt]="item.label"
                    [disabled]="$.controlState?.disabled ?? false"
                    [iconButton]="{
                        icon: 'fullscreen',
                        label: 'UI.VISUAL_SELECT.OPEN_IMAGE'
                    }"
                    [imageSize]="imagesSize"
                    [imageSrc]="
                        item.temporaryContentPreviewImageHiResLink ??
                        item.temporaryContentViewLink
                    "
                    (iconClicked)="openFullscreen(item)"
                    (imgClicked)="itemSelected(item, true)"
                />
                <interacta-radio-button
                    [align]="'start'"
                    [disabled]="$.controlState?.disabled ?? false"
                    [value]="item.id"
                >
                    <div class="flex min-h-24 items-center space-x-12">
                        <ng-container *ngIf="customLabelTemplate">
                            <ng-template
                                [ngTemplateOutlet]="customLabelTemplate"
                                [ngTemplateOutletContext]="{ item: item }"
                            />
                        </ng-container>

                        <span
                            *ngIf="!customLabelTemplate"
                            [ngClass]="{
                                italic: item.id === EMPTY_SEARCH_VALUE
                            }"
                            >{{ item.label | translate }}</span
                        >
                        <button
                            *ngIf="
                                canClearSelection &&
                                ({ item, controlValue: $.controlValue }
                                    | apply: isSelected)
                            "
                            interacta-icon-button
                            type="button"
                            [icon]="'close'"
                            [size]="'extra-small'"
                            (click)="$event.preventDefault(); clearSelection()"
                        ></button>
                    </div>
                </interacta-radio-button>
                <interacta-separator
                    *ngIf="
                        _items.list.length > 1 &&
                        quickItems &&
                        i === quickItems.length - 1
                    "
                />
            </div>
        </interacta-radio-group>
    }
</ng-container>
