import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Palette, Size } from '@interacta-shared/ui';
import { InteractaIcon } from '@interacta-shared/ui-icons';

export interface QuickAction<T> {
    label: string;
    active: boolean;
    value?: T;
    labelTooltip?: string;
    icon?: InteractaIcon;
}

@Component({
    selector: 'interacta-quick-action[actions]',
    templateUrl: './quick-action.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickActionComponent<T> implements OnChanges {
    @Input() title?: string;
    @Input() actions!: QuickAction<T>[];
    @Input() direction: 'horizontal' | 'vertical' = 'horizontal';
    @Input() size: Extract<Size, 'regular' | 'large' | 'extra-large'> =
        'regular';
    @Input() width: 'full' | 'max-content' = 'full';
    @Input() color: Extract<
        Palette,
        'primary-300' | 'primary' | 'gray-100' | 'gray-300'
    > = 'primary-300';
    @Input() enableAddAction = false;
    @Input() disabled = false;

    @Output() action = new EventEmitter<T>();
    @Output() addAction = new EventEmitter();

    @ViewChild('buttons') buttonsRef?: ElementRef<HTMLElement>;
    @ViewChild('showMoreButton') showMoreRef?: ElementRef<HTMLElement>;

    expanded = false;
    initialized = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.direction) {
            if (this.direction === 'vertical') {
                this.expanded = true;
            }
        }
    }
}
