import {
    animate,
    AnimationTriggerMetadata,
    style,
    transition,
    trigger,
} from '@angular/animations';
/**
 *
 * @param name trigger name of the transition
 * @param translateXStart Css unit, e.g.: 200px, 5rem
 * @param msDuration animation duration in milliseconds. 200 default
 * @returns
 */
export function translateFadeAnimation(
    name: string,
    translateXStart: string | null,
    translateYStart: string | null,
    direction: 'in' | 'out' | 'in-out',
    msDuration = 200,
    msDelay = 0,
): AnimationTriggerMetadata {
    const enter = transition(':enter', [
        style({
            opacity: 0,
            transform: `translate(${translateXStart ?? '0rem'}, ${
                translateYStart ?? '0rem'
            })`,
        }),
        animate(
            `${msDuration}ms ${msDelay}ms ease-out`,
            style({
                opacity: 1,
                transform: 'translate(0px)',
            }),
        ),
    ]);

    const leave = transition(':leave', [
        animate(
            `${msDuration}ms ${msDelay}ms ease-in`,
            style({
                opacity: 0,
                transform: `translate(${translateXStart ?? '0rem'}, ${
                    translateYStart ?? '0rem'
                })`,
            }),
        ),
    ]);

    const animation = [];

    if (direction !== 'out') {
        animation.push(enter);
    }

    if (direction !== 'in') {
        animation.push(leave);
    }

    return trigger(name, animation);
}
