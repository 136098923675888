import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HistoryRoutingService } from '@interacta-shared/util-common';

@Component({
    selector: 'interacta-back',
    templateUrl: './back.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackComponent {
    constructor(private historyRoutingService: HistoryRoutingService) {}

    back(): void {
        this.historyRoutingService.back();
    }
}
