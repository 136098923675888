import { IStateService } from '@modules/state/services/istate-service';
import { BehaviorSubject, Subject, Subscriber, first } from 'rxjs';

export interface DialogServiceV2Data {
    isOpen: boolean;
    isFetching?: boolean;
}

export abstract class DialogServiceV2<T> implements IStateService<T> {
    abstract state: T;
    abstract initialize(): void;
    abstract flush(): void;

    protected close<D extends DialogServiceV2Data, C>(
        closeSubject$: Subject<C>,
        data$: BehaviorSubject<D>,
        subscriber: Subscriber<C>,
    ): void {
        closeSubject$.pipe(first()).subscribe((closeData) => {
            data$.next({
                ...data$.value,
                isOpen: false,
            });
            subscriber.next(closeData);
            subscriber.complete();
        });
    }

    protected error<D extends DialogServiceV2Data>(
        data$: BehaviorSubject<D>,
        error: any,
    ): void {
        data$.next({
            ...data$.value,
            isOpen: false,
            isFetching: false,
        });
        console.error(error);
    }
}
