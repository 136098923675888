import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'interacta-private-email-form',
    templateUrl: './private-email-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivateEmailFormComponent {
    @Input() enableCancel = false;
    @Input() isEdit = false;
    @Input() privateEmailControl!: UntypedFormControl;
    @Input() isLoading = false;

    @Output() cancel = new EventEmitter();
    @Output() confirm = new EventEmitter<string | null>();
}
