import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { PaginatedList, paginatedListFromIList } from '@interacta-shared/util';
import { Observable, map } from 'rxjs';
import {
    toCatalogEntryItem,
    toCatalogsOperativityList,
} from '../models/catalog/catalog.deserialize';
import {
    ICatalogEntry,
    ICatalogsOperativityList,
} from '../models/catalog/catalog.model';
import { CatalogEntryFilter } from '../models/filter-catalog/catalog-entry-filter';
import { fromCatalogEntryFilter } from '../models/filter-catalog/catalog-entry-filter.serialize';

@Injectable({
    providedIn: 'root',
})
export class CatalogsService {
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/settings`;

    private http = inject(HttpClient);

    getCatalogs(
        catalogIds: number[],
        loadEntries = true,
    ): Observable<ICatalogsOperativityList[]> {
        return this.http
            .post<{
                catalogs: any[];
            }>(
                `${this.baseUrl}/post-definition/catalogs?loadEntries=${loadEntries}`,
                { catalogIds },
            )
            .pipe(
                map(({ catalogs }) =>
                    (catalogs || []).map(toCatalogsOperativityList),
                ),
            );
    }

    getEntries(
        filter: CatalogEntryFilter,
        catalogId: number,
    ): Observable<PaginatedList<ICatalogEntry>> {
        return this.http
            .post<any>(
                `${this.baseUrl}/post-definition/catalogs/${catalogId}/entries`,
                fromCatalogEntryFilter(filter),
            )
            .pipe(
                map((res) =>
                    paginatedListFromIList({
                        list: (<Array<any>>res.items || []).map(
                            toCatalogEntryItem,
                        ),
                        nextPageToken: res.nextPageToken,
                    }),
                ),
            );
    }
}
