import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { format } from 'date-fns-tz';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Timezone } from '../../model/timezone.model';
import { FormFieldV2Component } from '../form-field-v2/form-field-v2.component';
import { InputTextV2Component } from '../input-text-V2/input-text-V2.component';

@Component({
    standalone: true,
    imports: [
        FormFieldV2Component,
        InputTextV2Component,
        TranslateModule,
        CommonModule,
    ],
    selector: 'interacta-menu-timezone',
    templateUrl: './menu-timezone.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuTimezoneComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input({ required: true }) set timezones(timezones: Timezone[]) {
        this.sortedTimezones = timezones.sort((a, b) =>
            this.compareGreenwichDistance(a, b),
        );
    }
    @Input() defaultTimezones: Timezone[] = [];
    @Input() focusSearch = false;
    @Output() selectTimezone = new EventEmitter<Timezone>();

    @ViewChild(InputTextV2Component, { static: true })
    searchInput!: InputTextV2Component;

    today = new Date();
    searchControl = new FormControl('');
    filteredTimezones: Timezone[] = [];

    private sortedTimezones: Timezone[] = [];
    private destroy$ = new Subject<void>();

    public ngOnInit(): void {
        this.filteredTimezones = this.defaultTimezones;

        this.searchControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((search) => {
                if (search) {
                    this.filteredTimezones =
                        this.sortedTimezones.filter(
                            (timezone) =>
                                timezone.formattedZone
                                    ?.toLowerCase()
                                    .includes(search.toLowerCase()) ||
                                timezone.utcOffset
                                    ?.toLowerCase()
                                    .includes(search.toLowerCase()),
                        ) || [];
                } else {
                    this.filteredTimezones = this.defaultTimezones;
                }
            });
    }

    ngAfterViewInit(): void {
        if (this.focusSearch) {
            this.searchInput.focus();
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    private compareGreenwichDistance(t1: Timezone, t2: Timezone): number {
        const date = new Date();
        let t1Offset = Math.abs(+format(date, 'XX', { timeZone: t1.zoneId }));
        let t2Offset = Math.abs(+format(date, 'XX', { timeZone: t2.zoneId }));
        t1Offset = isNaN(t1Offset) ? 0 : t1Offset;
        t2Offset = isNaN(t2Offset) ? 0 : t2Offset;
        if (t1Offset < t2Offset) {
            return -1;
        }
        if (t1Offset > t2Offset) {
            return 1;
        }
        return 0;
    }
}
