/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PaginatedList, isDefined } from '@interacta-shared/util';
import {
    IPostLinkAndRelation,
    PostScreenLinkFieldIdsByPostId,
} from '@modules/communities/models/post-link-and-relation/post-link-and-relation.model';
import { AttachmentCategoryType } from '@modules/post/models/attachment/attachment.model';
import { emptyAttachmentsList } from '@modules/post/models/attachment/attachment.utils';
import { PostType } from '@modules/post/models/base-post.model';
import {
    ICustomPost,
    isCustomPost,
} from '@modules/post/models/custom-post.model';
import { GenericPost } from '@modules/post/models/generic-post.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomFields } from './../../models/custom-metadata/CustomFields.class';
import { ICustomField } from './../../models/custom-metadata/custom-metadata.model';
import {
    FetchInfo,
    PostFetch,
    PostLoadingInfo,
    PostState,
    postFeatureKey,
} from './post.reducer';

const selectCommunitiesFeature = createFeatureSelector<{
    [postFeatureKey]: PostState;
}>('communities');

export const selectPostFeature = createSelector(
    selectCommunitiesFeature,
    (state) => state[postFeatureKey],
);

export const selectAllPosts = createSelector(
    selectPostFeature,
    (postState) => postState.allPosts,
);

export const selectAllFetchInfos = createSelector(
    selectPostFeature,
    (state) => state.fetchInfo,
);

export const selectFetchInfo = (fetchType: PostFetch) =>
    createSelector(selectPostFeature, (state) => state.fetchInfo[fetchType]);

export const selectPosts = (fetchType: PostFetch) =>
    createSelector(
        selectAllPosts,
        selectFetchInfo(fetchType),
        (allPosts, fetchInfo): GenericPost[] =>
            fetchInfo.fetchOrder.map((id) => allPosts[id]).filter(isDefined),
    );

export const selectDetail = createSelector(
    selectPosts('detail'),
    (detailList) => detailList[0],
);

export const selectPostFetchedCommunities = () =>
    createSelector(selectPostFeature, (state) => state.postFetchedCommunities);

export const selectLoadingInfo = (fetchType: PostFetch) =>
    createSelector(selectPostFeature, (state) => state.loadingInfo[fetchType]);

export const selectPostById = (postId: number) =>
    createSelector(selectPostFeature, (state) => state.allPosts[postId]);

export const selectCurrentFilters = (fetchType: PostFetch) =>
    createSelector(selectPostFeature, (state) => state.filters[fetchType]);

export const selectPostError = (fetchType: PostFetch) =>
    createSelector(selectPostFeature, (state) => state.errors[fetchType]);

export const selectComments = (postId: number) =>
    createSelector(selectPostById(postId), (state) => state?.comments);

export const selectCustomFields = (postId: number) =>
    createSelector(selectPostById(postId), (state) =>
        state?.tag === PostType.CUSTOM ? state.customFields : null,
    );

export const selectPostCapabilities = (postId: number) =>
    createSelector(
        selectPostById(postId),
        (state) => state?.capabilities ?? null,
    );

export const selectScreenCustomFields = (postId: number) =>
    createSelector(selectPostById(postId), (state) =>
        state?.tag === PostType.CUSTOM ? state.customFieldsScreen : null,
    );

export const selectPostWorkflowHistory = (postId: number) =>
    createSelector(selectPostById(postId), (state) =>
        state?.tag === PostType.CUSTOM && state.workflowHistory
            ? state.workflowHistory
            : null,
    );

export const selectPostWorkflowHistoryItem = (
    postId: number,
    workflowHistoryId: string,
) =>
    createSelector(selectPostWorkflowHistory(postId), (state) =>
        state?.list.find((i) => i.id === workflowHistoryId),
    );

export const selectSyncToken = createSelector(
    selectPostFeature,
    (state) => state.syncToken,
);

export const selectDashboardCommunityId = createSelector(
    selectPostFeature,
    (state) => state.filters.dashboard.communityId,
);

export const selectLinksAndRelationsCount = (postId: number) =>
    createSelector(selectPostFeature, (state) =>
        state.relatedItemsCount.postId === postId
            ? state.relatedItemsCount.value
            : undefined,
    );

export const selectLinkedPostFetchInfo = (communityId: number) =>
    createSelector<object, PostState, FetchInfo | undefined>(
        selectPostFeature,
        (state) => state.linkedPosts.items[communityId]?.fetchInfo,
    );
export const selectLinkedPostLoadingInfo = (communityId: number) =>
    createSelector<object, PostState, PostLoadingInfo | undefined>(
        selectPostFeature,
        (state) => state.linkedPosts.items[communityId]?.loadingInfo,
    );
export const selectLinkedPostFieldIds = (communityId: number) =>
    createSelector<
        object,
        PostState,
        PostScreenLinkFieldIdsByPostId | undefined
    >(
        selectPostFeature,
        (state) => state.linkedPosts.items[communityId]?.linkedFieldIds,
    );

export const selectLinkedPosts = (communityId: number) =>
    createSelector(
        selectLinkedPostFetchInfo(communityId),
        selectAllPosts,
        (fetchInfo, allPosts): GenericPost[] | undefined =>
            fetchInfo?.fetchOrder?.map((id) => allPosts[id]).filter(isDefined),
    );

export const selectPaginatedLinkedPosts = (communityId: number) =>
    createSelector<
        object,
        GenericPost[] | undefined,
        PostLoadingInfo | undefined,
        PostScreenLinkFieldIdsByPostId | undefined,
        PaginatedList<IPostLinkAndRelation> | undefined
    >(
        selectLinkedPosts(communityId),
        selectLinkedPostLoadingInfo(communityId),
        selectLinkedPostFieldIds(communityId),
        (posts, loadingInfo, linkedFieldIds) => {
            return loadingInfo != null && linkedFieldIds != null
                ? {
                      isFetching: loadingInfo.isFetching,
                      totalCount: loadingInfo.totalCount ?? 0,
                      nextPageToken: loadingInfo.pageTokenInfo,
                      list: (posts ?? [])
                          .filter((p) => isCustomPost(p))
                          .map((p) => ({
                              ...(p as ICustomPost),
                              deleted: false,
                              postLinkFieldIds:
                                  linkedFieldIds[p.id].postLinkFieldIds,
                              screenLinkFieldIds:
                                  linkedFieldIds[p.id].screenLinkFieldIds,
                          })),
                  }
                : undefined;
        },
    );

export const selectCommentDraft = (postId: number) =>
    createSelector(selectPostById(postId), (post) => post?.commentDraft);

export const selectDocumentList = (postId: number) =>
    createSelector(
        selectPostById(postId),
        (post) =>
            post?.documentList ||
            emptyAttachmentsList(AttachmentCategoryType.DOCUMENT),
    );

export const selectMediaList = (postId: number) =>
    createSelector(
        selectPostById(postId),
        (post) =>
            post?.mediaList ||
            emptyAttachmentsList(AttachmentCategoryType.MULTIMEDIA),
    );

export const selectPostCommentDocumentList = (postId: number) =>
    createSelector(
        selectPostById(postId),
        (post) =>
            post?.commentsDocumentList ||
            emptyAttachmentsList(AttachmentCategoryType.DOCUMENT),
    );

export const selectPostCommentMediaList = (postId: number) =>
    createSelector(
        selectPostById(postId),
        (post) =>
            post?.commentsMediaList ||
            emptyAttachmentsList(AttachmentCategoryType.MULTIMEDIA),
    );

export const selectPostFilePickerMediaList = (postId: number) =>
    createSelector(
        selectPostById(postId),
        (post) =>
            post?.filePickerMediaList ||
            emptyAttachmentsList(AttachmentCategoryType.MULTIMEDIA),
    );

export const selectPostFilePickerDocumentList = (postId: number) =>
    createSelector(
        selectPostById(postId),
        (post) =>
            post?.filePickerDocumentList ||
            emptyAttachmentsList(AttachmentCategoryType.DOCUMENT),
    );

export const selectCommentAttachments = (postId: number, commentId: number) =>
    createSelector(
        selectComments(postId),
        (comments) =>
            comments?.list.find((c) => c.id === commentId)?.attachments,
    );

export const selectPostFilePickerAttachments = (
    postId: number,
    fieldId: number,
) =>
    createSelector(
        selectCustomFields(postId),
        (customFields: CustomFields | null) =>
            getViewValueByfieldId(customFields?.data, fieldId),
    );

export const selectScreenFilePickerAttachments = (
    postId: number,
    fieldId: number,
) =>
    createSelector(
        selectScreenCustomFields(postId),
        (customFields: CustomFields | null) =>
            getViewValueByfieldId(customFields?.data, fieldId),
    );

export const selectWorkflowHistoryAttachments = (
    postId: number,
    fieldId: number,
    workflowHistoryId: string,
) =>
    createSelector(
        selectPostWorkflowHistoryItem(postId, workflowHistoryId),
        (item) => getViewValueByfieldId(item?.workflowScreenData, fieldId),
    );

export const selectLinkedPostFilePickerAttachments = (
    postId: number,
    fieldId: number,
    communityId: number,
) =>
    createSelector(selectLinkedPosts(communityId), (posts) => {
        const post = posts?.find((l) => l.id === postId);

        const customFields =
            post && isCustomPost(post) ? post.customFields.data : undefined;
        return getViewValueByfieldId(customFields, fieldId);
    });

export const selectPreviousFilter = createSelector(
    selectPostFeature,
    (state) => state.previousFilter,
);

function getViewValueByfieldId(
    fields: ICustomField[] | undefined,
    fieldId: number,
): any | undefined {
    return fields?.find((c) => c.configuration.id === fieldId)?.viewValue;
}
