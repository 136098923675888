import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { filterMap, uuid } from '@interacta-shared/util';
import { Subject, takeUntil } from 'rxjs';
import { Size } from '../../model';
import { InputStatePipe } from '../../pipes/input-state.pipe';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
    selector: 'interacta-input-number-spinner',
    templateUrl: './input-number-spinner.component.html',
    styles: [
        `
            .no-spinner::-webkit-inner-spin-button,
            .no-spinner::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            /* Firefox */
            .no-spinner {
                -moz-appearance: textfield;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        IconButtonComponent,
        ReactiveFormsModule,
        AsyncPipe,
        InputStatePipe,
    ],
})
export class InputNumberSpinnerComponent implements OnChanges, OnDestroy {
    @Input() control!: FormControl<number | null>;
    @Input() isReadonly = false;

    @Input() minValue?: number;
    @Input() maxValue?: number;
    @Input() step = 1;
    @Input() iconSize: Size = 'regular';
    @Output() blurEmitter = new EventEmitter<void>();

    id = uuid();

    private destroy$ = new Subject<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['control']) {
            this.destroy$.next();
            this.enforceMinMaxValue(this.minValue, this.maxValue, this.control);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    stepDown(): void {
        this.control.setValue((this.control.value ?? 0) - this.step);
    }

    stepUp(): void {
        this.control.setValue((this.control.value ?? 0) + this.step);
    }

    private enforceMinMaxValue(
        min: number | undefined,
        max: number | undefined,
        control: FormControl<number | null>,
    ): void {
        if (min != null || max != null) {
            control.valueChanges
                .pipe(
                    takeUntil(this.destroy$),
                    filterMap((v) => v ?? undefined),
                )
                .subscribe((v) => {
                    if (min != null && v < min) {
                        control.setValue(min, { emitEvent: false });
                    } else if (max != null && v > max) {
                        control.setValue(max, { emitEvent: false });
                    }
                });
        }
    }
}
