<div
    class="flex min-h-0 flex-col space-y-16"
    [ngClass]="{ 'h-500': fixedHeight }"
>
    <interacta-tab
        class="shrink-0"
        size="auto"
        [tabs]="
            [
                viewers
                    ? {
                          id: PostReaction.VIEWS | apply: identity,
                          label: 'POST.VIEWS' | translate,
                          selected: selectedTab === PostReaction.VIEWS,
                          counter: viewers?.totalCount || 0
                      }
                    : null,
                likers
                    ? {
                          id: PostReaction.LIKES | apply: identity,
                          label: 'POST.LIKES' | translate,
                          selected: selectedTab === PostReaction.LIKES,
                          counter: likers?.totalCount || 0
                      }
                    : null
            ] | filterNull
        "
        (changeTab)="selectedTabEmit.emit($event)"
    ></interacta-tab>

    <interacta-input-search
        *ngIf="userCount[selectedTab] > 20 && enableSearch"
        class="mt-16 shrink-0"
        iconPosition="right"
        search
        [control]="searchMapControl[selectedTab]"
    ></interacta-input-search>

    <div
        class="flex flex-col space-y-8"
        interactaScrollTracker
        [ngClass]="{ 'overflow-y-auto': !selectedUsers.isFetching }"
        (scrolled)="onScroll($event)"
    >
        <div
            *ngIf="
                !selectedUsers.isFetching &&
                selectedUsers.nextPageToken.tag !== 'firstLoading'
            "
            class="grow"
            @users
        >
            <ng-container
                *ngFor="
                    let user of selectedUsers.list;
                    let last = last;
                    trackBy: trackUser
                "
                class="flex flex-col space-y-8"
            >
                <interacta-profiled-user
                    [showInfo]="true"
                    [user]="user"
                ></interacta-profiled-user>

                <interacta-separator
                    *ngIf="!last"
                    class="my-8 block"
                    thickness="soft"
                ></interacta-separator>
            </ng-container>
        </div>

        <interacta-load-more
            *ngIf="selectedUsers.nextPageToken.tag !== 'lastLoading'"
            class="flex items-center justify-center py-8"
            size="regular"
            [isLoading]="selectedUsers.isFetching"
            [label]="'UI.SHOW_MORE_UNCOUNT' | translate"
            (loadMore)="doSearch()"
        ></interacta-load-more>

        <interacta-no-result
            *ngIf="
                selectedUsers.nextPageToken.tag === 'lastLoading' &&
                selectedUsers.list.length === 0 &&
                !selectedUsers.isFetching
            "
            class="shrink-0 align-middle"
            imageName="no-results"
            [subtitle]="'UI.NO_RESULT_HINT' | translate"
            [title]="'DETAIL.NO_USERS_RESULT_1' | translate"
        ></interacta-no-result>
    </div>
</div>
