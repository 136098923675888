import { QuillText } from '@modules/core';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import {
    FeedbackTask,
    FeedbackTaskCommentSummary,
    FeedbackTaskList,
    FeedbackTaskState,
} from '@modules/tasks/models/task.model';
import { createAction, props } from '@ngrx/store';
import {
    FeedbackInsightsState,
    FeedbackTaskCommentList,
    FeedbackTaskFilters,
    FeedbackTaskHistory,
} from '../models/feedback.model';

export type FeedbackTaskError = any;

export const fetchFeedbackTasksSuccess = createAction(
    '[Feedback API] Fetch Feedback Tasks Success',
    props<{
        filters: FeedbackTaskFilters;
        tasks: FeedbackTaskList;
        pageToken: string | undefined;
    }>(),
);

export const fetchFeedbackTasksError = createAction(
    '[Feedback API] Fetch Feedback Tasks Error',
    props<{ postId: number; error: FeedbackTaskError }>(),
);

export const fetchCurrentFeedbackTaskSuccess = createAction(
    '[Feedback API] Fetch Current Feedback Task Success',
    props<{
        task: FeedbackTask;
    }>(),
);

export const fetchCurrentFeedbackTaskError = createAction(
    '[Feedback API] Fetch Current Feedback Tasks Error',
    props<{ taskId: number; error: FeedbackTaskError }>(),
);

export const fetchFeedbackInsightsDataSuccess = createAction(
    '[Feedback API] Fetch Feedback Insights Data Success',
    props<{
        postId: number;
        data: FeedbackInsightsState;
    }>(),
);

export const fetchFeedbackInsightsDataError = createAction(
    '[Feedback API] Fetch Feedback Insights Data Error',
    props<{ postId: number; error: FeedbackTaskError }>(),
);

export const fetchFeedbackInsightsFirstAnswerSuccess = createAction(
    '[Feedback API] Fetch Feedback Insights First Answer Average Time Success',
    props<{
        tasks: FeedbackTaskList;
    }>(),
);

export const fetchFeedbackInsightsFirstAnswerError = createAction(
    '[Feedback API] Fetch Feedback Insights First Answer Average Time Error',
    props<{ error: FeedbackTaskError }>(),
);

export const fetchFeedbackInsightsToClosedSuccess = createAction(
    '[Feedback API] Fetch Feedback Insights To Closed Average Time Success',
    props<{
        tasks: FeedbackTaskList;
    }>(),
);

export const fetchFeedbackInsightsToClosedError = createAction(
    '[Feedback API] Fetch Feedback Insights To Closed Average Time Error',
    props<{ error: FeedbackTaskError }>(),
);

export const answerFeedbackTaskSuccess = createAction(
    '[Feedback API] Answer Feedback Task Success',
    props<{
        task: FeedbackTask;
        fromState: FeedbackTaskState;
        message: QuillText;
    }>(),
);

export const validateFeedbackTaskSuccess = createAction(
    '[Feedback API] Validate Feedback Task Success',
    props<{
        task: FeedbackTask;
        fromState: FeedbackTaskState;
        message: QuillText;
    }>(),
);

export const feedbackTaskOperationError = createAction(
    '[Feedback API] Feedback Task Operation Error',
    props<{ task: FeedbackTask; error: FeedbackTaskError }>(),
);

export const fetchCurrentFeedbackTaskHistorySuccess = createAction(
    '[Feedback API] Fetch Current Feedback Tasks History Success',
    props<{
        history: FeedbackTaskHistory;
        prevPageToken?: string;
    }>(),
);

export const fetchCurrentFeedbackTaskHistoryError = createAction(
    '[Feedback API] Fetch Current Feedback Tasks History Error',
    props<{ error: FeedbackTaskError }>(),
);

export const editFeedbackTaskAttachmentSuccess = createAction(
    '[Feedback API] Edit Feedback Task Attachment Success',
    props<{
        task: FeedbackTask;
        updatedAttachment: IAttachment;
    }>(),
);
export const editFeedbackTaskAttachmentError = createAction(
    '[Feedback API] Edit Feedback Task Attachment Error',
    props<{
        taskId: number;
        error: FeedbackTaskError;
    }>(),
);

export const createFeedbackTaskCommentSuccess = createAction(
    '[Feedback] Create Feedback Task Comment Success',
    props<{
        taskId: number;
        fieldId: number;
        summary: FeedbackTaskCommentSummary;
        showCompliance: boolean;
    }>(),
);

export const createFeedbackTaskCommentError = createAction(
    '[Feedback] Create Feedback Task Comment Error',
    props<{ taskId: number; error: FeedbackTaskError }>(),
);

export const editFeedbackTaskCommentSuccess = createAction(
    '[Feedback] Edit Feedback Task Comment Success',
    props<{
        taskId: number;
        fieldId: number;
        summary: FeedbackTaskCommentSummary;
        showCompliance: boolean;
    }>(),
);

export const editFeedbackTaskCommentError = createAction(
    '[Feedback] Edit Feedback Task Comment Error',
    props<{ taskId: number; error: FeedbackTaskError }>(),
);

export const deleteFeedbackTaskCommentSuccess = createAction(
    '[Feedback] Delete Feedback Task Comment Success',
    props<{
        taskId: number;
        fieldId: number;
        summary: FeedbackTaskCommentSummary;
        showCompliance: boolean;
    }>(),
);

export const deleteFeedbackTaskCommentError = createAction(
    '[Feedback] Delete Feedback Task Comment Error',
    props<{ taskId: number; error: FeedbackTaskError }>(),
);

export const fetchFeedbackTaskCommentsSuccess = createAction(
    '[Feedback] Fetch Feedback Task Comments Success',
    props<{
        taskId: number;
        fieldId: number;
        comments: FeedbackTaskCommentList;
    }>(),
);

export const fetchFeedbackTaskCommentsError = createAction(
    '[Feedback] Fetch Feedback Task Comments Error',
    props<{
        taskId: number;
        fieldId: number;
        error: FeedbackTaskError;
    }>(),
);
