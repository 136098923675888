import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    MetadataActivationChange,
    MetadataActivationStatusMap,
} from '../models/custom-metadata-activation/custom-metadata-activation.model';
import {
    getConditionalValidatorsStream,
    initialActivationStatusMap,
} from '../models/custom-metadata-activation/custom-metadata-activation.utils';
import {
    ICustomField,
    ValidationType,
} from '../models/custom-metadata/custom-metadata.model';

@Injectable()
export class CustomMetadataActivationService {
    fieldsActivationStatusMap: Record<
        number,
        BehaviorSubject<MetadataActivationStatusMap>
    > = {};

    /**
     * Used to save the value of a custom-field when it's deactivated and its
     * value is set to null. When the custom-field is reactived its value
     * will be restored from this map.
     */
    private restoreValueMap = new Map<number, any>();

    init(
        postCustomFields: ICustomField[],
        formCustomFields: ICustomField[],
        controls: {
            [key: number]: AbstractControl;
        },
    ): Observable<MetadataActivationChange> {
        this.fieldsActivationStatusMap =
            initialActivationStatusMap(formCustomFields);

        return getConditionalValidatorsStream(
            postCustomFields,
            formCustomFields,
            controls,
        ).pipe(
            tap(({ fieldId, validationType, status }) => {
                this.fieldsActivationStatusMap[fieldId].next({
                    ...this.fieldsActivationStatusMap[fieldId].value,
                    [validationType]: status,
                });

                const control = controls[fieldId];

                // Update the validators immediately when it's deactivating,
                // delay until form submission otherwise
                if (!status.isActive) {
                    const originalValidators =
                        formCustomFields.find(
                            (f) => f.configuration.id === fieldId,
                        )?.validators ?? null;

                    control.setValidators(originalValidators);
                    control.updateValueAndValidity();
                }

                if (validationType === ValidationType.ENABLED) {
                    if (status.isActive) {
                        const previousValue = this.restoreValueMap.get(fieldId);

                        if (previousValue) {
                            control.setValue(previousValue);
                            this.restoreValueMap.delete(fieldId);
                        }

                        control.enable();
                    } else {
                        this.restoreValueMap.set(fieldId, control.value);

                        control.setValue(null);
                        control.disable();
                    }
                }
            }),
        );
    }
}
