import {
    animate,
    group,
    query,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { A11yModule } from '@angular/cdk/a11y';
import { NgClass, NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ClosableDialog, Size } from '../../model';
import { DialogService } from '../../services/dialog.service';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
    selector: 'interacta-dialog',
    templateUrl: './dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('dialog', [
            transition(':enter', [
                group([
                    query(':self', [
                        style({ opacity: 0 }),
                        animate('200ms ease', style({ opacity: 1 })),
                    ]),
                    query('#content', [
                        style({ opacity: 0, transform: 'scale(0.8)' }),
                        animate(
                            '200ms ease',
                            style({ opacity: 1, transform: 'scale(1)' }),
                        ),
                    ]),
                ]),
            ]),
            transition(':leave', [
                group([
                    query(':self', [
                        animate('200ms ease', style({ opacity: 0 })),
                    ]),
                    query('#content', [
                        animate(
                            '200ms ease',
                            style({ opacity: 0, transform: 'scale(0.8)' }),
                        ),
                    ]),
                ]),
            ]),
        ]),
        trigger('width', [
            state(
                'extra-small',
                style({
                    width: '22rem',
                }),
            ),
            state(
                'small',
                style({
                    width: '29rem',
                }),
            ),
            state(
                'regular',
                style({
                    width: '42.25rem',
                }),
            ),
            state(
                'large',
                style({
                    width: '53.5rem',
                }),
            ),
            state(
                'extra-large',
                style({
                    width: '91.666667%',
                }),
            ),
            transition('* => *', [animate('300ms ease')]),
        ]),
    ],
    standalone: true,
    imports: [
        NgIf,
        A11yModule,
        NgClass,
        IconButtonComponent,
        MatTooltipModule,
        TranslateModule,
    ],
})
export class DialogComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
    @Input() isOpen = false;
    @Input() size: Size | 'fit-content' = 'extra-small';
    @Input() closeOnBackdropClick = true;
    @Input() customRegularHeight = false;
    @Input() contentOverflowAuto = true;
    @Input() fullHeight = false;

    @Output() closing = new EventEmitter<void>();
    @Output() closed = new EventEmitter<void>();

    @ViewChild('container') templateRef!: TemplateRef<any>;

    @HostListener('document:keydown.escape', ['$event'])
    onKeydownHandler(): void {
        if (this.isOpen) this.close();
    }

    private dialog: ClosableDialog | null = null;

    private destroy$ = new Subject<void>();

    constructor(
        private dialogService: DialogService,
        private viewContainerRef: ViewContainerRef,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter(
                    (event) => this.isOpen && event instanceof NavigationEnd,
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(() => this.close());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['isOpen'] &&
            this.isOpen &&
            !changes['isOpen'].isFirstChange()
        ) {
            this.create();
        }

        if (
            changes['isOpen'] &&
            !this.isOpen &&
            !changes['isOpen'].isFirstChange()
        ) {
            this.close();
        }
    }

    ngAfterViewInit(): void {
        if (this.isOpen) {
            this.create();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        if (this.dialog) {
            this.close();
        }
    }

    close(): void {
        this.dialog?.close();
        this.dialog = null;
        this.closing.emit();
    }

    private create(): void {
        this.dialog = this.dialogService.create(
            this.templateRef,
            this.viewContainerRef,
        );
    }
}
