import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { IUser } from '@modules/core/models/user.model';
import {
    AttachmentCategoryType,
    AttachmentStorageType,
} from './attachment/attachment.model';
import { IOrder } from './filter-post/filter-post.model';

export type AttachmentEntityType =
    | 'post'
    | 'task'
    | 'comment'
    | 'postFilePicker'
    | 'screenFilePicker';

export interface IPostAttachmentFilters {
    pageToken: string;
    pageSize: number;
    calculateTotalItemsCount: boolean;
    types?: AttachmentStorageType[];
    entityTypes: AttachmentEntityType[];
    mimeTypes: string[];
    mimeTypeCategory: AttachmentCategoryType;
    postFilePickerFieldId?: number;
    wfScreenFilePickerFieldId?: number;
    language: string;
    order: IOrder;
    aiSupportedFilter?: boolean;

    loadViewLink?: boolean;
    loadDownloadLink?: boolean;
    loadPreviewImageLink?: boolean;
    loadPreviewImageAnimatedLink?: boolean;
    loadPreviewImageHiResLink?: boolean;
    loadPreviewImageHiResAnimatedLink?: boolean;
}

export interface ITaskAttachmentFilters {
    postId?: number;
    name: string;
    mimeTypes: string[];
    mimeTypeCategory: AttachmentCategoryType;
    hashtag: IHashTag[];
    hashtagsLogicalAnd?: boolean;
    createdByUser: IUser[];
    creationDateFrom: Date;
    creationDateTo: Date;
    communityIds: number[];
    language: string;
    pageSize: number;
    order: IOrder;
    pageToken: string;
    calculateTotalItemsCount: boolean;
    entityTypes: AttachmentEntityType[];
    taskIds?: number[];
    types?: AttachmentStorageType[];

    loadViewLink?: boolean;
    loadPreviewImageLink?: boolean;
    loadPreviewImageHiResLink?: boolean;
    postFilePickerFieldId?: number;
    wfScreenFilePickerFieldId?: number;
}

function toAttachmentEntityType(entityType: AttachmentEntityType): number {
    switch (entityType) {
        case 'post':
            return 1;
        case 'task':
            return 2;
        case 'comment':
            return 3;
        case 'postFilePicker':
            return 4;
        case 'screenFilePicker':
            return 5;
    }
}

export class AttachmentFilterSerialize {
    public static filterToAttachmentPostListServer = (
        filters: IPostAttachmentFilters,
    ): any => ({
        calculateTotalItemsCount: filters.calculateTotalItemsCount ?? false,
        entityTypes: filters.entityTypes.map(toAttachmentEntityType),
        language: filters.language,
        mimeTypeCategory: filters.mimeTypeCategory,
        mimeTypes: filters.mimeTypes,
        orderBy: filters.order ? filters.order.orderBy : null,
        orderDesc: filters.order ? filters.order.orderDesc : null,
        pageSize: filters.pageSize,
        pageToken: filters.pageToken,
        postFilePickerFieldId: filters.postFilePickerFieldId ?? null,
        types: filters.types ?? [
            AttachmentStorageType.STORAGE,
            AttachmentStorageType.DRIVE,
        ],
        wfScreenFilePickerFieldId: filters.wfScreenFilePickerFieldId ?? null,
        aiSupportedFilter: filters.aiSupportedFilter ?? false,
    });

    public static filterToAttachmentTaskListServer = (
        filters: ITaskAttachmentFilters,
    ): any => ({
        createdByUserId:
            filters.createdByUser && filters.createdByUser.length > 0
                ? filters.createdByUser[0].id
                : null,
        name: filters.name,
        mimeTypes: filters.mimeTypes,
        mimeTypeCategory: filters.mimeTypeCategory,
        creationTimestampFrom: filters.creationDateFrom
            ? filters.creationDateFrom.getTime()
            : null,
        creationTimestampTo: filters.creationDateTo
            ? filters.creationDateTo.getTime()
            : null,
        communityIds: filters.communityIds || [],
        hashtagIds: (filters.hashtag || []).map(
            (hashtag: IHashTag) => hashtag.id,
        ),
        hashtagsLogicalAnd:
            filters.hashtag && filters.hashtag.length > 0
                ? filters.hashtagsLogicalAnd
                : undefined,
        orderBy: filters.order ? filters.order.orderBy : null,
        orderDesc: filters.order ? filters.order.orderDesc : null,
        pageToken: filters.pageToken,
        pageSize: filters.pageSize,
        language: filters.language,
        postId: filters.postId,
        types: filters.types ?? [
            AttachmentStorageType.STORAGE,
            AttachmentStorageType.DRIVE,
        ],
        calculateTotalItemsCount: filters.calculateTotalItemsCount ?? null,
        entityTypes: filters.entityTypes.map(toAttachmentEntityType),
        taskIds: filters.taskIds || [],
        postFilePickerFieldId: filters.postFilePickerFieldId,
        wfScreenFilePickerFieldId: filters.wfScreenFilePickerFieldId,
    });
}
