export interface IExternalLink {
    id: number;
    label: string;
    url: string;
    authRequired: boolean;
    imgSrc: string | undefined;
}

export enum SuiteId {
    'CHAT' = -3,
    'XLEARNING' = -2,
    'GUIDE_CENTER' = -1,
}

export const toExternalLink = (externalLink: any): IExternalLink => ({
    id: externalLink.id,
    label: externalLink.label,
    url: externalLink.url,
    authRequired: externalLink.authRequired ?? false,
    imgSrc:
        externalLink.id === SuiteId.GUIDE_CENTER
            ? './assets/images-2.0/suite-links/guidecenter-link.jpg'
            : undefined,
});

export const toExternalLinkArray = (
    record: any,
    chatEnabled: boolean,
    xLearningEnabled: boolean,
    plantBaseUrl?: string,
): IExternalLink[] => {
    const linksArray: IExternalLink[] = [];

    if (xLearningEnabled) {
        linksArray.push({
            id: SuiteId.XLEARNING,
            label: 'DIGITAL_WORKPLACE.XLEARNING',
            url: plantBaseUrl + '/learning/',
            authRequired: false,
            imgSrc: './assets/images-2.0/suite-links/xlearning-link.jpg',
        });
    }
    if (chatEnabled) {
        linksArray.push({
            id: SuiteId.CHAT,
            label: 'DIGITAL_WORKPLACE.CHAT',
            url: plantBaseUrl + '/chat/',
            authRequired: false,
            imgSrc: './assets/images-2.0/suite-links/chat-link.jpg',
        });
    }

    return linksArray.concat((record?.items || []).map(toExternalLink));
};
