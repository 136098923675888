<div
    *ngFor="let _ of instances | array"
    class="flex items-start"
    [ngClass]="{
        'py-4': size !== 'large',

        'py-8': size === 'large'
    }"
>
    <div
        class="bg-surface-300 animate-pulse rounded-full"
        [ngClass]="{
            'h-40 w-40': size !== 'small',
            'w-30 h-30': size === 'small'
        }"
    ></div>
    <div
        class="w-120 bg-surface-300 ml-8 animate-pulse rounded-md"
        [ngClass]="{
            'h-30': size !== 'small',
            'h-20': size === 'small',
            'w-120': size !== 'large',
            'w-200': size === 'large'
        }"
    ></div>
</div>
