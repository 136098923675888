import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notNull',
    standalone: true,
})
export class NotNullPipe implements PipeTransform {
    /**
     * Just ensure the given value is not null or undefined.
     * Wrapping a value inside an *ngIf="" is not enough when you want to accept
     * a falsy value like the number 0 or the empty string. In these cases this
     * pipe come in handy.
     */
    transform(value: unknown | null | undefined): boolean {
        return value !== null && value !== undefined;
    }
}
