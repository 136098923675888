import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Size } from '../../model';
import { InputDateTimeComponent } from '../input-date-time/input-date-time.component';

@Component({
    selector: 'interacta-input-date',
    standalone: true,
    imports: [InputDateTimeComponent],
    templateUrl: './input-date.component.html',
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateComponent {
    @Input({ required: true }) control!: FormControl<Date | null>;
    @Input() isReadonly = false;
    @Input() size: Extract<Size, 'regular' | 'small' | 'extra-large'> =
        'regular';
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() showFastSelect = true;
    @Input() canClear = true;
}
