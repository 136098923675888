import { isDefined } from '@interacta-shared/util';
import { ICatalogsOperativityList } from '@modules/communities/models/catalog/catalog.model';
import { map, ReplaySubject } from 'rxjs';

export class CatalogsState {
    catalogs$ = new ReplaySubject<Record<
        number,
        ICatalogsOperativityList
    > | null>(1);

    isInitialized$ = this.catalogs$.pipe(map(isDefined));
}
