import {
    Directive,
    OnInit,
    HostListener,
    Input,
    ElementRef,
    EventEmitter,
    Output,
} from '@angular/core';
import { isOrLessIE11 } from '../helpers/generic.utils';

enum MoveArrowType {
    UP = 1,
    DOWN = 2,
}
@Directive({
    selector: '[injMoveCursorList]',
})
export class MoveCursorListDirective implements OnInit {
    @Input() currentIndex: number;
    @Input() disableSelectionFirst: boolean;
    @Input() set list(list: any[]) {
        this._list = list ? list : [];
        if (!this.disableSelectionFirst) {
            this.indexChanged(0);
        }
    }
    @Input() childrenClassName: string;
    @Output() itemClicked: EventEmitter<any> = new EventEmitter();
    @Output() currentIndexChanged: EventEmitter<number> = new EventEmitter();

    get list(): any[] {
        return this._list;
    }
    private _list: any[];
    private readonly heightTollerancy = 5;

    constructor(private elem: ElementRef) {}

    @HostListener('focus') onFocus() {
        if (
            this.list &&
            this.list.length > 0 &&
            this.currentIndex === undefined
        ) {
            this.indexChanged(0);
        }
    }

    @HostListener('blur') onBlur() {
        this.indexChanged(0);
    }

    @HostListener('document:keydown.arrowup', ['$event']) onArrowUpHandler() {
        this.list && this.move(MoveArrowType.UP);
    }

    @HostListener('document:keydown.arrowdown', ['$event'])
    onArrowDownHandler() {
        this.list && this.move(MoveArrowType.DOWN);
    }

    @HostListener('document:keydown.enter', ['$event']) onEnterHandler(
        event: KeyboardEvent,
    ) {
        this.itemClicked.emit({
            event: event,
            item: this.list[this.currentIndex],
        });
    }

    private indexChanged(index: number) {
        this.currentIndex = index;
        this.currentIndexChanged.emit(this.currentIndex);
    }

    private move(moveType: MoveArrowType) {
        let i = this.currentIndex;
        if (moveType === MoveArrowType.UP && this.currentIndex) {
            if (i === 0) {
                return;
            }
            i--;
        } else if (
            moveType === MoveArrowType.UP &&
            this.currentIndex === 0 &&
            this.disableSelectionFirst
        ) {
            this.indexChanged(undefined);
            return;
        }
        if (moveType === MoveArrowType.DOWN) {
            if (this.currentIndex === undefined && this.list.length > 0) {
                this.indexChanged(0);
                return;
            }
            if (i === this.list.length - 1) {
                return;
            }
            i++;
        }
        this.indexChanged(i);
        this.scrollCardToItem(i, moveType);
    }

    private scrollCardToItem(index: number, moveType: MoveArrowType) {
        const cardScroll = this.elem.nativeElement;
        const mentionItem: HTMLCollection = this.childrenClassName
            ? cardScroll.getElementsByClassName(this.childrenClassName)
            : this.elem.nativeElement.children;

        if (!mentionItem || mentionItem[index] === undefined) {
            return;
        }

        if (
            moveType === MoveArrowType.DOWN &&
            cardScroll.scrollTop + cardScroll.offsetHeight >
                (<any>mentionItem[index]).offsetTop +
                    (<any>mentionItem[index]).offsetHeight +
                    this.heightTollerancy
        ) {
            return;
        }
        if (
            moveType === MoveArrowType.UP &&
            cardScroll.scrollTop + cardScroll.offsetHeight <
                (<any>mentionItem[index]).offsetTop
        ) {
            return;
        }

        if (isOrLessIE11()) {
            return;
        } // FERLA --> ScrollTo non funziona su IE (quindi se hai IE non meriti di scrollare .. )
        cardScroll.scrollTo({ top: (<any>mentionItem[index]).offsetTop });
    }

    ngOnInit(): void {}
}
