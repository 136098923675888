import { IList } from '@interacta-shared/util';
import { toHashtag } from '@modules/communities/models/hashtag/hashtag.deserialize';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';

export interface IUserActivitiesFilter {
    onlyFollowedByMe: boolean;
    communityId?: number;
    name?: string;
    pageSize?: number;
    pageToken?: string;
    calculateTotalItemsCount?: boolean;
}

export class UserActivitiesDeserialize {
    public static hashtagFollowed = (record: any): IList<IHashTag> => {
        const hashtagFollowed = {} as IList<IHashTag>;
        hashtagFollowed.list = record.items ? record.items.map(toHashtag) : [];
        hashtagFollowed.nextPageToken = record.nextPageToken;
        hashtagFollowed.totalCount = record.totalItemsCount;
        return hashtagFollowed;
    };
}
