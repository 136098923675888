import {
    CURRENT_USER_SEARCH_VALUE,
    EMPTY_SEARCH_VALUE,
    isCurrentUser,
    isDefined,
    mapArrayById,
    objectIsEmpty,
} from '@interacta-shared/util';
import { ICommunity } from '@modules/communities/models/communities.model';
import { ICustomFieldDefinition } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { IUsersGroup } from '@modules/core';
import { mPartition } from '@modules/core/helpers/generic.utils';
import { IUser } from '@modules/core/models/user.model';
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns';
import { getMimeTypeObjsFromAttachmentMimeTypes } from '../attachment/attachment.utils';
import { PostType } from '../base-post.model';
import {
    EventFilterDateOptions,
    ExtendedCustomFieldServerData,
    FilterDateOptions,
    FilterType,
    IEventPostFilters,
    IPostFilters,
    IPostFiltersCustomField,
} from '../filter-post/filter-post.model';
import {
    defaultPostOrderClause,
    emptyEventPostFilters,
} from '../filter-post/filter-post.utils';
import {
    CustomQuickFilterData,
    QuickFilter,
    QuickFilterFromServer,
    QuickFilterListFromServer,
    QuickFiltersList,
    QuickFilterType,
} from '../quick-filters/quick-filters.model';
import { SurveyType } from '../survey-post/survey-post.model';
import {
    getDefaultQuickFiltersList,
    getSystemQuickFilters,
    isSystemQuickFilter,
} from './quick-filters.util';

export const toQuickFiltersList = (
    filters: QuickFilterListFromServer[] | null,
    communities: ICommunity[],
): QuickFiltersList => {
    const list: QuickFiltersList = getDefaultQuickFiltersList();
    (filters || []).forEach((element) => {
        const community =
            communities.find((c) => c.id === element.communityId) ?? null;
        const quickFiltersFromServer =
            element.quickFilters?.map((e) => toQuickFilter(e, community)) ?? [];

        let quickFilters = quickFiltersFromServer.length
            ? quickFiltersFromServer
            : getSystemQuickFilters();

        if (!quickFiltersFromServer.some(isSystemQuickFilter)) {
            const [show, notShow] = mPartition(
                getSystemQuickFilters(
                    !!community?.metadata?.workflowDefinition,
                ),
                (f) => f.showInBar,
            );

            quickFilters = [...show, ...quickFiltersFromServer, ...notShow].map(
                (f, i) => ({ ...f, order: i + 1 }),
            );
        }

        list[element.communityId ?? 'dashboard'] = quickFilters;
    });

    return list;
};

export const toQuickFilter = (
    item: QuickFilterFromServer,
    community: ICommunity | null,
): QuickFilter => {
    if (item.type === QuickFilterType.CUSTOM) {
        const quickFilter: QuickFilter = {
            id: item.id,
            type: item.type,
            label: item.label,
            description: item.description ?? null,
            order: item.order,
            showInBar: item.showInBar,
            shared: item.shared,
            filters: item.parameters
                ? toPartialPostFilter(item.parameters, community)
                : {},
        };

        return quickFilter;
    } else {
        return {
            ...getSystemQuickFilters().find((q) => q.type === item.type)!,
            id: item.id,
            showInBar: item.showInBar,
            order: item.order,
        };
    }
};

export const toPartialPostFilter = (
    parameters: string,
    community: Pick<ICommunity, 'id' | 'metadata'> | null,
): Partial<IPostFilters> => {
    const data: Partial<CustomQuickFilterData> = JSON.parse(parameters);

    let deserializedCreatedBy: IUser[] | null = null;
    const deserializedPostTypes: PostType[] = [];
    const deserializedSurveyTypes: SurveyType[] = [];

    if (data.createdByUserId) {
        deserializedCreatedBy = isCurrentUser(data.createdByUserId)
            ? [{ id: CURRENT_USER_SEARCH_VALUE } as IUser]
            : [{ id: data.createdByUserId } as IUser];
    } else if (data.createdByUserIds) {
        deserializedCreatedBy = mapArrayById(data.createdByUserIds)!.map(
            (id) => {
                return isCurrentUser(id)
                    ? ({ id: CURRENT_USER_SEARCH_VALUE } as IUser)
                    : ({ id } as IUser);
            },
        );
    }

    if (data.postTypes) {
        if (
            (!community?.metadata || community.metadata?.customPostEnabled) &&
            data.postTypes.includes(PostType.CUSTOM)
        ) {
            deserializedPostTypes.push(PostType.CUSTOM);
        }

        if (
            (!community?.metadata || community.metadata?.eventPostEnabled) &&
            data.postTypes.includes(PostType.EVENT)
        ) {
            deserializedPostTypes.push(PostType.EVENT);
        }

        if (
            (!community?.metadata || community.metadata?.surveyPostEnabled) &&
            data.postTypes.includes(PostType.SURVEY) &&
            data.postSurveyTypes?.includes(SurveyType.SURVEY)
        ) {
            deserializedPostTypes.push(PostType.SURVEY);
            deserializedSurveyTypes.push(SurveyType.SURVEY);
        }

        if (
            (!community?.metadata || community.metadata?.feedbackPostEnabled) &&
            data.postTypes.includes(PostType.SURVEY) &&
            data.postSurveyTypes?.includes(SurveyType.FEEDBACK)
        ) {
            deserializedPostTypes.push(PostType.SURVEY);
            deserializedSurveyTypes.push(SurveyType.FEEDBACK);
        }
    }

    const filters: Partial<IPostFilters> = {
        containsText: data.title || data.description || null,
        createdByUser: deserializedCreatedBy,
        createdByGroup: data.createdByGroupIds
            ? mapArrayById(data.createdByGroupIds)!.map(
                  (id) => ({ id }) as IUsersGroup,
              )
            : null,
        postTypes: deserializedPostTypes ?? [],
        postSurveyTypes:
            deserializedSurveyTypes ??
            (data.postTypes?.includes(PostType.SURVEY)
                ? [SurveyType.SURVEY] //handle quick filters created before postSurveyTypes implementation
                : []),
        ...toCreationDate(data),
        ...toEventData(data),
        visibility: data.visibility,
        hashtag: data.hashtagIds
            ? data.hashtagIds.map((id) => ({ id }) as IHashTag)
            : undefined,
        hashtagsLogicalAnd: data.hashtagsLogicalAnd,
        communityId: community?.id ?? undefined,
        dashboardWorkflowStatus:
            !community?.id && data.currentWorkflowStatusIds
                ? { ids: data.currentWorkflowStatusIds }
                : undefined,
        workflowStatus:
            community?.id != null && data.currentWorkflowStatusIds
                ? { ids: data.currentWorkflowStatusIds }
                : undefined,
        customFields: data.postFieldFilters
            ? toCustomFieldData(data.postFieldFilters)
            : [],
        screenFields: data.screenFieldFilters
            ? toCustomFieldData(data.screenFieldFilters)
            : [],
        attachmentName: data.attachmentName || null,
        attachmentHashtag: data.attachmentHashtagIds
            ? data.attachmentHashtagIds.map((id) => ({ id }) as IHashTag)
            : undefined,
        attachmentHashtagsLogicalAnd: data.attachmentHashtagsLogicalAnd,
        ...toAttachmentModifiedDate(data),
        attachmentCreatedByUser: data.attachmentCreatedByUserId
            ? [{ id: data.attachmentCreatedByUserId } as IUser]
            : null,
        attachmentSourceType: data.attachmentSourceType ?? null,
        mimeTypes: data.mimeTypes
            ? getMimeTypeObjsFromAttachmentMimeTypes(data.mimeTypes)
            : null,
        toManage: data.toManage ?? false,
        pinned: data.pinned ?? false,
        order:
            data.orderBy != null && data.orderDesc != null
                ? { orderBy: data.orderBy, orderDesc: data.orderDesc }
                : defaultPostOrderClause(),
    };
    return filters;
};

function toCreationDate(quickFilter: Partial<CustomQuickFilterData>): {
    creationDateType: FilterDateOptions | null;
    creationDateFrom: Date | null;
    creationDateTo: Date | null;
} {
    let data: {
        creationDateType: FilterDateOptions | null;
        creationDateFrom: Date | null;
        creationDateTo: Date | null;
    } = {
        creationDateType: null,
        creationDateFrom: null,
        creationDateTo: null,
    };

    if (quickFilter && quickFilter.creationDateType) {
        const now = new Date();
        switch (quickFilter.creationDateType) {
            case 'today':
                data = {
                    creationDateType: FilterDateOptions.TODAY,
                    creationDateFrom: startOfDay(now),
                    creationDateTo: null,
                };
                break;
            case 'yesterday':
                data = {
                    creationDateType: FilterDateOptions.YESTERDAY,
                    creationDateFrom: startOfDay(subDays(now, 1)),
                    creationDateTo: endOfDay(subDays(now, 1)),
                };
                break;
            case 'last7Days':
                data = {
                    creationDateType: FilterDateOptions.LAST_7_DAYS,
                    creationDateFrom: startOfDay(subDays(now, 7)),
                    creationDateTo: null,
                };
                break;
            case 'last30Days':
                data = {
                    creationDateType: FilterDateOptions.LAST_30_DAYS,
                    creationDateFrom: startOfDay(subDays(now, 30)),
                    creationDateTo: null,
                };
                break;
            case 'custom':
                data = {
                    creationDateType: FilterDateOptions.CUSTOM,
                    creationDateFrom: quickFilter.creationTimestampFrom
                        ? startOfDay(quickFilter.creationTimestampFrom)
                        : null,
                    creationDateTo: quickFilter.creationTimestampTo
                        ? endOfDay(quickFilter.creationTimestampTo)
                        : null,
                };
                break;
            default:
                console.warn(
                    `Unrecognized QuickFilterDateOptions ${quickFilter.creationDateType} `,
                );
                break;
        }
    }

    return data;
}

function toEventData(quickFilter: Partial<CustomQuickFilterData>): {
    event: IEventPostFilters | null;
    eventType: EventFilterDateOptions | null;
} {
    let data: {
        event: IEventPostFilters | null;
        eventType: EventFilterDateOptions | null;
    } = { event: null, eventType: null };

    if (
        quickFilter.postTypes &&
        quickFilter.postTypes.length > 0 &&
        quickFilter.postTypes.includes(PostType.EVENT)
    ) {
        const now = new Date();
        if (quickFilter.eventType == null) {
            data = {
                eventType: null,
                event: {
                    ...emptyEventPostFilters(),
                    allEvents: true,
                },
            };
        } else {
            switch (quickFilter.eventType) {
                case 'ongoing':
                    data = {
                        eventType: EventFilterDateOptions.ONGOING,
                        event: {
                            ...emptyEventPostFilters(),
                            startDateTo: now,
                            endDateFrom: now,
                        },
                    };
                    break;
                case 'upcoming':
                    data = {
                        eventType: EventFilterDateOptions.UPCOMING,
                        event: {
                            ...emptyEventPostFilters(),
                            startDateFrom: now,
                            startDateTo: endOfDay(addDays(now, 14)),
                        },
                    };
                    break;
                case 'inProgram':
                    data = {
                        eventType: EventFilterDateOptions.IN_PROGRAM,
                        event: {
                            ...emptyEventPostFilters(),
                            startDateFrom: now,
                        },
                    };
                    break;
                case 'ended':
                    data = {
                        eventType: EventFilterDateOptions.ENDED,
                        event: {
                            ...emptyEventPostFilters(),
                            endDateTo: now,
                        },
                    };
                    break;
                case 'custom':
                    data = {
                        eventType: EventFilterDateOptions.CUSTOM,
                        event: {
                            ...emptyEventPostFilters(),
                            startDateFrom: quickFilter.event
                                ?.startDateFromTimestamp
                                ? startOfDay(
                                      quickFilter.event.startDateFromTimestamp,
                                  )
                                : undefined,
                            startDateTo: quickFilter.event?.startDateToTimestamp
                                ? endOfDay(
                                      quickFilter.event.startDateToTimestamp,
                                  )
                                : undefined,
                        },
                    };
                    break;
                default:
                    console.warn(
                        `Unrecognized QuickFilterDateOptions ${quickFilter.creationDateType} `,
                    );
                    break;
            }
        }
    }

    return data;
}

function toAttachmentModifiedDate(
    quickFilter: Partial<CustomQuickFilterData>,
): {
    attachmentModifiedDateType: FilterDateOptions | null;
    attachmentModifiedDateFrom: Date | null;
    attachmentModifiedDateTo: Date | null;
} {
    let data: {
        attachmentModifiedDateType: FilterDateOptions | null;
        attachmentModifiedDateFrom: Date | null;
        attachmentModifiedDateTo: Date | null;
    } = {
        attachmentModifiedDateType: null,
        attachmentModifiedDateFrom: null,
        attachmentModifiedDateTo: null,
    };

    if (quickFilter && quickFilter.attachmentModifiedDateType) {
        const now = new Date();
        switch (quickFilter.attachmentModifiedDateType) {
            case 'today':
                data = {
                    attachmentModifiedDateType: FilterDateOptions.TODAY,
                    attachmentModifiedDateFrom: startOfDay(now),
                    attachmentModifiedDateTo: null,
                };
                break;
            case 'yesterday':
                data = {
                    attachmentModifiedDateType: FilterDateOptions.YESTERDAY,
                    attachmentModifiedDateFrom: startOfDay(subDays(now, 1)),
                    attachmentModifiedDateTo: endOfDay(subDays(now, 1)),
                };
                break;
            case 'last7Days':
                data = {
                    attachmentModifiedDateType: FilterDateOptions.LAST_7_DAYS,
                    attachmentModifiedDateFrom: startOfDay(subDays(now, 7)),
                    attachmentModifiedDateTo: null,
                };
                break;
            case 'last30Days':
                data = {
                    attachmentModifiedDateType: FilterDateOptions.LAST_30_DAYS,
                    attachmentModifiedDateFrom: startOfDay(subDays(now, 30)),
                    attachmentModifiedDateTo: null,
                };
                break;
            case 'custom':
                data = {
                    attachmentModifiedDateType: FilterDateOptions.CUSTOM,
                    attachmentModifiedDateFrom:
                        quickFilter.attachmentModifiedTimestampFrom
                            ? startOfDay(
                                  quickFilter.attachmentModifiedTimestampFrom,
                              )
                            : null,
                    attachmentModifiedDateTo:
                        quickFilter.attachmentModifiedTimestampTo
                            ? endOfDay(
                                  quickFilter.attachmentModifiedTimestampTo,
                              )
                            : null,
                };
                break;
            default:
                console.warn(
                    `Unrecognized QuickFilterDateOptions ${quickFilter.attachmentModifiedDateType} `,
                );
                break;
        }
    }

    return data;
}

function toCustomFieldData(
    fields: ExtendedCustomFieldServerData[],
): IPostFiltersCustomField[] {
    return fields.filter(isDefined).map((f) => getCustomFieldValueByType(f));
}

function getCustomFieldValueByType(
    field: ExtendedCustomFieldServerData,
): IPostFiltersCustomField {
    let value: unknown;

    switch (field.typeId) {
        case FilterType.EQUAL:
        case FilterType.LIKE:
        case FilterType.CONTAINS:
            value = Array.isArray(field.parameters)
                ? field.parameters[0]
                : field.parameters;
            break;
        case FilterType.INTERVAL:
        case FilterType.IN:
            value = Array.isArray(field.parameters)
                ? field.parameters
                : [field.parameters];
            break;
        case FilterType.ISNULL_OR_IN: {
            const params = Array.isArray(field.parameters)
                ? field.parameters
                : [field.parameters];

            value = [EMPTY_SEARCH_VALUE, ...params];
            break;
        }
        case FilterType.IS_EMPTY:
            value = EMPTY_SEARCH_VALUE;
            break;
        default:
            console.error('Unhandled FieldType in fieldTypeToString');
            break;
    }

    return {
        definition: {
            id: field.columnId,
        } as ICustomFieldDefinition,
        value:
            field.optionalData && !objectIsEmpty(field.optionalData)
                ? {
                      ...field.optionalData,
                      value,
                  }
                : value,
    } as IPostFiltersCustomField;
}
