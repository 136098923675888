import { InteractaIcon } from '@interacta-shared/ui-icons';
import { PageTokenInfo, firstLoading } from '@interacta-shared/util';

export interface AdminV2PaginatedFilters {
    pageToken: PageTokenInfo;
    pageSize: number;
}

export type AdminV2PaginatedColConfig<T> = {
    [key in keyof Partial<T>]: {
        label: string;
        headerClasses?: string[];
        classes?: string[] | ((col: T[key]) => string[]);
        sortable: boolean;
        defaultOrderDirection?: 'ASC' | 'DESC';
        template?: any | boolean;
        format?: (col: T[key]) => string;
    };
};

interface AdminV2PaginatedActionButtonConfig<
    T extends AdminV2PaginatedListRow,
> {
    label: string | null;
    tooltip: string | null;
    icon: InteractaIcon;
    menu: AdminV2PaginatedActionConfig<T>[] | null;
}

export type AdminV2PaginatedSingleActionButtonConfig<
    T extends AdminV2PaginatedListRow,
> = AdminV2PaginatedActionButtonConfig<T> & {
    click: ((row: T) => void | Promise<void>) | null;
    condition: ((row: T) => boolean) | null;
};

export type AdminV2PaginatedMassActionButtonConfig<
    T extends AdminV2PaginatedListRow,
> = AdminV2PaginatedActionButtonConfig<T> & {
    click:
        | ((
              selectedRows: Record<T['id'], boolean>,
              selectedRowsStats: AdminV2PaginatedListSelectedRowsStats,
          ) => void | Promise<void>)
        | null;
    condition:
        | ((
              selectedRows: Record<T['id'], boolean>,
              selectedRowsStats: AdminV2PaginatedListSelectedRowsStats,
          ) => boolean)
        | null;
};

export interface AdminV2PaginatedActionConfig<
    T extends AdminV2PaginatedListRow,
> {
    icon: InteractaIcon;
    label: string;
    click: ((row: T) => void | Promise<void>) | null;
    separator: boolean | ((row: T) => boolean);
    condition: ((row: T) => boolean) | null;
}

export interface AdminV2PaginatedListRow {
    id: number;
    selected: boolean;
    logicallyDisabled: boolean;
    loading: boolean;
    deleted: boolean;
}

export interface AdminV2PaginatedList<T extends AdminV2PaginatedListRow> {
    list: T[][];
    nextPageToken: PageTokenInfo;
    totalCount: number;
    isFetching: boolean;
    currentPage: number;
}

export interface AdminV2PaginatedListIndex {
    pageIdx: number;
    itemIdx: number;
}

export interface AdminV2PaginatedListSelectedRowsStats {
    total: number;
    deleted: number;
    all: boolean;
}

export type AdminV2VisibilityCols<T> = {
    [key in keyof Partial<T>]: boolean | undefined;
};

export const DefaultAdminV2PaginatedFilters: AdminV2PaginatedFilters = {
    pageSize: 50,
    pageToken: firstLoading(),
};
