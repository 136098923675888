import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Size } from '@interacta-shared/ui';

@Component({
    selector: 'interacta-group-tag',
    templateUrl: './group-tag.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupTagComponent {
    @Input({ required: true }) label!: string;
    @Input() size: Extract<Size, 'regular' | 'small'> = 'regular';
    @Input() excludedGroupsCount = 0;
}
