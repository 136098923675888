import { Index, PageTokenInfo } from '@interacta-shared/util';
import { ContentMimeType } from '@interacta-shared/util-common';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { IUser } from '@modules/core';
import { GoogleDriveFile } from '@modules/core/models/google-drive/google-drive.model';
import { IBasePost } from '@modules/post/models/base-post.model';
import { FileItem } from 'ng2-file-upload';

export const ATTACHMENTS_COUNT_OPERATION_LIMIT = 200;

export type AttachmentVisualizationType = 'chip' | 'thumb';

export enum AttachmentViewType {
    THUMBNAIL = 1,
    PREVIEW = 2,
}

export enum AttachmentCategoryType {
    MULTIMEDIA = 'multimedia',
    DOCUMENT = 'other',
    MIXED = 'mixed',
}

export enum AttachmentLayout {
    GRID = 'grid',
    ROW = 'row',
}

export enum AttachmentStorageType {
    STORAGE = 1,
    DRIVE = 2,
}

export type IAttachmentPost = Pick<
    IBasePost,
    | 'id'
    | 'type'
    | 'title'
    | 'code'
    | 'descriptionDelta'
    | 'draft'
    | 'currentWorkflowState'
    | 'metadata'
>;

export interface IAttachment {
    id: Index;
    type: AttachmentStorageType;
    name: string;
    contentRef?: string;
    contentMimeType: ContentMimeType;
    size: number;
    isDraft: boolean;
    temporaryContentViewLink?: string;
    temporaryContentDownloadLink?: string;
    temporaryContentPreviewImageLink?: string;
    temporaryContentPreviewImageHiResLink?: string;
    temporaryContentPreviewImageHiResAnimatedLink?: string;
    temporaryContentEmbedLink?: string;
    inPending?: boolean;
    inUpdating?: boolean;
    inDeleting?: boolean;
    creatorUser?: IUser;
    creationTimestamp?: Date;
    versionCreationTimestamp?: Date;
    lastModifyTimestamp?: Date;
    versionCreatorUser?: IUser;
    versionNumber?: number;
    versionName?: string;
    versionId?: number;
    versions?: IAttachment[];
    hashtags?: IHashTag[];
    isMediaVideo: boolean;
    isMediaAudio: boolean;
    isMediaImage: boolean;
    isMediaGif: boolean;
    isEmbeddable?: boolean;
    iconPath?: string;
    iconColor?: string;
    isCurrentVersion?: boolean;
    communityId?: number;
    selected?: boolean;
    isSelectedPreview?: boolean;
    isAttachmentRef?: boolean;
    referencedAttachmentId?: number;
    isPartial?: boolean;
    markAsHandled?: boolean; // used in attachment form handler for managing same-name-attachments
    isLoadingError?: boolean;
    isCanceled?: boolean; // true if upload has been canceled
    fileItem?: FileItem;
    capabilities?: IAttachmentCapabilities;
    drive?: GoogleDriveFile;
    canDownload?: boolean;
    clientUid?: string;
    post?: IAttachmentPost;
    commentId?: number;
    postFilePickerFieldId?: number;
    screenFilePickerFieldId?: number;
    uploadProgress?: number;
    attachmentsUploadSessionId?: string;
    downloadable?: boolean;
    isNew?: boolean;
    addedInLastRework?: boolean;
    addedInLastReview?: boolean;
}

export interface IAttachmentTemporary {
    name?: string;
    contentRef: string;
    uploadUrl: string;
    uploadMultipartRequestBodyParams: any;
    temporaryDownloadUrl: string;
    hashtags?: IHashTag[];
    isLegacy?: boolean;
    post?: IAttachmentPost;
}

export interface IAttachmentListEdit {
    addAttachments: IAttachment[];
    updateAttachments: IAttachment[];
    removeAttachmentIds: number[];
    attachmentsUploadSessionId?: string;
}

export interface IAttachmentListEditResponse {
    addAttachments: IAttachment[];
    updateAttachments: IAttachment[];
    removeAttachmentIds: number[];
    nextOccToken: number;
}

export interface IAttachmentCapabilities {
    canDelete: boolean;
    canEdit: boolean;
    canEditHashtags: boolean;
}

export interface IAttachmentCounter {
    mediasCount: number;
    documentsCount: number;
}

export interface IListAttachments {
    attachments: IAttachment[];
    pageTokenInfo: PageTokenInfo;
    totalCount: number;
    attachmentCategoryType?: AttachmentCategoryType;
    isLoading: boolean;
    isError: boolean;
}

export enum AttachmentMimeTypeFilter {
    AUDIO = 1,
    IMAGE,
    VIDEO,
    PDF,
    TEXT,
    DOCUMENT,
    SPREADSHEET,
    PRESENTATION,
    DRAWING,
    ARCHIVE,
}

export type AttachmentWithSession = IAttachment &
    Required<Pick<IAttachment, 'attachmentsUploadSessionId'>>;

export interface ICoverImage {
    iconPath?: string;
    temporaryContentViewLink?: string;
    temporaryContentPreviewImageLink?: string;
    contentRef?: string;
    temporaryContentPreviewImageHiResLink?: string;
}

export interface MimeTypeObj {
    id: AttachmentMimeTypeFilter;
    label: string;
    src: string;
}

export interface AttachmentInList {
    list: IListAttachments;
    index: number;
}
