import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import {
    PaginatedList,
    paginatedListFromIList,
    toPaginatedList,
} from '@interacta-shared/util';
import { AdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { IUser, IUsersGroup, UserExtendedDeserialize } from '@modules/core';

import { InputSelectSearchEvent } from '@interacta-shared/ui';
import { AdminV2OccToken } from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import { toIUserReverseFullName } from '@modules/admin-v2-user/models/admin-v2-user.deserialize';
import {
    toAdminV2WorkspaceRoleAssociationsList,
    toAdminV2WorkspaceSelected,
} from '@modules/admin-v2-workspace/models/admin-v2-workspace.deserialize';
import {
    AdminV2Workspace,
    AdminV2WorkspaceRoleAssociations,
    AdminV2WorkspaceSelected,
} from '@modules/admin-v2-workspace/models/admin-v2-workspace.model';
import {
    ApiV2AdminGroupsFiltersRequest,
    getDefaultBaseApiV2AdminFilterRequest,
} from '@modules/core/models/admin-api-v2-filters/admin-api-v2-filters.model';
import { UserSearchDeserialize } from '@modules/core/models/user-autocomplete.model';
import { Observable, map } from 'rxjs';
import {
    toAdminV2Group,
    toAdminV2GroupDetail,
    toAdminV2GroupRow,
} from '../models/admin-v2-group.deserialize';
import {
    AdminV2Group,
    AdminV2GroupDetail,
    AdminV2GroupFilters,
    AdminV2GroupListPage,
    AdminV2GroupSort,
    AdminV2GroupsUsersUpdate,
    AdminV2NewGroup,
} from '../models/admin-v2-group.model';
import {
    fromAdminV2GroupDetail,
    fromAdminV2GroupFilters,
    fromAdminV2GroupsUsersUpdate,
    fromAdminV2NewGroup,
} from '../models/admin-v2-group.serialize';

@Injectable({ providedIn: 'root' })
export class AdminV2GroupService {
    private readonly baseUrlData = `${inject(ENVIRONMENT).apiBasePath.common}/admin/data/groups`;
    private readonly baseUrlManage = `${inject(ENVIRONMENT).apiBasePath.common}/admin/manage`;
    private readonly baseUrlDataV2 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/admin/data/groups`;
    private readonly baseUrlDataV3 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v3/admin/data/groups`;
    private readonly baseUrlManageV3 = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v3/admin/manage`;

    private readonly http = inject(HttpClient);

    getMembers(
        groupId: AdminV2Group['id'],
        search: InputSelectSearchEvent,
        pageSize?: number,
    ): Observable<PaginatedList<IUser>> {
        return this.http
            .post<any>(`${this.baseUrlData}/${groupId}/members`, {
                pageToken: search.nextPageToken,
                fullTextFilter: search.text,
                calculateTotalItemsCount: !search.nextPageToken,
                pageSize: pageSize ?? 20,
            })
            .pipe(
                map((people: any) =>
                    toPaginatedList(people, (user) =>
                        toIUserReverseFullName(user),
                    ),
                ),
            );
    }

    updateMembers(
        groups: AdminV2GroupsUsersUpdate,
    ): Observable<AdminV2Group[]> {
        return this.http
            .put<any>(
                `${this.baseUrlManage}/groups/members`,
                fromAdminV2GroupsUsersUpdate(groups),
            )
            .pipe(map((res) => res.successGroups.map(toAdminV2Group)));
    }

    // usata nella pagina gruppi in admin
    getGroupsList(
        filter: AdminV2GroupFilters,
        paginatedFilters?: AdminV2PaginatedFilters,
        sort?: AdminV2GroupSort,
    ): Observable<AdminV2GroupListPage> {
        const groupFilters = fromAdminV2GroupFilters(
            filter,
            paginatedFilters,
            sort,
        );
        return this.http
            .post<any>(`${this.baseUrlDataV3}`, groupFilters)
            .pipe(map((res) => toPaginatedList(res, toAdminV2GroupRow)));
    }

    // usata nella member.service per il member-picker
    getIUsersGroups(
        filters: AdminV2GroupFilters,
        paginatedFilters?: AdminV2PaginatedFilters,
        sort?: AdminV2GroupSort,
    ): Observable<PaginatedList<IUsersGroup>> {
        const groupFilters = fromAdminV2GroupFilters(
            filters,
            paginatedFilters,
            sort,
        );
        return this.http
            .post<any>(`${this.baseUrlDataV3}`, groupFilters)
            .pipe(
                map((res) =>
                    toPaginatedList(res, UserExtendedDeserialize.usersGroup),
                ),
            );
    }

    searchGroupsApiV2(
        filter: Partial<ApiV2AdminGroupsFiltersRequest>,
    ): Observable<PaginatedList<IUsersGroup>> {
        return this.http
            .post<any>(`${this.baseUrlDataV2}`, {
                ...getDefaultBaseApiV2AdminFilterRequest(),
                ...filter,
            })
            .pipe(
                map((res) => UserSearchDeserialize.groupSearch(res)),
                map((people) => paginatedListFromIList(people)),
            );
    }

    // WORKSPACES
    getSelectedWorkspaces(
        groupId: AdminV2Group['id'],
    ): Observable<AdminV2WorkspaceSelected[]> {
        return this.http
            .get<any>(`${this.baseUrlData}/${groupId}/workspaces`)
            .pipe(map((res) => res.items.map(toAdminV2WorkspaceSelected)));
    }

    editWorkspaces(
        groupIds: AdminV2Group['id'][],
        addWorkspaceIds: AdminV2Workspace['id'][],
        removeWorkspaceIds: AdminV2Workspace['id'][],
    ): Observable<void> {
        return this.http.post<void>(
            `${this.baseUrlManage}/workspaces/member-groups`,
            {
                groupsIds: groupIds,
                addWorkspacesIds: addWorkspaceIds,
                removeWorkspacesIds: removeWorkspaceIds,
            },
        );
    }

    editAllFilteredWorkspaces(
        filters: AdminV2GroupFilters,
        workspaceIds: AdminV2Workspace['id'][],
        add: boolean,
    ): Observable<void> {
        return this.http.post<void>(
            `${this.baseUrlManageV3}/workspaces/member-groups/${
                add ? 'add' : 'remove'
            }/all`,
            {
                filters: fromAdminV2GroupFilters(filters),
                workspaceIds,
            },
        );
    }

    // DELETE/RESTORE
    deleteByIds(groupIds: AdminV2Group['id'][]): Observable<void> {
        return this.http.post<void>(`${this.baseUrlManageV3}/groups/delete`, {
            groupIds,
        });
    }

    deleteAllFiltered(filter: AdminV2GroupFilters): Observable<void> {
        return this.http.post<void>(
            `${this.baseUrlManageV3}/groups/delete/all`,
            {
                filters: fromAdminV2GroupFilters(filter),
            },
        );
    }

    restoreByIds(groupIds: AdminV2Group['id'][]): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrlManage}/groups/${groupIds[0]}/restore`,
            null,
        );
    }

    // GROUP DETAIL
    getGroupDetail(
        groupId: AdminV2Group['id'],
    ): Observable<AdminV2GroupDetail> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${groupId}/detail`)
            .pipe(map((res) => toAdminV2GroupDetail(res)));
    }

    // Abilitazione e Ruoli
    getWorkspaceRoleAssociationsList(
        groupId: AdminV2Group['id'],
    ): Observable<AdminV2WorkspaceRoleAssociations[]> {
        return this.http
            .get<any>(`${this.baseUrlDataV3}/${groupId}/role-associations`)
            .pipe(
                map((result) => toAdminV2WorkspaceRoleAssociationsList(result)),
            );
    }

    /* EDIT GROUP */
    editGroup(groupDetail: AdminV2GroupDetail): Observable<AdminV2OccToken> {
        return this.http
            .put<{
                nextOccToken: AdminV2OccToken;
            }>(
                `${this.baseUrlManageV3}/groups/${groupDetail.id}`,
                fromAdminV2GroupDetail(groupDetail),
            )
            .pipe(map(({ nextOccToken }) => nextOccToken));
    }

    /* CREAZIONE */
    createGroup(group: AdminV2NewGroup): Observable<number> {
        return this.http
            .post<{
                id: AdminV2Group['id'];
            }>(`${this.baseUrlManageV3}/groups`, fromAdminV2NewGroup(group))
            .pipe(map(({ id }) => id));
    }
}
