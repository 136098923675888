export type QuillIcons = {
    bold: string;
    italic: string;
    underline: string;
    list: {
        ordered: string;
        bullet: string;
    };
    link: string;
    table: string;
};

// Used to overwrite default in quill-toolbar.component.ts
export const bold = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M12.75 5.25c.688 0 1.313.172 1.875.516a3.552 3.552 0 011.36 1.359c.343.563.515 1.188.515 1.875 0 .922-.328 1.75-.984 2.484a3.658 3.658 0 011.78 1.36c.47.625.704 1.344.704 2.156 0 .688-.172 1.32-.516 1.898a3.78 3.78 0 01-1.359 1.36 3.653 3.653 0 01-1.875.492H6V5.25h6.75zm-5.25 6h5.25c.64 0 1.172-.21 1.594-.633C14.78 10.18 15 9.641 15 9c0-.64-.219-1.172-.656-1.594-.422-.437-.953-.656-1.594-.656H7.5v4.5zm0 6h6.75c.64 0 1.172-.21 1.594-.633.437-.437.656-.976.656-1.617 0-.64-.219-1.172-.656-1.594-.422-.437-.953-.656-1.594-.656H7.5v4.5zm5.25-13.5H4.5v16.5h9.75c.953 0 1.828-.234 2.625-.703a5.21 5.21 0 001.922-1.899A5.209 5.209 0 0019.5 15c0-.781-.172-1.523-.516-2.227a5.288 5.288 0 00-1.359-1.828C17.875 10.29 18 9.641 18 9c0-.953-.234-1.828-.703-2.625a5.157 5.157 0 00-1.922-1.922 5.083 5.083 0 00-2.625-.703zM9 8.25h3.75a.73.73 0 01.54.21c.14.142.21.321.21.54a.73.73 0 01-.21.54.73.73 0 01-.54.21H9v-1.5zm0 6h5.25a.73.73 0 01.54.21c.14.142.21.321.21.54a.73.73 0 01-.21.54.73.73 0 01-.54.21H9v-1.5z"/>
</svg>
`;

// Used to overwrite default in quill-toolbar.component.ts
export const italic = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M8.813 3.75l-.047.703L8.578 7.5h1.5l-.656 9h-1.5l-.188 2.953-.07.797h7.524l.234-3.75h-1.5l.656-9h1.5l.188-2.953.07-.797H8.813zm1.406 1.5h4.5l-.047.75h-1.5l-.797 11.203-.047.797h1.5l-.047.75h-4.5l.047-.75h1.5l.797-11.203.047-.797h-1.5l.047-.75z"/>
</svg>
`;

// Used to overwrite default in quill-toolbar.component.ts
export const underline = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M6 3v9c0 1.094.266 2.102.797 3.023a6.327 6.327 0 002.18 2.18c.921.531 1.93.797 3.023.797a5.823 5.823 0 003-.797 6.072 6.072 0 002.18-2.18c.547-.921.82-1.93.82-3.023V3h-1.5v9c0 .828-.203 1.586-.61 2.273a4.404 4.404 0 01-1.617 1.641A4.522 4.522 0 0112 16.5a4.522 4.522 0 01-2.273-.586 4.587 4.587 0 01-1.641-1.64A4.521 4.521 0 017.5 12V3H6zM4.5 19.5V21h15v-1.5h-15z"/>
</svg>
`;

// Used to overwrite default in quill-toolbar.component.ts
export const orderedList = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M4.477 2.62l-.235.258-.328.234c-.25.172-.453.258-.61.258v1.5c.22 0 .438-.04.657-.117.125-.047.297-.133.516-.258l.023-.023V7.87H6V2.62H4.477zM8.25 4.87v1.5H21v-1.5H8.25zm-3.375 4.5c-.516 0-.96.188-1.336.563-.36.359-.539.796-.539 1.312v.375h1.5v-.375c0-.078.04-.156.117-.234.094-.094.18-.141.258-.141.078 0 .156.047.234.14.094.079.141.157.141.235L3.234 13.19 3 13.401v1.219h3.75v-1.5H5.46l.657-.656.094-.07-.024-.024c.172-.14.305-.313.399-.516.11-.218.164-.421.164-.609a1.75 1.75 0 00-.563-1.313 1.75 1.75 0 00-1.312-.562zm3.375 2.25v1.5H21v-1.5H8.25zM3 16.12v1.5h1.031l-.281.469v1.031h1.125c.078 0 .156.047.234.14.094.079.141.157.141.235 0 .078-.047.164-.14.258-.079.078-.157.117-.235.117H3v1.5h1.875c.516 0 .953-.18 1.313-.54.375-.374.562-.82.562-1.335 0-.36-.11-.68-.328-.96a2.213 2.213 0 00-.82-.61l.304-.516.094-.187V16.12H3zm5.25 2.25v1.5H21v-1.5H8.25z"/>
</svg>
`;

// Used to overwrite default in quill-toolbar.component.ts
export const bulletList = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M3.37 3.75v4.5h4.5v-4.5h-4.5zm1.5 1.5h1.5v1.5h-1.5v-1.5zm4.5 0v1.5h11.25v-1.5H9.37zm-6 4.5v4.5h4.5v-4.5h-4.5zm1.5 1.5h1.5v1.5h-1.5v-1.5zm4.5 0v1.5h11.25v-1.5H9.37zm-6 4.5v4.5h4.5v-4.5h-4.5zm1.5 1.5h1.5v1.5h-1.5v-1.5zm4.5 0v1.5h11.25v-1.5H9.37z"/>
</svg>
`;

// Used to overwrite default in quill-toolbar.component.ts
export const link = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M16.313 3a4.5 4.5 0 00-1.805.375 4.55 4.55 0 00-1.524 1.008l-1.101 1.101a4.863 4.863 0 00-1.031 1.524 4.7 4.7 0 00-.352 1.781c0 .61.11 1.188.328 1.734l1.219-1.218c-.078-.5-.04-.992.117-1.477a3.1 3.1 0 01.774-1.289l1.101-1.101a3.04 3.04 0 011.031-.68 3.16 3.16 0 011.242-.258c.422 0 .829.086 1.22.258.39.156.734.383 1.03.68.407.406.68.882.82 1.43.141.546.141 1.1 0 1.663a3.07 3.07 0 01-.82 1.43l-1.101 1.101a3.28 3.28 0 01-1.055.704 3.25 3.25 0 01-1.218.234c-.157 0-.32-.016-.493-.047l-1.218 1.219a4.566 4.566 0 001.71.328c.626 0 1.227-.117 1.805-.352a4.863 4.863 0 001.524-1.03l1.101-1.102a4.55 4.55 0 001.008-1.524c.25-.578.375-1.18.375-1.805a4.585 4.585 0 00-1.383-3.304 4.388 4.388 0 00-1.523-1.031A4.586 4.586 0 0016.312 3zM14.46 8.46l-6 6 1.078 1.08 6-6-1.078-1.08zM8.812 10.5c-.624 0-1.226.125-1.804.375a4.55 4.55 0 00-1.524 1.008l-1.101 1.101a4.863 4.863 0 00-1.031 1.524A4.76 4.76 0 003 16.313c0 .625.117 1.218.352 1.78.234.563.57 1.071 1.007 1.524.454.453.961.797 1.524 1.031.578.235 1.18.352 1.804.352.625 0 1.227-.117 1.805-.352a4.863 4.863 0 001.524-1.03l1.101-1.102a4.55 4.55 0 001.008-1.524c.25-.578.375-1.172.375-1.781 0-.61-.11-1.188-.328-1.734l-1.219 1.218c.078.5.04 1-.117 1.5a3.118 3.118 0 01-.774 1.266l-1.101 1.102a3.281 3.281 0 01-1.055.703c-.39.156-.797.234-1.219.234-.421 0-.828-.078-1.218-.234a3.354 3.354 0 01-1.032-.703 3.071 3.071 0 01-.82-1.43 3.243 3.243 0 010-1.64 3.07 3.07 0 01.82-1.454l1.102-1.101a3.04 3.04 0 011.031-.68A3.16 3.16 0 018.812 12c.157 0 .32.016.493.047l1.218-1.219a4.566 4.566 0 00-1.71-.328z"/>
</svg>
`;

// Used to overwrite default in quill-toolbar.component.ts
export const table = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path d="M19.105 3H4.895C3.853 3 3 3.9 3 5v14c0 1.1.853 2 1.895 2h14.21C20.147 21 21 20.1 21 19V5c0-1.1-.853-2-1.895-2Zm.395 1.5v4h-15v-4h15Zm-5.132 15H9.632V10h4.736v9.5ZM4.5 10H8v9.5H4.5V10ZM16 19.5V10h3.5v9.5H16Z"/>
</svg>
`;
