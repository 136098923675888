import {
    animate,
    group,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    HostListener,
    inject,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@interacta-shared/data-access-auth';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { TipService } from '@interacta-shared/feature-tip';
import { filterMap, isDefined } from '@interacta-shared/util';
import { APP_LOGO } from '@interacta-shared/util-common';
import { dashboardCommunity } from '@modules/app-routing/routes';
import { AttachmentsVersionsService } from '@modules/attachments/services/attachments-versions.service';
import { ICommunity } from '@modules/communities/models/communities.model';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import {
    changeFilters,
    editAttachments,
} from '@modules/communities/store/post/post.actions';
import { selectPostCapabilities } from '@modules/communities/store/post/post.selectors';
import { LocalStorageService } from '@modules/core/services/local-storage.service';
import { editFeedbackTaskAttachmentSuccess } from '@modules/feedback/store/feedback-api.actions';
import { ImageEditorComponent } from '@modules/image-editor';
import {
    AttachmentCategoryType,
    AttachmentStorageType,
    IAttachment,
} from '@modules/post/models/attachment/attachment.model';
import { emptyPostFilters } from '@modules/post/models/filter-post/filter-post.utils';
import { AttachmentService } from '@modules/post/services/attachment.service';
import {
    AnyGallerySourceDetail,
    CUSTOM_ACTIONS_FEATURES,
    NO_MANAGE_VERSION_FEATURES,
} from '@modules/state/models/gallery-source.model';
import { CommunitiesStateService } from '@modules/state/services/communities-state.service';
import { GalleryStateService } from '@modules/state/services/gallery-state.service';
import { StateService } from '@modules/state/services/state.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import createPanZoom, { PanZoom, PanZoomOptions } from 'panzoom';
import {
    BehaviorSubject,
    concatMap,
    filter,
    first,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    takeUntil,
    withLatestFrom,
} from 'rxjs';
import * as SectionsActions from '../../store/sections/sections.actions';

@Component({
    selector: 'interacta-light-box',
    templateUrl: './light-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('light-box', [
            transition(':enter', [
                group([
                    query(':self', [
                        style({ opacity: 0 }),
                        animate('200ms ease', style({ opacity: 1 })),
                    ]),
                    query('#attachment', [
                        style({ transform: 'scale(0.4)' }),
                        animate('200ms ease', style({ transform: 'scale(1)' })),
                    ]),
                ]),
            ]),
            transition(':leave', [
                group([
                    query(':self', [
                        animate('200ms ease', style({ opacity: 0 })),
                    ]),
                    query('#attachment', [
                        animate(
                            '200ms ease',
                            style({ transform: 'scale(0.4)' }),
                        ),
                    ]),
                ]),
            ]),
        ]),
    ],
})
export class LightBoxComponent implements OnInit, OnDestroy {
    readonly logo = inject(APP_LOGO).dark;

    @ViewChild('attachmentContainer')
    attachmentContainerRef?: ElementRef<HTMLElement>;

    @ViewChild('imageEditor', { read: ViewContainerRef })
    imageEditor!: ViewContainerRef;
    lazyComponent: ComponentRef<ImageEditorComponent> | undefined;

    readonly MIN_ZOOM = 100;
    readonly MAX_ZOOM = 250;
    readonly ZOOM_INCREMENT = 25;
    readonly CUSTOM_ACTIONS_FEATURES = CUSTOM_ACTIONS_FEATURES;

    community$!: Observable<ICommunity | null>;
    attachment$!: Observable<IAttachment | undefined>;
    goToAudioVideoFrame$ = new BehaviorSubject<number | null>(null);
    canShowSearchBar$ = new BehaviorSubject<boolean>(false);
    zoom = 100;
    baseScale = 100;
    minZoom = this.MIN_ZOOM;
    maxZoom = this.MAX_ZOOM;
    isVideoMuted = true;
    attachmentRef?: HTMLElement;

    isFullscreen = false;
    panzoom?: PanZoom;
    panzoomDefaultOptions: PanZoomOptions = {
        minZoom: this.MIN_ZOOM / 100,
        maxZoom: this.MAX_ZOOM / 100,
        smoothScroll: false,
        zoomSpeed: 0.1,
        zoomDoubleClickSpeed: 1,
        disableKeyboardInteraction: true,
        beforeMouseDown: () => {
            if (!this.panzoom) return true;

            const shouldIgnore =
                this.panzoom.getTransform().scale ===
                    this.panzoom.getMinZoom() ||
                this.zoom === Math.round(this.baseScale);

            return shouldIgnore;
        },
    };

    AttachmentStorageType = AttachmentStorageType;
    AttachmentCategoryType = AttachmentCategoryType;

    private destroy$ = new Subject<void>();
    private readonly VIDEO_MUTED_PROPERTY = 'videoMuted';

    constructor(
        public appState: StateService,
        private galleryStateService: GalleryStateService,
        private attachmentService: AttachmentService,
        private communitiesStateService: CommunitiesStateService,
        private localStorageService: LocalStorageService,
        private tipService: TipService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private store: Store,
        private actions: Actions,
        private attachmentsVersionsService: AttachmentsVersionsService,
        private configurationService: ConfigurationService,
        private injector: Injector, //private environmentInjector: EnvironmentInjector
        private translate: TranslateService,
        private authService: AuthService,
    ) {
        this.canShowSearchBar$.next(
            !!this.configurationService.getEnvironmentInfo()?.installedFeatures
                .advancedSearchSections,
        );
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEscapeDown(): void {
        if (!this.appState.galleryState.isEditModeActive.value) this.close();
    }

    @HostListener('document:keydown.ArrowLeft', ['$event'])
    onLeftArrowDown(): void {
        if (!this.appState.galleryState.isEditModeActive.value) this.previous();
    }

    @HostListener('document:keydown.ArrowRight', ['$event'])
    onRightArrowDown(): void {
        if (!this.appState.galleryState.isEditModeActive.value) this.next();
    }

    ngOnInit(): void {
        this.attachment$ = this.appState.galleryState.attachment;

        this.attachment$
            .pipe(takeUntil(this.destroy$))
            .subscribe((attachment) => {
                this.setZoomOnChangeAttachment(attachment);
                this.goToAudioVideoFrame$.next(null);
            });

        this.community$ = this.attachment$.pipe(
            map((attachment) => attachment?.communityId),
            switchMap((communityId) =>
                communityId != null
                    ? this.communitiesStateService.getCommunity(communityId)
                    : of(null),
            ),
        );

        this.authService.login$
            .pipe(takeUntil(this.destroy$))
            .subscribe((_) => {
                this.isVideoMuted =
                    this.localStorageService.getEntry(
                        this.VIDEO_MUTED_PROPERTY,
                    ) ?? true;
            });

        this.router.events
            .pipe(
                withLatestFrom(this.appState.galleryState.isOpen),
                filter(
                    ([event, isOpen]) =>
                        isOpen && event instanceof NavigationEnd,
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(() => this.close());

        this.actions
            .pipe(
                ofType(editFeedbackTaskAttachmentSuccess),
                withLatestFrom(this.appState.galleryState.isOpen),
                filterMap(([{ updatedAttachment }, isOpen]) =>
                    isOpen ? updatedAttachment : undefined,
                ),
                withLatestFrom(this.galleryStateService.state.attachmentsList),
                takeUntil(this.destroy$),
            )
            .subscribe(([updatedAttachment, attachmentsList]) => {
                if (attachmentsList == null || attachmentsList.isLoading) {
                    return;
                }
                this.galleryStateService.state.attachmentsList.next({
                    ...attachmentsList,
                    attachments: attachmentsList.attachments.map((a) =>
                        a.id === updatedAttachment.id ? updatedAttachment : a,
                    ),
                });
                this.closeEditor();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    close(): void {
        if (this.isFullscreen) {
            this.closeFullscreen();
        } else {
            this.galleryStateService.setInfoBarOpen(false);
            this.galleryStateService.setSearchBarMode(false);
            this.galleryStateService.closeGallery();
            this.store.dispatch(SectionsActions.flush());
            this.cdr.markForCheck();
        }
        this.goToAudioVideoFrame$.next(null);
    }

    previous(): void {
        this.galleryStateService.previous();
    }

    next(): void {
        this.galleryStateService.next();
    }

    download(attachment: IAttachment): void {
        this.attachmentService.downloadAttachment(attachment);
    }

    openInDrive(attachment: IAttachment): void {
        if (!attachment.drive) {
            console.error(
                "Not a Google Drive attachment, can't open in Drive.",
            );
            return;
        }
        window.open(attachment.drive.webViewLink, '_blank');
    }

    favorite(): void {
        // TODO: IISP-3478
    }

    info(): void {
        if (this.galleryStateService.getSearchBarMode()) {
            this.appState.galleryState.isSearchBarActive.next(false);
        }
        this.appState.galleryState.showInfo.next(
            !this.galleryStateService.getInfoBarOpen(),
        );
        this.cdr.markForCheck();
    }

    openSearchBar(): void {
        if (this.galleryStateService.getInfoBarOpen()) {
            this.appState.galleryState.showInfo.next(false);
        }

        this.appState.galleryState.isSearchBarActive.next(
            !this.galleryStateService.getSearchBarMode(),
        );
        this.cdr.markForCheck();
    }

    closeFullscreen(): void {
        document.exitFullscreen();
        this.isFullscreen = false;
    }

    fullscreen(): void {
        this.attachmentContainerRef?.nativeElement.requestFullscreen();
        this.isFullscreen = true;
    }

    onZoom(direction: 'in' | 'out'): void {
        if (!this.attachmentRef || !this.panzoom) return;

        const currScale = this.panzoom?.getTransform().scale;
        const currZoom = currScale * this.baseScale;
        const sign = direction === 'out' ? -1 : 1;
        // We want to increment/decrement to multiples of ZOOM_INCREMENT.
        // If the current zoom is already a multiple of ZOOM_INCREMENT we skip
        // the computation in order to avoid floating point error propagation
        const desiredIncrement =
            this.zoom % this.ZOOM_INCREMENT === 0
                ? this.ZOOM_INCREMENT
                : sign === 1
                  ? this.ZOOM_INCREMENT - (currZoom % this.ZOOM_INCREMENT)
                  : (currZoom - this.ZOOM_INCREMENT) % this.ZOOM_INCREMENT;
        const delta = (sign * desiredIncrement) / this.baseScale;

        this.panzoom?.smoothZoomAbs(
            this.attachmentRef.clientWidth / 2,
            this.attachmentRef.clientHeight / 2,
            currScale + delta,
        );

        this.cdr.markForCheck();
    }

    changeAttachment(index: number): void {
        this.galleryStateService.updateIndex(index);
    }

    loadAttachmentsPage(untilIdx: number): void {
        this.galleryStateService.fetchNextPage(untilIdx);
    }

    changeMuteVideo(isMuted: boolean): void {
        this.isVideoMuted = isMuted;
        this.localStorageService.setEntry(
            this.VIDEO_MUTED_PROPERTY,
            isMuted,
            true,
        );
        this.cdr.markForCheck();
    }

    showCantPlayErrorMessage(type: 'audio' | 'video'): void {
        const message =
            type === 'audio'
                ? 'LIGHTBOX.AUDIO_NOT_SUPPORTED'
                : 'LIGHTBOX.VIDEO_NOT_SUPPORTED';
        this.tipService.warn(message);
    }

    initPanzoom(
        attachment: IAttachment,
        element: HTMLElement,
        initialScale: number,
    ): void {
        if (!attachment.isMediaImage) return;
        this.panzoom?.dispose();

        this.attachmentRef = element;
        this.baseScale = initialScale;
        this.zoom = Math.round(initialScale);
        this.minZoom = this.zoom;

        // minZoom : MIN_ZOOM = 1 : initialScale
        const minZoom = Math.min(1, this.MIN_ZOOM / this.baseScale);

        // maxZoom : MAX_ZOOM = 1 : initialScale
        const maxZoom = Math.max(1, this.MAX_ZOOM / this.baseScale);

        this.panzoom = createPanZoom(element, {
            ...this.panzoomDefaultOptions,
            minZoom,
            maxZoom,
        });

        this.panzoom.on('zoom', (event: PanZoom) => {
            this.onPanzoomZoom(event);
        });

        this.cdr.markForCheck();
    }

    onPanzoomZoom(panzoom: PanZoom): void {
        const currScale = panzoom.getTransform().scale;
        const container = this.attachmentContainerRef?.nativeElement;

        if (container && currScale === panzoom.getMinZoom()) {
            const width = container.clientWidth;
            const height = container.clientHeight;

            const centerX = (width - width * currScale) / 2;
            const centerY = (height - height * currScale) / 2;

            this.panzoom?.smoothMoveTo(centerX, centerY);
        }

        // zoom : currScale = initialScale : 1
        this.zoom = Math.round(this.baseScale * currScale);

        this.cdr.markForCheck();
    }

    onChangeScale(scale: number): void {
        if (!this.panzoom) return;

        this.baseScale = scale;

        const currScale = this.panzoom.getTransform().scale;

        this.panzoom.setMinZoom(Math.min(1, this.MIN_ZOOM / this.baseScale));
        this.panzoom.setMaxZoom(Math.max(1, this.MAX_ZOOM / this.baseScale));

        // zoom : currScale = baseScale : 1
        this.zoom = Math.round(currScale * this.baseScale);
    }

    filterByHahstag(hashtag: IHashTag): void {
        this.communitiesStateService
            .getPostMetadata(hashtag.communityId)
            .subscribe((metadata) => {
                if (metadata.hashTagEnabled) {
                    this.store.dispatch(
                        changeFilters({
                            fetchType: 'dashboard',
                            updatedFilters: {
                                ...emptyPostFilters(),
                                communityId: hashtag.communityId,
                                attachmentHashtag: [hashtag],
                            },
                            showTip: {
                                title: 'HASHTAG_INFO.FILTER_TIP_TITLE',
                                message: 'HASHTAG_INFO.FILTER_TIP_MESSAGE',
                                translateParams: { hashtag: hashtag.name },
                            },
                        }),
                    );
                    this.router.navigate([
                        dashboardCommunity,
                        hashtag.communityId,
                    ]);
                    this.close();
                } else {
                    this.tipService.warn('HASHTAG_INFO.DISABLED_COMMUNITY_TIP');
                }
            });
    }

    showVersions(
        attachment: IAttachment | null | undefined,
        detail: AnyGallerySourceDetail | null,
    ): void {
        if (attachment && detail) {
            this.close();

            //ci sono situazioni (bug?) in cui il post non è presente in attachment
            const obs$ = attachment.post?.id
                ? this.store.select(selectPostCapabilities(attachment.post.id))
                : of(null);

            obs$.pipe(
                first(),
                switchMap((capabilities) =>
                    this.attachmentsVersionsService
                        .open({
                            attachment,
                            canAddVersion:
                                (capabilities?.canEditAttachments ?? false) &&
                                !NO_MANAGE_VERSION_FEATURES.includes(
                                    detail.feature,
                                ),
                            canEditPost: capabilities?.canModify ?? false,
                            acceptedMimeType: undefined,
                            confirmLabel: 'BUTTON.LABEL_BUTTON_SAVE',
                        })
                        .pipe(filter(isDefined), takeUntil(this.destroy$)),
                ),
            ).subscribe(
                (attachment: IAttachment) =>
                    attachment.post &&
                    this.store.dispatch(
                        editAttachments({
                            postId: attachment.post.id,
                            attachments: [attachment],
                        }),
                    ),
            );
        }
    }

    private setZoomOnChangeAttachment(attachment?: IAttachment): void {
        // TODO IISP-5686 remove !attachment?.drive
        if (attachment?.isMediaImage && !attachment?.drive) {
            this.minZoom = this.MIN_ZOOM;
            this.maxZoom = this.MAX_ZOOM;
        } else {
            this.minZoom = this.MIN_ZOOM;
            this.maxZoom = this.minZoom;
            this.zoom = this.minZoom;
            this.panzoom?.dispose();
            this.panzoom = undefined;
        }
    }

    private closeEditor(): void {
        this.lazyComponent?.destroy();
        this.imageEditor.clear();
        this.galleryStateService.setEditMode(false);
    }

    async editAttachment(attachment: IAttachment): Promise<void> {
        if (
            this.galleryStateService.getInfoBarOpen() ||
            this.galleryStateService.getSearchBarMode()
        ) {
            this.info();
        }
        this.imageEditor.clear();

        const lazyBarrel = await import('@modules/image-editor');
        const lang = this.configurationService.getCurrentLanguage().code;
        await lazyBarrel.loadL10n(lang, this.translate);

        this.lazyComponent = this.imageEditor.createComponent(
            lazyBarrel.ImageEditorComponent,
            { injector: this.injector },
        );
        this.lazyComponent.instance.attachment = attachment;
        this.lazyComponent.instance.closeEditor.subscribe(() =>
            this.closeEditor(),
        );
        this.lazyComponent.instance.saveChanges
            .pipe(
                concatMap((image) =>
                    of(image).pipe(
                        withLatestFrom(
                            this.galleryStateService.state.sourceDetail,
                        ),
                    ),
                ),
                takeUntil(this.destroy$),
            )
            .subscribe(([imageBlob, detail]) => {
                if (detail) {
                    this.galleryStateService.saveEditedAttachment(
                        detail,
                        imageBlob,
                        attachment,
                    );
                }
            });

        /*
         ** [IISP-8207] Alla seconda apertura dell'editor il click sul bottone 'matita' non scatena la stopPropagation,
         ** è stato quindi aggiunto il setTimeout altrimenti isEditModeActive va ad eliminare il componente
         ** light-box-header e quindi la propagazione arriva direttamente al div sopra che ha il click con la close()
         */
        setTimeout(() => this.galleryStateService.setEditMode(true), 0);
    }
}
