import { cleanEmptyi18nString } from '@modules/core/helpers/generic.utils';

import { isDefined, mapArrayById } from '@interacta-shared/util';
import { Member } from '@modules/core/models/member/member.model';
import { IPostFiltersCustomField } from '@modules/post/models/filter-post/filter-post.model';
import { InputSelectRoleItem } from '@modules/shared-v2/components/input-roles-picker/input-roles-picker.component';
import {
    ICustomField,
    ICustomFieldMetadataBase,
    IEnumValues,
    MetadataFieldModeType,
} from './custom-metadata.model';
import {
    cleanFromQrCodeSnakeCaseMetadata,
    getCustomFilterServerData,
    getQuickFiltersCustomData,
    getServerValue,
} from './custom-metadata.utils';

export const fromServerCustomFields = (
    customFieldsMap: ICustomField[],
): any => {
    const record: Record<number, unknown> = {};
    customFieldsMap
        .filter((customField) => !customField.configuration.readonly)
        .forEach((customField) => {
            record[customField.configuration.id] = getServerValue(
                customField.inputValue,
                customField.configuration,
            );
        });
    return record;
};

export const fromCustomFieldsSearch = (
    filterCustomFields: IPostFiltersCustomField[],
): any => filterCustomFields.map(getCustomFilterServerData).filter(isDefined);

export const fromCustomFieldsSearchToQuickFilterData = (
    filterCustomFields: IPostFiltersCustomField[],
): any => filterCustomFields.map(getQuickFiltersCustomData).filter(isDefined);

export const fromEnumValues = (enumValue: IEnumValues): unknown => ({
    id: enumValue.id ?? null,
    deleted: enumValue.deleted,
    label: cleanEmptyi18nString(enumValue.label),
    parentIds: enumValue.parentIds || [],
});

export const fromFieldMetadataV2 = (
    metadata: ICustomFieldMetadataBase,
): any => {
    const metadataToSave = {
        ...metadata,
        group_picker_from_role:
            metadata.group_picker_from_role &&
            Array.isArray(metadata.group_picker_from_role)
                ? mapArrayById(
                      metadata.group_picker_from_role as InputSelectRoleItem[],
                  )?.[0]
                : metadata.group_picker_from_role ?? undefined,
        user_picker_from_role:
            metadata.user_picker_from_role &&
            Array.isArray(metadata.user_picker_from_role)
                ? mapArrayById(
                      metadata.user_picker_from_role as InputSelectRoleItem[],
                  )?.[0]
                : metadata.user_picker_from_role ?? undefined,
        user_picker_from_group:
            metadata.user_picker_from_group &&
            Array.isArray(metadata.user_picker_from_group)
                ? (metadata.user_picker_from_group as Member[])[0].innerId
                : metadata.user_picker_from_group ?? undefined,
        community_id: getCommunityIdFromObj(metadata),
        catalog_id:
            metadata.catalog_id && Array.isArray(metadata.catalog_id)
                ? mapArrayById(metadata.catalog_id)?.[0]
                : metadata.catalog_id ?? undefined,
    };

    return cleanFromQrCodeSnakeCaseMetadata(metadataToSave);
};

const metadataFieldModeTypeMap: Record<MetadataFieldModeType, number> = {
    post: 1,
    screen: 2,
    'ack-task-screen': 3,
    survey: 4,
};

export const fromMetadataFieldModeType = (
    fieldMode: MetadataFieldModeType,
): number => metadataFieldModeTypeMap[fieldMode];

export const getCommunityIdFromObj = (
    metadata: ICustomFieldMetadataBase,
): number | undefined => {
    return metadata.community_id && Array.isArray(metadata.community_id)
        ? mapArrayById(metadata.community_id)?.[0]
        : metadata.community_id ?? undefined;
};
