import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { InputSelectItem, InputSelectSearchEvent } from '@interacta-shared/ui';
import {
    PaginatedList,
    emptyPaginatedList,
    fetchPaginatedList,
    fetchPaginatedListSuccess,
    fromPageToken,
} from '@interacta-shared/util';
import { AdminV2RolesService } from '@modules/admin-v2-roles/services/admin-v2-roles.service';
import { CommunitiesService } from '@modules/communities/services/communities.service';
import { getLabelServerTranslation } from '@modules/core/helpers/generic.utils';
import { OperationalRolesSearchRequest } from '@modules/core/models/user-autocomplete.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';

export interface InputSelectRoleItem extends InputSelectItem {
    communityAssociation: boolean;
    deleted: boolean;
}
@Component({
    selector: 'interacta-input-roles-picker',
    templateUrl: './input-roles-picker.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputRolesPickerComponent implements OnInit, OnDestroy {
    @Input({ required: true }) control!: UntypedFormControl;
    @Input({ required: true }) name = '';
    @Input() communityId?: number;
    @Input() description = '';
    @Input() isReadonly = false;
    @Input() maxSelectableItems = Infinity;
    @Input() showError = true;
    @Input() useAdminApi = true;

    roles$ = new BehaviorSubject<PaginatedList<InputSelectRoleItem>>(
        emptyPaginatedList(0),
    );

    private destroy$ = new Subject<void>();

    constructor(
        private adminV2RolesService: AdminV2RolesService,
        private communitiesService: CommunitiesService,
        private configurationService: ConfigurationService,
    ) {}

    ngOnInit(): void {
        this.loadRoles({
            text: '',
            nextPageToken: null,
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    loadRoles(filters: InputSelectSearchEvent): void {
        const filter: OperationalRolesSearchRequest = {
            name: filters.text ?? undefined,
            pageToken: filters.nextPageToken ?? undefined,
            calculateTotalItemsCount: true,
            checkCommunityAssociation:
                this.useAdminApi && this.communityId
                    ? this.communityId
                    : undefined,
        };

        this.roles$.next(
            fetchPaginatedList(this.roles$.value, filter.pageToken),
        );

        if (!this.useAdminApi && !this.communityId) {
            throw Error(
                `communityId is required for RolesPicker in Operative Mode`,
            );
        }

        const api$ = this.useAdminApi
            ? this.adminV2RolesService.searchOperativeRoles(filter)
            : this.communitiesService.getCommunityOperativeRoleList(
                  this.communityId!,
                  filter,
              );

        api$.pipe(
            map((rolesList) => {
                const roles: PaginatedList<InputSelectRoleItem> = {
                    list: rolesList.list.map((l) => ({
                        id: l.id,
                        label:
                            getLabelServerTranslation(
                                l.name,
                                this.configurationService.getCurrentLanguage()
                                    .code,
                                this.configurationService.getDefaultInfo()
                                    .systemLanguage?.code,
                            ) ?? '',
                        communityAssociation: l.communityAssociation ?? false,
                        deleted: l.deleted ?? false,
                    })),
                    totalCount: rolesList.totalCount ?? 0,
                    nextPageToken: fromPageToken(rolesList.nextPageToken),
                    isFetching: false,
                };
                return roles;
            }),
            withLatestFrom(this.roles$),
            map(([nextList, currList]) =>
                fetchPaginatedListSuccess(currList, nextList),
            ),
            takeUntil(this.destroy$),
        ).subscribe((list) => this.roles$.next(list));
    }
}
