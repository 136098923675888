import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Injectable,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Signal,
    SimpleChanges,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import {
    IValidationError,
    IValidationErrorPayload,
    getValidationErrorPayload,
} from '@interacta-shared/data-access-error';
import { openHeightAnimation } from '@interacta-shared/ui';
import { isDefined } from '@interacta-shared/util';
import { MetadataActivationStatusMap } from '@modules/communities/models/custom-metadata-activation/custom-metadata-activation.model';
import { getActiveValidators } from '@modules/communities/models/custom-metadata-activation/custom-metadata-activation.utils';
import {
    CustomFieldType,
    ICustomField,
    ICustomFieldDefinition,
    MetadataFieldModeType,
    ValidationType,
} from '@modules/communities/models/custom-metadata/custom-metadata.model';
import {
    constructFormGroup,
    getCustomFieldFullDescription,
    patchFormGroup,
    updateInputValueFromFormGroup,
} from '@modules/communities/models/custom-metadata/custom-metadata.utils';
import {
    IScreen,
    IScreenContext,
    IScreenEdit,
} from '@modules/communities/models/screen.model';
import { IWorkflowOperation } from '@modules/communities/models/workflow/workflow.model';
import { CustomMetadataActivationService } from '@modules/communities/services/custom-metadata-activation.service';
import { FormControlsService } from '@modules/communities/services/form-controls.service';
import { PostActionsApi } from '@modules/communities/store';
import { getFilePickerGallerySourceDetail } from '@modules/communities/utils/file-picker-gallery-source-detail';
import {
    DataFormControlsValidators,
    FormServiceAbstract,
} from '@modules/core/helpers/form-service.abstract';
import { parseCustomServerValidationError } from '@modules/core/helpers/form-validation-errors.utils';
import { IPostMetadata } from '@modules/post/models/base-post.model';
import { ListenChangesScreenData } from '@modules/post/models/listen-changes/listen-changes.model';
import { getListenChanges } from '@modules/post/models/listen-changes/listen-changes.utils';
import { PostFormActions } from '@modules/post/store/form';
import * as PostFormSelectors from '@modules/post/store/form/post-form.selectors';
import { QuillTable } from '@modules/shared-v2/models/quill-table/quill-table.model';
import { GalleryStateService } from '@modules/state/services/gallery-state.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ScreenDataValidator extends DataFormControlsValidators {
    fieldsMap!: UntypedFormGroup;

    init(customFieldsMap: ICustomField[]): void {
        this.fieldsMap = constructFormGroup(customFieldsMap);
    }

    patch(customFieldsMap: ICustomField[]): void {
        patchFormGroup(this.fieldsMap, customFieldsMap);
    }

    setValidators(
        customFields: ICustomField[],
        fieldsActivationStatusMap: Record<
            number,
            BehaviorSubject<MetadataActivationStatusMap>
        >,
    ): void {
        customFields.forEach((field) => {
            this.updateValidator(
                this.fieldsMap.controls[field.configuration.id],
                getActiveValidators(fieldsActivationStatusMap, field),
            );
        });
    }

    setValidatorsServerSide(error: IValidationErrorPayload): void {
        super.setValidatorsServerSide(error);
        if ((error?.validationErrors?.list?.length ?? 0) > 0) {
            (error.validationErrors.list ?? []).forEach(
                (validationError: IValidationError) => {
                    let refError: ValidationErrors;
                    if (
                        (refError =
                            parseCustomServerValidationError(validationError))
                    ) {
                        if (
                            validationError.customFieldId &&
                            validationError.customFieldId in
                                this.fieldsMap.controls
                        ) {
                            this.fieldsMap.controls[
                                validationError.customFieldId
                            ].setErrors(refError);
                        }
                    }
                },
            );
        }
    }
}

@Injectable()
export class PostTransitionFormService extends FormServiceAbstract<
    IScreen,
    ScreenDataValidator
> {
    get data(): IScreen {
        return this._data;
    }

    public get customFieldsScreenMap(): ICustomField[] {
        return this._customFieldsScreenMap;
    }

    private _data!: IScreen;
    private _customFieldsScreenMap!: ICustomField[];
    private workflowOperationId?: number;

    init(data: IScreenEdit, workflowOperationId?: number): void {
        this._data = data;
        this._customFieldsScreenMap = data.customFields.visibleInEdit;
        this.dataFormControls.init(this._customFieldsScreenMap);
        this.form.setControl('fieldsMap', this.dataFormControls.fieldsMap);
        this.workflowOperationId = workflowOperationId;
    }

    patch(data: IScreenEdit, workflowOperationId?: number): void {
        this._data = data;
        this._customFieldsScreenMap = data.customFields.visibleInEdit;
        this.dataFormControls.patch(this._customFieldsScreenMap);
        this.form.setControl('fieldsMap', this.dataFormControls.fieldsMap);
        this.workflowOperationId = workflowOperationId;
    }

    public getContextData(
        listenChangesFieldId?: number,
    ): IScreenContext | undefined {
        const _data = this.prepareData();

        if (
            !_data ||
            (isDefined(listenChangesFieldId) &&
                this.dataFormControls.fieldsMap.controls[listenChangesFieldId]
                    .valid == false)
        ) {
            return undefined;
        }
        return {
            postId: _data.postId,
            workflowOperationId: this.workflowOperationId,
            customFields: _data.customFields,
        };
    }

    public prepareData(): IScreenEdit {
        const data: IScreenEdit = {
            ...this.data,
        };
        data.customFields.data = updateInputValueFromFormGroup(
            this.dataFormControls.fieldsMap,
            this.customFieldsScreenMap,
        );
        return data;
    }
}

@Component({
    selector: 'interacta-post-transition-form',
    templateUrl: './post-transition-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: DataFormControlsValidators, useClass: ScreenDataValidator },
        PostTransitionFormService,
        CustomMetadataActivationService,
        FormControlsService,
    ],
    animations: [openHeightAnimation('field', '*')],
})
export class PostTransitionFormComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input({ required: true }) postDefinition!: IPostMetadata;
    @Input({ required: true }) screen!: IScreen;
    @Input({ required: true }) actionId!: IWorkflowOperation['id'];
    @Input() createMention?: boolean;

    @Output() listenChange = new EventEmitter<ListenChangesScreenData>();

    readonly ValidationType = ValidationType;
    readonly CustomFieldType = CustomFieldType;
    readonly MetadataFieldModeType = MetadataFieldModeType;

    private destroy$ = new Subject<void>();
    private initForm$ = new Subject<void>();

    tables: Signal<QuillTable[]>;

    constructor(
        public formService: PostTransitionFormService,
        public customMetadataActivationService: CustomMetadataActivationService,
        public translate: TranslateService,
        private formControlsService: FormControlsService,
        private actions: Actions,
        private galleryStateService: GalleryStateService,
        private store: Store,
    ) {
        this.tables = toSignal(
            this.store.select(PostFormSelectors.selectTables),
            { initialValue: [] },
        );
    }

    trackByFn(_index: number, items: ICustomField[]): string {
        if (items.length === 1) {
            return items[0].configuration.id + '';
        } else {
            return items[0].configuration.id + items[1].configuration.id + '';
        }
    }

    getCustomFieldFullDescription = ($: {
        configuration: ICustomFieldDefinition;
        translate: TranslateService;
    }) => getCustomFieldFullDescription($.configuration, $.translate);

    ngOnInit(): void {
        this.actions
            .pipe(
                ofType(
                    PostActionsApi.workflowTransitionError,
                    PostActionsApi.editWorkflowScreenError,
                ),
                takeUntil(this.destroy$),
            )
            .subscribe((error) => {
                const errors = getValidationErrorPayload(error.error);
                if (errors) {
                    this.formService.dataFormControls.setValidatorsServerSide(
                        errors,
                    );
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const _screen: IScreen = changes.screen?.currentValue ?? this.screen;
        const _postDefinition: IPostMetadata =
            changes.postDefinition?.currentValue ?? this.postDefinition;

        if (
            _screen &&
            _postDefinition &&
            (changes.screen || changes.postDefinition)
        ) {
            this.patchForm();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    submit(): IScreen | null {
        this.formService.markFormGroupTouched(this.formService.form);

        this.formService.dataFormControls.setValidators(
            this.formService.customFieldsScreenMap,
            this.customMetadataActivationService.fieldsActivationStatusMap,
        );

        if (!this.formService.checkValidity()) {
            return null;
        }

        return this.updateData(this.screen);
    }

    openDocument(event: {
        index: number;
        fieldId: number;
        filePickerMode: MetadataFieldModeType;
    }): void {
        this.galleryStateService.openGallery(
            getFilePickerGallerySourceDetail(event, this.screen.postId),
            event.index,
        );
    }

    addOrEditQuillTable(table: QuillTable): void {
        this.store.dispatch(PostFormActions.addOrEditQuillTable({ table }));
    }

    private patchForm(): void {
        this.initForm$.next();

        this.formService.init(this.screen, this.actionId as number);

        const controls = this.formService.dataFormControls.fieldsMap.controls;

        this.formControlsService.init(controls);

        this.customMetadataActivationService
            .init(
                this.screen.customFields.data,
                this.screen.customFields.visibleInEdit,
                controls,
            )
            .pipe(takeUntil(this.initForm$), takeUntil(this.destroy$))
            .subscribe();

        if (!this.postDefinition.workflowDefinition) {
            console.warn('Unexpectedly missing workflow definition');
            return;
        }

        if (this.screen.tables && this.screen.tables.length) {
            this.screen.tables.forEach((table) => {
                this.addOrEditQuillTable(table);
            });
        }

        getListenChanges(
            this.postDefinition.workflowDefinition.screenFieldMetadatas,
            controls,
        )
            .pipe(takeUntil(this.initForm$), takeUntil(this.destroy$))
            .subscribe((change) => {
                const field = this.formService.customFieldsScreenMap.find(
                    (f) => f.configuration.id === change.fieldId,
                );
                const controlField =
                    this.formService.dataFormControls.fieldsMap.controls[
                        change.fieldId
                    ];

                if (!field || !controlField) {
                    console.warn(
                        'Unexpectedly missing the field or control associated to a listen changes field',
                    );
                    return;
                }

                const validators = getActiveValidators(
                    this.customMetadataActivationService
                        .fieldsActivationStatusMap,
                    field,
                );

                controlField.markAsTouched();
                controlField.setValidators(validators);
                controlField.updateValueAndValidity();

                const context = this.formService.getContextData(change.fieldId);
                if (context) {
                    this.listenChange.emit({
                        screen: this.screen,
                        context,
                        changedScreenFieldId: change.fieldId,
                    });
                }
            });
    }

    private updateData(screenEdit: IScreenEdit): IScreenEdit {
        const screen: IScreenEdit = {
            ...screenEdit,
            ...this.formService.mapDataFromForm(),
        };
        screen.customFields.data = updateInputValueFromFormGroup(
            this.formService.dataFormControls.fieldsMap,
            this.formService.customFieldsScreenMap,
        );
        screen.tables = this.tables();

        return screen;
    }
}
