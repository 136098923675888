import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { Index } from '@interacta-shared/util';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import { fromCommunityListFilter } from '@modules/post/models/filter-post/filter-post.serialize';
import { Observable } from 'rxjs';

export interface ExecuteCustomActionResponseDTO {
    message: string;
    postDataHasChanged?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class CustomActionsService {
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts/manage/communities`;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) {}

    callCustomActionsApi(
        communityId: number,
        filters: IPostFilters | null,
        customButtonId: number,
        postIds: Index[] | null,
    ): Observable<ExecuteCustomActionResponseDTO> {
        return this.http.post<ExecuteCustomActionResponseDTO>(
            `${this.baseUrl}/${communityId}/execute-custom-actions/${customButtonId}`,
            {
                postIds,
                filters: filters
                    ? fromCommunityListFilter(
                          filters,
                          this.authService.getCurrentUserData() || undefined,
                      )
                    : null,
            },
        );
    }
}
