<div
    *ngIf="{
        solid: bgColor === 'surface-A',
        transparent: bgColor === 'gray-500'
    } as $"
    class="relative rounded-full p-12"
    matRipple
    [ngClass]="{
        'bg-surface-A hover:bg-surface-300 outline outline-2 outline-gray-300':
            $.solid && !disabled,
        'bg-surface-300': $.solid && disabled,
        'bg-transparent-gray-500-30': $.transparent,
        'hover:bg-transparent-gray-700-30': $.transparent && !disabled
    }"
>
    <interacta-icon
        class="h-24 w-24 transition-colors"
        [icon]="icon"
        [ngClass]="{
            'text-text': !disabled,
            'text-text-disabled': disabled
        }"
    ></interacta-icon>
</div>
