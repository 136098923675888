import {
    FormArray,
    FormControl,
    FormGroup,
    ValidationErrors,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { hasValidationErrorByCodeAndField } from '@interacta-shared/data-access-error';
import { flatten, isDefined } from '@interacta-shared/util';
import { parseUrl } from '@interacta-shared/util-common';
import { wrapWithAnchor } from '@modules/core/helpers/i18n.utils';
import { AppSelectors, AppState } from '@modules/core/store';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import {
    AdminV2ExportFeature,
    AdminV2ImportFeature,
} from '../models/admin-v2-import-export.model';
import { AdminV2PaginatedListRow } from '../models/admin-v2-paginated.model';

export const parseIfNotNull = <T, K>(
    item: T | null | undefined,
    cb: (args: T) => K,
): K | NonNullable<T> | null => (isDefined(item) ? cb(item) : item ?? null);

export const checkValueInKey = (
    array: { [key: string]: any }[],
    key: string,
    value: string | number,
): boolean => array.some((item) => item[key] === value);

export const buildExportLink = (
    label: string,
    feature: AdminV2ExportFeature | AdminV2ImportFeature,
): string => wrapWithAnchor(label, `text-text-link i18n-link ${feature}`);

export const listenForFeatures = (
    srcElement: HTMLElement,
    features: AdminV2ExportFeature[] | AdminV2ImportFeature[],
): Observable<string> => {
    return new Observable((observable) => {
        const emit = (feature: string) => {
            observable.next(feature);
        };

        features.forEach((feature) =>
            srcElement
                .querySelector(`a.text-text-link.i18n-link.${feature}`)
                ?.addEventListener('click', () => emit(feature)),
        );
    });
};

export const getSelectedRowByIdFromPages = <T extends AdminV2PaginatedListRow>(
    id: number,
    pages: T[][],
): T | undefined => {
    let selectedRow: T | undefined;
    for (const page of pages) {
        selectedRow = page.find((row) => row.id === id);
        if (selectedRow) break;
    }
    return selectedRow;
};

export const getSelectedRowsByIdFromPages = <T extends AdminV2PaginatedListRow>(
    ids: T['id'][],
    pages: T[][],
): T[] => {
    const idSet = new Set(ids);
    return flatten(pages).filter((p) => idSet.has(p.id));
};

export const reorderList = <T>(
    list: T[],
    newIndex: number,
    prevIndex: number,
): T[] => {
    // la splice modifica direttamente l'array, quindi prima ne faccio una copia
    const reorderedList = [...list];
    reorderedList.splice(newIndex, 0, reorderedList.splice(prevIndex, 1)[0]);
    return reorderedList;
};

export const typedObjectKeys = <T extends object>(object: T): Array<keyof T> =>
    Object.keys(object) as Array<keyof T>;

export const getCurrentRouteLastPath = (
    appStore: Store<AppState>,
): Observable<string | null> => {
    return appStore.select(AppSelectors.selectRouteState).pipe(
        map((routeState) => {
            const url = routeState?.url;
            if (url) {
                const { paths } = parseUrl(url);
                return paths[paths.length - 1];
            }
            return null;
        }),
    );
};

export const getVisibleColsNumber = (visibleCols: {
    [key in keyof Partial<AdminV2PaginatedListRow>]: boolean;
}): number => {
    return Object.values(visibleCols).filter((value) => value === true).length;
};

export const objToKeyValueList = <T extends object>(
    obj: T,
): { key: keyof T; value: T[keyof T] }[] =>
    typedObjectKeys(obj).map((key) => ({
        key,
        value: obj[key],
    }));

export const getFormChildrenErrors = (
    parentForm: FormGroup | FormArray,
): ValidationErrors[] => {
    const errors: ValidationErrors[] = [];
    const findChildrenErrors = (form: FormGroup | FormArray) => {
        Object.keys(form.controls).forEach((field) => {
            const control = form.get(field);
            if (control?.errors) {
                for (const keyError of Object.keys(control.errors)) {
                    errors.push({
                        controlName: field,
                        errorName: keyError,
                        errorValue: control.errors[keyError],
                    });
                }
            }
            if (control instanceof FormGroup || control instanceof FormArray) {
                findChildrenErrors(control);
            }
        });
    };
    findChildrenErrors(parentForm);
    return errors;
};

export const childrenUpdateValueAndValidity = (
    parentForm: FormGroup | FormArray,
): void => {
    const findChildrenErrors = (form: FormGroup | FormArray) => {
        Object.keys(form.controls).forEach((field) => {
            const control = form.get(field);
            if (control instanceof FormGroup || control instanceof FormArray) {
                findChildrenErrors(control);
            } else {
                control?.markAsTouched();
                control?.updateValueAndValidity();
            }
        });
    };
    findChildrenErrors(parentForm);
};

export const setCheckboxControl = (
    control: FormControl<boolean>,
    value: boolean,
): void => {
    control.setValue(value);
    control.markAsDirty();
};

export const isStringFormControl = (
    control: FormControl,
): control is FormControl<string> => typeof control.value === 'string';

export const enityIdFromRoute = (route: ActivatedRoute): Observable<number> =>
    route.params.pipe(
        map((route) => {
            const id = parseInt(route.id as string);
            if (isNaN(id)) {
                throw new Error("Can't parse route.params.id to int");
            }
            return id;
        }),
    );

export const hasNotUniqueValidationErrorByFieldName = (
    object: unknown,
    field: string,
    exactFieldName = true,
): boolean => {
    return hasValidationErrorByCodeAndField(
        object,
        'NOT_UNIQUE',
        field,
        exactFieldName,
    );
};
