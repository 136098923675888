import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { translateFadeAnimation } from '@interacta-shared/ui';
import { ICustomButton } from '@modules/core/models/custom-button/custom-button.models';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-light-box-header-buttons',
    templateUrl: './light-box-header-buttons.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        translateFadeAnimation('fadeLeft', '20px', null, 'in', 200, 100),
    ],
})
export class LightBoxHeaderButtonsComponent implements OnChanges {
    @Input() isInfoActive = false;
    @Input() showDownload = false;
    @Input() showOpenInDrive = false;
    @Input() isSearchBarActive = false;
    @Input() canShowSearchBar = false;
    @Input() canEdit = true;

    @Input() attachment!: IAttachment;
    @Input() customButtons: ICustomButton[] = [];

    @Output() download = new EventEmitter<void>();
    @Output() openInDrive = new EventEmitter<void>();
    @Output() info = new EventEmitter<void>();
    @Output() favorite = new EventEmitter<void>();
    @Output() closeDialog = new EventEmitter<void>();
    @Output() editImage = new EventEmitter<void>();
    @Output() openSearchBar = new EventEmitter<void>();

    expanded = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.attachment || changes.customButtons) {
            this.expanded = false;
        }
    }
}
