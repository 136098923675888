import {
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { firstLoading, mapArrayById, uuid } from '@interacta-shared/util';
import { requiredList } from '@interacta-shared/util-common';
import { difference } from '@modules/core/helpers/generic.utils';
import {
    extractGroupsFromMembers,
    extractUsersFromMembers,
    wrapMember,
    wrapMembers,
} from '@modules/core/models/member/member.utils';
import {
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { attachmentsToAttachmentList } from '@modules/post/models/attachment/attachment.utils';
import { isPast } from 'date-fns';
import { emptyTaskFilters } from './filter-task.model';
import {
    FeedbackTaskState,
    GenericTask,
    ISubTask,
    ITaskCreate,
    ITaskEdit,
    ITaskType,
    PriorityType,
    StandardTask,
    TaskList,
    TaskState,
} from './task.model';

export const emptyTaskList = (): TaskList => ({
    list: [],
    isFetching: false,
    nextPageToken: firstLoading(),
    filter: emptyTaskFilters(),
    totalCount: 0,
    assignedToMeExpiringTasksCount: 0,
    assignedToMeExpiredTasksCount: 0,
    expiredTasksCount: 0,
    expiringTasksCount: 0,
    totalTasksCount: 0,
    assignedToMeTasksCount: 0,
    openTasksCount: 0,
    assignedToMeMinTasksExpirationDate: undefined,
});

export const fromFormGroupToTask = (
    formGroup: UntypedFormGroup,
): StandardTask => {
    const subtasks: ISubTask[] = (
        <Array<string>>formGroup.controls.subtaskFormArray.value || []
    ).map((t: string) => ({
        description: t,
        state: TaskState.OPEN,
    }));

    const assigneeUser = formGroup.controls.assigneeControl.value
        ? extractUsersFromMembers(
              formGroup.controls.assigneeControl.value,
          )[0] ?? null
        : null;

    const assigneeGroup = formGroup.controls.assigneeControl.value
        ? extractGroupsFromMembers(
              formGroup.controls.assigneeControl.value,
          )[0] ?? null
        : null;

    const watcherUsers = formGroup.controls.viewersControl.value
        ? extractUsersFromMembers(formGroup.controls.viewersControl.value)
        : [];
    const watcherGroups = formGroup.controls.viewersControl.value
        ? extractGroupsFromMembers(formGroup.controls.viewersControl.value)
        : [];

    return {
        id: null,
        type: ITaskType.STANDARD,
        assigneeUser,
        assigneeGroup,
        watcherUsers,
        watcherGroups,
        descriptionDelta: formGroup.controls.descriptionControl.value,
        title: formGroup.controls.titleControl.value,
        expiration: {
            localDatetime: formGroup.controls.dateTimeControl.value,
            timezone: formGroup.controls.timezoneControl.value,
            zonedDatetime: formGroup.controls.dateTimeControl.value,
        },
        priority: formGroup.controls.priorityControl.value,
        subTasks: subtasks,
        deleted: false,
        attachmentsList: fromFormGroupToAttachmentList(formGroup),
        state: TaskState.OPEN,
    };
};

export const fromFormGroupToAttachmentList = (
    formGroup: UntypedFormGroup,
): IListAttachments => {
    const attachments = formGroup.controls.attachments.value || [];
    return attachmentsToAttachmentList(attachments);
};

export const fromFormGroupToTaskCreate = (
    formGroup: UntypedFormGroup,
): ITaskCreate => {
    const attachments = formGroup.controls.attachments.value || [];
    const attachmentsList = attachmentsToAttachmentList(attachments);

    return {
        ...fromFormGroupToTask(formGroup),
        clientUid: uuid(),
        attachmentsList,
    };
};

export const fromTaskToFormGroup = (task: StandardTask): UntypedFormGroup => {
    const watcherUsers = task.watcherUsers
        ? wrapMembers(task.watcherUsers)
        : [];
    const watcherGroups = task.watcherGroups
        ? wrapMembers(task.watcherGroups)
        : [];

    const assigneeUser = task.assigneeUser
        ? wrapMember(task.assigneeUser)
        : null;
    const assigneeGroup = task.assigneeGroup
        ? wrapMember(task.assigneeGroup)
        : null;
    const assignee = assigneeUser ?? assigneeGroup;

    return new UntypedFormGroup({
        titleControl: new UntypedFormControl(task.title ?? '', [
            Validators.required,
        ]),
        descriptionControl: new UntypedFormControl(task.descriptionDelta ?? ''),
        dateTimeControl: new UntypedFormControl(
            task.expiration?.localDatetime ?? null,
            [Validators.required],
        ),
        assigneeControl: new UntypedFormControl(assignee ? [assignee] : [], [
            requiredList,
        ]),
        viewersControl: new UntypedFormControl([
            ...watcherUsers,
            ...watcherGroups,
        ]),
        priorityControl: new UntypedFormControl(
            task.priority ?? PriorityType.NONE,
        ),
        subtaskFormArray: new UntypedFormArray(
            task.subTasks
                ? task.subTasks.map(
                      (st) => new UntypedFormControl(st.description),
                  )
                : [],
        ),
        timezoneControl: new UntypedFormControl(
            task.expiration?.timezone ?? null,
        ),
        attachments: new UntypedFormControl(
            task.attachmentsList?.attachments ?? [],
        ),
    });
};

export const fromFormGroupToTaskEdit = (
    formGroup: UntypedFormGroup,
    sourceTask: StandardTask,
): ITaskEdit => {
    const watcherUsers = formGroup.controls.viewersControl.value
        ? extractUsersFromMembers(formGroup.controls.viewersControl.value)
        : [];

    const watcherGroups = formGroup.controls.viewersControl.value
        ? extractGroupsFromMembers(formGroup.controls.viewersControl.value)
        : [];
    const watcherUsersIdsSet = new Set(mapArrayById(watcherUsers) || []);
    const watcherGroupsIdsSet = new Set(mapArrayById(watcherGroups) || []);
    const attachmentsIdsSet = new Set(
        mapArrayById(
            (formGroup.controls.attachments.value as IAttachment[]).filter(
                (a) => !a.inDeleting,
            ),
        ) || [],
    );

    const actualWatcherUserIdsSet = new Set(
        mapArrayById(sourceTask.watcherUsers || []) || [],
    );
    const actualWatcherGroupIdsSet = new Set(
        mapArrayById(sourceTask.watcherGroups || []) || [],
    );

    const actualAttachmentsIdsSet = new Set(
        mapArrayById(sourceTask.attachmentsList?.attachments || []) || [],
    );

    const addWatcherUserIds = Array.from(
        difference(watcherUsersIdsSet, actualWatcherUserIdsSet),
    );
    const addWatcherGroupIds = Array.from(
        difference(watcherGroupsIdsSet, actualWatcherGroupIdsSet),
    );
    const removeWatcherUserIds = Array.from(
        difference(actualWatcherUserIdsSet, watcherUsersIdsSet),
    );
    const removeWatcherGroupIds = Array.from(
        difference(actualWatcherGroupIdsSet, watcherGroupsIdsSet),
    );

    const attachmentList = fromFormGroupToAttachmentList(formGroup);

    const addAttachments = Array.from(
        difference(attachmentsIdsSet, actualAttachmentsIdsSet),
    );

    const removeAttachmentIds = Array.from(
        difference(actualAttachmentsIdsSet, attachmentsIdsSet),
    );

    return {
        ...fromFormGroupToTask(formGroup),
        id: sourceTask.id,
        occToken: sourceTask.occToken,
        addAttachments: attachmentList.attachments.filter(
            (a) => addAttachments.indexOf(a.id) >= 0,
        ),
        updateAttachments: [],
        removeAttachmentIds,
        addWatcherUserIds,
        addWatcherGroupIds,
        removeWatcherGroupIds,
        removeWatcherUserIds,
    };
};

export const isExpired = (task: GenericTask): boolean =>
    [
        TaskState.OPEN,
        FeedbackTaskState.OPEN,
        FeedbackTaskState.IN_PROGRESS,
    ].includes(task.state) &&
    !!task.expiration?.zonedDatetime &&
    isPast(task.expiration.zonedDatetime);
