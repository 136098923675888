<ng-container
    *ngIf="{
        state: control | inputState: isReadonly | async,
        showAIButton: showAIContentGenButton$ | async
    } as $"
>
    @if ($.state; as state) {
        <div
            class="notranslate flex"
            injAttachmentCopyPaste
            [canPasteFile]="canPasteFile"
            (uploadFiles)="copiedFiles.emit($event)"
        >
            @if (withToolbar) {
                <quill-editor
                    class="min-w-0 grow overflow-hidden break-normal"
                    classes="ql-comment-editor"
                    format="object"
                    injMentionEventHandler
                    [class]="classes"
                    [formControl]="control"
                    [mentionClickEnabled]="mentionClickEnabled"
                    [modules]="quillModule"
                    [ngClass]="{
                        'block w-full rounded-lg border-2 p-8 pb-0':
                            withBorders,
                        'border-border-error': withBorders && state.invalid,
                        'border-border-300 focus-within:border-text':
                            withBorders && !state.invalid,
                        'bg-surface-100 text-text-disabled': state.disabled,
                        'bg-surface-A': !state.disabled
                    }"
                    [placeholder]="placeholder"
                    [readOnly]="state.disabled"
                    (onBlur)="blurEmitter.emit()"
                    (onEditorCreated)="onEditorCreated($event)"
                >
                    <div quill-editor-toolbar>
                        <interacta-quill-toolbar
                            [aiEnabled]="$.showAIButton"
                            [disableButtons]="state.disabled"
                            [tableEnabled]="this.tableEnabled"
                            [tagEnabled]="!!this.enableHashtagAutocomplete"
                            [userMentionEnabled]="
                                !!this.enableMemberAutocomplete
                            "
                            [withMarginLeft]="withMarginLeft"
                            (addEmoji)="addEmoji($event)"
                            (addTable)="addEmptyTable($event)"
                            (aiClick)="aiClick()"
                            (mentionClick)="addAnnotation($event)"
                        />
                    </div>
                </quill-editor>
            } @else {
                <quill-editor
                    class="grow break-all"
                    classes="ql-comment-editor"
                    format="object"
                    [class]="classes"
                    [formControl]="control"
                    [modules]="{
                        toolbar: false,
                        keyboard: quillKeyboardModule,
                        mention: mentionModule
                    }"
                    [ngClass]="{
                        'block w-full rounded-lg border-2 p-8 pb-0':
                            withBorders,
                        'border-border-error': withBorders && state.invalid,
                        'border-border-300 focus-within:border-text':
                            withBorders && !state.invalid,
                        'bg-surface-100 text-text-disabled': state.disabled
                    }"
                    [placeholder]="placeholder"
                    [readOnly]="state.disabled"
                    (onBlur)="blurEmitter.emit()"
                    (onEditorCreated)="onEditorCreated($event)"
                />
            }
        </div>
    }
</ng-container>
