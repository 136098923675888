import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageComponent, Size } from '@interacta-shared/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'interacta-no-result',
    templateUrl: './no-result.component.html',
    imports: [ImageComponent, NgClass, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoResultComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() size: Extract<Size, 'small' | 'regular' | 'large'> = 'regular';
    @Input({ required: true }) imageName!:
        | 'no-results'
        | 'empty-page'
        | 'gardening'
        | 'reading'
        | 'admin'
        | 'attachments';
    @Input() format: 'png' | 'webp' = 'webp';
    @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
    @Input() textAlign: 'left' | 'center' | 'right' = 'left';
    @Input() textSize: 'small' | 'medium' = 'medium';
    @Input() imgSrc: 'pro-tip' | 'doodles' = 'doodles';
    @Input() lockedColor?: 'dark' | 'light';
}
