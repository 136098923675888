<div
    class="flex"
    [ngClass]="{
        'flex-col': orientation === 'vertical',
        'flex-wrap': orientation === 'horizontal',
        'gap-x-8': horizontalSpacing === 'regular',
        'gap-x-16': horizontalSpacing === 'large',
        'gap-y-8': verticalSpacing === 'regular',
        'gap-y-16': verticalSpacing === 'large'
    }"
>
    <ng-content></ng-content>
</div>
