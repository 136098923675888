import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ICustomButton } from '@modules/core/models/custom-button/custom-button.models';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { isFileAttachment } from '@modules/post/models/attachment/attachment.utils';

@Component({
    selector: 'interacta-light-box-header',
    templateUrl: './light-box-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightBoxHeaderComponent implements OnInit {
    @Input() attachment: IAttachment | null = null;
    @Input() isInfoActive = false;
    @Input() isSearchBarActive = false;
    @Input() canShowSearchBar = false;
    @Input() canEdit = false;
    @Input() customButtons: ICustomButton[] = [];

    @Output() download = new EventEmitter<IAttachment>();
    @Output() openInDrive = new EventEmitter<IAttachment>();
    @Output() info = new EventEmitter<void>();
    @Output() favorite = new EventEmitter<void>();
    @Output() closeDialog = new EventEmitter<void>();
    @Output() editImage = new EventEmitter<void>();
    @Output() openSearchBar = new EventEmitter<void>();

    isFileAttachment = false;

    ngOnInit(): void {
        this.isFileAttachment = this.attachment
            ? isFileAttachment(this.attachment)
            : false;
    }
}
