<interacta-dialog
    size="regular"
    [customRegularHeight]="true"
    [isOpen]="isDialogOpen"
    (closing)="closeDialog.emit()"
>
    <span class="typo-t6" title>{{
        'ADMIN.LABEL_OTHER_LANGUAGES' | translate
    }}</span>
    <interacta-dialog-description class="pb-16">{{
        'TRANSLATION.LABEL_TRANSLATIONS_ALL_LANGUAGES' | translate
    }}</interacta-dialog-description>
    <div class="flex min-h-0 flex-col space-y-16 overflow-auto pr-4" content>
        <div *ngFor="let language of languages; let last = last">
            <interacta-input-translation-v2
                [defaultLanguage]="defaultLanguage"
                [fieldName]="fieldName"
                [form]="localForm"
                [inputType]="inputType"
                [isFieldRequired]="isFieldRequired"
                [language]="language"
                [plainTextMaxLength]="plainTextMaxLength"
                [readonly]="readonly"
                [showOtherLanguages]="false"
                [withBorders]="true"
                [withMarginLeft]="true"
            >
            </interacta-input-translation-v2>
        </div>
    </div>

    <interacta-dialog-buttons>
        <button
            appearance="ghost"
            interacta-dialog-button
            type="button"
            [label]="'BUTTON.LABEL_BUTTON_CANCEL' | translate"
            (click)="closeDialog.emit()"
        ></button>
        <button
            appearance="fill"
            interacta-dialog-button
            type="button"
            [bgColor]="'primary'"
            [label]="'BUTTON.LABEL_BUTTON_CONFIRM' | translate"
            (click)="closeDialog.emit(localForm)"
        ></button>
    </interacta-dialog-buttons>
</interacta-dialog>
