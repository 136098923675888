import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    inject,
} from '@angular/core';
import { Member } from '@modules/core/models/member/member.model';
import {
    isMemberGroup,
    isMemberUser,
} from '@modules/core/models/member/member.utils';
import { GroupInfoV2Service } from '@modules/group-info/group-info-v2.service';
import { MemberClickBehavior } from '@modules/shared-v2/components/member/member.component';
import { ProfilePreviewDialogStateService } from '@modules/state/services/profile-preview-dialog-state.service';

// Versione amministativa del componente member, aggiunge funzionalità nuove come la chip dello stato dell'utente
// Un giorno andrà mergiata con member per diventare un'unica entità

@Component({
    selector: 'interacta-admin-v2-member',
    templateUrl: './admin-v2-member.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminV2MemberComponent {
    @Input({ required: true }) member!: Member;
    @Input() customInfo: string | null = null;
    @Input() memberClickBehavior: Omit<MemberClickBehavior, 'profile'> =
        'preview';
    @Input() disabled = false;
    @Input() infoTemplate?: TemplateRef<any>;

    @Output()
    memberSelected = new EventEmitter<Member>();

    private profilePreviewDialogStateService = inject(
        ProfilePreviewDialogStateService,
    );
    private readonly groupInfoService = inject(GroupInfoV2Service);

    readonly isMemberUser = isMemberUser;
    readonly isMemberGroup = isMemberGroup;

    selectMember(): void {
        if (this.memberClickBehavior === 'preview') {
            if (isMemberUser(this.member)) {
                this.profilePreviewDialogStateService
                    .open(this.member.user.id)
                    .subscribe();
            } else {
                this.groupInfoService
                    .openDialog(this.member.group.id)
                    .subscribe();
            }
        } else {
            this.memberSelected.emit(this.member);
        }
    }
}
