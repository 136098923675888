import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';

@Component({
    selector: 'interacta-light-box-mini-slider',
    templateUrl: './light-box-mini-slider.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightBoxMiniSliderComponent {
    @Input() currentIdx = 0;
    @Input() attachmentsCount = 0;

    @Output() previous = new EventEmitter<void>();
    @Output() next = new EventEmitter<void>();
}
