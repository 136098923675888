import {
    adminV2WorkspaceAdmins,
    adminV2WorkspaceCommunities,
    adminV2WorkspaceMembers,
    adminV2WorkspaceSettings,
} from '@modules/admin-v2-routing/admin-v2-routes';
import {
    AdminV2ColumnVisibility,
    AdminV2MenuItem,
} from '@modules/admin-v2-shared/models/admin-v2-shared.model';
import {
    AdminV2WorkspaceFiltersLabel,
    AdminV2WorkspaceRow,
    AdminV2WorkspaceSort,
} from '../models/admin-v2-workspace.model';

export const DefaultWorkspaceColumnVisibility: AdminV2ColumnVisibility<AdminV2WorkspaceRow> =
    {
        name: true,
        communities: true,
        lastModifyTimestamp: true,
        lastModifyUser: false,
        creationTimestamp: false,
        creatorUser: false,
        adminMembers: false,
        membersCount: true,
    };

export const DefaultWorkspaceSortOrder: AdminV2WorkspaceSort = {
    sortColumn: 'name',
    orderDesc: false,
};

const filtersLabelRoot = 'ADMIN_V2.WORKSPACES.FILTERS';
export const WorkspaceFiltersLabels: AdminV2WorkspaceFiltersLabel = {
    search: `${filtersLabelRoot}.SEARCH`,
    communities: `${filtersLabelRoot}.COMMUNITY`,
    adminMembers: `${filtersLabelRoot}.ADMIN_MEMBER`,
};

const labelRootMenu = 'ADMIN_V2.WORKSPACES.DETAIL.MENU';
export const WorkspaceMenuItems: AdminV2MenuItem[] = [
    {
        label: `${labelRootMenu}.SETTINGS`,
        url: adminV2WorkspaceSettings,
    },
    {
        label: `${labelRootMenu}.ADMINISTRATORS`,
        url: adminV2WorkspaceAdmins,
    },
    {
        label: `${labelRootMenu}.MEMBERS`,
        url: adminV2WorkspaceMembers,
    },
    {
        label: `${labelRootMenu}.COMMUNITIES`,
        url: adminV2WorkspaceCommunities,
    },
];
