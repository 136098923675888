import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { formatDateUsingIntl } from '@interacta-shared/util-common';

const tooltipDateFormat: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
};

/**
 * See https://injenia.atlassian.net/l/cp/r6L3KnZu
 *
 */
@Pipe({
    name: 'dateTooltip',
})
export class DateTooltipPipe implements PipeTransform {
    constructor(private configurationService: ConfigurationService) {}

    transform(date: Date | null | undefined): string {
        return date
            ? formatDateUsingIntl(
                  date,
                  tooltipDateFormat,
                  'PPPPpp',
                  this.configurationService.getCurrentLanguage().code,
              )
            : '';
    }
}
