import { DigitalWorkplaceDefinition } from '@modules/digital-workplace/models/digital-workplace.model';
import { createAction, props } from '@ngrx/store';

export type HomePagesError = any;

export const fetchHomeDefinitions = createAction(
    '[Home Pages] Fetch Home Definitions',
    props<{
        force: boolean;
    }>(),
);

export const fetchHomeDefinitionsSuccess = createAction(
    '[Home Pages API] Fetch Home Definitions Success',
    props<{
        homeDefinitions: DigitalWorkplaceDefinition[];
    }>(),
);

export const fetchHomeDefinitionsError = createAction(
    '[Home Pages API] Fetch Home Definitions Error',
    props<{
        error: HomePagesError;
    }>(),
);

export const markHomeAsViewed = createAction(
    '[Home Pages] Mark Home as viewed',
    props<{
        id: DigitalWorkplaceDefinition['id'];
    }>(),
);

export const markHomeAsViewedSuccess = createAction(
    '[Home Pages API] Mark Home as viewed Success',
    props<{
        id: DigitalWorkplaceDefinition['id'];
        viewedTimestamp: number;
    }>(),
);

export const markHomeAsViewedError = createAction(
    '[Home Pages API] Mark Home as viewed Error',
    props<{
        id: DigitalWorkplaceDefinition['id'];
        error: HomePagesError;
    }>(),
);

export const clear = createAction('[Home Pages] Clear');

export const invalidate = createAction('[Home Pages] Invalidate');
