import {
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
} from '@angular/forms';
import {
    fromDateToISO8601,
    toDateFromISO8601,
    toDateTimeFromString,
} from '@interacta-shared/data-access-configuration';
import {
    CURRENT_USER_SEARCH_VALUE,
    CURRENT_USER_SEARCH_VALUE_LABEL,
    EMPTY_SEARCH_VALUE,
    Index,
    isDefined,
    mapArrayById,
    mapById,
} from '@interacta-shared/util';
import {
    dateMustBeGreaterThanOrEqual,
    dateMustBeLessThanOrEqual,
    maxLengthDelta,
    maxLengthList,
    minLengthDelta,
    minLengthList,
} from '@interacta-shared/util-common';
import { IUser, IUsersGroup } from '@modules/core';
import { CustomValidators } from '@modules/core/helpers/custom.validators';
import { Delta2Server } from '@modules/core/helpers/delta/delta-2-server.class';
import { DeltaUtility } from '@modules/core/helpers/delta/delta-utility.class';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import { Member } from '@modules/core/models/member/member.model';
import { isMember, wrapMember } from '@modules/core/models/member/member.utils';
import { UserExtendedDeserialize } from '@modules/core/models/user-group.model';
import { UserDeserilize } from '@modules/core/models/user.model';
import { IconRating } from '@modules/core/models/utility.model';
import { CustomFieldsConfigurationBase } from '@modules/custom-fields-configuration/models/custom-fields-configuration.model';
import { DateCustomFieldFilterOutput } from '@modules/post-filters/components/dashboard-filters-date-time/dashboard-filters-date-time.component';
import {
    toAttachmentDetails,
    toAttachmentsList,
} from '@modules/post/models/attachment/attachment.deserialize';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { fromAttachmentFilePicker } from '@modules/post/models/attachment/attachment.serialize';
import { getUpdateAttachments } from '@modules/post/models/attachment/attachment.utils';
import {
    CustomFieldServerData,
    ExtendedCustomFieldServerData,
    FilterType,
    IPostFiltersCustomField,
    ServerFilterByType,
} from '@modules/post/models/filter-post/filter-post.model';
import { toPostLink } from '@modules/post/models/post-link/post-link-break-circular.deserialize';
import { TranslateService } from '@ngx-translate/core';
import produce from 'immer';
import { Delta } from 'quill/core';
import { toCatalogEntryItem } from '../catalog/catalog.deserialize';
import {
    ConfigurableFieldType,
    ConfigurableFieldTypeInfo,
    ConfigurableFieldTypes,
    CustomFieldType,
    FieldType,
    ICustomField,
    ICustomFieldAdminValidation,
    ICustomFieldDefinition,
    ICustomFieldMetadata,
    ICustomFieldMetadataBase,
    ILink,
    IValidationMetadata,
    METADATA_QR_CODE_DOT_NOTATION,
    METADATA_QR_CODE_SECRET_DOT_NOTATION,
    MetadataGenericListType,
    ValidationType,
} from './custom-metadata.model';

export function getValidatorByType(
    fieldType: FieldType,
    validation: IValidationMetadata,
    metadata: ICustomFieldMetadata = {},
): ValidatorFn | null {
    switch (validation.validationType) {
        case ValidationType.REQUIRED_FIELD:
            if (
                isGenericListFieldByInputTypesNoWrap(fieldType, metadata, [
                    MetadataGenericListType.FILE_PICKER,
                ])
            ) {
                return CustomValidators.filePickerRequired();
            } else
                return fieldType === FieldType.LINK
                    ? CustomValidators.requiredLink
                    : CustomValidators.required;
        case ValidationType.MIN_VALUE:
            if (fieldType === FieldType.DATE) {
                const date = toDateFromISO8601(validation.parameter ?? '');
                return date ? dateMustBeGreaterThanOrEqual(date) : null;
            } else if (fieldType === FieldType.DATETIME) {
                const date = toDateTimeFromString(validation.parameter ?? '');
                return date ? dateMustBeGreaterThanOrEqual(date, true) : null;
            } else {
                return validation.parameter != null
                    ? CustomValidators.min(+validation.parameter)
                    : null;
            }
        case ValidationType.MAX_VALUE:
            if (fieldType === FieldType.DATE) {
                const date = toDateFromISO8601(validation.parameter ?? '');
                return date ? dateMustBeLessThanOrEqual(date) : null;
            } else if (fieldType === FieldType.DATETIME) {
                const date = toDateTimeFromString(validation.parameter ?? '');
                return date ? dateMustBeLessThanOrEqual(date, true) : null;
            } else {
                return validation.parameter != null
                    ? CustomValidators.max(+validation.parameter)
                    : null;
            }
        case ValidationType.MIN_LENGTH:
            if (validation.parameter != null) {
                if (
                    isGenericListFieldByInputTypesNoWrap(fieldType, metadata, [
                        MetadataGenericListType.FILE_PICKER,
                    ])
                ) {
                    return CustomValidators.filePickerMinLength(
                        +validation.parameter,
                    );
                } else if (
                    fieldType === FieldType.SELECT_MULTIPLE_VALUES ||
                    fieldType === FieldType.GENERIC_LIST
                ) {
                    return minLengthList(+validation.parameter);
                } else if (fieldType === FieldType.HTML) {
                    return minLengthDelta(+validation.parameter);
                } else {
                    return CustomValidators.minLength(+validation.parameter);
                }
            } else {
                return null;
            }
        case ValidationType.MAX_LENGTH:
            if (validation.parameter != null) {
                if (
                    isGenericListFieldByInputTypesNoWrap(fieldType, metadata, [
                        MetadataGenericListType.FILE_PICKER,
                    ])
                ) {
                    return CustomValidators.filePickerMaxLength(
                        +validation.parameter,
                    );
                } else if (
                    fieldType === FieldType.SELECT_MULTIPLE_VALUES ||
                    fieldType === FieldType.GENERIC_LIST
                ) {
                    return maxLengthList(+validation.parameter);
                } else if (fieldType === FieldType.HTML) {
                    return maxLengthDelta(+validation.parameter);
                } else {
                    return CustomValidators.maxLength(+validation.parameter);
                }
            } else {
                return null;
            }
        case ValidationType.ENABLED:
            return CustomValidators.enabled;
        case ValidationType.ADMITTED_MIME_TYPES: {
            const mimeTypesArray = Array.isArray(validation.validationParameter)
                ? validation.validationParameter
                : [];
            return CustomValidators.checkAdmittedMimeTypes(mimeTypesArray);
        }
        default:
            console.error(
                'Unknown validation type: ',
                validation.validationType,
            );
            return null;
    }
}

function getFieldTypeValidators(fieldType: FieldType): ValidatorFn[] {
    switch (fieldType) {
        case FieldType.INT:
        case FieldType.BIGINT:
            return [
                CustomValidators.max(Number.MAX_SAFE_INTEGER),
                CustomValidators.checkInt(),
            ];
        case FieldType.DECIMAL:
            return [CustomValidators.checkDecimal()];
        case FieldType.DATE:
            return [CustomValidators.checkDate()];
        case FieldType.DATETIME:
            return [CustomValidators.checkDate()];
        case FieldType.STRING:
        case FieldType.LINK:
            return [CustomValidators.linkLabel];
        default:
            return [];
    }
}

export function getValidators(field: ICustomFieldDefinition): ValidatorFn[] {
    const deprecatedRequiredValidator = field.required
        ? [CustomValidators.required]
        : [];

    const typeValidators = getFieldTypeValidators(field.type);

    const withoutActivation = (field?.validations ?? []).filter(
        (v) => !v.activation,
    );

    const standardValidators = withoutActivation.map((validation) =>
        getValidatorByType(field.type, validation, field.metadata),
    );

    return [
        ...deprecatedRequiredValidator,
        ...typeValidators,
        ...standardValidators,
    ].filter(isDefined);
}

/**
 * Metodo per ottenere i valori da passare alla form di creazione/modifica
 */
export function getInputFieldValue(
    inputValue: any,
    field: ICustomFieldDefinition,
): any {
    if (inputValue == null) {
        return null;
    }
    let value = null;
    switch (field.type) {
        case FieldType.DATE:
            if (Array.isArray(inputValue)) {
                value = inputValue.map((v) => (v ? new Date(v) : null));
            } else {
                value = new Date(inputValue);
            }
            // for date input field the time must be ignored
            // elsewhere there could be errors of validation server side
            break;
        case FieldType.DATETIME:
            value = new Date(inputValue);
            break;
        case FieldType.HTML:
            value = inputValue ? new Server2Delta().process(inputValue) : null;
            break;
        case FieldType.SELECT:
        case FieldType.SELECT_HIERARCHICAL:
            value =
                inputValue && field.enumValues
                    ? field.enumValues.find((i) => i.id === inputValue)
                    : inputValue;
            break;
        case FieldType.SELECT_MULTIPLE_VALUES:
            value =
                inputValue && inputValue.length > 0 && field.enumValues
                    ? field.enumValues.filter((i) =>
                          (<Array<number>>inputValue).includes(i.id),
                      )
                    : inputValue;
            break;
        case FieldType.GENERIC_LIST:
            value = getGenericListInputFieldValue(
                inputValue,
                field.metadata?.generic_entity_list_config_id,
                field.id,
                field.validations,
                field.metadata?.hierarchical,
                field.leaf,
            );
            break;
        default:
            value = inputValue;
    }
    return value;
}

/**
 * Metodo per ottenere i valori da mostrare nel dettaglio post
 */
export function getViewFieldValue(
    originValue: any,
    field: ICustomFieldDefinition,
): unknown {
    let value: unknown = null;
    switch (field.type) {
        case FieldType.SELECT:
        case FieldType.SELECT_HIERARCHICAL:
            value =
                (<Array<any>>field.enumValues)?.find(
                    (e) => +e.id === +originValue,
                ) ?? null;
            break;
        case FieldType.SELECT_MULTIPLE_VALUES:
            value = originValue?.length
                ? (<Array<any>>originValue || []).map((id) => {
                      return field.enumValues?.find((e) => +e.id === +id);
                  })
                : null;

            break;
        case FieldType.HTML:
            value =
                originValue && originValue.length
                    ? new Server2Delta().process(originValue)
                    : null;
            break;
        case FieldType.LINK:
            {
                const linkValue: ILink = { ...originValue };
                value = linkValue ? linkValue.label || linkValue.url : null;
            }
            break;
        case FieldType.GENERIC_LIST:
            value = getGenericListViewFieldValue(originValue, field);
            break;
        default:
            value = originValue;
    }
    return value;
}

/**
 * Metodo per ottenere i valori da passare al be per il salvataggio
 */
export function getServerValue(
    inputValue: any,
    field: ICustomFieldDefinition,
    isFilterMode = false,
): unknown {
    if (inputValue == null) {
        return null;
    }
    let value: unknown = null;
    switch (field.type) {
        case FieldType.DATE:
            {
                const updatedInputValue = isFilterMode
                    ? (inputValue as DateCustomFieldFilterOutput).value
                    : inputValue;

                if (Array.isArray(updatedInputValue)) {
                    value = updatedInputValue.map((v) =>
                        v ? fromDateToISO8601(v) : null,
                    );
                } else {
                    const date = updatedInputValue as Date;
                    const now_utc = date ? fromDateToISO8601(date) : null;
                    value = now_utc;
                }
            }
            break;
        case FieldType.DATETIME: {
            const updatedInputValue = isFilterMode
                ? (inputValue as DateCustomFieldFilterOutput).value
                : inputValue;
            if (Array.isArray(updatedInputValue)) {
                value = (<Array<Date | null>>updatedInputValue).map((v) =>
                    v ? v.getTime() : null,
                );
            } else {
                value = updatedInputValue
                    ? (updatedInputValue as Date).getTime()
                    : null;
            }
            break;
        }
        case FieldType.BIGINT:
        case FieldType.INT:
            if (isFilterMode) {
                value = inputValue;
            } else {
                value =
                    inputValue != null ? parseInt(inputValue as string) : null;
            }
            break;
        case FieldType.HTML:
            if (isFilterMode) {
                //while filtering, html fields are plain text
                value =
                    (inputValue as string)?.trim().length > 0
                        ? inputValue
                        : null;
            } else {
                value = inputValue
                    ? new Delta2Server().process(inputValue)
                    : null;
            }
            break;

        case FieldType.LINK:
            if (isFilterMode) {
                //while filtering, link fields are plain text
                value =
                    (inputValue as string)?.trim().length > 0
                        ? inputValue
                        : null;
            } else {
                value = inputValue;
            }
            break;
        case FieldType.STRING:
        case FieldType.TEXT:
            value =
                (inputValue as string)?.trim().length > 0 ? inputValue : null;
            break;
        case FieldType.GENERIC_LIST:
            value = getGenericListServerValue(
                inputValue,
                field.metadata?.generic_entity_list_config_id,
                field.id,
            );
            break;
        case FieldType.SELECT:
            value = mapById(inputValue);
            break;
        case FieldType.SELECT_MULTIPLE_VALUES:
        case FieldType.SELECT_HIERARCHICAL:
            value = Array.isArray(inputValue)
                ? mapArrayById(inputValue)
                : mapById(inputValue);
            break;
        default:
            value = inputValue;
    }
    return value;
}

export function isCatalogGenericListField(
    field: Pick<ICustomFieldDefinition, 'type' | 'metadata'>,
): boolean {
    return isGenericListFieldByInputType(
        field,
        MetadataGenericListType.CATALOG,
    );
}

export function isGenericListFieldByInputType(
    field: Pick<ICustomFieldDefinition, 'type' | 'metadata'>,
    type: MetadataGenericListType,
): boolean {
    return isGenericListFieldByInputTypesNoWrap(field.type, field.metadata, [
        type,
    ]);
}

export function isGenericListFieldByInputTypes(
    field: Pick<ICustomFieldDefinition, 'type' | 'metadata'>,
    types: MetadataGenericListType[],
): boolean {
    return isGenericListFieldByInputTypesNoWrap(
        field.type,
        field.metadata,
        types,
    );
}

export function isGenericListFieldByInputTypesNoWrap(
    fieldType: FieldType,
    metadata: ICustomFieldMetadataBase,
    metadataGenericListType: MetadataGenericListType[],
): boolean {
    return (
        fieldType === FieldType.GENERIC_LIST &&
        !!metadata?.generic_entity_list_config_id &&
        metadataGenericListType.includes(metadata.generic_entity_list_config_id)
    );
}

// UTILITY FOR FORM CONTROLS

// from data to Form
export function constructFormGroup(
    customFieldsVisible: ICustomField[],
): UntypedFormGroup {
    const group: { [key: string]: UntypedFormControl } = {};

    customFieldsVisible.forEach((field) => {
        const id = field.configuration.id;
        group[id] = new UntypedFormControl(field.inputValue);
    });

    return new UntypedFormGroup(group);
}

export function patchFormGroup(
    formGroup: UntypedFormGroup,
    customFieldsVisible: ICustomField[],
): void {
    const group: Record<string, any> = {};

    customFieldsVisible.forEach((field) => {
        const id = field.configuration.id;
        if (formGroup.controls[id]) {
            group[id] = field.inputValue;
        } else {
            formGroup.addControl(
                `${id}`,
                new UntypedFormControl(field.inputValue),
            );
        }
    });

    formGroup.patchValue(group);
}

// from form to data
export function updateInputValueFromFormGroup(
    formGroup: UntypedFormGroup,
    customFieldsMap: ICustomField[],
    customFieldsMapVisible: ICustomField[] = customFieldsMap,
): ICustomField[] {
    return customFieldsMap.map((field) => {
        const visibleField = customFieldsMapVisible.find(
            (v) => v.configuration.id === field.configuration.id,
        );
        if (visibleField) {
            return {
                ...visibleField,
                inputValue: formGroup.controls[field.configuration.id].value,
            };
        } else {
            return field;
        }
    });
}

/**
 *  Quando in un campo a tendina viene selezionato un valore e poi tolto,
 * il valore risultante è un array vuoto e non null. Il metodo
 * getServerFilterByType costruisce l’oggetto params con un array vuoto.
 * La successiva chiamata al BE va in errore perchè se specifichi un filtro,
 * deve esserci almeno un valore. Per le tendine, [] !== null o undefined */
function checkEmptyObjectToServer(objectToServer: ServerFilterByType): boolean {
    return (
        (Array.isArray(objectToServer.parameters) &&
            objectToServer.parameters?.length === 0 &&
            objectToServer.typeId !== FilterType.ISNULL_OR_IN) ||
        (objectToServer.parameters == null &&
            objectToServer.typeId !== FilterType.IS_EMPTY)
    );
}

// FILTERS OTHERS
export function getCustomFilterServerData({
    definition,
    value,
}: IPostFiltersCustomField): CustomFieldServerData | undefined {
    const objectToServer = getServerFilterByType(definition, value, 'filter');

    if (!objectToServer || checkEmptyObjectToServer(objectToServer)) return;

    return {
        columnId: definition.id,
        parameters: objectToServer.parameters,
        typeId: objectToServer.typeId,
    };
}

// QUICK FILTERS
export function getQuickFiltersCustomData({
    definition,
    value,
}: IPostFiltersCustomField): ExtendedCustomFieldServerData | undefined {
    const objectToServer = getServerFilterByType(
        definition,
        value,
        'quickFilterSave',
    );

    if (!objectToServer || checkEmptyObjectToServer(objectToServer)) return;

    return {
        columnId: definition.id,
        fieldType: objectToServer.fieldType,
        parameters: objectToServer.parameters,
        typeId: objectToServer.typeId,
        optionalData: objectToServer.optionalData,
    };
}

function getServerFilterByType(
    field: ICustomFieldDefinition,
    value: unknown,
    mode: 'filter' | 'quickFilterSave',
): ServerFilterByType | null {
    const parameters = getServerValue(value, field, true);

    if (parameters == null) {
        return null;
    }

    const data: ServerFilterByType = {
        fieldType: field.type,
        typeId: FilterType.LIKE,
        parameters: Array.isArray(parameters) ? parameters : [parameters],
        optionalData: undefined,
    };

    switch (field.type) {
        case FieldType.BIGINT:
        case FieldType.INT:
        case FieldType.DECIMAL:
        case FieldType.RATING:
            data.typeId =
                Array.isArray(value) && value[0] !== value[1]
                    ? FilterType.INTERVAL
                    : value === EMPTY_SEARCH_VALUE
                      ? FilterType.IS_EMPTY
                      : FilterType.EQUAL;
            if (data.typeId === FilterType.IS_EMPTY) {
                data.parameters = undefined;
            }
            break;
        case FieldType.FLAG:
            data.typeId =
                value === EMPTY_SEARCH_VALUE
                    ? FilterType.IS_EMPTY
                    : FilterType.EQUAL;
            if (data.typeId === FilterType.IS_EMPTY) {
                data.parameters = undefined;
            }
            break;
        case FieldType.DATETIME:
        case FieldType.DATE: {
            const dateFilter = value as DateCustomFieldFilterOutput;
            data.typeId = Array.isArray(dateFilter.value)
                ? FilterType.INTERVAL
                : FilterType.EQUAL;

            if (mode === 'quickFilterSave') {
                data.optionalData = {
                    type: dateFilter.type,
                    includeToday: dateFilter.includeToday,
                    daysCount: dateFilter.daysCount,
                };
            }
            break;
        }
        case FieldType.SELECT:
        case FieldType.SELECT_MULTIPLE_VALUES:
        case FieldType.SELECT_HIERARCHICAL:
        case FieldType.GENERIC_LIST: {
            data.typeId = FilterType.IN;

            const paramSet = new Set<unknown>(
                Array.isArray(data.parameters)
                    ? data.parameters
                    : [data.parameters],
            );

            if (paramSet.has(CURRENT_USER_SEARCH_VALUE)) {
                paramSet.delete(CURRENT_USER_SEARCH_VALUE);
                paramSet.add(CURRENT_USER_SEARCH_VALUE_LABEL);
                data.parameters = Array.from(paramSet);
            }

            if (paramSet.has(EMPTY_SEARCH_VALUE)) {
                data.typeId = FilterType.ISNULL_OR_IN;
                paramSet.delete(EMPTY_SEARCH_VALUE);
                data.parameters = Array.from(paramSet);
            }
            break;
        }
    }
    return data;
}

function getGenericListInputFieldValue(
    inputValue: any,
    metadataConfigId: MetadataGenericListType | undefined,
    customFieldId: number,
    validations: IValidationMetadata[],
    isHierarchy: boolean | undefined,
    isLeaf: boolean,
): any {
    let value = null;
    if (metadataConfigId && inputValue != null) {
        switch (metadataConfigId) {
            case MetadataGenericListType.CORE_USER:
                value = (<Array<unknown>>inputValue)
                    .map(UserDeserilize.userDetails)
                    .map(wrapMember);
                break;
            case MetadataGenericListType.CORE_GROUP:
                value = (<Array<unknown>>inputValue)
                    .map(UserExtendedDeserialize.usersGroup)
                    .map(wrapMember);
                break;
            case MetadataGenericListType.CATALOG: {
                const arrayValues = Array.isArray(inputValue)
                    ? inputValue
                    : [inputValue];

                if (
                    isCatalogMultiple(isHierarchy ?? false, isLeaf, validations)
                ) {
                    value = (arrayValues || []).map(toCatalogEntryItem);
                } else {
                    value = arrayValues.length
                        ? toCatalogEntryItem(arrayValues[0])
                        : null;
                }

                break;
            }
            case MetadataGenericListType.POST_LINK:
                value = (<Array<unknown>>inputValue).map(toPostLink);
                break;
            case MetadataGenericListType.FILE_PICKER:
                value = (<Array<unknown>>inputValue).map((a) =>
                    toAttachmentDetails(a),
                );
                break;
            default:
                console.warn(`Found unsupported generic_entity_list_config_id = ${metadataConfigId}
                            for custom field with id = ${customFieldId}`);
        }
    }
    return value;
}

function getGenericListViewFieldValue(
    originValue: any,
    fieldDefinition: ICustomFieldDefinition,
): unknown {
    let value: unknown = [];
    const metadataConfigId =
        fieldDefinition.metadata?.generic_entity_list_config_id;

    if (metadataConfigId && originValue != null) {
        switch (metadataConfigId) {
            case MetadataGenericListType.CORE_USER:
                value = (<Array<any>>originValue || []).map(
                    (a) => (a = UserDeserilize.userDetails(a)),
                );
                break;
            case MetadataGenericListType.CORE_GROUP:
                value = (<Array<any>>originValue || []).map(
                    (a) => (a = UserExtendedDeserialize.usersGroup(a)),
                );
                break;
            case MetadataGenericListType.CATALOG: {
                const arrayValues = Array.isArray(originValue)
                    ? originValue
                    : [originValue];

                value = (arrayValues || []).map(toCatalogEntryItem);
                break;
            }
            case MetadataGenericListType.POST_LINK:
                value = (originValue || []).map(
                    (p: any) => (p = toPostLink(p)),
                );
                break;
            case MetadataGenericListType.FILE_PICKER: {
                const arrayValues = Array.isArray(originValue)
                    ? originValue
                    : [originValue];
                value = toAttachmentsList(
                    arrayValues,
                    arrayValues.length,
                    null,
                    null,
                );
                break;
            }
            default:
                console.warn(`Found unsupported generic_entity_list_config_id = ${metadataConfigId}
                            for custom field with id = ${fieldDefinition.id}`);
        }
    }
    return value;
}

function getGenericListServerValue(
    inputValue: any,
    metadataConfigId: MetadataGenericListType | undefined,
    customFieldId: number,
): unknown {
    let value: unknown;
    switch (metadataConfigId) {
        case MetadataGenericListType.CORE_USER:
        case MetadataGenericListType.CORE_GROUP:
            value = (Array.isArray(inputValue) ? inputValue : [inputValue]).map(
                (m: Member | IUser | IUsersGroup) =>
                    isMember(m) ? m.innerId : m.id,
            );
            break;
        case MetadataGenericListType.POST_LINK:
        case MetadataGenericListType.CATALOG:
            value = mapArrayById(
                Array.isArray(inputValue) ? inputValue : [inputValue],
            );
            break;
        case MetadataGenericListType.FILE_PICKER:
            value = getFilePickerAttachmentServerValue(inputValue);
            break;
        default:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            console.warn(`Found unsupported generic_entity_list_config_id = ${metadataConfigId}
                for custom field with id = ${customFieldId}`);
    }
    return value;
}

function getFilePickerAttachmentServerValue(inputValue: any): unknown {
    const attachments: IAttachment[] = Array.isArray(inputValue)
        ? [...inputValue]
        : [inputValue];

    const updateAttachments = getUpdateAttachments(attachments);
    const updateAttachmentIds = new Set<IAttachment['id']>(
        mapArrayById(updateAttachments),
    );

    const finalAttachmentsArray = attachments
        .filter(
            (a) =>
                !a.isLoadingError && //filtro gli attachments in errore
                !a.inDeleting && //filtro gli eliminati
                !a.isCanceled,
        )
        //aggiorno gli updated attachment con i valori ottenuti
        .map((a) =>
            updateAttachmentIds.has(a.id)
                ? updateAttachments.find((i) => i.id === a.id)
                : a,
        )
        .filter(isDefined)
        .map((a) => fromAttachmentFilePicker(a));

    return finalAttachmentsArray;
}

export function extractCustomFieldTypeFromAdminInputForm(
    input: Pick<
        CustomFieldsConfigurationBase,
        'type' | 'fieldType' | 'metadata' | 'parentId' | 'childIds'
    >,
    localParentId?: Index,
): FieldType {
    if (!input || !input.type) {
        throw new Error(
            'Missing required input in extractCustomFieldTypeFromAdminInputForm',
        );
    }

    let value = null;
    switch (input.type.code) {
        case ConfigurableFieldType.NUMBER:
            value =
                input.metadata?.decimal_digits ?? 0 > 0
                    ? FieldType.DECIMAL
                    : isDefined(input.fieldType) &&
                        input.fieldType === FieldType.INT
                      ? input.fieldType
                      : FieldType.BIGINT;
            break;
        case ConfigurableFieldType.SELECT_SINGLE:
            value = isDefined(input.metadata?.catalog_id)
                ? FieldType.GENERIC_LIST
                : isDefined(input.parentId) ||
                    isDefined(localParentId) ||
                    input.childIds?.length
                  ? FieldType.SELECT_HIERARCHICAL
                  : FieldType.SELECT;
            break;
        case ConfigurableFieldType.SELECT_MULTI:
            value = isDefined(input.metadata?.catalog_id)
                ? FieldType.GENERIC_LIST
                : FieldType.SELECT_MULTIPLE_VALUES;
            break;
        default:
            //se si tratta di campi che hanno un mapping unico
            value = input.type.mapToTypes[0];
    }

    return value;
}

export function getConfigurableFieldTypeInfoFromRecord(
    type: FieldType,
    metadata: ICustomFieldMetadata,
    validations?: ICustomFieldAdminValidation,
): ConfigurableFieldTypeInfo {
    if (!type) {
        throw new Error('field type must be defined');
    }
    let value: ConfigurableFieldTypeInfo | undefined;

    if (type === FieldType.STRING) {
        value = ConfigurableFieldTypes.find(
            (i) =>
                i.code ===
                (metadata?.qr_code === true
                    ? ConfigurableFieldType.QR_CODE
                    : ConfigurableFieldType.STRING),
        );
    } else if (type === FieldType.HTML) {
        value = ConfigurableFieldTypes.find(
            (i) =>
                i.code ===
                (metadata?.generate_post_comment === true
                    ? ConfigurableFieldType.COMMENT
                    : ConfigurableFieldType.HTML),
        );
    } else if (type === FieldType.GENERIC_LIST) {
        switch (metadata?.generic_entity_list_config_id) {
            case MetadataGenericListType.CORE_USER:
                value = ConfigurableFieldTypes.find(
                    (i) => i.code === ConfigurableFieldType.USER_PICKER,
                );
                break;
            case MetadataGenericListType.CORE_GROUP:
                value = ConfigurableFieldTypes.find(
                    (i) => i.code === ConfigurableFieldType.GROUP_PICKER,
                );
                break;
            case MetadataGenericListType.POST_LINK:
                value = ConfigurableFieldTypes.find(
                    (i) => i.code === ConfigurableFieldType.POST_PICKER,
                );
                break;
            case MetadataGenericListType.CATALOG:
                value = ConfigurableFieldTypes.find(
                    (i) =>
                        i.code ===
                        (validations?.maxValue === '1'
                            ? ConfigurableFieldType.SELECT_SINGLE
                            : ConfigurableFieldType.SELECT_MULTI),
                );
                break;
            case MetadataGenericListType.FILE_PICKER:
                value = ConfigurableFieldTypes.find(
                    (i) => i.code === ConfigurableFieldType.FILE_PICKER,
                );
                break;
        }
    } else {
        value = ConfigurableFieldTypes.find(
            (i) => i.mapToTypes.indexOf(type) > -1,
        );
    }
    if (!value) {
        throw new Error(`unknown field type ${type}`);
    }
    return value;
}

export function isCatalogAware(
    fieldTypeInfoCode: ConfigurableFieldType,
): boolean {
    return [
        ConfigurableFieldType.SELECT_SINGLE,
        ConfigurableFieldType.SELECT_MULTI,
    ].includes(fieldTypeInfoCode);
}

export function fieldTypeToString(fieldType: FieldType): string {
    switch (fieldType) {
        case FieldType.INT:
            return 'Int';
        case FieldType.BIGINT:
            return 'Bigint';
        case FieldType.DECIMAL:
            return 'Decimal';
        case FieldType.DATE:
            return 'Date';
        case FieldType.DATETIME:
            return 'Datetime';
        case FieldType.STRING:
            return 'String';
        case FieldType.SELECT:
            return 'Select';
        case FieldType.SELECT_MULTIPLE_VALUES:
            return 'Select Multiple';
        case FieldType.TEXT:
            return 'Text';
        case FieldType.FLAG:
            return 'Flag';
        case FieldType.HTML:
            return 'HTML';
        case FieldType.RATING:
            return 'Rating';
        case FieldType.SELECT_HIERARCHICAL:
            return 'Select Hierarchical';
        case FieldType.LINK:
            return 'Link';
        case FieldType.GENERIC_LIST:
            return 'Generic List';
        default:
            console.error('Unhandled FieldType in fieldTypeToString');
            return '';
    }
}

export function createCustomField(
    serverValue: unknown,
    definition: ICustomFieldDefinition,
): ICustomField {
    return {
        configuration: definition,
        serverValue,
        inputValue: getInputFieldValue(serverValue, definition),
        viewValue: getViewFieldValue(serverValue, definition),
        validators: getValidators(definition),
    };
}

export function isCustomValueBlank(value: any): boolean {
    return !isDefined(value) || value.length === 0 || value.totalCount === 0;
}

const NOT_CONFIGURABLE_METADATA_KEYS = [
    METADATA_QR_CODE_DOT_NOTATION,
    METADATA_QR_CODE_SECRET_DOT_NOTATION,
];

function knownMetadataKeys(): string[] {
    const base: Required<ICustomFieldMetadataBase> = {
        feedback_icon_type: IconRating.STAR,
        feedback_max_value: 0,
        feedback_min_value: 0,
        feedback_step: 0,
        generic_entity_list_config_id: MetadataGenericListType.CORE_USER,
        catalog_id: 0,
        community_id: 0,
        inverse_relation_include_terminal_states: false,
        inverse_relation_order_by: '',
        inverse_relation_order_desc: false,
        hierarchical: false,
        user_picker_from_community: false,
        user_picker_from_role: 0,
        user_picker_from_group: 0,
        group_picker_from_workspace: false,
        group_picker_from_community: false,
        group_picker_from_role: 0,
        group_picker_from_prefix: '',
        extends_post_visibility: false,
        sends_notifications: false,
        strict_order: false,
        disabled_drive: false,
        disabled_local_filesystem: false,
        generate_post_comment: false,
        decimal_digits: 0,
        qr_code: false,
        qr_code_secret: false,
        dynamic: false,
        field_group_id: 0,
    };
    return Object.keys(base);
}

export function isKnownMetadataKey(key: string): boolean {
    return (
        knownMetadataKeys().includes(key) ||
        NOT_CONFIGURABLE_METADATA_KEYS.includes(key)
    );
}

export function extractBaseMetadata(
    originalMetadata: ICustomFieldMetadata,
): ICustomFieldMetadataBase | null {
    const productMetadataKeys = knownMetadataKeys();
    return produce(originalMetadata, (draft: ICustomFieldMetadata) => {
        Object.keys(draft).forEach((k) => {
            if (!productMetadataKeys.includes(k)) {
                delete draft[k];
            }
        });
    });
}

export function cleanFromNotConfigurableMetadata(
    originalMetadata: ICustomFieldMetadata,
): ICustomFieldMetadata | null {
    if (!originalMetadata) return null;
    const cleanedMetadata = cleanFromMetadataBase(originalMetadata);
    return produce(cleanedMetadata, (draft: ICustomFieldMetadata) => {
        Object.keys(draft).forEach((k) => {
            if (NOT_CONFIGURABLE_METADATA_KEYS.includes(k)) {
                delete draft[k];
            }
        });
    });
}

export function cleanFromMetadataBase(
    originalMetadata: ICustomFieldMetadata,
): ICustomFieldMetadata | null {
    if (!originalMetadata) return null;

    const productMetadataKeys = knownMetadataKeys();
    return produce(originalMetadata, (draft: ICustomFieldMetadata) => {
        Object.keys(draft).forEach((k) => {
            if (productMetadataKeys.includes(k)) {
                delete draft[k];
            }
        });
    });
}

export function cleanFromQrCodeSnakeCaseMetadata(
    metadata: ICustomFieldMetadata,
): ICustomFieldMetadata | null {
    if (!metadata) return null;

    if (isDefined(metadata.qr_code)) {
        metadata[METADATA_QR_CODE_DOT_NOTATION] = metadata.qr_code;
        delete metadata.qr_code;
    }
    if (isDefined(metadata.qr_code_secret)) {
        (metadata[METADATA_QR_CODE_SECRET_DOT_NOTATION] =
            metadata.qr_code_secret),
            delete metadata.qr_code_secret;
    }

    return metadata;
}

export function isCustomFieldEmpty(field: ICustomField): boolean {
    return (
        field.viewValue == null ||
        field.viewValue === '' ||
        (Array.isArray(field.viewValue) && field.viewValue.length === 0) ||
        field.viewValue.totalCount === 0
    );
}

export function getMaxLength(
    validations: IValidationMetadata[] | undefined,
): number | undefined {
    const parameter = validations?.find(
        (i) => i.validationType === ValidationType.MAX_LENGTH,
    )?.parameter;
    return parameter ? Number(parameter) : undefined;
}

export function getMaxSelectableItems(
    validations: IValidationMetadata[] | undefined,
): number {
    return getMaxLength(validations) ?? Infinity;
}

export function isCatalogMultiple(
    isHierarchy: boolean,
    isLeaf: boolean,
    validations: IValidationMetadata[] | undefined,
): boolean {
    return (!isHierarchy || isLeaf) && 1 !== getMaxLength(validations);
}

export const getNotSearchableTypes = (): ConfigurableFieldType[] => [
    ConfigurableFieldType.FILE_PICKER,
];

export const geAdvancedManagementTypes = (): ConfigurableFieldType[] => [
    ConfigurableFieldType.USER_PICKER,
    ConfigurableFieldType.GROUP_PICKER,
];

export const getNotSortableTypes = (): ConfigurableFieldType[] => [
    ConfigurableFieldType.PARAGRAPH,
    ConfigurableFieldType.LINK,
    ConfigurableFieldType.HTML,
    ConfigurableFieldType.COMMENT,
    ConfigurableFieldType.SELECT_SINGLE,
    ConfigurableFieldType.SELECT_MULTI,
    ConfigurableFieldType.USER_PICKER,
    ConfigurableFieldType.GROUP_PICKER,
    ConfigurableFieldType.POST_PICKER,
    ConfigurableFieldType.FILE_PICKER,
];

export const getTypesWithMetadata = (): ConfigurableFieldType[] => [
    ConfigurableFieldType.NUMBER,
    ConfigurableFieldType.QR_CODE,
    ConfigurableFieldType.FEEDBACK,
    ConfigurableFieldType.SELECT_SINGLE,
    ConfigurableFieldType.SELECT_MULTI,
    ConfigurableFieldType.USER_PICKER,
    ConfigurableFieldType.GROUP_PICKER,
    ConfigurableFieldType.FILE_PICKER,
    ConfigurableFieldType.POST_PICKER,
];

export const getTypesWithoutValidation = (): ConfigurableFieldType[] => [
    ConfigurableFieldType.FLAG,
    ConfigurableFieldType.FEEDBACK,
    ConfigurableFieldType.SELECT_SINGLE,
];

export const getGroupableFieldTypes = (): ConfigurableFieldType[] => [
    ConfigurableFieldType.DATE,
    ConfigurableFieldType.DATETIME,
    ConfigurableFieldType.NUMBER,
];

export const getCustomFieldFullDescription = (
    field: Pick<
        ICustomFieldDefinition,
        'description' | 'customFieldType' | 'validations'
    >,
    translate: TranslateService,
): ICustomFieldDefinition['description'] => {
    if (field.customFieldType === CustomFieldType.LINK) return null;
    let defaultDescription: string | null;

    const baseLabel = 'CUSTOM_FIELDS.DEFAULT_DESCRIPTION.';

    switch (field.customFieldType) {
        case CustomFieldType.COMMENT: {
            defaultDescription = translate.instant(
                `${baseLabel}${field.customFieldType.toUpperCase()}`,
            );
            break;
        }
        case CustomFieldType.POST_PICKER:
        case CustomFieldType.CATALOG: {
            const { minLength, maxLength } = getMinMaxLengthByField(
                field.validations,
            );

            if (minLength && !maxLength) {
                defaultDescription = translate.instant(
                    minLength > 1
                        ? `${baseLabel}MIN_LENGTH_PLUR`
                        : `${baseLabel}MIN_LENGTH_SING`,
                    { minLength },
                );
            } else if (!minLength && maxLength && maxLength > 1) {
                defaultDescription = translate.instant(
                    `${baseLabel}MAX_LENGTH_PLUR`,
                    { maxLength },
                );
            } else if (minLength && maxLength) {
                defaultDescription = translate.instant(
                    `${baseLabel}MIN_AND_MAX_LENGTH`,
                    { minLength, maxLength },
                );
            } else {
                defaultDescription = null;
            }
            break;
        }
        case CustomFieldType.USER_PICKER:
        case CustomFieldType.GROUP_PICKER:
        case CustomFieldType.SELECT_MULTIPLE_VALUES: {
            const { minLength, maxLength } = getMinMaxLengthByField(
                field.validations,
            );

            if (minLength && !maxLength) {
                defaultDescription = translate.instant(
                    minLength > 1
                        ? `${baseLabel}MIN_LENGTH_PLUR`
                        : `${baseLabel}MIN_LENGTH_SING`,
                    { minLength },
                );
            } else if (!minLength && maxLength) {
                defaultDescription = translate.instant(
                    maxLength > 1
                        ? `${baseLabel}MAX_LENGTH_PLUR`
                        : `${baseLabel}MAX_LENGTH_SING`,
                    { maxLength },
                );
            } else if (minLength && maxLength) {
                defaultDescription = translate.instant(
                    `${baseLabel}MIN_AND_MAX_LENGTH`,
                    { minLength, maxLength },
                );
            } else {
                defaultDescription = null;
            }
            break;
        }
        default: {
            defaultDescription = null;
        }
    }

    if (field.description) {
        if (typeof field.description === 'object' && defaultDescription) {
            return {
                ops: [
                    ...((field.description as Delta).ops ?? []),
                    { insert: `${defaultDescription}\n` },
                ],
            };
        }

        return field.description;
    }
    if (defaultDescription) {
        return DeltaUtility.fromString(defaultDescription);
    }
    return field.description;
};

const getMinMaxLengthByField = (
    validations: ICustomFieldDefinition['validations'],
): { minLength: number | null; maxLength: number | null } => {
    let minLength: number | null = null;
    let maxLength: number | null = null;
    const minValidationFound = validations.find(
        (v) => v.validationType === ValidationType.MIN_LENGTH,
    );
    const maxValidationFound = validations.find(
        (v) => v.validationType === ValidationType.MAX_LENGTH,
    );

    if (minValidationFound && minValidationFound.parameter) {
        minLength = +minValidationFound.parameter;
    }

    if (maxValidationFound && maxValidationFound.parameter) {
        maxLength = +maxValidationFound.parameter;
    }

    return { minLength, maxLength };
};
