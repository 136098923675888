import {
    Language,
    Timezone,
} from '@interacta-shared/data-access-configuration';
import { PaginatedList } from '@interacta-shared/util';
import { IUser } from '@modules/core';
import {
    Member,
    MemberGroup,
    MemberUser,
    SplittedMembers,
} from '@modules/core/models/member/member.model';
import { CustomFieldsConfigurationBaseWithValidationAndOrder } from '@modules/custom-fields-configuration/models/custom-fields-configuration.model';
import { ICustomFieldDefinition } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { Tag } from '@modules/core/models/tag/tag.model';
import { SurveyTaskList } from '@modules/tasks/models/task.model';
import {
    IBasePost,
    IBasePostCreate,
    IBasePostEdit,
    PostType,
    RecipientGroupCardinality,
} from './../base-post.model';

/**
 * Vedi anche SurveyPostDataResponseDTO
 */
export interface SurveyPost extends IBasePost {
    tag: PostType.SURVEY;
    surveyType: SurveyType;
    startAt:
        | {
              zonedDatetime?: Date;
              localDatetime: Date;
          }
        | undefined;
    expiration:
        | {
              zonedDatetime?: Date;
              localDatetime: Date;
          }
        | undefined;
    scheduledClosing:
        | {
              zonedDatetime?: Date;
              localDatetime: Date;
          }
        | undefined;
    closingTimestamp: Date | null;
    closingUser: IUser | null;
    timezone?: Timezone;
    surveyLanguage: Language;
    /**
     * Definizione del "Post" nel senso di lista di domande già configurate. Da
     * trattare come i campi custom del post
     */
    fieldDefinitions: SurveyQuestionDefinition[];
    recipients?: SurveyRecipients;

    answerViewerType: SurveyAnswerViewerType;
    /**
     * Includi destinatari tra i visualizzatori (attivabile solo se answerViewerType=1-CUSTOM)
     */
    recipientAnswerViewers: boolean;

    customAnswerViewer?: Partial<SplittedMembers>;
    /** Abilita la possibilità di modificare le risposte*/
    editAnswerEnabled: boolean;
    managers?: Partial<SplittedMembers>;

    toCreateSurveyTasksCount?: number;

    // computed fields

    state: SurveyState;

    // lazy loaded fields

    answerTaskAssigneeList?: PaginatedList<Member>; // survery or feedback task assingee list

    managersCount?: number; // each group's member counts
    answerViewersCount?: number; // each group's member counts
}

export type SurveyState = 'scheduled' | 'open' | 'closed';

export interface SurveyStateStyle {
    label: string;
    background: string;
}

export enum SurveyType {
    SURVEY = 1,
    FEEDBACK = 2,
}

export enum SurveyAnswerViewerType {
    CUSTOM = 1, //destinatari e/o utenti/gruppi selezionati
    ORGANIZATION_CHART = 2, //organigramma aziendale
}

export type SurveyQuestionDefinition = Pick<
    ICustomFieldDefinition,
    | 'id'
    | 'parentId'
    | 'leaf'
    | 'label'
    | 'description'
    | 'type'
    | 'metadata'
    | 'readonly'
    | 'required'
    | 'validations'
    | 'enumValues'
    | 'listenChanges'
    | 'fieldMode'
    | 'customFieldType'
>;

export type SurveyQuestionForCreate =
    CustomFieldsConfigurationBaseWithValidationAndOrder;

/**
 * VEDI ANCHE SurveyPostRecipientsDataResponseDTO
 */
export interface SurveyRecipients {
    users?: MemberUser[];
    groups?: SurveyRecipientsGroup[];
    groupTags: Tag[];
    groupTagExcludedGroups: SurveyRecipientTagExcludedGroups[];
    recipientUsersTotalCount: number;
    recipientGroupCardinality?: RecipientGroupCardinality;
}

export interface SurveyRecipientsGroup extends MemberGroup {
    membersCount?: number;
    recipientsResponseCount: number;
    deleted?: boolean;
    email?: string;
    visible?: boolean;
}

export interface SurveyRecipientTagExcludedGroups {
    tagId: Tag['id'];
    excludedGroups: SurveyRecipientsGroup[];
}

export interface SurveyPostCreate extends IBasePostCreate, SurveyPost {
    /**
     * Domande generate in fase di creazione del post. da trattare come i campi
     * screen in amministrazione (edit transizioni)
     */
    surveyQuestions?: SurveyQuestionForCreate[];
}
export interface SurveyPostEdit extends IBasePostEdit, SurveyPostCreate {
    sendNotification: boolean;
}

export interface SurveyQuickAction {
    survey: SurveyPost;
    mode: SurveyDialogMode;
}

export enum SurveyDialogMode {
    REPLY = 'reply',
    DETAIL = 'detail',
    PREVIEW = 'preview',
    MONITOR = 'monitor',
    CONCURRENCY_ERROR = 'concurrency_error',
    CLOSE = 'close',
}

export interface SurveyOperationsDialogState {
    isOpen: boolean;
    survey: SurveyPost | null;
    isLoading: boolean;
    mode: SurveyDialogMode;
    myExpiringTasks: SurveyTaskList | null;
}

export type QuestionsEditMode = 'draft' | 'full' | 'limited';
