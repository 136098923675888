<button
    *ngIf="{
        border: appearance === 'border',
        fill: appearance === 'fill',
        iconClose: icon === 'close'
    } as $"
    class="typo-xs-bold-uppercase flex h-32 w-max items-center gap-4 rounded-3xl px-12"
    matRipple
    type="button"
    [disabled]="disabled"
    [matRippleDisabled]="disabled"
    [ngClass]="{
        'border-border-700 border border-solid': $.border && !isActive,
        'border-border-primary': $.border && isActive && !disabled,
        'border-border-500': $.border && disabled,
        'bg-surface-primary': isActive && $.fill,
        'bg-surface-A': !isActive && $.fill && bgColor === 'surface-A',
        'bg-surface-B': !isActive && $.fill && bgColor === 'surface-B',
        'hover:bg-surface-100': enableHover
    }"
    (click)="chipClicked.emit()"
>
    <span
        [ngClass]="{
            'text-text-primary':
                (!isActive && $.fill) || ($.border && isActive),
            'text-text-on-primary-reverse': isActive && $.fill,
            'text-text-mid-contrast': $.border && !isActive
        }"
    >
        {{ label }}
    </span>

    <div
        *ngIf="icon && !disabled && ((isActive && $.iconClose) || !$.iconClose)"
        class="flex rounded-full p-4"
        matRipple
        [matRippleDisabled]="disabled"
        [matTooltip]="iconTooltip"
        [ngClass]="{
            'text-text-mid-contrast hover:bg-surface-B':
                !$.iconClose && !disabled,
            'text-text-on-primary-reverse hover:bg-surface-primary':
                $.iconClose && !disabled,
            'text-text-disabled cursor-default': disabled
        }"
    >
        <interacta-icon
            class="h-16 w-16"
            [icon]="icon"
            (click)="iconClicked.emit(); $event.stopPropagation()"
        ></interacta-icon>
    </div>
</button>
