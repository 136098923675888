import { BehaviorSubject } from 'rxjs';
import { CurrentChatStatusUpdatedEventBody } from '@modules/chat/models/chat.models';

export type CurrentChatStatus = CurrentChatStatusUpdatedEventBody;

export class ChatState {
    canShow$ = new BehaviorSubject<boolean>(false);
    isOpen$ = new BehaviorSubject<boolean>(false);
    isCollapsed$ = new BehaviorSubject<boolean>(true);
    unreadChatStatus$ = new BehaviorSubject<number>(0);
    chatUrl$ = new BehaviorSubject<string>('');
    currentChatStatus$ = new BehaviorSubject<CurrentChatStatus | null>(null);
}
