import { Pipe, PipeTransform } from '@angular/core';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { ICustomButton } from '../models/custom-button/custom-button.models';

@Pipe({
    name: 'filterAttachmentCustomButtons',
})
export class FilterAttachmentCustomButtonsPipe implements PipeTransform {
    transform(
        customButtons: ICustomButton[] = [],
        attachments: IAttachment[] | IAttachment = [],
    ): ICustomButton[] {
        // Multiple selection
        if (Array.isArray(attachments)) {
            return customButtons.filter((e) =>
                this.checkCustomButtonByAttachments(e, attachments),
            );
        } else {
            return customButtons.filter((e) =>
                this.checkCustomButtonByAttachment(e, attachments),
            );
        }
    }

    private checkCustomButtonByAttachments(
        e: ICustomButton,
        attachments: IAttachment[],
    ): boolean {
        const selectedCount = attachments.length;
        return (
            e.minItems <= selectedCount &&
            e.maxItems >= selectedCount &&
            (e.mimeTypes?.length === 0 ||
                attachments.every((attachment) =>
                    e.mimeTypes?.includes(attachment.contentMimeType),
                ))
        );
    }

    private checkCustomButtonByAttachment(
        e: ICustomButton,
        attachment: IAttachment,
    ): boolean {
        return (
            (e.minItems === 1 && e.mimeTypes?.length === 0) ||
            (e.mimeTypes?.includes(attachment.contentMimeType) ?? true)
        );
    }
}
