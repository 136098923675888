import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Size } from '@interacta-shared/ui';
import { Member } from '@modules/core/models/member/member.model';
import { Tag, TagMember } from '@modules/core/models/tag/tag.model';
import {
    getExcludedGroupCountByTagId,
    isMemberOfTagMember,
    isTagOfTagMember,
} from '@modules/core/models/tag/tag.utils';
import { SurveyRecipientTagExcludedGroups } from '@modules/post/models/survey-post/survey-post.model';
import { MemberClickBehavior } from '../member/member.component';

@Component({
    selector: 'interacta-tag-member-dialog',
    templateUrl: './tag-member-dialog.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagMemberDialogComponent implements OnChanges {
    @Input() isDialogOpen = false;
    @Input() list: TagMember[] = [];
    @Input() title: string | null = '';
    @Input() size: Size = 'regular';
    @Input() memberClickBehavior: MemberClickBehavior = 'profile';
    @Input() tagExcludedGroups: SurveyRecipientTagExcludedGroups[] | null =
        null;

    @Output() closeDialog = new EventEmitter<void>();

    tags: Tag[] = [];
    members: Member[] = [];

    getExcludedGroupCountByTagId = getExcludedGroupCountByTagId;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.list) {
            this.members = this.list.filter(isMemberOfTagMember);
            this.tags = this.list.filter(isTagOfTagMember);
        }
    }

    trackById(index: number, item: TagMember): TagMember['id'] {
        return item.id;
    }
}
