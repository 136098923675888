import { Index } from '@interacta-shared/util';
import { HomeCommunityIndex } from '@modules/core/models/utility.model';
import { VisibilityType } from '@modules/post/models/base-post.model';
import { AttachmentStorageType } from '../attachment/attachment.model';
import { PostType } from '../base-post.model';
import { ExtendedCustomFieldServerData } from '../filter-post/filter-post.model';
import { SurveyType } from '../survey-post/survey-post.model';
import { IOrder, IPostFilters } from './../filter-post/filter-post.model';

export interface QuickFilter {
    id: Index;
    type: QuickFilterType;
    label: string;
    description: string | null;
    filters: Partial<IPostFilters>;
    order: number;
    showInBar: boolean;
    shared: boolean;
}

export enum QuickFilterType {
    MY_POST = 1,
    TO_MANAGE = 2,
    MENTIONED = 3,
    FOLLOWED_BY_ME = 4,
    CUSTOM = 5,
}

export type QuickFiltersList = Record<HomeCommunityIndex, QuickFilter[]>;

export interface QuickFilterListFromServer {
    communityId: number | null;
    quickFilters: QuickFilterFromServer[];
}

export interface QuickFilterFromServer {
    id: number;
    type: number;
    label: string;
    description: string | null;
    order: number;
    showInBar: boolean;
    shared: boolean;
    parameters: string; //stringified of CustomQuickFilterData
}

export type QuickFilterDateOptions =
    | 'today'
    | 'yesterday'
    | 'last7Days'
    | 'last30Days'
    | 'custom'
    | null;

export type QuickFilterEventDateOptions =
    | 'ongoing'
    | 'upcoming'
    | 'inProgram'
    | 'ended'
    | 'custom'
    | null;

export const ActualQuickFilterVersion = 4;
export interface CustomQuickFilterData {
    createdByUserId: number | null; // Legacy, replaced with createdByUserIds
    createdByUserIds: (number | 'current')[] | null;
    createdByGroupIds: number[] | null;
    title: string | null;
    description: string | null;
    creationDateType: QuickFilterDateOptions | null;
    creationTimestampFrom: number | null;
    creationTimestampTo: number | null;
    postTypes: PostType[];
    postSurveyTypes: SurveyType[];
    event: {
        startDateFromTimestamp?: number;
        startDateToTimestamp?: number;
        endDateFromTimestamp?: number;
        endDateToTimestamp?: number;
    } | null;
    eventType: QuickFilterEventDateOptions | null;
    hashtagIds: number[] | null;
    hashtagsLogicalAnd: boolean;
    visibility: VisibilityType | null;
    currentWorkflowStatusIds: number[] | null;
    postFieldFilters: ExtendedCustomFieldServerData[];
    screenFieldFilters: ExtendedCustomFieldServerData[];

    attachmentName: string | null;
    attachmentHashtagIds: number[] | null;
    attachmentHashtagsLogicalAnd: boolean;
    attachmentModifiedDateType: QuickFilterDateOptions | null;
    attachmentModifiedTimestampFrom: number | null;
    attachmentModifiedTimestampTo: number | null;
    attachmentCreatedByUserId: number | null;
    attachmentSourceType: AttachmentStorageType | null;
    mimeTypes: number[] | null;

    toManage: boolean | null;
    pinned: boolean | null;
    orderBy: IOrder['orderBy'] | null;
    orderDesc: IOrder['orderDesc'] | null;

    version: number;
}
