import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    inject,
} from '@angular/core';
import { Size } from '@interacta-shared/ui';
import { EMPTY_SEARCH_VALUE, getRandomColor } from '@interacta-shared/util';
import { IUsersGroup } from '@modules/core';
import { GroupInfoV2Service } from '@modules/group-info/group-info-v2.service';

@Component({
    selector: 'interacta-users-group',
    templateUrl: './users-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersGroupComponent implements OnInit {
    @Input() group!: IUsersGroup;
    @Input() size: Extract<Size, 'regular' | 'small' | 'extra-small'> =
        'regular';
    @Input() showInfo = false;
    @Input() fontWeight: 'bold' | 'regular' = 'bold';
    @Input() rtl = false;
    @Input() canClick = false;

    colorClass?: string;
    EMPTY_SEARCH_VALUE = EMPTY_SEARCH_VALUE;

    private readonly groupInfoService = inject(GroupInfoV2Service);

    ngOnInit(): void {
        this.colorClass = getRandomColor(this.group.id);
    }

    openGroupInfo(): void {
        this.groupInfoService.openDialog(this.group.id).subscribe();
    }
}
