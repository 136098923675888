<div class="typo-sm-bold mb-32 shrink-0">
    <span *ngIf="recentCommunities?.length">{{
        'SIDEBAR.RECENT_COMMUNITIES' | translate
    }}</span>

    <a
        *ngFor="let recentCommunity of recentCommunities"
        class="mt-12 flex cursor-pointer flex-col space-y-12"
        (click)="selectedCommunity.emit(recentCommunity)"
    >
        <div class="flex items-center">
            <interacta-icon
                class="mr-8 h-24 w-24"
                icon="community"
            ></interacta-icon>
            <span
                class="typo-xs-bold line-clamp-2 grow break-words transition-opacity duration-500"
                >{{ recentCommunity.name }}</span
            >
        </div>
        <interacta-separator thickness="soft"></interacta-separator>
    </a>
</div>
