import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { InputSelectSearchEvent } from '@interacta-shared/ui';
import {
    IList,
    PaginatedList,
    paginatedListFromIList,
} from '@interacta-shared/util';
import {
    CommunityTreeDeserialize,
    ICommunity,
} from '@modules/communities/models/communities.model';
import { MetadataFieldModeType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { Member } from '@modules/core/models/member/member.model';
import {
    IGroupPickerSearchRequest,
    IMemberSearchRequest,
    IUserPickerSearchRequest,
    IUserSearchRequest,
    SearchMemberOrderType,
    SearchUserOrderType,
    UserSearchDeserialize,
    UserSearchSerialize,
} from '@modules/core/models/user-autocomplete.model';
import {
    IUser,
    PartialSharedProfile,
    SharedProfile,
    UserDeserilize,
} from '@modules/core/models/user.model';
import { updatePerson } from '@modules/people/store/people.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { toMemberList } from '../models/member/member.deserialize';
import {
    IUsersGroup,
    UserExtendedDeserialize,
    UserExtendedSerialize,
} from '../models/user-group.model';

@Injectable({ providedIn: 'root' })
export class UsersService {
    private baseUrlCore = `${inject(ENVIRONMENT).apiBasePath.common}/core`;
    private baseUrlPeople = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/people`;
    private baseUrlPeopleGroups = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/people/groups`;

    constructor(
        private http: HttpClient,
        private store: Store,
    ) {}

    searchUsers(
        search: IUserSearchRequest,
    ): Observable<PaginatedList<PartialSharedProfile>> {
        if (search.name && !search.orderBy)
            search.orderBy = SearchUserOrderType.NAME;

        return this.http
            .post<any>(
                `${this.baseUrlPeople}/users`,
                UserSearchSerialize.userSearch(search),
            )
            .pipe(
                map((res: any) => UserSearchDeserialize.userSearch(res)),
                map((people: IList<PartialSharedProfile>) =>
                    paginatedListFromIList(people),
                ),
            );
    }

    searchGroups(
        search: IMemberSearchRequest,
    ): Observable<PaginatedList<IUsersGroup>> {
        if (search.name && !search.orderBy)
            search.orderBy = SearchMemberOrderType.NAME;

        return this.http
            .post<any>(
                `${this.baseUrlPeople}/groups`,
                UserSearchSerialize.groupSearch(search),
            )
            .pipe(
                map((res: any) => UserSearchDeserialize.groupSearch(res)),
                map((people: IList<IUsersGroup>) =>
                    paginatedListFromIList(people),
                ),
            );
    }

    public getUserPickerList(
        fieldId: number,
        itemId: number,
        type: MetadataFieldModeType,
        search: IUserPickerSearchRequest,
    ): Observable<PaginatedList<IUser>> {
        return this.http
            .post<any>(
                `${this.baseUrlPeople}/users/${type}-user-picker/${fieldId}/${
                    type === MetadataFieldModeType.SURVEY
                        ? 'posts'
                        : 'communities'
                }/${itemId}`,
                UserSearchSerialize.userPickerSearch(search),
            )
            .pipe(
                map((res: any) => UserSearchDeserialize.userSearch(res)),
                map((res: IList<IUser>) => paginatedListFromIList(res)),
            );
    }

    public getGroupPickerList(
        fieldId: number,
        itemId: number,
        type: MetadataFieldModeType,
        search: IGroupPickerSearchRequest,
    ): Observable<PaginatedList<IUsersGroup>> {
        return this.http
            .post<any>(
                `${this.baseUrlPeople}/${
                    type === MetadataFieldModeType.SURVEY ? 'groups' : 'users'
                }/${type}-group-picker/${fieldId}/${
                    type === MetadataFieldModeType.SURVEY
                        ? 'posts'
                        : 'communities'
                }/${itemId}`,
                UserSearchSerialize.groupPickerSearch(search),
            )
            .pipe(
                map((res: any) => UserSearchDeserialize.groupSearch(res)),
                map((res: IList<IUsersGroup>) => paginatedListFromIList(res)),
            );
    }

    public getUserDetails(userId?: number): Observable<SharedProfile> {
        const path = userId
            ? `${this.baseUrlPeople}/users/${userId}/info`
            : `${this.baseUrlCore}/user-profile/info`;
        return this.http
            .get<any>(path)
            .pipe(
                map((res: any) =>
                    UserExtendedDeserialize.userProfileDetails(res),
                ),
            );
    }

    getUsersDetailBatch(userIds: number[]): Observable<IUser[]> {
        return this.http
            .post<{ users: any[] }>(`${this.baseUrlPeople}/users/data`, {
                userIds,
            })
            .pipe(
                map((e) => e.users.map((u) => UserDeserilize.userDetails(u))),
            );
    }

    getGroupsDetailBatch(groupIds: number[]): Observable<IUsersGroup[]> {
        return this.http
            .post<{ groups: any[] }>(`${this.baseUrlPeople}/groups/data`, {
                groupIds,
            })
            .pipe(
                map((e) =>
                    e.groups.map((g) => UserExtendedDeserialize.usersGroup(g)),
                ),
            );
    }

    public setUserDetail(details: SharedProfile): Observable<SharedProfile> {
        const detailsToEdit = UserExtendedSerialize.userProfile(details);
        return this.http
            .put<any>(`${this.baseUrlCore}/user-profile/info`, detailsToEdit)
            .pipe(
                map((res: any) =>
                    UserExtendedDeserialize.userProfileDetails(res),
                ),
                tap((user) => this.store.dispatch(updatePerson({ user }))),
            );
    }

    public setUserBiography(details: SharedProfile): Observable<SharedProfile> {
        const detailsToEdit = UserExtendedSerialize.userBiography(details);
        return this.http
            .put<any>(
                `${this.baseUrlCore}/user-profile/info/biography`,
                detailsToEdit,
            )
            .pipe(
                map((res: any) =>
                    UserExtendedDeserialize.userProfileDetails(res),
                ),
            );
    }

    public getCommonCommunities(userId: number): Observable<ICommunity[]> {
        return this.http
            .get<any>(
                `${this.baseUrlPeople}/users/${userId}/common-communities`,
            )
            .pipe(
                map((result: any) =>
                    result.communities.map(
                        CommunityTreeDeserialize.commonCommunityDetails,
                    ),
                ),
            );
    }

    public getMembers(filter: IMemberSearchRequest): Observable<IList<Member>> {
        return this.http
            .post<any>(`${this.baseUrlPeople}/members`, filter)
            .pipe(
                map((res: any) =>
                    toMemberList(
                        res,
                        res.totalItemsCount as number | undefined,
                    ),
                ),
            );
    }

    public blockUser(userId: number): Observable<any> {
        return this.http.put(
            `${this.baseUrlPeople}/users/${userId}/block`,
            null,
        );
    }

    public restoreUser(userId: number): Observable<any> {
        return this.http.put(
            `${this.baseUrlPeople}/users/${userId}/restore`,
            null,
        );
    }

    public getGroupMembers(
        groupId: number,
        search: InputSelectSearchEvent,
        calculateTotalItemsCount: boolean,
        pageSize = 20,
    ): Observable<PaginatedList<PartialSharedProfile>> {
        return this.http
            .post<any>(`${this.baseUrlPeopleGroups}/${groupId}/members`, {
                pageToken: search.nextPageToken,
                fullTextFilter: search.text,
                calculateTotalItemsCount: calculateTotalItemsCount,
                pageSize: pageSize,
            })
            .pipe(
                map((res) => UserSearchDeserialize.userSearch(res)),
                map((people: IList<PartialSharedProfile>) =>
                    paginatedListFromIList(people),
                ),
            );
    }

    public getCurrentUserGroups(): Observable<IUsersGroup[]> {
        return this.http
            .get(`${this.baseUrlPeople}/my-groups`)
            .pipe(
                map((res: any) =>
                    res.groups.map(UserExtendedDeserialize.usersGroup),
                ),
            );
    }
}
