import { mapArrayById } from '@interacta-shared/util';
import {
    AdminV2PaginatedFilters,
    DefaultAdminV2PaginatedFilters,
} from '@modules/admin-v2-shared/models/admin-v2-paginated.model';
import { fromAdminV2PaginatedFilters } from '@modules/admin-v2-shared/models/admin-v2-paginated.serialize';
import {
    cleanEmptyI18nDeltaV2,
    cleanEmptyI18nStringV2,
    fromI18nDeltaV2,
} from '@modules/admin-v2-shared/utils/admin-v2-i18n';
import {
    extractGroupsFromMembers,
    extractUsersFromMembers,
} from '@modules/core/models/member/member.utils';
import { DefaultWorkspaceSortOrder } from '../utils/admin-v2-workspace-config';
import {
    AdminV2MemberSearchRequest,
    AdminV2NewWorkspace,
    AdminV2WorkspaceFilters,
    AdminV2WorkspaceRow,
    AdminV2WorkspaceSettingsUpdate,
    AdminV2WorkspaceSort,
} from './admin-v2-workspace.model';

export const fromAdminV2WorkspaceFilters = (
    filters: AdminV2WorkspaceFilters,
    paginatedFilters?: AdminV2PaginatedFilters,
    sort?: AdminV2WorkspaceSort,
): any => {
    if (!sort) {
        sort = DefaultWorkspaceSortOrder;
    }
    if (!paginatedFilters) {
        paginatedFilters = DefaultAdminV2PaginatedFilters;
    }

    return {
        fullTextFilter: filters.search,
        communityIds: mapArrayById(filters.communities),
        adminUserIds: mapArrayById(
            extractUsersFromMembers(filters.adminMembers ?? []),
        ),
        adminGroupIds: mapArrayById(
            extractGroupsFromMembers(filters.adminMembers ?? []),
        ),
        orderTypeId: getSortColumn(sort.sortColumn),
        orderDesc: sort.orderDesc,
        ...fromAdminV2PaginatedFilters(paginatedFilters),
    };
};

const getSortColumn = (sortColumn: keyof AdminV2WorkspaceRow) => {
    switch (sortColumn) {
        case 'creatorUser':
            return 'creationUser';
        default:
            return sortColumn;
    }
};

export const fromAdminV2NewWorkspace = (
    workspace: AdminV2NewWorkspace,
): any => ({
    name: workspace.name,
    description: workspace.description,
    coverImageContentRef: workspace.contentRef,
    externalId: workspace.externalId,
});

export const fromAdminV2WorkspaceSettingsUpdate = (
    update: AdminV2WorkspaceSettingsUpdate,
): any => ({
    name: cleanEmptyI18nStringV2(update.name),
    description: fromI18nDeltaV2(cleanEmptyI18nDeltaV2(update.description)),
    newCoverImageContentRef: update.coverImage.contentRef,
    externalId: update.externalId,
});

export const fromAdminV2MemberSearchRequest = (
    request: AdminV2MemberSearchRequest,
): any => ({
    name: request.search ?? null,
    notInCommunityId: request.notInCommunityId ?? null,
    checkCommunityMember: request.checkCommunityMember ?? null,
    checkOperationalRoleAssociation: request.roleAssociation
        ? {
              communityId: request.roleAssociation.communityId,
              operationalRoleId: request.roleAssociation.roleId,
          }
        : undefined,
});
