import { Injectable } from '@angular/core';
import { isDefined } from '@interacta-shared/util';
import { ICatalogsOperativityList } from '@modules/communities/models/catalog/catalog.model';
import { CatalogsService } from '@modules/communities/services/catalogs.service';
import { idArraytoMap, mPartition } from '@modules/core/helpers/generic.utils';
import { LocalStorageService } from '@modules/core/services/local-storage.service';
import { Observable, Subject, filter, iif, of, takeUntil } from 'rxjs';
import {
    concatMap,
    first,
    map,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { CatalogsState } from '../models/catalogs-state.model';
import { IStateService } from './istate-service';
import { StateService } from './state.service';

const catalogsCacheKey = 'catalogs';

@Injectable({
    providedIn: 'root',
})
export class CatalogsStateService implements IStateService<CatalogsState> {
    readonly state: CatalogsState;
    private readonly destroy$ = new Subject<void>();

    constructor(
        stateService: StateService,
        private localStorageService: LocalStorageService,
        private catalogsService: CatalogsService,
    ) {
        this.state = stateService.catalogsState;
    }

    initialize(): void {
        this.localStorageService
            .getIdbEntry<Record<number, ICatalogsOperativityList>>(
                catalogsCacheKey,
            )
            .pipe(
                map((cache) => cache ?? {}),
                first(),
            )
            .subscribe((cache) => this.state.catalogs$.next(cache));

        this.keepCacheInSync();
    }

    /**
     * Returns an Observable containing the requested catalogs.
     * First all requested catalogs' etags are loaded from BE API.
     * Then requested catalogs' entries are fetched from cache (if any).
     *  If they are not stale, the are used as is,
     *  otherwise they are loaded from BE API and cache is updated.
     */
    getCatalogs(catalogIds: number[]): Observable<ICatalogsOperativityList[]> {
        return iif(
            () => catalogIds.length > 0,
            this.state.isInitialized$.pipe(
                first((isInitialized) => isInitialized),
                switchMap((_) =>
                    this.catalogsService.getCatalogs(catalogIds, false).pipe(
                        withLatestFrom(this.state.catalogs$),
                        concatMap(([catalogs, catalogsCache]) =>
                            of({ catalogs, catalogsCache }).pipe(
                                map(({ catalogs }) =>
                                    catalogs.map((c) => ({
                                        ...c,
                                        entries:
                                            catalogsCache?.[c.id]?.entries ??
                                            [],
                                    })),
                                ),
                                switchMap((catalogs) =>
                                    this.updateStaleCatalogs(
                                        catalogsCache ?? {},
                                        catalogs,
                                    ),
                                ),
                                tap((catalogs) =>
                                    this.state.catalogs$.next({
                                        ...catalogsCache,
                                        ...idArraytoMap(catalogs),
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
            of([]),
        );
    }

    flush(): void {
        this.state.catalogs$.next(null);
        this.destroy$.next();
        this.keepCacheInSync();
    }

    private updateStaleCatalogs(
        catalogsCache: Record<number, ICatalogsOperativityList>,
        catalogs: ICatalogsOperativityList[],
    ): Observable<ICatalogsOperativityList[]> {
        const [upToDateCatalogs, staleCatalogs] = mPartition(
            catalogs,
            (catalog) => catalogsCache[catalog.id]?.etag === catalog.etag,
        );

        const staleCatalogIds = staleCatalogs.map((c) => c.id);

        return iif(
            () => staleCatalogs.length > 0,
            this.catalogsService
                .getCatalogs(staleCatalogIds, true)
                .pipe(
                    map((updatedCatalogs) => [
                        ...upToDateCatalogs,
                        ...updatedCatalogs,
                    ]),
                ),
            of(upToDateCatalogs),
        );
    }

    private keepCacheInSync(): void {
        this.state.catalogs$
            .pipe(takeUntil(this.destroy$), filter(isDefined))
            .subscribe((catalogs) => {
                this.localStorageService
                    .setIdbEntry(catalogsCacheKey, catalogs)
                    .subscribe();
            });
    }

    clearCatalogsCache(allUsers?: boolean): Observable<void> {
        if (allUsers) {
            return this.localStorageService.bulkDeleteIdbEntries(
                new RegExp(catalogsCacheKey),
            );
        } else {
            return this.localStorageService.deleteIdbEntry(
                catalogsCacheKey,
                true,
            );
        }
    }
}
