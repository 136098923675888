<button
    class="flex w-full flex-1 items-center gap-x-8"
    type="button"
    [disabled]="disabled"
    [ngClass]="{
        'opacity-50': disabled
    }"
    (click)="selectMember()"
>
    <interacta-member-avatar [member]="member" />

    <div class="flex flex-col text-start">
        <div class="typo-sm-bold line-clamp-1">
            {{ member | memberFullname }}
        </div>
        <div class="text-text-mid-contrast typo-xs line-clamp-1 break-all">
            <ng-container *ngIf="infoTemplate; else defaultTemplate">
                <ng-template
                    *ngTemplateOutlet="infoTemplate; context: member"
                />
            </ng-container>
            <ng-template #defaultTemplate>
                <div
                    *ngIf="customInfo; else defaultInfo"
                    [matTooltip]="customInfo"
                >
                    {{ customInfo }}
                </div>

                <ng-template #defaultInfo>
                    <!-- USER -->
                    <ng-container
                        *ngIf="isMemberUser(member) && member.user.contactEmail"
                        >{{ member.user.contactEmail }}</ng-container
                    >

                    <!-- GROUP -->
                    <ng-container *ngIf="isMemberGroup(member)">
                        <ng-container
                            *ngTemplateOutlet="
                                memberGroupTemplate;
                                context: member
                            "
                        />
                    </ng-container>
                </ng-template>
            </ng-template>
        </div>
    </div>
</button>

<ng-template #memberGroupTemplate let-group="group">
    <span *ngIf="group.membersCount !== undefined; tagBlock">
        {{
            'ADMIN_V2.SHARED.MEMBERS.USERS_COUNTER'
                | translate
                    : {
                          counter: group.membersCount
                      }
        }}
    </span>
    <ng-template #tagBlock>
        <div
            *ngIf="group.tags?.length"
            class="flex flex-wrap items-center gap-4"
            [ngClass]="{
                ' opacity-50': disabled
            }"
        >
            <interacta-group-tag
                *ngFor="let tag of group.tags"
                [label]="tag.name"
                [size]="'small'"
            />
        </div>
    </ng-template>
</ng-template>
