import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';

@Directive({
    selector: '[injAttachmentCopyPaste]',
})
export class AttachmentCopyPasteDirective {
    @Input() canPasteFile = false;
    @Output() uploadFiles = new EventEmitter<File[]>();

    @HostListener('paste', ['$event']) attachmentPaste(
        e: ClipboardEvent,
    ): void {
        const clipboardData: DataTransfer | null = e.clipboardData;

        if (
            !this.canPasteFile ||
            !clipboardData ||
            !clipboardData.types.includes('Files')
        )
            return;

        e.preventDefault();
        const items = clipboardData.items;
        const files: File[] = [];
        if (items && items.length) {
            for (const index in items) {
                const item = items[index];
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    file && files.push(file);
                }
            }
        }

        if (files.length) this.uploadFiles.emit(files);
    }
}
