import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface WipLabels {
    title: string;
    description: string;
}

@Component({
    selector: 'interacta-wip-page',
    templateUrl: './wip-page.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WipPageComponent implements OnInit {
    labels!: WipLabels;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        if (!this.route.snapshot.data.wip) {
            throw new Error(
                'Route data "wip: {title, description}" must be provided',
            );
        }

        this.labels = this.route.snapshot.data.wip;
    }
}
