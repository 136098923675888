import { Directive } from '@angular/core';
import { TipService } from '@interacta-shared/feature-tip';
import {
    ATTACHMENTS_COUNT_OPERATION_LIMIT,
    IAttachment,
} from '@modules/post/models/attachment/attachment.model';
import {
    getAddAttachments,
    getRemoveAttachmentIds,
    getUpdateAttachments,
} from '@modules/post/models/attachment/attachment.utils';
import { FormServiceAbstract } from '../../core/helpers/form-service.abstract';
import { Member } from '../../core/models/member/member.model';
import {
    isMemberGroup,
    isMemberUser,
    wrapMembers,
} from '../../core/models/member/member.utils';
import {
    ICommonCollectionsHolder,
    ICommonCollectionsHolderCreate,
    ICommonCollectionsHolderEdit,
} from '../models/common-collections-holder.model';

type TCreate<T> = T & ICommonCollectionsHolderCreate;
type TEdit<T> = T & ICommonCollectionsHolderEdit;

@Directive()
export abstract class CommonCollectionsHolderServiceAbstract<
    T extends ICommonCollectionsHolder,
> {
    protected watcherUserIds: number[] = [];
    protected watcherGroupIds: number[] = [];

    constructor(
        protected tipService: TipService,
        protected formService: FormServiceAbstract<T, any>,
    ) {}

    prepareDataEditing<K extends TEdit<T>>(
        data: K,
        watchersDisabled?: boolean,
        enableValidation = true,
    ): K | null {
        const _data = this.prepareData(data, enableValidation);
        if (!_data) {
            return null;
        }
        this.editAddAndRemoveCollections(_data, watchersDisabled);
        if (
            _data.updateAttachments?.length > ATTACHMENTS_COUNT_OPERATION_LIMIT
        ) {
            this.tipService.warn(
                'VALIDATION_BASE.LABEL_ERROR_ATTACHMENT_UPDATE_LIMIT',
                'duration',
                {
                    limit: ATTACHMENTS_COUNT_OPERATION_LIMIT,
                    selectedCount: _data.updateAttachments.length,
                },
            );
            return null;
        }
        if (
            _data.addAttachments &&
            _data.addAttachments.length > ATTACHMENTS_COUNT_OPERATION_LIMIT
        ) {
            this.tipService.warn(
                'VALIDATION_BASE.LABEL_ERROR_ATTACHMENT_ADD_LIMIT',
                'duration',
                {
                    limit: ATTACHMENTS_COUNT_OPERATION_LIMIT,
                    selectedCount: _data.addAttachments.length,
                },
            );
            return null;
        }
        if (
            _data.removeAttachmentIds?.length >
            ATTACHMENTS_COUNT_OPERATION_LIMIT
        ) {
            this.tipService.warn(
                'VALIDATION_BASE.LABEL_ERROR_ATTACHMENT_DELETE_LIMIT',
                'duration',
                {
                    limit: ATTACHMENTS_COUNT_OPERATION_LIMIT,
                    selectedCount: _data.removeAttachmentIds.length,
                },
            );
            return null;
        }
        return _data;
    }

    prepareDataSaving<K extends TCreate<T>>(
        data: K,
        enableValidation = true,
    ): K | null {
        const _data = this.prepareData(data, enableValidation);
        if (!_data) {
            return null;
        }
        // WARNING was (data.attachments || [])
        _data.addAttachments = (
            _data.attachmentsList?.attachments || []
        ).filter(
            (attachment: IAttachment) =>
                (attachment.isNew || attachment.inPending) &&
                !attachment.inDeleting &&
                !attachment.isLoadingError,
        );
        if (_data.addAttachments?.length > ATTACHMENTS_COUNT_OPERATION_LIMIT) {
            this.tipService.warn(
                'VALIDATION_BASE.LABEL_ERROR_ATTACHMENT_ADD_LIMIT',
                'duration',
                {
                    limit: ATTACHMENTS_COUNT_OPERATION_LIMIT,
                    selectedCount: _data.addAttachments.length,
                },
            );
            return null;
        }
        return _data;
    }

    computeCollection(data: T): void {
        this.watcherUserIds = (data.watcherUsers || []).map((w) => w.id);
        this.watcherGroupIds = (data.watcherGroups || []).map((w) => w.id);
    }

    updateFormValues(data: T): void {
        this.formService.updateFormValues(data);
        this.formService.form.patchValue({
            attachments: data.attachmentsList
                ? data.attachmentsList.attachments
                : [],
            watchers:
                data.watcherUsers || data.watcherGroups
                    ? wrapMembers(
                          data.watcherUsers ?? [],
                          data.watcherGroups ?? [],
                      )
                    : [],
        });
    }

    protected editAddAndRemoveCollections(
        data: TEdit<T>,
        watchersDisabled?: boolean,
    ): void {
        if (!watchersDisabled) {
            data.addWatcherUserIds = (data.watcherUsers ?? [])
                .filter((w) => this.watcherUserIds.indexOf(w.id) === -1)
                .map((w) => w.id);
            data.addWatcherGroupIds = (data.watcherGroups ?? [])
                .filter((w) => this.watcherGroupIds.indexOf(w.id) === -1)
                .map((w) => w.id);

            data.removeWatcherUserIds = (this.watcherUserIds || []).filter(
                (id) =>
                    (data.watcherUsers || [])
                        .map((watcher) => watcher.id)
                        .indexOf(id) === -1,
            );
            data.removeWatcherGroupIds = (this.watcherGroupIds || []).filter(
                (id) =>
                    (data.watcherGroups || [])
                        .map((watcher) => watcher.id)
                        .indexOf(id) === -1,
            );
        }

        // WARNING was this._getUpdateAttachments(data.attachments)
        data.updateAttachments = getUpdateAttachments(
            data.attachmentsList?.attachments || [],
        );
        // WARNING was (data.attachments || [])
        data.addAttachments = getAddAttachments(
            data.attachmentsList?.attachments || [],
        );

        data.removeAttachmentIds = getRemoveAttachmentIds(
            data.attachmentsList?.attachments || [],
        );
    }

    protected prepareData<K extends TCreate<T> | TEdit<T>>(
        data: K,
        enableValidation = true,
    ): K | null {
        if (enableValidation && !this.formService.checkValidity()) {
            return null;
        }

        const watchers: Member[] =
            this.formService.dataFormControls.watchers?.value || [];

        const mappedFromForm = this.formService.mapDataFromForm();

        return {
            ...data,
            ...mappedFromForm,
            watcherUsers: watchers.filter(isMemberUser).map((w) => w.user),
            watcherGroups: watchers.filter(isMemberGroup).map((w) => w.group),
        };
    }
}
