import { zeroFill } from '@interacta-shared/util';
import { ZonedDateTime } from './date-time.model';

export const fromDateToTimestamp = (date: Date): number => {
    const now = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    );
    return now;
};

export const fromDateToTimestampUTC = (
    date: Date | null | undefined,
): number | null => {
    if (!date) {
        return null;
    }
    // IISP-595: https://stackoverflow.com/questions/948532/how-do-you-convert-a-javascript-date-to-utc
    const now_utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return now_utc.getTime();
};

export const fromDateToTimestampLocal = (
    date: Date | null | undefined,
): number | null => {
    if (!date) {
        return null;
    }
    return date.getTime();
};

/**
 * Serialize a date (without time) in ISO-8601 formatted string, such as "2018-11-30"
 * @param date
 */
export const fromDateToISO8601 = (date: Date): string | null => {
    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1;
    let dt: number | string = date.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }
    return date ? `${year}-${month}-${dt}` : null; // YYYY-MM-DD
};

/**
 * Serialize a date-time in ISO-8601 formatted string, such as "1975-08-19T23:15:30.000Z"
 * @param date
 */
export const fromDateTimeToISO8601 = (date: Date): string | null => {
    return date ? date.toISOString() : null;
};

/**
 * Serialize a date-time in Local time formatted string, such as "1975-08-19T23:15:30" or "1975-08-19 23:15:30"
 * @param date
 * @param separator char used to separate date and time - default T
 */
export const fromDateTimeToLocalString = (
    date: Date,
    separator = 'T',
): string | null => {
    return date
        ? `${zeroFill(date.getFullYear(), 4)}-${zeroFill(
              date.getMonth() + 1,
              2,
          )}-${zeroFill(date.getDate(), 2)}${separator}${zeroFill(
              date.getHours(),
              2,
          )}:${zeroFill(date.getMinutes(), 2)}:${zeroFill(
              date.getSeconds(),
              2,
          )}`
        : null;
};

export const fromZonedDatetime = (
    value: ZonedDateTime,
): { datetime: string | null; timezone: string | null } => ({
    datetime: value.localDatetime
        ? fromDateTimeToLocalString(value.localDatetime)
        : null,
    timezone: value.timezone?.zoneId ?? null,
});
