import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'interacta-light-box-zoom',
    templateUrl: './light-box-zoom.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightBoxZoomComponent {
    @Input({ required: true }) zoom!: number;
    @Input() minZoom = 100;
    @Input() maxZoom = 250;

    @Output() zoomOut = new EventEmitter<void>();
    @Output() zoomIn = new EventEmitter<void>();
}
