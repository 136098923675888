import { ManageTaskSrcListType } from '@modules/manage/models/manage.model';
import {
    AttachmentCategoryType,
    IAttachment,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { GlobalSearchTabIndex } from '@modules/search-v2/models/global-search-filters/global-search-filters.utils';
import { Observable } from 'rxjs';
import { IFilePickerAttachment } from './gallery-state.model';

interface PostGallerySourceDetail {
    type: AttachmentCategoryType;
    postId: number;
}
interface FilePickerGallerySourceDetail {
    filePicker: IFilePickerAttachment;
    postId: number;
}

interface TaskGallerySourceDetail {
    type: AttachmentCategoryType;
    taskId: number;
}

export interface GallerySourceDescriptionRegistry {
    community: {
        type: AttachmentCategoryType;
        communityId: number;
    };
    post: PostGallerySourceDetail;
    postComment: PostGallerySourceDetail;
    filePickers: PostGallerySourceDetail; // Custom and screen file pickers to show in post detail attachments tab
    comment: PostGallerySourceDetail & {
        commentId: number;
    };
    postFilePicker: FilePickerGallerySourceDetail;
    postPreviewFilePicker: FilePickerGallerySourceDetail;
    postFormFilePicker: FilePickerGallerySourceDetail;
    linkedPostFilePicker: FilePickerGallerySourceDetail;
    screenFilePicker: FilePickerGallerySourceDetail;
    surveyFilePicker: FilePickerGallerySourceDetail;
    task: TaskGallerySourceDetail;
    toManageTask: TaskGallerySourceDetail & {
        srcList: ManageTaskSrcListType;
    };
    toMonitorTask: TaskGallerySourceDetail & {
        srcList: ManageTaskSrcListType;
    };
    globalSearchAttachment: {
        tabIndex: GlobalSearchTabIndex;
    };
    feedbackComment: {
        type: AttachmentCategoryType;
        commentId: number;
    };
    embedded: {
        attachmentList: IListAttachments | undefined;
    };
}

export type GallerySourceFeature = keyof GallerySourceDescriptionRegistry;

export type GallerySourceDetail<T> = T extends GallerySourceFeature
    ? GallerySourceDescriptionRegistry[T] & { feature: T }
    : never;

export type AnyGallerySourceDetail = GallerySourceDetail<GallerySourceFeature>;

export type GallerySourceFn<F extends GallerySourceFeature> = (
    parameters: GallerySourceDetail<F>,
) => Observable<IListAttachments>;

export interface GalleryFetchParamenters<F extends GallerySourceFeature> {
    detail: GallerySourceDetail<F>;
    pageToken: string | undefined;
    pageSize: number;
}

export type GalleryFetchFn<F extends GallerySourceFeature> = (
    parameters: GalleryFetchParamenters<F>,
) => void;

export type GalleryFetchPreviewFn<F extends GallerySourceFeature> = (
    parameters: GallerySourceDetail<F>,
) => void;

export type GalleryEditFn<F extends GallerySourceFeature> = (
    detail: GallerySourceDetail<F>,
    imageBlob: Blob,
    attachment: IAttachment,
) => void;

export interface GallerySource<F extends GallerySourceFeature> {
    sourceFn: GallerySourceFn<F>;
    fetchFn?: GalleryFetchFn<F>;
    fetchPreviewFn?: GalleryFetchPreviewFn<F>;
    editFn?: GalleryEditFn<F>;
}

export type GallerySources = {
    [F in GallerySourceFeature]: GallerySource<F>;
};

export const NO_MANAGE_VERSION_FEATURES: Array<GallerySourceFeature> = [
    'embedded',
    'globalSearchAttachment',
    'task',
    'toManageTask',
    //TODO aggiungere qui eventuali altre feature
];

export const FETCH_NEXT_FEATURES: Array<GallerySourceFeature> = [
    'post',
    'postComment',
    'toManageTask',
    'community',
    'globalSearchAttachment',
    //TODO aggiungere qui eventuali altre feature
];

export const CUSTOM_ACTIONS_FEATURES: Array<GallerySourceFeature> = [
    'post',
    'community',
    'globalSearchAttachment',
    'embedded',
    //TODO aggiungere qui eventuali altre feature
];
