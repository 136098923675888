<div
    *ngIf="{
        colorGray100: color === 'gray-100',
        colorGray300: color === 'gray-300',
        colorPrimary300: color === 'primary-300',
        colorPrimary: color === 'primary'
    } as $"
    class="flex items-center gap-x-8 overflow-hidden transition-colors"
    [ngClass]="{
        'bg-surface-100': !$.colorPrimary300 && !$.colorGray300,
        'bg-surface-300': $.colorGray300,
        'bg-surface-primary-low-contrast': $.colorPrimary300,
        'w-full': width === 'full',
        'max-w-352': width === 'full' && direction === 'vertical',
        'max-w-max': width === 'max-content',
        'h-44': !expanded && size === 'large',
        'h-36': !expanded && size === 'regular',
        'rounded-lg p-4': direction === 'horizontal',
        'justify-between': direction === 'horizontal' && enableAddAction
    }"
>
    <span
        *ngIf="title != null && title !== '' && direction === 'horizontal'"
        class="typo-xs-bold shrink-0 px-8 py-4"
        >{{ title | translate }}</span
    >
    <div
        *ngIf="actions.length > 0"
        #buttons
        class="flex h-full grow items-center gap-8 overflow-hidden"
        injShowMoreChildren
        [active]="!expanded"
        [ngClass]="{
            'flex-wrap': expanded,
            'flex-col': direction === 'vertical'
        }"
    >
        <button
            *ngFor="
                let quickAction of actions;
                let index = index;
                let last = last
            "
            class="rounded-4 typo-xs-bold flex h-full shrink-0 items-center justify-center py-4 transition-colors"
            injShowMoreItem
            mat-ripple
            type="button"
            [disabled]="disabled"
            [matTooltip]="quickAction.labelTooltip || '' | translate"
            [matTooltipDisabled]="!quickAction.labelTooltip"
            [matTooltipShowDelay]="200"
            [ngClass]="{
                'bg-surface-primary-low-contrast':
                    ($.colorGray100 || $.colorGray300) && quickAction.active,
                'bg-surface-300': $.colorGray100 && !quickAction.active,
                'bg-surface-500': $.colorGray300 && !quickAction.active,
                'bg-surface-primary': $.colorPrimary,
                'bg-surface-A': $.colorPrimary300,
                'text-text':
                    $.colorPrimary300 ||
                    (($.colorGray100 || $.colorGray300) && quickAction.active),
                'text-text-mid-contrast': $.colorGray100 && !quickAction.active,
                'min-w-32 px-8': size === 'regular',
                'min-w-40 px-12': size === 'large',
                'min-w-96 px-16': size === 'extra-large',
                'w-full': direction === 'vertical'
            }"
            (click)="action.emit(quickAction.value)"
        >
            <interacta-icon
                *ngIf="quickAction.icon"
                class="mr-4 h-20 w-20"
                [icon]="quickAction.icon"
            ></interacta-icon>
            <span> {{ quickAction.label | translate }}</span>
        </button>

        <button
            *ngIf="!expanded"
            #showMoreButton
            class="w-64 shrink-0"
            injShowMoreButton
            interacta-button
            type="button"
            [appearance]="'ghost'"
            [bgColor]="'textColor'"
            [icon]="'plus'"
            [label]="'QUICK_ACTION_MORE' | translate"
            (click)="expanded = true"
        ></button>
    </div>

    <button
        *ngIf="title == null && direction === 'horizontal' && enableAddAction"
        class="ml-auto shrink-0 py-4"
        injShowMoreItem
        interacta-icon-button
        type="button"
        [bgColor]="'surface-A'"
        [icon]="'plus'"
        [matTooltip]="'QUICK_ACTION_ADD_ACTION' | translate"
        [matTooltipShowDelay]="200"
        [shape]="'rectangular'"
        [size]="'extra-small'"
        (click)="addAction.emit()"
    ></button>
</div>
