import { IBasePostDifferential } from '@modules/post/models/base-post.model';
import { createAction, props } from '@ngrx/store';

export type DifferentialError = any;

export const initSyncToken = createAction('[Differential] Init Sync Token');

export const initSyncTokenSuccess = createAction(
    '[Differential] Init Sync Token Success',
    props<{ syncToken: string }>(),
);

export const initSyncTokenError = createAction(
    '[Differential] Init Sync Token Error',
    props<{ error: DifferentialError }>(),
);

export const fetchDifferential = createAction(
    '[Differential] Fetch Differential',
);

export const fetchDifferentialSuccess = createAction(
    '[Differential] Fetch Differential Success',
    props<{ syncToken: string; posts: IBasePostDifferential[] }>(),
);

export const fetchDifferentialError = createAction(
    '[Differential] Fetch Differential Error',
    props<{ error: DifferentialError }>(),
);

export const addToDiffPosts = createAction(
    '[Differential] Add To Diff Posts',
    props<{ post: IBasePostDifferential; currentUserId: number }>(),
);

export const removeFromDiffPosts = createAction(
    '[Differential] Remove From Diff Posts',
    props<{ post: IBasePostDifferential }>(),
);

export const mergeDiffPosts = createAction(
    '[Differential] Merge Diff Posts',
    props<{ posts: IBasePostDifferential[] }>(),
);

export const discardDiffPosts = createAction(
    '[Differential] Discard Diff Posts',
);

export const mergePartiallyDashboardOrHome = createAction(
    '[Differential] Merge Differential partially in Dashboard',
    props<{ post: IBasePostDifferential }>(),
);

export const mergePartiallyInDetail = createAction(
    '[Differential] Merge Differential partially in Detail',
    props<{ post: IBasePostDifferential; addToDiffPostDetail: boolean }>(),
);

export const discardDiffPostDetail = createAction(
    '[Differential] Discard Diff Post Detail',
);

export const mergeDiffPostDetail = createAction(
    '[Differential] Merge Diff Post Detail',
    props<{ post: IBasePostDifferential }>(),
);
