import { LocalStorageService } from '@modules/core/services/local-storage.service';
import {
    AttachmentCategoryType,
    AttachmentLayout,
} from '@modules/post/models/attachment/attachment.model';
import {
    DashboardLayout,
    DashboardView,
} from '@modules/post/models/post-list.model';
import { DataLoaderCached } from './data-loader.model';

export type CacheMap<T> = Record<string | number, T>;

export interface CommunityUI {
    dashboardView: DashboardView;
    dashboardLayout: DashboardLayout;
    attachmentLayout: AttachmentLayout;
    attachmentView: AttachmentCategoryType;
}
export interface WorkspaceUI {
    isAccordionOpen: boolean;
}
export interface LeftMenuUI {
    isOpen: boolean;
}

export interface ManageUI {
    processesOpen: boolean;
    tasksOpen: boolean;
    draftsOpen: boolean;
    mentionsOpen: boolean;
}
export class UIState {
    public workspacesUI: DataLoaderCached<CacheMap<WorkspaceUI>>;
    public communitiesUI: DataLoaderCached<CacheMap<CommunityUI>>;
    public leftMenuUI: DataLoaderCached<LeftMenuUI>;
    public manageUI: DataLoaderCached<ManageUI>;

    constructor(private localStorageService: LocalStorageService) {
        this.workspacesUI = new DataLoaderCached(
            'workspacesUI',
            this.localStorageService,
        );

        this.communitiesUI = new DataLoaderCached(
            'communitiesUI',
            this.localStorageService,
        );

        this.leftMenuUI = new DataLoaderCached(
            'leftMenuUI',
            this.localStorageService,
            <LeftMenuUI>{ isOpen: true },
        );

        this.manageUI = new DataLoaderCached(
            'manageUI',
            this.localStorageService,
            this.manageUIDefaults(),
        );
    }

    initialize(): void {
        this.workspacesUI.initialize();
        this.communitiesUI.initialize();
        this.leftMenuUI.initialize();
        this.manageUI.initialize();
    }

    manageUIDefaults(): ManageUI {
        return {
            draftsOpen: true,
            mentionsOpen: true,
            processesOpen: true,
            tasksOpen: true,
        };
    }
}
