import { PartialSharedProfile } from '@modules/core';
import { BehaviorSubject } from 'rxjs';

export const initialState = (): ProfilePreviewDialogData => ({
    isOpen: false,
    isFetching: false,
    isAdmin: false,
});

export class ProfilePreviewDialogState {
    public data = new BehaviorSubject<ProfilePreviewDialogData>(initialState());
}
export interface ProfilePreviewDialogData {
    isOpen: boolean;
    isFetching: boolean;
    isAdmin: boolean;
    user?: PartialSharedProfile;
}
