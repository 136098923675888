<ng-container *ngIf="control | inputState: isReadonly | async as state">
    <div
        #openMenuBtn="cdkOverlayOrigin"
        cdkOverlayOrigin
        class="flex min-h-40 items-center justify-between rounded-lg border-2 px-8 py-4"
        [ngClass]="{
            'border-border-300 focus-within:border-text': !state.invalid,
            'border-border-error': state.invalid,
            'bg-surface-A text-text': !state.disabled,
            'cursor-pointer': !state.disabled,
            'bg-surface-100 text-text-disabled pointer-events-none cursor-default':
                state.disabled
        }"
        (click)="openDialog($event)"
    >
        <div *ngIf="!isOpen" class="flex flex-wrap gap-4">
            <ng-container *ngIf="{ value: value$ | async } as $">
                <span
                    *ngIf="!$.value?.length && label"
                    class="typo-medium text-text-low-contrast"
                >
                    {{ label }}
                </span>
                <div
                    *ngFor="
                        let item of $.value | slice: 0 : VISIBLE_CHIPS;
                        trackBy: trackItem
                    "
                    class="text-text bg-surface-B flex max-w-full shrink-0 cursor-default items-center rounded-3xl px-8 py-4"
                    (click)="$event.stopPropagation()"
                >
                    <ng-template
                        [ngTemplateOutlet]="selectedItemTemplate"
                        [ngTemplateOutletContext]="{ item: item }"
                    >
                    </ng-template>

                    <button
                        *ngIf="
                            maxSelectableItems > 1 &&
                            !lockedIds.includes(item.id)
                        "
                        class="-my-4 ml-8"
                        interacta-icon-button
                        type="button"
                        [attr.aria-label]="
                            'TAG_MEMBER_PICKER.UNSELECT' | translate
                        "
                        [icon]="'close'"
                        [matTooltip]="'TAG_MEMBER_PICKER.UNSELECT' | translate"
                        [size]="'small'"
                        (click)="
                            removeItemByChip(item); $event.stopPropagation()
                        "
                    ></button>
                </div>
                <button
                    *ngIf="$.value && $.value.length > VISIBLE_CHIPS"
                    class="text-text bg-surface-B typo-sm w-min shrink-0 rounded-3xl px-8 py-4"
                    matRipple
                    type="button"
                    [attr.aria-label]="'TAG_MEMBER_PICKER.SHOW_ALL' | translate"
                    [matTooltip]="'TAG_MEMBER_PICKER.SHOW_ALL' | translate"
                    (click)="
                        extendedTagMemberPickerDialogIsOpen = true;
                        $event.stopPropagation()
                    "
                >
                    {{ '+' + ($.value.length - VISIBLE_CHIPS) }}
                </button>
            </ng-container>
        </div>
        <input
            *ngIf="isOpen"
            #input
            autocomplete="off"
            class="w-full pr-40"
            type="text"
            [formControl]="textSearchControl"
            [id]="inputId"
            [readonly]="textSearchControl.disabled || isReadonly"
            (focus)="inputFocus()"
        />
        <button
            class="ml-auto"
            interacta-icon-button
            type="button"
            [icon]="
                isLimitReached
                    ? 'close'
                    : isOpen
                      ? 'search-left'
                      : 'chevron-big-down'
            "
            [ngClass]="{
                'text-text-mid-contrast': !state.invalid,
                'text-text-error': state.invalid
            }"
            (click)="iconClicked($event)"
        ></button>
    </div>
    <interacta-menu
        positionX="after"
        positionY="below"
        [open]="isOpen"
        [origin]="openMenuBtn"
        (closing)="isOpen && closeMenu()"
    >
        <interacta-menu-decorator
            class="block"
            [style.width.px]="openMenuBtn.elementRef.nativeElement?.clientWidth"
        >
            <div
                *ngIf="{
                    search:
                        (textSearchControl
                            | formControlValueV2$
                            | async
                            | trim) ?? '',
                    tags: tags$ | async,
                    members: members$ | async
                } as $"
                #scroll
                class="max-h-240 flex flex-col overflow-y-auto"
                interactaScrollTracker
                (scrolled)="onScroll($event)"
            >
                <ng-container *ngIf="!$.search.length && $.tags && $.members">
                    <div
                        class="border-border-300 flex items-center gap-16 border-b px-12 py-8"
                    >
                        <button
                            interacta-button
                            type="button"
                            [active]="activeTab === 'MEMBER'"
                            [appearance]="'ghost'"
                            [label]="
                                'TAG_MEMBER_PICKER.MEMBERS'
                                    | translate
                                    | uppercase
                            "
                            [size]="'small'"
                            (click)="setActiveTab('MEMBER')"
                        ></button>
                        <button
                            interacta-button
                            type="button"
                            [active]="activeTab === 'TAG'"
                            [appearance]="'ghost'"
                            [label]="
                                'TAG_MEMBER_PICKER.TAGS' | translate | uppercase
                            "
                            [size]="'small'"
                            (click)="setActiveTab('TAG')"
                        ></button>
                    </div>

                    <div
                        *ngIf="
                            searchPlaceholderTemplate && activeTab === 'MEMBER'
                                ? $.members.isFetching && !$.members.list.length
                                : $.tags.isFetching && !$.tags.list.length;
                            else dataTemplate
                        "
                        class="flex flex-col"
                    >
                        <ng-template
                            [ngTemplateOutlet]="searchPlaceholderTemplate"
                        >
                        </ng-template>
                    </div>

                    <ng-template #dataTemplate>
                        <div class="flex flex-col">
                            <ng-container *ngIf="activeTab === 'MEMBER'">
                                <ng-container
                                    *ngFor="
                                        let item of $.members.list;
                                        trackBy: trackItem;
                                        let idx = index
                                    "
                                >
                                    <button
                                        *ngIf="maxSelectableItems === 1"
                                        class="typo-medium hover:bg-surface-B px-12 text-left"
                                        type="button"
                                        (click)="changeSelection(true, item)"
                                    >
                                        <ng-template
                                            [ngTemplateOutlet]="
                                                searchItemTemplate
                                            "
                                            [ngTemplateOutletContext]="{ item }"
                                        >
                                        </ng-template>
                                    </button>

                                    <!-- Caso multiselezione -->
                                    <interacta-checkbox
                                        *ngIf="maxSelectableItems > 1"
                                        class="typo-medium hover:bg-surface-B px-12"
                                        [checked]="
                                            selectedIds.includes(item.id)
                                        "
                                        [disabled]="
                                            (isLimitReached &&
                                                !selectedIds.includes(
                                                    item.id
                                                )) ||
                                            lockedIds.includes(item.id) ||
                                            state.disabled
                                        "
                                        [fullWidth]="true"
                                        [value]="item.id"
                                        (changeCheck)="
                                            changeSelection(
                                                $event === true,
                                                item
                                            )
                                        "
                                    >
                                        <ng-template
                                            [ngTemplateOutlet]="
                                                searchItemTemplate
                                            "
                                            [ngTemplateOutletContext]="{ item }"
                                        >
                                        </ng-template>
                                    </interacta-checkbox>
                                </ng-container>
                                <ng-template
                                    [ngTemplateOutlet]="showMoreComponent"
                                    [ngTemplateOutletContext]="{
                                        list: $.members
                                    }"
                                ></ng-template>
                                <div
                                    *ngIf="
                                        !$.members.isFetching &&
                                        !$.members.totalCount
                                    "
                                    class="typo-sm p-12"
                                >
                                    {{
                                        'TAG_MEMBER_PICKER.MEMBERS_NO_RESULT'
                                            | translate
                                    }}
                                </div>
                            </ng-container>
                            <ng-container *ngIf="activeTab === 'TAG'">
                                <ng-container
                                    *ngFor="
                                        let item of $.tags.list;
                                        trackBy: trackItem;
                                        let idx = index
                                    "
                                >
                                    <button
                                        *ngIf="maxSelectableItems === 1"
                                        class="typo-medium hover:bg-surface-B px-12 text-left"
                                        type="button"
                                        (click)="changeSelection(true, item)"
                                    >
                                        <ng-template
                                            [ngTemplateOutlet]="
                                                searchItemTemplate
                                            "
                                            [ngTemplateOutletContext]="{ item }"
                                        >
                                        </ng-template>
                                    </button>

                                    <!-- Caso multiselezione -->
                                    <interacta-checkbox
                                        *ngIf="maxSelectableItems > 1"
                                        class="typo-medium hover:bg-surface-B px-12"
                                        [checked]="
                                            selectedIds.includes(item.id)
                                        "
                                        [disabled]="
                                            (isLimitReached &&
                                                !selectedIds.includes(
                                                    item.id
                                                )) ||
                                            lockedIds.includes(item.id) ||
                                            state.disabled
                                        "
                                        [fullWidth]="true"
                                        [value]="item.id"
                                        (changeCheck)="
                                            changeSelection(
                                                $event === true,
                                                item
                                            )
                                        "
                                    >
                                        <ng-template
                                            [ngTemplateOutlet]="
                                                searchItemTemplate
                                            "
                                            [ngTemplateOutletContext]="{ item }"
                                        >
                                        </ng-template>
                                    </interacta-checkbox>
                                </ng-container>
                                <ng-template
                                    [ngTemplateOutlet]="showMoreComponent"
                                    [ngTemplateOutletContext]="{ list: $.tags }"
                                ></ng-template>
                                <div
                                    *ngIf="
                                        !$.tags.isFetching && !$.tags.totalCount
                                    "
                                    class="typo-sm p-12"
                                >
                                    {{
                                        'TAG_MEMBER_PICKER.TAGS_NO_RESULT'
                                            | translate
                                    }}
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>
                </ng-container>

                <ng-container
                    *ngIf="$.search.length > 0 && $.tags && $.members"
                >
                    <div
                        *ngIf="
                            $.tags.isFetching ||
                                ($.members.isFetching &&
                                    !$.members.list.length);
                            else dataTemplate
                        "
                        class="flex flex-col"
                    >
                        <ng-template
                            [ngTemplateOutlet]="searchPlaceholderTemplate"
                        >
                        </ng-template>
                    </div>

                    <ng-template #dataTemplate>
                        <div
                            *ngIf="
                                !$.tags.list.length && !$.members.list.length
                            "
                            class="typo-sm p-12"
                        >
                            {{ 'UI.NO_RESULT' | translate }}
                        </div>

                        <!-- TAG -->
                        <div *ngIf="$.tags.list.length" class="flex flex-col">
                            <div
                                class="border-border-300 typo-xs-bold-uppercase w-full border-b px-12 py-8"
                            >
                                {{ 'TAG_MEMBER_PICKER.TAGS' | translate }}
                            </div>
                            <ng-container
                                *ngFor="
                                    let item of $.tags.list;
                                    trackBy: trackItem;
                                    let idx = index
                                "
                            >
                                <button
                                    *ngIf="maxSelectableItems === 1"
                                    class="typo-medium hover:bg-surface-B px-12 text-left"
                                    type="button"
                                    (click)="changeSelection(true, item)"
                                >
                                    <ng-template
                                        [ngTemplateOutlet]="searchItemTemplate"
                                        [ngTemplateOutletContext]="{ item }"
                                    >
                                    </ng-template>
                                </button>

                                <!-- Caso multiselezione -->
                                <interacta-checkbox
                                    *ngIf="maxSelectableItems > 1"
                                    class="typo-medium hover:bg-surface-B px-12"
                                    [checked]="selectedIds.includes(item.id)"
                                    [disabled]="
                                        (isLimitReached &&
                                            !selectedIds.includes(item.id)) ||
                                        lockedIds.includes(item.id) ||
                                        state.disabled
                                    "
                                    [fullWidth]="true"
                                    [value]="item.id"
                                    (changeCheck)="
                                        changeSelection($event === true, item)
                                    "
                                >
                                    <ng-template
                                        [ngTemplateOutlet]="searchItemTemplate"
                                        [ngTemplateOutletContext]="{ item }"
                                    >
                                    </ng-template>
                                </interacta-checkbox>
                            </ng-container>
                        </div>
                        <!-- MEMBERS -->
                        <div
                            *ngIf="$.members.list.length"
                            class="flex flex-col"
                        >
                            <div
                                class="border-border-300 typo-xs-bold-uppercase w-full border-b px-12 py-8"
                            >
                                {{ 'TAG_MEMBER_PICKER.MEMBERS' | translate }}
                            </div>
                            <ng-container
                                *ngFor="
                                    let item of $.members.list;
                                    trackBy: trackItem;
                                    let idx = index
                                "
                            >
                                <button
                                    *ngIf="maxSelectableItems === 1"
                                    class="typo-medium hover:bg-surface-B px-12 text-left"
                                    type="button"
                                    (click)="changeSelection(true, item)"
                                >
                                    <ng-template
                                        [ngTemplateOutlet]="searchItemTemplate"
                                        [ngTemplateOutletContext]="{ item }"
                                    >
                                    </ng-template>
                                </button>

                                <!-- Caso multiselezione -->
                                <interacta-checkbox
                                    *ngIf="maxSelectableItems > 1"
                                    class="typo-medium hover:bg-surface-B px-12"
                                    [checked]="selectedIds.includes(item.id)"
                                    [disabled]="
                                        (isLimitReached &&
                                            !selectedIds.includes(item.id)) ||
                                        lockedIds.includes(item.id) ||
                                        state.disabled
                                    "
                                    [fullWidth]="true"
                                    [value]="item.id"
                                    (changeCheck)="
                                        changeSelection($event === true, item)
                                    "
                                >
                                    <ng-template
                                        [ngTemplateOutlet]="searchItemTemplate"
                                        [ngTemplateOutletContext]="{ item }"
                                    >
                                    </ng-template>
                                </interacta-checkbox>
                            </ng-container>
                            <ng-template
                                [ngTemplateOutlet]="showMoreComponent"
                                [ngTemplateOutletContext]="{ list: $.members }"
                            ></ng-template>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </interacta-menu-decorator>
    </interacta-menu>
</ng-container>

<ng-template #searchPlaceholderTemplate>
    <interacta-member-list-placeholder
        class="px-12"
    ></interacta-member-list-placeholder>
</ng-template>
<ng-template #selectedItemTemplate let-item="item">
    <interacta-tag-splittable
        *ngIf="item | apply: isTagOfTagMember; else member"
        [recipientsTagExcludedGroups]="
            recipientsTagExcludedGroupsControl | formControlValueV2$ | async
        "
        [tag]="item"
        (tagInfo)="openTagMembers(item)"
    >
    </interacta-tag-splittable>

    <ng-template #member>
        <interacta-member
            [fontWeight]="'regular'"
            [member]="item"
            [memberClickBehavior]="'none'"
            [size]="'small'"
        ></interacta-member
    ></ng-template>
</ng-template>
<ng-template #searchItemTemplate let-item="item">
    <interacta-group-tag
        *ngIf="item | apply: isTagOfTagMember; else member"
        class="block py-8"
        [excludedGroupsCount]="
            {
                tagId: item.id,
                tagExcludedGroups:
                    recipientsTagExcludedGroupsControl
                    | formControlValueV2$
                    | async
            } | apply: getExcludedGroupCountByTagId
        "
        [label]="item.name"
    ></interacta-group-tag>
    <ng-template #member>
        <interacta-member
            class="block py-8"
            [member]="item"
            [memberClickBehavior]="'none'"
            [showInfo]="true"
        ></interacta-member>
    </ng-template>
</ng-template>
<ng-template #showMoreComponent let-list="list">
    <interacta-load-more
        *ngIf="list.nextPageToken.tag === 'regularLoading'"
        class="flex items-center justify-center py-8"
        size="regular"
        [isLoading]="list.isFetching"
        [label]="'UI.SHOW_MORE_UNCOUNT' | translate"
        (loadMore)="doSearch()"
    ></interacta-load-more>
</ng-template>

<ng-container *ngIf="tagGroupDialogData$ | async as data">
    <interacta-tag-groups-dialog
        *ngIf="data.tag && data.isOpen"
        [communityId]="communityId"
        [isOpen]="data.isOpen"
        [isTagLocked]="data.isTagLocked"
        [originalExcludedGroupIds]="data.originalExcludedGroupIds"
        [recipientsTagExcludedGroupsControl]="
            recipientsTagExcludedGroupsControl
        "
        [tag]="data.tag"
        (closeDialog)="
            tagGroupDialogData$.next({
                isOpen: false,
                tag: null,
                originalExcludedGroupIds: [],
                isTagLocked: false
            })
        "
    >
    </interacta-tag-groups-dialog>
</ng-container>

<interacta-input-tag-members-extended-field-dialog
    [isOpen]="extendedTagMemberPickerDialogIsOpen"
    [lockedIds]="lockedIds"
    [recipients]="control.value ?? []"
    [recipientsTagExcludedGroups]="
        recipientsTagExcludedGroupsControl.value ?? []
    "
    (closeDialog)="extendedTagMemberPickerDialogIsOpen = false"
    (openTagMembers)="openTagMembers($event)"
    (updateRecipients)="updateRecipients($event)"
>
</interacta-input-tag-members-extended-field-dialog>
