import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {
    ConfigurationService,
    UserCredentialsValidFormat,
    credentialFormatMessage,
} from '@interacta-shared/data-access-configuration';
import { InputSelectItem } from '@interacta-shared/ui';
import { ValidationKey, isDefined } from '@interacta-shared/util';
import {
    DateGranularity,
    dateGranularity,
} from '@interacta-shared/util-common';
import { AdminV2CommunityCustomField } from '@modules/admin-v2-community/models/admin-v2-community.model';
import { ILink } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { SurveyQuestionForCreate } from '@modules/post/models/survey-post/survey-post.model';
import { TranslateService } from '@ngx-translate/core';
import { getLabelServerTranslationV2 } from './i18n.utils';

export enum CustomValidationKey {
    MIN_LENGTH_FILE_LIST = 'minlengthFileSelect',
    MAX_LENGTH_FILE_LIST = 'maxlengthFileSelect',

    ALREADY_EXISTS = 'alreadyExists',
    NUMBER_LOWER_THAN = 'numberLowerThan',
    NUMBER_GREATER_THAN = 'numberGreaterThan',
    CHECK_INT = 'checkInt',
    CHECK_DECIMAL = 'checkDecimal',
    CHECK_ALPHANUMERIC = 'checkAlphanumeric',
    CHECK_DATE = 'checkDate',
    HASHTAG_NOT_UNIQUE = 'hashtagNotUnique',
    EXTERNAL_REF_NOT_UNIQUE = 'externalRefNotUnique',
    DELETED = 'deleted',

    MIME_TYPE_NOT_ADMITTED = 'mimeTypeNotAdmitted',
    MAIL_ALREADY_EXISTS = 'mailAlreadyExists',
    VALUE_ALREADY_EXISTS = 'valueAlreadyExists',
    CUSTOM_FIELD_NOT_ALREADY_EXIST = 'customFieldNotAlreadyExist',
    INVALID_SYMBOLS = 'invalidSymbols',

    CHECK_MATERIAL_DATEPICKER_PARSE = 'matDatepickerParse',
    ALL_OR_NONE = 'allOrNone',
    LINK_LABEL = 'linkLabel',
    USERNAME_ALREADY_EXISTS = 'usernameAlreadyExists',
    GOOGLE_ACCOUNT_ID_ALREADY_EXISTS = 'googleAccountAlreadyExists',
    MICROSOFT_ACCOUNT_ID_ALREADY_EXISTS = 'microsoftAccountAlreadyExists',
    DATE_TIME_GREATER_THEN_START = 'dateTimeGreaterThanStart',
    DATE_TIME_GREATER_EQUAL_THEN_START = 'dateTimeGreaterEqualThanStart',
}

export class CustomValidators extends Validators {
    static numberMustBeLowerThanOrEqual(
        formControl: UntypedFormControl,
        label: string,
    ): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            if (
                !c.value ||
                !isDefined(formControl?.value) ||
                +formControl.value >= +c.value
            ) {
                return null;
            }

            const result = {
                [CustomValidationKey.NUMBER_LOWER_THAN]: {
                    number: formControl.value,
                    label: label,
                },
            };

            return result;
        };
    }

    static numberMustBeGreaterThanOrEqual(
        formControl: UntypedFormControl,
        label: string,
    ): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            if (
                !c.value ||
                !isDefined(formControl?.value) ||
                +formControl.value <= +c.value
            ) {
                return null;
            }

            const result = {
                [CustomValidationKey.NUMBER_GREATER_THAN]: {
                    number: formControl.value,
                    label: label,
                },
            };
            return result;
        };
    }

    /*
     * extends Validators.max that is not able da handle bigint numbers
     */
    static maxLengthNumeric(param: number): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const result: ValidationErrors = {
                [ValidationKey.MAX_VALUE]: {
                    max: '9'.repeat(param - 1),
                },
            };

            return c.value && (c.value as number).toString().length > param
                ? result
                : null;
        };
    }

    static checkAdmittedMimeTypes(acceptedType: string[]): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const result: ValidationErrors = {
                [CustomValidationKey.MIME_TYPE_NOT_ADMITTED]: {},
            };
            const setAcceptedMimeTypes = (acceptedType || []).filter(
                (t) => !t.match('^\\.'),
            );
            const validAttachmentsMimeTypes = this.getFilePickerValidFilesArray(
                c.value,
            ).map((a) => a.contentMimeType.toString());

            // match "audio/*", "video/*", "image/*" and MIME types
            return validAttachmentsMimeTypes.some(
                (attachmentMimeType) =>
                    !setAcceptedMimeTypes.some((mimeType) => {
                        const reg = new RegExp(mimeType);
                        return !!reg.exec(attachmentMimeType);
                    }),
            )
                ? result
                : null;
        };
    }

    static checkInt(): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const result: ValidationErrors = {
                [CustomValidationKey.CHECK_INT]: {},
            };
            //'^[0-9+e.]*$' - instead of '^[0-9]*$' cause a number like that "2.1474836473432542e+23" is possible
            // let reg = new RegExp('^[0-9+e.]*$');
            const reg = new RegExp('^-?[0-9+-]*$');
            return c.value && !reg.test(c.value as string) ? result : null;
        };
    }

    static checkDecimal(): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const result = {
                [CustomValidationKey.CHECK_DECIMAL]: {},
            };
            const reg = new RegExp('^-?[0-9]+(.[0-9]+)?$');
            return c.value && !reg.test(c.value as string) ? result : null;
        };
    }

    static checkAlphanumeric(): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const result = {
                [CustomValidationKey.CHECK_ALPHANUMERIC]: {},
            };
            const reg = new RegExp('^[0-9A-Za-z]*$');
            return c.value && !reg.test(c.value as string) ? result : null;
        };
    }

    static checkValidMailName(c: UntypedFormControl): ValidationErrors | null {
        const validationError = {
            [ValidationKey.EMAIL]: {},
        };
        const regExp = /^[\w.-]*$/;
        return c.value?.match(regExp) ? null : validationError;
    }

    static checkInputSelectEmail(): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const validationError = {
                [ValidationKey.EMAIL]: {},
            };
            let valid = true;
            if (c.value)
                c.value.forEach((value: InputSelectItem) => {
                    if (valid && !reg.test(value.label)) valid = false;
                });
            return valid ? null : validationError;
        };
    }

    static checkDate(): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const result = {
                [CustomValidationKey.CHECK_DATE]: {},
            };
            // TODO
            if (!c.value) {
                return null;
            }
            if (c.value instanceof Date) {
                return null;
            }
            return result;
        };
    }

    static valueAlreadyExists<T extends string | number>(
        list: T[] | null,
    ): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            if (!c || !isDefined(c.value)) return null;

            return (list || []).includes(c.value as T)
                ? {
                      [CustomValidationKey.VALUE_ALREADY_EXISTS]: {},
                  }
                : null;
        };
    }

    static mailAlreadyExists(list: string[] | null): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            if (!c || !c.value) return null;

            return (list || []).indexOf(c.value as string) === -1
                ? null
                : {
                      [CustomValidationKey.MAIL_ALREADY_EXISTS]: {},
                  };
        };
    }

    static inputSelectEmailAlreadyExists(list: string[] | null): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            if (!c || !c.value || !c.value[0]) return null;

            return (list || []).indexOf(c.value[0].label as string) === -1
                ? null
                : {
                      [CustomValidationKey.MAIL_ALREADY_EXISTS]: {},
                  };
        };
    }

    static requiredLink(c: AbstractControl): ValidationErrors | null {
        return c.value?.url ? null : { required: true };
    }

    static trimmedRequired(c: AbstractControl): ValidationErrors | null {
        const result = {
            [ValidationKey.REQUIRED]: {},
        };
        const valid =
            typeof c.value === 'string'
                ? c.value.trim().length
                : isDefined(c.value);
        return valid ? null : result;
    }

    /**
     * The enabled validator is defined only for compliance with the other
     * validators, but it's not an acutal validator. In fact it doesn't validate
     * anything.
     */
    public static enabled(_: AbstractControl): ValidationErrors | null {
        return null;
    }

    public static filePickerRequired(): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const result: ValidationErrors = {
                [ValidationKey.REQUIRED]: {},
            };
            const validFiles = this.getFilePickerValidFilesArray(c.value);
            return validFiles.length === 0 ? result : null;
        };
    }

    public static filePickerMinLength(param: number): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const result = {
                [CustomValidationKey.MIN_LENGTH_FILE_LIST]: {
                    requiredLength: param,
                },
            };
            const validFiles = this.getFilePickerValidFilesArray(c.value);
            const num = validFiles.length > 0 ? validFiles.length : -1;
            return num != -1 && num < param ? result : null;
        };
    }

    public static filePickerMaxLength(param: number): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const validFiles = this.getFilePickerValidFilesArray(c.value);
            const actualLength = validFiles.length > 0 ? validFiles.length : -1;
            const result: ValidationErrors = {
                [CustomValidationKey.MAX_LENGTH_FILE_LIST]: {
                    requiredLength: param,
                    actualLength,
                },
            };

            return actualLength != -1 && actualLength > param ? result : null;
        };
    }

    static getFilePickerValidFilesArray(value: any): IAttachment[] {
        if (!value || !Array.isArray(value)) return [];

        return ((value as IAttachment[]) || []).filter(
            (a) => !a.inDeleting && !a.isLoadingError && !a.isCanceled,
        );
    }

    public static surveyQuestionsMinLength(param: number): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const result = {
                [ValidationKey.REQUIRED]: {},
            };
            const validQuestions = this.getSurveyQuestionsValidArray(c.value);
            const num = validQuestions.length ?? -1;
            return num != -1 && num < param ? result : null;
        };
    }

    static getSurveyQuestionsValidArray(value: any): SurveyQuestionForCreate[] {
        if (!value || !Array.isArray(value)) return [];

        return ((value as SurveyQuestionForCreate[]) || []).filter(
            (a) => (a.id || a.localId) && !a.deleted,
        );
    }

    // validatore usato nei campi standard della nuova amministrazione, nell'autcompletamento del titolo
    static customFieldNotAlreadyExist(
        configurationService: ConfigurationService,
    ): ValidatorFn {
        return (
            c: AbstractControl<AdminV2CommunityCustomField>,
        ): ValidationErrors | null => {
            const customField = c.value;
            if (customField && customField.deleted) {
                return <ValidationErrors>{
                    [CustomValidationKey.CUSTOM_FIELD_NOT_ALREADY_EXIST]: {
                        field: getLabelServerTranslationV2(
                            customField.labelV2,
                            configurationService,
                        ),
                    },
                };
            }
            return null;
        };
    }

    static invalidSymbols(symbols: string[], fieldLabel?: string): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const pattern = new RegExp(
                `^[^${symbols.join('').replace('\\', '\\\\')}]*$`,
            );

            const patternValidator = Validators.pattern(pattern);
            const validationResult = patternValidator(c);
            if (validationResult) {
                return {
                    [CustomValidationKey.INVALID_SYMBOLS]: {
                        errors: validationResult,
                        symbols: symbols.join(' '),
                        fieldLabel: fieldLabel ?? '',
                    },
                };
            }
            return null;
        };
    }

    /**
     * @deprecated
     * @param message
     * @returns
     */
    public static allOrNone(message?: string) {
        return (group: UntypedFormGroup): ValidationErrors | null => {
            const values: any[] = Object.keys(group.controls).map(
                (key) => group.get(key)?.value,
            );

            if (values.every((v) => !!v) || values.every((v) => !v)) {
                return null;
            } else {
                const result: ValidationErrors = message
                    ? { [ValidationKey.MESSAGE]: message }
                    : { [CustomValidationKey.ALL_OR_NONE]: {} };

                return result;
            }
        };
    }

    static linkLabel({ value }: AbstractControl): ValidationErrors | null {
        const link: ILink = value;
        return link && link.label && !link.url
            ? {
                  [CustomValidationKey.LINK_LABEL]: true,
              }
            : null;
    }

    static passwordFormat(
        format: UserCredentialsValidFormat,
        translateService: TranslateService,
    ): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            const result = {
                [ValidationKey.MESSAGE]: credentialFormatMessage(
                    format,
                    translateService,
                ),
            };
            let valid = !!c.value;
            valid = valid && c.value.length >= format.minLength;
            valid = valid && c.value.length <= format.maxLength;
            valid =
                valid &&
                c.value.replace(/[^A-Z]/g, '').length >=
                    format.atLeastUpperCharsQuantity;
            valid =
                valid &&
                c.value.replace(/[^a-z]/g, '').length >=
                    format.atLeastLowerCharsQuantity;
            valid =
                valid &&
                c.value.replace(/[^0-9]/g, '').length >=
                    format.atLeastNumericCharsQuantity;

            return !valid ? result : null;
        };
    }

    static serverErrorKey(key: string): Record<string, boolean> {
        const obj = {
            [key]: true,
        };
        return obj;
    }

    public static dateTimeGreaterThanStart(
        start: Date,
        strict?: boolean,
        granularity: DateGranularity = 'day',
    ): ValidatorFn {
        return (c: AbstractControl): ValidationErrors | null => {
            if (!c.value) {
                return null;
            }

            const granularStart = dateGranularity(start, granularity);
            const granularValue = dateGranularity(c.value as Date, granularity);

            const valueIsAfterStart = strict
                ? granularValue > granularStart
                : granularValue >= granularStart;

            if (valueIsAfterStart) {
                return null;
            }

            return {
                [strict
                    ? CustomValidationKey.DATE_TIME_GREATER_THEN_START
                    : CustomValidationKey.DATE_TIME_GREATER_EQUAL_THEN_START]:
                    {},
            };
        };
    }
}
