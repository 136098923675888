import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { ClosableDialog } from '../model/closable-dialog.model';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private cdkOverlay: Overlay) {}

    create(
        templateRef: TemplateRef<any>,
        viewContainerRef: ViewContainerRef,
    ): ClosableDialog {
        const overlayRef = this.cdkOverlay.create({
            panelClass: 'interacta-dialog',
        });
        const portal = new TemplatePortal(templateRef, viewContainerRef);
        overlayRef.attach(portal);

        return {
            close: () => this.close(overlayRef),
        };
    }

    private close(overlayRef: OverlayRef) {
        overlayRef.detach();
    }
}
