import { Index } from '@interacta-shared/util';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    AIContentGenerationType,
    AIContextType,
    MAIN_THREAD,
} from '../models/ai.model';
import { AIState } from './ai.reducer';

export const selectAIFeature = createFeatureSelector<AIState>('ai');

export const selectIsPanelOpen = createSelector(
    selectAIFeature,
    (state) => state.isPanelOpen,
);

export const selectIsPanelAnimating = createSelector(
    selectAIFeature,
    (state) => state.isPanelAnimating,
);

export const selectCachedAttachments = createSelector(
    selectAIFeature,
    (state) => state.cachedAttachments,
);

export const selectCachedAttachment = (id: number) =>
    createSelector(
        selectCachedAttachments,
        (attachments) => attachments.find((a) => a.id === id) ?? null,
    );

export const selectCachedAttachmentsPostList = createSelector(
    selectAIFeature,
    (state) => state.cachedAttachmentsLists['post'],
);
export const selectCachedAttachmentsCommentList = createSelector(
    selectAIFeature,
    (state) => state.cachedAttachmentsLists['comment'],
);
export const selectCachedAttachmentsOtherList = createSelector(
    selectAIFeature,
    (state) => state.cachedAttachmentsLists['postFilePicker'],
);

export const selectActiveContextType = createSelector(
    selectAIFeature,
    (state) => state.activeContext,
);

export const selectActiveContext = createSelector(selectAIFeature, (state) =>
    state.activeContext ? state.contexts[state.activeContext] : null,
);

export const selectActiveThread = createSelector(
    selectActiveContext,
    (state) => (state?.activeThread ? state.activeThread : MAIN_THREAD),
);

export const selectActiveInitialContentGeneration = createSelector(
    selectActiveContext,
    (state) => state?.contentGeneration?.initialContent,
);

export const selectActiveGeneratedContentContext = createSelector(
    selectActiveContext,
    (state) => state?.contentGeneration,
);

export const selectActiveGeneratedContent = (type: AIContentGenerationType) =>
    createSelector(selectActiveGeneratedContentContext, (state) =>
        state ? state[type] : null,
    );

export const selectContext = (contextType: AIContextType) =>
    createSelector(selectAIFeature, (state) => state.contexts[contextType]);

export const selectActiveHistory = (threadId?: Index) =>
    createSelector(selectActiveContext, (state) =>
        state ? state.threads[threadId ?? MAIN_THREAD] : null,
    );

export const selectActiveIsFetching = (threadId?: Index) =>
    createSelector(selectActiveContext, (state) =>
        state ? state.threads[threadId ?? MAIN_THREAD].isFetching : false,
    );

export const selectActiveSessionId = createSelector(
    selectActiveContext,
    (state) => (state ? state.sessionId : null),
);

export const selectActiveDraftQuestion = createSelector(
    selectActiveContext,
    (state) => (state ? state.draftQuestion : null),
);
