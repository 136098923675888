<div class="flex flex-col space-y-4">
    <span class="typo-sm-bold">{{
        'UI.TIME_PICKER.CHOOSE_TIME' | translate
    }}</span>
    <div class="w-160 relative flex flex-col gap-12">
        <input
            #openMenu="cdkOverlayOrigin"
            cdkOverlayOrigin
            class="border-border-300 typo-medium h-48 w-full rounded-lg border-2 pl-6"
            type="time"
            [formControl]="time"
            [readonly]="readonly"
            [step]="inputStepInSeconds"
            (click)="toggleTimeMenu($event)"
        />
        <interacta-icon
            class="absolute right-14 top-12 h-24 w-24"
            icon="clock"
        ></interacta-icon>
    </div>
</div>

<interacta-menu
    *ngIf="isTimeMenuOpen"
    positionX="after"
    positionY="above"
    [open]="isTimeMenuOpen"
    [origin]="openMenu"
    (closing)="closeTimeMenu()"
>
    <div class="max-h-260 bg-surface-A overflow-y-auto rounded-2xl shadow-sm">
        <interacta-menu-decorator>
            <interacta-button-menu
                *ngFor="let step of timeSteps"
                [classes]="'tabular-nums'"
                [label]="step | formatInputTime: timeFormat"
                (click)="selectTimeStep(step)"
            ></interacta-button-menu>
        </interacta-menu-decorator>
    </div>
</interacta-menu>
