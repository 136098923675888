import { sanitizeLink } from '../generic.utils';
import { DeltaUtility } from './delta-utility.class';

// @ts-ignore
import Delta from 'quill-delta/lib/delta';

function toIdToken(value: any): string {
    return `${value ? ':' + (value as string) : ''}`;
}
export class Delta2Server {
    static readonly linkRegMatcher =
        /((https?:|ftp:|www\.|[^\s:=]+@www\.).*?([a-z_/0-9\-#=&]+\)?))(?=(\.|,|;|\(|\)|\?|!)?("|«|»|\[|\s|\r|\n|$))/gi;
    static readonly anchorLinkRegExpMatcher =
        /href="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})"/;

    static findMatchForLink(element: string | null): string[] | null {
        return element?.match(this.linkRegMatcher) ?? null;
    }

    static findLinkInsideHref(element: string | null): string[] | null {
        return element?.match(this.anchorLinkRegExpMatcher) ?? null;
    }

    process(input: Delta): string | null {
        if (!input || !input.ops) return null;
        let output: Delta[] = input.ops;
        output = this.mentions(output);
        output = this.linkInnerText(output);
        output = DeltaUtility.sanitizeDeltaObject(output);
        return DeltaUtility.isEmpty(output) ? null : JSON.stringify(output);
    }

    private mentions(output: Delta[]): Delta[] {
        return output.map((element: Record<string, any>) => {
            const key = Object.keys(element).find(
                (key) => DeltaUtility.opsDeltaOperations.indexOf(key) > -1,
            );

            if (key && element[key].mention) {
                const {
                    instanceId,
                    mentionType,
                    id,
                    clientUid,
                    denotationChar,
                    value,
                } = element[key].mention;
                const mentionLink = `${DeltaUtility.prefixLinkCustom}:${
                    mentionType as string
                }${toIdToken(clientUid)}${toIdToken(id)}${toIdToken(
                    instanceId,
                )}`;

                return {
                    ...element,
                    [key]: `${denotationChar as string}${value as string}`,
                    attributes: { link: mentionLink },
                };
            } else {
                return element;
            }
        });
    }

    private linkInnerText(output: Delta[]): Delta[] {
        const result = [...output];
        let indexCopy = 0;
        output.forEach((element) => {
            if (element.insert && !element.attributes?.link) {
                const matchAll = Delta2Server.findMatchForLink(
                    element.insert as string,
                );
                if (matchAll) {
                    const newOpts = this.allMatchesLinkFound(element, matchAll);
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    result.splice(indexCopy, 1, ...newOpts);
                    indexCopy += newOpts.length;
                }
            }
            indexCopy++;
        });
        return result;
    }

    private allMatchesLinkFound(opt: any, matches: string[]): Delta[] {
        const newOpts = [];
        let consumingValue = opt.insert as string;
        matches.forEach((match) => {
            //cerco le parentesi chiuse che non sono aperte
            if (match[match.length - 1] === ')' && match.indexOf('(') < 0) {
                match = match.substring(0, match.length - 1);
            }

            const startIndex = consumingValue.indexOf(match);
            const endIndex = startIndex + match.length;
            startIndex > 0 &&
                newOpts.push(
                    this.createOpt(
                        'insert',
                        consumingValue.substring(0, startIndex),
                        opt.attributes,
                    ),
                );
            newOpts.push(
                this.createLinkOpt(
                    'insert',
                    consumingValue.substring(startIndex, endIndex),
                    opt.attributes,
                ),
            );

            if (endIndex <= consumingValue.length) {
                consumingValue = consumingValue.substring(
                    endIndex,
                    consumingValue.length,
                );
            }
        });
        consumingValue.length > 0 &&
            newOpts.push(
                this.createOpt('insert', consumingValue, opt.attributes),
            );
        return newOpts;
    }

    private createOpt(key: string, value: string, attributes?: any) {
        const object: Delta = {} as Delta;
        object[key] = value;
        if (attributes) {
            object.attributes = { ...attributes };
        }
        return object;
    }

    private createLinkOpt(key: string, value: string, attributes?: any) {
        const object = this.createOpt(key, value, attributes);
        object.attributes = { ...object.attributes } || {};
        object.attributes.link = sanitizeLink(value);
        return object;
    }
}
