export interface ILinkPreview {
    title?: string;
    image: string;
    url: string;
    hidden: boolean;
}

export class LinkPreviewSerialize {
    public static linkPreview = (linkPreview: ILinkPreview): any => {
        const record = {
            title: linkPreview?.title ?? undefined,
            image: linkPreview?.image,
            url: linkPreview?.url,
            hidden: linkPreview?.hidden,
        };
        return record;
    };
}
export class LinkPreviewDeserialize {
    public static linkPreview = (record: any): ILinkPreview => {
        const lp: ILinkPreview = {
            title: record?.title ?? undefined,
            image: record?.image,
            url: record?.url,
            hidden: record?.hidden ?? false,
        };
        return lp;
    };
}
