import { inject, Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { i18nDeltaV2, QuillText } from '@modules/core';
import { getDeltaServerTranslationV2 } from '@modules/core/helpers/i18n.utils';

@Pipe({
    name: 'currentLanguageDeltaV2',
})
export class CurrentLanguageDeltaV2Pipe implements PipeTransform {
    private readonly configurationService = inject(ConfigurationService);

    transform(value: i18nDeltaV2, translateLabel = false): QuillText {
        return getDeltaServerTranslationV2(
            value,
            this.configurationService,
            translateLabel,
        );
    }
}
