import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ICommunity } from '@modules/communities/models/communities.model';

@Component({
    selector: 'interacta-recent-communities[recentCommunities]',
    templateUrl: './recent-communities.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentCommunitiesComponent {
    @Input() recentCommunities!: ICommunity[];

    @Output() selectedCommunity = new EventEmitter<ICommunity>();
}
