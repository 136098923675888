import { fromEditableTableData } from '@modules/editable-table';
import { QuillTable } from './quill-table.model';

export const fromQuillTables = (tables: QuillTable[]): any =>
    tables.map(fromQuillTable);

export const fromQuillTable = (table: QuillTable): any => ({
    clientUid: table.clientUid,
    title: table.title,
    id: table.id,
    data: fromEditableTableData(table.data),
});
