import { Language } from '@interacta-shared/data-access-configuration';
import {
    emptyPaginatedList,
    fromPageToken,
    PaginatedList,
} from '@interacta-shared/util';
import { toWorkflowStatus } from '@modules/communities/models/workflow/workflow-base.deserialize';
import { toWorkflowOperation } from '@modules/communities/models/workflow/workflow.deserialize';
import { IWorkflowStatus } from '@modules/communities/models/workflow/workflow.model';
import { IUser, IUserAction, QuillText, UserDeserilize } from '@modules/core';
import { Delta2Server } from '@modules/core/helpers/delta/delta-2-server.class';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import { IEventHistory } from '@modules/core/models/notification-user.model';
import { toAttachmentsList } from '@modules/post/models/attachment/attachment.deserialize';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { fromAttachmentAddingBasicInfo } from '@modules/post/models/attachment/attachment.serialize';
import { PostType } from '@modules/post/models/base-post.model';
import { SurveyType } from '@modules/post/models/survey-post/survey-post.model';

export interface ICommentCapability {
    canDelete: boolean;
    canEdit: boolean;
    canEditLike: boolean;
    canReply: boolean;
}

export interface IComment {
    id: number;
    comment: QuillText;
    plainText: string;
    creatorUser?: IUser;
    creationTimestamp: Date;
    lastModifyTimestamp?: Date;
    deleted: boolean;
    mentions: IUser[];
    attachments: IAttachment[];
    communityId: number;
    capabilities?: ICommentCapability;
    parentComment?: IComment;
    likesCount?: number;
    likedByMe: boolean;
    likersList: PaginatedList<IUserAction>;
    isTranslated: boolean;
    language?: Language;
    postId?: number;
    postTitle?: string;
    postCustomId?: string;
    postType?: PostType;
    postSurveyType?: SurveyType;
    currentWorkflowState?: IWorkflowStatus;
    rightAlignText: boolean;
    customFieldInfo?: ICustomFieldInfoComment;
    isEdited: boolean;
    streamingTimestamp?: number;
    isVoiceComment: boolean;
    voiceComment?: VoiceComment;
}

export interface CommentCreateResponse {
    createdComment: IComment;
    postFolloweByMeEnabled: boolean;
}

export interface ICommentEditResponse extends IComment {
    removedAttachmentIds: IAttachment['id'][];
    addedAttachments: IAttachment[];
}

export type ICommentCreate = Pick<IComment, 'comment'> & {
    parentCommentId?: number;
    addAttachments?: IAttachment[];
};

export interface StreamingCommentCreate extends ICommentCreate {
    streamingTimestamp?: number;
}

export interface ICustomFieldInfoComment {
    activityHistoryEvent: PartialEventHistoryType;
    postCustomFieldId: number;
    postSurveyFieldId: number | undefined;
    workflowScreenFieldId: number;
    acknowledgeTaskScreenFieldId: number;
}

export interface StreamingCommentsList extends PaginatedList<IComment> {
    nextSyncToken: string | null;
}

export type PartialEventHistoryType = Pick<
    IEventHistory,
    'id' | 'typeId' | 'postWorkflowOperationDetail'
>;

export type ICommentEdit = ICommentCreate & Pick<IComment, 'id'>;

export type ITranslatedComment = Pick<
    IComment,
    | 'id'
    | 'comment'
    | 'creationTimestamp'
    | 'language'
    | 'parentComment'
    | 'isTranslated'
    | 'postId'
    | 'rightAlignText'
>;

export interface ICommentDraft {
    comment?: Partial<IComment>;
    parentComment?: IComment;
    isEditing: boolean;
}

export type CommentLocationType = 'dashboard' | 'detail' | 'lightBoxStreaming';

export type VoiceCommentPlaybackRateType = 1 | 1.5 | 2;

export enum CommentType {
    TEXT = 1,
    VOICE = 2,
}

export interface VoiceComment {
    temporaryContentLink: string;
    duration: number;
}

export class CommentDeserialize {
    static commentCapabilities = (
        capabilitiesRecord: any,
    ): ICommentCapability => ({
        canDelete: capabilitiesRecord?.canDelete ?? null,
        canEdit: capabilitiesRecord?.canEdit ?? null,
        canEditLike: capabilitiesRecord?.canEditLike ?? null,
        canReply: capabilitiesRecord?.canReply ?? null,
    });

    static customFieldInfoComment = (record: any): ICustomFieldInfoComment => ({
        postCustomFieldId: record.postCustomFieldId,
        workflowScreenFieldId: record.workflowScreenFieldId,
        acknowledgeTaskScreenFieldId: record.acknowledgeTaskScreenFieldId,
        postSurveyFieldId: record.postSurveyFieldId,
        activityHistoryEvent: {
            id: record.activityHistoryEvent.id,
            typeId: record.activityHistoryEvent.typeId,
            postWorkflowOperationDetail: record.activityHistoryEvent
                .postWorkflowOperationDetail
                ? toWorkflowOperation(
                      record.activityHistoryEvent.postWorkflowOperationDetail,
                  )
                : undefined,
        },
    });

    static comment = (record: any): IComment => {
        return {
            id: record.id,
            creatorUser: record.creatorUser
                ? UserDeserilize.userDetails(record.creatorUser)
                : undefined,
            creationTimestamp: new Date(record.creationTimestamp),
            lastModifyTimestamp: record.lastModifyTimestamp
                ? new Date(record.lastModifyTimestamp)
                : undefined,
            attachments: toAttachmentsList(
                <Array<any> | null>record.attachments,
                (<Array<any> | null>record.attachments)?.length ?? 0,
                null,
                null,
            ).attachments,
            mentions: record.mentions || [],
            deleted: record.deleted,
            parentComment: record.parentComment
                ? CommentDeserialize.comment(record.parentComment)
                : undefined,
            likesCount: record.likesCount,
            likedByMe: record.likedByMe,
            likersList:
                record.likersList ?? emptyPaginatedList(record.likesCount),
            postId: record.postId ?? null,
            postTitle: record.postTitle !== '' ? record.postTitle ?? null : '',
            postCustomId:
                record.postCustomId !== '' ? record.postCustomId ?? null : '',
            postType: record.postType ?? null,
            postSurveyType: record.postSurveyType ?? null,
            comment: record.commentDelta
                ? new Server2Delta().process(record.commentDelta)
                : null,
            plainText: record.commentPlainText ?? '',
            capabilities: CommentDeserialize.commentCapabilities(
                record.capabilities,
            ),
            rightAlignText: record.rightAlignText ?? false,
            customFieldInfo: record.customFieldInfo
                ? CommentDeserialize.customFieldInfoComment(
                      record.customFieldInfo,
                  )
                : undefined,
            isEdited: record.creationTimestamp !== record.lastModifyTimestamp,
            isTranslated: false,
            streamingTimestamp: record.streamingTimestamp,
            communityId: record.communityId,
            currentWorkflowState: record.currentWorkflowState
                ? toWorkflowStatus(record.currentWorkflowState)
                : undefined,

            isVoiceComment: record.commentType === CommentType.VOICE,
            voiceComment:
                record.commentType === CommentType.VOICE
                    ? CommentDeserialize.voiceComment(record.voiceComment)
                    : undefined,
        };
    };

    static commentEdit = (
        record: any,
        addedAttachments: Array<any> | null,
        removedAttachmentIds: any,
    ): ICommentEditResponse => {
        return {
            ...CommentDeserialize.comment(record),
            removedAttachmentIds: removedAttachmentIds ?? [],
            addedAttachments: addedAttachments
                ? toAttachmentsList(
                      addedAttachments,
                      addedAttachments.length,
                      null,
                      null,
                  ).attachments
                : [],
        };
    };

    static commentsList = (record: any): PaginatedList<IComment> => {
        const comments: PaginatedList<IComment> = {
            list: (<Array<any>>record.items || []).map((item) =>
                CommentDeserialize.comment(item),
            ),
            nextPageToken: fromPageToken(record.nextPageToken),
            totalCount: record.totalItemsCount,
            isFetching: false,
        };
        return comments;
    };

    static streamingCommentsList = (record: any): StreamingCommentsList => {
        const comments: StreamingCommentsList = {
            list: (<Array<any>>record.items || []).map((item) => ({
                ...CommentDeserialize.comment(item),
                capabilities: {
                    ...emptyCommentCapabilities(),
                    canReply: item.capabilities?.canReply ?? false,
                },
            })),
            nextPageToken: fromPageToken(record.nextPageToken),
            totalCount: record.totalItemsCount,
            isFetching: false,
            nextSyncToken: record.nextSyncToken as string,
        };
        return comments;
    };

    static translatedComment = (
        comment: any,
        postId: number,
        language: Language,
    ): ITranslatedComment => {
        return {
            id: comment.id,
            postId: postId,
            comment: comment.commentDelta
                ? new Server2Delta().process(comment.commentDelta)
                : null,
            creationTimestamp: new Date(comment.creationTimestamp),
            language: language,
            parentComment: comment.parentComment
                ? CommentDeserialize.comment(comment.parentComment)
                : undefined,
            isTranslated: true,
            rightAlignText: comment.rightAlignText ?? false,
        };
    };

    static voiceComment(voiceComment: any): VoiceComment {
        return {
            temporaryContentLink: voiceComment.temporaryContentLink,
            duration: Number(voiceComment.metadata?.duration ?? 0),
        };
    }
}

export class CommentSerialize {
    static fromComment = (comment: ICommentEdit | ICommentCreate): any => {
        const record = <any>{};
        record.comment = comment.comment
            ? new Delta2Server().process(comment.comment)
            : null;
        record.commentFormat = 1;
        record.attachments = (comment.addAttachments || []).map((a) =>
            fromAttachmentAddingBasicInfo(a),
        );
        record.parentCommentId = comment.parentCommentId
            ? comment.parentCommentId
            : null;
        return record;
    };

    static fromStreamingComment = (comment: StreamingCommentCreate): any => {
        const record: any = {
            ...CommentSerialize.fromComment(comment),
            streamingTimestamp: comment.streamingTimestamp,
        };
        return record;
    };
}

export const emptyCommentCapabilities = (): ICommentCapability => ({
    canEdit: false,
    canReply: false,
    canEditLike: false,
    canDelete: false,
});
