<interacta-filter-collapse-wrapper
    *ngIf="{
        filterControl: filterControl | formControlValueV2$ | async
    } as $"
    [active]="($.filterControl | notNull) && $.filterControl.length > 0"
    [collapsed]="collapsed"
    [label]="label"
    [pinnable]="pinnable"
    [pinned]="pinned"
    (toggleCollapsed)="toggleCollapsed.emit()"
    (togglePinned)="togglePinned.emit()"
>
    <interacta-input-multiple-select
        [control]="filterControl"
        [items]="items"
        [itemsSpacing]="itemsSpacing"
        [orientation]="orientation"
        [withChips]="false"
    />
</interacta-filter-collapse-wrapper>
