import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ConfigurationService,
    Language,
} from '@interacta-shared/data-access-configuration';
import { Observable } from 'rxjs';

@Injectable()
export class CurrentLanguageInterceptor implements HttpInterceptor {
    constructor(private configurationService: ConfigurationService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        let req = request;
        if (this.configurationService) {
            const currentLanguage: Language =
                this.configurationService.getCurrentLanguage();
            if (currentLanguage) {
                req = request.clone({
                    setHeaders: {
                        'interacta-preferred-language': currentLanguage.code,
                    },
                });
            }
        }

        return next.handle(req);
    }
}
