export function enumKeys<T extends string | number>(
    enumObj: Record<T, unknown>,
): T[] {
    return Object.keys(enumObj).filter((o) => isNaN(parseInt(o))) as T[];
}

export function enumValues<
    T extends string | number,
    U extends string | number,
>(enumObj: Record<T, U>): U[] {
    const values = Object.values<U>(enumObj);
    if (
        values.some((o) => typeof o === 'string') &&
        values.some((o) => typeof o === 'number')
    ) {
        return values.filter((o) => typeof o === 'number');
    }
    return values;
}

export function getEnumLabelByValue(
    enumObj: Record<number, string>,
    value: number,
): string | undefined {
    return enumObj[value];
}
