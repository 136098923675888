<interacta-dialog
    *ngIf="{
        excludedGroups: excludedGroups$ | async,
        tagGroups: tagGroups$ | async,
        filteredTagGroups: filteredTagGroups$ | async
    } as $"
    [isOpen]="isOpen"
    [size]="'small'"
    (closing)="closeDialog.emit()"
>
    <h6 class="typo-t6" title>
        {{ 'TAG_MEMBER_PICKER.SPLIT_GROUPS_FROM_TAG_DIALOG.TITLE' | translate }}
    </h6>
    <div
        *ngIf="$.tagGroups"
        class="typo-sm text-text mb-16 flex flex-col gap-y-8"
        subtitle
    >
        <p
            [innerHTML]="
                $.tagGroups.totalCount
                    | i18nPlural
                        : {
                              '=0': 'TAG_MEMBER_PICKER.SPLIT_GROUPS_FROM_TAG_DIALOG.SUBTITLE_ZERO',
                              '=1': 'TAG_MEMBER_PICKER.SPLIT_GROUPS_FROM_TAG_DIALOG.SUBTITLE_SING',
                              other: 'TAG_MEMBER_PICKER.SPLIT_GROUPS_FROM_TAG_DIALOG.SUBTITLE_PLUR'
                          }
                    | translate
                        : { count: $.tagGroups.totalCount, tagName: tag.name }
            "
        ></p>

        <p
            *ngIf="
                $.excludedGroups?.length ||
                !isTagLocked ||
                (isTagLocked && originalExcludedGroupIds.length)
            "
        >
            {{
                ($.excludedGroups?.length
                    ? 'TAG_MEMBER_PICKER.SPLIT_GROUPS_FROM_TAG_DIALOG.REMOVED_GROUPS'
                    : 'TAG_MEMBER_PICKER.SPLIT_GROUPS_FROM_TAG_DIALOG.CAN_REMOVE_GROUPS_INFO'
                ) | translate
            }}
        </p>
    </div>

    <div class="h-screen-80 flex min-h-0 w-full flex-col gap-y-16" content>
        <div
            *ngIf="$.excludedGroups?.length"
            class="max-h-160 flex shrink-0 flex-col gap-y-8 overflow-y-auto"
        >
            <div
                *ngFor="let group of $.excludedGroups"
                class="flex items-center gap-4"
            >
                <interacta-chip-member
                    [bgColor]="'surface-B'"
                    [member]="group"
                    [size]="'extra-small'"
                ></interacta-chip-member>
                <button
                    class="text-palette-quinary"
                    interacta-icon-button
                    type="button"
                    [attr.aria-label]="
                        'TAG_MEMBER_PICKER.SPLIT_GROUPS_FROM_TAG_DIALOG.ADD_TO_SELECTION_TOOLTIP'
                            | translate
                    "
                    [icon]="'plus-circle'"
                    [matTooltip]="
                        'TAG_MEMBER_PICKER.SPLIT_GROUPS_FROM_TAG_DIALOG.ADD_TO_SELECTION_TOOLTIP'
                            | translate
                    "
                    [size]="'extra-small'"
                    (click)="removeFromExcluded(group)"
                ></button>
            </div>
        </div>

        <interacta-input-search
            *ngIf="totalGroupsCount >= 20"
            [control]="searchControl"
            [iconPosition]="'right'"
        ></interacta-input-search>

        <interacta-separator
            *ngIf="totalGroupsCount < 20"
            [thickness]="'soft'"
        ></interacta-separator>

        <div
            *ngIf="$.filteredTagGroups"
            #scroll
            class="flex h-full grow flex-col gap-y-8 overflow-y-auto"
            interactaScrollTracker
            (scrolled)="onScroll($event)"
        >
            <ng-container *ngFor="let group of $.filteredTagGroups.list">
                <div class="flex items-center gap-4">
                    <interacta-chip-member
                        [bgColor]="'surface-B'"
                        [member]="group"
                        [size]="'small'"
                    ></interacta-chip-member>
                    <button
                        *ngIf="
                            !isTagLocked ||
                            (isTagLocked &&
                                originalExcludedGroupIds.includes(group.id))
                        "
                        interacta-icon-button
                        type="button"
                        [attr.aria-label]="
                            'TAG_MEMBER_PICKER.SPLIT_GROUPS_FROM_TAG_DIALOG.REMOVE_TO_SELECTION_TOOLTIP'
                                | translate
                        "
                        [bgColor]="'surface-A'"
                        [icon]="'check-circle-undeterminate'"
                        [matTooltip]="
                            'TAG_MEMBER_PICKER.SPLIT_GROUPS_FROM_TAG_DIALOG.REMOVE_TO_SELECTION_TOOLTIP'
                                | translate
                        "
                        [size]="'small'"
                        (click)="addToExcluded(group)"
                    ></button>
                </div>
            </ng-container>

            <interacta-load-more
                *ngIf="
                    $.filteredTagGroups.nextPageToken.tag === 'regularLoading'
                "
                class="flex items-center justify-center py-8"
                size="regular"
                [isLoading]="$.filteredTagGroups.isFetching"
                [label]="'UI.SHOW_MORE_UNCOUNT' | translate"
                (loadMore)="doSearch()"
            ></interacta-load-more>
        </div>
    </div>

    <interacta-dialog-buttons>
        <button
            appearance="ghost"
            interacta-dialog-button
            type="button"
            [label]="'BUTTON.LABEL_BUTTON_CANCEL' | translate"
            (click)="closeDialog.emit()"
        ></button>
        <button
            appearance="fill"
            interacta-dialog-button
            type="button"
            [bgColor]="'primary'"
            [label]="'SHARED.CONFIRM_DIALOG.DEFAULT_CONFIRM' | translate"
            (click)="confirm()"
        ></button>
    </interacta-dialog-buttons>
</interacta-dialog>
