import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { assertUnreachable } from '@interacta-shared/util';
import { SurveyPost } from '@modules/post/models/survey-post/survey-post.model';
import {
    getClosingDate,
    isFeedBackPostType,
} from '@modules/post/models/survey-post/survey-post.utils';
import { TranslateService } from '@ngx-translate/core';
import { enUS } from 'date-fns/locale';
import {
    DateFormattedLabels,
    surveyPostClosingLabels,
    surveyPostTypedClosedLabels,
} from '../models/date-formatted-labels';
import { getTranslatedDateFormattedLabel } from '../utils/expiration-date.utils';

/**
 * See https://injenia.atlassian.net/l/cp/r6L3KnZu
 *
 */
@Pipe({
    name: 'surveyPostClosingDate',
})
export class SurveyPostClosingDatePipe implements PipeTransform {
    locale!: Locale;

    constructor(
        private configurationService: ConfigurationService,
        private translateService: TranslateService,
    ) {
        this.configurationService
            .getLocaleDateFns()
            .subscribe((locale) => (this.locale = locale));
    }

    transform(post: SurveyPost): string | null {
        if (!this.locale) {
            console.warn(`The locale shouldn't be null here.`);
        }

        // Defaulting to enUS which is always eagerly loaded by date-fns and
        // doesn't increase bundle size.
        const locale = this.locale ?? enUS;

        const date = getClosingDate(post);
        const _date =
            date != null
                ? date instanceof Date
                    ? date
                    : date.zonedDatetime
                : null;
        if (!_date) return null;

        return getTranslatedDateFormattedLabel(
            this.translateService,
            this.getLabelsOfCategory(post),
            locale,
            _date,
        );
    }

    private getLabelsOfCategory(post: SurveyPost): DateFormattedLabels {
        const state = post.state;
        switch (state) {
            case 'open':
            case 'scheduled':
                return surveyPostClosingLabels;
            case 'closed':
                return surveyPostTypedClosedLabels(
                    isFeedBackPostType(post) ? 'FEEDBACK' : 'SURVEY',
                );
            default:
                assertUnreachable(state);
        }
    }
}
