import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DateTimeComponent } from '@interacta-shared/ui';

@Component({
    selector: 'interacta-date-time-extended',
    templateUrl: './date-time-extended.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeExtendedComponent extends DateTimeComponent {}
