import { isDefined } from '@interacta-shared/util';
import { enumValues } from '@interacta-shared/util-common';
import {
    DashboardPost,
    DashboardPostCustom,
} from '@modules/dashboard/models/dashboard-post.model';
import { CallToActionType } from '@modules/digital-workplace/models/digital-workplace.model';
import {
    AdvancedPostType,
    ConfigurationFieldType,
    IPostMetadata,
    PostType,
} from '../models/base-post.model';
import { isCustomPost } from '../models/custom-post.model';
import { SurveyType } from '../models/survey-post/survey-post.model';

export const getPostTypes = (
    actionType: CallToActionType,
): AdvancedPostType => {
    switch (actionType) {
        case CallToActionType.CREATE_POST:
            return { type: PostType.CUSTOM, subtype: undefined };
        case CallToActionType.CREATE_EVENT:
            return { type: PostType.EVENT, subtype: undefined };
        case CallToActionType.CREATE_SURVEY:
            return { type: PostType.SURVEY, subtype: SurveyType.SURVEY };
        case CallToActionType.CREATE_FEEDBACK:
            return { type: PostType.SURVEY, subtype: SurveyType.FEEDBACK };
        default:
            return { type: PostType.CUSTOM, subtype: undefined };
    }
};

export const isPostTypeEnabled = (
    type: PostType,
    metadata: IPostMetadata | undefined,
): boolean => {
    if (!metadata) return true;
    if (type === PostType.CUSTOM) {
        return metadata.customPostEnabled;
    }
    if (type === PostType.EVENT) {
        return metadata.eventPostEnabled;
    }
    if (type === PostType.SURVEY) {
        return metadata.surveyPostEnabled || metadata.feedbackPostEnabled;
    }
    return false;
};

export const isSurveyTypeEnabled = (
    type: SurveyType,
    metadata: IPostMetadata | undefined,
): boolean => {
    if (!metadata) return true;
    if (type === SurveyType.SURVEY) {
        return metadata.surveyPostEnabled;
    }
    if (type === SurveyType.FEEDBACK) {
        return metadata.feedbackPostEnabled;
    }
    return false;
};

export const enabledPostTypes = (
    metadata: IPostMetadata | undefined,
): PostType[] => {
    return metadata
        ? enumValues(PostType).filter((type) =>
              isPostTypeEnabled(type, metadata),
          )
        : enumValues(PostType);
};

export const enabledSurveyTypes = (
    metadata: IPostMetadata | undefined,
): SurveyType[] => {
    return metadata
        ? enumValues(SurveyType).filter((type) =>
              isSurveyTypeEnabled(type, metadata),
          )
        : enumValues(SurveyType);
};

export const canViewDescription = (post: DashboardPost): boolean => {
    const isCustom = isCustomPost(post);

    return (
        isDefined(post.description) &&
        (!isCustom ||
            (isCustom &&
                post.metadata?.descriptionEnabled !==
                    ConfigurationFieldType.DISABLED))
    );
};

export const getCustomPostPreviewImage = (
    post: DashboardPostCustom,
): string | undefined => {
    return post.coverImage
        ? post.coverImage.temporaryContentViewLink ??
              post.coverImage.temporaryContentPreviewImageLink ??
              post.coverImage.temporaryContentPreviewImageLink
        : post.mainAttachment
          ? post.mainAttachment.temporaryContentViewLink ??
            post.mainAttachment.temporaryContentPreviewImageHiResLink ??
            post.mainAttachment.temporaryContentPreviewImageLink
          : undefined;
};
