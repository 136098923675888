import {
    AfterContentInit,
    Directive,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    inject,
} from '@angular/core';
import { ConsoleService } from '@interacta-shared/data-access-configuration';
import { GroupInfoV2Service } from '@modules/group-info/group-info-v2.service';
import { MentionType } from '@modules/mentions';
import {
    DATA_CLIENTUID,
    DATA_ID,
    DATA_MENTION_CLICK,
    DATA_MENTION_TYPE,
    DATA_VALUE,
} from '@modules/mentions/helpers/mention.utils';
import { Store } from '@ngrx/store';
import {
    hashtagMentionClick,
    tableMentionClick,
    userMentionClick,
} from '../store/mentions.actions';

interface ClickEventRegistry {
    hashtag: { id: number };
    user: { id: number };
    group: { id: number; groupName: string };
    table: {
        id: number | undefined;
        clientUid: string;
    };
}

type ClickEvent<T extends MentionType> = ClickEventRegistry[T];

export type MentionClickEnabled =
    | boolean
    | { [T in MentionType]: boolean | ((clickEvent: ClickEvent<T>) => void) };

@Directive({
    selector: '[injMentionEventHandler]',
})
export class MentionEventHandlerDirective
    implements AfterContentInit, OnChanges, OnDestroy
{
    @Input() mentionClickEnabled: MentionClickEnabled = true;
    @HostBinding('class.mention-event-handler--disabled') disabled = false;
    @HostBinding('class.mention-event-handler__hashtag--disabled')
    disabledHastag = false;
    @HostBinding('class.mention-event-handler__user--disabled') disabledUser =
        false;
    @HostBinding('class.mention-event-handler__group--disabled') disabledGroup =
        false;
    @HostBinding('class.mention-event-handler__table--disabled') disabledTable =
        false;

    private changes?: MutationObserver;

    private readonly consoleService = inject(ConsoleService);
    private readonly store = inject(Store);
    private readonly groupInfoService = inject(GroupInfoV2Service);

    private readonly defaultMentionClickHandlers: {
        [T in MentionType]: (clickEvent: ClickEvent<T>) => void;
    } = {
        hashtag: (clickEvent) =>
            this.store.dispatch(hashtagMentionClick(clickEvent)),
        user: (clickEvent) => this.store.dispatch(userMentionClick(clickEvent)),
        group: (clickEvent) =>
            this.groupInfoService.openDialog(clickEvent.id).subscribe(),
        table: (clickEvent) => {
            if (clickEvent.id != null) {
                this.store.dispatch(tableMentionClick({ id: clickEvent.id }));
            } else {
                console.error('undefined table mention id');
            }
        },
    };

    constructor(private element: ElementRef<Element>) {}

    private isMentionClickEnabled(mentionType: MentionType): boolean {
        return (
            this.mentionClickEnabled === true ||
            (typeof this.mentionClickEnabled === 'object' &&
                (this.mentionClickEnabled[mentionType] === true ||
                    typeof this.mentionClickEnabled[mentionType] ===
                        'function'))
        );
    }

    private handleMentionClick<T extends MentionType>(
        type: T,
        clickEvent: ClickEvent<T>,
    ) {
        if (this.mentionClickEnabled === true) {
            this.defaultMentionClickHandlers[type](clickEvent);
            return;
        }

        if (typeof this.mentionClickEnabled === 'object') {
            const typeEnabled = this.mentionClickEnabled[type];

            if (typeof typeEnabled === 'function') {
                typeEnabled(clickEvent);
                return;
            }
            if (typeEnabled === true) {
                this.defaultMentionClickHandlers[type](clickEvent);
                return;
            }
        }
    }
    private addClickListenerToTagMention(tagElem: Element): void {
        if (this.isMentionClickEnabled('hashtag')) {
            const noListener = !tagElem.getAttribute(DATA_MENTION_CLICK);
            if (noListener) {
                tagElem.setAttribute(DATA_MENTION_CLICK, '1');
                const id = tagElem.getAttribute(DATA_ID);
                tagElem.addEventListener('click', () => {
                    this.consoleService.debugDev('Hashtag Mention clicked', id);
                    if (id == null) {
                        console.error(
                            `Hashtag Mention ${DATA_ID} attribute must be not null`,
                        );
                        return;
                    }
                    this.handleMentionClick('hashtag', { id: +id });
                });
            }
        }
    }

    private addClickListenerToTableMention(tableElem: Element): void {
        if (this.isMentionClickEnabled('table')) {
            const noListener = !tableElem.getAttribute(DATA_MENTION_CLICK);
            if (noListener) {
                tableElem.setAttribute(DATA_MENTION_CLICK, '1');
                const _id = tableElem.getAttribute(DATA_ID);
                const id = _id ? +_id : undefined;
                const clientUid = tableElem.getAttribute(
                    DATA_CLIENTUID,
                ) as string;
                const value = {
                    id,
                    clientUid,
                };
                tableElem.addEventListener('click', () => {
                    this.consoleService.debugDev(
                        'Table Mention clicked',
                        clientUid,
                    );
                    this.handleMentionClick('table', value);
                });
            }
        }
    }

    private addClickListenerToUserMention(userElem: Element) {
        if (this.isMentionClickEnabled('user')) {
            const noListener = !userElem.getAttribute(DATA_MENTION_CLICK);
            if (noListener) {
                userElem.setAttribute(DATA_MENTION_CLICK, '1');
                const id = userElem.getAttribute(DATA_ID);
                userElem.addEventListener('click', () => {
                    this.consoleService.debugDev('User Mention clicked', id);
                    if (id == null) {
                        console.error(
                            `User Mention ${DATA_ID} attribute must be not null`,
                        );
                        return;
                    }
                    this.handleMentionClick('user', { id: +id });
                });
            }
        }
    }

    private addClickListenerToGroupMention(groupElem: Element) {
        if (this.isMentionClickEnabled('group')) {
            const noListener = !groupElem.getAttribute(DATA_MENTION_CLICK);
            if (noListener) {
                groupElem.setAttribute(DATA_MENTION_CLICK, '1');
                const id = groupElem.getAttribute(DATA_ID);
                const groupName = groupElem.getAttribute(DATA_VALUE) ?? '';
                groupElem.addEventListener('click', () => {
                    this.consoleService.debugDev('Group Mention clicked', id);
                    if (id == null) {
                        console.error(
                            `Group Mention ${DATA_ID} attribute must be not null`,
                        );
                        return;
                    }
                    this.handleMentionClick('group', { id: +id, groupName });
                });
            }
        }
    }

    private detectMentioningInnerText(element: Element) {
        const mentions = element.getElementsByClassName(`mention`);
        if (mentions?.length > 0) {
            for (let i = 0; i < mentions.length; i++) {
                const type = <MentionType | null>(
                    mentions[i].getAttribute(DATA_MENTION_TYPE)
                );
                switch (type) {
                    case 'user':
                        this.addClickListenerToUserMention(mentions[i]);
                        break;
                    case 'hashtag':
                        this.addClickListenerToTagMention(mentions[i]);
                        break;
                    case 'group':
                        this.addClickListenerToGroupMention(mentions[i]);
                        break;
                    case 'table':
                        this.addClickListenerToTableMention(mentions[i]);
                        break;
                    default:
                        console.debug('mention type not supported', type);
                }
            }
        }
    }

    private detectDomChange() {
        const element = this.element.nativeElement;

        this.changes = new MutationObserver((mutations: MutationRecord[]) => {
            mutations.forEach((_: MutationRecord) =>
                this.detectMentioningInnerText(element),
            );
        });
        this.changes.observe(element, {
            childList: true,
            subtree: true,
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['mentionClickEnabled']) {
            this.disabled = !this.mentionClickEnabled;
            this.disabledHastag =
                this.mentionClickEnabled &&
                !this.isMentionClickEnabled('hashtag');
            this.disabledUser =
                this.mentionClickEnabled && !this.isMentionClickEnabled('user');
            this.disabledGroup =
                this.mentionClickEnabled &&
                !this.isMentionClickEnabled('group');
            this.disabledTable =
                this.mentionClickEnabled &&
                !this.isMentionClickEnabled('table');
        }
    }

    ngAfterContentInit(): void {
        if (this.mentionClickEnabled) {
            this.detectDomChange();
        }
    }

    ngOnDestroy(): void {
        this.changes?.disconnect();
    }
}
