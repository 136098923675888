import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ScrollTrackerEvent, Size } from '@interacta-shared/ui';
import { PageTokenInfo } from '@interacta-shared/util';
import { Member } from '@modules/core/models/member/member.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MemberClickBehavior } from '../member/member.component';

@Component({
    selector: 'interacta-dialog-members[totalCount]',
    templateUrl: './dialog-members.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogMembersComponent implements OnChanges, OnInit, OnDestroy {
    @Input() totalCount!: number;
    @Input() isDialogOpen = false;
    @Input() title: string | null = '';
    @Input() memberList: Member[] = [];
    @Input() isFetching = false;
    @Input() nextPageToken?: PageTokenInfo;
    @Input() size: Size = 'regular';
    @Input() memberClickBehavior: MemberClickBehavior = 'profile';
    @Input() enableSearch = false;

    @Output() closeDialog = new EventEmitter<void>();
    @Output() nextPagination = new EventEmitter<void>();
    @Output() searchedMembers = new EventEmitter<string>();

    searchMembers = new FormControl('', { nonNullable: true });
    searchedMembers$!: Observable<Member[]>;
    membersList$ = new BehaviorSubject<Member[]>([]);
    destroy$ = new Subject<void>();

    ngOnInit(): void {
        this.searchMembers.valueChanges
            .pipe(
                debounceTime(600),
                distinctUntilChanged(),
                takeUntil(this.destroy$),
            )
            .subscribe((res: string) => this.searchedMembers.emit(res));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.memberList) {
            this.membersList$.next([
                ...this.membersList$.value,
                ...this.memberList,
            ]);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    newPage(): void {
        if (!this.isFetching) this.nextPagination.emit();
    }

    trackByFn(index: number, item: Member): number {
        return item.innerId;
    }

    scrollTracker(event: ScrollTrackerEvent): void {
        if (event.endReached && this.nextPageToken?.tag !== 'lastLoading') {
            this.newPage();
        }
    }
}
