import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthService, CurrentUser } from '@interacta-shared/data-access-auth';
import { ErrorService } from '@interacta-shared/data-access-error';
import {
    InputSelectSearchEvent,
    emptyInputSelectSearchEvent,
} from '@interacta-shared/ui';
import {
    EMPTY_SEARCH_VALUE,
    Index,
    PaginatedList,
    emptyPaginatedList,
} from '@interacta-shared/util';
import { MetadataFieldModeType } from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { AdminCapabilities } from '@modules/core';
import { Member } from '@modules/core/models/member/member.model';
import {
    getCurrentUserPlaceholderAsMember,
    getEmptyGroupPlaceholderAsMember,
    getEmptyUserPlaceholderAsMember,
    wrapMember,
} from '@modules/core/models/member/member.utils';
import { MemberService } from '@modules/shared-v2/services/member.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { MemberClickBehavior } from '../member/member.component';

export type MemberPickerType =
    | 'custom_user'
    | 'custom_group'
    | 'community_members'
    | 'community_users'
    | 'community_groups'
    | 'task_members'
    | 'task_assignees'
    | 'admin_users'
    | 'admin_groups'
    | 'admin_workspace_members'
    | 'admin_v2_groups'
    | 'admin_v2_manager'
    | 'admin_v2_users'
    | 'admin_v2_areas'
    | 'admin_v2_business_units'
    | 'admin_v2_workspace_admin_members'
    | 'admin_v2_community_admin_members'
    | 'admin_v2_members';

export type MemberPickerGroupType = Extract<
    MemberPickerType,
    'custom_group' | 'admin_groups' | 'admin_v2_groups' | 'community_groups'
>;
export type MemberPickerUserType = Extract<
    MemberPickerType,
    'custom_user' | 'admin_users' | 'admin_v2_users' | 'community_users'
>;

/**
        Per far partire la ricerca, è necessario che almeno uno tra communityId,
        workspaceId e fieldId venga passato in input al componente,
        in modo tale che l'if della onChanges venga risolto.
    */
@Component({
    selector: 'interacta-input-member-picker',
    templateUrl: './input-member-picker.component.html',
    providers: [MemberService],
})
export class InputMemberPickerComponent implements OnChanges, OnDestroy {
    @Input({ required: true }) type!: MemberPickerType;
    @Input({ required: true }) control!: UntypedFormControl;
    @Input() communityId?: number;
    @Input() workspaceId?: number;
    @Input() fieldId?: number;
    @Input() postId?: number;
    @Input() fieldMode: MetadataFieldModeType = MetadataFieldModeType.POST;
    @Input() dynamic = false;
    @Input() isReadonly = false;
    @Input() maxSelectableItems = Infinity;
    @Input() enableEmptyUserSelectionOption = false;
    @Input() enableEmptyGroupSelectionOption = false;
    @Input() enableCurrentUserSelectionOption = false;
    @Input() currentUserFirst = false;
    @Input() includeNotVisible = false;
    @Input() memberClickBehavior: MemberClickBehavior = 'none';
    @Input() lockedIds: Index[] = [];
    @Input() enableDragAndDrop = false;
    @Input() excludeItemsByMemberId?: number;
    @Input() excludeItemsByOrganizationId?: number;
    @Input() manageType?: Extract<
        AdminCapabilities,
        'manageUsers' | 'manageDigitalWorkplace'
    >[];

    public EMPTY_SEARCH_VALUE = EMPTY_SEARCH_VALUE;

    emptyUserPlaceholder: Member;
    emptyGroupPlaceholder: Member;
    currentUserPlaceholder: Member;

    members$ = new BehaviorSubject<PaginatedList<Member>>(
        emptyPaginatedList(0),
    );

    showSearchPlaceholders = true;

    private currentUser: CurrentUser | null;
    private filter$ = new Subject<InputSelectSearchEvent>();
    private destroy$ = new Subject<void>();
    private init$ = new Subject<void>();

    constructor(
        private memberService: MemberService,
        private errorService: ErrorService,
        private translateService: TranslateService,
        protected authService: AuthService,
    ) {
        const notAssigned: string = this.translateService.instant(
            'DASHBOARD.FILTERS.NOT_ASSIGNED',
        );
        const currentUser: string = this.translateService.instant(
            'DASHBOARD.FILTERS.CURRENT_USER',
        );
        this.emptyUserPlaceholder =
            getEmptyUserPlaceholderAsMember(notAssigned);
        this.emptyGroupPlaceholder =
            getEmptyGroupPlaceholderAsMember(notAssigned);
        this.currentUserPlaceholder =
            getCurrentUserPlaceholderAsMember(currentUser);
        this.currentUser = this.authService.getCurrentUserData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fieldId || changes.communityId || changes.workspaceId) {
            this.init$.next();
            this.filter$
                .pipe(
                    switchMap((filter) =>
                        this.searchApi(filter).pipe(
                            map((members) => ({ members, filter })),
                            catchError((error) => {
                                this.errorService.handle(error);
                                return EMPTY;
                            }),
                        ),
                    ),
                    takeUntil(this.destroy$),
                    takeUntil(this.init$),
                )
                .subscribe(({ members, filter }) => {
                    const currentList = this.members$.value;
                    const newList: PaginatedList<Member> = {
                        ...members,
                        list:
                            filter.nextPageToken != null
                                ? [...currentList.list, ...members.list]
                                : this.enableEmptyGroupSelectionOption
                                  ? [
                                        this.emptyGroupPlaceholder,
                                        ...members.list,
                                    ]
                                  : this.enableEmptyUserSelectionOption ||
                                      this.enableCurrentUserSelectionOption ||
                                      this.currentUserFirst
                                    ? [
                                          ...this.addPlaceholderOrCurrentUserAsFirst(
                                              members.list,
                                              filter.text,
                                          ),
                                      ]
                                    : [...members.list],
                        isFetching: false,
                    };
                    this.members$.next(newList);
                    this.showSearchPlaceholders = false;
                });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    search(filter: InputSelectSearchEvent): void {
        this.filter$.next(filter);
    }

    itemListOpened(isOpen: boolean): void {
        if (isOpen) {
            this.filter$.next(emptyInputSelectSearchEvent());
        } else {
            this.showSearchPlaceholders = true;
        }
    }

    private addPlaceholderOrCurrentUserAsFirst(
        list: Member[],
        textFilter: string,
    ): Member[] {
        let internalList: Member[] = [...list];

        if (this.currentUserFirst && !textFilter) {
            internalList = this.putCurrentUserAsFirstElement(list);
        }

        if (this.enableEmptyUserSelectionOption) {
            internalList = [this.emptyUserPlaceholder, ...internalList];
        }

        if (this.enableCurrentUserSelectionOption) {
            internalList = [this.currentUserPlaceholder, ...internalList];
        }

        return internalList;
    }

    private putCurrentUserAsFirstElement(list: Member[]): Member[] {
        if (this.currentUser == null) return list;

        const membersWithoutCurrentUser: Member[] = list.filter(
            (i) => i.innerId !== this.currentUser?.id,
        );

        const currentUserAsMember: Member = wrapMember({
            ...this.currentUser,
        });

        return [currentUserAsMember, ...membersWithoutCurrentUser];
    }

    private searchApi(
        filter: InputSelectSearchEvent,
    ): Observable<PaginatedList<Member>> {
        this.members$.next({ ...this.members$.getValue(), isFetching: true });
        return this.memberService.search(
            filter,
            this.type,
            this.postId ?? null,
            this.communityId ? [this.communityId] : null,
            this.fieldMode,
            this.fieldId ?? null,
            this.dynamic,
            this.workspaceId ?? null,
            this.includeNotVisible,
            this.excludeItemsByMemberId,
            this.excludeItemsByOrganizationId,
            this.manageType,
        );
    }
}
