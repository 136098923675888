@if (user.accountPhotoUrl && !loadError) {
    <interacta-image
        class="flex"
        alt="{{ user.fullName }}'s avatar"
        classes="rounded-full object-cover {{
            size === 'extra-large'
                ? 'size-160'
                : size === 'large'
                  ? 'size-120'
                  : size === 'medium'
                    ? 'size-60'
                    : size === 'regular'
                      ? 'size-40'
                      : size === 'small'
                        ? 'size-30'
                        : 'size-24'
        }}"
        [fallback]="user.accountPhotoUrl"
        [src]="user.highResAccountPhotoUrl ?? user.accountPhotoUrl"
        (loadError)="loadError = true"
    />
} @else {
    <div
        [attr.aria-label]="user.fullName"
        class="flex items-center justify-center rounded-full object-cover {{
            size === 'extra-large'
                ? 'size-160'
                : size === 'large'
                  ? 'size-120'
                  : size === 'medium'
                    ? 'size-60'
                    : size === 'regular'
                      ? 'size-40'
                      : size === 'small'
                        ? 'size-30'
                        : 'size-24'
        }} text-text-on-avatar {{ user.id | apply: getRandomColor }}"
        [ngClass]="{
            'typo-t1': size === 'extra-large',
            'typo-t2': size === 'large',
            'typo-t5': size === 'medium',
            'typo-t6': size === 'regular',
            'typo-sm-bold': size === 'small',
            'typo-xs-bold': size === 'extra-small'
        }"
    >
        {{ abbreviationName | uppercase }}
    </div>
}

<img
    *ngIf="enableChristmasEasterEgg"
    alt="image"
    class="scale-250 rotate-30 absolute -right-8 -top-12"
    src="./assets/images-2.0/doodles/christmas-hat.png"
/>
<div
    *ngIf="canEdit && isLoading"
    class="flex items-center content-center absolute top-0 left-0 rounded-full bg-transparent-black-not-black-40 {{
        size === 'extra-large'
            ? 'size-160'
            : size === 'large'
              ? 'size-120'
              : size === 'medium'
                ? 'size-60'
                : size === 'regular'
                  ? 'size-40'
                  : size === 'small'
                    ? 'size-30'
                    : 'size-24'
    }}"
>
    <interacta-loader class="mx-auto" [size]="'large'" />
</div>

<button
    *ngIf="canEdit"
    class="absolute bottom-6 right-6"
    interacta-icon-button
    type="button"
    [bgColor]="'surface-B'"
    [border]="true"
    [disabled]="isLoading"
    [icon]="'camera'"
    [size]="size === 'extra-large' ? 'regular' : 'small'"
    (click)="avatarEdit.emit()"
></button>
