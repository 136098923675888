<p
    class="border-border-500 w-max max-w-full break-all rounded-3xl py-2"
    [ngClass]="{
        'inline-block': inline,
        'line-clamp-1': !inline,
        'typo-t4': size === 'large',
        'typo-sm-bold': size === 'regular',
        'border-2 px-12 ': size !== 'small',
        'typo-xs-bold border px-8 ': size === 'small'
    }"
>
    {{ code }}
</p>
