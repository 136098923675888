import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-attachment-icon[attachment]',
    template: `
        <div
            class="bg-transparent-white flex h-24 w-24 items-center justify-center rounded-full"
            *ngIf="
                (attachment.isMediaVideo || attachment.isMediaGif) &&
                attachment.temporaryContentPreviewImageLink
            "
        >
            <img
                *ngIf="attachment.isMediaVideo || attachment.isMediaGif"
                [src]="attachment.iconPath"
                class="w-16"
            />
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentIconComponent {
    @Input() attachment!: IAttachment;
}
