import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormatPurePipe } from 'ngx-date-fns';
import { BehaviorSubject, Subject, combineLatest, concat, of } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Size } from '../../model';
import { Timezone } from '../../model/timezone.model';
import { InputTextV2Component } from '../input-text-V2/input-text-V2.component';
import { StepMode } from '../input-time-v2/input-time-v2.component';
import { MenuDateTimeComponent } from '../menu-date-time/menu-date-time.component';

export type DateTimeType = 'date' | 'date-time';

@Component({
    standalone: true,
    imports: [
        InputTextV2Component,
        CommonModule,
        OverlayModule,
        MenuDateTimeComponent,
    ],
    selector: 'interacta-input-date-time',
    templateUrl: './input-date-time.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FormatPurePipe],
})
export class InputDateTimeComponent implements OnChanges, OnDestroy {
    @Input({ required: true }) control!: FormControl<Date | null>;
    @Input({ required: true }) timezones!: Timezone[];
    @Input({ required: true }) userTimezone!: Timezone | null;
    @Input() timezoneControl = new FormControl<Timezone | null>(null);
    @Input() isReadonly = false;
    @Input() type: DateTimeType = 'date-time';
    @Input() size: Extract<Size, 'regular' | 'small' | 'extra-large'> =
        'regular';
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() showFastSelect = true;
    @Input() stepMode: StepMode = 'minute';
    @Input() editTimezone = false;
    @Input() canClear = true;

    private readonly formatPurePipe = inject(FormatPurePipe);

    isMenuOpen = false;
    formattedDateTime = new FormControl('');
    destroy$ = new Subject<void>();
    init$ = new Subject<void>();
    type$ = new BehaviorSubject(this.type);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['type']) {
            this.type$.next(this.type);
        }
        if (changes['control']) {
            this.init$.next();
            this.formattedDateTime.setErrors(this.control.errors);

            combineLatest([
                concat(of(this.control.value), this.control.valueChanges),
                this.type$,
                concat(this.control.status, this.control.statusChanges),
            ])
                .pipe(
                    debounceTime(100),
                    takeUntil(this.destroy$),
                    takeUntil(this.init$),
                )
                .subscribe(([date, _, status]) => {
                    this.formattedDateTime.setValue(
                        date != null
                            ? this.formatPurePipe.transform(
                                  date,
                                  this.type === 'date' ? 'P' : 'Pp',
                              )
                            : null,
                    );
                    if (status === 'INVALID') {
                        this.control.touched
                            ? this.formattedDateTime.markAsTouched()
                            : this.formattedDateTime.markAsUntouched();
                        this.formattedDateTime.setErrors(this.control.errors, {
                            emitEvent: true,
                        });
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    toggleMenu(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

    iconAction(): void {
        if (this.formattedDateTime.value !== null) {
            this.control.setValue(null);
            this.control.markAsDirty();
        } else {
            this.isMenuOpen = true;
        }
    }

    confirm(): void {
        this.isMenuOpen = false;
    }
}
