<div class="w-360 bg-transparent-black-not-black flex h-full py-64">
    <ng-container *ngIf="attachment">
        <div
            class="mt-24 flex min-h-0 grow flex-col space-y-16 overflow-auto px-24"
        >
            <div class="flex flex-col space-y-4">
                <h2 class="typo-t6 break-words">{{ attachment.name }}</h2>

                <button
                    *ngIf="(attachment.versionNumber || 1) > 1"
                    class="flex items-center space-x-8"
                    type="button"
                    (click)="showVersions.emit()"
                >
                    <interacta-icon class="h-24 w-24" [icon]="'history-clock'">
                    </interacta-icon>
                    <span class="typo-sm">
                        {{
                            'LIGHTBOX.VERSION'
                                | translate
                                    : { number: attachment.versionNumber }
                        }}
                    </span>
                </button>
            </div>

            <interacta-light-box-info-section
                *ngIf="attachment.creatorUser"
                [label]="'LIGHTBOX.POSTED_BY' | translate"
            >
                <interacta-profiled-user
                    [color]="'white'"
                    [memberClickBehavior]="'none'"
                    [user]="attachment.creatorUser"
                ></interacta-profiled-user>
            </interacta-light-box-info-section>

            <interacta-light-box-info-section
                *ngIf="attachment.post"
                [label]="'LIGHTBOX.POST' | translate"
            >
                <a
                    class="flex flex-col gap-y-4"
                    [routerLink]="['/post', attachment.post.id]"
                >
                    <interacta-post-code
                        *ngIf="attachment.post.code"
                        size="small"
                        [code]="attachment.post.code"
                    ></interacta-post-code>
                    <span
                        class="typo-sm-bold text-text-primary hover:underline"
                        >{{
                            attachment.post.title ||
                                ('POST.NO_TITLE' | translate)
                        }}</span
                    >
                </a>
            </interacta-light-box-info-section>

            <interacta-light-box-info-section
                *ngIf="community"
                [label]="'LIGHTBOX.COMMUNITY' | translate"
            >
                <a
                    class="typo-sm-bold text-text-primary break-words hover:underline"
                    [routerLink]="['/community', community.id]"
                >
                    {{ community.name | currentLanguage }}
                </a>
            </interacta-light-box-info-section>

            <interacta-light-box-info-section
                *ngIf="attachment.drive"
                [label]="'LIGHTBOX.SOURCE' | translate"
            >
                <p class="text-text-primary">
                    <interacta-icon
                        class="mr-2 inline-block h-24 w-24 align-middle"
                        icon="google-drive"
                    ></interacta-icon>
                    <span class="typo-sm-bold">{{
                        'DASHBOARD.ATTACHMENTS.SOURCES.GOOGLE_DRIVE' | translate
                    }}</span>
                </p>
            </interacta-light-box-info-section>

            <interacta-light-box-info-section
                *ngIf="attachment.size"
                [label]="'LIGHTBOX.SIZE' | translate"
            >
                <p class="typo-sm">
                    {{ attachment.size | formatBytes }}
                </p>
            </interacta-light-box-info-section>

            <interacta-light-box-info-section
                [label]="'LIGHTBOX.CREATION_DATE' | translate"
            >
                <p class="typo-sm">
                    {{
                        attachment.creationTimestamp | interactaDate: 'datetime'
                    }}
                </p>
            </interacta-light-box-info-section>

            <interacta-light-box-info-section
                [label]="'LIGHTBOX.MODIFY_DATE' | translate"
            >
                <p class="typo-sm">
                    {{
                        attachment.lastModifyTimestamp
                            | interactaDate: 'datetime'
                    }}
                </p>
            </interacta-light-box-info-section>

            <interacta-light-box-info-section
                *ngIf="attachment.hashtags && attachment.hashtags.length > 0"
                class="min-h-0 grow"
                [label]="'LIGHTBOX.HASHTAG' | translate"
            >
                <div class="mt-4 flex flex-wrap gap-12">
                    <button
                        *ngFor="let hashtag of attachment.hashtags"
                        type="button"
                        (click)="hashtagClicked.emit(hashtag)"
                    >
                        <interacta-hashtag-chip
                            [label]="hashtag.name"
                        ></interacta-hashtag-chip>
                    </button>
                </div>
            </interacta-light-box-info-section>

            <!-- TODO: IISP-3479 -->
            <!-- <div class="flex items-center text-text-low-contrast">
                <interacta-icon icon="open-eye" class="w-18 h-18"></interacta-icon>
                <span class="ml-4 typo-xs">TODO</span>
            </div> -->
        </div>
    </ng-container>
</div>
