import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Injectable,
    Input,
    OnDestroy,
    OnInit,
    Output,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { uuid } from '@interacta-shared/util';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class RadioGroupService<T> {
    groupName!: string;
    value = new ReplaySubject<{ value: T; emit: boolean }>(1);

    init(): void {
        this.groupName = uuid();
    }

    changeSelectedValue(value: T, emit = true): void {
        this.value.next({ value, emit });
    }
}

@Component({
    selector: 'interacta-radio-group',
    templateUrl: './radio-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroupComponent),
            multi: true,
        },
        RadioGroupService,
    ],
    standalone: true,
    imports: [NgClass],
})
export class RadioGroupComponent<T>
    implements OnInit, OnDestroy, ControlValueAccessor
{
    @Input() orientation: 'vertical' | 'horizontal' = 'vertical';
    @Input() verticalSpacing: 'regular' | 'large' = 'regular';
    @Input() horizontalSpacing?: 'regular' | 'large';

    @Output() changeSelection = new EventEmitter<T>();

    private onControlChange?: (value: T) => void;
    private onControlTouched?: () => void;
    private destroy$ = new Subject<void>();

    constructor(private radioGroupService: RadioGroupService<T>) {}

    ngOnInit(): void {
        this.radioGroupService.init();
        this.radioGroupService.value
            .pipe(
                takeUntil(this.destroy$),
                filter(({ emit }) => emit),
            )
            .subscribe(({ value }) => {
                this.changeSelection.emit(value);

                if (this.onControlChange) {
                    this.onControlChange(value);
                }

                if (this.onControlTouched) {
                    this.onControlTouched();
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    writeValue(value: T): void {
        this.radioGroupService.changeSelectedValue(value, false);
    }

    registerOnChange(fn: (value: T) => void): void {
        this.onControlChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onControlTouched = fn;
    }
}
