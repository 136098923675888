import { flatten } from '@interacta-shared/util';
import { IPostFilters } from '../filter-post/filter-post.model';
import {
    QuickFilter,
    QuickFilterType,
    QuickFiltersList,
} from './quick-filters.model';

const MyPostQuickFilter: QuickFilter = {
    id: 'my_post',
    type: QuickFilterType.MY_POST,
    label: 'DASHBOARD.FILTERS.MY_POSTS',
    description: 'DASHBOARD.FILTERS.MY_POSTS_DESCRIPTION',
    filters: {
        createdByMe: true,
    },
    order: 0,
    showInBar: true,
    shared: false,
};

const ToManageQuickFilter: QuickFilter = {
    id: 'to_manage',
    type: QuickFilterType.TO_MANAGE,
    label: 'DASHBOARD.FILTERS.TO_MANAGE',
    description: 'DASHBOARD.FILTERS.TO_MANAGE_DESCRIPTION',
    filters: {
        toManage: true,
    },
    order: 1,
    showInBar: true,
    shared: false,
};

const MentionedQuickFilter: QuickFilter = {
    id: 'mentions',
    type: QuickFilterType.MENTIONED,
    label: 'DASHBOARD.FILTERS.MENTIONS',
    description: 'DASHBOARD.FILTERS.MENTIONS_DESCRIPTION',
    filters: {
        mentioned: true,
    },
    order: 2,
    showInBar: true,
    shared: false,
};
const FollowedByMeQuickFilter: QuickFilter = {
    id: 'followed_by_me',
    type: QuickFilterType.FOLLOWED_BY_ME,
    label: 'DASHBOARD.FILTERS.FOLLOWED',
    description: 'DASHBOARD.FILTERS.FOLLOWED_DESCRIPTION',
    filters: {
        followedByMe: true,
    },
    order: 3,
    showInBar: false,
    shared: false,
};

function getToManageQuickFilter(
    order: number,
    showInBar: boolean,
): QuickFilter {
    return { ...ToManageQuickFilter, order, showInBar };
}

function getFollowedByMeQuickFilter(
    order: number,
    showInBar: boolean,
): QuickFilter {
    return { ...FollowedByMeQuickFilter, order, showInBar };
}

export const getDefaultQuickFiltersList = (): QuickFiltersList => {
    return {
        dashboard: getSystemQuickFilters(true),
    };
};

export const isSystemQuickFilter = (
    quickFilter: Pick<QuickFilter, 'type'>,
): boolean => {
    return quickFilter.type !== QuickFilterType.CUSTOM;
};

/**
 * @returns true if the quick filter has bean saved server side (i.e. users' quick filters order for this community has been initialized)
 */
export const isSavedQuickFilter = (
    quickFilter: Pick<QuickFilter, 'id'>,
): boolean => {
    return typeof quickFilter.id === 'number';
};

export const getSystemQuickFilters = (toManageFirst = false): QuickFilter[] => {
    return toManageFirst
        ? [
              MyPostQuickFilter,
              getToManageQuickFilter(1, toManageFirst),
              MentionedQuickFilter,
              getFollowedByMeQuickFilter(3, !toManageFirst),
          ]
        : [
              MyPostQuickFilter,
              getFollowedByMeQuickFilter(1, !toManageFirst),
              MentionedQuickFilter,
              getToManageQuickFilter(3, toManageFirst),
          ];
};

/**
 * @returns a Partial<IPostFilters> with all the keys set by system quick filters with all the values false
 */
export const emptySystemQuickFilters = (): QuickFilter['filters'] => {
    const filterKeys = flatten(
        getSystemQuickFilters()
            .map((f) => f.filters)
            .map((f) => Object.keys(f)),
    ) as (keyof IPostFilters)[];

    return filterKeys.reduce((accumulator, value) => {
        return {
            ...accumulator,
            [value]: false,
        };
    }, {});
};
