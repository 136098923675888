import { PaginatedList, toPaginatedList } from '@interacta-shared/util';
import {
    toAdminV2Community,
    toAdminV2CommunityCapabilities,
    toAdminV2CommunityRoles,
} from '@modules/admin-v2-community/models/admin-v2-community.deserialize';
import {
    mergeRoleWithSameId,
    toAdminV2MemberRolesAssociations,
} from '@modules/admin-v2-roles/models/admin-v2-roles.deserialize';
import { toAdminV2MemberItem } from '@modules/admin-v2-shared/models/admin-v2-member.deserialize';
import {
    i18nStringToV2,
    toI18nDeltaV2,
    toI18nStringV2,
} from '@modules/admin-v2-shared/utils/admin-v2-i18n';
import { parseIfNotNull } from '@modules/admin-v2-shared/utils/admin-v2-utils';
import { toWorkflowDefinition } from '@modules/communities/models/workflow/workflow.deserialize';
import { MemberGroup } from '@modules/core/models/member/member.model';
import { wrapMember } from '@modules/core/models/member/member.utils';
import {
    AdminV2Workspace,
    AdminV2WorkspaceCommunity,
    AdminV2WorkspaceDetail,
    AdminV2WorkspacePreview,
    AdminV2WorkspaceRoleAssociations,
    AdminV2WorkspaceRow,
    AdminV2WorkspaceSelected,
} from './admin-v2-workspace.model';

export const toAdminV2WorkspaceRow = (record: any): AdminV2WorkspaceRow => ({
    id: record.id,
    name: toI18nStringV2(record.name),
    communities: {
        communities: (record.communities ?? []).map(toAdminV2Community),
        workspaceId: record.id,
        workspaceCapabilities: record.adminCapabilities,
    },
    lastModifyTimestamp: record.lastModifyTimestamp,
    lastModifyUser: toAdminV2MemberItem(wrapMember(record.lastModifyUser)),
    creationTimestamp: record.creationTimestamp,
    creatorUser: toAdminV2MemberItem(wrapMember(record.creatorUser)),
    adminMembers: {
        type: 'workspace',
        id: record.id,
        members: (record.adminMembers ?? []).map(toAdminV2MemberItem),
        canEdit: record.adminCapabilities.editWorkspace ?? false,
    },
    membersCount: record.membersCount,
    adminCapabilities: record.adminCapabilities,
    deleted: false,
    loading: false,
    logicallyDisabled: false,
    selected: false,
});

export const toAdminV2WorkspaceDetail = (
    record: any,
): AdminV2WorkspaceDetail => ({
    id: record.workspace.id,
    name: record.workspace.name,
    description: toI18nDeltaV2(record.workspace.description),
    creationTimestamp: record.workspace.creationTimestamp,
    lastModifyTimestamp: record.workspace.lastModifyTimestamp,
    coverImageTemporaryContentViewLink:
        record.workspace.coverImageTemporaryContentViewLink,
    coverImageContentRef: record.workspace.coverImageContentRef,
    externalId: record.workspace.externalId,
    occToken: record.workspace.occToken,
    capabilities: record.capabilities,
});

export const toAdminV2WorkspaceGroups = (
    record: any,
): PaginatedList<MemberGroup> =>
    toPaginatedList(
        record,
        (x) =>
            wrapMember({
                id: x.id,
                fullName: x.name,
                membersCount: x.membersCount ?? 0,
            }) as MemberGroup,
    );

export const toAdminV2WorkspaceCommunities = (
    record: any,
): AdminV2WorkspaceCommunity[] =>
    record.communities.map(toAdminV2WorkspaceCommunity);

const toAdminV2WorkspaceCommunity = (
    record: any,
): AdminV2WorkspaceCommunity => ({
    ...toAdminV2Community(record),
    coverImagePreviewUrl: record.coverImageContentPreviewImageLink,
    membersCount: record.membersCount,
    postCount: record.postCount,
    workflow: record.workflowDefinition
        ? toWorkflowDefinition(record.workflowDefinition)
        : null,
    visibleInOrganizationTree: record.visibleInOrganizationTree,
    capabilities: toAdminV2CommunityCapabilities(record.adminCapabilities),
});

export const toAdminV2Workspace = (record: any): AdminV2Workspace => ({
    id: record.id,
    name: i18nStringToV2(record.name),
});

export const toAdminV2WorkspaceSelected = (
    record: any,
): AdminV2WorkspaceSelected => ({
    ...toAdminV2Workspace(record),
    selected: record.selected,
});

const toAdminV2WorkspaceRoleAssociations = (
    record: any,
): AdminV2WorkspaceRoleAssociations => ({
    workspace: toAdminV2Workspace(record.workspace),
    communities: record.communities
        ? record.communities.map(toAdminV2CommunityRoles)
        : [],
    adminRoles: mergeRoleWithSameId(record.adminRoles),
});

export const toAdminV2WorkspaceRoleAssociationsList = (
    record: any,
): AdminV2WorkspaceRoleAssociations[] =>
    (record.workspaces || []).map(toAdminV2WorkspaceRoleAssociations);

export const toAdminV2WorkspacePreview = (
    record: any,
): AdminV2WorkspacePreview => ({
    ...toAdminV2Workspace(record.workspace),
    description: toI18nDeltaV2(record.workspace.description),
    coverImageTemporaryContentViewLink:
        record.workspace.coverImageTemporaryContentViewLink,
    adminMembers: parseIfNotNull(
        record.adminRoleAssociations,
        toAdminV2MemberRolesAssociations,
    ),
    communitiesCount: record.communitiesCount,
    capabilities: record.capabilities,
});
