import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Size } from '@interacta-shared/ui';

@Component({
    selector: 'interacta-end-list',
    templateUrl: './end-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EndListComponent {
    @Input({ required: true }) imageName!:
        | 'end-list'
        | 'empty-page'
        | 'gardening'
        | 'reading';
    @Input() title = '';
    @Input() subtitle = '';
    @Input() size: Extract<Size, 'small' | 'regular' | 'large'> = 'regular';
    @Input() format: 'png' | 'webp' = 'webp';
    @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
    @Input() textAlign: 'left' | 'center' | 'right' = 'left';
}
