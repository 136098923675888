<div
    class="flex max-w-full items-center space-x-8"
    [ngClass]="rtl ? 'flex-row-reverse space-x-reverse' : ''"
>
    <div
        *ngIf="user.icon; else avatar"
        class="shrink-0 flex items-center justify-center rounded-full {{
            isEmptyOrCurrent() ? 'bg-avatar-I' : colorClass()
        }}"
        [ngClass]="{
            'size-40': size === 'regular',
            'size-30': size === 'small',
            'size-24': size === 'extra-small'
        }"
    >
        <interacta-icon
            class="text-text-on-avatar h-full w-full"
            [icon]="user.icon"
        ></interacta-icon>
    </div>
    <ng-template #avatar>
        <interacta-avatar
            [size]="size"
            [user]="{
                id: user.id,
                fullName: user.fullName,
                accountPhotoUrl: user.accountPhotoUrl || ''
            }"
        ></interacta-avatar>
    </ng-template>
    <div class="flex min-w-0 grow flex-col">
        <div class="flex items-center gap-x-8">
            <p
                class="line-clamp-1 break-all"
                [ngClass]="{
                    'text-text': color === 'black',
                    'text-text-mid-contrast': color === 'gray',
                    'text-bg-card': color === 'white',
                    'typo-xs':
                        size === 'extra-small' && fontWeight === 'regular',
                    'typo-xs-bold':
                        size === 'extra-small' && fontWeight === 'bold',
                    'typo-sm':
                        size !== 'extra-small' && fontWeight === 'regular',
                    'typo-sm-bold':
                        size !== 'extra-small' && fontWeight === 'bold',
                    'pr-4 italic': isEmptyOrCurrent(),
                    'text-left': !rtl,
                    'grow text-right': rtl
                }"
            >
                {{ user.fullName }}
            </p>
            <interacta-icon
                *ngIf="user.followedByMe"
                class="text-text-primary size-20"
                [icon]="'bell'"
            ></interacta-icon>
        </div>

        <ng-container
            *ngIf="
                showInfo &&
                (user.caption || user.contactEmail || additionalInfo)
            "
        >
            <p
                class="text-text-mid-contrast typo-xs line-clamp-1 break-all"
                [matTooltip]="user.caption ?? ''"
                [matTooltipDisabled]="!user.caption"
                [ngClass]="{
                    'text-left': !rtl,
                    'text-right': rtl
                }"
            >
                {{ user.caption ?? user.contactEmail ?? additionalInfo }}
            </p>
        </ng-container>
    </div>
</div>
