<div
    class="flex items-center"
    [ngClass]="{
        'flex-col justify-center ': orientation === 'vertical',
        'justify-center gap-32': orientation === 'horizontal',
        'gap-16': orientation === 'vertical' && size !== 'small'
    }"
>
    <interacta-image
        [alt]="
            (imageName === 'no-results' ? 'UI.NO_RESULT' : 'UI.EMPTY_PAGE')
                | translate
        "
        [classes]="
            'object-contain shrink-0 ' +
            (size === 'large'
                ? 'h-308'
                : size === 'regular'
                  ? 'h-250'
                  : 'h-120')
        "
        fallback="./assets/shared/images/doodles/{{ imageName }}.{{
            format === 'webp' ? 'png' : 'webp'
        }}"
        src="./assets/shared/images/doodles/{{ imageName }}.{{
            format === 'webp' ? 'webp' : 'png'
        }}"
    ></interacta-image>

    <div class="flex flex-col items-center space-y-12">
        @if (title) {
            <div
                [innerHTML]="title"
                [ngClass]="{
                    'text-center': textAlign === 'center',
                    'typo-medium': textSize === 'medium',
                    'typo-sm': textSize === 'small',
                    'text-text': !lockedColor,
                    'text-text-white': lockedColor === 'light'
                }"
            ></div>
        }
        @if (subtitle) {
            <div
                class="text-text-mid-contrast typo-sm"
                [innerHTML]="subtitle"
                [ngClass]="{ 'text-center': textAlign === 'center' }"
            ></div>
        }
    </div>
</div>
