<div
    *ngIf="{ showExternalLinks: showExternalLinks$ | async } as $"
    class="bg-surface-B rounded-2xl p-16"
>
    <interacta-accordion
        [isOpen]="$.showExternalLinks ?? false"
        [label]="'DIGITAL_WORKPLACE.EXTERNAL_LINKS' | translate"
        [size]="'extra-large'"
        (toggleOpen)="showExternalLinks$.next(!$.showExternalLinks)"
    >
        <div class="flex flex-wrap gap-16">
            @for (externalLink of externalLinks; track externalLink.id) {
                <button
                    class="bg-surface-A w-204 relative flex flex-col items-center justify-between gap-y-8 rounded-lg p-8"
                    type="button"
                    (click)="openExternalLink(externalLink)"
                >
                    @if (externalLink.imgSrc) {
                        <interacta-image
                            class="aspect-video grow"
                            [alt]="''"
                            [classes]="'h-full w-auto'"
                            [src]="externalLink.imgSrc"
                        ></interacta-image>

                        <interacta-image
                            class="absolute right-0 top-0 size-32"
                            [alt]="'Interacta suite link'"
                            [classes]="'h-full w-auto'"
                            [src]="'./assets/images-2.0/suite-links/avatar.png'"
                        ></interacta-image>
                    } @else {
                        <div
                            class="aspect-video grow overflow-hidden rounded-lg"
                            [ngStyle]="{
                                background:
                                    $index
                                    | apply: getRandomSecondaryColorVariables
                            }"
                        ></div>
                    }
                    <p
                        class="typo-sm flex w-full shrink-0 items-center justify-between"
                    >
                        {{ externalLink.label | translate }}
                        <interacta-icon
                            *ngIf="!externalLink.imgSrc"
                            class="size-24"
                            [icon]="'external-link'"
                        ></interacta-icon>
                    </p>
                </button>
            }
        </div>
    </interacta-accordion>
</div>
