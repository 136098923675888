import { Injectable } from '@angular/core';
import { LocalStorageService } from '@modules/core/services/local-storage.service';
import { CatalogsState } from '../models/catalogs-state.model';
import { ChatState } from '../models/chat-state.model';
import { CommunitiesState } from '../models/communities-state.model';
import { GalleryState } from '../models/gallery-state.model';
import { LinksDialogState } from '../models/links-dialog-state.model';
import { NotificationBellState } from '../models/notification-bell-state.model';
import { ProfilePreviewDialogState } from '../models/profile-preview-dialog-state.model';
import { UIState } from '../models/ui-state.model';
import { ExternalLinksState } from './../models/external-links-state.model';

@Injectable({ providedIn: 'root' })
export class StateService {
    readonly communitiesState: CommunitiesState;
    readonly catalogsState: CatalogsState;
    readonly galleryState: GalleryState;
    readonly notificationBellState: NotificationBellState;
    readonly uiState: UIState;
    readonly externalLinksState: ExternalLinksState;
    readonly profilePreviewDialogState: ProfilePreviewDialogState;
    readonly linksDialogState: LinksDialogState;
    readonly chatState: ChatState;

    constructor(localStorageService: LocalStorageService) {
        this.communitiesState = new CommunitiesState();
        this.catalogsState = new CatalogsState();
        this.galleryState = new GalleryState();
        this.notificationBellState = new NotificationBellState();
        this.uiState = new UIState(localStorageService);
        this.externalLinksState = new ExternalLinksState();
        this.profilePreviewDialogState = new ProfilePreviewDialogState();
        this.linksDialogState = new LinksDialogState();
        this.chatState = new ChatState();
    }
}
