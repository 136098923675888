import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICommunity } from '@modules/communities/models/communities.model';

@Component({
    selector: 'interacta-community-card',
    templateUrl: './community-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunityCardComponent {
    @Input() community!: ICommunity;
}
