import { inject, Pipe, PipeTransform } from '@angular/core';
import { isDefined } from '@interacta-shared/util';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'translateArray',
})
export class TranslateArrayPipe implements PipeTransform {
    #translate = inject(TranslateService);

    transform(value: string[] | undefined): string[] {
        return (value || [])
            .filter(isDefined)
            .map((label) => this.#translate.instant(label));
    }
}
