import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    inject,
} from '@angular/core';
import { isDefined } from '@interacta-shared/util';
import { IUser, IUsersGroup } from '@modules/core';
import { Member } from '@modules/core/models/member/member.model';
import {
    getMemberFullname,
    isMemberGroup,
    isMemberUser,
    wrapMember,
} from '@modules/core/models/member/member.utils';
import { GroupInfoV2Service } from '@modules/group-info/group-info-v2.service';
import { ProfilePreviewDialogStateService } from '@modules/state/services/profile-preview-dialog-state.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'interacta-member-list',
    templateUrl: './member-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberListComponent implements OnChanges {
    @Input() unwrappedMemberList!: (IUser | IUsersGroup)[] | null;
    @Input() fieldName!: string | null;
    @Input() showAll = false;
    @Input() canClick = true;
    hiddenCount$ = new BehaviorSubject<number>(0);
    wrappedMemberList: Member[] = [];
    isDialogOpen = false;

    readonly MAX_VISIBLE_MEMBERS = 20;

    getMemberFullname = getMemberFullname;
    isMemberUser = isMemberUser;

    private readonly groupInfoService = inject(GroupInfoV2Service);

    constructor(
        private profilePreviewDialogStateService: ProfilePreviewDialogStateService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.unwrappedMemberList) {
            this.wrappedMemberList = (this.unwrappedMemberList || [])
                .map((u) => wrapMember(u))
                .filter(isDefined);
        }
    }

    trackMember(_idx: number, item: Member): number {
        return item.innerId;
    }

    openMemberDialog(user: Member): void {
        if (isMemberUser(user)) {
            this.profilePreviewDialogStateService
                .open(user.innerId)
                .subscribe();
        }

        if (isMemberGroup(user)) {
            this.groupInfoService.openDialog(user.group.id).subscribe();
        }
    }

    showMoreCount(countFromDirective: number): void {
        const differential = Math.max(
            0,
            this.wrappedMemberList.length - this.MAX_VISIBLE_MEMBERS,
        );
        this.hiddenCount$.next(countFromDirective + differential);
    }
}
