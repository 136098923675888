import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'interacta-separator',
    templateUrl: './separator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass],
})
export class SeparatorComponent {
    @Input() thickness: 'strong' | 'soft' | 'on-shadow' = 'strong';
    @Input() height: 'regular' | 'large' = 'regular';
}
