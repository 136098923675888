import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IList } from '@interacta-shared/util';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { PartialSharedProfile } from '@modules/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CommunitiesStateService } from '../../state/services/communities-state.service';
import {
    IUserActivitiesFilter,
    UserActivitiesDeserialize,
} from '../models/user-activities.model';

@Injectable({
    providedIn: 'root',
})
export class UserActivitiesService {
    private baseHashtagUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/update-notifications/hashtags`;
    private baseUserUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/people/users`;

    constructor(
        private http: HttpClient,
        private communitiesStateService: CommunitiesStateService,
    ) {}

    setHashtagFollow(hashtagId: number, follow: boolean): Observable<void> {
        return this.http.put<void>(
            `${this.baseHashtagUrl}/${hashtagId}/follow`,
            { follow },
        );
    }

    setUserFollow(userId: number, follow: boolean): Observable<any> {
        return this.http.put(`${this.baseUserUrl}/${userId}/follow`, {
            follow,
        });
    }

    toggleUserFollow(user: PartialSharedProfile): Observable<any> {
        return user.followedByMe
            ? this.setUserFollow(user.id, false)
            : this.setUserFollow(user.id, true);
    }

    getHashtagsFollowed(
        filter: IUserActivitiesFilter,
    ): Observable<IList<IHashTag>> {
        return this.http.post(`${this.baseHashtagUrl}/followed`, filter).pipe(
            map((record) => UserActivitiesDeserialize.hashtagFollowed(record)),
            mergeMap((hashtags: IList<IHashTag>) => {
                if (!hashtags || hashtags.list.length === 0) {
                    return of(hashtags);
                }
                return forkJoin(
                    hashtags.list.map((tag: IHashTag) => {
                        return this.communitiesStateService
                            .getCommunity(tag.communityId)
                            .pipe(map((res) => (tag.community = res)));
                    }),
                ).pipe(map(() => hashtags));
            }),
        );
    }
}
