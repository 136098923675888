import { Params } from '@angular/router';
import { IList } from '@interacta-shared/util';
import { IPostFilters } from '@modules/post/models/filter-post/filter-post.model';
import { ICommunity } from '../communities.model';
import { toHashtag } from './hashtag.deserialize';
import { IHashTag } from './hashtag.model';

export const hashtagList = (community: ICommunity): IList<IHashTag> => ({
    list: community ? community.metadata.activeHashtags.map(toHashtag) : [],
    totalCount: community.metadata.activeHashtags.length,
});

export const searchTag = (
    search: string = '',
    hashtags: IHashTag[],
): IHashTag[] => {
    const lowerCaseSearch = search.toLowerCase();
    return hashtags.filter(
        (tag: IHashTag) =>
            tag.name.toLowerCase().indexOf(lowerCaseSearch) !== -1,
    );
};

export const filterTag = (
    qparams: Params,
    communityHashtags: IHashTag[],
    filterHashtags: IHashTag[],
): IHashTag => {
    if (qparams && qparams.filterTag) {
        const filterByUrl = { id: +qparams.filterTag };
        const hashtagFilter = communityHashtags.find(
            (hashtag) => hashtag.id === filterByUrl.id,
        );
        if (
            !filterHashtags.find((hashtag) => hashtag.id === hashtagFilter.id)
        ) {
            return hashtagFilter;
        }
    }
};

export const parseHashtagsInRoute = (
    rawRouteHashtags: any,
    currFilters: IPostFilters,
    communityId: number,
): IHashTag[] => {
    const parsedHashtag: { id: number; name: string } =
        JSON.parse(rawRouteHashtags);
    const hashtag: IHashTag = {
        ...parsedHashtag,
        communityId,
        deleted: false,
    };
    const currHashtagsInFilter = currFilters.hashtag || [];
    return currHashtagsInFilter.find((item) => item.id === parsedHashtag.id)
        ? currHashtagsInFilter
        : [...currHashtagsInFilter, hashtag];
};
