import { Pipe, PipeTransform } from '@angular/core';
import { ParticipantsResponseCount } from '@modules/post/models/base-post.model';
import { EventParticipateType } from '@modules/post/models/event-post.model';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'participantCounters',
})
export class ParticipantCountersPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(counters?: ParticipantsResponseCount): string {
        if (!counters) {
            return '';
        }

        const yesCount = counters[EventParticipateType.YES] ?? 0;
        const maybeCount = counters[EventParticipateType.MAYBE] ?? 0;
        const noCount = counters[EventParticipateType.NO] ?? 0;

        const yes = yesCount
            ? `${yesCount} ${this.translateService.instant('EVENT.ANSWER_YES')}`
            : '';
        const no = noCount
            ? `${noCount} ${this.translateService.instant('EVENT.ANSWER_NO')}`
            : '';
        const maybe = maybeCount
            ? `${maybeCount} ${this.translateService.instant(
                  'EVENT.ANSWER_MAYBE',
              )}`
            : '';

        return [yes, no, maybe]
            .filter((r) => !!r)
            .join(', ')
            .toLowerCase();
    }
}
