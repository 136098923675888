export function areEqualObjects<T, K extends keyof T>(
    src: T,
    dst: T,
    notKeysCompare?: K[],
): boolean {
    notKeysCompare = notKeysCompare || [];
    if (src === dst || objectsAreEmpty(src, dst)) {
        return true;
    }
    if (!src || !dst || typeof src === 'string' || typeof dst === 'string') {
        return false;
    }
    if (src.toString && dst.toString && src.toString() !== dst.toString()) {
        return false;
    }

    return (
        _auxAreEqualObjects(src, dst, notKeysCompare) &&
        _auxAreEqualObjects(dst, src, notKeysCompare)
    );
}

export function objectIsEmpty<T>(obj: T): boolean {
    if (obj == null) {
        return true;
    }
    if (!(obj instanceof Date) && obj instanceof Object) {
        return Object.keys(obj).length === 0;
    }
    return false;
}

function _auxAreEqualObjects(
    src: any,
    dst: any,
    notKeysCompare?: any,
): boolean {
    let equals = true;
    Object.keys(src).forEach((key) => {
        if (
            notKeysCompare.indexOf(key) === -1 &&
            !areEqualObjects(src[key], dst[key])
        ) {
            equals = false;
        }
    });
    return equals;
}

function objectsAreEmpty(obj1: any, obj2: any) {
    return objectIsEmpty(obj1) && objectIsEmpty(obj2);
}
