import { ZonedDateTime } from '@interacta-shared/data-access-configuration';
import {
    ConfigurableFieldTypes,
    FieldType,
    ICustomFieldDefinition,
} from '@modules/communities/models/custom-metadata/custom-metadata.model';
import { updateTimeRelatedState } from '@modules/communities/store/post/post.actions';
import { TimeoutsService } from '@modules/core/services/timeouts.service';
import { SurveyQuestionForCreate } from '@modules/post/models/survey-post/survey-post.model';
import { Store } from '@ngrx/store';
import { differenceInMilliseconds, isAfter, isFuture, isPast } from 'date-fns';
import { IBasePost, PostType } from '../base-post.model';
import { GenericPost } from '../generic-post.model';
import {
    SurveyPost,
    SurveyQuestionDefinition,
    SurveyState,
    SurveyStateStyle,
    SurveyType,
} from './survey-post.model';

export function isSurveyPost(post: IBasePost): post is SurveyPost {
    return post.type === PostType.SURVEY;
}

export function asSurveyPost(post: IBasePost): SurveyPost | undefined {
    return isSurveyPost(post) ? post : undefined;
}

export function isSurveyPostType(post: IBasePost): post is SurveyPost {
    return isSurveyPost(post) && post.surveyType === SurveyType.SURVEY;
}

export function isFeedBackPostType(post: IBasePost): post is SurveyPost {
    return isSurveyPost(post) && post.surveyType === SurveyType.FEEDBACK;
}

export function getSurveyPostType(
    post: GenericPost,
): SurveyPost['surveyType'] | undefined {
    return isSurveyPost(post) ? post.surveyType : undefined;
}

const stateBackgroudMap: Record<SurveyState, SurveyStateStyle['background']> = {
    scheduled: 'bg-status-G',
    open: 'bg-status-D',
    closed: 'bg-status-F',
};

export function buildSurveyState(
    startAt: SurveyPost['startAt'],
    scheduledClosing: SurveyPost['scheduledClosing'],
    closingTimestamp: SurveyPost['closingTimestamp'],
): SurveyState {
    const _startAt = startAt?.zonedDatetime ?? startAt?.localDatetime;
    const _scheduledClosing =
        scheduledClosing?.zonedDatetime ?? scheduledClosing?.localDatetime;

    return closingTimestamp != null ||
        (_scheduledClosing && isPast(_scheduledClosing))
        ? 'closed'
        : _startAt && isFuture(_startAt)
          ? 'scheduled'
          : 'open';
}

export function buildSurveyStateStyle(
    state: SurveyState,
    surveyType: SurveyType,
): SurveyStateStyle {
    return {
        label: `${
            surveyType === SurveyType.FEEDBACK ? 'FEEDBACK' : 'SURVEY'
        }.STATE.${state.toUpperCase()}`,
        background: stateBackgroudMap[state],
    };
}

export function isSurveyPostExpired(post: SurveyPost): boolean {
    const _expirationClosing =
        post.expiration?.zonedDatetime ?? post.expiration?.localDatetime;

    return (
        post.state === 'open' &&
        !!_expirationClosing &&
        isPast(_expirationClosing)
    );
}

export const getEmptyQuestionForCreate = (
    order = 0,
): SurveyQuestionForCreate => ({
    label: '',
    description: '',
    type: ConfigurableFieldTypes[0],
    fieldType: FieldType.TEXT,
    readonly: false,
    deleted: false,
    required: false,
    metadata: {},
    originalMetadata: {},
    childIds: [],
    validations: {},
    enumValues: [],
    localId: null,
    order,
});

export function fromSurveyDefinitionToCustomDefinition(
    surveyQuestionDefinition: SurveyQuestionDefinition,
): ICustomFieldDefinition {
    return {
        ...surveyQuestionDefinition,
        name: '',
        visibleInCreate: false,
        visibleInDetail: false,
        visibleInEdit: false,
        visibleInPreview: false,
        searchable: false,
        sortable: false,
    };
}

export function getExpirationDate(
    survey: Pick<SurveyPost, 'expiration' | 'timezone'>,
): ZonedDateTime | undefined {
    return survey.expiration
        ? {
              zonedDatetime:
                  survey.expiration.zonedDatetime ??
                  survey.expiration.localDatetime,
              localDatetime: survey.expiration.localDatetime,
              timezone: survey.timezone,
          }
        : undefined;
}

export function getScheduledClosingDate(
    survey: Pick<SurveyPost, 'scheduledClosing' | 'timezone'>,
): ZonedDateTime | undefined {
    return survey.scheduledClosing
        ? {
              zonedDatetime:
                  survey.scheduledClosing.zonedDatetime ??
                  survey.scheduledClosing.localDatetime,
              localDatetime: survey.scheduledClosing.localDatetime,
              timezone: survey.timezone,
          }
        : undefined;
}

export function getStartAtDate(
    survey: Pick<SurveyPost, 'startAt' | 'timezone' | 'creationTimestamp'>,
): ZonedDateTime | Date | undefined {
    return survey.startAt
        ? {
              zonedDatetime:
                  survey.startAt.zonedDatetime ?? survey.startAt.localDatetime,
              localDatetime: survey.startAt.localDatetime,
              timezone: survey.timezone,
          }
        : survey.creationTimestamp;
}

export function getClosingDate(
    survey: Pick<
        SurveyPost,
        'state' | 'closingTimestamp' | 'scheduledClosing' | 'timezone'
    >,
): ZonedDateTime | Date | undefined {
    return (
        survey.closingTimestamp ??
        (survey.scheduledClosing
            ? {
                  zonedDatetime:
                      survey.scheduledClosing.zonedDatetime ??
                      survey.scheduledClosing.localDatetime,
                  localDatetime: survey.scheduledClosing.localDatetime,
                  timezone: survey.timezone,
              }
            : undefined)
    );
}

export function canShowExpirationDateWhenClosed(post: SurveyPost): boolean {
    const closeTimestamp =
        post.closingTimestamp ?? post.scheduledClosing?.localDatetime;

    const expiredBeforeClosing =
        post.expiration != null &&
        closeTimestamp != null &&
        isAfter(closeTimestamp, post.expiration.localDatetime);

    return post.state === 'closed' && expiredBeforeClosing;
}

export function setSurveyStateUpdateTimeout(
    post: SurveyPost,
    timeoutsService: TimeoutsService,
    store: Store,
): void {
    if (post.state === 'open' && post.scheduledClosing) {
        const ms = differenceInMilliseconds(
            post.scheduledClosing.zonedDatetime ??
                post.scheduledClosing.localDatetime,
            new Date(),
        );

        const postId = post.id;

        const fn = (): void => {
            store.dispatch(
                updateTimeRelatedState({
                    postId,
                }),
            );
        };

        timeoutsService.setTimeout(`surveyExpiration:${postId}`, fn, ms);
    }
}
