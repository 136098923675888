import { fromPageToken } from '@interacta-shared/util';
import {
    getPathColor,
    getPathIcon,
    isMediaAudio,
    isMediaGif,
    isMediaImage,
    isMediaVideo,
} from '@interacta-shared/util-common';
import { toHashtag } from '@modules/communities/models/hashtag/hashtag.deserialize';
import { UserDeserilize } from '@modules/core';
import { Server2Delta } from '@modules/core/helpers/delta/server-2-delta.class';
import { GoogleDriveFileDeserialize } from '@modules/core/models/google-drive/google-drive.model';
import { IBasePost, IPostMetadata } from '../base-post.model';
import { isCustomPost } from '../custom-post.model';
import {
    AttachmentCategoryType,
    AttachmentStorageType,
    IAttachment,
    IAttachmentCapabilities,
    IAttachmentCounter,
    IAttachmentListEditResponse,
    IAttachmentPost,
    IAttachmentTemporary,
    IListAttachments,
} from './attachment.model';
import {
    attachmentsFiles,
    attachmentsMedia,
    buildTemporaryContentEmbedLink,
    isEmbeddable,
} from './attachment.utils';

/**
 *
 * @param pageToken undefined --> firstLoading
 *                  string --> regularLoading
 *                  null --> lastLoading
 * @returns
 */
export const toAttachmentsList = (
    attachments: any[] | null,
    count: number,
    attachmentCategoryType: AttachmentCategoryType | null = null,
    pageToken?: string | null,
): IListAttachments => ({
    attachments: attachments ? attachments.map(toAttachmentDetails) : [],
    pageTokenInfo: fromPageToken(pageToken),
    totalCount: count,
    attachmentCategoryType: attachmentCategoryType ?? undefined,
    isLoading: false,
    isError: false,
});

export const toAttachmentCapabilities = (
    record: any,
): IAttachmentCapabilities => ({
    canDelete: record.canDelete,
    canEdit: record.canEdit,
    canEditHashtags: record.canEditHashtags,
});

export const toAttachmentDetails = (record: any): IAttachment => {
    return toAttachmentDetailsWithPostData(record);
};

export const toAttachmentDetailsWithPostData = (
    record: any,
    postMetadata?: IPostMetadata,
): IAttachment => {
    const drive = record.drive
        ? GoogleDriveFileDeserialize.googleDriveFileDes(record.drive)
        : undefined;
    const temporaryContentDownloadLink: string =
        record.temporaryContentDownloadLink ?? record.drive?.webContentLink;
    const creatorUser = record.creatorUser
        ? UserDeserilize.userDetails(record.creatorUser)
        : undefined;
    const attachment: IAttachment = {
        id: record.id,
        type: drive
            ? AttachmentStorageType.DRIVE
            : AttachmentStorageType.STORAGE,
        name: record.name,
        contentRef: record.contentRef,
        contentMimeType: record.contentMimeType,
        versionNumber: record.versionNumber,
        versionId: record.versionId,
        size: record.size,
        isDraft: false,
        temporaryContentViewLink: record.temporaryContentViewLink,
        temporaryContentDownloadLink,
        temporaryContentPreviewImageLink:
            record.temporaryContentPreviewImageLink,

        temporaryContentPreviewImageHiResLink:
            record.temporaryContentPreviewImageHiResLink,
        temporaryContentPreviewImageHiResAnimatedLink:
            record.temporaryContentPreviewImageHiResAnimatedLink,

        creatorUser,
        versionCreatorUser: record.versionCreatorUser
            ? UserDeserilize.userDetails(record.versionCreatorUser)
            : creatorUser,
        versionCreationTimestamp: record.versionCreationTimestamp
            ? new Date(record.versionCreationTimestamp as number)
            : new Date(record.lastModifyTimestamp as number),
        creationTimestamp: record.creationTimestamp
            ? new Date(record.creationTimestamp as number)
            : undefined,
        lastModifyTimestamp: record.lastModifyTimestamp
            ? new Date(record.lastModifyTimestamp as number)
            : undefined,
        hashtags: record.hashtags
            ? (record.hashtags as any[]).map(toHashtag)
            : [],
        isMediaVideo: isMediaVideo(record.contentMimeType),
        isMediaAudio: isMediaAudio(record.contentMimeType),
        isMediaImage: isMediaImage(record.contentMimeType),
        isMediaGif: isMediaGif(record.contentMimeType),
        isEmbeddable: isEmbeddable(!!drive, record.contentMimeType),
        communityId: record.post ? record.post.communityId : undefined,
        iconPath: getPathIcon(record.contentMimeType),
        iconColor: getPathColor(record.contentMimeType),
        isAttachmentRef: !!record.reference,
        referencedAttachmentId: record.referencedId,
        capabilities: record.capabilities
            ? toAttachmentCapabilities(record.capabilities)
            : undefined,
        drive,
        canDownload: !drive || !!temporaryContentDownloadLink,
        post: record.post
            ? toAttachmentPost(record.post, postMetadata)
            : undefined,
        commentId: record.commentId ?? null,
        postFilePickerFieldId: record.postFilePickerFieldId,
        screenFilePickerFieldId: record.screenFilePickerFieldId,
        downloadable: record.downloadable,
        addedInLastRework: record.addedInLastRework,
        addedInLastReview: record.addedInLastReview,
    };

    attachment.temporaryContentEmbedLink =
        buildTemporaryContentEmbedLink(attachment);
    return attachment;
};

export const toAttachmentDetailsTemporary = (
    record: any,
): IAttachmentTemporary => ({
    contentRef: record.contentRef,
    uploadUrl: record.uploadUrl,
    uploadMultipartRequestBodyParams: record.uploadMultipartRequestBodyParams,
    temporaryDownloadUrl: record.temporaryDownloadUrl,
});

export const toAttachmentListDetails = (record: any): IAttachment[] => {
    const versions = (record.versions as any[]).map((element: any) =>
        toAttachmentDetails(element),
    );
    if (versions.length > 0) {
        versions[0].isCurrentVersion = true;
    }
    return versions;
};

export const toAttachmentPost = (
    record: any,
    metadata?: IPostMetadata,
): IAttachmentPost => ({
    id: record.id ?? null,
    type: record.type ?? null,
    title: record.title ?? null,
    code: record.customId ?? null,
    descriptionDelta:
        record.descriptionDelta && typeof record.descriptionDelta === 'string'
            ? new Server2Delta().process(record.descriptionDelta as string)
            : record.descriptionDelta &&
                typeof record.descriptionDelta === 'object' &&
                'ops' in record.descriptionDelta
              ? record.descriptionDelta
              : null,
    draft: record.publicationTimestamp == null,
    currentWorkflowState: record.currentWorkflowState,
    metadata,
});

export const toAttachmentsListEditResponse = (
    record: any,
): IAttachmentListEditResponse => ({
    addAttachments:
        (record.addedAttachments as any[])?.map(toAttachmentDetails) ?? [],
    updateAttachments:
        (record.updatedAttachments as any[])?.map(toAttachmentDetails) ?? [],
    removeAttachmentIds: record.removedAttachmentIds ?? [],
    nextOccToken: record.occToken,
});

export const toAttachmentsCounter = (record: any): IAttachmentCounter => {
    const mediasCount: number =
        ((record?.imageAttachmentsCount as number) ?? 0) +
        ((record?.videoAttachmentsCount as number) ?? 0) +
        ((record?.audioAttachmentsCount as number) ?? 0);
    const documentsCount: number =
        record?.documentsCount ?? (record?.attachmentsCount ?? 0) - mediasCount;

    return {
        mediasCount,
        documentsCount,
    };
};

export function toFilePickerAttachmentsCounter(
    post: IBasePost,
): IAttachmentCounter {
    if (!isCustomPost(post)) {
        return {
            mediasCount: 0,
            documentsCount: 0,
        };
    }
    const customAndScreenFields = [
        ...post.customFields.notEmptyFilePickerFields,
        ...post.customFieldsScreen.notEmptyFilePickerFields,
    ];

    const attachments = customAndScreenFields
        .map((field) => field.viewValue.attachments as IAttachment[])
        .filter((attachments) => attachments?.length > 0)
        .flat();

    return {
        mediasCount: attachmentsMedia(attachments).length,
        documentsCount: attachmentsFiles(attachments).length,
    };
}
