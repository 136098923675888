import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ScrollTrackerEvent, openHeightAnimation } from '@interacta-shared/ui';
import {
    PaginatedList,
    emptyPaginatedList,
    getNextPageToken,
} from '@interacta-shared/util';
import { IUser, IUserAction } from '@modules/core';
import {
    PostReaction,
    TogglePostReaction,
} from '@modules/post/models/post-reaction/post-reaction.model';
import { Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'interacta-dialog-likers-viewers',
    templateUrl: './dialog-likers-viewers.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [openHeightAnimation('users', '*')],
})
export class DialogLikersViewersComponent
    implements OnChanges, OnInit, OnDestroy
{
    @Input() selectedTab: TogglePostReaction = PostReaction.LIKES;
    @Input() likers: PaginatedList<IUserAction> | null = null;
    @Input() viewers: PaginatedList<IUserAction> | null = null;
    @Input() enableSearch = true;
    @Input() fixedHeight = true;

    @Output() selectedTabEmit: EventEmitter<TogglePostReaction> =
        new EventEmitter();
    @Output() searchUsers: EventEmitter<{
        pageToken: string | undefined;
        name: string;
    }> = new EventEmitter();

    searchMapControl: Record<TogglePostReaction, UntypedFormControl> = {
        [PostReaction.LIKES]: new UntypedFormControl(''),
        [PostReaction.VIEWS]: new UntypedFormControl(''),
    };
    PostReaction = PostReaction;
    selectedUsers: PaginatedList<IUserAction> = emptyPaginatedList();
    destroy$ = new Subject<void>();
    userCount: Record<string, number> = {
        likes: 0,
        views: 0,
    };

    identity(val: TogglePostReaction): TogglePostReaction {
        return val;
    }

    switchUsersToVisualize(): void {
        if (this.selectedTab === PostReaction.LIKES && this.likers) {
            this.selectedUsers = { ...this.likers };
        } else if (this.selectedTab === PostReaction.VIEWS && this.viewers) {
            this.selectedUsers = { ...this.viewers };
        }
    }

    trackUser(_idx: number, user: IUser): IUser['id'] {
        return user.id;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedTab || changes.likers || changes.viewers) {
            this.switchUsersToVisualize();
        }
    }

    ngOnInit(): void {
        const changes$ = Object.values(this.searchMapControl).map((c) =>
            c.valueChanges.pipe(debounceTime(500), distinctUntilChanged()),
        );
        merge(...changes$)
            .pipe(takeUntil(this.destroy$))
            .subscribe((name: string) => {
                this.searchUsers.emit({
                    name,
                    pageToken: undefined,
                });
            });

        this.userCount = {
            likes: this.likers?.totalCount ?? 0,
            views: this.viewers?.totalCount ?? 0,
        };
    }

    onScroll(event: ScrollTrackerEvent): void {
        if (
            event.endReached &&
            this.selectedUsers.nextPageToken.tag === 'regularLoading' &&
            !this.selectedUsers.isFetching
        ) {
            this.doSearch();
        }
    }

    doSearch(): void {
        this.searchUsers.emit({
            name: this.searchMapControl[this.selectedTab].value,
            pageToken:
                getNextPageToken(this.selectedUsers.nextPageToken) ?? undefined,
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
