import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { InputSelectItem } from '@interacta-shared/ui';
import { i18nString } from '@modules/core';
import { getLabelServerTranslation } from '@modules/core/helpers/generic.utils';

@Pipe({
    name: 'toInputSelectItems',
})
export class ToInputSelectItemsPipe implements PipeTransform {
    private currentLanguage: string;

    constructor(private configurationService: ConfigurationService) {
        this.currentLanguage =
            this.configurationService.getCurrentLanguage().code;
    }

    transform(
        values: {
            id: number;
            label: i18nString;
            temporaryContentViewLink?: string;
        }[],
    ): InputSelectItem[] {
        return values.map((enumValue) => ({
            id: enumValue.id,
            label:
                getLabelServerTranslation(
                    enumValue.label,
                    this.currentLanguage,
                    this.configurationService.getDefaultInfo().systemLanguage
                        ?.code,
                ) ?? '',
            temporaryContentViewLink: enumValue.temporaryContentViewLink,
        }));
    }
}
