import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { StorageFileService } from '@interacta-shared/data-access-common';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import { Index } from '@interacta-shared/util';
import { downloadFromHref } from '@interacta-shared/util-common';
import { isOrLessIE11 } from '@modules/core/helpers/generic.utils';
import {
    toAttachmentDetails,
    toAttachmentDetailsTemporary,
    toAttachmentListDetails,
    toAttachmentsList,
} from '@modules/post/models/attachment/attachment.deserialize';
import {
    AttachmentCategoryType,
    AttachmentStorageType,
    IAttachment,
    IAttachmentTemporary,
    IListAttachments,
} from '@modules/post/models/attachment/attachment.model';
import { Observable, Subject, from, of } from 'rxjs';
import { concatMap, delay, first, map, mergeMap } from 'rxjs/operators';
import {
    AttachmentEntityType,
    AttachmentFilterSerialize,
    IPostAttachmentFilters,
    ITaskAttachmentFilters,
} from '../../post/models/filter-attachment.model';
import { IOrder } from '../models/filter-post/filter-post.model';

interface LoadAttachmentsOptions {
    nextPageToken?: string;
    attachmentCategoryType?: AttachmentCategoryType;
    pageSize?: number;
    order?: IOrder;
    calculateTotalItemsCount?: boolean;
    entityTypes?: AttachmentEntityType[];
    mimeTypes?: string[];
    types?: AttachmentStorageType[];
    loadViewLink?: boolean;
    loadPreviewImageLink?: boolean;
    loadPreviewImageHiResLink?: boolean;
    postFilePickerFieldId?: number;
    wfScreenFilePickerFieldId?: number;
    aiSupportedFilter?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AttachmentService {
    private readonly storageFileService = inject(StorageFileService);
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/posts`;
    private readonly taskBaseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/tasks`;
    private readonly coreStorageUrl = `${inject(ENVIRONMENT).apiBasePath.common}/core/storage`;
    private readonly attachmentsUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication/attachments`;
    private readonly PAGE_SIZE: number = 10;

    private downloadableAttachments$ = new Subject<{
        attachment: IAttachment;
        isTaskAttachment: boolean;
    }>();

    constructor(private http: HttpClient) {
        this.downloadableAttachments$
            .pipe(
                concatMap((item) => of(item).pipe(delay(2000))),
                mergeMap(({ attachment, isTaskAttachment }) =>
                    this.getAttachmentDownloadLink(
                        attachment.id,
                        attachment.temporaryContentDownloadLink,
                        isTaskAttachment,
                    ).pipe(map((url) => ({ url, name: attachment.name }))),
                ),
            )
            .subscribe(({ url, name }) => downloadFromHref(url, name));
    }

    uploadNewAttachment(): Observable<IAttachmentTemporary> {
        if (isOrLessIE11()) {
            return this._uploadAttachmentLegacy();
        }
        return this._uploadAttachment();
    }

    getAttachmentVersions(attachmentId: number): Observable<IAttachment[]> {
        return this.http
            .get<any>(
                `${this.attachmentsUrl}/data/attachment-versions/${attachmentId}`,
            )
            .pipe(map(toAttachmentListDetails));
    }

    downloadAttachment(
        attachment: IAttachment,
        isTaskAttachment = false,
    ): void {
        if (attachment.canDownload && attachment.downloadable) {
            this.downloadableAttachments$.next({
                attachment,
                isTaskAttachment,
            });
        } else {
            console.error(`Can't download attachment ${attachment.id}`);
        }
    }

    getBatchAttachmentDetails(
        attachments: IAttachment[],
    ): Observable<IListAttachments> {
        const attachmentIds = attachments.map((a) => ({
            attachmentId: a.id,
            versionId: a.versionId,
        }));

        return this.http
            .post<{ attachments: any }>(
                `${this.attachmentsUrl}/data/attachments-detail-by-id/`,
                {
                    attachmentIds,
                },
                {
                    params: {
                        loadViewLink: 'true',
                        loadDownloadLink: 'false',
                        loadPreviewImageLink: 'true',
                    },
                },
            )
            .pipe(
                map(({ attachments }) =>
                    toAttachmentsList(
                        attachments,
                        attachmentIds.length,
                        null,
                        null,
                    ),
                ),
            );
    }

    getAttachmentDetail(attachmentId: number): Observable<IAttachment> {
        return this.http
            .get<any>(
                `${this.attachmentsUrl}/data/attachment-detail-by-id/${attachmentId}`,
                {
                    params: {
                        loadViewLink: 'true',
                        loadDownloadLink: 'true',
                        loadPreviewImageLink: 'true',
                    },
                },
            )
            .pipe(
                map((res: any) =>
                    toAttachmentDetails({
                        ...res.attachmentData,
                        post: { ...res.post },
                    }),
                ),
            );
    }

    getAttachmentDownloadLink(
        attachmentId: IAttachment['id'],
        actualLink: string | undefined,
        isTaskAttachment: boolean,
    ): Observable<string> {
        if (actualLink) {
            return of(actualLink);
        }
        return this.http
            .get<any>(
                `${
                    isTaskAttachment ? this.taskBaseUrl : this.baseUrl
                }/data/attachment-detail-by-id/${attachmentId}`,
                {
                    params: {
                        loadViewLink: 'false',
                        loadDownloadLink: 'true',
                        loadPreviewImageLink: 'false',
                    },
                },
            )
            .pipe(
                map((res: any) => {
                    return res?.attachmentData?.temporaryContentDownloadLink;
                }),
            );
    }

    loadPostAttachments(
        postId: number,
        {
            nextPageToken,
            attachmentCategoryType,
            pageSize = this.PAGE_SIZE,
            order = {
                orderBy: 'creationTimestamp',
                orderDesc: false,
            },
            calculateTotalItemsCount = false,
            entityTypes = ['post'],
            mimeTypes,
            types,
            loadViewLink,
            loadPreviewImageLink,
            loadPreviewImageHiResLink,
            wfScreenFilePickerFieldId,
            postFilePickerFieldId,
            aiSupportedFilter = false,
        }: LoadAttachmentsOptions = {},
    ): Observable<IListAttachments> {
        const attachmentFilter = <IPostAttachmentFilters>{
            order,
            calculateTotalItemsCount,
            pageSize: pageSize,
            mimeTypeCategory: attachmentCategoryType,
            pageToken: nextPageToken,
            entityTypes,
            mimeTypes,
            types,
            loadViewLink,
            loadPreviewImageLink,
            loadPreviewImageHiResLink,
            postFilePickerFieldId,
            wfScreenFilePickerFieldId,
            aiSupportedFilter,
        };
        return this.postAttachmentsList(postId, attachmentFilter);
    }

    loadTaskAttachments(
        taskId: number,
        {
            nextPageToken,
            attachmentCategoryType,
            pageSize = this.PAGE_SIZE,
            order = {
                orderBy: 'creationTimestamp',
                orderDesc: false,
            },
            calculateTotalItemsCount = false,
            entityTypes = ['task'],
        }: LoadAttachmentsOptions = {},
    ): Observable<IListAttachments> {
        const attachmentFilter = <ITaskAttachmentFilters>{
            taskIds: [taskId],
            order,
            calculateTotalItemsCount,
            pageSize,
            mimeTypeCategory: attachmentCategoryType,
            pageToken: nextPageToken,
            entityTypes,
        };
        return this.taskAttachmentsList(attachmentFilter);
    }

    massiveUploadHashtags(
        attachmentIds: Index[],
        addHashtagIds: number[],
        removeHashtagIds: number[],
    ): Observable<IAttachment[]> {
        return this.http
            .put<{ updatedAttachments: any[] }>(
                `${this.baseUrl}/manage/edit-post-attachments-hashtags`,
                {
                    attachmentIds: attachmentIds,
                    addHashtagIds: addHashtagIds,
                    removeHashtagIds: removeHashtagIds,
                },
            )
            .pipe(
                map((res) =>
                    res.updatedAttachments.map((a) => toAttachmentDetails(a)),
                ),
            );
    }

    deleteBatchAttachments(
        attachmentIds: IAttachment['id'][],
    ): Observable<number[]> {
        return this.http
            .put<any>(
                `${this.attachmentsUrl}/manage/delete-batch-attachments`,
                {
                    attachmentIds,
                },
            )
            .pipe(map((res: any) => res.attachmentIds ?? []));
    }

    uploadBlob(blob: Blob): Observable<IAttachmentTemporary> {
        return this.uploadNewAttachment().pipe(
            first(),
            mergeMap((uploadData) => {
                const formData = new FormData();

                for (const key in uploadData.uploadMultipartRequestBodyParams) {
                    formData.append(
                        key,
                        uploadData.uploadMultipartRequestBodyParams[key],
                    );
                }
                formData.append('file', blob);

                return from(
                    fetch(uploadData.uploadUrl, {
                        method: 'POST',
                        body: formData,
                    }),
                ).pipe(map((_) => uploadData));
            }),
        );
    }

    private postAttachmentsList(
        postId: number,
        filters: IPostAttachmentFilters,
    ): Observable<IListAttachments> {
        const loadViewLink = String(filters.loadViewLink ?? true);
        const loadPreviewImageLink = String(
            filters.loadPreviewImageLink ?? true,
        );
        const loadPreviewImageHiResLink = String(
            filters.loadPreviewImageHiResLink ?? false,
        );
        return this.http
            .post<{
                items: any[] | null;
                totalItemsCount: number;
                nextPageToken: string | undefined;
            }>(
                `${this.attachmentsUrl}/data/posts/${postId}/attachments-list`,
                AttachmentFilterSerialize.filterToAttachmentPostListServer(
                    filters,
                ),
                {
                    params: {
                        loadViewLink,
                        loadDownloadLink: 'false',
                        loadPreviewImageLink,
                        loadPreviewImageHiResLink,
                    },
                },
            )
            .pipe(
                map((res) =>
                    toAttachmentsList(
                        res.items,
                        res.totalItemsCount,
                        null,
                        res.nextPageToken,
                    ),
                ),
            );
    }

    private taskAttachmentsList(
        filters: ITaskAttachmentFilters,
    ): Observable<IListAttachments> {
        const loadViewLink = String(filters.loadViewLink ?? true);
        const loadPreviewImageLink = String(
            filters.loadPreviewImageLink ?? true,
        );
        const loadPreviewImageHiResLink = String(
            filters.loadPreviewImageHiResLink ?? false,
        );
        return this.http
            .post<{
                items: any[] | null;
                totalItemsCount: number;
                nextPageToken: string | undefined;
            }>(
                `${this.attachmentsUrl}/data/attachments-list`,
                AttachmentFilterSerialize.filterToAttachmentTaskListServer(
                    filters,
                ),
                {
                    params: {
                        loadViewLink,
                        loadDownloadLink: 'false',
                        loadPreviewImageLink,
                        loadPreviewImageHiResLink,
                    },
                },
            )
            .pipe(
                map((res) =>
                    toAttachmentsList(
                        res.items,
                        res.totalItemsCount,
                        null,
                        res.nextPageToken,
                    ),
                ),
            );
    }

    private _uploadAttachment(): Observable<IAttachmentTemporary> {
        return this.storageFileService
            .prepareNewStorageFile()
            .pipe(map(toAttachmentDetailsTemporary));
    }

    private _uploadAttachmentLegacy(): Observable<IAttachmentTemporary> {
        const attachmentTemporaryLegacy = <IAttachmentTemporary>{
            uploadUrl: `${this.coreStorageUrl}/upload-new-attachment-legacy`,
            isLegacy: true,
        };
        return of(attachmentTemporaryLegacy);
    }
}
