import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { QuillText, i18nDeltaV2, i18nStringV2 } from '../models/utility.model';

const wrapWithElement = (
    value: string,
    element: 'span' | 'a',
    cssClass?: string,
): string => {
    return `<${element} class="${cssClass ?? ''}">${value}</${element}>`;
};

export const wrapWithSpan = (value: string, cssClass: string): string => {
    return wrapWithElement(value, 'span', cssClass);
};

export const wrapWithAnchor = (value: string, cssClass: string): string => {
    return wrapWithElement(value, 'a', cssClass);
};

export const getLabelServerTranslationV2 = (
    str: i18nStringV2,
    configurationService: ConfigurationService,
    translateLabel = false,
): string => {
    if (translateLabel) {
        const currentLang = configurationService.getCurrentLanguage().code;
        if (str.i18ns !== null && str.i18ns[currentLang]) {
            return str.i18ns[currentLang];
        }
    }
    return str.label ?? '';
};

export const getDeltaServerTranslationV2 = (
    labelObj: i18nDeltaV2,
    configurationService: ConfigurationService,
    translateLabel = false,
): QuillText => {
    if (translateLabel) {
        const currentLanguage = configurationService.getCurrentLanguage().code;
        if (labelObj.i18ns !== null && labelObj.i18ns[currentLanguage]) {
            return labelObj.i18ns[currentLanguage];
        }
    }
    return labelObj.label;
};
