import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { IListAttachments } from '@modules/post/models/attachment/attachment.model';

@Component({
    selector: 'interacta-light-box-previews',
    templateUrl: './light-box-previews.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightBoxPreviewsComponent implements OnChanges, AfterViewInit {
    @Input({ required: true }) attachmentsList!: IListAttachments;
    @Input() currentIndex = 0;

    @Output() changeAttachment = new EventEmitter<{ index: number }>();
    @Output() loadAttachmentsPage = new EventEmitter<{
        untilIdx: number;
    }>();

    @ViewChild('scroll') scrollRef?: ElementRef<HTMLElement>;
    @ViewChildren('preview') previews!: QueryList<ElementRef<HTMLElement>>;

    readonly MAX_PREVIEWS = 10;
    readonly ATTACHMENT_WIDTH_REM = 3.5;
    readonly ATTACHMENT_MARGIN_REM = 0.5;
    readonly BORDER_WIDTH = 0.125;
    readonly MAX_WIDTH =
        this.ATTACHMENT_WIDTH_REM * this.MAX_PREVIEWS +
        this.ATTACHMENT_MARGIN_REM * (this.MAX_PREVIEWS - 1) +
        this.BORDER_WIDTH * 2;

    pageBegin = 0;
    hoverPreview: boolean[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentIndex && changes.currentIndex.firstChange) {
            if (this.currentIndex > this.MAX_PREVIEWS) {
                this.pageBegin =
                    this.currentIndex - Math.floor(this.MAX_PREVIEWS / 2);
            }
        }

        if (changes.currentIndex && !changes.currentIndex.firstChange) {
            if (
                this.currentIndex > changes.currentIndex.previousValue &&
                this.currentIndex >= this.pageBegin + this.MAX_PREVIEWS
            ) {
                this.pageBegin += 1;
                this.slideToPageBegin(this.pageBegin);
            }

            if (
                this.currentIndex < changes.currentIndex.previousValue &&
                this.currentIndex < this.pageBegin
            ) {
                this.pageBegin -= 1;
                this.slideToPageBegin(this.pageBegin);
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.currentIndex > this.MAX_PREVIEWS) {
            this.slideToPageBegin(
                this.currentIndex - Math.floor(this.MAX_PREVIEWS / 2) + 1,
            );
        }
    }

    slide(direction: 'left' | 'right'): void {
        const sign = direction === 'left' ? -1 : 1;

        this.pageBegin += sign * Math.floor(this.MAX_PREVIEWS / 2);

        this.slideToPageBegin(this.pageBegin);

        if (
            this.pageBegin + this.MAX_PREVIEWS >
            this.attachmentsList.attachments.length
        ) {
            this.loadAttachmentsPage.emit({
                untilIdx: this.pageBegin + this.MAX_PREVIEWS,
            });
        }
    }

    trackPreview = (idx: number): string => {
        return this.attachmentsList.attachments[idx] == null
            ? `placeholder-${idx}`
            : `preview-${idx}`;
    };

    private slideToPageBegin(pageBegin: number): void {
        const preview = this.previews.get(pageBegin);

        if (this.scrollRef && preview) {
            this.scrollRef.nativeElement.scrollLeft =
                preview.nativeElement.offsetLeft - this.BORDER_WIDTH;
        }
    }
}
