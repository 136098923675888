export enum PostViewScenario {
    DETAIL = 1, //currently not used
    LIST = 2,
    CREATE = 3, //currently not used
}

export interface IPostView {
    id?: number;
    name?: string;
    titleEnabled: boolean;
    customIdEnabled: boolean;
    descriptionEnabled: boolean;
    currentWorkflowStateEnabled: boolean;
    postFieldIds?: number[];
}

export type PostViewScenarioIndex = { [key in PostViewScenario]?: IPostView };
