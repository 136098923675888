import { Injectable } from '@angular/core';
import { AuthService } from '@interacta-shared/data-access-auth';
import { del, get, keys, set } from 'idb-keyval';
import { Observable, from, of, throwError } from 'rxjs';
import { filter, map, mapTo, switchMap, toArray } from 'rxjs/operators';

class UserNotLoggedError extends Error {
    constructor() {
        super(
            `You tried to read or write a user-aware information from browser Storage, but you're not logged in.`,
        );
    }
}

export type UserAwareMode = boolean | 'ifLogged';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    private readonly keyPrefix = 'interacta2:';

    constructor(private authService: AuthService) {}

    /**
     * @returns undefined if key is not found, or the user isn't logged and userAware is true
     */
    public getEntry<T>(
        key: string,
        userAware: UserAwareMode = true,
    ): T | undefined {
        try {
            const item = localStorage.getItem(this.composeKey(key, userAware));
            return item ? JSON.parse(item) : undefined;
        } catch (error) {
            if (error instanceof UserNotLoggedError) {
                console.warn(
                    `You tried to get a user-aware information (${key}) from Local Storage, but you're not logged in.`,
                );
                return undefined;
            } else {
                throw error;
            }
        }
    }

    public setEntry(
        key: string,
        value: any,
        userAware: UserAwareMode = true,
    ): void {
        try {
            const actualKey = this.composeKey(key, userAware);
            if (value != null) {
                localStorage.setItem(actualKey, JSON.stringify(value));
            } else {
                localStorage.removeItem(actualKey);
            }
        } catch (error) {
            if (error instanceof UserNotLoggedError) {
                console.warn(
                    `You tried to ${
                        value != null ? 'set' : 'remove'
                    } a user-aware information (${key}) into Local Storage, but you're not logged in.`,
                );
            } else {
                throw error;
            }
        }
    }

    /**
     * @throws {Error} when the user isn't logged
     */
    public getIdbEntry<T>(
        key: string,
        userAware = true,
    ): Observable<T | undefined> {
        try {
            const promise: Promise<T> = get(this.composeKey(key, userAware));
            return from(promise);
        } catch (error) {
            if (error instanceof UserNotLoggedError) {
                console.warn(
                    `You tried to get a user-aware information (${key}) from IndexedDB, but you're not logged in.`,
                );
                return of(undefined);
            } else {
                return throwError(error);
            }
        }
    }

    /**
     * @throws {Error} when the user isn't logged
     */
    public setIdbEntry(
        key: string,
        value: any,
        userAware = true,
    ): Observable<void> {
        try {
            const promise: Promise<void> = set(
                this.composeKey(key, userAware),
                value,
            );
            return from(promise);
        } catch (error) {
            if (error instanceof UserNotLoggedError) {
                console.warn(
                    `You tried to set a user-aware information (${key}) into IndexedDB, but you're not logged in.`,
                );
                return of(void 0);
            } else {
                return throwError(error);
            }
        }
    }

    /**
     * delete a database entry
     * @throws {Error} when the user isn't logged
     */
    public deleteIdbEntry(key: string, userAware = true): Observable<void> {
        try {
            const promise: Promise<void> = del(this.composeKey(key, userAware));
            return from(promise);
        } catch (error) {
            if (error instanceof UserNotLoggedError) {
                console.warn(
                    `You tried to delete a user-aware information (${key}) from IndexedDB, but you're not logged in.`,
                );
                return of(void 0);
            } else {
                return throwError(error);
            }
        }
    }

    /**
     * delete all database entries matching given key pattern
     */
    public bulkDeleteIdbEntries(keyPattern: RegExp): Observable<void> {
        return from(keys()).pipe(
            switchMap((_keys) => _keys),
            filter(
                (key) =>
                    typeof key === 'string' &&
                    !!new RegExp(`^${this.keyPrefix}`).exec(key) &&
                    !!keyPattern.exec(key),
            ),
            map((key) => del(key)),
            toArray(),
            mapTo(void 0),
        );
    }

    private composeKey(key: string, userAware: UserAwareMode): string {
        let suffix = '';
        if (userAware) {
            suffix = this.getLoggedUserSuffix(userAware);
        }

        return this.keyPrefix.concat(key, suffix);
    }

    /**
     * @throws {Error} when the user isn't logged and userAware === ture
     */
    private getLoggedUserSuffix(userAware: UserAwareMode): string {
        const loggedUser = this.authService.getCurrentUserData();
        if (!loggedUser && userAware === true) {
            throw new UserNotLoggedError();
        }
        return loggedUser ? `:${loggedUser.id}` : '';
    }
}
