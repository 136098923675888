//USER
export const adminV2UserOverview = 'overview';
export const adminV2UserProfile = 'profile';
export const adminV2UserOrganization = 'organization';
export const adminV2UserPrivacy = 'privacy';
export const adminV2UserGroups = 'groups';
export const adminV2UserRoles = 'roles';

// GROUP
export const adminV2GroupOverview = 'overview';
export const adminV2GroupInfo = 'info';
export const adminV2GroupUsers = 'users';
export const adminV2GroupRoles = 'roles';

// ORGANIZATION
export const adminV2OrganizationArea = 'area';
export const adminV2OrganizationBusinessUnit = 'business-unit';
export const adminV2OrganizationOverview = 'overview';
export const adminV2OrganizationSettings = 'settings';
export const adminV2OrganizationUsers = 'users';

// WORKSPACE
export const adminV2WorkspaceOverview = 'overview';
export const adminV2WorkspaceSettings = 'settings';
export const adminV2WorkspaceAdmins = 'administrators';
export const adminV2WorkspaceMembers = 'members';
export const adminV2WorkspaceCommunities = 'communities';

// COMMUNITY
export const adminV2CommunityOverview = 'overview';
export const adminV2CommunityInfo = 'info';
export const adminV2CommunitySettings = 'settings';
export const adminV2CommunityPostFields = 'post-fields';
export const adminV2CommunityAcknowledgment = 'acknowledgment';
export const adminV2CommunityRoles = 'roles';
export const adminV2CommunityWorkflow = 'workflow';
export const adminV2CommunityHashtags = 'hashtags';

// CATALOG
export const adminV2CatalogOverview = 'overview';
export const adminV2CatalogInfo = 'info';
export const adminV2CatalogCommunities = 'communities';
export const adminV2CatalogEntries = 'entries';

// LANGUAGE MANAGER
export const adminV2LanguagesManagerCommunity = 'community';
export const adminV2LanguagesManagerWorkspace = 'workspace';
export const adminV2LanguagesManagerCatalog = 'catalog';
export const adminV2LanguagesManagerRoles = 'roles';
