<div
    *ngIf="{
        selectorWidth: selectorWidth$ | async
    } as $"
    class="border-border-300 relative flex border-solid"
    [ngClass]="{
        'flex-col border-r': direction === 'vertical',
        'border-b': direction === 'horizontal'
    }"
>
    <div
        *ngFor="let tab of tabs"
        #tabs
        class="flex min-w-0 shrink-0 text-center"
        [ngClass]="{
            'h-28 justify-start': direction === 'vertical',
            'flex-col justify-center': direction === 'horizontal',
            'flex-1': size === 'auto',
            'w-180': direction === 'horizontal' && size === 'regular',
            'w-156': direction === 'horizontal' && size === 'small',
            'h-40': direction === 'vertical' && size === 'regular',
            'h-28': direction === 'vertical' && size === 'small'
        }"
    >
        <button
            class="typo-xs-bold-uppercase relative flex w-full items-center px-24"
            type="button"
            [ngClass]="{
                'h-28 justify-start': direction === 'vertical',
                'flex-col': direction === 'horizontal' && variant === 'images',
                'items-start justify-center ': direction === 'horizontal',
                'py-8': direction === 'horizontal' && !tab.imageSrc,
                'pb-2 pt-8': direction === 'horizontal' && tab.imageSrc,
                'w-180': direction === 'horizontal' && size === 'regular',
                'w-156': direction === 'horizontal' && size === 'small',
                'h-40': direction === 'vertical' && size === 'regular',
                'h-28': direction === 'vertical' && size === 'small',
                'text-text-primary': tab.selected,
                'text-text-low-contrast': !tab.selected,
                'bg-surface-A rounded-t-2xl':
                    variant === 'images' && tab.selected
            }"
            (click)="changeTab.emit(tab.id)"
        >
            <div *ngIf="tab.imageSrc" class="h-20 w-20 shrink-0">
                <img
                    class="mr-4 h-16 w-16"
                    alt="{{ tab.label }}'s' image"
                    [src]="tab.imageSrc"
                />
            </div>

            <div class="relative flex">
                <span
                    [matTooltip]="tab.label"
                    [matTooltipDisabled]="size !== 'auto'"
                    [matTooltipShowDelay]="1500"
                    [ngClass]="{ 'line-clamp-1': size === 'auto' }"
                    >{{ tab.label }}</span
                >
                <span *ngIf="tab.counter !== null">
                    &nbsp;({{
                        size !== 'auto' && tab.counter > 999
                            ? '999+'
                            : tab.counter
                    }})</span
                >
                <div
                    *ngIf="tab.badge"
                    class="bg-surface-primary ng-star-inserted absolute -right-12 top-0 h-8 w-8 rounded-full"
                ></div>
            </div>
            <interacta-icon
                *ngIf="tab.icon"
                class="ml-8 h-20 w-20"
                [icon]="tab.icon"
            >
            </interacta-icon>
        </button>
    </div>
    <span
        *ngIf="selectedIndex !== -1"
        class="bg-surface-primary absolute rounded transition-transform"
        [ngClass]="{
            'right-0 w-4': direction === 'vertical',
            '-bottom-3 h-4 ': direction === 'horizontal',
            'w-180': direction === 'horizontal' && size === 'regular',
            'w-156': direction === 'horizontal' && size === 'small',
            'h-40': direction === 'vertical' && size === 'regular',
            'h-28': direction === 'vertical' && size === 'small'
        }"
        [ngStyle]="{
            transform:
                direction === 'horizontal'
                    ? 'translateX(' + 100 * selectedIndex + '%)'
                    : 'translate(63%,' + 100 * selectedIndex + '%)'
        }"
        [style.width.px]="size === 'auto' ? $.selectorWidth : undefined"
    ></span>
</div>
