<div
    class="bg-surface-100 absolute"
    [ngClass]="{
        'rounded-lg border': appearance === 'border'
    }"
    [style.height.px]="height"
    [style.width.px]="width"
    (dragleave)="isMouseOver.emit(false)"
    (dragover)="isMouseOver.emit(true)"
    (drop)="isMouseOver.emit(false)"
>
    <div
        appearance="border"
        class="bg-surface-100 pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        icon="arrow-up"
        interacta-button
        size="regular"
        [bgColor]="'textColor'"
        [label]="'DASHBOARD.ATTACHMENTS.DRAG_DROP_LABEL' | translate"
    ></div>
</div>
