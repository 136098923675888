import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, merge } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'interacta-other-filters-text',
    templateUrl: './other-filters-text.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherFiltersTextComponent implements OnChanges, OnInit, OnDestroy {
    @Input() label = '';
    @Input() description = '';
    @Input() value = '';
    @Input() collapsed = false;
    @Input() iconSearch = false;
    @Input() pinned?: boolean;
    @Input() pinnable?: boolean;
    @Input() disabled = false;

    @Output() togglePinned = new EventEmitter();
    @Output() toggleCollapsed = new EventEmitter();
    @Output() emitSearch = new EventEmitter<string>();

    control = new UntypedFormControl();
    enterPressed$ = new Subject<void>();

    private destroy$ = new Subject<void>();

    ngOnInit(): void {
        merge(
            this.enterPressed$.pipe(
                map(() => this.control.value),
                filter((value) => value != null),
            ),
            this.control.valueChanges.pipe(
                debounceTime(1000),
                filter(
                    (search) =>
                        search !== this.value &&
                        (!search.length || search.length > 2),
                ),
            ),
        )
            .pipe(takeUntil(this.destroy$))
            .subscribe((value: string) => this.emitSearch.emit(value));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.control.setValue(this.value ?? '', { emitEvent: false });
        }

        if (changes.disabled) {
            this.disabled ? this.control.disable() : this.control.enable();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    resetInput(): void {
        this.control.setValue('');
    }
}
