import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import {
    Index,
    PaginatedList,
    fetchPaginatedList,
    fetchPaginatedListSuccess,
    fromPageToken,
} from '@interacta-shared/util';

import { InputSelectSearchEvent } from '@interacta-shared/ui';
import { formatDate } from '@interacta-shared/util-common';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, map, tap, withLatestFrom } from 'rxjs';
import {
    AdminV2Date,
    AdminV2FilterDateOptions,
    AdminV2FiltersByDateType,
    isAdminV2Date,
} from '../models/admin-v2-date.model';
import {
    AdminV2PaginatedColConfig,
    AdminV2PaginatedFilters,
    AdminV2PaginatedListRow,
} from '../models/admin-v2-paginated.model';
import { typedObjectKeys } from './admin-v2-utils';

export type AdminV2FilterOptions = {
    id: number;
    label: string;
}[];

export type AdminV2VisibleCols<T> = { [key in keyof Partial<T>]: boolean };

interface AdminV2FilterBoolean {
    type: 'boolean';
    value: number | null;
}
interface AdminV2FilterString {
    type: 'string';
    value: string | null;
}
interface AdminV2FilterMultiple<T extends { id: Index }> {
    type: 'multiple';
    value: T[] | number[];
}
interface AdminV2FilterDate {
    type: 'date';
    value: AdminV2FiltersByDateType | null;
}
export type AdminV2Filter<T extends { id: Index }> =
    | AdminV2FilterBoolean
    | AdminV2FilterString
    | AdminV2FilterMultiple<T>
    | AdminV2FilterDate;

export const parseFilter = <T extends { id: Index }>(
    filter: AdminV2Filter<T>,
): string | boolean | number[] | AdminV2Date | T[] | null => {
    if (filter.value !== null) {
        switch (filter.type) {
            case 'boolean':
                return !!filter.value;
            case 'date':
                return <AdminV2Date>{
                    from: filter.value.fromDate ?? null,
                    to: filter.value.toDate ?? null,
                    type: filter.value.type,
                };
            case 'multiple':
            case 'string':
                return filter.value.length ? filter.value : null;
            default:
                return null;
        }
    }
    return null;
};

export const getFlagFilterValue = (value?: boolean | null): number | null => {
    if (value === true) {
        return 1;
    } else if (value === false) {
        return 0;
    }
    return null;
};

export const computeStringLabel = <T, L extends Record<keyof T, string>>(
    key: keyof T,
    filters: T,
    labels: L,
    translateService: TranslateService,
): string | null => {
    const value = filters[key];
    if (!value) return null;
    if (key === 'status') {
        const status = value as keyof L;
        const filterLabel = labels[status];
        return translateService.instant(filterLabel);
    }
    return `${translateService.instant(labels[key])}: ${value}`;
};

export const computeMultipleLabel = <T, L extends Record<keyof T, string>>(
    key: keyof T,
    value: string[],
    labels: L,
    translateService: TranslateService,
): string | null => {
    if (value.length === 0) return null;
    return `${translateService.instant(labels[key])}: ${
        value.length > 1
            ? translateService.instant(
                  'ADMIN_V2.SHARED.FILTERS.CUSTOM_FIELD_MULTISELECT_BANNER',
                  { counter: value.length },
              )
            : value[0]
    }`;
};

export const computeDateLabel = <T, L extends Record<keyof T, string>>(
    key: keyof T,
    val: AdminV2Date | undefined,
    labels: L,
    translateService: TranslateService,
    configurationService: ConfigurationService,
): string | null => {
    if (!val) return null;
    const date = val as AdminV2Date;
    let value = '';
    const label = `${translateService.instant(labels[key])}: `;
    switch (date.type) {
        case AdminV2FilterDateOptions.TODAY:
            value = 'ADMIN_V2.SHARED.DATE.TODAY';
            break;
        case AdminV2FilterDateOptions.YESTERDAY:
            value = 'ADMIN_V2.SHARED.DATE.YESTERDAY';
            break;
        case AdminV2FilterDateOptions.LAST_7_DAYS:
            value = 'ADMIN_V2.SHARED.DATE.LAST_7_DAYS';
            break;
        case AdminV2FilterDateOptions.LAST_30_DAYS:
            value = 'ADMIN_V2.SHARED.DATE.LAST_30_DAYS';
            break;
    }
    if (value !== '') {
        return `${label}${translateService.instant(value)}`;
    }
    if (!date.from && !date.to) return null;
    return `${label}${
        date.from
            ? `${translateService.instant(
                  'ADMIN_V2.SHARED.FILTERS.FROM_DATE',
              )} ${formatDate(
                  date.from,
                  'date',
                  configurationService.getCurrentLanguage().code,
              )} `
            : ''
    }${
        date.to && date.from
            ? `${translateService.instant(
                  'ADMIN_V2.SHARED.FILTERS.TO_DATE_CUSTOM_BANNER',
              )} ${formatDate(
                  date.to,
                  'date',
                  configurationService.getCurrentLanguage().code,
              )}`
            : date.to
              ? `${translateService.instant(
                    'ADMIN_V2.SHARED.FILTERS.TO_DATE',
                )} ${formatDate(
                    date.to,
                    'date',
                    configurationService.getCurrentLanguage().code,
                )}`
              : ''
    }`;
};

export const computeBooleanLabel = <T, L extends Record<keyof T, string>>(
    key: keyof T,
    filters: T,
    labels: L,
    labelTrue: string,
    labelFalse: string,
    translateService: TranslateService,
): string | null => {
    const value = filters[key];
    if (value === undefined) return null;
    return `${translateService.instant(
        labels[key],
    )}: ${translateService.instant(value ? labelTrue : labelFalse)}`;
};

export const countNonEmptyFilters = <T>(
    filters: Partial<T>,
    defaultStatusFilter?: string,
): number => {
    let count = 0;
    for (const key of typedObjectKeys(filters)) {
        const filter = filters[key];
        if (isAdminV2Date(filter)) {
            if (filter?.from || filter?.to) {
                count++;
            }
        } else if (key === 'status') {
            if (defaultStatusFilter && filter !== defaultStatusFilter) {
                count++;
            }
        } else if (key !== 'search' && filter !== undefined && filter !== '') {
            count++;
        }
    }
    return count;
};

export const filterIsEmpty = <T>(
    filters: Partial<T>,
    defaultStatusFilter?: string,
): boolean => {
    for (const key of typedObjectKeys(filters)) {
        const filter = filters[key];
        if (isAdminV2Date(filter)) {
            if (filter?.from || filter?.to) {
                return false;
            }
        } else if (key === 'status') {
            if (defaultStatusFilter && filter !== defaultStatusFilter) {
                return false;
            }
        } else if (filter !== undefined && filter !== '') {
            return false;
        }
    }
    return true;
};

export const fromAdminV2Date = (
    date?: AdminV2Date,
): { from?: number; to?: number } => ({
    from: date?.from ? date.from.getTime() : undefined,
    to: date?.to ? date.to.getTime() : undefined,
});

export const getSelectedRowsIds = (
    selectedRows: Record<AdminV2PaginatedListRow['id'], boolean>,
): number[] =>
    Object.keys(selectedRows)
        .filter((key: any) => selectedRows[key])
        .map(Number);

export const getColsOptions = <T>(
    cols: AdminV2PaginatedColConfig<T>,
): {
    label: string;
    value: keyof T;
}[] =>
    typedObjectKeys(cols)
        .filter((col) => cols[col]?.sortable)
        .map((col) => ({
            label: cols[col]?.label ?? '',
            value: col,
        }));

export const fetchNextPageFromInputSearch = <T, F, R>(
    inputFilters: InputSelectSearchEvent,
    currentFilters: F,
    list$: BehaviorSubject<PaginatedList<T>>,
    fetchList: (
        filters: F,
        paginatedFilters?: AdminV2PaginatedFilters,
    ) => Observable<PaginatedList<R>>,
    mapRow: (row: R) => T,
    pageSize?: number,
): Observable<void> => {
    list$.next(
        fetchPaginatedList(
            list$.value,
            inputFilters.nextPageToken ?? undefined,
        ),
    );

    const { filters, paginatedFilters } = fromInputSelectSearchEvent(
        inputFilters,
        currentFilters,
        pageSize,
    );

    return fetchList(filters, paginatedFilters).pipe(
        withLatestFrom(list$),
        map(([nextList, currList]) =>
            fetchPaginatedListSuccess(currList, {
                ...nextList,
                list: nextList.list.map(mapRow),
            }),
        ),
        tap((list) => list$.next(list)),
        map(() => void 0),
    );
};

export const fromInputSelectSearchEvent = <T>(
    inputFilters: InputSelectSearchEvent,
    currentFilters: T,
    pageSize = 10,
): {
    filters: T;
    paginatedFilters: AdminV2PaginatedFilters;
} => ({
    filters: {
        ...currentFilters,
        search: inputFilters.text ?? '',
    },
    paginatedFilters: {
        pageToken: fromPageToken(inputFilters.nextPageToken ?? undefined),
        pageSize,
    },
});

export const showClearFilters = <T>({
    filters,
    statusFilter,
}: {
    filters: Partial<T>;
    statusFilter?: string;
}): boolean => {
    const filtersKey = typedObjectKeys(filters);
    return (
        filtersKey.length > 0 &&
        !(
            statusFilter &&
            'status' in filtersKey &&
            filtersKey.status === statusFilter
        )
    );
};
