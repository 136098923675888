import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { formatDateUsingIntl } from '@interacta-shared/util-common';
import { TranslateService } from '@ngx-translate/core';
import {
    differenceInHours,
    differenceInMinutes,
    format,
    isThisWeek,
    isThisYear,
    isTomorrow,
    isYesterday,
    millisecondsToHours,
} from 'date-fns';
import { Observable, concat, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

/**
 * See https://injenia.atlassian.net/l/cp/r6L3KnZu
 *
 */
@Pipe({
    name: 'interactaDateDistance$',
})
export class InteractaDateDistancePipe implements PipeTransform {
    constructor(
        private configurationService: ConfigurationService,
        private translateService: TranslateService,
    ) {}

    transform(date: Date | undefined): Observable<string> {
        return this.configurationService
            .getLocaleDateFns()
            .pipe(
                switchMap((locale) =>
                    concat(
                        of(this.formatDateToNow(locale, date)),
                        timer(0, 60_000).pipe(
                            map(() => this.formatDateToNow(locale, date)),
                        ),
                    ),
                ),
            );
    }

    private formatDateToNow(locale: Locale, date: Date | undefined): string {
        if (date) {
            const now = new Date();
            const msDiff = now.getTime() - date.getTime();
            const hours = millisecondsToHours(Math.abs(msDiff));

            if (hours < 1) {
                return this.minutes(date);
            }

            if (hours < 24) {
                return this.hours(date);
            }

            if (isYesterday(date)) {
                return this.yesterdayAt(locale, date);
            } else if (isTomorrow(date)) {
                return this.tomorrowAt(locale, date);
            }

            if (isThisWeek(date)) {
                return this.dayOfWeekAndTime(locale, date);
            }

            if (isThisYear(date)) {
                return this.dayOfTheMonthAndTime(locale, date);
            }

            return this.dayOfTheMonthAndYear(locale, date);
        } else {
            return '';
        }
    }

    private minutes(date: Date): string {
        const minutes = Math.max(
            1,
            Math.abs(differenceInMinutes(new Date(), date)),
        );
        const label: string = this.translateService.instant(
            minutes === 1
                ? 'DATE_FORMAT.POST.MINUTE_SHORT_SING'
                : 'DATE_FORMAT.POST.MINUTE_SHORT_PLUR',
        );

        return `${minutes} ${label}`;
    }

    private hours(date: Date): string {
        const hours = Math.max(
            1,
            Math.abs(differenceInHours(new Date(), date)),
        );
        const label: string = this.translateService.instant(
            hours === 1
                ? 'DATE_FORMAT.POST.HOUR_SING'
                : 'DATE_FORMAT.POST.HOUR_PLUR',
        );

        return `${hours} ${label}`;
    }

    private yesterdayAt(locale: Locale, date: Date): string {
        const time = format(date, 'p', { locale });
        const label: string = this.translateService.instant(
            'DATE_FORMAT.POST.YESTERDAY',
        );

        return `${label} ${time}`;
    }

    private tomorrowAt(locale: Locale, date: Date): string {
        const time = format(date, 'p', { locale });
        const label: string = this.translateService.instant(
            'DATE_FORMAT.POST.TOMORROW',
        );

        return `${label} ${time}`;
    }

    private dayOfWeekAndTime(locale: Locale, date: Date): string {
        return format(date, 'eee p', { locale });
    }

    private dayOfTheMonthAndTime(locale: Locale, date: Date): string {
        if (!locale.code) {
            throw new Error("locale.code can't be null");
        }
        // Can't format without the year using date-fns
        const day = formatDateUsingIntl(
            date,
            {
                month: 'short',
                day: 'numeric',
            },
            'PP',
            locale.code,
        );

        const time = format(date, 'p', { locale });

        return `${day} ${time}`;
    }

    private dayOfTheMonthAndYear(locale: Locale, date: Date): string {
        return format(date, 'PP', { locale });
    }
}
