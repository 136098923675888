import { Pipe, PipeTransform } from '@angular/core';
import {
    IInputTimeV2Value,
    TimeFormat,
} from '../components/input-time-v2/input-time-v2.component';

@Pipe({
    standalone: true,
    name: 'formatInputTime',
})
export class FormatInputTimePipe implements PipeTransform {
    transform(time: IInputTimeV2Value, timeFormat: TimeFormat = '24H'): string {
        if (timeFormat === '12H') {
            const am = time.hours <= 12;
            const hours = am ? time.hours : time.hours - 12;
            return `${this.pad(hours)}:${this.pad(time.minutes)} ${
                am ? 'AM' : 'PM'
            }`;
        } else {
            return `${this.pad(time.hours)}:${this.pad(time.minutes)}`;
        }
    }

    private pad(s: number): string {
        return s < 10 ? `0${s}` : `${s}`;
    }
}
