<div class="bg-surface-A w-full rounded-lg">
    <div
        *ngIf="showFastSelect"
        class="rounded-l-20 rounded-r-20 border-border-500 text-text-mid-contrast flex border-2"
    >
        <button
            class="typo-xs-bold-uppercase flex-1 py-8 text-center"
            type="button"
            (click)="fastSelectClick('yesterday')"
        >
            {{ 'UI.DATE_TIME.YESTERDAY' | translate }}
        </button>
        <button
            class="typo-xs-bold-uppercase border-border-500 flex-1 border-l-2 border-r-2 py-8 text-center"
            type="button"
            (click)="fastSelectClick('today')"
        >
            {{ 'UI.DATE_TIME.TODAY' | translate }}
        </button>
        <button
            class="typo-xs-bold-uppercase flex-1 py-8 text-center"
            type="button"
            (click)="fastSelectClick('tomorrow')"
        >
            {{ 'UI.DATE_TIME.TOMORROW' | translate }}
        </button>
    </div>
    <div class="flex flex-col">
        <mat-calendar
            class="w-282"
            [maxDate]="_maxDate || null"
            [minDate]="_minDate || null"
            [selected]="control.value"
            [startAt]="control.value || now"
            (selectedChange)="selectDate($event)"
        >
        </mat-calendar>

        <interacta-input-time-v2
            *ngIf="type === 'date-time'"
            class="mt-12"
            [editTimezone]="editTimezone"
            [formControl]="timeControl"
            [maxTime]="_maxDate"
            [minTime]="_minDate"
            [readonly]="stepMode === 'quarter'"
            [selectedDate]="selectedDate"
            [stepMode]="stepMode"
            (timeChange)="updateTime($event)"
        >
        </interacta-input-time-v2>

        <interacta-input-timezone
            *ngIf="editTimezone"
            class="mt-12"
            [control]="timezoneControl"
            [timezones]="timezones"
            [userTimezone]="userTimezone"
        ></interacta-input-timezone>
    </div>
</div>
