import { Index } from '@interacta-shared/util';
import { AttachmentEntityType } from '@modules/post/models/filter-attachment.model';
import { Delta } from 'quill/core';

export enum AIRole {
    USER = 'USER',
    MODEL = 'MODEL',
}

export enum AIMessageType {
    ATTACHMENT = 'ATTACHMENT',
    STANDARD = 'STANDARD',
    ERROR = 'ERROR',
    EXAMPLE = 'EXAMPLE',
    TOO_MANY_REQUESTS_ERROR = 'TOO_MANY_REQUESTS_ERROR',
    AI_EMPTY_ANSWER = 'AI_EMPTY_ANSWER',
    SAFETY_REASON_DANGEROUS_CONTENT = 'SAFETY_REASON_DANGEROUS_CONTENT',
    SAFETY_REASON_HARASSMENT = 'SAFETY_REASON_HARASSMENT',
    SAFETY_REASON_HATE_SPEECH = 'SAFETY_REASON_HATE_SPEECH',
    SAFETY_REASON_SEXUALLY_EXPLICIT = 'SAFETY_REASON_SEXUALLY_EXPLICIT',
    SAFETY_REASON_UNSPECIFIED = 'SAFETY_REASON_UNSPECIFIED',
    SAFETY_REASON_UNRECOGNIZED = 'SAFETY_REASON_UNRECOGNIZED',
    UNSUPPORTED_TYPE = 'UNSUPPORTED_TYPE',
    FETCH_FILE_ERROR = 'FETCH_FILE_ERROR',
}

export type AttachmentsListType = Extract<
    AttachmentEntityType,
    'post' | 'comment' | 'postFilePicker'
>;

export enum AIContextType {
    POST = 'POST',
    COMMUNITY = 'COMMUNITY',
    GUIDE = 'GUIDE',
    CONTENT_GEN = 'CONTENT_GEN',
}

export enum AIContentGenerationType {
    SUMMARIZE = 1,
    EXPAND = 2,
    TONE_OF_VOICE = 3,
    // Yet to be implemented
    // CORRECT = 4,
    // TRANSLATE = 5,
}

export enum AIToneOfVoiceType {
    FORMAL = 'FORMAL',
    CASUAL = 'CASUAL',
    NEUTRAL = 'NEUTRAL',
    PERSUASIVE = 'PERSUASIVE',
    EXCITED = 'EXCITED',
    EMPATHETIC = 'EMPATHETIC',
    AUTHORITARIAN = 'AUTHORITARIAN',
    FRIENDLY = 'FRIENDLY',
    CONFIDENT = 'CONFIDENT',
    POSITIVE = 'POSITIVE',
}

export const MAIN_THREAD = 'MAIN';

export interface AIMessage {
    id: number;
    role: AIRole;
    message: string;
    messageType: AIMessageType;
    timestamp: Date;
    alreadyAnimated?: boolean;
    like?: boolean;
    feedbacks?: AIFeedbackDetailType[];
    feedbackText?: string;
    attachmentId?: Index;
}
export interface HistoryThread {
    [threadId: Index]: {
        title: string | null;
        hasErrors?: boolean;
        isFetching: boolean;
        history: HistoryMap;
    };
}

export interface HistoryMap {
    [messageId: string]: AIMessage;
}

export interface AIResponse {
    answer: AIMessage;
    sessionId: number;
    questionId: number;
    clientId: string;
}

export interface AIContentGenerationResponseDetail {
    text: Delta;
    toneType?: AIToneOfVoiceType;
}
export interface AIContentGenerationResponse {
    contents: AIContentGenerationResponseDetail[];
}

export enum AIFeedbackDetailType {
    CORRECT = 'CORRECT',
    COMPLETE = 'COMPLETE',
    INSPIRATION = 'INSPIRATION',
    SAVED_TIME = 'SAVED_TIME',
    NICE_INTERACTION = 'NICE_INTERACTION',
    NOT_CORRECT = 'NOT_CORRECT',
    NOT_COMPLETE = 'NOT_COMPLETE',
    UNCLEAR = 'UNCLEAR',
    SLOW_INTERACTION = 'SLOW_INTERACTION',
    HARD_INTERACTION = 'HARD_INTERACTION',
    OFFENSIVE = 'OFFENSIVE',
}

export interface FeedbackChip {
    id: AIFeedbackDetailType;
    label: string;
    selected: boolean;
}

export const positiveChips: FeedbackChip[] = [
    {
        id: AIFeedbackDetailType.CORRECT,
        label: 'AI.FEEDBACK.POSITIVE.CORRECT_AND_STRAIGHT_TO_THE_POINT',
        selected: false,
    },
    {
        id: AIFeedbackDetailType.COMPLETE,
        label: 'AI.FEEDBACK.POSITIVE.COMPLETE',
        selected: false,
    },
    {
        id: AIFeedbackDetailType.INSPIRATION,
        label: 'AI.FEEDBACK.POSITIVE.INSPIRING',
        selected: false,
    },
    {
        id: AIFeedbackDetailType.SAVED_TIME,
        label: 'AI.FEEDBACK.POSITIVE.TIME_SAVING',
        selected: false,
    },
    {
        id: AIFeedbackDetailType.NICE_INTERACTION,
        label: 'AI.FEEDBACK.POSITIVE.PLEASING',
        selected: false,
    },
];

export const negativeChips: FeedbackChip[] = [
    {
        id: AIFeedbackDetailType.NOT_CORRECT,
        label: 'AI.FEEDBACK.NEGATIVE.INCORRECT',
        selected: false,
    },
    {
        id: AIFeedbackDetailType.NOT_COMPLETE,
        label: 'AI.FEEDBACK.NEGATIVE.INCOMPLETE',
        selected: false,
    },
    {
        id: AIFeedbackDetailType.UNCLEAR,
        label: 'AI.FEEDBACK.NEGATIVE.NOT_CLEAR',
        selected: false,
    },
    {
        id: AIFeedbackDetailType.SLOW_INTERACTION,
        label: 'AI.FEEDBACK.NEGATIVE.SLOW',
        selected: false,
    },
    {
        id: AIFeedbackDetailType.HARD_INTERACTION,
        label: 'AI.FEEDBACK.NEGATIVE.DIFFICULT',
        selected: false,
    },
    {
        id: AIFeedbackDetailType.OFFENSIVE,
        label: 'AI.FEEDBACK.NEGATIVE.OFFENSIVE',
        selected: false,
    },
];

export const supportedAttachmentMimeTypes = [
    'application/pdf',
    'audio/mpeg',
    'audio/mp3',
    'audio/wav',
    'image/png',
    'image/jpeg',
    'text/plain',
    'video/quicktime',
    'video/mpeg',
    'video/mp4',
    'video/mpg',
    'video/avi',
    'video/x-ms-wmv',
    'video/mpegps',
    'video/x-flv',
];
