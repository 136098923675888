import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@interacta-shared/data-access-configuration';
import {
    CommunityDetail,
    CommunityListFilter,
    CommunityTreeDeserialize,
    fromCommunityDashboardPreferences,
    fromCommunityManagerFilter,
    ICommunity,
    ICommunityCapabilities,
    ICommunityDashboardPreferences,
    ICommunityTree,
} from '@modules/communities/models/communities.model';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import {
    toQuickFilter,
    toQuickFiltersList,
} from '@modules/post/models/quick-filters/quick-filters.deserialize';

import { IList, Index } from '@interacta-shared/util';
import {
    IOperativeRole,
    UserDeserilize,
    UserExtendedDeserialize,
} from '@modules/core';
import {
    QuickFilter,
    QuickFilterFromServer,
    QuickFilterListFromServer,
    QuickFiltersList,
} from '@modules/post/models/quick-filters/quick-filters.model';
// IMPORT FROM INTERACTA MODULE
import { ConsoleService } from '@interacta-shared/data-access-configuration';
import {
    isDefined,
    PaginatedList,
    paginatedListFromIList,
    toPaginatedList,
} from '@interacta-shared/util';
import { toMemberItem } from '@modules/core/models/member/member.deserialize';
import { Member } from '@modules/core/models/member/member.model';
import { wrapMemberPaginatedList } from '@modules/core/models/member/member.utils';
import {
    IGenericCommunityFilter,
    OperationalRolesSearchRequest,
} from '@modules/core/models/user-autocomplete.model';
import { IPostMetadata } from '@modules/post/models/base-post.model';
import { CustomPostDeserialize } from '@modules/post/models/custom-post.model';
import {
    fromPartialQuickFilter,
    fromQuickFilter,
    fromQuickFiltersOrder,
} from '@modules/post/models/quick-filters/quick-filters.serialize';
import { isSystemQuickFilter } from '@modules/post/models/quick-filters/quick-filters.util';
import { CommunitiesState } from '@modules/state/models/communities-state.model';
import { StateService } from '@modules/state/services/state.service';
import { from, iif, Observable, of } from 'rxjs';
import {
    catchError,
    filter,
    first,
    map,
    mergeMap,
    toArray,
    withLatestFrom,
} from 'rxjs/operators';
import { toHashtag } from '../models/hashtag/hashtag.deserialize';
import { hashtagList } from '../models/hashtag/hashtag.utils';
import { IWorkspace } from '../models/workspace.model';
import { WorkspaceService } from './workspace.service';

type OrderedWorkspace = { workspace: IWorkspace; position: number };

@Injectable({ providedIn: 'root' })
export class CommunitiesService {
    private readonly baseUrl = `${inject(ENVIRONMENT).apiBasePath.common}/internal/v2/communication`;
    private readonly baseUrlSettings = `${this.baseUrl}/settings`;
    private readonly baseUrlPostFilters = `${this.baseUrlSettings}/preferences/post-lists-filters`;
    private readonly baseUrlAdmin = `${inject(ENVIRONMENT).apiBasePath.common}/admin/data`;
    private readonly consoleService = inject(ConsoleService);
    private readonly http = inject(HttpClient);
    private readonly appState = inject(StateService);
    private readonly workspaceService = inject(WorkspaceService);
    private communitiesState: CommunitiesState = this.appState.communitiesState;

    getCommunityTree(): Observable<ICommunityTree> {
        return this.http
            .get(`${this.baseUrlSettings}/organization-tree`)
            .pipe(
                map((res: any) => CommunityTreeDeserialize.communityTree(res)),
            );
    }

    /* Unless you need a fresh value, you probably want to use CommunitiesStateService.getAdminCommunityTree*/
    adminCommunityTree(loadCapabilities = true): Observable<ICommunityTree> {
        return this.http
            .get<any>(`${this.baseUrlAdmin}/admin-organization-tree`)
            .pipe(
                map((res: any) => CommunityTreeDeserialize.communityTree(res)),
                mergeMap((communityTree) =>
                    iif(
                        () => loadCapabilities,
                        from(communityTree.workspaceList).pipe(
                            map(
                                (workspace: IWorkspace, index: number) =>
                                    ({
                                        workspace,
                                        position: index,
                                    }) as OrderedWorkspace,
                            ),
                            mergeMap(({ workspace, position: index }) =>
                                this.workspaceService
                                    .getWorkspaceCapabilities(workspace.id)
                                    .pipe(
                                        map(
                                            (capabilities) =>
                                                ({
                                                    workspace: {
                                                        ...workspace,
                                                        capabilities,
                                                    },
                                                    position: index,
                                                }) as OrderedWorkspace,
                                        ),
                                    ),
                            ),
                            toArray(),
                            map((orderedWsList: OrderedWorkspace[]) =>
                                orderedWsList
                                    .sort((a, b) => a.position - b.position)
                                    .map((item) => item.workspace),
                            ),
                            map((workspaceList: IWorkspace[]) => ({
                                ...communityTree,
                                workspaceList,
                            })),
                        ),
                        of(communityTree),
                    ),
                ),
            );
    }

    /**
     * Unless you need a fresh value, you probably want to use CommunitiesStateService.getInsightCommunities
     */
    getInsightCommunities(): Observable<ICommunityTree> {
        return this.http
            .get(`${this.baseUrl}/dashboard/organization-tree`)
            .pipe(
                map((res: any) => CommunityTreeDeserialize.communityTree(res)),
            );
    }

    updateCommunityDashboardPreferences(
        communityPreferences: ICommunityDashboardPreferences,
    ): Observable<ICommunityTree> {
        return this.http
            .put(
                `${this.baseUrlSettings}/preferences/dashboard-communities`,
                fromCommunityDashboardPreferences(communityPreferences),
            )
            .pipe(
                map((res: any) => CommunityTreeDeserialize.communityTree(res)),
            );
    }

    /**
     * Unless you need a fresh value, you probably want to use CommunitiesStateService.getCommunity
     */
    getCommunityDetails(
        communityId: number,
        loadCounters = false,
    ): Observable<CommunityDetail> {
        return this.http
            .get(`${this.baseUrlSettings}/communities/${communityId}/details`, {
                params: { loadCounters },
            })
            .pipe(
                map((result: any) =>
                    CommunityTreeDeserialize.communityDetails(result),
                ),
            );
    }

    /**
     * Unless you need a fresh value, you probably want to use CommunitiesStateService.getPostMetadata
     */
    getPostMetadata(communityId: number): Observable<IPostMetadata> {
        return this.http
            .get(
                `${this.baseUrlSettings}/communities/${communityId}/post-definition`,
            )
            .pipe(map((res: any) => CustomPostDeserialize.postMetadata(res)));
    }

    getCommunityAdminCapabilities(
        communityId: number,
    ): Observable<ICommunityCapabilities> {
        return this.http
            .get(
                `${this.baseUrlAdmin}/communities/${communityId}/admin-capabilities`,
            )
            .pipe(
                map((res: any) =>
                    CommunityTreeDeserialize.communityCapabilities(res),
                ),
            );
    }

    getCommunityOperationalCapabilities(
        communityId: number,
    ): Observable<ICommunityCapabilities> {
        return this.http
            .get(
                `${this.baseUrlSettings}/communities/${communityId}/operational-capabilities`,
            )
            .pipe(
                map((res: any) =>
                    CommunityTreeDeserialize.communityCapabilities(res),
                ),
            );
    }

    getCommunityAdmin(
        communityId: number,
        filter?: IGenericCommunityFilter,
    ): Observable<PaginatedList<Member>> {
        return this.http
            .post<any>(
                `${this.baseUrlSettings}/communities/${communityId}/managers`,
                fromCommunityManagerFilter(filter),
            )
            .pipe(
                map((res) => UserDeserilize.userList(res)),
                map((res) =>
                    wrapMemberPaginatedList(paginatedListFromIList(res)),
                ),
            );
    }

    getCommunityViewersList(
        communityId: number,
        filters?: IGenericCommunityFilter,
    ): Observable<PaginatedList<Member>> {
        return this.http
            .post(
                `${this.baseUrlSettings}/communities/${communityId}/complete-visibility-members`,
                fromCommunityManagerFilter(filters),
            )
            .pipe(
                map((res: any) => toPaginatedList(res)),
                map((res: PaginatedList<any>) => ({
                    ...res,
                    list: res.list.map(toMemberItem),
                })),
            );
    }

    getCommunityDetailHashtags(
        communityId: number,
        filters?: IGenericCommunityFilter,
    ): Observable<PaginatedList<IHashTag>> {
        return this.http
            .post(
                `${this.baseUrlSettings}/communities/${communityId}/hashtags`,
                fromCommunityManagerFilter(filters),
            )
            .pipe(
                map((res: any) => toPaginatedList(res)),
                map((res: PaginatedList<any>) => ({
                    ...res,
                    list: res.list.map(toHashtag),
                })),
            );
    }

    getCommunityHashtags(communityId: number): Observable<IList<IHashTag>> {
        return this.communitiesState.communityTree$.pipe(
            filter(isDefined),
            first(),
            map((tree) => tree.communityList.find((c) => c.id === communityId)),
            filter(isDefined),
            map(hashtagList),
        );
    }

    getRecentCommunities(): Observable<ICommunity[]> {
        return this.http
            .get<{
                communities: Array<any>;
            }>(`${this.baseUrlSettings}/recent-communities`)
            .pipe(
                map(({ communities }) => {
                    return communities.map((res) =>
                        CommunityTreeDeserialize.communityInfoDetails(res),
                    );
                }),
            );
    }

    getQuickFilters(): Observable<QuickFiltersList> {
        return this.http
            .get<{
                filters: QuickFilterListFromServer[] | null;
            }>(`${this.baseUrlPostFilters}`)
            .pipe(
                withLatestFrom(this.communitiesState.communityTree$),
                map(([res, communityTree]) =>
                    toQuickFiltersList(
                        res.filters,
                        communityTree?.communityList ?? [],
                    ),
                ),
                catchError((error) => {
                    this.consoleService.errorDev(error);
                    throw error;
                }),
            );
    }

    /**
     * This API doesn't set correctly selected showInBar and order property, so
     * we set it correctly when deserialized.
     *
     * @param filter Quick Filter to create
     * @returns created quick Filter
     */
    saveCustomFilter(filter: QuickFilter): Observable<QuickFilter> {
        return this.http
            .post<{
                filter: QuickFilterFromServer;
            }>(`${this.baseUrlPostFilters}/custom`, fromQuickFilter(filter))
            .pipe(
                withLatestFrom(this.communitiesState.communityTree$),
                map(([res, communityTree]) => {
                    const foundedCommunity =
                        communityTree?.communityList.find(
                            (c) => c.id === filter.filters.communityId,
                        ) ?? null;

                    return toQuickFilter(
                        {
                            ...res.filter,
                            showInBar: filter.showInBar,
                            order: filter.order,
                        },
                        foundedCommunity,
                    );
                }),
            );
    }

    deleteCustomFilter(
        filterId: Index,
        communityId: number | null,
    ): Observable<QuickFilter[]> {
        return this.http
            .delete<{
                filters: QuickFilterFromServer[];
            }>(`${this.baseUrlPostFilters}/custom/${filterId}`)
            .pipe(
                withLatestFrom(this.communitiesState.communityTree$),
                map(([res, communityTree]) => {
                    const foundedCommunity =
                        communityTree?.communityList.find(
                            (c) => c.id === communityId,
                        ) ?? null;
                    return res.filters.map((r) =>
                        toQuickFilter(r, foundedCommunity),
                    );
                }),
                catchError((error) => {
                    this.consoleService.errorDev(error);
                    throw error;
                }),
            );
    }

    modifyCustomFilter(filter: QuickFilter): Observable<QuickFilter> {
        return this.http
            .put<{
                filter: QuickFilterFromServer;
            }>(
                `${this.baseUrlPostFilters}/custom/${filter.id}`,
                fromPartialQuickFilter(filter),
            )
            .pipe(
                withLatestFrom(this.communitiesState.communityTree$),
                map(([res, communityTree]) => {
                    const foundedCommunity =
                        communityTree?.communityList.find(
                            (c) => c.id === filter.filters.communityId,
                        ) ?? null;

                    return toQuickFilter(res.filter, foundedCommunity);
                }),
            );
    }

    changeQuickFiltersOrder(
        communityId: number | null,
        quickFilters: QuickFilter[],
    ): Observable<QuickFiltersList> {
        return this.http
            .put<{
                filters: QuickFilterListFromServer[];
            }>(
                `${this.baseUrlPostFilters}/orders`,
                fromQuickFiltersOrder(communityId, quickFilters),
            )
            .pipe(
                withLatestFrom(this.communitiesState.communityTree$),
                map(([res, communityTree]) =>
                    toQuickFiltersList(
                        res.filters,
                        communityTree?.communityList ?? [],
                    ),
                ),
            );
    }

    initializeQuickFilters(
        communityId: number | null,
        initializeFilters: QuickFilter[] = [],
    ): Observable<QuickFiltersList> {
        return this.http
            .put<{ filters: QuickFilterListFromServer[] | null }>(
                `${this.baseUrlPostFilters}`,
                {
                    communityId,
                    client: 'web',
                    initFilters: initializeFilters
                        .filter(isSystemQuickFilter)
                        .map((f) => ({
                            order: f.order,
                            showInBar: f.showInBar,
                            typeId: f.type,
                        })),
                },
            )
            .pipe(
                withLatestFrom(this.communitiesState.communityTree$),
                map(([res, communityTree]) =>
                    toQuickFiltersList(
                        res.filters,
                        communityTree?.communityList ?? [],
                    ),
                ),
                catchError((error) => {
                    this.consoleService.errorDev(error);
                    throw error;
                }),
            );
    }

    /**
     * API recupero dati di testata Community
     */
    getCommunityBaseInfo(communityId: number): Observable<ICommunity> {
        return this.http
            .get<{
                community: any;
            }>(`${this.baseUrlSettings}/communities/${communityId}`)
            .pipe(
                map(({ community }) =>
                    CommunityTreeDeserialize.communityInfoDetails(community),
                ),
            );
    }

    /**
     * API recupero lista community operative
     */
    getCommunities(
        filters: CommunityListFilter,
    ): Observable<PaginatedList<ICommunity>> {
        return this.http
            .post<{
                items: any[];
                nextPageToken: string | null;
                totalItemsCount: number | null;
            }>(`${this.baseUrlSettings}/communities`, filters)
            .pipe(
                map((res) =>
                    toPaginatedList(
                        res,
                        CommunityTreeDeserialize.commonCommunityDetails,
                    ),
                ),
            );
    }

    /**
     * API recupero dettaglio ruoli operativi della community
     */
    getOperativeRoleById(
        communityId: number,
        roleId: number,
    ): Observable<IOperativeRole> {
        return this.http
            .get<{
                operationalRole: any;
            }>(
                `${this.baseUrlSettings}/communities/${communityId}/operational-roles/${roleId}`,
            )
            .pipe(
                map(({ operationalRole }) =>
                    UserExtendedDeserialize.operativeRole(operationalRole),
                ),
            );
    }

    /**
     * API recupero lista ruoli operativi della community
     */
    getCommunityOperativeRoleList(
        communityId: number,
        search: OperationalRolesSearchRequest,
    ): Observable<IList<IOperativeRole>> {
        return this.http
            .post<any>(
                `${this.baseUrlSettings}/communities/${communityId}/operational-roles`,
                search,
            )
            .pipe(
                map((res) => UserExtendedDeserialize.operationalRoleList(res)),
            );
    }

    /**
     * API per pinnare una community
     */
    setCommunityPinned(communityId: number, pinned: boolean): Observable<void> {
        return this.http.put<void>(
            `${this.baseUrl}/posts/manage/communities/${communityId}/pin`,
            null,
            { params: { pinned } },
        );
    }
}
