import { HttpHeaders } from '@angular/common/http';
import {
    defaultSettings,
    Language,
} from '@interacta-shared/data-access-configuration';
import { flatten, isDefined } from '@interacta-shared/util';
import produce from 'immer';
import { defer, merge, Observable, of, Subject } from 'rxjs';
import {
    concatMap,
    delay,
    filter,
    scan,
    shareReplay,
    startWith,
    switchMap,
    tap,
    throttleTime,
} from 'rxjs/operators';
import {
    i18nString,
    IDataForEditError,
    IFieldTranslation,
} from '../models/utility.model';
import { i18nDelta } from './../models/utility.model';
import { Delta2Server } from './delta/delta-2-server.class';

/**
 * Copy the values of all of the data own properties. This method doesn't cover all use cases.
 * It is possible use specified clone method for more flexible uses.
 * @param data if has a clone method it will be used
 */
export function clone(data: any): any {
    if (data && typeof data['clone'] === 'function') {
        return data.clone();
    }
    if (!data || 'object' !== typeof data) {
        return data;
    }
    // trik to clone object (array, boolean, string, etc...)
    // ------ NOT WORKING WITH MAP
    return JSON.parse(JSON.stringify(data));
}

/*
 * support translations labels for data come from server
 *
 * @param labelObj: has translations labels { {it: "ciao"}, {en: "hello"} } or only one of them (the default language)
 * @param currentLanguage: the language code 'it' or 'en'
 * @param defaultLanguage: the default language code (i.e. 'it') some case it is
 *                          possible that not exists translation for the currentLanguage
 */
export function getLabelServerTranslation(
    labelObj: i18nString,
    currentLanguage: string,
    defaultLanguage: string = defaultSettings.language.code,
): string | undefined {
    if (labelObj && typeof labelObj === 'object') {
        if (labelObj[currentLanguage]) {
            return labelObj[currentLanguage];
        }
        if (labelObj[defaultLanguage]) {
            return labelObj[defaultLanguage];
        }
        if (Object.keys(labelObj)) {
            // elsewhere take the first not null label
            labelObj = cleanEmptyi18nString(labelObj);
            return labelObj[Object.keys(labelObj)[0]];
        }
    } else if (typeof labelObj === 'string') {
        return labelObj;
    }
}

export const newID = function (): string {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};

export function isChristmasTime(): boolean {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentDay = today.getDate();

    // Dicembre o la prima settimana di gennaio fino all'epifania
    return currentMonth == 12 || (currentMonth === 1 && currentDay <= 6);
}

export function isOrLessIE11(): boolean {
    const ua = navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');
    if (msie > 0 || trident > 0) {
        return true;
    }
    // other browser
    return false;
}

export function isTouchScreen(): boolean {
    try {
        document.createEvent('TouchEvent');
        return true;
    } catch (e) {
        return false;
    }
}

export function collectionOfNameAllLanguages(
    arrayOfName: { id: number; name: any; deleted?: boolean }[],
): { id: number; name: any }[] {
    const collection: { id: number; name: any }[] = [];
    (arrayOfName || []).forEach((item) => {
        Object.keys(item.name).forEach((lang) => {
            if (!item.deleted) {
                collection.push({ id: item.id, name: item.name[lang] });
            }
        });
    });
    return collection;
}

export function compareWithId<T extends { id }>(c1: T, c2: T): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
}

export function searchFlexibleString(item: string, search: string): boolean {
    let matched = true;
    const searchToken: string[] = search.toLowerCase().split(' ');
    const itemToken = item.toLowerCase();
    let regExString = '';
    searchToken.forEach(
        (f) =>
            (regExString =
                regExString + '(?=.*?' + escapeRegExp(f.toString()) + ')'),
    );
    const regEx = new RegExp(regExString + '^.*$');
    matched = matched && regEx.test(itemToken);
    return matched;
}

function escapeRegExp(search: string) {
    return search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function dataForEditError(
    errorCode: number,
    labelNotExists = 'NOTIFICATION_MESSAGE.LABEL_PAGE_NOT_EXISTS',
    labelNotAvailable = 'NOTIFICATION_MESSAGE.LABEL_PAGE_NOT_AVAILABLE',
): IDataForEditError {
    let foundError: IDataForEditError;
    if (errorCode === 404) {
        foundError = {
            notExists: true,
            labelNotExists,
        } as IDataForEditError;
    } else if (errorCode === 403) {
        foundError = {
            notAvailable: true,
            labelNotAvailable,
        } as IDataForEditError;
    }
    return foundError;
}

export function getTranslationsFromObject(
    languages: Language[],
    defaultLanguage: Language,
    data: { [key: string]: string },
): IFieldTranslation[] {
    const translations: IFieldTranslation[] = [];
    languages.forEach((language) => {
        translations.push({
            value: data ? data[language.code] : null,
            language: language,
            isDefaultLanguage: defaultLanguage.code === language.code,
        });
    });
    return translations;
}

/**
 * Just a shortcut to create a deferred Observable starting with a default value
 */
export function deferWithDefault<T>(
    defObs: () => Observable<T>,
    default_: T,
): Observable<T> {
    return defer(defObs).pipe(startWith(default_));
}

export function zipWith<S, T, U>(
    x: S[],
    y: T[],
    zipper: (x: S, y: T) => U,
): U[] {
    return x.map((x1, i) => zipper(x1, y[i]));
}

/**
 * Keeps the last fetched data in memory for the given milliseconds.
 * After that time has elapsed a new fetch() will be called.
 * @param action$ the action which triggers the load event
 * @param fetch the fetching function
 * @param loader the subject which controls the loading state
 * @param milliseconds the milliseconds after which a new `fetch` can be called
 */
export function cacheUntil<T>(
    action$: Observable<boolean>,
    fetch: () => Observable<T>,
    loader: Subject<boolean>,
    milliseconds: number,
): Observable<T> {
    const fetch$ = of('').pipe(
        tap((_) => loader.next(true)),
        switchMap((_) => fetch()),
        tap((_) => loader.next(false)),
    );
    return merge(
        action$.pipe(
            filter((isInitializing) => isInitializing),
            switchMap(() => fetch$),
        ),
        action$.pipe(
            throttleTime(milliseconds),
            filter((isInitializing) => !isInitializing),
            switchMap(() => fetch$),
        ),
    ).pipe(shareReplay(1));
}

export function parsePxNumber(property: string): number {
    return Number(property.substr(0, property.length - 2));
}

export function idArraytoMap<T extends { id: number | string }>(
    array: T[],
): Record<number | string, T> {
    const map: Record<number | string, T> = {};
    for (const value of array) {
        map[value.id] = value;
    }
    return map;
}

export function mTakeWhile<T>(array: T[], predicate: (x: T) => boolean): T[] {
    let idx = 0;
    while (idx < array.length && predicate(array[idx])) {
        idx++;
    }
    return array.slice(0, idx);
}

export function mPartition<T>(
    array: T[],
    predicate: (x: T) => boolean,
): [T[], T[]] {
    const withTruePredicate: T[] = [];
    const withFalsePredicate: T[] = [];
    for (const x of array) {
        if (predicate(x)) {
            withTruePredicate.push(x);
        } else {
            withFalsePredicate.push(x);
        }
    }
    return [withTruePredicate, withFalsePredicate];
}

export function sanitizeLink(link: string): string {
    if (link && link[0] === 'w') return `http://${link}`;
    return link;
}

export function union<T>(s1: Set<T>, s2: Set<T>): Set<T> {
    return new Set([...s1, ...s2]);
}

export function difference<T>(s1: Set<T>, s2: Set<T>): Set<T> {
    return new Set([...s1].filter((x) => !s2.has(x)));
}

export function intersection<T>(s1: Set<T>, s2: Set<T>): Set<T> {
    return new Set([...s1].filter((x) => s2.has(x)));
}

export function filterEmptyValuesFromObject<T>(obj: T): T {
    return Object.keys(obj)
        .filter((k) => isDefined(obj[k]))
        .reduce((a, k) => ({ ...a, [k]: obj[k] }), {} as T);
}

export function groupBy<T, GroupKey extends string | number | symbol>(
    xs: T[],
    selector: (x: T) => GroupKey,
): Record<GroupKey, T[]> {
    const obj = {} as Record<GroupKey, T[]>;
    for (const x of xs) {
        const key = selector(x);
        obj[key] = [...(obj[key] || []), x];
    }
    return obj;
}

export function cleanEmptyi18nString(i18nString: i18nString): i18nString {
    return produce(i18nString, (draft: i18nString) => {
        Object.keys(draft).forEach((k) => {
            if (!draft[k] || (draft[k] as string).trim().length === 0) {
                delete draft[k];
            }
        });
    });
}

export function cleanEmptyi18nDelta(i18nDelta: i18nDelta): i18nDelta {
    return produce(i18nDelta, (draft: i18nDelta) => {
        Object.keys(draft).forEach((k) => {
            if (!draft[k] || draft[k].length === 0) {
                delete draft[k];
            }
        });
    });
}

export function getCleanI18nDelta2Server(i18nDelta: i18nDelta): i18nDelta {
    const record = {};
    Object.keys(i18nDelta).forEach((k) => {
        record[k] = new Delta2Server().process(i18nDelta[k]);
    });
    return cleanEmptyi18nDelta(record);
}

export const convertToi18nString = (
    string: i18nString,
    defaultLanguage: string,
): i18nString => {
    return typeof string === 'string'
        ? {
              [defaultLanguage]: string,
          }
        : cleanEmptyi18nString(string);
};

export function flatMap<T1, T2>(array: T1[], fn: (x: T1) => T2[]): T2[] {
    return flatten(array.map(fn));
}

/**
 * Limits source events througput to count per period (e.g. at most 10 events every 1 second).
 * Every event will be emietted (lossless logic), delay is applied only if required.
 * @param count
 * @param period
 * @returns
 */

export function rateLimit(count: number, period: number) {
    return function <T>(source: Observable<T>): Observable<T> {
        interface RateLimitRecord<T> {
            delay: number;
            until: number;
            value: T;
        }

        return source.pipe(
            scan(
                (records, value) => {
                    const now = Date.now();
                    const since = now - period;

                    // Keep a record of all values received within the last period.

                    records = records.filter((record) => record.until > since);
                    if (records.length >= count) {
                        // until is the time until which the value should be delayed.

                        const firstRecord = records[0];
                        const lastRecord = records[records.length - 1];
                        const until =
                            firstRecord.until +
                            period * Math.floor(records.length / count);

                        // concatMap is used below to guarantee the values are emitted
                        // in the same order in which they are received, so the delays
                        // are cumulative. That means the actual delay is the difference
                        // between the until times.

                        records.push({
                            delay:
                                lastRecord.until < now
                                    ? until - now
                                    : until - lastRecord.until,
                            until,
                            value,
                        });
                    } else {
                        records.push({
                            delay: 0,
                            until: now,
                            value,
                        });
                    }
                    return records;
                },
                <RateLimitRecord<T>[]>[],
            ),
            concatMap((records) => {
                const lastRecord = records[records.length - 1];
                const observable = of(lastRecord.value);
                return lastRecord.delay
                    ? observable.pipe(delay(lastRecord.delay))
                    : observable;
            }),
        );
    };
}

export function sliceArrayIntoChunks<T>(
    arrayToSplit: T[],
    chunkSize = 10,
): T[][] {
    const res = [];
    for (let i = 0; i < arrayToSplit.length; i += chunkSize) {
        const chunk = arrayToSplit.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

export const extractFilenameFromHttpResponseHeader = (
    headers: HttpHeaders,
): string => {
    /*extract filename from headers*/
    let filename = '';
    const disposition = headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, '');
    }

    return filename;
};

/*
 * https://thewoods.blog/base64url/
 */

export function base64url_encode(value: string): string {
    return btoa(value)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}
