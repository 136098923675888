import { isDefined, uuid } from '@interacta-shared/util';
import {
    EditableTableData,
    EditableTableRow,
    latestVersion,
} from '@modules/editable-table';
import { MentionType } from '@modules/mentions';
import { Delta } from 'quill/core';
import { QuillTable, QuillTableSettings } from './quill-table.model';

export function buildTable(settings: QuillTableSettings): QuillTable {
    const columns: EditableTableData['columns'] = [];

    for (let i = 0; i < settings.columns; i++) {
        const key = uuid();
        columns.push({ key });
    }

    const row = () => {
        const row: EditableTableRow = {};
        columns.forEach(({ key }) => (row[key] = new Delta()));
        return row;
    };

    const rows: EditableTableRow[] = [];
    for (let i = 0; i < settings.rows; i++) {
        rows.push(row());
    }

    return {
        clientUid: settings.clientUid,
        title: settings.title,
        data: {
            version: latestVersion,
            headerRow: settings.headerRow,
            columns,
            rows,
        },
    };
}

export function replaceQuillTableTitle(
    delta: Delta,
    table: Pick<QuillTable, 'clientUid' | 'title'>,
): Delta {
    type Mention = {
        value: string;
        mentionType: MentionType;
        clientUid?: string;
        mentionClick?: number;
    };

    const deltaClone: Delta | undefined = JSON.parse(JSON.stringify(delta));

    deltaClone.ops
        ?.map((operation) => {
            return operation.insert != null &&
                typeof operation.insert !== 'string' &&
                !!operation.insert.mention
                ? (operation.insert.mention as Mention)
                : null;
        })
        .filter(isDefined)
        .forEach((mention) => {
            if (
                mention.mentionType === 'table' &&
                mention.clientUid === table.clientUid
            ) {
                mention.value = table.title;
            }
            // Click listener must be re-generated for all mentions
            delete mention.mentionClick;
        });

    return deltaClone;
}

export const generateQuillTableClientUid = (): QuillTable['clientUid'] =>
    uuid().replace(/-/g, '');

export function addOrEditQuillTable(
    tables: QuillTable[],
    table: QuillTable,
): QuillTable[] {
    const edit = tables.some((t) => t.clientUid === table.clientUid);
    return edit
        ? tables.map((t) => (t.clientUid === table.clientUid ? table : t))
        : [...tables, table];
}

export function areQuillTablesEqual(
    a: QuillTable | null | undefined,
    b: QuillTable | null | undefined,
): boolean {
    if (a == null && b == null) {
        return true;
    }
    if (a == null || b == null) {
        return false;
    }
    const _a = JSON.stringify(a);
    const _b = JSON.stringify(b);
    return _a === _b;
}
