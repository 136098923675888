<footer
    class="typo-sm relative mb-24 flex shrink-0 items-center gap-48"
    [ngClass]="{ 'mt-8': isAttachmentMediaList }"
>
    <interacta-light-box-mini-slider
        *ngIf="showMiniSlider"
        [attachmentsCount]="attachmentsCount"
        [currentIdx]="currentAttachmentIdx"
        (click)="$event.stopPropagation()"
        (next)="nextAttachment.next()"
        (previous)="previousAttachment.next()"
    ></interacta-light-box-mini-slider>

    <interacta-light-box-zoom
        *ngIf="isAttachmentMediaList"
        [maxZoom]="maxZoom"
        [minZoom]="minZoom"
        [zoom]="zoom"
        (click)="$event.stopPropagation()"
        (zoomIn)="zoomIn.emit()"
        (zoomOut)="zoomOut.emit()"
    ></interacta-light-box-zoom>
</footer>
