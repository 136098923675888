<div
    class="typo-sm"
    [innerHtml]="
        (isEdit
            ? 'USER.EDIT_PRIVATE_EMAIL_SUBTITLE'
            : 'USER.INSERT_PRIVATE_EMAIL_SUBTITLE'
        ) | translate
    "
></div>

<interacta-form-field-v2
    class="mt-16 block"
    [control]="privateEmailControl"
    [name]="'USER.LABEL_PRIVATE_EMAIL' | translate"
>
    <interacta-input-text-v2
        name="privateEmail"
        [control]="privateEmailControl"
    ></interacta-input-text-v2>
</interacta-form-field-v2>

<div class="mt-24 flex justify-end">
    <button
        *ngIf="enableCancel"
        appearance="ghost"
        interacta-button
        size="regular"
        type="button"
        [disabled]="isLoading"
        [label]="'CONFIG_BASE.LABEL_CANCEL' | translate"
        (click)="cancel.emit()"
    ></button>
    <button
        class="ml-24"
        interacta-button
        size="regular"
        type="button"
        [bgColor]="'primary'"
        [disabled]="privateEmailControl.invalid || isLoading"
        [label]="'BUTTON.LABEL_BUTTON_SAVE' | translate"
        (click)="confirm.emit(privateEmailControl.value)"
    ></button>
</div>
