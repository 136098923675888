import { AbstractControl, ValidatorFn } from '@angular/forms';
import { flatten } from '@interacta-shared/util';
import { zipWith } from '@modules/core/helpers/generic.utils';
import { isMember } from '@modules/core/models/member/member.utils';
import { BehaviorSubject, EMPTY, Observable, concat, from, of } from 'rxjs';
import {
    debounceTime,
    distinctUntilKeyChanged,
    map,
    mergeMap,
} from 'rxjs/operators';
import {
    ICustomField,
    ICustomFieldDefinition,
    MetadataGenericListValue,
    ValidationType,
} from '../custom-metadata/custom-metadata.model';
import { getValidatorByType } from '../custom-metadata/custom-metadata.utils';
import {
    EmptyMetadataConditionTypes,
    MetadataActivationChange,
    MetadataActivationStatusMap,
    MetadataCondition,
    MetadataConditionDateEquals,
    MetadataConditionDateNotEquals,
    MetadataConditionDateTimeEquals,
    MetadataConditionDateTimeNotEquals,
    MetadataConditionListContains,
    MetadataConditionListEquals,
    MetadataConditionListIn,
    MetadataConditionListNotContains,
    MetadataConditionListNotEquals,
    MetadataConditionListNotIn,
    MetadataConditionServerType,
    MetadataConditionType,
} from './custom-metadata-activation.model';

function genericListValueToIds(value: MetadataGenericListValue): number[] {
    if (typeof value === 'number') {
        return [value];
    }

    if (value == null) {
        return [];
    }

    if (!Array.isArray(value) && value.id) {
        return [value.id];
    }

    if (Array.isArray(value)) {
        return value.map((v) =>
            typeof v === 'number' ? v : isMember(v) ? v.innerId : v.id,
        );
    }

    return [];
}

function cleanDate(date: Date): Date {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
}

function cleanDatetime(date: Date): Date {
    const d = new Date(date);
    d.setSeconds(0, 0);
    return d;
}

function listContains(
    c: MetadataConditionListContains | MetadataConditionListNotContains,
    v: MetadataGenericListValue,
): boolean {
    const values = genericListValueToIds(v);
    const valuesSet = new Set(values);
    return c.parameter.every((n) => valuesSet.has(n));
}

function listEquals(
    c: MetadataConditionListEquals | MetadataConditionListNotEquals,
    v: MetadataGenericListValue,
): boolean {
    const values = genericListValueToIds(v);
    return (
        values.length === c.parameter.length &&
        zipWith(values, c.parameter, (v, p) => v === p).every((v) => v === true)
    );
}

function listEmpty(v: MetadataGenericListValue): boolean {
    if (typeof v === 'number') {
        return false;
    }

    return v == null || (Array.isArray(v) && v.length === 0);
}

function listIn(c: MetadataConditionListIn, v: MetadataGenericListValue) {
    const values = genericListValueToIds(v);
    const admissibleValuesSet = new Set(c.parameter);

    return (
        admissibleValuesSet.size > 0 &&
        values.length > 0 &&
        values.every((v) => admissibleValuesSet.has(v))
    );
}

function listNotIn(c: MetadataConditionListNotIn, v: MetadataGenericListValue) {
    const inputValues = genericListValueToIds(v);
    const notAdmissibleValuesSet = new Set(c.parameter);

    return (
        notAdmissibleValuesSet.size > 0 &&
        inputValues.length > 0 &&
        //true se non ci sono elementi selezionati in input che fanno parte della lista dei non ammessi
        !inputValues.some((v) => notAdmissibleValuesSet.has(v))
    );
}

function dateEquals(
    c: MetadataConditionDateEquals | MetadataConditionDateNotEquals,
    v: Date,
): boolean {
    return (
        v.getFullYear() === c.parameter.getFullYear() &&
        v.getMonth() === c.parameter.getMonth() &&
        v.getDay() === c.parameter.getDay()
    );
}

function datetimeEquals(
    c: MetadataConditionDateTimeEquals | MetadataConditionDateTimeNotEquals,
    v: Date,
): boolean {
    return (
        v.getFullYear() === c.parameter.getFullYear() &&
        v.getMonth() === c.parameter.getMonth() &&
        v.getDay() === c.parameter.getDay() &&
        v.getHours() === c.parameter.getHours() &&
        v.getMinutes() === c.parameter.getMinutes()
    );
}

export function metadataConditionServerTypeToString(
    conditionType: MetadataConditionServerType,
): string {
    switch (conditionType) {
        case MetadataConditionServerType.Empty:
            return 'Empty';
        case MetadataConditionServerType.NotEmpty:
            return 'Not Empty';
        case MetadataConditionServerType.Contains:
            return 'Contains';
        case MetadataConditionServerType.NotContains:
            return 'Not Contains';
        case MetadataConditionServerType.StartsWith:
            return 'Starts With';
        case MetadataConditionServerType.EndsWith:
            return 'Ends With';
        case MetadataConditionServerType.Equal:
            return 'Equal';
        case MetadataConditionServerType.NotEqual:
            return 'Not Equal';
        case MetadataConditionServerType.LessThan:
            return 'Less Than';
        case MetadataConditionServerType.GreaterThan:
            return 'Greater Than';
        case MetadataConditionServerType.Between:
            return 'Between';
        case MetadataConditionServerType.In:
            return 'In';
        case MetadataConditionServerType.NotIn:
            return 'Not In';
        default:
            console.error('Unhandled Meta in fieldTypeToString()');
            return '';
    }
}

/**
 * Creates a (ValidationType => Status) map for the given field. The map will
 * contain only the keys of the validations present in the field.
 *
 * The status will be active if the field has no conditions, inactive otherwise.
 */
function initialActivationStatus(
    field: ICustomFieldDefinition,
): MetadataActivationStatusMap {
    const defaultStatus: MetadataActivationStatusMap = {};

    const status = field.validations.reduce(
        (status, validation) => ({
            ...status,
            [validation.validationType]: { isActive: !validation.activation },
        }),
        defaultStatus,
    );

    return status;
}

/**
 * Creates a (FieldId => (ValidationType => Status)) map. In other words,
 * from every FieldId there will be a map containing the status of its
 * validators
 */
export function initialActivationStatusMap(
    customFieldsMap: ICustomField[],
): Record<number, BehaviorSubject<MetadataActivationStatusMap>> {
    const defaultStatusMap: Record<
        number,
        BehaviorSubject<MetadataActivationStatusMap>
    > = {};

    return customFieldsMap.reduce((acc, field) => {
        const id = field.configuration.id;
        return {
            ...acc,
            [id]: new BehaviorSubject(
                initialActivationStatus(field.configuration),
            ),
        };
    }, defaultStatusMap);
}

export function isConditionActive(
    condition: MetadataCondition | undefined,
    v: unknown,
): boolean {
    if (
        !condition ||
        (v == null && !EmptyMetadataConditionTypes.includes(condition.type))
    ) {
        return false;
    }

    switch (condition.type) {
        case MetadataConditionType.GenericEmpty:
            return v == null;
        case MetadataConditionType.GenericNotEmpty:
            return v != null;
        case MetadataConditionType.GenericEquals:
            return v === condition.parameter;
        case MetadataConditionType.GenericNotEquals:
            return v !== condition.parameter;
        case MetadataConditionType.GenericBetween:
            return condition.from <= v && v <= condition.to;
        case MetadataConditionType.TextEmpty:
            return v == null || (v as string) === '';
        case MetadataConditionType.TextNotEmpty:
            return (v as string) !== '';
        case MetadataConditionType.TextContains:
            return (v as string).includes(condition.parameter);
        case MetadataConditionType.TextNotContains:
            return v !== '' && !(v as string).includes(condition.parameter);
        case MetadataConditionType.TextStartsWith:
            return (v as string).startsWith(condition.parameter);
        case MetadataConditionType.TextEndsWith:
            return (v as string).endsWith(condition.parameter);
        case MetadataConditionType.ListContains:
            return listContains(condition, v as MetadataGenericListValue);
        case MetadataConditionType.ListNotContains:
            return (
                (!Array.isArray(v) || v.length > 0) &&
                !listContains(condition, v as MetadataGenericListValue)
            );
        case MetadataConditionType.ListEmpty:
            return listEmpty(v as MetadataGenericListValue);
        case MetadataConditionType.ListNotEmpty:
            return !listEmpty(v as MetadataGenericListValue);
        case MetadataConditionType.ListEquals:
            return listEquals(condition, v as MetadataGenericListValue);
        case MetadataConditionType.ListNotEquals:
            return (
                (!Array.isArray(v) || v.length > 0) &&
                !listEquals(condition, v as MetadataGenericListValue)
            );
        case MetadataConditionType.ListIn:
            return listIn(condition, v as MetadataGenericListValue);
        case MetadataConditionType.ListNotIn:
            return listNotIn(condition, v as MetadataGenericListValue);
        case MetadataConditionType.DateEquals:
            return dateEquals(condition, v as Date);
        case MetadataConditionType.DateNotEquals:
            return !dateEquals(condition, v as Date);
        case MetadataConditionType.DatetimeEquals:
            return datetimeEquals(condition, v as Date);
        case MetadataConditionType.DatetimeNotEquals:
            return !datetimeEquals(condition, v as Date);
        case MetadataConditionType.DateLessThan: {
            return cleanDate(v as Date) < cleanDate(condition.parameter);
        }
        case MetadataConditionType.DateGreaterThan: {
            return cleanDate(v as Date) > cleanDate(condition.parameter);
        }
        case MetadataConditionType.DatetimeLessThan: {
            return (
                cleanDatetime(v as Date) < cleanDatetime(condition.parameter)
            );
        }
        case MetadataConditionType.DatetimeGreaterThan: {
            return (
                cleanDatetime(v as Date) > cleanDatetime(condition.parameter)
            );
        }
        case MetadataConditionType.NumberGreaterThan:
            return (v as number) > condition.parameter;
        case MetadataConditionType.NumberLessThan:
            return (v as number) < condition.parameter;
        default:
            console.error(
                'Unhandled condition.type in getConditionActivationFn()',
            );
            return false;
    }
}

/**
 * @param postCustomFields - Every custom field of the post, including the
 * ones not visible in the form
 * @param visibleCustomFields - The custom fields visible in the form
 * @param controls - The controls of the custom fields visible in the form
 *
 * @returns A stream which will emit an event each time the activation state
 * of a custom field changes from active to inactive or viceversa
 */
export function getConditionalValidatorsStream(
    postCustomFields: ICustomField[],
    visibleCustomFields: ICustomField[],
    controls: {
        [key: string]: AbstractControl;
    },
): Observable<MetadataActivationChange> {
    const fieldsWithActivation = flatten(
        visibleCustomFields.map((field) => {
            return field.configuration.validations
                .filter((validation) => validation.activation)
                .map((validation) => ({
                    field,
                    validation,
                }));
        }),
    );

    return from(fieldsWithActivation).pipe(
        mergeMap(({ field, validation }) => {
            const sourceId = validation.activation?.sourceFieldId;

            // The sourceControl may be not visible in edit therefore not
            // present in the form
            const sourceControl: AbstractControl | undefined = sourceId
                ? controls[sourceId]
                : undefined;

            const sourceCustomField = postCustomFields.find(
                (c) => c.configuration.id === sourceId,
            );

            const controlValuesStream$ = concat(
                sourceCustomField ? of(sourceCustomField.inputValue) : EMPTY,
                sourceControl != null ? sourceControl.valueChanges : EMPTY,
            );

            return controlValuesStream$.pipe(
                debounceTime(50),
                map((value) => ({
                    isActive: isConditionActive(
                        validation.activation?.condition,
                        value,
                    ),
                })),
                distinctUntilKeyChanged('isActive'),
                map((status) => ({
                    fieldId: field.configuration.id,
                    validationType: validation.validationType,
                    status,
                })),
            );
        }),
    );
}

export function getActiveValidators(
    fieldsActivationStatusMap: Record<
        number,
        BehaviorSubject<MetadataActivationStatusMap>
    >,
    field: ICustomField,
): ValidatorFn[] {
    const { id, validations, type } = field.configuration;

    const visibility =
        fieldsActivationStatusMap[id].value[ValidationType.ENABLED];

    const isEnabled = !visibility || visibility.isActive;

    if (!isEnabled) {
        return [];
    }

    return Object.entries(fieldsActivationStatusMap[id].value)
        .filter(([_, status]) => status.isActive)
        .map(([validType, _]) => {
            const valType = +validType as ValidationType;
            const validation = validations.find(
                (v) => v.validationType === valType,
            );
            return getValidatorByType(
                type,
                validation,
                field?.configuration?.metadata,
            );
        });
}
