import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { areEqualObjects } from '@interacta-shared/util';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RadioGroupService } from '../radio-group/radio-group.component';

@Component({
    selector: 'interacta-radio-button',
    templateUrl: './radio-button.component.html',
    styles: [
        `
            :host {
                display: flex;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgClass, AsyncPipe],
})
export class RadioButtonComponent<T>
    implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
    @Input({ required: true }) value!: T;
    @Input() disabled = false;
    @Input() name?: string;
    @Input() checked?: boolean;
    @Input() align?: 'start' | 'center' = 'center';

    @Output() changeChecked = new EventEmitter<boolean>();

    checked$ = new ReplaySubject<boolean>(1);
    _name!: string;

    private destroy$ = new Subject<void>();

    @ViewChild('inputRadio')
    inputRadio!: ElementRef<HTMLInputElement>;

    constructor(@Optional() private radioGroupService?: RadioGroupService<T>) {}

    ngAfterViewInit(): void {
        this.checked$
            .pipe(takeUntil(this.destroy$))
            .subscribe((val) => (this.inputRadio.nativeElement.checked = val));
    }

    ngOnInit(): void {
        if (this.radioGroupService) {
            this.radioGroupService.value
                .pipe(takeUntil(this.destroy$))
                .subscribe(({ value }) => {
                    this.checked$.next(areEqualObjects(value, this.value));
                });

            this._name = this.radioGroupService.groupName;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['checked']) {
            this.checked$.next(this.checked ?? false);
        }

        if (changes['name']) {
            this._name = changes['name'].currentValue;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    change(event: any): void {
        const checked: boolean = event.target.checked ?? false;

        this.changeChecked.emit(checked);

        if (this.radioGroupService && checked) {
            this.radioGroupService.changeSelectedValue(this.value);
        }
    }
}
