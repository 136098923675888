<header
    class="px-360 typo-sm flex h-64 w-full shrink-0 items-center justify-between text-center"
>
    <span *ngIf="attachment" class="z-10 line-clamp-1 w-full break-all">{{
        attachment.name
    }}</span>
    <interacta-light-box-header-buttons
        *ngIf="attachment"
        class="fixed right-20 top-16 z-10 flex space-x-16 break-all"
        [attachment]="attachment"
        [canEdit]="canEdit"
        [canShowSearchBar]="canShowSearchBar"
        [customButtons]="customButtons"
        [isInfoActive]="isInfoActive"
        [isSearchBarActive]="isSearchBarActive"
        [showDownload]="!!attachment.canDownload && attachment.downloadable"
        [showOpenInDrive]="!!attachment.drive && isFileAttachment"
        (closeDialog)="closeDialog.emit()"
        (download)="download.emit(attachment)"
        (editImage)="editImage.emit()"
        (favorite)="favorite.emit()"
        (info)="info.emit()"
        (openInDrive)="openInDrive.emit(attachment)"
        (openSearchBar)="openSearchBar.emit()"
    ></interacta-light-box-header-buttons>
</header>
