import {
    LocalStorageService,
    UserAwareMode,
} from '@modules/core/services/local-storage.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

class DataLoader<T> {
    protected data: T | undefined;
    protected dataSubject: Subject<T | undefined>;

    constructor(data?: T) {
        this.data = data;
        this.dataSubject = new BehaviorSubject(this.data);
    }

    public staticData = (): T | undefined => this.data;
    public onDataChange = (): Observable<T | undefined> =>
        this.dataSubject.asObservable().pipe(distinctUntilChanged());

    public updateData(data: T | undefined) {
        this.data = data;
        this.dataSubject.next(data);
    }
}

export class DataLoaderBehaviour<T> extends DataLoader<T> {}

export class DataLoaderSubject<T> extends DataLoader<T> {
    constructor() {
        super();
        this.dataSubject = new Subject<T | undefined>();
    }
}

export class DataLoaderCached<T> extends DataLoader<T> {
    constructor(
        protected cacheKey: string,
        protected localStorageService: LocalStorageService,
        protected defaultData?: T,
        protected userAware: UserAwareMode = true,
    ) {
        super();
        this.dataSubject = new ReplaySubject(1);
        this.cacheKey = cacheKey;
        this.localStorageService = localStorageService;
        this.userAware = userAware;
        this.defaultData = defaultData;
        this.data = defaultData;
    }

    /**
     * Load data from cache
     */
    public initialize(): void {
        super.updateData(this.getCache());
    }

    public staticData = (): T | undefined => {
        return this.data;
    };

    public updateData(data: T): void {
        super.updateData(data);
        this.setCache(data);
    }

    private getCache(): T | undefined {
        const cache = this.localStorageService.getEntry<T>(
            this.cacheKey,
            this.userAware,
        );
        return cache ?? this.defaultData;
    }

    private setCache(data: T): void {
        this.localStorageService.setEntry(this.cacheKey, data, this.userAware);
    }
}
