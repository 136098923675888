import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { IAttachment } from '@modules/post/models/attachment/attachment.model';
import { createAction, props } from '@ngrx/store';

enum UploadActionType {
    OpenDialog = '[UploadDialog] Open Dialog',
    CloseDialog = '[UploadDialog] Close Dialog',
    ExpandDialog = '[UploadDialog] Expand Dialog',
    CollapseDialog = '[UploadDialog] Collapse Dialog',

    AttachmentLoadedSuccess = '[UploadDialog] Upload file success',
    SaveAttachment = '[UploadDialog] Save attachment to post',

    UpdateSessionAttachmentsCounterMap = '[UploadDialog] Update session Attachments Counter Map',
    AddHashtagsToAttach = '[UploadDialog] Add hashtags on SaveAttachment',
}

export const openDialog = createAction(UploadActionType.OpenDialog);
export const closeDialog = createAction(UploadActionType.CloseDialog);
export const expandDialog = createAction(UploadActionType.ExpandDialog);
export const collapseDialog = createAction(UploadActionType.CollapseDialog);

export const updateSessionAttachmentsMap = createAction(
    UploadActionType.UpdateSessionAttachmentsCounterMap,
    props<{
        sessionId: string;
        counter: number;
    }>(),
);
export const attachmentLoadedSuccess = createAction(
    UploadActionType.AttachmentLoadedSuccess,
    props<{
        attachment: IAttachment;
    }>(),
);

export const saveAttachment = createAction(
    UploadActionType.SaveAttachment,
    props<{
        attachment: IAttachment;
        postId: number;
    }>(),
);

export const addHashtagsToAttach = createAction(
    UploadActionType.AddHashtagsToAttach,
    props<{
        hashtags: IHashTag[];
    }>(),
);
